import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import useInternalization from '../../utils/hooks/useInternalization';
import { DefaultModalType } from '../../shared/types';
import { defaultModalInfo } from './utils/constants';
import { PLANS } from '../../utils/constants';
import { removeEmailNotificationInfoTag } from '../../utils/controllers/account/user';
import { toggleDefaultModalShow } from '../../utils/actions/defaultModal';
import { getDefaultModalState } from '../../utils/selectors/modals';
var DefaultModal = function () {
    var _a;
    var t = useInternalization().t;
    var _b = useSelector(getDefaultModalState), type = _b.type, show = _b.show;
    var accountId = useSelector(getAccountId);
    var userId = useSelector(getUserId);
    var dispatch = useDispatch();
    var typeInfo = defaultModalInfo[type];
    var isTypeEmailNotifInfo = type === DefaultModalType.EMAIL_NOTIF_INFO;
    if (!show)
        return null;
    var hideModal = function () {
        dispatch(toggleDefaultModalShow({ show: false }));
    };
    var redirectToSubscription = function () {
        browserHistory.push('/settings/subscription');
        hideModal();
    };
    var redirectToMyAccount = function () {
        browserHistory.push('/settings/my-account');
        hideModal();
    };
    var hasPlanInBlockedType = function (plan) { var _a; return typeInfo && !!((_a = typeInfo.plans) === null || _a === void 0 ? void 0 : _a.includes(plan)); };
    var getButtonsActions = function () {
        var defaultActions = {
            primary: redirectToSubscription,
            secondary: hideModal,
        };
        if (isTypeEmailNotifInfo) {
            var secondary = function () {
                removeEmailNotificationInfoTag(accountId, userId);
                redirectToMyAccount();
            };
            return { primary: hideModal, secondary: secondary };
        }
        return defaultActions;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { maxWidth: "sm", className: "defaultModal", open: show, onClose: hideModal },
            React.createElement(DialogTitle, null, typeInfo === null || typeInfo === void 0 ? void 0 :
                typeInfo.header,
                React.createElement(CloseIcon, { onClick: hideModal })),
            React.createElement("img", { src: typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.image, alt: typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.title }),
            React.createElement(DialogContent, { className: "content" },
                React.createElement("h1", null, typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.title),
                !!((_a = typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.plans) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("div", { className: "plans" },
                    t('Available in:'),
                    hasPlanInBlockedType(PLANS.team) && React.createElement("span", { className: "teamPlan" }, t('Team')),
                    hasPlanInBlockedType(PLANS.business) && React.createElement("span", { className: "businessPlan" }, t('Business')))),
                React.createElement("p", null, typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.desc),
                !!(typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.link) && (React.createElement("a", { href: typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.link, target: "_blank", rel: "noreferrer" }, t('Learn more in Planless Academy')))),
            React.createElement(DialogActions, { className: "actions" },
                React.createElement(Button, { variant: "contained", onClick: getButtonsActions().secondary }, typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.buttons.secondary.text),
                React.createElement(Button, { variant: "contained", onClick: getButtonsActions().primary, color: "primary" }, typeInfo === null || typeInfo === void 0 ? void 0 : typeInfo.buttons.primary.text)))));
};
export default DefaultModal;
