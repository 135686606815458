import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { CLOUD_FUNCTIONS } from '../constants';

const dbRelationship = {
    datetime: 'Datetime',
    aId: 'accountId',
    tId: 'task Id or root',
    minEffort: 'minEffort',
    maxEffort: 'maxEffort',
    nb_tasks_done: 'Nb tasks done',
    nb_tasks_not_done: 'Nb tasks not done',
    percent_done: '% done',
    time_worked: 'Time worked',
    days_off: 'Days-off',
    nb_Team_members: 'Nb Team members',
    nb_hours_workforce: 'Nb hours workforce',
    Deadline: 'Deadline',
    expected_finish: 'Expected finish',
    Earliest: 'Earliest',
    Latest: 'Latest',
    teamMembers: 'teamMembers',
    skills: 'skills',
    minEffort_Team_members: 'minEffort Team members',
    maxEffort_Team_members: 'maxEffort Team members',
    nb_tasks_done_Team_members: 'Nb tasks done Team members',
    nb_tasks_not_done_Team_members: 'Nb tasks not done Team members',
    percent_done_Team_members: '% done Team members',
    time_worked_Team_members: 'Time worked Team members',
    days_off_Team_members: 'Days-off Team members',
    minEffort_Skills: 'minEffort Skills',
    maxEffort_Skills: 'maxEffort Skills',
    nb_tasks_done_Skills: 'Nb tasks done Skills',
    nb_tasks_not_done_Skills: 'Nb tasks not done Skills',
    percent_done_Skills: '% done Skills',
    time_worked_Skills: 'Time worked Skills',
    nb_hours_events_on_the_day: 'Nb hours events on the day',
    nb_hours_events_on_the_day_Team_members: 'Nb hours events on the day Team members',
    teamMemberInternalCost: 'teamMemberInternalCost',
    teamMemberBillableCost: 'teamMemberBillableCost',
    totalInternalCost: 'totalInternalCost',
    totalBillableCost: 'totalBillableCost',
    totalInternalCostYear: 'totalInternalCostYear',
    totalBillableCostYear: 'totalBillableCostYear',
    totalInternalCostMonth: 'totalInternalCostMonth',
    totalBillableCostMonth: 'totalBillableCostMonth',
    totalInternalCostWeek: 'totalInternalCostWeek',
    totalBillableCostWeek: 'totalBillableCostWeek',
    totalInternalCostByUser: 'totalInternalCostByUser',
    totalBillableCostByUser: 'totalBillableCostByUser',
    totalInternalCostByUserYear: 'totalInternalCostByUserYear',
    totalBillableCostByUserYear: 'totalBillableCostByUserYear',
    totalInternalCostByUserMonth: 'totalInternalCostByUserMonth',
    totalBillableCostByUserMonth: 'totalBillableCostByUserMonth',
    totalInternalCostByUserWeek: 'totalInternalCostByUserWeek',
    totalBillableCostByUserWeek: 'totalBillableCostByUserWeek',
    totalInternalCostBySkill: 'totalInternalCostBySkill',
    totalBillableCostBySkill: 'totalBillableCostBySkill',
    totalInternalCostBySkillYear: 'totalInternalCostBySkillYear',
    totalBillableCostBySkillYear: 'totalBillableCostBySkillYear',
    totalInternalCostBySkillMonth: 'totalInternalCostBySkillMonth',
    totalBillableCostBySkillMonth: 'totalBillableCostBySkillMonth',
    totalInternalCostBySkillWeek: 'totalInternalCostBySkillWeek',
    totalBillableCostBySkillWeek: 'totalBillableCostBySkillWeek',
    budgetValue: 'budgetValue',
    budgetType: 'budgetType',
    periodicity: 'periodicity',
    expenses: 'expenses',
    calendarIntegrationEventByTeam: 'calendarIntegrationEventByTeam',
    calendarIntegrationEventTotal: 'calendarIntegrationEventTotal'
};

const mapStatsResults = (accountId, taskId, data) => {
    return (data.length ? data : []).map(line => {
        const result = { accountId, id: taskId };
        Object.keys(line).forEach(key => {
            result[dbRelationship[key]] = line[key] || '';
        });
        result.accountId = accountId;
        result.taskId = taskId;
        return result;
    });
};

export const getStatsData = async ({ accountId, taskId, prevResults = [], page = 1 }) => {
    try {
        const totalResults = prevResults;

        const { data: { results, nbPages } } = await axios.request({
            method: 'post',
            url: CLOUD_FUNCTIONS.getStatsData,
            data: { accountId, taskId, page }
        });

        if(nbPages > page) {
            return getStatsData({ accountId, taskId, prevResults: results, page: page + 1 });
        }

        return mapStatsResults(accountId, taskId, [...totalResults, ...results]);
    } catch(err) {
        Bugsnag.notify(err);
        return [];
    }
};

export const saveStatsData = (data) => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.saveForStats,
    data,
});


export const alertsHandler = (data) => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.alertsHandler,
    data,
});
