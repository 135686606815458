import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import SplitBy from '../SplitBy/SplitBy';
import OverViewCard from '../OverViewCard';
import CostBudgetTable from './CostBudgetTable';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { getPrevisionValue,  getExpensesValueNoAsync, getFieldNamesForPeriodicity, getTaskWorkValueFuture } from '../../../utils/costBudget';
import {  getAccountsDataTasks  } from '../../../utils/controllers/tasks/budget';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import { currencyFormat  } from '../../../utils/currencyFormat';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {
    agregateData,
    getDataByTask,
    convertToDateTime,
    deepCloneDataSets,
    backgroundColor,
    borderDash,
    downloadAsImage,
    getColor,
    exportCSV,
    validateNumber,
    getDateFormated,
    getMonth,
} from '../utils';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const getDataBySkills = ({lastValue, currentChartData, csvLines, data, tasksIds, skillsData, hasBreakdownByTask, stateTasks, totalInternalCostBySkillLabel, totalBillableCostBySkillLabel }) => data.reduce((acc, skillId) => {
    if(!skillId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = skillId + '-' + taskId;
            let internalCost = 0;
            let billableCost = 0;
            let budgetValue = 0;
            let expenses = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData && findData[totalInternalCostBySkillLabel] ) {
                const idxOfSelect = findData.skills.findIndex(key => skillId === key);
                if (idxOfSelect > -1) {
                    internalCost = parseInt(findData[totalInternalCostBySkillLabel][idxOfSelect]);
                    billableCost =  parseInt(findData[totalBillableCostBySkillLabel][idxOfSelect]);
                    budgetValue =  parseInt(findData.budgetValue);
                    expenses =  parseInt(findData.expenses);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    billableCost = lastChartPointData?.billableCost || 0;
                    internalCost = lastChartPointData?.internalCost || 0;
                    budgetValue =  lastChartPointData.budgetValue;
                    expenses = lastChartPointData.expenses;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                billableCost = lastChartPointData?.billableCost || 0;
                internalCost = lastChartPointData?.internalCost || 0;
                budgetValue =  lastChartPointData.budgetValue|| 0;
                expenses = lastChartPointData.expenses || 0;
            }

            acc.push({
                id,
                breakdownId: skillId,
                label:   skillsData[skillId].name +  '-' +  stateTasks[taskId].title,
                internalCost: internalCost,
                budgetValue,
                expenses,
                billableCost: billableCost,
                tasksId: [taskId],
                parentName: skillsData[skillId].name,
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }
    // if you split by task then

    // if no other breakdown.
    const linesWithSkill = csvLines.filter(line => line.skills.includes(skillId));

    const values = linesWithSkill.reduce((acc,l) => {
        const idxOfSelect = l.skills.findIndex(key => skillId === key);
        if (idxOfSelect >= 0) {
            acc.billableCost += l[totalBillableCostBySkillLabel] && l[totalBillableCostBySkillLabel][idxOfSelect] ? l[totalBillableCostBySkillLabel][idxOfSelect] : 0;
            acc.internalCost += l[totalInternalCostBySkillLabel] && l[totalInternalCostBySkillLabel][idxOfSelect] ? l[totalInternalCostBySkillLabel][idxOfSelect] : 0;
            acc.budgetValue += l['budgetValue'] && l['budgetValue'] ? l['budgetValue'] : 0;
            acc.expenses += l['expenses'] && l['expenses'] ? l['expenses'] : 0;
            acc.tasksId.push(l.id);
        }
        return acc;
    }, { internalCost: 0,budgetValue: 0, billableCost: 0, tasksId: []});

    acc.push({
        id: skillId,
        breakdownId: skillId,
        label:  skillsData[skillId].name,
        internalCost: values.internalCost,
        billableCost: values.billableCost,
        budgetValue: values.budgetValue,
        expenses: values.expenses,
        tasksId: values.tasksId,
        parentName: skillsData[skillId].name,
    });

    return acc;
}, []);

const breakdownDataByUseres = ({lastValue, currentChartData, csvLines, data, tasksIds, teamMembersData, hasBreakdownByTask, stateTasks,
    totalInternalCostByUserLabel,
    totalBillableCostByUserLabel,

}) => data.reduce((acc, userId) => {
    if(!userId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = userId + '-' + taskId;
            let internalCost = 0;
            let billableCost = 0;
            let budgetValue = 0;
            let expenses = 0;

            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData?.teamMembers?.findIndex(key => userId === key);
                if (idxOfSelect > -1 && findData[totalInternalCostByUserLabel]) {
                    internalCost = parseInt(findData[totalInternalCostByUserLabel][idxOfSelect]);
                    billableCost =  parseInt(findData[totalBillableCostByUserLabel][idxOfSelect]);
                    budgetValue =  parseInt(findData.budgetValue);
                    expenses =  parseInt(findData.expenses);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    billableCost = lastChartPointData?.billableCost || 0;
                    internalCost = lastChartPointData?.internalCost || 0;
                    budgetValue =  lastChartPointData.budgetValue|| 0;
                    expenses = lastChartPointData.expenses || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                billableCost = lastChartPointData?.billableCost || 0;
                internalCost = lastChartPointData?.internalCost || 0;
                budgetValue =  lastChartPointData.budgetValue|| 0;
                expenses = lastChartPointData.expenses || 0;
            }

            acc.push({
                id,
                label:   teamMembersData[userId]?.displayName +  '-' +  stateTasks[taskId].title,
                internalCost: internalCost,
                breakdownId: userId,
                billableCost: billableCost,
                tasksId: [taskId],
                budgetValue,
                expenses,
                parentName: teamMembersData[userId]?.displayName || 'Deleted User',
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }

    // if no other breakdown.
    const linesWithUser = csvLines.filter(line => line.teamMembers.includes(userId));
    const values = linesWithUser.reduce((acc,line) => {
        const idxOfSelect = line?.teamMembers?.findIndex(key => userId === key);
        if (idxOfSelect >= 0) {
            acc.billableCost += line[totalBillableCostByUserLabel] && line[totalBillableCostByUserLabel][idxOfSelect] ? line[totalBillableCostByUserLabel][idxOfSelect] : 0;
            acc.internalCost += line[totalInternalCostByUserLabel] && line[totalInternalCostByUserLabel][idxOfSelect] ? line[totalInternalCostByUserLabel][idxOfSelect] : 0;
            acc.budgetValue += line['budgetValue'] && line['budgetValue'] ? line['budgetValue'] : 0;
            acc.expenses += line['expenses'] && line['expenses'] ? line['expenses'] : 0;
            acc.tasksId.push(line.id);
        }
        return acc;
    }, { internalCost: 0, budgetValue: 0, billableCost: 0, tasksId: []});

    acc.push({
        id: userId,
        breakdownId: userId,
        label:  teamMembersData[userId]?.displayName  || 'Deleted User',
        internalCost: values.internalCost,
        billableCost: values.billableCost,
        budgetValue: values.budgetValue,
        expenses: values.expenses,
        tasksId: values.tasksId,
        parentName: teamMembersData[userId]?.displayName  || 'Deleted User',
    });

    return acc;
}, []);

ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 	'Internal Cost',
                data: [],
                yAxisID: 'y',
                pointRadius: 0
            },
            {
                label: 	'Billable Cost',
                data: [],
                yAxisID: 'y',
                pointRadius: 0
            },
        ]
    },
    options: {
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                    filter: (item)=>{
                        return (item.text.indexOf('Expenses Cost') === -1);
                    }
                }
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function(context){
                        return getDateFormated(context[0].label);
                    },
                    label: function(context) {
                        return `${context.dataset.label}: ${currencyFormat({ value: context.parsed.y, currency: 'EUR' })}`;
                    },
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size:12
                },
                titleColor: '#fff',
                titleFont: {
                    size:14
                },
                boxWidth: 8,
                boxHeight: 8
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function(value) {
                        const labelValue = this.getLabelForValue(value);
                        if(moment(labelValue, 'YYYY', true).isValid()){
                            return labelValue;
                        }
                        else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        }
                        else if(moment(labelValue, 'M/YYYY', true).isValid()){
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }

                        return `${moment(labelValue).format('MMM D, YY')}`;
                    },
                    color: '#999',
                    font: {
                        size: 11
                    }
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                lineWidth: 0,
                drawTicks: false,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11
                    }
                }
            },
        },

    },
};

const CostBudget = ({
    workspaceData,
    accountId,
    stateTasks,
    datesRange,
    workspaceSelected,
    childsParents,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    userTimezone
}) => {
    const accountSkills = useSelector((state) => state.app?.account?.skills);
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const settingsCurrency = useSelector((state)=> state?.app?.account?.settings.costBudget?.currency || 'USD');
    const [overviewCurrentData, setOverviewCurrentData] = useState( {
        internalCost:  0,
        billableCost:  0,
        budget: 0,
        type: 'fixed',
        periodicity: ''
    });
    const chartCanvas = useRef(null);
    const [csvData, setCSVData] = useState({});
    const [currentChart, setCurrentChart] = useState(null);
    const [accountDataTasks, setAccountDataTasks] = useState(null);
    const [currentData, setCurrentData] = useState({labels: [], datasets: {} } );
    const intl = useIntl();
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl).filter(el => el.value === 'none' || el.value === 'users' || el.value === 'skills');
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);
    const [displayData, setDisplayData] = useState({labels: [], datasets: {} } );
    const dispatch = useDispatch();

    const updateBreakdowns = async (newBreakdowns) => {
        setBreakdownData(newBreakdowns);
    };

    useEffect(() => {
        (async () => {
            const accountDataTasks = await getAccountsDataTasks();
            setAccountDataTasks(accountDataTasks);
        })();
    }, []);

    useEffect(()=> {
        if(currentChart){
            currentChart.options.plugins.tooltip.callbacks.label = (context) => {
                return`${context.dataset.label}: ${currencyFormat({ value: context.parsed.y, currency: settingsCurrency })}`;
            };
        }
    }, [settingsCurrency,currentChart]);
    // Should only occor on the mount
    useEffect( () => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = (e) => {
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);

                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));

                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });

                const ReactVirtGrid = document.getElementsByClassName('ReactVirtualized__Grid');

                if(
                    ReactVirtGrid.length > 1 &&
                   ReactVirtGrid[1]?.children.length &&
                   ReactVirtGrid[1]?.children[0]?.children.length
                ) {
                    const width = document.getElementsByClassName('ReactVirtualized__Grid')[1].children[0].children[0].style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };

            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]);


    const onWorkspaceChange = async () => {
        dispatch(showLoader());
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
        const data = await getDataByTask({
            accountId: accountId,
            workspaceData: workspaceData,
            selectedTasks: [],
        });

        setCSVData(data);
    };

    useEffect( () => {
        onWorkspaceChange();
    }, [workspaceData, accountId]);


    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }

        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item, datasetsParents) => {
            const defaultExpenses = datasetsParents.find(({id}) => id === 'defaultExpenses');
            const defaultBudget = datasetsParents.find(({id}) => id === 'defaultBudget');
            let line = '';
            line += `${item.name}\n`;
            line += `Internal Cost;${item.internalCost.join(';')}\n`;
            line += `Billable Cost;${item.billableCost.join(';')}\n`;
            line += `Budget;${defaultBudget.budgetValue.join(';')}\n`;
            line += `Expenses;${defaultExpenses.expenses.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'cost-budget');
    };

    const breakdownDataByTask = breakdownData.find(el => el.value === 'tasks');
    const breakdownDataBySkills = breakdownData.find(el => el.value === 'skills');
    const breakdownDataByUsers = breakdownData.find(el => el.value === 'users');

    const handleData = () => {
        if(currentChart && !_.isEmpty(csvData) && accountDataTasks) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let possibleBreaks = [];
            // get all the tasks IDS
            let workspaceTasks = workspaceSelected === 'root' ? Object.entries(stateTasks).map(([id, el]) => !el.parent ? id  : false).filter(Boolean) : [workspaceSelected];
            const { currentBudgetValue,currentExpensesValue } = workspaceTasks.reduce((acc, taskId ) => {
                const currentBudgetValue = _.get(stateTasks,[taskId, 'costBudgetSettings','value'], 0);
                const currentExpensesValue =  stateTasks[taskId] ? getExpensesValueNoAsync(stateTasks[taskId], stateTasks, accountDataTasks) : 0;
                return {
                    currentBudgetValue: currentBudgetValue + acc.currentBudgetValue,
                    currentExpensesValue: currentExpensesValue + acc.currentExpensesValue
                };
            }, {
                currentBudgetValue: 0,currentExpensesValue: 0
            });

            const taskPeriodicity = workspaceSelected === 'root' ? 'none' : _.get(stateTasks, [workspaceSelected, 'costBudgetSettings','periodicity' ], 'none');
            const taskCostType = workspaceSelected === 'root' ? 'fixed' : _.get(stateTasks, [workspaceSelected, 'costBudgetSettings','type'], 'fixed');
            const csvLabels = getFieldNamesForPeriodicity(taskPeriodicity);


            const allChartPoints = Object.values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime)*60*60*4 * 1000);
                    const formatedDate =  `${newDate.getFullYear()}/${getMonth(newDate.getMonth() + 1)}/${newDate.getDate()}`;

                    /*
                        if(moment(formatedDate, 'YYYY/MM/DD').isSame(moment(), 'day')){
                            return acc;
                        }
                    */

                    if(acc[formatedDate]){
                        return {
                            ...acc,
                            [formatedDate]: [el,...acc[formatedDate]]
                        };
                    }

                    return {
                        ...acc,
                        [formatedDate]: [el]
                    };
                }, {});

            let lastValue = '';
            // have to go throw all of the checkpoints
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                if (breakdownDataByUsers?.lvl === 0 && breakdownDataByUsers.selectedData.length) {
                    possibleBreaks = breakdownDataByUsers.selectedData;

                    const data = breakdownDataByUseres({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataByUsers.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        teamMembersData: accountTeamMembers,
                        totalInternalCostByUserLabel: csvLabels.totalInternalCostByUser,
                        totalBillableCostByUserLabel: csvLabels.totalBillableCostByUser,
                    });

                    lastValue = key;
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }

                if (breakdownDataBySkills?.lvl === 0 && breakdownDataBySkills.selectedData.length) {
                    possibleBreaks = breakdownDataBySkills.selectedData;

                    const data = getDataBySkills({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataBySkills.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        skillsData: accountSkills,
                        totalInternalCostBySkillLabel: csvLabels.totalInternalCostBySkill,
                        totalBillableCostBySkillLabel: csvLabels.totalBillableCostBySkill,
                    });
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }

                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) =>  {
                    return {
                        id: `default${idx}`,
                        label: '',
                        billableCost: parseFloat(_.get(line,[csvLabels.totalBillableCost],0)),
                        internalCost: parseFloat(_.get(line,[csvLabels.totalInternalCost],0)),
                        budgetValue: parseFloat(_.get(line,['budgetValue'],0)),
                        expenses: parseFloat(_.get(line,['expenses'],0)),
                        tasksId: [line.id],
                        parentName: ''
                    };
                });

                return { ...chartPointAcc,
                    [key]: values
                };
            }, {});

            const chartData = Object.entries(newData).reduce((acc, [key, items]) => {
                acc.labels.push(key);

                items.forEach((el, itemIdx) => {
                    if (!acc.datasets[el.id+'-internalCost']) {
                        const [colorA,colorB ] = getColor(possibleBreaks.length,itemIdx);
                        acc.datasets[el.id+'-internalCost'] =  {
                            ...el,
                            // missing this one
                            id: el.id,
                            label:  el.label + ' Internal Cost',
                            data: [validateNumber(el.internalCost)],
                            borderColor: colorA,
                            backgroundColor: colorA,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            source: el.tasksId,
                            labelType: 'internalCost',
                            segment: {
                                borderWidth: 2,
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorA, currentChart, userTimezone),
                            }
                        };
                        acc.datasets[el.id+'-billableCost'] =  {
                            ...el,
                            id: el.id,
                            source: el.tasksId,
                            // missing this one
                            label:  el.label + ' Billable Cost',
                            data: [validateNumber(el.billableCost)],
                            borderColor: colorB,
                            backgroundColor: colorB,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            // the tasks Id used
                            labelType: 'billableCost',
                            segment: {
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorB, currentChart, userTimezone),
                            }
                        };

                    } else {
                        acc.datasets[el.id+'-internalCost'].data.push(validateNumber(el.internalCost));
                        acc.datasets[el.id+'-billableCost'].data.push(validateNumber(el.billableCost));
                    }
                    if(!acc.datasets['budgetValue']) {
                        acc.datasets['expenses'] =  {
                            ...el,
                            showLine: false,
                            id: 'defaultExpenses',
                            ignoreColumn: true,
                            source: el.tasksId,
                            label: 'Expenses Cost',
                            data: [breakdownDataBySkills || breakdownDataByUsers ? 0 : validateNumber(el.expenses)],
                            borderColor: 'green',
                            backgroundColor: 'green',
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            labelType: 'expenses',
                            hidden: true,
                        };
                        acc.datasets['budgetValue'] =  {
                            ...el,
                            id: 'defaultBudget',
                            source: el.tasksId,
                            ignoreColumn: true,
                            // missing this one
                            label:  'Budget',
                            data: [validateNumber(el.budgetValue)],
                            borderColor: 'red',
                            backgroundColor: 'red',
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            // the tasks Id used
                            labelType: 'budgetValue',
                        };
                    }else {
                        acc.datasets['budgetValue'].data.push(validateNumber(el.budgetValue));
                        acc.datasets['expenses'].data.push(breakdownDataBySkills || breakdownDataByUsers ? 0 : validateNumber(el.expenses));
                    }
                });

                return acc;
            } , {
                labels: [],
                datasets: {
                    budgetValue: {
                        data: [],
                        borderColor: 'red',
                        backgroundColor: 'red',
                        cubicInterpolationMode: 'monotone',
                        pointRadius: 0,
                        borderWidth: 2,
                        pointBorderColor: '#fff',
                        pointBorderWidth: 1,
                        // the tasks Id used
                        labelType: 'budgetValue',
                        ignoreColumn: true,
                        // missing this one
                        label:  'Budget',
                        id: 'defaultBudget',
                    },
                    expenses: {
                        showLine: false,
                        id: 'defaultExpenses',
                        ignoreColumn: true,
                        // missing this one
                        label: 'Expenses Cost',
                        // TODO validan if breakdown should no have value
                        data: [],
                        borderColor: 'green',
                        backgroundColor: 'green',
                        cubicInterpolationMode: 'monotone',
                        pointRadius: 0,
                        borderWidth: 2,
                        pointBorderColor: '#fff',
                        pointBorderWidth: 1,
                        // the tasks Id used
                        labelType: 'expenses',
                        hidden: true,
                    }
                }
            });
            if(moment(endDate).tz(userTimezone).isAfter(moment().tz(userTimezone))) {
                let workspaceTasks =
                    workspaceSelected === 'root' ?
                        [({source: 'root', childs: Object.keys(stateTasks)})] :
                        [({source: workspaceSelected, childs: _.get(childsParents, workspaceSelected , [workspaceSelected])})];
                // used
                const availableDates = [];
                const skillsSplitByDay = {};
                const userSplitedByDay = {};
                const tasksSplitedByDay = {};
                workspaceTasks.forEach(base => {
                    base.childs.forEach(taskKey => {
                        const currentTask = { id: taskKey, ...stateTasks[taskKey] };
                        let estFinishDate = currentTask?.estimations?.expectedAt && moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);

                        /*   if(currentTask.status  === 'done' && moment(currentTask.doneAt).isSame(moment(), 'day')) {
                            estFinishDate = moment();
                            currentTask.estimations = {
                                userId: []
                            };
                        } else  */
                        if(
                            !estFinishDate
                            || currentTask.status  === 'done'
                            ||  moment(estFinishDate).tz(userTimezone).isBefore(moment().tz(userTimezone))
                            || currentTask.childrens || !currentTask?.skill?.length
                        ){
                            return false;
                        }


                        const expecetedDate = estFinishDate.format('YYYY/MM/DD');

                        if(breakdownDataBySkills && breakdownDataBySkills?.selectedData?.length) {
                            const skillsToCompare = breakdownDataBySkills.selectedData.length ? breakdownDataBySkills.selectedData : [];
                            if(!skillsToCompare.some(skillId => currentTask?.skill?.includes(skillId))) {
                                return  false;
                            }
                        } else if(breakdownDataByUsers && breakdownDataByUsers?.selectedData?.length) {
                            const estimationsUserId = Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId];
                            if(!estimationsUserId.find(el => breakdownDataByUsers.selectedData.includes(el))) {
                                return  false;
                            }
                        }

                        if(!availableDates.includes(expecetedDate)){
                            availableDates.push(expecetedDate);
                        }
                        let currentTaskInternalCost = 0;
                        let currentTaskBillableCost =0;
                        /*   if(moment(currentTask.doneAt).isSame(moment(), 'day')){
                            currentTaskInternalCost = currentExpensesValue +  getTaskWorkValue({task: currentTask, allTasks: stateTasks, checkWorktimeEntry: null, accountUsers: accountTeamMembers, costType: 'internalCost'});
                            currentTaskBillableCost = currentExpensesValue +  getTaskWorkValue({task: currentTask, allTasks: stateTasks, checkWorktimeEntry: null, accountUsers: accountTeamMembers, costType: 'billableCost'});
                        } else { */
                        currentTaskInternalCost = currentExpensesValue +  getTaskWorkValueFuture({ task: currentTask, allTasks: stateTasks, accountUsers: accountTeamMembers, accountSkills: accountSkills, costType: 'internalCost' });
                        currentTaskBillableCost = currentExpensesValue + getTaskWorkValueFuture({ task: currentTask, allTasks: stateTasks, accountUsers: accountTeamMembers, accountSkills: accountSkills, costType: 'billableCost' });
                        /*  } */
                        // for tasks
                        if(tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate][base.source]
                        ){
                            tasksSplitedByDay[expecetedDate][base.source].internalCost += currentTaskInternalCost;
                            tasksSplitedByDay[expecetedDate][base.source].billableCost += currentTaskBillableCost;
                        } else if(tasksSplitedByDay[expecetedDate] && tasksSplitedByDay[expecetedDate]) {
                            tasksSplitedByDay[expecetedDate][base.source] = {
                                internalCost: currentTaskInternalCost,
                                billableCost: currentTaskBillableCost,
                            };
                        } else {
                            tasksSplitedByDay[expecetedDate] = {
                                [base.source]: {
                                    internalCost: currentTaskInternalCost,
                                    billableCost: currentTaskBillableCost,
                                }
                            };
                        }

                        if(breakdownDataByUsers?.selectedData?.length) {
                            const estimationsUserId = Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId];
                            estimationsUserId.forEach(userId => {
                                if(userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][userId] ){
                                    userSplitedByDay[expecetedDate][userId].internalCost += currentTaskInternalCost;
                                    userSplitedByDay[expecetedDate][userId].billableCost += currentTaskBillableCost;
                                } else if(userSplitedByDay[expecetedDate]) {
                                    userSplitedByDay[expecetedDate][userId] =  {
                                        internalCost: currentTaskInternalCost,
                                        billableCost: currentTaskBillableCost,
                                    };
                                } else {
                                    userSplitedByDay[expecetedDate] = {
                                        [userId]: {
                                            internalCost: currentTaskInternalCost,
                                            billableCost: currentTaskBillableCost,
                                        }
                                    };
                                }
                            });
                        }

                        if(breakdownDataBySkills?.selectedData?.length) {
                            currentTask?.skill?.forEach(skillId => {
                                if(skillsSplitByDay[expecetedDate] && skillsSplitByDay[expecetedDate][skillId] ){
                                    skillsSplitByDay[expecetedDate][skillId].internalCost += currentTaskInternalCost;
                                    skillsSplitByDay[expecetedDate][skillId].billableCost += currentTaskBillableCost;
                                } else if(skillsSplitByDay[expecetedDate]) {
                                    skillsSplitByDay[expecetedDate][skillId] =  {
                                        internalCost: currentTaskInternalCost,
                                        billableCost: currentTaskBillableCost,
                                    };
                                } else {
                                    skillsSplitByDay[expecetedDate] = {
                                        [skillId]: {
                                            internalCost: currentTaskInternalCost,
                                            billableCost: currentTaskBillableCost,
                                        }
                                    };
                                }
                            });
                        }
                    });
                });
                // sort the dates
                availableDates.sort((a,b) => new Date(a).valueOf() - new Date(b).valueOf());
                const budgetValueDataSet = Object.values(chartData.datasets).find(el => el.labelType === 'budgetValue');
                const expensesValueDataSet = Object.values(chartData.datasets).find(el => el.labelType === 'expenses');
                const firstDay = moment().tz(userTimezone).add(1, 'day');
                const lastDay = moment(endDate).tz(userTimezone);
                while(firstDay.isBefore(lastDay)) {
                    const byDate = firstDay.format('YYYY/MM/DD');
                    const skillDoneThisDay = skillsSplitByDay[byDate];
                    const userTasksDoneThisDay = userSplitedByDay[byDate];
                    const tasksDoneThisDay = tasksSplitedByDay[byDate];
                    // compile it here ?
                    chartData.labels.push(byDate);
                    Object.values(chartData.datasets).forEach((el) => {
                        if (el.labelType !== 'budgetValue' && el.labelType !== 'expenses') {
                            let internalCost = 0;
                            let billableCost = 0;
                            // missing for task and users
                            if(breakdownDataBySkills?.selectedData?.length){
                                billableCost =  _.get(skillDoneThisDay, [el.breakdownId, 'billableCost' ], 0);
                                internalCost = _.get(skillDoneThisDay, [el.breakdownId, 'internalCost' ], 0);
                            }

                            if(breakdownDataByUsers?.selectedData?.length){
                                billableCost =  _.get(userTasksDoneThisDay, [el.breakdownId, 'billableCost' ], 0);
                                internalCost = _.get(userTasksDoneThisDay, [el.breakdownId, 'internalCost' ], 0);
                            }

                            if(!breakdownDataByUsers?.selectedData?.length && !breakdownDataBySkills?.selectedData?.length){
                                billableCost =  _.get(tasksDoneThisDay, [el.source[0], 'billableCost' ], 0);
                                internalCost =  _.get(tasksDoneThisDay, [el.source[0], 'internalCost' ], 0);
                            }

                            let lastValue = _.last(el.data);
                            // if the task data is defined by retainer we have to reset the data if it's diferent data according to the periodicity
                            if(taskCostType === 'retainer' && taskPeriodicity !== 'none') {
                                // should map the value of taskPeriodicity to moment
                                const hasSamePeriodicity = moment(firstDay).subtract(1, 'day').isSame(firstDay, taskPeriodicity);
                                if(!hasSamePeriodicity){
                                    lastValue = 0;
                                }
                            }

                            if (internalCost && el.labelType === 'internalCost'){
                                el.data.push(internalCost + lastValue);
                            } else if(billableCost && el.labelType === 'billableCost'){
                                el.data.push(billableCost + lastValue);
                            } else {
                                el.data.push(lastValue);
                            }
                        }
                    });

                    budgetValueDataSet.data.push(currentBudgetValue);
                    expensesValueDataSet.data.push(currentExpensesValue);
                    firstDay.add(1, 'day');
                }
            }
            // need to find the lowest value
            let min = -1;
            let max = 0;
            Object.values(chartData.datasets).forEach(el => {
                el.data.forEach(entry => {
                    if(max === 0 || max < entry)
                        max = entry;
                    if((min > entry || min === -1) && entry !== null)
                        min = entry;
                });
            });

            currentChart.options.scales.y.min = min === 0 ? 0 : min - (min * 0.10);
            setCurrentData(chartData);
        }
        dispatch(hideLoader());
    };

    useEffect(() => {
        if(currentChart && currentData){
            const displayData = agregateData(splitBy, deepCloneDataSets(currentData));
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(displayData);
        }
    }, [splitBy,currentData, currentChart]);

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    };

    useEffect(()=> {
        handleData();
    }, [accountDataTasks, datesRange, breakdownData, csvData, currentChart]);

    useEffect(()=> {
        if(accountDataTasks) {
            (async () => {

                let workspaceTasks = workspaceSelected === 'root' ? Object.entries(stateTasks).map(([id, el]) => !el.parent ? id  : false).filter(Boolean) : [workspaceSelected];

                const info = await Promise.all(workspaceTasks.map(async (el) => {
                    const currentTask = stateTasks[el];
                    if(!currentTask.childrens){
                        return {
                            internalCost:  0,
                            billableCost:  0,
                            budget: 0,
                            type: '',
                            periodicity: ''
                        };
                    }
                    const taskBudget = _.get(currentTask,['costBudgetSettings','value'], 0);
                    const taskBudgetType = _.get(currentTask,['costBudgetSettings','type'], 0);
                    const taskBudgetPeriodicity = _.get(currentTask,['costBudgetSettings','periodicity'], 'month');
                    try{
                        const internalCost  = await getPrevisionValue.now.internal(currentTask, stateTasks, accountTeamMembers, null, accountDataTasks);
                        const billableCost  = await getPrevisionValue.now.billable(currentTask, stateTasks, accountTeamMembers, null, accountDataTasks);

                        return {
                            internalCost:  internalCost,
                            billableCost:  billableCost,
                            budget: taskBudget,
                            type: taskBudgetType,
                            periodicity: taskBudgetPeriodicity
                        };
                    } catch(err) {
                        return {
                            internalCost:  0,
                            billableCost:  0,
                            budget: 0,
                            type: '',
                            periodicity: ''
                        };
                    }
                }));

                if (workspaceSelected ==='root') {
                    const aggregatedInfo = info.reduce((acc,el) => {
                        acc.internalCost = acc.internalCost +  el.internalCost;
                        acc.billableCost = acc.billableCost +  el.billableCost;
                        acc.budget = acc.budget +  el.budget;
                        return acc;
                    }, {
                        internalCost: 0,
                        billableCost: 0,
                        budget: 0,
                    });
                    setOverviewCurrentData(aggregatedInfo);
                    return;
                }

                setOverviewCurrentData(info[0]);
            })();
        }
    }, [stateTasks, accountDataTasks, childsParents, workspaceSelected, accountTeamMembers]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => _.uniq(workspaceData.map(el => el.teamMembers).flat()), [workspaceData]);
    // get all of the available skills
    const availableSkills = React.useMemo(() => _.uniq(workspaceData.map(el => el.skills).flat()), [workspaceData]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ?
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);



    if (!hasAutorization) {
        return <Unautorized />;
    }

    const breakdownRules = {
        'users': ['none'],
        'skills': ['none' ],
        'none': ['none' , 'users', 'skills'],
    };
    return <>
        <Paper className="topSection">
            <div className="breakdownsContainer">
                <BreakdownController
                    rules={breakdownRules}
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    tasks={subItensTasks}
                    skills={availableSkills}
                    teamMembers={availableTeamMembers}
                    workspaceSelected={workspaceSelected}
                />
            </div>
            {
                (breakdownDataBySkills || breakdownDataByUsers) && (<div className="warning-label">
                    <ReportProblemOutlinedIcon /> Expenses are not shown for this breakdown
                </div>)
            }
            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard
                    title="Actual Internal Cost"
                    value={currencyFormat({ value: overviewCurrentData.internalCost, currency: settingsCurrency })}
                />
                <OverViewCard title="Actual Billable Cost" value={currencyFormat({ value: overviewCurrentData.billableCost, currency: settingsCurrency })} />
                {
                    workspaceSelected !== 'root'
                        ?
                        <OverViewCard
                            title="Actual Budget"
                            value={overviewCurrentData.budget ? currencyFormat({ value: overviewCurrentData.budget, currency: settingsCurrency }) : 'N/A'}
                            subTitle={overviewCurrentData.budget  ?
                                `${intl.formatMessage({id: `${overviewCurrentData.type}-cost`})} ${overviewCurrentData.periodicity ? `,${intl.formatMessage({id: `per ${overviewCurrentData.periodicity}`})}` : ''}`
                                : ''
                            }
                        />
                        :
                        <OverViewCard
                            title="Actual Budget"
                            value="N/A"
                        />
                }
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(chartCanvas.current)}>
                    Save as Img
                    </Button>
                </div>
                <canvas height="300" width="800" ref={chartCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                        Save as CSV
                    </Button>
                </div>
                <CostBudgetTable
                    currency={settingsCurrency}
                    onColumnHover={onColumnHover}
                    userDateFormat={dateFormat}
                    datasets={displayData.datasets}
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
};

CostBudget.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(CostBudget);