import React from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from '../../utils/selectors/user';
import Form from './Form';
import List from './List';
import ErrorPage from '../ErrorPage/ErrorPage';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const BackOfficeQuotes = () => {
    const currentUserId = useSelector(getUserId);

    if(!['-Mm4CgCnhTs_J8ELTFWc', '-MXXbP3viMTtr6sBkqJ3', '-MrRbPq2Cxe8MC6beU9n'].includes(currentUserId)){
        return <ErrorPage />;
    }

    return <div className='BackOfficeQuotes'>
        <Form />
        <List />
    </div>;
};
export default withCustomErrorBoundary(BackOfficeQuotes);
