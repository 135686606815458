import { getSymbol } from '../../../utils/currencyFormat';
import { Currency } from '../../../shared/enums';
export var supportedCurrencies = [Currency.USD, Currency.EUR, Currency.GBP];
export var currencyOptions = [
    {
        currency: Currency.USD,
        symbol: getSymbol(Currency.USD),
    },
    {
        currency: Currency.EUR,
        symbol: getSymbol(Currency.EUR),
    },
    {
        currency: Currency.GBP,
        symbol: getSymbol(Currency.GBP),
    },
];
