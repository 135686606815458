import React from 'react';
import _ from 'underscore';
import { db } from './firebaseEvents';
import moment from 'moment-timezone';
import StateUserAvatar from '../components/UserAvatar/StateUserAvatar';
import ArrowDropRight from '@material-ui/icons/ArrowRight';
import SelectedIcon from '@material-ui/icons/Done';
import { detailedDiff } from 'deep-object-diff';
import filtersConf from '../views/Filters/filters.json';
import { REGULAR_TIME_FORMAT } from './constants';

const TABLET_BREAKPOINT_PIXELS = 768;
const ENTER_KEY_CODE = 13;

export const IsEnterKey = e => e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE;

export const checkIsMobileWidth = () => {
    if (
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <
        TABLET_BREAKPOINT_PIXELS
    ) {
        return true;
    }
    return false;
};

export const canUserFollowTask = ({ userId, taskId, accountTasks, basePermissions }) => {
    const comingFromBase = accountTasks[taskId]?.permissions?.comingFrom === 'base';
    const isBaseViewer = basePermissions?.viewers?.includes(userId) || false;
    const isBaseWorker = basePermissions?.workers?.includes(userId) || false;
    const isBaseOwner = (basePermissions?.owners && basePermissions.owners.includes(userId)) || false;
    const isBaseManager = (basePermissions?.managers && basePermissions?.managers?.includes(userId)) || false;

    if (comingFromBase && (isBaseViewer || isBaseWorker || isBaseOwner || isBaseManager)) {
        return true;
    }

    const taskPermissions = !comingFromBase
        ? accountTasks[accountTasks[taskId]?.permissions?.comingFrom]?.permissions
        : 'base';
    const isTaskViewer = taskPermissions?.viewers?.includes(userId) || false;
    const isTaskWorker = taskPermissions?.workers?.includes(userId) || false;
    const isTaskOwner = (taskPermissions?.owners && taskPermissions?.owners?.includes(userId)) || false;
    const isTaskManager = (taskPermissions?.managers && taskPermissions?.managers?.includes(userId)) || false;

    if (isTaskViewer || isTaskWorker || isTaskOwner || isTaskManager) {
        return true;
    }
    return false;
};

export const validateEmail = email => {
    /*eslint-disable */
    const emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /*eslint-enable */
    return emailPattern.test(email);
};

export const tracking = (name, data) =>
    (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') &&
    window?.analytics?.track &&
    window.analytics.track(name, data) &&
    window?.StonlyWidget &&
    window.StonlyWidget('refetchConfig');

export function getGroupByTitle(groupData, parent) {
    if (groupData) {
        var userGroups = [];

        _.each(groupData, (group, groupId) => {
            if (group.parent === parent) {
                userGroups.push({ ...group, id: groupId });
            }
        });

        return _.sortBy(userGroups, 'title');
    }
    return [];
}

export function getCustomSelect(
    type,
    usersData,
    skillsData,
    groupData,
    parent,
    searchText,
    values,
    onChange,
    limitToItems,
) {
    if (usersData) {
        var groupList = [];
        var userGroups = getGroupByTitle(groupData, parent);

        for (var i in userGroups) {
            var sublevel = getCustomSelect(
                type,
                usersData,
                skillsData,
                groupData,
                userGroups[i].id,
                searchText,
                values,
                onChange,
                limitToItems,
            );
            if (sublevel) {
                groupList.push(
                    <li key={'userGroup-' + userGroups[i].id}>
                        <span>{userGroups[i].title}</span>
                        <ArrowDropRight />
                        {sublevel}
                    </li>,
                );
            }
        }

        var results = groupList.concat(
            getRows(type, usersData, skillsData, parent, searchText, values, onChange, limitToItems),
        );

        if (results.length > 0) {
            return <ul>{results}</ul>;
        }
    }
    return null;
}

function getRows(type, usersData, skillsData, groupId, searchText, values, onChange, limitToItems) {
    var data, sortName;
    if (type === 'users') {
        data = usersData;
        sortName = 'displayName';
    } else if (type === 'skills') {
        data = skillsData;
        sortName = 'name';
    }

    var list = [],
        items = [];

    _.each(data, (item, itemId) => {
        if (type !== 'users' || !item.viewer) {
            items.push({ ...item, id: itemId });
        }
    });

    items = _.sortBy(items, sortName);

    _.each(items, item => {
        if (item && (!limitToItems || limitToItems.indexOf(item.id) !== -1)) {
            if (
                item.group === groupId &&
                item[sortName] &&
                item[sortName].search(new RegExp(encodeURIComponent(searchText), 'gi')) !== -1
            ) {
                if (type === 'users') {
                    var isSelected = values && values.indexOf(item.id) !== -1;
                    list.push(
                        <li key={'memberselect-' + item.id} data-userid={item.id} onClick={() => onChange(item.id)}>
                            {getUserAvatar(item.id, isSelected, usersData[item.id].avatar)}
                            <span>{item.displayName}</span>
                        </li>,
                    );
                } else if (type === 'skills') {
                    var selectedIcon = values && values.indexOf(item.id) !== -1 ? <SelectedIcon /> : null;
                    list.push(
                        <li key={'skillselect-' + item.id} data-skillid={item.id} onClick={() => onChange(item.id)}>
                            {selectedIcon}
                            <span>{item.name}</span>
                        </li>,
                    );
                }
            }
        }
    });

    return list;
}

export function getUserAvatar(userId, isSelected) {
    if (isSelected) {
        return <StateUserAvatar className="avatar emptyAvatar selectedAvatar" userId={userId} />;
    }

    return <StateUserAvatar className="avatar" userId={userId} />;
}

export function checkTaskAgainstLimit(task, childList, parentIds, limitViewToTask) {
    return !(
        limitViewToTask &&
        task.id !== limitViewToTask &&
        (!parentIds[task.id] || parentIds[task.id].indexOf(limitViewToTask) === -1) &&
        (!childList || childList.indexOf(limitViewToTask) === -1)
    );
}

export function checkTaskAgainstFiltersNew(
    quickSearch,
    filters,
    task,
    accountData,
    group = null,
    ignoreList,
    timezone = moment.tz.guess(),
    customFields
) {
    if (!group) {
        group = JSON.parse(filters);
    }

    if (_.isEmpty(filters) || _.isEmpty(group)) {
        return true;
    }

    var quickSearchResult = true;
    if (quickSearch !== '') {
        var temp = quickSearch.split(' ');
        temp.forEach(term => {
            if (quickSearchResult) {
                var res = checkTaskFilterNew(
                    task,
                    { id: 'title', type: 'contains', value: term },
                    accountData.tasks,
                    ignoreList,
                    customFields
                );
                if (!res) {
                    quickSearchResult = false;
                }
            }
        });
    }
    if (!quickSearchResult) {
        return quickSearchResult;
    }

    if (!group.id) {
        var groupAndor = group.andor;

        for (var key in group) {
            if (key === 'andor') {
                continue;
            }

            var result = checkTaskAgainstFiltersNew(
                quickSearch,
                filters,
                task,
                accountData,
                group[key],
                ignoreList,
                timezone,
                customFields
            );

            if (groupAndor === 'and' && !result) {
                return false;
            } else if (groupAndor === 'or' && result) {
                return true;
            }
        }

        if (groupAndor === 'and') {
            return true;
        } else if (groupAndor === 'or') {
            return false;
        }

        return result;
    } else {
        return checkTaskFilterNew(task, group, accountData.tasks, ignoreList, customFields);
    }
}

const defaultValidationMethods = {
    before: (currentValue, searchValue) =>
        currentValue && moment(currentValue, 'X').isValid() && moment(currentValue, 'X').isBefore(searchValue, 'day'),
    after: (currentValue, searchValue) =>
        currentValue && moment(currentValue, 'X').isValid() && moment(currentValue, 'X').isAfter(searchValue, 'day'),
    on: (currentValue, searchValue) =>
        currentValue && moment(currentValue, 'X').isValid() && moment(currentValue, 'X').isSame(searchValue, 'day'),
    hasValue: currentValue =>  Array.isArray(currentValue) ? currentValue.length > 0 : !!currentValue,
    hasNoValue: currentValue => Array.isArray(currentValue) ? currentValue.length < 1 : !currentValue,
    is: (currentValue, searchValue) => typeof currentValue === 'string' ? searchValue.indexOf(currentValue) !== -1 : Array.isArray(currentValue) ? searchValue.some(el=>currentValue.includes(el)) : false,
    isnot: (currentValue, searchValue) => typeof currentValue === 'string' ? searchValue.indexOf(currentValue) === -1 : Array.isArray(currentValue) ? !searchValue.some(el=>currentValue.includes(el)) : true,
    contains: (currentValue, searchValue) =>
        currentValue.match(new RegExp(encodeURIComponent(searchValue), 'gi')) !== null,
    doesnotcontain: (currentValue, searchValue) =>
        currentValue.match(new RegExp(encodeURIComponent(searchValue), 'gi')) === null,
    lowerthan: (currentValue, searchValue) => parseFloat(currentValue) < parseFloat(searchValue),
    higherthan: (currentValue, searchValue) => parseFloat(currentValue) > parseFloat(searchValue),
};

const filterCondictionsValidator = ({
    validationMethods = defaultValidationMethods,
    currentValue,
    filterType,
    filterValue,
}) => validationMethods[filterType] && validationMethods[filterType](currentValue, filterValue);

function checkTaskFilterNew(task, filter, taskList, ignoreList, customFields) {
    if (task) {
        if (typeof task.forcedUser === 'string') {
            task.forcedUser = [task.forcedUser];
        }
        if (typeof task.userWorking === 'string') {
            task.userWorking = [task.userWorking];
        }
        if (typeof task.doneBy === 'string') {
            task.doneBy = [task.doneBy];
        }
        if (task.estimations && typeof task.estimations.userId === 'string') {
            task.estimations.userId = [task.estimations.userId];
        }

        if (ignoreList && ignoreList.indexOf(filter.id) !== -1) {
            return true;
        } else if (filtersConf[filter.id] && filtersConf[filter.id].childTaskOnly && task.childrens) {
            return false;
        } else if (filter.id === 'status') {
            return filterCondictionsValidator({
                currentValue: task.status,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
            });
        } else if (filter.id === 'title') {
            var title = task.title || '';
            return filterCondictionsValidator({
                currentValue: title,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'directParent') {
            if (!task.parent) {
                return false;
            }
            var parentTitle = taskList[task.parent] ? taskList[task.parent].title : '';

            return filterCondictionsValidator({
                currentValue: parentTitle,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'parent') {
            if (!task.parent) {
                return false;
            }

            var parentTitles = [];
            var currentTask = taskList[task.parent];
            if (currentTask && currentTask.title) {
                parentTitles.push(currentTask.title);
            }
            while (currentTask && currentTask.parent) {
                if (taskList[currentTask.parent] && taskList[currentTask.parent].title) {
                    parentTitles.push(taskList[currentTask.parent].title);
                }
                currentTask = taskList[currentTask.parent];
            }

            return filterCondictionsValidator({
                currentValue: parentTitles,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    contains: (currentValue, searchValue) =>
                        _.find(currentValue, title => {
                            return title.match(new RegExp(encodeURIComponent(searchValue), 'gi')) !== null;
                        }) !== undefined,
                    doesnotcontain: (currentValue, searchValue) =>
                        _.find(currentValue, title => {
                            return title.match(new RegExp(encodeURIComponent(searchValue), 'gi')) !== null;
                        }) === undefined,
                },
            });
        } else if (filter.id === 'assigned') {
            var assigned = [];

            if (task.status === 'inprogress') {
                assigned = assigned.concat(task.userWorking);
            } else if (task.status !== 'done') {
                if (task.forcedUser) {
                    assigned = assigned.concat(task.forcedUser);
                } else if (task.estimations && task.estimations.userId) {
                    assigned = assigned.concat(task.estimations.userId);
                }
            } else if (task.doneBy) {
                assigned = assigned.concat(task.doneBy);
            } else if (task.userWorking) {
                assigned = assigned.concat(task.userWorking);
            } else if (task.forcedUser) {
                assigned = assigned.concat(task.forcedUser);
            }

            return filterCondictionsValidator({
                currentValue: assigned,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    is: (currentValue, searchValue) => _.intersection(currentValue, searchValue).length > 0,
                    isnot: (currentValue, searchValue) => !_.intersection(currentValue, searchValue).length > 0,
                },
            });
        } else if (filter.id === 'skill') {
            return filterCondictionsValidator({
                currentValue: task.skill,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'tags') {
            return filterCondictionsValidator({
                currentValue: task.tags,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    is: (currentValue, searchValue) =>
                        searchValue && searchValue.every(el => currentValue && currentValue.indexOf(el) !== -1),
                    isnot: (currentValue, searchValue) =>
                        searchValue &&
                        searchValue.every(el => !currentValue || (currentValue && currentValue.indexOf(el) === -1)),
                },
            });
        } else if (filter.id === 'priority') {
            var priority;
            // This will have to be updated to support custom priority table
            switch (task.priority) {
            case 0:
                priority = 'Normal';
                break;
            case 1:
                priority = 'Medium';
                break;
            case 2:
                priority = 'High';
                break;
            case 3:
                priority = 'Urgent';
                break;
            default:
                priority = 'Normal';
                break;
            }

            return filterCondictionsValidator({
                currentValue: priority,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'risk') {
            let risk;
            if (task.risks?.taskAtDanger) {
                risk = 'Due date in danger';
            } else if (task.risks?.taskAtRisk) {
                risk = 'Due date at risk';
            } else if (task.risks?.taskNoSkill) {
                risk = 'Missing skill';
            } else if (task.risks?.taskNoEffort) {
                risk = 'Missing effort';
            } else if (task.risks?.taskEstimationError) {
                risk = 'No team member with required skill';
            } else if (task.risks?.taskNoWorkers) {
                risk = 'No workers defined';
            } else {
                risk = 'None';
            }

            if (
                task.risks?.taskNoSkill &&
                task.risks?.taskNoEffort &&
                filter?.value && filter.value.length && filter.value[0] === 'Missing effort'
            ) {
                risk = 'Missing effort';
            }

            return filterCondictionsValidator({
                currentValue: risk,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'mineffort') {
            return filterCondictionsValidator({
                currentValue: task.minEffort * (task.nbWorkers > 1 ? task.nbWorkers : 1),
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    is: (currentValue, searchValue) => parseFloat(searchValue) === parseFloat(currentValue),
                },
            });
        } else if (filter.id === 'maxeffort') {
            return filterCondictionsValidator({
                currentValue: task.maxEffort * (task.nbWorkers > 1 ? task.nbWorkers : 1),
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    is: (currentValue, searchValue) => parseFloat(searchValue) === parseFloat(currentValue),
                },
            });
        } else if (filter.id === 'expectedDeliveryDate') {
            if (!task.estimations || !task.estimations.expectedAt) {
                return false;
            }

            return filterCondictionsValidator({
                currentValue: task.estimations?.expectedAt,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'earliestDeliveryDate') {
            if (!task.estimations || !task.estimations.earliestAt) {
                return false;
            }

            return filterCondictionsValidator({
                currentValue: task.estimations?.earliestAt,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'latestDeliveryDate') {
            if (!task.estimations || !task.estimations.endAt) {
                return false;
            }

            return filterCondictionsValidator({
                currentValue: task.estimations?.endAt,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'dependency') {
            return filterCondictionsValidator({
                currentValue: task.dependencies,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'delayDate') {
            return filterCondictionsValidator({
                currentValue: moment(task.delayUntil).isValid()
                    ? moment(task.delayUntil).unix()
                    : null,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        } else if (filter.id === 'deadline') {
            return filterCondictionsValidator({
                currentValue: moment(task.deadline).isValid()
                    ? moment(task.deadline).unix()
                    : null,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
            });
        } else if (filter.id === 'timeworked') {
            var timeworked = 0;
            if (task.workingTime) {
                for (var i in task.workingTime) {
                    timeworked += parseFloat(task.workingTime[i].hours);
                }
            }

            return filterCondictionsValidator({
                currentValue: timeworked,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: {
                    ...defaultValidationMethods,
                    hasValue: () => !!task.workingTime,
                    hasNoValue: () => !task.workingTime,
                },
            });
        } else if (filter.id === 'attachments') {
            return filterCondictionsValidator({
                currentValue: task.files,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        }
        else if(filter.id && customFields && customFields[filter.id]) {
            let currVal = task.customFields && task.customFields[filter.id] ? task.customFields[filter.id]:null;

            if(customFields[filter.id].type === 'checkbox'){
                currVal = task.customFields && task.customFields[filter.id] ? 'Checked':'Unchecked';
            }
            else if(currVal && customFields[filter.id].type === 'date'){
                currVal = moment(currVal, 'YYYY-MM-DD').format('X');
            }

            if(!currVal && customFields[filter.id].type === 'text'){
                currVal = '';
            }

            if(customFields[filter.id].type === 'people'){

                return filterCondictionsValidator({
                    currentValue: currVal,
                    filterType: filter.type,
                    taskType: 'task',
                    filterValue: filter.value,
                    validationMethods: {
                        ...defaultValidationMethods,
                        is: (currentValue, searchValue) => _.intersection(currentValue, searchValue).length > 0,
                        isnot: (currentValue, searchValue) => !_.intersection(currentValue, searchValue).length > 0,
                    },
                });
            }

            if(filter.type === 'choice'){


                return filterCondictionsValidator({
                    currentValue: currVal,
                    filterType: filter.type,
                    taskType: 'task',
                    filterValue: filter.value,
                    validationMethods: {
                        ...defaultValidationMethods,
                        is: (currentValue, searchValue) =>
                            searchValue && searchValue.every(el => currentValue && currentValue.indexOf(el) !== -1),
                        isnot: (currentValue, searchValue) =>
                            searchValue &&
                            searchValue.every(el => !currentValue || (currentValue && currentValue.indexOf(el) === -1)),
                    },
                });
            }

            return filterCondictionsValidator({
                currentValue: currVal,
                filterType: filter.type,
                taskType: 'task',
                filterValue: filter.value,
                validationMethods: defaultValidationMethods,
            });
        }
    }

    return false;
}

export function getBaseLocation(props) {
    if (props?.location?.query?.parentId) {
        return { id: props.location.query.parentId, title: getTaskPath(props) };
    }

    return { id: 'base', title: '---Top level of the workspace---' };
}

// Returns the task's path using / to separete
export function getTaskPath(props) {
    let taskId = props.location.query.parentId;
    if (props.account.tasks[taskId]) {
        var parentsPath = [props.account.tasks[taskId].title],
            currentTask = taskId;

        while (props.account.tasks[currentTask].parent) {
            parentsPath.push(props.account.tasks[props.account.tasks[currentTask].parent].title);
            currentTask = props.account.tasks[currentTask].parent;
        }
        parentsPath = parentsPath.reverse().join('/');

        return parentsPath;
    }
    return null;
}

// Returns the task's path using » to separete
export const getTaskPathArrow = (taskId, tasks) => {
    let parentsPath = [],
        currentTask = taskId;

    while (tasks[currentTask] && tasks[currentTask].parent) {
        parentsPath.push(tasks[tasks[currentTask].parent].title);
        currentTask = tasks[currentTask].parent;
    }
    parentsPath = parentsPath.reverse().join(' » ');

    return parentsPath;
};

export const getVisibleTasksOrdered = (id, stateTasks) => {
    const parentsBefore = _.flatten(getParentsBefore(id, stateTasks));

    const nextElementsParentNull = Object.keys(stateTasks)
        .filter(taskId => !stateTasks[taskId].parent && !parentsBefore.includes(taskId))
        .map(taskId => ({
            ...stateTasks[taskId],
            id: taskId,
            indent: 0,
        }));

    let taskIdChilds = [];
    parentsBefore.reverse().forEach(parentTaskId => {
        if (parentTaskId === id && stateTasks[parentTaskId] && !stateTasks[parentTaskId].childrens) {
            taskIdChilds.push(parentTaskId);
        }

        const childrens = stateTasks[parentTaskId]?.childrens.filter(c => c.type === 'task').map(c => c.id);

        childrens.sort((a, b) => (stateTasks[a].index > stateTasks[b].index ? 1 : -1));

        if (taskIdChilds.includes(parentTaskId)) {
            const i = taskIdChilds.findIndex(el => el === parentTaskId);
            taskIdChilds = [...taskIdChilds.slice(0, i + 1), ...childrens, ...taskIdChilds.slice(i + 1)];
        } else {
            taskIdChilds.push(parentTaskId, ...childrens);
        }
    });

    const visibleTasksCurrentId = taskIdChilds.map(taskId => ({
        ...stateTasks[taskId],
        id: taskId,
        indent: !stateTasks[taskId]?.parent ? 0 : _.flatten(getParentsBefore(taskId, stateTasks)).length * 2,
    }));

    const firstTreeTasks = [...visibleTasksCurrentId, ...nextElementsParentNull];

    const orderParentNull = firstTreeTasks.filter(task => !task.parent);

    orderParentNull.sort((a, b) => (a.index > b.index ? 1 : -1));
    const index = orderParentNull.findIndex(el => el.id === visibleTasksCurrentId[0]?.id);

    const visibleTasksOrdered = [
        ...orderParentNull.slice(0, index + 1),
        ...visibleTasksCurrentId.slice(1),
        ...orderParentNull.slice(index + 1),
    ];

    return visibleTasksOrdered;
};

export const getParentsBefore = (id, stateTasks) => {
    if (id && stateTasks[id] && stateTasks[id].parent) {
        return [stateTasks[id].parent, getParentsBefore(stateTasks[id].parent, stateTasks)];
    }
    return [];
};

export const getParentConnectedProject = (id, stateTasks) => {
    const parentsBefore = _.flatten(getParentsBefore(id, stateTasks));
    return Object.values(stateTasks).find(task => parentsBefore.includes(task.taskId) && task.isConnectProject);
};

export const getTaskPathSelectorsDialog = (stateTasks, taskId) => {
    if (stateTasks[taskId]) {
        let parentsPath = [stateTasks[taskId].title],
            currentTask = taskId;

        while (stateTasks[currentTask] && stateTasks[currentTask].parent) {
            if (stateTasks[stateTasks[currentTask].parent]) {
                parentsPath.push(stateTasks[stateTasks[currentTask].parent].title);
            }
            currentTask = stateTasks[currentTask].parent;
        }
        parentsPath.shift();
        parentsPath = parentsPath.reverse().join(' / ');

        return !parentsPath.length ? '/' : parentsPath;
    }
    return null;
};

export const getTasksWithPathSorted = stateTasks => {
    const tasksWithPath = Object.keys(stateTasks)
        .filter(taskId => stateTasks[taskId].canView)
        .map(taskId => ({
            id: taskId,
            ...stateTasks[taskId],
            path: getTaskPathSelectorsDialog(stateTasks, taskId),
        }))
        .sort((a, b) => (a.title?.toLowerCase()?.trimStart() > b.title?.toLowerCase()?.trimStart() ? 1 : -1));
    return tasksWithPath;
};

export const getFilteredTasksWithPath = (keyWord, newTasksWithPath) => {
    const filteredResults = newTasksWithPath
        .filter(task => {
            const title = task?.title?.toLowerCase();
            const path = task.path?.replaceAll('/', '').replace(/ {1,}/g, ' ').toLowerCase();

            let allKeywordsFound = true;
            keyWord
                .toLowerCase()
                .split(' ')
                .find(currKeyword => {
                    if (title?.indexOf(currKeyword) === -1 && path?.indexOf(currKeyword) === -1) {
                        allKeywordsFound = false;
                    }
                });

            if (allKeywordsFound) {
                return task;
            }

            return task.title.toLowerCase().includes(keyWord.toLowerCase());
        })
        .sort((a, b) => (a.title.toLowerCase().trimStart() > b.title.toLowerCase().trimStart() ? 1 : -1));

    return filteredResults;
};

export const getChildsFromParent = (id, stateTasks) => {
    if (stateTasks[id]?.childrens) {
        const childs = stateTasks[id].childrens
            .filter(({ type }) => type === 'task')
            .map(({ id: currentChildId }) => {
                if (stateTasks[currentChildId] && stateTasks[currentChildId].childrens) {
                    return [currentChildId, ...getChildsFromParent(currentChildId, stateTasks)];
                }
                return currentChildId;
            });
        return [childs];
    }
    return [];
};

export const getParentParentChilds = ({ currentCheckList, stateTasks, id }) => {
    const newCheckList = [...currentCheckList];
    const { parent } = stateTasks[id];
    if (parent || parent === null) {
        const parentChilds = _.flatten(getChildsFromParent(parent, stateTasks));
        const areAllChildsSelected = parentChilds.filter(el => !newCheckList.includes(el));
        if (!areAllChildsSelected.length) {
            const parentTask = stateTasks[parent];
            if (parentTask) {
                return getParentParentChilds({
                    currentCheckList: [parent, ...newCheckList],
                    stateTasks,
                    id: parent,
                });
            }

            return [parent, ...newCheckList];
        }
    }

    return newCheckList;
};

export function getItemPermissions(activeUserId, taskId, taskPermissions, tasks, isAdmin, basePermissions) {
    let perms = null;
    const comingFrom = taskPermissions ? taskPermissions.comingFrom : 'base';

    if (comingFrom === 'base') {
        perms = basePermissions;
    } else if (comingFrom === taskId) {
        perms = taskPermissions;
    } else if (tasks[comingFrom] && tasks[comingFrom].permissions) {
        perms = tasks[comingFrom].permissions;
    }

    if (!perms) {
        perms = basePermissions;
    }

    const canAdmin = isAdmin || (perms?.owners && perms?.owners.includes(activeUserId));
    const canManage = canAdmin || (perms?.managers && perms?.managers.includes(activeUserId));
    const canWork = perms?.workers && perms?.workers.includes(activeUserId);
    const canView = canManage || canWork || (perms?.viewers && perms?.viewers.includes(activeUserId));
    const canWorkersCreateTask = canWork && (perms?.canWorkersCreateTask ?? true);
    const canWorkersChangeEffort = canWork && (perms?.canWorkersChangeEffort ?? true);

    return {
        canAdmin: canAdmin ?? false,
        canManage: canManage ?? false,
        canWork: canWork ?? false,
        canView: canView ?? false,
        canWorkersCreateTask: canWorkersCreateTask ?? true,
        canWorkersChangeEffort: canWorkersChangeEffort ?? true,
        perms
    };
}

export const accountSettingsAdpater = data => ({
    basePermissions: data.basePermissions,
    boards: data.boards,
    chargebeeCustomerId: data.chargebeeCustomerId,
    costBudget: data.costBudget,
    createdAt: data.createdAt,
    customFields: data.customFields,
    dependenciesBlock: data.dependenciesBlock,
    lastSeen: data.lastSeen,
    lastStatsSaved: data.lastStatsSaved,
    name: data.name,
    code: data.code,
    billingInfo: data.billingInfo,
    chargebee: data.chargebee,
    isLoading: data.isLoading || false,
    id: data.accountId,
    expensesTypes: data.expensesTypes,
    firstStepsConfig: data.firstStepsConfig,
    isTrial: data.isTrial || false,
    isLifetime: data.isLifetime || false,
    seenMidTrialModal: data.seenMidTrialModal || false,
    storageSize: data.storageSize,
    endPlanAt: data.endPlanAt,
    plan: data.plan,
    planNbusers: data.planNbusers,
    planPeriodicity: data.planPeriodicity,
    planCurrency: data.planCurrency,
    subscriptionCanceled: data.subscriptionCanceled || false,
    trialExtended: data.trialExtended || false,
    canUsersManageOwnSkills: data.canUsersManageOwnSkills || false,
});

export const getUtmCookie = () => {
    const utmData = document.cookie.split(';').find(el => el.includes('utmData'));
    try {
        if (utmData) {
            const parsed = JSON.parse(utmData);

            return {
                source: _.get(parsed, 'source', ''),
                medium: _.get(parsed, 'medium', ''),
                campaign: _.get(parsed, 'campaign', ''),
                term: _.get(parsed, 'term', ''),
                content: _.get(parsed, 'content', ''),
            };
        }
    } catch (err) {
        return {
            source: 'none',
            medium: 'none',
            campaign: 'none',
            term: 'none',
            content: 'none',
        };
    }

    return {
        source: 'none',
        medium: 'none',
        campaign: 'none',
        term: 'none',
        content: 'none',
    };
};

const getTasksComparableData = data => {
    if (data.childrens) {
        data = _.pick(data, 'delayUntil', 'deadline', 'dependencies', 'parent', 'index', 'childrens', 'permissions');
    } else {
        data = _.pick(
            data,
            'delayUntil',
            'startOn',
            'minEffort',
            'maxEffort',
            'deadline',
            'skill',
            'skillRanges',
            'forcedUser',
            'userWorking',
            'dependencies',
            'priority',
            'status',
            'parent',
            'index',
            'permissions',
            'recurrency',
            'nbWorkers',
            'timerStart',
        );
        if (data.maxEffort) {
            data.maxEffort = parseFloat(data.maxEffort);
        }
        if (data.minEffort) {
            data.minEffort = parseFloat(data.minEffort);
        }
    }
    return data;
};

export const compareTask = (oldTask, newTask) => {
    const dataA = getTasksComparableData(oldTask);
    const dataB = getTasksComparableData(newTask);
    const result = detailedDiff(dataA, dataB);

    return !_.isEmpty(result.added) || !_.isEmpty(result.deleted) || !_.isEmpty(result.updated);
};

export const compareSettings = (oldSettings, newSettings) => {
    const dataA = _.pick(oldSettings, 'basePermissions');
    const dataB = _.pick(newSettings, 'basePermissions');
    const result = detailedDiff(dataA, dataB);

    return !_.isEmpty(result.added) || !_.isEmpty(result.deleted) || !_.isEmpty(result.updated);
};

export const compareUser = (oldData, newData) => {
    const fields = ['timezone', 'schedule', 'daysoff', 'skills', 'extraHours', 'events', 'memberType'];
    const dataA = _.pick(oldData, fields);
    const dataB = _.pick(newData, fields);
    const result = detailedDiff(dataA, dataB);

    return !_.isEmpty(result.added) || !_.isEmpty(result.deleted) || !_.isEmpty(result.updated);
};
export const compareDaysOff = (oldData, newData) => {
    const result = detailedDiff(oldData, newData);

    return !_.isEmpty(result.added) || !_.isEmpty(result.deleted) || !_.isEmpty(result.updated);
};

export const compareSkills = (oldData, newData) => {
    return Object.keys(oldData).length !== Object.keys(newData).length;
};

const promiseLoop = async op => {
    if (!op || !op.length) {
        return;
    }
    var onIt = op.pop();
    var doIt = await onIt();

    return [doIt, await promiseLoop(op)];
};

export const databaseBatchUpdate = async (updates = {}) => {
    const chunks = _.chunk(Object.keys(updates), 50);
    const updateToExecute = chunks.map(updateKeys => {
        const newUpdateSet = updateKeys.reduce((acc, updateKey) => {
            return {
                ...acc,
                [updateKey]: updates[updateKey],
            };
        }, {});
        return () => db.update(undefined, newUpdateSet);
    });
    return await promiseLoop(updateToExecute);
};

export const possibleUsersColors = [
    '#7b68ee',
    '#ffa12f',
    '#ff5722',
    '#f42c2c',
    '#f8306d',
    '#6484e6',
    '#5f81ff',
    '#0ab4ff',
    '#08c7e0',
    '#07a092',
    '#1db954',
    '#2ea52c',
    '#757380',
    '#202020',
];

export const pickRandomColor = () => `#${Math.floor(Math.random()*16777215).toString(16)}`;

export const pickColor = ({ accountUsers, usedColors = [] }) => {
    const alreadyInColors = Object.values(accountUsers)
        .map(user => user.color)
        .filter(Boolean);
    const availableColors = [
        ...possibleUsersColors.filter(colorRef => ![...usedColors, ...alreadyInColors].includes(colorRef)),
        ...alreadyInColors,
    ];

    return availableColors[0] ? availableColors[0] : pickRandomColor();
};


export const handleStopPropagation = (e) => { e && e.stopPropagation(); };

export const customError = (title, message, errorProperties) => {
    return new Error(title, { cause: { message, ...errorProperties } });
};

export const getCustomError = (error) => {
    const { message: title, cause } = error;
    const { message, ...errorProperties } = cause;

    return {
        title,
        message,
        ...errorProperties,
    };
};

export const isRegularTimeFormat = (timeFormat) => timeFormat === REGULAR_TIME_FORMAT;

export const getTimeDurationInSeconds = (startAt, endAt) => endAt ? moment(endAt).diff(moment(startAt), 'seconds') : 0;

export const DURATION_DAY_HOURS = 24;
