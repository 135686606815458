import React, { useMemo, useState } from 'react';
import classnames from 'clsx';
import PropTypes from 'prop-types';
import { updateTaskSkill, updateTaskSkillRange } from '../../utils/firebaseEvents';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountSkills, getAccountTaskCanManage, getAccountTaskHasChildren, getAccountTaskSkill, getAccountTaskSkillRages, getAccountTaskStatus } from '../../utils/selectors/account';
import SelectSkill from '../SelectSkill/SelectSkill';
import useInternalization from '../../utils/hooks/useInternalization';
import { TaskDetailSkillRange } from './TaskDetailSkillRange';
import { Button } from '@material-ui/core';

const TaskDetailSkillSelect = ({ taskId }) => {
    const { t } = useInternalization();
    const [isShowingSkillSelector, setIsShowingSkillSelector] = useState(false);

    const accountSkills = useSelector(getAccountSkills);
    const taskSkillIds = useSelector(getAccountTaskSkill(taskId));
    const taskSkillRanges = useSelector(getAccountTaskSkillRages(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskHaschildren = useSelector(getAccountTaskHasChildren(taskId));
    const accountId = useSelector(getAccountId);

    const taskSkills = useMemo(
        () => !taskSkillIds || !taskSkillIds.length
            ? []
            : taskSkillIds.filter(id => accountSkills[id]).map(id => ({
                id,
                range: taskSkillRanges[id] || { min: 1, max: 10 },
                ...accountSkills[id],
            })),
        [accountSkills, taskSkillIds]
    );

    const isDisabled = !(taskStatus !== 'done' && taskCanManage);
    const isParent = !!taskHaschildren;

    const onClickManageSkills = () => setIsShowingSkillSelector(!isShowingSkillSelector);

    const hideSkillSelect = () => {
        setIsShowingSkillSelector(false);
    };

    const changeTaskSkill = (skillIds) => {
        updateTaskSkill(accountId, taskId, skillIds);
        setIsShowingSkillSelector(false);
    };

    const onSkillRangeChange = (skillId, range) => {
        updateTaskSkillRange(accountId, taskId, skillId, range);
    };

    return (
        <li className={classnames({ hidden: isParent })}>
            <dl>
                <dt className="SkillRow_label">{t('Skill')}</dt>
                <dt>{!isParent && (
                    <>
                        <div
                            className="TaskSkillsContainer">
                            {!!taskSkills?.length && (
                                taskSkills.map(skill =>
                                    <TaskDetailSkillRange
                                        key={skill.id}
                                        skill={skill}
                                        onRangeCommitted={onSkillRangeChange}
                                        isDisabled={isDisabled} />
                                )
                            )}
                            <Button
                                className={classnames('TaskSkillsContainer__addSkill', { empty: !taskSkills?.length })}
                                onClick={onClickManageSkills}
                                variant="contained"
                                color="primary"
                                disabled={isDisabled}>
                                {t('MANAGE SKILLS')}
                            </Button>
                        </div>
                        {isShowingSkillSelector && !isDisabled && <SelectSkill
                            isMultiSelect
                            currentSkill={taskSkillIds}
                            onSelect={changeTaskSkill}
                            onClose={hideSkillSelect}
                            open={isShowingSkillSelector}
                        />}
                    </>
                )}</dt>
            </dl>
        </li>
    );

};

TaskDetailSkillSelect.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailSkillSelect;
