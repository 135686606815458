import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { useSelector } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {getUserAvatar} from '../../utils/utils';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'clsx';
import _ from 'underscore';
import moment from 'moment';
import dompurify from 'dompurify';
import {Twemoji} from 'react-emoji-render';
import linkifyHtml from 'linkifyjs/html';
import CommentFileIcon from '@material-ui/icons/InsertDriveFile';
import { getAccountChat } from '../../utils/selectors/account';
import { getUserDisplayName, getUserId } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const sanitizer = dompurify.sanitize;

const ChatSearch = ({location})=>{
    const chat = useSelector(getAccountChat); 
    const userId = useSelector(getUserId); 
    const userAvatar = useSelector(getUserAvatar);
    const userName = useSelector(getUserDisplayName);

    const parseComment = (comment) => {
        return sanitizer(comment
            .replace('\n\n', '<br/><br/>')
            .replace('\r\r', '<br/><br/>')
            .replace('\n', '<br/>')
            .replace('\r', '<br/>')
            .replace(/@\[((?:[^\]]*))\]\(((?:[^)]*))\)/gi, '<span class="mention">@$1</span>')
            .replace(/\*\*((?:[^*]*))\*\*/gi, '<b>$1</b>')
            .replace(/\*((?:[^*]*))\*/gi, '<em>$1</em>')
            .replace(/´((?:[^*]*))´/gi, '<code>$1</code>'));
    };

    const getCommentUserAt = (userId, date) => {
        var avatar = getUserAvatar(userId, false, userAvatar);

        if(!date){
            return avatar;
        }

        return (
            <div className="chatSentBy">
                {avatar}
                <strong>{userName || ''}</strong>
            </div>
        );
        
    };

    const getResults = (searchstring) => {
        // search channel names
        searchstring = encodeURIComponent(searchstring);
        var results = [], channels = [];

        for(var channelType in chat){
            if(channelType === 'channels'){
                for(var i in chat.channels){
                    if(i.match(new RegExp(searchstring, 'gi')) !== null){
                        channels.push({type:'channel', id: i});
                    }
                }
            }
            for(var channelId in chat[channelType]){

                if(
                    chat[channelType][channelId].participants &&
                    chat[channelType][channelId].participants.indexOf(userId) !== -1
                ){

                    for(var msgId in chat[channelType][channelId].messages){
                        var msg = chat[channelType][channelId].messages[msgId];

                        if(msg.type === 'text' || msg.type === 'file'){
                            
                            if(msg.value.match(new RegExp(searchstring, 'gi')) !== null){
                                var cid = channelId;
                                if(channelType === 'direct'){
                                    cid = _.difference(chat[channelType][channelId].participants, [userId])[0];
                                }
                                results.push({type:'message', channelType, channelId:cid, msg: msg, at: msg.at});
                            }

                        }

                    }

                }
                
            }
        }

        results = _.sortBy(results, 'at').reverse();

        return channels.concat(results);
    };


    if(
        chat && userId
    ){

        var searchstring = location.query.s;

        var results = getResults(searchstring);
        var array = [];

        results.forEach((res)=>{
            if(res.type === 'channel'){
                array.push(
                    <div key={'channel-' + res.id} className="item" onClick={()=>browserHistory.push('/chat/channels/' + res.id)}>
                        <span className="location">Channel #{res.id}</span>
                    </div>
                );
            }
            else if(res.type === 'message'){
                var location, message;
                var linkChannelId = res.channelId;

                if(res.channelType === 'direct'){
                    location = 'Direct message';
                }
                else if(res.channelType === 'channels'){
                    location = 'Channel #' + res.channelId;
                }
                else {
                    location = 'Group chat';
                }

                if(res.msg.type === 'text'){
                    message = <div dangerouslySetInnerHTML={{__html: linkifyHtml(parseComment(ReactDOMServer.renderToString(<Twemoji svg text={res.msg.value || ''}/>))) }} />;
                }
                else if(res.msg.type === 'file'){

                    if(res.msg.contentType && (res.msg.contentType === 'image/jpeg' || res.msg.contentType === 'image/png' || res.msg.contentType === 'image/gif')){
                        message = <div><a target="_blank" href={res.msg.url}><img src={res.msg.url} title={res.msg.value} alt={res.msg.value} /></a></div>;
                    }
                    else {
                        message = <div className="download"><CommentFileIcon /> {res.msg.value} <a target="_blank" href={res.msg.url}>Download</a></div>;
                    }
                }

                

                array.push(
                    <div key={'message-' + res.channelType + '-' + res.channelId + '-' + res.msg.k} className={classnames('item', {file: res.msg.type === 'file'})} onClick={()=>browserHistory.push('/chat/' + res.channelType + '/' + linkChannelId + '?msg=' + res.msg.k)}>
                        <span className="location">{location}<span className="when">{moment(res.at).format('llll')}</span></span>
                        {getCommentUserAt(res.msg.sentBy, res.msg.at)}
                        {message}
                    </div>
                );
            }
        });
        
        return (
            <Card className="ChatSearch">
                <CardHeader 
                    title='Searching your messages' 
                    subheader={'Found ' + results.length + ' results that contains the text "' + searchstring + '"'}
                    avatar={<SearchIcon />}
                />
                <CardContent>
                    <div className="messages">{array}</div>
                </CardContent>
            </Card>
        );

    }

    return null;
};

export default withRouter(withCustomErrorBoundary(ChatSearch));
