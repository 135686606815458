import _ from 'underscore';

export const childrenMatchSearchValue = (nestedChildrenNames, searchValue) => !!nestedChildrenNames.find(elName=>matchesSearchValue(elName, searchValue));
export const parentsMatchSearchValue  = (parentsBeforeNames, searchValue) => !!parentsBeforeNames.find(elName=>matchesSearchValue(elName, searchValue));

export const getProjectsListsWithParentAndChildIds = ({ currentList = [], parentId = null, parentsBefore = [] }) => {
    const newList = currentList.map(item=>({
        ...item,
        parentId: parentId,
        parentsBefore: _.uniq([...parentsBefore, parentId]),
        nestedChildrenIds: (item.childs && !_.isEmpty(item.childs)) ? _.flatten(getAllNestedChildrenIds({ currentList: item.childs })) : [],
        childs: (item.childs && !_.isEmpty(item.childs)) ?
            getProjectsListsWithParentAndChildIds({currentList: item.childs, parentId: item.id, projectId: item?.projectId, parentsBefore: _.uniq([...parentsBefore, parentId, item.id])}) :
            []
    }));

    return newList;
};

export const getOrderedProjectList = (currentList = []) => {
    const orderedList = [...currentList];

    orderedList.forEach(item => {
        if(item.childs && !_.isEmpty(item.childs)) {
            orderedList.push(getOrderedProjectList(item.childs));
        }
    });

    return _.flatten(orderedList);
};

export const getAllNestedChildrenIds = ({ currentList = [] }) => {
    const newList = currentList.map(item=>([
        item.id,
        (item.childs && !_.isEmpty(item.childs)) ? getAllNestedChildrenIds({currentList: item.childs}) : []
    ]));
    return newList;
};

export const matchesSearchValue = (value = '', searchValue = '') => !!(value.toLowerCase().includes(searchValue.toLowerCase()));

export const isAlreadySynchronized = ({ id, childIds, prevSyncList, orderedProjectList, nestedChildrenIds }) => {
    if(_.isEmpty(prevSyncList)) return false;

    if(_.isEmpty(childIds) && prevSyncList.find(el=>el.selectedItem===id)) {
        return true;
    }

    if(_.size(childIds) && childIds.every(id=>prevSyncList.map(el=>el.selectedItem).includes(id))) {
        return true;
    }

    const subLists = nestedChildrenIds.filter(childId=>orderedProjectList.find(el=>el.id===childId && _.isEmpty(el.childs)));
    const allSublistsAlreadySync = subLists.every(id=>prevSyncList.map(el=>el.selectedItem).includes(id));

    if(_.size(nestedChildrenIds) && allSublistsAlreadySync) {
        return true;
    }
    
    return false;
};

export const isLineChecked = (checkedItems, itemId, parentIds, hasChildren, childIds, nestedChildrenIds) => {
    return checkedItems.includes(itemId) || 
        parentIds.some(id=>checkedItems.includes(id)) ||
        (hasChildren && childIds.every(id=>checkedItems.includes(id))) ||
        (hasChildren && nestedChildrenIds.every(id=>checkedItems.includes(id)));
};

export const isLineIndeterminate = (isDisabled, checkedItems, itemId, childIds, nestedChildrenIds, hasChildren) => {
    return isDisabled || (
        (
            !checkedItems.includes(itemId) && 
            hasChildren
        ) && 
        (
            (checkedItems.some(id=>childIds.includes(id)) && !childIds.every(id=>checkedItems.includes(id))) 
          || 
            (checkedItems.some(id=>nestedChildrenIds.includes(id)) && !nestedChildrenIds.every(id=>checkedItems.includes(id)))
        )
    );
};

export const amountChildrenImportingSubTasks = (nestedChildrenIds, orderedProjectList, syncList) => {
    const childrenFromSyncList = nestedChildrenIds.filter(childId => 
        _.isEmpty(orderedProjectList.find(item=>item.id===childId)?.nestedChildrenIds) && 
            syncList.find(item=>item.selectedItem === childId)
    );

    if(childrenFromSyncList.every(childId => syncList.find(item=>item.selectedItem === childId)?.importSubtasks)) {
        return 'every';
    }

    if(childrenFromSyncList.some(childId => syncList.find(item=>item.selectedItem === childId)?.importSubtasks)) {
        return 'some';
    }

    return 'none';
};

export const getNewTree = ({ currentList, itemIdToExpand, itemChilds }) => {
    const rebuildTree = (currentList = []) => {
        const newTreeList = currentList.map(item => ({
            ...item,
            childs: (itemIdToExpand === item.id ? itemChilds : rebuildTree(item.childs))
        }));
        return newTreeList;
    };
        
    return rebuildTree(currentList);
};