import React from 'react';
import { updateTaskSourceStatus } from '../../../../utils/controllers/tasks/statuses';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';

const StatusItem = ({ item, isSelected = false, taskId, onClose }) => {
    const intl = useIntl();
    const { id, name, planlessStatus } = item;
    
    const handleSelectStatus = () => { 
        updateTaskSourceStatus({
            taskId: taskId,
            statusId: id, 
            statusName: name,
            planlessStatus: planlessStatus
        });
        
        onClose();
    };

    return  (
        <div onClick={handleSelectStatus} className={'Select_Status_empty'}>
            <div>
                <span>{name}</span>
                {!isSelected ? (
                    <Button  
                        className="Select_BTN"  
                        onClick={handleSelectStatus} 
                        variant="contained">
                        {intl.formatMessage({ id: 'Select' })}
                    </Button>
                ) : (
                    <Button className="Select_CURRENT_BTN" variant="contained">
                        {intl.formatMessage({ id: 'Current' })}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default StatusItem;