export var getApp = function (state) { return state === null || state === void 0 ? void 0 : state.app; };
export var getVersion = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.version; };
export var getLocale = function (state) { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.locale) || 'en'; };
export var getActiveUserTime = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.activeUserTime; };
export var getHasConnection = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.hasConnection; };
export var getOrderedTaskList = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList; };
export var getOrderedTaskListFirstId = function (state) { var _a, _b; return (((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList[0]) ? ((_b = state === null || state === void 0 ? void 0 : state.app) === null || _b === void 0 ? void 0 : _b.orderedTaskList[0]).id : null); };
export var getOrderedTaskListLength = function (state) { var _a, _b; return ((_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList) === null || _b === void 0 ? void 0 : _b.length) || 0; };
export var getAppTaskDescription = function (state) { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.taskDescription) || null; };
export var getAppGapiLoaded = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.gapiLoaded; };
export var getParentChildIds = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.parentChildIds; };
export var getRecurrentTaskUpdateModal = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.recurrentTaskUpdateModal; };
export var getChangeTaskLocationModal = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.changeTaskLocationModal; };
export var getSelectDependenciesModal = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.selectDependenciesModal; };
export var getSelectSourceStatusModal = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.modals) === null || _b === void 0 ? void 0 : _b.selectStatusModal; };
export var getWorkManager = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.workManager; };
export var getLoader = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loader; };
export var getLoaders = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders; };
export var getLoadersTasks = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders) === null || _b === void 0 ? void 0 : _b.tasks; };
export var getLoadersSkills = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders) === null || _b === void 0 ? void 0 : _b.skills; };
export var getLoadersSettings = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders) === null || _b === void 0 ? void 0 : _b.settings; };
export var getLoadersUsers = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders) === null || _b === void 0 ? void 0 : _b.users; };
export var getLoadersDaysOff = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.loaders) === null || _b === void 0 ? void 0 : _b.daysoff; };
export var getPopupGuides = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides; };
export var getPopupGuidesTaskRightClick = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.taskRightClick; };
export var getPopupGuidesPriorityMessage = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.priorityMessage; };
export var getPopupGuidesNoSkill = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.noSkill; };
export var getPopupGuidesEnterToAdd = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.enterToAdd; };
export var getPopupGuidesNoEffort = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.noEffort; };
export var getPopupGuidesWarnMessages = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.warnMessages; };
export var getPopupGuidesTaskMultipleEdit = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.popupGuides) === null || _b === void 0 ? void 0 : _b.taskMultipleEdit; };
export var getLimitViewTo = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.limitViewTo; };
export var getQuickSearch = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.quickSearch; };
export var getShowFilters = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.showFilters; };
export var getFilters = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.filters; };
export var getCalendarMembersToShow = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.calendarMembersToShow; };
export var getClosedSortables = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.closedSortables; };
export var getCalculating = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.calculating; };
export var getTimelineRange = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.timelineRange; };
export var getTimelineTasksToShow = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.timelineTasksToShow; };
export var getTimelineFirstLevelTasks = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.timelineFirstLevelTasks; };
export var getAmountTaskPlanned = function (state) { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList.filter(function (t) { return parseInt(t.maxEffort, 10) > 0 && t.skill; }).length) || 0; };
export var getFirstTaskNoSkill = function (state) { var _a, _b; return ((_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList.find(function (el) { return !el.skill && !el.childrens; })) === null || _b === void 0 ? void 0 : _b.id) || null; };
export var getFirstTaskNoEffort = function (state) { var _a, _b; return ((_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList.find(function (el) { return !parseInt(el.maxEffort, 10) && !el.childrens; })) === null || _b === void 0 ? void 0 : _b.id) || null; };
export var getFirstTaskPlanned = function (state) { var _a, _b; return ((_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.orderedTaskList.find(function (t) { return parseInt(t.maxEffort, 10) > 0 && t.skill && !t.childrens; })) === null || _b === void 0 ? void 0 : _b.id) || null; };
export var getTimelineColumns = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.timelineColumns; };
export var getDisablePopupGuides = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.disablePopupGuides; };
