import { Avatar } from '@material-ui/core';
import React from 'react';

const Info = ({name , companyName, hasTrial}) => <div className='infoCard'>
    <p>
                Hi {name || 'there'},
    </p>
    <p>
            Here’s are the details of your Planless {(hasTrial && 'free trial') || 'subscription'}{companyName && ' for ' + companyName}.
    </p>    
    <p>
            All that you need to do is click the <strong>{(hasTrial && 'Start free trial') || 'Subscribe now'}</strong> button on the bottom and follow the steps.
    </p>    
    <p>
            We’re excited to see you better plan your work.
    </p>
    <p>
            Any question you might have, feel free to <a href="mailto:greg@planless.io">send me an email.</a>
    </p>
    <p>
            Best,
    </p>
    <p className='regards-container'>
        <Avatar className="avatar" src={'https://firebasestorage.googleapis.com/v0/b/planless-prod.appspot.com/o/newAvatars%2F4f606e5c89c890f10168f494?alt=media&token=34ad9e80-f341-4e53-9574-885d123bd62d'} />
        <strong>Gregory Stoos</strong>    
    </p>
</div>;

export default Info; 