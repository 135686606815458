import React from 'react';
import Button from  '@material-ui/core/Button';
import { generateChargebeeCheckoutQuote } from  '../../utils/chargeBee';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { createAccount } from '../../utils/databaseQuerys/accounts';
import { updateQuote } from '../../utils/databaseQuerys/quote';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

const SubscritionInfo = ( {
    nbUsers,
    originalPrice, 
    finalPrice, 
    discount, 
    currency,
    discountLabel,
    hasDiscount = false, 
    hasTrial  = false,
    trialTime, 
    dueTodayPrice, 
    id, 
    email, 
    companyName, 
    plan, 
    planName,
    years,
    name, 
    months,
}) => {

    const handleSubscrition = async () => {
        if (window.Chargebee) {
            const chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.openCheckout({
                hostedPage: async function () {
                    return (await generateChargebeeCheckoutQuote({ qid: id})).data;
                },
                step: (step) => {
                    firebaseEvents.recordEvent('Checkout step', step);
                },
                error: function () {
                    firebaseEvents.recordEvent('Checkout error');
                    toastr.error('There has been an error while contacting the payment processor. Please contact us in the chat.');
                },
                success: function (chargeBeeid) {
                    try {
                        createAccount({
                            email,
                            accountName: companyName,
                            plan,
                            chargebeeCustomerId: chargeBeeid,
                            displayName: name,
                            nbUsers,
                            planEndAt: trialTime ? moment().add(trialTime,'days') : years ? moment().add(years,'years') : moment().add(months,'months'),
                            trialTime
                        });

                        updateQuote({
                            field: 'phase',
                            id,
                            value: 'submited'
                        });
                        firebaseEvents.recordEvent(trialTime ? 'Add subscription with trial' : 'Add subscription');
                    } catch(err) {
                        toastr.error('There has been an error while contacting the payment processor. Please contact us in the chat.');
                    }
                }
            }); 
        }
    };

    return <div className='subCard'>
        <div className='title-label'>
            Subscription
        </div>
        <div className='info-container'>
            <div className='small-info-container'>
                <div>
                    <p>
                        <strong>{planName}</strong>
                    </p>
                    <p>
                        {nbUsers} users
                    </p>
                </div>
                <div>
                    <strong>
                        {new Intl.NumberFormat('pt-PT', { style: 'currency', currency }).format(originalPrice)}
                    </strong>
                </div>
            </div>
            <div className='small-info-container'>
                <div>
                    <p>
                        <strong>Onboarding</strong>
                            
                    </p>
                </div>
                <div>
                    <strong>
                            Included
                    </strong>
                </div>
            </div>
            {
                hasTrial &&  <div className='small-info-container'>
                    <div>
                        <p>
                            <strong>Trial period</strong>
                        </p>
                    </div>
                    <div>
                        <strong>
                            {trialTime} days
                        </strong>
                    </div>
                </div>
            }

            { hasDiscount && <div className='small-info-container'>
                <div>
                    <p>
                        <strong>{discountLabel}</strong>
                    </p>
                </div>
                <div>
                    <strong>
                            -{discount}%
                    </strong>
                </div>
            </div>
            }
        </div>
        <div className='info-fotter'>
            <div className='small-info-container'>
                <div><strong>TOTAL</strong></div>    
                { hasTrial &&  <div className='small-space'>Total Due today</div>  }
            </div>
            <div className='small-price-container'>
                <div><strong>{new Intl.NumberFormat('pt-PT', { style: 'currency', currency }).format(finalPrice)}</strong></div>    
                { hasTrial &&  <div className='small-space'>{new Intl.NumberFormat('pt-PT', { style: 'currency', currency }).format(dueTodayPrice)}</div>} 
            </div>
        </div>
        <Button onClick={handleSubscrition}>
            { (hasTrial &&  'START FREE TRIAL') || 'SUBSCRIBE NOW'}
        </Button>
    </div>;
};

export default SubscritionInfo;