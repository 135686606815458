import React from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const ExpensesTypeSelector = ({ selected = [], onChange }) => {
    const expensesTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes || {});
    const expensesTypesOptions = Object.keys(expensesTypes)
        .map(id => ({name: expensesTypes[id].name,id: id}))
        .filter(({id}) => !selected.includes(id));

    const selectedTypes = selected.map(el => ({
        ...expensesTypes[el],
        id: el
    })) || [];

    return <Autocomplete
        size="small"
        className="expesesTypeSelector"
        filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if(params.inputValue !== ''){
                filtered.push({
                    inputValue: params.inputValue,
                    name: 'Create Type "' + params.inputValue + '"',
                });
            }

            return filtered;
        }}
        id="expensesType"
        options={expensesTypesOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label={'Expense Type'} margin="normal" InputLabelProps={{shrink: true}} />}
        value={selectedTypes.length ?  selectedTypes[0] : null}
        onChange={onChange}
    />;
};

export default ExpensesTypeSelector;