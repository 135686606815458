export const MENTIONS_INPUT_STYLES = {
    control: {
        backgroundColor: '#fff',
        fontSize: 13,
        fontWeight: 'normal'
    },

    input: {
        margin: 0
    },

    '&multiLine': {
        control: {
            border: '1px solid #ccc',
        },

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            height: 63,
            minHeight: 63,
            outline: 0,
            border: 0,
            overflowY: 'auto',
        },
    },

    suggestions: {
        zIndex: 999,
        boxShadow: '0 1px 3px rgba(0,0,0,0.25)',
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            borderBottom: '0',
            fontSize: 10,
            borderRadius: 3
        },

        item: {
            padding: '7px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#eee',
            },
        },
    },
};