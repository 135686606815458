import React, { useCallback, useEffect, useState } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import _ from 'underscore';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { getAppGapiLoaded } from '../../utils/selectors/app';
import { STORAGE_LIMIT } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { getAccountId, getAccountPlan, getAccountStorageSize, getAccountTaskCanManage, getAccountTaskCanWork, getAccountTaskFiles } from '../../utils/selectors/account';
import { Button } from '@material-ui/core';
import { sendErrorReport } from '../../utils/errorReportHandlers';
import { getUserId } from '../../utils/selectors/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PrettyFileIcons from 'pretty-file-icons';

const TaskDetailFiles = ({ activeTab, taskId }) => {
    const intl = useIntl();
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [fileUploader, setFileUploader] = useState(null);
    const [launchFilePicker, setLaunchFilePicker] = useState(false);

    const storageSize = useSelector(getAccountStorageSize);
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskCanWork = useSelector(getAccountTaskCanWork(taskId));
    const taskFiles = useSelector(getAccountTaskFiles(taskId));
    const plan = useSelector(getAccountPlan);
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const gapiLoaded = useSelector(getAppGapiLoaded);

    const createGdrivePicker = useCallback(() => {
        const user = window.gapi.auth2.getAuthInstance().currentUser.get();
        const token = user.getAuthResponse(true).access_token;

        if (!window.planlessFilePicker) {
            window.planlessFilePicker = {};
        }

        if (!window.planlessFilePicker[token + '-' + taskId]) {
            window.planlessFilePicker[token + '-' + taskId] = new window.google.picker.PickerBuilder()
                .addView(new window.google.picker.DocsView())
                .setOAuthToken(token)
                .setCallback(gdrivePickerCallback)
                .build();
        }

        _.each(window.planlessFilePicker, picker => {
            picker.setVisible(false);
        });

        window.planlessFilePicker[token + '-' + taskId].setVisible(true);
    }, [taskId]);

    useEffect(() => {
        if (launchFilePicker && gapiLoaded) {
            window.gapi.load('picker', {
                callback: () => {
                    setLaunchFilePicker(false);
                    createGdrivePicker();
                },
            });
        }
    }, [gapiLoaded, createGdrivePicker]);

    const uploadFile = (e) => {
        const {target: { files }} = e;

        if(!files[0]) return;
        
        const fileSizeGb = files[0].size / 1000 / 1000 / 1000;
        const workspaceSize = storageSize || 0;

        if (fileSizeGb > 0.25) {
            toastr.error(intl.formatMessage({id: 'file.over.250MB'}));
        }
        
        // compare to account max
        else if (STORAGE_LIMIT[plan] && workspaceSize + fileSizeGb > STORAGE_LIMIT[plan]) {
            toastr.error(intl.formatMessage({id: 'max.workspace.storage.error'}));
        } 
        else {
            firebase
                .storage()
                .ref(`files/${accountId}/${taskId}`)
                .listAll()
                .then(taskRef => {
                    const sameNameExist = !!taskRef.items.find((item) => item.name === files[0].name);
            
                    if (sameNameExist) {
                        toastr.error(intl.formatMessage({id: 'storage.filename.same.error'}));
                    } else {
                        fileUploader.startUpload(files[0]);
                    }
                })
                .catch((error) => {
                    handleUploadError(error);
                });
        }

        setAnchorEl2(null);
    };

    const handleUploadStart = () => {
        setIsUploading(true);
        setProgress(0);
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const handleUploadError = (errorInfo) => {
        toastr.error(intl.formatMessage({id:'error uploading file'}));
        setIsUploading(false);
        setAnchorEl2(null);

        if(errorInfo) {
            sendErrorReport(
                {
                    accountId: accountId,
                    currentUserId: userId,
                    localStorageData: JSON.stringify(localStorage.valueOf()), 
                    errorInfo: JSON.stringify({ errorInfo }),
                    currentPath: location.href
                }
            ); 
        }
    };
    const handleUploadSuccess = (filename) => {
        const fullPath = `files/${accountId}/${taskId}`;

        firebase
            .storage()
            .ref(fullPath)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                firebase
                    .storage()
                    .ref(fullPath)
                    .child(filename)
                    .getMetadata()
                    .then(metadata => {
                        addFile({
                            firebaseStorage: true,
                            name: filename,
                            url: url,
                            fullPath: metadata.fullPath,
                            size: metadata.size / 1000 / 1000 / 1000,
                        });
                    }).then(() => {
                        setProgress(100);
                        setIsUploading(false);
                    })
                    .catch((error) => {
                        handleUploadError(error);
                    }); 
            }).catch((error) => {
                handleUploadError(error);      
            });

        firebaseEvents.addChallenge('attachFile');
    };

    const loadGoogleDriveFile = () => {
        if (gapiLoaded) {

            if(window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
                window.gapi.load('picker', {
                    callback: () => {
                        createGdrivePicker();
                    },
                });
            } 
            else {
                window.gapi.auth2.getAuthInstance().signIn();    
            }
        }

        setAnchorEl2(null);
    };

    const gdrivePickerCallback = (data) => {
        if (data && data.action === 'picked') {
            addFile({
                name: data.docs[0].name,
                url: data.docs[0].url,
                icon: data.docs[0].iconUrl,
            });
        }
    };

    const addFile = (doc) => {
        firebaseEvents.addChallenge('attachFile');
        firebaseEvents.addFileToTask(accountId, taskId, doc);
    };

    const loadDropboxFile = () => {
        if (window.Dropbox) {
            window.Dropbox.choose({
                success: data => {
                    addFile({
                        name: data[0].name,
                        url: data[0].link,
                        icon: data[0].icon,
                    });
                },
            });
        }

        setAnchorEl2(null);
    };

    const loadBoxFile = () => {
        if (window.BoxSelect) {
            let boxSelect = new window.BoxSelect({
                clientId: 'frtwhrc8kug4oag5y0wjl29rvxqa7fh1',
                linkType: 'direct',
                multiselect: false,
            });

            boxSelect.success(data => {
                addFile({
                    name: data[0].name,
                    url: data[0].url,
                });
            });

            boxSelect.launchPopup();
        }
    
        setAnchorEl2(null);
    };

    const handleFileAddBtnClick = (e) => {
        setAnchorEl2(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl2(null);
    };

    const getFileList = (files) => {
        if (files) {
            return _.map(files, (file, fileId) => {
                let icon = (
                    <img
                        src={'../../../stylesheets/assets/fileTypes/' + PrettyFileIcons.getIcon(file.name, 'svg')}
                        alt="file type icon"
                    />
                );
                let type = PrettyFileIcons.getIcon(file.name).toUpperCase() + ' File';
                if (type === 'UNKNOWN File') {
                    type = 'File';
                }

                let deleteIcon = (
                    <Button color="secondary" size="small" className="deleteFile" onClick={() => deleteFile(fileId)}>
                        Delete
                    </Button>
                );
                if (!taskCanManage &&!taskCanWork) {
                    deleteIcon = null;
                }

                return (
                    <Card elevation={0} key={fileId}>
                        <CardHeader avatar={icon} title={file.name} subheader={type} />
                        <CardActions disableSpacing>
                            <Button color="primary" size="small" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                const a = document.createElement('a');
                                a.setAttribute('download', file.name);
                                a.setAttribute('href', file.url);
                                a.setAttribute('_target', '_blank');
                                a.setAttribute('_target', '_blank');
                                a.setAttribute('target', '_blank');
                                a.setAttribute('data-sidebar', 'preventClose');
                                a.style.display = 'none';
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }}>
                                Download
                            </Button>
                            {deleteIcon}
                        </CardActions>
                    </Card>
                );
            });
        }
        return <em>No files attached</em>;
    };

    const handleSetFileUploaderInstance = (instance) => {
        setFileUploader(instance);  
    };

    const deleteFile = (fileId) => {
        firebaseEvents.removeFileFromTask(accountId, taskId, fileId);
    };

    return (
        <div>
            <div className={classnames('filesSection', { hide: activeTab !== 'files' })}>

                {!taskFiles ? (
                    <div className="noFiles">
                        <img src="../../../stylesheets/assets/nofiles.png?v=2" alt="No files" />
                        <h1>
                        No files for now.
                            <br />
                        Start adding some!
                        </h1>
                    </div>
                ) : (
                    <div className="filesList">{getFileList(taskFiles)}</div>
                )}
    
                <Button
                    className="fileAddBtn"
                    color="primary"
                    size="small"
                    onClick={(e) => handleFileAddBtnClick(e)}
                >
                    {intl.formatMessage({id: 'ADD A FILE' })}
                </Button>
    
                <Menu
                    anchorEl={anchorEl2}
                    open={!!anchorEl2}
                    className="taskDetailsAddfilesMenu"
                    keepMounted
                    onClose={handleCloseMenu}
                >
                    <MenuItem className={classnames('addFileMenuitem')} value="upload">
                        {intl.formatMessage({id: 'Upload file'})}
                        <FileUploader
                            ref={handleSetFileUploaderInstance}
                            accept="*"
                            storageRef={firebase.storage().ref(`files/${accountId}/${taskId}`)}
                            onChange={uploadFile}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadSuccess}
                            onProgress={handleProgress}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                width: '100%',
                                opacity: 0,
                            }}
                        />
                    </MenuItem>
                    <MenuItem className="addFileMenuitem" value="google" onClick={loadGoogleDriveFile}>
                        {intl.formatMessage({id: 'From Google drive'})}
                    </MenuItem>
                    <MenuItem className="addFileMenuitem" value="dropbox" onClick={loadDropboxFile}>
                        {intl.formatMessage({id: 'From Dropbox'})}
                    </MenuItem>
                    <MenuItem className="addFileMenuitem" value="box" onClick={loadBoxFile}>
                        {intl.formatMessage({id: 'From Box'})}
                    </MenuItem>
                </Menu>
            </div>

            {isUploading && (
                <div className="fileUploadProgress">
                    <p>{intl.formatMessage({id: 'Uploading file'})}...</p>
                    <LinearProgress variant="determinate" value={progress} />
                    <div>{progress}%</div>
                </div>
            )}
        </div>
    );
};

TaskDetailFiles.propTypes = {
    activeTab: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired
};

export default TaskDetailFiles;
