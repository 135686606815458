import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { Divider } from '@material-ui/core';
import { getAccountId } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const AddMemberDayOff = ({router, location, params}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const intl = useIntl();
    const accountId = useSelector(getAccountId);

    const handleSelect = (date) => {
        setStartDate(date.startDate);
        setEndDate(date.endDate);
    };

    const close = () => {
        if(location.state !== 'from_integrations') {
            browserHistory.push(`/settings/team-members/${router.params.uid}`);
            return;
        }

        browserHistory.push({
            pathname: `/settings/integrations/${params.id}/user/${router.params.uid}`,
            state: 'from_integrations'
        });
    };

    const save = () => {
        firebaseEvents.addChallenge('addMemberDayoff');
        firebaseEvents.addMemberDayoff(
            accountId,
            router.params.uid, 
            {startDate, endDate}, 
            intl
        );
        close();
    };
    

    return (
        <Dialog 
            className='AddMemberDayOff'
            open={true}
            onClose={close}
            maxWidth={false}
            data-sidebar='preventClose'
        >
            <DialogTitle>{intl.formatMessage({id:'Select your dates'})}</DialogTitle>
            <Divider />
            <DialogContent className="content">
                <DateRange
                    onInit={handleSelect}
                    onChange={handleSelect}
                    minDate={moment()}
                    linkedCalendars={true}
                />
            </DialogContent>
            <DialogActions className="actions">
                <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
                <Button variant="contained" onClick={save} color="primary">{intl.formatMessage({id:'Add a day off'})}</Button>
            </DialogActions>
        </Dialog>
    );

};

export default withRouter(withCustomErrorBoundary(AddMemberDayOff));