import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'clsx';
import DoingIcon from '@material-ui/icons/AccessTime';
import DoneIcon from '@material-ui/icons/Done';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import {selectSourceStatusModal, setWorkManager} from '../App/AppActions';
import WhoIsWorking from './components/WhoIsWorking';
import StopWorking from './components/StopWorking';
import {
    getAccountId,
    getAccountTaskCanManage,
    getAccountTaskCanWork,
    getAccountTaskNbworkers,
    getAccountTaskStatus,
    getAccountTaskEstimationsUserId,
    getAccountTaskUserworking,
    getAccountTaskDoneby,
    getAccountTaskForcedUsers
} from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { toastr } from 'react-redux-toastr';

import useInternalization from '../../utils/hooks/useInternalization';
import { handleStopPropagation } from '../../utils/utils';
import useSyncData from '../../utils/hooks/useSyncData';

const TaskStatusIcon = ({taskId, className}) =>{
    const dispatch = useDispatch();
    const { t } = useInternalization();
    const { isSyncAppTask, hasSourceStatus } = useSyncData(taskId);

    const [isShowingWhoIsWorking, setIsShowingWhoIsWorking] = useState(false);
    const [isShowingStopWorking, setIsShowingStopWorking] = useState(false);
    const [taskWorker, setTaskWorker] = useState([]);

    const accountId = useSelector(getAccountId);
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskNbworkers = useSelector(getAccountTaskNbworkers(taskId));
    const taskForcedUsers = useSelector(getAccountTaskForcedUsers(taskId));
    const taskEstimationsUserId = useSelector(getAccountTaskEstimationsUserId(taskId));
    const taskUsersWorking = useSelector(getAccountTaskUserworking(taskId));
    const taskDoneBy = useSelector(getAccountTaskDoneby(taskId));
    const canWork = useSelector(getAccountTaskCanWork(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));

    const handleClickAway = useCallback((e) => {
        e?.stopPropagation();

        if(document.querySelectorAll('.SelectTeamMember_Modal').length){
            return;
        }

        if (
            $(e.target).closest('.TaskStatusIcon').length === 0 &&
            $(e.target).closest('.userSelectItem').length === 0 &&
            (isShowingWhoIsWorking || isShowingStopWorking)
        ) {

            setIsShowingWhoIsWorking(false);
            setIsShowingStopWorking(false);
        }
    }, [isShowingWhoIsWorking, isShowingStopWorking]);

    const handleMarkAsDone = useCallback((e) => {
        e.stopPropagation();

        setIsShowingWhoIsWorking(false);

        dispatch(setWorkManager({
            taskId: taskId,
            userId: taskDoneBy,
            previousState: taskStatus,
            newState: 'done',
        }));
    },[taskStatus,taskId,taskDoneBy]);

    const handleStopWorking = useCallback((e) => {
        e.stopPropagation();

        setIsShowingWhoIsWorking(false);

        dispatch(setWorkManager({
            taskId: taskId,
            userId: taskUsersWorking || taskEstimationsUserId,
            previousState: taskStatus,
            newState: 'todo',
        }));
    },[taskStatus, taskId, taskUsersWorking]);

    const handleStartWorking = useCallback((e) => {
        e?.stopPropagation();

        setIsShowingWhoIsWorking(false);

        if(!taskWorker.length) {
            toastr.error(t('taskstatusicon.start.working'));
            return;
        }

        dispatch(setWorkManager({
            taskId: taskId,
            userId: taskWorker,
            previousState: taskStatus,
            newState: 'inprogress',
        }));
    },[taskStatus, taskId, taskWorker]);

    const handleIconClick = useCallback((e) => {
        e?.stopPropagation();

        if((!canWork && !canManage)) return;

        if(isSyncAppTask && hasSourceStatus) {
            dispatch(selectSourceStatusModal({ show: true, taskId }));
            return;
        }

        if (isShowingStopWorking) {
            setIsShowingStopWorking(false);
            return;
        }

        if (isShowingWhoIsWorking) return;

        if (taskStatus === 'todo') {
            setIsShowingWhoIsWorking(true);
            return;
        }

        if (taskStatus === 'inprogress') {
            setIsShowingStopWorking(true);
            return;
        }

        if (taskStatus === 'done') {
            const taskLine = $(e.currentTarget).closest('.TaskLine');
            taskLine.addClass('goingto-todo');
            taskLine.removeClass('goingto-done');

            setIsShowingStopWorking(false);

            firebaseEvents.updateTaskStatus(accountId, taskId, 'todo');
            return;
        }

        setIsShowingWhoIsWorking(false);
        setIsShowingStopWorking(false);
    }, [canWork, canManage, taskStatus, isShowingStopWorking, isShowingWhoIsWorking, accountId, taskId, isSyncAppTask, hasSourceStatus]);

    const changeAssignedUser = useCallback((value) => setTaskWorker(value), []);

    useEffect(() => {
        if(!taskForcedUsers && !taskEstimationsUserId) {
            setTaskWorker([]);
            return;
        }

        if(taskStatus === 'todo') {
            setTaskWorker(taskForcedUsers || taskEstimationsUserId);
        }
    }, [taskForcedUsers, taskEstimationsUserId, taskStatus]);

    useEffect(()=>{
        document.addEventListener('click', handleClickAway);

        return function cleanup(){
            document.removeEventListener('click', handleClickAway);
        };
    }, [handleClickAway]);

    return (
        <div
            onClick={e => handleStopPropagation(e)}
            className={classnames('TaskStatusIcon', {
                disabled: (!canWork && !canManage),
            })}
        >
            {isShowingWhoIsWorking && (
                <WhoIsWorking
                    showWhoIsWorking={isShowingWhoIsWorking}
                    taskNbworkers={taskNbworkers}
                    userWorking={taskWorker}
                    handleSetStartWorking={handleStartWorking}
                    handleChangeAssignedUser={changeAssignedUser}
                    handleMarkAsDone={handleMarkAsDone}
                    taskId={taskId}
                />
            )}

            {isShowingStopWorking && (
                <StopWorking
                    showStopWorking={isShowingStopWorking}
                    handleMarkAsDone={handleMarkAsDone}
                    handleStopWorking={handleStopWorking}
                />
            )}

            <div
                onClick={handleIconClick}
                className={classnames('icon', taskStatus, {
                    [className]: className,
                    icon: !className,
                    showWhoIsWorking: isShowingWhoIsWorking,
                    showStopWorking: isShowingStopWorking,
                    disabled: (!canWork && !canManage),
                })}
            >
                <DoingIcon className="DoingIcon" />
                <DoneIcon className="DoneIcon" />
            </div>
        </div>
    );
};

export default React.memo(withCustomErrorBoundary(TaskStatusIcon));