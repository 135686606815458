import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import _ from 'underscore';

import SelectTeamMember from '../../../SelectTeamMember/SelectTeamMember';
import { getAccountId, getAccountTasks } from '../../../../utils/selectors/account';
import { getAccountDataSyncAppType, getAccountDataSyncAppUsers } from '../../../../utils/selectors/accountData';
import { removeSyncAppUser, setSyncAppMatchUser, setSyncAppUnmatchUser } from '../../../../utils/controllers/syncApp/syncApp';
import { batchUpdates } from '../../../../utils/databaseQuerys/notifications';
import CreateNewMemberModal from '../../../CreateNewMemberModal/CreateNewMemberModal';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const LineActions = ({ username, sourceDeleted, email, sourceId, syncAppId, matched = false }) => {
    const intl = useIntl();
    const accountId = useSelector(getAccountId);
    const accountTasks = useSelector(getAccountTasks);
    const syncAppType = useSelector(getAccountDataSyncAppType(syncAppId));

    const handleMatchUser = memberId => {
        setSyncAppMatchUser({
            syncAppType,
            syncAppId,
            planlessId: memberId,
            sourceId: sourceId,
        });
        const tasksWithTheUser = Object.keys(accountTasks).filter(taskId => {
            const oa = _.get(accountTasks, [taskId, 'originalAssignees'], []);
            const fu = _.get(accountTasks, [taskId, 'forcedUser'], []);

            return !fu.length && !!oa.find(u => u.id === sourceId);
        });

        const updates = tasksWithTheUser.reduce(
            (acc, taskId) => ({
                ...acc,
                [`/accounts/${accountId}/tasks/${taskId}/forcedUser`]: [memberId],
            }),
            {},
        );

        if (!_.isEmpty(updates)) {
            batchUpdates(updates);
        }
    };

    const handleRemoveMatch = () => {
        if (sourceDeleted) {
            removeSyncAppUser({
                syncAppId,
                sourceId: sourceId,
            });
            return;
        }

        setSyncAppUnmatchUser({
            syncAppType,
            syncAppId,
            planlessId: null,
            sourceId: sourceId,
        });
    };

    return (
        <div className="PlanlessUserLineActions">
            {matched ? (
                <Button className="PlanlessUserLineActions__unmatch" onClick={handleRemoveMatch} type="button">
                    {intl.formatMessage({ id: 'Unmatch' })}
                </Button>
            ) : (
                <UnMatchedActions syncAppId={syncAppId} username={username} email={email} handleMatchUser={handleMatchUser} />
            )}
        </div>
    );
};

const UnMatchedActions = ({ username, email, handleMatchUser, syncAppId }) => {
    const intl = useIntl();
    const syncAppUsers = useSelector(getAccountDataSyncAppUsers(syncAppId));
    const [usersUsed, setUsersUsed] = useState([]);
    const [openSelectMemberDialog, setOpenSelectMemberDialog] = useState(false);
    const [openCreateMemberDialog, setOpenCreateMemberDialog] = useState(false);
    const handleCloseSelectMemberDialog = () => setOpenSelectMemberDialog(false);
    const handleOpenSelectMemberDialog = () => setOpenSelectMemberDialog(true);
    const handleCloseCreateMemberDialog = () => setOpenCreateMemberDialog(false);
    const handleOpenCreateMemberDialog = () => {
        setOpenCreateMemberDialog(true);
    };
    const handleCreateMemberDialog = newUserId => {
        setOpenCreateMemberDialog(false);
        handleMatchUser(newUserId);
    };
    const handleSelectMemberDialog = newUserId => {
        setOpenSelectMemberDialog(false);
        handleMatchUser(newUserId);
    };

    React.useEffect(() => {
        if(syncAppUsers){
            setUsersUsed(Object.values(syncAppUsers).map(el => el.planlessId).filter(Boolean));
        }
    }, [syncAppUsers]);

    return (
        <>
            <Button className="PlanlessUserLineActions__create" onClick={handleOpenCreateMemberDialog} type="button">
                {intl.formatMessage({ id: 'Create Member' })}
            </Button>
            <Button
                className="PlanlessUserLineActions__manageMatch"
                onClick={handleOpenSelectMemberDialog}
                type="button"
            >
                {intl.formatMessage({ id: 'Manage Match' })}
            </Button>
            {openSelectMemberDialog && (
                <SelectTeamMember
                    open={openSelectMemberDialog}
                    currentUser={[]}
                    maxSelect={1}
                    onClose={handleCloseSelectMemberDialog}
                    onSelect={handleSelectMemberDialog}
                    email={email}
                    blockUsers={usersUsed}
                />
            )}
            {openCreateMemberDialog && (
                <CreateNewMemberModal
                    displayName={username}
                    isOpen={openCreateMemberDialog}
                    onClose={handleCloseCreateMemberDialog}
                    onSubmit={handleCreateMemberDialog}
                    defaultEmail={email}
                />
            )}
        </>
    );
};

export default withCustomErrorBoundary(LineActions);
