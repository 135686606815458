import React, { memo, useCallback, useMemo, useState } from 'react';
import Tooltip from 'rc-tooltip';
import CreateNewFolder from '../../components/CreateNewFolder';
import { addChallenge } from '../../utils/firebaseEvents';
import InfoIcon from '@material-ui/icons/Info';
import { browserHistory } from 'react-router';
import ExportData  from './ExportData';
import { Button, Checkbox, FormControlLabel, IconButton, ListItemIcon, ListSubheader, Menu, MenuItem } from '@material-ui/core';
import { AddBox, ControlPoint, ControlPointDuplicate, CreateNewFolderOutlined, ExpandMore, IndeterminateCheckBox, Settings } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountCustomFields, getAccountId, getAccountTaskCanManage, getAccountTaskCanView, getAccountTaskCanWork, getAccountTaskCanWorkersCreateTask, getAccountTaskIsRecurring } from '../../utils/selectors/account';
import { getParentChildIds, getTimelineColumns } from '../../utils/selectors/app';
import { defineClosedSortables, setTimelineColumns } from '../App/AppActions';
import useSyncData from '../../utils/hooks/useSyncData';
import useInternalization from '../../utils/hooks/useInternalization';

const TaskListHeader = ({ taskIdLimitView }) => {
    const { t } = useInternalization();
    const dispatch = useDispatch();

    const [addMenuAnchor, setAddMenuAnchor] = useState(null);
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [timelineColumnMenuAnchor, setTimelineColumnMenuAnchor] = useState(null);

    const { childs } = useSelector(getParentChildIds);
    const accountId = useSelector(getAccountId);
    const canView = useSelector(getAccountTaskCanView(taskIdLimitView));
    const canManage = useSelector(getAccountTaskCanManage(taskIdLimitView));
    const canWork = useSelector(getAccountTaskCanWork(taskIdLimitView));
    const canWorkersCreateTask = useSelector(getAccountTaskCanWorkersCreateTask(taskIdLimitView));

    const taskIsRecuring = useSelector(getAccountTaskIsRecurring(taskIdLimitView));
    const timelineColumns = useSelector(getTimelineColumns);
    const customFields = useSelector(getAccountCustomFields);

    const { canAddSyncAppTask, isSyncAppTask, syncFolder, currentTask } = useSyncData(taskIdLimitView);

    const handleExpandAll = useCallback(() => {
        if (accountId) {
            addChallenge('expandAll');
            dispatch(defineClosedSortables([], accountId));
        }
    },[accountId]);

    const handleCollapseAll = useCallback(() => {
        if (accountId) {
            addChallenge('collapseAll');
            dispatch(defineClosedSortables(Object.keys(childs), accountId));
        }
    },[accountId, childs]);

    const changeColumns = useCallback(( columnId, checked ) => {
        let cols = timelineColumns.filter(el=>el !== columnId);
        if(checked) cols.push(columnId);

        dispatch(setTimelineColumns({ cols }));
    }, [timelineColumns]);

    const handleAddMenuAnchor = useCallback(({ currentTarget }) => setAddMenuAnchor(currentTarget));

    const handleCloseMenuAnchor = useCallback(() => setAddMenuAnchor(null));

    const handleShowAddNewFolder = useCallback(() => {
        setShowAddFolder(true);
        setAddMenuAnchor(null);
    });

    const handleCloseAddNewFolder = useCallback(() => setShowAddFolder(false));

    const customFieldsMenus = React.useMemo(() => {
        if(!customFields) return null;

        return Object.keys(customFields)
            .sort((a, b) => (customFields[a].name.toUpperCase() < customFields[b].name.toUpperCase()) ? -1 : 1 )
            .reduce((acc, fieldId) => {
                return [
                    ...acc,
                    <MenuItem dense key={fieldId}>
                        <FormControlLabel
                            control={<Checkbox size="small" checked={timelineColumns.includes(fieldId)} onChange={(e)=>changeColumns(fieldId, e.target.checked)} />}
                            label={customFields[fieldId].name}
                        />
                    </MenuItem>
                ];
            }, [<ListSubheader key="customfields">{t('Custom fields')}</ListSubheader>]);
    }, [customFields, timelineColumns]);

    const MenuItems = React.useMemo(() => {
        const items = [
            <MenuItem key="addtask" dense onClick={()=>{ browserHistory.push(!taskIdLimitView ? '/tasks/addTask':`/tasks/addTask?parentId=${taskIdLimitView}`); setAddMenuAnchor(null); }}>
                <ListItemIcon><ControlPoint /></ListItemIcon>
                {t('Add a task')}
            </MenuItem>,
            <MenuItem key="addlist" dense onClick={()=>{ browserHistory.push(!taskIdLimitView ? '/tasks/addList':`/tasks/addList?parentId=${taskIdLimitView}`); setAddMenuAnchor(null); }}>
                <ListItemIcon><ControlPointDuplicate /></ListItemIcon>
                {t('Add a list of tasks')}
            </MenuItem>,
        ];

        const createFolderMenuItem = (
            <MenuItem key="addfolder" dense onClick={handleShowAddNewFolder}>
                <ListItemIcon><CreateNewFolderOutlined /></ListItemIcon>
                {t('Add a folder')}
            </MenuItem>
        );

        if(!syncFolder && currentTask?.sourceType !== 'list') {
            items.push(createFolderMenuItem);
        }


        if (!!syncFolder && syncFolder.importSubtasks) {
            items.push(createFolderMenuItem);
        }

        return (!taskIdLimitView || (canView && canManage && !taskIsRecuring)) ? items : [];
    }, [taskIdLimitView, canView, canManage, taskIsRecuring, syncFolder, currentTask]);

    const customFieldsTimelineColumns = React.useMemo(() => {
        if(!customFields) return null;

        return Object.keys(customFields)
            .sort((a, b) => (customFields[a].name.toUpperCase() < customFields[b].name.toUpperCase()) ? -1 : 1 )
            .reduce((acc, fieldId) => {
                return [
                    ...acc,
                    timelineColumns.includes(fieldId) ? <div key={fieldId} className='customFieldCol'>{customFields[fieldId].name}</div> : []
                ];
            }, []);
    }, [customFields, timelineColumns]);

    const canAddCommonTask = useMemo(
        () => !taskIdLimitView || ((canManage || (canWork && canWorkersCreateTask)) && !taskIsRecuring),
        [canManage, taskIsRecuring, canWork, canWorkersCreateTask]
    );

    const canShowAddTask = useMemo(
        () => isSyncAppTask ? canAddCommonTask && canAddSyncAppTask : canAddCommonTask,
        [isSyncAppTask, canAddCommonTask, canAddSyncAppTask]
    );

    return (
        <>
            <div className="tasks-header" id="tasks-header">
                <div className='buttons'>
                    {canShowAddTask && (
                        <Button
                            size="small"
                            endIcon={<ExpandMore/>}
                            className="add"
                            variant='contained'
                            color="primary"
                            data-sidebar='preventClose'
                            onClick={handleAddMenuAnchor}>
                            {t('add')}
                        </Button>
                    )}
                    <Tooltip overlay={t('Expand All')} placement="bottom">
                        <IconButton
                            data-sidebar='preventClose'
                            onClick={handleExpandAll}
                            className="expand"
                            variant='contained'>
                            <AddBox/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip overlay={t('Collapse All')} placement="bottom">
                        <IconButton
                            data-sidebar='preventClose'
                            onClick={handleCollapseAll}
                            className="collapse"
                            variant='contained'>
                            <IndeterminateCheckBox/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip overlay={t('Show Columns')} placement="bottom">
                        <IconButton
                            data-sidebar='preventClose'
                            className="settings"
                            variant='contained'
                            onClick={(e)=>setTimelineColumnMenuAnchor(e.currentTarget)}>
                            <Settings/>
                        </IconButton>
                    </Tooltip>
                    <ExportData />

                    {canShowAddTask && (
                        <Menu
                            className='addTasksMenu'
                            open={Boolean(addMenuAnchor)}
                            anchorEl={addMenuAnchor}
                            onClose={handleCloseMenuAnchor}
                            keepMounted
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {MenuItems}
                        </Menu>
                    )}

                    <Menu
                        className='timelineColumnsMenu'
                        open={Boolean(timelineColumnMenuAnchor)}
                        anchorEl={timelineColumnMenuAnchor}
                        onClose={()=>setTimelineColumnMenuAnchor(null)}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('assignee')} onChange={(e)=>changeColumns('assignee', e.target.checked)} />}
                                label={t('Assignee')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('skill')} onChange={(e)=>changeColumns('skill', e.target.checked)} />}
                                label={t('Skill')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('effort')} onChange={(e)=>changeColumns('effort', e.target.checked)} />}
                                label={t('Effort')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('priority')} onChange={(e)=>changeColumns('priority', e.target.checked)} />}
                                label={t('Priority')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('duedate')} onChange={(e)=>changeColumns('duedate', e.target.checked)} />}
                                label={t('Due date')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('starton')} onChange={(e)=>changeColumns('starton', e.target.checked)} />}
                                label={t('Start on')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('startafter')} onChange={(e)=>changeColumns('startafter', e.target.checked)} />}
                                label={t('Wait until')}
                            />
                        </MenuItem>
                        <MenuItem dense>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={timelineColumns.includes('timeworked')} onChange={(e)=>changeColumns('timeworked', e.target.checked)} />}
                                label={t('Time worked')}
                            />
                        </MenuItem>
                        {customFieldsMenus}
                    </Menu>

                </div>
                {timelineColumns.includes('skill') && <div className='skillCol'>{t('Skill')}</div>}
                {timelineColumns.includes('effort') && <div className='effortCol'>{t('Effort')}<Tooltip placement="top" overlay={t('task.table.effort.help.text')}><InfoIcon /></Tooltip></div>}
                {timelineColumns.includes('priority') && <div className='priorityCol'>{t('Priority')}</div>}
                {timelineColumns.includes('duedate') && <div className='duedateCol'>{t('Due date')}</div>}
                {timelineColumns.includes('starton') && <div className='startonCol'>{t('Start on')}</div>}
                {timelineColumns.includes('startafter') && <div className='startafterCol'>{t('Wait until')}</div>}
                {timelineColumns.includes('timeworked') && <div className='timeworkedCol'>{t('Time worked')}</div>}
                {customFieldsTimelineColumns}
            </div>
            {showAddFolder && <CreateNewFolder parent={taskIdLimitView} onClose={handleCloseAddNewFolder} />}
        </>
    );
};

export default memo(TaskListHeader);