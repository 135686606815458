import Bugsnag from '@bugsnag/js';
import {
    CLOUD_FUNCTIONS
} from './constants';
import axios from 'axios';

export const sendErrorReport = ({
    accountId,
    currentUserId,
    localStorageData,
    errorInfo,
    currentPath,
}) => {
    const data = {
        accountId,
        currentUserId,
        localStorageData,
        errorInfo,
        currentPath
    };

    return axios.request({
        method: 'POST',
        url: CLOUD_FUNCTIONS.errorReportHandler,
        data
    }).catch(function (error) {
        // handle error
        Bugsnag.notify(error);
    });
};