import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import PlanlessUserLine from './PlanlessUserLine';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const UserLine = ({ sourceColor, sourceAvatar, sourceDeleted, username, sourceId, planlessId, email, syncAppId, type }) => {
    return <>
        <div className={'ToolUser'}>
            <UserAvatar 
                displayName={username || email}
                color={sourceColor}
                avatarUrl={sourceAvatar}
                deletedUser={sourceDeleted}
                disableTooltip={true}
            />
            {sourceDeleted ?   <Typography variant='subtitle2' className="userDeletedLabel">User Deleted</Typography> :  <Typography variant='subtitle2'>{username || email}</Typography>}
            <Divider />
        </div>
        <PlanlessUserLine 
            planlessId={planlessId} 
            username={username} 
            sourceDeleted={sourceDeleted} 
            email={email} 
            sourceId={sourceId} 
            syncAppId={syncAppId}
            matched={type === 'matchedUsers'}
        />
    </>;
};


export default React.memo(withCustomErrorBoundary(UserLine));