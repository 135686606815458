import React, { useEffect, memo } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import * as firebaseEvents from '../../utils/firebaseEvents';
import moment from 'moment-timezone';
import classnames from 'clsx';
import $ from 'jquery';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { getAccountId, getAccountTaskComments, getAccountUsers } from '../../utils/selectors/account';
import { useSelector } from 'react-redux';
import { getUserAvatar } from '../../utils/utils';
import { getUserId } from '../../utils/selectors/user';
import TaskDetailComment from './TaskDetailComment';
import TaskCommentInput from '../TaskCommentInput/TaskCommentInput';

const TaskDetailComments = ({ activeTab, taskId, taskNbComments }) => {
    const intl = useIntl();

    const tasksCommments = useSelector(getAccountTaskComments(taskId));
    const accountId = useSelector(getAccountId);
    const users = useSelector(getAccountUsers);
    const currentUserId = useSelector(getUserId);

    useEffect(() => {
        setCommentsHeight();  
        scrollToEndComments();
        
        $(window).on('resize', setCommentsHeight());
        
        return () => {
            $(window).off('resize', setCommentsHeight());
        };
    }, [activeTab, taskId, taskNbComments]);


    const setCommentsHeight = () => {
        const taskDetailHeaderWarnings = $('.taskDetailHeader + .warnings');
        let height =
            $('.taskDetailHeader').outerHeight() +
            $('.timerWarnings').outerHeight() +
            (taskDetailHeaderWarnings.outerHeight(true) || 0) +
            $('.MuiTabs-root').outerHeight(true) +
            $('.newComment').outerHeight() -
            20;
        if (taskDetailHeaderWarnings.outerHeight(true) > 0) {
            height -= 20;
        }
        $('.commentsSection').css('height', 'calc(100vh - ' + height + 'px)');
    };

    const scrollToEndComments = () => {
        const commentSection = $('.commentsSection');
        let height = commentSection.prop('scrollHeight') - commentSection.innerHeight();

        commentSection.scrollTop(height);
    };

    const getCommentUserAt = (userId, date) => {
        const avatar = getUserAvatar(userId, false, users[userId]?.avatar);

        if (!date) {
            return avatar;
        }

        return (
            <div className="commentHeader">
                {avatar}
                <strong>{users[userId] ? users[userId]?.displayName : ''}</strong>
                <span>{moment(date).fromNow()}</span>
            </div>
        );
    };

    const deleteComment = (commentId) => {
        toastr.confirm(intl.formatMessage({id: 'Are you sure you want to delete this comment?'}), {
            onOk: () => {
                firebaseEvents.deleteCommentFromTask(accountId, taskId, commentId);
            },
            okText: intl.formatMessage({id: 'DELETE'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
        });
    };
    
    const getComments = () => {
        if (tasksCommments) {
            return _.map(tasksCommments, (comment, commentId) => {
                return (
                    <TaskDetailComment
                        key={commentId}
                        taskId={taskId}
                        comment={comment}
                        id={commentId}
                        mine={comment.from === currentUserId}
                        userAt={getCommentUserAt(comment.from, comment.at)}
                        deleteAction={() => deleteComment(commentId)}
                    />
                );
            });
        }
        return (
            <div className="noComments">
                <img src="../../../stylesheets/assets/nocomments.png?v=2" alt="No comments" />
                <h1>
                    {intl.formatMessage({id: 'No comments for now.'})}
                    <br />
                    {intl.formatMessage({id: 'Start the conversion below!'})}
                </h1>
            </div>
        );
    };

    return (
        <div className={classnames('commentsSection', { hide: activeTab !== 'comments' })}>
            {getComments()}
            <div className="newComment">
                {getCommentUserAt(currentUserId)}
                <TaskCommentInput taskId={taskId} />
            </div>
        </div>
    );
};

TaskDetailComments.PropTypes = {
    taskId: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    taskNbComments: PropTypes.number
};

export default memo(TaskDetailComments);


