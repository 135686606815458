
import { flatten as _flatten }  from 'underscore';

const getAllChildrensOfTask = (taskId, allTasks) => {
    const currentTask = allTasks[taskId];
    if(!currentTask) {
        return [];
    }

    return (Array.isArray(currentTask.childrens)) ? _flatten([taskId, ...currentTask.childrens.map(el => getAllChildrensOfTask(el.id, allTasks))]) : [taskId];
};

const getParentsTree = ({taskId, allTasks}) => {
    if(allTasks[taskId] && allTasks[taskId].parent){
        return [allTasks[taskId].parent, ...getParentsTree({taskId: allTasks[taskId].parent, allTasks })];
    }

    return [];
};

export function getParentChildIds(list, all){
    const parents  = {};
    const childs  =  {};

    list.forEach(el => {
        parents[el.id] = getParentsTree({taskId: el.id, allTasks: all }); 
        const childrens = getAllChildrensOfTask(el.id, all); 
        childrens.shift(); 

        if(childrens.length){
            childs[el.id] = childrens;
        }
    });

    return {
        parents,
        childs
    };
}
