import React from 'react';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { SkillItem } from './SkillItem';

export function GroupItem({
    group,
    checkGroupIsOpen,
    onOpenGroup,
    isParentExpanded,
    checkSkillIsSelected,
    onSelectSkill,
    isMultiSelect,
}) {
    const marginLeft = `${group.lvl * 1.5}rem`;
    const isShowing = group.parent ? isParentExpanded && checkGroupIsOpen(group.id) : checkGroupIsOpen(group.id);

    return (
        <React.Fragment key={group.id}>
            <div
                className="SelectSkill_Group"
                style={{ marginLeft }}
                onClick={onOpenGroup}
                id={group.id}
                key={group.id}>
                {!isShowing ? (
                    <KeyboardArrowRight id={group.id} onClick={onOpenGroup} />
                ) : (
                    <KeyboardArrowDown id={group.id} onClick={onOpenGroup} />
                )}
                {group?.name}
            </div>
            {isShowing && group.skills.map(skill => skill.skills?.length ? (
                <GroupItem {...{
                    group: skill,
                    checkGroupIsOpen,
                    isParentExpanded: isShowing,
                    onOpenGroup,
                    onSelectSkill,
                    isMultiSelect,
                    checkSkillIsSelected,
                }} />
            ) : (
                <SkillItem {...{
                    skill,
                    onSelectSkill,
                    isMultiSelect,
                    isSkillSelected: checkSkillIsSelected(skill.id),
                    isShowing,
                }} />
            ))}
        </React.Fragment>
    );
}