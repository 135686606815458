var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import AvatarEditor from 'react-avatar-editor';
import moment from 'moment-timezone';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Switch, TextField, } from '@material-ui/core';
import { Create as AvatarUploadIcon } from '@material-ui/icons';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { getAccountCanUsersManageOwnSkills } from '../../utils/selectors/account';
import Skills from '../EditTeamMember/tabs/Skills';
import { MILITARY_TIME_FORMAT, REGULAR_TIME_FORMAT } from '../../utils/constants';
var MyAccount = function (_a) {
    var children = _a.children;
    var _b = __read(useState({
        avatarEditorPosition: { x: 0, y: 0 },
        avatarEditorScale: 0,
        definedUserEmail: false,
        displayName: '',
        email: '',
        uploadedImage: null,
    }), 2), fields = _b[0], setFields = _b[1];
    var userAccounts = useSelector(function (state) { return state.app.userAccounts; });
    var userData = useSelector(function (state) { return state.app.user.data; });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    var accountId = useSelector(function (state) { return state.app.account.settings.id; });
    var intl = useIntl();
    var avatarRef = useRef(null);
    var canUsersManageOwnSkills = useSelector(getAccountCanUsersManageOwnSkills);
    var t = function (id, values) { return intl.formatMessage({ id: id }, values); };
    var getUserData = function (field) { var _a; return (_a = (userData && userData[field])) !== null && _a !== void 0 ? _a : ''; };
    var uploadAvatar = function (e) {
        var _a, _b;
        var file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (!file)
            return;
        if (['image/png', 'image/jpeg'].includes(file.type)) {
            var reader_1 = new FileReader();
            reader_1.addEventListener('load', function () {
                setFields(__assign(__assign({}, fields), { uploadedImage: reader_1.result }));
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
                $('#fileUpload')[0].value = '';
            }, false);
            if (file)
                reader_1.readAsDataURL(file);
        }
        else {
            toastr.error(t('Error'), t('File must be in PNG or JPG format!'));
        }
    };
    var AvatarUploadIconClick = function () {
        $('#fileUpload').trigger('click');
    };
    var updateField = function (_a) {
        var _b;
        var field = _a.field, value = _a.value;
        setFields(__assign(__assign({}, fields), (_b = {}, _b[field] = value, _b)));
    };
    var updateEmail = function () {
        firebaseEvents.changeAccountEmail(fields.email, t);
    };
    var updatePassword = function () {
        firebaseEvents.resetPassword(getUserData('email'), intl, function () { return false; });
    };
    var closeAvatarEditor = function () { return updateField({ field: 'uploadedImage', value: null }); };
    var saveAvatarEditor = function () {
        var _a;
        (_a = avatarRef === null || avatarRef === void 0 ? void 0 : avatarRef.current) === null || _a === void 0 ? void 0 : _a.getImageScaledToCanvas().toBlob(function (blob) {
            firebaseEvents.addChallenge('changeAvatar');
            firebaseEvents.changeAvatar(userAccounts, userData.userId, blob, t);
            closeAvatarEditor();
        });
    };
    var getAvatarEditor = function () {
        if (fields.uploadedImage) {
            return (React.createElement(Dialog, { onClose: closeAvatarEditor, className: "AvatarEditor", open: true },
                React.createElement(DialogContent, { className: "content" },
                    React.createElement(AvatarEditor, { ref: avatarRef, image: fields.uploadedImage, width: 200, height: 200, border: 50, borderRadius: 200, color: [0, 0, 0, 0.75], scale: fields.avatarEditorScale, rotate: 0, position: fields.avatarEditorPosition, onPositionChange: function (position) { return updateField({ field: 'avatarEditorPosition', value: position }); } }),
                    React.createElement(Slider, { value: fields.avatarEditorScale, min: 1, max: 10, onChange: function (_, value) { return updateField({ field: 'avatarEditorScale', value: value }); } })),
                React.createElement(DialogActions, { className: "actions" },
                    React.createElement(Button, { variant: "contained", onClick: closeAvatarEditor }, t('Cancel')),
                    React.createElement(Button, { variant: "contained", onClick: saveAvatarEditor, color: "primary" }, t('Save')))));
        }
        return React.createElement(React.Fragment, null);
    };
    var updateNotifications = function (event) {
        firebaseEvents.segmentTrack('Click on Browser Notification Toggle', userData.userId);
        var fireEvent = event.target.checked
            ? 'doAskNotificationPermission'
            : 'doRemoveNotificationPermission';
        firebaseEvents[fireEvent](accountId, userData.userId);
    };
    var updateEmailNotifications = function (event) {
        firebaseEvents.segmentTrack('Click on Email Notification Toggle', userData.userId);
        firebaseEvents.updateMemberData(accountId, userData.userId, {
            emailNotifications: event.target.checked,
        });
    };
    var getNotificationSection = function () {
        // Check if browser supports notifications
        if (window.Notification) {
            var granted = !!(window.Notification.permission === 'granted' && (userData === null || userData === void 0 ? void 0 : userData.pushToken));
            return (React.createElement(FormControlLabel, { control: (React.createElement(Switch, { size: "small", checked: granted, onChange: updateNotifications, color: "secondary" })), label: "Browser Notifications" }));
        }
        return React.createElement(React.Fragment, null);
    };
    var getEmailNotificationSection = function () {
        if (userData) {
            var status_1 = userData.emailNotifications;
            return (React.createElement(FormControlLabel, { control: (React.createElement(Switch, { size: "small", checked: status_1, onChange: updateEmailNotifications, color: "secondary" })), label: "Email Notifications" }));
        }
        return React.createElement(React.Fragment, null);
    };
    var getDatetimeFormatsSection = function () {
        if (userData) {
            var dateFormat = userData.dateFormat ? userData.dateFormat : 'DD/MM/YYYY';
            var timeFormat = userData.timeFormat ? userData.timeFormat : 'HH:mm';
            return (React.createElement("div", { className: "dateTimeFormats" },
                React.createElement(FormControl, { component: "fieldset" },
                    React.createElement(FormLabel, { component: "legend" }, "Date format"),
                    React.createElement(RadioGroup, { name: "dateFormat", value: dateFormat, onChange: function (e) {
                            firebaseEvents.updateMemberData(accountId, userData.userId, {
                                dateFormat: e.target.value,
                            });
                        } },
                        React.createElement(FormControlLabel, { value: "DD/MM/YYYY", control: React.createElement(Radio, { size: "small" }), label: "DD/MM/YYYY" }),
                        React.createElement(FormControlLabel, { value: "MM/DD/YYYY", control: React.createElement(Radio, { size: "small" }), label: "MM/DD/YYYY" }),
                        React.createElement(FormControlLabel, { value: "YYYY-MM-DD", control: React.createElement(Radio, { size: "small" }), label: "YYYY-MM-DD" }))),
                React.createElement(FormControl, { component: "fieldset" },
                    React.createElement(FormLabel, { component: "legend" }, "Time format"),
                    React.createElement(RadioGroup, { name: "timeFormat", value: timeFormat, onChange: function (e) {
                            firebaseEvents.updateMemberData(accountId, userData.userId, {
                                timeFormat: e.target.value,
                            });
                        } },
                        React.createElement(FormControlLabel, { value: MILITARY_TIME_FORMAT, control: React.createElement(Radio, { size: "small" }), label: "24h" }),
                        React.createElement(FormControlLabel, { value: REGULAR_TIME_FORMAT, control: React.createElement(Radio, { size: "small" }), label: "AM/PM" })))));
        }
        return React.createElement(React.Fragment, null);
    };
    var getTimezoneSection = function () { return (React.createElement("p", { className: "timezone" },
        "Your timezone is set to ",
        ' ',
        " ",
        React.createElement("b", null, (userData === null || userData === void 0 ? void 0 : userData.timezone) || moment.tz.guess()))); };
    useEffect(function () {
        setFields({
            email: getUserData('email'),
            definedUserEmail: false,
            displayName: getUserData('displayName'),
            uploadedImage: null,
            avatarEditorPosition: {
                x: 0.5,
                y: 0.5,
            },
            avatarEditorScale: 1.2,
        });
    }, [userData]);
    return (React.createElement("div", { className: "MyAccount" },
        React.createElement("h1", null, "My Account"),
        React.createElement("div", { className: "section" },
            React.createElement("div", { className: "avatarContainer" },
                React.createElement(UserAvatar, { displayName: (userData === null || userData === void 0 ? void 0 : userData.displayName) || '', avatarUrl: (userData === null || userData === void 0 ? void 0 : userData.avatar) || '', color: (userData === null || userData === void 0 ? void 0 : userData.color) || '', className: "avatar" }),
                React.createElement("div", null,
                    React.createElement("input", { type: "file", id: "fileUpload", onChange: uploadAvatar }))),
            React.createElement(AvatarUploadIcon, { onClick: function () { return AvatarUploadIconClick(); }, type: "file", id: "AvatarUploadIcon" }),
            React.createElement("form", { className: "email" },
                React.createElement(TextField, { type: "email", margin: "normal", label: t('My email'), value: fields.email, onChange: function (e) { return updateField({ field: 'email', value: e.target.value }); }, fullWidth: true }),
                React.createElement(Button, { variant: "contained", className: "changeBtn", color: "primary", onClick: updateEmail }, t('Change my account email'))),
            React.createElement("form", { className: "password" },
                React.createElement(TextField, { type: "password", className: "passField", margin: "normal", label: t('My password'), defaultValue: "qwertyuiop", fullWidth: true, disabled: true }),
                React.createElement(Button, { variant: "contained", className: "changeBtn", color: "primary", onClick: updatePassword }, t('Change my password')))),
        React.createElement("div", { className: "notifs" },
            React.createElement("h4", null, "Notifications"),
            getNotificationSection(),
            getEmailNotificationSection()),
        React.createElement("div", { className: "dateAndTime" },
            React.createElement("h4", null, "Date & Time formats"),
            getDatetimeFormatsSection(),
            getTimezoneSection()),
        canUsersManageOwnSkills && (React.createElement("div", { className: "my-skills" },
            React.createElement("h4", null, "My skills"),
            React.createElement(Skills, { helperText: "Indicate here your skills and your level of expertise on each one. Set as 0 to remove a skill." }))),
        getAvatarEditor(),
        children));
};
export default withCustomErrorBoundary(MyAccount);
