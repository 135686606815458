export const pluginsEnabledList = [
    'align',
    'charCounter',
    'codeBeautifier',
    'codeView',
    'colors',
    'draggable',
    'emoticons',
    'entities',
    'fontFamily',
    'fontSize',
    'fullscreen',
    'help',
    'image',
    'imageManager',
    'file',
    'inlineClass',
    'inlineStyle',
    'link',
    'lists',
    'paragraphFormat',
    'paragraphStyle',
    'print',
    'quote',
    'save',
    'specialCharacters',
    'table',
    'url',
    'video',
    'wordPaste'
];

export const toolbarButtonsList = {
    moreText: {
        buttons: [
            'bold', 
            'italic', 
            'underline', 
            'strikeThrough', 
            'subscript', 
            'superscript', 
            'fontFamily', 
            'fontSize', 
            'textColor', 
            'backgroundColor', 
            'inlineClass', 
            'inlineStyle', 
            'clearFormatting'
        ],
        buttonsVisible: 3
    },
    moreParagraph: {
        buttons: [
            'alignLeft', 
            'alignCenter', 
            'alignRight', 
            'alignJustify', 
            'outdent', 
            'indent', 
            'formatOL', 
            'formatUL', 
            'paragraphFormat', 
            'paragraphStyle', 
            'checkbox',
            'quote'
        ],
        buttonsVisible: 4
    },
    moreRich: {
        buttons: [
            'insertLink',  
            'insertTable', 
            'emoticons', 
            'insertImage', 
            'insertVideo',
            'insertFile',
            'specialCharacters', 
            'insertHR'
        ],
        buttonsVisible: 4
    },
    moreMisc: {
        buttons: [
            'undo', 
            'redo', 
            //'fullscreen', 
            'print', 
            'getPDF', 
            'selectAll', 
            //'html', 
            //'help', 
            //'save'
        ],
        align: 'right',
        buttonsVisible: 2
    }
};

export const fontFamilyOptions =  {
    'Eina01,sans-serif': 'Eina01', 
    'Roboto,sans-serif': 'Roboto',
    'Open Sans,sans-serif': 'Open Sans',
    'Oswald,sans-serif': 'Oswald',
    'Montserrat,sans-serif': 'Montserrat', 
};