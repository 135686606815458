export const notificationTypes = ['inapp', 'email'];
const previsionTypes = ['now', 'future'];
const alertTypes = ['percentage', 'fixedCost'];
const onValue = ['internal', 'billable'];

export const isAlertValid = (alert) => {
    return alert.to.length &&
        alert.notificationType.length  &&
        alertTypes.includes(alert.alertType)  &&
        !!parseFloat(alert.value) &&
        previsionTypes.includes(alert.previsionType)  &&
        onValue.includes(alert.onValue) &&
        alert.name.trim();
};