import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import { openReccurentTaskModal } from '../App/AppActions';
import {
    getAccountId,
    getAccountTaskCanWorkersChangeEffort,
    getAccountTaskCanManage,
    getAccountTaskCanView,
    getAccountTaskCanWork,
    getAccountTaskMaxEffort,
    getAccountTaskMinEffort,
    getAccountTaskNbworkers,
    getAccountTaskStoryPoint,
} from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import SelectEffort from '../SelectEffort/SelectEffort';
import { SYNC_APP_TYPE } from '../Integrations/utils';
import useSyncData from '../../utils/hooks/useSyncData';

const formatNum = (number) => {
    return Math.round(number * 100) / 100;
};

const TaskEffortInputs = ({taskId, disabled, taskLine, multiEffortDetails, onChange})=>{
    const dispatch = useDispatch();
    const taskStoryPoint = useSelector(getAccountTaskStoryPoint(taskId));
    const { syncApp, syncAppType } = useSyncData(taskId);
    const storyPointsActivated = [SYNC_APP_TYPE.JIRA, SYNC_APP_TYPE.JIRASERVER].includes(syncAppType) && syncApp?.effortSettings?.onUpdate?.estimationActive === 'storyPoint';

    const canManage = useSelector(getAccountTaskCanManage(taskId));
    const canView = useSelector(getAccountTaskCanView(taskId));
    const canWork = useSelector(getAccountTaskCanWork(taskId));
    const canWorkersChangeEffort = useSelector(getAccountTaskCanWorkersChangeEffort(taskId));

    const minEffort = useSelector(getAccountTaskMinEffort(taskId));
    const maxEffort = useSelector(getAccountTaskMaxEffort(taskId));
    const nbWorkers = useSelector(getAccountTaskNbworkers(taskId));
    const accountId = useSelector(getAccountId);

    const [stateMinEffort, setStateMinEffort] = useState(0);
    const [stateMaxEffort, setStateMaxEffort] = useState(0);
    const [stateNbWorkers, setStateNbWorkers] = useState(1);
    const [stateDisabled, setStateDisabled] = useState(false);
    const [showMinEffortInput, setShowMinEffortInput] = useState(false);
    const [showMaxEffortInput, setShowMaxEffortInput] = useState(false);
    const [selectEffortOpen, setSelectEffortOpen] = useState(false);

    useEffect(() => {
        if(!taskId) return;

        setStateDisabled(disabled);
        setStateNbWorkers(nbWorkers || 1);

        setStateMinEffort(formatNum(minEffort || 0));
        setStateMaxEffort(formatNum(maxEffort || 0));
    }, [minEffort, maxEffort, taskId, nbWorkers, disabled]);

    const handleClick = (e) => {
        e && e.stopPropagation();
        const nbWorkersClick = e.target.closest('.nbWorkers');

        if(storyPointsActivated && !nbWorkersClick) {
            setSelectEffortOpen(true);
        }
    };

    const handleClose = () => setSelectEffortOpen(false);

    const setOnChange = (changed) => {
        var minvalue = stateMinEffort;
        const regex = new RegExp(/\d(m|d)?$/);

        if(!regex.test(minvalue)) {
            minvalue = 0;
        }

        if (minvalue[minvalue.length - 1] === 'd') {
            minvalue = minvalue.slice(0, minvalue.length - 1) * 8;
        } else if (minvalue[minvalue.length - 1] === 'm') {
            minvalue = minvalue.slice(0, minvalue.length - 1) / 60;
        }

        var maxvalue = stateMaxEffort;
        if(!regex.test(maxvalue)) {
            maxvalue = 0;
        }

        if (maxvalue[maxvalue.length - 1] === 'd') {
            maxvalue = maxvalue.slice(0, maxvalue.length - 1) * 8;
        } else if (maxvalue[maxvalue.length - 1] === 'm') {
            maxvalue = maxvalue.slice(0, maxvalue.length - 1) / 60;
        }

        if (changed === 'min' && minvalue > maxvalue) {
            maxvalue = minvalue;
            setStateMinEffort(formatNum(minvalue));
            setStateMaxEffort(formatNum(maxvalue));

            onChange(minvalue, maxvalue);
        } else if (changed === 'max' && minvalue > maxvalue) {
            minvalue = maxvalue;

            setStateMinEffort(formatNum(minvalue));
            setStateMaxEffort(formatNum(maxvalue));
            onChange(minvalue, maxvalue);
        } else {
            onChange(minvalue, maxvalue);
        }
    };

    const setTaskMinEffort = () => {
        var value = stateMinEffort;
        const regex = new RegExp(/\d(m|d)?$/);
        if(!regex.test(value)) {
            value = 0;
        }

        if (value[value.length - 1] === 'd') {
            value = value.slice(0, value.length - 1) * 8;
        } else if (value[value.length - 1] === 'm') {
            value = parseInt(value.slice(0, value.length - 1) / 60 * 100)/100;
        }

        if (!isNaN(parseFloat(value))) {
            if (onChange) {
                setOnChange('min');
            } else if (parseFloat(value) !== parseFloat(minEffort)) {
                firebaseEvents.setTaskMinEffort(accountId, taskId, value);
            }
        }

        setStateMinEffort(value ?? 0);
        setStateMaxEffort((value > stateMaxEffort) ? value:stateMaxEffort);
        setShowMinEffortInput(false);
    };

    const setTaskMaxEffort = () => {
        var value = stateMaxEffort;
        const regex = new RegExp(/\d(m|d)?$/);

        if(!regex.test(value)) {
            value = 0;
        }

        if (value[value.length - 1] === 'd') {
            value = value.slice(0, value.length - 1) * 8;
        } else if (value[value.length - 1] === 'm') {
            value = parseInt(value.slice(0, value.length - 1) / 60 * 100)/100;
        }


        if (!isNaN(parseFloat(value))) {
            if (onChange) {
                setOnChange('max');
            } else if (parseFloat(value) !== parseFloat(maxEffort)) {
                firebaseEvents.setTaskMaxEffort(accountId, taskId, value);
            }
        }

        setStateMaxEffort(value ?? 0);
        setStateMinEffort((value < stateMinEffort) ? value:stateMinEffort);
        setShowMaxEffortInput(false);

    };

    const handleMinChange = (e) => {
        setStateMinEffort(e.target.value.replace(',', '.'));
    };

    const handleMaxChange = (e) => {
        setStateMaxEffort(e.target.value.replace(',', '.'));
    };

    const doShowMinEffortInput = () => {
        if (
            !stateDisabled &&
            stateNbWorkers === 1 &&
            (canManage || (canWork && canWorkersChangeEffort))
        ) {
            setShowMinEffortInput(true);
        }
    };

    const doShowMaxEffortInput = () => {
        if (
            !stateDisabled &&
            stateNbWorkers === 1 &&
            (canManage || (canWork && canWorkersChangeEffort))
        ) {
            setShowMaxEffortInput(true);
        }
    };

    const handleNbWorkersChange = (e) => {
        let val = parseInt(e.target.value);
        if (isNaN(val) || val < 1) {
            val = 1;
        } else if (val > 9) {
            val = 9;
        }
        setStateNbWorkers(val);
    };

    const setTaskNbWorkers = () => {
        let val = parseInt(stateNbWorkers);
        if (isNaN(val) || val <= 1) {
            val = null;
        } else if (val > 9) {
            val = 9;
        }
        firebaseEvents.setTaskNbWorkers(accountId, taskId, val);
        dispatch(openReccurentTaskModal({
            taskId: taskId,
            updatedField: 'nbWorkers',
        }));
    };

    const abbreviatedNumform = (n) => {
        if (n < 100) {
            n = Math.round(n * 100) / 100;
            return parseInt(parseFloat(n).toString()*100)/100;
        }

        if (n >= 100 && n < 1000) {
            return n % 1 === 0 ? Number(n).toFixed() : Number(n).toFixed(1);
        }

        if (n >= 1000 && n < 100000) {
            n = Math.round(n / 100) / 10;
            return n.toString() + 'K';
        }

        if (n >= 100000) {
            n = Math.round(n / 1000);
            return n.toString() + 'K';
        }
    };

    if (
        taskId &&
        !canView
    ) {
        return null;
    }

    var isDisabled =
        taskId &&
        (
            stateDisabled ||
            (stateNbWorkers > 1 && taskLine) ||
            (!canManage && (!canWork || !canWorkersChangeEffort))
        );

    let minEffValFormat = abbreviatedNumform(stateMinEffort),
        maxEffValFormat = abbreviatedNumform(stateMaxEffort);

    let minEffVal = stateMinEffort || 0,
        maxEffVal = stateMaxEffort || 0,
        title = null;

    if(new RegExp(/\d(m|d)?$/).test(stateMaxEffort)) {
        maxEffVal = stateMaxEffort;
    }
    if(new RegExp(/\d(m|d)?$/).test(stateMinEffort)) {
        minEffVal = stateMinEffort;
    }

    if (taskLine && stateNbWorkers > 1) {
        if(minEffVal !== '' && !new RegExp(/\d(m|d)?$/).test(minEffVal)){
            minEffVal *= stateNbWorkers;
            minEffVal = parseInt(parseFloat(minEffVal)*100)/100;
        }
        if(maxEffVal !== '' && !new RegExp(/\d(m|d)?$/).test(maxEffVal)){
            maxEffVal *= stateNbWorkers;
            maxEffVal = parseInt(parseFloat(maxEffVal)*100)/100;
        }
        title = 'Multiple workers on this task. Go to details panel to edit the effort.';
    }

    var minEffortContent = (
        <span
            className={classnames('minEffort', {
                disabled: isDisabled,
                fromTaskLine: taskLine,
                multiWorker: stateNbWorkers > 1,
            })}
            data-task-id={taskId}
            onFocus={doShowMinEffortInput}
            onClick={doShowMinEffortInput}

            tabIndex={stateDisabled ? null : '0'}
            title={title}
        >
            {disabled ? minEffValFormat : minEffVal}
        </span>
    );

    var maxEffortContent = (
        <span
            className={classnames('maxEffort', {
                disabled: isDisabled,
                fromTaskLine: taskLine,
                multiWorker: stateNbWorkers > 1,
            })}
            data-task-id={taskId}
            onClick={doShowMaxEffortInput}
            onFocus={doShowMaxEffortInput}

            tabIndex={stateDisabled ? null : '0'}
            title={title}
        >
            {disabled ? maxEffValFormat : maxEffVal}
        </span>
    );

    if (!taskLine || showMinEffortInput) {
        minEffortContent = (
            <input
                autoFocus={!isDisabled && taskLine}
                className={classnames('minEffort', { fromTaskLine: taskLine })}
                data-task-id={taskId}
                disabled={isDisabled}
                value={minEffVal}
                onChange={handleMinChange}
                onBlur={setTaskMinEffort}
                onFocus={e => { if(e?.target){ e.target.select(); setTimeout(()=>e?.target && e.target.select(), 50); } }}
                onClick={e => e?.target && e.stopPropagation()}
            />
        );
    }

    if (!taskLine || showMaxEffortInput) {
        maxEffortContent = (
            <input
                autoFocus={!isDisabled && taskLine}
                className={classnames('maxEffort', { fromTaskLine: taskLine })}
                data-task-id={taskId}
                disabled={isDisabled}
                value={maxEffVal}
                onChange={handleMaxChange}
                onBlur={setTaskMaxEffort}
                onFocus={e => { if(e?.target){ e.target.select(); setTimeout(()=>e?.target && e.target.select(), 50); } }}
                onClick={e => e?.target && e.stopPropagation()}
            />
        );
    }

    return (
        <>
            <div
                className={classnames('TaskEffortInputs', { disabled: isDisabled, fromTaskLine: taskLine })}
            >
                <div
                    onClick={handleClick}
                    className={classnames('taskEffortInputs__effortInputs', {
                        jiratype: storyPointsActivated
                    })}
                >
                    {minEffortContent}
                    <span className="effortSep">-</span>
                    {maxEffortContent}
                </div>

                {taskLine || multiEffortDetails ? null : (
                    <div className="nbWorkers">
                        x
                        <input
                            disabled={disabled}
                            type="number"
                            min={1}
                            max={9}
                            step={1}
                            value={stateNbWorkers}
                            onChange={handleNbWorkersChange}
                            onBlur={setTaskNbWorkers}
                            onFocus={e => e?.target && e.target.select()}
                        />
                        worker(s)
                    </div>
                )}
            </div>

            {selectEffortOpen && (
                <SelectEffort
                    open={selectEffortOpen}
                    onClose={handleClose}
                    taskId={taskId}
                    currentStoryPointId={Object.keys(taskStoryPoint || {})[0] || null}
                />
            )}
        </>
    );
};

export default memo(withCustomErrorBoundary(TaskEffortInputs));
