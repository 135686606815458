import React from 'react';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TimelineIcon from '@material-ui/icons/Sort';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import BoardIcon from '@material-ui/icons/ViewWeekOutlined';
import classnames from 'clsx';
import { showFilters } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ViewModesSwitch = ({routes}) => {
    const intl = useIntl();
    const dispatch = useDispatch();


    const handleChangeViewMode = (viewMode) => {
        if(viewMode === 'calendar' || viewMode === 'workload') {
            dispatch(showFilters(false));
        }
        browserHistory.push(`${viewMode}`);
    };

    return (
        <div className="viewModes">

            <ButtonGroup
                size="small"
                color="primary"
            >
            
                <Button 
                    className={classnames({active: routes[3].path === 'tasks'})}
                    onClick={() => handleChangeViewMode('tasks')}
                >   
                    <TimelineIcon />
                    <h3>{intl.formatMessage({id:'Timeline'})}</h3>
                </Button>
            
            
                <Button 
                    className={classnames({active: routes[3].path === 'list'})}
                    onClick={() => handleChangeViewMode('list')}
                >
                    <ListIcon />
                    <h3>{intl.formatMessage({id:'List'})}</h3>
                </Button>
            
            
                <Button 
                    className={classnames({active: routes[3].path === 'board'})}
                    onClick={() => handleChangeViewMode('board')}
                >
                    <BoardIcon />
                    <h3>{intl.formatMessage({id:'Kanban'})}</h3>
                </Button>
            
            </ButtonGroup>

        </div>
    );
};

export default withRouter(withCustomErrorBoundary(ViewModesSwitch));

