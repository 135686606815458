import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import moment from 'moment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ExpensesDialog from './ExpensesDialog';
import { updateTaskExpenses, getTaskExpenses } from  '../../../utils/controllers/tasks/expenses';
import Proptypes from 'prop-types';
import { currencyFormat  } from '../../../utils/currencyFormat';
import {toastr} from 'react-redux-toastr';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Expense = ({ taskId }) => {
    const intl = useIntl();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editExpenseId, setEditExpenseId] = useState(null);
    const [expenses, setExpenses] = useState([]);
    const userDateFormat = useSelector((state) => state.app?.user?.data?.dateFormat || 'DD/MM/YYYY');
    const maxTotal = expenses.reduce((acc, expense) => acc + parseInt(expense.total) ,0);
    const maxTotalVit = expenses.reduce((acc, expense) => acc + parseInt(expense.totalWithVat) ,0);
    const expensesTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes || {});
    const currency = useSelector((state) => state.app?.account?.settings.costBudget.currency?.value || 'EUR');
    
    useEffect(() => {
        getTaskExpenses(taskId).then(data => setExpenses(data || []));
    }, []);

    const handleDialog = () => {
        setDialogOpen(!dialogOpen);
        setEditExpenseId(null);
    };
    
    const handleAdd = (expense) => {
        let newExpenses = [];
        if(expense.id){
            const expesesCopy = [...expenses].filter(el => el.id !== expense.id);
            newExpenses = [...expesesCopy, expense];
        } else {
            newExpenses = [...expenses, {
                ...expense,
                id: expenses.length + 1,
            }];
        }
        setExpenses(newExpenses);
        setDialogOpen(!dialogOpen);
        updateTaskExpenses(taskId, newExpenses);
        setEditExpenseId(null);

    };

    const handleDelete = (id) => {
        toastr.confirm(
            intl.formatMessage({id: 'delete-expense-warning'}),
            {
                onOk: () => {
                    const expesesCopy = [...expenses].filter(el => el.id !== id);
                    setExpenses(expesesCopy);
                    updateTaskExpenses(taskId, expesesCopy);
                },
                okText: intl.formatMessage({id: 'OK'}),
                cancelText: intl.formatMessage({id:  'toastr.confirm.cancel' })
            },
        );
    };

    const handleEdit = (id) => {
        setEditExpenseId(id);
        setDialogOpen(!dialogOpen);
    };
    
    return <div className="Expenses">

        <Button variant="contained" onClick={handleDialog} color="primary" aria-label="add" >
            {intl.formatMessage({id: 'expense.add-new'})}
        </Button>

        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{intl.formatMessage({id: 'expense.date'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.title'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.type'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.pricePerUnit'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.units'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.total'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.vat'})}</TableCell>
                    <TableCell align="left">{intl.formatMessage({id: 'expense.totalWithVat'})}</TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {expenses.length 
                    ?
                    <>
                        {expenses.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell align="left">{moment(row.date).format(userDateFormat)}</TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell align="left"> 
                                    { 
                                        row.types && row.types.map(el => 
                                            <div key={el} className={`type-content color${expensesTypes[el].color}`}>
                                                {expensesTypes[el].name} 
                                            </div>
                                        ) || '-'
                                    }
                                </TableCell>
                                <TableCell align="left">{currencyFormat({ value: row.pricePerUnit, currency: currency })}</TableCell>
                                <TableCell align="left">{row.units}</TableCell>
                                <TableCell align="left">{currencyFormat({ value: row.total, currency: currency })}</TableCell>
                                <TableCell align="left">{currencyFormat({ value: row.vat, currency: currency })}</TableCell>
                                <TableCell align="left">{currencyFormat({ value: row.totalWithVat, currency: currency })}</TableCell>
                                <TableCell>
                                    <EditIcon className='icon' onClick={() => handleEdit(row.id)} />
                                    <DeleteIcon className='icon' onClick={() => handleDelete(row.id)} />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={4}></TableCell>
                            <TableCell className={'bold'} align="left" colSpan={0}>{intl.formatMessage({id: 'expense.total'})}</TableCell>
                            <TableCell className={'bold'} align="left" >{currencyFormat({ value: maxTotal, currency: currency })}</TableCell>
                            <TableCell className={'bold'} align="left" ></TableCell>
                            <TableCell className={'bold'} align="left" >{currencyFormat({ value: maxTotalVit, currency: currency })}</TableCell>
                            <TableCell className={'bold'} align="left" ></TableCell>
                        </TableRow>
                    </>
                    : <TableRow><TableCell align="center" colSpan={8}>{intl.formatMessage({id: 'No expenses found'})}</TableCell></TableRow>
                }
            </TableBody>
        </Table>
        <ExpensesDialog editExpense={editExpenseId ? expenses.find(el => el.id === editExpenseId) : null} open={dialogOpen} handleAdd={handleAdd} handleClose={handleDialog} />
    </div>;
};


Expense.propTypes = {
    taskId: Proptypes.string.isRequired,
}; 
export default withCustomErrorBoundary(Expense);