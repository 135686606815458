import React, { memo } from 'react';
import DangerIcon from '@material-ui/icons/Warning';
import Tooltip from 'rc-tooltip';
import { useSelector, shallowEqual } from 'react-redux';
import {
    getAccountTaskRisksTaskAtDanger,
    getAccountTaskRisksTaskAtRisk,
    getAccountTaskRisksTaskEstimationError,
    getAccountTaskRisksTaskForcedUserNoShe,
    getAccountTaskRisksTaskNoEffort,
    getAccountTaskRisksTaskNoSkill,
    getAccountTaskRisksTaskNoWorkers,
    getAccountTaskStatus
} from '../../utils/selectors/account';
import { useIntl } from 'react-intl';

const TaskLineWarnings = ({ taskId }) => {
    const intl = useIntl();
    const taskStatus = useSelector(getAccountTaskStatus(taskId), shallowEqual);
    const taskRiskTaskForcedUserNoShe = useSelector(getAccountTaskRisksTaskForcedUserNoShe(taskId), shallowEqual);
    const taskRiskTaskAtDanger = useSelector(getAccountTaskRisksTaskAtDanger(taskId), shallowEqual);
    const taskRiskTaskAtRisk = useSelector(getAccountTaskRisksTaskAtRisk(taskId), shallowEqual);
    const taskRiskTaskNoSkill = useSelector(getAccountTaskRisksTaskNoSkill(taskId), shallowEqual);
    const taskRiskTaskNoEffort = useSelector(getAccountTaskRisksTaskNoEffort(taskId), shallowEqual);
    const taskRiskTaskEstimationError = useSelector(getAccountTaskRisksTaskEstimationError(taskId), shallowEqual);
    const taskRisksTaskNoWorkers = useSelector(getAccountTaskRisksTaskNoWorkers(taskId), shallowEqual);

    if(taskStatus === 'done') {
        return null;
    }

    return (
        <>
            {
                taskRiskTaskAtDanger ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'deadline.danger'})}>
                        <DangerIcon className="DangerIcon" />
                    </Tooltip>
                ) : taskRiskTaskForcedUserNoShe ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'deadline.taskForcedUserNoShe'})}>
                        <DangerIcon className="DangerIcon" />
                    </Tooltip>
                ) : taskRiskTaskAtRisk ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'deadline.risk'})}>
                        <DangerIcon className="RiskIcon" />
                    </Tooltip>
                ) : taskRiskTaskNoSkill ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'task.risk.noskill'})}>
                        <DangerIcon className="DangerIcon" />
                    </Tooltip>
                ) : taskRiskTaskNoEffort ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'task.risk.noeffort'})}>
                        <DangerIcon className="DangerIcon" />
                    </Tooltip> 
                ) : taskRiskTaskEstimationError ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:taskRiskTaskEstimationError})}>
                        <DangerIcon className="DangerIcon" />
                    </Tooltip>
                ) : taskRisksTaskNoWorkers ? (
                    <Tooltip placement="right" overlay={intl.formatMessage({id:'task.risk.noworker'})}>
                        <DangerIcon className="RiskIcon" />
                    </Tooltip>
                ) : null} 
        </>
    );
};

export default memo(TaskLineWarnings);