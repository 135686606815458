var _a, _b, _c;
import { Recurrence, Currency } from './enums';
export var marks = [
    { value: 1, label: '5' },
    { value: 2, label: '10' },
    { value: 3, label: '15' },
    { value: 4, label: '25' },
    { value: 5, label: '50' },
    { value: 6, label: '75' },
    { value: 7, label: '100' },
    { value: 8, label: '150' },
    { value: 9, label: '250' },
    { value: 10, label: 'MORE' },
];
export var PRICING_TABLE = (_a = {},
    _a[Recurrence.YEARLY] = (_b = {},
        _b[Currency.USD] = {
            5: 669,
            10: 1299,
            15: 1879,
            25: 3049,
            50: 5839,
            75: 8449,
            100: 10799,
            150: 15299,
            250: 21999,
            MORE: 'Contact us',
        },
        _b[Currency.EUR] = {
            5: 619,
            10: 1249,
            15: 1799,
            25: 2919,
            50: 5619,
            75: 8099,
            100: 10349,
            150: 14929,
            250: 23749,
            MORE: 'Contact us',
        },
        _b[Currency.GBP] = {
            5: 529,
            10: 1069,
            15: 1519,
            25: 2469,
            50: 4759,
            75: 6879,
            100: 8809,
            150: 12679,
            250: 20239,
            MORE: 'Contact us',
        },
        _b),
    _a[Recurrence.MONTHLY] = (_c = {},
        _c[Currency.USD] = {
            5: 74,
            10: 144,
            15: 209,
            25: 339,
            50: 649,
            75: 939,
            100: 1199,
            150: 1699,
            250: 2449,
            MORE: 'Contact us',
        },
        _c[Currency.EUR] = {
            5: 69,
            10: 139,
            15: 199,
            25: 324,
            50: 624,
            75: 899,
            100: 1149,
            150: 1659,
            250: 2639,
            MORE: 'Contact us',
        },
        _c[Currency.GBP] = {
            5: 59,
            10: 119,
            15: 169,
            25: 274,
            50: 529,
            75: 764,
            100: 979,
            150: 1409,
            250: 2249,
            MORE: 'Contact us',
        },
        _c),
    _a);
