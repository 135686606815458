import React from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import MultiAssignDetails from './MultiAssignDetails';
import MultiSkillDetails from './MultiSkillDetails';
import MultiEffortDetails from './MultiEffortDetails';
import MultiPriorityDetails from './MultiPriorityDetails';
import MultiDeadlineDetails from './MultiDeadlineDetails';
import MultiDelayDetails from './MultiDelayDetails';
import MultiTagsDetails from './MultiTagsDetails';
import MultiDeleteDetails from './MultiDeleteDetails';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ActionsDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const handleMultiMarkDone = () => {
        firebaseEvents.multiMarkDone(selectedItems).then(() => {
            setSelectedLines();
        });
        showMultiDetails();
    };

    const handleNo = () => {
        showMultiDetails();
    };

    const handleRemoveAssign = () => {
        firebaseEvents.multiRemoveForceAssign(selectedItems).then(() => {
            setSelectedLines();
        });
        showMultiDetails();
    };

    return <div className="actionsDetails">
        <div id="multiStatusDetails">
                    Are you sure you want to mark all these tasks as done?
            <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleMultiMarkDone}
            >
                Yes
            </Button>
            <Button variant="outlined" size="small" onClick={handleNo}>
                No
            </Button>
        </div>
        <div id="multiRemoveForceAssignDetails">
                    Are you sure you want to remove all forced team members from these tasks?
            <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleRemoveAssign}
            >
                Yes
            </Button>
            <Button variant="outlined" size="small" onClick={handleNo}>
                No
            </Button>
        </div>
        <MultiAssignDetails
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiSkillDetails 
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiEffortDetails 
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiPriorityDetails 
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiDeadlineDetails 
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiDelayDetails 
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiTagsDetails
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
        <MultiDeleteDetails
            setSelectedLines={setSelectedLines}
            showMultiDetails={showMultiDetails}
            selectedItems={selectedItems}
        />
    </div>;
};

export default withCustomErrorBoundary(ActionsDetails); 