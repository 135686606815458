import _ from 'underscore';
import { ADD_TASK_LIST_LEVEL } from '../utils';

const CLICKUP_SOURCE_TYPES = {
    WORKSPACE: 'workspace',
    SPACE: 'space',
    FOLDER: 'folder',
    FOLDERLESSLIST: 'folderlessList',
    LIST: 'list',
    TASK: 'task',
    SUBTASK: 'subtask',
};

// number of parents of each type
const getEligibleTypes = (importSubtasks) => importSubtasks
    ? [CLICKUP_SOURCE_TYPES.FOLDERLESSLIST, CLICKUP_SOURCE_TYPES.LIST, CLICKUP_SOURCE_TYPES.TASK]
    : [CLICKUP_SOURCE_TYPES.FOLDERLESSLIST, CLICKUP_SOURCE_TYPES.LIST];

const sourceTypeParentsCountMapper = {
    0: CLICKUP_SOURCE_TYPES.WORKSPACE,
    1: CLICKUP_SOURCE_TYPES.SPACE,
    2: [CLICKUP_SOURCE_TYPES.FOLDER, CLICKUP_SOURCE_TYPES.FOLDERLESSLIST],
    3: CLICKUP_SOURCE_TYPES.LIST,
    4: CLICKUP_SOURCE_TYPES.TASK,
    5: CLICKUP_SOURCE_TYPES.SUBTASK,
};

const getTaskSourceType = (task, parentsCount) => {
    const sourceType = sourceTypeParentsCountMapper[parentsCount];
    const { FOLDERLESSLIST, FOLDER } = CLICKUP_SOURCE_TYPES;
    const sourceTypeToFilterWhenIsArray = _.isArray(sourceType) && task?.isConnectProject ? FOLDERLESSLIST : FOLDER;

    return _.isArray(sourceType)
        ? sourceType.find(s => s === sourceTypeToFilterWhenIsArray)
        : sourceType;
};

export const getClickUpInfo = ({ task, parentsCount, importSubtasks }) => {
    const eligibleTypes = getEligibleTypes(importSubtasks);

    const canAddTask = () => {
        const sourceType = getTaskSourceType(task, parentsCount);
        return eligibleTypes.includes(sourceType);
    };

    const getAddTaskListLevel = () => {
        const { FOLDERLESSLIST, LIST, TASK } = CLICKUP_SOURCE_TYPES;
        const sourceType = getTaskSourceType(task, parentsCount);
        const isTaskLevel = sourceType === TASK;

        if (isTaskLevel && importSubtasks) {
            return ADD_TASK_LIST_LEVEL.ONE;
        }

        if ([FOLDERLESSLIST, LIST].includes(sourceType)) {
            return importSubtasks ? ADD_TASK_LIST_LEVEL.TWO : ADD_TASK_LIST_LEVEL.ONE;
        }
    };

    return {
        canAddSyncAppTask: canAddTask(),
        hasDependencies: true,
        hasSourceTags: true,
        hasSourceStatus: true,
        addTaskListLevel: getAddTaskListLevel()
    };
};