import _ from 'underscore';
import { getState } from '../../../configureStore';
import { getNewEffortRange, getNextFibonacciNumber } from '../../../views/Integrations/components/SyncSettings/utils';
import { db } from '../../firebaseEvents';
import { getAccountId } from '../../selectors/account';

export const updateTaskStoryPoint = async (storyPoint, taskId) => {
    const accountId = getAccountId(getState());
    const { spvalue, effort: { max, min } } = storyPoint;

    await db.set(`/accounts/${accountId}/tasks/${taskId}/storyPoint`, {
        [storyPoint.id]: { 
            spvalue, 
            min, 
            max 
        }
    });
};

export const updateTaskEffortByStoryPoint = async (storyPoint, taskId) => {
    const accountId = getAccountId(getState());
    const { min, max } = storyPoint.effort;

    const taskEffortUpdates = {
        [`/accounts/${accountId}/tasks/${taskId}/maxEffort`]: max,
        [`/accounts/${accountId}/tasks/${taskId}/minEffort`]: min,
    };

    await db.update(undefined, taskEffortUpdates);
};

export const setEstimationActive = async ({ syncAppId, estimationActive }) => {
    const accountId = getAccountId(getState());
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/onUpdate/estimationActive`, estimationActive);
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/import/estimationActive`, estimationActive);
};


export const setOriginalEstimateValue = async ({ syncAppId, value }) => {
    const accountId = getAccountId(getState());
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/onUpdate/originalEstimation/value`, value);
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/import/originalEstimation/value`, value);
};

export const createStoryPoint = async ({ syncAppId, storyPoints }) => {
    const accountId = getAccountId(getState());

    const biggerStoryPoint = !_.isEmpty(storyPoints) ? _.max(storyPoints, 'spvalue') : 1;
    const currStoryPointValues = _.map(storyPoints, (storyPoint) => storyPoint.spvalue);
    const nextStoryPointNumber = getNextFibonacciNumber({ prevNumbers: currStoryPointValues });
    const nextMaxMinEfforts = getNewEffortRange(biggerStoryPoint?.effort?.max || 1);

    const key = await db.pushkey(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/onUpdate/storyPoints/value`);
    
    const updates = ['import', 'onUpdate'].reduce((acc, type) => {
        acc[`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/${type}/storyPoints/value/${key}`] = {
            spvalue: nextStoryPointNumber, 
            effort: nextMaxMinEfforts,
            id: key,
        };
        return acc;
    }, {});

    await db.update(undefined, updates);
};

export const updateStoryPoint = async ({ syncAppId, storyPointData, spId}) => {
    const accountId = getAccountId(getState());
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/onUpdate/storyPoints/value/${spId}`, storyPointData);
    await db.set(`/accountsData/${accountId}/syncApp/${syncAppId}/effortSettings/import/storyPoints/value/${spId}`, storyPointData);
};