import _ from 'underscore';
import { db } from '../firebaseEvents';

export const mapNotifications = ({ notification }) => ({
    taskId: _.get(notification, 'taskId', ''),
    id: _.get(notification, 'id', ''),
    createdAt: _.get(notification, 'createdAt', ''),
    status: _.get(notification, 'status', ''),
    type: _.get(notification, 'type', ''),
    toBeSentAt: _.get(notification, 'toBeSentAt', ''),
    toBeSentTo: _.get(notification, 'toBeSentTo', ''),
    createdBy: _.get(notification, 'createdBy', ''),
    field: _.get(notification, 'field', ''),
    oldValue: _.get(notification, 'oldValue', ''),
    newValue: _.get(notification, 'newValue', ''),
});

export const notificationsPath = ({ userId, accountId }) =>  `/accountsData/${accountId}/users/${userId}/notifications`;
export const notificationsPathBell = ({ userId, accountId }) =>  `/accountsData/${accountId}/users/${userId}/notificationsBell`;

export const getNotification = ({ id, userId, accountId }) => {
    return db.once(`${notificationsPath({ userId, accountId })}/${id}`);
};

export const getNotifications = ({ userId, accountId }) => {
    return db.once(`${notificationsPath({ userId, accountId })}`);
};

export const getListenerNotifications = ({ userId, accountId} ,callback) => {
    return db.on(`${notificationsPath({ userId, accountId })}`, callback);
};

export const offNotificationsListener = ({ userId, accountId }) => {
    return db.off(`${notificationsPath({ userId, accountId })}`);
};

export const getBellListenerNotifications = ({ userId, accountId} ,callback) => {
    return db.on(`${notificationsPathBell({ userId, accountId })}`, callback);
};

export const offBellNotificationsListener = ({ userId, accountId }) => {
    return db.off(`${notificationsPathBell({ userId, accountId })}`);
};

export const getListenerNotification = ({ userId, accountId, id}, callback) => {
    return db.on(`${notificationsPath({ userId, accountId })}/${id}`, callback);
};

export const offNotificationListener = ({ userId, accountId, id},) => {
    return db.off(`${notificationsPath({ userId, accountId })}/${id}`);
};

export const createNotification = ({ notification, id, userId, accountId,  }) => {
    const key = id || db.pushkey(`${notificationsPath({ userId, accountId })}`);

    return db.set(`${notificationsPath({ userId, accountId })}/${key}`, mapNotifications({ notification, id: key }));
};

export const updateNotification = ({ id, field, value,  userId, accountId,}) => {
    return db.set(`${notificationsPath({ userId, accountId })}/${id}/${field}`, value );
};

export const updateBellNotification = ({  value,  userId, accountId,}) => {
    return db.set(`${notificationsPathBell({ userId, accountId })}`, value );
};
export const batchUpdates = (batchUpdate) => {
    return db.update(undefined,batchUpdate);
};