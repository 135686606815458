import { Button, Switch, Dialog, DialogTitle, DialogActions, DialogContent, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {  withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountUserExtraHours, getAccountUserTimezone } from '../../../utils/selectors/account';
import moment from 'moment-timezone';
import { getUserDateformat, getUserTimeformat } from '../../../utils/selectors/user';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';
import _ from 'underscore';
import { toastr } from 'react-redux-toastr';
import { db } from '../../../utils/firebaseEvents';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ExtraHours = ({router})=>{
    const intl = useIntl();
    let [showAdd, setShowAdd] = useState(false);
    let [date, setDate] = useState(moment());
    let [start, setStart] = useState(moment().hours(9).minutes(0));
    let [end, setEnd] = useState(moment().hours(18).minutes(0));
    let [showFutureOnly, setShowFutureOnly] = useState(true);
    const userId = router.params.uid;
    const userExtraHours = useSelector(getAccountUserExtraHours(userId));
    const dateFormat = useSelector(getUserDateformat);
    const timeFormat = useSelector(getUserTimeformat);
    const timezone = useSelector(getAccountUserTimezone(userId));
    const accountId = useSelector(getAccountId);

    const newExtraHours = ()=>{
        if(start.isSameOrAfter(end)){
            toastr.error(intl.formatMessage({id:'The end hour should be after the start hour'}));
        }
        else {
            const startVal = moment.tz(moment(date).hours(moment(start).hour()).minutes(moment(start).minutes()).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', timezone).unix(),
                endVal = moment.tz(moment(date).hours(moment(end).hour()).minutes(moment(end).minutes()).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', timezone).unix();

            for(var i in userExtraHours){
                if(
                    (endVal <= userExtraHours[i].end && endVal > userExtraHours[i].start) ||
                    (startVal >= userExtraHours[i].start && startVal < userExtraHours[i].end)
                ){
                    toastr.error(intl.formatMessage({id:'Extra hours are overlapping an existing extra hour.'}));
                    return;
                }
            }

            db.push(`/accounts/${accountId}/users/${userId}/extraHours`, {start: startVal, end: endVal}).then(()=>{
                setDate(moment());
                setStart(moment().hours(9).minutes(0));
                setEnd(moment().hours(18).minutes(0));
                setShowAdd(false);
                toastr.success(intl.formatMessage({id:'Extra hours successfully added'}));
            });
        }
    };

    const deleteExtraHours = (itemId) => {
        toastr.confirm(
            intl.formatMessage({id: 'Are you sure you want to delete this extra hours?'}), 
            {
                onOk: () => {
                    db.remove(`/accounts/${accountId}/users/${userId}/extraHours/${itemId}`);
                },
                okText: intl.formatMessage({id: 'CONFIRM'}),
                cancelText: intl.formatMessage({id: 'CANCEL'}),
            }
        );
    };

    const list = useMemo(()=>{

        if(!userExtraHours){
            return intl.formatMessage({id: 'No extra hours defined'});
        }

        let list = _.sortBy(
            Object.keys(userExtraHours)
                .filter(el=>(!showFutureOnly || userExtraHours[el].end > parseInt(Date.now()/1000)))
                .map(item=>{return {id: item, start: userExtraHours[item].start, end: userExtraHours[item].end};}),
            'start'
        )
            .map(key=>(
                <TableRow key={key.id} hover={true}>
                    <TableCell>{moment.tz(userExtraHours[key.id].start, 'X', timezone).format(dateFormat)}</TableCell>
                    <TableCell>{moment.tz(userExtraHours[key.id].start, 'X', timezone).format(timeFormat)}</TableCell>
                    <TableCell>{moment.tz(userExtraHours[key.id].end, 'X', timezone).format(timeFormat)}</TableCell>
                    <TableCell>
                        <DeleteIcon onClick={()=>{ deleteExtraHours(key.id); }} />
                    </TableCell>
                </TableRow>
            ));

        if(list.length === 0){
            return intl.formatMessage({id: 'No future extra hours defined'});
        }

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'Date'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'From'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'To'})}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list}
                </TableBody>
            </Table>
        );

    }, [userId, userExtraHours, showFutureOnly]);
    
    return (
        <div className="ExtraHours">
            <div className="ExtraHours-header">
                <div>
                    <Button variant='contained' color="primary" onClick={()=>setShowAdd(true)}>Add Extra Hours</Button>
                </div>
                <div className="ExtraHours-switch">
                    {intl.formatMessage({id: 'ALL'})}
                    <Switch size="small" color="primary" checked={showFutureOnly} onChange={()=>{ setShowFutureOnly(!showFutureOnly); }} />
                    {intl.formatMessage({id: 'FUTURE'})}
                </div>
            </div>
            <div className="ExtraHours-list">
                {list}
            </div>

            <Dialog onClose={()=>setShowAdd(false)} aria-labelledby="simple-dialog-title" open={showAdd} className="ExtraHours-Dialog">
                <DialogTitle id="simple-dialog-title">
                    {intl.formatMessage({id: 'New Extra hours'})}
                    <IconButton aria-label="close" onClick={()=>setShowAdd(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DatePicker
                        format={dateFormat}
                        label={intl.formatMessage({ id: 'Date' })}
                        autoOk={true}
                        onChange={(d)=>{
                            setDate(d);
                        }}
                        value={date}
                        cancelLabel={null}
                        okLabel={null}
                        fullWidth
                    />
                    <TimePicker
                        ampm={timeFormat === 'LT'}
                        format={timeFormat}
                        label={intl.formatMessage({ id: 'Start hour' })}
                        autoOk={true}
                        onChange={(d)=>{
                            setStart(d);
                        }}
                        value={start}
                        cancelLabel={null}
                        okLabel={null}
                        fullWidth
                        minutesStep={5}
                    />
                    <TimePicker
                        ampm={timeFormat === 'LT'}
                        format={timeFormat}
                        label={intl.formatMessage({ id: 'End hour' })}
                        autoOk={true}
                        onChange={(d)=>{
                            setEnd(d);
                        }}
                        value={end}
                        cancelLabel={null}
                        okLabel={null}
                        fullWidth
                        minutesStep={5}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={()=>{
                        setDate(moment());
                        setStart(moment().hours(9).minutes(0));
                        setEnd(moment().hours(18).minutes(0));
                        setShowAdd(false);
                    }}>{intl.formatMessage({id: 'Cancel'})}</Button>
                    <Button color="primary" variant="contained" onClick={newExtraHours}>
                        {intl.formatMessage({id: 'Add Extra hours'})}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(ExtraHours));