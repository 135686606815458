import {withErrorBoundary} from 'react-error-boundary';
import ErrorMessage from './ErrorMessage';
import Bugsnag from '@bugsnag/js';

function withCustomErrorBoundary(component){
    return withErrorBoundary(
        component, 
        {
            FallbackComponent: ErrorMessage, 
            onError(err){ 
                Bugsnag.notify(err);
            } 
        } 
    );
}

export { withCustomErrorBoundary };