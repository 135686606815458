import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import _ from 'underscore';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { updateTaskSourceStatus } from '../../utils/controllers/tasks/statuses';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import StatusList from './components/StatusList/StatusList';
import { handleStopPropagation } from '../../utils/utils';
import { getSelectSourceStatusModal } from '../../utils/selectors/app';
import { SYNC_APP_NAMES } from '../Integrations/utils';
import { selectSourceStatusModal } from '../App/AppActions';
import useSyncData from '../../utils/hooks/useSyncData';

const SelectSourceStatusModal = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { taskId, show } = useSelector(getSelectSourceStatusModal);

    const { syncAppType, currentTask, syncFolder } = useSyncData(taskId);
    const [searchValue, setSearchValue] = useState('');
    const [syncFolderStatuses, setSyncFolderStatuses] = useState([]);

    const handleModalSearch = React.useCallback(({ target: { value } }) => setSearchValue(value), []);

    const statuses = React.useMemo(() => syncFolderStatuses.reduce((acc, statusItem) => {
        if(searchValue.trim() !== '') {
            if(statusItem.name.toLowerCase().match(searchValue.toLowerCase())) {
                return [...acc, statusItem];
            }
            return acc;
        }
        return [...acc, statusItem];
    }, []), [syncFolderStatuses, searchValue]);

    useEffect(() => {
        if(syncFolder) {
            const statuses = Object.values(syncFolder?.statuses || {});
            setSyncFolderStatuses(statuses);

            const isValidSourceStatus = !_.isUndefined(currentTask?.sourceStatus) && !_.isNull(currentTask?.sourceStatus) && !_.isEmpty(currentTask?.sourceStatus);
            if(taskId && !isValidSourceStatus && statuses.length) {
                updateTaskSourceStatus({
                    taskId: taskId,
                    statusId: statuses[0].id,
                    statusName: statuses[0].name,
                    planlessStatus: statuses[0].planlessStatus
                });
            }
        }
    }, [taskId, syncFolder]);

    const handleClose = useCallback((e) => {
        handleStopPropagation(e);
        setSearchValue('');
        dispatch(selectSourceStatusModal({ show: false, taskId: null }));
    }, [dispatch]);

    if(!taskId || !show) return null;

    return <>
        <Dialog maxWidth={'md'} className="SelectSourceStatus" onClose={handleClose} open={show} data-sidebar='preventClose'>
            <MuiDialogTitle className="SelectSourceStatus__title" disableTypography onClick={handleStopPropagation}>
                <div className='SelectStatusHeader'>
                    <Typography variant="h6"> {SYNC_APP_NAMES[syncAppType]} {intl.formatMessage({ id: 'Status' })}</Typography>
                    <SyncProjectLogo
                        toolName={currentTask?.syncAppType}
                        tooltipPlacement={'bottom'}
                        fromViewMode={'taskSidebarHeader'}
                        task={currentTask}
                    />
                </div>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers onClick={handleStopPropagation}>
                <div className="input-search-container">
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment className="search-icon" position="start"><SearchIcon /></InputAdornment>,
                        }}
                        placeholder="Search"
                        className="input-search"
                        fullWidth
                        onChange={handleModalSearch}
                        autoFocus
                    />
                </div>
                { _.isEmpty(statuses) ? (
                    <div className="Status-not-found-container">
                        <div className="Status-not-found">
                            {intl.formatMessage({ id: 'No Status found' })}
                        </div>
                    </div>
                ) : (
                    <StatusList
                        statuses={statuses}
                        currentStatusSelected={currentTask?.sourceStatus}
                        taskId={taskId}
                        onClose={handleClose}
                    />
                )}
            </MuiDialogContent>
        </Dialog>
    </>;
};

export default React.memo(withCustomErrorBoundary(SelectSourceStatusModal));