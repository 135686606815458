import React from 'react';
import classnames from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAppTaskDescription } from '../../utils/selectors/app';
import TextEditor from '../../components/TextEditor/TextEditor';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskDetailDescription = ({ taskId, activeTab, isTaskSyncProject }) => {
    const taskDescription = useSelector(getAppTaskDescription);

    return (
        <div
            className={classnames('descriptionSection', {
                hide: activeTab !== 'description',
                disabled: isTaskSyncProject
            })}
        >
            <TextEditor
                value={taskDescription && taskDescription[taskId]}
                taskId={taskId}
                isTaskSyncProject={isTaskSyncProject}
            />
        </div>
    );
};

TaskDetailDescription.propTypes = {
    taskId: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default withCustomErrorBoundary(TaskDetailDescription);
