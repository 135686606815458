import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import { browserHistory } from 'react-router';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';

import { getAccountDataSyncApps } from '../../../../utils/selectors/accountData';
import { SYNC_APP_NAMES, SYNC_APP_TYPE } from '../../utils';
import ConnectedProjects from '../ConnectedProjects/ConnectedProjects';
import SyncSettings from '../SyncSettings/SyncSettings';
import UserMap from '../UserMap/UserMap';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TABS_HIDDEN_FOR = ['slack', 'google-calendar', 'microsoft-calendar'];
const TAB_SYNCSETTINGS_HIDDEN_FOR = [SYNC_APP_TYPE.TRELLO, SYNC_APP_TYPE.BASECAMP];

const SyncToolPage = ({params, children}) => {
    const intl = useIntl();
    const toolName = params.id;
    const [activeTab, setActiveTab] = useState(!TAB_SYNCSETTINGS_HIDDEN_FOR.includes(toolName) ? 'settings':'user mapping');
    const [syncAppId, setSyncAppId ] = useState(null);
    const accountDataSyncApps = useSelector(getAccountDataSyncApps);

    useEffect(() => {
        if(toolName && accountDataSyncApps) {
            const syncAppId = Object.values(accountDataSyncApps || {}).find(el=>el.type===toolName)?.id;
            setSyncAppId(syncAppId);
        }
    }, [toolName, accountDataSyncApps]);

    const handleSwitchTab = (e, value) => setActiveTab(value);

    const handleGoBack = () => browserHistory.push('/settings/integrations');

    return (
        <div className="SyncToolPage">
            <h1><span onClick={handleGoBack}>{intl.formatMessage({ id: 'Integrations' })}</span> <ChevronRightIcon />{SYNC_APP_NAMES[toolName]}</h1>

            {!TABS_HIDDEN_FOR.includes(toolName) && (
                <Tabs
                    value={activeTab}
                    onChange={(e, value) => handleSwitchTab(e, value)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {!TAB_SYNCSETTINGS_HIDDEN_FOR.includes(toolName) && <Tab label={intl.formatMessage({id: 'SYNCHRONIZATION SETTINGS'})} value="settings" />}
                    <Tab label={intl.formatMessage({id: 'USER MAPPING'})} value="user mapping" />
                    <Tab label={intl.formatMessage({id: 'CONNECTED PROJECTS'})} value="connected projects" />
                </Tabs>
            )}

            {!TAB_SYNCSETTINGS_HIDDEN_FOR.includes(toolName) && activeTab === 'settings' && <SyncSettings toolName={toolName} syncAppId={syncAppId}/>}
            {activeTab === 'user mapping' && <UserMap toolName={toolName} syncAppId={syncAppId}/>}
            {activeTab === 'connected projects' && <ConnectedProjects toolName={toolName} syncAppId={syncAppId}/>}

            {children}
        </div>
    );
};

export default withCustomErrorBoundary(SyncToolPage);