var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import 'firebase/storage';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Radio';
import { toastr } from 'react-redux-toastr';
import { createRecurringFunctions, recurringFactory } from '../../utils/reccurentTasks';
import { getAccountTask, getAccountTasks } from '../../utils/selectors/account';
import { getUserDateformat } from '../../utils/selectors/user';
import { showLoader, hideLoader } from '../App/AppActions';
import WeekdayButton from './WeekdayButton';
import { tracking } from '../../utils/utils';
var checkRules = {
    week: function (settings, currentTask) {
        if (!settings.everyXWeek) {
            return false;
        }
        var newTask = recurringFactory.week({
            recurringSettings: settings,
            currentTask: currentTask,
        });
        return newTask.length > 2 && !!Object.keys(settings.weekdays).find(function (el) { return settings.weekdays[el]; });
    },
    month: function (settings, currentTask) {
        var newTask = recurringFactory.month({
            recurringSettings: settings,
            currentTask: currentTask,
        });
        return (newTask.length > 2
            && !!((settings.monthType === 'day' && settings.dayOfEvent)
                || (settings.monthType === 'ordinal'
                    && settings.onThe
                    && Object.keys(settings.weekdays).find(function (el) { return settings.weekdays[el]; }))));
    },
    year: function (settings, currentTask) {
        var newTask = recurringFactory.year({
            recurringSettings: settings,
            currentTask: currentTask,
        });
        return newTask.length > 2 && !!settings.dayOfEvent;
    },
};
var months = {
    January: 31,
    February: 29,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
};
var RecurrentTask = function (_a) {
    var taskId = _a.taskId, handleClose = _a.handleClose;
    var dispatch = useDispatch();
    var _b = __read(useState({
        type: 'week',
        startDate: Date.now(),
        dateLimit: Date.now(),
        numberOfRepetitions: 50,
        // for the week
        everyXWeek: 1,
        weekdays: {
            mon: true,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sun: false,
            sat: false,
        },
        // for month config
        onThe: 1,
        dayOfEvent: 10,
        // for year config
        dayByYear: 1,
        monthByYear: 'January',
        //
        monthType: 'day',
        typeOfLimit: 'occurrences',
    }), 2), settings = _b[0], setSettings = _b[1];
    var task = useSelector(getAccountTask(taskId));
    var stateTasks = useSelector(getAccountTasks);
    var userDateFormat = useSelector(getUserDateformat);
    var formatMessage = useIntl().formatMessage;
    if (!task || !stateTasks) {
        return null;
    }
    var handleSave = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tracking({
                        name: 'Task transformed into recurrent task',
                        data: {
                            taskId: taskId,
                            settings: settings,
                        },
                    });
                    dispatch(showLoader());
                    return [4 /*yield*/, createRecurringFunctions({
                            stateTasks: stateTasks,
                            taskId: taskId,
                            recurringSettings: settings,
                            toastr: toastr,
                        })];
                case 1:
                    _a.sent();
                    dispatch(hideLoader());
                    handleClose(e);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCheckbox = function (_a) {
        var value = _a.target.value;
        setSettings(__assign(__assign({}, settings), { typeOfLimit: value }));
    };
    var handleCheckboxMonth = function (_a) {
        var value = _a.target.value;
        setSettings(__assign(__assign({}, settings), { monthType: value }));
    };
    var changePeriodicty = function (e) {
        var _a;
        if ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) {
            setSettings(__assign(__assign({}, settings), { type: e.target.value }));
        }
    };
    var handleXWeek = function (_a) {
        var value = _a.target.value;
        setSettings(function (currentSettings) { return (__assign(__assign({}, currentSettings), { everyXWeek: value })); });
    };
    var handleNRepetitions = function (_a) {
        var value = _a.target.value;
        var v = value;
        if (v > 100) {
            toastr.error('Max repetitions is 100', 'The maximum number of repetitions allowed is 100');
        }
        if (v <= 100) {
            setSettings(__assign(__assign({}, settings), { numberOfRepetitions: v }));
        }
    };
    var handleMonthDaySelector = function (_a) {
        var value = _a.target.value;
        setSettings(__assign(__assign({}, settings), { dayOfEvent: value }));
    };
    var handleMonthWeekSelector = function (_a) {
        var _b;
        var value = _a.target.value;
        var v = value;
        setSettings(__assign(__assign({}, settings), { weekdays: __assign(__assign({}, settings.weekdays), (_b = {}, _b[v] = !settings.weekdays[v], _b)) }));
    };
    var handleMonthOrdinalSelector = function (_a) {
        var value = _a.target.value;
        var v = value;
        setSettings(__assign(__assign({}, settings), { onThe: v }));
    };
    var handleWeekDaySelector = function (e) {
        var _a;
        var _b;
        if ((_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.id) {
            var id = e.currentTarget.id;
            setSettings(__assign(__assign({}, settings), { weekdays: __assign(__assign({}, settings.weekdays), (_a = {}, _a[id] = !settings.weekdays[id], _a)) }));
        }
    };
    var handleStartDate = function (e) {
        if (e && e.isAfter(moment())) {
            var dateLimit = e.isAfter(moment(settings.dateLimit)) ? e.valueOf() : settings.dateLimit;
            setSettings(__assign(__assign({}, settings), { startDate: e.valueOf(), dateLimit: dateLimit }));
        }
    };
    var handleDayYearSelector = function (_a) {
        var value = _a.target.value;
        var v = value;
        setSettings(__assign(__assign({}, settings), { dayByYear: v }));
    };
    var handleMonthYearSelector = function (_a) {
        var value = _a.target.value;
        var v = value;
        setSettings(__assign(__assign({}, settings), { monthByYear: v }));
    };
    var handleEndDate = function (e) {
        if (e && e.isAfter(moment(settings.startDate))) {
            setSettings(__assign(__assign({}, settings), { dateLimit: e.valueOf() }));
        }
    };
    return (React.createElement(Dialog, { maxWidth: "lg", className: "RecurentTaskDialog", onClose: handleClose, "aria-labelledby": "customized-dialog-title", open: true, "data-sidebar": "preventClose" },
        React.createElement(MuiDialogTitle, { className: "Modal__Title", disableTypography: true },
            React.createElement(Typography, { variant: "h6" }, formatMessage({ id: 'Make task recurrent' }))),
        React.createElement(MuiDialogContent, { className: "content", dividers: true },
            React.createElement(FormControl, { className: "input__size", fullWidth: true, component: "fieldset" },
                React.createElement(InputLabel, null,
                    formatMessage({ id: 'Periodicity' }),
                    " "),
                React.createElement(Select, { name: "periodicity", id: "periodicity", value: settings.type, onChange: changePeriodicty },
                    React.createElement(MenuItem, { key: "week", value: "week" }, formatMessage({ id: 'Week' })),
                    React.createElement(MenuItem, { key: "month", value: "month" }, formatMessage({ id: 'Month' })),
                    React.createElement(MenuItem, { key: "year", value: "year" }, formatMessage({ id: 'Year' })))),
            settings.type === 'week' && (React.createElement("div", { className: "Week__Container" },
                React.createElement("div", { className: "Weeks__Selector" },
                    formatMessage({ id: 'Every' }),
                    React.createElement(Input, { value: settings.everyXWeek, onChange: handleXWeek, className: "Weeks__Input", name: "everyXWeek" }),
                    formatMessage({ id: 'weeks' })),
                React.createElement("div", { className: "Weekdays__Container" },
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "mon", name: "Mon.", isActive: settings.weekdays.mon }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "tue", name: "Tue.", isActive: settings.weekdays.tue }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "wed", name: "Wed.", isActive: settings.weekdays.wed }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "thu", name: "Thu.", isActive: settings.weekdays.thu }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "fri", name: "Fri.", isActive: settings.weekdays.fri }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "sat", name: "Sat.", isActive: settings.weekdays.sat }),
                    React.createElement(WeekdayButton, { handleWeekDaySelector: handleWeekDaySelector, id: "sun", name: "Sun.", isActive: settings.weekdays.sun })))),
            settings.type === 'month' && (React.createElement("div", { className: "Month__Container" },
                React.createElement("div", null,
                    React.createElement(Checkbox, { checked: settings.monthType === 'day', value: "day", onChange: handleCheckboxMonth, color: "primary" }),
                    formatMessage({ id: 'On The' }),
                    React.createElement(Select, { disabled: settings.monthType !== 'day', className: "Day__Selector", name: "day", id: "day", value: settings.dayOfEvent, onChange: handleMonthDaySelector }, Array.from({ length: 30 }).map(function (_, idx) { return (React.createElement(MenuItem, { key: Math.random(), value: idx }, idx)); })),
                    formatMessage({ id: 'Of Every Month' })),
                React.createElement("div", { className: "BYWeek__Selector" },
                    React.createElement(Checkbox, { checked: settings.monthType === 'ordinal', value: "ordinal", onChange: handleCheckboxMonth, color: "primary" }),
                    formatMessage({ id: 'On The' }),
                    React.createElement(Select, { className: "Select_Input", disabled: settings.monthType !== 'ordinal', name: "ordinal", id: "ordinal", value: settings.onThe, onChange: handleMonthOrdinalSelector },
                        React.createElement(MenuItem, { key: "1", value: "1" }, formatMessage({ id: 'First' })),
                        React.createElement(MenuItem, { key: "4", value: "2" }, formatMessage({ id: 'Second' })),
                        React.createElement(MenuItem, { key: "12", value: "3" }, formatMessage({ id: 'Third' }))),
                    React.createElement(Select, { className: "Select_Input", disabled: settings.monthType !== 'ordinal', name: "month-week-day", id: "month-week-day", value: Object.keys(settings.weekdays).find(function (el) { return settings.weekdays[el]; }) || 'mon', onChange: handleMonthWeekSelector },
                        React.createElement(MenuItem, { key: "month-week-day-mon", value: "mon" }, formatMessage({ id: 'Monday' })),
                        React.createElement(MenuItem, { key: "month-week-day-tue", value: "tue" }, formatMessage({ id: 'Tuesday' })),
                        React.createElement(MenuItem, { key: "month-week-day-wed", value: "wed" }, formatMessage({ id: 'Wednesday' })),
                        React.createElement(MenuItem, { key: "month-week-day-thu", value: "thu" }, formatMessage({ id: 'Thursday' })),
                        React.createElement(MenuItem, { key: "month-week-day-fri", value: "fri" }, formatMessage({ id: 'Friday' })),
                        React.createElement(MenuItem, { key: "month-week-day-sat", value: "sat" }, formatMessage({ id: 'Saturday' })),
                        React.createElement(MenuItem, { key: "month-week-day-sun", value: "sun" }, formatMessage({ id: 'Sunday' }))),
                    React.createElement("span", { className: "Label_Right" }, formatMessage({ id: 'Of Every Month' }))))),
            settings.type === 'year' && (React.createElement("div", { className: "Year__Container" },
                React.createElement(FormControl, { fullWidth: true, className: "buttons-container", component: "fieldset" },
                    React.createElement(InputLabel, null,
                        formatMessage({ id: 'Day' }),
                        " "),
                    React.createElement(Select, { name: "days", id: "days", value: settings.dayByYear, onChange: handleDayYearSelector }, Array.from({ length: months[settings.monthByYear] }).map(function (m, idx) { return (React.createElement(MenuItem, { key: "month-week-day-mon-".concat(idx + 1), value: idx + 1 }, idx + 1)); }))),
                React.createElement(FormControl, { fullWidth: true, className: "buttons-container", component: "fieldset" },
                    React.createElement(InputLabel, null,
                        formatMessage({ id: 'Month' }),
                        " "),
                    React.createElement(Select, { name: "months", id: "months", value: settings.monthByYear, onChange: handleMonthYearSelector }, Object.keys(months).map(function (m) { return (React.createElement(MenuItem, { key: "month-week-day-mon-".concat(m), value: m }, formatMessage({ id: m }))); }))))),
            React.createElement("div", { className: "Datepicker__Container" },
                React.createElement(DatePicker, { className: "input__size", format: userDateFormat, margin: "normal", label: formatMessage({ id: 'Starting' }), onChange: handleStartDate, value: settings.startDate, autoOk: true, cancelLabel: null, okLabel: null, id: "date", fullWidth: true })),
            React.createElement("div", null,
                React.createElement("div", { className: "Limits__Container" },
                    React.createElement(FormControlLabel, { control: (React.createElement(Checkbox, { size: "small", checked: settings.typeOfLimit === 'date', value: "date", onChange: handleCheckbox, color: "primary" })), label: formatMessage({ id: 'Until' }) }),
                    React.createElement(DatePicker, { className: "Date__picker", format: userDateFormat, margin: "none", onChange: handleEndDate, disabled: settings.typeOfLimit !== 'date', value: settings.dateLimit, autoOk: true, cancelLabel: null, okLabel: null, id: "date", fullWidth: true })),
                React.createElement("div", { className: "Limits__Container" },
                    React.createElement(FormControlLabel, { control: (React.createElement(Checkbox, { size: "small", checked: settings.typeOfLimit === 'occurrences', value: "occurrences", onChange: handleCheckbox, color: "primary" })), label: formatMessage({ id: 'Repeat' }) }),
                    React.createElement(FormControl, { component: "fieldset" },
                        React.createElement(Input, { className: "Occurrence__input", disabled: settings.typeOfLimit !== 'occurrences', value: settings.numberOfRepetitions, onChange: handleNRepetitions, id: "numberOfRepetitions", name: "numberOfRepetitions" })),
                    React.createElement("div", { className: "Time__label" }, formatMessage({ id: 'Times' }))))),
        React.createElement(MuiDialogActions, null,
            React.createElement(Button, { onClick: handleClose, variant: "contained" }, formatMessage({ id: 'Cancel' })),
            React.createElement(Button, { disabled: (settings.typeOfLimit === 'date' && moment(settings.dateLimit).isBefore(settings.startDate))
                    || (settings.typeOfLimit === 'occurrences' && !settings.numberOfRepetitions)
                    || !checkRules[settings.type](settings, task), className: "transform-btn", variant: "contained", color: "primary", onClick: handleSave }, formatMessage({ id: 'Transform' })))));
};
export default RecurrentTask;
