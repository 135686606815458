import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { DateRange } from 'react-date-range';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as firebaseEvents from '../../utils/firebaseEvents';
import { Divider } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const AddAccountDayOff = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const intl = useIntl();

    const handleSelect = (date) => {
        setStartDate(date.startDate);
        setEndDate(date.endDate);
    };

    const close = () => {
        browserHistory.push('/settings/daysoff');
    };

    const save = () => {
        firebaseEvents.addChallenge('addGlobalDayoff');
        firebaseEvents.addAccountDayoff({startDate, endDate}, intl);
        close();
    };
        

    return (
        <Dialog 
            className='AddAccountDayOff'
            open={true}
            onClose={close}
            maxWidth={false}
        >
            <DialogTitle>{intl.formatMessage({id: 'Select your dates'})}</DialogTitle>
            <Divider/>
            <DialogContent className="content">
                <DateRange
                    onInit={handleSelect}
                    onChange={handleSelect}
                    minDate={moment()}
                    linkedCalendars={true}
                />
            </DialogContent>
            <DialogActions className="actions">
                <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
                <Button variant="contained" onClick={save} color="primary">{intl.formatMessage({id:'Add a day off'})}</Button>
            </DialogActions>
        </Dialog>
    );

};

export default withCustomErrorBoundary(AddAccountDayOff);
