import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Switch } from '@material-ui/core';
import { changeCanUsersManageOwnSkills } from '../../../utils/firebaseEvents';
import { getAccountCanUsersManageOwnSkills } from '../../../utils/selectors/account';

export function ManageSkillsPermission() {
    const canUsersManageOwnSkills = useSelector(getAccountCanUsersManageOwnSkills);
    const [isChecked, setIsChecked] = useState(canUsersManageOwnSkills);

    useEffect(() => {
        changeCanUsersManageOwnSkills(isChecked);
    }, [isChecked]);

    return (
        <FormControlLabel
            control={(
                <Switch
                    size="small"
                    checked={isChecked}
                    onChange={(_, checked) => setIsChecked(checked)}
                    color="secondary"
                />
            )}
            label="Users can manage their own skills"
        />
    );
}