import React  from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import classnames from 'clsx';
import OverViewCard from '../OverViewCard';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const DaysOffOverview = ({ 
    stateTasks,
    userTimezone
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const globalDaysOff = useSelector(state => state?.app?.account?.daysoff) || {};

    const overviewCurrentData = React.useMemo(() => {
        const GDOArray = Object.values(globalDaysOff);
        // get all of the days of firsts
        const allOffDaysOff = [];
        Object.values(accountTeamMembers).forEach(user => {
            // remove the viewers
            if(!user.viewer) {
                const usersDaysOff =  user.daysoff;
                if(usersDaysOff){
                    Object.values(usersDaysOff).forEach(el => {
                        allOffDaysOff.push({...el, userId: user.userId});
                    });
                }   
                // add the global days off to each users.
                GDOArray.forEach(globalDay => allOffDaysOff.push({...globalDay, userId: user.userId}));
            }
        });
        return allOffDaysOff.reduce((acc, dayOff) => {
            if(moment.tz(dayOff.endDate, 'YYYY-MM-DD', userTimezone).isBefore(moment().tz(userTimezone))){
                return {
                    ...acc,
                    past: acc.past + 1
                };
            }

            return {
                ...acc,
                future: acc.past + 1
            };
        }, { future: 0, past: 0});
    }, [stateTasks]); 
    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return<div className={classnames('bigNumbers')}>
        <OverViewCard title='Total Of Days Off' value={overviewCurrentData.future +  overviewCurrentData.past} />
        <OverViewCard title={'Past Days Off'} value={overviewCurrentData.past}  />
        <OverViewCard title={'Future Days Off'} value={overviewCurrentData.future} />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="days-off" 
                to={`${path}/days-off`}
            >
                Go to <b>Days Off</b> report
            </Button>
        </div>
    </div>;
};

DaysOffOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
};

export default DaysOffOverview;