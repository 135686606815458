import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUserAvatar} from '../../utils/utils';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import { getCalendarMembersToShow } from '../../utils/selectors/app';
import { getAccountUsers } from '../../utils/selectors/account';
import { getUserDisplayName, getUserId, getUserPermissionsWorkload } from '../../utils/selectors/user';
import _ from 'underscore';
import { setCalendarMembersToShow } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const CalendarMembersSelect = ({routes}) => {
    const intl = useIntl();
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const memberResources = {
        ...getOnlyResourceUsers(accountUsers),
        [userId]: {
            ...accountUsers[userId]
        }
    };
    
    const displayName = useSelector(getUserDisplayName);
    const workloadPermissions = useSelector(getUserPermissionsWorkload);
    const calendarMembersToShow = useSelector(getCalendarMembersToShow);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(calendarMembersToShow.length === 0){
            dispatch(setCalendarMembersToShow([userId]));
        }
    }, [calendarMembersToShow, userId]);



    const handlePreventSidebarClose = () => {
        const muiPopper = document.querySelector('.MuiAutocomplete-popper');
        if(muiPopper && !muiPopper.getAttribute('data-sidebar')) {
            muiPopper.setAttribute('data-sidebar', 'preventClose');
        }
    };

    const calendarMembersChange = (e, values) => {
        if(values.length === 0){
            values = [userId];
        }
        dispatch(setCalendarMembersToShow(values));
    };




    let userlist = [];

    if(displayName && routes[3].path === 'calendar'){
        let tempOptions = [{id: userId, name: displayName}];
        _.each(workloadPermissions, (u)=>{ 
            if(memberResources[u]){
                tempOptions.push({id: u, name: memberResources[u].displayName}); 
            }
        });
        tempOptions = _.sortBy(tempOptions, 'name');

        _.each(tempOptions, (u)=>{ userlist.push(u.id); });

    }


    return (
        <div className='StructTasksHeader__calendar' data-sidebar='preventClose' onClick={handlePreventSidebarClose}>
            <Autocomplete
                size="small"
                className="autocomplete calendarMembers"
                key="autocompleteCalendarMembers"
                multiple
                filterSelectedOptions={true}                                
                options={userlist}
                value={calendarMembersToShow}
                getOptionLabel={option => <div className="optionUser" key={option}>{getUserAvatar(option, false, memberResources[option].avatar)}{(memberResources[option])?memberResources[option].displayName:''}</div>}
                renderInput={params => (
                    <TextField  {...params} variant="outlined" label={intl.formatMessage({id:'Resources calendars to show'})} data-sidebar='preventClose'/>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        if(memberResources[option]){
                            var props = getTagProps({ index });
                            if(option === userId && calendarMembersToShow.length < 2){
                                delete props.onDelete;
                            }
                            return (
                                <Chip
                                    data-sidebar='preventClose'
                                    avatar={getUserAvatar(option, false, memberResources[option].avatar)}
                                    variant="outlined"
                                    label={(memberResources[option])?memberResources[option].displayName:''}
                                    {...props}
                                />
                            );
                        }
                        return null;
                    })
                }

                filterOptions={(options, {inputValue})=>{
                    var results = [];
                    options.forEach((o)=>{
                        if(memberResources[o] && memberResources[o].displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1){
                            results.push(o);
                        }
                    });
                    return results;
                }}
                onChange={calendarMembersChange}
            />
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(CalendarMembersSelect));

