import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserPastEvents } from '../../utils/firebaseEvents';
import moment from 'moment-timezone';
import classnames from 'clsx';
import { browserHistory } from 'react-router';
import { Calendar as BigCalendar, Views, momentLocalizer } from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { tracking } from '../../utils/utils';
import NotInMobile from '../NotInMobile/NotInMobile';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import { getCalendarMembersToShow } from '../../utils/selectors/app';
import { getUserId, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import { getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

moment.locale('en', {
    week: {
        dow: 1,
    },
});

const CalendarEvent = ({ event }) => {
    return (event.type === 'userEvent') ? (
        <div>
            <p>
                <strong>{event.title}</strong>
            </p>
            {event.canSee && (
                <p className="user-event-display">
                    {event.scope === 'google' ? (
                        <img src="../../../stylesheets/assets/google-icon.svg" className="logo" />
                    ) : (
                        <img src="../../../stylesheets/assets/outlook-icon.svg" className="logo" />
                    )}
                    {event.calendarSummary}
                </p>
            )}
        </div>
    ) : (
        <div>
            <p>
                <strong>{event.title}</strong>
            </p>
            <p className="path">{event.path}</p>
        </div>
    );
};

const EventWrapper = ({event, children}) => {
    return (
        <div
            className={classnames(
                event.type + '-slot',
                'index-' + (parseInt(event.index) + 1),
                { isPast: event.isPast },
                { isFreeTime: event.isFreeTime },
            )}
            onClick={() => {  
                if (event.type === 'task') {
                    browserHistory.push('/calendar/' + event.itemId);
                } else if (event.canSee) {
                    browserHistory.push('/calendar/userEvents/' + event.itemId);
                }
            }}
        >
            {children}
        </div>
    );
};

class CustomToolbar extends Toolbar {
    render() {
        const {label} = this.props;

        return (
            <div className="toolbar">
                <div className="rbc-toolbar-label">{label}</div>
                <div>
                    <Button variant="outlined" size="small" onClick={() => this.navigate('TODAY')}>
                        today
                    </Button>
                    <IconButton size="small" onClick={() => this.navigate('PREV')}>
                        <ChevronLeft fontSize="large" />
                    </IconButton>
                    <IconButton size="small" onClick={() => this.navigate('NEXT')}>
                        <ChevronRight fontSize="large" />
                    </IconButton>
                </div>
            </div>
        );
    }
}

const Calendar = ({children})=>{
    const timezone = useSelector(getUserTimezone);
    const userId = useSelector(getUserId);
    const timeFormat = useSelector(getUserTimeformat);
    const tasks = useSelector(getAccountTasks);
    const users = useSelector(getAccountUsers);
    const calendarMembers = useSelector(getCalendarMembersToShow);

    const [usersPastEvents, setUsersPastEvents] = useState({});

    const getTaskPath = (taskId) => {
        return (tasks && tasks[taskId] && tasks[taskId].path) ? tasks[taskId].path.join(' / ') : null;
    };

    const getEventsForUser = (user, userIndex) => {
        
        let list = [];

        for (let taskId in tasks) {
            let currTask = tasks[taskId];

            if (
                !currTask.childrens &&
                currTask.estimations?.userId &&
                currTask.estimations?.periods &&
                currTask.estimations.userId.indexOf(user) !== -1 &&
                currTask.estimations.periods[user]
            ) {
                currTask.estimations.periods[user].forEach((period, index) => {
                    let start = moment.tz(period.start, 'X', timezone || moment.tz.guess()),
                        end = moment.tz(period.end, 'X', timezone || moment.tz.guess());

                    list.push({
                        id: 'task-' + taskId + '-' + index,
                        type: 'task',
                        itemId: taskId,
                        start: start.toDate(),
                        end: end.toDate(),
                        title: currTask.title,
                        path: getTaskPath(taskId),
                        index: userIndex,
                    });
                });
            }
        }
        // get shared events
        // get Calendar from other users
        const accountsTemMembers = users;
        const currentUserId = userId;
      
        if (currentUserId === user && (usersPastEvents[currentUserId] || accountsTemMembers[currentUserId]?.events)) {
            const events = [
                ...(usersPastEvents[currentUserId] || []),
                ...(accountsTemMembers[currentUserId]?.events || []),
            ];

            events.forEach(currEnv => {
                let start = moment.tz(currEnv.start, 'X', timezone || moment.tz.guess()),
                    end = moment.tz(currEnv.end, 'X', timezone || moment.tz.guess());

                list.push({
                    id: currEnv.id,
                    type: 'userEvent',
                    itemId: currEnv.id,
                    start: start.toDate(),
                    end: end.toDate(),
                    title: currEnv.name,
                    path: '',
                    index: userIndex,
                    isFreeTime: currEnv.isFreeTime,
                    calendarSummary: currEnv.calendarName || '',
                    scope: currEnv.scope || 'google',
                    canSee: true,
                });
            });
        }

        // get shared events
        // get Calendar from other users
        if (
            currentUserId !== user &&
            accountsTemMembers &&
            accountsTemMembers[user] &&
            accountsTemMembers[user]?.events
        ) {
            
            // user events
            const events = [...(usersPastEvents[user] || []), ...(accountsTemMembers[user].events || [])];
            events.forEach(currEnv => {
                let start = moment.tz(currEnv.start, 'X', timezone || moment.tz.guess()),
                    end = moment.tz(currEnv.end, 'X', timezone || moment.tz.guess());
                let canSee = accountsTemMembers[user].calendars && accountsTemMembers[user].calendars[currEnv.calendarPlanlessId] && accountsTemMembers[user].calendars[currEnv.calendarPlanlessId].sharedWith && accountsTemMembers[user].calendars[currEnv.calendarPlanlessId].sharedWith.includes(currentUserId);

                list.push({
                    id: currEnv.id,
                    type: 'userEvent',
                    itemId: currEnv.id,
                    start: start.toDate(),
                    end: end.toDate(),
                    title: canSee ? currEnv.name : 'block',
                    path: '',
                    index: userIndex,
                    canSee,
                    isFreeTime: currEnv.isFreeTime,
                    calendarSummary: currEnv.calendarName || '',
                    scope: currEnv.scope || 'google',
                });
            });
        }

        return list;
    };

    useEffect(()=>{
        tracking('Saw Calendar View');
        // get User Events.
        (async () => {
            if (calendarMembers) {
                const userEvents = {};
                await Promise.all(
                    calendarMembers.map(userId =>
                        getUserPastEvents({ userId }).then(a => (userEvents[userId] = a)),
                    ),
                );

                setUsersPastEvents(userEvents);
            }
        })();
    }, [calendarMembers]);


    useEffect(()=>{
        const innerCalendar = document.querySelector('.rbc-time-view');
        const innerCalendarContent = document.querySelector('.rbc-time-content');
        if(innerCalendar && innerCalendarContent) {
            innerCalendar.setAttribute('data-sidebar', 'preventClose');
            innerCalendarContent.setAttribute('data-sidebar', 'preventClose');
        }
    });

    if (calendarMembers && calendarMembers.length) {
        let eventList = [];
        calendarMembers.forEach((currUserId, index) => {
            eventList = eventList.concat(getEventsForUser(currUserId, index));
        });
        moment.tz.setDefault(timezone || moment.tz.guess());
        const localizer = momentLocalizer(moment);

        return (
            <div className="Calendar">
                <div className={classnames('CalendarContainer', {empty: eventList.length === 0})}>
                    {eventList.length === 0 ? (
                        
                        <NoTaskFound eventEmptyState={true} />

                    ) : (

                        <BigCalendar
                            data-sidebar='preventClose'
                            getNow={() =>
                                moment()
                                    .tz(timezone || moment.tz.guess())
                                    .toDate()
                            }
                            events={eventList}
                            toolbar={true}
                            view={Views.WEEK}
                            localizer={localizer}
                            timeslots={2}
                            startAccessor="start"
                            endAccessor="end"
                            scrollToTime={moment().set({ h: 8, m: 0 }).toDate()}
                            popup={true}
                            formats={{
                                dayHeaderFormat: (date, culture, localizer) =>
                                    localizer.format(date, 'dddd MMMM Do YYYY', culture),
                                dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd D', culture),
                                timeGutterFormat: (date, culture, localizer) =>
                                    localizer.format(date, timeFormat || 'HH:mm', culture),
                                monthHeaderFormat: (date, culture, localizer) =>
                                    localizer.format(date, 'MMMM', culture),
                                eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
                                    localizer.format(start, timeFormat || 'HH:mm', culture) +
                                    ' - ' +
                                    localizer.format(end, timeFormat || 'HH:mm', culture),
                            }}
                            onView={() => {}}
                            components={{
                                event: CalendarEvent,
                                eventWrapper: EventWrapper,
                                toolbar: CustomToolbar,
                                week: {
                                    header: ({ date, localizer }) => (
                                        <div>
                                            {localizer.format(date, 'ddd')}
                                            <span>{localizer.format(date, 'D')}</span>
                                        </div>
                                    ),
                                },
                            }}
                        />

                    )}

                </div>

                {children}

                <NotInMobile/>
            </div>
        ); 
    }

    return null;
};

export default withCustomErrorBoundary(Calendar);