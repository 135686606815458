import React from 'react';
import Proptypes from 'prop-types';
import { browserHistory } from 'react-router';
import OpenTaskIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

const TaskEventCell = ({ name, id, type }) => {
    const splitPathName = window.location.pathname.split('/');

    return <div className={'title-task-cell'}>
        {name}
        <IconButton className="openBtn" data-id={id} onClick={() => browserHistory.push(`/${splitPathName[1]}/${splitPathName[2]}/${splitPathName[3]}/${type}/${id}`)}>
            <OpenTaskIcon />
        </IconButton>
    </div>;
};

TaskEventCell.propTypes = {
    id: Proptypes.string,
    type: Proptypes.string,
    name: Proptypes.string,
};

export default TaskEventCell;