export const getOrderedTaskList =  (tasks, parent = null) => {
    let list = [],
        currentList = {};

    if(!parent){
        Object.keys(tasks).forEach((tid)=>{
            if(!tasks[tid].parent){
                currentList[tasks[tid].index] = tid;
            }
        });
    }
    else if(tasks && tasks[parent] && tasks[parent].childrens && tasks[parent].childrens.length) {
        tasks[parent].childrens.forEach((item)=>{
            if(tasks[item.id]){
                currentList[tasks[item.id].index] = item.id;
            }
        });
    }

    if(currentList){

        Object.keys(currentList).forEach((i)=>{
            let t = tasks[currentList[i]];
            if(t.dependenciesCalc){
                delete t.dependenciesCalc;
            }
            if(t.dependentOnThisCalc){
                delete t.dependentOnThisCalc;
            }

            list.push({...t, taskId: currentList[i], id: currentList[i], tasktype: 'task'});

            if(t.childrens){
                list = list.concat(getOrderedTaskList(tasks, currentList[i]));
            }
        });
    }

    return list;
};

export const newGetOrderedTaskList = (tasks) => {
    const mainParents = Object.keys(tasks)
        .filter(taskId => !tasks[taskId].parent)
        .map(taskId => ({
            ...tasks[taskId],
            id: taskId
        })).sort((a,b) => a.index - b.index);
    return mainParents.map(task => {
        return task.childrens ? 
            [   
                task, 
                ...getChilds(getOnlyTaskChilds(task.childrens,tasks), tasks, task.id)
            ]
            : [task];
    });
};

const getOnlyTaskChilds = (taskChilds, tasks) =>
    taskChilds
        .filter(task => task.type === 'task')
        .map(el => ({
            ...tasks[el.id],
            id: el.id,
            taskId: el.id,
            // dont know why but old code would delete this, is better if we just say that this is null
            dependenciesCalc: null,
            dependentOnThisCalc: null,
        }))
        .sort((a,b) => a.index - b.index);

const getChilds = (tasksId, tasks) => {
    return tasksId.map(task => {
        return task.childrens ? 
            [
                task,
                ...getChilds(getOnlyTaskChilds(task.childrens, tasks), tasks, task.taskId)
            ]
            : [task];
    });
};

