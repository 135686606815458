import { FormControl, FormLabel, Input } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Tooltip from 'rc-tooltip';
import React, { useEffect } from 'react';
import { setSyncAppEffortSettingsByField } from '../../../../../utils/controllers/syncApp/syncApp';
import { withCustomErrorBoundary } from '../../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../../../../utils/hooks/useInternalization';

const AsanaSyncOptions = ({ effortChangeType, syncAppId, effortSettingsData}) => {
    const { t } = useInternalization();
    const [rangeOnAddProject, setRangeOnAddProject] = React.useState(20);
    const [rangeOnToolUpdate, setRangeOnToolUpdate] = React.useState(20);

    useEffect(() => {
        if(effortSettingsData) {
            setRangeOnAddProject(parseInt(effortSettingsData.import.value));
            setRangeOnToolUpdate(parseInt(effortSettingsData.onUpdate.value));
        }
    }, [effortSettingsData]);

    const handleUpdateRange = (e) => {
        setSyncAppEffortSettingsByField(syncAppId, 'import/value', parseInt(e.target.value));
        setSyncAppEffortSettingsByField(syncAppId, 'onUpdate/value', parseInt(e.target.value));
    };

    const rangeValuesByType = {
        'onAddProject': rangeOnAddProject,
        'onToolUpdate': rangeOnToolUpdate
    };

    return (
        <FormControl className='range'>
            <FormLabel component='legend'>
                {t('Apply a range of: ')}
                <Tooltip
                    placement="top" 
                    overlay={t('If you define 20%, a 10h estimation will become a range of 8h-12h.')}
                >
                    <Info />
                </Tooltip>
            </FormLabel>
            <Input
                onChange={(e) => handleUpdateRange(e, effortChangeType)}
                className="range__number"
                value={rangeValuesByType[effortChangeType]}
                id="range"
                name="range"
                type="number"
            />
            <span>%</span>
        </FormControl>
    );
};

export default withCustomErrorBoundary(AsanaSyncOptions);