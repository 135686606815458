import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {withRouter, browserHistory} from 'react-router';
import { getAccountId, getAccountUserDaysOff } from '../../../utils/selectors/account';
import _ from 'underscore';
import moment from 'moment-timezone';
import { getUserDateformat } from '../../../utils/selectors/user';
import { toastr } from 'react-redux-toastr';
import { addChallenge, deleteMemberDayoff } from '../../../utils/firebaseEvents';
import DeleteIcon from '@material-ui/icons/Delete';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const DaysOff = ({router, location, params}) => {
    const intl = useIntl();
    
    const userDaysOff = useSelector(getAccountUserDaysOff(router.params.uid));
    const userDateFormat = useSelector(getUserDateformat);
    const accountId = useSelector(getAccountId);

    const deleteDayoff = (dayoffId) => {
        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this day off?'}), {
            onOk: () => {

                addChallenge('deleteMemberDayoff');
                deleteMemberDayoff(
                    accountId,
                    router.params.uid,
                    dayoffId,
                    intl
                );
            },
            okText: intl.formatMessage({id:'Delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
    };

    const handleOpenAddADayOff = () => {
        if(location.state !== 'from_integrations') {
            browserHistory.push(`/settings/team-members/${router.params.uid}/addDayOff`);
            return;
        }

        browserHistory.push({
            pathname: `/settings/integrations/${params.id}/user/${router.params.uid}/addDayOff`,
            state: 'from_integrations'
        });
    };

    const getMemberDaysoff = () => {
        if (userDaysOff) {
            var list = [];

            _.each(userDaysOff, (dayoff, dayoffId) => {
                list.push(
                    <TableRow key={dayoffId} hover={true}>
                        <TableCell>{moment(dayoff.startDate).format((userDateFormat) ? userDateFormat : 'DD/MM/YYYY')}</TableCell>
                        <TableCell>{moment(dayoff.endDate).format((userDateFormat) ? userDateFormat : 'DD/MM/YYYY')}</TableCell>
                        <TableCell>{moment(dayoff.endDate).diff(moment(dayoff.startDate), 'days') + 1}</TableCell>
                        <TableCell>
                            <DeleteIcon onClick={() => deleteDayoff(dayoffId)} />
                        </TableCell>
                    </TableRow>
                );
            });

            return (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id:'From'})}</TableCell>
                            <TableCell>{intl.formatMessage({id:'To'})}</TableCell>
                            <TableCell>{intl.formatMessage({id:'Days'})}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list}
                    </TableBody>
                </Table>
            );
        }
        return null;
    };

    return <div className='days-off'>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddADayOff}
        >{intl.formatMessage({id:'Add a day off'})}</Button>

        {getMemberDaysoff()}
    </div>;
};

export default withRouter(withCustomErrorBoundary(DaysOff));