import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import { downloadFile, getPath, getUserInfo } from '../utils';
import TableContainer from '../TableContainer';
import Unautorized from '../../Unautorized/Unautorized';
import TableCell from '@material-ui/core/TableCell';
import TaskEventCell from '../TaskEventCell';
import UserCell from '../UserCell';
import Chip from '@material-ui/core/Chip';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const RiskList = ({
    stateTasks,
    workspaceSelected,
    hasAutorization,
    dateFormat,
    childsParents,
    userTimezone
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills || {});
    const intl = useIntl();
    const [dataTablesData, setDataTablesData] = useState([]);
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl);
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);

    const updateBreakdowns = async (newBreakdowns) => {
        setBreakdownData(newBreakdowns);
    };


    useEffect( () => {
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
    }, [workspaceSelected]);


    const headCells = [
        { id: 'path',  label: 'Path' },
        { id: 'taskName',  label: 'Task Name' },
        { id: 'dueDate',type: 'date',  label: 'Due Date', },
        { id: 'expectedFinishDate', type: 'date', label: 'Expected At' },
        { id: 'earliestFinishDate', type: 'date', label: 'Earliest At' },
        { id: 'latestFinishDate',type: 'date',  label: 'Latest At' },
        { id: 'assignedTo', type:'array-users', label: 'Assigned To' },
    ];

    const handleExportAsCSV = ({name, data}) => {
        const columns = headCells.map(el =>  el.label).join(';');
        const content = data.map(el => {
            const row = headCells.reduce((acc, { id, type }) => {
                let field = el[id];

                if(type === 'array-users') {
                    field = field.map(user => user.name).join(',');
                }

                if(type === 'date') {
                    field =  el[id] !== 'N/A' ? moment(el[id]).format(dateFormat) : el[id];
                }

                return `${acc}${field};`;
            }, '');
            return row;
        });
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${columns}\r\n${content.join('\r\n')}`), `Risk-list${name}`);
    };

    useEffect(()=> {
        const newDataTablesData = [];
        if (stateTasks) {
            // get breakdowns =
            const breakdownBySkillEntry = breakdownData.find(el => el.value === 'skills');
            const breakdownByTaskEntry = breakdownData.find(el => el.value === 'tasks');
            const breakdownByUsersEntry = breakdownData.find(el => el.value === 'users');

            let workspaceTasks = {};
            if(breakdownByTaskEntry?.selectedData.length) {
                workspaceTasks = breakdownByTaskEntry.selectedData.map(el => {
                    return childsParents[el] ? ({source: el, childs: childsParents[el]}) : ({source: el, childs: [el]});
                });
            } else {
                workspaceTasks = [
                    {
                        source: workspaceSelected,
                        childs: workspaceSelected === 'root' ? Object.keys(stateTasks) : _.get(childsParents, workspaceSelected , [workspaceSelected])
                    }
                ];
            }

            const getTaskInfo = (task, id) => ({
                id: id,
                path: task.parent ? getPath(task.parent, stateTasks) : task.title,
                taskName: task.title || '',
                startDate: task.estimations?.startAt ||  'N/A',
                expectedFinishDate: task.estimations?.expectedAt ||  'N/A',
                dueDate: task.deadline ||  'N/A',
                earliestFinishDate: task.estimations?.earliestAt ||  'N/A',
                latestFinishDate: task.estimations?.expectedAt ||  'N/A',
                assignedTo: [],
                skill: task?.skill?.length && !_.isEmpty(accountSkills) ? task.skill.map(skillId => accountSkills[skillId]?.name || '').join(', ') : '',
                effort: `${task.minEffort}-${task.maxEffort}`,
            });

            workspaceTasks.forEach(taskEntry => {
                taskEntry.childs.forEach(taskId => {

                    const currentTask = stateTasks[taskId];
                    if(!currentTask || currentTask.status === 'done'){
                        return;
                    }
                    if(!currentTask.risks?.taskAtDanger  && !currentTask.risks?.taskAtRisk){
                        return;
                    }
                    // if not it means there is data.
                    const taskInfo = getTaskInfo(currentTask, taskId);
                    let key = workspaceSelected;
                    let name = workspaceSelected === 'root' ? '' : stateTasks[workspaceSelected].title;

                    let taskUsers = [];
                    if(currentTask.estimations?.userId){
                        taskUsers = (Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId])
                            .map(el => ({isForced: false, userId: el ,avatar: getUserInfo(accountTeamMembers[el])?.avatar, name: getUserInfo(accountTeamMembers[el]).displayName}));
                    } else if(currentTask.forcedUser) {
                        taskUsers = (Array.isArray(currentTask.forcedUser) ? currentTask.forcedUser : [currentTask.forcedUser]).map(el => ({isForced: true, userId: el,avatar: getUserInfo(accountTeamMembers[el])?.avatar, name: getUserInfo(accountTeamMembers[el])?.displayName }));
                    }
                    taskInfo.assignedTo.push(...taskUsers);

                    if (breakdownBySkillEntry && breakdownBySkillEntry.lvl === 0 && breakdownBySkillEntry.selectedData.length) {
                        if(!currentTask?.skill?.some(skillId => breakdownBySkillEntry.selectedData.includes(skillId))){
                            return false;
                        }
                        key = currentTask?.skill?.join('|');
                        name  = currentTask?.skill?.map(skillId => accountSkills[skillId]?.name || '').join(' ');

                        // if it is breakdown by task as well
                        if(breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length){

                            name  += ` ${stateTasks[taskEntry.source].title}`;

                            key += `|${taskEntry.source}`;
                        }
                    }

                    if (breakdownByUsersEntry && breakdownByUsersEntry.lvl === 0 && breakdownByUsersEntry.selectedData.length) {
                        const onlySelectedUsers = taskUsers.filter(el => breakdownByUsersEntry.selectedData.includes(el.userId));
                        if(!onlySelectedUsers.length) {
                            return  false;
                        }

                        onlySelectedUsers.forEach(el => {
                            let keyForUsers = el.userId;
                            let nameForUsers = el.name;

                            if(breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length){

                                nameForUsers  += ` ${stateTasks[taskEntry.source].title}`;

                                keyForUsers += `|${taskEntry.source}`;
                            }

                            const dataTableEntry = newDataTablesData.find(el => el.id === keyForUsers);

                            if (dataTableEntry) {
                                dataTableEntry.data.push({ ...taskInfo, assignedTo: [el] });
                            } else {
                                newDataTablesData.push({ name: nameForUsers, id: keyForUsers, data: [{ ...taskInfo, assignedTo: [el] }]});
                            }
                        });
                        return;
                    }

                    if (breakdownByTaskEntry && breakdownByTaskEntry.lvl === 0 && breakdownByTaskEntry.selectedData.length) {
                        key = taskEntry.source;
                        name  = stateTasks[taskEntry.source].title;
                        if (breakdownBySkillEntry && breakdownBySkillEntry.selectedData.length) {
                            key += currentTask?.skill?.join('|');
                            name += currentTask?.skill?.map(skillId => accountSkills[skillId]?.name || '').join(' ');
                        }

                        if (breakdownByUsersEntry && breakdownByUsersEntry.selectedData.length) {
                            const onlySelectedUsers = taskUsers.filter(el => breakdownByUsersEntry.selectedData.includes(el.userId));
                            if(!onlySelectedUsers.length) {
                                return  false;
                            }

                            onlySelectedUsers.forEach(el => {
                                name  += ` ${el.name || ''}`;
                                key += `|${el.userId}`;

                                const dataTableEntry = newDataTablesData.find(el => el.id === el.userId);

                                if (dataTableEntry) {
                                    dataTableEntry.data.push({ ...taskInfo, assignedTo: [el] });
                                } else {
                                    newDataTablesData.push({ name, id: key, data: [{ ...taskInfo, assignedTo: [el] }]});
                                }
                            });

                            return;
                        }
                    }

                    // no breakdowns = taskInfo
                    const dataTableEntry = newDataTablesData.find(el => el.id === key);

                    if (dataTableEntry) {
                        dataTableEntry.data.push(taskInfo);
                    } else {
                        newDataTablesData.push({ name, id: key, data: [taskInfo]});
                    }
                });
            });
            setDataTablesData(newDataTablesData);
        }

    }, [stateTasks, workspaceSelected, breakdownData]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => Object.keys(accountTeamMembers), [accountTeamMembers]);
    const availableSkills = React.useMemo(() => Object.keys(accountSkills), [accountSkills]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ?
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    if (!hasAutorization) {
        return <Unautorized />;
    }

    const renderRow =  (idx, row) => [
        <TableCell key={`${row.id}-cell-path`} align="left">{row.path}</TableCell>,
        <TableCell key={`${row.id}-cell-name`} align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>,
        <TableCell key={`${row.id}-cell-dueDate`} align="left">{row.dueDate !== 'N/A' ? moment.tz(row.dueDate, 'YYYY-MM-DD', userTimezone).format(dateFormat) : row.dueDate }</TableCell>,
        <TableCell key={`${row.id}-cell-expected`} align="left">{row.expectedFinishDate !== 'N/A' ? moment.tz(row.expectedFinishDate, 'X', userTimezone).format(dateFormat) : row.expectedFinishDate }</TableCell>,
        <TableCell key={`${row.id}-cell-earliest`} align="left">{row.earliestFinishDate !== 'N/A' ? moment.tz(row.earliestFinishDate, 'X', userTimezone).format(dateFormat) : row.earliestFinishDate }</TableCell>,
        <TableCell key={`${row.id}-cell-latest`} align="left">{row.latestFinishDate !== 'N/A' ? moment.tz(row.latestFinishDate, 'X', userTimezone).format(dateFormat) : row.latestFinishDate }</TableCell>,
        <TableCell key={`${row.id}-cell-users`} className={'users-cell'} align="left">
            { !row.assignedTo.length && 'N/A' }
            {
                [...row.assignedTo].slice(0,3).map(el =>  <UserCell key={el.userId} userId={el.userId} color={el.color} avatarPath={el.avatar} name={el.name}  />)
            }
            {row.assignedTo.length > 3 && <Chip className="plus-chip" label={`+${row.participants.length - 3}`} disabled />}
        </TableCell>,
    ];

    return <>
        <Paper>
            <div className="breakdownsContainer">
                <BreakdownController
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    tasks={subItensTasks}
                    teamMembers={availableTeamMembers}
                    skills={availableSkills}
                    enabled={dataTablesData.length}
                    workspaceSelected={workspaceSelected}
                />
            </div>
        </Paper>
        <Paper>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                    dataTablesData.length ?
                        dataTablesData.map(el =>
                            <TableContainer
                                defaultOrder='desc'
                                defaultSort='dueDate'
                                headCells={headCells}
                                renderRow={renderRow}
                                exportAsCSV={handleExportAsCSV} key={el.id} {...el}
                            />)
                        : <div className={'empty'}>No Project/Task at risk found</div>
                }
            </div>
        </Paper>
    </>;
};

RiskList.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    dateFormat: Proptypes.string.isRequired,
    hasAutorization: Proptypes.bool.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(RiskList);