import React from 'react';
import Drafts from '@material-ui/icons/Drafts';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Email from '@material-ui/icons/Email';
import _ from 'underscore';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTasks } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { databaseBatchUpdate, getTaskPath } from '../../utils/utils';
import { notificationsPath } from '../../utils/databaseQuerys/notifications';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'rc-tooltip';

const NotificationInfo = ({ taskId, notifications }) => {
    const accountTasks = useSelector(getAccountTasks);
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);
    const firstNot = notifications[0];
    const task = _.get(accountTasks, [taskId], firstNot.taskData);
    const taskPath = getTaskPath({
        location: { query: { parentId: firstNot.parent } },
        account: {
            tasks: accountTasks,
        },
    });
    const taskName = task.title;

    const allNotificationsReaded = !notifications.find((notification) => !notification.readed);

    const markTaskAsRead = (e) => {
        e.stopPropagation(e);

        const updates = notifications.reduce((acc, notification) => {
            return {
                ...acc,
                [`${notificationsPath({ accountId: currentAccountId, userId: currentUserId})}/${notification.id}/readed`]: !allNotificationsReaded
            };
        }, {});
        databaseBatchUpdate(updates);
        
    };
    
    return (
        <div data-opensidebar="true" data-field="details" className="Card-Header">
            {
                taskPath  && <div className="Path-Container">
                    <FolderOpen />
                    <span>{taskPath}</span>{' '}
                </div>
            }
            <div className="Card-Title-Container">
                <div className="">
                    <TaskStatusIcon
                        className="NotificationStatus"
                        taskId={taskId}
                        delay={0}
                        classList="taskStatus"
                        status={task.status}
                    />
                </div>
                <div className="Task-title">{taskName}</div>
            </div>
            {!allNotificationsReaded && <Tooltip placement="right" overlay="Mark as read" className="avatar">
                <IconButton aria-label="close" className="Mark-as-read" onClick={markTaskAsRead}>
                    <Email />
                </IconButton>
            </Tooltip>
            }
            {allNotificationsReaded && <Tooltip placement="right" overlay="Mark as unread" className="avatar">
                <IconButton aria-label="close" className="Mark-as-read" onClick={markTaskAsRead}>
                    <Drafts />
                </IconButton>
            </Tooltip>}
        </div>
    );
};

export default React.memo(NotificationInfo);
