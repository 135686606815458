import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { 
    CLOUD_FUNCTIONS
} from './constants';

export const createChargeBeeCustomer = ({ accountId, email })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.chargebeeCreateCustomerManual,
    params: { accountId, email }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const generateChargebeeCheckoutQuote = ({ qid })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.chargebeeCheckoutQuote,
    params: { qid }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const generateChargebeeCheckoutNew = ({ chargebeeCustomerId, planPeriodicity, planCurrency, planNbResources, workspaceId })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.chargebeeCheckoutNew,
    params: { chargebeeCustomerId, planPeriodicity, planCurrency, planNbResources, workspaceId }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const addUserToSubscription = ({ chargebeeCustomerId, nbUsers })  => axios.request({
    method: 'GET',
    url: CLOUD_FUNCTIONS.addUserToSubscription,
    params: { chargebeeCustomerId, nbUsers }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const removeUserToSubscription = ({ chargebeeCustomerId, nbUsers }) => axios.request({
    method: 'GET',
    url: CLOUD_FUNCTIONS.removeUserToSubscription,
    params: { chargebeeCustomerId, nbUsers }
}).catch((error) => {
    Bugsnag.notify(error);
});

export const chargebeeUpgradePlan = ({ chargebeeCustomerId, nbResources, currency, periodicity })  => axios.request({
    method: 'GET',
    url: CLOUD_FUNCTIONS.chargebeeUpgradePlan,
    params: { chargebeeCustomerId, nbResources, currency, periodicity }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});