import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { sortByDate } from '../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TaskEventCell from '../TaskEventCell';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const getTaskInfo = (task, taskId) => ({
    id: taskId,	
    taskName: task.title || 'N/A',
    doneAt: task.doneAt,
});

const RecentlyDoneTasksOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
    dateFormat,
    userTimezone
}) => {

    const tasks = React.useMemo(()=> {
        if (stateTasks) {
            let workspaceTasks =  workspaceSelected === 'root' ? 
                Object.keys(stateTasks) : _.get(childsParents, workspaceSelected , [workspaceSelected]);

          
            return workspaceTasks
                .filter(taskId => {
                    const currentTask = stateTasks[taskId];
                    if(!currentTask || currentTask.status !== 'done' || currentTask.childrens){
                        return false;
                    }
                    return true;
                })
                .map(el => getTaskInfo(stateTasks[el],el));
        }

    }, [stateTasks, workspaceSelected, childsParents]);
    const path = window.location.pathname.split('/').slice(0,3).join('/');


    return <Card>
        <CardHeader 
            title={<span> <b> Recently Done Tasks</b></span> }
            action={
                <Button component={Link}  size="small" variant="outlined" color="primary"
                    key="recently-done-tasks" 
                    to={`${path}/recently-done-tasks`}
                >
                    See All <b>Recently Done Tasks</b>
                </Button>
            }
        />
        <CardContent>
            {
                tasks.length ? 
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Done At</TableCell>
                                <TableCell>Task Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.sort((a,b) => -sortByDate(a,b,'doneAt')).slice(0,5).map((row) => (
                                <TableRow key={`RecentlyDoneTasksOverview-${row.id}-${row.doneAt}`}>
                                    <TableCell align="left">{row.doneAt !== 'N/A' ? moment.tz(row.doneAt, 'YYYY-MM-DD', userTimezone).format(dateFormat) : row.doneAt }</TableCell>
                                    <TableCell align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className='empty  small'>No Data</div>
            }
        </CardContent>
    </Card>;
};

RecentlyDoneTasksOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    dateFormat: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default RecentlyDoneTasksOverview;