import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import _ from 'underscore';
import Button from '@material-ui/core/Button';

import * as firebaseEvents from '../../utils/firebaseEvents';
import { getAccountBasePermissions, getAccountId, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { taskBasePermissionsTypes } from './constants';
import PermissionsBaseAutocomplete from './components/PermissionsBaseAutocomplete';
import { FormControlLabel, Switch } from '@material-ui/core';

const PermissionsBase = () => {
    const intl = useIntl();
    const [workspaceAdmins, setWorkspaceAdmins] = useState([]);
    const [owners, setOwners] = useState([]);
    const [managers, setManagers] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [viewers, setViewers] = useState([]);

    const [canWorkersCreateTask, setCanWorkersCreateTask] = useState(true);
    const [canWorkersChangeEffort, setCanWorkersChangeEffort] = useState(true);

    const accountBasePermissions = useSelector(getAccountBasePermissions);
    const users = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);

    const handleUpdateOwners = React.useCallback((newOwnersIds) => {
        const updatedManagersIds = _.filter(managers, (managerId) => !newOwnersIds.includes(managerId));
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newOwnersIds.includes(viewerId));

        setOwners(newOwnersIds);
        setManagers(updatedManagersIds);
        setViewers(updatedViewersIds);
    }, [managers, viewers]);

    const handleUpdateManagers = React.useCallback((newManagersIds) => {
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newManagersIds.includes(viewerId));

        setManagers(newManagersIds);
        setViewers(updatedViewersIds);
    }, [viewers]);

    const handleUpdateWorkers = React.useCallback((newWorkersIds) => {
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newWorkersIds.includes(viewerId));

        setWorkers(newWorkersIds);
        setViewers(updatedViewersIds);
    }, [viewers]);

    const handleUpdateViewers = React.useCallback((newViewersIds) => {
        const updatedManagersIds = _.filter(managers, (managerId) => !newViewersIds.includes(managerId));
        const updatedOwnersIds = _.filter(owners, (ownerId) => !newViewersIds.includes(ownerId));

        setViewers(newViewersIds);
        setManagers(updatedManagersIds);
        setOwners(updatedOwnersIds);
    }, [managers, owners]);

    const handleChangePermissions = React.useCallback(({ usersIds, type, actionType }) => {
        const removeByPermissionType = {
            owners: () => setOwners(_.filter(owners, (id) => usersIds.includes(id))),
            managers: () => setManagers(_.filter(managers, (id) => usersIds.includes(id))),
            workers: () => setWorkers(_.filter(workers, (id) => usersIds.includes(id))),
            viewers: () => setViewers(_.filter(viewers, (id) => usersIds.includes(id))),
        };

        const updateByPermissionType = {
            owners: () => handleUpdateOwners(usersIds),
            managers: () => handleUpdateManagers(usersIds),
            workers: () => handleUpdateWorkers(usersIds),
            viewers: () => handleUpdateViewers(usersIds),
        };

        if (actionType === 'remove-option') {
            removeByPermissionType[type]();
            return;
        }

        updateByPermissionType[type]();
    }, [
        handleUpdateOwners,
        handleUpdateManagers,
        handleUpdateWorkers,
        handleUpdateViewers,
        owners,
        managers,
        workers,
        viewers
    ]);

    const handleSave = React.useCallback(() => {
        toastr.confirm(intl.formatMessage({ id: 'permissions.base.confirm.save' }), {
            onOk: () => {
                if (!users) return;

                const data = {
                    owners,
                    managers,
                    workers,
                    viewers,
                    comingFrom: 'base',
                    canWorkersCreateTask,
                    canWorkersChangeEffort,
                };

                // Check if they exist before adding
                data.owners = _.filter(data.owners, (userId) => !!users[userId]);
                data.managers = _.filter(data.managers, (userId) => !!users[userId]);
                data.workers = _.filter(data.workers, (userId) => !!users[userId]);
                data.viewers = _.filter(data.viewers, (userId) => !!users[userId]);

                firebaseEvents.addChallenge('changeBasePermissions');
                firebaseEvents.setPermissions(accountId, 'base', data, 'base', intl);
            },
            okText: intl.formatMessage({ id: 'toastr.confirm.save' }),
            cancelText: intl.formatMessage({ id: 'toastr.confirm.cancel' }),
        });
    }, [
        users,
        workspaceAdmins,
        owners,
        managers,
        workers,
        viewers,
        canWorkersCreateTask,
        canWorkersChangeEffort,
    ]);

    useEffect(() => {
        if (users && accountId) {
            const adminsIds = _.filter(users, (user) => user.permissions.admin).map((user) => user.userId) || [];
            const ownersIds = _.uniq([...accountBasePermissions?.owners || [], ...adminsIds]);
            const managersIds = accountBasePermissions?.managers || [];
            const workersIds = accountBasePermissions?.workers || [];
            const viewersIds = accountBasePermissions?.viewers || [];
            const canWorkersCreateTask = accountBasePermissions?.canWorkersCreateTask ?? true;
            const canWorkersChangeEffort = accountBasePermissions?.canWorkersChangeEffort ?? true;

            setOwners(ownersIds);
            setManagers(managersIds);
            setWorkers(workersIds);
            setViewers(viewersIds);
            setWorkspaceAdmins(adminsIds);

            setCanWorkersCreateTask(canWorkersCreateTask);
            setCanWorkersChangeEffort(canWorkersChangeEffort);
        }
    }, [users, accountId, accountBasePermissions]);

    const autocompleteStateOptions = {
        owners,
        managers,
        workers,
        viewers,
    };

    const PermissionComponent = ({ type }) => {
        const Component = () => (
            <PermissionsBaseAutocomplete
                basePermissionType={type}
                onChange={handleChangePermissions}
                currentValues={autocompleteStateOptions[type]}
                owners={owners}
                managers={managers}
                workers={workers}
                viewers={viewers}
            />
        );

        if (type === 'workers') {
            return (
                <>
                    <Component />
                    <FormControlLabel
                        className="workers-checkbox"
                        control={(
                            <Switch
                                size="small"
                                checked={canWorkersCreateTask}
                                onChange={(_, checked) => setCanWorkersCreateTask(checked)}
                                color="secondary"
                            />
                        )}
                        label="Workers can create tasks"
                    />
                    <FormControlLabel
                        className="workers-checkbox"
                        control={(
                            <Switch
                                size="small"
                                checked={canWorkersChangeEffort}
                                onChange={(_, checked) => setCanWorkersChangeEffort(checked)}
                                color="secondary"
                            />
                        )}
                        label="Workers can change efforts"
                    />
                </>
            );
        }

        return <Component />;
    };

    return (
        <div className="PermissionsBase">
            {taskBasePermissionsTypes.map((type) => <PermissionComponent key={type} type={type}/>)}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
            >
                {intl.formatMessage({ id: 'Save base permissions' })}
            </Button>
        </div>
    );
};

export default memo(withCustomErrorBoundary(PermissionsBase));
