import { toastr } from 'react-redux-toastr';
import _ from 'underscore';

import { SLACK_REDIRECT_URL } from '../../utils/constants';
import { getAuthToken } from '../../utils/calendarIntegration';
import { getAuthUrl } from '../../utils/services/syncApp';
import { getAccountDataSyncApps } from '../../utils/selectors/accountData';
import { getAccountTasks } from '../../utils/selectors/account';
import { getParentChildIds } from '../../utils/selectors/app';
import { getState } from '../../configureStore';

export const GUIDE_INTEGRATIONS_BASE_LINK = 'https://stonly.com/guide/en';
export const GUIDE_INTEGRATIONS_LINK_BY_TOOL = {
    'clickup': '/clickup-integration-planless-xrkFqlRvf3/Steps/1597394',
    'jira': '/jira-integration-planless-7YD5ppEBK0/Steps/1603887',
    'jiraServer': '/synchronize-jira-server-projects-baEV1VbEt8/Steps/',
    'asana': '/synchronize-asana-projects-3ksMZTp1bE/Steps/1794236',
};
export const SIRV_INTEGRATIONS_LOGOS_URL = 'https://planless.sirv.com/App/integrations';

export const SYNC_APP_TYPE = {
    'JIRA': 'jira',
    'CLICKUP': 'clickup',
    'ASANA': 'asana',
    'TRELLO': 'trello',
    JIRASERVER: 'jiraServer',
    BASECAMP: 'basecamp',
};

const CLICKUP_TASK_LINK = 'https://app.clickup.com/t';
const JIRA_TASK_LINK = null;
const TRELLO_TASK_LINK = null;

export const TOOL_SOURCE_LINKS = {
    [SYNC_APP_TYPE.CLICKUP]: CLICKUP_TASK_LINK,
    [SYNC_APP_TYPE.JIRA]: JIRA_TASK_LINK,
    [SYNC_APP_TYPE.TRELLO]: TRELLO_TASK_LINK,
};
export const SYNC_APP_TYPES = Object.values(SYNC_APP_TYPE);
export const SYNC_CALENDAR_APP_TYPES = ['google', 'microsoft'];
export const SYNC_APP_NAMES = {
    'clickup': 'ClickUp',
    'jira': 'Jira',
    'jiraServer': 'Jira Server',
    'asana': 'Asana',
    'trello': 'Trello',
    'google-calendar': 'Google Calendar',
    'microsoft-calendar': 'Microsoft Calendar',
    'slack':'Slack',
    basecamp: 'Basecamp',
};
export const SYNC_APP_DESCRIPTION = {
    'clickup':'Synchronize your projects from ClickUp, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    'jira':'Synchronize your projects from Jira, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    'jiraServer':'Synchronize your projects from Jira Server, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    'asana':'Synchronize your projects from Asana, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    'trello':'Synchronize your boards from Trello, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    basecamp:'Synchronize your projects from Basecamp, and always ensure the best planning possible. Your team keeps working as usual while you gain planning assistance, complete visibility, and have it constantly updated.',
    'google-calendar':'Connect your Google Calendar to have your work plan between your meetings and a realistic workload allocation. Planless will also take into account meetings and everyday work life.',
    'microsoft-calendar': 'Connect your Outlook Calendar to have your work plan between your meetings and a realistic workload allocation. Planless will also take into account meetings and everyday work life.',
    'slack':'Get updates with Planless notifications directly on your Slack workspace and never miss any updates. Quickly connect and manage how and where you want to receive it.',
};
export const connectivityButtonLabel = {
    'Connected': 'Settings',
    'Available': 'Connect',
    'Error': 'Reconnect',
};

export const connnectSlackUrl = () => {
    window.location.replace(
        `https://slack.com/oauth/v2/authorize
        ?client_id=${process.env.SLACK_CLIENT_ID}
        &scope=incoming-webhook,chat:write,channels:join,groups:write
        &redirect_uri=${SLACK_REDIRECT_URL}`
    );
};

export const connectGoogleCalendar = async (userId, accountId) => {
    try {
        const data = await getAuthToken({userId, accountId, scope: 'google'});
        window.location.replace(data.data);
    } catch (err) {
        toastr.error('Something went wrong. Please try again');
    }
};

export const connectMicrosoftCalendar = async (userId, accountId) => {
    try {
        const data = await getAuthToken({userId, accountId, scope: 'microsoft'});
        window.location.replace(data.data);
    } catch (err) {
        toastr.error('Something went wrong. Please try again');
    }
};

export const connectSyncTool = async ({ type, currentUserId, accountId, appData }) => {
    try {
        const redirectUri = await getAuthUrl({ type, currentUserId, accountId, appData });
        window.location.href = redirectUri.data;
    } catch (err) {
        toastr.error('Something went wrong. Please try again');
    }
};

export const shouldBlockSyncListReorder = ({ taskChanged, parentDest, parentOrigin, isTaskSyncProject}) => {
    if(parentDest === parentOrigin) return false;

    const syncApps = getAccountDataSyncApps(getState());
    const parentChildIds = getParentChildIds(getState());
    const tasks = getAccountTasks(getState());

    const parentDestTask = tasks[parentDest];
    const taskChangedData = tasks[taskChanged];
    const taskBeginDragIsConnectProject = tasks[taskChanged]?.isConnectProject && tasks[taskChanged]?.sourceId;
    const parentsOfNewLocation = [parentDest, ..._.get(parentChildIds, ['parents', parentDest], [])].map(parentId=>tasks[parentId]);
    const folderDestSyncProject = parentsOfNewLocation.find(parent=> parent?.isConnectProject);
    const syncAppOfDest = _.get(syncApps, [folderDestSyncProject?.syncAppId], null);

    const syncFolderOfDest = _.find(syncAppOfDest?.syncFolder, syncList => syncList.sourceId === folderDestSyncProject?.sourceId) || null;

    if(!isTaskSyncProject && !folderDestSyncProject && !parentDestTask?.sourceId) {
        return false;
    }

    if(taskBeginDragIsConnectProject && !folderDestSyncProject) {
        return false;
    }

    if(isTaskSyncProject && !_.isNull(parentOrigin) && parentOrigin !== parentDest) {
        return true;
    }

    if(isTaskSyncProject && !_.isNull(parentOrigin) && !_.isNull(parentDest) && parentOrigin !== parentDest) {
        return true;
    }

    if(!!folderDestSyncProject || !!parentDestTask?.sourceId) {
        if(parentsOfNewLocation.length >=3) {
            return true;
        }

        if(parentsOfNewLocation.length > 1 && !syncFolderOfDest?.importSubtasks) {
            return true;
        }

        if(!syncFolderOfDest?.importSubtasks && !!taskChangedData?.childrens) {
            return true;
        }

        if(!!taskChangedData?.childrens && taskChangedData?.childrens.map(child=>tasks[child?.id]).find(child=>child?.childrens)) {
            return true;
        }

        if(syncFolderOfDest?.importSubtasks && taskChangedData?.childrens && parentsOfNewLocation.length === 2) {
            return true;
        }
    }
    return false;
};

export const getAppTypeFromQuery = (query) => {
    const app = _.get(query, 'app', null);
    const state = _.get(query, 'state', 'clickup');

    if(state.includes('scope')) {
        return _.get(JSON.parse(state), 'scope', null);
    }

    return app ? app : state;
};