import { ADD_TASK_LIST_LEVEL } from '../utils';

export const getBasecampInfo = ({ task, parentsCount, isInsideSyncFolder, importSubtasks }) => {
    const canAddTask = () => {
        const isTodoGroup = parentsCount === 4;
        const isTodo = parentsCount === 5;
        const isLastLevel = importSubtasks ? isTodo : isTodoGroup;
        const isEligibleSourceType = ['Todolist', 'Todoset'].includes(task?.sourceType);

        return isEligibleSourceType || (!task?.sourceType && isInsideSyncFolder && !isLastLevel);
    };

    const getAddTaskListLevel = () => {
        const isTodoset = parentsCount === 2;
        const isTodoList = parentsCount === 3;

        return isTodoset ? ADD_TASK_LIST_LEVEL.THREE : isTodoList ? ADD_TASK_LIST_LEVEL.TWO : ADD_TASK_LIST_LEVEL.ONE;
    };

    return {
        canAddSyncAppTask: canAddTask(),
        hasDependencies: false,
        hasSourceTags: false,
        hasSourceStatus: false,
        addTaskListLevel: getAddTaskListLevel(),
    };
};
