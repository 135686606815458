import React, { memo } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TeamIcon from '@material-ui/icons/Group';
import SkillsIcon from '@material-ui/icons/School';
import AccountIcon from '@material-ui/icons/AccountBalanceWallet';
import DaysoffIcon from '@material-ui/icons/Today';
import BoardIcon from '@material-ui/icons/ViewColumn';
import PermissionsIcon from '@material-ui/icons/Security';
import BasePermissionsIcon from '@material-ui/icons/VerifiedUser';
import { Divider, Typography } from '@material-ui/core';
import PermissionsByType from '../PermissionsByType';
import PermissionsBase from '../../../PermissionsBase/PermissionsBase';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { ManageSkillsPermission } from '../ManageSkillsPermission';
var PermissionSection = function (_a) {
    var permissionType = _a.permissionType, sectionTitle = _a.sectionTitle;
    var t = useInternalization().t;
    var iconByType = {
        admin: React.createElement(PermissionsIcon, null),
        team: React.createElement(TeamIcon, null),
        skills: React.createElement(SkillsIcon, null),
        daysoff: React.createElement(DaysoffIcon, null),
        boards: React.createElement(BoardIcon, null),
        subscription: React.createElement(AccountIcon, null),
        base: React.createElement(BasePermissionsIcon, null),
        memberSkills: React.createElement(SkillsIcon, null),
    };
    var componentByType = {
        admin: React.createElement(PermissionsByType, { permissionType: permissionType }),
        team: React.createElement(PermissionsByType, { permissionType: permissionType }),
        skills: React.createElement(PermissionsByType, { permissionType: permissionType }),
        daysoff: React.createElement(PermissionsByType, { permissionType: permissionType }),
        boards: React.createElement(PermissionsByType, { permissionType: permissionType }),
        subscription: React.createElement(PermissionsByType, { permissionType: permissionType }),
        base: React.createElement(PermissionsBase, null),
        memberSkills: React.createElement(ManageSkillsPermission, null),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ExpansionPanel, { elevation: 0, defaultExpanded: (permissionType === 'admin') },
            React.createElement(ExpansionPanelSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                React.createElement(Typography, { variant: "h6" },
                    iconByType[permissionType],
                    t(sectionTitle))),
            React.createElement(Divider, null),
            React.createElement(ExpansionPanelDetails, { className: "sectionContent" },
                React.createElement(Typography, { variant: "body2", component: "div" },
                    t("settings.permissions.".concat(permissionType, ".text")),
                    React.createElement("div", { className: "userList" }, componentByType[permissionType]))))));
};
export default memo(withCustomErrorBoundary(PermissionSection));
