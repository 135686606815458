import React from 'react';
import Proptypes from 'prop-types';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const UserCell = ({ name, userId }) => {
    return   <div key={userId} className={'user-cell'}>
        <StateUserAvatar
            className="avatar"
            userId={userId}
        />
        {name || '(deleted user)'}
    </div>;
};

UserCell.propTypes = {
    userId: Proptypes.string,
    name: Proptypes.string,
    avatarPath: Proptypes.string,
    color: Proptypes.string,
};

export default UserCell;