import React from 'react';
import { Router, Route, IndexRedirect, browserHistory } from 'react-router';
import { compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducersExport';
import { syncHistoryWithStore } from 'react-router-redux';
import { actionsIgnore } from './utils/middlewares';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';
import ReduxToastr from 'react-redux-toastr';

import App from './views/App/App';
import StructOff from './views/StructOff/StructOff';
import StructOn from './views/StructOn/StructOn';
import StructTasks from './views/StructTasks/StructTasks';
import NotFound from './views/NotFound/NotFound';
import OverviewOffer from './views/OverviewOffer/OverviewOffer';
import Integrations from './views/Integrations/Integrations';
import SyncToolPage from './views/Integrations/components/SyncToolPage/SyncToolPage';

import Tasks from './views/Tasks/Tasks';
import List from './views/List/List';
import Settings from './views/Settings/Settings';
import Login from './views/Login/Login';
import CreateAccount from './views/CreateAccount/CreateAccount';
import CreateAccountA from './views/CreateAccountA/CreateAccountA';
import CreateAccountB from './views/CreateAccountB/CreateAccountB';
import AuthAction from './views/AuthAction/AuthAction';
import MyAccount from './views/MyAccount/MyAccount';
import TeamMembers from './views/TeamMembers/TeamMembers';
import Skills from './views/Skills/Skills';
import Account from './views/Account';
import SettingsPermissions from './views/SettingsPermissions/SettingsPermissions';
import EditTeamMember from './views/EditTeamMember/EditTeamMember';
import EditSkill from './views/EditSkill/EditSkill';
import AddMemberDayOff from './views/AddMemberDayOff/AddMemberDayOff';
import BackOfficeQuotes from './views/BackOfficeQuotes/BackOfficeQuotes';
import AddAccountDayOff from './views/AddAccountDayOff/AddAccountDayOff';
import SendMemberInvite from './views/SendMemberInvite/SendMemberInvite';
import Daysoff from './views/Daysoff/Daysoff';
import ResetPassword from './views/ResetPassword/ResetPassword';
import TaskDetail from './views/TaskDetail/TaskDetail';
import AddWorkTime from './views/AddWorkTime/AddWorkTime';
import AddTaskList from './views/AddTaskList/AddTaskList';
import DuplicateTask from './views/DuplicateTask/DuplicateTask';
import BoardsSettings from './views/BoardsSettings/BoardsSettings';
import TagsSettings from './views/TagsSettings/TagsSettings';
import CustomFieldsSettings from './views/CustomFieldsSettings/CustomFieldsSettings';
import Board from './views/Board/Board';
import Calendar from './views/Calendar/Calendar';
import BoardStartWorking from './views/BoardStartWorking/BoardStartWorking';
import Workload from './views/Workload/Workload';
import WorkspaceSettings from './views/WorkspaceSettings/WorkspaceSettings';
import FinancialSettings from './views/FinancialSettings/FinancialSettings';
import ConnectSlack from './views/ConnectSlack/ConnectSlack';
import Permissions from './views/Permissions/Permissions';
import ApiKeys from './views/ApiKeys/ApiKeys';
import Import from './views/Import/Import';
import Dashboard from './views/Dashboard/Dashboard';
import Notifications from './views/Notifications/Notifications';
import Chat from './views/Chat/Chat';
import ChatChannel from './views/ChatChannel/ChatChannel';
import ChatSearch from './views/ChatSearch/ChatSearch';
import TaskHistory from './views/TaskHistory/TaskHistory';
import ConnectCalendar from './views/ConnectCalendar/ConnectCalendar';
import UserEvents from './views/UserEvents/UserEvents';
import SelectWorkspace from './views/SelectWorkspace/SelectWorkspace';
import CreateWorkspace from './views/CreateWorkspace/CreateWorkspace';
import BudgetManagementDialog from './views/BudgetManagementDialog/BudgetManagementDialog';
import FirstSteps from './views/FirstSteps/FirstSteps';
import AddTaskModal from './views/AddTaskModal/AddTaskModal';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import versionJson from '../version.json';

// Reports
import Reports from './views/Reports/Reports';

var createStoreWithMiddleware, prevUrl;

if(process.env.NODE_ENV !== 'production'){
    /* eslint-disable no-underscore-dangle */
    if(window.__REDUX_DEVTOOLS_EXTENSION__){
        createStoreWithMiddleware = compose(
            applyMiddleware(thunk, actionsIgnore),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )(createStore);
    }
    else {
        createStoreWithMiddleware = compose(
            applyMiddleware(thunk, actionsIgnore)
        )(createStore);
    }
    /* eslint-enable */
}
else {
    createStoreWithMiddleware = compose(
        applyMiddleware(thunk, actionsIgnore)
    )(createStore);
}

const store = createStoreWithMiddleware(rootReducer);
const history = syncHistoryWithStore(browserHistory, store);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducersExport', () => {
        const nextRootReducer = require('./reducersExport');
        store.replaceReducer(nextRootReducer);
    });
}

Bugsnag.start({
    apiKey: 'db5f18b961f31e45eeabab4f94562631',
    appVersion: versionJson.version,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
        event.setUser(
            getAppData().user?.data?.uid,
            getAppData().user?.data?.email,
            getAppData().user?.data?.userId,
        );
        event.addMetadata('account', {
            accountId: getAppData().account.settings.id
        });
    }
});

// Wrap your entire app tree in the ErrorBoundary provided
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);


export function getAppData(){
    return store.getState()?.app;
}

export function getState(){
    return store.getState();
}

export function getRouting(){
    return store.getState()?.routing;
}

const Store = () => {
    const UserIsAuthenticated = connectedRouterRedirect({
        authenticatedSelector: state => !!(state.app.user && state.app.user.email),
        redirectPath: '/login',
        allowRedirectBack: true
    });

    const onChangeRoute = () => {
        if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
            window.analytics.page();
            if(window.stonlyTrack){
                window.stonlyTrack('track', 'pageview');
            }
            if(window.StonlyWidget){
                window.StonlyWidget('refetchConfig');
            }
        }

        if (
            document.location.pathname &&
            prevUrl &&
            document.location.pathname.indexOf(prevUrl) === -1 &&
            prevUrl.indexOf(document.location.pathname) === -1
        ) {
            if(
                !(
                    document.location.pathname.indexOf('/tasks/') !== -1 &&
                    prevUrl.indexOf('/tasks/') !== -1
                )
            ){
                window.scrollTo(0,0);
            }
        }

        prevUrl = document.location.pathname;
    };

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <div className="Store">
                    <Router history={history} onUpdate={onChangeRoute}>
                        <Route path="/" component={App}>
                            <Route component={UserIsAuthenticated(StructOn)}>
                                <IndexRedirect to="/dashboard" />
                                <Route path='first-steps' component={FirstSteps} />
                                <Route path='backoffice/quotes' component={BackOfficeQuotes} />
                                <Route path='dashboard' component={Dashboard}>
                                    <Route path=':uid' component={TaskDetail}>
                                        <Route path='addWorkTime' component={AddWorkTime} />
                                        <Route path='duplicate' component={DuplicateTask} />
                                        <Route path='permissions' component={Permissions} />
                                        <Route path='history' component={TaskHistory} />
                                    </Route>
                                    <Route path='userEvents/:uid' component={UserEvents} />
                                </Route>
                                <Route path='insights/:taskId/:mode' component={Reports} >
                                    <Route path='userEvents/:uid' component={UserEvents} />
                                    <Route path='task/:uid' component={TaskDetail}>
                                        <Route path='budget' component={BudgetManagementDialog} />
                                        <Route path='addWorkTime' component={AddWorkTime} />
                                        <Route path='duplicate' component={DuplicateTask} />
                                        <Route path='permissions' component={Permissions} />
                                        <Route path='history' component={TaskHistory} />
                                    </Route>
                                </Route>
                                <Route path='notifications' component={Notifications}>
                                    <Route path=':uid' component={TaskDetail}>
                                        <Route path='addWorkTime' component={AddWorkTime} />
                                        <Route path='duplicate' component={DuplicateTask} />
                                        <Route path='permissions' component={Permissions} />
                                        <Route path='history' component={TaskHistory} />
                                    </Route>
                                </Route>

                                <Route path='workspaces' component={SelectWorkspace}/>
                                <Route path='create-workspace' component={CreateWorkspace} />
                                <Route component={StructTasks}>
                                    <Route path='tasks' component={Tasks}>
                                        <Route path='addTask' component={AddTaskModal} />
                                        <Route path='addList' component={AddTaskList} />
                                        <Route path='duplicate/:uid' component={DuplicateTask} />
                                        <Route path=':uid' component={TaskDetail}>
                                            <Route path='budget' component={BudgetManagementDialog} />
                                            <Route path='addWorkTime' component={AddWorkTime} />
                                            <Route path='duplicate' component={DuplicateTask} />
                                            <Route path='permissions' component={Permissions} />
                                            <Route path='history' component={TaskHistory} />
                                        </Route>
                                    </Route>
                                    <Route path='list' component={List}>
                                        <Route path='addTask' component={AddTaskModal} />
                                        <Route path='duplicate/:uid' component={DuplicateTask} />
                                        <Route path=':uid' component={TaskDetail}>
                                            <Route path='addWorkTime' component={AddWorkTime} />
                                            <Route path='duplicate' component={DuplicateTask} />
                                            <Route path='permissions' component={Permissions} />
                                            <Route path='history' component={TaskHistory} />
                                        </Route>
                                    </Route>
                                    <Route path='board' component={Board}>
                                        <Route path='addTask' component={AddTaskModal} />
                                        <Route path='startWorking/:uid/:boardid' component={BoardStartWorking} />
                                        <Route path='duplicate/:uid' component={DuplicateTask} />
                                        <Route path=':uid' component={TaskDetail}>
                                            <Route path='addWorkTime' component={AddWorkTime} />
                                            <Route path='duplicate' component={DuplicateTask} />
                                            <Route path='permissions' component={Permissions} />
                                            <Route path='history' component={TaskHistory} />
                                        </Route>
                                    </Route>
                                    <Route path='calendar' component={Calendar}>
                                        <Route path=':uid' component={TaskDetail}>
                                            <Route path='addWorkTime' component={AddWorkTime} />
                                            <Route path='duplicate' component={DuplicateTask} />
                                            <Route path='permissions' component={Permissions} />
                                            <Route path='history' component={TaskHistory} />
                                        </Route>
                                        <Route path='userEvents/:uid' component={UserEvents} />
                                    </Route>
                                    <Route path='workload' component={Workload}>
                                        <Route path='duplicate/:uid' component={DuplicateTask} />
                                        <Route path=':uid' component={TaskDetail}>
                                            <Route path='addWorkTime' component={AddWorkTime} />
                                            <Route path='duplicate' component={DuplicateTask} />
                                            <Route path='permissions' component={Permissions} />
                                            <Route path='history' component={TaskHistory} />
                                        </Route>
                                    </Route>
                                </Route>

                                <Route path='chat' component={Chat}>
                                    <Route path='search' component={ChatSearch} />
                                    <Route path=':type/:uid' component={ChatChannel} />
                                </Route>
                                <Route path='settings' component={Settings}>
                                    <Route path='my-account' component={MyAccount}>
                                        <Route path='calendars/connect' component={ConnectCalendar}/>
                                    </Route>
                                    <Route path='workspace' component={WorkspaceSettings} />
                                    <Route path='financials' component={FinancialSettings} />
                                    <Route path='team-members' component={TeamMembers}>
                                        <Route path=':uid' component={EditTeamMember}>
                                            <Route path='addDayOff' component={AddMemberDayOff} />
                                        </Route>
                                    </Route>
                                    <Route path='skills' component={Skills}>
                                        <Route path=':uid' component={EditSkill} />
                                    </Route>
                                    <Route path='boards' component={BoardsSettings} />
                                    <Route path='tags' component={TagsSettings} />
                                    <Route path='customFields' component={CustomFieldsSettings} />
                                    <Route path='subscription' component={Account} />

                                    <Route path='daysoff' component={Daysoff}>
                                        <Route path='add' component={AddAccountDayOff} />
                                    </Route>

                                    <Route path='permissions' component={SettingsPermissions} />
                                    <Route path='integrations' component={Integrations}>
                                        <Route path='connectSlack' component={ConnectSlack} />
                                    </Route>
                                    <Route path='integrations/:id' component={SyncToolPage}>
                                        <Route path='user/:uid' component={EditTeamMember}>
                                            <Route path='addDayOff' component={AddMemberDayOff} />
                                            <Route path='sendInvite' component={SendMemberInvite} />
                                        </Route>
                                    </Route>
                                    <Route path='integrations' component={Integrations}>
                                        <Route path='connectSlack' component={ConnectSlack} />
                                    </Route>
                                    <Route path='integrations/:id' component={SyncToolPage}>
                                        <Route path='user/:uid' component={EditTeamMember}>
                                            <Route path='addDayOff' component={AddMemberDayOff} />
                                            <Route path='sendInvite' component={SendMemberInvite} />
                                        </Route>
                                    </Route>
                                    <Route path='import-data' component={Import}>
                                        <Route path='basecamp' component={Import} />
                                        <Route path='wrike' component={Import} />
                                    </Route>
                                    <Route path='apikeys' component={ApiKeys} />
                                </Route>
                            </Route>
                            <Route component={StructOff}>
                                <Route path='login' component={Login}>
                                    <Route path='reset-password' component={ResetPassword}/>
                                </Route>
                                <Route path='create-account' component={CreateAccount} />
                                <Route path='create-account/a' component={CreateAccountA} />
                                <Route path='create-account/b' component={CreateAccountB} />
                                <Route path='authAction' component={AuthAction} />
                            </Route>
                            <Route path='overviewoffer' component={OverviewOffer} />
                            <Route path="*" component={NotFound} />
                        </Route>
                    </Router>

                    <ReduxToastr
                        timeOut={30000}
                        preventDuplicates={true}
                        newestOnTop={false}
                        position="bottom-center"
                        transitionIn="bounceIn"
                        transitionOut="bounceOut"
                        progressBar
                    />
                </div>
            </Provider>
        </ErrorBoundary>
    );

};

export default Store;