import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, TextField } from '@material-ui/core';
import { validateEmail, tracking } from '../../../js/utils/utils';
import { createNewMember, sendMemberInvite } from '../../../js/utils/firebaseEvents';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import classnames from 'clsx';
import { MEMBER_TYPES } from '../../utils/memberTypes';

const InviteTeamMembers = ({ accountId, inviterName, workspaceName, nextStep }) => {
    const accountUsers = useSelector(state => state.app?.account.users);
    const alreadyExistingEmails = Object.values(accountUsers || {}).map(el => el.email);
    const intl = useIntl();
    const [newTeamMembers, setNewTeamMembers] = useState({
        0: {
            displayName: '',
            email: '',
        },
        1: {
            displayName: '',
            email: '',
        },
        2: {
            displayName: '',
            email: '',
        },
    });

    const setUserDataById = (id, data) => {
        setNewTeamMembers({
            ...newTeamMembers,
            [id]: {
                ...newTeamMembers[id],
                ...data
            }
        });
    };
    
    const handleSkip = () => {
        tracking('Skiped invite users', { accountId: accountId } );
        nextStep();
    };

    const handleSubmit = () => {
        const tmValues = Object.values(newTeamMembers);
        const usedEmail = alreadyExistingEmails;
        const newTMCreate = tmValues.filter((newTM) => {
            const emailValid =  (newTM.displayName.trim() !== '' && newTM.email === '') || (newTM.email.trim() !== '' && validateEmail(newTM.email) && !usedEmail.includes(newTM.email));
            const isValid = newTM.displayName.trim() !== '' || emailValid;
            usedEmail.push(newTM.email);

            return isValid;
        });

        if (newTMCreate.length) {
            toastr.success(`Added ${newTMCreate.length} team members to your workspace`);
            let count = 0;
            newTMCreate.forEach(async (el) => {
                const newMemberId = await createNewMember({
                    displayName: el.displayName,
                    email: el.email,
                    accountId,
                    memberType: MEMBER_TYPES.userAndResource
                });
                if (el.email) {
                    count++;
                    sendMemberInvite(accountId, newMemberId, {}, el.email, inviterName, workspaceName, intl, false);
                }
            });

            tracking('Invited after create a new account', {
                created: newTMCreate.length,
                invited: count
            });

            nextStep();
        }
    };

    const tmValues = Object.values(newTeamMembers);
    const usedEmails = [
        ...alreadyExistingEmails,
        ...tmValues.filter(el => validateEmail(el.email || '')).map(el => el.email)
    ];

    const invalidTM = tmValues.filter((newTM) => {
        const emailValid =  (newTM.displayName.trim() !== '' && newTM.email === '') || (newTM.email.trim() !== '' && validateEmail(newTM.email) && usedEmails.filter(el => el === newTM.email).length === 1);
        if(newTM.displayName.trim() !== '' || newTM.email !== '') {
            return !(newTM.displayName.trim() !== '' || emailValid);
        }

        return false;
    });
    const validTM = tmValues.filter((newTM) => {
        const emailValid =  (newTM.displayName.trim() !== '' && newTM.email === '') || (newTM.email.trim() !== '' && validateEmail(newTM.email) && usedEmails.filter(el => el === newTM.email).length === 1);

        return newTM.displayName.trim() !== '' || emailValid;
    });

    return (
        <div className='CreateWorkspace'>
            <div className="inviteTeam">
                <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
                <h2>{intl.formatMessage({ id: 'Invite your team' })}</h2>
                <span className="invite-team-info">{intl.formatMessage({ id: 'Planless is more fun with your team. Set the name of your teammates and their email address to invite them to join the party.' })}</span>

                <form className='CreateWorkspace__inputs'>
                    <TeamMemberInputs uniqueEmail={usedEmails.filter(el => el === newTeamMembers[0].email).length === 1} id={0} user={newTeamMembers[0]} setUserDataById={setUserDataById} />
                    <TeamMemberInputs uniqueEmail={usedEmails.filter(el => el === newTeamMembers[1].email).length === 1} id={1} user={newTeamMembers[1]} setUserDataById={setUserDataById} />
                    <TeamMemberInputs uniqueEmail={usedEmails.filter(el => el === newTeamMembers[2].email).length === 1} id={2} user={newTeamMembers[2]} setUserDataById={setUserDataById} />
                    <div>
                        <Button className={classnames({ saveDisabled: invalidTM.length || !validTM.length })} disabled={invalidTM.length} onClick={handleSubmit} type='submit'>{intl.formatMessage({ id: 'invite teammates' })}</Button>
                        {
                            (!invalidTM.length && !validTM.length) && <Button className="cancelBtn" onClick={handleSkip}>{intl.formatMessage({ id: 'Later' })}</Button>}
                    </div>
                </form>
            </div>
        </div>
    );
};

const TeamMemberInputs = ({ id, setUserDataById, user, uniqueEmail }) => {
    const setUserName = (e) => setUserDataById(id, { displayName: e.target.value });
    const setUserEmail = (e) => setUserDataById(id, { email: e.target.value });
    const intl = useIntl();

    return <div className='inputUserData'>
        <TextField
            defaultValue={user?.displayName || ''}
            onChange={setUserName}
            label={intl.formatMessage({ id: 'Name' })}
            type='text'
            size='small'
            variant='standard'
        />
        <TextField
            defaultValue={user?.email || ''}
            onChange={setUserEmail}
            label={intl.formatMessage({ id: 'Email' })}
            error={user?.email !== '' && (!validateEmail(user?.email) || !uniqueEmail)}
            helperText={
                user?.email !== '' && (!validateEmail(user?.email) || !uniqueEmail) ?
                    intl.formatMessage({ id: 'Must be a valid email and not used mutiple times.' }) : ''
            }
            type='text'
            size='small'
            variant='standard'
        />
    </div>;
};

export default memo(InviteTeamMembers);

