import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo, sortOtherToLast } from '../utils';
import _ from 'underscore';
import Proptypes from 'prop-types';

import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const EffortBreakdownOverview = ({
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);

    const [data, setData] = useState({
        teamMembersData: [],
        skillsData: [],
        tasksData: [],
        totalMaxEffort: 0,
        totalMinEffort:0,
        total: 0
    });

    useEffect(() => {
        // in the case of beeing root lvl
        // get all of the sub childs
        const workspaceMainItens = workspaceSelected === 'root' ?
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
            : _.get(stateTasks[workspaceSelected], 'childrens' , []);

        const workspaceMainItensChilds  = {};
        workspaceMainItens.forEach(({id}) => {
            workspaceMainItensChilds[id] =  _.get(childsParents, id , [id]);
        });
        const workspaceMainItensChildsToEntries = Object.entries(workspaceMainItensChilds);


        const workspaceTasks = workspaceSelected === 'root' ?
            Object.keys(stateTasks) :
            _.get(childsParents, workspaceSelected , [workspaceSelected]);

        if( workspaceTasks.length) {
            const teamMembersData =  {};
            const skillsData =  {};
            const tasksData =  {};
            let totalMaxEffort = 0;
            let totalMinEffort =  0;

            workspaceTasks.forEach(taskId => {
                const currentTask = stateTasks[taskId];

                if(!currentTask || currentTask.status === 'done' || (stateTasks.parent &&  !stateTasks[stateTasks.parent]) || currentTask.childrens){
                    return;
                }

                // handle team members
                let taskUsers = [];

                // split
                if(currentTask.estimations?.userId){
                    taskUsers = (Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId])
                        .map(el => ({isForced: false, userId: el , avatar: getUserInfo(accountTeamMembers[el]).avatar,  name: getUserInfo(accountTeamMembers[el]).displayName}));
                } else if(currentTask.forcedUser) {
                    taskUsers = (Array.isArray(currentTask.forcedUser) ?  currentTask.forcedUser : [currentTask.forcedUser]).map(el => ({isForced: true, avatar: getUserInfo(accountTeamMembers[el]).avatar, userId: el, name: getUserInfo(accountTeamMembers[el])?.displayName }));
                }

                totalMaxEffort += parseInt(currentTask.maxEffort) || 0;
                totalMinEffort += parseInt(currentTask.minEffort) || 0;

                // handle skills
                const skills = currentTask?.skill?.length ? currentTask.skill : [];
                const skillDatasetsMax = skills.map(skillId => skillsData[skillId]).filter(Boolean);

                if (skillDatasetsMax.length) {
                    skillDatasetsMax.forEach(skillDatasetMax => {
                        skillDatasetMax.minEffort += parseInt(currentTask.minEffort) || 0;
                        skillDatasetMax.maxEffort += parseInt(currentTask.maxEffort) || 0;
                    });
                } else {
                    skills.forEach(skillId => {
                        skillsData[skillId] = {
                            id: skillId,
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: accountSkills[skillId]?.name
                        };
                    });
                }

                // handle team members
                if(taskUsers.length) {
                    taskUsers.forEach(({userId}) => {
                        const userDetail = accountTeamMembers[userId];
                        const datasetEntry = teamMembersData[userId];

                        if(datasetEntry){
                            datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                            datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                        } else {
                            teamMembersData[userId] = {
                                id: userId,
                                minEffort: parseInt(currentTask.minEffort) ||  0,
                                maxEffort: parseInt(currentTask.maxEffort) ||  0,
                                name: userDetail?.displayName || '(deleted user)'
                            };
                        }
                    });
                } else {
                    const userData = teamMembersData.none;
                    if(userData){
                        userData.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        userData.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        teamMembersData.none = {
                            id: 'none',
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: 'Not Assigned'
                        };
                    }
                }
                // get parent
                const mainItemEntrie = workspaceMainItensChildsToEntries.find(([, childs]) => childs.includes(taskId));
                if(mainItemEntrie) {
                    const datasetEntry = tasksData[mainItemEntrie[0]];

                    if(datasetEntry){
                        datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        tasksData[mainItemEntrie[0]] = {
                            id: mainItemEntrie[0],
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: stateTasks[mainItemEntrie[0]].title
                        };
                    }
                } else {
                    const datasetEntry = tasksData[taskId];
                    if(datasetEntry){
                        datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        tasksData[taskId] = {
                            id: taskId,
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: stateTasks[taskId].title
                        };
                    }
                }

            });

            const calculateAverage = (data) => {
                Object
                    .entries(data)
                    .forEach(([,el]) => {
                        el.value = (el.maxEffort + el.minEffort) / 2;
                    });

                return data;
            };

            const convertData = (dataEntry) => {
                const data = [];
                Object
                    .values(dataEntry)
                    .sort((a, b) => b.value - a.value)
                    .forEach((el, idx) => {
                        if(idx === 3){
                            data.push({
                                name: 'Others',
                                value: el.value
                            });
                            return;
                        }

                        if(idx > 3) {
                            data[3].value += el.value;
                            return ;
                        }

                        data.push({
                            name: el.name,
                            value: el.value
                        });
                        return ;
                    });
                return data;
            };


            setData({
                skillsData: convertData(calculateAverage(skillsData)),
                tasksData: convertData(calculateAverage(tasksData)),
                teamMembersData: convertData(calculateAverage(teamMembersData)),
                totalMaxEffort,
                totalMinEffort,
                total: (totalMaxEffort + totalMinEffort) / 2
            });
        }

    }, [workspaceSelected, stateTasks, accountTeamMembers, childsParents, accountSkills]);

    const path = window.location.pathname.split('/').slice(0,3).join('/');
    let hasData = (toCheck) =>  toCheck.find(el => el.value);

    return <div className={'overview-repartition'}>
        <div>
            <div>
                Average effort per Sub Items
            </div>
            <div>
                {
                    hasData(data.tasksData) ? data
                        .tasksData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div>
            <div>
                Average effort per Team Members
            </div>
            <div>
                {
                    hasData(data.teamMembersData) ? data
                        .teamMembersData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div>
            <div>
                Average effort per Skills
            </div>
            <div>
                {
                    hasData(data.skillsData) ? data
                        .skillsData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="effort-breakdown"
                to={`${path}/effort-breakdown`}
            >
                Go to <b>Effort repartition</b> report
            </Button>
        </div>
    </div>;
};

EffortBreakdownOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default EffortBreakdownOverview;