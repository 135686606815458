import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import _ from 'underscore';
import { OpenInNew } from '@material-ui/icons';

import StoryPointLine from './components/StoryPointLine/StoryPointLine';
import useInternalization from '../../utils/hooks/useInternalization';
import { handleStopPropagation } from '../../utils/utils';
import { updateTaskEffortByStoryPoint, updateTaskStoryPoint } from '../../utils/controllers/syncApp/jira';
import { JIRA_ESTIMATION_TYPES } from '../Integrations/components/SyncSettings/utils';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { CircularProgress } from '@material-ui/core';
import useSyncData from '../../utils/hooks/useSyncData';

const SelectEffort = ({ onClose, open = true, currentStoryPointId = null, taskId }) => {
    const { t } = useInternalization();
    const { syncApp } = useSyncData(taskId);
    const [isLoading, setIsLoading] = React.useState(false);

    const storyPoints = syncApp?.effortSettings && _.reduce(syncApp.effortSettings, (acc, data, effortType) => {
        if(effortType !== 'onUpdate') return acc;

        if(data.estimationActive === JIRA_ESTIMATION_TYPES.STORYPOINT) {
            return {...acc, ...data.storyPoints.value};
        }
        return acc;
    }, {}) || {};

    const handleSelectStoryPoint = async (storyPoint) => {
        try {
            setIsLoading(true);
            await updateTaskStoryPoint(storyPoint, taskId);
            await updateTaskEffortByStoryPoint(storyPoint, taskId);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    const handleManageEffortSettings = (e) => {
        handleStopPropagation(e);
        window.open('/settings/integrations/jira');
    };

    const handleClose = (e) => {
        handleStopPropagation(e);
        onClose();
    };

    return (
        <Dialog
            maxWidth={'md'}
            className="SelectEffort_Modal"
            onClose={handleClose}
            onClick={handleStopPropagation}
            aria-labelledby="customized-dialog-title"
            open={open}
            data-sidebar='preventClose'
        >
            <MuiDialogTitle className="title" disableTypography onClick={handleStopPropagation}>
                <Typography variant="h6">{t('select.effort.title')}</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers>

                {isLoading ? (
                    <div className="SelectEffort_Modal_Loader">
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className="SelectEffort__labels__header">
                            <Typography variant="subtitle2">{t('select.effort.header1')}</Typography>
                            <Typography variant="subtitle2">{t('select.effort.header2')}</Typography>
                        </div>

                        {!_.isEmpty(storyPoints) ? _.map(storyPoints, (storyPoint, spId) => {
                            return (
                                <StoryPointLine
                                    key={`${storyPoint.spvalue}-${spId}`}
                                    storyPoint={storyPoint}
                                    isCurrentSp={currentStoryPointId === spId}
                                    handleSelectStoryPoint={handleSelectStoryPoint}
                                />
                            );
                        }) : (
                            <h3>{t('select.effort.empty')}</h3>
                        )}
                    </>
                )}

            </MuiDialogContent>
            <MuiDialogActions className='actions effortSelector'>
                <Button
                    id="manage-btn"
                    variant="contained"
                    color="primary"
                    onClick={handleManageEffortSettings}
                    endIcon={<OpenInNew />}
                >
                    {t('select.effort.manageButton')}
                </Button>
            </MuiDialogActions>
        </Dialog >
    );
};

export default withCustomErrorBoundary(SelectEffort);