import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

const SelectProjectDialogTitle = ({ step }) => {
    const intl = useIntl();

    return (
        <>
            {step === 1 ? (
                <Typography variant="h6"> {intl.formatMessage({ id: 'Select Projects' })}</Typography>
            ) : (
                <div>
                    <Typography variant="body2"> {intl.formatMessage({ id: 'Import Subtasks?' })}</Typography>
                    <Typography variant="body2"> {intl.formatMessage({ id: 'import.subtasks.tooltip.info' })}</Typography>
                </div>
            )}
        </>
    );
};

export default SelectProjectDialogTitle;