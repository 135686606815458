import React from 'react';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { Link } from 'react-router';

import classnames from 'clsx';
import Button from '@material-ui/core/Button';
import OverViewCard from '../OverViewCard';

const TimeWorkedOverview = ({ 
    datesRange,
    workspaceSelected,
    stateTasks,
    childsParents,
    userTimezone
}) => {
    const overviewCurrentData = React.useMemo(() => {
        const startDate = moment(datesRange.startDate);
        const endDate = moment(datesRange.endDate)
            .isAfter(moment().tz(userTimezone)) ? moment().tz(userTimezone) : moment(datesRange.endDate) ;
        const workspaceTasks = workspaceSelected === 'root' ? Object.keys(stateTasks) : 
            _.get(childsParents, workspaceSelected , [workspaceSelected]);
 
        let totalWorktime = 0;
        workspaceTasks.forEach(taskId => {
            const currentTask = stateTasks[taskId];
            if(!currentTask || !currentTask.workingTime){
                return;
            }
            const workTimeEntries = Object.values(currentTask.workingTime);
            workTimeEntries.forEach((workTimeEntry) => {
                const workTimeDate = moment.tz(workTimeEntry.date, 'YYYY-MM-DD', userTimezone);
                if (!workTimeDate.isBetween(startDate, endDate)) {
                    return false;
                }
                totalWorktime += parseFloat(workTimeEntry.hours);
            });
        });       
        
        const timeWorkPerMonth = totalWorktime > 0 ? parseInt(totalWorktime / endDate.diff(startDate, 'months')) : 'N/A';
        const timeWorkPerWeek = totalWorktime > 0 ?  parseInt(totalWorktime / endDate.diff(startDate, 'weeks')) : 'N/A';
        const timeWorkedPerDay = totalWorktime > 0 ?  parseFloat(totalWorktime / endDate.diff(startDate, 'days')).toFixed(2) : 'N/A';
        
        return {
            totalTimeWorked: parseInt(totalWorktime) || 'N/A',
            timeWorkPerMonth,
            timeWorkPerWeek,
            timeWorkedPerDay
        };
    }, [stateTasks,childsParents, workspaceSelected, datesRange]); 
    const path = window.location.pathname.split('/').slice(0,3).join('/');
    const renderText = (data) => isNaN(data) || data === 'N/A' ? 'N/A' : `${data}h`;
    
    return <div className={classnames('bigNumbers')}>
        <OverViewCard 
            title={'Total Time Worked'} 
            value={renderText(overviewCurrentData.totalTimeWorked)}
        />
        <OverViewCard 
            title={'Average Time Worked per Day'} value={renderText(overviewCurrentData.timeWorkedPerDay)} 
        />
        <OverViewCard 
            title={'Average Time Worked per Week'} 
            value={renderText(overviewCurrentData.timeWorkPerWeek)} />
        <OverViewCard 
            title={'Average Time Worked per Month'} 
            value={renderText(overviewCurrentData.timeWorkPerMonth)} 
        />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="time-worked-total" 
                to={`${path}/time-worked-total`}
            >
                Go to <b>Time Worked (total)</b> report
            </Button>
        </div>
    </div>;
};

TimeWorkedOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default TimeWorkedOverview;