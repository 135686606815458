import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Popper } from '@material-ui/core';
import {ArrowLeft, ArrowDropUp} from '@material-ui/icons';
import * as firebaseEvents from '../utils/firebaseEvents';
import { useSelector } from 'react-redux';
import { getDisablePopupGuides, getPopupGuides } from './selectors/app';

const DefaultTooltip =({ placement, tooltipId, open, newRef }) => {
    const popupGuidesDisalbed = useSelector(getDisablePopupGuides);
    if(popupGuidesDisalbed) return null;
    
    const intl = useIntl();
    const TABLET_BREAKPOINT_PIXELS = 768;
    const [isTabletWidth, setIsTabletWidth] = useState((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < TABLET_BREAKPOINT_PIXELS);
    const allPopups = useSelector(getPopupGuides);
    const allPopupsGuidesSeen = allPopups ? Object.values(allPopups).every(el=>el) : false;

    useEffect(() => {
        const checkIsMobileWidth = () => {
            if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < TABLET_BREAKPOINT_PIXELS) {
                setIsTabletWidth(true);
            }
        };

        window.addEventListener('resize', checkIsMobileWidth);

        return () => {
            window.removeEventListener('resize', checkIsMobileWidth);
        };

    }, [isTabletWidth]);

    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    const onClose = (e) => {
        e.stopPropagation();
        firebaseEvents.setCompletedPopupGuide(tooltipId);

    };

    const getMessage = () => {
        const icons = {taskRightClick: '👆' ,priorityMessage: '👆',enterToAdd:'💡', taskMultipleEdit: '🕒',noSkill: '⚠️', noEffort: '⚠️',warnMessages: '⚠️'};

        return <>
            {tooltipId !== 'taskRightClick' && tooltipId !==  'enterToAdd' && tooltipId !==  'priorityMessage' && <h1>{icons[tooltipId]} {t(`popupguide.${tooltipId}-top`)}</h1>}
            <p>{(tooltipId === 'taskRightClick' || tooltipId ===  'priorityMessage') && (icons[tooltipId])} {t(`popupguide.${tooltipId}-bottom`)}</p>
        </>;
    };

    const getArrow = () => {
        const ArrowLeftCenter = <ArrowLeft className='arrowLeft' />;
        const ArrowLeftStart = <ArrowLeft className='arrowLeft left-start' />;
        const ArrowUpEnd = <ArrowDropUp className='arrowUp up-end' />;
        const ArrowUp = <ArrowDropUp className='arrowUp' />;
        const popupArrow = {
            noEffort: ArrowUp,
            noSkill: ArrowUp,
            priorityMessage: ArrowUpEnd,
            taskRightClick: ArrowUp,
            taskMultipleEdit: ArrowUp,
            enterToAdd: ArrowLeftStart,
            warnMessages: ArrowLeftCenter,
        };
        return popupArrow[tooltipId];
    };

    if(!open) { return null; }

    
    return newRef && !isTabletWidth && !allPopupsGuidesSeen ?
        <Popper 
            anchorEl={newRef} 
            open={open} 
            className='customMessageToolTip' 
            placement={placement} 
            id={tooltipId}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false,
                    options: {
                        altBoundary: false,
                        rootBoundary: 'document',
                        padding: 0,
                    },
                }, 
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: false,
                        altBoundary: false,
                        tether: false,
                        rootBoundary: 'document',
                        padding: 0,
                    },
                }
            ]}
        >   
            <div className="customMessageToolTip__inner">
                {getArrow()}
                {getMessage()}
                <Button onClick={onClose} type="button">{t('got-it')}</Button>
            </div>
        </Popper> : null;
};

export default DefaultTooltip;
