import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import {toastr} from 'react-redux-toastr';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getAccountId, getAccountName, getAccountUser, getAccountUserDisplayName } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const SendMemberInvite = ({router})=>{
    const userId = router.params.uid;
    const intl = useIntl();

    const userData = useSelector(getAccountUser(userId));
    const name = useSelector(getAccountName);
    const accountId = useSelector(getAccountId);
    const displayName = useSelector(getAccountUserDisplayName(userId));

    const [email, setEmail] = useState('');

    const close = () => {
        browserHistory.push('/' + router.routes[2].path + '/' + router.routes[3].path + '/' + userId);
    };

    const validateEmail = (email) => {
        /*eslint-disable */
        var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
        /*eslint-enable */
        return emailPattern.test(email);   
    }; 

    const save = () => {

        if(validateEmail(email)){

            firebaseEvents.addChallenge('inviteMemberName');
            
            firebaseEvents.sendMemberInvite(
                accountId,
                userId,
                userData,
                email, 
                displayName,
                name,
                intl
            );
            close();
        }
        else {
            toastr.error(intl.formatMessage({id:'The email address is not correct!'}));
        }
    };


    return (
        <Dialog 
            className='SendMemberInvite'
            open={true}
            onClose={close}
            maxWidth="xs"
            fullWidth={true}
            data-sidebar='preventClose'
        >
            <DialogTitle>{intl.formatMessage({id:'Invite viewer'})}</DialogTitle>
            <DialogContent className="content">
                <TextField
                    className="email"
                    type="email" 
                    label={intl.formatMessage({id:'Viewers\'s email'})} 
                    onChange={(e)=>setEmail(e.target.value)}
                    onKeyDown={(e)=>{if(e.keyCode === 13) save(); }}
                />
            </DialogContent>
            <DialogActions className="actions">
                <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
                <Button variant="contained" onClick={save} color="primary">{intl.formatMessage({id:'Invite viewer'})}</Button>
            </DialogActions>
        </Dialog>
    );

};

export default withRouter(withCustomErrorBoundary(SendMemberInvite));
