import React from 'react';
import PropTypes from 'prop-types';
import TaskPrioritySelect from '../TaskPrioritySelect/TaskPrioritySelect';
import { useSelector } from 'react-redux';
import { getAccountTaskCanManage, getAccountTaskHasChildren, getAccountTaskPriority } from '../../utils/selectors/account';
import { useIntl } from 'react-intl';

const TaskDetailPrioritySelect = ({ taskId }) => {
    const intl = useIntl();
    const taskHasChildren = useSelector(getAccountTaskHasChildren(taskId));
    const taskPriority = useSelector(getAccountTaskPriority(taskId));
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));

    return (
        <li>
            <dl>
                <dt>{intl.formatMessage({id: 'Priority'})}</dt>
                <dt>
                    <TaskPrioritySelect
                        taskId={taskId}
                        isBlank={!!taskHasChildren}
                        level={taskPriority}
                        showLabel={true}
                        canManage={taskCanManage}
                        multiTask={false}
                    />
                </dt>
            </dl>
        </li>
    );
};

TaskDetailPrioritySelect.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailPrioritySelect;
