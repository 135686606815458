import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import SplitBy from '../SplitBy/SplitBy';
import ImageIcon from '@material-ui/icons/Image';
import OverViewCard from '../OverViewCard';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import ReactDOM from 'react-dom';

import {
    agregateData,
    convertToDateTime,
    deepCloneDataSets,
    borderDash,
    getDataByTask,
    downloadAsImage,
    getColor,
    exportCSV,
    validateNumber,
    getDateFormated,
    getMonth,
    getCsvFile,
} from '../utils';
import DaysOffTable from './DaysOffTable';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const breakdownDataByUseres = ({lastValue, currentChartData, csvLines, data, tasksIds, teamMembersData, hasBreakdownByTask, stateTasks}) => data.reduce((acc, userId) => {
    if(!userId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = userId + '-' + taskId;
            let daysOff = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData?.teamMembers?.findIndex(key => userId === key);
                if (idxOfSelect > -1) {
                    daysOff = parseInt(findData['Days-off Team members'][idxOfSelect]);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    daysOff = lastChartPointData?.daysOff || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                daysOff = lastChartPointData?.daysOff || 0;
            }

            acc.push({
                id,
                label:   teamMembersData[userId]?.displayName +  '-' +  stateTasks[taskId].title,
                daysOff: daysOff,
                tasksId: [taskId],
                breakdownId: userId,
                parentName: teamMembersData[userId]?.displayName || 'Deleted User',
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }

    // if no other breakdown.
    const linesWithUser = csvLines.filter(line => line.teamMembers.includes(userId));
    const vadas = linesWithUser.reduce((acc,line) => {
        const idxOfSelect = line?.teamMembers?.findIndex(key => userId === key);
        if (idxOfSelect >= 0) {
            acc.daysOff += parseInt(line['Days-off Team members'][idxOfSelect]);
            acc.tasksId.push(line.id);
        }
        return acc;
    }, { daysOff: 0, tasksId: []});

    acc.push({
        id: userId,
        label:  teamMembersData[userId]?.displayName  || 'Deleted User',
        daysOff: vadas.daysOff,
        tasksId: vadas.tasksId,
        breakdownId: userId,
        parentName: teamMembersData[userId]?.displayName  || 'Deleted User',
    });

    return acc;
}, []);


ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 	'Days Off',
                data: [],
                yAxisID: 'y',
                pointRadius: 0
            },
        ]
    },
    options: {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                }
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function(context){
                        return getDateFormated(context[0].label);
                    },
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size:12
                },
                titleColor: '#fff',
                titleFont: {
                    size:14
                },
                boxWidth: 8,
                boxHeight: 8
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function(value) {
                        const labelValue = this.getLabelForValue(value);
                        if(moment(labelValue, 'YYYY', true).isValid()){
                            return labelValue;
                        }
                        else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        }
                        else if(moment(labelValue, 'M/YYYY', true).isValid()){
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }

                        return `${moment(labelValue).format('MMM D, YY')}`;
                    },
                    color: '#999',
                    font: {
                        size: 11
                    }
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                lineWidth: 0,
                drawTicks: false,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11
                    }
                }
            },
        },
    },
};

const DaysOffChart = ({
    accountId,
    stateTasks,
    datesRange,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    userTimezone
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const globalDaysOff = useSelector(state => state?.app?.account?.daysoff) || {};
    const chartCanvas = useRef(null);
    const [currentChart, setCurrentChart] = useState(null);
    const [currentData, setCurrentData] = useState({labels: [], datasets: {} } );
    const intl = useIntl();
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl).filter(el => el.value === 'none' || el.value === 'users');
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);
    const [displayData, setDisplayData] = useState({labels: [], datasets: {} } );
    const [totalDaysOff, setTotalDaysOff] = useState({ future: 0, past: 0});
    const dispatch = useDispatch();
    const [csvData, setCSVData] = useState({});
    const [workspaceData, setWorkspaceData] = useState([]);

    useEffect( () => {
        dispatch(showLoader());
        getCsvFile(accountId, 'root', userTimezone).then(async (result) => {
            const data = await getDataByTask({
                accountId: accountId,
                workspaceData: result,
                selectedTasks: [],
                userTimezone
            });

            setWorkspaceData(result);
            setCSVData(data);
            dispatch(hideLoader());
        });
    }, [accountId]);

    const updateBreakdowns = async (newBreakdowns) => {
        setBreakdownData(newBreakdowns);
    };

    // Should only occor on the mount
    useEffect( () => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = (e) => {
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);

                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));

                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });
                if(document.getElementsByClassName('ReactVirtualized__Grid').length > 1){
                    const width = document.getElementsByClassName('ReactVirtualized__Grid')[1]?.children[0]?.children[0]?.style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };

            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]);


    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }

        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item) => {
            let line = '';
            line += `${item.name}\n`;
            line += `Days Off;${item.daysOff.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'days-off-chart');
    };

    const breakdownDataByTask = breakdownData.find(el => el.value === 'tasks');
    const breakdownDataByUsers = breakdownData.find(el => el.value === 'users');

    const handleData =  async () => {
        if(currentChart && !_.isEmpty(csvData)) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let possibleBreaks = [];
            let totalPastDaysOff =  0;
            let totalFutureDaysOff =  0;
            // get all the tasks IDS
            const allTasksId = Object.keys(csvData);
            const allChartPoints = Object.values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime)*60*60*4 * 1000);
                    const formatedDate =  `${newDate.getFullYear()}/${getMonth(newDate.getMonth() + 1)}/${newDate.getDate()}`;

                    if(acc[formatedDate]){
                        return {
                            ...acc,
                            [formatedDate]: [el,...acc[formatedDate]]
                        };
                    }

                    return {
                        ...acc,
                        [formatedDate]: [el]
                    };
                }, {});


            let lastValue = '';
            let daysOff = 0;
            // have to go throw all of the checkpoints
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                // if there is no break down we aggregate the information.
                if (breakdownDataByUsers?.lvl === 0 && breakdownDataByUsers.selectedData.length) {
                    possibleBreaks = breakdownDataByUsers.selectedData;

                    const data = breakdownDataByUseres({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataByUsers.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        teamMembersData: accountTeamMembers
                    });

                    lastValue = key;
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }
                //if you have the lvl 0 breakdown beeing tasks =
                if (breakdownDataByTask?.lvl === 0) {
                    possibleBreaks = allTasksId;

                    const values = allTasksId.reduce((tasksAcc, taskId) => {
                        // lvl 2 === teamMember
                        const findData = csvLines.find(line => line.id === taskId);
                        if(breakdownDataByUsers  && breakdownDataByUsers.selectedData.length){
                            const teamMembers = breakdownDataByUsers.selectedData.length ? breakdownDataByUsers.selectedData : [];
                            possibleBreaks = teamMembers;
                            teamMembers.forEach(tmId => {
                                let id = taskId +  '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData?.teamMembers?.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        daysOff = parseInt(findData['Days-off Team members'][idxOfSelect]);
                                    } else if(lastValue) {
                                        const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                        daysOff = lastChartPointData?.daysOff || 0;
                                    }
                                } else if(lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    daysOff = lastChartPointData?.daysOff || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label: stateTasks[taskId].title +  '-' +  accountTeamMembers[tmId]?.displayName || 'Delete User',
                                    daysOff: daysOff,
                                    tasksId: [taskId],
                                    breakdownId: tmId,
                                    parentName: stateTasks[taskId].title,
                                    subName: accountTeamMembers[tmId]?.displayName,
                                });
                            });
                            return tasksAcc;
                        }

                        if(findData){
                            daysOff = parseInt(findData['Days-off']);
                        } else if(lastValue) {
                            const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === taskId);
                            daysOff = lastChartPointData?.daysOff || 0;
                        }

                        return [ ...tasksAcc, {
                            id: taskId,
                            label: stateTasks[taskId]?.title || 'N/A',
                            daysOff: daysOff,
                            tasksId: [taskId],
                            parentName: stateTasks[taskId]?.title || 'N/A',
                        }];
                    }, []);
                    // saving last value
                    lastValue = key;

                    return {
                        ...chartPointAcc,
                        [key]: values
                    };
                }

                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) =>  {
                    return {
                        id: `default${idx}`,
                        label: '',
                        daysOff: parseInt(line['Days-off']),
                        tasksId: [line.id],
                        parentName: ''
                    };
                });

                return { ...chartPointAcc,
                    [key]: values
                };
            }, {});

            const chartData = Object.entries(newData).reduce((acc, [key, items]) => {
                acc.labels.push(key);

                items.forEach((el, itemIdx) => {
                    totalPastDaysOff += el.daysOff || 0;
                    if (!acc.datasets[el.id+'-daysOff']) {
                        const [colorA ] = getColor(possibleBreaks.length,itemIdx);
                        acc.datasets[el.id+'-daysOff'] =  {
                            ...el,
                            // missing this one
                            id: el.id,
                            label:  el.label + ' Days Off',
                            data: [validateNumber(el.daysOff)],
                            borderColor: colorA,
                            backgroundColor: colorA,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4,
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            source: el.tasksId,
                            pointBorderWidth: 1,
                            labelType: 'daysOff',
                            segment: {
                                borderWidth: 2,
                                borderDash: borderDash(currentChart, userTimezone),
                            }
                        };
                    } else {
                        acc.datasets[el.id+'-daysOff'].data.push(validateNumber(el.daysOff));
                    }
                });

                return acc;
            } , {
                labels: [],
                datasets: {}
            });

            if(moment(endDate).tz(userTimezone).isAfter(moment().tz(userTimezone))) {
                const firstDay = moment().tz(userTimezone);
                const lastDay = moment(endDate).tz(userTimezone);
                lastDay.add(1, 'day');
                const newLabels = [];
                const allOffDaysOff = [];
                const userSplitedByDay = {};
                const tasksSplitedByDay = {};
                const GDOArray = Object.values(globalDaysOff);
                // get all of the days of firsts
                Object.values(accountTeamMembers).forEach(user => {
                    // remove the viewers
                    if(!user.viewer) {
                        const usersDaysOff =  user.daysoff;
                        if(usersDaysOff){
                            Object.values(usersDaysOff).forEach(el => {
                                allOffDaysOff.push({...el, userId: user.userId});
                            });
                        }
                        // add the global days off to each users.
                        GDOArray.forEach(globalDay => allOffDaysOff.push({...globalDay, userId: user.userId}));
                    }
                });

                while(firstDay.isBefore(lastDay)) {
                    let countUsersDaysOff = 0;
                    const formatedDate = firstDay.format('YYYY/MM/DD');
                    allOffDaysOff.forEach(el => {
                        if(firstDay.isBetween(moment.tz(el.startDate, 'YYYY-MM-DD', userTimezone), moment.tz(el.endDate, 'YYYY-MM-DD', userTimezone))){
                            countUsersDaysOff += 1;

                            if(breakdownDataByUsers?.selectedData?.length) {
                                if(userSplitedByDay[formatedDate] && userSplitedByDay[formatedDate][el.userId] ){
                                    userSplitedByDay[formatedDate][el.userId].daysOff += 1;
                                } else {
                                    userSplitedByDay[formatedDate] = {
                                        [el.userId]: {
                                            daysOff: 1,
                                        }
                                    };
                                }
                            }
                        }
                    });

                    tasksSplitedByDay[formatedDate] = {
                        ['root']:  {
                            daysOff: countUsersDaysOff,
                        }
                    };

                    totalFutureDaysOff += countUsersDaysOff;
                    newLabels.push(firstDay.format('YYYY/MM/DD'));
                    firstDay.add(1, 'day');
                }
                newLabels.forEach(byDate => {
                    const userTasksDoneThisDay = userSplitedByDay[byDate];
                    const tasksDoneThisDay = tasksSplitedByDay[byDate];

                    chartData.labels.push(byDate);
                    Object.values(chartData.datasets).forEach((el) => {
                        let value = { daysOff: 0 };

                        if(breakdownDataByUsers?.selectedData?.length && userTasksDoneThisDay && userTasksDoneThisDay[el.breakdownId]){
                            value = userTasksDoneThisDay[el.breakdownId] || value;
                        }

                        if(!breakdownDataByUsers){
                            value = tasksDoneThisDay[el.source[0]] || value;
                        }

                        el.data.push(value.daysOff);
                    });
                });
            }


            currentChart.data.labels = [];
            const datasetsSize = currentChart.data.datasets.length;
            // clear old datasets data
            for(let i = 0; i < datasetsSize; i++){
                currentChart.data.datasets[i].data = [];
            }
            // this will add every single day to our datasets
            // if the splitBy is !== than day we have to aggregate
            /* if(splitBy !== 'day'){
                displayData = agregateData(splitBy, {...deepCloneDataSets(chartData), stakData: true});
            }
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            */
            ReactDOM.unstable_batchedUpdates(()=> {
                setTotalDaysOff({
                    future: totalFutureDaysOff,
                    past: totalPastDaysOff,
                });
                setCurrentData(chartData);
            });
        }
        dispatch(hideLoader());
    };


    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    };

    useEffect(() => {
        if(currentChart && currentData){
            const displayData = agregateData(splitBy, {...deepCloneDataSets(currentData), stakData: true });
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(displayData);
        }
    }, [splitBy,currentData, currentChart]);


    useEffect(()=> {
        handleData();
    }, [datesRange, breakdownData, csvData, currentChart]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => _.uniq(workspaceData.map(el => el.teamMembers).flat()), [workspaceData]);

    if (!hasAutorization) {
        return <Unautorized />;
    }
    const breakdownRules = {
        'users': ['none'],
        'none': ['none' , 'users'],
    };

    return <>
        <Paper className="topSection">
            <div className="breakdownsContainer">
                <BreakdownController
                    rules={breakdownRules}
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    teamMembers={availableTeamMembers}
                    workspaceSelected={'root'}
                />
            </div>

            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard title='Total Of Days Off' value={`${totalDaysOff.future +  totalDaysOff.past} days`} />
                <OverViewCard title={'Past Days Off'} value={`${totalDaysOff.past} days`}  />
                <OverViewCard title={'Future Days Off'} value={`${totalDaysOff.future} days`} />
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(chartCanvas.current)}>
                    Save as Img
                    </Button>
                </div>
                <canvas height="300" width="800" ref={chartCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                        Save as CSV
                    </Button>
                </div>
                <DaysOffTable
                    onColumnHover={onColumnHover}
                    userDateFormat={dateFormat}
                    datasets={displayData.datasets}
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
};

DaysOffChart.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(DaysOffChart);