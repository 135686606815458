import React, { memo, useState, useEffect} from 'react';
import Proptypes from 'prop-types';
import { useIntl } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import { Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SelectorsDialogSearch from '../SelectorsDialogSearch/SelectorsDialogSearch';
import SelectorsDialogTree from '../SelectorsDialogTree/SelectorsDialogTree';

const SelectorsDialog = ({ isSaveDisabled, accountId, taskId, currentParent, newParent, type, checkedItems, open, onClose, onSelect, onSave, onCheck, stateTasksReplace = null }) => {
    const intl = useIntl();
    const [keyWord, setKeyWord] = useState('');
    const [showTree, setShowTree] = useState(true);

    useEffect(() => {
        if (!open) {
            setShowTree(true);
            setKeyWord('');
        }
    }, [open, type]);

    const handleChange = (e) => {
        setShowTree(false);
        setKeyWord(e.target.value);
    };

    const handleClearInput = () => {
        setShowTree(true);
        setKeyWord('');
    };

    return (
        <Dialog maxWidth={'md'} open={open} elevation={2} aria-labelledby="customized-dialog-title" onClose={onClose}>
            <div className='Select'>
                <MuiDialogTitle className="title" disableTypography >
                    <Typography variant="h6">{type==='manageDependencies'?intl.formatMessage({id: 'Select Dependencies'}):intl.formatMessage({id: 'Change Location'})}</Typography>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="Select__inputWrapper">

                    <div className="Select__input">
                        <SearchIcon id="searchIcon" />
                        <DebounceInput placeholder="Search..." type="text" value={keyWord} minLength={1} debounceTimeout={1000} onChange={handleChange}/>

                        {!showTree && 
                            (
                                <div id="clearInput" onClick={handleClearInput}>
                                    <CloseIcon />
                                    {keyWord !== '' ? (<span>{intl.formatMessage({id: 'Clear & go to the tree view'})}</span>) : (<span>{intl.formatMessage({id: 'Go to the tree view'})}</span>)}
                                </div>
                            )
                        }
                    </div>

                </DialogContent>

                {showTree ?
                    (<SelectorsDialogTree stateTasksReplace={stateTasksReplace} accountId={accountId} checkedItems={checkedItems} onCheck={onCheck} onSelect={onSelect} taskId={taskId} type={type} newParent={newParent} currentParent={currentParent}/>) 
                    : (<SelectorsDialogSearch stateTasksReplace={stateTasksReplace} keyWord={keyWord} checkedItems={checkedItems} onCheck={onCheck} onSelect={onSelect} taskId={taskId} type={type} newParent={newParent} currentParent={currentParent}/>)
                }

                <DialogActions className="Select__btnOptions">
                    <Button variant="contained" onClick={onClose}>
                        {intl.formatMessage({id: 'CANCEL'})}
                    </Button>
                    <Button disabled={isSaveDisabled} className={`${isSaveDisabled ? 'saveDisabled' : ''}`} variant="contained" color="primary" onClick={onSave}>
                        {intl.formatMessage({id: 'SAVE CHANGES'})}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

SelectorsDialog.propTypes = {
    isSaveDisabled: Proptypes.bool,
    taskId: Proptypes.string.isRequired,
    checkedItems: Proptypes.array,
    currentParent: Proptypes.string,
    newParent: Proptypes.string,
    type: Proptypes.string,
    open: Proptypes.bool.isRequired,
    onClose: Proptypes.func,
    onSelect: Proptypes.func,
    onSave: Proptypes.func,
    onCheck: Proptypes.func
};

export default memo(SelectorsDialog);
