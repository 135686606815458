import React from 'react';
import Slider from '@material-ui/core/Slider';

const SkillSlider = ({ onChange, onChangeCommitted, value }) => <div className="level">
    <Slider
        color="secondary"
        min={0}
        max={10}
        step={1}
        value={value}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
    />
    <div className="sliderLegend"><span>0</span><span></span><span></span><span></span><span></span><span>5</span><span></span><span></span><span></span><span></span><span>10</span></div>
</div>;

export default SkillSlider;