import React from 'react';
import Button from '@material-ui/core/Button';
import { HUBSPOT_LINK_FREE_CALL } from '../../utils/constants';
import { useIntl } from 'react-intl';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const StepsHelp = () => {
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    return (
        <div className="StepsHelp">
            <h1>{t('We are here to help')}</h1>
            <div>
                <p>
                    {t('Need help understanding how Planless works, in how to get started or on the best way to organize things for your team?')}
                </p>
                <Button onClick={() => window.open(HUBSPOT_LINK_FREE_CALL)} className="book-call" variant="contained">
                    {t('BOOK A FREE CALL')}
                </Button>
            </div>

        </div>
    );
};

export default withCustomErrorBoundary(StepsHelp);
