import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { tracking } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTaskCanManage, getAccountTaskCanView, getAccountTaskDependencies, getAccountTaskPath, getAccountTaskTitle } from '../../utils/selectors/account';
import { useIntl } from 'react-intl';
import { Chip } from '@material-ui/core';

export const getTaskPath = (taskId, tasks) => {
    if (tasks && tasks[taskId]) {
        let parentsPath = [tasks[taskId].title],
            currentTask = taskId;

        while (tasks[currentTask] && tasks[currentTask].parent) {
            if (tasks[tasks[currentTask].parent]) {
                parentsPath.push(tasks[tasks[currentTask].parent].title);
            }
            currentTask = tasks[currentTask].parent;
        }
        parentsPath = parentsPath.reverse().join(' / ');

        return parentsPath;
    }
    return null;
};

const TaskDetailDependenciesListItem = ({ taskId, disabled, onDelete }) => {
    const intl = useIntl();

    const taskCanView = useSelector(getAccountTaskCanView(taskId));
    const taskTitle = useSelector(getAccountTaskTitle(taskId));
    const taskPath = useSelector(getAccountTaskPath(taskId));

    if(!taskPath){
        return null;
    }

    return (
        <Chip
            disabled={disabled}
            label={taskCanView ? taskPath.join(' / ') + ' / ' + taskTitle : `['${intl.formatMessage({id: 'Task not visible to you'})}']`}
            onDelete={onDelete}
        />
    );
};


const TaskDetailDependenciesList = ({ taskId }) => {
    const taskDependencies = useSelector(getAccountTaskDependencies(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));
    
    const accountId = useSelector(getAccountId);

    const handleRemoveSingleDependency = (id) => {
        tracking('Delete Single Dependecy of Task', {accountId, taskId});
        const newDependencies = _.uniq(taskDependencies.filter(el => el !== id));
        firebaseEvents.changeTaskDependentOn(accountId, taskId, newDependencies);
    };
    
    let defaultValues = [];
    if (taskDependencies) {
        _.uniq(taskDependencies).forEach(taskId => {
            defaultValues.push({
                id: taskId
            });
        });
    }

    return (
        <div className={classnames('dependenciesSelect', 'premium')}>
            {defaultValues.map((option, index) => {
                return (
                    <TaskDetailDependenciesListItem
                        taskId={option.id}
                        key={`${option}-${index}`}
                        disabled={!canManage}
                        onDelete={() => handleRemoveSingleDependency(option.id)}
                    />
                );
            })}
        </div>
    );
};

TaskDetailDependenciesList.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailDependenciesList;
