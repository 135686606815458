import { useIntl } from 'react-intl';
import Proptypes from 'prop-types';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';

export default function NestedList({ tree, checkboxData = [], handleCheckbox, handleSelectAll }) {
    const intl = useIntl();
    const [openGroups, setOpenGroups] = React.useState([]);
    const allItens = Object.values(tree).map(el => el.childs || [{id: el.id}] ).flat().map(el => el.id);

    const handleClick = (id, hasChilds) => {
        if(hasChilds){
            if(openGroups.includes(id)) {
                return setOpenGroups(openGroups.filter(el => el !== id));
            }
            return setOpenGroups([...openGroups, id]);
        }
        handleCheckbox(id);
    };
    
    const onSelectAll = () => {
        handleSelectAll(allItens.length === checkboxData.length ? [] : allItens );    
    };
    
    return (
        <List>
            <ListItem button>
                <Button variant="contained" onClick={onSelectAll}>
                    {allItens.length === checkboxData.length ? intl.formatMessage({id: 'Unselect All'}) : intl.formatMessage({id: 'Select All'})}
                </Button>
            </ListItem>
            { Object.keys(tree).map(mainKey => {
                const hasChilds = !!tree[mainKey].childs;

                return <React.Fragment key={`breakdown-drop-${mainKey}`}>
                    <ListItem button size="small" onClick={() => handleClick(mainKey, hasChilds)}>
                        {hasChilds ? openGroups.includes(mainKey) ? <ArrowDropDown /> : <ArrowRight /> : null }
                        {!hasChilds && <Checkbox
                            checked={checkboxData.includes(tree[mainKey].id)}
                        />}
                        <ListItemText primary={tree[mainKey].title} />
                    </ListItem>
                    {hasChilds && tree[mainKey].childs.map(child => 
                        <Collapse  key={`breakdown-drop-${child.id}`} in={openGroups.includes(mainKey)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem onClick={() => handleCheckbox(child.id)} button>
                                    <Checkbox
                                        checked={checkboxData.includes(child.id)}
                                    />
                                    <ListItemText primary={child.title} />
                                </ListItem>
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>;
            })}
        </List>
    );
}

NestedList.propTypes = {
    tree: Proptypes.object.isRequired,
    checkboxData: Proptypes.array.isRequired,
    handleCheckbox: Proptypes.func.isRequired, 
    handleSelectAll: Proptypes.func.isRequired,
};