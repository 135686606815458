import React from 'react';
import { useSelector } from 'react-redux';
import { Card, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { setSyncAppEffortSettingsByField } from '../../../../utils/controllers/syncApp/syncApp';
import { effortChangeTypes, EVENT_NAMES_BY_TOOL } from './utils.js';
import SyncronizeOptions from './SyncronizeOptions';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { getAccountDataSyncApp } from '../../../../utils/selectors/accountData';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { segmentTrack } from '../../../../utils/firebaseEvents';
import { getUserId } from '../../../../utils/selectors/user';

const EffortType = ({ type, toolName, syncAppId }) => {
    const { t } = useInternalization();
    const currentUserId = useSelector(getUserId);
    const [syncAppEffortSettings, setSyncAppEffortSettings] = React.useState(null);

    const syncAppData = useSelector(getAccountDataSyncApp(syncAppId));

    React.useEffect(() => {
        if(!syncAppData) return;

        const effortSettings = syncAppData?.effortSettings;

        if(effortSettings){
            setSyncAppEffortSettings(effortSettings);
        }
    }, [syncAppData]);

    const handleUpdateSync = (e) => {
        const value = parseInt(e.target.value);
        setSyncAppEffortSettingsByField(syncAppId, 'import/on', value);
        setSyncAppEffortSettingsByField(syncAppId, 'onUpdate/on', value);

        segmentTrack(EVENT_NAMES_BY_TOOL[toolName][value], { userId: currentUserId });
    };

    const optionsValuesByType = {
        'onAddProject': syncAppEffortSettings?.import?.on || 0,
        'onToolUpdate': syncAppEffortSettings?.onUpdate?.on || 0,
    };

    return (
        <Card elevation={0}>
            <FormControl component="fieldset">
                <RadioGroup
                    name="effort"
                    value={optionsValuesByType[type]}
                    onChange={(e) => handleUpdateSync(e, type)}
                >
                    {effortChangeTypes[toolName][type].options.map((option, index) => { // Sync or Don't sync
                        return <div key={index}>
                            <FormControlLabel
                                value={index} 
                                control={<Radio size="small" />} 
                                label={t(option)} 
                            />
                        </div>;
                    })}
                            
                    {optionsValuesByType[type] === 1 && ( // 1: Syncronization extra options will be displayed
                        <SyncronizeOptions 
                            toolName={toolName} 
                            effortChangeType={type}
                            syncAppEffortSettings={syncAppEffortSettings}
                            syncAppId={syncAppId}
                        />
                    )}

                </RadioGroup>
            </FormControl>
        </Card>
    );
};

export default withCustomErrorBoundary(EffortType);