import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Unautorized = () => {
    const intl = useIntl();

    return (
        <div className='Unautorized'>
            <div><img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" /></div>
            <h1>{intl.formatMessage({id:'You are not allowed here!'})}</h1>
            <p>{intl.formatMessage({id:'unautorized.page.content'})}</p>
            <Link to="/">&laquo; {intl.formatMessage({id:'not.found.404.link.text'})}</Link>
        </div>
    );
};

export default withCustomErrorBoundary(Unautorized);