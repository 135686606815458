import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import {  downloadFile, getUserInfo } from '../utils';

import Paper from '@material-ui/core/Paper';
import ChartWithList  from '../ChartWithList';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';


const UpcomingEvents = ({
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);
    const [data, setData] = useState({
        teamMembersData: {
            done: {},
            toBeDone: {}
        },
        skillsData: {
            done: {},
            toBeDone: {}
        },
        tasksData: {
            done: {},
            toBeDone: {}
        },
        totalTasksDone: 0,
        totalTasksToBeDone: 0
    });

    useEffect(() => {
        // in the case of beeing root lvl
        // get all of the sub childs
        const workspaceMainItens = workspaceSelected === 'root' ?
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
            : _.get(stateTasks[workspaceSelected], 'childrens' , [workspaceSelected]);
        const workspaceMainItensChilds  = {};
        workspaceMainItens.forEach(({id}) => {
            workspaceMainItensChilds[id] =  [..._.get(childsParents, id , [id])];
        });

        const workspaceMainItensChildsToEntries = Object.entries(workspaceMainItensChilds);
        let totalTasksDone = 0;
        let totalTasksToBeDone = 0;

        const workspaceTasks = workspaceSelected === 'root' ?
            Object.keys(stateTasks) :
            _.get(childsParents, workspaceSelected , [workspaceSelected]);

        if( workspaceTasks.length) {
            const teamMembersData =  {
                done: {},
                toBeDone: {}
            };
            const skillsData =  {
                done: {},
                toBeDone: {}
            };
            const tasksData =  {
                done: {},
                toBeDone: {}
            };
            workspaceTasks.forEach(taskId => {
                const currentTask = stateTasks[taskId];

                if(!currentTask || (stateTasks.parent &&  !stateTasks[stateTasks.parent]) || currentTask.childrens){
                    return;
                }


                let datasetType = 'done';
                // handle team members
                let taskUsers = [];

                // split
                if (currentTask.status !== 'done') {
                    if(currentTask.estimations?.userId){
                        taskUsers = (Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId])
                            .map(el => ({isForced: false, userId: el , avatar: getUserInfo(accountTeamMembers[el]).avatar,  name: getUserInfo(accountTeamMembers[el]).displayName}));
                    } else if(currentTask.forcedUser) {
                        taskUsers = (Array.isArray(currentTask.forcedUser) ? currentTask.forcedUser : [currentTask.forcedUser]).map(el => ({isForced: true, avatar: getUserInfo(accountTeamMembers[el]).avatar, userId: el, name: getUserInfo(accountTeamMembers[el])?.displayName }));
                    }
                    totalTasksToBeDone++;
                    datasetType = 'toBeDone';
                } else {
                    totalTasksDone++;
                    if(currentTask.userWorking) {
                        taskUsers = (Array.isArray(currentTask.userWorking) ? currentTask.userWorking : [currentTask.userWorking])
                            .map(el => ({userId: el ,  name: getUserInfo(accountTeamMembers[el])?.displayName}));
                    } else {
                        taskUsers = (Array.isArray(currentTask.doneBy) ? currentTask.doneBy : [currentTask.doneBy])
                            .map(el => ({userId: el, name: getUserInfo(accountTeamMembers[el])?.displayName}));
                    }
                }
                // handle skills
                const skills = currentTask?.skill?.length ? currentTask.skill : [];
                const skillDatasets = skills.map(skillId => skillsData[datasetType][skillId]).filter(Boolean);

                if (skillDatasets.length) {
                    skillDatasets.forEach(skillDataset => {
                        skillDataset.value += 1;
                    });
                } else {
                    if (skills.length) {
                        skills.forEach(skillId => {
                            skillsData[datasetType][skillId] = {
                                id: skillId,
                                name: accountSkills[skillId]?.name,
                                value: 1
                            };
                        });
                    } else {
                        skillsData[datasetType]['none'] = {
                            id: 'none',
                            name: 'No Skill defined',
                            value: 1
                        };
                    }
                }
                // handle team members
                if(taskUsers.length) {
                    taskUsers.forEach(({userId}) => {
                        const userDetail = accountTeamMembers[userId];
                        const userData = teamMembersData[datasetType][userId];

                        if(userData){
                            userData.value += 1;
                        } else {
                            teamMembersData[datasetType][userId] = {
                                id: userId,
                                value: 1,
                                name: userDetail?.displayName || '(deleted user)'
                            };
                        }
                    });
                } else {
                    const userData = teamMembersData[datasetType].none;

                    if(userData){
                        userData.value += 1;
                    } else {
                        teamMembersData[datasetType].none = {
                            id: 'none',
                            value: 1,
                            name: 'Not Assigned'
                        };
                    }
                }
                // get parent
                const mainItemEntrie = workspaceMainItensChildsToEntries.find(([, childs]) => childs.includes(taskId) );
                if(mainItemEntrie) {
                    const taskDataset = tasksData[datasetType][mainItemEntrie[0]];

                    if(taskDataset){
                        taskDataset.value += 1;
                    } else {
                        tasksData[datasetType][mainItemEntrie[0]] = {
                            id: mainItemEntrie[0],
                            value: 1,
                            name: stateTasks[mainItemEntrie[0]].title
                        };
                    }
                } else {
                    const datasetEntry = tasksData[datasetType][taskId];
                    if(datasetEntry){
                        datasetEntry.value += 1;
                    } else {
                        tasksData[datasetType][taskId] = {
                            id: taskId,
                            value:1,
                            name: stateTasks[taskId].title
                        };
                    }
                }

            });

            setData({
                skillsData,
                tasksData,
                teamMembersData,
                totalTasksDone,
                totalTasksToBeDone
            });
        }
    }, [workspaceSelected,  stateTasks, accountTeamMembers, childsParents, accountSkills]);

    const exportCSV = useCallback((data, totalDataOfChart , name) => {
        const headers = 'Sub Itens; % Task Completion';
        const rows = Object
            .values(data)
            .sort((a,b) => b.value - a.value)
            .map(el => {
                const lineValue = el.value > 0 ? `${parseFloat((el.value / totalDataOfChart) * 100).toFixed(2)}%` : `${0}%`;
                return `${el.name};${lineValue};`;
            });
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${headers}\r\n${rows.join('\r\n')}`), name);
    }, []);

    let hasData = (toCheck) =>  Object.keys(toCheck).find(key => toCheck[key].value);

    return <Paper className={'breakdown-page'} >
        <ChartWithList
            isEmpty={!hasData(data.tasksData.toBeDone)}
            key='ChartWithList-task-sub-tein'
            chartData={data.tasksData.toBeDone}
            chartTitle='Task to be done Repartition Per Sub Items'
            totalDataOfChart={data.totalTasksToBeDone}
            exportCSV={exportCSV}
            id={'subitens'}
        />
        <ChartWithList
            isEmpty={!hasData(data.teamMembersData.toBeDone)}
            key='ChartWithList-task-By-Team-Members'
            chartData={data.teamMembersData.toBeDone}
            chartTitle='Task to be done Repartition Per Team Members'
            totalDataOfChart={data.totalTasksToBeDone}
            exportCSV={exportCSV}
            id={'teammembers'}
        />
        <ChartWithList
            isEmpty={!hasData(data.skillsData.toBeDone)}
            key='ChartWithList-task-By-Skills'
            chartData={data.skillsData.toBeDone}
            chartTitle='Task to be done Repartition Per Skills'
            totalDataOfChart={data.totalTasksToBeDone}
            exportCSV={exportCSV}
            id={'skills'}
        />
    </Paper>;
};

UpcomingEvents.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(UpcomingEvents);