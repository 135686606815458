import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import Input from '@material-ui/core/Input';

import Proptypes from 'prop-types';
import classnames from 'clsx';

const ExpensesCreate = ({ handleOnAdd, open, value, handleClose }) =>  {
    const intl = useIntl();
    const [inputValue, setValue] = useState(value);
    const [selectedColor, setSelectedColor] = useState(value);

    const handleInputs = ({ target: { value }}) => {
        setValue(value);
    };

    const updateSelectedColor = (value) => {
        setSelectedColor(value);
    };

    const onAdd = () => {
        handleOnAdd({name: inputValue, color: selectedColor});
    };

    return <Dialog className="Expenses" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <MuiDialogTitle className="title" disableTypography >
            <Typography variant="h6">{intl.formatMessage({id: 'expense.add-new-expense-type'})}</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="new-type" dividers>
            <Input
                onChange={handleInputs}
                value={inputValue}
                id="new-tpe"
                label={intl.formatMessage({id: 'new-expense-type'})}
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={intl.formatMessage({id: 'new-expense-type'})}
            />
            <div className="colors">
                <div onClick={()=> updateSelectedColor(1)} className={classnames('color1', {active: selectedColor === 1})} />
                <div onClick={()=> updateSelectedColor(2)} className={classnames('color2', {active: selectedColor === 2})} />
                <div onClick={()=> updateSelectedColor(3)} className={classnames('color3', {active: selectedColor === 3})} />
                <div onClick={()=> updateSelectedColor(4)} className={classnames('color4', {active: selectedColor === 4})} />
                <div onClick={()=> updateSelectedColor(5)} className={classnames('color5', {active: selectedColor === 5})} />
                <div onClick={()=> updateSelectedColor(6)} className={classnames('color6', {active: selectedColor === 6})} />
                <div onClick={()=> updateSelectedColor(7)} className={classnames('color7', {active: selectedColor === 7})} />
                <div onClick={()=> updateSelectedColor(8)} className={classnames('color8', {active: selectedColor === 8})} />
                <div onClick={()=> updateSelectedColor(9)} className={classnames('color9', {active: selectedColor === 9})} />
                <div onClick={()=> updateSelectedColor(10)} className={classnames('color10', {active: selectedColor === 10})} />
            </div>
        </MuiDialogContent>
        <MuiDialogActions>
            <Button 
                variant="contained" color="primary" onClick={onAdd}>
                {intl.formatMessage({id: 'Add new Type'})}
            </Button>
            <Button variant="contained" onClick={handleClose}>
                {intl.formatMessage({id: 'Cancel'})}
            </Button>
        </MuiDialogActions>
    </Dialog>;
};


ExpensesCreate.propTypes = {
    editExpense: Proptypes.object,
    open: Proptypes.bool.isRequired,
    handleClose: Proptypes.func.isRequired,
    handleAdd: Proptypes.func.isRequired
}; 

export default ExpensesCreate;