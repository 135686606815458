import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
    connectGoogleCalendar,
    connectivityButtonLabel,
    connectMicrosoftCalendar,
    connectSyncTool,
    connnectSlackUrl,
    SYNC_APP_TYPE
} from '../utils';
import { getUserId, getUserPermissionsAdmin } from '../../../utils/selectors/user';
import { getAccountId } from '../../../utils/selectors/account';
import Button from '../../../components/Button';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { JiraServerDataModal } from './JiraServerAuthModal/JiraServerAuthModal';
import useModal from '../../../utils/hooks/useModal';

const StatusActionButton = ({ hasError, status, toolName }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingJiraServerModal, toggleJiraServerModal] = useModal();

    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const canAdmin = useSelector(getUserPermissionsAdmin);

    const connectHandlers = {
        slack: () => connnectSlackUrl(),
        clickup: () => connectSyncTool({ type: SYNC_APP_TYPE.CLICKUP}),
        jira: () => connectSyncTool({ type: SYNC_APP_TYPE.JIRA }),
        jiraServer: () => toggleJiraServerModal(),
        asana: () => connectSyncTool({ type: SYNC_APP_TYPE.ASANA }),
        trello: () => connectSyncTool({ type: SYNC_APP_TYPE.TRELLO }),
        basecamp: () => connectSyncTool({ type: SYNC_APP_TYPE.BASECAMP }),
        'google-calendar': () => connectGoogleCalendar(userId, accountId),
        'microsoft-calendar': () => connectMicrosoftCalendar(userId, accountId),
    };

    const handleClick = async () => {
        if(hasError) {
            try{
                setIsLoading(true);
                await connectHandlers[toolName]();
            }catch(err){
                toastr.error(err.message);
            }finally{
                setIsLoading(false);
            }
            return;
        }

        if(status === 'Connected') {
            browserHistory.push(`/settings/integrations/${toolName}`);
            return;
        }

        try{
            setIsLoading(true);
            await connectHandlers[toolName]();
        }catch(err){
            toastr.error(err.message);
        }finally{
            setIsLoading(false);
        }
    };

    return (
        <>
            {
                toolName === SYNC_APP_TYPE.JIRASERVER && (
                    <JiraServerDataModal
                        open={isShowingJiraServerModal}
                        handleClose={toggleJiraServerModal}
                        handleConnect={(appData) => connectSyncTool({
                            type: SYNC_APP_TYPE.JIRASERVER,
                            currentUserId: userId,
                            accountId,
                            appData,
                        })}
                    />
                )
            }
            <Button
                isLoading={isLoading}
                className={`${status} ${isLoading && 'Loading'}`}
                onClick={handleClick}
                disabled={!canAdmin && toolName === 'clickup'}
            >
                {intl.formatMessage({id: !hasError ? connectivityButtonLabel[status] : 'reconnect'})}
            </Button>
        </>
    );
};

export default withCustomErrorBoundary(StatusActionButton);