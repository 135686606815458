import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import classnames from 'clsx';
import _ from 'underscore';
import Button from '@material-ui/core/Button';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Switch, Typography,
} from '@material-ui/core';
import {
    getAccountBasePermissions,
    getAccountId,
    getAccountPlan,
    getAccountTaskHasChildren,
    getAccountTaskOriginalPermissions,
    getAccountTaskParent,
    getAccountTaskPermissions,
    getAccountTasks,
    getAccountUsers,
} from '../../utils/selectors/account';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { taskPermissionsTypes } from './constants';
import PermissionsAutocomplete from './components/PermissionsAutocomplete';
import ChildrensSwitches from './components/ChildrenSwitches';
import { permissionsByPlan } from '../../utils/constants';
import Unautorized from '../Unautorized/Unautorized';
import ComingFromSection from './components/ComingFromSection';


const Permissions = ({ router, location }) => {
    const intl = useIntl();
    const [owners, setOwners] = useState([]);
    const [managers, setManagers] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [viewers, setViewers] = useState([]);

    const [revertPermissions, setRevertPermissions] = useState(false);
    const [applyToChildrens, setApplyToChildrens] = useState(true);
    const [applyToAllChildrens, setApplyToAllChildrens] = useState(true);
    const [editActive, setEditActive] = useState(false);

    const [canWorkersCreateTask, setCanWorkersCreateTask] = useState(true);
    const [canWorkersChangeEffort, setCanWorkersChangeEffort] = useState(true);

    const taskId = router?.params?.uid;
    const plan = useSelector(getAccountPlan);
    const accountTasks = useSelector(getAccountTasks);
    const taskPermissions = useSelector(getAccountTaskPermissions(taskId));
    const taskHasChildren = useSelector(getAccountTaskHasChildren(taskId));
    const taskParent = useSelector(getAccountTaskParent(taskId));
    const accountBasePermissions = useSelector(getAccountBasePermissions);
    const originalTaskPermissions = useSelector(getAccountTaskOriginalPermissions(taskId));
    const users = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);

    const handleClose = React.useCallback(() => {
        browserHistory.push(location.pathname.replace('/permissions', ''));
    }, []);

    const handleSetEditActive = React.useCallback(() => setEditActive(true), []);

    const handleApplyToChildrens = React.useCallback(() => setApplyToChildrens(!applyToChildrens), [applyToChildrens]);

    const handleApplyToAllChildren = React.useCallback(() => {
        setApplyToAllChildrens(!applyToAllChildrens);
    }, [applyToAllChildrens]);

    const handleCancelChange = React.useCallback(() => {
        setRevertPermissions(false);
        setEditActive(false);
    }, []);

    const handleUpdateOwners = React.useCallback((newOwnersIds) => {
        const updatedManagersIds = _.filter(managers, (managerId) => !newOwnersIds.includes(managerId));
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newOwnersIds.includes(viewerId));

        setOwners(newOwnersIds);
        setManagers(updatedManagersIds);
        setViewers(updatedViewersIds);
    }, [managers, viewers]);

    const handleUpdateManagers = React.useCallback((newManagersIds) => {
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newManagersIds.includes(viewerId));

        setManagers(newManagersIds);
        setViewers(updatedViewersIds);
    }, [viewers]);

    const handleUpdateWorkers = React.useCallback((newWorkersIds) => {
        const updatedViewersIds = _.filter(viewers, (viewerId) => !newWorkersIds.includes(viewerId));

        setWorkers(newWorkersIds);
        setViewers(updatedViewersIds);
    }, [viewers]);

    const handleUpdateViewers = React.useCallback((newViewersIds) => {
        const updatedManagersIds = _.filter(managers, (managerId) => !newViewersIds.includes(managerId));
        const updatedOwnersIds = _.filter(owners, (ownerId) => !newViewersIds.includes(ownerId));

        setViewers(newViewersIds);
        setManagers(updatedManagersIds);
        setOwners(updatedOwnersIds);
    }, [managers, owners]);

    const handleChangePermissions = React.useCallback(({ usersIds, type, actionType }) => {
        const removeByPermissionType = {
            owners: () => setOwners(_.filter(owners, (id) => usersIds.includes(id))),
            managers: () => setManagers(_.filter(managers, (id) => usersIds.includes(id))),
            workers: () => setWorkers(_.filter(workers, (id) => usersIds.includes(id))),
            viewers: () => setViewers(_.filter(viewers, (id) => usersIds.includes(id))),
        };

        const updateByPermissionType = {
            owners: () => handleUpdateOwners(usersIds),
            managers: () => handleUpdateManagers(usersIds),
            workers: () => handleUpdateWorkers(usersIds),
            viewers: () => handleUpdateViewers(usersIds),
        };

        if (actionType === 'remove-option') {
            removeByPermissionType[type]();
            return;
        }

        updateByPermissionType[type]();
    }, [
        handleUpdateOwners,
        handleUpdateManagers,
        handleUpdateWorkers,
        handleUpdateViewers,
        owners,
        managers,
        workers,
        viewers
    ]);

    const handleSave = React.useCallback(() => {
        if (!users) return;

        const data = {
            owners,
            managers,
            workers,
            viewers,
            revertPermissions,
            applyToChildrens,
            applyToAllChildrens,
            canWorkersCreateTask,
            canWorkersChangeEffort,
        };

        const previousComingFrom = taskPermissions?.comingFrom;

        // Check if they exist before adding
        data.owners = _.filter(data.owners, (userId) => !!users[userId]);
        data.managers = _.filter(data.managers, (userId) => !!users[userId]);
        data.workers = _.filter(data.workers, (userId) => !!users[userId]);
        data.viewers = _.filter(data.viewers, (userId) => !!users[userId]);

        firebaseEvents.addChallenge('changeTaskPermissions');
        firebaseEvents.setPermissions(accountId, taskId, data, previousComingFrom, intl);

        handleClose();
    }, [
        users,
        owners,
        managers,
        workers,
        viewers,
        accountId,
        taskId,
        taskPermissions,
        revertPermissions,
        handleClose,
        canWorkersCreateTask,
        canWorkersChangeEffort,
    ]);

    const handleDeleteCurrentPermissions = React.useCallback(() => {
        setRevertPermissions(true);
        setApplyToChildrens(true);
        setApplyToAllChildrens(true);
    }, []);

    const setCurrentPermissions = React.useCallback(() => {
        if (users && taskPermissions && accountTasks) {
            const comingFrom = taskPermissions?.comingFrom;
            const permissions = (comingFrom === 'base' && accountBasePermissions) ? accountBasePermissions : accountTasks[comingFrom]?.permissions;

            const adminsIds = _.filter(users, (user) => user.permissions.admin).map((user) => user.userId) || [];
            const ownersIds = _.uniq([...permissions?.owners || [], ...adminsIds]);
            const managersIds = permissions?.managers || [];
            const viewersIds = permissions?.viewers || [];
            const workersIds = originalTaskPermissions ? originalTaskPermissions : (permissions?.workers || []);
            const canWorkersCreateTask = permissions?.canWorkersCreateTask ?? true;
            const canWorkersChangeEffort = permissions?.canWorkersChangeEffort ?? true;

            setOwners(ownersIds);
            setManagers(managersIds);
            setWorkers(workersIds);
            setViewers(viewersIds);
            setEditActive(taskPermissions?.comingFrom === taskId);

            setCanWorkersCreateTask(canWorkersCreateTask);
            setCanWorkersChangeEffort(canWorkersChangeEffort);
        }
    }, [users, taskPermissions, accountBasePermissions, taskId, accountTasks]);

    useEffect(() => {
        if (users && taskPermissions && accountTasks) {
            setCurrentPermissions();
        }
    }, [users, taskPermissions, accountBasePermissions, setCurrentPermissions, accountTasks]);

    const autocompleteStateOptions = {
        owners,
        managers,
        workers,
        viewers,
    };

    const PermissionComponent = ({ type }) => {
        const Component = () => (
            <PermissionsAutocomplete
                originalTaskPermissions={originalTaskPermissions}
                permissionType={type}
                onChange={handleChangePermissions}
                currentValues={autocompleteStateOptions[type]}
                owners={owners}
                managers={managers}
                workers={workers}
                viewers={viewers}
            />
        );

        if (type === 'workers') {
            return (
                <>
                    <Component />
                    <FormControlLabel
                        className="workers-checkbox"
                        control={(
                            <Switch
                                size="small"
                                checked={canWorkersCreateTask}
                                onChange={(_, checked) => setCanWorkersCreateTask(checked)}
                                color="secondary"
                            />
                        )}
                        label="Workers can create tasks"
                    />
                    <FormControlLabel
                        className="workers-checkbox"
                        control={(
                            <Switch
                                size="small"
                                checked={canWorkersChangeEffort}
                                onChange={(_, checked) => setCanWorkersChangeEffort(checked)}
                                color="secondary"
                            />
                        )}
                        label="Workers can change efforts"
                    />
                </>
            );
        }

        return <Component />;
    };

    return (
        <Dialog
            className="Permissions"
            open
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            data-sidebar="preventClose"
        >
            <DialogTitle>{intl.formatMessage({ id: 'Permissions' })}</DialogTitle>
            <DialogContent className="content">

                <ComingFromSection
                    comingFrom={taskPermissions?.comingFrom || 'base'}
                    taskId={taskId}
                    taskParent={taskParent}
                    revertPermissions={revertPermissions}
                    editActive={editActive}
                    cancelChange={handleCancelChange}
                    setEditActive={handleSetEditActive}
                    deletePermissions={handleDeleteCurrentPermissions}
                />

                {permissionsByPlan.permissionsTask.includes(plan) && taskPermissions ? (
                    <div className={classnames('permSettings',
                        { inactive: !(editActive || taskPermissions?.comingFrom === taskId) })
                    }
                    >
                        {!revertPermissions ? (
                            <>
                                {taskPermissionsTypes.map((type) => (
                                    <>
                                        <PermissionComponent key={type} type={type} />
                                        <Divider variant="middle" />
                                    </>
                                ))}
                            </>
                        ) : (
                            <div>
                                <Typography variant="body2" className="revertText">
                                    {intl.formatMessage({ id: 'permissions.revert.description' })}
                                </Typography>
                                <Typography variant="body2" className="revertText2">
                                    {intl.formatMessage({ id: 'permissions.revert.description2' })}
                                </Typography>
                            </div>
                        )}

                        {editActive && taskHasChildren && (
                            <ChildrensSwitches
                                revertPermissions={revertPermissions}
                                applyToAllChildrens={applyToAllChildrens}
                                applyToChildrens={applyToChildrens}
                                handleApplyToChildren={handleApplyToChildrens}
                                handleApplyToAllChildren={handleApplyToAllChildren}
                            />
                        )}

                        <Divider variant="middle" />
                    </div>
                ) : (
                    <Unautorized />
                )}

            </DialogContent>

            <DialogActions className="actions">
                {permissionsByPlan.permissionsTask.includes(plan) && taskPermissions ? (
                    <>
                        <Button variant="contained" onClick={handleClose}>
                            {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!(editActive || taskPermissions?.comingFrom === taskId)}
                            onClick={handleSave}
                            color="primary"
                        >
                            {intl.formatMessage({ id: 'Apply permissions' })}
                        </Button>
                    </>
                ) : (
                    <Button variant="contained" onClick={handleClose}>
                        {intl.formatMessage({ id: 'Cancel' })}
                    </Button>
                )}

            </DialogActions>
        </Dialog>
    );
};

export default memo(withRouter(withCustomErrorBoundary(Permissions)));
