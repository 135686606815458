import React from 'react';
import { useSelector } from 'react-redux';
import { getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { updateNotification } from '../../utils/databaseQuerys/notifications';
import { useIntl } from 'react-intl';
import Tooltip from 'rc-tooltip';
import UserAvatar from '../../components/UserAvatar/UserAvatar';

const SingledNotificationLine = ({ userColor, field, notificationId, userAvatar, userName, date, comparasionInfo, label, isRead, labelValues = {} }) => {
    const intl = useIntl();
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);
    
    const handleReadedState = (e) => {
        e.stopPropagation(e);

        updateNotification({
            accountId: currentAccountId,
            field: 'readed',
            id: notificationId,
            userId:  currentUserId,
            value: !isRead
        });
    };

    return (
        <div className="Notification-Container">
            <div data-opensidebar="true" data-field={field} className="Notification-Information">
                <div>
                    <UserAvatar
                        className="avatar"
                        displayName={userName}
                        avatarUrl={userAvatar}
                        color={userColor}
                    />
                </div>
                <div>
                    <div>
                        <span className="userName">{userName}</span> <strong>{intl.formatMessage({id: label}, labelValues)}</strong>
                    </div>
                    <div>{date}</div>
                </div>
            </div>
            {comparasionInfo}
            <Tooltip placement="right" overlay={isRead ? 'Mark as unread' : 'Mark as read'} className="avatar">
                <div onClick={handleReadedState} className='mark-action'>
                    { !isRead && <div className='bullet' /> }
                </div>
            </Tooltip>
        </div>
    );
};

export default React.memo(SingledNotificationLine);