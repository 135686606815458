import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import _ from 'underscore';
import classnames from 'clsx';

import SortableHandleOpen from '@material-ui/icons/ExpandMore';
import SortableHandleClosed from '@material-ui/icons/ChevronRight';
import Checkbox from '@material-ui/core/Checkbox';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';

import { getChildsFromParent, getParentsBefore, getVisibleTasksOrdered } from '../../utils/utils';

export const isSelectDisabled = (id, taskId, currentParent, status, childrens, canManage, childsFromThisTask) => {
    if(
        !canManage ||
        taskId === id || 
        currentParent === id ||
        childsFromThisTask.includes(id) ||
        (status !== 'todo' && !childrens) ||
        (!currentParent && id === 'ROOT')
    ) {
        return true;
    }
    return false;
};

export const isCheckboxDisabled = (id, taskId, currentParent, status, childrens, childsFromThisTask, parentsBeforeThisTask, stateTasks) => {
    if( 
        (status !== 'todo' && !childrens) ||
        (taskId === id || _.flatten(getParentsBefore(id, stateTasks)).includes(taskId) || parentsBeforeThisTask.includes(id)) ||
        (taskId === id || (currentParent === id || (!currentParent && id === 'ROOT')) || childsFromThisTask.includes(id))
    ) {
        return true;
    }
    return false;
};

export const isIndeterminate = (checkedItems, id, stateTasks) => {
    if(
        !checkedItems?.includes(id) &&
        _.flatten(getChildsFromParent(id, stateTasks)).some((c) => checkedItems?.includes(c))
    ) {
        return true;
    }
    return false;
};

export const isChecked = (checkedItems, id, stateTasks) => {
    if(
        checkedItems?.includes(id) ||
        (
            stateTasks[id].childrens ? _.flatten(getChildsFromParent(id, stateTasks)).every((c) => c.type === 'task' &&
            checkedItems?.includes(c)) : false
        )
    ) {
        return true;
    }
    return false;
};

const SelectorsDialogTree = ({ checkedItems, onCheck, taskId, currentParent, type, newParent, onSelect, stateTasksReplace = null }) => {
    const [openLines, setOpenLines] = useState([]);
    const [visibleLines, setVisibleLines] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const [parentsBeforeThisTask, setParentsBeforeThisTask] = useState([]);
    const intl = useIntl();
    const stateTasks = stateTasksReplace || useSelector((state) => state.app.account.tasks);

    useEffect(() => {
        if(taskId) {
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
            setParentsBeforeThisTask(_.flatten(getParentsBefore(taskId, stateTasks)));
        }

        if (stateTasks && stateTasks[taskId]) {
            const parentsBefore = _.flatten(getParentsBefore(taskId, stateTasks));
            const visibleTasksOrdered = getVisibleTasksOrdered(taskId, stateTasks);

            if(type === 'changeParent') {
                const root = {
                    title: 'TOP LEVEL OF THE WORKSPACE',
                    id: 'ROOT',
                    index: 0,
                    indent: 0,
                    canView: true,
                    canManage: true,
                    status: 'todo',
                };

                setVisibleLines([root, ...visibleTasksOrdered.map((t) => ({
                    ...t,
                    indent: t.indent + 2,
                }))]);
                setOpenLines([root.id, ...parentsBefore]);
                return;
            }

            setVisibleLines(visibleTasksOrdered);
            setOpenLines(parentsBefore);
        }   
    }, [taskId, stateTasks, type]);

    const closeTasks = (id) => {
        if(id === 'ROOT') {
            setVisibleLines(visibleLines.filter((t) => t.id === 'ROOT'));
            setOpenLines([]);
            return;
        }

        const index = visibleLines.findIndex((el) => el.id === id);
        const parentBefore = visibleLines
            .slice(0, index + 1)
            .map((el) => el.parent);

        const lastChildIndexClicked = visibleLines
            .slice(index + 1)
            .findIndex((el) => !el.parent || parentBefore.includes(el.parent));

        if (lastChildIndexClicked >= 0) {
            setVisibleLines([
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + lastChildIndexClicked + 1),
            ]);
        } else {
            setVisibleLines(visibleLines.slice(0, index + 1));
        }

        const openTasksChildrens = _.flatten(getChildsFromParent(id, stateTasks)).filter((c) => visibleLines.map(task => task.id).includes(c));

        setOpenLines(
            openLines.filter((el) => el !== id && !openTasksChildrens.includes(el))
        );
    };

    const openTasks = (id) => {
        const index = visibleLines.findIndex((el) => el.id === id);

        if(id === 'ROOT') {
            setVisibleLines([
                ...visibleLines,
                ...Object.keys(stateTasks).filter((taskId) => !stateTasks[taskId].parent)
                    .map((taskId) => ({
                        ...stateTasks[taskId],
                        indent: 2
                    }))
                    .sort((a,b) => a.index > b.index ? 1 : -1)
            ]);
            setOpenLines(['ROOT']);
            return;
        }

        if (stateTasks[id] && stateTasks[id].childrens) {

            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...stateTasks[id].childrens
                    .filter((el) => el.type === 'task' && stateTasks[el.id].parent)
                    .map((cTask) => ({
                        ...stateTasks[cTask.id],
                        id: cTask.id,
                        indent: visibleLines[index].indent + 2,
                    }))
                    .sort((a, b) => (a.index > b.index ? 1 : -1)),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines([...newVisibleLines]);
            setOpenLines([...openLines, id]);
        } else {
            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines(newVisibleLines);
            setOpenLines([...openLines, id]);
        }
    };

    const toggleOpenClose = (id) => {
        if (openLines.includes(id)) {
            closeTasks(id);
        } else {
            openTasks(id);
        }
    };

    const handleClick = (e, id, isDisabled) => {
        const isClickable =  
            e.target.classList.contains('itemTitle') ||
            e.target.classList.contains('row') ||
            e.target.classList.contains('taskStatus') ||
            e.target.classList.contains('lineNode');
        
        if(!isDisabled && isClickable) {
            if(type === 'manageDependencies') {
                onCheck(id, e);
            } else {
                onSelect(id);
            }
        }
    };

    const getIcons = (status, id, childrens, openTasks) => {

        if (childrens && openTasks.includes(id) || id === 'ROOT' && openTasks.includes('ROOT')) {
            return <SortableHandleOpen className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        
        if (childrens && !openTasks.includes(id) || id === 'ROOT' && !openTasks.includes('ROOT')) {
            return <SortableHandleClosed className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        
        return <TaskStatusIcon taskId={taskId}/>;
        
    };

    const getCheckOrSelectLine = (id, isDisabled) => {   
        if(type === 'manageDependencies') {
            
            return <Checkbox
                color='primary'
                disabled={isDisabled}
                onChange={(e) => onCheck(id, e)} 
                inputProps={{'aria-label': 'secondary checkbox'}}
                checked={isChecked(checkedItems, id, stateTasks)}
                indeterminate={isIndeterminate(checkedItems, id, stateTasks)}
            />;

        } else {
            if((currentParent && currentParent === id || (!currentParent && id === 'ROOT'))) {
                return <span className='currentParent'>{intl.formatMessage({id: 'CURRENT PARENT' })}</span>;
            }

            if(id === newParent) {
                return <span onClick={() => onSelect(id)} className='newParent'>{intl.formatMessage({id: 'NEW PARENT' })}</span>;
            } 

            if(id == taskId) {
                return <span className='currentTaskId'>{intl.formatMessage({id: 'ACTIVE TASK' })}</span>;
            }
            
            if(!isDisabled) {
                return <span onClick={() => onSelect(id)} className='hoverSelect'>{intl.formatMessage({id: 'SELECT' })}</span>;
            }
        }
    };

    return (
        <div className='SelectorsDialogTree'>
            <ul className='SelectorsDialogTree__container'>
                {visibleLines.filter(t=>t.canView).map(({ id, title, indent, status, childrens, canManage }, index) => {

                    const isDisabled = type === 'changeParent' ?
                        isSelectDisabled(id, taskId, currentParent, status, childrens, canManage, childsFromThisTask) :
                        isCheckboxDisabled(id, taskId, currentParent, status, childrens, childsFromThisTask, parentsBeforeThisTask, stateTasks);

                    return (
                        <li key={`${id}-${index}`} onClick={(e) => handleClick(e, id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'} style={{ paddingLeft: `${indent}rem`, paddingRight: '0.6rem'}} >
                            
                            {getIcons(status, id, childrens, openLines)} 

                            <div className='lineNode'>
                                <span className={classnames('itemTitle', {hasNoChildrens: !childrens})}>{title}</span>
                            </div>

                            {getCheckOrSelectLine(id, isDisabled)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

SelectorsDialogTree.propTypes = {
    type: Proptypes.string.isRequired,
    newParent: Proptypes.string,
    taskId: Proptypes.string.isRequired,
    parent: Proptypes.string,
    checked: Proptypes.object,
    onSelect: Proptypes.func,
    onCheck: Proptypes.func,
};

export default SelectorsDialogTree;
