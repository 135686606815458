import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import classnames from 'clsx';


const CustomDatePicker = ({value, onChange, className  = '', ...rest }) => <dt {...rest} tab-index="1"  className="customDatePicker">
    <DatePicker
        format={'DD/MM/YYYY'}
        className={classnames('customDatePicker', className)}
        margin="normal"
        placeholder={'None'}
        value={value ? value : null}
        onChange={onChange}
        autoOk={true}
        fullWidth={true}
        cancelLabel={null}
        okLabel={null}
        disableOpenPicker={false}
        open={false}
        readOnly={true}
    />
</dt>;

export default CustomDatePicker;