import React, { useEffect, useState } from 'react';
import classnames from 'clsx';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { removeExpenseType, updateCostBudgetSettingsExpensesTypes, updateExpensesTypeColor, updateExpensesTypeName } from  '../../utils/controllers/tasks/expenses';
import {toastr} from 'react-redux-toastr';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ExpensesManager = () => {
    const expensesTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes);
    const [currentTypes, setCurrentTypes] = useState({});
    const intl = useIntl();

    
    useEffect(()=> {
        if(!_.isEmpty(expensesTypes)) {
            setCurrentTypes(expensesTypes);
        }
    }, [expensesTypes]);

    const onAddNewTag = () => {
        updateCostBudgetSettingsExpensesTypes({
            name: 'New expense type',
            color: 1, 
        }).then(ref => setCurrentTypes({
            ...currentTypes,
            [ref.key]: {
                name: 'New expense type',
                color: 1, 
            }
        }));
    };

    
    const changeTagColor = (expenseId, color) => {
        updateExpensesTypeColor(expenseId, color);
    };
    
    const updateTypeText = (expenseId) => {
        updateExpensesTypeName(expenseId, currentTypes[expenseId].name);
    };

    const deleteExpenseType = (expenseId) => {
        toastr.confirm(intl.formatMessage({id:  'expense-types-settings-text-warning'}), {
            onOk: () => {
                const clone = {...currentTypes};
                delete clone[expenseId];
                setCurrentTypes(clone);
                
                removeExpenseType(expenseId);
            },
            okText: intl.formatMessage({id: 'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id: 'toastr.confirm.cancel'}),
            id: ('toastr-confirm-delete')
        });
    };

    const changeTypeText = (expenseId,value ) => {
        const clone = {...currentTypes};
        clone[expenseId].name = value;
        setCurrentTypes(clone);
    };

    return <div className="ExpensesManager">
        <Button  variant="contained" color="primary" onClick={onAddNewTag }>{intl.formatMessage({id: 'add-new-expense-type'})}</Button>
        <p>
            {intl.formatMessage({id: 'expense-types-settings-text-intro'})}
        </p>
        <div className="listType">
            {Object.keys(currentTypes).map(id => 
                <div key={id} className="lineType">
                    <TextField
                        className="title"
                        value={currentTypes[id].name}
                        onChange={({target: { value } })=> { changeTypeText(id, value); }}
                        onBlur={ ()=>{ updateTypeText(id); }}
                    />
                    <div className="colors">
                        <div onClick={()=> changeTagColor(id, 1)} className={classnames('color1', {active: currentTypes[id].color === 1})}/>
                        <div onClick={()=> changeTagColor(id, 2)} className={classnames('color2', {active: currentTypes[id].color === 2})}/>
                        <div onClick={()=> changeTagColor(id, 3)} className={classnames('color3', {active: currentTypes[id].color === 3})}/>
                        <div onClick={()=> changeTagColor(id, 4)} className={classnames('color4', {active: currentTypes[id].color === 4})}/>
                        <div onClick={()=> changeTagColor(id, 5)} className={classnames('color5', {active: currentTypes[id].color === 5})}/>
                        <div onClick={()=> changeTagColor(id, 6)} className={classnames('color6', {active: currentTypes[id].color === 6})}/>
                        <div onClick={()=> changeTagColor(id, 7)} className={classnames('color7', {active: currentTypes[id].color === 7})}/>
                        <div onClick={()=> changeTagColor(id, 8)} className={classnames('color8', {active: currentTypes[id].color === 8})}/>
                        <div onClick={()=> changeTagColor(id, 9)} className={classnames('color9', {active: currentTypes[id].color === 9})}/>
                        <div onClick={()=> changeTagColor(id, 10)} className={classnames('color10', {active: currentTypes[id].color === 10})}/>
                    </div>
                    <DeleteIcon onClick={ ()=>{ deleteExpenseType(id); }} />
                </div>
            )}
        </div>
    </div>;
};

export default withCustomErrorBoundary(ExpensesManager);