import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as firebaseEvents from '../../utils/firebaseEvents';
import {canSee} from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import CopyIcon from '@material-ui/icons/FileCopy';
import {toastr} from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import _ from 'underscore';
import { permissionsByPlan } from '../../utils/constants';
import { getUserPermissions } from '../../utils/selectors/user';
import { getAccountApiKeys, getAccountId, getAccountPlan } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position='fixed';  //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        // 
    }

    document.body.removeChild(textArea);
}


const ApiKeys = () => {
    const intl = useIntl();
    const userPermissions = useSelector(getUserPermissions);
    const plan = useSelector(getAccountPlan);
    const accountId = useSelector(getAccountId);
    const apiKeys = useSelector(getAccountApiKeys);

    const addKey = () => {

        firebaseEvents.addChallenge('generateApikey');
        firebaseEvents.addApiKey(accountId);
    };

    const copyKey = (key) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(key);
            return;
        }
        navigator.clipboard.writeText(key);
        toastr.success('Key copied');
    };

    const changeName = (code, e) => {
        firebaseEvents.nameApiKey(
            code,
            e.target.value
        );
    };
    
    const deleteKey = (code) => {
        toastr.confirm(intl.formatMessage({id:'Are you sure you want to revoke this api key? All integrations using this key will not work anymore.'}), {
            onOk: () => {
                firebaseEvents.deleteApiKey(
                    code
                );
            },
            okText: intl.formatMessage({id:'toastr.revoke'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
        
    };
        

    if(!permissionsByPlan.apikeys.includes(plan) || !canSee(['admin'], {data: {permissions: userPermissions}})) return <Unautorized />;

    let keys = [];

    if(apiKeys){
        _.each(apiKeys, (key, code)=>{
            keys.push(
                <div key={code}>
                    <div className='divCode'>{code}</div>
                    <a title="copy code" onClick={()=>{ copyKey(code); }}><CopyIcon/></a>
                    {name}
                    <TextField
                        className="label"
                        placeholder={intl.formatMessage({id:'Api key label'})} 
                        value={key.name} 
                        onChange={(e)=>{ changeName(code, e); } }
                        fullWidth={true}
                    />
                    <Button aria-label="delete" variant="outlined" color="secondary" size="small" onClick={()=>{ deleteKey(code); } }>
                        {intl.formatMessage({id:'Revoke'})}    
                    </Button>
                </div>
            );
        });
    }

    return (
        <div className='ApiKeys'>
            <Typography variant="h1" gutterBottom>
                {intl.formatMessage({id:'Api keys'})}
            </Typography>

            <p>{intl.formatMessage({id:'apiKeys description'}, {
                link1: <a key="link1" href="https://api.planless.io" target="_blank">https://api.planless.io</a>,
                link2: <a key="link2" href="https://zapier.com" target="_blank">Zapier</a>
            })}</p>

            <Button variant="contained" color="secondary" onClick={addKey}>{intl.formatMessage({id:'Add an api key'})}</Button>

            <div className="keys">{keys}</div>
        </div>
    );

};

export default withCustomErrorBoundary(ApiKeys);