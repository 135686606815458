export var MEMBER_TYPES_LABELS = {
    userOnly: 'User Only',
    resourceOnly: 'Resource Only',
    userAndResource: 'User & Resource',
};
export var MEMBER_TYPES_DESCRIPTIONS = {
    userOnly: 'Manages project parameters, and can’t be assigned to tasks.',
    resourceOnly: 'Can be assigned to tasks, have their work planned, but can’t manage projects.',
    userAndResource: 'Can be assigned to tasks, have their work planned, and can manage projects.',
};
