import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'clsx';
import {toastr} from 'react-redux-toastr';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import {canSee} from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import { getAccountTags } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TagLine = ({title, tags, tagId, color})=>{
    const intl = useIntl();
    const [stateTitle, setStateTitle] = useState(title);

    const changeTagTitle = () => {
        
        if(stateTitle !== title){
            var titles = [];
            if(tags){
                for(var i in tags){
                    titles.push(tags[i].title);
                }
            }
            if(titles.indexOf(stateTitle) !== -1){
                toastr.error('A tag with this name already exist');
                setStateTitle(title);
            }
            else {
                firebaseEvents.changeTagTitle(tagId, stateTitle);
                toastr.success('Tag title changed');
            }
        }
    };


    const deleteTag = () => {
        toastr.confirm('Are you sure you want to delete this tag? It will be removed from all tasks that have this tag.', {
            onOk: () => {
                firebaseEvents.deleteTag(tagId);
            },
            okText: intl.formatMessage({id:'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
            id: ('toastr-confirm-delete')
        });
    };

    const changeTagColor = (newColor) => {
        if(newColor !== color){
            firebaseEvents.changeTagColor(tagId, newColor);
        }
    };

    return (
        <div className="tagLine">
            <TextField
                className="tagTitle"
                value={stateTitle}
                onChange={(e)=>{
                    setStateTitle(e.target.value);
                }}
                onBlur={changeTagTitle}
            />
            <div className="colors">
                <div onClick={()=>changeTagColor(1)} className={classnames('color1', {active: color === 1})}/>
                <div onClick={()=>changeTagColor(2)} className={classnames('color2', {active: color === 2})}/>
                <div onClick={()=>changeTagColor(3)} className={classnames('color3', {active: color === 3})}/>
                <div onClick={()=>changeTagColor(4)} className={classnames('color4', {active: color === 4})}/>
                <div onClick={()=>changeTagColor(5)} className={classnames('color5', {active: color === 5})}/>
                <div onClick={()=>changeTagColor(6)} className={classnames('color6', {active: color === 6})}/>
                <div onClick={()=>changeTagColor(7)} className={classnames('color7', {active: color === 7})}/>
                <div onClick={()=>changeTagColor(8)} className={classnames('color8', {active: color === 8})}/>
                <div onClick={()=>changeTagColor(9)} className={classnames('color9', {active: color === 9})}/>
                <div onClick={()=>changeTagColor(10)} className={classnames('color10', {active: color === 10})}/>
            </div>
            <DeleteIcon onClick={deleteTag} />
        </div>
    );
};

const TagsSettings = ()=>{
    const intl = useIntl();
    
    const tags = useSelector(getAccountTags);
    const userPermissions = useSelector(getUserPermissions);

    const getTagsList = () => {
        var results = [];
        if(tags){
            var temp = [];

            for(var tagId in tags){
                temp.push({id:tagId, title: tags[tagId].title, color: tags[tagId].color});
            }
            temp = _.sortBy(temp, 'title');

            temp.forEach((line)=>{
                results.push(
                    <TagLine 
                        key={line.id} 
                        tagId={line.id} 
                        title={line.title} 
                        color={line.color} 
                        tags={tags}
                    />);
            });

        }
        return results;
    };

    if(!canSee(['admin', 'boards'],  { data: { permissions: userPermissions }})) return <Unautorized />;

    return (
        <div className='TagsSettings'>
            <h1>{intl.formatMessage({id:'Tags'})}</h1>
            
            <Button variant="contained" color="primary" onClick={()=>firebaseEvents.addNewTag()}>{intl.formatMessage({id:'Add new tag'})}</Button>

            <p>
                {intl.formatMessage({id:'tags-settings-text-intro'})}
            </p>

            <div className="tagsList">
                {getTagsList()}
            </div>

            
        </div>
    );
};

export default withCustomErrorBoundary(TagsSettings);
