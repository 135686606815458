import React from 'react';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import 'firebase/auth';
import {toastr} from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const SocialSignup = () => {
    const intl = useIntl();

    const doSocialSignIn = (provider) => {

        if(provider.providerId === 'google.com'){
            provider.addScope('profile');
            provider.addScope('email');
        } else if(provider.providerId === 'facebook.com'){
            provider.addScope('public_profile');
            provider.addScope('email');
        }
        else if(provider.providerId === 'github.com'){
            provider.addScope('user:email');
        }

        firebase.auth().signInWithPopup(provider)
            .then((user)=>{
                if(!user.user.email) {
                    throw {
                        title: 'We could not complete your account registration. Please use other sign up methods available.',
                        code: 'no-email'
                    };
                }

                if(!user.user.emailVerified){
                    user.user.sendEmailVerification();
                    if(window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')){ window.analytics.track('Sent email verification'); }
                }
            })
            .catch(function(error) {
                if(error.code === 'auth/account-exists-with-different-credential'){
                    firebase.auth().fetchSignInMethodsForEmail(error.email).then((result)=>{
                        toastr.error(intl.formatMessage({id:'error.title'}), intl.formatMessage({id:error.code + '/' + result.toString()}));
                    });
                }
                else {
                    const title = intl.formatMessage({id:'error.title'});
                    const message = error?.code ? intl.formatMessage({id:error.code}) : ''  ;
                    toastr.error(title, message);
                }
            });
    };

    return (
        <div className='SocialSignup'>
            <Button
                onClick={()=>doSocialSignIn(new firebase.auth.GoogleAuthProvider())}>
                <img src="../../../stylesheets/assets/google.svg" alt="Google icon" />
            </Button>
            <Button
                onClick={()=>doSocialSignIn(new firebase.auth.FacebookAuthProvider())}>
                <img src="../../../stylesheets/assets/facebook.svg" alt="facebook icon" />
            </Button>
            <Button
                onClick={()=>doSocialSignIn(new firebase.auth.TwitterAuthProvider())}>
                <img src="../../../stylesheets/assets/twitter.svg" alt="twitter icon" />
            </Button>
            <Button
                onClick={()=>doSocialSignIn(new firebase.auth.GithubAuthProvider())}>
                <img src="../../../stylesheets/assets/github.svg" alt="github icon" />
            </Button>
        </div>
    );
};

export default withCustomErrorBoundary(SocialSignup);
