import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default  ({title, value, subTitle}) => <Card className="bigNumbersCard"
>
    <CardContent>
        <Typography color="textSecondary" gutterBottom>
            {title}
        </Typography>
        <Typography variant="h5" component="h5">
            {value}
        </Typography>
        {
            subTitle && <Typography>
                {subTitle}
            </Typography>
        }

    </CardContent>
</Card>;

