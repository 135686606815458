var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { Divider } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { browserHistory } from 'react-router';
import { memberResourcersTypes, MEMBER_TYPES, getOnlyResourceUsers } from '../../utils/memberTypes';
import { getAccountPlanNbusers, getAccountUserMemberType, getAccountUsers, getAccountUser, getAccountUserUid, getAccountId, } from '../../utils/selectors/account';
import { updateMemberType } from '../../utils/controllers/users/memberTypes';
import { MEMBER_TYPES_DESCRIPTIONS, MEMBER_TYPES_LABELS } from './constants';
import removeMemberFromTasksWorkers from '../../utils/controllers/tasks/assignees';
import removeMemberFromBasePermissions from '../../utils/controllers/account/settings/basePermissions/basePermissions';
import { toggleUpgradeSubscriptionModalShow } from '../../utils/actions/upgradeSubscriptionModal';
import { taskUserIsWorkingOn } from './utils/utils';
import useInternalization from '../../utils/hooks/useInternalization';
// eslint-disable-next-line import/no-cycle
import { defineClosedSortables, setLimitViewTo, showFilters, updateFilters, } from '../../views/App/AppActions';
var MemberTypeDropdown = function (_a) {
    var memberId = _a.memberId, _b = _a.fromViewMode, fromViewMode = _b === void 0 ? 'EditTeamMember' : _b, _c = _a.actionMode, actionMode = _c === void 0 ? 'update' : _c, onSelect = _a.onSelect, defaultMemberType = _a.defaultMemberType;
    var t = useInternalization().t;
    var dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    var currentMemberType = useSelector(getAccountUserMemberType(memberId)) || defaultMemberType;
    var memberUid = useSelector(getAccountUserUid(memberId));
    var memberData = useSelector(getAccountUser(memberId));
    var planNbusers = useSelector(getAccountPlanNbusers);
    var accountUsers = useSelector(getAccountUsers);
    var accountId = useSelector(getAccountId);
    if (!accountUsers)
        return null;
    var memberResources = getOnlyResourceUsers(accountUsers);
    var _d = __read(React.useState(null), 2), anchorEl = _d[0], setAnchorEl = _d[1];
    var _e = __read(React.useState(currentMemberType), 2), selectedMemberType = _e[0], setSelectedMemberType = _e[1];
    var handleSetNewMemberType = React.useCallback(function (memberType) {
        onSelect(memberType);
        setSelectedMemberType(memberType);
        setAnchorEl(null);
    }, [onSelect]);
    var handleUpdateMemberType = React.useCallback(function (newMemberType) {
        updateMemberType(memberId, newMemberType);
        if (newMemberType === 'userOnly') {
            removeMemberFromTasksWorkers(memberId);
            removeMemberFromBasePermissions({ userId: memberId, basePermissionTypes: ['workers'] });
        }
        setSelectedMemberType(newMemberType);
        setAnchorEl(null);
        toastr.success('Member type updated', '');
    }, [memberId]);
    var handleCheckPlanAndUpdateType = React.useCallback(function (newMemberType) {
        if ((_.size(memberResources) + 1) > parseFloat(planNbusers)) {
            dispatch(toggleUpgradeSubscriptionModalShow({ show: true }));
        }
        else {
            handleUpdateMemberType(newMemberType);
        }
    }, [dispatch, memberResources, handleUpdateMemberType]);
    var handleUpdateTypeAction = React.useCallback(function (newMemberType, updateType) {
        var memberUpdateTypes = {
            resourceOnlyToUserAndResource: function () { return handleUpdateMemberType(newMemberType); },
            userAndResourceToResourceOnly: function () { return handleUpdateMemberType(newMemberType); },
            resourceTypeToUserOnly: function () { return handleUpdateMemberType(newMemberType); },
            userOnlyToResourceOnly: function () { return handleCheckPlanAndUpdateType(newMemberType); },
            userOnlyToUserAndResource: function () { return handleCheckPlanAndUpdateType(newMemberType); },
        };
        if (taskUserIsWorkingOn(memberId) && newMemberType === 'userOnly') {
            toastr.confirm(t('memberTypes.confirm.goToTask.inprogress'), {
                onOk: function () {
                    // eslint-disable-next-line max-len
                    dispatch(updateFilters({ 0: { 0: { id: 'status', type: 'is', value: ['inprogress'] }, 1: { id: 'assigned', type: 'is', value: [memberId] }, andor: 'and' }, andor: 'and' }, accountId));
                    dispatch(showFilters(true));
                    dispatch(setLimitViewTo({ accountId: accountId, value: null }));
                    dispatch(defineClosedSortables([], accountId));
                    browserHistory.push('/tasks');
                },
                okText: t('memberTypes.confirm.gototask'),
                cancelText: t('CANCEL'),
            });
            return;
        }
        toastr.confirm(t("memberTypes.confirm.".concat(updateType)), {
            onOk: function () { return memberUpdateTypes[updateType](); },
            okText: t('CONFIRM'),
            cancelText: t('CANCEL'),
        });
    }, [handleUpdateMemberType, handleCheckPlanAndUpdateType, dispatch]);
    var handleSelectAction = React.useCallback(function (e, newMemberType) {
        if (newMemberType === currentMemberType)
            return;
        if (actionMode === 'create') {
            handleSetNewMemberType(newMemberType);
            return;
        }
        var updateType = '';
        if (currentMemberType === 'userAndResource' && newMemberType === 'resourceOnly') {
            updateType = 'userAndResourceToResourceOnly';
        }
        if (currentMemberType === 'userOnly' && newMemberType === 'resourceOnly') {
            updateType = 'userOnlyToResourceOnly';
        }
        if (memberResourcersTypes.includes(currentMemberType) && newMemberType === 'userOnly') {
            updateType = 'resourceTypeToUserOnly';
        }
        if (currentMemberType === 'userOnly' && newMemberType === 'userAndResource') {
            updateType = 'userOnlyToUserAndResource';
        }
        if (currentMemberType === 'resourceOnly' && newMemberType === 'userAndResource') {
            updateType = 'resourceOnlyToUserAndResource';
        }
        handleUpdateTypeAction(newMemberType, updateType);
    }, [handleSetNewMemberType, handleUpdateTypeAction, currentMemberType]);
    var handleOpenDropdown = React.useCallback(function (e) {
        setAnchorEl(e.currentTarget);
    }, []);
    var handleClose = React.useCallback(function () { return setAnchorEl(null); }, []);
    return (React.createElement("div", { className: "MemberTypeDropDown ".concat(fromViewMode) },
        React.createElement(List, { component: "nav", "aria-label": "Device settings" },
            React.createElement(ListItem, { button: true, "aria-haspopup": "true", "aria-controls": "lock-menu", "aria-label": "member type", onClick: handleOpenDropdown },
                currentMemberType && (React.createElement(ListItemText, { className: "memberTypeLabel", primary: MEMBER_TYPES_LABELS[currentMemberType] })),
                React.createElement(ArrowDropDownIcon, null))),
        React.createElement(Menu, { id: "MemberTypeDropDown__menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, getContentAnchorEl: null, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            } }, _.map(MEMBER_TYPES, function (memberType) { return (React.createElement("div", { key: memberType },
            React.createElement(MenuItem, { disabled: Boolean(memberType === 'resourceOnly' && (memberUid || (memberData === null || memberData === void 0 ? void 0 : memberData.permissions.admin))), selected: memberType === selectedMemberType, onClick: function (event) { return handleSelectAction(event, memberType); } },
                React.createElement("h1", null, MEMBER_TYPES_LABELS[memberType]),
                React.createElement("p", null, _.get(MEMBER_TYPES_DESCRIPTIONS, [memberType]))),
            React.createElement(Divider, null))); }))));
};
export default memo(MemberTypeDropdown);
