import React, { useCallback, useMemo } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import _ from 'underscore';
import {  useIntl } from 'react-intl';
import {  useDispatch, useSelector } from 'react-redux';
import { hideLoader, openChangeTaskLocationModal, setLimitViewTo, showLoader } from '../App/AppActions';
import { browserHistory } from 'react-router';
import { getAccountId, getAccountCostBudgetSetting, getAccountPlan, getAccountTaskParent, getAccountTaskChildrens, getAccountTaskIsRecurring, getAccountTaskCanAdmin, getAccountTaskCanView, getAccountTaskCanManage, getAccountBasePermissionsOwners, getAccountBasePermissionsManagers, getAccountTaskISourceId, getAccountTaskSyncAppId, getAccountTaskSyncAppType, getAccountTaskSourceKey, getAccountTaskCanWork, getAccountTaskCanWorkersCreateTask } from '../../utils/selectors/account';
import { toastr } from 'react-redux-toastr';
import { permissionsByPlan } from '../../utils/constants';
import { FormatLineSpacing } from '@material-ui/icons';
import { tracking } from '../../utils/utils';
import ContentCopy from '@material-ui/icons/FileCopyOutlined';
import Delete from '@material-ui/icons/Delete';
import Open from '@material-ui/icons/OpenInNew';
import AddTaskIcon from '@material-ui/icons/ControlPoint';
import AddListIcon from '@material-ui/icons/ControlPointDuplicate';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Security from '@material-ui/icons/Security';
import LimitIcon from '@material-ui/icons/Pageview';
import InsertChart from '@material-ui/icons/InsertChart';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserId, getUserPermissionsAdmin } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { desyncProject } from '../../utils/services/syncApp';
import { getParentChildIds } from '../../utils/selectors/app';
import useSyncData from '../../utils/hooks/useSyncData';
import { STOP_SYNC_EVENT_BY_TOOL } from '../Integrations/components/ConnectedProjects/constants';
import { updateSyncAppCounterNbProjects } from '../../utils/controllers/account/settings/syncAppCounters';
import { SYNC_APP_TYPE } from '../Integrations/utils';

const TasksRightClickMenu = ({ taskId, position })  => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const accountId = useSelector(getAccountId);
    const accountPlan = useSelector(getAccountPlan);
    const costBudgetIsOn = useSelector(getAccountCostBudgetSetting);
    const taskParent = useSelector(getAccountTaskParent(taskId));
    const taskChildrens = useSelector(getAccountTaskChildrens(taskId));
    const syncAppId = useSelector(getAccountTaskSyncAppId(taskId));
    const taskIsRecuring = useSelector(getAccountTaskIsRecurring(taskId));
    const canAdmin = useSelector(getAccountTaskCanAdmin(taskId));
    const canView = useSelector(getAccountTaskCanView(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));
    const canManageParent = useSelector(getAccountTaskCanManage(taskParent));
    const canWork = useSelector(getAccountTaskCanWork(taskId));
    const canWorkersCreateTask = useSelector(getAccountTaskCanWorkersCreateTask(taskId));
    const isUserAdmin = useSelector(getUserPermissionsAdmin);
    const baseOwners = useSelector(getAccountBasePermissionsOwners);
    const baseManagers = useSelector(getAccountBasePermissionsManagers);
    const taskSourceId = useSelector(getAccountTaskISourceId(taskId));
    const taskSourceKey = useSelector(getAccountTaskSourceKey(taskId));
    const syncAppType = useSelector(getAccountTaskSyncAppType(taskId));
    const userId = useSelector(getUserId);
    const parentChildIds = useSelector(getParentChildIds);

    const { canAddSyncAppTask, isSyncAppTask, syncFolder, currentTask } = useSyncData(taskId);

    const rightMenuAddClick = useCallback(() => {
        browserHistory.push('/tasks/addTask?parentId=' + taskId);
    }, [taskId]);

    const rightMenuAddListClick = useCallback(() => {
        browserHistory.push('/tasks/addList?parentId=' + taskId);
    }, [taskId]);

    const rightMenuOpenClick = useCallback(() => {
        browserHistory.push('/tasks/' + taskId);
    }, [taskId]);

    const rightMenuDuplicateClick = useCallback(() => {
        if(taskSourceId && !taskParent) {
            toastr.confirm(
                intl.formatMessage({id: 'synchronization.duplicate.alert'}), {
                    onOk: () => {
                        browserHistory.push(`/tasks/duplicate/${taskId}`);
                    },
                    okText: intl.formatMessage({id:'CONTINUE'}),
                    cancelText: intl.formatMessage({id:'CANCEL'}),
                }
            );
            return;
        }
        browserHistory.push(`/tasks/duplicate/${taskId}`);
        return;

    }, [taskId, taskSourceId,taskParent]);

    const rightMenuInsightsClick = useCallback(() => {
        browserHistory.push(`/insights/${taskId}/overview`);
    }, [taskId, permissionsByPlan, accountPlan]);

    const rightMenuStopSyncClick = useCallback( async () => {
        toastr.confirm(
            intl.formatMessage({id:'synchronization.stop.alert'}),
            {
                onOk: async () => {
                    try {
                        const isJiraTask = syncAppType === SYNC_APP_TYPE.JIRA;
                        const mainSourceId = isJiraTask ? taskSourceKey : taskSourceId;
                        dispatch(showLoader());
                        await desyncProject({
                            accountId,
                            syncAppId,
                            mainSourceId,
                            taskIds: [taskId, ..._.get(parentChildIds,['childs', taskId], [])],
                            parentTaskIds: _.get(parentChildIds, ['parents', taskId], []),
                        });

                        const existingIds = Object.values(syncFolder || {}).map(el => el.sourceId);
                        const nbConnectedProjects = existingIds.filter(id => !!id && id !== taskSourceId).length;

                        await updateSyncAppCounterNbProjects({ syncAppType, nbConnectedProjects });

                        firebaseEvents.segmentTrack(STOP_SYNC_EVENT_BY_TOOL[syncAppType], { userId });

                        toastr.success(intl.formatMessage({id:'Synchronization successfuly ended'}));
                    } catch (error) {
                        toastr.error(intl.formatMessage({id: 'stop.sync.error'}));
                    } finally {
                        dispatch(hideLoader());
                    }
                },
                okText: intl.formatMessage({id:'CONTINUE'}),
                cancelText: intl.formatMessage({id:'CANCEL'}),
            },
        );
    }, [taskId, permissionsByPlan, accountPlan, syncAppType, userId, syncFolder, taskSourceId]);

    const rightMenuBudgetManagement = useCallback(() => {
        browserHistory.push(`/tasks/${taskId}/budget`);
    }, [taskId, permissionsByPlan, accountPlan]);

    const rightMenuChangeTaskLocationClick = useCallback((e) => {
        e.preventDefault();
        dispatch(openChangeTaskLocationModal({ taskId: taskId, parent: taskParent}));
    }, [taskId, taskParent]);

    const rightMenuLimitViewClick = useCallback(() => {
        tracking('Clicked limit view to this', {accountId, taskId, userId});
        window.scrollTo(0, 0);
        dispatch(setLimitViewTo({accountId, value: taskId}));
    }, [taskId, accountId, userId]);

    const handlePermissions =  useCallback(() => {
        browserHistory.push('/tasks/' + taskId + '/permissions');
    }, [taskId, permissionsByPlan, accountPlan]);

    const rightMenuDeleteClick = useCallback(() => {
        if(!taskId) return;

        if(taskChildrens) {
            toastr.confirm(
                intl.formatMessage({id:'All sub tasks of this task will also be deleted. This operation cannot be reverted! Are you sure?'}),
                {
                    onOk: () => {
                        firebaseEvents.deleteTasks([taskId]);
                        toastr.success(intl.formatMessage({id:'Tasks deleted'}));
                    },
                    okText: intl.formatMessage({id:'toastr.confirm.delete'}),
                    cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
                    id: ('toastr-confirm-delete')
                },
            );
        } else {
            const action = firebaseEvents.deleteTasks([taskId]);

            toastr.success(intl.formatMessage({id:'Task deleted'}), '', {
                component: (
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            action?.undo();
                            toastr.removeByType('success');
                        }}
                    >
                        {intl.formatMessage({id:'Cancel'})}
                    </a>
                ),
            });
        }
    }, [taskId, taskChildrens]);

    const canDeleteDuplicate = useMemo(()=>{
        if (canManage) {
            if (
                (taskParent && canAdmin) ||
                (taskParent && canManageParent) ||
                (
                    !taskParent &&
                    (
                        isUserAdmin ||
                        baseOwners.indexOf(userId) !== -1 ||
                        baseManagers.indexOf(userId) !== -1
                    )
                )
            ) {
                return true;
            }
        }
        return false;
    }, [canManage, taskParent, canAdmin, canManageParent, isUserAdmin, baseOwners, baseManagers, userId]);

    const canAddCommonTask = useMemo(
        () => (canManage || (canWork && canWorkersCreateTask)) && !taskIsRecuring,
        [canManage, taskIsRecuring, canWork, canWorkersCreateTask]
    );

    const canShowAddTask = useMemo(
        () => isSyncAppTask ? canAddCommonTask && canAddSyncAppTask : canAddCommonTask,
        [isSyncAppTask, canAddCommonTask, canAddSyncAppTask]
    );

    return useMemo(() => {
        return (
            <Paper id="TaskLineRightClickMenu" elevation={3} style={{top: position.top, left: position.left}}>
                <MenuList className="taskMenuList">

                    {canView &&
                        <MenuItem className="taskOpenMenu" onClick={rightMenuOpenClick}>
                            <Open /> {intl.formatMessage({id:'Open'})}
                        </MenuItem>
                    }

                    {canShowAddTask &&
                        <MenuItem className="taskAddMenu" onClick={rightMenuAddClick}>
                            <AddTaskIcon /> {intl.formatMessage({id:'Add a task'})}
                        </MenuItem>
                    }

                    {canShowAddTask &&
                        <MenuItem className="taskAddMenu" onClick={rightMenuAddListClick}>
                            <AddListIcon /> {intl.formatMessage({id:'Add a list of Tasks'})}
                        </MenuItem>
                    }

                    {canDeleteDuplicate && (!taskIsRecuring || taskChildrens) &&
                        <MenuItem className="taskDuplicateMenu" onClick={rightMenuDuplicateClick}>
                            <ContentCopy /> {intl.formatMessage({id:'Duplicate'})}
                        </MenuItem>
                    }

                    {canDeleteDuplicate && !taskSourceId &&
                        <MenuItem className="taskDeleteMenu" onClick={rightMenuDeleteClick}>
                            <Delete /> {intl.formatMessage({id:'Delete'})}
                        </MenuItem>
                    }

                    {canView && taskChildrens &&
                        <MenuItem className="taskLimitMenu" onClick={rightMenuLimitViewClick}>
                            <LimitIcon /> {intl.formatMessage({id:'Limit view to this'})}
                        </MenuItem>
                    }

                    {canView && canManage && !taskIsRecuring && !taskSourceId &&
                        <MenuItem className="taskChangeLocation" onClick={rightMenuChangeTaskLocationClick}>
                            <FormatLineSpacing /> {intl.formatMessage({id:'Change Location'})}
                        </MenuItem>
                    }

                    {canAdmin &&
                        <MenuItem
                            className={classnames('taskPermissionMenu', 'premium')}
                            onClick={handlePermissions}
                        >
                            <Security /> {intl.formatMessage({id:'Permissions'})}
                        </MenuItem>
                    }

                    {canManage && costBudgetIsOn && taskChildrens && (
                        <MenuItem className="taskFinancialsMenu onlyManagers" onClick={rightMenuBudgetManagement}>
                            <MonetizationOn /> {intl.formatMessage({id:'Financials'})}
                        </MenuItem>
                    )}

                    {canManage && taskChildrens &&
                        <MenuItem className="taskInsightsMenu onlyManagers" onClick={rightMenuInsightsClick}>
                            <InsertChart /> {intl.formatMessage({id:'Insights'})}
                        </MenuItem>
                    }

                    {canManage && taskChildrens && taskSourceId && currentTask.isConnectProject &&
                        <MenuItem className="taskStopSyncMenu onlyManagers" onClick={rightMenuStopSyncClick}>
                            <AccountTreeIcon /> {intl.formatMessage({id:'Stop Synchronization'})}
                        </MenuItem>
                    }
                </MenuList>
            </Paper>
        );
    }, [
        position,
        canView,
        rightMenuOpenClick,
        canManage,
        taskIsRecuring,
        rightMenuAddClick,
        rightMenuAddListClick,
        canDeleteDuplicate,
        taskChildrens,
        rightMenuDuplicateClick,
        rightMenuDeleteClick,
        rightMenuLimitViewClick,
        rightMenuChangeTaskLocationClick,
        canAdmin,
        handlePermissions,
        costBudgetIsOn,
        rightMenuBudgetManagement,
        rightMenuInsightsClick,
        currentTask,
        syncFolder,
        canWork,
        canWorkersCreateTask,
    ]);
};

export default React.memo(withCustomErrorBoundary(TasksRightClickMenu));
