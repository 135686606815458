import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { canSee } from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import { permissionsByPlan } from '../../utils/constants';
import { getAccountPlan } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import PermissionSection from './components/PermissionSection';
import { sectionTypes } from './constants';
var SettingsPermissions = function () {
    var intl = useIntl();
    var plan = useSelector(getAccountPlan);
    var userPermissions = useSelector(getUserPermissions);
    if (!permissionsByPlan.permissions.includes(plan) || !canSee(['admin'], { data: { permissions: userPermissions } })) {
        return React.createElement(Unautorized, null);
    }
    return (React.createElement("div", { className: "SettingsPermissions" },
        React.createElement(Typography, { variant: "h1", gutterBottom: true }, intl.formatMessage({ id: 'Permissions' })),
        sectionTypes.map(function (_a) {
            var permissionType = _a.permissionType, sectionTitle = _a.sectionTitle;
            return (React.createElement(PermissionSection, { key: sectionTitle, permissionType: permissionType, sectionTitle: sectionTitle }));
        })));
};
export default withCustomErrorBoundary(SettingsPermissions);
