import React, { memo, useState, useEffect } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import InviteTeamMembers from './InviteTeamMembers';
import ChoseBeging from './ChoseBeging';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, TextField } from '@material-ui/core';
import { companySizes } from './utils';
import isoCountries from '../../../js/utils/isoCountries.json';
import { toastr } from 'react-redux-toastr';
import useLocationInfo from '../../utils/hooks/useLocationInfo';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { MEMBER_TYPES } from '../../utils/memberTypes';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const CreateWorkspace = () => {
    const intl = useIntl();
    const { locationCountryCode } = useLocationInfo();
    const [userName, setUserName] = useState('');
    const [newAccountId, setNewACcountId] = useState(null);
    const [formStep, setFormStep] = useState(0);
    const [workspaceName, setWorkspaceName] = useState(intl.formatMessage({ id: 'workspaces.create-workspace-name' }));
    const [phoneNumber, setPhoneNumber] = useState('');
    const [industry, setIndustry] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [country, setCountry] = useState(() => {
        if (localStorage.getItem('ipData') && isoCountries[JSON.parse(localStorage.getItem('ipData'))?.country_code2]) {
            return isoCountries[JSON.parse(localStorage.getItem('ipData'))?.country_code2];
        }
        return '';
    });

    const user = useSelector(state => state.app?.user);
    const accountId = useSelector(state => state.app?.account?.settings?.id);

    useEffect(() => {
        if (user && user.displayName) {
            setUserName(user.displayName);
        }

        if (localStorage.getItem('prevWorkspaceData')) {
            let prevWorkspaceData = JSON.parse(localStorage.getItem('prevWorkspaceData'));
            if (prevWorkspaceData.phoneNumber) { setPhoneNumber(prevWorkspaceData.phoneNumber); }
            if (prevWorkspaceData.companySize) { setCompanySize(prevWorkspaceData.companySize); }
            if (prevWorkspaceData.country) { setCountry(prevWorkspaceData.country); }
            if (prevWorkspaceData.industry) { setIndustry(prevWorkspaceData.industry); }
        }
    }, [user]);

    const storeInputFieldData = () => {
        let prevWorkspaceData = {};

        if (phoneNumber !== '' && phoneNumber !== '+') { prevWorkspaceData.phoneNumber = phoneNumber; }
        if (companySize !== '') { prevWorkspaceData.companySize = companySize; }
        if (country !== '') { prevWorkspaceData.country = country; }
        if (industry !== '') { prevWorkspaceData.industry = industry; }

        if (prevWorkspaceData !== {}) {
            localStorage.setItem('prevWorkspaceData', JSON.stringify(prevWorkspaceData));
        }
    };

    const handleCreateWorkspace = async (e) => {
        e.preventDefault();
        storeInputFieldData();

        const missingInputValues = userName === '' || workspaceName === '' || companySize === '' || phoneNumber === '';

        if (!missingInputValues) {
            try {
                const newAccountId = await firebaseEvents.createAccount({
                    uid: user?.uid,
                    userName: userName,
                    accountName: workspaceName,
                    email: user?.email,
                    avatar: user?.photoURL,
                    phoneNumber,
                    companySize,
                    country,
                    industry,
                    memberType: MEMBER_TYPES.userAndResource
                });

                if (newAccountId) {
                    
                    if(companySize === 'Just Me'){
                        setFormStep(2);
                        firebaseEvents.segmentTrack('Skiped invite users', { accountId: newAccountId } );
                    } else {
                        setFormStep(1);
                    }
                    setNewACcountId(newAccountId);
                    firebaseEvents.segmentTrack('Create Account', { accountId: newAccountId});
                }
            } catch (err) {
                toastr.error('Something went wrong');
            }
        }
        else {
            toastr.error('Please fill in all fields');
        }
    };
    const nextStep = () => {
        setFormStep(2);
    };

    if(formStep === 1){
        // Stonly purposes
        window.history.replaceState(null, null, '#invite');

        return <InviteTeamMembers
            accountId={newAccountId}
            inviterName={userName}
            workspaceName={workspaceName}
            nextStep={nextStep}
        />;
    }
    
    if(formStep === 2){
        return <ChoseBeging />;
    }

    return <div className='CreateWorkspace'>
        <div>
            <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
            <h2>{intl.formatMessage({ id: 'workspaces.create-new-title' })}</h2>
            <form className='CreateWorkspace__inputs' onSubmit={(e) => handleCreateWorkspace(e)}>
                <TextField
                    defaultValue={user?.displayName || ''}
                    onChange={(e) => setUserName(e.target.value)}
                    label={intl.formatMessage({ id: 'Your name' })}
                    type='text'
                    size='small'
                    variant='standard'
                />
                <FormControl>
                    <InputLabel id="companySizeLabel">{intl.formatMessage({ id: 'Company Size' })}</InputLabel>

                    <Select
                        labelId="companySizeLabel"
                        value={companySize}
                        onChange={(e) => {
                            setCompanySize(e.target.value);
                        }}
                    >
                        {companySizes.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    placeholder='e.g. My Workspace'
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    value={workspaceName}
                    label={intl.formatMessage({ id: 'Your workspace name' })}
                    type='text'
                    size='small'
                    variant='standard'
                />
                <FormControl>
                    <InputLabel id="phoneNumberLabel">{intl.formatMessage({ id: 'Phone Number' })}</InputLabel>
                    <PhoneInput
                        country={locationCountryCode.toLowerCase()}
                        value={phoneNumber}
                        onChange={phone => setPhoneNumber(phone)}
                    />
                </FormControl>
                <div>
                    {accountId && <Button className="cancelBtn" onClick={() => browserHistory.goBack()}>{intl.formatMessage({ id: 'Cancel' })}</Button>}
                    <Button type='submit'>{intl.formatMessage({ id: 'workspaces.create-workspace' })}</Button>
                </div>
            </form>
        </div>
    </div>;
};

export default memo(withCustomErrorBoundary(CreateWorkspace));

