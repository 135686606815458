import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import SplitBy from '../SplitBy/SplitBy';
import OverViewCard from '../OverViewCard';
import TaskBurnDownTable from './TaskBurnDownTable';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import ReactDOM from 'react-dom';

import {
    agregateData,
    getDataByTask,
    convertToDateTime,
    deepCloneDataSets,
    backgroundColor,
    borderDash,
    downloadAsImage,
    getColor,
    exportCSV,
    validateNumber,
    getDateFormated,
    getMonth,
} from '../utils';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const getDataBySkills = ({lastValue, currentChartData, csvLines, data, tasksIds, skillsData, hasBreakdownByTask, stateTasks}) => data.reduce((acc, skillId) => {
    if(!skillId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = skillId + '-' + taskId;
            let done = 0;
            let notDone = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData.skills.findIndex(key => skillId === key);
                if (idxOfSelect > -1) {
                    done = parseInt(findData['Nb tasks done Skills'][idxOfSelect]);
                    notDone =  parseInt(findData['Nb tasks not done Skills'][idxOfSelect]);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    notDone = lastChartPointData?.notDone || 0;
                    done = lastChartPointData?.done || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                notDone = lastChartPointData?.notDone || 0;
                done = lastChartPointData?.done || 0;
            }

            acc.push({
                id,
                breakdownId: skillId,
                label:   skillsData[skillId].name +  '-' +  stateTasks[taskId].title,
                done: done,
                notDone: notDone,
                tasksId: [taskId],
                parentName: skillsData[skillId].name,
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }
    // if you split by task then

    // if no other breakdown.
    const linesWithSkill = csvLines.filter(line => line.skills.includes(skillId));

    const vadas = linesWithSkill.reduce((acc,l) => {
        const idxOfSelect = l.skills.findIndex(key => skillId === key);
        if (idxOfSelect >= 0) {
            acc.notDone +=  parseInt(l['Nb tasks not done Skills'][idxOfSelect]);
            acc.done += parseInt(l['Nb tasks done Skills'][idxOfSelect]);
            acc.tasksId.push(l.id);
        }
        return acc;
    }, { done: 0, notDone: 0, tasksId: []});

    acc.push({
        id: skillId,
        breakdownId: skillId,
        label:  skillsData[skillId].name,
        done: vadas.done,
        notDone: vadas.notDone,
        tasksId: vadas.tasksId,
        parentName: skillsData[skillId].name,
    });

    return acc;
}, []);

const breakdownDataByUseres = ({lastValue, currentChartData, csvLines, data, tasksIds, teamMembersData, hasBreakdownByTask, stateTasks}) => data.reduce((acc, userId) => {
    if(!userId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = userId + '-' + taskId;
            let done = 0;
            let toBeDone = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData?.teamMembers?.findIndex(key => userId === key);
                if (idxOfSelect > -1) {
                    done = parseInt(findData['Nb tasks done Team members'][idxOfSelect]);
                    toBeDone =  parseInt(findData['Nb tasks not done Team members'][idxOfSelect]);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    toBeDone = lastChartPointData?.notDone || 0;
                    done = lastChartPointData?.done || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                toBeDone = lastChartPointData?.notDone || 0;
                done = lastChartPointData?.done || 0;
            }

            acc.push({
                id,
                label:   teamMembersData[userId]?.displayName +  '-' +  stateTasks[taskId].title,
                done: done,
                breakdownId: userId,
                notDone: toBeDone,
                tasksId: [taskId],
                parentName: teamMembersData[userId]?.displayName || 'Deleted User',
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }

    // if no other breakdown.
    const linesWithUser = csvLines.filter(line => line.teamMembers.includes(userId));
    const vadas = linesWithUser.reduce((acc,line) => {
        const idxOfSelect = line?.teamMembers?.findIndex(key => userId === key);
        if (idxOfSelect >= 0) {
            acc.done += parseInt(line['Nb tasks done Team members'][idxOfSelect]);
            acc.notDone +=  parseInt(line['Nb tasks not done Team members'][idxOfSelect]);
            acc.tasksId.push(line.id);
        }
        return acc;
    }, { done: 0, notDone: 0, tasksId: []});

    acc.push({
        id: userId,
        breakdownId: userId,
        label:  teamMembersData[userId]?.displayName  || 'Deleted User',
        done: vadas.done,
        notDone: vadas.notDone,
        tasksId: vadas.tasksId,
        parentName: teamMembersData[userId]?.displayName  || 'Deleted User',
    });

    return acc;
}, []);


ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                // missing this one
                label:  'Percentage complete',
                data: [],
                borderColor: 'black',
                backgroundColor: 'black',
                yAxisID: 'y1',
                pointRadius: 2
            },
            {
                label: 	'Tasks done',
                data: [],
                fill: true,
                yAxisID: 'y',
                pointRadius: 0
            },
            {
                label: 	'Tasks not done',
                data: [],
                fill: true,
                yAxisID: 'y',
                pointRadius: 0
            },
        ]
    },
    options: {
        animation: false,
        stacked: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                    filter: (item)=>{
                        return (item.text.indexOf('% complete') === -1);
                    }
                }
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function(context){
                        return getDateFormated(context[0].label);
                    },
                    label: function(context) {
                        return context.dataset.label.includes('% complete') ? `${context.dataset.label}: ${Math.round(context.parsed.y * 100) / 100}%` : `${context.dataset.label}: ${context.parsed.y}`;
                    },
                    footer: function(data) {
                        const lineTotal = data.reduce((acc, el) =>  !el.dataset.label.includes('% complete') && el.datasetIndex >= 0 ? acc + el.parsed.y : acc , 0);
                        const lineDone = data.reduce((acc, el) =>  el.dataset.label.includes('Done') && !el.dataset.label.includes('Not Done') && el.datasetIndex >= 0 ? acc + el.parsed.y : acc , 0);
                        const linePercent = (lineDone/lineTotal*100).toFixed(2);
                        if(data.length  >3){
                            return `Total Tasks: ${lineTotal}\nGlobal % complete: ${linePercent}%`;
                        }
                        return `Total Tasks: ${lineTotal}`;
                    }
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size:12
                },
                titleColor: '#fff',
                titleFont: {
                    size:14
                },
                boxWidth: 8,
                boxHeight: 8
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function(value) {
                        const labelValue = this.getLabelForValue(value);
                        if(moment(labelValue, 'YYYY', true).isValid()){
                            return labelValue;
                        }
                        else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        }
                        else if(moment(labelValue, 'M/YYYY', true).isValid()){
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }

                        return `${moment(labelValue).format('MMM D, YY')}`;
                    },
                    color: '#999',
                    font: {
                        size: 11
                    }
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                lineWidth: 0,
                drawTicks: false,
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11
                    }
                }
            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',
                min: 0,
                max: 100,
                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    callback: function(value) {
                        return `${Math.round(value * 100) / 100}%`;
                    }
                }
            },
        },

    },
};

const TaskBurndown = ({
    workspaceData,
    accountId,
    stateTasks,
    datesRange,
    workspaceSelected,
    childsParents,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    userTimezone
}) => {
    const accountSkills = useSelector((state) => state.app?.account?.skills || {});
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const chartCanvas = useRef(null);
    const [csvData, setCSVData] = useState({});
    const [currentChart, setCurrentChart] = useState(null);
    const [currentData, setCurrentData] = useState({labels: [], datasets: {} } );
    const intl = useIntl();
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl);
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);
    const [displayData, setDisplayData] = useState({labels: [], datasets: {} } );
    const dispatch = useDispatch();



    const updateBreakdowns = async (newBreakdowns) => {
        const breakdownDataByTask = newBreakdowns.find(el => el.value === 'tasks');
        if (
            !breakdownDataByTask?.selectedData.length && !csvData[workspaceSelected]
            ||
            breakdownDataByTask?.selectedData.length && csvData[workspaceSelected]
            ||
            breakdownDataByTask?.selectedData.length &&
            breakdownDataByTask?.selectedData.length !== Object.keys(csvData).length     &&
            !csvData[workspaceSelected]
        ) {
            dispatch(showLoader());
            const data = await getDataByTask({
                accountId: accountId,
                workspaceData: workspaceData,
                selectedTasks: breakdownDataByTask?.selectedData || [],
                userTimezone
            });
            ReactDOM.unstable_batchedUpdates(()=> {
                setBreakdownData(newBreakdowns);
                setCSVData(data);
            });
            return;
        }

        setBreakdownData(newBreakdowns);
    };

    // Should only occor on the mount
    useEffect( () => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = (e) => {
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);

                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));

                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });
                if(document.getElementsByClassName('ReactVirtualized__Grid').length > 1){
                    const width = document.getElementsByClassName('ReactVirtualized__Grid')[1].children[0].children[0].style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };

            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]);


    const onWorkspaceChange = async () => {
        dispatch(showLoader());
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
        const data = await getDataByTask({
            accountId: accountId,
            workspaceData: workspaceData,
            selectedTasks: [],
            userTimezone
        });

        setCSVData(data);
    };

    useEffect( () => {
        onWorkspaceChange();
    }, [workspaceData, accountId]);


    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }

        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item) => {
            let line = '';
            const totalTask = item.done.map((el, idx) => el + item.notDone[idx]);
            const percentFormat = item.progress.map((el) => el ? `${Math.round( el * 100) / 100}%` : '0%');
            line += `${item.name}\n`;
            line += `Done;${item.done.join(';')}\n`;
            line += `Not Done;${item.notDone.join(';')}\n`;
            line += `Total Tasks;${totalTask.join(';')}\n`;
            line += `Percente;${percentFormat.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'task-burndown');
    };

    const breakdownDataByTask = breakdownData.find(el => el.value === 'tasks');
    const breakdownDataBySkills = breakdownData.find(el => el.value === 'skills');
    const breakdownDataByUsers = breakdownData.find(el => el.value === 'users');

    const handleData =  async () => {
        if(currentChart && !_.isEmpty(csvData)) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let possibleBreaks = [];
            // get all the tasks IDS
            const allTasksId = Object.keys(csvData);
            const allChartPoints = Object.values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime)*60*60*4 * 1000);
                    const formatedDate =  `${newDate.getFullYear()}/${getMonth(newDate.getMonth() + 1)}/${newDate.getDate()}`;

                    if(acc[formatedDate]){
                        return {
                            ...acc,
                            [formatedDate]: [el,...acc[formatedDate]]
                        };
                    }

                    return {
                        ...acc,
                        [formatedDate]: [el]
                    };
                }, {});


            let lastValue = '';
            // have to go throw all of the checkpoints
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                // if there is no break down we aggregate the information.
                let TTdone = 0;
                let TTnotDone = 0;
                if (breakdownDataByUsers?.lvl === 0 && breakdownDataByUsers.selectedData.length) {
                    possibleBreaks = breakdownDataByUsers.selectedData;

                    const data = breakdownDataByUseres({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataByUsers.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        teamMembersData: accountTeamMembers
                    });

                    lastValue = key;
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }

                if (breakdownDataBySkills?.lvl === 0 && breakdownDataBySkills.selectedData.length) {
                    possibleBreaks = breakdownDataBySkills.selectedData;

                    const data = getDataBySkills({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataBySkills.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        skillsData: accountSkills
                    });
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }
                //if you have the lvl 0 breakdown beeing tasks =
                if (breakdownDataByTask?.lvl === 0) {
                    possibleBreaks = allTasksId;

                    const values = allTasksId.reduce((tasksAcc, taskId) => {
                        TTdone = 0;
                        TTnotDone = 0;
                        // lvl 2 === teamMember
                        const findData = csvLines.find(line => line.id === taskId);
                        if(breakdownDataByUsers  && breakdownDataByUsers.selectedData.length){
                            const teamMembers = breakdownDataByUsers.selectedData.length ? breakdownDataByUsers.selectedData : [];
                            possibleBreaks = teamMembers;
                            teamMembers.forEach(tmId => {
                                let id = taskId +  '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData?.teamMembers?.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        TTnotDone = parseInt(findData['Nb tasks not done Team members'][idxOfSelect]);
                                        TTdone = parseInt(findData['Nb tasks done Team members'][idxOfSelect]);
                                    } else if(lastValue) {
                                        const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                        TTnotDone = lastChartPointData?.notDone || 0;
                                        TTdone = lastChartPointData?.done || 0;
                                    }
                                } else if(lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    TTnotDone = lastChartPointData?.notDone || 0;
                                    TTdone = lastChartPointData?.done || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label: stateTasks[taskId].title +  '-' +  accountTeamMembers[tmId]?.displayName || 'Delete User',
                                    done: TTdone,
                                    breakdownId: tmId,
                                    notDone: TTnotDone,
                                    tasksId: [taskId],
                                    parentName: stateTasks[taskId].title,
                                    subName: accountTeamMembers[tmId]?.displayName,
                                });
                            });
                            return tasksAcc;
                        }

                        if(breakdownDataBySkills  && breakdownDataBySkills.selectedData.length ){
                            possibleBreaks = breakdownDataBySkills.selectedData;
                            breakdownDataBySkills.selectedData.forEach(tmId => {
                                let id = taskId +  '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData.skills.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        TTdone = parseInt(findData['Nb tasks done Skills'][idxOfSelect]);
                                        TTnotDone = parseInt(findData['Nb tasks not done Skills'][idxOfSelect]);
                                    }
                                } else if(lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    TTnotDone = lastChartPointData?.notDone || 0;
                                    TTdone = lastChartPointData?.done || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label: stateTasks[taskId].title +  '-' +  accountSkills[tmId].name,
                                    done: TTdone,
                                    notDone: TTnotDone,
                                    tasksId: [taskId],
                                    breakdownId: tmId,
                                    parentName: stateTasks[taskId].title,
                                    subName: accountSkills[tmId].name,
                                });
                            });
                            return tasksAcc;
                        }


                        if(findData){
                            TTnotDone = parseInt(findData['Nb tasks not done']);
                            TTdone = parseInt(findData['Nb tasks done']);
                        } else if(lastValue) {
                            const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === taskId);
                            TTnotDone = lastChartPointData?.notDone || 0;
                            TTdone = lastChartPointData?.done || 0;
                        }

                        return [ ...tasksAcc, {
                            id: taskId,
                            label: stateTasks[taskId]?.title || 'N/A',
                            done: TTdone,
                            notDone: TTnotDone,
                            tasksId: [taskId],
                            parentName: stateTasks[taskId]?.title || 'N/A',
                        }];
                    }, []);
                    // saving last value
                    lastValue = key;

                    return {
                        ...chartPointAcc,
                        [key]: values
                    };
                }

                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) =>  {
                    return {
                        id: `default${idx}`,
                        label: '',
                        done: parseInt(line['Nb tasks done']),
                        notDone: parseInt(line['Nb tasks not done']),
                        tasksId: [line.id],
                        parentName: ''
                    };
                });

                return { ...chartPointAcc,
                    [key]: values
                };
            }, {});

            const chartData = Object.entries(newData).reduce((acc, [key, items]) => {
                acc.labels.push(key);

                items.forEach((el, itemIdx) => {
                    if (!acc.datasets[el.id+'-done']) {
                        const [colorA,colorB ] = getColor(possibleBreaks.length,itemIdx);
                        acc.datasets[el.id+'-done'] =  {
                            ...el,
                            // missing this one
                            id: el.id,
                            label:  el.label + ' Tasks Done',
                            data: [validateNumber(el.done)],
                            borderColor: colorA,
                            backgroundColor: colorA,
                            fill: true,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            source: el.tasksId,
                            labelType: 'done',
                            segment: {
                                borderWidth: 2,
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorA, currentChart, userTimezone),
                            }
                        };
                        acc.datasets[el.id+'-not-done'] =  {
                            ...el,
                            id: el.id,
                            source: el.tasksId,
                            // missing this one
                            label:  el.label + ' Tasks Not Done',
                            data: [validateNumber(el.notDone)],
                            borderColor: colorB,
                            backgroundColor: colorB,
                            fill: true,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            // the tasks Id used
                            labelType: 'notDone',
                            segment: {
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorB, currentChart, userTimezone),
                            }
                        };
                        const progress = (el.done*100) / (el.notDone + el.done);
                        acc.datasets[el.id+'-progress'] =  {
                            ...el,
                            // missing this one
                            label:  el.label + ' % complete',
                            id: el.id,
                            source: el.tasksId,
                            data: [validateNumber(progress)],
                            borderColor: 'rgba(0,0,0,0)',
                            backgroundColor: 'rgba(0,0,0,0)',
                            fill: false,
                            yAxisID: 'y1',
                            cubicInterpolationMode: 'monotone',
                            borderWidth: 0,
                            pointRadius: 0,
                            pointBorderColor: 'rgba(0,0,0,0)',
                            pointBorderWidth: 1,
                            // the tasks Id used
                            labelType: 'progress'
                        };
                    } else {
                        const progress = (el.done*100) / (el.notDone + el.done);
                        acc.datasets[el.id+'-done'].data.push(validateNumber(el.done));
                        acc.datasets[el.id+'-not-done'].data.push(validateNumber(el.notDone));
                        acc.datasets[el.id+'-progress'].data.push(validateNumber(progress));
                    }
                });

                return acc;
            } , {
                labels: [],
                datasets: {}
            });

            if(moment(endDate).isAfter(moment().tz(userTimezone))) {
                /**
                 * in  case of existing a break by task -> all selected we get all of the childs of all the parents
                 * if we select more than one task we need to go and get all of them.
                 */
                const taskBreakdown = breakdownData.find(el => el.value === 'tasks');
                let workspaceTasks;
                if(taskBreakdown?.selectedData.length) {
                    workspaceTasks = taskBreakdown.selectedData.map(el => {
                        return childsParents[el] ? ({source: el, childs: childsParents[el]}) : ({source: el, childs: [el]});
                    });
                } else {
                    workspaceTasks =
                        workspaceSelected === 'root' ?
                            [({source: 'root', childs: Object.keys(stateTasks)})] :
                            [({source: workspaceSelected, childs: _.get(childsParents, workspaceSelected , [workspaceSelected])})];
                }
                // used
                const availableDates = [];
                const skillsSplitByDay = {};
                const userSplitedByDay = {};
                const tasksSplitedByDay = {};
                workspaceTasks.forEach(base => {
                    base.childs.forEach(taskKey => {
                        const currentTask = { id: taskKey, ...stateTasks[taskKey] };
                        const estFinishDate = currentTask?.estimations?.expectedAt && moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);

                        if(
                            !estFinishDate || currentTask.status  === 'done'  ||  moment.tz(estFinishDate, 'X', userTimezone).isBefore(moment().tz(userTimezone)) || estFinishDate.isAfter(endDate) || currentTask.childrens
                        ){
                            return false;
                        }

                        if(breakdownDataBySkills && breakdownDataBySkills?.selectedData?.length) {
                            const skillsToCompare = breakdownDataBySkills.selectedData.length ? breakdownDataBySkills.selectedData : [];
                            if(!skillsToCompare.some(el => currentTask?.skill?.includes(el))) {
                                return  false;
                            }
                        } else if(breakdownDataByUsers && breakdownDataByUsers?.selectedData?.length) {
                            const estimationsUserId = Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId];
                            if(!estimationsUserId.find(el => breakdownDataByUsers.selectedData.includes(el))) {
                                return  false;
                            }
                        }
                        const expecetedDate = estFinishDate.format('YYYY/MM/DD');

                        if(!availableDates.includes(expecetedDate)){
                            availableDates.push(expecetedDate);
                        }

                        // for tasks
                        if(tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate][base.source]
                        ){
                            tasksSplitedByDay[expecetedDate][base.source]++;
                        } else if(tasksSplitedByDay[expecetedDate] && tasksSplitedByDay[expecetedDate]) {
                            tasksSplitedByDay[expecetedDate][base.source] = 1;
                        } else {
                            tasksSplitedByDay[expecetedDate] = {
                                [base.source]: 1
                            };
                        }
                        // for TM
                        if(breakdownDataByTask?.selectedData?.length && breakdownDataByUsers?.selectedData?.length){
                            const estimationsUserId = Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId];
                            estimationsUserId.forEach(userId => {
                                if(userSplitedByDay[expecetedDate] &&
                                    userSplitedByDay[expecetedDate][userId] &&
                                    userSplitedByDay[expecetedDate][userId][base.source]
                                ){
                                    userSplitedByDay[expecetedDate][userId][base.source]++;
                                } else if(userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][userId]) {
                                    userSplitedByDay[expecetedDate][userId][base.source] = 1;
                                } else {
                                    userSplitedByDay[expecetedDate] = {
                                        [userId]: {
                                            [base.source]: 1
                                        }
                                    };
                                }
                            });
                        } else if(breakdownDataByUsers?.selectedData?.length) {
                            const estimationsUserId = Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId];
                            estimationsUserId.forEach(userId => {
                                if(userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][userId] ){
                                    userSplitedByDay[expecetedDate][userId]++;
                                } else if(userSplitedByDay[expecetedDate]) {
                                    userSplitedByDay[expecetedDate][userId] = 1;
                                } else {
                                    userSplitedByDay[expecetedDate] = {
                                        [userId]: 1
                                    };
                                }
                            });
                        }
                        // for users
                        if(breakdownDataByTask?.selectedData?.length && breakdownDataBySkills?.selectedData?.length){
                            currentTask?.skill?.forEach(skillId => {
                                if(skillsSplitByDay[expecetedDate] &&
                                skillsSplitByDay[expecetedDate][skillId] &&
                                skillsSplitByDay[expecetedDate][skillId][base.source]
                                ){
                                    skillsSplitByDay[expecetedDate][skillId][base.source]++;
                                } else if(skillsSplitByDay[expecetedDate] && skillsSplitByDay[expecetedDate][skillId]) {
                                    skillsSplitByDay[expecetedDate][skillId][base.source] = 1;
                                } else {
                                    skillsSplitByDay[expecetedDate] = {
                                        [skillId]: {
                                            [base.source]: 1
                                        }
                                    };
                                }
                            });
                        } else if(breakdownDataBySkills?.selectedData?.length) {
                            currentTask?.skill?.forEach(skillId => {
                                if(skillsSplitByDay[expecetedDate] && skillsSplitByDay[expecetedDate][skillId] ){
                                    skillsSplitByDay[expecetedDate][skillId]++;
                                } else if(skillsSplitByDay[expecetedDate]) {
                                    skillsSplitByDay[expecetedDate][skillId] = 1;
                                } else {
                                    skillsSplitByDay[expecetedDate] = {
                                        [skillId]: 1
                                    };
                                }
                            });
                        }
                    });
                });

                // sort the dates
                availableDates.sort((a,b) => new Date(a).valueOf() - new Date(b).valueOf());

                availableDates.forEach(byDate => {
                    const skillDoneThisDay = skillsSplitByDay[byDate];
                    const userTasksDoneThisDay = userSplitedByDay[byDate];
                    const tasksDoneThisDay = tasksSplitedByDay[byDate];
                    // compile it here ?
                    chartData.labels.push(byDate);
                    Object.values(chartData.datasets).forEach((el) => {
                        if (el.labelType !== 'progress') {
                            let value = 0;
                            // missing for task and users
                            if(breakdownDataBySkills?.selectedData?.length && skillDoneThisDay[el.breakdownId]){
                                value = skillDoneThisDay[el.breakdownId];

                                if(breakdownDataByTask && breakdownDataByTask?.selectedData?.length){
                                    value = skillDoneThisDay[el.breakdownId][el.source[0]];
                                }
                            }

                            if(breakdownDataByUsers?.selectedData?.length && userTasksDoneThisDay[el.breakdownId]){
                                value = userTasksDoneThisDay[el.breakdownId];

                                if(breakdownDataByTask && breakdownDataByTask?.selectedData?.length){
                                    value = userTasksDoneThisDay[el.breakdownId][el.source[0]];
                                }
                            }

                            if(!breakdownDataByUsers?.selectedData?.length && !breakdownDataBySkills?.selectedData?.length){
                                value = tasksDoneThisDay[el.source[0]] || value;
                            }

                            const lastValue = _.last(el.data);
                            let newValue = lastValue;
                            if (value && el.labelType === 'done'){
                                newValue += value;
                                el.data.push(newValue);
                            } else if(value && el.labelType === 'notDone'){
                                newValue -= value;
                                el.data.push(newValue >= 0 ?  newValue : 0);
                            } else {
                                el.data.push(lastValue);
                            }
                        }
                    });

                    const subProgressLines = Object.values(chartData.datasets).filter(el => el.labelType === 'progress' && el.id);
                    subProgressLines.forEach(el => {
                        const lineTotal = _.last(chartData.datasets[el.id+'-done'].data) +  _.last(chartData.datasets[el.id+'-not-done'].data);
                        const lineDone =  _.last(chartData.datasets[el.id+'-done'].data);
                        el.data.push((lineDone * 100) / lineTotal);
                    });
                });
            }



            if(!moment(chartData.labels[0]).tz(userTimezone).isSame(startDate, 'day')){
                chartData.labels = [startDate.format('YYYY/MM/DD'),...chartData.labels];
                Object.values(chartData.datasets)
                    .forEach(el => {
                        el.data =  [el.data[0],...el.data];
                    });
            }
            if(!moment(_.last(chartData.labels)).tz(userTimezone).isSame(endDate, 'day')){
                chartData.labels.push(endDate.format('YYYY/MM/DD'));
                Object.values(chartData.datasets).forEach(el => el.data.push(_.last(el.data)));
            }

            // need to find the lowest value
            let min = -1;
            let max = 0;
            Object.values(chartData.datasets).forEach(el => {
                el.data.forEach(entry => {
                    if(max === 0 || max < entry)
                        max = entry;
                    if((min > entry || min === -1) && entry !== null)
                        min = entry;
                });
            });

            currentChart.options.scales.y.min = min === 0 ? 0 : min - (min * 0.10);
            // this will add every single day to our datasets
            const aggregatedDataByDay = agregateData('day', deepCloneDataSets(chartData));
            /* let displayData = aggregatedDataByDay;
            if(splitBy !== 'day'){
                displayData = agregateData(splitBy, deepCloneDataSets(aggregatedDataByDay));
            }

            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            */
            setCurrentData(aggregatedDataByDay);
            /*         setDisplayData(displayData);    */
        }
        dispatch(hideLoader());
    };

    useEffect(() => {
        if(currentChart && currentData){
            const displayData = agregateData(splitBy, deepCloneDataSets(currentData));
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(displayData);
        }
    }, [splitBy,currentData, currentChart]);

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    };

    useEffect(()=> {
        handleData();
    }, [datesRange, breakdownData, csvData, currentChart]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => _.uniq(workspaceData.map(el => el.teamMembers).flat()), [workspaceData]);
    // get all of the available skills
    const availableSkills = React.useMemo(() => _.uniq(workspaceData.map(el => el.skills).flat()), [workspaceData]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ?
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    const workspaceTasks = React.useMemo(() => workspaceSelected === 'root' ? Object.keys(stateTasks) : _.get(childsParents, workspaceSelected , [workspaceSelected]), [workspaceSelected, childsParents]);

    const overviewCurrentData = React.useMemo(() => workspaceTasks.reduce((acc, el) => {
        const currentTask = stateTasks[el];

        if(!currentTask || stateTasks.parent && !stateTasks[stateTasks.parent]){
            return acc;
        }

        if(currentTask.status === 'done' && !currentTask.childrens){
            acc.done++;
        }

        if(currentTask.status !== 'done' && !currentTask.childrens){
            acc.notDone++;
        }

        return acc;
    }, { done: 0, notDone: 0 }), [workspaceTasks, stateTasks]);

    if (!hasAutorization) {
        return <Unautorized />;
    }

    return <>
        <Paper className="topSection">
            <div className="breakdownsContainer">
                <BreakdownController
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    tasks={subItensTasks}
                    skills={availableSkills}
                    teamMembers={availableTeamMembers}
                    workspaceSelected={workspaceSelected}
                />
            </div>

            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard title={'Tasks Done'} value={overviewCurrentData.done} />
                <OverViewCard title={'Tasks to be Done'} value={overviewCurrentData.notDone} />
                <OverViewCard title={'Total Tasks'} value={overviewCurrentData.done + overviewCurrentData.notDone} />
                <OverViewCard title={'% complete'} value={`${Math.round((overviewCurrentData.done*100) / (overviewCurrentData.notDone + overviewCurrentData.done) * 100) / 100}%`  } />
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(chartCanvas.current)}>
                    Save as Img
                    </Button>
                </div>
                <canvas height="300" width="800" ref={chartCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                        Save as CSV
                    </Button>
                </div>
                <TaskBurnDownTable
                    onColumnHover={onColumnHover}
                    userDateFormat={dateFormat}
                    datasets={displayData.datasets}
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
};

TaskBurndown.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(TaskBurndown);