import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getAccountTaskRisksTaskAtDanger, getAccountTaskRisksTaskAtRisk, getAccountTaskRisksTaskEstimationError, getAccountTaskRisksTaskForcedUserNoShe, getAccountTaskRisksTaskNoEffort, getAccountTaskRisksTaskNoSkill, getAccountTaskStatus } from '../../utils/selectors/account';
import { useSelector } from 'react-redux';
import DangerIcon from '@material-ui/icons/Warning';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskDetailWarnings = ({ taskId }) => {
    const intl = useIntl();

    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const TaskRisksTaskForcedUserNoShe = useSelector(getAccountTaskRisksTaskForcedUserNoShe(taskId));
    const TaskRisksTaskAtDanger = useSelector(getAccountTaskRisksTaskAtDanger(taskId));
    const TaskRisksTaskAtRisk = useSelector(getAccountTaskRisksTaskAtRisk(taskId));
    const TaskRisksTaskNoSkill = useSelector(getAccountTaskRisksTaskNoSkill(taskId));
    const TaskRisksTaskNoEffort = useSelector(getAccountTaskRisksTaskNoEffort(taskId));
    const TaskRisksTaskEstimationError = useSelector(getAccountTaskRisksTaskEstimationError(taskId));

    const getWarnings = () => {
        if (TaskRisksTaskForcedUserNoShe) {
            return (
                <div className="warnings danger">
                    <DangerIcon className="DangerIcon" /> {intl.formatMessage({id: 'deadline.taskForcedUserNoShe'})}
                </div>
            );
        }

        if (TaskRisksTaskAtDanger) {
            return (
                <div className="warnings danger">
                    <DangerIcon className="DangerIcon" /> {intl.formatMessage({id: 'deadline.danger'})}
                </div>
            );
        } 
        if (TaskRisksTaskAtRisk) {
            return (
                <div className="warnings risk">
                    <DangerIcon className="RiskIcon" /> {intl.formatMessage({id: 'deadline.risk'})}
                </div>
            );
        } 
        if (TaskRisksTaskNoSkill) {
            return (
                <div className="warnings danger">
                    <DangerIcon className="DangerIcon" /> {intl.formatMessage({id: 'task.risk.noskill'})}
                </div>
            );
        } 

        if (TaskRisksTaskNoEffort) {
            return (
                <div className="warnings danger">
                    <DangerIcon className="DangerIcon" /> {intl.formatMessage({id: 'task.risk.noeffort'})}
                </div>
            );
        } 

        if (TaskRisksTaskEstimationError === 'no.user.with.required.skill') {
            return (
                <div className="warnings danger">
                    <DangerIcon className="DangerIcon" /> {intl.formatMessage({id: 'no.user.with.required.skill'})}
                </div>
            );
        }
        return null;
    };


    return (
        <div>
            {taskStatus !== 'done' && getWarnings()}
        </div>
    );
};

TaskDetailWarnings.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default withCustomErrorBoundary(TaskDetailWarnings);
