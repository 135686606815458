import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { getListenerNotifications, updateBellNotification, offNotificationsListener } from '../../utils/databaseQuerys/notifications';
import NotificationsHubHeader from './NotificationsHubHeader';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../../components/Loader';
import RenderNotifications from './RenderNotifications';
import { getLoadersTasks, getLoadersUsers } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const NotificationsHub = ({ children }) => {
    const [currentTab, setCurrentTab] = useState('all');
    const [currentNotifications, setCurrentNotifications] = useState({});
    const [switchValue, setSwitchValue] = useState(true);
    const [loading, setLoading] = useState(true);
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);
    const taskLoaded = useSelector(getLoadersTasks);
    const usersLoaded = useSelector(getLoadersUsers);

    useEffect(() => {
        
        if (currentAccountId && currentUserId) {
            getListenerNotifications(
                {
                    accountId: currentAccountId,
                    userId: currentUserId,
                },
                data => {
                    if (data.val()) {
                        setCurrentNotifications(data.val());
                        updateBellNotification({
                            accountId: currentAccountId,
                            userId: currentUserId,
                            value: 0
                        });
                    }
                      
                    if(loading){
                        setLoading(false);
                    }
                },
            );
        }

        return () => {
            if(currentAccountId && currentUserId){
                offNotificationsListener({
                    accountId: currentAccountId,
                    userId: currentUserId,
                });
            }
        };
    }, [currentAccountId, currentUserId]);

    const handleChangeTab = useCallback((_, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleChangeSwitch = useCallback(() => {
        setSwitchValue(!switchValue);
    }, [switchValue]);

    const aggregatedByDays = React.useMemo(() => {
        if (_.isEmpty(currentNotifications)) {
            return {
                today: {},
                sevenDays: {},
                earlier: {},
            };
        }
        const splittedIntoTask = Object.values(currentNotifications).reduce((acc, notification) => {
            if(switchValue && notification.readed) {
                return acc;
            }
            
            if(currentTab === 'mentions') {
                if(notification.field !== 'comments' && notification.field !== 'forcedUser') {
                    return acc;
                }
                if(notification.field === 'comments' && !Object.values(_.get(notification,['newValue'], {})).find(comment => comment.mentions?.find(mention => mention.id === currentUserId))) {
                    return acc;
                }
                if(notification.field === 'forced' && !_.get(notification,['newValue'],  []).map(user => user.id === currentUserId)) {
                    return acc;
                }
                
            }
            
            if (acc[notification.taskId]) {
                return {
                    ...acc,
                    [notification.taskId]: [...acc[notification.taskId], notification],
                };
            }
            
            return {
                ...acc,
                [notification.taskId]: [notification],
            };
        }, {});
        
        return Object.keys(splittedIntoTask).reduce(
            (acc, taskId) => {
                const lastNot = splittedIntoTask[taskId][splittedIntoTask[taskId].length-1];
                if (moment(lastNot.createdAt).isSame(moment(), 'day')) {
                    return {
                        ...acc,
                        today: {
                            ...acc.today,
                            [taskId]: splittedIntoTask[taskId].sort((a,b) => a.createdAt > b.createdAt ? -1 : 1),
                        },
                    };
                }
                if (moment(lastNot.createdAt).isBetween(moment().add(1, 'days'), moment().add(8, 'days'), 'day')) {
                    return {
                        ...acc,
                        sevenDays: {
                            ...acc.sevenDays,
                            [taskId]: splittedIntoTask[taskId].sort((a,b) => a.createdAt > b.createdAt ? -1 : 1),
                        },
                    };
                }
                return {
                    ...acc,
                    earlier: {
                        ...acc.earlier,
                        [taskId]: splittedIntoTask[taskId].sort((a,b) => a.createdAt > b.createdAt ? -1 : 1),
                    },
                };
            },
            {
                today: {},
                sevenDays: {},
                earlier: {},
            },
        );
    }, [currentNotifications, currentTab, currentUserId, switchValue]);

    if(taskLoaded || usersLoaded || loading) {
        return (
            <CardContent>
                <Loader fullScreen={false} show={true} />
            </CardContent>
        );
    }

    if(_.isEmpty(aggregatedByDays.today) && _.isEmpty(aggregatedByDays.sevenDays) && _.isEmpty(aggregatedByDays.earlier)){
        return (
            <div className="Notifications">
                <NotificationsHubHeader
                    handleChangeSwitch={handleChangeSwitch} 
                    switchValue={switchValue}
                    handleChange={handleChangeTab}
                    value={currentTab}
                />
                <div className="allRead">
                    <img src="../../../stylesheets/assets/nonotif.png?v=2" alt="No notification"/>
                    {switchValue && <span>No unread notifications</span>}
                    {!switchValue && <span>No readed notifications</span>}
                </div>
            </div>
        );
    }

    return (
        <div className="Notifications">
            <NotificationsHubHeader
                handleChangeSwitch={handleChangeSwitch} 
                switchValue={switchValue}
                handleChange={handleChangeTab}
                value={currentTab}
            />
            { !_.isEmpty(aggregatedByDays.today) && <RenderNotifications title="today" notificationsByTask={aggregatedByDays.today} /> }
            { !_.isEmpty(aggregatedByDays.sevenDays) && <RenderNotifications title="sevenDays" notificationsByTask={aggregatedByDays.sevenDays} /> }
            { !_.isEmpty(aggregatedByDays.earlier) && <RenderNotifications title="earlier" notificationsByTask={aggregatedByDays.earlier} /> }
            {children}
        </div>
    );
};

export default withCustomErrorBoundary(NotificationsHub);

