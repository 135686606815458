import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getAccountTaskDoneAt, getAccountTaskEstimations, getAccountTaskRisksTaskAtDanger, getAccountTaskRisksTaskAtRisk, getAccountTaskStartedWorkingAt, getAccountTaskStatus } from '../../utils/selectors/account';
import { getUserDateformat, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import { useIntl } from 'react-intl';
import classnames from 'clsx';

const TaskDetailTimeline = ({taskId}) => {
    const intl = useIntl();

    const taskStartedWorkingAt = useSelector(getAccountTaskStartedWorkingAt(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskDoneAt = useSelector(getAccountTaskDoneAt(taskId));
    const taskEstimations = useSelector(getAccountTaskEstimations(taskId));
    const taskAtDanger = useSelector(getAccountTaskRisksTaskAtDanger(taskId));
    const taskAtRisk = useSelector(getAccountTaskRisksTaskAtRisk(taskId));
    
    const timeFormat = useSelector(getUserTimeformat);
    const timezone = useSelector(getUserTimezone);
    const dateFormat = useSelector(getUserDateformat);

    const userDateFormat = (dateFormat || 'DD/MM/YY') + ' ' + (timeFormat || 'HH:mm');
    let legends = [];

    if (taskStartedWorkingAt) {
        legends.push(
            <div key="Started" className="worked">
                <span>{intl.formatMessage({id: 'First started'})}</span>
                <strong>{moment(taskStartedWorkingAt).format(dateFormat)}</strong>
            </div>,
        );
    }

    if (taskStatus === 'done') {
        legends.push(
            <div key="Finished" className="done">
                <span>{intl.formatMessage({id: 'Finished'})}</span>
                <strong>{moment(taskDoneAt).format(userDateFormat)}</strong>
            </div>,
        );
    } else {
        if (taskEstimations && taskEstimations.startAt) {
            legends.push(
                <div key="Start" className="working">
                    <span>{intl.formatMessage({id: 'Start working'})}</span>
                    <strong>
                        {moment
                            .tz(taskEstimations.startAt, 'X', timezone || moment.tz.names())
                            .format(userDateFormat)}
                    </strong>
                </div>,
            );
        }

        if (taskEstimations && taskEstimations.expectedAt) {
            legends.push(
                <div key="Expected" className="expected">
                    <span>{intl.formatMessage({id: 'Expected done'})}</span>
                    <strong>
                        {moment
                            .tz(taskEstimations.expectedAt, 'X', timezone || moment.tz.names())
                            .format(userDateFormat)}
                    </strong>
                </div>,
            );
        }

        if (taskEstimations && taskEstimations.endAt) {
            legends.push(
                <div key="Latest" className="working">
                    <span>{intl.formatMessage({id: 'Latest done'})}</span>
                    <strong>
                        {moment.tz(taskEstimations.endAt, 'X', timezone || moment.tz.names()).format(userDateFormat)}
                    </strong>
                </div>,
            );
        }
    }

    return (
        <div className={classnames('timeline', { danger: taskAtDanger, risk: taskAtRisk })}>
            {legends}
        </div>
    );
};

TaskDetailTimeline.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailTimeline;
