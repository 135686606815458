import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getBaseLocation, tracking } from '../../utils/utils';
import { createNewTask } from '../../utils/firebaseEvents';
import { DatePicker } from '@material-ui/pickers';
import SelectSkill from '../SelectSkill/SelectSkill';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import SelectorsDialog from '../SelectorsDialog/SelectorsDialog';
import CloseIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from 'rc-tooltip';
import classnames from 'clsx';
import { browserHistory, withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'underscore';
import SelectTag from '../SelectTag/SelectTag';
import Chip from '@material-ui/core/Chip';
import RemoveIcon from '@material-ui/icons/Cancel';
import { getAccountBasePermissions, getAccountId, getAccountSkills, getAccountTags, getAccountTasks, getSkillsDescription } from '../../utils/selectors/account';
import { getUserDateformat, getUserPermissionsAdmin } from '../../utils/selectors/user';
import { getOrderedTaskList } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useConfirmToastrEnterKey from '../../utils/hooks/useConfirmEnterKey';

const getTaskPath = ({ taskId, tasks }) => {
    if (tasks[taskId]) {
        var parentsPath = [tasks[taskId].title],
            currentTask = taskId;

        while (tasks[currentTask] && tasks[currentTask].parent) {
            parentsPath.push(tasks[tasks[currentTask].parent].title);
            currentTask = tasks[currentTask].parent;
        }
        parentsPath = parentsPath.reverse().join('/');

        return parentsPath;
    }
    return null;
};

const AddTaskModal = ({ routes, location }) => {
    const intl = useIntl();
    useConfirmToastrEnterKey({ disableConfirm: false });

    const [isShowingSkillSelector, setIsShowingSkillSelector] = useState(false);
    const [openSelDependencies, setOpenSelDependencies] = useState(false);
    const [openSelTags, setOpenSelTags] = useState(false);
    const [isUserSelectorOpen, setUserSelector] = useState(false);
    const [taskData, setTask] = useState({
        title: '',
        maxEffort: 1,
        minEffort: 0,
        priority: 0,
        forcedUser: null,
        depedencies: [],
        dueDate: null,
        delayDate: null,
        parent: null,
    });
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [constraintsOpen, setConstraintsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(0);
    const [depedencies, setDepedencies] = useState([]);
    const [tags, setTags] = useState([]);
    const userDateFormat = useSelector(getUserDateformat);
    const orderedTaskList = useSelector(getOrderedTaskList);
    const basePermissions = useSelector(getAccountBasePermissions);
    const accountId = useSelector(getAccountId);
    const accountTasks = useSelector(getAccountTasks);
    const accountTags = useSelector(getAccountTags);
    const accountSkills = useSelector(getAccountSkills);
    const isAdmin = useSelector(getUserPermissionsAdmin);
    const dataToSelectUser = React.useMemo(() => [selectedUser], [selectedUser]);

    const skillsDescription = useSelector(getSkillsDescription(selectedSkills));
    const hideSkillSelector = () => setIsShowingSkillSelector(false);
    const showSkillSelector = () => setIsShowingSkillSelector(true);

    // for trello integration, if the location is a board, change the location to be the first board under it and open the More constraints
    useEffect(()=>{
        if(taskData?.parent?.id && accountTasks[taskData?.parent?.id]?.sourceType === 'board') {
            const firstBoardId = accountTasks[taskData?.parent?.id].childrens.sort((a, b) => accountTasks[a.id]?.index < accountTasks[b.id]?.index ? -1 : 1)[0].id;
            const parent = getBaseLocation({
                location: {
                    query: {
                        parentId: firstBoardId
                    }
                },
                account: {
                    tasks: accountTasks,
                    settings: {
                        basePermissions: basePermissions
                    }
                },
                user: { data: { permissions: { admin: isAdmin } } }
            });

            setTask({
                ...taskData,
                parent,
            });
            hideSkillSelector();
            setDepedencies([]);
            setConstraintsOpen(true);
            toastr.warning(intl.formatMessage({id: 'addTaskModal.trello.board.warning'}));
        }
    }, [taskData.parent, accountTasks, basePermissions, isAdmin]);

    const handleClose = useCallback(() => {
        tracking('CanceledAddTask', {
            accountId: accountId,
            currentSkill: Object.keys(accountSkills || {}).length,
            currentTasks:  Object.keys(accountTasks || {}).length
        });
        const path = routes[3].path === ':uid' ? '/' + routes[2].path : '/' + routes[3].path;
        browserHistory.push(path);
    }, [routes]);

    useEffect(() => {
        const data = getBaseLocation({
            location: {
                query: {
                    parentId: location.query?.parentId
                }
            },
            account: {
                tasks: accountTasks,
                settings: {
                    basePermissions: basePermissions
                }
            },
            user: { data: { permissions: { admin: isAdmin } } }
        });

        setTask({
            ...taskData,
            parent: data,
        });

    }, [accountTasks, basePermissions, isAdmin, location]);

    const handleInputs = ({ target: { value, name } }) => {
        setTask({
            ...taskData,
            [name]: value,
        });
    };

    const handleMinEffortInput = ({ target: { value } }) => {
        value = value.replace(',', '.');

        if (value[value.length - 1] === 'd') {
            value = Math.round((parseFloat(value.slice(0, value.length - 1)) * 8) * 100) / 100;
        } else if (value[value.length - 1] === 'm') {
            value = Math.round((parseFloat(value.slice(0, value.length - 1)) / 60) * 100) / 100;
        }

        value = !value ? 0 : value;
        setTask({
            ...taskData,
            minEffort: value,
        });
    };

    const handleMaxEffortInput = ({ target: { value } }) => {
        value = value.replace(',', '.');

        if (value[value.length - 1] === 'd') {
            value = Math.round((parseFloat(value.slice(0, value.length - 1)) * 8) * 100) / 100;
        } else if (value[value.length - 1] === 'm') {
            value = Math.round((parseFloat(value.slice(0, value.length - 1)) / 60) * 100) / 100;
        }

        value = !value ? 0 : value;
        setTask({
            ...taskData,
            maxEffort: value,
        });
    };

    const handleMaxEffortBlur = () => {
        setTask({
            ...taskData,
            maxEffort: taskData.maxEffort,
            minEffort: (!isNaN(taskData.maxEffort) && parseFloat(taskData.maxEffort) < parseFloat(taskData.minEffort)) ? taskData.maxEffort : taskData.minEffort,
        });
    };

    const handleMinEffortBlur = () => {
        setTask({
            ...taskData,
            minEffort: taskData.minEffort,
            maxEffort: !isNaN(taskData.minEffort) && parseFloat(taskData.minEffort) > parseFloat(taskData.maxEffort) ? taskData.minEffort : taskData.maxEffort,
        });
    };

    const autoCompleteOptions = React.useMemo(() => {
        const options = [{ id: 'base', title: '---' + intl.formatMessage({ id: 'Top level of the workspace' }) + '---' }];
        if (orderedTaskList) {
            orderedTaskList.forEach((t) => {
                if (
                    t.canManage &&
                    // rules for trello synced folders
                    accountTasks[t.id].sourceType !== 'board' &&
                    accountTasks[t.id].sourceType !== 'card' &&
                    (!accountTasks[t.id].parent || accountTasks[accountTasks[t.id].parent]?.sourceType !== 'list') &&
                    !(
                        (
                            accountTasks[t.id].sourceType === 'list' ||
                            !accountTasks[t.id].parent ||
                            accountTasks[accountTasks[t.id].parent]?.sourceType !== 'board'
                        )
                        &&
                        !accountTasks[t.id].sourceId
                    )
                ) {
                    options.push({
                        id: t.id,
                        title: getTaskPath({
                            tasks: accountTasks,
                            taskId: t.id
                        })
                    });
                }
            });
        }

        return options;
    }, [orderedTaskList, accountTasks]);


    const changeDueDate = (date) => {
        setTask({
            ...taskData,
            dueDate: date ? date.valueOf() : null,
        });
    };

    const changeDelayDate = (date) => {
        setTask({
            ...taskData,
            delayDate: date ? date.valueOf() : null,
        });
    };

    const openMoreContraints = () => {
        setConstraintsOpen(true);
    };

    const handleDependenciesSelector = () => {
        setOpenSelDependencies(!openSelDependencies);
    };

    const handleTagsSelector = () => {
        setOpenSelTags(!openSelTags);
    };

    const handleUserSelectorOpen = () => {
        setUserSelector(!isUserSelectorOpen);
    };

    const onSelectSkills = useCallback((skillIds) => {
        setSelectedSkills(skillIds);
        hideSkillSelector();
    }, [taskData]);

    const handleSelectTeamMember = useCallback((userId) => {
        setUserSelector(false);

        if (selectedUser === userId) {
            setSelectedUser(0);
            return;
        }

        setSelectedUser(userId);
    }, [taskData]);

    const setParentLvl = (e, val) => {
        setTask({
            ...taskData,
            parent: val,
        });
        setIsShowingSkillSelector(false);
        setDepedencies([]);
    };

    const handleDependencies = (val) => {
        if (depedencies.includes(val)) {
            setDepedencies(depedencies.filter(el => el !== val));
            return;
        }
        setDepedencies([...depedencies, val]);
    };

    const handleTags = (val) => {
        if(typeof val === 'string'){
            setTags(tags.filter(tag=>tag!==val));
        }
        else {
            setTags(val);
            handleTagsSelector();
        }
    };

    const stateTasksReplace = React.useMemo(() => {
        const stateTasksReplace = {
            ...accountTasks,
            ['newTask']: {
                ...taskData,
                parent: taskData.parent?.id !== 'base' ? taskData.parent?.id : null
            }
        };

        if (taskData.parent && taskData.parent?.id !== 'base' && stateTasksReplace[taskData.parent.id]) {
            stateTasksReplace[taskData.parent.id].childrens = stateTasksReplace[taskData.parent.id].childrens ? [...stateTasksReplace[taskData.parent.id].childrens] : [{id: 'newTask'}];
        }

        return stateTasksReplace;
    }, [accountTasks, taskData]);

    const handleCreateTask = () => {
        if (String(taskData.title).trim() === '') {
            toastr.error('The task\'s name is required.');
            return;
        }
        if (taskData.maxEffort <= 0) {
            toastr.error('An effort is required.');
            return;
        }

        if (!selectedSkills) {
            setTimeout(()=>{
                toastr.confirm('Without a Skill your task won\'t be planned automatically. Are you sure?', {
                    onOk: () => {
                        createNewTask({
                            ...taskData,
                            parentId: taskData.parent?.id !== 'base' ? taskData.parent.id : null,
                            skill: selectedSkills,
                            dependencies: depedencies,
                            assignee: selectedUser,
                            tags: tags
                        });
                        handleClose();
                    },
                    okText: 'ADD TASK',
                    cancelText: 'CANCEL',
                });
            }, 200);
            return;
        }

        createNewTask({
            ...taskData,
            parentId: taskData.parent?.id !== 'base' ? taskData.parent.id : null,
            skill: selectedSkills,
            dependencies: depedencies,
            assignee: selectedUser,
            tags: tags
        });

        handleClose();
    };

    const handleTitleKeyPress = (e) => {
        if ((e.key === 'Enter' || e.keyCode === 13) && String(taskData.title).trim() !== '') {
            handleCreateTask();
            return;
        }
    };
    const handleFocus = e => e.target.select();

    return <Dialog
        className="AddTaskModal"
        open={true}
        onClose={handleClose}
        maxWidth={false}
        aria-labelledby="customized-dialog-title">
        <MuiDialogTitle className="title" disableTypography >
            <Typography variant="h6"> {intl.formatMessage({ id: 'Add a Task' })}</Typography>
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers>

            <FormControl className="row" component="fieldset">
                <TextField
                    onKeyPress={handleTitleKeyPress}
                    className="remove-padding"
                    autoFocus
                    placeholder="Task Name"
                    value={taskData.title}
                    onChange={handleInputs}
                    id="title"
                    name="title"
                    label={intl.formatMessage({ id: 'Task Name' })}
                    type="text"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </FormControl>
            <div className="row">
                <FormControl fullWidth className="skill-container" component="fieldset">
                    <FormLabel className="shrink-label" component="legend">{intl.formatMessage({ id: 'Skill' })} </FormLabel>
                    <Select
                        name="skill"
                        id="skill"
                        value={selectedSkills.length ? selectedSkills[0] : 0}
                        onOpen={showSkillSelector}
                        open={false}
                    >
                        <MenuItem key={0} value={0}>{intl.formatMessage({ id: 'Select a Skill' })}</MenuItem>
                        {selectedSkills.length && (
                            <MenuItem
                                key={selectedSkills.length ? selectedSkills[0] : 0}
                                value={selectedSkills.length ? selectedSkills[0] : 0}>{skillsDescription}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl fullWidth className="effort-container" component="fieldset">
                    <FormLabel className="shrink-label effort-label" component="legend">
                        {intl.formatMessage({ id: 'Min. & Max. Effort (h)' })}
                        <Tooltip placement="top" overlay={intl.formatMessage({ id: 'task.table.effort.help.text' })}>
                            <InfoIcon />
                        </Tooltip>
                    </FormLabel>
                    <FormControl fullWidth component="div">
                        <Input
                            onFocus={handleFocus}
                            onBlur={handleMinEffortBlur}
                            className="min-effort-input"
                            value={taskData.minEffort}
                            onChange={handleMinEffortInput}
                            id="minEffort"
                            name="minEffort"
                            label={intl.formatMessage({ id: 'Min. Effort' })}
                            type="text"
                        />
                    </FormControl>
                    <span className="effort-bar">-</span>
                    <FormControl fullWidth component="div">
                        <Input
                            onFocus={handleFocus}
                            onBlur={handleMaxEffortBlur}
                            className="max-effort-input"
                            value={taskData.maxEffort}
                            onChange={handleMaxEffortInput}
                            id="maxEffort"
                            name="maxEffort"
                            label={intl.formatMessage({ id: 'Max. Effort' })}
                            type="text"
                        />
                    </FormControl>
                </FormControl>
                <FormControl fullWidth className="inputs-container" component="fieldset">
                    <FormLabel className="shrink-label" component="legend">{intl.formatMessage({ id: 'Priority' })} </FormLabel>
                    <TextField
                        select
                        name="priority"
                        id="priority"
                        className={classnames('remove-padding')}
                        value={taskData.priority}
                        onChange={handleInputs}
                    >
                        <MenuItem className="priorityMenuItem" key={0} value={0}>
                            <div className="priorityMenuItem">
                                <div className="priority-ball" />{intl.formatMessage({ id: 'Normal' })}
                            </div>
                        </MenuItem>
                        <MenuItem className="priorityMenuItem" key={1} value={1}>
                            <div className="priorityMenuItem">
                                <div className="priority-ball medium" />{intl.formatMessage({ id: 'Medium' })}
                            </div>
                        </MenuItem>
                        <MenuItem className="priorityMenuItem" key={2} value={2}>
                            <div className="priorityMenuItem">
                                <div className="priority-ball high" />{intl.formatMessage({ id: 'High' })}
                            </div>
                        </MenuItem>
                        <MenuItem className="priorityMenuItem" key={3} value={3}>
                            <div className="priorityMenuItem">
                                <div className="priority-ball urgent" />{intl.formatMessage({ id: 'Urgent' })}
                            </div>
                        </MenuItem>
                    </TextField>
                </FormControl>
            </div>
            {constraintsOpen && <><div className="row">
                <div className="due-date-container">
                    <DatePicker
                        className="date-input"
                        format={userDateFormat}
                        placeholder={intl.formatMessage({ id: 'None' })}
                        emptyLabel={intl.formatMessage({ id: 'None' })}
                        label={<>
                            {intl.formatMessage({ id: 'Wait until' })}
                            <Tooltip className="startAfterTooltip" placement="top" overlay={intl.formatMessage({ id: 'task.table.startAfter.help.text' })}>
                                <InfoIcon />
                            </Tooltip>
                        </>}
                        autoOk={true}
                        onChange={changeDelayDate}
                        value={taskData.delayDate}
                        cancelLabel={null}
                        okLabel={null}
                        id="date"
                        fullWidth
                    />

                    {taskData.delayDate && <RemoveIcon onClick={() => changeDelayDate(null)} />}
                </div>
                <div className="date-input-container">
                    <DatePicker
                        className="date-input-delay"
                        placeholder={intl.formatMessage({ id: 'None' })}
                        emptyLabel={intl.formatMessage({ id: 'None' })}
                        format={userDateFormat}
                        label={intl.formatMessage({ id: 'Due Date' })}
                        onChange={changeDueDate}
                        value={taskData.dueDate}
                        autoOk={true}
                        cancelLabel={null}
                        okLabel={null}
                        id="date"
                        fullWidth
                    />
                    {taskData.dueDate && <RemoveIcon onClick={() => changeDueDate(null)} />}
                </div>
            </div>
            <div className="row">
                {!isEmpty(accountTasks) && <div className="dependencies-container-actions">
                    <span className="dependencies-label">
                        {intl.formatMessage({ id: 'Dependencies' })}
                    </span>
                    {!!depedencies.length && <div className="dependencies-container">
                        {depedencies.map(el => <div key={`dep-${el}`} className='dependencies-bullet'>
                            <span>
                                {accountTasks[el].title}
                            </span>
                            <CloseIcon onClick={() => handleDependencies(el)} />
                        </div>)}
                    </div>}
                    <Button onClick={handleDependenciesSelector} className="dependencies-btn " variant="contained">
                        {intl.formatMessage({ id: 'add a dependency' })}
                    </Button>
                    {openSelDependencies && <SelectorsDialog
                        open={openSelDependencies}
                        onSave={handleDependenciesSelector}
                        onCheck={handleDependencies}
                        onClose={handleDependenciesSelector}
                        taskId={'newTask'}
                        accountId={accountId}
                        checkedItems={depedencies}
                        isSaveDisabled={false}
                        type="manageDependencies"
                        stateTasksReplace={stateTasksReplace}
                    />}
                </div>
                }

                <div className="tags-container-actions">
                    <span className="tags-label">
                        {intl.formatMessage({ id: 'Tags' })}
                    </span>
                    {!!tags.length && <div className="tags-container">
                        {tags.map(el => <Chip key={`dep-${el}`} label={accountTags[el].title} className={'color'+accountTags[el].color} onDelete={() => handleTags(el)} />)}
                    </div>}
                    <Button onClick={handleTagsSelector} className="tags-btn " variant="contained">
                        {intl.formatMessage({ id: 'add a tag' })}
                    </Button>
                    {openSelTags && <SelectTag
                        open={openSelTags}
                        currentTags={tags}
                        onSelect={handleTags}
                        onClose={handleTagsSelector}
                    />}
                </div>
            </div>

            {!isEmpty(accountTasks) &&
                <Autocomplete
                    className="destAutocomplete"
                    options={autoCompleteOptions}
                    value={taskData.parent}
                    getOptionLabel={option => option.title}
                    disableClearable
                    renderInput={params => (
                        <TextField {...params} label={intl.formatMessage({ id: 'Location' })} fullWidth />
                    )}
                    onChange={setParentLvl}
                />
            }

            </>
            }
        </MuiDialogContent>
        <MuiDialogActions>
            {
                !constraintsOpen && <div onClick={openMoreContraints} className="add-constraints">
                    + {intl.formatMessage({ id: 'Add more constraints' })}
                </div>
            }
            <Button
                className="cancel-btn"
                onClick={handleClose}
                variant="contained"
            >
                {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
            <Button
                disabled={isNaN(taskData.maxEffort) || isNaN(taskData.maxEffort)}
                className="submit-btn"
                variant="contained"
                color="primary"
                onClick={handleCreateTask}
            >
                {intl.formatMessage({ id: 'ADD TASK' })}
            </Button>
        </MuiDialogActions>
        {isShowingSkillSelector && <SelectSkill
            isMultiSelect
            currentSkill={selectedSkills}
            onSelect={onSelectSkills}
            onClose={hideSkillSelector}
            open={isShowingSkillSelector}
        />}
        <SelectTeamMember
            currentUser={dataToSelectUser}
            onSelect={handleSelectTeamMember}
            onClose={handleUserSelectorOpen}
            open={isUserSelectorOpen}
        />
    </Dialog>;
};


export default React.memo(withRouter(withCustomErrorBoundary(AddTaskModal)));
