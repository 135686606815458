import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectTag from '../SelectTag/SelectTag';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { tracking } from '../../utils/utils';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTags, getAccountTaskTags, getAccountTaskISourceId, getAccountTaskSyncAppType } from '../../utils/selectors/account';
import { Button, Chip } from '@material-ui/core';
import { getUserId } from '../../utils/selectors/user';

const IGNORE_SYNC_PROJECT = ['trello'];

const TaskDetailSelectTags = ({ taskId }) => {
    const intl = useIntl();
    const [manageTagsOpen, setManageTagsOpen] = useState(false);
    const [currentTags, setCurrentTags] = useState([]);
    const [startTimer, setStartTimer] = useState(0);
    const isSyncProject = useSelector(getAccountTaskISourceId(taskId));
    const taskTags = useSelector(getAccountTaskTags(taskId));
    const accountTags = useSelector(getAccountTags);
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const syncAppType = useSelector(getAccountTaskSyncAppType(taskId));

    useEffect(() => {
        setCurrentTags(taskTags);
    }, [taskTags, taskId]);

    const handleSaveNewTags = (list) => {
        const endTimer = performance.now();
        const timeStamp = Math.round(endTimer - startTimer);
        tracking('TaskDetail Save new tag', {accountId: accountId, userId: userId, timestamp: timeStamp});
        
        firebaseEvents.changeTags(taskId, list);
        setManageTagsOpen(false);
    };

    const handleClose = () => {
        tracking('TaskDetail canceled adding new tags', {accountId: accountId, userId: userId});
        setManageTagsOpen(false);
    };

    const handleOpen = () => {
        setManageTagsOpen(true);
        setStartTimer(performance.now());
    };

    const handleRemoveSingleTag = (tagId) => {
        const newArray = currentTags.filter(id=> id !== tagId);
        firebaseEvents.changeTags(taskId, newArray);
    };

    const getTagsList = (currentTags) =>{
        let result = [];
        if (accountTags && currentTags) {
            currentTags.forEach(tag => {
                if (accountTags[tag]) {
                    result.push(<Chip
                        key={tag} 
                        label={accountTags[tag].title} 
                        className={'color' + accountTags[tag].color} 
                        onDelete={() => handleRemoveSingleTag(tag)} 
                    />);
                }
            });
        }
        return result;
    };

    return (
        <li>
            <dl>
                <dt>{intl.formatMessage({id:'Tags'})}</dt>
                <dt>
                    <div className="listTags">
                        <div>
                            {getTagsList(currentTags)}
                        </div>
                        <Button
                            onClick={handleOpen}
                            variant="contained"
                            color="primary"
                            disabled={isSyncProject && !IGNORE_SYNC_PROJECT.includes(syncAppType)}
                        >
                            {intl.formatMessage({id:'MANAGE TAGS'})}
                        </Button>
                    </div>

                    <SelectTag
                        open={manageTagsOpen}
                        currentTags={currentTags}
                        onSelect={handleSaveNewTags}
                        onClose={handleClose}
                    />
                </dt>
            </dl>
        </li>
    );
};

TaskDetailSelectTags.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailSelectTags;
