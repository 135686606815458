import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { useSelector } from 'react-redux';

import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import FeedbackIcon from '@material-ui/icons/Chat';
import GetHelpIcon from '@material-ui/icons/Help';
import { CompareArrows as ChangeWorkspaceIcon, Slideshow } from '@material-ui/icons';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { ACADEMY_LINK, UPVOTY_LINK } from '../../utils/constants';
import { getAccountCode, getAccountFirstSteps, getAccountName } from '../../utils/selectors/account';
import { getUserFirstSteps, getUserDisplayName, getUserId } from '../../utils/selectors/user';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const HeaderUserAccount = () => {
    const intl = useIntl();
    const accountStepsConfig = useSelector(getAccountFirstSteps);
    const userFirstStepsConfig = useSelector(getUserFirstSteps);
    const name = useSelector(getAccountName);
    const code = useSelector(getAccountCode);
    const userId = useSelector(getUserId);
    const displayName = useSelector(getUserDisplayName);

    const userAvatar = useCallback(() => {
        return (
            <IconButton onClick={() => browserHistory.push('/settings/my-account')}>
                <StateUserAvatar
                    className="avatar"
                    userId={userId}
                    disableTooltip={true}
                />
            </IconButton>
        );
    }, [displayName, userId]);

    const videosWatchedByUser = Object.keys(userFirstStepsConfig?.videos || {}).length === 7;
    const isSkillsStepCompleted = accountStepsConfig?.skills?.completed;
    const isTasksStepCompleted = accountStepsConfig?.tasks?.completed;
    const isUsersStepCompleted = accountStepsConfig?.users?.completed;
    const isUserWorkingStep = userFirstStepsConfig?.startWorking?.completed;
    const allCompleted =
        videosWatchedByUser &&
        isSkillsStepCompleted &&
        isTasksStepCompleted &&
        isUsersStepCompleted &&
        isUserWorkingStep;
    const hasAccessToChangeWorkspace = true; //(userAccounts || []).length > 1 || (userEmail || '').includes('planless');

    return (

        <div className='HeaderUserAccount'>

            <div className="userAccountIconMenu">
                <div className='userAccountMenu'>
                    <div className='userAccountIconMenu__top'>
                        <div className='workspaceData' onClick={() => browserHistory.push('/settings/workspace') }>
                            <h2>{name || intl.formatMessage({id:'Untitled'})}</h2>
                            <h3>{code}</h3>
                        </div>
                    </div>

                    { hasAccessToChangeWorkspace && <MenuItem onClick={()=>{ browserHistory.push('/workspaces'); }}><ChangeWorkspaceIcon /> {intl.formatMessage({id:'Change Workspace'})}</MenuItem>}
                    <MenuItem onClick={()=>{ window.open(ACADEMY_LINK); }}><GetHelpIcon /> {intl.formatMessage({id:'Get Help'})}</MenuItem>
                    <MenuItem onClick={()=>{ window.open(UPVOTY_LINK); }}><FeedbackIcon /> {intl.formatMessage({id:'Give feedback'})}</MenuItem>
                    {allCompleted && <MenuItem onClick={() => { browserHistory.push('/first-steps');}}><Slideshow /> {intl.formatMessage({id:'How to'})}</MenuItem>}
                    <MenuItem onClick={()=>firebaseEvents.logOut(intl)}><LogoutIcon className="logoutIcon" /> {intl.formatMessage({id:'Log out'})}</MenuItem>
                </div>
            </div>
            {userAvatar()}
        </div>
    );
};

export default withCustomErrorBoundary(HeaderUserAccount);
