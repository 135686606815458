import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import SplitBy from '../SplitBy/SplitBy';
import ImageIcon from '@material-ui/icons/Image';
import OverViewCard from '../OverViewCard';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import ReactDOM from 'react-dom';

import {
    agregateData,
    convertToDateTime,
    deepCloneDataSets,
    borderDash,
    getDataByTask,
    downloadAsImage,
    getColor,
    exportCSV,
    validateNumber,
    getDateFormated,
    getMonth,
    isSameDate,
} from '../utils';
import EventsTable from './EventsTable';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const parseMoment = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
};

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
}

const breakdownDataByUseres = ({
    lastValue,
    currentChartData,
    csvLines,
    data,
    tasksIds,
    teamMembersData,
    hasBreakdownByTask,
    stateTasks,
}) =>
    data.reduce((acc, userId) => {
        if (!userId) {
            return acc;
        }

        if (hasBreakdownByTask) {
            //get all the availble tasks
            tasksIds.forEach(taskId => {
                const id = userId + '-' + taskId;
                let hoursOfEvents = 0;
                const findData = csvLines.find(line => line.id === taskId);
                if (findData) {
                    const idxOfSelect = findData?.teamMembers?.findIndex(key => userId === key);
                    if (idxOfSelect > -1) {
                        const value = findData['Nb hours events on the day Team members']
                            ? parseInt(findData['Nb hours events on the day Team members'][idxOfSelect] || 0)
                            : 0;
                        const valueCalendarIntegration = findData['calendarIntegrationEventByTeam']
                            ? parseInt(findData['calendarIntegrationEventByTeam'][idxOfSelect] || 0)
                            : 0;

                        hoursOfEvents = value + valueCalendarIntegration;
                    } else if (lastValue) {
                        const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                        hoursOfEvents = lastChartPointData?.hoursOfEvents || 0;
                    }
                } else if (lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    hoursOfEvents = lastChartPointData?.hoursOfEvents || 0;
                }

                acc.push({
                    id,
                    label: teamMembersData[userId]?.displayName + '-' + stateTasks[taskId].title,
                    hoursOfEvents: hoursOfEvents,
                    tasksId: [taskId],
                    breakdownId: userId,
                    parentName: teamMembersData[userId]?.displayName || 'Deleted User',
                    subName: stateTasks[taskId].title,
                });
            });

            return acc;
        }

        // if no other breakdown.
        const linesWithUser = csvLines.filter(line => line.teamMembers.includes(userId));
        const vadas = linesWithUser.reduce(
            (acc, line) => {
                const idxOfSelect = line?.teamMembers?.findIndex(key => userId === key);
                if (idxOfSelect >= 0) {
                    const value = line['Nb hours events on the day Team members']
                        ? parseInt(line['Nb hours events on the day Team members'][idxOfSelect] || 0)
                        : 0;
                    const valueCalendarIntegration = line['calendarIntegrationEventByTeam']
                        ? parseInt(line['calendarIntegrationEventByTeam'][idxOfSelect] || 0)
                        : 0;

                    acc.hoursOfEvents += (value + valueCalendarIntegration) | 0;
                    acc.tasksId.push(line.id);
                }
                return acc;
            },
            { hoursOfEvents: 0, maxEffort: 0, tasksId: [] },
        );

        acc.push({
            id: userId,
            label: teamMembersData[userId]?.displayName || 'Deleted User',
            hoursOfEvents: vadas.hoursOfEvents,
            tasksId: vadas.tasksId,
            breakdownId: userId,
            parentName: teamMembersData[userId]?.displayName || 'Deleted User',
        });

        return acc;
    }, []);

const AddNewDataSplited = ({
    tasksSplitedByDay,
    expecetedDate,
    source,
    timeSpent,
    breakdownDataByTask,
    breakdownDataByUsers,
    userSplitedByDay,
    userId,
}) => {
    // for tasks
    if (
        tasksSplitedByDay[expecetedDate] &&
        tasksSplitedByDay[expecetedDate] &&
        tasksSplitedByDay[expecetedDate][source]
    ) {
        tasksSplitedByDay[expecetedDate][source].hoursOfEvents += timeSpent;
    } else if (tasksSplitedByDay[expecetedDate] && tasksSplitedByDay[expecetedDate]) {
        tasksSplitedByDay[expecetedDate][source] = {
            hoursOfEvents: timeSpent,
        };
    } else {
        tasksSplitedByDay[expecetedDate] = {
            [source]: {
                hoursOfEvents: timeSpent,
            },
        };
    }
    // for TM
    if (breakdownDataByTask?.selectedData?.length && breakdownDataByUsers?.selectedData?.length) {
        if (
            userSplitedByDay[expecetedDate] &&
            userSplitedByDay[expecetedDate][userId] &&
            userSplitedByDay[expecetedDate][userId][source]
        ) {
            userSplitedByDay[expecetedDate][userId][source].hoursOfEvents += timeSpent;
        } else if (userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][userId]) {
            userSplitedByDay[expecetedDate][userId][source] = {
                hoursOfEvents: timeSpent,
            };
        } else {
            userSplitedByDay[expecetedDate] = {
                [userId]: {
                    [source]: {
                        hoursOfEvents: timeSpent,
                    },
                },
            };
        }
    } else if (breakdownDataByUsers?.selectedData?.length) {
        if (userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][userId]) {
            userSplitedByDay[expecetedDate][userId].hoursOfEvents += timeSpent;
        } else if (userSplitedByDay[expecetedDate]) {
            userSplitedByDay[expecetedDate][userId] = {
                hoursOfEvents: timeSpent,
            };
        } else {
            userSplitedByDay[expecetedDate] = {
                [userId]: {
                    hoursOfEvents: timeSpent,
                },
            };
        }
    }
};

ChartJs.Chart.register.apply(
    null,
    Object.values(ChartJs).filter(chartClass => chartClass.id),
);

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Max. effort',
                data: [],
                yAxisID: 'y',
                pointRadius: 0,
            },
            {
                label: 'Min. effort',
                data: [],
                yAxisID: 'y',
                pointRadius: 0,
            },
        ],
    },
    options: {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11,
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                    filter: item => {
                        return item.text.indexOf('Average Effort') === -1;
                    },
                },
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return getDateFormated(context[0].label);
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size: 12,
                },
                titleColor: '#fff',
                titleFont: {
                    size: 14,
                },
                boxWidth: 8,
                boxHeight: 8,
            },
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function (value) {
                        const labelValue = this.getLabelForValue(value);
                        if (moment(labelValue, 'YYYY', true).isValid()) {
                            return labelValue;
                        } else if (moment(labelValue, 'YYYY/MM/DD', true).isValid()) {
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        } else if (moment(labelValue, 'M/YYYY', true).isValid()) {
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }

                        return labelValue;
                    },
                    color: '#999',
                    font: {
                        size: 11,
                    },
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                lineWidth: 0,
                drawTicks: false,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11,
                    },
                },
            },
        },
    },
};

const Events = ({
    workspaceData,
    accountId,
    stateTasks,
    datesRange,
    workspaceSelected,
    childsParents,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    userTimezone,
    usersAllEvents,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountEvents = useSelector(state => state?.app?.account?.events);
    const chartCanvas = useRef(null);
    const [csvData, setCSVData] = useState({});
    const [overviewData, setOverviewData] = useState({
        future: 0,
        past: 0,
    });
    const [currentChart, setCurrentChart] = useState(null);
    const [currentData, setCurrentData] = useState({ labels: [], datasets: {} });
    const intl = useIntl();
    const defaultBreakdownLvls = DefaultBreakdownLvls(intl).filter(
        el => el.value === 'none' || el.value === 'users' || el.value === 'tasks',
    );
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);
    const [displayData, setDisplayData] = useState({ labels: [], datasets: {} });
    const dispatch = useDispatch();

    const updateBreakdowns = async newBreakdowns => {
        const breakdownDataByTask = newBreakdowns.find(el => el.value === 'tasks');
        if (
            (!breakdownDataByTask?.selectedData.length && !csvData[workspaceSelected]) ||
            (breakdownDataByTask?.selectedData.length && csvData[workspaceSelected]) ||
            (breakdownDataByTask?.selectedData.length &&
                breakdownDataByTask?.selectedData.length !== Object.keys(csvData).length &&
                !csvData[workspaceSelected])
        ) {
            dispatch(showLoader());
            const data = await getDataByTask({
                accountId: accountId,
                workspaceData: workspaceData,
                selectedTasks: breakdownDataByTask?.selectedData || [],
                userTimezone,
            });

            ReactDOM.unstable_batchedUpdates(() => {
                setBreakdownData(newBreakdowns);
                setCSVData(data);
            });
            return;
        }

        setBreakdownData(newBreakdowns);
    };

    // Should only occor on the mount
    useEffect(() => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = e => {
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);

                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));

                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach(tChild => {
                    tChild.classList.add('hover-row');
                });
                if (document.getElementsByClassName('ReactVirtualized__Grid').length > 1) {
                    const width = document
                        .getElementsByClassName('ReactVirtualized__Grid')[1]?.children[0]?.children[0]?.style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: dataX * width - 60 });
                }
            };

            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () =>
                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]);

    const onWorkspaceChange = async () => {
        dispatch(showLoader());
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
        const data = await getDataByTask({
            accountId: accountId,
            workspaceData: workspaceData,
            selectedTasks: [],
            userTimezone,
        });

        setCSVData(data);
    };

    useEffect(() => {
        onWorkspaceChange();
    }, [workspaceData, accountId]);

    const onColumnHover = columnIndex => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if (columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach(tChild => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return { datasetIndex: idx, index: columnIndex - 1 };
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }

        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = title => getDateFormated(title);

        const renderColumns = item => {
            let line = '';
            line += `${item.name}\n`;
            line += `hoursOfEvents;${item.hoursOfEvents.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets, displayData.labels, renderColumns, formatTitle, 'events');
    };

    const breakdownDataByTask = breakdownData.find(el => el.value === 'tasks');
    const breakdownDataByUsers = breakdownData.find(el => el.value === 'users');

    const handleData = async () => {
        if (currentChart && !_.isEmpty(csvData)) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let pastEventHoursCount = 0;
            let futureEventHoursCount = 0;
            let possibleBreaks = [];
            // get all the tasks IDS
            const allTasksId = Object.keys(csvData);
            const allChartPoints = Object.values(csvData)
                .flat()
                .filter(a => convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime) * 60 * 60 * 4 * 1000);
                    const formatedDate = `${newDate.getFullYear()}/${getMonth(
                        newDate.getMonth() + 1,
                    )}/${newDate.getDate()}`;

                    if (acc[formatedDate]) {
                        return {
                            ...acc,
                            [formatedDate]: [el, ...acc[formatedDate]],
                        };
                    }

                    return {
                        ...acc,
                        [formatedDate]: [el],
                    };
                }, {});

            let lastValue = '';
            let hoursOfEvents = 0;
            // have to go throw all of the checkpoints
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                // if there is no break down we aggregate the information.
                if (breakdownDataByUsers?.lvl === 0 && breakdownDataByUsers.selectedData.length) {
                    possibleBreaks = breakdownDataByUsers.selectedData;

                    const data = breakdownDataByUseres({
                        csvLines: csvLines,
                        currentChartData: chartPointAcc,
                        data: breakdownDataByUsers.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        teamMembersData: accountTeamMembers,
                    });

                    lastValue = key;
                    return {
                        ...chartPointAcc,
                        [key]: data,
                    };
                }

                //if you have the lvl 0 breakdown beeing tasks =
                if (breakdownDataByTask?.lvl === 0) {
                    possibleBreaks = allTasksId;

                    const values = allTasksId.reduce((tasksAcc, taskId) => {
                        // lvl 2 === teamMember
                        const findData = csvLines.find(line => line.id === taskId);
                        if (breakdownDataByUsers && breakdownDataByUsers.selectedData.length) {
                            const teamMembers = breakdownDataByUsers.selectedData.length
                                ? breakdownDataByUsers.selectedData
                                : [];
                            possibleBreaks = teamMembers;
                            teamMembers.forEach(tmId => {
                                let id = taskId + '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData?.teamMembers?.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        const value = findData['Nb hours events on the day Team members']
                                            ? parseInt(
                                                findData['Nb hours events on the day Team members'][idxOfSelect] || 0,
                                            )
                                            : 0;
                                        const valueCalendarIntegration = findData['calendarIntegrationEventByTeam']
                                            ? parseInt(findData['calendarIntegrationEventByTeam'][idxOfSelect] || 0)
                                            : 0;

                                        hoursOfEvents = value + valueCalendarIntegration;
                                    } else if (lastValue) {
                                        const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                        hoursOfEvents = lastChartPointData?.hoursOfEvents || 0;
                                    }
                                } else if (lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    hoursOfEvents = lastChartPointData?.hoursOfEvents || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label:
                                        stateTasks[taskId].title + '-' + accountTeamMembers[tmId]?.displayName ||
                                        'Delete User',
                                    hoursOfEvents: hoursOfEvents,
                                    tasksId: [taskId],
                                    breakdownId: tmId,
                                    parentName: stateTasks[taskId].title,
                                    subName: accountTeamMembers[tmId]?.displayName,
                                });
                            });
                            return tasksAcc;
                        }

                        if (findData) {
                            hoursOfEvents =
                                parseInt(findData['Nb hours events on the day']) |
                                (0 + parseInt(findData['calendarIntegrationEventTotal'])) |
                                0;
                        } else if (lastValue) {
                            const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === taskId);
                            hoursOfEvents = lastChartPointData?.hoursOfEvents || 0;
                        }

                        return [
                            ...tasksAcc,
                            {
                                id: taskId,
                                label: stateTasks[taskId]?.title || 'N/A',
                                hoursOfEvents: hoursOfEvents,
                                tasksId: [taskId],
                                parentName: stateTasks[taskId]?.title || 'N/A',
                            },
                        ];
                    }, []);
                    // saving last value
                    lastValue = key;

                    return {
                        ...chartPointAcc,
                        [key]: values,
                    };
                }
                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) => {
                    const lineValue =
                        (parseInt(line['Nb hours events on the day']) +
                            parseInt(line['calendarIntegrationEventTotal'])) |
                        0;
                    return {
                        id: `default${idx}`,
                        label: '',
                        hoursOfEvents: lineValue < 0 ? 0 : lineValue,
                        tasksId: [line.id],
                        parentName: '',
                    };
                });

                return { ...chartPointAcc, [key]: values };
            }, {});

            const chartData = Object.entries(newData).reduce(
                (acc, [key, items]) => {
                    acc.labels.push(key);

                    items.forEach((el, itemIdx) => {
                        if (!acc.datasets[el.id + '-hoursOfEvents']) {
                            const [colorA] = getColor(possibleBreaks.length, itemIdx);
                            acc.datasets[el.id + '-hoursOfEvents'] = {
                                ...el,
                                // missing this one
                                id: el.id,
                                label: el.label + 'Event Hours',
                                data: [validateNumber(el.hoursOfEvents)],
                                borderColor: colorA,
                                backgroundColor: 'rgba(' + hexToRgb(colorA) + ',0.1)',
                                cubicInterpolationMode: 'monotone',
                                tension: 0.4,
                                pointRadius: 0,
                                borderWidth: 2,
                                pointBorderColor: '#fff',
                                source: el.tasksId,
                                pointBorderWidth: 1,
                                labelType: 'hoursOfEvents',
                                segment: {
                                    borderWidth: 2,
                                    borderDash: borderDash(currentChart, userTimezone),
                                },
                            };
                        } else {
                            acc.datasets[el.id + '-hoursOfEvents'].data.push(validateNumber(el.hoursOfEvents));
                        }
                        pastEventHoursCount += parseInt(validateNumber(el.hoursOfEvents));
                    });

                    return acc;
                },
                {
                    labels: [],
                    datasets: {},
                },
            );

            if (moment(endDate).tz(userTimezone).isAfter(moment().tz(userTimezone))) {
                const userSplitedByDay = {};
                const availableDates = [];
                const tasksSplitedByDay = {};
                let filteredEvents = [];
                if (breakdownDataByTask?.selectedData.length) {
                    const accountEventsKeys = accountEvents ? Object.keys(accountEvents) : [];
                    filteredEvents = breakdownDataByTask.selectedData.map(el => {
                        const childsTasks = _.get(childsParents, el, [el]);
                        return {
                            source: el,
                            events: accountEventsKeys.filter(
                                eventId =>
                                    accountEvents[eventId].parent === workspaceSelected ||
                                    childsTasks.includes(accountEvents[eventId].parent),
                            ),
                        };
                    });
                } else {
                    const accountEventsKeys = accountEvents ? Object.keys(accountEvents) : [];

                    filteredEvents = [
                        {
                            source: workspaceSelected,
                            events:
                                workspaceSelected === 'root'
                                    ? accountEventsKeys
                                    : accountEventsKeys.filter(
                                        eventId =>
                                            accountEvents[eventId].parent === workspaceSelected ||
                                              _.get(childsParents, workspaceSelected, [workspaceSelected]).includes(
                                                  accountEvents[eventId].parent,
                                              ),
                                    ),
                        },
                    ];
                }

                filteredEvents.forEach(base => {
                    base.events.forEach(eventId => {
                        const event = accountEvents[eventId];

                        if (!event) {
                            return false;
                        }

                        // is the event beetween the date range so after today and before the endDate
                        // if there is no recurrency  --- will go normal way the event is valid our will be the startDate of Event.
                        if (
                            !event.recurrency &&
                            moment.tz(event.start, 'X', userTimezone).isBetween(moment().tz(userTimezone), endDate)
                        ) {
                            const expecetedDate = moment.tz(event.start, 'X', userTimezone).format('YYYY/MM/DD');
                            if (!availableDates.includes(expecetedDate)) {
                                availableDates.push(expecetedDate);
                            }
                            // time spent
                            // missing going throug the start date and end date intervalls.
                            const timeSpent = event.allDay ? 8 : parseFloat((event.end - event.start) / 60 / 60);
                            if (event.participants?.length) {
                                event.participants.forEach(participantId => {
                                    futureEventHoursCount += timeSpent;
                                    AddNewDataSplited({
                                        tasksSplitedByDay,
                                        expecetedDate,
                                        timeSpent,
                                        breakdownDataByTask,
                                        breakdownDataByUsers,
                                        userSplitedByDay,
                                        source: base.source,
                                        userId: participantId,
                                    });
                                });
                            } else {
                                futureEventHoursCount += timeSpent;
                                AddNewDataSplited({
                                    tasksSplitedByDay,
                                    expecetedDate,
                                    timeSpent,
                                    breakdownDataByTask,
                                    breakdownDataByUsers,
                                    userSplitedByDay,
                                    source: base.source,
                                    userId: 0,
                                });
                            }
                            return;
                        }
                        const today = moment().tz(userTimezone);
                        if (event.recurrency && moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                            const timeSpent = event.allDay ? 8 : parseFloat((event.end - event.start) / 60 / 60);
                            let lastEvent = moment.tz(event.start, 'X', userTimezone);
                            while (lastEvent.isBefore(endDate)) {
                                if (lastEvent.isAfter(today)) {
                                    const endOfWeek = moment(lastEvent).endOf('week');
                                    Object.keys(event.recurrency).forEach(weekday => {
                                        if (event.recurrency[weekday] && parseMoment[weekday]) {
                                            const expecetedDate = moment(endOfWeek).day(parseMoment[weekday]);
                                            const expecetedDateFormated = expecetedDate.format('YYYY/MM/DD');
                                            if (expecetedDate.isAfter(today, 'day')) {
                                                if (!availableDates.includes(expecetedDateFormated)) {
                                                    availableDates.push(expecetedDateFormated);
                                                }
                                                if (event.participants?.length) {
                                                    event.participants.forEach(participantId => {
                                                        futureEventHoursCount += timeSpent;

                                                        AddNewDataSplited({
                                                            tasksSplitedByDay,
                                                            expecetedDate: expecetedDateFormated,
                                                            timeSpent,
                                                            breakdownDataByTask,
                                                            breakdownDataByUsers,
                                                            userSplitedByDay,
                                                            source: base.source,
                                                            userId: participantId,
                                                        });
                                                    });
                                                } else {
                                                    futureEventHoursCount += timeSpent;

                                                    AddNewDataSplited({
                                                        tasksSplitedByDay,
                                                        expecetedDate: expecetedDateFormated,
                                                        timeSpent,
                                                        breakdownDataByTask,
                                                        breakdownDataByUsers,
                                                        userSplitedByDay,
                                                        source: base.source,
                                                        userId: 0,
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                                lastEvent.add(parseInt(event.recurrency.everyXweeks), 'weeks');
                            }
                        }
                    });
                });
                // if on root lvl I have to get all of the calendar integrated events for each user
                if (!breakdownDataByTask?.length && workspaceSelected === 'root') {
                    const users = breakdownDataByUsers?.selectedData?.length
                        ? Object.values(accountTeamMembers).filter(el =>
                            breakdownDataByUsers?.selectedData.includes(el.userId),
                        )
                        : Object.values(accountTeamMembers);
                    const allEvents = _.flatten(
                        users.map(user =>
                            usersAllEvents[user.userId]
                                ? usersAllEvents[user.userId].map(event => ({ ...event, userId: user.userId }))
                                : [],
                        ),
                    );
                    const filterAllEvents = allEvents.filter(event =>
                        moment.tz(event.start, 'X', userTimezone).isBetween(moment().tz(userTimezone), endDate, 'day'),
                    );
                    filterAllEvents.forEach(event => {
                        const expecetedDate = moment.tz(event.start, 'X', userTimezone).format('YYYY/MM/DD');
                        if (!availableDates.includes(expecetedDate)) {
                            availableDates.push(expecetedDate);
                        }
                        let timeSpent = event.allDay
                            ? 8
                            : (moment.unix(event.end).diff(moment.unix(event.start), 'minutes') | 0) / 60;
                        timeSpent = Math.floor(timeSpent * 100) / 100;
                        futureEventHoursCount += timeSpent;
                        // for tasks
                        if (
                            tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate] &&
                            tasksSplitedByDay[expecetedDate]['root']
                        ) {
                            tasksSplitedByDay[expecetedDate]['root'].hoursOfEvents += timeSpent;
                        } else if (tasksSplitedByDay[expecetedDate] && tasksSplitedByDay[expecetedDate]) {
                            tasksSplitedByDay[expecetedDate]['root'] = {
                                hoursOfEvents: timeSpent,
                            };
                        } else {
                            tasksSplitedByDay[expecetedDate] = {
                                ['root']: {
                                    hoursOfEvents: timeSpent,
                                },
                            };
                        }

                        if (breakdownDataByUsers?.selectedData?.length) {
                            if (userSplitedByDay[expecetedDate] && userSplitedByDay[expecetedDate][event.userId]) {
                                userSplitedByDay[expecetedDate][event.userId].hoursOfEvents += timeSpent;
                            } else if (userSplitedByDay[expecetedDate]) {
                                userSplitedByDay[expecetedDate][event.userId] = {
                                    hoursOfEvents: timeSpent,
                                };
                            } else {
                                userSplitedByDay[expecetedDate] = {
                                    [event.userId]: {
                                        hoursOfEvents: timeSpent,
                                    },
                                };
                            }
                        }
                    });
                }
                // sort the dates
                availableDates.sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf());

                availableDates.forEach(byDate => {
                    const userTasksDoneThisDay = userSplitedByDay[byDate];
                    const tasksDoneThisDay = tasksSplitedByDay[byDate];

                    chartData.labels.push(byDate);
                    Object.values(chartData.datasets).forEach(el => {
                        let value = { hoursOfEvents: 0 };

                        if (
                            breakdownDataByUsers?.selectedData?.length &&
                            userTasksDoneThisDay &&
                            userTasksDoneThisDay[el.breakdownId]
                        ) {
                            value = userTasksDoneThisDay[el.breakdownId] || value;

                            if (breakdownDataByTask && breakdownDataByTask?.selectedData?.length) {
                                value = userTasksDoneThisDay[el.breakdownId][el.source[0]] || value;
                            }
                        }

                        if (!breakdownDataByUsers) {
                            value =
                                tasksDoneThisDay && tasksDoneThisDay[el.source[0]]
                                    ? tasksDoneThisDay[el.source[0]]
                                    : value;
                        }

                        el.data.push(value.hoursOfEvents || 0);
                    });
                });
            }

            currentChart.data.labels = [];
            const datasetsSize = currentChart.data.datasets.length;
            // clear old datasets data
            for (let i = 0; i < datasetsSize; i++) {
                currentChart.data.datasets[i].data = [];
            }

            const firstDay = new Date(startDate);
            const lastDay = new Date(endDate);
            lastDay.setDate(lastDay.getDate() + 1);
            const newLabels = [];
            let idxWithExistingData = 0;
            while (!isSameDate(firstDay, lastDay)) {
                const formatedFirstDay = `${firstDay.getFullYear()}-${getMonth(
                    firstDay.getMonth() + 1,
                )}-${firstDay.getDate()}`;
                const labelExists = chartData.labels.find(el => isSameDate(new Date(el), firstDay));

                if (!labelExists) {
                    Object.values(chartData.datasets).forEach(dataSet => {
                        dataSet.data.splice(idxWithExistingData, 0, 0);
                    });
                }

                newLabels.push(formatedFirstDay);
                firstDay.setDate(firstDay.getDate() + 1);
                idxWithExistingData++;
            }
            chartData.labels = newLabels;
            let displayData = chartData;
            // if the splitBy is !== than day we have to aggregate
            if (splitBy !== 'day') {
                displayData = agregateData(splitBy, { ...deepCloneDataSets(chartData), stakData: true });
            }
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();

            ReactDOM.unstable_batchedUpdates(() => {
                setOverviewData({
                    future: futureEventHoursCount,
                    past: pastEventHoursCount,
                });
                setCurrentData(chartData);
                setDisplayData(displayData);
            });
        }
        dispatch(hideLoader());
    };

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
        currentChart.data.labels = [];
        const datasetsSize = currentChart.data.datasets.length;
        // clear old datasets data
        for (let i = 0; i < datasetsSize; i++) {
            currentChart.data.datasets[i].data = [];
        }

        const aggregatedData = agregateData(mode, { ...deepCloneDataSets(currentData), stakData: true });
        setDisplayData(aggregatedData);
        currentChart.data.datasets = Object.values(aggregatedData.datasets);
        currentChart.data.labels = aggregatedData.labels;
        currentChart.update();
        currentChart.resize();
    };

    useEffect(() => {
        handleData();
    }, [datesRange, breakdownData, csvData, currentChart, usersAllEvents]);

    const availableTeamMembers = Object.keys(accountTeamMembers);

    // get all of the sub childs
    const subItensTasks = React.useMemo(
        () =>
            workspaceSelected === 'root'
                ? Object.entries(stateTasks)
                    .map(([id, el]) => (!el.parent ? { id } : false))
                    .filter(Boolean)
                : _.get(stateTasks[workspaceSelected], 'childrens', []),
        [workspaceSelected],
    );

    if (!hasAutorization) {
        return <Unautorized />;
    }

    const breakdownRules = {
        users: ['none', 'tasks'],
        tasks: ['none', 'users'],
        none: ['none', 'users'],
    };
    return (
        <>
            <Paper className="topSection">
                <div className="breakdownsContainer">
                    <BreakdownController
                        rules={breakdownRules}
                        breakdowns={breakdownData}
                        updateBreakdowns={updateBreakdowns}
                        tasks={subItensTasks}
                        teamMembers={availableTeamMembers}
                        workspaceSelected={workspaceSelected}
                    />
                </div>

                <div className="splitByContainer">
                    <SplitBy currentValue={splitBy} onChange={onChangeSplit} />
                </div>
            </Paper>
            <Paper>
                <div className={classnames('bigNumbers')}>
                    <OverViewCard
                        title="Total Event Hours"
                        value={`${parseFloat(overviewData.future + overviewData.past).toFixed(2)} h`}
                    />
                    <OverViewCard title={'Past Event Hours'} value={`${parseFloat(overviewData.past).toFixed(2)} h`} />
                    <OverViewCard
                        title={'Future Event Hours'}
                        value={`${parseFloat(overviewData.future).toFixed(2)} h`}
                    />
                    <OverViewCard
                        title={`Average Event Hours Per ${intl.formatMessage({ id: splitBy })}`}
                        value={`${parseInt((overviewData.future + overviewData.past) / currentData.labels.length)} h`}
                    />
                </div>
                <div className={classnames('chartArea')}>
                    <div>
                        <Button
                            size="small"
                            color="primary"
                            aria-label="save as img"
                            startIcon={<ImageIcon />}
                            onClick={() => downloadAsImage(chartCanvas.current)}
                        >
                            Save as Img
                        </Button>
                    </div>
                    <canvas height="300" width="800" ref={chartCanvas} />
                </div>
                <div className={classnames('dataTableArea')}>
                    <div>
                        <Button
                            size="small"
                            color="primary"
                            aria-label="save as csv"
                            startIcon={<CsvIcon />}
                            onClick={handleExportAsCSV}
                        >
                            Save as CSV
                        </Button>
                    </div>
                    <EventsTable
                        onColumnHover={onColumnHover}
                        userDateFormat={dateFormat}
                        datasets={displayData.datasets}
                        labels={displayData.labels}
                    />
                </div>
            </Paper>
        </>
    );
};

Events.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(Events);
