import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import _ from 'underscore';
import {toastr} from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getOrderedTaskList } from '../../utils/selectors/app';
import { getUserId } from '../../utils/selectors/user';
import { getAccountId, getAccountTasks } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useSyncData from '../../utils/hooks/useSyncData';
import AddTaskListExample from './AddTaskListExample';
import AddTaskListAutoComplete from './AddTaskListAutoComplete';
import { getTaskPathDescription } from './utils';
import useInternalization from '../../utils/hooks/useInternalization';

const AddTaskList = ({router}) => {
    const { t } = useInternalization();
    const [list, setList] = useState('');
    const [location, setLocation] = useState({id: 'base', title: '---Top level of the workspace---', syncAppType: null});
    const locationId = location && location?.id || '';

    const orderedTaskList = useSelector(getOrderedTaskList);
    const userId = useSelector(getUserId);
    const tasks = useSelector(getAccountTasks);
    const accountId = useSelector(getAccountId);

    const { isSyncAppTask, currentTask, addTaskListLevel, syncAppType } = useSyncData(locationId);

    useEffect(()=>{
        if (router.location.query.parentId) {
            const task = tasks[router.location.query.parentId];
            setLocation({ ...task, title: getTaskPathDescription(task) });
        }
    }, [router.location.query.parentId, tasks]);

    const handleClose = () => browserHistory.push('/tasks');

    const getTasksToAdd = () => {
        const myList = list.split('\n');
        const tempTasks = [];
        const levelsTemp = {};

        let error = false;
        let previousLevel = -1;

        _.each(myList, (task)=>{
            let nbLevels = 0;

            while (task[0] === '-') {
                nbLevels += 0.5;
                task = task.substring(1);
            }

            const isValidLevel = Number.isInteger(nbLevels) && (nbLevels <= previousLevel || nbLevels === (previousLevel + 1));

            if (!isValidLevel) {
                error = true;
                return;
            }

            levelsTemp[nbLevels] = tempTasks.length;

            const isMultiLevels = nbLevels > 0;
            const parentLevel = isMultiLevels ? levelsTemp[nbLevels - 1] : null;

            if (isMultiLevels && !_.isNull(parentLevel)) {
                tempTasks[parentLevel].childrens = tempTasks[parentLevel].childrens || [];
                tempTasks[parentLevel].childrens.push(tempTasks.length);
            }

            tempTasks.push({
                level: nbLevels,
                parent: parentLevel,
                title: _.isEmpty(task) ? t('Untitled') : task,
            });

            previousLevel = nbLevels;
        });

        return {
            tempTasks,
            error,
        };
    };

    const handleSave = () => {
        const { tempTasks, error } = getTasksToAdd();

        if(error){
            toastr.error(t('error.title'), t('add.task.list.error'));
            return;
        }

        const levels = Math.max(...tempTasks.map(el => el.level));
        const isSyncAppValidLevel = Number.isInteger(addTaskListLevel) && levels <= (addTaskListLevel - 1);

        if(isSyncAppTask && !isSyncAppValidLevel) {
            toastr.error(t('clickup.list.reorder.error'));
            return;
        }

        const isValidTaskLocation = locationId && locationId !== 'base';
        const parentId = isValidTaskLocation ? locationId : null;
        const parentTask = !_.isNull(parentId) ? tasks[parentId] : null;
        const parentIndex = !_.isNull(parentTask) && Number.isInteger(parentTask?.index) ? parentTask.index + 1 : 0;
        const parentChildrens = _.get(parentTask, 'childrens', []);

        firebaseEvents.addTaskList(
            accountId,
            tempTasks,
            parentId,
            parentIndex,
            parentChildrens,
            userId,
            null,
            false
        );

        firebaseEvents.addChallenge('addListTask');
        handleClose();
    };

    const getTaskListExample = useCallback(() => {
        return <AddTaskListExample
            task={currentTask}
            isSyncAppTask={isSyncAppTask}
            syncAppType={syncAppType}
            addTaskListLevel={addTaskListLevel} />;
    }, [isSyncAppTask, addTaskListLevel, syncAppType, locationId]);

    const onLocationChange = (e, val) => setLocation(val);

    return (
        <Dialog
            className='AddTaskList'
            open={true}
            onClose={handleClose}
            maxWidth={false}
        >
            <DialogTitle>{t('Add a list of Tasks')}</DialogTitle>
            <DialogContent className="content">
                <div className="content">
                    <AddTaskListAutoComplete
                        tasks={tasks}
                        orderedTaskList={orderedTaskList}
                        onLocationChange={onLocationChange}
                        userId={userId}
                        location={location} />
                    <div className="flex">
                        <div>
                            <textarea autoFocus onChange={(e)=>setList(e.target.value)} value={list} placeholder={t('List here your tasks...')}></textarea>
                        </div>
                        <div>
                            <p>{t('Help')}:</p>
                            {!isSyncAppTask ?
                                <p>{t('add.tasks.list.help.desc')}</p>
                                :
                                <p>
                                    {t('add.tasks.list.help.desc.p.default')}
                                    <span className='syncApp-help-info'>{t(`add.tasks.list.help.desc.${syncAppType}`)}</span>
                                </p>
                            }
                            {getTaskListExample()}
                        </div>
                    </div>


                </div>
            </DialogContent>

            <DialogActions className="actions">
                <Button variant="contained" onClick={handleClose}>{t('Cancel')}</Button>
                <Button variant="contained" disabled={!location || list.trim() === ''} onClick={handleSave} color="primary">{t('Add task list')}</Button>
            </DialogActions>

        </Dialog>
    );

};

export default withRouter(withCustomErrorBoundary(AddTaskList));

