import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { browserHistory } from 'react-router';
import classnames from 'clsx';
import _ from 'underscore';
import $ from 'jquery';
import moment from 'moment-timezone';
import Avatar from '@material-ui/core/Avatar';
import OpenIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/KeyboardArrowDown';
import Tooltip from 'rc-tooltip';
import Timeline from '../Timeline/Timeline';
import { getTaskPathArrow } from '../../utils/utils';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Refresh from '@material-ui/icons/Refresh';
import baffle from 'baffle';
import { tracking } from '../../utils/utils';
import NotInMobile from '../NotInMobile/NotInMobile';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import { getOrderedTaskList } from '../../utils/selectors/app';
import { getUserId, getUserPermissions, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import { getAccountDaysoff, getAccountId, getAccountTasks, getAccountUserGroups, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const Workload = ({children})=>{
    const intl = useIntl();

    const orderedTaskList = useSelector(getOrderedTaskList);
    const timeFormat = useSelector(getUserTimeformat);
    const timezone = useSelector(getUserTimezone);
    const tasks = useSelector(getAccountTasks);
    const daysoff = useSelector(getAccountDaysoff);
    const accountId = useSelector(getAccountId);

    const userGroups = useSelector(getAccountUserGroups);
    const userPermissions = useSelector(getUserPermissions);
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const memberResouces = getOnlyResourceUsers(accountUsers);

    const [closedSortables, setClosedSortables] = useState([]);
    const [timelineRange, setTimelineRange] = useState({
        start: moment().tz(timezone || moment.tz.guess()).subtract(1, 'days'),
        end: moment().tz(timezone || moment.tz.guess()).add(14, 'days')
    });

    let timelineItems = [],
        timelineGroups = [];


    const expandAll = () => {
        var closedSortablesList = [];

        $('.userItem').each(function(index, element){            
            closedSortablesList.push(
                $(element).data('item-id')
            );
        });
        if(userId && accountId){

            if(localStorage.getItem('workloadClosedSortables-' + accountId) === closedSortablesList.toString()){
                setClosedSortables([]);
            }
            else {
                setClosedSortables(closedSortablesList);
            }

            firebaseEvents.addChallenge('expandWorkload');
            localStorage.setItem('workloadClosedSortables-' + accountId, closedSortablesList);
        }
    };


    const collapseAll = () => {
        var closedSortablesList = [];

        $('.item').each(function(index, element){            
            closedSortablesList.push(
                $(element).data('item-id')
            );
        });

        if(userId && accountId){
            firebaseEvents.addChallenge('expandWorkload');
            localStorage.setItem('workloadClosedSortables-' + accountId, closedSortablesList);
        }

        setClosedSortables(closedSortablesList);

    };

    const defineClosedSortables = () => {
        if(userId && accountId && localStorage.getItem('workloadClosedSortables-' + accountId)){
            setClosedSortables(localStorage.getItem('workloadClosedSortables-' + accountId).split(','));
        }
        else{
            expandAll();
        }
    };

    const changeHandler = () => {
        $('.vis-foreground .vis-group.tasksGroup').each((i,e)=>{
            $($('.taskItem')[i]).height($(e).height());
        });


        $('.vis-timeline').height(
            $('.list > .group').height()+36
        );


        $('.vis-panel.vis-background, .vis-panel.vis-center').each((i,e)=>{
            $(e).height(
                $('.list > .group').height()-3
            );
        });

        var groupsHeight = 0;
        $('.vis-panel.vis-center .vis-foreground .vis-group').each((i,e)=>{
            groupsHeight += $(e).height();
        });

        $('.vis-panel.vis-center .vis-itemset').height(groupsHeight);
        
    };

    const setTimelineHelperPosition = () => {
        let timelineHelper = $('#timelineHelper');
        if(timelineHelper){
            timelineHelper.css('top',  $(window).scrollTop()+200);
        }
    };

    useEffect(()=>{
        if(accountId){
            var range = localStorage.getItem('workloadTimelineRange-' + accountId);

            if(range){
                setTimelineRange(JSON.parse(range));
            }
        }
    },[accountId]);

    useEffect(()=>{
        $(window).on('resize', changeHandler);
        $(window).on('scroll', setTimelineHelperPosition);
        $(window).on('resize', setTimelineHelperPosition);

        tracking('Saw Workload View');

        return function cleanup(){
            $(window).off('resize', changeHandler);
            $(window).off('scroll', setTimelineHelperPosition);
            $(window).off('resize', setTimelineHelperPosition);
        };
    }, []);

    useEffect(()=>{
        if(userId && accountId && accountUsers && tasks){
            defineClosedSortables();
        }
    }, [userId, accountId, accountUsers, tasks]);

    useEffect(()=>{
        setTimelineHelperPosition();

        baffle('.baffle').set({
            characters: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz',
            exclude: ['»']
        }).once();
    });


    const itemoverHandler = () => {
        baffle('.vis-tooltip .baffle').set({
            characters: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz',
            exclude: ['»']
        }).once();
    };

    const getUserAvatar = (userId) => {
        if(!userId) return <Tooltip placement="right" overlay={intl.formatMessage({id:'Not defined'})}><Avatar className="avatar emptyAvatar">?</Avatar></Tooltip>;

        var displayName = null;
        
        if(memberResouces[userId] && memberResouces[userId].displayName){
            displayName = memberResouces[userId].displayName;
        }
        
        return <Tooltip placement="right" overlay={displayName}>
            <StateUserAvatar
                className="avatar"
                displayName={memberResouces[userId]?.displayName}
                avatarUrl={memberResouces[userId]?.avatar}
                color={memberResouces[userId]?.color}
                userId={userId}
            />
        </Tooltip>;
    };

    const getWarnings = (atRisk, atDanger, taskForcedUserNoShe) => {
        var content = {icon: null, desc: null};

        if(atDanger){
            return {
                icon: 'DangerIcon',
                desc: intl.formatMessage({id:'deadline.danger'})
            };
        }
        
        if(atRisk){
            return {
                icon: 'RiskIcon',
                desc: intl.formatMessage({id:'deadline.risk'})
            };
        }

        if(taskForcedUserNoShe){
            return {
                icon: 'RiskIcon',
                desc: intl.formatMessage({id:'deadline.taskForcedUserNoShe'})
            };
        }
        
        return content;
    };

    const timelineItemsPush = (userId, startAt, endAt, update) => {
        var base = {
            group:'tasks.'+userId,
            start: startAt.setHours(1,0,0),
            end: endAt.setHours(23,0,0),
            type: 'range'
        };

        timelineItems.push({...base, ...update});
    };

    const addTaskTimelineItem = (userId, task) => {
        let startAt, endAt;

        let path = getTaskPathArrow(task.id, tasks);
        let taskTitle = task.name;

        if(!task.canView){
            path = '<span data-sidebar="preventClose" class="baffle">'+path+'</span>';
            taskTitle = '<span data-sidebar="preventClose" class="baffle">'+taskTitle+'</span>';
        }

        startAt = new Date(task.estimations.startAt*1000);
        endAt = new Date(task.estimations.expectedAt*1000);

        const warnings = getWarnings(task.risks?.taskAtRisk, task.risks?.taskAtDanger, task.risks?.taskForcedUserNoShe);
        
        taskTitle = task.title;
        if(!task.canView){
            path = '<span data-sidebar="preventClose" class="baffle">'+path+'</span>';
            taskTitle = '<span data-sidebar="preventClose" class="baffle">'+taskTitle+'</span>';
        }

        let content = '<div data-sidebar="preventClose" class="itemContent" data-id="' + task.id + '"><em data-sidebar="preventClose">' + path + '</em><span data-sidebar="preventClose">'+taskTitle+'</span></div>';
        let title = '<div>' + path + '</div><b>'+taskTitle+'</b>';

        if(warnings.icon){
            content = '<div data-sidebar="preventClose" class="itemContent" data-id="' + task.id + '"><em data-sidebar="preventClose">' + path + '</em><span data-sidebar="preventClose"><i class="material-icons ' + warnings.icon + '">warning</i>'+taskTitle+'</span></div>';
            title = '<div>' + path + '</div><b>'+taskTitle+'</b><div class="warning ' + warnings.icon + '">' + warnings.desc + '</div>';
        }

        timelineItemsPush(userId, startAt, endAt, {
            content: content,
            title: title,
            className: 'taskRange ' + task.status + ' priority-'+task.priority
        });
    };

    const getUserGroups = (parent, isClosed, taskList) => {
        if(userGroups && userId){

            var groups = userGroups,
                list = [];

            _.each(groups, (group, groupId)=>{
                group.id = groupId;
            });

            groups = _.sortBy(groups, 'title');

            _.each(groups, (group)=>{
                var groupId = group.id;

                if(group.parent === parent){
                    
                    var visible = 'opened';
                    if(closedSortables.indexOf('group.' + groupId) !== -1){
                        visible = 'closed';
                    }

                    if(!isClosed){
                        var indexGroup = timelineGroups.length;
                        timelineGroups.push({id:'group.' + groupId, index: timelineGroups.length});
                    }

                    var childGroups = getUserGroups(groupId, (visible === 'closed'), taskList);


                    if(childGroups){

                        list.push(
                            <div key={'group.' + groupId}>
                                <div className={'groupItem item '+visible} data-item-id={'group.' + groupId} onClick={toogleOpenClose}>
                                    {visible === 'closed' ? (<OpenIcon className="openClose" />) : <CloseIcon className="openClose" />}
                                    {group.title}
                                </div>
                                {childGroups}
                            </div>
                        );
                    }
                    else {
                        timelineGroups.splice(indexGroup, 1);
                        for(var i = indexGroup; i< timelineGroups.length; ++i){
                            timelineGroups[i].index = timelineGroups[i].index-1;
                        }
                    }
                }
            });

            var usersList = {...memberResouces};

            _.each(usersList, (user, userId)=>{
                user.id = userId;
            });
            usersList = _.sortBy(usersList, 'displayName');
            _.each(usersList, (user)=>{
                var currentUserId = user.id;
                
                if(
                    currentUserId === userId ||
                    (userPermissions && userPermissions.workload && userPermissions.workload.indexOf(currentUserId)!==-1)
                ){
                    user = {...memberResouces[currentUserId]};

                    if(user.group === parent){

                        var visible = 'opened';
                        if(closedSortables.indexOf('user.' + currentUserId) !== -1){
                            visible = 'closed';
                        }

                        if(!isClosed){
                            timelineGroups.push({id:'user.' + currentUserId, index: timelineGroups.length});

                            var startAt = new Date(timelineRange.start);
                            startAt.setDate(startAt.getDate() -5);
                            var lastEngineDate = new Date();
                            var engineAvailabilities = user.engineAvailabilities;

                            if(user.lastEngineDate){
                                lastEngineDate = new Date(user.lastEngineDate*1000);
                            }
                            else if(engineAvailabilities){
                                lastEngineDate = new Date(Object.keys(engineAvailabilities)[Object.keys(engineAvailabilities).length-1]*1000);
                            }
                            let userHoursPerDay = {'0':0,'1':0,'2':0,'3':0,'4':0,'5':0,'6':0};
                            (user.schedule || []).forEach(s=>{
                                userHoursPerDay[s.d] += s.nbMin/60;
                            });

                            while(startAt.getTime() <= new Date(timelineRange.end).getTime()+(1000*60*60*24*5)){
                                var weekDay = parseInt(moment.tz(startAt, user.timezone || moment.tz.guess()).format('d'));
                                let todayHours = userHoursPerDay[weekDay];
                                if(moment.tz(startAt, user.timezone || moment.tz.guess()).isSame(moment().tz(user.timezone || moment.tz.guess()), 'day')){
                                    todayHours = 0;
                                    (user.schedule || []).forEach(s=>{
                                        if(parseInt(s.d) === weekDay){
                                            const startArr = s.start.split(':');
                                            const endArr = s.end.split(':');
                                            let start = moment().tz(user.timezone || moment.tz.guess()).hours(parseInt(startArr[0])).minutes(parseInt(startArr[1])).seconds(0).unix();
                                            if(start<moment().tz(user.timezone || moment.tz.guess()).unix()){
                                                start = moment().tz(user.timezone || moment.tz.guess()).unix();
                                            }
                                            let end = moment().tz(user.timezone || moment.tz.guess()).hours(parseInt(endArr[0])).minutes(parseInt(endArr[1])).seconds(0).unix();
                                            if(end > start){
                                                todayHours+= parseFloat((end-start)/60/60);
                                            }
                                        }
                                    });
                                }

                                let isDayOff = (todayHours === 0);
                                //checking user's daysoff
                                if(!isDayOff && user.daysoff){
                                    
                                    for(var d in user.daysoff){
                                        var s = moment.tz(user.daysoff[d].startDate, user.timezone || moment.tz.guess()).toDate(),
                                            e = moment.tz(user.daysoff[d].endDate, user.timezone || moment.tz.guess()).toDate();

                                        if(startAt.getTime() >= s.getTime() && startAt.getTime() <= e.getTime()){
                                            isDayOff = true;
                                            break;
                                        }
                                    }
                                }

                                //checking global daysoff
                                if(!isDayOff && daysoff){

                                    for(var gd in daysoff){
                                        var sd = new Date(daysoff[gd].startDate),
                                            ed = new Date(daysoff[gd].endDate);
                                        
                                        if(startAt.getTime() >= sd.getTime() && startAt.getTime() <= ed.getTime()){
                                            isDayOff = true;
                                            break;
                                        }
                                    }

                                }
                                
                                // add extra hours to todayHours
                                if(user.extraHours){
                                    for(var ehId in user.extraHours){
                                        var seh = moment.tz(user.extraHours[ehId].start, 'X', user.timezone || moment.tz.guess()).toDate(),
                                            eeh = moment.tz(user.extraHours[ehId].end, 'X', user.timezone || moment.tz.guess()).toDate();
                                        
                                        if((startAt.getTime()-(1000*60*60*24)) <= seh.getTime() && startAt.getTime() >= eeh.getTime() && eeh.getTime() >= Date.now()){

                                            let currStart =user.extraHours[ehId].start;
                                            let currEnd =user.extraHours[ehId].end;
                                            if(currStart*1000 < Date.now()){
                                                currStart = parseInt(Date.now()/1000);
                                            }
                                            if(currEnd*1000 < Date.now()){
                                                currEnd = parseInt(Date.now()/1000);
                                            }

                                            if(isDayOff){
                                                todayHours += parseFloat((currEnd-currStart)/60/60);
                                            }
                                            //check total hours add based on overlaps between the extra hour and the schedule of the day: TODO
                                            else {
                                                let toAdd = parseFloat((currEnd-currStart)/60/60);

                                                (user.schedule || []).forEach(s=>{
                                                    if(parseInt(s.d) === weekDay){
                                                        const startArr = s.start.split(':');
                                                        const endArr = s.end.split(':');
                                                        let start = moment.tz(currStart, 'X', user.timezone || moment.tz.guess()).hours(parseInt(startArr[0])).minutes(parseInt(startArr[1])).seconds(0).unix();
                                                        let end = moment.tz(currStart, 'X', user.timezone || moment.tz.guess()).hours(parseInt(endArr[0])).minutes(parseInt(endArr[1])).seconds(0).unix();
                                                        if(start*1000 < Date.now()){
                                                            start = parseInt(Date.now()/1000);
                                                        }
                                                        if(end*1000 < Date.now()){
                                                            end = parseInt(Date.now()/1000);
                                                        }

                                                        //case schedule contained in extraHour
                                                        if(currStart <= start && currEnd >= end){
                                                            toAdd -= parseFloat((end-start)/60/60);
                                                        }
                                                        //case contained in schedule
                                                        else if(currStart >= start && currStart < end && currEnd <= end){
                                                            toAdd -= parseFloat((currEnd-currStart)/60/60);
                                                        }
                                                        //case overlap start
                                                        else if(currStart <= start && currEnd <= end && currEnd >= start){
                                                            toAdd -= parseFloat((currEnd-start)/60/60);
                                                        }
                                                        //case overlap end
                                                        else if(currStart >= start && currStart <= end && currEnd >= end){
                                                            toAdd -= parseFloat((end-currStart)/60/60);
                                                        }
                                                        
                                                    }
                                                });

                                                todayHours += toAdd;
                                            }
                                        }
                                    }
                                }


                                if(todayHours === 0 || isDayOff){
                                    timelineItems.push({
                                        group:'user.' + currentUserId,
                                        start: startAt.setHours(0,0,0),
                                        end: startAt.setHours(23,59,59),
                                        type: 'background',
                                    });
                                }
                                else {
                                    
                                    var today = new Date();
                                    today.setHours(0,0,0,0);

                                    if(startAt.getTime() >= today.getTime() && startAt.getTime() <= lastEngineDate.getTime()){
                                        let percent = 100,
                                            nbHoursWork = parseFloat(todayHours);

                                        for(let curr in engineAvailabilities){
                                            if(moment.tz(engineAvailabilities[curr].start, 'X', user.timezone || moment.tz.guess()).format('YYYY-MM-DD') === moment(startAt).format('YYYY-MM-DD')){
                                                if((engineAvailabilities[curr].end*1000)>Date.now()){
                                                    if((engineAvailabilities[curr].start*1000)<Date.now()){
                                                        nbHoursWork -= ((engineAvailabilities[curr].end - parseInt(Date.now()/1000))/60/60);
                                                    }
                                                    else {
                                                        nbHoursWork -= ((engineAvailabilities[curr].end - engineAvailabilities[curr].start)/60/60);
                                                    }
                                                }
                                            }
                                            if(moment.tz(engineAvailabilities[curr].start, 'X', user.timezone || moment.tz.guess()).isAfter(moment(startAt).add(1,'day'))) {
                                                break;
                                            }
                                        }

                                        percent = Math.round(nbHoursWork/parseFloat(todayHours)*10)*10;
                                        
                                        timelineItems.push({
                                            group:'user.' + currentUserId,
                                            start: startAt.setHours(0,0,0),
                                            end: startAt.setHours(23,59,59),
                                            type: 'range',
                                            content: percent + '%',
                                            title: nbHoursWork.toFixed(2) + '/' + todayHours.toFixed(2) + 'h',
                                            className: 'userDailyHours percent' + percent/10
                                        });
                                    }
                                }
                                startAt.setDate(startAt.getDate() + 1);
                            }
                            
                        }

                        var tasks = null, userOpenClose = <div className="openClose hidden" />;

                        if(taskList && visible !== 'closed' && taskList[currentUserId]){
                            
                            timelineGroups.push({id:'tasks.'+currentUserId, index: timelineGroups.length, className: 'tasksGroup'});

                            _.each(taskList[currentUserId], (t)=>{
                                addTaskTimelineItem(currentUserId, t);
                            });

                            tasks = (
                                <div className="tasks"><div className="taskItem" /></div>
                            );
                        }

                        if(taskList && taskList[currentUserId]){
                            userOpenClose = (visible === 'closed' ? (<OpenIcon className="openClose" />): <CloseIcon className="openClose" />);
                        }

                        list.push(
                            <div key={'user.' + currentUserId}>
                                <div className={'userItem item '+visible} data-item-id={'user.' + currentUserId} onClick={toogleOpenClose}>
                                    {userOpenClose}
                                    {getUserAvatar(currentUserId)}
                                    <span className="userName">{user.displayName}</span>
                                </div>
                                {tasks}
                            </div>
                        );
                    }
                }
            });


            if(!list.length || list.length === 0){
                return null;
            }

            return (
                <div className="group">
                    {list}
                </div>
            );

        }

        return null;
    };

    const toogleOpenClose = (e) => {
        var isOpen = $(e.target).closest('.item').hasClass('opened'),
            itemId = $(e.target).closest('.item').data('item-id');

        var list = localStorage.getItem('workloadClosedSortables-' + accountId).split(',');

        if(isOpen) {
            list.push(itemId);
        }
        else {
            var index = list.indexOf(itemId);
            list.splice(index, 1);
        }
        firebaseEvents.addChallenge('expandWorkload');
        localStorage.setItem('workloadClosedSortables-' + accountId, list);
        setClosedSortables(list);
    };

    const rangeChangeHandler = (e) => {
        var start = moment.tz(e.start, timezone || moment.tz.guess()).set('h',0).set('m',0).set('s',0).set('ms',0),
            end = moment.tz(e.end, timezone || moment.tz.guess()).set('h',0).set('m',0).set('s',0).set('ms',0),
            minStart = moment().tz(timezone || moment.tz.guess()).subtract(1, 'days').set('h',0).set('m',0).set('s',0).set('ms',0);

        var range = localStorage.getItem('workloadTimelineRange-' + accountId);
        range = JSON.parse(range);
        
        if(start.isBefore(minStart)){
            start = minStart;
        }

        if(!range || start.toISOString() !== range.start || end.toISOString() !== range.end){
            localStorage.setItem('workloadTimelineRange-' + accountId, JSON.stringify({start: start, end: end}));
            setTimelineRange({start: start, end: end});
        }
    };

    const resetTimelineRange = () => {
        localStorage.setItem('workloadTimelineRange-' + accountId, JSON.stringify({start: moment().tz(timezone || moment.tz.guess()).subtract(1, 'days'), end: moment().tz(timezone || moment.tz.guess()).add(14, 'days')}));
        setTimelineRange({start: moment().tz(timezone || moment.tz.guess()).subtract(1, 'days'), end: moment().tz(timezone || moment.tz.guess()).add(14, 'days')});
    };

    const zoomInTimelineRange = () => {
        var range = localStorage.getItem('workloadTimelineRange-' + accountId);
        range = JSON.parse(range);

        if(moment.tz(range.start, timezone || moment.tz.guess()).isBefore(moment().tz(timezone || moment.tz.guess()).subtract(1, 'days'))){
            range.start = moment().tz(timezone || moment.tz.guess()).subtract(1, 'days').format('YYYY-MM-DD');
        }

        var diff = moment.tz(range.end, timezone || moment.tz.guess()).diff(moment.tz(range.start, timezone || moment.tz.guess()), 'days');

        diff = Math.floor(diff/2);

        if(diff < 7){
            diff = 7;
        }
        localStorage.setItem('workloadTimelineRange-' + accountId, JSON.stringify({start: moment.tz(range.start, timezone || moment.tz.guess()), end: moment.tz(range.start, timezone || moment.tz.guess()).add(diff, 'days')}));
        setTimelineRange({start: moment.tz(range.start, timezone || moment.tz.guess()), end: moment.tz(range.start, timezone || moment.tz.guess()).add(diff, 'days')});
    };

    const zoomOutTimelineRange = () => {
        var range = localStorage.getItem('workloadTimelineRange-' + accountId);
        range = JSON.parse(range);

        if(moment.tz(range.start, timezone || moment.tz.guess()).isBefore(moment().tz(timezone || moment.tz.guess()).subtract(1, 'days'))){
            range.start = moment().tz(timezone || moment.tz.guess()).subtract(1, 'days').format('YYYY-MM-DD');
        }

        var diff = moment.tz(range.end, timezone || moment.tz.guess()).diff(moment.tz(range.start, timezone || moment.tz.guess()), 'days');

        diff = Math.floor(diff*2);

        if(diff > 365){
            diff = 365;
        }
        localStorage.setItem('workloadTimelineRange-' + accountId, JSON.stringify({start: moment.tz(range.start, timezone || moment.tz.guess()), end: moment.tz(range.start, timezone || moment.tz.guess()).add(diff, 'days')}));
        setTimelineRange({start: moment.tz(range.start, timezone || moment.tz.guess()), end: moment.tz(range.start, timezone || moment.tz.guess()).add(diff, 'days')});
        
    };

    const getTaskList = () => {
      
        if(
            accountId &&
            tasks 
        ){
            var list = [];
            var startRangeAt = new Date(timelineRange.start);
            var endRangeAt = new Date(timelineRange.end);
            var start = null, end = null, i=0;

            var taskList = orderedTaskList;

            _.each(taskList, (t)=>{
                if(t && !t.childrens && t.status !== 'done' && tasks && tasks[t.id]?.estimations
                    && tasks[t.id]?.estimations?.startAt 
                        && tasks[t.id]?.estimations?.expectedAt
                ){
                    if(tasks &&
                        tasks[t.id] && 
                        tasks[t.id].estimations && 
                        tasks[t.id].estimations.startAt && 
                        tasks[t.id].estimations.expectedAt){

                        start = new Date(tasks[t.id].estimations.startAt*1000);
                        end = new Date(tasks[t.id].estimations.expectedAt*1000);

                        if(start <= endRangeAt && end >= startRangeAt){
                            list.push({...t, estimations: tasks[t.id].estimations, globalIndex: i});
                            i++;
                        }

                    }
                }
            });
            
            // ordering the list 

            list = _.sortBy(list, (t)=>{
                var multiplier = 0,
                    baseScore = (t.estimations.startAt*1000) + t.globalIndex;
                    
                if(t.recurrency){
                    baseScore = t.globalIndex;
                }
                else if(t.start){
                    baseScore = (t.start*1000) + t.globalIndex;
                }
                
                switch(t.priority){
                case 1:
                    multiplier += 30000;
                    break;
                case 2:
                    multiplier += 20000;
                    break;
                case 3:
                    multiplier += 10000;
                    break;
                default:
                    multiplier += 40000;
                    break;
                }

                if(t.status === 'inprogress'){
                    multiplier = multiplier / 100;
                }
                
                return baseScore + multiplier;
            });


            var userList = {};
            var user = null;

            _.each(list, (t)=>{

                if(t.status === 'todo'){
                    user = t.estimations.userId;
                }
                else if(t.status === 'inprogress'){
                    user = t.userWorking;
                }
                else {
                    user = t.participants;
                }
                if(typeof user === 'string'){
                    user = [user];
                }

                for(var i in user){
                    if(!userList[user[i]]){
                        userList[user[i]] = [];
                    }
                    userList[user[i]].push(t);
                }

            });

            return userList;
        }
        return null;
    };

    const clickHandler = (e) => {
        document.activeElement.blur(); 
        $('body').trigger('mouseup');

        if(e.what && e.what === 'item'){
            var isEvent = $(e.event.target).closest('.taskRange').hasClass('event') ? 'event/':'';
            var elId = $(e.event.target).closest('.itemContent').data('id');
            if(elId){
                setTimeout(()=>{ $('.vis-tooltip').css('visibility', 'hidden'); }, 100);
                browserHistory.push('/workload/' + isEvent + elId);
            }
        }
    };

    if(accountId){
        
        var taskList = getTaskList();
        var list = getUserGroups(undefined, false, taskList);
        
        if(timelineGroups.length){

            var expanAllTitle = intl.formatMessage({id:'Expand groups'});
            var workloadClosedSortables = localStorage.getItem('workloadClosedSortables-' + accountId);

            if(workloadClosedSortables === closedSortables.toString()){
                expanAllTitle = intl.formatMessage({id:'Expand groups'});
            }

            return (
                <div className={classnames('Workload', {empty: !orderedTaskList || orderedTaskList.length === 0})} data-sidebar='preventClose'>

                    {!orderedTaskList || orderedTaskList.length === 0 ? (

                        <NoTaskFound workloadEmptyState={true} />

                    ) : (
                        <>
                            <div className="Workload__content">
                                <div className="Workload__list">
                                    <div className="listHeader">
                                        <a onClick={expandAll}>{expanAllTitle}</a>
                                        <a onClick={collapseAll}>{intl.formatMessage({id:'Collapse All'})}</a>
                                    </div>
                                    {list}
                                </div>
                                <div className="timeline">
                                    <Timeline
                                        items={timelineItems}
                                        start={timelineRange.start}
                                        end={timelineRange.end}
                                        groups={timelineGroups}
                                        clickHandler={clickHandler}
                                        changedHandler={changeHandler}
                                        rangechangedHandler={rangeChangeHandler}
                                        itemoverHandler={itemoverHandler}
                                        stack={true}
                                        zoomMax={1000*60*60*24*365}
                                        minOption={moment().subtract(1, 'days')}
                                        timeAxis={{
                                            scale: 'day',
                                            step:1
                                        }}
                                        timeFormat={(timeFormat)?timeFormat:'HH:mm'}
                                    />
                                </div>

                            </div>

                            <div id="timelineHelper">
                                <div onClick={resetTimelineRange}><Refresh /></div>
                                <div onClick={zoomInTimelineRange}><ZoomIn /></div>
                                <div onClick={zoomOutTimelineRange}><ZoomOut /></div>
                            </div>

                            {children}
                        </>
                    )}

                    <NotInMobile />
                    
                </div>
            );
        }
    }

    return null;

};

export default withCustomErrorBoundary(Workload);
