import React, { useState } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import TaskPrioritySelect from '../TaskPrioritySelect/TaskPrioritySelect';

const MultiPriorityDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [multiPriority, setMultiPriority]= useState(0);

    const handleConfirm = () => {
        firebaseEvents.multiPriorityChange(multiPriority, selectedItems).then(() => {
            setSelectedLines();
            setMultiPriority(0);
        });
        showMultiDetails();
    };

    const handleChange = (newLvl) => {
        setMultiPriority(newLvl);
    };

    const handleNo = () => {
        setMultiPriority(0);
    };

    return   <div id="multiPriorityDetails">
        <label>Set tasks priority to</label>
        <TaskPrioritySelect
            level={multiPriority}
            showLabel={false}
            onChange={handleChange}
            canManage={true}
            multiTask={true}
        />
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
            Cancel
        </Button>
    </div>;
};

export default MultiPriorityDetails;