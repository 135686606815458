import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import { getUserInfo, sortOtherToLast } from '../utils';

import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const UpcomingEvents = ({
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);
    const [data, setData] = useState({
        teamMembersData: [],
        skillsData: [],
        tasksData: [],
        totalTasksDone: 0,
        totalTasksToBeDone: 0
    });

    useEffect(() => {
        // in the case of beeing root lvl
        // get all of the sub childs
        const workspaceMainItens = workspaceSelected === 'root' ?
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
            : _.get(stateTasks[workspaceSelected], 'childrens' , []);

        const workspaceMainItensChilds  = {};
        workspaceMainItens.forEach(({id}) => {
            workspaceMainItensChilds[id] =  _.get(childsParents, id , [id]);
        });
        const workspaceMainItensChildsToEntries = Object.entries(workspaceMainItensChilds);
        let totalTasksDone = 0;
        let totalTasksToBeDone = 0;

        const workspaceTasks = workspaceSelected === 'root' ?
            Object.keys(stateTasks) :
            _.get(childsParents, workspaceSelected , [workspaceSelected]);

        if( workspaceTasks.length) {
            const teamMembersData =  {};
            const skillsData =  {};
            const tasksData =  {};
            workspaceTasks.forEach(taskId => {
                const currentTask = stateTasks[taskId];

                if(!currentTask || currentTask.status === 'done' || (stateTasks.parent &&  !stateTasks[stateTasks.parent]) || currentTask.childrens){
                    return;
                }


                // handle team members
                let taskUsers = [];
                // split
                if(currentTask.estimations?.userId){
                    taskUsers = (Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId])
                        .map(el => ({isForced: false, userId: el , avatar: getUserInfo(accountTeamMembers[el]).avatar,  name: getUserInfo(accountTeamMembers[el]).displayName}));
                } else if(currentTask.forcedUser) {
                    taskUsers = (Array.isArray(currentTask.forcedUser) ?  currentTask.forcedUser : [currentTask.forcedUser]).map(el => ({isForced: true, avatar: getUserInfo(accountTeamMembers[el]).avatar, userId: el, name: getUserInfo(accountTeamMembers[el])?.displayName }));
                }
                totalTasksToBeDone++;

                // handle skills
                const skills = currentTask?.skill?.length ? currentTask.skill : [];
                const skillDatasets = skills.map(skillId => skillsData[skillId]).filter(Boolean);

                if (skillDatasets.length) {
                    skillDatasets.forEach(skillDataset => {
                        skillDataset.value += 1;
                    });
                } else {
                    if (skills.length) {
                        skills.forEach(skillId => {
                            skillsData[skillId] = {
                                id: skillId,
                                name: accountSkills[skillId]?.name,
                                value: 1
                            };
                        });
                    } else {
                        skillsData['none'] = {
                            id: 'none',
                            name: 'No Skill defined',
                            value: 1
                        };
                    }
                }

                // handle team members
                if(taskUsers.length) {
                    taskUsers.forEach(({userId}) => {
                        const userDetail = accountTeamMembers[userId];
                        const userData = teamMembersData[userId];

                        if(userData){
                            userData.value += 1;
                        } else {
                            teamMembersData[userId] = {
                                id: userId,
                                value: 1,
                                name: userDetail?.displayName || '(deleted user)'
                            };
                        }
                    });
                } else {
                    const userData = teamMembersData.none;

                    if(userData){
                        userData.value += 1;
                    } else {
                        teamMembersData.none = {
                            id: 'none',
                            value: 1,
                            name: 'Not Assigned'
                        };
                    }
                }
                // get parent
                const mainItemEntrie = workspaceMainItensChildsToEntries.find(([, childs]) => childs.includes(taskId));
                if(mainItemEntrie) {
                    const taskDataset = tasksData[mainItemEntrie[0]];

                    if(taskDataset){
                        taskDataset.value += 1;
                    } else {
                        tasksData[mainItemEntrie[0]] = {
                            id: mainItemEntrie[0],
                            value: 1,
                            name: stateTasks[mainItemEntrie[0]].title
                        };
                    }
                } else {
                    const datasetEntry = tasksData[taskId];
                    if(datasetEntry){
                        datasetEntry.value += 1;
                    } else {
                        tasksData[taskId] = {
                            id: taskId,
                            value:1,
                            name: stateTasks[taskId].title
                        };
                    }
                }

            });

            const convertData = (dataEntry) => {
                const data = [];
                Object
                    .values(dataEntry)
                    .sort((a, b) => b.value - a.value)
                    .forEach((el, idx) => {
                        if(idx === 3){
                            data.push({
                                name: 'Others',
                                value: el.value
                            });
                            return;
                        }

                        if(idx > 3) {
                            data[3].value += el.value;
                            return ;
                        }
                        data.push({
                            name: el.name,
                            value: el.value
                        });
                        return ;
                    });
                return data;
            };

            setData({
                skillsData: convertData(skillsData),
                tasksData: convertData(tasksData),
                teamMembersData: convertData(teamMembersData),
                totalTasksDone,
                totalTasksToBeDone
            });
        }

    }, [workspaceSelected,  stateTasks, accountTeamMembers, childsParents, accountSkills]);
    const path = window.location.pathname.split('/').slice(0,3).join('/');
    let hasData = (toCheck) =>  toCheck.find(el => el.value);

    return <div className="overview-repartition">
        <div>
            <div>
                To be done per Sub Items
            </div>
            <div>
                {
                    hasData(data.tasksData) ? data
                        .tasksData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.totalTasksToBeDone) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div>
            <div>
                To be done per Team Members
            </div>
            <div>
                {
                    hasData(data.teamMembersData) ? data
                        .teamMembersData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.totalTasksToBeDone) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div>
            <div>
                To be done per Skills
            </div>
            <div>
                {
                    hasData(data.skillsData) ? data
                        .skillsData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.totalTasksToBeDone) * 100).toFixed(2)}%`}</b></li>)
                        : <div className='empty small'> No Data</div>
                }
            </div>
        </div>
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="task-breakdown"
                to={`${path}/task-breakdown`}
            >
                Go to <b>Task repartition</b> report
            </Button>
        </div>
    </div>;
};

UpcomingEvents.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default UpcomingEvents;