import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import _ from 'underscore';
import $ from 'jquery';
import classnames from 'clsx';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import SelectSkill from '../SelectSkill/SelectSkill';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUserDateformat, getUserId } from '../../utils/selectors/user';
import { getAccountSkills,  getAccountTags, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const FiltersDetail = ({
    value,
    val,
    type,
    label,
    groupIndex,
    filterIndex,
    filterConf,
    effective,
    onRequestDelete,
    onChangeValues,
    onChangeType,
})=>{
    const intl = useIntl();

    const userId = useSelector(getUserId);
    const dateFormat = useSelector(getUserDateformat);
    const users = useSelector(getAccountUsers);
    const skills = useSelector(getAccountSkills);
    const tags = useSelector(getAccountTags);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showSkillSelect, setShowSkillSelect] = useState(false);
    const [stateValue, setStateValue] = useState(value);

    useEffect(()=>{
        setStateValue(value);
    }, [value]);


    const doShowUserSelect = () => {
        setShowUserSelect(true);
    };

    const hideUserSelect = (e) => {
        if ($(e.target).closest('.whoSelectFilterDetail').length === 0) {
            setShowUserSelect(false);
        }
    };

    const doShowSkillSelect = () => {
        setShowSkillSelect(true);
    };

    const hideSkillSelect = (e) => {
        if ($(e.target).closest('.skillSelectFilterDetail').length === 0) {
            setShowSkillSelect(false);
        }
    };

    const changeAssignedUser = (values) => {
        const data = Array.isArray(values) ? values : [values];
        setShowUserSelect(false);
        onChangeValues(data);
    };

    const changeAssignedSkill = (values) => {
        const data = Array.isArray(values) ? values : [values];

        setShowSkillSelect(false);

        onChangeValues(data);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        event.preventDefault();

        if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
            setOpen(false);

        }
        else {
            setOpen(!open);
            setAnchorEl(event.currentTarget);
        }
    };

    const doRequestDelete = () => {
        onRequestDelete(groupIndex, filterIndex);
    };

    const updateType = (event, value) => {
        onChangeType(value);
    };

    const onChoiceSelect = (event) => {
        onChangeValues(event.target.value);
    };

    const handleChangeTags = (event, values) => {
        onChangeValues(values.map((item) => item.id));
    };

    const onStringType = (event) => {
        setStateValue(event.target.value);
    };

    const onStringChange = (event) => {
        onChangeValues(event.target.value);
    };

    const onDateChange = (date) => {
        onChangeValues(date.format('YYYY-MM-DD'));
    };


    const getForm = () => {

        var filterType = filterConf.type;

        if (filterType === 'choice') {
            return getChoiceForm();
        }
        else if (filterType === 'string') {
            return getStringForm();
        }
        else if (filterType === 'number') {
            return getNumberForm();
        }
        else if (filterType === 'date') {
            return getDateForm();
        }
        else if (filterType === 'has') {
            return getHasForm();
        }

        return null;

    };

    const getChoiceSelect = (type) => {
        if (type === 'is' || type === 'isnot') {

            var items = [];

            if (_.isArray(filterConf.choices)) {
                _.each(filterConf.choices, (choice) => {
                    items.push(
                        <MenuItem
                            className="filterDetailSelectItem"
                            key={choice}
                            value={choice}
                        >
                            {intl.formatMessage({id:choice})}
                        </MenuItem>
                    );
                });

                return (
                    <TextField
                        select
                        className="filterDetailSelect"
                        SelectProps={{ multiple: true }}
                        value={value}
                        onChange={onChoiceSelect}
                    >
                        {items}
                    </TextField>
                );
            }



            if (filterConf.choices === 'users') {
                const displayNames = _.map(value, (val) => {
                    if (val === userId) {
                        return intl.formatMessage({id:'Me'});
                    }
                    else {
                        return (users[val]) ? users[val].displayName : '';
                    }
                });


                return (
                    <div className="whoSelectFilterDetail">
                        <div onClick={doShowUserSelect}>
                            <span>{displayNames.join(',')}</span>
                        </div>
                        {showUserSelect && <SelectTeamMember
                            maxSelect={Object.keys(users).length}
                            currentUser={value}
                            onSelect={changeAssignedUser}
                            onClose={hideUserSelect}
                            open={showUserSelect}
                        />}
                    </div>
                );

            }

            if (filterConf.choices === 'skills') {

                var skillNames = _.map(value, (val) => {
                    return skills[val].name;
                });

                return (
                    <div className="skillSelectFilterDetail">
                        <div onClick={doShowSkillSelect}>
                            <span>{skillNames.join(',')}</span>
                        </div>
                        {showSkillSelect && <SelectSkill
                            isMultiSelect
                            currentSkill={value}
                            onSelect={changeAssignedSkill}
                            onClose={hideSkillSelect}
                            open={showSkillSelect}
                        />}
                    </div>
                );
            }

            if (filterConf.choices === 'tags') {
                var tagNames = _.map(value, (val) => {
                    if (tags && tags[val]) {
                        return { id: val, title: tags[val].title, color: tags[val].color };
                    }
                });

                return (
                    <Autocomplete
                        size="small"
                        multiple
                        id="tagsSelect"
                        options={getTags(tagNames)}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option) => {
                            return (
                                <span className="tagSelectItem">{option.title}</span>
                            );
                        }}
                        value={tagNames}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const title = option.title;
                                let props = getTagProps({ index });
                                // support new colors
                                if(isNaN(option.color)) {
                                    props.style={
                                        backgroundColor: option.color
                                    };
                                } else {
                                    props.className += ' color' + option.color;
                                }

                                return (
                                    <Chip label={title} {...props} />
                                );
                            })
                        }
                        renderInput={params => (
                            <TextField
                                autoFocus
                                {...params}
                                fullWidth
                                className="tagsInput"
                            />
                        )}
                        disableClearable
                        onChange={handleChangeTags}
                    />
                );
            }
        }

        return null;
    };

    const getTags = (defaultValues) => {
        var results = [];
        if (tags) {
            for (var i in tags) {
                var has = false;
                for (var j in defaultValues) {
                    if (!has && i === defaultValues[j].id) {
                        has = true;
                    }
                }
                if (!has) {
                    results.push({ id: i, title: tags[i].title, color: tags[i].color });
                }
            }
        }
        results = _.sortBy(results, 'title');
        return results;
    };

    const getNumberInputs = (type) => {

        if (type !== 'hasValue' && type !== 'hasNoValue') {

            return [
                <TextField
                    autoFocus
                    className="numberTextfield"
                    inputProps={{ type: 'number', min: filterConf.min, max: filterConf.max }}
                    value={stateValue}
                    onChange={onStringType}
                    onBlur={onStringChange}
                />,
                filterConf.suffix ? <span>{intl.formatMessage({id:filterConf.suffix})}</span> : <span/>
            ];
        }

        return null;
    };

    const getDateInputs = (type) => {

        if (type !== 'hasValue' && type !== 'hasNoValue') {

            return (
                <DatePicker
                    className="dateTextfield"
                    format={dateFormat || 'DD/MM/YYYY'}
                    margin="normal"
                    value={value}
                    onChange={onDateChange}
                    autoOk={true}
                    cancelLabel={null}
                    okLabel={null}
                />
            );
        }

        return null;
    };

    const getChoiceForm = () => {

        var hasAValue = [];

        if (filterConf.hasornot) {
            hasAValue = [
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasValue"
                    value="hasValue"
                    label={intl.formatMessage({id:'hasValue'})}
                />,
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasNoValue"
                    value="hasNoValue"
                    label={intl.formatMessage({id:'hasNoValue'})}
                />
            ];
        }

        return (
            <div>
                <RadioGroup name={label} value={type} onChange={updateType}>
                    <FormControlLabel control={<Radio color="primary" />}
                        value="is"
                        label={intl.formatMessage({id:'is'})}
                    />
                    <FormControlLabel control={<Radio color="primary" />}
                        value="isnot"
                        label={intl.formatMessage({id:'isnot'})}
                    />
                    {hasAValue}
                </RadioGroup>
                {getChoiceSelect(type)}
            </div>
        );
    };

    const getStringForm = () => {
        return (
            <div>
                <RadioGroup name={label} value={type} onChange={updateType}>
                    <FormControlLabel control={<Radio color="primary" />} value="is" label={intl.formatMessage({id:'is'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="isnot" label={intl.formatMessage({id:'isnot'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="contains" label={intl.formatMessage({id:'contains'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="doesnotcontain" label={intl.formatMessage({id:'doesnotcontain'})} />
                </RadioGroup>
                <TextField
                    autoFocus
                    value={stateValue}
                    onChange={onStringType}
                    onBlur={onStringChange}
                    fullWidth={true}
                />
            </div>
        );
    };

    const getNumberForm = () => {

        var hasAValue = [];

        if (filterConf.hasornot) {
            hasAValue = [
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasValue"
                    value="hasValue"
                    label={intl.formatMessage({id:'hasValue'})}
                />,
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasNoValue"
                    value="hasNoValue"
                    label={intl.formatMessage({id:'hasNoValue'})}
                />
            ];
        }

        return (
            <div>
                <RadioGroup name={label} value={type} onChange={updateType}>
                    <FormControlLabel control={<Radio color="primary" />} value="lowerthan" label={intl.formatMessage({id:'lowerthan'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="higherthan" label={intl.formatMessage({id:'higherthan'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="is" label={intl.formatMessage({id:'is'})} />
                    {hasAValue}
                </RadioGroup>
                {getNumberInputs(type)}
            </div>
        );

    };

    const getDateForm = () => {

        var hasAValue = [];

        if (filterConf.hasornot) {
            hasAValue = [
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasValue"
                    value="hasValue"
                    label={intl.formatMessage({id:'hasValue'})}
                />,
                <FormControlLabel control={<Radio color="primary" />}
                    key="hasNoValue"
                    value="hasNoValue"
                    label={intl.formatMessage({id:'hasNoValue'})}
                />
            ];
        }

        return (
            <div>
                <RadioGroup name={label} value={type} onChange={updateType}>
                    <FormControlLabel control={<Radio color="primary" />} value="before" label={intl.formatMessage({id:'before'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="after" label={intl.formatMessage({id:'after'})} />
                    <FormControlLabel control={<Radio color="primary" />} value="on" label={intl.formatMessage({id:'on'})} />
                    {hasAValue}
                </RadioGroup>
                {getDateInputs(type)}
            </div>
        );
    };

    const getHasForm = () => {
        return (
            <div>
                <RadioGroup name={label} value={type} onChange={updateType}>
                    <FormControlLabel control={<Radio color="primary" />}
                        key="hasValue"
                        value="hasValue"
                        label={intl.formatMessage({id:'hasValue'})}
                    />
                    <FormControlLabel control={<Radio color="primary" />}
                        key="hasNoValue"
                        value="hasNoValue"
                        label={intl.formatMessage({id:'hasNoValue'})}
                    />
                </RadioGroup>
            </div>
        );
    };

    var labelVal = [<b key="1">{label} </b>, <em key="2"> {intl.formatMessage({id:type})} '{val}'</em>];
    if (type === 'hasValue' || type === 'hasNoValue') {
        labelVal = [<b key="1">{label} </b>, <em key="2"> {intl.formatMessage({id:type})}</em>];
    }

    if (filterConf.type === 'number' && filterConf.suffix && type !== 'hasValue' && type !== 'hasNoValue') {
        labelVal.push(<em> {filterConf.suffix}</em>);
    }

    return (
        <div className={classnames('FilterDetail', { striked: !effective })}>
            <Chip
                data-label={label}
                className={classnames('chip filterDetail', { open: open })}
                onClick={handleClick}
                onDelete={doRequestDelete}
                label={labelVal}
                icon={<Icon className="material-icons">{filterConf.icon}</Icon>}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                onClose={handleRequestClose}
                className="FiltersDetailPopover"
            >
                <div className="filtersDetailsForm">
                    {getForm()}
                    <Button fullWidth={true} color="primary" className="closeBtn" onClick={handleRequestClose}>{intl.formatMessage({id:'Close'})}</Button>
                </div>
            </Popover>
        </div>
    );


};

export default withCustomErrorBoundary(FiltersDetail);
