import Avatar from '@material-ui/core/Avatar';
import UserIcon from '@material-ui/icons/AccountCircle';
import React, {useState, useEffect } from 'react';
import moment from 'moment-timezone';
import CustomSideBar from '../CustomSideBar/CustomSideBar';
import { useSelector } from 'react-redux';
import linkifyHtml from 'linkifyjs/html';
import Button from '@material-ui/core/Button';
import { Clear, Check, QueryBuilder, ChevronLeft } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';
import Selectors from '../../utils/selectors';
import { getEventData } from '../../utils/calendarIntegration';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { toastr } from 'react-redux-toastr';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';


const mapFreqForInterval = {
    'DAILY': 'days',
    'WEEKLY': 'weeks',
    'MONTHLY': 'month',
};

const mapFreqWithout = {
    'DAILY': 'Daily',
    'WEEKLY': 'Weekly',
    'MONTHLY': 'Monthly',
    'YEARLY': 'Annually',
};

const weekDaysName = {
    'Fri': 'Friday',
    'Mon': 'Monday',
    'Thu': 'Thusday',
    'Sat': 'Saturday',
    'Sun': 'Sunday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesay',
};      
const allWeekDays = [
    'Fri',
    'Mon',
    'Thu',
    'Sat',
    'Sun',
    'Tue',
    'Wed',
];
const workDays = [
    'Fri',
    'Mon',
    'Thu',
    'Tue',
    'Wed',
];

const ReccureceDisplay = (data) => {
    let string = '';
    if(data.interval){
        string += `Every ${data.interval} `;
        
        if(data.frequency){
            string += mapFreqForInterval[data.frequency];
        }
        
        if(data.onThe){
            string += `, on the ${data.onThe}ª`;
        }

        const onlyWeekdays = Object.keys(data).filter(key => workDays.includes(key) && data[key]);
        if(onlyWeekdays.length === 5) {
            string += ', on weekdays';
        } else {
            const on = Object.keys(data).reduce((acc, key) => {
                if(allWeekDays.includes(key) && data[key]) {
                    acc += acc.length > 1  ? `, ${weekDaysName[key]}` :  weekDaysName[key];
                }
                return acc;
            } ,'');
            if(on.length){
                string += `, ${on}`;
            }
        }

        if(data.count){
            string += `, for ${data.count} times`;
        }

        return string; 
    }
   
    string =  `${mapFreqWithout[data.frequency]}`;

    if(data.frequency !== 'DAILY'){
  
        const onlyWeekdays = Object.keys(data).filter(key => workDays.includes(key) && data[key]);
        if(onlyWeekdays.length === 5) {
            string += ', on weekdays';
        } else {
            const on = Object.keys(data).reduce((acc, key) => {
                if(allWeekDays.includes(key) && data[key]) {
                    acc += acc.length > 1  ? `, ${weekDaysName[key]}` :  weekDaysName[key];
                }
                return acc;
            } ,'');
            if(on.length){
                string += `, on ${on}`;
            }
        }
    }
   
    if(data.count){
        string += `, for ${data.count}`;
    }
    return string;
};

const responseStatusIcons = {
    accepted:  <Check />,
    declined: <Clear />,
    needsAction: <QueryBuilder />
};

const UserEvents  = ({ children, routeParams: { uid }, routes, params }) => {
    const intl = useIntl();
    const userId = useSelector(Selectors.userSelector.getUserId);
    const accountId = useSelector(Selectors.userSelector.getUserAccountId);
    const accountTeamMembers = useSelector(Selectors.accountSelector.getAccountUsers);
    const [userEvent, setUserEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = ()=> {
        setTimeout(()=>{
            if(routes[2]?.path?.includes('insights')){
                browserHistory.push(`/insights/${params.taskId}/${params.mode}`);
                return;
            }
            if(routes[3].path === 'userEvents/:uid'){
                browserHistory.push('/' + routes[2].path);
            }
            else {
                browserHistory.push('/' + routes[3].path);
            }
        }, 500);
    };
  
    useEffect(() => {
        if(uid && userId && accountId) {
            
            (async () => {
                try {
                    setIsLoading(true);
                    setUserEvent(null);

                    const eventData = await getEventData({
                        eventId: uid,
                        userId,
                        accountId
                    }); 
                    
                    setUserEvent(eventData);
                } catch(err) {
                    toastr.error('Something went wrong when trying to open the event.');
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [uid, userId, accountId]);
    
    const videoConference = userEvent && userEvent.conferenceEntryPoints ? userEvent.conferenceEntryPoints.filter(el => el.entryPointType === 'video') : [];
  
    if (isLoading) {
        return <CustomSideBar onClose={handleClose} className={'EventDetail'}>
            <div className="UserEventDetail user-event-loading">
                <div className="user-event-loading">
                    <CircularProgress></CircularProgress>
                </div>
            </div>
        </CustomSideBar>;
    }

    return <>
        <CustomSideBar onClose={handleClose} className={'EventDetail'}>
            {userEvent && (
                <div className="UserEventDetail">
                    <div className="eventDetailHeader">
                        <div>
                            <div className="event-title" >
                                {userEvent.name}
                            </div>
                            <div className="event-sub-title">
                                {moment.unix(userEvent.start).format('dddd, MMMM Do YYYY')} - {moment.unix(userEvent.end).format('HH:mm')}
                            </div>
                            {userEvent.recurrency && <div className="event-sub-title">
                                {ReccureceDisplay(userEvent.recurrency)}
                            </div>}
                        </div>
                        <div>
                            <Button size="small" variant="outlined" color="primary" onClick={() => window.open(userEvent.link)}>{intl.formatMessage({id: 'Open in Google calendar'})}</Button>
                    
                            { !!videoConference.length && <div className="join-conference">
                                {
                                    videoConference.map((el,idx) => <Button key={`${idx}-video-conf`} onClick={() => window.open(el.uri)} >
                                        {intl.formatMessage({id: 'Join Video Conference'})}
                                    </Button>
                                    )
                                }
                            </div>
                            }
                        </div>
                    </div>
            
                    { userEvent.location && <div className="event-location"> 
                        <div className="details-title">
                            {intl.formatMessage({id: 'LOCATION'})}
                        </div>
                        <div>
                            <a href={userEvent.location}>{userEvent.location}</a>
                        </div>
                    </div>}

                    { !!userEvent.participants?.length && <div className="event-location">
                        <div className="details-title">
                            {intl.formatMessage({id: 'PARTICIPANTS'})}
                        </div> 
                        <div>
                            {userEvent.participants.map(p => <div key={p.email} className="event-participants">
                                {getUserAvatar(p.email, accountTeamMembers, p.self, userId)}
                                <div className="participant-email">
                                    {p.email}
                                </div>
                                <div className={`participant-status-icon ${p.responseStatus}`}>
                                    {responseStatusIcons[p.responseStatus] || responseStatusIcons.missingAction }
                                </div>
                            </div>)}
                        </div>
                    </div>
                    }
                    { userEvent.description &&  <div className="event-location">
                        <div className="details-title">
                            {intl.formatMessage({id: 'DETAILS'})} 
                        </div>
                        <div className="event-description" dangerouslySetInnerHTML={{__html: linkifyHtml(userEvent.description.replace(/(\r\n|\n|\r)/gm, '<br/>')) }} />
                    </div>
                    }
                    {
                        !userEvent.description && 
                        !userEvent.participants?.length && 
                        !userEvent.location && 
                        !videoConference.length && 
                        <div className="event-empty">
                            {intl.formatMessage({id: 'No details available'})} 
                        </div>
                    }
                    {children}
                </div>
            )}
        </CustomSideBar>
        <Button className="backBtnSidebar" onClick={handleClose}><ChevronLeft /> {intl.formatMessage({id: 'BACK'})}</Button>
    </>;
};

export default withCustomErrorBoundary(UserEvents);


function getUserAvatar(email, teamMembersData, isSelf, userId) {
    if(!teamMembersData) {
        return null;
    }
    if(isSelf && userId && teamMembersData[userId]) {
        return <StateUserAvatar
            className="avatar"
            userId={userId}
        />;
    }

    const exists = Object.values(teamMembersData).find(el => el.email === email);
    if (exists && exists.avatar) {
        return <StateUserAvatar
            className="avatar"
            userId={exists.userId}
        />;
    }

    return <Avatar key={email} className="avatar emptyAvatar" ><UserIcon /></Avatar>;
}
