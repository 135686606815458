import ReactDOMServer from 'react-dom/server';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import {toastr} from 'react-redux-toastr';
import { browserHistory, withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import {getUserAvatar} from '../../utils/utils';
import GroupIcon from '@material-ui/icons/Group';
import ChatInput from '../ChatInput/ChatInput';
import Badge from '@material-ui/core/Badge';
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import dompurify from 'dompurify';
import {Twemoji} from 'react-emoji-render';
import linkifyHtml from 'linkifyjs/html';
import CommentFileIcon from '@material-ui/icons/InsertDriveFile';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddParticipantIcon from '@material-ui/icons/GroupAdd';
import RemoveParticipantIcon from '@material-ui/icons/VoiceOverOff';
import RenameIcon from '@material-ui/icons/Edit';
import ChangeDescIcon from '@material-ui/icons/Description';
import LeaveIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';

import {isAdmin} from '../../utils/userFunctions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { getUserId, getUserPermissions, getUserTimeformat } from '../../utils/selectors/user';
import { getAccountChat, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const sanitizer = dompurify.sanitize;

var scrolledFirst = false;

const ChatChannel = ({router, location})=>{
    const intl = useIntl();

    const timeFormat = useSelector(getUserTimeformat);
    const userPermissions = useSelector(getUserPermissions);
    const userId = useSelector(getUserId);
    const users = useSelector(getAccountUsers);
    const chat = useSelector(getAccountChat);

    const [showLastMessagesBtn, setShowLastMessagesBtn] = useState(false);
    const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null,);
    const [showAddParticipant, setShowAddParticipant] = useState(false);
    const [showRemoveParticipant, setShowRemoveParticipant] = useState(false);
    const [showRenameChannel, setShowRenameChannel] = useState(false);
    const [showChangeDesc, setShowChangeDesc] = useState(false);
    const [addPartList, setAddPartList] = useState([]);
    const [removePartList, setRemovePartList] = useState([]);
    const [renameChannelText, setRenameChannelText] = useState('');
    const [channelDescText, setChannelDescText] = useState('');
    const [renaming, setRenaming] = useState(false);

    const checkDaySepVisibility = () => {
        var countVisible = 0, nextItem;
        $('.daySeparator:not(.fixedDaySeparator)').each((index)=>{
            $('.fixedDaySeparator').addClass('hidden');

            var currElTop = $($('.daySeparator')[index]).offset().top - $($('.daySeparator')[index]).outerHeight();
            var startTop = $('.ChatChannel').offset().top + $('.ChatChannel .MuiCardHeader-root').outerHeight();
            var endTop = startTop + 100;

            if(currElTop > startTop && currElTop < endTop){
                countVisible++;
            }
            else if(currElTop < startTop){

                if(!nextItem || currElTop > nextItem.currElTop){
                    nextItem = {el: $($('.daySeparator')[index]), currElTop};
                }

            }
        });

        if(countVisible === 0 && nextItem){
            $('.fixedDaySeparator').removeClass('hidden').html(nextItem.el.html());
        }
    };

    const setMessagesHeight = () => {
        checkDaySepVisibility();

        var chatHeader = $('.ChatChannel .MuiCardHeader-root');
        var chatInput = $('.ChatInput');
        var height = chatHeader.outerHeight() + chatInput.outerHeight() + 120;
        $('.ChatChannel .messages').css('height', 'calc(100vh - ' + height + 'px)');
    };

    const scrollToEndMessages = () => {
        setTimeout(()=>{
            var chatMessages = $('.ChatChannel .messages');
            var height = chatMessages.prop('scrollHeight') - chatMessages.innerHeight();

            chatMessages.scrollTop(height);

            chatMessages.off('scroll');
            chatMessages.on('scroll', (e)=>{
                var scrollAmount = e.target.scrollTop;
                var maxScroll = $(e.target).prop('scrollHeight') - $(e.target).innerHeight();
                if(maxScroll - scrollAmount > 500 && !showLastMessagesBtn){
                    setShowLastMessagesBtn(true);
                }
                else if(maxScroll - scrollAmount <= 500 && showLastMessagesBtn){
                    setShowLastMessagesBtn(false);
                }

                checkDaySepVisibility();
            });

        }, 500);
    };

    const scrollToMessageId = () => {
        setTimeout(()=>{
            var chatMessages = $('.ChatChannel .messages');
            var pos = $('.ChatChannel .messages .item[data-msg-id=' + location.query.msg + ']').position().top - 200 + chatMessages.scrollTop();

            chatMessages.scrollTop(pos);

            chatMessages.off('scroll');
            chatMessages.on('scroll', (e)=>{
                var scrollAmount = e.target.scrollTop;
                var maxScroll = $(e.target).prop('scrollHeight') - $(e.target).innerHeight();
                if(maxScroll - scrollAmount > 500 && !showLastMessagesBtn){
                    setShowLastMessagesBtn(true);
                }
                else if(maxScroll - scrollAmount <= 500 && showLastMessagesBtn){
                    setShowLastMessagesBtn(false);
                }

                checkDaySepVisibility();
            });

        }, 500);
    };

    const parseComment = (comment) => {
        return sanitizer(comment
            .replace('\n\n', '<br/><br/>')
            .replace('\r\r', '<br/><br/>')
            .replace('\n', '<br/>')
            .replace('\r', '<br/>')
            .replace(/@\[((?:[^\]]*))\]\(((?:[^)]*))\)/gi, '<span class="mention">@$1</span>')
            .replace(/\*\*((?:[^*]*))\*\*/gi, '<b>$1</b>')
            .replace(/\*((?:[^*]*))\*/gi, '<em>$1</em>')
            .replace(/´((?:[^*]*))´/gi, '<code>$1</code>'));
    };

    const getLastMessagesBtn = () => {
        if(showLastMessagesBtn){
            return (
                <Button
                    size="small"
                    className="lastMessagesBtn"
                    variant="contained"
                    color="primary"
                    onClick={scrollToEndMessages}
                >
                    Go to last messages
                </Button>
            );
        }
        return null;
    };

    const deleteMessage = (e) => {
        var messageId = $(e.currentTarget).attr('data-message-id');

        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this message?'}), {
            onOk: () => {
                firebaseEvents.deleteChatMessage(
                    router.params.type,
                    router.params.uid,
                    messageId
                );
            },
            okText: intl.formatMessage({id:'DELETE'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
    };

    const getMessages = () => {
        var channelId, messages = [];

        if(router.params.type === 'direct'){
            for(var i in chat.direct){
                var currChannel = chat.direct[i];
                if(currChannel.participants && currChannel.participants.indexOf(userId) !== -1 && currChannel.participants.indexOf(router.params.uid) !== -1){
                    channelId = i;
                }
            }
        }
        else {
            channelId = router.params.uid;
        }

        if(chat[router.params.type] && chat[router.params.type][channelId]){
            var list = _.sortBy(chat[router.params.type][channelId].messages, (msg)=>{
                return moment(msg.at).valueOf();
            });

            list.forEach((item)=>{
                messages.push(item);
            });
        }

        var results = [], lastMsg;

        messages.forEach((msg)=>{
            var userAt, text, unreadClass = '', deleteBtn;

            if(!msg.seenBy || msg.seenBy.indexOf(userId) === -1){
                unreadClass = ' unread';
            }

            var searchedClass = (msg.k === location.query.msg)?' highlight':'';

            if(!lastMsg || moment(msg.at).format('YYYY-MM-DD') !== moment(lastMsg.at).format('YYYY-MM-DD')){
                results.push(
                    <div className="daySeparator" key={'daysep-' + moment(msg.at).format('YYYY-MM-DD')}>
                        <span>{moment(msg.at).format('dddd Do MMMM YYYY')}</span>
                    </div>
                );
                lastMsg = null;
            }

            if(msg.type === 'text'){
                userAt = getCommentUserAt(msg.sentBy, msg.at);
                if(lastMsg && lastMsg.sentBy === msg.sentBy && (lastMsg.type === 'text' || lastMsg.type === 'file' || lastMsg.type === 'googleMeeting')){
                    userAt = null;
                }
                if(msg.sentBy === userId){
                    deleteBtn = <IconButton className="deleteMsgBtn" data-message-id={msg.k} onClick={deleteMessage}><DeleteIcon /></IconButton>;
                }

                results.push(
                    <div className={'item' + unreadClass + searchedClass} key={msg.k} data-msg-id={msg.k}>
                        {userAt}
                        <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                        <div dangerouslySetInnerHTML={{__html: linkifyHtml(parseComment(ReactDOMServer.renderToString(<Twemoji svg text={msg.value || ''}/>))) }} />
                        {deleteBtn}
                    </div>
                );
            }
            else if(msg.type === 'file'){

                userAt = getCommentUserAt(msg.sentBy, msg.at);
                if(lastMsg && lastMsg.sentBy === msg.sentBy && (lastMsg.type === 'text' || lastMsg.type === 'file' || lastMsg.type === 'googleMeeting')){
                    userAt = null;
                }


                if(msg.sentBy === userId){
                    deleteBtn = <IconButton className="deleteMsgBtn" data-message-id={msg.k} onClick={deleteMessage}><DeleteIcon /></IconButton>;
                }

                if(msg.contentType && (msg.contentType === 'image/jpeg' || msg.contentType === 'image/png' || msg.contentType === 'image/gif')){
                    results.push(
                        <div key={msg.k} className={'item file' + unreadClass + searchedClass} data-msg-id={msg.k}>
                            {userAt}
                            <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                            <div><a target="_blank" href={msg.url}><img className="fileImage" src={msg.url} title={msg.value} alt={msg.value} /></a></div>
                            {deleteBtn}
                        </div>
                    );
                }
                else {
                    results.push(
                        <div key={msg.k} className={'item file' + unreadClass + searchedClass} data-msg-id={msg.k}>
                            {userAt}
                            <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                            <div className="download"><CommentFileIcon /> {msg.value} <a target="_blank" href={msg.url}>Download</a></div>
                            {deleteBtn}
                        </div>
                    );
                }
            }
            else if(msg.type === 'googleMeeting'){
                userAt = getCommentUserAt(msg.sentBy, msg.at);
                if(lastMsg && lastMsg.sentBy === msg.sentBy && (lastMsg.type === 'text' || lastMsg.type === 'file' || lastMsg.type === 'googleMeeting')){
                    userAt = null;
                }

                if(msg.sentBy === userId){
                    deleteBtn = <IconButton className="deleteMsgBtn" data-message-id={msg.k} onClick={deleteMessage}><DeleteIcon /></IconButton>;
                }

                results.push(
                    <div className={'item' + unreadClass + searchedClass} key={msg.k} data-msg-id={msg.k}>
                        {userAt}
                        <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                        <div className="googleMeeting">
                            <strong>Created a Google meeting</strong>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                href={msg.meetingData}
                                target="_blank"
                            >Join meeting</Button>
                        </div>
                        {deleteBtn}
                    </div>
                );
            }
            else if(msg.type === 'added' && users[msg.sentBy]){
                text = '@[' + ((users[msg.sentBy])?users[msg.sentBy].displayName:'') + '](' + msg.sentBy + ') has been added to this ' + ((router.params.type === 'channels')?'channel':'group');
                results.push(
                    <div className={'item added' + unreadClass} key={msg.k}>
                        <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                        <div dangerouslySetInnerHTML={{__html: linkifyHtml(parseComment(ReactDOMServer.renderToString(<Twemoji svg text={text}/>))) }} />
                    </div>
                );
            }
            else if(msg.type === 'left' && users[msg.sentBy]){
                text = '@[' + ((users[msg.sentBy])?users[msg.sentBy].displayName:'') + '](' + msg.sentBy + ') has left this ' + ((router.params.type === 'channels')?'channel':'group');
                results.push(
                    <div className={'item added' + unreadClass} key={msg.k}>
                        <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                        <div dangerouslySetInnerHTML={{__html: linkifyHtml(parseComment(ReactDOMServer.renderToString(<Twemoji svg text={text}/>))) }} />
                    </div>
                );
            }
            else if(msg.type === 'removed' && users[msg.sentBy]){
                text = '@[' + ((users[msg.sentBy])?users[msg.sentBy].displayName:'') + '](' + msg.sentBy + ') has been removed from this ' + ((router.params.type === 'channels')?'channel':'group');
                results.push(
                    <div className={'item added' + unreadClass} key={msg.k}>
                        <span className="hour">{moment(msg.at).format((timeFormat)?timeFormat:'HH:mm')}</span>
                        <div dangerouslySetInnerHTML={{__html: linkifyHtml(parseComment(ReactDOMServer.renderToString(<Twemoji svg text={text}/>))) }} />
                    </div>
                );
            }

            lastMsg = msg;
        });

        if(!results.length){
            return (
                <div className="noMessages">
                    <img src="../../../stylesheets/assets/nocomments.png?v=2" />
                    <h1>No activity in this chat for now.<br/>Start the conversion below!</h1>
                </div>
            );
        }

        return (
            <div>{results}</div>
        );
    };

    const getCommentUserAt = (userId, date) => {
        var avatar = getUserAvatar(userId, false, users[userId]?.avatar);

        if(!date){
            return avatar;
        }

        return (
            <div className="chatSentBy">
                {avatar}
                <strong>{(users[userId])?users[userId].displayName:''}</strong>
            </div>
        );

    };

    const getMenuItems = () => {
        var items = [];

        if(router.params.type !== 'direct'){
            var label = (router.params.type === 'channels')?'channel':'group chat';

            var channel = chat[router.params.type][router.params.uid];
            var isUserOwner = false,
                isUserInviter = false;

            if(
                isAdmin({
                    user: {
                        data: {
                            permissions: userPermissions
                        }
                    }
                }) ||
                (
                    channel.perm &&
                    channel.perm.owners &&
                    channel.perm.owners.indexOf(userId) !== -1
                )
            ){
                isUserOwner = true;
                isUserInviter = true;
            }
            else if(
                router.params.type === 'groups' ||
                (
                    channel.perm &&
                    channel.perm.inviters &&
                    channel.perm.inviters.indexOf(userId) !== -1
                )
            ){
                isUserInviter = true;
            }

            if(isUserInviter && router.params.uid !== 'general'){
                items.push(<MenuItem key="add" onClick={()=>{
                    setShowAddParticipant(true);
                    setSettingsMenuAnchor(null);
                }}><ListItemIcon><AddParticipantIcon fontSize="small"/></ListItemIcon>Add participants</MenuItem>);
            }
            if(isUserOwner && router.params.uid !== 'general'){
                items.push(<MenuItem key="remove" onClick={()=>{
                    setShowRemoveParticipant(true);
                    setSettingsMenuAnchor(null);
                }}><ListItemIcon><RemoveParticipantIcon fontSize="small"/></ListItemIcon>Remove participants</MenuItem>);
            }

            if(isUserOwner && router.params.type === 'channels'){
                if(router.params.uid !== 'general'){
                    items.push(<MenuItem key="rename" onClick={()=>{
                        setShowRenameChannel(true);
                        setSettingsMenuAnchor(null);
                        setRenameChannelText(router.params.uid);
                    }}><ListItemIcon><RenameIcon fontSize="small"/></ListItemIcon>Rename channel</MenuItem>);
                }
                items.push(<MenuItem key="description" onClick={()=>{
                    setShowChangeDesc(true);
                    setSettingsMenuAnchor(null);
                    setChannelDescText(chat[router.params.type][router.params.uid].description);
                }}><ListItemIcon><ChangeDescIcon fontSize="small"/></ListItemIcon>Change channel description</MenuItem>);
            }

            if(!isUserOwner && router.params.uid !== 'general'){
                items.push(<MenuItem key="leave" onClick={leaveChannel}><ListItemIcon><LeaveIcon fontSize="small"/></ListItemIcon>Leave {label}</MenuItem>);
            }
            if(isUserOwner && router.params.uid !== 'general'){
                items.push(<MenuItem key="delete" className="delete" onClick={deleteChannel}><ListItemIcon><DeleteIcon fontSize="small"/></ListItemIcon>Delete {label}</MenuItem>);
            }

        }

        if(!items.length){
            return null;
        }

        return (
            <Menu
                className="chatChannelSettingsMenu"
                anchorEl={settingsMenuAnchor}
                keepMounted
                open={Boolean(settingsMenuAnchor)}
                onClose={()=>setSettingsMenuAnchor(null)}
            >
                {items}
            </Menu>
        );
    };

    const leaveChannel = () => {
        var label = (router.params.type === 'channels')?'channel':'group chat';
        setSettingsMenuAnchor(null);

        toastr.confirm(intl.formatMessage({id:'Are you sure you want to leave this ' + label + '?'}), {
            onOk: () => {
                firebaseEvents.leaveChatChannel(
                    router.params.type,
                    router.params.uid
                );
                browserHistory.push('/chat');
            },
            okText: intl.formatMessage({id:'Leave'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
    };

    const deleteChannel = () => {
        var label = (router.params.type === 'channels')?'channel':'group chat';
        setSettingsMenuAnchor(null);

        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this ' + label + '?'}), {
            onOk: () => {
                firebaseEvents.deleteChatChannel(
                    router.params.type,
                    router.params.uid
                );
                browserHistory.push('/chat');
            },
            okText: intl.formatMessage({id:'DELETE'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
    };

    const getAddParticipantsModal = () => {

        if(router.params.type!=='direct' && chat[router.params.type] && chat[router.params.type][router.params.uid]){

            var channelParticipants = chat[router.params.type][router.params.uid].participants;

            var options = [];

            var tempOptions = [];
            _.each(users, (u, k)=>{
                if(!u.viewer && k && k !== userId && channelParticipants.indexOf(k) ===-1){
                    tempOptions.push({id: k, name: u.displayName});
                }
            });
            tempOptions = _.sortBy(tempOptions, 'name');

            _.each(tempOptions, (u)=>{ options.push(u.id); });

            return (
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={showAddParticipant}
                    onClose={()=>{
                        setShowAddParticipant(false);
                        setAddPartList([]);
                    }}
                >
                    <DialogTitle>Add participants</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            size="small"
                            className="autocomplete addParticipants"
                            multiple
                            filterSelectedOptions={true}
                            options={options}
                            value={addPartList}
                            getOptionLabel={option => <div className="optionUser" key={option}>{getUserAvatar(option, false, users[option].avatar)}{(users[option])?users[option].displayName:''}</div>}
                            renderInput={params => (
                                <TextField autoFocus {...params} variant="outlined" label={intl.formatMessage({id:'Participants to add'})} fullWidth />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    if(users[option]){
                                        var props = getTagProps({ index });
                                        return (
                                            <Chip
                                                avatar={getUserAvatar(option, false, users[option].avatar)}
                                                variant="outlined"
                                                label={(users[option])?users[option].displayName:''}
                                                {...props}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }

                            filterOptions={(options, {inputValue})=>{
                                var results = [];
                                options.forEach((o)=>{
                                    if(users[o] && users[o].displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1){
                                        results.push(o);
                                    }
                                });
                                return results;
                            }}
                            onChange={(e, values)=>{setAddPartList(values);}}
                        />
                    </DialogContent>
                    <DialogActions className="ChatChannelDialogActions">
                        <Button variant="contained" onClick={()=>{
                            setShowAddParticipant(false);
                            setAddPartList([]);
                        }}>Cancel</Button>
                        <Button variant="contained" onClick={addParticipants} color="primary">Add participants</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const getRemoveParticipantsModal = () => {
        if(router.params.type!=='direct' && chat[router.params.type] && chat[router.params.type][router.params.uid]){

            var channelParticipants = chat[router.params.type][router.params.uid].participants;

            var options = [];

            var tempOptions = [];
            _.each(users, (u, k)=>{
                if(k && k !== userId && channelParticipants.indexOf(k) !== -1){
                    tempOptions.push({id: k, name: u.displayName});
                }
            });
            tempOptions = _.sortBy(tempOptions, 'name');

            _.each(tempOptions, (u)=>{ options.push(u.id); });

            return (
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={showRemoveParticipant}
                    onClose={()=>{
                        setShowRemoveParticipant(false);
                        setRemovePartList([]);
                    }}
                >
                    <DialogTitle>Remove participants</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            size="small"
                            className="autocomplete removeParticipants"
                            multiple
                            filterSelectedOptions={true}
                            options={options}
                            value={removePartList}
                            getOptionLabel={option => <div className="optionUser" key={option}>{getUserAvatar(option, false, users[option].avatar)}{(users[option])?users[option].displayName:''}</div>}
                            renderInput={params => (
                                <TextField autoFocus {...params} variant="outlined" label={intl.formatMessage({id:'Participants to remove'})} fullWidth />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    if(users[option]){
                                        var props = getTagProps({ index });
                                        return (
                                            <Chip
                                                avatar={getUserAvatar(option, false, users[option].avatar)}
                                                variant="outlined"
                                                label={(users[option])?users[option].displayName:''}
                                                {...props}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }

                            filterOptions={(options, {inputValue})=>{
                                var results = [];
                                options.forEach((o)=>{
                                    if(users[o] && users[o].displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1){
                                        results.push(o);
                                    }
                                });
                                return results;
                            }}
                            onChange={(e, values)=>{setRemovePartList(values);}}
                        />
                    </DialogContent>
                    <DialogActions className="ChatChannelDialogActions">
                        <Button variant="contained" onClick={()=>{
                            setShowRemoveParticipant(false);
                            setRemovePartList([]);
                        }}>Cancel</Button>
                        <Button variant="contained" onClick={removeParticipants} color="primary">Remove participants</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const addParticipants = () => {
        if(addPartList.length){

            firebaseEvents.addChatParticipants(
                router.params.type,
                router.params.uid,
                addPartList
            );
            setShowAddParticipant(false);
            setAddPartList([]);

        }
        else {
            toastr.error('Select at least 1 participant');
        }
    };

    const removeParticipants = () => {
        if(removePartList.length){

            firebaseEvents.removeChatParticipants(
                router.params.type,
                router.params.uid,
                removePartList
            );
            setShowRemoveParticipant(false);
            setRemovePartList([]);

        }
        else {
            toastr.error('Select at least 1 participant');
        }
    };

    const getRenameModal = () => {
        if(router.params.type === 'channels' && chat[router.params.type] && chat[router.params.type][router.params.uid]){
            return (
                <Dialog
                    maxWidth="xs"
                    fullWidth
                    open={showRenameChannel}
                    onClose={()=>{
                        setShowRenameChannel(false);
                        setRenameChannelText('');
                    }}
                >
                    <DialogTitle>Rename channel</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            className='renameChannel'
                            label="Channel name"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                pattern: '[A-Za-z0-9-_]{3,20}',
                                maxLength: 35
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                            }}
                            value={renameChannelText}
                            onChange={(e)=>setRenameChannelText(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className="ChatChannelDialogActions">
                        <Button variant="contained" onClick={()=>{
                            setShowRenameChannel(false);
                            setRenameChannelText('');
                        }}>Cancel</Button>
                        <Button variant="contained" onClick={renameChannel} color="primary">Rename</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const renameChannel = () => {
        var newName = renameChannelText;

        if(newName.length > 35){
            toastr.error('Channel name can be maximum 35 characters');
        }
        else if(newName.length < 4){
            toastr.error('Channel name must be at least 4 characters');
        }
        else if(!newName.match(/^[A-Za-z0-9-_]{4,35}$/)){
            toastr.error('Channel name can only contain letters, numbers, "-" and "_" (No spaces)');
        }
        else if(chat.channels[newName]){
            toastr.error('Channel name already exist.');
        }
        else {
            setRenaming(true);
            firebaseEvents.renameChatChannel(router.params.type, router.params.uid, newName).then(()=>{
                browserHistory.push('/chat/channels/' + newName);
                setRenaming(false);
                setShowRenameChannel(false);
            });
        }
    };

    const getChangeDescriptionModal = () => {
        if(router.params.type === 'channels' && chat[router.params.type] && chat[router.params.type][router.params.uid]){
            return (
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={showChangeDesc}
                    onClose={()=>{
                        setShowChangeDesc(false);
                        setChannelDescText('');
                    }}
                >
                    <DialogTitle>Change channel descritpion</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            label="Channel description"
                            className="channelDesc"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={channelDescText}
                            onChange={(e)=>setChannelDescText(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className="ChatChannelDialogActions">
                        <Button variant="contained" onClick={()=>{
                            setShowChangeDesc(false);
                            setChannelDescText('');
                        }}>Cancel</Button>
                        <Button variant="contained" onClick={changeChannelDescription} color="primary">Rename</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const changeChannelDescription = () => {
        firebaseEvents.changeChannelDescription(router.params.type, router.params.uid, channelDescText);
        setShowChangeDesc(false);
        setChannelDescText('');
    };



    useEffect(()=>{
        checkDaySepVisibility();
    }, [router, timeFormat, userPermissions, userId, users, chat]);

    useEffect(()=>{
        if (!chat) {
            return;
        }

        firebaseEvents.markMessagesOfChannelAsRead(router.params.type, router.params.uid);
    }, [router.params.type, router.params.uid, chat]);

    useEffect(()=>{
        $(window).on('resize', setMessagesHeight);

        return function cleanup(){
            scrolledFirst = false;
            firebaseEvents.markMessagesOfChannelAsRead(router.params.type, router.params.uid);
            $(window).off('resize', setMessagesHeight);
        };
    }, []);

    useEffect(()=>{
        setMessagesHeight();

        if(!scrolledFirst && $('.ChatChannel .messages') && !location.query.msg){
            scrollToEndMessages();
            scrolledFirst = true;
        }
        if(!scrolledFirst && location.query.msg){
            scrolledFirst = true;
            scrollToMessageId();
        }

    });

    if(
        userId &&
        chat
    ){

        if(router.params.type === 'direct' && !users[router.params.uid]){
            return null;
        }

        //if channel or group doesn't exist or user is not participant we redirect to chat root
        if(
            router.params.type !== 'direct' &&
            !renaming &&
            (
                !chat[router.params.type] ||
                !chat[router.params.type][router.params.uid] ||
                chat[router.params.type][router.params.uid].participants.indexOf(userId) === -1
            )
        ){
            browserHistory.push('/chat');
            return null;
        }

        var title = null, description = null, avatar = null, participants;

        if(router.params.type === 'groups'){
            title = 'Group chat';
            description = [];
            participants = chat[router.params.type][router.params.uid].participants;
            participants.forEach((p)=>{
                if(p!==userId && users[p]){
                    description.push((users[p])?users[p].displayName:'');
                }
            });
            description.sort();
            description.reverse();
            description.push('You');
            description.reverse();
            description = description.join(', ');
            description = <div className="participants"><GroupIcon/> {description}</div>;

            avatar = <Avatar><GroupIcon/></Avatar>;
        }
        else if(router.params.type === 'direct'){
            title = (users[router.params.uid])?users[router.params.uid].displayName:'';
            description = 'Direct chat';

            var user = users[router.params.uid];

            avatar = (
                <Badge overlap="rectangular" variant="dot" invisible={(user.connected && user.connected.state === 'online')?false:true}>
                    {getUserAvatar(router.params.uid, false, users[router.params.uid].avatar)}
                </Badge>
            );
        }
        else {
            title = router.params.uid;
            description = (
                chat[router.params.type] &&
                chat[router.params.type][router.params.uid] &&
                chat[router.params.type][router.params.uid].description
            )?chat[router.params.type][router.params.uid].description:'...';

            var tmpDesc = [];

            participants = Object.keys(users);
            participants.forEach((p)=>{
                if(p!==userId && users[p]){
                    tmpDesc.push((users[p])?users[p].displayName:'');
                }
            });
            tmpDesc.sort();
            tmpDesc.reverse();
            tmpDesc.push('You');
            tmpDesc.reverse();
            tmpDesc = tmpDesc.join(', ');

            description = <div>{description}<div className="participants"><GroupIcon/> {tmpDesc}</div></div>;

            avatar = <Avatar>#</Avatar>;
        }

        var menuItems = getMenuItems();


        return (
            <div>
                <Card elevation={0} square={true} key={'chatchannel-' + router.params.type + '-' + router.params.uid} className="ChatChannel" id="ChatChannel">
                    <CardHeader
                        title={title}
                        subheader={description}
                        avatar={avatar}
                        action={
                            (menuItems)?
                                (
                                    <IconButton aria-label="settings" onClick={(e)=>setSettingsMenuAnchor(e.currentTarget)}>
                                        <SettingsIcon />
                                    </IconButton>
                                ):null
                        }
                    />
                    <CardContent>
                        <div className="messages">
                            {getMessages()}
                        </div>

                        {getLastMessagesBtn()}

                        <div className="fixedDaySeparator daySeparator"></div>

                        {menuItems}

                        {getAddParticipantsModal()}
                        {getRemoveParticipantsModal()}
                        {getRenameModal()}
                        {getChangeDescriptionModal()}

                    </CardContent>
                </Card>
                <ChatInput channelType={router.params.type} channelId={router.params.uid} onUpdate={()=>{ setMessagesHeight(); scrollToEndMessages(); }} />
            </div>
        );

    }

    return null;

};

export default withRouter(withCustomErrorBoundary(ChatChannel));
