import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import { getAuthToken } from '../../utils/calendarIntegration';
import Selectors from '../../utils/selectors';
import { toastr } from 'react-redux-toastr';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ConnectCalendar = () => {
    const currentUserId = useSelector(Selectors.userSelector.getUserId);
    const currentAccountId = useSelector(Selectors.userSelector.getUserAccountId);
    const [isLoading] = useState(false);
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    const handleClose = () => {
        browserHistory.push('/settings/my-account');
    };

    const getGoogleCalList = async () => {
        try {
            const data = await getAuthToken({
                userId: currentUserId,
                accountId: currentAccountId,
                scope: 'google',
            });
            window.location.replace(data.data);
        } catch (err) {
            toastr.error('Something went wrong please try again!');
        }
    };

    const getOutlook = async () => {
        try {
            const data = await getAuthToken({
                userId: currentUserId,
                accountId: currentAccountId,
                scope: 'microsoft',
            });
            window.location.replace(data.data);
        } catch (err) {
            toastr.error('Something went wrong please try again!');
        }
    };

    return (
        <Dialog className="ConnectCalendar" open={true} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{t('Connect a calendar')}</DialogTitle>
            <DialogContent className="content">
                <h3>Select your calendar provider</h3>
                {isLoading && <CircularProgress> </CircularProgress>}
                <div className="listCalendars">
                    <img
                        src="../../../stylesheets/assets/google-calendar.png"
                        alt="Google calendar"
                        onClick={getGoogleCalList}
                    />
                    <span />
                    <img src="../../../stylesheets/assets/outlook.png" alt="Outlook" onClick={getOutlook} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default withCustomErrorBoundary(ConnectCalendar);
