export var Currency;
(function (Currency) {
    Currency["USD"] = "USD";
    Currency["EUR"] = "EUR";
    Currency["GBP"] = "GBP";
})(Currency || (Currency = {}));
export var Recurrence;
(function (Recurrence) {
    Recurrence["MONTHLY"] = "MONTHLY";
    Recurrence["YEARLY"] = "YEARLY";
})(Recurrence || (Recurrence = {}));
