import { Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import _ from 'underscore';

import { updateStoryPoint } from '../../../../../utils/controllers/syncApp/jira';
import { withCustomErrorBoundary } from '../../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { segmentTrack } from '../../../../../utils/firebaseEvents';
import { getUserId } from '../../../../../utils/selectors/user';
import { formatShortCuts } from '../utils';

export const StoryPointLine = ({ storyPoint, spId, syncAppId, allStoryPoints }) => {
    const currentUserId = useSelector(getUserId);
    const [spvalue, setSpvalue] = React.useState(0);
    const [minEffort, setMinEffort] = React.useState(0);
    const [maxEffort, setMaxEffort] = React.useState(0);

    useEffect(() => {
        if(storyPoint) {
            const { spvalue, effort: { min, max }} = storyPoint;

            setSpvalue(spvalue);
            setMinEffort(min);
            setMaxEffort(max);
        }
    }, [storyPoint]);

    const isStoryPointValid = (newSpValue) => {
        const spAlreadyInUse = _.find(allStoryPoints, sp => sp.spvalue === newSpValue && spId !== sp.id);

        if(spAlreadyInUse) {
            toastr.error('Not allowed to have 2 story points with the same value!');
            setSpvalue(storyPoint.spvalue);
            return false;
        }
        return true;
    };

    const handleUpdateStoryPoint = async () => {
        if(!isStoryPointValid(spvalue)) return;

        const { max, min } = formatShortCuts({ max: maxEffort, min: minEffort });

        const storyPointUpdated = {
            ...storyPoint, 
            spvalue: spvalue, 
            effort: { min, max }
        };

        await updateStoryPoint({
            syncAppId, 
            storyPointData: storyPointUpdated,
            spId,
        });

        setMinEffort(min);
        setMaxEffort(max);
    };

    const handleDeleteStoryPoint = async () => {
        await updateStoryPoint({
            syncAppId, 
            storyPointData: null,
            spId,
        });

        segmentTrack('deleteStoryPoint', { userId: currentUserId });
    };

    const handleValidateMinEffortChange = () => {
        const min = formatShortCuts({ min: minEffort, max: maxEffort }).min;
        const rangeOverlapping = _.find(allStoryPoints, sp => sp.effort.min <= min && sp.effort.max >= min && sp.id !== spId);

        if(min === 0) return;

        if(rangeOverlapping) {
            toastr.error('Effort ranges can’t overlap!');
            setMinEffort(storyPoint.effort.min);
            return;
        }
        setMinEffort(min);
        handleUpdateStoryPoint();
    };


    const handleValidateMaxEffortChange = () => {
        const max = formatShortCuts({ max: maxEffort, min: minEffort }).max;
        const rangeOverlapping = _.find(allStoryPoints, sp => sp.effort.min <= max && sp.effort.max >= max && sp.id !== spId);

        if(max === 0) return;

        if(rangeOverlapping) {
            toastr.error('Effort ranges can’t overlap!');
            setMaxEffort(storyPoint.effort.max);
            return;
        }
        
        setMaxEffort(max);
        handleUpdateStoryPoint();
    };

    const handleChangeMinEffort = (e) => setMinEffort(e.target.value);
    const handleChangeMaxEffort = (e) => setMaxEffort(e.target.value);
    const handleChangeSpValue = (e) => setSpvalue(parseInt(e.target.value || 0));

    return (
        <div className="StoryPointLine">
            <div className="StoryPoint__value">
                <input
                    type="text"
                    value={spvalue}
                    onBlur={handleUpdateStoryPoint}
                    onChange={handleChangeSpValue}
                />
            </div>

            <div className="effort__value">
                <div className="effort__value__inputs">
                    <input
                        type="text" 
                        value={minEffort}
                        onBlur={handleValidateMinEffortChange}
                        onChange={handleChangeMinEffort}
                    />
                    <span>-</span>
                    <input
                        type="text"
                        value={maxEffort}
                        onBlur={handleValidateMaxEffortChange}
                        onChange={handleChangeMaxEffort}
                    />
                </div>
            </div>

            <Button
                className="StoryPointLine__Delete"
                onClick={handleDeleteStoryPoint}
                startIcon={<Delete/>}
            />
        </div>
    );
};

export default withCustomErrorBoundary(StoryPointLine);