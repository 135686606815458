/* eslint-disable max-len */
import _ from 'underscore';
import { getState } from '../../../../../configureStore';
import { db } from '../../../../firebaseEvents';
import { getAccountBasePermissions, getAccountId } from '../../../../selectors/account';
var removeMemberFromBasePermissions = function (_a) {
    var userId = _a.userId, basePermissionTypes = _a.basePermissionTypes;
    var accountId = getAccountId(getState());
    var accountBasePermissions = getAccountBasePermissions(getState());
    if (!accountBasePermissions || !accountId)
        return;
    var updates = basePermissionTypes.reduce(function (acc, type) {
        var permissions = _.get(accountBasePermissions, [type], []);
        if (!(permissions === null || permissions === void 0 ? void 0 : permissions.includes(userId)))
            return acc;
        var membersArrUpdated = _.without(permissions, userId);
        acc["/accounts/".concat(accountId, "/settings/basePermissions/").concat(type)] = !_.isEmpty(membersArrUpdated) ? membersArrUpdated : null;
        return acc;
    }, {});
    if (!_.isEmpty(updates)) {
        db.update(undefined, updates);
    }
};
export default removeMemberFromBasePermissions;
