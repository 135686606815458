import React from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';

const MultiDeleteDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
  
    const handleConfirm = () => {
        const taskIdsToDelete = selectedItems.map(task=>task.id);
        firebaseEvents.deleteTasks(taskIdsToDelete);
        setSelectedLines();
        showMultiDetails();
    };
   

    const handleNo = () => {
        showMultiDetails();
    };

    return  <div id="multiDeleteDetails">
        <label>
        Are you sure you want to delete all these items?
            <br />
            <span>This operation is irreversible</span>
        </label>
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Yes
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
            No
        </Button>
    </div>;
};

export default MultiDeleteDetails; 