import React from 'react';
import _ from 'underscore';
import TextField from '@material-ui/core/TextField';
import { getAccountUsers } from '../../utils/selectors/account';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

export default ({ values, onChange }) => {
    const accountTeamMembers = useSelector(getAccountUsers);
    const selectedUsers = values.map(el => accountTeamMembers[el]) || [];
    const memberResouces = getOnlyResourceUsers(accountTeamMembers);
    const memberResoucesArr = _.toArray(memberResouces);
    
    
    return <div>
        <FormControl fullWidth className="buttons-container" component="fieldset">
            <Autocomplete
                fullWidth
                value={selectedUsers}
                onChange={onChange}
                filterSelectedOptions={true}
                multiple
                className="autocomplete owners"
                id="users-To"
                options={memberResoucesArr}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const props = getTagProps({ index });

                        return (
                            <Chip
                                avatar={ <StateUserAvatar
                                    className="avatar"
                                    userId={option.userId}
                                />}
                                variant="outlined"
                                label={option?.displayName || ''}
                                {...props}
                            />
                        );
                    })
                }
                filterOptions={(options, {inputValue})=>{
                    return options.filter(o => (o.displayName?.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1));
                }}
                getOptionLabel={(option) => <div key={option.displayName} className="optionUser">
                    <StateUserAvatar
                        className="avatar"
                        userId={option.userId}
                    />
                    {option?.displayName || ''} </div>}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Task Done By" variant="outlined" />}
            />
        </FormControl>
    </div>;
};