import React from 'react';
import MaterialButton from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const Button = (props) =>  <MaterialButton 
    color={props.color}
    className={props.className}
    onClick={props.onClick}
    disabled={props.disabled}
    disableElevation={props.disableElevation}
    disableFocusRipple={props.disableFocusRipple}
    endIcon={props.endIcon}
    fullWidth={props.fullWidth}
    href={props.href}
    size={props.size}
    startIcon={props.startIcon}
    variant={props.variant}
>
    {props.isLoading && <div className="button-loading-container"> <CircularProgress size='1rem' /> </div> } 
    {props.children}
</MaterialButton>;

export default Button;