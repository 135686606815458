import React, { useCallback, useEffect, useState } from 'react';
import Menu from './Menu/Menu';
import DateFilter from './DateFilter/DateFilter';
import TopLevelSelector from './TopLevelSelector/TopLevelSelector';
import DateDriftGraph from './DateDrift/DateDrift';
import TaskBurndown from './TaskBurndown/TaskBurndown';
import TimeWorkedChart from './TimeWorked/TimeWorked';
import TeamMembersChart from './TeamMembersChart/TeamMembersChart';
import Effort from './Effort/Effort';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import { getCsvFile, getSplitType, menuItems } from './utils';
import { tracking } from '../../utils/utils';
// import Typography from '@material-ui/core/Typography';
// import { useIntl } from 'react-intl';
import { isAdmin } from '../../utils/userFunctions';
import { showLoader, hideLoader } from '../../views/App/AppActions';
import DaysOffChart from './DaysOfChart/DaysOffChart';
import Events from './Events/Events';
import ExpensesList from './ExpensesList/ExpensesList';
import UpcomingTasks from './UpcomingTasks/UpcomingTasks';
import RecentlyDoneTasks from './RecentlyDoneTasks/RecentlyDoneTasks';
import RecentsEvents from './RecentsEvents/RecentsEvents';
import RiskList from './RiskList/RiskList';
import UpcomingEvents from './UpcomingEvents/UpcomingEvents';
import TimeWorkDetail from './TimeWorkDetail/TimeWorkDetail';
import TaskBreakdown from './TaskBreakdown/TaskBreakdown';
import EffortBreakdown from './EffortBreakdown/EffortBreakdown';
import Overview from './Overview/Overview';
import CostBudget from './CostBudget/CostBudget';
import CostBudgetRepartition from './CostBudgetRepartition/CostBudgetRepartition';
import BillableCostBudgetRepartition from './CostBudgetRepartition/BillableCostBudgetRepartition';
import ExpenseTypeRepartition from './CostBudgetRepartition/ExpenseTypeRepartition';
import Unautorized from '../Unautorized/Unautorized';
import moment from 'moment-timezone';
import { getUserPastEvents } from '../../utils/firebaseEvents';
import { permissionsByPlan } from '../../utils/constants';
import NotInMobile from '../NotInMobile/NotInMobile';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import SectionTitle from '../SectionTitle/SectionTitle';
import { Equalizer } from '@material-ui/icons';

const convertToDateTime = time => {
    return moment.unix(parseInt(time) * 60 * 60 * 4);
};

const Reports = ({ params, children }) => {
    const user = useSelector(state => state.app.user || {});
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const basePermissions = useSelector(state => state.app.account?.settings.basePermissions || {});
    const accountPlan = useSelector(state => state.app.account?.settings.plan || {});
    const userDateFormat = useSelector(state => state.app?.user?.data?.dateFormat || 'DD/MM/YYYY');
    const userTimezone = useSelector(state => state.app?.user?.data?.timezone || moment.tz.guess());
    const stateTasks = useSelector(
        state => state.app?.account?.tasks || {},
        (a, b) => a && b && Object.keys(a).length === Object.keys(b).length,
    );
    const childsParents = useSelector(
        state => state.app?.parentChildIds?.childs,
        (a, b) => a && b && Object.keys(a).length === Object.keys(b).length,
    );
    const accountId = useSelector(state => state?.app?.account?.settings.id);
    const [workspaceSelected, setWorkspaceSelected] = useState();
    const [dateRange, setDateRange] = useState({
        startDate: moment().tz(userTimezone).subtract(7, 'days'),
        endDate: moment().tz(userTimezone),
    });
    const [maxMinRange, setMaxMinRange] = useState({
        startDate: moment().tz(userTimezone).subtract(7, 'days'),
        endDate: moment().tz(userTimezone),
    });
    const [workspaceData, setWorkspaceData] = useState([]);
    // object used to get the a user events, contains both the past saved into accountsData and the datas.
    const [usersAllEvents, setUsersAllEvents] = useState({});
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [splitBy, setSplitBy] = useState('day');
    const isUserAdmin = isAdmin({ user });
    // const intl = useIntl();
    const dispatch = useDispatch();
    const activeMenu = menuItems.find(el => el.type === params.mode);

    useEffect(() => {
        if (accountTeamMembers) {
            (async () => {
                const usersEventsData = {};
                await Promise.all(
                    Object.keys(accountTeamMembers).map(userId =>
                        getUserPastEvents(userId).then(a => {
                            usersEventsData[userId] = [...(a || []), ...(accountTeamMembers[userId].events || [])];
                        }),
                    ),
                );
                setUsersAllEvents(usersEventsData);
            })();
        }
    }, [accountTeamMembers]);

    useEffect(() => {
        if (!accountId || !params?.taskId && !stateTasks) {
            return;
        }

        dispatch(showLoader());

        const workspace = params.taskId || 'root';
        setWorkspaceSelected(workspace);

        getCsvFile(accountId, workspace, userTimezone).then(result => {
            setWorkspaceData(result);

            if (result.length) {
                const beginingDate = convertToDateTime(result[0].Datetime);
                let maxLastest = moment().tz(userTimezone);

                if (workspace === 'root') {
                    Object.keys(stateTasks).forEach(taskKey => {
                        const currentTask = stateTasks[taskKey];
                        if (currentTask.estimations && currentTask.estimations.endAt) {
                            maxLastest = moment
                                .tz(currentTask.estimations.endAt, 'X', userTimezone)
                                .isBefore(maxLastest)
                                ? maxLastest
                                : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
                        }
                    });
                } else {
                    const currentTask = stateTasks[workspace];
                    if (currentTask && currentTask?.estimations?.endAt) {
                        maxLastest = moment
                            .tz(currentTask.estimations.endAt, 'X', userTimezone)
                            .isBefore(maxLastest)
                            ? maxLastest
                            : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
                    }
                }

                if (!isFirstTime) {
                    setDateRange({
                        startDate: moment(beginingDate).tz(userTimezone),
                        endDate: moment(maxLastest).tz(userTimezone),
                    });

                    setSplitBy(
                        getSplitType(moment(beginingDate).tz(userTimezone), moment(maxLastest).tz(userTimezone)),
                    );
                }

                setMaxMinRange({
                    startDate: moment(beginingDate).tz(userTimezone),
                    endDate: moment(maxLastest).tz(userTimezone),
                });

                setIsFirstTime(true);
            }

            dispatch(hideLoader());
        });

    }, [params.taskId, accountId, stateTasks]);

    const handleMenuClick = useCallback(
        type => {
            browserHistory.push(`/insights/${workspaceSelected}/${type}`);
        },
        [workspaceSelected],
    );
    // get Info of the select
    const onWorkspaceChange = useCallback(
        taskId => {
            browserHistory.push(`/insights/${taskId}/${params.mode}`);
            setWorkspaceSelected(taskId);

            tracking('Reports Workspace change', { taskId, mode: params.mode });
        },
        [params.mode, accountId],
    );

    const onSplitChange = useCallback(data => {
        tracking('Click on reports periodicity', { split: data });
        setSplitBy(data);
    }, []);

    // change the data range
    const onChangeDataRange = useCallback(({ startDate, endDate }) => {
        setDateRange({ startDate, endDate });
        setSplitBy(getSplitType(startDate, endDate));
        tracking('Reports date range', { startDate, endDate });
    });
    const isUserManager = basePermissions?.managers?.includes(user.data?.userId) || false;
    const hasAutorization =
        (workspaceSelected === 'root' && (isUserAdmin || isUserManager)) ||
        (workspaceSelected !== 'root' && stateTasks[workspaceSelected]?.canManage);


    if (!permissionsByPlan.insights.includes(accountPlan) || (stateTasks && params.taskId !== 'root' && !stateTasks[params.taskId])) {
        return <Unautorized />;
    }

    tracking('Saw Insights Page');

    return (
        <div className="Reports">
            <div className="left-container">
                <div className="option-bar">
                    <SectionTitle title="Insights" icon={<Equalizer />} />
                </div>
                <div>
                    <Menu activeMenu={params.mode} handleMenuClick={handleMenuClick} />
                </div>
            </div>
            <div className="right-container">

                <div className="option-bar">
                    <TopLevelSelector workspaceSelected={workspaceSelected} onChange={onWorkspaceChange} />
                    {/* <div className="option-item">
                        <Typography variant="h4" component="h4" gutterBottom>
                            {intl.formatMessage({ id: `reports.types.${params.mode}` })}
                        </Typography>
                    </div> */}
                    <div className="option-item date">
                        <DateFilter
                            enabled={activeMenu?.usesDateRange}
                            maxMinRange={maxMinRange}
                            dateFormat={userDateFormat}
                            onChange={onChangeDataRange}
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            userTimezone={userTimezone}
                        />
                    </div>
                </div>
                {params.mode === 'overview' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <Overview
                                usersAllEvents={usersAllEvents}
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'date-drift' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <DateDriftGraph
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'task-count-burndown' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <TaskBurndown
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'effort-burndown' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <Effort
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'time-worked-total' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <TimeWorkedChart
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'team-members-count' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <TeamMembersChart
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={'root'}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'days-off' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <DaysOffChart
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                accountId={accountId}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'events' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <Events
                                usersAllEvents={usersAllEvents}
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                dateFormat={userDateFormat}
                                accountId={accountId}
                                childsParents={childsParents}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'cost-budget' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <CostBudget
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceData={workspaceData}
                                workspaceSelected={workspaceSelected}
                                datesRange={dateRange}
                                dateFormat={userDateFormat}
                                accountId={accountId}
                                childsParents={childsParents}
                                setSplitBy={onSplitChange}
                                splitBy={splitBy}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'upcoming-tasks' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <UpcomingTasks
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                dateFormat={userDateFormat}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'recently-done-tasks' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <RecentlyDoneTasks
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                dateFormat={userDateFormat}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'recent-events' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <RecentsEvents
                                usersAllEvents={usersAllEvents}
                                dateFormat={userDateFormat}
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'risks' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <RiskList
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'expenses-list' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <ExpensesList
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'upcoming-events' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <UpcomingEvents
                                usersAllEvents={usersAllEvents}
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                dateFormat={userDateFormat}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'time-worked-detailed' && (
                    <div className="report-container">
                        {!!workspaceData.length && (
                            <TimeWorkDetail
                                stateTasks={stateTasks}
                                datesRange={dateRange}
                                dateFormat={userDateFormat}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'task-breakdown' && (
                    <div>
                        {!!workspaceData.length && (
                            <TaskBreakdown
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'effort-breakdown' && (
                    <div>
                        {!!workspaceData.length && (
                            <EffortBreakdown
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                                userTimezone={userTimezone}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'internal-cost-budget-repartition' && (
                    <div>
                        {!!workspaceData.length && (
                            <CostBudgetRepartition
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'billable-cost-budget-repartition' && (
                    <div>
                        {!!workspaceData.length && (
                            <BillableCostBudgetRepartition
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                            />
                        )}
                    </div>
                )}
                {params.mode === 'expenses-repartition' && (
                    <div>
                        {!!workspaceData.length && (
                            <ExpenseTypeRepartition
                                stateTasks={stateTasks}
                                hasAutorization={hasAutorization}
                                workspaceSelected={workspaceSelected}
                                childsParents={childsParents}
                            />
                        )}
                    </div>
                )}
            </div>
            {children}

            <NotInMobile />
        </div>
    );
};

export default withCustomErrorBoundary(Reports);
