import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { getUserAvatar, validateEmail } from '../../utils/utils';
import { getUserDisplayName } from '../../utils/selectors/user';
import {
    getAccountCostBudgetSetting,
    getAccountId,
    getAccountName,
    getAccountUser,
    getAccountUserAvatar,
    getAccountUserDisplayName,
    getAccountUserEmail,
    getAccountUserMemberType,
    getAccountUserUid
} from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MemberTypeDropdown from '../../components/MemberTypeDropdown/MemberTypeDropdown';
import CustomSideBar from '../CustomSideBar/CustomSideBar';
import BudgetManagementTab from './tabs/BudgetManagement';
import ExtraHours from './tabs/ExtraHours';
import Skills from './tabs/Skills';
import Availability from './tabs/Availability';
import DaysOff from './tabs/DaysOff';
import Permissions from './tabs/Permissions';
import _ from 'underscore';
import classnames from 'clsx';
import { tabByMemberType, tabNameByIndex } from './constants';

const EditTeamMember = ({children, router, location, params})=>{
    const intl = useIntl();

    const memberId = router.params.uid;

    const memberData = useSelector(getAccountUser(memberId));
    const memberType = useSelector(getAccountUserMemberType(memberId));
    const memberAvatar = useSelector(getAccountUserAvatar(memberId));
    const memberDisplayName = useSelector(getAccountUserDisplayName(memberId));
    const memberEmail = useSelector(getAccountUserEmail(memberId));
    const memberUid = useSelector(getAccountUserUid(memberId));
    const accountId = useSelector(getAccountId);
    const accountName = useSelector(getAccountName);
    const userName = useSelector(getUserDisplayName);
    const accountCostBudgetSetting = useSelector(getAccountCostBudgetSetting);

    const [displayName, setDisplayName] = useState(memberDisplayName);
    const [email, setEmail] = useState('');
    const [activeTab, setActiveTab] = useState(0);

    useEffect(()=>{
        if(displayName !== memberDisplayName){
            setDisplayName(memberDisplayName);
        }
    }, [memberDisplayName]);

    useEffect(() => {
        if (!memberType) {
            return;
        }

        const memberTypeTabs = tabByMemberType[memberType];
        const currentActiveTab = tabNameByIndex[activeTab];
        const isCurrentTabValid = memberTypeTabs.includes(currentActiveTab);

        if(!isCurrentTabValid){
            const firstValidTab = memberTypeTabs[0];
            const newTab = Number(_.invert(tabNameByIndex)[firstValidTab]);
            setActiveTab(newTab);
        }
    }, [memberType]);

    const handleClose = () => {
        if(location.state !== 'from_integrations') {
            setTimeout(() => {
                browserHistory.push('/settings/team-members/');
            }, 500);
            return;
        }

        setTimeout(() => {
            browserHistory.push(`/settings/integrations/${params.id}`);
        }, 500);
    };

    const saveName = ()=>{
        firebaseEvents.addChallenge('changeMemberName');
        firebaseEvents.updateMemberData(
            accountId,
            memberId,
            { displayName: (displayName==='')?intl.formatMessage({id:'Untitled'}):displayName }
        );
        if(displayName===''){
            setDisplayName(intl.formatMessage({id:'Untitled'}));
        }
    };

    const inviteUser = () => {
        if(validateEmail(email)){
            firebaseEvents.addChallenge('inviteMemberName');

            firebaseEvents.sendMemberInvite(
                accountId,
                memberId,
                memberData,
                email,
                memberDisplayName,
                userName,
                intl
            );
        } else {
            toastr.error(intl.formatMessage({id:'The email address is not correct!'}));
        }
    };

    const getUserEmailOrInviteBtn = () => {
        if(memberType === 'resourceOnly' && memberEmail) {
            return <div className={classnames('userEmail', { resourceOnly: !memberUid })}>Email: <span>{memberEmail}</span></div>;
        }

        else if (memberEmail && !memberUid) {
            return (
                <>
                    <div className="userEmail">Email: {memberEmail}</div>
                    <Button
                        variant="contained"
                        className="sendInviteBtn resend"
                        color="secondary"
                        onClick={() => {
                            firebaseEvents.resendMemberInvite(
                                memberEmail,
                                memberDisplayName,
                                accountName,
                                intl
                            );
                        }}
                    >{intl.formatMessage({id:'Resend invite'})}</Button>
                </>
            );
        }

        return <div className="inviteTeamMemberContainer">
            <TextField
                className="email-field"
                type="text"
                label={intl.formatMessage({id:'Email'})}
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
            />
            <Button
                variant="contained"
                className="sendInviteBtn"
                color="secondary"
                onClick={inviteUser}
            >{intl.formatMessage({id:'Send invite'})}</Button>
        </div>;
    };

    const changeTab = (e, val) => {
        setActiveTab(val);
    };

    if(!memberData) return null;

    return (
        <CustomSideBar onClose={handleClose} className={'EditTeamMember'}>
            <div data-sidebar='preventClose'>
                <div className="flex">
                    <div className="memberAvatar">
                        {getUserAvatar(memberId, false, memberAvatar)}
                    </div>
                    <div className='memberInput'>
                        <TextField
                            autoFocus={memberDisplayName === 'New Member'}
                            margin="normal"
                            type="text"
                            className="nameInput"
                            label={intl.formatMessage({id:'Name'})}
                            value={displayName}
                            onChange={(e)=>{ setDisplayName(e.target.value); }}
                            onBlur={saveName}
                        />
                        {getUserEmailOrInviteBtn()}
                    </div>
                </div>

                <div className="memberType">
                    <small>Member Type</small>
                    <MemberTypeDropdown memberId={memberId} fromViewMode={'EditTeamMember'}/>
                </div>

                <AppBar position="static" color="default">
                    <Tabs
                        value={activeTab}
                        onChange={changeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                    >
                        <Tab disabled={!tabByMemberType[memberType]?.includes('Skills')} value={0} label={intl.formatMessage({id:'Skills'})} />
                        <Tab disabled={!tabByMemberType[memberType]?.includes('Availability')} value={1} label={intl.formatMessage({id:'Availability'})} />
                        <Tab disabled={!tabByMemberType[memberType]?.includes('Extra hours')} value={2} label={intl.formatMessage({id:'Extra hours'})} />
                        <Tab disabled={!tabByMemberType[memberType]?.includes('Days off')} value={3} label={intl.formatMessage({id:'Days off'})} />
                        {accountCostBudgetSetting && <Tab disabled={!tabByMemberType[memberType]?.includes('costs')} value={4} label={intl.formatMessage({id:'costs'})} />}
                        <Tab disabled={!tabByMemberType[memberType]?.includes('Permissions')} value={5} label={intl.formatMessage({id:'Permissions'})} />
                    </Tabs>
                </AppBar>
                {activeTab === 0 && <Skills />}
                {activeTab === 1 && <Availability calendarDisabled={memberType === 'userOnly'}/>}
                {activeTab === 2 && <ExtraHours />}
                {activeTab === 3 && <DaysOff />}
                {activeTab === 4 && accountCostBudgetSetting && <BudgetManagementTab />}
                {activeTab === 5 && <Permissions memberId={memberId}/>}
            </div>
            {children}
        </CustomSideBar>
    );
};

export default memo(withRouter(withCustomErrorBoundary(EditTeamMember)));