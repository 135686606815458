import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import classnames from 'clsx';
import OverViewCard from '../OverViewCard';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const parseMoment = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
};

const EventsOverview = ({ stateTasks, workspaceSelected, childsParents, userTimezone, datesRange, usersAllEvents }) => {
    const accountEvents = useSelector(state => state?.app?.account?.events);
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);

    const overviewData = React.useMemo(() => {
        const today = moment().tz(userTimezone);
        let pastEvent = 0;
        let futureEvent = 0;
        let totalEvents = 0;
        const endDate = moment(datesRange.endDate);
        // if on root lvl I have to get all of the calendar integrated events for each user
        if (workspaceSelected === 'root') {
            const users = Object.values(accountTeamMembers);
            const allEvents = _.flatten(
                users.map(user =>
                    usersAllEvents[user.userId]
                        ? usersAllEvents[user.userId].map(event => ({ ...event, userId: user.userId }))
                        : [],
                ),
            );
            const filterAllEvents = allEvents.filter(event =>
                moment.tz(event.start, 'X', userTimezone).isBetween(moment().tz(userTimezone), endDate, 'day'),
            );
            filterAllEvents.forEach(event => {
                let timeSpent = event.allDay
                    ? 8
                    : (moment.unix(event.end).diff(moment.unix(event.start), 'minutes') | 0) / 60;
                timeSpent = Math.floor(timeSpent * 100) / 100;
                // is the event beetween the date range so after today and before the endDate
                // if there is no recurrency  --- will go normal way the event is valid our will be the startDate of Event.
                if (moment.tz(event.start, 'X', userTimezone).isAfter(today)) {
                    totalEvents++;
                    futureEvent += timeSpent;
                }
                if (moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                    pastEvent += timeSpent;
                    totalEvents++;
                }
            });
        }

        if (stateTasks && accountEvents) {
            const filteredEvents =
                workspaceSelected === 'root'
                    ? Object.keys(accountEvents)
                    : Object.keys(accountEvents).filter(
                        eventId =>
                            accountEvents[eventId].parent === workspaceSelected ||
                              _.get(childsParents, workspaceSelected, [workspaceSelected]).includes(
                                  accountEvents[eventId].parent,
                              ),
                    );

            filteredEvents.forEach(id => {
                const event = accountEvents[id];
                if (!event) {
                    return;
                }
                const timeSpent = event.allDay ? 8 : parseFloat((event.end - event.start) / 60 / 60);
                const timeSpentWithParticipants = timeSpent * (event.participants?.length || 0);
                // is the event beetween the date range so after today and before the endDate
                // if there is no recurrency  --- will go normal way the event is valid our will be the startDate of Event.
                if (!event.recurrency && moment.tz(event.start, 'X', userTimezone).isAfter(today)) {
                    totalEvents++;
                    futureEvent += timeSpentWithParticipants;
                }
                if (!event.recurrency && moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                    pastEvent += timeSpent;
                    totalEvents++;
                }
                if (event.recurrency && moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                    let lastEvent = moment.tz(event.start, 'X', userTimezone);
                    while (lastEvent.isBefore(endDate)) {
                        const endOfWeek = moment(lastEvent).endOf('week');
                        Object.keys(event.recurrency).forEach(weekday => {
                            if (event.recurrency[weekday] && parseMoment[weekday]) {
                                const expecetedDate = moment(endOfWeek).day(parseMoment[weekday]);
                                totalEvents++;
                                if (expecetedDate.isAfter(today) && lastEvent.isAfter(today)) {
                                    futureEvent += timeSpentWithParticipants;
                                }
                                if (expecetedDate.isBefore(today)) {
                                    pastEvent += timeSpent;
                                }
                            }
                        });
                        lastEvent.add(parseInt(event.recurrency.everyXweeks), 'weeks');
                    }
                }
            });
        }
        return {
            future: futureEvent,
            past: pastEvent,
            totalEvents,
        };
    }, [stateTasks, workspaceSelected, datesRange, accountEvents, usersAllEvents]);
    const path = window.location.pathname.split('/').slice(0, 3).join('/');
    const renderText = data => (isNaN(data) ? 'N/A' : `${parseFloat(data).toFixed(2)}h`);
    return (
        <div className={classnames('bigNumbers')}>
            <OverViewCard title="Total Event Hours" value={renderText(overviewData.future + overviewData.past)} />
            <OverViewCard title={'Past Event Hours'} value={renderText(overviewData.past)} />
            <OverViewCard title={'Future Event Hours'} value={renderText(overviewData.future)} />
            <OverViewCard
                title={'Average event duration'}
                value={renderText(
                    parseFloat((overviewData.future + overviewData.past) / overviewData.totalEvents).toFixed(2),
                )}
            />
            <div className="overview-report-link">
                <Button
                    component={Link}
                    size="small"
                    variant="outlined"
                    color="primary"
                    key="events"
                    to={`${path}/events`}
                >
                    Go to <b>Events</b> report
                </Button>
            </div>
        </div>
    );
};

EventsOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    datesRange: Proptypes.object.isRequired,
    childsParents: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
};

export default EventsOverview;
