import React, { useState, useEffect, memo } from 'react';
import { getAccountId } from '../../utils/selectors/account';
import { getNewTree, getOrderedProjectList, getProjectsListsWithParentAndChildIds } from './utils';
import { getItems } from '../../utils/services/syncApp';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import _ from 'underscore';
import useDebounce from '../../utils/hooks/useDebounce';
import ProjectsTreeViewFull from './components/TreeViewFull/ProjectsTreeViewFull';
import ProjectsTreeViewByStep from './components/TreeViewByStep/ProjectsTreeViewByStep';
import SelectProjectDialogTitle from './components/SelectProjectDialogTitle';
import SelectProjectActionButtons from './components/SelectProjectActionButtons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { toastr } from 'react-redux-toastr';
import { CircularProgress } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { TREE_LEVELS_BY_TOOL } from './constants';

const SelectProject = ({ open, onClose, onSelect, syncAppId, currentProjects = [], currentSyncList = [], ignoreSubtasksOption = false, toolName }) => {
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [blockExpandMore, setBlockExpandMore] = useState(false);
    const [selectByStep, setSelectByStep] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [treeStep, setTreeStep] = useState('');
    const [syncList, setSyncList] = useState([]);
    const [parentsSwitched, setParentsSwitched] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [orderedProjectList, setOrderedProjectList] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const accountId = useSelector(getAccountId);

    useEffect(() => {
        if(!open) {
            setCheckedItems([]);
            return;
        }

        setStep(1);
        setSearchValue('');

        (async () => {
            try {
                setIsLoading(true);
                if(!accountId || !syncAppId) return;

                const { data: { data: dataItems, maxRequestLimitReached } } = await getItems({ syncAppId, accountId });
                setSelectByStep(!!maxRequestLimitReached);
                setTreeStep(maxRequestLimitReached ? 'workspaces' : '');
                handleSetProjectList(dataItems);
            } catch (error) {
                toastr.error('Something went wrong when trying to retrieve your projects.');
                onClose();
            } finally {
                setIsLoading(false);
            }
        })();
    }, [open, accountId, syncAppId, handleSetProjectList]);

    useEffect(() => {
        if(step===2) {
            handleCreateSyncList();
            return;
        }
        setSyncList([]);
    }, [step, handleCreateSyncList]);

    const handleSubmit = () => { onSelect(ignoreSubtasksOption ? getSyncList() : syncList); };

    const handleSearch = React.useCallback(({ target: { value } }) => setSearchValue(value), []);

    const handleNextStep = React.useCallback(() => setStep(2), []);

    const handleSetFirstStep = React.useCallback(() => setStep(1), []);

    useDebounce(() => setDebouncedSearchValue(searchValue), 500, [searchValue]);

    const getSyncList = () => {
        return checkedItems.reduce((acc, itemId) => {
            const item = orderedProjectList.find(el=>el.id===itemId);

            if(_.isEmpty(item.childs)) {
                acc.push({
                    selectedItem: itemId,
                    name: item.name,
                    importSubtasks: (currentSyncList.find(el=>el.selectedItem===itemId)?.importSubtasks || false),
                    type: item.type || null,
                    parents: item.parentsBefore ?
                        item.parentsBefore
                            .filter(id=>id) // filter root items (parent is null)
                            .map(parentId=>({
                                id: parentId,
                                name: orderedProjectList.find(el=>el.id ===parentId)?.name,
                                type: orderedProjectList.find(el=>el.id ===parentId)?.type || null,
                            }))
                        : [],
                });
            }

            return acc;
        }, []);
    };

    const handleCreateSyncList = () => {
        setSyncList(getSyncList());
    };

    const handleCheckItem = React.useCallback(({ item, parent }) => {
        const siblings = parent && parent.childIds.filter(id=>id!==item.id);
        const hasNestedChildrens = item.nestedChildrenIds && !_.isEmpty(item.nestedChildrenIds);

        // Checking
        if (!checkedItems.includes(item.id)) {

            if(siblings && siblings.every(id => [...checkedItems, item.id].includes(id))) {
                setCheckedItems(_.uniq([...checkedItems, parent.id, ...parent.nestedChildrenIds]));
                return;
            }

            if(hasNestedChildrens) {
                setCheckedItems(_.uniq([...checkedItems, item.id, ...item.nestedChildrenIds]));
                return;
            }

            setCheckedItems(_.uniq([...checkedItems, item.id]));
            return;
        }

        // Unchecking
        if(hasNestedChildrens) {

            if(!parent) {
                setCheckedItems(checkedItems.filter(id=>id!==item.id && !item.nestedChildrenIds.includes(id)));
                return;
            }

            setCheckedItems(checkedItems.filter(id=>id!==item.id && !item.parentsBefore.includes(id) && !item.nestedChildrenIds.includes(id)));
            return;
        }

        setCheckedItems(checkedItems.filter(id=>id !== item.id && !item.parentsBefore.includes(id)));
    }, [checkedItems]);

    const handleToggleImportSubtasks = React.useCallback((itemData) => {

        if(!_.isEmpty(itemData?.nestedChildrenIds)) {

            const nestedChildrenUpdates = itemData.nestedChildrenIds.reduce((acc, childId) => {
                const child = syncList.find(item=>item.selectedItem===childId);

                if(child) {
                    child.importSubtasks = !(parentsSwitched.find(id=>itemData.id===id));
                    acc.push(child);
                }
                return acc;
            }, []);

            const updatedList = _.uniq([
                ...syncList,
                ...nestedChildrenUpdates
            ]);

            if(parentsSwitched.find(id=>itemData.id===id)) {
                setParentsSwitched(parentsSwitched.filter(id=>id!==itemData.id));
                setSyncList(updatedList);
                return;
            }

            setParentsSwitched([...parentsSwitched, itemData.id]);
            setSyncList(updatedList);
            return;
        }

        const item = syncList.find(item=>item.selectedItem===itemData.id);
        item.importSubtasks = !item.importSubtasks;

        const updatedList = _.uniq([
            ...syncList,
            item
        ]);

        setSyncList(updatedList);

    }, [syncList]);

    const handleSetProjectList = React.useCallback((itemsData) => {
        const projectsTreeList = getProjectsListsWithParentAndChildIds({ currentList: itemsData });
        const orderedProjectList = getOrderedProjectList(projectsTreeList);
        setProjectList(projectsTreeList);
        setOrderedProjectList(orderedProjectList);
        setBlockExpandMore(false);
    }, []);

    const handleExpandTreeLevel = React.useCallback(async (itemToExpand) => {
        const allowExpand = _.get(TREE_LEVELS_BY_TOOL, [toolName, 'workspace', itemToExpand.type], null);
        const childrenType = _.get(TREE_LEVELS_BY_TOOL, [toolName, 'workspace', itemToExpand.type, 'childrenType'], null);

        if(allowExpand) {
            setBlockExpandMore(true);
            const { data: { childs } } = await getItems({ syncAppId, accountId, dataType: childrenType, itemId: itemToExpand.id });
            const newTree = getNewTree({ currentList: projectList, itemIdToExpand: itemToExpand.id, itemChilds: childs });
            handleSetProjectList(newTree);
        }
    }, [handleSetProjectList, syncAppId, accountId, projectList]);

    const treeDataLoadInfo = React.useMemo(() => {
        return orderedProjectList.filter(item => item.type === 'workspace').reduce((acc, workspace) => {
            return {
                ...acc,
                [workspace.id]: {
                    allChildsLoaded: workspace.childs.every(child => child.childs && child.childs.length),
                    childs: workspace.childs.reduce((childAcc, child) => {
                        return {
                            ...childAcc,
                            [child.id]: {
                                isLoaded: Boolean(child.childs && child.childs.length)
                            }
                        };
                    }, {})
                }
            };
        }, {});
    },[orderedProjectList]);

    return <>
        <Dialog maxWidth={'lg'} className="SelectProject_Modal" onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>

            <MuiDialogTitle className="title" disableTypography>
                <SelectProjectDialogTitle step={step} onClose={onClose}/>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>

            <MuiDialogContent className="content" dividers>
                {isLoading ? (
                    <div className={'container__loader'}>
                        <CircularProgress />
                    </div>
                ) :
                    <>
                        <div className="input-search-container">
                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment className="search-icon" position="start"><SearchIcon /></InputAdornment>,
                                }}
                                placeholder="Search..."
                                className="input-search"
                                fullWidth
                                value={searchValue}
                                onChange={handleSearch}
                            />
                        </div>

                        {!_.isEmpty(projectList) && (
                            <div className="Project-list-container" style={{paddingBottom: `${step*1.8}rem`}}>
                                {selectByStep ? (
                                    <ProjectsTreeViewByStep
                                        blockExpandMore={blockExpandMore}
                                        treeDataLoadInfo={treeDataLoadInfo}
                                        treeStep={treeStep}
                                        currentList={projectList}
                                        handleExpandTreeLevel={handleExpandTreeLevel}
                                        handleCheckItem={handleCheckItem}
                                        handleToggleImportSubtasks={handleToggleImportSubtasks}
                                        checkedItems={checkedItems}
                                        orderedProjectList={orderedProjectList}
                                        step={step}
                                        syncList={_.isEmpty(syncList) ? currentSyncList : syncList}
                                        searchValue={debouncedSearchValue}
                                        prevSyncList={currentSyncList}
                                        toolName={toolName}
                                    />
                                ) : (
                                    <ProjectsTreeViewFull
                                        currentList={projectList}
                                        handleCheckItem={handleCheckItem}
                                        handleToggleImportSubtasks={handleToggleImportSubtasks}
                                        checkedItems={checkedItems}
                                        orderedProjectList={orderedProjectList}
                                        step={step}
                                        syncList={_.isEmpty(syncList) ? currentSyncList : syncList}
                                        searchValue={debouncedSearchValue}
                                        prevSyncList={currentSyncList}
                                    />
                                )}
                            </div>
                        )}
                    </>
                }
            </MuiDialogContent>

            <MuiDialogActions className='actions'>
                <SelectProjectActionButtons
                    step={ignoreSubtasksOption ? 2 : step}
                    handleNextStep={handleNextStep}
                    handleSetFirstStep={handleSetFirstStep}
                    handleSubmit={handleSubmit}
                    onClose={onClose}
                    nextDisabled={_.isEmpty(checkedItems) || _.isEqual(checkedItems, currentProjects)}
                />
            </MuiDialogActions>
        </Dialog >
    </>;
};

SelectProject.propTypes = {
    open: Proptypes.bool.isRequired,
    syncAppId: Proptypes.string,
    onClose: Proptypes.func.isRequired,
    onSelect: Proptypes.func.isRequired,
    currentSyncList: Proptypes.array.isRequired,
    currentProjects: Proptypes.array,
    toolName: Proptypes.string,
};

export default memo(withCustomErrorBoundary(SelectProject));