import * as app from './AppConstants';

import { getItemPermissions } from '../../utils/utils';
import * as firebaseEvents from '../../utils/firebaseEvents';
import engine from 'workerize-loader?inline!./../../utils/engine';
import Dexie from 'dexie';
import { getUserId } from '../../utils/selectors/user';
import { getAccountBasePermissions, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';

var engineInstances = [];


export function changeLocale(locale) {
    return {
        type: app.CHANGE_LOCALE,
        locale,
    };
}

export function setAccountSettings(data) {
    return {
        type: app.SET_ACCOUNT_SETTINGS,
        data,
    };
}

export const setAccountFilters = () => (dispatch, getState) => {
    const { filters, account } = getState().app;
    if (filters === '{"andor":"and"}' && account?.settings?.id) {
        const localFilters = localStorage.getItem('filters-' + account.settings.id);

        if (localFilters === '{"andor":"and"}') {
            return dispatch({
                type: app.SET_FILTERS,
                data: filters,
            });
        }

        return dispatch({
            type: app.SET_FILTERS,
            data: localFilters,
        });
    }
    return dispatch({
        type: app.SET_FILTERS,
        data: filters,
    });
};

export const addPermissionsToTasks = (withTasks) => (dispatch, getState) => {
    const state = getState();
    const userId = getUserId(state);
    const accountTasks = getAccountTasks(state);
    const basePermissions = getAccountBasePermissions(state);
    const accountUsers = getAccountUsers(state);

    let newTasks = withTasks || accountTasks;
    if (Object.keys(newTasks).length && state.app.user?.data?.userId && Object.keys(state.app.account.users).length && state.app.account?.settings?.basePermissions) {
        Object.keys(newTasks).forEach(el => {
            const perms = getItemPermissions(
                userId,
                el,
                accountTasks[el]?.permissions,
                accountTasks,
                accountUsers[userId]?.permissions?.admin || false,
                basePermissions
            );

            newTasks[el].canAdmin = accountTasks[el]?.canAdmin || perms?.canAdmin;
            newTasks[el].canManage = accountTasks[el]?.canManage || perms?.canManage;
            newTasks[el].canWork = accountTasks[el]?.canWork || perms?.canWork;
            newTasks[el].canView = accountTasks[el]?.canView || perms?.canView;
            newTasks[el].canWorkersCreateTask = accountTasks[el]?.canWorkersCreateTask || perms?.canWorkersCreateTask;
            newTasks[el].canWorkersChangeEffort = accountTasks[el]?.canWorkersChangeEffort || perms?.canWorkersChangeEffort;

            if (accountTasks[el]?.estimations) {
                newTasks[el].estimations = accountTasks[el].estimations;
            }
        });
        dispatch({
            type: app.SET_TASKS,
            data: newTasks,
        });
    }
};

export function setSkills(data) {
    return {
        type: app.SET_SKILLS,
        data,
    };
}

export function setEvents(data) {
    return {
        type: app.SET_EVENTS,
        data,
    };
}

export function setUsers(data) {
    return {
        type: app.SET_USERS,
        data,
    };
}
export function setDaysOff(data) {
    return {
        type: app.SET_DAYS_OFF,
        data,
    };
}

export function setAccountPropertyData(field, data) {
    return {
        type: app.SET_ACCOUNT_PROPERTY_DATA,
        field,
        data,
    };
}

export function setTasksEstimations(data) {
    return {
        type: app.SET_TASKS_ESTIMATIONS,
        data,
    };
}

export function gapiLoad(isLoaded) {
    return {
        type: app.GAPI_LOADED,
        status: isLoaded,
    };
}

export function connectionState(state) {
    return {
        type: app.CONNECTED,
        state,
    };
}

export function defineUser(user) {
    return {
        type: app.DEFINE_USER,
        user: user,
    };
}

export function defineUserData(data) {
    return {
        type: app.DEFINE_USER_DATA,
        data: data,
    };
}

export function defineUserFirstCalendarConnected(data) {
    return {
        type: app.DEFINE_USER_FIRST_CALENDAR_CONNECTED,
        data: data,
    };
}

export function defineUserAccounts(data) {
    return {
        type: app.DEFINE_USER_ACCOUNTS,
        accounts: data,
    };
}

export function defineUserChallenges(data) {
    return {
        type: app.DEFINE_USER_CHALLENGES,
        data: data,
    };
}

export function defineUserFirstStepsConfigs(data) {
    return {
        type: app.DEFINE_USER_FIRST_STEPS,
        data: data,
    };
}

export function defineUserPopupGuideConfigs(data) {
    return {
        type: app.DEFINE_USER_POPUP_GUIDE_CONFIG,
        data: data,
    };
}

/**
 * To support the current development it's we are updating the state with the new data and then running the database updates.
 * @param {*} newAccountData
 * @param {*} oldAccountData
 * @param {*} dbUpdates
 * @returns
 */
export const singleEngineRun = () => (dispatch, getState) => {
    const {
        account: {
            daysoff,
            skills,
            users,
            tasks,
            events,
            settings: { basePermissions },
        },
        loaders,
        user,
    } = getState().app;
    const isAllDataLoaded = Object.keys(loaders).find(el => loaders[el]);
    if (!isAllDataLoaded && daysoff && users && tasks && user?.data) {
        dispatch({
            type: app.CALCULATING,
            data: true,
        });
        engineInstances.forEach(ins => {
            ins.terminate();
        });
        const newInstance = engine();
        engineInstances.push(newInstance);
        newInstance
            .process(
                {
                    users: users,
                    skills: skills,
                    daysoff: daysoff,
                    events: events,
                    tasks: tasks,
                    basePermissions: basePermissions,
                },
                null,
                user.data.userId,
                true,
            )
            .then(result => {

                dispatch({
                    type: app.COMBINED_ENGINE_UPDATE,
                    data: result,
                });


                // need to run the save for stats and alerts, to support the leggacy
                firebaseEvents.saveStats(result);
                firebaseEvents.alertsHandlerCaller(result);

                dispatch({
                    type: app.CALCULATING,
                    data: false,
                });
            });
    }
};

export function combinedUpdate({tasks,users,orderedTaskList,parentChildIds}){
    return dispatch => {
        dispatch({
            type: app.COMBINED_ENGINE_UPDATE,
            data: {tasks,users,orderedTaskList,parentChildIds}
        });
    };
}


export function cleanDbupdates() {
    return {
        type: app.CLEAN_DBUPDATES,
    };
}
export function showLoader() {
    return {
        type: app.SHOW_LOADER,
    };
}

export function hideLoader() {
    return {
        type: app.HIDE_LOADER,
    };
}

export function updateFilters(filters, accountId) {
    localStorage.setItem('filters-' + accountId, JSON.stringify(filters));
    return dispatch => {
        dispatch(showLoader());
        setTimeout(() => {
            dispatch({
                type: app.UPDATE_FILTERS,
                filters: JSON.stringify(filters),
            });
            dispatch(hideLoader());
        }, 200);
    };
}

export function defineDiscountCode(code) {
    return {
        type: app.DEFINE_DISCOUNT_CODE,
        code: code,
    };
}


export function removeDiscountCode() {
    return {
        type: app.REMOVE_DISCOUNT_CODE,
    };
}

export function setWorkManager(data) {
    return {
        type: app.SET_WORK_MANAGER,
        data,
    };
}

export function setActiveUserTime(data) {
    return {
        type: app.SET_ACTIVE_USER_TIME,
        data,
    };
}

export function quickSearch(data) {
    return dispatch => {
        dispatch(showLoader());
        setTimeout(() => {
            dispatch({
                type: app.QUICK_SEARCH,
                data,
            });
            dispatch(hideLoader());
        }, 200);
    };
}

export function defineOrderedTaskList(data) {
    return {
        type: app.DEFINE_ORDERED_TASKLIST,
        data,
    };
}

export function defineParentChildIds(data) {
    return {
        type: app.DEFINE_PARENTCHILDIDS,
        data,
    };
}

export function defineClosedSortables(data, accountId) {
    var db = new Dexie('planlessStore');
    db.version(1).stores({
        planlessStore: 'key',
    });
    db.planlessStore.put({ key: 'closedSortables-' + accountId, data });

    return {
        type: app.DEFINE_CLOSEDSORTABLES,
        data,
    };
}

export function setTaskDescription(taskId, value) {
    return {
        type: app.SET_TASK_DESCRIPTION,
        taskId,
        value,
    };
}

export function unsetTaskDescription() {
    return {
        type: app.UNSET_TASK_DESCRIPTION,
    };
}

export function openReccurentTaskModal({ taskId, updatedField }) {
    return {
        type: app.UPDATE_RECURRENT_TASK_MODAL,
        payload: {
            isOpen: true,
            data: {
                taskId,
                updatedField,
            },
        },
    };
}

export function closeReccurentTaskModal() {
    return {
        type: app.CLOSE_RECURRENT_TASK_MODAL,
        payload: {
            isOpen: false,
            data: {
                taskId: null,
                updatedField: null,
            },
        },
    };
}

export function resetData() {
    return {
        type: app.LOGOUT,
    };
}

export function resetDataSettings() {
    return {
        type: app.RESET_SETTINGS,
    };
}

export function openChangeTaskLocationModal({ taskId, parent }) {
    return {
        type: app.OPEN_CHANGE_TASK_LOCATION_MODAL,
        payload: {
            isOpen: true,
            data: {
                taskId,
                parent,
            },
        },
    };
}

export function closeChangeTaskLocationModal() {
    return {
        type: app.CLOSE_CHANGE_TASK_LOCATION_MODAL,
        payload: {
            isOpen: false,
            data: {
                taskId: null,
                parent: null
            },
        },
    };
}

export function openSelectDependenciesModal({ taskId, dependencies }) {
    return {
        type: app.OPEN_SELECT_DEPENDENCIES_MODAL,
        payload: {
            isOpen: true,
            data: {
                taskId,
                dependencies,
            },
        },
    };
}

export function closeSelectDependenciesModal() {
    return {
        type: app.CLOSE_SELECT_DEPENDENCIES_MODAL,
        payload: {
            isOpen: false,
            data: {
                taskId: null,
                dependencies: null
            },
        },
    };
}

export function setLimitViewTo({accountId, value}) {
    if(value){
        localStorage.setItem('limitViewTo-' + accountId, value);
    }
    else {
        localStorage.removeItem('limitViewTo-' + accountId);
    }
    return {
        type: app.SET_LIMITVIEWTO,
        value
    };
}

export function showFilters(value) {
    return {
        type: app.SHOW_FILTERS,
        value
    };
}

export function setCalendarMembersToShow(value) {
    return {
        type: app.SET_CALENDAR_MEMBERS_TO_SHOW,
        value
    };
}

export function setTimelineRange(value) {
    return {
        type: app.SET_TIMELINE_RANGE,
        value
    };
}

export function updateSingleTask({taskId, data}) {
    return {
        type: app.UPDATE_SINGLE_TASK,
        taskId,
        data
    };
}

export function updateSingleUser({userId, data}) {
    return {
        type: app.UPDATE_SINGLE_USER,
        userId,
        data
    };
}

export function setAccountDataSyncApps(data) {
    return {
        type: app.SET_ACCOUNTDATA_SYNC_APPS,
        data
    };
}

export function setTimelineColumns({cols}) {
    localStorage.setItem('timelineColumns', cols.join(','));
    return {
        type: app.SET_TIMELINE_COLUMNS,
        data: cols
    };
}

export function setTasksLoaded() {
    return {
        type: app.SET_TASKS_LOADED
    };
}

export function selectSourceStatusModal(data) {
    return {
        type: app.SELECT_SOURCE_STATUS_MODAL,
        data
    };
}