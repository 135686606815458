import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import useInternalization from '../../utils/hooks/useInternalization';

const MultiDeadlineDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [dueDate, setDueDate] = useState(null);
    const userDateFormat = useSelector(getUserDateformat);
    const userTimeFormat = useSelector(getUserTimeformat);
    const dateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
    const { t } = useInternalization();

    const handleConfirm =() => {
        firebaseEvents.multiDuedateChange(dueDate, selectedItems).then(() => {
            setSelectedLines();
            setDueDate(null);
        });

        showMultiDetails();
    };

    const handleChange = (e) => {
        setDueDate(e);
    };

    const handleNo = () => {
        showMultiDetails();
    };

    return  (
        <div id="multiDeadlineDetails">
            <label>Set tasks due date to</label>
            <DateTimePicker
                variant="inline"
                className="multiDeadlinePicker"
                format={dateTimeFormat}
                margin="normal"
                placeholder={t('Select a date')}
                value={dueDate}
                onChange={handleChange}
                autoOk
                fullWidth
                PopoverProps={{ className: 'multiDatePicker' }}
            />
            <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleConfirm}
            >
            Apply
            </Button>
            <Button variant="outlined" size="small" onClick={handleNo}>
            Cancel
            </Button>
        </div>
    );
};

export default MultiDeadlineDetails;