import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Unautorized from '../Unautorized/Unautorized';
import Skeleton from '@material-ui/lab/Skeleton';

import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import { CLOUD_FUNCTIONS } from '../../utils/constants';
import { getAccountId, getAccountSkills, getAccountTags, getAccountTaskCanView, getAccountUsers } from '../../utils/selectors/account';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskHistory = ({router, location}) => {
    const intl = useIntl();

    const accountId = useSelector(getAccountId);
    const users = useSelector(getAccountUsers);
    const skills = useSelector(getAccountSkills);
    const tags = useSelector(getAccountTags);
    const dateFormat = useSelector(getUserDateformat);
    const timeFormat = useSelector(getUserTimeformat);
    const canView = useSelector(getAccountTaskCanView(router.params.uid));

    const [content, setContent] = useState(null);
    const [loadingContent, setLoadingContent] = useState(false);

    const parseHistory = (data) => {
        
        if(data.length){
            var list = [];
            
            data.reverse();

            data.forEach((line)=>{
                var message, userName = 'Unknown', userNameB = 'Unknown', userNameA = 'Unknown', skillB = 'Not defined', skillA = 'Not defined';

                if(users){
                    if(users[line.uId] && users[line.uId].displayName){
                        userName = users[line.uId].displayName;
                    }
                    if(users[line.b] && users[line.b].displayName){
                        userNameB = users[line.b].displayName;
                    }
                    if(users[line.a] && users[line.a].displayName){
                        userNameA = users[line.a].displayName;
                    }
                }

                if(skills){
                    if(skills[line.b] && skills[line.b].name){
                        skillB = skills[line.b].name;
                    }
                    if(skills[line.a] && skills[line.a].name){
                        skillA = skills[line.a].name;
                    }
                }

                switch(line.f){
                case 'forcedUser':
                    if(!line.b){
                        message = <dt><em>{userName}</em> <b>forced the assignment</b> to "{userNameA}".</dt>;
                    }
                    else {
                        message = <dt><em>{userName}</em> removed "{userNameB}" as <b>forced assignee</b>.</dt>;
                    }
                    break;

                case 'description':
                    message = <dt><em>{userName}</em> changed the task <b>description</b>.</dt>;
                    break;

                case 'dependencies':
                    message = <dt><em>{userName}</em> changed the task <b>dependencies</b>.</dt>;
                    break;
                case 'costBudgetSettings': {
                    const beforeValue = _.get(line.b, 'value', 0);
                    const beforePeriodicity = _.get(line.b, 'periodicity', 'none');
                    const beforeType = _.get(line.b, 'type', 'type');
                    const afterValue = _.get(line.a, 'value', 0);
                    const afterPeriodicity = _.get(line.a, 'periodicity', 'none');
                    const afterType = _.get(line.a, 'type', 'type');

                    message = (<dt><em>{userName}</em> changed the task budget from {beforeValue} to {afterValue}, 
                    the periodicity from {beforePeriodicity} to {afterPeriodicity} and the type from {beforeType} to {afterType}.</dt>);
                    break;
                }

                case 'tags':
                    var beforeList = [], afterList = [];
                    line.b = line.b.split(',');
                    line.a = line.a.split(',');
                    
                    if(tags){
                        line.b.forEach((item)=>{
                            if(tags[item]){
                                beforeList.push(tags[item].title);
                            }
                        });
                        line.a.forEach((item)=>{
                            if(tags[item]){
                                afterList.push(tags[item].title);
                            }
                        });
                    }
                    if(beforeList.length === 0){
                        beforeList = '"No tags"';
                    }
                    else {
                        beforeList = '"' + beforeList.join(',') + '"';
                    }
                    if(afterList.length === 0){
                        afterList = '"No tags"';
                    }
                    else {
                        afterList = '"' + afterList.join(',') + '"';
                    }
                    message = <dt><em>{userName}</em> changed the task <b>tags</b> from {beforeList} to {afterList}.</dt>;
                    break;
                    
                case 'minEffort':
                    if(line.b !== '' && line.a !== '' && !isNaN(line.b) && !isNaN(line.a)){
                        message = <dt><em>{userName}</em> changed the task <b>minimum effort</b> from {parseFloat(line.b)} to {parseFloat(line.a)} hours.</dt>;
                    }
                    break;
                    
                case 'maxEffort':
                    if(line.b !== '' && line.a !== '' && !isNaN(line.b) && !isNaN(line.a)){
                        message = <dt><em>{userName}</em> changed the task <b>maximum effort</b> from {parseFloat(line.b)} to {parseFloat(line.a)} hours.</dt>;
                    }
                    break;

                case 'created':
                    message = <dt><em>{userName}</em> <b>created</b> task.</dt>;
                    break;

                case 'skill':
                    message = <dt><em>{userName}</em> changed the task <b>skill</b> from "{skillB}" to "{skillA}".</dt>;
                    break;

                case 'title':
                    message = <dt><em>{userName}</em> changed the task <b>title</b> from "{line.b}" to "{line.a}".</dt>;
                    break;

                case 'priority':
                    var before = '', after = '';
                    if(line.b === '1'){ before = 'Medium'; }
                    else if(line.b === '2'){ before = 'High'; }
                    else if(line.b === '3'){ before = 'Urgent'; }
                    else { before = 'Normal'; }
                    
                    if(line.a === '1'){ after = 'Medium'; }
                    else if(line.a === '2'){ after = 'High'; }
                    else if(line.a === '3'){ after = 'Urgent'; }
                    else { after = 'Normal'; }

                    message = <dt><em>{userName}</em> changed the task <b>priority</b> from "{before}" to "{after}".</dt>;
                    break;

                case 'delay':
                    if(line.b === ''){
                        message = <dt><em>{userName}</em> defined the task <b>Wait until date</b> to the {moment(line.a, 'YYYY-MM-DD').format('Do MMMM YYYY')}.</dt>;
                    }
                    else if(line.a === ''){
                        message = <dt><em>{userName}</em> removed the task <b>Wait until date</b>.</dt>;
                    }
                    else {
                        message = <dt><em>{userName}</em> changed the task <b>Wait until date</b> from the {moment(line.b, 'YYYY-MM-DD').format('Do MMMM YYYY')} to the {moment(line.a, 'YYYY-MM-DD').format('Do MMMM YYYY')}.</dt>;
                    }
                    break;

                case 'deadline':
                    if(line.b === ''){
                        message = <dt><em>{userName}</em> defined the task <b>deadline</b> to the {moment(line.a, 'YYYY-MM-DD').format('Do MMMM YYYY')}.</dt>;
                    }
                    else if(line.a === ''){
                        message = <dt><em>{userName}</em> removed the task <b>deadline</b>.</dt>;
                    }
                    else {
                        message = <dt><em>{userName}</em> changed the task <b>deadline</b> from the {moment(line.b, 'YYYY-MM-DD').format('Do MMMM YYYY')} to the {moment(line.a, 'YYYY-MM-DD').format('Do MMMM YYYY')}.</dt>;
                    }
                    break;
                }

                if(message){

                    list.push(
                        <li key={line.timestamp.value}>
                            <dl>
                                <dt>
                                    {moment(line.timestamp.value).format(
                                        ((dateFormat)?dateFormat:'DD/MM/YYYY') + ' ' + 
                                        ((timeFormat && timeFormat === 'LT')?'LTS':'HH:mm:ss')
                                    )}
                                </dt>
                                {message}
                            </dl>
                        </li>
                    );

                }
            });
            setContent(<ul>{list}</ul>);
        }
        else {
            setContent(<em>No history found</em>);
        }
    };

    const requestData = () => {
        setLoadingContent(true);
        $.ajax({
            url: CLOUD_FUNCTIONS.getTaskHistory,
            method: 'POST',
            data: {
                accountId: accountId,
                taskId: router.params.uid
            },
            async: true,
            crossDomain: true
        }).done((res)=>{
            parseHistory(res);
        });
    };

    useEffect(()=>{

        if(!content && accountId && !loadingContent){
            requestData();
        }

    }, [content, accountId, loadingContent]);

    const close = () => {
        setContent(null);
        browserHistory.push(location.pathname.replace('/history', ''));
    };

    const getContent = () => {
        if(!content){
            return (
                <DialogContent className="content">
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                    <Skeleton variant="text" height={25} width={200} />
                    <Skeleton variant="text" height={25} width={600} />
                </DialogContent>
            );
        }
        else {
            return (
                <DialogContent className="content">
                    {content}
                </DialogContent>
            );
        }
    };

    if(canView){  
        return (
            <Dialog 
                className='TaskHistory'
                open={true}
                onClose={close}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Changes history</DialogTitle>
                {getContent()}
                <DialogActions className="actions">
                    <Button variant="contained" onClick={close} >{intl.formatMessage({id:'Close'})}</Button>
                </DialogActions>
                
            </Dialog>
        );
    }


    return (
        <Dialog 
            className='TaskHistory'
            open={true}
            onClose={close}
            fullWidth
        >
            <DialogTitle>Changes history</DialogTitle>
            <DialogContent className="content">
                <Unautorized />
            </DialogContent>
            <DialogActions className="actions">
                <Button variant="contained" onClick={close} >{intl.formatMessage({id:'Close'})}</Button>
            </DialogActions>

        </Dialog>
    );

};

export default withRouter(withCustomErrorBoundary(TaskHistory));