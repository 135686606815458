import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import classnames from 'clsx';
import OverViewCard from '../OverViewCard';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const EffortOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    // get all of the sub childs 
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ? 
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean) 
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    const workspaceTasks = React.useMemo(() =>workspaceSelected === 'root' ? 
        subItensTasks.map(el => childsParents[el.id]).flat() : _.get(childsParents, workspaceSelected , [workspaceSelected]), [workspaceSelected, childsParents]);

    const overviewCurrentData = React.useMemo(() => workspaceTasks.reduce((acc, el) => {
        const currentTask = stateTasks[el];

        if(!currentTask || stateTasks.parent && !stateTasks[stateTasks.parent]){
            return acc;
        }
        const nbWorkers =  currentTask.nbWorkers || 1;
        if(currentTask.status !== 'done' && !currentTask.childrens){
            const maxEffort = parseInt(currentTask.maxEffort) * nbWorkers;
            const minEffort = parseInt(currentTask.minEffort) * nbWorkers;
            acc.maxEffort += isNaN(maxEffort)  ? 0 : maxEffort;
            acc.minEffort += isNaN(minEffort)  ? 0 : minEffort;
        }

        return acc;
    }, { maxEffort: 0, minEffort: 0 }), [workspaceTasks, stateTasks]); 

    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return <div className={classnames('bigNumbers')}>
        <OverViewCard title='Max. Effort' value={`${overviewCurrentData.maxEffort} h`} />
        <OverViewCard title={'Min. Effort'} value={`${overviewCurrentData.minEffort} h`}  />
        <OverViewCard title={'Average Effort'} value={`${(overviewCurrentData.maxEffort + overviewCurrentData.minEffort) / 2} h`} />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="effort-burndown"
                to={`${path}/effort-burndown`}
            >
                Go to <b>Effort Burndown</b> report
            </Button>
        </div>
    </div>;
};

EffortOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default EffortOverview;