import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';
import moment from 'moment';

const DateDriftTable = ({  tableColumnSize, onColumnHover, datasets, labels,  }) =>   {
    const renderColumns = useCallback(((row, isChild) => {
        const data = [{ isName: true, value: row.name } ];
        const getValue = (date) =>  date ? moment.unix(date).format('MMM D, YY') : 'N/A';
        labels.forEach((l, idx) => {
            data.push( { isChild, value: getValue(row[row.id][idx]) } );
        });

        return [data];
    }),[labels]);

    const formatTitle = (title) => {
        if(title === 'Current'){
            return title;
        }
        return getDateFormated(title);
    };

    return <Table
        tableColumnSize={tableColumnSize}
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
    />;
};

export default React.memo(DateDriftTable);