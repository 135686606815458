import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';

const EffortTable = ({ onColumnHover, datasets, labels, userDateFormat }) =>   {
    const renderColumns = useCallback(((row, isChild) => {
        const maxEffort = [{ isTitle: true, value: 'Max. effort' } ];
        const titleRow = [{ isName: true,  value: row.name , isChild } ];
        const minEffort = [{ isTitle: true, value: 'Min. effort' } ];
        const averageEffort = [{ isTitle: true, value: 'Average effort' } ];


        labels.forEach((l, idx) => {
            maxEffort.push( { value:  `${row.maxEffort[idx]} h`, isChild } );
            minEffort.push( { value:  `${row.minEffort[idx]} h`, isChild  } );
            averageEffort.push( { value:  `${(row.minEffort[idx] + row.maxEffort[idx]) / 2} h`, isChild  } );
            titleRow.push( { value: null, isChild  } );
        });

        return [ 
            titleRow,
            maxEffort,
            minEffort,
            averageEffort,
        ];
    }),[labels]);
    const formatTitle = (title) =>  getDateFormated(title);

    return <Table
        size="small"
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
        userDateFormat={userDateFormat} />;
};

export default React.memo(EffortTable);