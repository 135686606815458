import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useSelector } from 'react-redux';
import { getAccountUsers } from '../../utils/selectors/account';
import { useIntl } from 'react-intl';
import classnames from 'clsx';
import { Chip } from '@material-ui/core';
import { getUserAvatar } from '../../utils/utils';
import DatePicker from '../../components/DatePicker';
import SingledNotificationLine from './SingledNotificationLine';
import TaskDetailComment from '../TaskDetail/TaskDetailComment';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import ItemFile from '../../components/ItemFile';
import { diff } from 'deep-object-diff';

const NormalRowCompare = ({ before, after }) => (
    <>
        <div data-opensidebar="true" data-field="details" className="Before-Container">{before}</div>
        <div className="Comparasion-Icon">
            <ArrowRightAltIcon />
        </div>
        <div data-opensidebar="true" data-field="details" className="After-Container">{after}</div>
    </>
);

const fields = {
    title: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field }) => {
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={
                        <div className="Comparasion-Container">
                            <NormalRowCompare before={_.isEmpty(beforeValues) ? 'None' : beforeValues} after={_.isEmpty(afterValues) ? '' : afterValues} />
                        </div>
                    }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    label={'updated title'}
                />
            );
        },
    },
    skill: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            
            const label = !beforeValues ? 'added-skill' : !afterValues ? 'removed-skill' : 'updated-skill'; 
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={
                        <div className="Comparasion-Container">
                            <NormalRowCompare before={beforeValues} after={afterValues} />
                        </div>
                    }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label={label}
                />
            );
        },
    },
    effort: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={
                        <div className="Comparasion-Container">
                            <NormalRowCompare before={beforeValues} after={afterValues} />
                        </div>
                    }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label="update-effort"
                />
            );
        },
    },
    status: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={
                        <div className="Comparasion-Container">
                            <NormalRowCompare before={itnl.formatMessage({id: beforeValues === 'todo' ? 'to do' : beforeValues})} after={itnl.formatMessage({id:  afterValues === 'todo' ? 'to do' :  afterValues})} />
                        </div>
                    }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label="updated status"
                />
            );
        },
    },
    forcedUser: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={
                        <div className="Comparasion-Container">
                            {!(beforeValues || []).length ? <div data-opensidebar="true" data-field="details"  className='automatically-assigne'>{itnl.formatMessage({id: 'Automaticly assigned'})}</div> : <div className='usersContainer'>{beforeValues.map(user => {
                                return (
                                    <div data-opensidebar="true" data-field="details"  key={user.id} id={user.id} className={classnames('userItem')}>
                                        {getUserAvatar(user, false, user.avatar)}
                                        {user.displayName}
                                    </div>
                                );
                            })}</div>}
                            <div className="Comparasion-Icon">
                                <ArrowRightAltIcon />
                            </div>
                            {!(afterValues || []).length ? <div data-opensidebar="true" data-field="details" className='automatically-assigne'>{itnl.formatMessage({id: 'Automaticly assigned'})}</div> : <div className='usersContainer'>{afterValues.map(user => {
                                return (
                                    <div data-opensidebar="true" data-field="details"  key={user.id} id={user.id} className={classnames('userItem')}>
                                        {getUserAvatar(user, false, user.avatar)}
                                        {user.displayName}
                                    </div>
                                );
                            })}</div>}
                        </div>
                    }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label="updated-assignee"
                />
            );
        },
    },
    delayUntil: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues,  userAvatar, userName, date, field }) => {
            
            const label = !beforeValues ? 'updated-start-after' : !afterValues ? 'removed-start-after' : 'added-start-after';
            
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={ <div  className="Comparasion-Container">
                        <DatePicker  data-opensidebar="true" data-field="details" className='line-through' value={!_.isEmpty(beforeValues) ? beforeValues : null} />
                        <div className="Comparasion-Icon">
                            <ArrowRightAltIcon />
                        </div>
                        <DatePicker  data-opensidebar="true" data-field="details"  className='cursor-pointer' value={!_.isEmpty(afterValues) ? afterValues : null} />
                    </div> }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    label={label}
                />
            );
        },
    },
    deadline: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field }) => {
            

            const label = !beforeValues ? 'updated-due-date' : !afterValues ? 'removed-due-date' : 'added-due-date';
            
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={ <div className="Comparasion-Container">
                        <DatePicker data-opensidebar="true" data-field="details"  className='line-through'  value={!_.isEmpty(beforeValues) ? beforeValues : null} />
                        <div className="Comparasion-Icon">
                            <ArrowRightAltIcon />
                        </div>
                        <DatePicker data-opensidebar="true" data-field="details"  className='cursor-pointer' value={!_.isEmpty(afterValues) ? afterValues : null} />
                    </div> }
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    label={label}
                />
            );
        },
    },
    files: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            const deletedValues = !beforeValues ? [] : Object.keys(beforeValues).filter(key => !(afterValues && afterValues[key]));
            const newValues = !afterValues ? [] : Object.keys(afterValues).filter(key => !(beforeValues && beforeValues[key]));
            const lines = [];

            if (newValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`files-${lines.length}`}
                        comparasionInfo={
                            <div data-opensidebar="true" data-field="files" className='files-container cursor-pointer' >
                                {newValues.map(key => (
                                    <ItemFile 
                                        name={afterValues[key].name}
                                        fileId={key}
                                        key={key}
                                        url={afterValues[key].url}
                                    />
                                ))}
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={'added-files'}
                    />,
                );
            }

            if (deletedValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`files-${lines.length}`}
                        comparasionInfo={
                            <div data-opensidebar="true"  data-field="files" >
                                {deletedValues.map(key => (
                                    <ItemFile 
                                        key={key}
                                        name={beforeValues[key].name}
                                        fileId={key}
                                        url={beforeValues[key].url}
                                    />
                                ))}
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={'deleted-files'}
                    />,
                );
            }

            return lines;
        },
    },
    priority: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            

            const priorityMap = {
                0: 'normal',
                1: 'medium',
                2: 'high',
                3: 'urgent',
            }; 
            const getPriority = (value) => isNaN(value) ? priorityMap[0] : priorityMap[value];
            
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={<div className="Comparasion-Container">
                        <div data-opensidebar="true" data-field="details"  className="priorityMenuItem">
                            <div className={`priority-ball ${getPriority(beforeValues)}`} /> {getPriority(beforeValues)}
                        </div>
                        <div className="Comparasion-Icon">
                            <ArrowRightAltIcon />
                        </div>
                        <div data-opensidebar="true" data-field="details"  className="priorityMenuItem">
                            <div className={`priority-ball ${getPriority(afterValues)}`} /> {getPriority(afterValues)}
                        </div>
                    </div>}
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label="update-priority"
                />
            );
        },
    },
    dependencies: {
        getLines: ({ userColor, actionType, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            const newValues = _.isEmpty(afterValues) ? [] : afterValues.filter(task => _.isEmpty(beforeValues) || !beforeValues.find(bTask => bTask.taskId === task.taskId));
            const lines = [];
            

            if (newValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`dependencies-${lines.length}`}
                        comparasionInfo={
                            <div>
                                <div data-opensidebar="true" data-field="details"  className={classnames('dependenciesSelect', 'premium', { ['line-through']: actionType === 'deleted'})}>
                                    {newValues.map((option, index) => {
                                        return <Chip key={`${option}-${index}`} label={option.title} />;
                                    })}
                                </div>
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={actionType === 'deleted' ? 'deleted-dependencies' : 'added-dependencies'}
                    />,
                );
            }
            return lines;
        },
    },
    tags: {
        getLines: ({ userColor,  actionType, notificationId, isRead, afterValues, userAvatar, userName, date, field, itnl }) => {
            
            

            const newValues = Object.keys(afterValues || {});
            const lines = [];

            if (newValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`tags-${lines.length}`}
                        comparasionInfo={
                            <div data-opensidebar="true"  data-field="details">
                                {newValues.map(tagKey => (
                                    <Chip
                                        key={afterValues[tagKey].title}
                                        label={afterValues[tagKey].title}
                                        className={classnames(`tag color${afterValues[tagKey].color}`, { ['line-through']: actionType === 'deleted'})}
                                    />
                                ))}
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={actionType === 'deleted' ? 'deleted-tags' : 'added-tags'}
                    />,
                );
            }

            return lines;
        },
    },
    childrens: {
        
        getLines: ({ userColor, actionType, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            
            
            const newValues = _.isEmpty(afterValues) ? [] : afterValues.filter(task => _.isEmpty(beforeValues) || !beforeValues.find(bTask => bTask.taskId === task.taskId));
            const lines = [];

            if (newValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`childrens-${lines.length}`}
                        comparasionInfo={
                            <div>
                                <div data-opensidebar="true"  data-field="details" className={classnames('dependenciesSelect', 'premium', 'childrensChips', { ['line-through']: actionType === 'deleted'})}>
                                    {newValues.map((option, index) => {
                                        return <Chip key={`${option}-${index}`} label={option.title || itnl.formatMessage({id:'Untitled'})} />;
                                    })}
                                </div>
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={actionType === 'deleted' ? 'remove-childrens' : 'added-childrens'}
                        labelValues={{count: newValues.length}}
                    />,
                );
            }

            return lines;
        },
    },
    parent: {
        getLines: ({ userColor, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field, itnl }) => {
            
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={ <div className="Comparasion-Container">
                        <NormalRowCompare before={beforeValues} after={afterValues} />
                    </div>}
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    itnl={itnl}
                    label="update-location"
                />
            );
        },
    },
    comments: {
        getLines: ({ userColor, actionType, notificationId, isRead, afterValues, beforeValues, userAvatar, userName, date, field }) => {
            
            const commentsKeys = _.union(Object.keys(beforeValues || {}),Object.keys(afterValues || {}));
            
            return commentsKeys.reduce((acc, commentKey, idx) => {
                if(actionType === 'deleted') {
                    const comment = afterValues[commentKey];

                    acc.push(<SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`comments-${commentKey}`}
                        comparasionInfo={<div  data-opensidebar="true" className='notifications-comments deleted cursor-pointer' data-field={field}>
                            <TaskDetailComment
                                showUserName={false}
                                key={idx}
                                comment={comment}
                                mine={false}
                                userAt={
                                    <div className="commentHeader">
                                        <strong>{comment.createdBy}</strong>
                                    </div>
                                }
                            />
                        </div>}
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        label="deleted-comments"
                    />);

                    return acc;
                }

                if(actionType === 'added') {
                    const comment = afterValues[commentKey];
                    acc.push(<SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`comments-${commentKey}`}
                        comparasionInfo={<div data-opensidebar="true" data-field={field} className="notifications-comments cursor-pointer">
                            <TaskDetailComment
                                showUserName={false}
                                key={idx}
                                comment={comment}
                                mine={false}
                                userAt={
                                    <div className="commentHeader">
                                        <strong>{comment.createdBy}</strong>
                                        <span>{moment(comment.at).fromNow()}</span>
                                    </div>
                                }
                            />
                        </div>}
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        label="added-comments"
                    />);
                }

                if(actionType === 'updated') {
                    const oldComment = _.isEmpty(beforeValues[commentKey]) ? { comment: '', createdBy: null, at: null  } : beforeValues[commentKey];
                    const newComment = afterValues[commentKey];
                    acc.push(<SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`comments-${commentKey}`}
                        comparasionInfo={
                            <div data-opensidebar="true" data-field={field} className="notifications-comments Comparasion-Container cursor-pointer">
                                <TaskDetailComment
                                    showUserName={false}
                                    key={idx}
                                    comment={oldComment}
                                    mine={false}
                                    userAt={
                                        <div className="commentHeader">
                                            <strong>{oldComment.createdBy}</strong>
                                            <span>{moment(oldComment.at).fromNow()}</span>
                                        </div>
                                    }
                                />
                                <div className="Comparasion-Icon">
                                    <ArrowRightAltIcon />
                                </div>
                                <TaskDetailComment
                                    showUserName={false}
                                    key={idx+1}
                                    comment={newComment}
                                    mine={false}
                                    userAt={
                                        <div className="commentHeader">
                                            <strong>{newComment.createdBy}</strong>
                                            <span>{moment(newComment.at).fromNow()}</span>
                                        </div>
                                    }
                                />
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        label="updated-comments"
                    />);
                }

                return acc;
            }, []);
        },
    },
    workingTime: {
        getLines: ({ userColor, actionType,  notificationId, isRead, afterValues, beforeValues,userAvatar, userName, date,accountUsers, field, itnl }) => {
            const newValues = !afterValues ? [] : Object.keys(afterValues).filter(key => !(beforeValues && beforeValues[key]));
            const lines = [];
            const getWorkTimeMember = (val) => Array.isArray(val) ? val : [val];
            if (newValues.length) {
                lines.push(
                    <SingledNotificationLine
                        isRead={isRead}
                        notificationId={notificationId}
                        key={`workingTime-${lines.length}`}
                        comparasionInfo={
                            <div data-opensidebar="true" data-field={field}>
                                {newValues.map(key => (<div className="timeworkEntry cursor-pointer">
                                    <span>{moment(afterValues[key].date).format('DD/MM/YYYY')}</span>
                                    {
                                        getWorkTimeMember(afterValues[key].member).map(userKey => <div className='Notification-worktime-container'>
                                            {getUserAvatar(userKey, false, accountUsers[userKey]?.avatar)}
                                            <span>{accountUsers[userKey]?.displayName || 'Deleted Team Member'}</span>
                                        </div>)
                                    }
                                    <span>{parseFloat(afterValues[key].hours).toFixed(2)}h</span>
                                </div>
                                ))}
                            </div>
                        }
                        userAvatar={userAvatar}
                        userName={userName}
                        userColor={userColor}
                        date={date}
                        field={field}
                        itnl={itnl}
                        label={actionType === 'deleted' ? 'deleted-time-worked' : 'added-time-worked'}
                    />,
                );
            }

            return lines;
        },
    },
    description: {
        getLines: ({ userColor, notificationId, isRead, beforeValues, afterValues, userAvatar, userName, date, field }) => {
            
            return (
                <SingledNotificationLine
                    isRead={isRead}
                    notificationId={notificationId}
                    comparasionInfo={ <div data-opensidebar="true" data-field={field} className="Comparasion-Container descriptions cursor-pointer">
                        <div className='fr-box fr-basic fr-toolbar-open'>
                            <div className='fr-wrapper froola-wrapper'>
                                <div className='fr-element fr-view'>
                                    <div dangerouslySetInnerHTML={{ __html: beforeValues }}/>
                                </div>
                            </div>
                        </div>
                        <div className="Comparasion-Icon">
                            <ArrowRightAltIcon />
                        </div>
                        <div data-opensidebar="true" data-field={field} className='fr-box fr-basic fr-toolbar-open cursor-pointer'>
                            <div className='fr-wrapper froola-wrapper'>
                                <div className='fr-element fr-view'>
                                    <div dangerouslySetInnerHTML={{ __html: afterValues }} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    userAvatar={userAvatar}
                    userName={userName}
                    userColor={userColor}
                    date={date}
                    field={field}
                    label="update-description"
                />
            );
        },
    },
};


const NotificationLine = ({ taskId, actionType, createdBy, notificationId, beforeValue, afterValue, createdAt, field, isRead, type, taskData={} }) => {
    const accountUsers = useSelector(getAccountUsers);
    const userAvatar = _.get(accountUsers, [createdBy, 'avatar'], null);
    const userName = _.get(accountUsers, [createdBy, 'displayName'], null);
    const userColor = _.get(accountUsers, [createdBy, 'color'], null);
    const date = moment(createdAt).format('MMM DD h:mm A');
    const itnl = useIntl();

    if(type === 'deleted'){
        

        return  <SingledNotificationLine
            userAvatar={userAvatar}
            notificationId={notificationId}
            userName={userName}
            userColor={userColor}
            isRead={isRead}
            date={date}
            field={field}
            label={'deleted the task'}
        />;
    }

    if(type === 'budgetAlert'){
        return  <SingledNotificationLine
            userAvatar={null}
            notificationId={notificationId}
            userName="System"
            isRead={isRead}
            date={date}
            field={field}
            comparasionInfo={<div className="Comparasion-Container">
                {itnl.formatMessage({id: 'notif.alerts.trigger'}, taskData)}
            </div>}
            label={'Budget Alert'}
        />;
    }

   
    return fields[field].getLines({
        taskId,
        accountUsers: accountUsers,
        afterValues: afterValue,
        beforeValues: beforeValue,
        userAvatar,
        userName,
        userColor,
        notificationId,
        actionType: actionType,
        date,
        field,
        itnl,
        isRead,
    });
};

export default React.memo(NotificationLine, (prev, next) => {
    return diff(prev, next);
});