import React, { useCallback, useMemo, useState } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import PropTypes from 'prop-types';
import { getAccountId, getAccountTaskPriority } from '../../utils/selectors/account';
import { useSelector } from 'react-redux';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { handleStopPropagation } from '../../utils/utils';
import useInternalization from '../../utils/hooks/useInternalization';

const TaskPrioritySelect = ({ taskId, isBlank, canManage, multiTask = false, onChange, level }) =>{

    const { t } = useInternalization();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const accountId = useSelector(getAccountId);
    const levelVal = useSelector(getAccountTaskPriority(taskId)) || level || 0;
    const levels = {0: 'Normal', 1: 'Medium', 2: 'High', 3: 'Urgent'};

    const handleOpen = useCallback((e) => {
        handleStopPropagation(e);
        setAnchorEl(e.currentTarget);
        setOpen(true);
    }, []);

    const handleClose = useCallback((e) => {
        handleStopPropagation(e);
        setOpen(false);
        setAnchorEl(null);
    }, []);

    const handleSelect = useCallback((e, newLvl) => {        
        if(multiTask) {
            onChange(newLvl);
            handleClose(e);
            return;
        }
        
        firebaseEvents.updateTaskPriority(accountId, taskId, newLvl);
        handleClose(e);
    }, [multiTask, accountId, taskId, onChange, handleClose]);


    const render = useMemo(()=>{
    
        if(isBlank) {
            return <div className="TaskPrioritySelect empty" data-sidebar='preventClose'/>;
        }

        return (
            <div 
                data-sidebar='preventClose'    
                className={classnames('TaskPrioritySelect', {disabled: !canManage})}
            >
                <Button
                    aria-controls={open ? 'basic-menu' : null}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : null}
                    onClick={handleOpen}
                    disabled={!canManage}
                    className={classnames('TaskPrioritySelect__current', {
                        empty: isBlank,
                        medium: levelVal === 1,
                        high: levelVal === 2,
                        urgent: levelVal === 3
                    })}
                >
                    <span>{levels[levelVal] || t('Normal')}</span>
                </Button>
                <Menu
                    data-sidebar='preventClose'
                    className='TaskPrioritySelect__Menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    value={levelVal || 0}
                >
                    <MenuItem className="taskPriorityMenuItem" onClick={(e) =>  handleSelect(e, 0)} value={0} key={0}>
                        {t('Normal')}
                    </MenuItem>
                    <MenuItem className="taskPriorityMenuItem" onClick={(e) =>  handleSelect(e, 1)} value={1} key={1}>
                        {t('Medium')}
                    </MenuItem>
                    <MenuItem className="taskPriorityMenuItem" onClick={(e) =>  handleSelect(e, 2)} value={2} key={2}>
                        {t('High')}
                    </MenuItem>
                    <MenuItem className="taskPriorityMenuItem" onClick={(e) =>  handleSelect(e, 3)} value={3} key={3}>
                        {t('Urgent')}
                    </MenuItem>
                </Menu>
            </div>
        );
    }, [isBlank, canManage, handleOpen, levelVal, anchorEl, open, handleClose, handleSelect, t]);

    return render;
};

TaskPrioritySelect.propTypes = {
    taskId: PropTypes.string,
    isBlank: PropTypes.bool,
    level: PropTypes.number,
    canManage: PropTypes.bool.isRequired,
    multiTask: PropTypes.bool.isRequired,
    onChange: PropTypes.func
};

export default React.memo(withCustomErrorBoundary(TaskPrioritySelect));
