import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Apps, ChevronRight, CreateNewFolderOutlined, ExpandMore, FolderOpenOutlined } from '@material-ui/icons';
import classnames from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getAccountTasks } from '../../utils/selectors/account';
import { defineClosedSortables, setLimitViewTo } from '../App/AppActions';
import { getLimitViewTo } from '../../utils/selectors/app';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import CreateNewFolder from '../../components/CreateNewFolder';
import useIsLocalStorageAvailable from '../../utils/hooks/useIsLocalStorageAvailable';

const SideMenuTreeSelector = ({ isAppBlocked }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLocalStorageAvailable = useIsLocalStorageAvailable();
    const [openedItems, setOpenedItems] = useState([]);
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const accountId = useSelector(getAccountId);
    const limitViewTo = useSelector(getLimitViewTo);
    const tasks = useSelector(getAccountTasks);
    const isInTasks = ['tasks','list','board'].includes(window.location.pathname.replace('/',''));

    const goTo = useCallback((taskId = null) => {
        dispatch(setLimitViewTo({accountId, value: taskId}));
        dispatch(defineClosedSortables(
            (taskId !== null || !tasks) ? [] : Object.values(tasks).filter( ({childrens}) => !!childrens).map(({id}) => id),
            accountId
        ));
        browserHistory.push('/tasks');
    }, [accountId, tasks]);

    const getItems = useCallback((parentId)=>{
        if(tasks) {
            return Object.values(tasks).filter(({ parent, childrens, canView }) => canView && parent === parentId && childrens).map(({ id, taskId }) => getTreeItem(id || taskId));
        }
        return [];
    }, [tasks, getTreeItem, openedItems]);

    const handleSetOpenedItems = useCallback((e, items) => {
        e.stopPropagation();
        setOpenedItems(items);
    }, []);

    const getTreeItem = useCallback((taskId) => {
        if(!taskId) return;

        const subItems = getItems(taskId);

        return (
            <div className='treeItem' key={taskId}>

                <a className={isInTasks && limitViewTo === taskId ? 'active':null} onClick={()=>goTo(taskId)}>
                    {!openedItems.includes(taskId) && !!subItems.length && <ChevronRight onClickCapture={(e)=>handleSetOpenedItems(e, [...openedItems, taskId])} />}
                    {openedItems.includes(taskId) && !!subItems.length && <ExpandMore onClickCapture={(e)=>handleSetOpenedItems(e, openedItems.filter(el=>el !== taskId))} />}
                    {!subItems.length && <FolderOpenOutlined className='folder' onClick={()=>goTo(taskId)} />}
                    <span>
                        {tasks[taskId]?.title}
                    </span>
                    {tasks && tasks[taskId]?.sourceId && tasks[taskId]?.isConnectProject && tasks[taskId]?.syncAppType &&
                        <SyncProjectLogo
                            toolName={tasks[taskId]?.syncAppType} task={tasks[taskId]} fromViewMode={'SideMenuAppIcon'}/>
                    }
                </a>
                {openedItems.includes(taskId) && !!subItems.length && <div className='childs'>{subItems}</div>}
            </div>
        );
    }, [limitViewTo, openedItems, tasks, getItems, handleSetOpenedItems]);

    useEffect(()=>{
        if(isLocalStorageAvailable && localStorage.getItem('SideMenuTreeOpenedItems')){
            setOpenedItems(localStorage.getItem('SideMenuTreeOpenedItems').split(','));
        }
    }, [isLocalStorageAvailable]);

    useEffect(()=>{
        if(isLocalStorageAvailable) {
            localStorage.setItem('SideMenuTreeOpenedItems', openedItems.join(','));
        }
    }, [openedItems, isLocalStorageAvailable]);

    return (
        <div className='SideMenuTreeSelector'>
            <div>
                <a
                    onClick={()=>goTo()}
                    className={isInTasks && limitViewTo === null ? 'active':null}
                >
                    <Apps />
                    <span>
                        {intl.formatMessage({id:'All folders and tasks'})}
                    </span>
                </a>
                {!!getItems().length && <Tooltip title={intl.formatMessage({id: 'Add a folder'})}><IconButton onClick={()=>setShowCreateFolder(true)} className='addProjectIcon' size='small'><CreateNewFolderOutlined /></IconButton></Tooltip>}
            </div>
            <div className={classnames('SideMenuTree', {noFolder: getItems().length === 0})}>
                {!!getItems().length && getItems()}

                {!getItems().length && (
                    <Button
                        disabled={isAppBlocked}
                        onClick={()=>setShowCreateFolder(true)}
                        startIcon={<CreateNewFolderOutlined />}
                        className='addProjectBtn'
                        variant='contained'
                        size='small'
                        color="primary"
                    >
                        {intl.formatMessage({id: 'Add a folder'})}
                    </Button>
                )}
            </div>

            {showCreateFolder && <CreateNewFolder fromSideBar onClose={()=>setShowCreateFolder(false)} />}
        </div>
    );
};

export default React.memo(SideMenuTreeSelector);