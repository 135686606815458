import React, { useCallback } from 'react';
import Tooltip from 'rc-tooltip';
import Delete from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Person from '@material-ui/icons/Person';
import VoiceOverOff from '@material-ui/icons/VoiceOverOff';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import EventAvailable from '@material-ui/icons/EventAvailable';
import School from '@material-ui/icons/School';
import Label from '@material-ui/icons/Label';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ActionsDetails  from './ActionsDetails';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ListIconButton = ({
    overlay, type, id, Icon, showMultiDetails
}) => {
    const handleClick = useCallback(() => showMultiDetails(type), [showMultiDetails, type]);

    return <Tooltip placement="top" overlay={overlay}>
        <IconButton id={id} onClick={handleClick}>
            {Icon}
        </IconButton>
    </Tooltip>;
};


const MultiSelectMenu = ({showMultiDetails, closeMultiSelectMenu, setSelectedLines, selectedItems }) => {
    return (
        <div id="multiSelectMenu">
            <div>
                {selectedItems.length} lines selected
            </div>
            <div className="actions">
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    placement="top"
                    overlay="Mark as done"
                    id="multiStatus"
                    type="Status"
                    Icon={<CheckCircle />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Force assign"
                    id="multiAssign"
                    type="Assign"
                    Icon={<Person />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Remove forced team members"
                    id="multiRemoveForceAssign"
                    type="RemoveForceAssign"
                    Icon={<VoiceOverOff />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Change skill"
                    id="multiSkill"
                    type="Skill"
                    Icon={<School />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Change priority"
                    id="multiPriority"
                    type="Priority"
                    Icon={<PriorityHigh />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Change wait until"
                    id="multiDelay"
                    type="Delay"
                    Icon={<EventAvailable />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Change due date"
                    id="multiDeadline"
                    type="Deadline"
                    Icon={<EventAvailable />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Change tags"
                    id="multiTags"
                    type="Tags"
                    Icon={<Label />}
                />
                <ListIconButton
                    showMultiDetails={showMultiDetails}
                    overlay="Delete"
                    id="multiDelete"
                    type="Delete"
                    Icon={<Delete />}
                />
            </div>
            <ActionsDetails
                setSelectedLines={setSelectedLines}
                showMultiDetails={showMultiDetails}
                selectedItems={selectedItems}
            />
            <CloseIcon onClick={closeMultiSelectMenu} />
        </div>
    );
};

export default React.memo(withCustomErrorBoundary(MultiSelectMenu)); 