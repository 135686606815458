import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
var ChildrensSwitches = function (_a) {
    var revertPermissions = _a.revertPermissions, applyToChildrens = _a.applyToChildrens, applyToAllChildrens = _a.applyToAllChildrens, handleApplyToChildren = _a.handleApplyToChildren, handleApplyToAllChildren = _a.handleApplyToAllChildren;
    var intl = useIntl();
    var onApplyToChildrens = function () { return handleApplyToChildren(); };
    var onApplyToAllChildrens = function () { return handleApplyToAllChildren(); };
    return (React.createElement("div", { className: "permSwitches" },
        React.createElement(Divider, { variant: "middle" }),
        !revertPermissions ? (React.createElement(FormControlLabel, { key: "applyToChildrens", className: "applyToChildrens", control: (React.createElement(Switch, { size: "small", checked: applyToChildrens, onChange: onApplyToChildrens })), label: intl.formatMessage({ id: 'permissions.applyToChildrens.label' }) }))
            : null,
        React.createElement(FormControlLabel, { key: "applyToAllChildrens", className: "applyToAllChildrens", control: (React.createElement(Switch, { size: "small", checked: applyToAllChildrens, onChange: onApplyToAllChildrens })), label: intl.formatMessage({ id: 'permissions.applyToAllChildrens.label' }) })));
};
export default ChildrensSwitches;
