import React, { useEffect } from 'react';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import Sortable from 'sortablejs';
import { getAccountId, getAccountUserGroups, getAccountUsers } from '../../utils/selectors/account';
import { getGroupByTitle } from '../../utils/utils';
import { withRouter } from 'react-router';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import GroupLine from './GroupLine';
import UserLine from './UserLine';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const MembersTree = ({ handleAddNewMember }) => {
    const intl = useIntl();
    const [treeData, setTreeData] = React.useState([]);
    const [sortableList, setSortableList] = React.useState([]);

    const accountId = useSelector(getAccountId);
    const users = useSelector(getAccountUsers);

    const userGroups = useSelector(getAccountUserGroups);

    useEffect(() => {
        if(accountId && users) {
            setTreeData(getMembersGroupsTree());
        }
    }, [accountId, users, getMembersGroupsTree, userGroups]);

    useEffect(()=>{
        destroySortables();
        if(treeData && users) {
            createSortables();
        }

        return () => {
            destroySortables();
        };
    }, [treeData, users, destroySortables, createSortables]);


    const destroySortables = () => {
        sortableList.forEach(sortable => {
            if(sortable.el){
                sortable.destroy();
            }
        });
        setSortableList([]);
    };

    const createSortables = () => {
        const list = Array.from(document.getElementsByClassName('group')).reduce((acc, item) => {
            const sortable = Sortable.create(item, {
                group: 'groupId',
                filter: '.groupInput',
                preventOnFilter: false,
                onEnd: evt => {
                    $(evt.item).appendTo($(evt.from));

                    const userId = evt.item.getAttribute('data-userid');
                    const groupDestId = evt.to.getAttribute('data-groupid');
                    const groupCurrentId = evt.item.getAttribute('data-groupid');
                    const isChangingUserGroup = evt.item.className.includes('userLine');

                    firebaseEvents.addChallenge('orderMembers');

                    if (isChangingUserGroup) {
                        firebaseEvents.changeUserGroup(accountId, userId, groupDestId || undefined);
                        return;
                    }
                    firebaseEvents.changeUserGroupParent(accountId, groupCurrentId, groupDestId || undefined);
                },
            });

            acc.push(sortable);
            return acc;
        }, []);

        setSortableList(list);
    };

    const getMembersGroupsTree = (parent) => {
        const groups = getGroupByTitle(userGroups, parent);

        if(!_.isEmpty(groups)) {
            const currentGroups = groups.map(({ id, title }) => ({
                groupId: id,
                title,
                groups: getMembersGroupsTree(id),
                members: _.filter(users, user => user && user.group === id)
                    .sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1)
            }));

            return [
                ...currentGroups,
                ..._.filter(users, user => user && _.isUndefined(user.group))
                    .sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1)
            ];
        }

        if(_.isEmpty(groups) && !parent) {
            return _.filter(users, user => user && user?.displayName && _.isUndefined(user.group))
                .sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1);
        }
        return [];
    };

    const addNewMemember = (groupId) => handleAddNewMember(groupId);

    return (
        <>
            <div className='MembersTree-header'>
                <Typography variant={'subtitle2'}>{intl.formatMessage({id: 'Name'})}</Typography>
                <Typography variant={'subtitle2'}>{intl.formatMessage({id: 'Member Type'})}</Typography>
            </div>
            <div className="group" data-groupid={null} data-sidebar='preventClose'>
                {treeData.map((item, index) => {
                    const { groupId, groups, members, title, userId } = item;

                    if(groupId) {
                        return (
                            <SortableGroups
                                key={`${groupId}-${index}`}
                                groupId={groupId}
                                groups={_.filter(groups, group => !_.isUndefined(group.groupId))}
                                title={title}
                                members={members}
                                handleAddNewMember={addNewMemember}
                            />
                        );
                    }

                    return <UserLine memberId={userId} key={`${userId}-${index}`}/>;
                })}
            </div>
        </>
    );
};

export default withRouter(withCustomErrorBoundary(MembersTree));

export const SortableGroups = ({ groupId, groups, members, title, handleAddNewMember }) => {
    return (
        <GroupLine
            key={groupId}
            groupId={groupId}
            title={title}
            handleAddNewMember={handleAddNewMember}
            emptyGroup={_.isEmpty(members)}
        >
            <div className="group" data-groupid={groupId} data-sidebar='preventClose'>
                {groups && groups.map(({ groupId, members, title, groups }) => {
                    return (
                        <SortableGroups
                            key={groupId}
                            groupId={groupId}
                            groups={_.filter(groups, group => !_.isUndefined(group.groupId))}
                            title={title}
                            members={members}
                            handleAddNewMember={handleAddNewMember}
                        />
                    );
                })}

                {members && members.map(({ userId }, index) => {
                    return <UserLine memberId={userId} key={`${userId}-${index}`}/>;
                })}
            </div>
        </GroupLine>
    );
};