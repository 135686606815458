var _a;
import { MEMBER_TYPES } from '../../utils/memberTypes';
export var tabByMemberType = (_a = {},
    _a[MEMBER_TYPES.resourceOnly] = ['Skills', 'Availability', 'Extra hours', 'Days off', 'costs'],
    _a[MEMBER_TYPES.userAndResource] = ['Skills', 'Availability', 'Extra hours', 'Days off', 'costs', 'Permissions'],
    _a[MEMBER_TYPES.userOnly] = ['Availability', 'Permissions'],
    _a);
export var tabNameByIndex = {
    0: 'Skills',
    1: 'Availability',
    2: 'Days off',
    3: 'costs',
    4: 'Permissions',
    5: 'Extra hours',
};
