import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { 
    getAccountTaskCanManage, 
    getAccountTaskDoneby, 
    getAccountTaskEstimationsUserId, 
    getAccountTaskForcedUsers, 
    getAccountTaskISourceId, 
    getAccountTaskNbworkers, 
    getAccountTaskStatus, 
    getAccountTaskUserworking, 
    getAccountUsers 
} from '../../utils/selectors/account';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import RemoveIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';
import _ from 'underscore';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const TaskDetailAssignedUsers = ({ taskId }) => {
    const intl = useIntl();
    const [showAssignedUserChoice, setShowAssignedUserChoice] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    
    const taskSourceId = useSelector(getAccountTaskISourceId(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskNbWorkers = useSelector(getAccountTaskNbworkers(taskId));
    const taskForcedUsers = useSelector(getAccountTaskForcedUsers(taskId));
    const taskEstimationsUserId = useSelector(getAccountTaskEstimationsUserId(taskId));
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskUserWorking = useSelector(getAccountTaskUserworking(taskId));
    const taskDoneBy = useSelector(getAccountTaskDoneby(taskId));
    const users = useSelector(getAccountUsers);

    useEffect(() => {
        document.addEventListener('click', handleClickAway);

        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, []);

    const handleClickAway = (e) => {
        const userItemCard = document.querySelector('#userItemCard');

        if(userItemCard && !userItemCard.contains(e.target)) {
            setTooltipOpen(false);
        }
    };

    const handleShowTooltip = () => {
        if(taskStatus === 'inprogress' && !taskSourceId) {
            setTooltipOpen(true);
        }
    };

    const changeAssignedUser = (values) => {
        const selectedUsers = Array.isArray(values) ? values : [values];
        const nbWorkers = taskNbWorkers || 1;
        const prevAssignees = _.uniq([
            ...taskEstimationsUserId || [], 
            ...taskForcedUsers || [], 
        ]);

        if(!selectedUsers.length) {
            setShowAssignedUserChoice(false);
            return;
        }

        if(selectedUsers.length > nbWorkers) {
            toastr.error('Maximum number of workers already selected. Remove one first.');
            return;
        }
        
        if(
            _.size(prevAssignees) > 1 &&
            selectedUsers.every(assignee => prevAssignees.includes(assignee)) &&
            prevAssignees.every(assignee => selectedUsers.includes(assignee))
        ) {
            setShowAssignedUserChoice(false);
            return;
        }

        if(taskStatus === 'done'){
            firebaseEvents.changeAssignedUser(taskId, selectedUsers);
            setShowAssignedUserChoice(false);
            return;
        }

        toastr.confirm(intl.formatMessage({id: 'Are you sure you want to force/assign this member on the task?'}), {
            onOk: () => {
                firebaseEvents.changeAssignedUser(taskId, selectedUsers);
                setShowAssignedUserChoice(false);
                return;
            },
            okText: intl.formatMessage({id: 'FORCE ASSIGN'}),
            cancelText: intl.formatMessage({id: 'toastr.confirm.cancel'}),
        });
    };

    const handleShowAssignedUserChoice = (e) => {
        if(taskStatus === 'inprogress' && !taskSourceId) {
            setTooltipOpen(true);
            return;
        }

        if ($(e.target).closest('.RemoveMember').length === 0 && !$(e.target).hasClass('.RemoveMember')) {
            setShowAssignedUserChoice(true);
            return;
        }
    };

    const hideAssignedUserChoice = (e) => {
        if ($(e.target).closest('.assignedUserChoice').length === 0 && $(e.target).closest('.redux-toastr').length === 0) {
            setShowAssignedUserChoice(false);
            return;
        }
    };

    const handleRemoveForcedUser = (userId) => {
        if(taskForcedUsers.length === 1) {
            handleRemoveAllForced();
            return;
        }
        firebaseEvents.removeForcedUser(taskId, userId);
    };

    const handleRemoveAllForced = () => {
        firebaseEvents.removeAllForcedUsers(taskId);
    };

    const getAssignedUser = () => {
        let userId = null;
        let forced = false;
        let label = intl.formatMessage({id: '(predicted)'});

        if (taskStatus !== 'done') {
            if (!taskNbWorkers || taskNbWorkers === 1) {
                if (taskForcedUsers) {
                    userId = taskForcedUsers;
                    forced = true;
                    label = intl.formatMessage({id: '(forced)'});
                } else if (taskEstimationsUserId) {
                    userId = taskEstimationsUserId;
                }
            } else {
                if (taskForcedUsers && taskForcedUsers.length === taskNbWorkers) {
                    userId = taskForcedUsers;
                    forced = true;
                    label = intl.formatMessage({id: '(forced)'});
                } else if (taskEstimationsUserId) {
                    userId = taskEstimationsUserId;
                    if (taskForcedUsers) {
                        forced = 'partial';
                        label = intl.formatMessage({id: '(forced partially)'});
                    }
                }
            }
        } else {
            userId = taskDoneBy;
            label = null;
        }

        let isDisabled = !(taskStatus !== 'done' && taskCanManage);

        if (taskStatus === 'inprogress' && (taskUserWorking || taskEstimationsUserId || taskForcedUsers)) {
            userId = taskEstimationsUserId || taskUserWorking || taskForcedUsers;
            label = !taskSourceId ?  intl.formatMessage({id: '(working)'}) : label;
            isDisabled = !taskSourceId;
        }

        if(taskStatus === 'inprogress' && taskSourceId) {
            if(taskEstimationsUserId && taskForcedUsers && taskForcedUsers.length !== taskNbWorkers) {
                label = intl.formatMessage({id: '(forced partially)'});
            } else if (taskForcedUsers) {
                label = intl.formatMessage({id: '(forced)'});
            }
        }
        
        if(taskStatus === 'done'){
            userId = taskDoneBy || taskForcedUsers || taskUserWorking;
        }

        if (typeof userId === 'string') {
            userId = [userId];
        }

        if (userId) {
            let userList = [];

            userId.forEach(user => {
                if (users[user]) {
                    const force = !isDisabled && taskForcedUsers && taskForcedUsers.indexOf(user) !== -1;

                    userList.push(
                        <div onClick={handleShowTooltip} key={user} id='userItemCard' className={classnames('userItem', 
                            { 
                                forced: force, 
                                taskInProgress: taskStatus === 'inprogress',
                                taskInProgressDisabled: taskStatus === 'inprogress' && !taskSourceId
                            }
                        )}>
                            <StateUserAvatar
                                className="avatar"
                                userId={user}
                                disableTooltip={true}
                            />
                            {users[user]?.displayName || ''}
                            {force && <RemoveIcon data-user-id={user} className="RemoveMember" onClick={() => handleRemoveForcedUser(user)} />}
                        </div>
                    );
                }
            });
            if (taskNbWorkers && taskNbWorkers > 1) {
                while (userList.length < taskNbWorkers) {
                    userList.push(
                        <div key={'null-' + userList.length} className={classnames('userItem none', {
                            taskInProgress: taskStatus === 'inprogress',
                            taskInProgressDisabled: taskStatus === 'inprogress' && !taskSourceId
                        })}>
                            {intl.formatMessage({id: 'Not defined'})}
                        </div>,
                    );
                }
            }

            let removeAllForced = null;

            if ((taskStatus === 'todo' || taskSourceId) &&  taskForcedUsers && taskForcedUsers.length > 1) {
                removeAllForced = (
                    <a className="removeAllForced" onClick={handleRemoveAllForced}>
                        {intl.formatMessage({id: 'Remove all forced'})}
                    </a>
                );
            }

            return (
                <li>
                    <dl className="assignedToDl">
                        <dt className={classnames('assignedToLabel', {done: taskStatus === 'done'})}>
                            {taskStatus === 'done' ? intl.formatMessage({id: 'Done by'}) : intl.formatMessage({id: 'Assigned to'})}    
                        </dt>
                        <dt>
                            <div className={classnames('assignedUser', { disabled: isDisabled })}>
                                {label && <label>{label}</label>}
                                <Tooltip 
                                    open={tooltipOpen} 
                                    hidden={taskStatus !== 'inprogress'} 
                                    placement='top-start' 
                                    title={intl.formatMessage({id: 'Task is in progress. Assignee can not be changed'})}
                                    disableHoverListener
                                    disableFocusListener
                                    disableTouchListener
                                >
                                    <div className={classnames('nameContainer', { forced: forced })} onClick={handleShowAssignedUserChoice}>
                                        {userList}
                                    </div>
                                </Tooltip>

                                {removeAllForced}
                                {showAssignedUserChoice && <SelectTeamMember
                                    maxSelect={taskNbWorkers || 1}
                                    currentUser={userId || []}
                                    onSelect={changeAssignedUser}
                                    onClose={hideAssignedUserChoice}
                                    open={showAssignedUserChoice}
                                    taskId={taskId}
                                />}

                            </div>
                        </dt>
                    </dl>
                </li>
            );
        }
        return (
            <li>
                <dl className="assignedToDl">
                    <dt className={classnames('assignedToLabel', {done: taskStatus === 'done'})}>
                        {taskStatus === 'done' ? intl.formatMessage({id: 'Done by'}) : intl.formatMessage({id: 'Assigned to'})}
                    </dt>
                    <dt>
                        <div className={classnames('assignedUser', { disabled: isDisabled })}>

                            {label && <label>{label}</label>}
                        
                            <div className={classnames('nameContainer', { forced: forced })} onClick={handleShowAssignedUserChoice}>
                                <div className={classnames('userItem', { 
                                    taskInProgress: taskStatus === 'inprogress',
                                    taskInProgressDisabled: taskStatus === 'inprogress' && !taskSourceId
                                })}
                                >
                                    {intl.formatMessage({id: 'Not defined'})}
                                </div>
                            </div>

                            {
                                showAssignedUserChoice && <SelectTeamMember
                                    maxSelect={taskNbWorkers || 1}
                                    currentUser={taskForcedUsers || []}
                                    onSelect={changeAssignedUser}
                                    onClose={hideAssignedUserChoice}
                                    open={showAssignedUserChoice}
                                    taskId={taskId}
                                />
                            }
                        </div>
                    </dt>
                </dl>
            </li>
        );
    };

    return (
        <div>
            {getAssignedUser()}
        </div>
    );
};

TaskDetailAssignedUsers.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailAssignedUsers;
