export const companySizes = [
    '2 to 10',
    '11 to 25',
    '26 to 50',
    '51 to 100',
    '101 to 200',
    '201 to 500',
    '500+',
    'Just Me'
];

export const industryFields = [
    'Aerospace',
    'Automotive',
    'Banking',
    'Chemical',
    'Construction',
    'Consumer',
    'Defense',
    'Education',
    'Electronics',
    'Energy',
    'Entertainment',
    'Food',
    'Government',
    'Healthcare',
    'Hospitality',
    'Insurance',
    'Legal',
    'Manufacturing',
    'Marketing',
    'Medical',
    'Non',
    'Pharmaceutical',
    'Plastics',
    'Professional',
    'Publishing',
    'Real',
    'Retail',
    'Small',
    'Software',
    'Technology',
    'Telecommunications',
    'Transportation',
    'Travel',
    'Utilities',
    'Wholesale',
];