import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import StatusSection from './components/StatusSection/StatusSection';
import _ from 'underscore';
import { withRouter } from 'react-router';
import { hideLoader, showLoader } from '../App/AppActions';
import { connectApp } from '../../utils/services/syncApp';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '../../utils/selectors/user';
import { getAccountId } from '../../utils/selectors/account';
import { toastr } from 'react-redux-toastr';
import { connectAccount } from '../../utils/calendarIntegration';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getAppTypeFromQuery, SYNC_APP_NAMES, SYNC_APP_TYPES, SYNC_CALENDAR_APP_TYPES } from './utils';
import Bugsnag from '@bugsnag/js';
import { segmentTrack } from '../../utils/firebaseEvents';
import { customError, getCustomError } from '../../utils/utils';

const Integrations = ({ children, router }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);

    const integrationsHandlers = async ({ query, appType }) => {
        if(SYNC_CALENDAR_APP_TYPES.includes(appType)) {
            try {
                await connectAccount(query);
            } catch (error) {
                throw customError('Something went wrong', 'Please verify if enough permissions have been granted', { appType });
            }
        }

        if(SYNC_APP_TYPES.includes(appType)) {
            try {
                const code = query?.code || query?.token || query?.oauth_verifier;
                await connectApp({ accountId: currentAccountId, currentUserId: currentUserId, code, type: appType });
                segmentTrack(`connected${SYNC_APP_NAMES[appType]}`, { accountId: currentAccountId });
            } catch (error) {
                throw customError('Something went wrong', 'Please try again', { appType });
            }
        }
    };

    useEffect(() => {
        const query = _.get(router, ['location', 'query'], null);
        if(!query || _.isEmpty(query) || !currentUserId || !currentAccountId) return;

        (async () => {
            try {
                dispatch(showLoader());

                const appType = getAppTypeFromQuery(query);

                if(!appType) {
                    throw customError('Something went wrong', 'No connection available for the selected application', { appType });
                }

                await integrationsHandlers({ query, appType });

            } catch (error) {
                const { title, message } = getCustomError(error);
                toastr.error(title, message);
                Bugsnag.notify(error);
            } finally {
                dispatch(hideLoader());
            }
        })();
    }, [router, currentAccountId, currentUserId, integrationsHandlers]);

    return (
        <div className="Integrations">
            <h1>{intl.formatMessage({ id: 'Integrations' })}</h1>
            <StatusSection />
            {children}
        </div>
    );
};

export default withCustomErrorBoundary(withRouter(Integrations));
