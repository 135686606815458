import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/messaging';
import { browserHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import moment from 'moment-timezone';
import _ from 'underscore';
import $ from 'jquery';
import { getItemPermissions, tracking, accountSettingsAdpater, getUtmCookie, compareTask, compareUser, compareSettings, compareSkills, pickColor } from './utils';
import { createDefaultWorkspace } from './createDefaultWorkspace';
import { createChargeBeeCustomer } from './chargeBee';
import { affiliateAddTrial } from './affiliates';
import { newGetOrderedTaskList } from './engine/getOrderedTaskList';
import { getParentChildIds } from './engine/getParentChildIds';
import Bugsnag from '@bugsnag/js';
import { CLOUD_FUNCTIONS, PLANS, UPVOTY_DOMAIN } from './constants';
import { memberResourcersTypes, MEMBER_TYPES } from './memberTypes';
import { getAppData, getRouting, getState } from '../configureStore';
import { getPopupGuides, getParentChildIds as getParentChildIdsState, getDisablePopupGuides} from './selectors/app';
import { getAccountChargebeeCustomerId, getAccountId, getAccountTasks, getAccountUsers } from './selectors/account';
import { getUserEmail, getUserId, getUserAccounts } from './selectors/user';

import { isFollowTypeManually } from './controllers/tasks/followers';
import removeMemberFromTasksWorkers from './controllers/tasks/assignees';
import { updateIsTrial } from './controllers/account/settings/isTrial';
import {
    defineUser,
    connectionState,
    resetDataSettings,
    defineUserChallenges,
    defineUserFirstCalendarConnected,
    defineUserFirstStepsConfigs,
    defineUserPopupGuideConfigs,
    defineUserData,
    defineUserAccounts,
    showLoader,
    singleEngineRun,
    setAccountSettings,
    setAccountFilters,
    addPermissionsToTasks,
    combinedUpdate,
    updateSingleTask,
    setUsers,
    updateSingleUser,
    setSkills,
    setDaysOff,
    setAccountPropertyData,
    resetData,
    hideLoader,
    openReccurentTaskModal,
    closeSelectDependenciesModal,
    setTaskDescription,
    unsetTaskDescription,
    setAccountDataSyncApps,
    setTasksLoaded,
} from '../views/App/AppActions';

import { alertsHandler, saveStatsData } from './services/statsService';

var Promise = require('es6-promise').Promise;

var database, storage, messaging, dispatch, authUser, listeningAccountId, listeningUserId;
let allDataLoaded = false;

firebase.initializeApp({
    projectId: process.env.FIREBASE_PROJECTID,
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    // databaseURL: 'http://localhost:9000/?ns=planless-staging',
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID,
});

database = firebase.database();

storage = firebase.storage();

export function segmentTrack(name, data) {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
        if (window.analytics) {
            window.analytics.track(name, data);
        }
        if(window.stonlyTrack){
            window.stonlyTrack('track', name);
        }
        if (name && authUser && authUser.email) {
            db.push('/hbTimelineEvents', { name, data: data || '', email: authUser.email });
        }

        if(window.StonlyWidget){
            window.StonlyWidget('refetchConfig');
        }
        Bugsnag.leaveBreadcrumb(name, data);
    }
}

export const db = {
    v: 'value',
    r: p => database.ref(p),
    set: (p, v) => db.r(p).set(v),
    equal: (p, o, v, f) => db.r(p).orderByChild(o).equalTo(v).once(db.v).then(f),
    equalSimply: (p, o, v) => db.r(p).orderByChild(o).equalTo(v).once(db.v),
    order: (p, o, s, f) => db.r(p).orderByChild(o).startAt(s).once(db.v).then(f),
    update: (p, u) => db.r(p).update(u),
    push: (p, d) => db.r(p).push(d),
    pushkey: p => db.push(p).key,
    off: p => db.r(p).off(db.v),
    on: (p, f) => db.r(p).on(db.v, f),
    once: p => db.r(p).once(db.v),
    remove: p => db.r(p).remove(),
};


export function defineFirebaseEvents(d) {
    dispatch = d;

    // Auth state change handler for login/logout
    firebase.auth().onAuthStateChanged(user => {
        if(user && !user.email) {
            return;
        }

        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
            authUser = user;
        } else {
            authUser = user;
            // localStorage.clear();
            // authUser = {...user, email: 'lotte@ecotest.be', uid:'Nx2NNpNni7cF0kOsb49VLYqhDE43'};
            // authUser = {...user, email: 'crm@wedogrowth.io', uid:'CN4sN7bUUuPcFszdTyokQWrXMiz1'};
            // authUser = {...user, email: 'wilfredl@stijlgenoten.nl', uid:'HR6sIPgVnihMvxtSVlHyAdZdqXZ2'};
            // authUser = {...user, email: 'larysson@planless.io', uid:'gjIU3D9Ma8V9vqFjvryxq8ziu073'};
            // authUser = {...user, email: 'holly.sudol20@gmail.com', uid:'ZJ1XjxW1AKVzcTXLHtusQquI8xK2'};
            // authUser = {...user, email: 'produccion@23lunes.studio', uid:'U68eMTOXSkNGsHWdANWo9xFWKeR2'};
            // authUser = {...user, email: 'rhili@attardco.com', uid:'oHqjO2ikP3OhtKz2ZNBG5ZSYAsF2'};
            // authUser = {...user, email: 'lv@kriss.lv', uid:'xjGANK6W9uMV6XXZyoy76pS47VV2'};
            // authUser = {...user, email: 'demo+free@planless.io', uid:'tG2EJUgZ6AdgzffE9BC0mGNTXhf1'};
            // authUser = {...user, email: 'moira@3ci.agency', uid:'nvkMS1KERwNNiDAkUd2utPekQw42'};
            // authUser = {...user, email: 'robert@bravadayachts.com', uid:'pXUvmncSSqbCU31ZtR2z5lpJhRi1'};
            // authUser = {...user, email:'tiagoalves943@gmail.com', uid:'EjvUldeiyEe6MkiTtRdIaLTQnP32'};
            // authUser = {...user, email:'caio@planless.io', uid:'VtKL6jyAlMRHKC6kPYcqRT2zCci2'};
            // authUser = {...user, email:'greg@planless.io', uid:'3Eu1f9Dl3QcK45BXZbBaq7nuXNB3'};
            // authUser = {...user, email:'matheus@planless.io', uid:'v8afnQU9GOe845wMY032Bt5g1It1'};
            // authUser = {...user, email:'jlluis@flamagas.com', uid:'mkw8199b1odI2ohEg8J5nHqQ2v33'};
            // authUser = {...user, email:'moira@3ci.agency', uid:'nvkMS1KERwNNiDAkUd2utPekQw42'};
            // authUser = {...user, email:'brittany@1stblvd.com', uid:'XUsk2LSB2JcI676dmWpj0QSof2U2'};
            // authUser = {...user, email:'abrinker@nautical-designs.com', uid:'LfRkzdHXeyPFsN3WecEH2EySorV2'};
            // authUser = {...user, email:'jose@planless.io', uid:'AGkkn85RQaUN5GpTypekMuo3qu93'};
            // authUser = {...user, email:'gregstoos@gmail.com', uid:'gDcfp5t6NLVeGXr7m4z9eShMYsF3'};
            // authUser = {...user, email:'gregstoos@gmail.com', uid:'ApyFF6u6IlMsbW5aNgn6RSTruo53'};
            // authUser = {...user, email:'snap@snap.pt', uid:'27xhRzBEbUbGkVDK8XmbD92jijC2'};
            // authUser = {...user, email:'tiagoalves943@gmail.com', uid:'EjvUldeiyEe6MkiTtRdIaLTQnP32'};
            // authUser = {...user, email:'miguel.jimenez@ffwd.is', uid:'pp9zii1TKbV91OYeeX6qSQpAZf02'};
            // authUser = {...user, email:'angela@designontap.com', uid:'3tyftPbxBzOZNROY3Jk8jtg8Can2'};
            // authUser = {...user, email:'miciver@gmail.com', uid:'JtK9c0oh5CdAc6r1D7Ry2rorZ1S2'};
            // authUser = {...user, email:'mariusz.glinski@businessweb.pl', uid:'PNEBZpVPjCfLe2XBozKyuQAV2Zs2'};
            // authUser = {...user, email:'gaspari.simone@gmail.com', uid:'gTAzJggqaCO5vEnbNxMQXKUkZG72'};
            // authUser = {...user, email:'sasha@lionique.com', uid:'5wnM8cSW9xTEwTfS5u4hXsRt6jI2'};
            // authUser = { ...user, email: 'federico@federicojorge.com', uid: 'Go0O33MG8tctSd8rp1v33WI1UiY2' };
            // authUser = {...user, email:'a.cardenas@vallfirest.com', uid:'jjfCExY2mVMDJowyqNgxztaJqPl2'};
            // authUser = {...user, email:'etaylor@attardco.com', uid:'WrjjFkRLLrQKui0LZDbmyZUFJV63'};
            // authUser = {...user, email:'mark@flocc.co', uid:'U8hW66x60TeHW9dFJuaK3gyFyxX2'};
            // authUser = {...user, email:'brittany@adsandfunnels.co', uid:'3yVwOPa1qtXyZWRfwwByLqLktIl1'};
            // authUser = {...user, email:'jessica.green@fieldnation.com', uid:'RAtBGchSu6cZpVTaQQhp58IhCjW2'};
            // authUser = {...user, email:'zach@117group.com', uid:'Q2CC2VlhwSRxki3BRtnlPkyEWVX2'};
            // authUser = {...user, email:'brittany@adsandfunnels.co', uid:'3yVwOPa1qtXyZWRfwwByLqLktIl1'};
            // authUser = {...user, email:'rodrigo@flextem.org', uid:'eFpvgPaEJDVjueVosiHcHXpiU7E3'};
            // authUser = {...user, email:'toth.mariann@neting.hu', uid:'sugJLUArNgMspuxZSfNxNkVvUUn1'};
            // authUser = {...user, email:'elodie@hemblem.com', uid:'c2SBNR8HKGQ5I40krsurl3ewgxy1'};
            // authUser = {...user, email:'joel@notiondesigngroup.com', uid:'ij7FlxlKytfKI1AYArDAyi9eFdw2'};
            // authUser = {...user, email:'jlluis@flamagas.com', uid:'mkw8199b1odI2ohEg8J5nHqQ2v33'};
            // authUser = {...user, email:'nuno.tenazinha@kobu.pt', uid:'L8ZDTeSyx3MuZ5U8DKHAvehvGMk1'};
            // authUser = {...user, email:'b.nanda@gmail.com', uid:'o7o14bElbwbH769HLbOj57xgenS2'};
            // authUser = {...user, email:'r.manickam@ubiquedigital.com', uid:'eVxZUnfWtQWvSD7H0MKjqsMGrFh2'};
            // authUser = {...user, email:'dinox34@minime-labs.com', uid:'n6i4QWclnSR3wTE9TQsu262kCLm1'};
            // authUser = {...user, email:'pawelpolcyn@gmail.com', uid:'ykB0Wy0aiXOfHdYpV8lD77MXUGH2'};
            // authUser = {...user, email:'serwer@filtreo.pl', uid:'72mtPA4fPzPpJnICNyPglGOQkGC2'};
            // authUser = {...user, email:'info@synotius.com', uid:'GZk9CK7LrYU9X1j8YBWLEFVzv4z2'};
            // authUser = {...user, email:'pia@enteria.uy', uid:'lROnvThIiqUlJg64wuMMNOom1Xy1'};
            // authUser = {...user, email:'maa@business-setting.com', uid:'tQYGFlkCcLgczKKNt68XHgG40cu2'};
            // authUser = {...user, email:'tolazabal@unomasdos.com', uid:'WeSKO1bv75hfNbepTYsmxZFBJGy1'};
            // authUser = {...user, email:'steve@sitegeek.co.uk', uid:'BqR9dbstMvToGfUzhx1q0JtBWcz2'};
            // authUser = {...user, email:'jessica.camilleri@firstbridge.com', uid:'GC2qevwTjsRYzXlLbBMgXGDlBO23'};
            // authUser = {...user, email:'info@leax.sk', uid:'vK6mKG72kJV8GDRVWYfLVuqXvGj2'};
            // authUser = {...user, email:'lizz@pawlytics.com', uid:'oe3TpyXtWdUpBpD5K0ffLmrKA4e2'};
            // authUser = {...user, email:'boris@my-ceo.com', uid:'hLjv6mYKOchLAuPGYuwmvicIIa23'};
            // authUser = {...user, email:'lstetson@paradynamix.com', uid:'Yscp4nyC66NPRmFeVlTcgLEbjdh2'};
            // authUser = {...user, email:'otto@eco-test.be', uid:'u7Ml9oFa1jYqfblzv4sXTp3H83r2'};
            // authUser = {...user, email:'dfridge@mindspring.com', uid:'fN9BZYfaBGhUskbpWIAlu9zMfaf2'};
            // authUser = {...user, email:'todd@cabrydesign.com', uid:'yUS8z4rGRCTz94MsLKwZJNnCIm92'};
            // authUser = {...user, email:'alex+plakett@agencehoffman.com', uid:'i7GcWxmgCGURBy18CuJqhOUcPMn1'};
            // authUser = {...user, email:'benci@flex-ap.com', uid:'60H4OvG1NXQFErFthSuHLYOhN8h1'};
            // authUser = {...user, email:'adam@clevermaninc.com', uid:'H1IO14WHiHfqNVgT65NZlswcHLF2'};
            // authUser = {...user, email:'jt@onalytics.tech', uid:'3AVjaYO4q2fAqtRWcXvmriRWSOf1'};
            // authUser = {...user, email:'jon.l.mongul28@gmail.com', uid:'Lzb6ONi3rfT5mI7WcUW9XnEJj6v1'};
            // authUser = {...user, email:'jessica.green@fieldnation.com', uid:'RAtBGchSu6cZpVTaQQhp58IhCjW2'};
            // authUser = {...user, email:'andrea.faccin@gmail.com', uid:'vLNodGz16KPAE4Uf1X8fQ1trlQ43'};
            // authUser = {...user, email:'robert@bravadayachts.com', uid:'pXUvmncSSqbCU31ZtR2z5lpJhRi1'};
            // authUser = {...user, email:'jyl@3qdigital.com', uid:'eFomyk66FzaK0NbqW5F37LaqbGq1'};
            // authUser = {...user, email:'renaud@b-o-d.fr', uid:'wKH3fz49IaW22JBObVogyLrcuQa2'};
            // authUser = {...user, email:'stefan@responsewave.se', uid:'fVjGpUrjgPhM1ZiVDcK8XpdNasr1'};
            // authUser = {...user, email:'galenmchatton@gmail.com', uid:'YWDLTcv2HXNvKehklNdmz8m18JF2'};
            // authUser = {...user, email:'rafael.canizares@lasonanta.es', uid:'yjZnqgQfZXRcfq9ZNoOZGHJMc3o2'};
            // authUser = {...user, email:'adamsb2000@bellsouth.net', uid:'9WhO6dREkhSjCurX87a6fddcCkx1'};
            // authUser = {...user, email:'carlos@theleongroup.com', uid:'8cDv11mtsUhYG59I7McUS5gBxz02'};
            // authUser = {...user, email:'phia@theseedgroup.co.uk', uid:'9moCmPmuILcyOqrrLXJO0bwlLFy1'};
            // authUser = {...user, email:'bhave@bhave.it', uid:'z0rlAjxodqRH58QtBL2qyPewfKC2'};
            // authUser = {...user, email:'joshua@jaaronwoodcountertops.com', uid:'Z1iX0DwYPLRpzCt1pZDQMHrE3AC3'};
        }

        dispatch(defineUser(authUser));

        if (authUser) {
            // start loading data in localstorage
            if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
                segmentTrack('Login');
                const utm = getUtmCookie();
                window.analytics.identify(authUser.uid, { email: authUser.email, utm: utm });

                if(window.stonlyTrack){
                    window.stonlyTrack('identify', authUser.uid, { email: authUser.email, utm: utm });
                }
            }

            checkUserHasAccount(authUser);

            browserHistory.push(
                getRouting().locationBeforeTransitions.pathname + getRouting().locationBeforeTransitions.search,
            );
        }
    });

    if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
    }


    // handle disconnection
    db.on('.info/connected', function (snap) {
        if (snap.val() === true && window.navigator.onLine) {
            dispatch(connectionState(true));
        } else if (snap.val() === false && !window.navigator.onLine) {
            dispatch(connectionState(false));
        }
    });
}

export function checkUserHasAccount(authUser) {
    var accountId = null,
        userId = null;
    const utm = getUtmCookie();
    // if user doesn't have an associated account, create one
    db.equal('/users', 'uid', authUser.uid, user => {
        if (!user.val()) {
            db.equal('/users', 'email', authUser.email, u => {
                accountId = null;
                userId = null;


                if (!u.val()) {
                    userId = db.pushkey('/users');
                    db.set('/users/' + userId, {
                        uid: authUser.uid,
                        email: authUser.email,
                        utm: utm
                    }).then(() => {
                        checkUserHasAccountTreat(null, userId); //, authUser
                    });
                } else {
                    userId = Object.keys(u.val())[0];
                    accountId = u.val()[userId].activeAccount;

                    if (!accountId && u.val()[userId].accounts) {
                        accountId = u.val()[userId].accounts[0];
                    }

                    checkUserHasAccountTreat(accountId, userId); //, authUser
                }
            });
        } else {

            userId = Object.keys(user.val())[0];
            accountId = user.val()[userId].activeAccount;

            if (!accountId && user.val()[userId].accounts) {
                accountId = user.val()[userId].accounts[0];
            }

            checkUserHasAccountTreat(accountId, userId); //, authUser
        }
    });
}

export function checkUserHasAccountTreat(accountId, userId) {
    if (!accountId) {
        browserHistory.push('/create-workspace');
        return;
    }

    setUserAccountsStateChangedListener(userId);
    setUserStateChangedListener(userId, accountId);
    setAccountStateChangedListener(accountId, userId);

    if (window.Notification && window.Notification.permission !== 'granted') {
        askNotificationPermission(accountId, userId);
    }
}

export function doAskNotificationPermission(accountId, userId) {
    askNotificationPermission(accountId, userId);
}

export function doRemoveNotificationPermission(accountId, userId) {
    updateMemberData(accountId, userId, { pushToken: null, browserNotifications: false });
}

function askNotificationPermission(accountId, userId) {
    var userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        return;
    }

    // ask service worker permission
    if (firebase.messaging.isSupported() && window.Notification) {
        window.Notification.requestPermission().then(function (permission) {
            if (permission === 'granted') {
                messaging.getToken().then(function (currentToken) {
                    segmentTrack('Accepted push notifications', userId);
                    updateMemberData(accountId, userId, { pushToken: currentToken, browserNotifications: true });
                });
            } else {
                segmentTrack('Blocked push notifications', userId);
            }
        });
    }
}

export function getRandomCode() {
    var min = 100,
        max = 999;

    var code1 = Math.floor(Math.random() * (max - min)) + min;
    var code2 = Math.floor(Math.random() * (max - min)) + min;
    var code3 = Math.floor(Math.random() * (max - min)) + min;

    return code1 + '-' + code2 + '-' + code3;
}

export function createAccount(data) {
    return new Promise((resolve) => {
        const newAccountKey = db.pushkey('/accounts'),
            code = getRandomCode();


        // get the user accounts plans
        if (listeningAccountId) {
            db.off(`/accounts/${listeningAccountId}/settings`);
            db.r(`/accounts/${listeningAccountId}/tasks`).off('child_added');
            db.r(`/accounts/${listeningAccountId}/tasks`).off('child_removed');
            db.r(`/accounts/${listeningAccountId}/tasks`).off('child_changed');
            db.r(`/accounts/${listeningAccountId}/users`).off('child_added');
            db.r(`/accounts/${listeningAccountId}/users`).off('child_removed');
            db.r(`/accounts/${listeningAccountId}/users`).off('child_changed');
            db.off(`/accounts/${listeningAccountId}/skills`);
            db.off(`/accounts/${listeningAccountId}/daysoff`);
            db.off(`/accounts/${listeningAccountId}/chat`);
            db.off(`/accounts/${listeningAccountId}/logger`);
            db.off(`/accounts/${listeningAccountId}/skillGroups`);
            db.off(`/accounts/${listeningAccountId}/slackConnections`);
            db.off(`/accounts/${listeningAccountId}/userGroups`);
            listeningAccountId = null;
        }

        if (listeningUserId) {
            db.off('/accounts/' + listeningUserId.accountId + '/users/' + listeningUserId.userId);
            listeningUserId = null;
        }

        db.equal('/users', 'uid', authUser.uid, async (user) => {
            const userId = Object.keys(user.val())[0];

            const userData = {
                [userId]: {
                    uid: data.uid,
                    userId: userId,
                    accountId: newAccountKey,
                    color: '#7b68ee',
                    displayName: data.userName,
                    email: data.email,
                    isAdmin: true,
                    phoneNumber: data?.phoneNumber || null,
                    memberType: MEMBER_TYPES.userAndResource,
                    emailNotifications: true,
                    browserNotifications: false,
                    permissions: {
                        admin: true,
                        team: false,
                        skills: false,
                        daysoff: false,
                        boards: false,
                        subscription: false,
                        workload: [userId]
                    },
                    avatar: data.avatar,
                    schedule: [
                        { d: '1', day: 'Mon', start: '09:00', end: '12:00', nbMin: 180 },
                        { d: '1', day: 'Mon', start: '13:00', end: '18:00', nbMin: 300 },
                        { d: '2', day: 'Tue', start: '09:00', end: '12:00', nbMin: 180 },
                        { d: '2', day: 'Tue', start: '13:00', end: '18:00', nbMin: 300 },
                        { d: '3', day: 'Wed', start: '09:00', end: '12:00', nbMin: 180 },
                        { d: '3', day: 'Wed', start: '13:00', end: '18:00', nbMin: 300 },
                        { d: '4', day: 'Thu', start: '09:00', end: '12:00', nbMin: 180 },
                        { d: '4', day: 'Thu', start: '13:00', end: '18:00', nbMin: 300 },
                        { d: '5', day: 'Fri', start: '09:00', end: '12:00', nbMin: 180 },
                        { d: '5', day: 'Fri', start: '13:00', end: '18:00', nbMin: 300 },
                    ],
                    timezone: moment.tz.guess() || 'Europe/London',
                }
            };

            let userAccounts = user.val()[userId].accounts || [];
            const accountsPlans = await Promise.all(userAccounts.map(el => new Promise(resolve => {
                db.once(`/accounts/${el}/settings/plan`).then(result => resolve(result.val()));
            })));

            const filterFree = accountsPlans ? accountsPlans.filter(el => el === PLANS.free) : [];
            if (filterFree.length >= 2) {
                // toastr.error('You already have 2 free workspaces upgrade or delete one off them to create a new one.');
                // return resolve(null);
            }
            // create account
            db.set('/accounts/' + newAccountKey, {
                id: newAccountKey,
                settings: {
                    accountInfo: {
                        ...data,
                        utm: user.val()[userId].utm || getUtmCookie(),
                    },
                    id: newAccountKey,
                    accountId: newAccountKey,
                    name: data.accountName,
                    code: code,
                    planNbusers: 999,
                    createdAt: moment().format(),
                    endPlanAt: moment().add(15, 'days').format(),
                    plan: PLANS.business,
                    boards: {
                        inprogress: {
                            name: 'In progress',
                            index: 0,
                        },
                    },
                    basePermissions: {
                        workers: [userId],
                    },
                    isTrial: true,
                    isLifetime: false,
                    canUsersManageOwnSkills: false,
                },
                chat: {
                    channels: {
                        general: {
                            createdAt: moment().format(),
                            createdBy: userId,
                            description:
                                'This is a general channel for all team members. Change this description to welcome your team members.',
                            participants: Object.keys(userData),
                            perm: {
                                owners: [userId],
                            },
                        },
                    },
                },
                users: userData,
            });
            // chargebeeCreateCustomerManual with user Email
            userAccounts.push(newAccountKey);
            db.set('/users/' + userId + '/accounts', userAccounts);
            db.set('/users/' + userId + '/activeAccount', newAccountKey);

            createChargeBeeCustomer({
                accountId: newAccountKey,
                email: data.email
            }).then(() => {
                // if there is that cookie it means it comes from afiliate programs.
                const pl_aid = document.cookie.split(';').find(e => e.match('pl_aid'));
                if (pl_aid) {
                    const value = pl_aid.split('=')[1];
                    affiliateAddTrial({
                        pl_aid: value,
                        chargebeeCustomerId: getAppData()?.account?.settings?.chargebeeCustomerId,
                    });
                }
            }).catch(err => Bugsnag.notify(err));

            if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
                window.analytics.identify(authUser.uid, { email: authUser.email, useraccounts: userAccounts });
                if(window.stonlyTrack){
                    window.stonlyTrack('identify', authUser.uid, { email: authUser.email, useraccounts: userAccounts });
                }
            }

            dispatch(resetDataSettings());

            segmentTrack('Created workspace', { accountId: newAccountKey });
            addChallenge('createWorkspace');
            setUserStateChangedListener(userId, newAccountKey);
            setAccountStateChangedListener(newAccountKey, userId);

            return resolve(newAccountKey);
        });
    });
}

export function setUserStateChangedListener(userId, accountId) {
    const popupGuidesDisabled = getDisablePopupGuides(getState());

    if (listeningUserId) {
        db.off('/accounts/' + listeningUserId.accountId + '/users/' + listeningUserId.userId);
        db.off(`/users/${userId}/popupsGuideConfig`);
        db.off(`/users/${userId}/firstCalendarConnected`);
        db.off(`/users/${userId}/firstStepsConfig`);
        db.off(`/users/${userId}/challenges`);
    }

    listeningUserId = { accountId: accountId, userId: userId };
    db.on('/accounts/' + accountId + '/users/' + userId, user => {
        onUserDataChange(user.val(), userId, accountId);
    });


    db.on('/users/' + userId + '/challenges', challenges => {
        const challengesVal = challenges.val();

        if (!challengesVal) {
            const newChallenges = { completed: ['createAccount'], tasksCreated: 0 };
            db.set('/users/' + userId + '/challenges', newChallenges);
            dispatch(defineUserChallenges(newChallenges));
            return;
        }

        dispatch(defineUserChallenges(challengesVal));
    });

    db.on(`/users/${userId}/firstCalendarConnected`, data => {
        let isConnected = false;
        if(data.val()) {
            isConnected = true;
        }
        dispatch(defineUserFirstCalendarConnected(isConnected));
    });

    db.on('/users/' + userId + '/firstStepsConfig', data => {
        const dataVal = data.val();

        if (dataVal) {
            dispatch(defineUserFirstStepsConfigs(dataVal));
        }
    });

    if(!popupGuidesDisabled) {
        db.on(`/users/${userId}/popupsGuideConfig`, data => {
            const popupsDataVal = data.val();
            if(popupsDataVal) {
                dispatch(defineUserPopupGuideConfigs(popupsDataVal));
            }
        });
    }
}

let upvotyIdentifyDone = false;
let bugsnagSetuserDone = false;

function onUserDataChange(userVal, userId, accountId) {
    if (userVal) {
        if(userVal.disconnectAndRefresh){
            db.remove(`/accounts/${accountId}/users/${userId}/disconnectAndRefresh`);
            logOut();
            setTimeout(()=>{
                document.location.reload();
            }, 2000);
        }
        dispatch(defineUserData(userVal));

        if (authUser && userVal.email !== authUser.email) {
            updateMemberData(accountId, userId, { email: authUser.email });
        }
        if (authUser && userVal.uid !== authUser.uid) {
            updateMemberData(accountId, userId, { uid: authUser.uid });
        }

        userVal.accountId = accountId;

        var uval = { ...userVal };
        uval.userid = userId;
        uval.email = authUser.email;
        if (userVal.displayName) {
            uval.displayname = userVal.displayName;
        }
        if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
            window.analytics.identify(uval.uid, uval);

            if(window.stonlyTrack){
                window.stonlyTrack('identify', uval.uid);
            }
        }
        if (!bugsnagSetuserDone) {
            bugsnagSetuserDone = true;
            Bugsnag.setUser = { id: uval.uid, data: uval };
        }

        if (!upvotyIdentifyDone && window.upvoty) {
            upvotyIdentifyDone = true;
            window.upvoty.init('identify', {
                user: {
                    id: authUser.uid,
                    name: userVal.displayName,
                    email: authUser.email,
                },
                baseUrl: UPVOTY_DOMAIN,
                publicKey: 'd0f1997295cec60e32f47a2ac17894e8',
            });
        }
    }
}

export function addChallenge(name) {
    if (getAppData()?.userChallenges) {
        var completed = getAppData()?.userChallenges.completed || [];
        if (completed.indexOf(name) === -1) {
            segmentTrack('Add challenge', {
                accountId: getAppData()?.account?.settings?.id,
                userId: getAppData()?.user?.data?.userId,
                name,
            });
            completed.push(name);
            if (
                !getAppData()?.account?.maxChallengesCompleted ||
                getAppData()?.account?.maxChallengesCompleted < completed.length
            ) {
                if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
                    window.analytics.identify(getAppData()?.user?.data?.uid, {
                        email: authUser.email,
                        maxChallengesCompleted: completed.length,
                        maxchallengescompleted: completed.length,
                    });

                    if(window.stonlyTrack){
                        window.stonlyTrack('identify', getAppData()?.user?.data?.uid, {
                            email: authUser.email,
                            maxChallengesCompleted: completed.length,
                            maxchallengescompleted: completed.length,
                        });
                    }

                    if (completed.length === 10) {
                        segmentTrack('Completed 10 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 15) {
                        segmentTrack('Completed 15 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 20) {
                        segmentTrack('Completed 20 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 25) {
                        segmentTrack('Completed 25 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 30) {
                        segmentTrack('Completed 30 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 40) {
                        segmentTrack('Completed 40 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 50) {
                        segmentTrack('Completed 50 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 60) {
                        segmentTrack('Completed 60 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 70) {
                        segmentTrack('Completed 70 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 80) {
                        segmentTrack('Completed 80 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                    if (completed.length === 90) {
                        segmentTrack('Completed 90 challenges', {
                            accountId: getAppData()?.account?.settings?.id,
                            userId: getAppData()?.user?.data?.userId,
                        });
                    }
                }
                db.set(
                    '/accounts/' + getAppData()?.account?.settings?.id + '/settings/maxChallengesCompleted',
                    completed.length,
                );
            }
            if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
                window.analytics.identify(authUser.uid, {
                    email: authUser.email,
                    challengescompleted: completed.length,
                    challenges: completed.toString(),
                });

                if(window.stonlyTrack){
                    window.stonlyTrack('identify', authUser.uid, {
                        email: authUser.email,
                        challengescompleted: completed.length,
                        challenges: completed.toString(),
                    });
                }
            }
            db.set('/users/' + getAppData()?.user?.data?.userId + '/challenges/completed', completed);
        }
    }
}

function changeChallengeTaskCount(quantity) {
    var nb = getAppData()?.userChallenges.tasksCreated + quantity;
    db.set('/users/' + getAppData()?.user?.data?.userId + '/challenges/tasksCreated', nb);

    if (nb >= 10) {
        addChallenge('add10Task');
    }
    if (nb >= 50) {
        addChallenge('add50Task');
    }
    if (nb >= 100) {
        addChallenge('add100Task');
    }
    if (nb >= 250) {
        addChallenge('add250Task');
    }
    if (nb >= 500) {
        addChallenge('add500Task');
    }
    if (nb >= 1000) {
        addChallenge('add1000Task');
    }
}

export function setUserAccountsStateChangedListener(userId) {
    db.on('/users/' + userId + '/accounts', accounts => {
        const accountsVal = accounts.val();
        let list = [];

        if (window.analytics && authUser && authUser.uid && accountsVal && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
            window.analytics.identify(authUser.uid, { email: authUser.email, userAccounts: accountsVal.join(',') });

            if(window.stonlyTrack){
                window.stonlyTrack('identify', authUser.uid, { email: authUser.email, userAccounts: accountsVal.join(',') });
            }
        }
        if (accountsVal) {
            accountsVal.forEach(a => {
                db.once(`/accounts/${a}/settings`).then(acc => {
                    if (acc && acc.val()) {
                        list.push({ id: a, name: acc.val().name, code: acc.val().code });
                    }
                    Bugsnag.addMetadata = { account: list };
                    dispatch(defineUserAccounts([...list]));
                });
            });
        }
    });
}

export function changeActiveAccount(accountId) {
    // TODO remove userId from function
    const userId = getUserId(getState());

    dispatch(resetDataSettings());

    dispatch(showLoader());

    if (localStorage.getItem('userAccounts')) {
        var userAccounts = JSON.parse(localStorage.getItem('userAccounts'));
        var newList = [];
        userAccounts.forEach(acc => {
            if (acc.id === accountId) {
                newList.push(acc);
            }
        });
        userAccounts.forEach(acc => {
            if (acc.id !== accountId) {
                newList.push(acc);
            }
        });
    }

    db.set('/users/' + userId + '/activeAccount', accountId).then(() => {
        if (getAppData()?.account?.settings?.id !== accountId) {
            window.location.replace('/');
        }
    });

    setUserStateChangedListener(userId, accountId);
    setAccountStateChangedListener(accountId, userId);
}

export function setAccountStateChangedListener(accountId, userId) {
    //force recalculate estimations every 1 min
    setInterval(() => {
        if (allDataLoaded && getAppData()?.lastRecalculate && getAppData()?.lastRecalculate < Date.now() - 60000) {
            dispatch(singleEngineRun());
        }
        if(getAppData().account?.settings?.id && getAppData().user?.data?.userId){
            db.r(`/usersConnected/${getAppData().account.settings.id}/${getAppData().user?.data?.userId}`).set({
                email: getAppData().user?.data?.email,
                accountName: getAppData()?.account?.settings?.name,
                version: getAppData().version
            });
        }
    }, 10000);

    var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    if (listeningAccountId) {
        db.off('.info/connected');
        db.off('/accounts/' + listeningAccountId);
        db.off(`/accounts/${listeningAccountId}/settings`);
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_added');
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_removed');
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_changed');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_added');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_removed');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_changed');
        db.off(`/accounts/${listeningAccountId}/skills`);
        db.off(`/accounts/${listeningAccountId}/daysoff`);
        db.off(`/accounts/${listeningAccountId}/chat`);
        db.off(`/accounts/${listeningAccountId}/logger`);
        db.off(`/accounts/${listeningAccountId}/skillGroups`);
        db.off(`/accounts/${listeningAccountId}/slackConnections`);
        db.off(`/accounts/${listeningAccountId}/userGroups`);
        db.off(`/accountsData/${listeningAccountId}/syncApp`);
        if (getAppData()?.account?.settings?.id && getAppData()?.account?.users && getAppData()?.account?.users[userId]) {
            database.ref('/accounts/' + listeningAccountId + '/users/' + userId + '/connected').set(isOfflineForDatabase);
        }
        db.r(`/usersConnected/${listeningAccountId}/${userId}`).remove();
    }

    listeningAccountId = accountId;

    const isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    const userConnectedRef = db.r(`/usersConnected/${accountId}/${userId}`);
    userConnectedRef.set(getAppData().version);
    userConnectedRef.onDisconnect().remove();

    db.on('.info/connected', function (snap) {
        if (snap.val() === true) {
            database
                .ref('/accounts/' + listeningAccountId + '/users/' + userId + '/connected')
                .onDisconnect()
                .set(isOfflineForDatabase)
                .then(function () {
                    if (
                        (
                            !getAppData()?.user?.data?.connected?.state === 'online' || isOnlineForDatabase.last_changed - getAppData()?.user?.data?.connected?.last_changed > 60000
                        ) && userId
                    ) {
                        database.ref('/accounts/' + listeningAccountId + '/users/' + userId + '/connected').set(isOnlineForDatabase);
                    }
                });
        }
    });

    db.on(`/accounts/${accountId}/settings`, accountSettings => {
        const accountSettingsData = accountSettings.val();

        if (accountSettingsData) {
            const settingsMapped = accountSettingsAdpater({ ...accountSettingsData, accountId });

            dispatch(setAccountSettings(settingsMapped));
            dispatch(setAccountFilters());
            dispatch(addPermissionsToTasks());

            const shouldUpdateEngine = compareSettings(getAppData().account.settings || {}, accountSettingsData || {});

            if(shouldUpdateEngine) {
                dispatch(singleEngineRun());
            }
        }
    });

    let doTasksUpdatesTimeout,
        doTasksUpdatesTasks = {};

    const doTasksUpdates = ({tasks = {}, type, taskId}) => {
        if(doTasksUpdatesTimeout){
            clearTimeout(doTasksUpdatesTimeout);
        }

        if((type==='added' || type==='changed') && Object.keys(doTasksUpdatesTasks).length){
            tasks = {
                ...doTasksUpdatesTasks,
                [taskId]: tasks[taskId]
            };
        }
        else if(type==='removed' && Object.keys(doTasksUpdatesTasks).length){
            tasks = doTasksUpdatesTasks;
            delete tasks[taskId];
        }

        doTasksUpdatesTasks = tasks;

        doTasksUpdatesTimeout = setTimeout(()=>{
            let list = _.flatten(newGetOrderedTaskList(tasks));
            const parentChildIds = getParentChildIds(list, tasks);
            dispatch(combinedUpdate({
                tasks: tasks,
                users: getAppData().account.users,
                orderedTaskList: list,
                parentChildIds: parentChildIds
            }));
            dispatch(addPermissionsToTasks());
            dispatch(singleEngineRun());
            doTasksUpdatesTasks = {};
        }, 50);
    };

    let tasksLoaded = false;

    db.r('/accounts/' + accountId + '/tasks').on('child_added', task =>{
        tasksLoaded = true;

        let tasks = getAppData().account.tasks || {};
        if(!tasks[task.key]){
            tasks[task.key] = task.val();
            tasks[task.key].index = parseInt(tasks[task.key].index);
            if(tasks[task.key].canView){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/canView');
                delete tasks[task.key].canView;
            }
            if(tasks[task.key].canManage){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/canManage');
                delete tasks[task.key].canManage;
            }
            if(tasks[task.key].canAdmin){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/canAdmin');
                delete tasks[task.key].canAdmin;
            }
            if(tasks[task.key].canWork){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/canWork');
                delete tasks[task.key].canWork;
            }
            if(tasks[task.key].estimations){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/estimations');
                delete tasks[task.key].estimations;
            }
            if(tasks[task.key].closedSortable){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/closedSortable');
                delete tasks[task.key].closedSortable;
            }
            if(tasks[task.key].path){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/path');
                delete tasks[task.key].path;
            }
            if(tasks[task.key].risks){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/risks');
                delete tasks[task.key].risks;
            }
            if(tasks[task.key].tasktype){
                db.remove('/accounts/' + accountId + '/tasks/' + task.key + '/tasktype');
                delete tasks[task.key].tasktype;
            }

            doTasksUpdates({tasks, type:'added', taskId:task.key});
        }
    });

    db.r('/accounts/' + accountId + '/tasks').on('child_removed', task =>{
        tasksLoaded = true;

        let tasks = getAppData().account.tasks || {};
        if(tasks[task.key]){
            delete tasks[task.key];
            doTasksUpdates({tasks, type:'removed', taskId:task.key});
        }
    });

    db.r('/accounts/' + accountId + '/tasks').on('child_changed', task =>{
        tasksLoaded = true;

        let tasks = getAppData().account.tasks || {};
        const newTaskData = task.val() || {};
        newTaskData.index = parseInt(newTaskData.index);

        if(tasks[task.key] && tasks[task.key].estimations){
            newTaskData.estimations = tasks[task.key].estimations;
        }
        if(tasks[task.key] && tasks[task.key].canView){
            newTaskData.canView = tasks[task.key].canView;
        }
        if(tasks[task.key] && tasks[task.key].canAdmin){
            newTaskData.canAdmin = tasks[task.key].canAdmin;
        }
        if(tasks[task.key] && tasks[task.key].canManage){
            newTaskData.canManage = tasks[task.key].canManage;
        }
        if(tasks[task.key] && tasks[task.key].canWork){
            newTaskData.canWork = tasks[task.key].canWork;
        }
        if(tasks[task.key] && tasks[task.key].path){
            newTaskData.path = tasks[task.key].path;
        }
        if(tasks[task.key] && tasks[task.key].risks){
            newTaskData.risks = tasks[task.key].risks;
        }

        if(tasks[task.key] && newTaskData){
            let taskList = {...tasks, [task.key]: newTaskData};

            if(compareTask(tasks[task.key], newTaskData)){
                doTasksUpdates({tasks: taskList, type:'changed', taskId:task.key});
            }
            else {
                dispatch(updateSingleTask({taskId: task.key, data: newTaskData}));
            }
        }
    });

    setTimeout(()=>{
        if(!tasksLoaded){
            dispatch(setTasksLoaded());
        }
    }, 3000);


    const doUsersUpdates = (users = {}) => {
        dispatch(setUsers(users));
        dispatch(addPermissionsToTasks());
        dispatch(singleEngineRun());
    };

    db.r('/accounts/' + accountId + '/users').on('child_added', user =>{
        let users = getAppData().account.users || {};
        if(!users[user.key]){
            users[user.key] = user.val();
            doUsersUpdates(users);
        }
    });

    db.r('/accounts/' + accountId + '/users').on('child_removed', user =>{
        let users = getAppData().account.users || {};
        if(users[user.key]){
            delete users[user.key];
            doUsersUpdates(users);
        }
    });

    db.r('/accounts/' + accountId + '/users').on('child_changed', user =>{
        let users = getAppData().account.users || {};
        const newUserData = user.val() || {};

        if(users[user.key] && newUserData){
            if(compareUser(users[user.key], newUserData)){
                doUsersUpdates({...users, [user.key]: newUserData});
            }
            else {
                dispatch(updateSingleUser({userId: user.key, data: newUserData}));
            }
        }
    });

    db.on('/accounts/' + accountId + '/skills', skills => {
        const shouldUpdateEngine = compareSkills(getAppData().account.skills || {}, skills.val() || {});
        dispatch(setSkills(skills.val() || {}));
        dispatch(addPermissionsToTasks());

        if(shouldUpdateEngine){
            dispatch(singleEngineRun());
        }
    });

    db.on(`/accounts/${accountId}/daysoff`, data => {
        dispatch(setDaysOff(data.val() || {}));
        dispatch(singleEngineRun());
    });

    db.on(`/accounts/${accountId}/chat`, data => {
        dispatch(setAccountPropertyData('chat', data.val()));
    });
    db.on(`/accounts/${accountId}/skillGroups`, data => {
        dispatch(setAccountPropertyData('skillGroups', data.val() || {}));
    });

    db.on(`/accounts/${accountId}/apiKeys`, data => {
        dispatch(setAccountPropertyData('apiKeys', data.val()));
    });

    db.on(`/accounts/${accountId}/slackConnections`, data => {
        dispatch(setAccountPropertyData('slackConnections', data.val()));
    });

    db.on(`/accounts/${accountId}/userGroups`, data => {
        dispatch(setAccountPropertyData('userGroups', data.val() || {}));
    });
    db.on(`/accounts/${accountId}/tags`, data => {
        dispatch(setAccountPropertyData('tags', data.val()));
    });

    db.on(`/accountsData/${accountId}/syncApp`, data => {
        const syncApps = data.val();
        if(syncApps && window?.analytics?.identify && authUser?.email && authUser?.uid) {
            window.analytics.identify(authUser.uid,
                {
                    email: authUser.email,
                    NbConnectedProjects: Object.values(syncApps).length,
                    NbUserMapped: _.flatten(Object.values(syncApps).map(el => Object.values(el.users || {}))).length,
                    NbUserMappedMatched: _.flatten(Object.values(syncApps).map(el => Object.values(el.users || {}))).filter(el => el.planlessId).length
                }
            );
            if(window.stonlyTrack){
                window.stonlyTrack('identify', authUser.uid, {
                    email: authUser.email,
                    NbConnectedProjects: Object.values(syncApps).length,
                    NbUserMapped: _.flatten(Object.values(syncApps).map(el => Object.values(el.users || {}))).length,
                    NbUserMappedMatched: _.flatten(Object.values(syncApps).map(el => Object.values(el.users || {}))).filter(el => el.planlessId).length
                });
            }
        }

        dispatch(setAccountDataSyncApps(syncApps));
    });
}

export function allDataLoadedActions() {
    allDataLoaded = true;

    var accountData = getAppData().account,
        companyName = null,
        address = null,
        city = null,
        country = null,
        postalCode = null,
        fiscalNumber = null;

    if (accountData) {
        Bugsnag.addMetadata = { account: accountData };
        selfCleanup({
            ...accountData,
            orderedTaskList: getAppData().orderedTaskList || [],
            parentChildIds: getAppData().parentChildIds || {},
        });

        if (
            !accountData.settings.lastSeen ||
            moment(accountData.settings.lastSeen).isBefore(moment().subtract(1, 'hours'))
        ) {
            var now = moment().format();
            db.set('/accounts/' + accountData.settings.id + '/settings/lastSeen', now);
            accountData.settings.lastSeen = now;
        }

        if(accountData.settings.endPlanAt && !moment(accountData.settings.endPlanAt).isSameOrAfter(moment(), 'day')) {
            updateIsTrial(accountData.settings.id);
        }

        if (accountData.settings.billingInfo) {
            companyName = accountData.settings.billingInfo.companyName;
            address = accountData.settings.billingInfo.address;
            city = accountData.settings.billingInfo.city;
            country = accountData.settings.billingInfo.country;
            postalCode = accountData.settings.billingInfo.postalCode;
            fiscalNumber = accountData.settings.billingInfo.fiscalNumber;
        }

        if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
            var Nbmembers = 0,
                Nbviewers = 0;
            for (var t in accountData.users) {
                if (accountData.users[t].viewer) {
                    Nbviewers++;
                } else {
                    Nbmembers++;
                }
            }

            window.analytics.group(accountData.settings.id, {
                accountId: accountData.settings.id,
                name: companyName || accountData.settings.name,
                address: address,
                city: city,
                country: country,
                postalCode: postalCode,
                fiscalNumber: fiscalNumber,
                plan: accountData.settings.plan,
                endPlan_at: accountData.settings.endPlanAt,
                created_at: accountData.settings.createdAt,
                planNbusers: accountData.settings.planNbusers,
                chargebeeCustomerId: accountData.settings.chargebeeCustomerId,
                Nbtasks: accountData.tasks ? Object.keys(accountData.tasks).length : 0,
                Nbmembers: Nbmembers,
                Nbviewers: Nbviewers,
                Nbskills: accountData.skills ? Object.keys(accountData.skills).length : 0,
                isTrial: accountData.settings.isTrial,
                isLifetime: accountData.settings.isLifetime,
                subscriptionCanceled: accountData.settings.subscriptionCanceled,
            });

            if(window.mixpanel && window.mixpanel.get_group){
                let end = new Date(accountData.settings.endPlanAt);
                if(end.getFullYear() > 2099){
                    end.setFullYear(2099);
                }

                window.mixpanel.get_group('accountId', accountData.settings.id).set({
                    accountId: accountData.settings.id,
                    name: companyName || accountData.settings.name,
                    address: address,
                    city: city,
                    country: country,
                    postalCode: postalCode,
                    fiscalNumber: fiscalNumber,
                    plan: accountData.settings.plan,
                    endPlan_at: end.toJSON(),
                    created_at: accountData.settings.createdAt,
                    planNbusers: accountData.settings.planNbusers,
                    chargebeeCustomerId: accountData.settings.chargebeeCustomerId,
                    Nbtasks: accountData.tasks ? Object.keys(accountData.tasks).length : 0,
                    Nbmembers: Nbmembers,
                    Nbviewers: Nbviewers,
                    Nbskills: accountData.skills ? Object.keys(accountData.skills).length : 0,
                });
            }
        }
    }
}


async function selfCleanup(accountData) {
    let updates = {};
    let taskDependenciesError = {};
    let childrenIndexes = {};
    let parent;

    const accountId = getAccountId(getState());
    const chargebeeCustomerId = getAccountChargebeeCustomerId(getState());
    const currentUserId = getUserId(getState());
    const currentUserEmail = getUserEmail(getState());
    const momentTimezonesList = moment.tz.names();

    if(!currentUserId) {
        return;
    }
    if (accountId && currentUserEmail && !chargebeeCustomerId) {
        createChargeBeeCustomer({
            accountId: accountId,
            email: currentUserEmail
        });
    }
    if (accountId && !accountData.settings.costBudget) {
        updates[`/accounts/${accountId}/settings/costBudget`] = {
            currency: 'USD',
            isOn: true,
        };
    }

    for (var taskId in accountData.tasks) {
        var task = accountData.tasks[taskId],
            childrens = task.childrens ? [...task.childrens] : [];
        parent = task.parent;

        if (typeof task.skill === 'string') {
            updates[`/accounts/${accountId}/tasks/${taskId}/skill`] = [task.skill];
        }

        const cleanUpDependeciesResult = cleanUpDependeciesOfTask({
            taskId,
            allTasks: accountData.tasks,
        });
        updates = _.extend(updates, cleanUpDependeciesResult);

        if (parent && !accountData.tasks[parent]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId] = null;
        } else if (parent && parent === taskId) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId] = null;
        } else if (parent) {
            var found = false,
                childs = accountData.tasks[parent].childrens ? [...accountData.tasks[parent].childrens] : [];

            childs.forEach(item => {
                if (item && item.type === 'task' && item.id === taskId) {
                    found = true;
                }
            });

            if (!found) {
                childs.push({ type: 'task', id: taskId });
                updates['/accounts/' + accountData.settings.id + '/tasks/' + parent + '/childrens'] = childs;
            }
        }

        if (updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId] !== null) {

            for(var followerId in task.followers){
                if(
                    !getItemPermissions(
                        followerId,
                        taskId,
                        accountData.tasks[taskId].permissions,
                        accountData.tasks,
                        accountData.users[followerId]?.permissions?.admin || false,
                        accountData?.settings?.basePermissions)
                        .canView
                ){
                    updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/followers/' + followerId] = null;
                }
            }

            var childrensChanges = [];

            childrens.forEach((item, index) => {
                if (item) {
                    if (accountData.tasks) {
                        if (!accountData.tasks[item.id]) {
                            childrensChanges.push(index);
                        } else if (
                            !accountData.tasks[item.id].parent ||
                            accountData.tasks[item.id].parent !== taskId
                        ) {
                            childrensChanges.push(index);
                        }
                    }
                }
            });

            if (childrensChanges.length) {
                childrensChanges = childrensChanges.reverse();
                childrensChanges.forEach(i => {
                    childrens.splice(i, 1);
                });
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/childrens'] = childrens;
            }
            else if(task.childrens) {
                //check for indexes of childrens
                let temp = [];
                task.childrens.forEach((t)=>{
                    if(accountData.tasks[t.id]){
                        temp.push({id: t.id, index: (parseInt(accountData.tasks[t.id].index) >=0 )?parseInt(accountData.tasks[t.id].index):0});
                    }
                });
                temp = _.sortBy(temp, 'index');
                temp.forEach((t,i)=>{
                    if(parseInt(accountData.tasks[t.id].index) !== i){
                        updates['/accounts/' + accountData.settings.id + '/tasks/' + t.id + '/index'] = i;
                    }
                });
            }

            if(parseInt(task.index) < 0 && Object.keys(updates).indexOf('/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/index') === -1){
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/index'] = 0;
            }

            taskDependenciesError[taskId] = [];

            if (task.dependentOnThis) {
                var tempDependentOnThis = [];
                task.dependentOnThis.forEach((dep, index) => {
                    if (!accountData.tasks[dep]) {
                        taskDependenciesError[taskId].push(dep + ' in ' + taskId + ' dependentOnThis but not found');
                        tempDependentOnThis.push(index);
                    } else if (!accountData.tasks[dep].dependencies) {
                        taskDependenciesError[taskId].push(dep + ' in ' + taskId + ' dependencies not present');
                        tempDependentOnThis.push(index);
                    } else if (!accountData.tasks[dep].dependencies.indexOf(taskId) === -1) {
                        taskDependenciesError[taskId].push(dep + ' dependencies does not contain ' + taskId);
                        tempDependentOnThis.push(index);
                    }
                });

                if (tempDependentOnThis.length) {
                    tempDependentOnThis = tempDependentOnThis.reverse();
                    var temp = [...task.dependentOnThis];
                    tempDependentOnThis.forEach(i => {
                        temp.splice(i, 1);
                    });
                    if (temp.length === 0) {
                        temp = null;
                    }
                    updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/dependentOnThis'] = temp;
                }
            }

            if (task.dependencies) {
                var tempDependencies = [];
                task.dependencies.forEach((dep, index) => {
                    if (!accountData.tasks[dep]) {
                        taskDependenciesError[taskId].push(dep + ' in ' + taskId + ' dependencies but not found');
                        tempDependencies.push(index);
                    } else if (!accountData.tasks[dep].dependentOnThis) {
                        taskDependenciesError[taskId].push(dep + ' in ' + taskId + ' dependentOnThis not present');
                        tempDependencies.push(index);
                    } else if (!accountData.tasks[dep].dependentOnThis.indexOf(taskId) === -1) {
                        taskDependenciesError[taskId].push(dep + ' dependentOnThis does not contain ' + taskId);
                        tempDependencies.push(index);
                    }
                });

                if (tempDependencies.length) {
                    tempDependencies = tempDependencies.reverse();
                    var temp2 = [...task.dependencies];
                    tempDependencies.forEach(i => {
                        temp2.splice(i, 1);
                    });
                    if (temp2.length === 0) {
                        temp2 = null;
                    }
                    updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/dependencies'] = temp2;
                }
            }

            if (taskDependenciesError[taskId].length === 0) {
                delete taskDependenciesError[taskId];
            }

            if (parent) {
                if (!childrenIndexes[parent]) {
                    childrenIndexes[parent] = {};
                }
                if (childrenIndexes[parent][parseInt(task.index)]) {
                    childrenIndexes[parent][parseInt(task.index) + '_' + taskId] = { type: 'task', id: taskId };
                } else {
                    childrenIndexes[parent][parseInt(task.index)] = { type: 'task', id: taskId };
                }
            } else {
                if (!childrenIndexes['root']) {
                    childrenIndexes['root'] = {};
                }
                if (childrenIndexes['root'][parseInt(task.index)]) {
                    childrenIndexes['root'][parseInt(task.index) + '_' + taskId] = { type: 'task', id: taskId };
                } else {
                    childrenIndexes['root'][parseInt(task.index)] = { type: 'task', id: taskId };
                }
            }

            if (task.dependenciesCalc) {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/dependenciesCalc'] = null;
            }
            if (task.dependentOnThisCalc) {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/dependentOnThisCalc'] = null;
            }

            if (task.nbWorkers && typeof task.nbWorkers === 'string') {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/nbWorkers'] = parseInt(task.nbWorkers);
            }
        }

        if (updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId] !== null && task.forcedUser) {
            let forced = typeof task.forcedUser === 'string' ? [task.forcedUser] : task.forcedUser,
                final = [];

            forced.forEach(u => {
                if (accountData.users[u]) {
                    final.push(u);
                }
            });
            if (final.length !== forced.length) {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/forcedUser'] = final;
            }
        }

        // clean permissions. Permissions can only be on a task/folder itself, base permissions or a direct/undirect parent
        if (
            updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId] !== null &&
            task.permissions?.comingFrom !== 'base' &&
            task.permissions?.comingFrom !== taskId &&
            task.permissions?.comingFrom !== task.parent
        ) {
            if(!_.isEmpty(accountData.parentChildIds.parents) && !accountData.parentChildIds?.parents[taskId]){
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/permissions'] = {comingFrom:'base'};
            }
            else if(accountData.tasks[task.parent] && !_.isEmpty(accountData.parentChildIds.parents) && accountData.parentChildIds?.parents[taskId].indexOf(task.permissions?.comingFrom) === -1){
                updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/permissions'] = {
                    comingFrom: accountData.tasks[task.parent].permissions.comingFrom
                };
            }
        }


    }

    //check first level indexes
    let firstLevel = {};
    for (var tId in accountData.tasks) {
        if(!accountData.tasks[tId].parent){
            let index = parseInt(accountData.tasks[tId].index);
            while(firstLevel[index]){
                index++;
            }
            firstLevel[index] = tId;
        }
    }
    var currIndex = 0;
    for (var i in firstLevel) {
        if(
            parseInt(accountData.tasks[firstLevel[i]].index) !== currIndex &&
            Object.keys(updates).indexOf('/accounts/' + accountData.settings.id + '/tasks/' + firstLevel[i]) === -1 &&
            Object.keys(updates).indexOf('/accounts/' + accountData.settings.id + '/tasks/' + firstLevel[i] + '/index') === -1
        ){
            updates['/accounts/' + accountData.settings.id + '/tasks/' + firstLevel[i] + '/index'] = i;
        }
    }

    let colorsPicked = [];
    for (var userId in accountData.users) {
        if(!accountData.users[userId].userId){
            updates['/accounts/' + accountData.settings.id + '/users/' + userId] = null;
        }
        else {
            if(!accountData.users[userId].color) {
                const color = pickColor({
                    accountUsers: accountData.users,
                    usedColors: colorsPicked
                });
                colorsPicked.push(color);
                updates[`/accounts/${accountData.settings.id}/users/${userId}/color`] = color;
            }

            if (!accountData.users[userId].displayName || accountData.users[userId].displayName === '') {
                updates['/accounts/' + accountData.settings.id + '/users/' + userId + '/displayName'] = 'Untitled';
            }
            for (var filterId in accountData.users[userId].filterPresets) {
                if (accountData.users[userId].filterPresets[filterId].filters.search('"id":"affected"') !== -1) {
                    let fixed = accountData.users[userId].filterPresets[filterId].filters.replaceAll(
                        '"id":"affected"',
                        '"id":"assigned"',
                    );
                    updates[
                        '/accounts/' + accountData.settings.id + '/users/' + userId + '/filterPresets/' + filterId + '/filters'
                    ] = fixed;
                }
            }
            if (!accountData.users[userId].timezone || momentTimezonesList.indexOf(accountData.users[userId].timezone) === -1) {
                updates['/accounts/' + accountData.settings.id + '/users/' + userId + '/timezone'] =
                    moment.tz.guess() || 'Europe/London';
            }

            if (accountData.users[userId].engineAvailabilities) {
                updates['/accounts/' + accountData.settings.id + '/users/' + userId + '/engineAvailabilities'] = null;
            }

            if (accountData.users[userId].viewer &&
                (!accountData.settings?.basePermissions?.viewers || accountData.settings.basePermissions.viewers.indexOf(userId) === -1)
            ) {
                updates['/accounts/' + accountData.settings.id + '/settings/basePermissions/viewers'] = [...(accountData?.settings?.viewers || []), userId];
            }
        }
    }

    if (Object.keys(updates).length > 0) {
        const chunks = _.chunk(Object.keys(updates), 50);
        const promiseLoop = async (op) => {
            if(!op || !op.length) {
                return;
            }
            var onIt = op.pop();
            var doIt = await onIt();

            return [doIt, await promiseLoop(op)];
        };

        const updateToExecute = chunks.map((updateKeys) => {
            const newUpdateSet = updateKeys.reduce((acc,updateKey) => {
                return {
                    ...acc,
                    [updateKey]: updates[updateKey]
                };
            }, {});
            return () => db.update(undefined, newUpdateSet);
        });
        await promiseLoop(updateToExecute);
    }
}
export function addMemberDayoff(accountId, userId, dateRange, intl) {

    const value = {
        startDate: dateRange.startDate.format(),
        endDate: dateRange.endDate.format(),
    };
    db.push('/accounts/' + accountId + '/users/' + userId + '/daysoff', value).then(()=>{
        segmentTrack('Add member dayoff', { accountId, userId, value });
        toastr.success(intl.formatMessage({id:'member.dayoff.added'}));
    });

}

export function deleteMemberDayoff(accountId, userId, dayoffId, intl) {

    db.set('/accounts/' + accountId + '/users/' + userId + '/daysoff/' + dayoffId, null).then(()=>{
        segmentTrack('Delete member dayoff', { accountId, userId, dayoffId });
        toastr.success(intl.formatMessage({id:'member.dayoff.deleted'}));
    });
}

export function updateMemberData(accountId, userId, data) {
    if(!accountId ||  !userId){
        return;
    }

    segmentTrack('Update member data', { accountId, userId, data });
    db.update('/accounts/' + accountId + '/users/' + userId, data);

    if (data.uid) {
        db.update('/users/' + userId, { uid: data.uid });
    }
}

export function createNewMember({ memberType = MEMBER_TYPES.userAndResource, accountId = null, skills = null, groupId = null, displayName = '', email = null }) {
    const accountUsers = getAccountUsers(getState());
    const usersKeys = Object.keys(accountUsers).filter((id)=>!accountUsers[id]?.viewer);

    let accountData = getAppData().account;

    // only triggers the first steps if the is only one user it means it is creating the first new one
    handleFirstStepsAccount({ type: 'users' });

    const isAdmin = memberType !== MEMBER_TYPES.resourceOnly;
    const key = db.pushkey('/users');
    const value = {
        accountId: accountId,
        memberType,
        group: groupId,
        userId: key,
        displayName: displayName || 'New Member',
        emailNotifications:true,
        browserNotifications: false,
        email,
        schedule: [
            { d: '1', day: 'Mon', start: '09:00', end: '12:00', nbMin: 180 },
            { d: '1', day: 'Mon', start: '13:00', end: '18:00', nbMin: 300 },
            { d: '2', day: 'Tue', start: '09:00', end: '12:00', nbMin: 180 },
            { d: '2', day: 'Tue', start: '13:00', end: '18:00', nbMin: 300 },
            { d: '3', day: 'Wed', start: '09:00', end: '12:00', nbMin: 180 },
            { d: '3', day: 'Wed', start: '13:00', end: '18:00', nbMin: 300 },
            { d: '4', day: 'Thu', start: '09:00', end: '12:00', nbMin: 180 },
            { d: '4', day: 'Thu', start: '13:00', end: '18:00', nbMin: 300 },
            { d: '5', day: 'Fri', start: '09:00', end: '12:00', nbMin: 180 },
            { d: '5', day: 'Fri', start: '13:00', end: '18:00', nbMin: 300 },
        ],
        isAdmin: isAdmin,
        skills: skills,
        timezone: moment.tz.guess() || 'Europe/London',
        permissions: {
            admin: isAdmin,
            team: isAdmin,
            skills: isAdmin,
            daysoff: isAdmin,
            boards: isAdmin,
            subscription: isAdmin,
            workload: [...usersKeys, key],
        },
        color: pickColor({
            accountUsers: accountData.users,
        })
    };

    let updates = {
        [`/users/${key}`]: { accounts: [accountId]},
        [`/accounts/${accountId}/users/${key}`]: value
    };

    usersKeys.forEach(userKey => {
        if(accountUsers[userKey]?.permissions?.admin) {
            updates[`/accounts/${accountId}/users/${userKey}/permissions/workload`] =  [...usersKeys, key];
        }
    });

    var data = addChatParticipants('channels', 'general', [key], accountData, true);
    updates = _.extend(updates, data.updates);

    var basePermissions = accountData.settings.basePermissions;

    if(memberResourcersTypes.includes(memberType)) {
        basePermissions.workers.push(key);
    }

    var data2 = setBasePermissions(accountId, basePermissions, accountData, true, false);
    updates = _.extend(updates, data2.updates);

    db.update(undefined, updates);
    segmentTrack('Create new member', { accountId, skills, memberId: key, memberType});
    addChallenge('addMember');

    return new Promise((resolve) => {
        resolve(key);
    });
}

export function createNewViewer(accountId, skills, groupId, intl) {
    var key = db.pushkey('/users');
    segmentTrack('Create new viewer', { accountId, viewerId: key });

    db.update(undefined, {
        ['/users/' + key]: {accounts: [accountId]},
        ['/accounts/' + accountId + '/users/' + key]: {
            accountId: accountId,
            userId: key,
            viewer: true,
            displayName: intl.formatMessage({id:'New Viewer'}),
            isAdmin: false,
            group: null,
            skills: skills,
            timezone: moment.tz.guess() || 'Europe/London',
            permissions: { admin: false, team: false, skills: false, daysoff: false, boards: false, subscription: false },
        },
        ['/accounts/' + accountId + '/settings/basePermissions/viewers']: getAppData().account.settings?.basePermissions?.viewers ? [...getAppData().account.settings?.basePermissions?.viewers, key] : [key]
    });

    return key;
}

export function deleteMember(accountId, memberId, intl, isViewer) {
    if (accountId && memberId) {

        var updates = {};

        _.each(getAppData().account.tasks, (task, taskId) => {
            if (typeof task.userWorking === 'string') {
                task.userWorking = [task.userWorking];
            }
            if (task.userWorking && task.userWorking.indexOf(memberId) !== -1) {
                var oldUserWorking = task.userWorking;
                oldUserWorking.splice(task.userWorking.indexOf(memberId), 1);
                if (oldUserWorking.length === 0) {
                    oldUserWorking = null;
                }
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/userWorking'] = oldUserWorking;
            }
            if (
                task.forcedUser &&
                ((typeof task.forcedUser === 'string' && task.forcedUser === memberId) ||
                    task.forcedUser.indexOf(memberId) !== -1)
            ) {
                var oldForcedUser = task.forcedUser;
                if (typeof oldForcedUser === 'string') {
                    oldForcedUser = [oldForcedUser];
                }
                oldForcedUser.splice(task.forcedUser.indexOf(memberId), 1);
                if (oldForcedUser.length === 0) {
                    oldForcedUser = null;
                }
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/forcedUser'] = oldForcedUser;
            }

            for (var id in task.followers) {
                if (id === memberId) {
                    updates['/accounts/' + accountId + '/tasks/' + taskId + '/followers'] = task.followers;
                    break;
                }
            }

            // remove user from tasks permissions
            if (task.permissions && task.permissions.owners && task.permissions.owners.indexOf(memberId) !== -1) {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/permissions/owners'] = task.permissions.owners.splice(task.permissions.owners.indexOf(memberId), 1);
            }
            if (task.permissions && task.permissions.managers && task.permissions.managers.indexOf(memberId) !== -1) {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/permissions/managers'] = task.permissions.managers.splice(task.permissions.managers.indexOf(memberId), 1);
            }
            if (task.permissions && task.permissions.workers && task.permissions.workers.indexOf(memberId) !== -1) {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/permissions/workers'] = task.permissions.workers.splice(task.permissions.workers.indexOf(memberId), 1);
            }
            if (task.permissions && task.permissions.viewers && task.permissions.viewers.indexOf(memberId) !== -1) {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/permissions/viewers'] = task.permissions.viewers.splice(task.permissions.viewers.indexOf(memberId), 1);
            }
        });

        _.each(getAppData().account.users, (user, userId) => {
            // remove user from users permissions
            if (userId !== memberId && user.permissions && user.permissions.workload && user.permissions.workload.indexOf(memberId) !== -1) {
                updates['/accounts/' + accountId + '/users/' + userId + '/permissions/workload'] = user.permissions.workload.splice(user.permissions.workload.indexOf(memberId), 1);
            }
        });

        db.once('/users/' + memberId).then(user => {
            user = { ...user.val() };

            if (!user.accounts) {
                user = null;
            } else if (!user.uid && user.accounts.length === 1 && user.accounts[0] === accountId) {
                user = null;
            } else {
                user.accounts = user.accounts.filter(val => {
                    return val !== accountId;
                });
                if (user.activeAccount === accountId) {
                    user.activeAccount = null;
                }
            }

            db.set('/users/' + memberId, user);
        });

        updates['/accounts/' + accountId + '/users/' + memberId] = null;

        db.update(undefined, updates);

        segmentTrack('Delete member', { accountId, memberId });
        if (isViewer) {
            toastr.success(intl.formatMessage({id:'viewer.deleted'}));
        } else {
            toastr.success(intl.formatMessage({id:'member.deleted'}));
        }
    }
}

export function updateSkillData(accountId, skillId, data) {
    db.set(
        '/accounts/' + accountId + '/skills/' + skillId,
        _.extend({...getAppData().account.skills[skillId]}, data)
    );
    segmentTrack('Update skill data', { accountId, skillId, data });
}

export function deleteSkill(skillId, accountId, intl) {
    if (accountId && skillId) {
        var updates = {};

        updates['/accounts/' + accountId + '/skills/' + skillId] = null;

        _.each(getAppData().account.users, (user, userId) => {
            if (user.skills && user.skills[skillId]) {
                updates['/accounts/' + accountId + '/users/' + userId + '/skills/' + skillId] = null;
            }
        });

        _.each(getAppData().account.tasks, (task, taskId) => {
            if (task.skill === skillId) {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/skill'] = null;
            }
        });

        db.update(undefined, updates);

        segmentTrack('Delete skill data', { skillId, accountId });
        toastr.success(intl.formatMessage({id:'skill.deleted'}));
    }
}

export function createNewSkill(accountId, groupId, intl) {
    // handle the steps
    let accountData = getAppData().account;
    var updates = {};

    if (!groupId) {
        groupId = null;
    }

    var key = db.pushkey('/accounts/' + accountId + '/skills');
    const newData = {
        name: intl('New Skill'),
        factor: 2,
    };
    if (groupId) {
        newData.group = groupId;
    }

    // only triggers the first steps if there are no skills
    handleFirstStepsAccount({ type: 'skills' });

    updates['/accounts/' + accountId + '/skills/' + key] = newData;

    // add to all members of the account
    _.each(accountData.users, (user, userId) => {
        updates['/accounts/' + accountId + '/users/' + userId + '/skills/' + key] = 0;
    });

    db.update(undefined, updates);

    segmentTrack('Create new skill', { accountId, skillId: key });
    return key;
}

export function addSkillToUsers({ skillKey, usersAndSkillLvl }) {
    // handle the steps
    const accountId = getAppData().account.settings.id;
    const updates = usersAndSkillLvl.reduce((acc, el) => {
        acc['/accounts/' + accountId + '/users/' + el.userId + '/skills/' + skillKey] = el.skillLvl;
        return acc;
    }, {});

    segmentTrack('ChangedSkillLevel', { accountId, skillId: skillKey });
    db.update(undefined, updates);
}

export function removeSkillFromUsers({ skillKey, usersToRemoveSkill }) {
    const accountId = getAppData().account.settings.id;
    const updates = usersToRemoveSkill.reduce((acc, el) => {
        acc['/accounts/' + accountId + '/users/' + el + '/skills/' + skillKey] = null;
        return acc;
    }, {});

    segmentTrack('RemovedSkill', { accountId, skillId: skillKey });
    db.update(undefined, updates);
}

export function createNewSkillIsolated(skillName, groupId = null) {

    // handle the steps
    const accountId = getAppData().account.settings.id;
    const key = db.pushkey('/accounts/' + accountId + '/skills');
    const newData = {
        name: skillName,
        factor: 2
    };

    if(groupId) { newData.group = groupId; }
    // only triggers the first steps if there are no skills
    handleFirstStepsAccount({ type: 'skills' });

    db.set('/accounts/' + accountId + '/skills/' + key, newData);


    segmentTrack('Create new skill', { accountId, skillId: key });

    return key;
}

export function addAccountDayoff(dateRange, intl) {
    tracking('Added global days-off');
    db.push('/accounts/' + getAppData().account.settings.id + '/daysoff', {
        startDate: dateRange.startDate.format(),
        endDate: dateRange.endDate.format(),
    });
    toastr.success(intl.formatMessage({id:'account.dayoff.added'}));
}

export function deleteAccountDayoff(accountId, dayoffId, intl) {
    toastr.success(intl.formatMessage({id:'account.dayoff.deleted'}));
    db.remove('/accounts/' + accountId + '/daysoff/' + dayoffId);
}

export function sendMemberInvite(accountId, memberId, memberData, email, inviterName, workspaceName, intl, showToast = true) {
    const originalMemberId = memberId;
    let updates = {},
        error = false;
    // search for /users with the email
    db.equal('/users', 'email', email, u => {
        // if found the user with the email
        if (u.val()) {
            var userId = Object.keys(u.val())[0];
            var user = u.val()[userId];

            // if the user has already accounts
            if (user.accounts) {
                if (user.accounts.indexOf(accountId) !== -1) {
                    error = true;
                } else {
                    // add the accountId to his accounts
                    user.accounts.push(accountId);
                }
            } else {
                // create the accounts property with [accountId]
                user.accounts = [accountId];
            }
            if (!error) {
                updates['/users/' + userId + '/accounts'] = user.accounts;

                if (_.isEmpty(memberData)) {
                    memberData = {
                        ...getAppData().account.users[memberId],
                    };
                }
                // in both cases, change the account user with the id found in /users

                updates['/accounts/' + accountId + '/users/' + memberId] = null;

                memberData.uid = user.uid;
                memberData.email = email;
                memberData.userId = userId;
                memberData.engineAvailabilities = null;
                memberData.lastEngineDate = null;
                if (user.avatar) {
                    memberData.avatar = user.avatar;
                }
                if (user.avatarStoragePath) {
                    memberData.avatarStoragePath = user.avatarStoragePath;
                }

                updates['/accounts/' + accountId + '/users/' + userId] = memberData;

                memberId = userId;
                // check permissions
                const basePermissions = getAppData().account?.settings?.basePermissions;
                if(basePermissions){
                    Object.keys(basePermissions).forEach(el => {
                        if(basePermissions[el] && basePermissions[el].includes(originalMemberId)){
                            updates[`/accounts/${accountId}/settings/basePermissions/${el}`] = [
                                ...basePermissions[el].filter(userIdIn => userIdIn !== originalMemberId),
                                memberId
                            ];
                        }
                    });
                }
                // add user to general chat if not a viewer
                if (!memberData.viewer) {
                    var generalChannelParticipants = [...getAppData()?.account?.chat.channels.general.participants].filter(el => el !== originalMemberId);
                    generalChannelParticipants.push(userId);

                    updates['/accounts/' + accountId + '/chat/channels/general/participants'] = generalChannelParticipants;
                }
            }
        }
        // if not found
        else {
            // use the actual member id in /users and add his email
            updates['/users/' + memberId + '/email'] = email;
            updates['/accounts/' + accountId + '/users/' + memberId + '/email'] = email;
        }

        if (!error) {
            // send invite to email
            $.ajax({
                url: CLOUD_FUNCTIONS.sendMemberInviteEmailNew,
                type: 'GET',
                data: {
                    inviterName: inviterName,
                    email: email,
                    workspaceName: workspaceName,
                },
                success: function () { },
                error: function () { },
            });

            removeMemberFromTasksWorkers(originalMemberId);
            db.update(undefined, updates);

            if (showToast) {
                if (!memberData.viewer) {
                    toastr.success(intl.formatMessage({id:'sent.invite.member'}));
                }
                else {
                    toastr.success(intl.formatMessage({id:'sent.invite.viewer'}));
                }
            }
            segmentTrack('Send member invite', { inviterName, memberId, email, accountId, workspaceName });
        } else {
            if (showToast) {
                toastr.error(intl.formatMessage({id:'sent.invite.member.already.exist'}));
            }
        }
    });
}

export function resendMemberInvite(email, inviterName, workspaceName, intl) {
    // send invite to email
    $.ajax({
        url: CLOUD_FUNCTIONS.sendMemberInviteEmailNew,
        type: 'GET',
        data: {
            inviterName: inviterName,
            email: email,
            workspaceName: workspaceName,
        },
        success: function () { },
        error: function () { },
    });
    toastr.success(intl.formatMessage({id:'sent.invite.member'}));
}

export function resetPassword(email, intl, closeAction) {
    segmentTrack('Ask password reset');

    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(
            () => {
                toastr.success(intl.formatMessage({id:'We sent you an email to reset your password!'}));
                closeAction();
            },
            error => {
                toastr.error(intl.formatMessage({id:error.message}));
            },
        );
}

function dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2);
}

function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}

export function changeAvatar(accountIds, userId, file, intl) {
    var filename = generateId(24);
    var ref = storage.ref();
    var docRef = ref.child('newAvatars/' + filename);

    var metadata = {
        contentType: file.type,
        cacheControl: 'public,max-age=31536000',
    };

    docRef.put(file, metadata).then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
            db.once('/users/' + userId).then(user => {
                if (user.val().avatarStoragePath) {
                    storage.ref(user.val().avatarStoragePath).delete();
                }

                accountIds.forEach(a => {
                    updateMemberData(a.id, userId, { avatar: downloadURL, avatarStoragePath: 'newAvatars/' + filename });
                });

                db.set('/users/' + userId + '/avatar', downloadURL);
                db.set('/users/' + userId + '/avatarStoragePath', 'newAvatars/' + filename);

                segmentTrack('Changed avatar', { accountIds, userId, downloadURL });
                toastr.success(intl('Your avatar was succesfully changed!'));
            });
        });
    });
}

export function changeAccountEmail(email, intl) {
    var t = intl;
    firebase
        .auth()
        .currentUser.updateEmail(email)
        .then(
            function () {
                db.set('/users/' + getAppData()?.user?.data?.userId + '/email', email);
                document.location.reload();
            },
            function (error) {
                toastr.error(t(error.message));
            },
        );
}

export function logOut(intl) {
    if(listeningAccountId && getAppData()?.user?.data?.userId){
        db.r(`/usersConnected/${listeningAccountId}/${getAppData().user.data.userId}`).remove();
    }

    segmentTrack('Logout');

    // get the user accounts plans
    if (listeningAccountId) {
        db.off(`/accounts/${listeningAccountId}/settings`);
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_added');
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_removed');
        db.r(`/accounts/${listeningAccountId}/tasks`).off('child_changed');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_added');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_removed');
        db.r(`/accounts/${listeningAccountId}/users`).off('child_changed');
        db.off(`/accounts/${listeningAccountId}/skills`);
        db.off(`/accounts/${listeningAccountId}/daysoff`);
        db.off(`/accounts/${listeningAccountId}/chat`);
        db.off(`/accounts/${listeningAccountId}/logger`);
        db.off(`/accounts/${listeningAccountId}/skillGroups`);
        db.off(`/accounts/${listeningAccountId}/slackConnections`);
        db.off(`/accounts/${listeningAccountId}/userGroups`);
        listeningAccountId = null;
    }
    dispatch(resetData(null));
    allDataLoaded = false;

    firebase
        .auth()
        .signOut()
        .then(() => {
            dispatch(resetData(null));

            for (var i in localStorage) {
                if (i.indexOf('firebase:authUser') !== -1) {
                    localStorage.removeItem(i);
                }
            }
            browserHistory.push('/login');
        })
        .catch(function (error) {
            toastr.error(intl.formatMessage({id:error.message}));
        });
}

export function createNewTask({
    title,
    skill,
    parentId,
    assignee,
    priority,
    maxEffort,
    minEffort,
    dueDate,
    delayDate,
    dependencies,
    tags
}) {
    let accountData = getAppData().account;
    const accountId = getAccountId(getState());
    const userId = getUserId(getState());
    segmentTrack('Add task', { accountId: accountData.settings.id });

    if(parentId) {
        segmentTrack('Add subtask', { accountId: accountData.settings.id });
    }

    const updates = {};
    const index = 0;
    const newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');

    // only triggers the first steps if there are no tasks
    handleFirstStepsAccount({ type: 'tasks' });

    const newTaskData = {
        createdAt: moment().format(),
        permissions: { comingFrom: parentId ? accountData.tasks[parentId]?.permissions?.comingFrom : 'base' },
        index: index || 0,
        title: title,
        status: 'todo',
        createdBy: userId,
        followers: { [userId]: 'manually' },
        canView: true,
        skill: skill,
        forcedUser: assignee ? [assignee] : [],
        priority,
        maxEffort,
        minEffort,
        deadline: dueDate,
        delayUntil: delayDate,
        dependencies,
        tags,
        parent: parentId
    };

    // if there is a parentId we add the new task to the parent childrens
    if (parentId) {
        const newChilds = accountData.tasks[parentId].childrens ? [{ id: newTaskKey, type: 'task' }, ...accountData.tasks[parentId].childrens] : [{ id: newTaskKey, type: 'task' }];
        const taskFollowers = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
            acc[userId] = 'inherited';
            return acc;
        }, {});

        newTaskData.followers = taskFollowers;

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = newChilds;
    }


    var tasks = getTasksFromParent(parentId);
    let newIndexes = {};
    //insert new task
    newIndexes[newTaskKey] = {id: newTaskKey,index};

    tasks.forEach(t => {
        var tIndex = t.index;

        if (t.index >= index) {
            tIndex = t.index + 1;
        }
        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(item.id === newTaskKey){
            if(index !== currindex){
                newTaskData.index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });
    updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = newTaskData;

    dispatch(hideLoader());
    changeChallengeTaskCount(1);

    db.update(undefined, updates);
}

export function addTask(accountId, parentId, title, key, content, userId) {
    segmentTrack('Add task', { accountId });

    var tasks = getAppData().account?.tasks || {};
    var updates = {};
    var index = 0;
    var newTaskKey;

    // only triggers the first steps if there are no tasks
    handleFirstStepsAccount({ type: 'tasks' });

    if (!key) {
        newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');

        var followObj = {};
        var permissions;

        if (parentId) {
            permissions = { comingFrom: tasks[parentId].permissions.comingFrom };
            followObj = [userId, ...Object.keys(_.get(tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
                acc[userId] = 'inherited';
                return acc;
            }, {});
        } else {
            permissions = { comingFrom: 'base' };
            followObj[userId] = 'inherited';
        }
        let newTaskData = {
            createdAt: moment().format(),
            index: index || 0,
            title: title || '',
            maxEffort: 1,
            status: 'todo',
            createdBy: userId,
            followers: followObj,
            permissions: permissions,
        };
        if (parentId) {
            newTaskData.parent = parentId;
        }
        updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = newTaskData;
        // patch for showing up immediately
        newTaskData.canView = true;

    } else {
        newTaskKey = key;
        updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = content;
        index = content.index || 0;

        if (content.parent) {
            parentId = content.parent;
        }
    }

    // if there is a parentId we add the new task to the parent childrens
    if (parentId) {
        var newChilds = [{ id: newTaskKey, type: 'task' }];

        _.each(tasks[parentId].childrens, c => {
            newChilds.push(c);
        });

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = newChilds;
    }


    var tasksFromParent = getTasksFromParent(parentId);
    let newIndexes = {};
    //insert new task
    newIndexes[newTaskKey] = {id: newTaskKey,index};

    tasksFromParent.forEach(t => {
        var tIndex = t.index;

        if (t.index >= index) {
            tIndex = t.index + 1;
        }
        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(item.id === newTaskKey){
            if(index !== currindex){
                updates['/accounts/' + accountId + '/tasks/' + newTaskKey].index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });



    db.update(undefined, updates);

    dispatch(hideLoader());

    changeChallengeTaskCount(1);

    return new Promise(resolve => {
        resolve(newTaskKey);
    });
}

export function updateTaskTitle(accountId, taskId, title) {
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/title', title);
}

export function getTasksFromParent(parentId, tasks = null) {
    if(!tasks){
        tasks = getAppData()?.account?.tasks;
    }
    var list = {},
        index = 0;

    if (tasks) {
        for (var t in tasks) {
            if (
                tasks[t].parent === parentId ||
                (!parentId && !tasks[t].parent)
            ) {
                list[t] = { ...tasks[t], id: t, tasktype: 'task' };
            }
        }
    }
    var completeList = _.sortBy(list, 'index');
    var result = [];

    completeList.forEach(t => {
        result.push({ ...t, index });
        index++;
    });

    return result;
}


export function reorderTasks({ type = 'update', taskId = null, initParent = null, destParent = null, oldIndex = null, newIndex= null }) {
    if(!taskId) return;

    const accountTasks = getAccountTasks(getState());
    const accountId = getAccountId(getState());
    const currentUserId = getUserId(getState());
    const parentChildIds = getParentChildIdsState(getState());
    const taskData = accountTasks[taskId];
    const baseTasksPath = `/accounts/${accountId}/tasks`;

    if(type === 'update') {
        const siblings = getTasksFromParent(taskData?.parent || null, accountTasks);

        const updates = siblings.reduce((acc, task) => {
            if(task.id === taskId) {
                acc[`${baseTasksPath}/${taskId}/index`] = newIndex;
                return acc;
            }

            if(task.index > oldIndex && task.index < newIndex) {
                acc[`${baseTasksPath}/${task.id}/index`] = task.index - 1;
                return acc;
            }

            if(task.index < oldIndex && task.index > newIndex) {
                acc[`${baseTasksPath}/${task.id}/index`] = task.index + 1;
                return acc;
            }

            if(task.index === newIndex && task.index > oldIndex) {
                acc[`${baseTasksPath}/${task.id}/index`] = task.index - 1;
                return acc;
            }

            if(task.index === newIndex && task.index < oldIndex) {
                acc[`${baseTasksPath}/${task.id}/index`] = task.index + 1;
                return acc;
            }

            return acc;
        }, {});

        db.update(undefined, updates);
        addChallenge('orderTask');
        segmentTrack('Reorder Task', { accountId, taskId, userId: currentUserId});

    } else {

        let updates = {};

        // Update parent
        if(_.isNull(destParent) || destParent === 'ROOT') {
            updates[`${baseTasksPath}/${taskId}/parent`] = null;
        } else {
            updates[`${baseTasksPath}/${taskId}/parent`] = destParent;
        }


        // Update initial siblings indexes
        const initSiblings = getTasksFromParent(taskData?.parent, accountTasks);

        initSiblings.forEach(task => {
            const taskIndex = task.index;

            if(task.id === taskId) {
                updates[`${baseTasksPath}/${taskId}/index`] = newIndex;
                return;
            }

            if(task.index > oldIndex) {
                updates[`${baseTasksPath}/${task.id}/index`] = task.index - 1;
                return;
            }

            if(taskIndex !== task.index) {
                updates[`${baseTasksPath}/${task.id}/index`] = taskIndex;
            }
        });


        // Update destParent subtasks indexes
        const destSiblings = getTasksFromParent(destParent, accountTasks);

        destSiblings.forEach(task => {
            const taskIndex = task.index;

            if(task.index >= newIndex) {
                updates[`${baseTasksPath}/${task.id}/index`] = task.index + 1;
            }

            if(taskIndex !== task.index) {
                updates[`${baseTasksPath}/${task.id}/index`] = taskIndex;
            }
        });

        // Remove task from the initial Parent
        if(accountTasks[initParent]) {
            const initSiblings = accountTasks[initParent]?.childrens;
            const updatedChildren = _.filter(initSiblings, child => child.id !== taskId);

            updates[`${baseTasksPath}/${initParent}/childrens`] = updatedChildren;
        }

        // Add task to the destParent
        if(accountTasks[destParent]) {
            const destSiblingsUpdates = [
                {id: taskId, type: 'task'},
                ...accountTasks[destParent]?.childrens ?? []
            ];

            updates[`${baseTasksPath}/${destParent}/childrens`] = destSiblingsUpdates;
        }

        // Update permissions
        const currPemissions = accountTasks[taskId]?.permissions;
        const destPermissions = _.isNull(destParent) || destParent === 'ROOT' ? { comingFrom:  'base' } :  {comingFrom: accountTasks[destParent]?.permissions?.comingFrom};
        const permissions = currPemissions?.comingFrom === taskId ? currPemissions : destPermissions;

        if(!_.isEqual(accountTasks[taskId]?.permissions, permissions)){
            updates[`${baseTasksPath}/${taskId}/permissions`] = permissions;
        }

        if(!accountTasks[taskId]?.childrens) {
            db.update(undefined, updates);
            segmentTrack('Reorder subtask', { accountId, taskId, userId: currentUserId});
            addChallenge('orderSubTask');
            return;
        }

        const taskChildrenIds = _.get(parentChildIds, ['childs', taskId], []);

        taskChildrenIds.forEach(taskId => {
            if (!accountTasks[taskId]) return;

            if(
                !_.isEqual(accountTasks[taskId]?.permissions, permissions) &&
                    accountTasks[taskId].permissions.comingFrom !== taskId &&
                    (
                        accountTasks[taskId].parent === taskId ||
                        accountTasks[taskId].permissions.comingFrom !== accountTasks[taskId].parent
                    )
            ) {
                updates[`${baseTasksPath}/${taskId}/permissions`] = permissions;
            }
        });

        db.update(undefined, updates);
        segmentTrack('Reorder folder', { accountId, taskId, userId: currentUserId});
        addChallenge('orderSubTasks');
    }
}

export function updateTaskStatus(accountId, taskId, status, shouldReturnUpdates, accountData, boardId) {
    if (!accountData) {
        accountData = getAppData().account;
    }

    segmentTrack('Update task status', { accountId, taskId, status });

    var update = { status: status, doneAt: null };

    if (status === 'done') {
        update.doneAt = moment().format('YYYY-MM-DD');
        addChallenge('doneTask');
    } else if (status === 'todo') {
        addChallenge('stopWorkTask');
    }

    var task = getAppData()?.account?.tasks[taskId];
    if (boardId) {
        update.board = boardId;
    } else {
        var actualBoardStatus = 'none';
        if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
            actualBoardStatus = task.board;
        } else if (task.board && getAppData()?.account?.boards[task.board]) {
            if (getAppData()?.account?.boards[task.board].status !== 'todo') {
                actualBoardStatus = 'inprogress';
            } else {
                actualBoardStatus = 'todo';
            }
        }

        if (status !== actualBoardStatus) {
            update.board = status;
        }
    }

    var taskData = {...accountData.tasks[taskId]};
    taskData = _.extend(taskData, update);

    const validParams = [
        'childrens',
        'deadline',
        'followers',
        'index',
        'maxEffort',
        'minEffort',
        'parent',
        'permissions',
        'priority',
        'startedWorkingAt',
        'status',
        'title',
        'doneAt',
        'skill',
        'timerStart',
        'userWorking',
        'forcedUser',
        'dependentOnThis',
        'dependencies',
        'delayUntil',
        'nbWorkers',
        'workingTime',
        'originalAssignees',
        'originalDependencies',
        'sourceStatus',
        'sourceId',
        'sourceType',
        'sourceUrl',
        'sourceTags',
        'sourceKey',
        'syncAppId',
        'syncAppType',
        'storyPoint',
    ];
    Object.keys(taskData).forEach((param)=>{
        if(validParams.indexOf(param) === -1){
            delete taskData[param];
        }
    });

    const updates = {
        ['/accounts/' + accountId + '/tasks/' + taskId]: taskData
    };

    if (status === 'inprogress') {
        handleFirstStepsUserStartWorking();
    }

    if (shouldReturnUpdates) {
        return { updates };
    } else {
        db.update(undefined, updates);
        return new Promise(resolve => {
            resolve();
        });
    }
}

export function updateTaskBoard(accountId, taskId, boardId) {
    segmentTrack('Update task board', { accountId, taskId, boardId });
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/board', boardId);
}

export function updateTaskSkill(accountId, taskId, skillIds) {
    addChallenge('changeTaskSkill');
    if(!getPopupGuides()?.noSkill) {setCompletedPopupGuide('noSkill');}
    segmentTrack('Update task skill', { accountId, taskId, skillIds });
    dispatch(openReccurentTaskModal({ taskId, updatedField: 'skill'}));

    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/skill', skillIds);
}

export function updateTaskSkillRange(accountId, taskId, skillId, range) {
    segmentTrack('Update task skill range', { accountId, taskId, skillId, range });
    db.set(`/accounts/${accountId}/tasks/${taskId}/skillRanges/${skillId}`, range);
}

export function setTaskMinEffort(accountId, taskId, value) {
    let taskData = {...getAppData().account.tasks[taskId]};
    let updates = {};
    updates['/accounts/' + accountId + '/tasks/' + taskId + '/minEffort'] = parseFloat(value).toFixed(2);

    if (!taskData.maxEffort || parseFloat(taskData.maxEffort) < parseFloat(value)) {
        updates['/accounts/' + accountId + '/tasks/' + taskId + '/maxEffort'] = parseFloat(value).toFixed(2);
    }

    if(!getPopupGuides()?.noEffort && value !== 0) {setCompletedPopupGuide('noEffort');}

    dispatch(openReccurentTaskModal({
        taskId: taskId,
        updatedField: 'minEffort',
    }));

    addChallenge('changeTaskEffort');
    segmentTrack('Set task minEffort', { accountId, taskId, value });
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve();
    });
}

export function setTaskMaxEffort(accountId, taskId, value) {
    let taskData = {...getAppData().account.tasks[taskId]};
    let updates = {};
    updates['/accounts/' + accountId + '/tasks/' + taskId + '/maxEffort'] =  parseFloat(value).toFixed(2);
    if (!taskData.minEffort) {
        updates['/accounts/' + accountId + '/tasks/' + taskId + '/minEffort'] = 0;
    } else if (parseFloat(taskData.minEffort) > parseFloat(value)) {
        updates['/accounts/' + accountId + '/tasks/' + taskId + '/minEffort'] = parseFloat(value).toFixed(2);
    }

    if(!getPopupGuides()?.noEffort && value !== 0) {setCompletedPopupGuide('noEffort');}

    dispatch(openReccurentTaskModal({
        taskId: taskId,
        updatedField: 'maxEffort',
    }));

    addChallenge('changeTaskEffort');
    segmentTrack('Set task maxEffort', { accountId, taskId, value });
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve();
    });
}

export function setTaskNbWorkers(accountId, taskId, value) {

    let task = {...getAppData().account.tasks[taskId]};
    let updates = {};

    const temp = value || 1;
    let taskForcedUser = task.forcedUser;
    if (typeof taskForcedUser === 'string') {
        taskForcedUser = [taskForcedUser];
    }

    if (taskForcedUser && taskForcedUser.length > temp) {
        taskForcedUser.splice(temp);
        updates['/accounts/' + accountId + '/tasks/' + taskId + '/forcedUser'] = taskForcedUser;
    }

    updates['/accounts/' + accountId + '/tasks/' + taskId + '/nbWorkers'] = value;

    segmentTrack('Set task nbWorkers', { accountId, taskId, value });

    db.update(undefined, updates);

    return new Promise(resolve => {
        resolve();
    });
}

export function changeTaskDeadline(accountId, taskId, date) {
    addChallenge('duedateTask');
    segmentTrack('Change task deadline', { accountId, taskId, date });
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/deadline', date);
}

function changeTaskStartonSave(accountId, taskId, date) {
    segmentTrack('Change task Start on', { accountId, taskId, date });
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/startOn', date);
}

export function changeTaskStarton(accountId, taskId, date) {
    return new Promise((resolve)=>{
        // check if task has a "Wait until"
        if(date && getAppData().account.tasks[taskId].delayUntil){
            toastr.confirm('By setting a Start on date, the Wait until date of the task will be removed', {
                onOk: () => {
                    changeTaskDelay(accountId, taskId, null);
                    changeTaskStartonSave(accountId, taskId, date);
                    resolve(true);
                },
                onCancel: () => {
                    resolve(false);
                },
                okText: 'CONFIRM',
                cancelText: 'CANCEL',
            });
        }
        else {
            changeTaskStartonSave(accountId, taskId, date);
            resolve(true);
        }
    });
}

export function changeTaskDelay(accountId, taskId, date) {
    addChallenge('delayTask');
    segmentTrack('Change task delay', { accountId, taskId, date });
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/delayUntil', date);
}

export function addFileToTask(accountId, taskId, doc) {
    let newFileKey = db.pushkey('/accounts/' + accountId + '/tasks/' + taskId + '/files');

    let updates = {
        ['/accounts/' + accountId + '/tasks/' + taskId + '/files/' + newFileKey]: doc,
    };

    segmentTrack('Add file to task', { accountId, taskId, doc });

    if (doc.firebaseStorage) {
        var newStorageSize = getAppData().account.storageSize || 0;
        newStorageSize += doc.size;
        updates['/accounts/' + accountId + '/settings/storageSize'] = newStorageSize;
    }

    db.update(undefined, updates);
}

export function addFileToTaskComment(accountId, userId, taskId, doc) {
    let newCommentKey = db.pushkey('/accounts/' + accountId + '/tasks/' + taskId + '/comments');

    let data = {
        at: moment().format(),
        type: 'file',
        comment: doc.name,
        url: doc.url,
        fullPath: doc.fullPath,
        from: userId,
        contentType: doc.contentType,
        mentions: null,
    };

    let updates = {
        ['/accounts/' + accountId + '/tasks/' + taskId + '/comments/' + newCommentKey]: data,
        ['/accounts/' + accountId + '/tasks/' + taskId + '/followers/' + userId]: true,
    };

    if (doc.firebaseStorage) {
        var newStorageSize = getAppData().account.storageSize || 0;
        newStorageSize += doc.size;
        updates['/accounts/' + accountId + '/settings/storageSize'] = newStorageSize;
    }

    db.update(undefined, updates);
}

export function removeFileFromTask(accountId, taskId, fileId) {
    let accountData = getAppData().account;
    let updates = {};

    if (accountData.tasks[taskId].files[fileId].firebaseStorage) {
        var newStorageSize = accountData.settings.storageSize;
        newStorageSize -= accountData.tasks[taskId].files[fileId].size;
        if (newStorageSize < 0) {
            newStorageSize = 0;
        }

        updates['/accounts/' + accountId + '/settings/storageSize'] = newStorageSize;

        storage.ref(accountData.tasks[taskId].files[fileId].fullPath).delete();
    }

    updates['/accounts/' + accountId + '/tasks/' + taskId + '/files/' + fileId] = null;

    addChallenge('removeFile');
    segmentTrack('Remove file from task', { accountId, taskId, fileId });

    db.update(undefined, updates);
}

export function addTaskComment(accountId, taskId, userId, comment, mentions = []) {
    const accountTasks = getAccountTasks(getState());
    const prevFollowersIds = !_.isEmpty(accountTasks[taskId]?.followers) ? Object.keys(accountTasks[taskId]?.followers || {}) : [];

    const newCommentKey = db.pushkey(`/accounts/${accountId}/tasks/${taskId}/comments`);
    const data = {
        at: moment().format(),
        comment: comment,
        from: userId,
        mentions: mentions,
    };

    const updates = {
        [`/accounts/${accountId}/tasks/${taskId}/comments/${newCommentKey}`]: data,
        [`/accounts/${accountId}/tasks/${taskId}/followers/${userId}`]: 'inherited',
    };

    _.each(mentions, user => {
        if (user.id !== userId) {
            updates[`/accounts/${accountId}/tasks/${taskId}/followers/${user.id}`] = 'inherited';
        }
    });

    db.update(undefined, updates);

    const userMentionsArr = mentions && Array.isArray(mentions) ? mentions.map(user=>user.id) : [];
    const usersFollowingTaskUpdated = _.uniq([...Object.keys(accountTasks[taskId]?.followers || {}), ...userMentionsArr, userId]) || [];

    segmentTrack('Add comment on task', { accountId, taskId, userId, comment, mentions });
    segmentTrack('Follow task by tag mentioning', { accountId, taskId, userId, followingByMention: _.uniq([...userMentionsArr, userId]) });

    // If there are new followers
    if(!usersFollowingTaskUpdated.every(userId => prevFollowersIds.includes(userId))) {
        segmentTrack('Users following task', { accountId, taskId, usersFollowingTaskUpdated });
        segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers: usersFollowingTaskUpdated.length || 0});
    }
}

export function updateTaskComment(accountId, taskId, commentId, userId, comment, mentions) {
    segmentTrack('Edit comment on task', { accountId, taskId, commentId, userId, comment, mentions });

    let updates = {
        ['/accounts/' + accountId + '/tasks/' + taskId + '/comments/' + commentId + '/comment']: comment,
        ['/accounts/' + accountId + '/tasks/' + taskId + '/comments/' + commentId + '/mentions']: mentions,
        ['/accounts/' + accountId + '/tasks/' + taskId + '/comments/' + commentId + '/lastChanged']: moment().format(),
        ['/accounts/' + accountId + '/tasks/' + taskId + '/followers/' + userId]: true,
    };

    _.each(mentions, user => {
        if (user.id !== userId) {
            updates['/accounts/' + accountId + '/tasks/' + taskId + '/followers/' + user.id] = true;
        }
    });

    db.update(undefined,updates);
}

export function deleteCommentFromTask(accountId, taskId, commentId) {
    segmentTrack('Delete comment on task', { accountId, taskId, commentId });
    db.remove('/accounts/' + accountId + '/tasks/' + taskId + '/comments/' + commentId);
}

export function removeAllForcedUsers(taskId) {
    const accountId = getAccountId(getState());
    const activeUserId = getUserId(getState());

    addChallenge('unforceTeammate');
    segmentTrack('Remove all forced users', { accountId, taskId, activeUserId });
    dispatch(openReccurentTaskModal({ taskId, updatedField: 'forcedUser' }));
    db.remove('/accounts/' + accountId + '/tasks/' + taskId + '/forcedUser');
    db.remove('/accounts/' + accountId + '/tasks/' + taskId + '/userWorking');
}

export function removeForcedUser(taskId, userIdToRemove) {
    const activeUserId = getUserId(getState());
    const accountId = getAccountId(getState());
    const accountTasks = getAccountTasks(getState());

    const forcedUsers = accountTasks[taskId]?.forcedUser || [];
    const usersWorking = accountTasks[taskId]?.userWorking || [];

    const forcedUsersLeft = forcedUsers.filter(userId=>userId!==userIdToRemove);
    const usersWorkingLeft = usersWorking.filter(userId=>userId!==userIdToRemove);

    addChallenge('unforceTeammate');
    segmentTrack('Remove single forced user', { accountId, taskId, activeUserId });
    dispatch(openReccurentTaskModal({taskId,updatedField: 'forcedUser'}));

    db.set(`/accounts/${accountId}/tasks/${taskId}/forcedUser/`, forcedUsersLeft);
    db.set(`/accounts/${accountId}/tasks/${taskId}/userWorking/`, usersWorkingLeft);
}

export function changeAssignedUser(taskId, selectedUsers) {
    const accountTasks = getAccountTasks(getState());
    const accountId = getAccountId(getState());
    const currentUserId = getUserId(getState());
    const currentTask = accountTasks[taskId];

    const usersIds = Array.isArray(selectedUsers) ? selectedUsers : [selectedUsers];
    const prevForcedUser = currentTask?.forcedUser?.filter(id=>usersIds.includes(id)) || [];
    const prevTaskFollowers = currentTask?.followers ? currentTask?.followers : {};

    let newForceAssign = usersIds;

    if(prevForcedUser) {
        newForceAssign = _.uniq([
            ...newForceAssign,
            ...prevForcedUser
        ]);
    }

    if(_.isEqual(usersIds, [currentUserId])) {
        newForceAssign = [currentUserId];
    }

    const followersUpdated = _.uniq([...Object.keys(prevTaskFollowers), ...usersIds, currentUserId]).reduce((acc, userId) => {
        acc[userId] = 'inherited';
        return acc;
    }, {});

    const updates = {
        [`/accounts/${accountId}/tasks/${taskId}/forcedUser/`]: newForceAssign,
        [`/accounts/${accountId}/tasks/${taskId}/followers/`]: followersUpdated,
    };

    if(currentTask?.status === 'inprogress' && currentTask?.sourceId) {
        updates[`/accounts/${accountId}/tasks/${taskId}/userWorking/`] = newForceAssign;
    }

    const usersFollowingTaskUpdated = _.uniq([
        ...Object.keys(currentTask?.followers || {}),
        ...newForceAssign,
        currentUserId
    ]) || [];

    segmentTrack('Change assigned user', { accountId, taskId, newForceAssign });
    segmentTrack('Follow task by force assign', { accountId, taskId, followersByForceAssign: _.uniq([...newForceAssign, currentUserId]) });

    // If there are new followers
    if(_.size(usersFollowingTaskUpdated) && !usersFollowingTaskUpdated.every(userId => Object.keys(prevTaskFollowers).includes(userId))) {
        segmentTrack('Users following task', { accountId, taskId, usersFollowingTaskUpdated });
        segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers: usersFollowingTaskUpdated.length || 0});
    }

    addChallenge('forceTeammate');
    dispatch(openReccurentTaskModal({ taskId, updatedField: 'forcedUser' }));
    db.update(undefined, updates);
}

export function changeTaskDependentOn(accountId, taskId, values) {
    segmentTrack('Change task dependencies', { accountId, taskId, values });

    var listOfTasks = {...getAppData().account.tasks},
        actualDependencies = listOfTasks[taskId].dependencies || [],
        updates = {};

    _.each(actualDependencies, oldDep => {
        var oldList = listOfTasks[oldDep].dependentOnThis || [];
        oldList = _.without(oldList, taskId);
        updates['/accounts/' + accountId + '/tasks/' + oldDep + '/dependentOnThis'] = oldList;
    });

    _.each(values, newDep => {
        var newList = listOfTasks[newDep].dependentOnThis || [];
        if (newList.indexOf(taskId) === -1) {
            newList.push(taskId);
        }
        updates['/accounts/' + accountId + '/tasks/' + newDep + '/dependentOnThis'] = newList;
    });

    updates['/accounts/' + accountId + '/tasks/' + taskId + '/dependencies'] = values;

    addChallenge('addDependency');
    addChallenge('removeDependency');
    dispatch(closeSelectDependenciesModal());
    dispatch(openReccurentTaskModal({taskId, updatedField: 'dependencies'}));

    db.update(undefined, updates);
}


export function addTimeWorked(accountId, taskId, data, markDone) {
    const accountTasks = getAccountTasks(getState());
    const prevFollowersIds = !_.isEmpty(accountTasks[taskId]?.followers) ? Object.keys(accountTasks[taskId]?.followers) : [];
    const activeUserId = getUserId(getState());
    let updates = {};
    const accountData = getAppData().account;

    if (typeof data.member === 'string') {
        data.member = [data.member];
    }

    let toSave = { ...data };
    toSave.member = data.member;
    let key = db.pushkey('/accounts/' + accountId + '/tasks/' + taskId + '/workingTime');

    updates['/accounts/' + accountId + '/tasks/' + taskId + '/workingTime/' + key] = toSave;

    data.member.forEach(userId => {
        if(accountData.tasks[taskId].followers && !accountData.tasks[taskId].followers[userId]) {
            updates[`/accounts/${accountId}/tasks/${taskId}/followers/${userId}`] = 'inherited';
        }
    });

    if(!data.member.includes(activeUserId) && !isFollowTypeManually(accountData.tasks, taskId, activeUserId)) {
        updates[`/accounts/${accountId}/tasks/${taskId}/followers/${activeUserId}`] = 'inherited';
    }

    if (markDone) {
        const upd = updateTaskStatus(accountId, taskId, 'done', true, accountData);
        updates = _.extend(updates, upd.updates);
    }

    const usersFollowingTaskUpdated = _.uniq([...Object.keys(accountData.tasks[taskId].followers || {}), ...data.member, activeUserId]) || [];

    segmentTrack('Add time worked', { accountId, taskId, data, markDone });
    segmentTrack('Following by adding time worked', {accountId, taskId, followersByTimeWorked: _.uniq([...data.member, activeUserId])});

    // If there are new followers
    if(!usersFollowingTaskUpdated.every(userId => prevFollowersIds.includes(userId))) {
        segmentTrack('Users following task', { accountId, taskId, usersFollowingTaskUpdated });
        segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers: usersFollowingTaskUpdated.length || 0});
    }

    addChallenge('addTimeWorked');
    db.update(undefined, updates);
}

export function removeTimeWorked({accountId, taskId, timeWorkedId}) {
    const deletedTimeWorkedContent = getAppData().account.tasks[taskId].workingTime[timeWorkedId];

    addChallenge('removeTimeWorked');
    segmentTrack('Removed time worked', { accountId, taskId, timeWorkedId });

    db.remove(`/accounts/${accountId}/tasks/${taskId}/workingTime/${timeWorkedId}`);

    return {
        undo: () => {
            addTimeWorked(accountId, taskId, deletedTimeWorkedContent, false);
        }
    };
}

export function addTaskList(accountId, list, parentId, index, parentChildrens, userId, forceSkillId, fromFirstTasks) {
    let accountData = getAppData().account;

    if (!forceSkillId) {
        forceSkillId = null;
    }

    let pushKeys = [],
        updates = {},
        followObj = {};

    index = 0;

    for (var i = 0; i < list.length; ++i) {
        let key = db.pushkey('/accounts/' + accountId + '/tasks/');
        pushKeys.push(key);
    }

    i = 0;

    let closedSortables = [];
    if (localStorage.getItem('closedSortables-' + accountId)) {
        closedSortables = localStorage.getItem('closedSortables-' + accountId).split(',');
    }

    let permissions;
    if (parentId) {
        permissions = { comingFrom: accountData.tasks[parentId].permissions.comingFrom };
        followObj = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
            acc[userId] = 'inherited';
            return acc;
        }, {});

    } else {
        permissions = { comingFrom: 'base' };
        followObj[userId] = 'inherited';
    }

    _.each(list, task => {
        let parent = null,
            childrens = [];

        if (task.childrens) {
            _.each(task.childrens, childIndex => {
                childrens.push({ id: pushKeys[childIndex], type: 'task' });
            });
            closedSortables.push(pushKeys[i]);
        }

        if (task.parent || task.parent === 0) {
            parent = pushKeys[task.parent];
        } else if (parentId) {
            parent = parentId;
            parentChildrens.push({ id: pushKeys[i], type: 'task' });
        }

        let minEff = 0;
        let maxEff = 1;

        if (task.minEffort) {
            minEff = task.minEffort;
        }
        if (task.maxEffort) {
            maxEff = task.maxEffort;
        }

        let newData = {
            createdAt: moment().format(),
            index: index + i,
            status: 'todo',
            createdBy: userId,
            followers: followObj,
            title: task.title || '',
            permissions: permissions,
        };
        if (parent) {
            newData.parent = parent;
        }
        if (childrens) {
            newData.childrens = childrens;
        }
        if (forceSkillId) {
            newData.skill = forceSkillId;
        }
        if (minEff || minEff === 0) {
            newData.minEffort = minEff;
        }
        if (maxEff || maxEff === 1) {
            newData.maxEffort = maxEff;
        }

        updates['/accounts/' + accountId + '/tasks/' + pushKeys[i]] = newData;
        newData.canView = true;

        i++;
    });

    localStorage.setItem('closedSortables-' + accountId, closedSortables);

    if (parentId && parentChildrens.length) {
        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = parentChildrens;
    }

    if (!fromFirstTasks) {

        var tasksFromParent = getTasksFromParent(parentId);
        let newIndexes = {};
        //insert new tasks
        pushKeys.forEach(key=>{
            newIndexes[key] = {id: key,index: updates['/accounts/' + accountId + '/tasks/' + key].index};
        });

        tasksFromParent.forEach(t => {
            var tIndex = t.index;

            if (t.index >= index) {
                tIndex = t.index + list.length;
            }
            newIndexes[t.id] = {id: t.id, index: tIndex};
            if (tIndex !== t.index) {
                updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
            }
        });


        newIndexes = _.sortBy(newIndexes, 'index');
        newIndexes.forEach((item, currindex)=>{
            if(pushKeys.indexOf(item.id) !== -1){
                if(updates['/accounts/' + accountId + '/tasks/' + item.id].index !== currindex){
                    updates['/accounts/' + accountId + '/tasks/' + item.id].index = currindex;
                }
            }
            else if(
                (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
                (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
            ){
                updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
            }
        });

        segmentTrack('Add projects', { accountId, userId });
    }

    changeChallengeTaskCount(pushKeys.length);

    segmentTrack('Add list of tasks', { accountId, list, parentId, index, userId });

    db.update(undefined, updates);
}

function getDuplicatedTaskWithOptions(taskId, options) {
    var task = {...getAppData()?.account?.tasks[taskId]};
    delete task.dependenciesCalc;
    delete task.dependentOnThis;
    delete task.dependentOnThisCalc;
    delete task.estimations;
    delete task.delayUntilCalc;
    delete task.workingTime;
    delete task.startedWorkingAt;
    delete task.id;

    task.createdAt = moment().format();
    task.createdBy = getAppData()?.user?.data?.userId;
    task.permissions = { comingFrom: 'base' };

    if (!options.status) {
        task.status = 'todo';
    }

    if ((task.minEffort || task.minEffort === 0) && !options.effort) {
        delete task.minEffort;
    }
    if ((task.maxEffort || task.maxEffort === 0) && !options.effort) {
        delete task.maxEffort;
    }
    if (task.skill && !options.skill) {
        delete task.skill;
    }

    if (task.deadline && !options.due) {
        delete task.deadline;
    }
    if (task.delayUntil && !options.delay) {
        delete task.delayUntil;
    }
    if (task.dependencies && !options.dependencies) {
        delete task.dependencies;
    }
    if (task.forcedUser && !options.forced) {
        delete task.forcedUser;
    }
    if (task.files && !options.files) {
        delete task.files;
    }
    if (task.comments && !options.comments) {
        delete task.comments;
    }
    if (task.followers && !options.followers) {
        delete task.followers;
    }

    if (!task.followers) {
        task.followers = {};
    }
    if (!task.followers[getAppData()?.user?.data?.userId]) {
        task.followers[getAppData()?.user?.data?.userId] = true;
    }

    return task;
}

function stripTaskProps(task){
    delete task.estimations;
    delete task.path;
    delete task.risks;
    delete task.sourceId;
    delete task.syncAppId;
    delete task.syncAppType;
    return task;
}

export async function duplicateTask(accountId, taskId, options) {
    let accountData = getAppData().account;

    var tasks = accountData.tasks,
        updates = {},
        duplicateTasksDescriptions = [];

    if (tasks[taskId]) {
        // case of a single task
        if (!tasks[taskId].childrens || !options.childrens) {
            var key = db.pushkey('/accounts/' + accountId + '/tasks/'),
                taskToDuplicate = getDuplicatedTaskWithOptions(taskId, options);

            taskToDuplicate.index++;


            var tasksP = getTasksFromParent(taskToDuplicate.parent);
            let newIndexes = {};
            //insert new task
            newIndexes[key] = {id: key,index: taskToDuplicate.index};

            tasksP.forEach(t => {
                const tIndex = t.index >= taskToDuplicate.index ? t.index + 1 : t.index;

                newIndexes[t.id] = {id: t.id, index: tIndex};
                if (tIndex !== t.index) {
                    updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
                }
            });


            newIndexes = _.sortBy(newIndexes, 'index');
            newIndexes.forEach((item, currindex)=>{
                if(item.id === key){
                    if(taskToDuplicate.index !== currindex){
                        taskToDuplicate.index = currindex;
                    }
                }
                else if(
                    (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && tasks[item.id].index !== currindex) ||
                    (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
                ){
                    updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
                }
            });



            if (taskToDuplicate.childrens) {
                delete taskToDuplicate.childrens;
            }

            // if task has a parent, we add the duplicate to the parent's childrens
            if (tasks[taskId].parent) {
                var parentChilds = tasks[tasks[taskId].parent].childrens,
                    parentChildsIndex = _.find(parentChilds, (el, ind) => {
                        if (el.id === taskId && el.type === 'task') {
                            return ind + 1;
                        }
                    });

                parentChilds.splice(parentChildsIndex, 0, { id: key, type: 'task' });

                updates['/accounts/' + accountId + '/tasks/' + tasks[taskId].parent + '/childrens'] = parentChilds;
            }
            taskToDuplicate.title = taskToDuplicate.title || '';
            taskToDuplicate.title += ' (copy)';

            // duplicate the task permissions
            const comingFrom = accountData.tasks[taskId]?.permissions?.comingFrom;

            if (comingFrom === taskId) {
                taskToDuplicate.permissions = accountData.tasks[taskId]?.permissions;
                taskToDuplicate.permissions.comingFrom = key;
            } else if (comingFrom !== 'base') {
                taskToDuplicate.permissions = accountData.tasks[taskId]?.permissions;
            }

            updates['/accounts/' + accountId + '/tasks/' + key] = stripTaskProps(taskToDuplicate);

            // if has dependencies, update dependencies "dependentOnThis"
            if (taskToDuplicate.dependencies) {
                _.each(_.uniq(taskToDuplicate.dependencies), dep => {
                    if (tasks[dep]) {
                        var deps = [];

                        if (tasks[dep].dependentOnThis) {
                            deps = tasks[dep].dependentOnThis;
                        }

                        deps.push(key);

                        updates['/accounts/' + accountId + '/tasks/' + dep + '/dependentOnThis'] = deps;
                    }
                });
            }

            addChallenge('duplicateTask');

            changeChallengeTaskCount(1);
            segmentTrack('Duplicate task', { accountId, taskId, options });

            const getTaskDescriptionRef = (taskKey) => `accountsData/${accountId}/tasks/${taskKey}/description`;

            const taskDescription = (await db.once(getTaskDescriptionRef(taskId))).val();

            if (options.description && taskDescription) {
                updates[getTaskDescriptionRef(key)] = taskDescription;
            }

            db.update(undefined, updates);
        }
        // case of multiple tasks
        else {
            var taskList = _.union([taskId], getChildrensOfTask(getAppData()?.parentChildIds.parents, taskId));
            var taskToDuplicate2 = getDuplicatedTaskWithOptions(taskId, options);

            var keyMap = {},
                newKeysList = [],
                k;

            taskList.forEach((t, i) => {
                if (getAppData()?.account?.tasks[t]) {
                    k = db.pushkey('/accounts/' + accountId + '/tasks/');
                    taskList[i] = { id: t, tasktype: 'task' };
                }

                keyMap[taskList[i].id] = k;
                newKeysList.push(k);
            });

            // var tasksP2 = getTasksFromParent(taskToDuplicate2.parent);

            // tasksP2.forEach(t => {
            //     const newIndex = t.index > taskToDuplicate2.index ? t.index + 1 : t.index;

            //     updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = newIndex;
            // });





            var tasksP2 = getTasksFromParent(taskToDuplicate2.parent);
            let newIndexes = {};
            //insert new task
            newIndexes['newDuplicate'] = {id: 'newDuplicate',index: taskToDuplicate2.index};

            tasksP2.forEach(t => {
                const tIndex = t.index >= taskToDuplicate2.index ? t.index + 1 : t.index;

                newIndexes[t.id] = {id: t.id, index: tIndex};
                if (tIndex !== t.index) {
                    updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
                }
            });


            newIndexes = _.sortBy(newIndexes, 'index');
            newIndexes.forEach((item, currindex)=>{
                if(item.id === 'newDuplicate'){
                    if(newIndexes[item.id] !== currindex){
                        newIndexes[item.id] = currindex;
                    }
                }
                else if(
                    (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && tasks[item.id].index !== currindex) ||
                    (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
                ){
                    updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
                }
            });



            var currentDuplicate;
            _.each(taskList, currentTask => {
                currentDuplicate = getDuplicatedTaskWithOptions(currentTask.id, options);

                if (currentTask.id === taskId) {
                    currentDuplicate.index = newIndexes['newDuplicate'];
                    currentDuplicate.title = currentDuplicate.title || '';
                    currentDuplicate.title += ' (copy)';
                }

                // check depenencies ids throught the task list to duplicate
                if (currentDuplicate.dependencies) {
                    var newDep = [];

                    _.each(_.uniq(currentDuplicate.dependencies), dep1 => {
                        if (taskList.indexOf(dep1) !== -1) {
                            newDep.push(keyMap[dep1]);
                        } else {
                            newDep.push(dep1);
                        }
                    });
                    currentDuplicate.dependencies = newDep;
                }

                // check children ids throught the task list to duplicate
                if (currentDuplicate.childrens) {
                    var newChilds = [];

                    _.each(_.uniq(currentDuplicate.childrens), child => {
                        if (child) {
                            taskList.forEach(t => {
                                if (t.id === child.id) {
                                    newChilds.push({ id: keyMap[child.id], type: t.tasktype });
                                }
                            });
                        }
                    });
                    currentDuplicate.childrens = newChilds;
                }

                if (currentDuplicate.parent && keyMap[currentDuplicate.parent]) {
                    currentDuplicate.parent = keyMap[currentDuplicate.parent];
                }

                var tempUpdates = {};
                for(var r in updates){
                    if (r === '/accounts/' + accountId + '/tasks/' + keyMap[currentTask.id] + '/dependentOnThis') {
                        currentDuplicate.dependentOnThis = updates[r];
                    } else {
                        tempUpdates[r] = updates[r];
                    }
                }
                updates = tempUpdates;

                updates['/accounts/' + accountId + '/tasks/' + keyMap[currentTask.id]] = stripTaskProps(currentDuplicate);

                if (options.description) {
                    duplicateTasksDescriptions.push({ old: currentTask.id, new: keyMap[currentTask.id] });
                }

                // update permissions comingFrom with the updated id's
                const comingFrom = tasks[currentTask.id].permissions.comingFrom;
                const parent = tasks[currentTask.id].parent;

                if (comingFrom === currentTask.id) {
                    currentDuplicate.permissions = tasks[currentTask.id].permissions;
                    currentDuplicate.permissions.comingFrom = keyMap[currentTask.id];
                }
                if (comingFrom === parent) {
                    currentDuplicate.permissions = tasks[tasks[currentTask.id].parent].permissions;
                    currentDuplicate.permissions.comingFrom = taskList.includes(parent) ? keyMap[parent] : parent;
                } else if (comingFrom !== 'base') {
                    currentDuplicate.permissions = tasks[currentTask.id].permissions;
                }

                // if has dependencies, update dependencies "dependentOnThis"
                if (currentDuplicate.dependencies) {
                    _.each(_.uniq(currentDuplicate.dependencies), dep2 => {
                        var isInnerDependency = false;
                        taskList.forEach(t => {
                            if (t.id === dep2) {
                                isInnerDependency = true;
                            }
                        });
                        if (isInnerDependency && keyMap[dep2]) {
                            for(var r in updates){
                                if (r === '/accounts/' + accountId + '/tasks/' + keyMap[currentTask.id]) {
                                    let index = updates[r].dependencies.indexOf(dep2);
                                    updates[r].dependencies[index] = keyMap[dep2];
                                }
                            }
                            dep2 = keyMap[dep2];
                        }

                        if (tasks[dep2]) {
                            var deps2 = [];

                            if (tasks[dep2] && tasks[dep2].dependentOnThis) {
                                deps2 = tasks[dep2].dependentOnThis;
                            }

                            deps2.push(keyMap[currentTask.id]);

                            let found = false;
                            for(var r2 in updates){
                                if (r2 === '/accounts/' + accountId + '/tasks/' + dep2) {
                                    found = true;
                                    if (!updates[r2].dependentOnThis) {
                                        updates[r2].dependentOnThis = deps2;
                                    } else {
                                        updates[r2].dependentOnThis = updates[r2].dependentOnThis.concat(deps2);
                                    }
                                }
                            }

                            if (!found) {
                                updates['/accounts/' + accountId + '/tasks/' + dep2 + '/dependentOnThis'] = deps2;
                            }
                        } else if (newKeysList.indexOf(dep2) !== -1) {
                            let found2 = false;

                            for(var r3 in updates){
                                if (r3 === '/accounts/' + accountId + '/tasks/' + dep2) {
                                    found2 = true;
                                    if (!updates[r3].dependentOnThis) {
                                        updates[r3].dependentOnThis = [keyMap[currentTask.id]];
                                    } else {
                                        var deps3 = updates[r3].dependentOnThis;
                                        deps3.push(keyMap[currentTask.id]);
                                        updates[r3].dependentOnThis = deps3;
                                    }
                                }
                            }

                            if (!found2) {
                                if (accountData.tasks[dep2]) {
                                    updates['/accounts/' + accountId + '/tasks/' + dep2 + '/dependentOnThis'] = [keyMap[currentTask.id]];
                                }
                            }
                        }
                    });
                }
            });

            if (tasks[taskId].parent) {
                var parentChilds2 = tasks[tasks[taskId].parent].childrens,
                    parentChilds2Index = _.find(parentChilds2, (el2, ind2) => {
                        if (el2 && el2.id === taskId && el2.type === 'task') {
                            return ind2 + 1;
                        }
                    });

                parentChilds2.splice(parentChilds2Index, 0, { id: keyMap[taskId], type: 'task' });

                updates['/accounts/' + accountId + '/tasks/' + tasks[taskId].parent + '/childrens'] = parentChilds2;
            }

            addChallenge('duplicateTasks');

            if (duplicateTasksDescriptions.length > 0) {
                db.once('/accountsData/' + accountId + '/tasks').then(tasks => {
                    const tasksData = tasks.val();
                    duplicateTasksDescriptions.forEach(item => {
                        if (tasksData && tasksData[item.old] && tasksData[item.old].description) {
                            updates['/accountsData/' + accountId + '/tasks/' + item.new + '/description'] = tasksData[item.old].description;
                        }
                    });

                    changeChallengeTaskCount(newKeysList.length);
                    segmentTrack('Duplicate task', { accountId, taskId, options });

                    db.update(undefined, updates);
                });
            } else {
                changeChallengeTaskCount(newKeysList.length);
                segmentTrack('Duplicate task', { accountId, taskId, options });

                db.update(undefined, updates);
            }
        }
    }
}

function getChildrensOfTask(parentsList, taskId) {
    var tempChildrens = [];

    _.each(parentsList, (currentTask, id) => {
        if (currentTask.indexOf(taskId) !== -1) {
            tempChildrens.push(id);
        }
    });

    return tempChildrens;
}

export function updateTaskPriority(accountId, taskId, level) {

    segmentTrack('Update task priority', { accountId, taskId, level });
    dispatch(openReccurentTaskModal({
        taskId: taskId,
        updatedField: 'priority',
    }));

    if(!getPopupGuides()?.priorityMessage) {setCompletedPopupGuide('priorityMessage');}

    addChallenge('changeTaskPriority');
    db.set('/accounts/' + accountId + '/tasks/' + taskId + '/priority', level);
}

export function savePreset(accountId, userId, presetId, presetName, filters, intl) {
    segmentTrack('Save preset', { accountId, userId, presetId, presetName, filters });

    if (presetId) {
        toastr.success(intl.formatMessage({id:'Preset updated'}));
        db.set('/accounts/' + accountId + '/users/' + userId + '/filterPresets/' + presetId + '/filters', filters);
    } else {
        toastr.success(intl.formatMessage({id:'Preset created'}));
        db.push('/accounts/' + accountId + '/users/' + userId + '/filterPresets', { name: presetName, filters: filters });
    }
}

export function deletePreset(accountId, userId, presetId, intl) {
    segmentTrack('Delete preset', { accountId, userId, presetId });
    toastr.success(intl({id:'Preset deleted'}));
    db.remove('/accounts/' + accountId + '/users/' + userId + '/filterPresets/' + presetId);
}

export function addBoard(accountId, name) {
    let accountData = getAppData().account;
    segmentTrack('Add new board', { accountId });

    for (var i in accountData.settings.boards) {
        accountData.settings.boards[i].id = i;
    }

    let orderedBoards = _.sortBy(accountData.settings.boards, 'index');
    let updates = {};

    orderedBoards.forEach(board => {
        updates['/accounts/' + accountId + '/settings/boards/' + board.id + '/index'] = board.index + 1;
    });

    const key = db.pushkey('/accounts/' + accountId + '/settings/boards');
    const newBoardData = {
        name,
        index: 0,
        status: 'todo',
    };

    updates['/accounts/' + accountId + '/settings/boards/' + key] = newBoardData;

    for (var j in accountData.settings.boards) {
        delete accountData.settings.boards[j].id;
    }

    db.update(undefined, updates);
}

export function deleteBoard(boardId, boardIndex, accountId, intl) {
    let accountData = getAppData().account;

    for (var i in accountData.settings.boards) {
        accountData.settings.boards[i].id = i;
    }

    let orderedBoards = _.sortBy(accountData.settings.boards, 'index');
    let updates = {};

    orderedBoards.forEach(board => {
        if (board.index > boardIndex) {
            updates['/accounts/' + accountId + '/settings/boards/' + board.id + '/index'] = parseInt(board.index) - 1;
        }
    });

    for (var taskId in accountData.tasks) {
        if (accountData.tasks[taskId].board === boardId) {
            if (accountData.tasks[taskId].status === 'todo') {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/board'] = null;
            } else if (accountData.tasks[taskId].status === 'inprogress') {
                updates['/accounts/' + accountId + '/tasks/' + taskId + '/board'] = 'inprogress';
            }
        }
    }

    updates['/accounts/' + accountId + '/settings/boards/' + boardId] =  null;

    segmentTrack('Delete board', { accountId, boardId });
    toastr.success(intl.formatMessage({id:'board.deleted'}));

    db.update(undefined, updates);
}

export function updateBoardTitle(boardId, title, accountId) {
    db.set('/accounts/' + accountId + '/settings/boards/' + boardId + '/name', title);
}

export function orderBoards(list, accountId) {
    var updates = {};
    for (var i in list) {
        updates['/accounts/' + accountId + '/settings/boards/' + list[i] + '/index'] =  parseInt(i);
    }
    db.update(undefined, updates);
}

export function changeBoardStatus(boardId, status) {
    let accountData = getAppData().account;
    // check if there are tasks on the board if yes, send error
    var boardHasTasks = false;
    for (var taskId in accountData.tasks) {
        if (!boardHasTasks && accountData.tasks[taskId].board && accountData.tasks[taskId].board === boardId) {
            boardHasTasks = true;
            toastr.error(
                'You can\'t change the status on a board that contains tasks. Remove all tasks from this board first.',
            );
            break;
        }
    }
    if (!boardHasTasks) {
        db.set('/accounts/' + accountData.settings.id + '/settings/boards/' + boardId + '/status', status ? 'inprogress' : 'todo');
    }
}

export function createNewUserGroup() {
    const accountId = getAccountId(getState());
    addChallenge('addMemberGroup');
    segmentTrack('Create user group', { accountId });
    db.push('/accounts/' + accountId + '/userGroups', { title: 'New group'});
}

export function deleteUserGroup(accountId, groupId, intl) {
    db.remove('/accounts/' + accountId + '/userGroups/' + groupId).then(()=>{
        segmentTrack('Delete user group', { accountId, groupId });
        toastr.success(intl.formatMessage({id:'group.deleted'}));
    });
}

export function changeUserGroup(accountId, userId, toGroupId) {
    db.set('/accounts/' + accountId + '/users/' + userId + '/group', toGroupId || null);
}

export function changeUserGroupParent(accountId, groupId, toGroupId) {
    db.set('/accounts/' + accountId + '/userGroups/' + groupId + '/parent', toGroupId || null);
}

export function changeUserGroupTitle(accountId, groupId, title) {
    db.set('/accounts/' + accountId + '/userGroups/' + groupId + '/title', title);
}

export function updateSentSurveytoUser(accountId, userId) {
    db.set('/accounts/' + accountId + '/users/' + userId + '/surveySent', true);
}

export function createNewSkillGroup(accountId, intl) {
    segmentTrack('Create skill group', { accountId });
    db.push('/accounts/' + accountId + '/skillGroups', { title: intl.formatMessage({id:'New group'}) });
}

export function deleteSkillGroup(accountId, groupId, intl) {
    db.remove('/accounts/' + accountId + '/skillGroups/' + groupId).then(()=>{
        segmentTrack('Delete skill group', { accountId, groupId });
        toastr.success(intl.formatMessage({id:'group.deleted'}));
    });
}

export function changeSkillGroup(accountId, skillId, toGroupId) {
    db.set('/accounts/' + accountId + '/skills/' + skillId + '/group', toGroupId);
}

export function changeSkillGroupParent(accountId, groupId, toGroupId) {
    db.set('/accounts/' + accountId + '/skillGroups/' + groupId + '/parent', toGroupId);
}

export function changeSkillGroupTitle(accountId, groupId, title) {
    db.set('/accounts/' + accountId + '/skillGroups/' + groupId + '/title', title);
}

export function changeWorkspaceName(accountId, name) {
    const userAccounts = getUserAccounts(getState());
    if(!userAccounts) return;

    const accountsList = userAccounts.map(a => ({ ...a, name: a.id === accountId ? name : a.name }));

    db.set('/accounts/' + accountId + '/settings/name', name);
    dispatch(defineUserAccounts(accountsList));

    segmentTrack('Change workspace name', { accountId, name });
}

export function addSlackAccess(slackCred) {
    const accountId = getAccountId(getState());
    slackCred.tasksAdded = true;
    slackCred.tasksChanged = true;
    slackCred.tasksDeleted = true;
    slackCred.eventsAdded = true;
    slackCred.eventsChanged = true;
    slackCred.eventsDeleted = true;

    segmentTrack('Add slack connection', { accountId, slackCred });
    db.push(`/accounts/${accountId}/slackConnections`, slackCred);
}

export function removeSlackConnection(connectionId) {
    const accountId = getAccountId(getState());
    segmentTrack('Remove slack connection', { accountId, connectionId });
    db.remove(`/accounts/${accountId}/slackConnections/` + connectionId);
}

export function changeSlackConnectionHierarchyFilter(connectionId, values) {
    const accountId = getAccountId(getState());
    const hierarchyValues = !_.isEmpty(values) ? values : null;
    db.set(`/accounts/${accountId}/slackConnections/${connectionId}/hierarchy`, hierarchyValues);
}

export function changeSlackConnectionVar(accountId, connectionId, name, value) {
    db.set('/accounts/' + accountId + '/slackConnections/' + connectionId + '/' + name, value);
}

export function changeSlackConnectionAssignedFilter(accountId, connectionId, values) {
    db.set('/accounts/' + accountId + '/slackConnections/' + connectionId + '/assignedTo', values);
}

export function changeSlackConnectionMadebyFilter(connectionId, values) {
    const accountId = getAccountId(getState());
    const madeByValues = !_.isEmpty(values) ? values : null;
    db.set(`/accounts/${accountId}/slackConnections/${connectionId}/madeBy`, madeByValues);
}

// Does not update workload neither base task permissions
export function changeSettingsPermissions(permissionType, usersIds = []) {
    const accountId = getAccountId(getState());
    const accountUsers = getAccountUsers(getState());
    const defaultPermissions = {
        admin: false,
        team: false,
        skills: false,
        daysoff: false,
        boards: false,
        subscription: false,
    };

    const updates = usersIds.reduce((acc, userId) => {
        const userHasPermissions = !!accountUsers[userId]?.permissions;
        const permissionsToUpdate = userHasPermissions ? accountUsers[userId]?.permissions : defaultPermissions;

        const newUpdatedPermissions = {
            ...permissionsToUpdate,
            [permissionType]: usersIds.includes(userId)
        };

        acc[`/accounts/${accountId}/users/${userId}/permissions`] = newUpdatedPermissions;
        return acc;
    }, {});

    segmentTrack('Changed settings permissions', { accountId, permissionType, usersIds });
    db.update(undefined, updates);
}

export function changeCanUsersManageOwnSkills(canManageOwnSkills) {
    const accountId = getAccountId(getState());
    segmentTrack('Changed settings permissions', { accountId, canManageOwnSkills });
    db.set(`/accounts/${accountId}/settings/canUsersManageOwnSkills`, canManageOwnSkills);
}

export const removeUserPermission = (permissionType, userId) => {
    const accountId = getAccountId(getState());
    const user = getAccountUsers(getState())[userId];

    const updatedUserPermissions = {
        ...user.permissions,
        [permissionType]: false
    };

    const updates = {[`/accounts/${accountId}/users/${userId}/permissions`]: updatedUserPermissions};
    segmentTrack('Removed user settings permissions', { accountId, permissionType, userId });
    db.update(undefined, updates);
};

export function updateWorkloadPermissions(memberId, values) {
    const accountId = getAccountId(getState());

    segmentTrack('Updated user workload permissions', { accountId, memberId, values });
    db.set(`/accounts/${accountId}/users/${memberId}/permissions/workload`, values);
}

export function setPermissions(accountId, itemId, data, previousComingFrom, intl) {
    let accountData = getAppData().account;
    let updates = {};

    if (itemId === 'base') {
        const basePermUpdates = setBasePermissions(accountId, data, accountData, true);
        updates = basePermUpdates.updates;
    } else {
        var dataToSave = { ...data };
        delete dataToSave.revertPermissions;
        delete dataToSave.applyToChildrens;
        delete dataToSave.applyToAllChildrens;
        delete dataToSave.editActive;
        delete dataToSave.type;
        dataToSave.comingFrom = itemId;

        if (data.revertPermissions) {
            var parent = accountData.tasks[itemId].parent;
            if (!parent) {
                dataToSave = { comingFrom: 'base' };
            } else {
                dataToSave = { comingFrom: accountData.tasks[parent].permissions.comingFrom };
            }
        }

        // apply rules to this task
        if(
            accountData.tasks[itemId] &&
            (
                !_.isEqual(accountData.tasks[itemId].permissions?.comingFrom, dataToSave.comingFrom) ||
                !_.isEqual(accountData.tasks[itemId].permissions?.owners, dataToSave.owners) ||
                !_.isEqual(accountData.tasks[itemId].permissions?.managers, dataToSave.managers) ||
                !_.isEqual(accountData.tasks[itemId].permissions?.workers, dataToSave.workers) ||
                !_.isEqual(accountData.tasks[itemId].permissions?.viewers, dataToSave.viewers)
            )
        ){
            updates['/accounts/' + accountId + '/tasks/' + itemId + '/permissions'] = dataToSave;
        }

        // if data.applyToChildrens ou data.applyToAllChildrens get list of all childrens
        if (data.applyToChildrens || data.applyToAllChildrens) {
            var taskList = getChildrensOfTask(getAppData()?.parentChildIds.parents, itemId);

            // for each task
            taskList.forEach(item => {
                var itemPermissions;
                if (accountData.tasks[item]) {
                    itemPermissions = { ...accountData.tasks[item].permissions };
                }
                // if should apply to child apply rules
                if (data.applyToAllChildrens || (data.applyToChildrens && itemPermissions && itemPermissions.comingFrom === previousComingFrom)) {
                    let dataToSave2 = {...dataToSave};
                    if (dataToSave2.comingFrom !== item) {
                        delete dataToSave2.managers;
                        delete dataToSave2.owners;
                        delete dataToSave2.viewers;
                        delete dataToSave2.workers;
                    }

                    if(
                        accountData.tasks[item] &&
                        (
                            !_.isEqual(accountData.tasks[item].permissions?.comingFrom, dataToSave.comingFrom) ||
                            !_.isEqual(accountData.tasks[item].permissions?.owners, dataToSave.owners) ||
                            !_.isEqual(accountData.tasks[item].permissions?.managers, dataToSave.managers) ||
                            !_.isEqual(accountData.tasks[item].permissions?.workers, dataToSave.workers) ||
                            !_.isEqual(accountData.tasks[item].permissions?.viewers, dataToSave.viewers)
                        )
                    ){
                        updates['/accounts/' + accountId + '/tasks/' + item + '/permissions'] = dataToSave2;
                    }


                    // Clean up new workers allowed from forced assignees
                    if(accountData?.tasks[item]?.forcedUser && accountData?.tasks[item]?.status === 'todo' && dataToSave?.workers) {
                        const currForcedUsers = accountData.tasks[item].forcedUser;
                        const forcedUserUpdated = currForcedUsers.filter(userId=>dataToSave.workers.includes(userId));

                        updates['/accounts/' + accountId + '/tasks/' + item + '/forcedUser'] = forcedUserUpdated;
                    }
                }
            });
        }

    }

    segmentTrack('Updated permissions', { accountId, data });
    toastr.success(intl.formatMessage({id:'permissions.saved'}));
    db.update(undefined, updates);
}

function setBasePermissions(accountId, data, accountData, returnChanges) {
    if (!accountData) {
        accountData = { ...getAppData().account };
    }

    var baseData = { ...data };
    delete baseData.comingFrom;

    var updates = {
        ['/accounts/' + accountId + '/settings/basePermissions']: baseData
    };

    segmentTrack('Updated base permissions', { accountId, data });

    if (returnChanges) {
        return {
            updates,
        };
    } else {
        db.update(undefined, updates);
    }
}

export function addApiKey(accountId) {
    var newApiKey = db.pushkey('/apiKeys') + db.pushkey('/apiKeys') + db.pushkey('/apiKeys');

    db.update(undefined, {
        ['/apiKeys/' + newApiKey]: { accountId: accountId, name: '' },
        ['/accounts/' + accountId + '/apiKeys/' + newApiKey]: { name: '' }
    });
}

export function deleteApiKey(code) {
    db.remove('/apiKeys/' + code);
    db.remove('/accounts/' + getAppData().account.settings.id + '/apiKeys/' + code);
}

export function nameApiKey(code, name) {
    db.update(undefined, {
        ['/apiKeys/' + code + '/name']: name,
        ['/accounts/' + getAppData().account.settings.id + '/apiKeys/' + code + '/name']: name
    });
}

export function importTasks(accountId, userId, data, parentId) {
    let accountData = getAppData().account;
    let updates = {};

    if (parentId === 'base') {
        parentId = null;
    }

    data.skills.forEach((val, index) => {
        if (val.value === 'new') {
            var newSkillKey = db.pushkey('/accounts/' + accountId + '/skills');
            data.skills[index].value = newSkillKey;
            updates['/accounts/' + accountId + '/skills/' + newSkillKey] = { name: val.name, factor: 2 };
        }
    });
    let countNewUsers = 0;
    data.teams.forEach((val, index) => {
        if (val.value === 'new') {
            var newUserKey = db.pushkey('/users');
            data.teams[index].value = newUserKey;

            updates['/users/' + newUserKey] = { accounts: [accountId] };
            let userVal = {
                accountId: accountId,
                userId: newUserKey,
                displayName: val.name,
                schedule: [
                    { d: '1', day: 'Mon', start: '09:00', end: '12:00', nbMin: 180 },
                    { d: '1', day: 'Mon', start: '13:00', end: '18:00', nbMin: 300 },
                    { d: '2', day: 'Tue', start: '09:00', end: '12:00', nbMin: 180 },
                    { d: '2', day: 'Tue', start: '13:00', end: '18:00', nbMin: 300 },
                    { d: '3', day: 'Wed', start: '09:00', end: '12:00', nbMin: 180 },
                    { d: '3', day: 'Wed', start: '13:00', end: '18:00', nbMin: 300 },
                    { d: '4', day: 'Thu', start: '09:00', end: '12:00', nbMin: 180 },
                    { d: '4', day: 'Thu', start: '13:00', end: '18:00', nbMin: 300 },
                    { d: '5', day: 'Fri', start: '09:00', end: '12:00', nbMin: 180 },
                    { d: '5', day: 'Fri', start: '13:00', end: '18:00', nbMin: 300 },
                ],
                timezone: moment.tz.guess() || 'Europe/London',
                isAdmin: false,
                permissions: {
                    admin: false,
                    team: false,
                    skills: false,
                    daysoff: false,
                    boards: false,
                    subscription: false,
                },
                filterPresets: {
                    activetasks: {
                        filters: '{"0":{"0":{"id":"status","type":"isnot","value":["done"]},"andor":"and"},"andor":"and"}',
                        name: 'Active tasks',
                    },
                    mytasks: {
                        filters:
                            '{"0":{"0":{"id":"status","type":"is","value":["todo"]},"1":{"id":"assigned","type":"is","value":["' +
                            newUserKey +
                            '"]},"andor":"and"},"andor":"and"}',
                        name: 'My tasks to be done',
                    },
                    whatimworkingon: {
                        filters:
                            '{"0":{"0":{"id":"tasktype","type":"is","value":["task"]},"1":{"id":"status","type":"is","value":["inprogress"]},"2":{"id":"assigned","type":"is","value":["' +
                            newUserKey +
                            '"]},"andor":"and"},"andor":"and"}',
                        name: 'What I\'m working on',
                    },
                    whatmyteamisdoing: {
                        filters:
                            '{"0":{"0":{"id":"tasktype","type":"is","value":["task"]},"1":{"id":"status","type":"is","value":["inprogress"]},"2":{"id":"assigned","type":"isnot","value":["' +
                            newUserKey +
                            '"]},"andor":"and"},"andor":"and"}',
                        name: 'What my team is doing',
                    },
                },
            };
            updates['/accounts/' + accountId + '/users/' + newUserKey] = userVal;
            countNewUsers++;

            var basePermissions = accountData.settings.basePermissions;
            basePermissions.workers.push(newUserKey);

            var basePermUpd = setBasePermissions(accountId, basePermissions, accountData, true);
            updates = _.extend(updates, basePermUpd.updates);

        }
    });

    var rootChildrens = [];

    data.results.forEach((t, i) => {
        data.results[i].childrens = [];

        data.results[i].taskId = db.pushkey('/accounts/' + accountId + '/tasks');
    });

    data.results.forEach((task, index) => {
        var skill = null,
            assignedTo = null,
            status = task.status || 'todo',
            minEffort = task.minEffort || null,
            maxEffort = task.maxEffort || null,
            parent = parentId || null,
            forcedUser = null,
            userWorking = null,
            startedWorkingAt = null,
            doneAt = null,
            taskIndex = rootChildrens.length;

        if (task?.skill?.length) {
            skill = task.skill?.map(skillIndex => data.skills[skillIndex].value);
        }
        if ((task.assignedTo || task.assignedTo === 0) && data.teams[task.assignedTo] !== 'no') {
            if (data.teams[task.assignedTo].value) {
                assignedTo = data.teams[task.assignedTo].value;
            } else {
                assignedTo = data.teams[task.assignedTo];
            }
        }
        if (task.parent || task.parent === 0) {
            parent = data.results[task.parent]?.taskId;
        }
        if (status === 'todo' && (task.assignedTo || task.assignedTo === 0)) {
            forcedUser = [assignedTo];
        }
        if (status === 'inprogress' && (task.assignedTo || task.assignedTo === 0)) {
            userWorking = [assignedTo];
            startedWorkingAt = moment().format('YYYY-MM-DD');
        }
        if (status === 'done' && task.doneAt) {
            doneAt = moment(task.doneAt).format('YYYY-MM-DD');
        }
        if (status === 'done' && !task.doneAt) {
            doneAt = moment().format('YYYY-MM-DD');
        }

        if (!data.results[index].childrens) {
            data.results[index].childrens = [];
        }

        if (task.parent || task.parent === 0) {
            taskIndex = data.results[task.parent].childrens.length;
            data.results[task.parent].childrens.push({ id: data.results[index].taskId, type: 'task' });
        } else {
            rootChildrens.push({ id: data.results[index].taskId, type: 'task' });
        }

        let followObj = {};
        let permissions;

        if (parentId && accountData.tasks[parentId].permissions) {
            permissions = { comingFrom: accountData.tasks[parentId].permissions.comingFrom };
            followObj = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
                acc[userId] = 'inherited';
                return acc;
            }, {});
        } else {
            permissions = { comingFrom: 'base' };
            followObj[userId] = 'inherited';
        }
        let newTaskData = {
            permissions: permissions,
            createdAt: moment().format(),
            status: status,
            createdBy: userId,
            followers: followObj,
            title: task.title || '',
            priority: task.priority || 0,
            index: taskIndex,
            minEffort:  minEffort ? minEffort : 0,
            maxEffort:  maxEffort ? maxEffort : 1,
            skill: skill && skill.length ? skill : [],
        };

        if (parent) {
            newTaskData.parent = parent;
        }
        if (task.dueDate) {
            newTaskData.deadline = task.dueDate;
        }
        if (task.delayDate) {
            newTaskData.delayUntil = task.delayDate;
        }
        if (forcedUser) {
            newTaskData.forcedUser = forcedUser;
        }
        if (userWorking) {
            newTaskData.userWorking = userWorking;
        }
        if (startedWorkingAt) {
            newTaskData.startedWorkingAt = startedWorkingAt;
        }
        if (doneAt) {
            newTaskData.doneAt = doneAt;
        }

        updates['/accounts/' + accountId + '/tasks/' + data.results[index].taskId] = newTaskData;
        newTaskData.canView = true;

        if (task.description) {
            updates['/accountsData/' + accountId + '/tasks/' + data.results[index].taskId + '/description'] = task.description;
        }
    });

    // childrens
    data.results.forEach(task => {
        if (task.childrens.length > 0) {
            var tempUpdates = {};
            for(var ref in updates){
                if (ref === '/accounts/' + accountId + '/tasks/' + task.taskId) {
                    tempUpdates[ref] = {...updates[ref], childrens:task.childrens};
                } else {
                    tempUpdates[ref] = updates[ref];
                }
            }
            updates = tempUpdates;
        }
    });
    if (parentId) {
        var actualChilds = accountData.tasks[parentId].childrens || [];
        rootChildrens.forEach(val => {
            actualChilds.push(val);
        });

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] =  actualChilds;
    }


    var tasksP = getTasksFromParent(parentId);
    let newIndexes = {};
    let newList = [];
    //insert new tasks if from this parent
    data.results.forEach(task => {
        if(updates['/accounts/' + accountId + '/tasks/' + task.taskId] && updates['/accounts/' + accountId + '/tasks/' + task.taskId].parent === parentId){
            newList.push(task.taskId);
            newIndexes[task.taskId] = {id: task.taskId, index: updates['/accounts/' + accountId + '/tasks/' + task.taskId].index};
        }
    });

    tasksP.forEach(t => {
        let tIndex = 0;
        if(t.index >= 0){
            tIndex = t.index + rootChildrens.length;
        }

        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(newList.indexOf(item.id) !== -1){
            if(updates['/accounts/' + accountId + '/tasks/' + item.id].index !== currindex){
                updates['/accounts/' + accountId + '/tasks/' + item.id].index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });

    changeChallengeTaskCount(data.results.length);
    segmentTrack('Imported tasks');

    let countUsers = countNewUsers;
    for (var i in accountData.users) {
        if (!accountData.users[i].viewer) {
            countUsers++;
        }
    }
    // if more users than on pla, update the subscription
    if (accountData.settings.plan !== 'trial' && accountData.settings.planNbusers < countUsers) {
        $.ajax({
            url: CLOUD_FUNCTIONS.addUserToSubscription,
            type: 'GET',
            data: {
                chargebeeCustomerId: accountData.settings.chargebeeCustomerId,
                nbUsers: countUsers
            },
        });
    }

    if (accountData.settings.plan === 'trial' && countUsers > 4) {
        toastr.error('Your import could not be completed.');
    }
    else {
        db.update(undefined, updates);
    }
    return new Promise(resolve => {
        resolve();
    });
}

export function removeGamification() {
    if (getAppData()?.user?.data?.userId) {
        db.set('/users/' + getAppData()?.user?.data?.userId + '/challenges/show', false);
    }
}


export function addTodo(title, order) {
    let path = '/accounts/' + getAppData().account.settings.id + '/users/' + getAppData()?.user?.data?.userId + '/todos';
    const newTodoKey = db.pushkey(path);

    tracking('Added task private todo', {
        accountId: getAppData().account.settings?.id,
        userId: getAppData()?.user?.data?.userId,
    });

    db.set(path + '/' + newTodoKey, { id: newTodoKey, title, order });
    return new Promise(resolve => {
        resolve(newTodoKey);
    });
}

export function saveTodoTitle(title, todoId) {
    return db.set(
        '/accounts/' + getAppData().account.settings.id + '/users/' + getAppData()?.user?.data?.userId + '/todos/' + todoId + '/title',
        title
    );
}

export function markTodo(checked, todoId) {
    var todos = {...getAppData()?.user?.data.todos};
    if (checked) {
        var todoOrder = todos[todoId].order;
        for (var i in todos) {
            if (todos[i].order > todoOrder) {
                todos[i].order = todos[i].order - 1;
            }
            if (i === todoId) {
                todos[i].doneAt = moment().unix();
                todos[i].order = null;
            }
        }
    } else {
        var heighest = 0;
        for (var j in todos) {
            if (todos[j].order > heighest) {
                heighest = todos[j].order;
            }
        }
        todos[todoId].doneAt = null;
        todos[todoId].order = heighest + 1;
    }

    return db.set(
        '/accounts/' + getAppData().account.settings.id + '/users/' + getAppData()?.user?.data?.userId + '/todos',
        todos
    );
}

export function deleteTodo(todoId) {
    var todos = {...getAppData()?.user?.data.todos};
    if (!todos[todoId].doneAt) {
        for (var i in todos) {
            if (todos[i].order > todos[todoId].order) {
                todos[i].order = todos[i].order - 1;
            }
        }
    }
    delete todos[todoId];

    tracking('Deleted task private todo', {
        accountId: getAppData().account.settings?.id,
        userId: getAppData()?.user?.data?.userId,
    });

    return db.set(
        '/accounts/' + getAppData().account.settings.id + '/users/' + getAppData()?.user?.data?.userId + '/todos',
        todos
    );
}

export function orderTodos(todoId, oldIndex, newIndex) {
    var todos = {...getAppData()?.user?.data.todos};
    for (var i in todos) {
        if (oldIndex < newIndex && todos[i].order >= oldIndex && todos[i].order <= newIndex) {
            todos[i].order = todos[i].order - 1;
        } else if (oldIndex > newIndex && todos[i].order >= newIndex && todos[i].order <= oldIndex) {
            todos[i].order = todos[i].order + 1;
        }
    }
    todos[todoId].order = newIndex;

    return db.set(
        '/accounts/' + getAppData().account.settings.id + '/users/' + getAppData()?.user?.data?.userId + '/todos',
        todos
    );
}

export function saveTimer(taskId, start, pause) {
    return db.update(undefined, {
        ['/accounts/' + getAppData().account.settings.id + '/tasks/' + taskId + '/timerPause']: pause,
        ['/accounts/' + getAppData().account.settings.id + '/tasks/' + taskId + '/timerStart']: start
    });
}

export function addChatMessage(accountId, channelType, channelId, userId, comment, mentions) {
    let updates = {};
    segmentTrack('Add chat message', { accountId, channelType, channelId, userId, comment, mentions });

    let channelIdChecked;
    let msgUpdate;

    if (channelType === 'direct') {
        for (var i in getAppData()?.account?.chat.direct) {
            var currChannel = getAppData()?.account?.chat.direct[i];
            if (
                currChannel.participants &&
                currChannel.participants.indexOf(userId) !== -1 &&
                currChannel.participants.indexOf(channelId) !== -1
            ) {
                channelIdChecked = i;
            }
        }

        if (!channelIdChecked) {
            channelIdChecked = db.pushkey('/accounts/' + accountId + '/chat/direct');
            updates['/accounts/' + accountId + '/chat/direct/' + channelIdChecked + '/participants'] = [userId, channelId];

            msgUpdate = addChatMessagePush(
                accountId,
                channelType,
                channelIdChecked,
                userId,
                comment,
                mentions,
                'text',
                null,
                null,
                true,
            );
            updates = _.extend(updates, msgUpdate.updates);

        } else {
            msgUpdate = addChatMessagePush(
                accountId,
                channelType,
                channelIdChecked,
                userId,
                comment,
                mentions,
                'text',
                null,
                null,
                true,
            );
            updates = _.extend(updates, msgUpdate.updates);
        }
    } else {
        channelIdChecked = channelId;
        msgUpdate = addChatMessagePush(
            accountId,
            channelType,
            channelIdChecked,
            userId,
            comment,
            mentions,
            'text',
            null,
            null,
            true,
        );
        updates = _.extend(updates, msgUpdate.updates);
    }

    db.update(undefined, updates);
}

function addChatMessagePush(
    accountId,
    channelType,
    channelIdChecked,
    userId,
    comment,
    mentions,
    type,
    doc,
    meetingData,
    shouldReturn,
) {
    let updates = {};

    var newKey = db.pushkey('/accounts/' + accountId + '/chat/' + channelType + '/' + channelIdChecked + '/messages');
    var data = {
        at: moment().format(),
        k: newKey,
        seenBy: [userId],
        sentBy: userId,
        type: type,
        value: comment,
        mentions: mentions,
    };

    if (doc) {
        data.value = doc.name;
        data.url = doc.url;
        data.fullPath = doc.fullPath;
        data.contentType = doc.contentType;
    }

    if (meetingData) {
        data.meetingData = meetingData;
    }

    updates['/accounts/' + accountId + '/chat/' + channelType + '/' + channelIdChecked + '/messages/' + newKey] = data;

    if (shouldReturn) {
        return {
            updates
        };
    } else {
        db.update(undefined, updates);
    }
}

export function addFileToChat(accountId, userId, channelType, channelId, doc) {
    let updates = {};
    segmentTrack('Add file to chat', { accountId, userId, channelType, channelId, doc });

    var channelIdChecked;
    let msgUpdate;

    if (channelType === 'direct') {
        for (var i in getAppData()?.account?.chat.direct) {
            var currChannel = getAppData()?.account?.chat.direct[i];
            if (
                currChannel.participants &&
                currChannel.participants.indexOf(userId) !== -1 &&
                currChannel.participants.indexOf(channelId) !== -1
            ) {
                channelIdChecked = i;
            }
        }

        if (!channelIdChecked) {
            channelIdChecked = db.pushkey('/accounts/' + accountId + '/chat/direct');
            updates['/accounts/' + accountId + '/chat/direct/' + channelIdChecked + '/participants'] = [userId, channelId];

            msgUpdate = addChatMessagePush(
                accountId,
                channelType,
                channelIdChecked,
                userId,
                null,
                null,
                'file',
                doc,
                null,
                true,
            );
            updates = _.extend(updates, msgUpdate.updates);
        } else {
            msgUpdate = addChatMessagePush(
                accountId,
                channelType,
                channelIdChecked,
                userId,
                null,
                null,
                'file',
                doc,
                null,
                true,
            );
            updates = _.extend(updates, msgUpdate.updates);
        }
    } else {
        channelIdChecked = channelId;
        msgUpdate = addChatMessagePush(
            accountId,
            channelType,
            channelIdChecked,
            userId,
            null,
            null,
            'file',
            doc,
            null,
            true,
        );
        updates = _.extend(updates, msgUpdate.updates);
    }

    if (doc.firebaseStorage) {
        var newStorageSize = getAppData()?.account?.storageSize || 0;
        newStorageSize += doc.size;
        getAppData().account.storageSize = newStorageSize;
        updates['/accounts/' + accountId + '/settings/storageSize'] = newStorageSize;
    }

    db.update(undefined, updates);
}

export function markMessagesOfChannelAsRead(type, uid) {
    if (getAppData()?.user?.data?.userId && getAppData().account?.chat[type]) {
        var userId = getAppData()?.user?.data?.userId;
        var channelIdChecked;

        if (type === 'direct') {
            for (var i in getAppData()?.account?.chat.direct) {
                var currChannel = getAppData()?.account?.chat.direct[i];
                if (
                    currChannel.participants &&
                    currChannel.participants.indexOf(userId) !== -1 &&
                    currChannel.participants.indexOf(uid) !== -1
                ) {
                    channelIdChecked = i;
                }
            }
        } else {
            channelIdChecked = uid;
        }

        if (channelIdChecked && getAppData()?.account?.chat[type][channelIdChecked]) {
            var updates = {};

            var messages = getAppData().account.chat[type][channelIdChecked].messages;

            for (var j in messages) {
                var msg = messages[j];
                var values = msg.seenBy || [];
                if (values.indexOf(userId) === -1) {
                    values.push(userId);
                    updates['/accounts/' + getAppData()?.account?.settings?.id + '/chat/' + type + '/' + channelIdChecked + '/messages/' + msg.k + '/seenBy'] = values;
                }
            }

            db.update(undefined, updates);
        }
    }
}

export function markChatMessageAsRead(channelType, channelId, messageKey) {
    let accountData = getAppData().account;

    if (
        getAppData()?.user?.data?.userId &&
        getAppData().account &&
        accountData.chat &&
        accountData.chat[channelType] &&
        accountData.chat[channelType][channelId] &&
        accountData.chat[channelType][channelId].messages &&
        accountData.chat[channelType][channelId].messages[messageKey]
    ) {
        var userId = getAppData()?.user?.data?.userId;
        var values = accountData.chat[channelType][channelId].messages[messageKey].seenBy || [];
        if (values.indexOf(userId) === -1) {
            values.push(userId);
        }
        db.set(
            '/accounts/' + getAppData()?.account?.settings?.id + '/chat/' + channelType + '/' + channelId + '/messages/' + messageKey + '/seenBy',
            values
        );
    }
}

export function deleteChatChannel(channelType, channelId) {
    db.remove('/accounts/' + getAppData()?.account?.settings?.id + '/chat/' + channelType + '/' + channelId);
}

export function leaveChatChannel(channelType, channelId) {
    let accountData = getAppData().account;
    let updates = {};
    var channel = {...accountData.chat[channelType][channelId]};
    var index = channel.participants.indexOf(getAppData()?.user?.data?.userId);
    channel.participants.splice(index, 1);

    if (channel.perm && channel.perm.inviters && channel.perm.inviters.indexOf(getAppData()?.user?.data?.userId) !== -1) {
        var index2 = channel.perm.inviters.indexOf(getAppData()?.user?.data?.userId);
        channel.perm.inviters.splice(index2, 1);
    }
    updates['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId] = channel;

    var newKey = db.pushkey(
        '/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages',
    );
    let newData = {
        k: newKey,
        type: 'left',
        sentBy: getAppData()?.user?.data?.userId,
        at: moment().format(),
    };
    updates['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages/' + newKey] = newData;

    db.update(undefined, updates);
}

export function addChatParticipants(channelType, channelId, usersToAdd, accountData, returnChanges) {
    if (!accountData) {
        accountData = { ...getAppData().account };
    }
    var channelParticipants = [...accountData.chat[channelType][channelId].participants];
    channelParticipants = channelParticipants.concat(usersToAdd);

    var updates = {
        ['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/participants']: channelParticipants
    };

    if (usersToAdd) {
        usersToAdd.forEach(userId => {
            var newKey = db.pushkey(
                '/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages',
            );
            var value = {
                k: newKey,
                type: 'added',
                sentBy: userId,
                at: moment().format(),
                seenBy: [getAppData()?.user?.data?.userId],
            };
            updates['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages/' + newKey] = value;
        });
    }

    if (returnChanges) {
        return {
            updates
        };
    } else {
        db.update(undefined, updates);
    }
}

export function removeChatParticipants(channelType, channelId, usersToRemove) {
    let accountData = getAppData().account;
    let updates = {};
    var channelParticipants = [...accountData.chat[channelType][channelId].participants];
    channelParticipants = _.difference(channelParticipants, usersToRemove);

    updates['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/participants'] = channelParticipants;

    usersToRemove.forEach(userId => {
        var newKey = db.pushkey(
            '/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages',
        );
        const newData = {
            k: newKey,
            type: 'removed',
            sentBy: userId,
            at: moment().format(),
            seenBy: [getAppData()?.user?.data?.userId],
        };
        updates['/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelId + '/messages/' + newKey] = newData;
    });

    db.update(undefined, updates);
}

export function renameChatChannel(channelType, channelId, newName) {
    return db.update(undefined, {
        ['/accounts/' + getAppData().account.settings.id + '/chat/' + channelType + '/' + newName]: { ...getAppData().account.chat[channelType][channelId] },
        ['/accounts/' + getAppData().account.settings.id + '/chat/' + channelType + '/' + channelId]: null
    });
}

export function changeChannelDescription(channelType, channelId, newDesc) {
    db.set(
        '/accounts/' + getAppData()?.account?.settings?.id + '/chat/' + channelType + '/' + channelId + '/description',
        newDesc
    );
}

export function createGroupChat(participants) {
    let messages = {};

    participants.forEach(p => {
        messages['add' + p] = {
            k: 'add' + p,
            type: 'added',
            sentBy: p,
            at: moment().format(),
            seenBy: [getAppData()?.user?.data?.userId],
        };
    });

    participants = [getAppData()?.user?.data?.userId].concat(participants);

    var newKey = db.pushkey('/accounts/' + getAppData().account.settings.id + '/chat/groups');
    const data = {
        participants,
        messages,
        perm: {
            owners: [getAppData()?.user?.data?.userId],
        },
    };

    db.set('/accounts/' + getAppData().account.settings.id + '/chat/groups/' + newKey, data);

    return new Promise(resolve => {
        resolve(newKey);
    });
}

export function createChannel(name, participants) {
    var messages = {};

    participants.forEach(p => {
        messages['add' + p] = {
            k: 'add' + p,
            type: 'added',
            sentBy: p,
            at: moment().format(),
            seenBy: [getAppData()?.user?.data?.userId],
        };
    });

    participants = [getAppData()?.user?.data?.userId].concat(participants);
    let data = {
        participants,
        messages,
        perm: {
            owners: [getAppData()?.user?.data?.userId],
        },
    };

    db.set('/accounts/' + getAppData().account.settings.id + '/chat/channels/' + name, data);
    return new Promise(resolve => {
        resolve(name);
    });
}

export function deleteChatMessage(channelType, channelId, messageId) {
    let accountData = getAppData().account;
    var channelIdChecked;

    if (channelType === 'direct') {
        for (var i in accountData.chat.direct) {
            var currChannel = accountData.chat.direct[i];
            if (
                currChannel.participants &&
                currChannel.participants.indexOf(getAppData()?.user?.data?.userId) !== -1 &&
                currChannel.participants.indexOf(channelId) !== -1
            ) {
                channelIdChecked = i;
            }
        }
    } else {
        channelIdChecked = channelId;
    }

    if (
        accountData.chat[channelType] &&
        accountData.chat[channelType][channelIdChecked] &&
        accountData.chat[channelType][channelIdChecked].messages &&
        accountData.chat[channelType][channelIdChecked].messages[messageId]
    ) {
        var data = { ...accountData.chat[channelType][channelIdChecked].messages[messageId] };
        data.type = 'deleted-' + data.type;

        db.set(
            '/accounts/' + accountData.settings.id + '/chat/' + channelType + '/' + channelIdChecked + '/messages/' + messageId,
            data
        );
    }
}

export function addChatGoogleMeeting(channelType, channelId, link) {
    if (link) {
        var channelIdChecked;

        if (channelType === 'direct') {
            for (var i in getAppData().account.chat.direct) {
                var currChannel = getAppData().account.chat.direct[i];
                if (
                    currChannel.participants &&
                    currChannel.participants.indexOf(getAppData()?.user?.data?.userId) !== -1 &&
                    currChannel.participants.indexOf(channelId) !== -1
                ) {
                    channelIdChecked = i;
                }
            }
        } else {
            channelIdChecked = channelId;
        }

        addChatMessagePush(
            getAppData().account.settings.id,
            channelType,
            channelIdChecked,
            getAppData()?.user?.data?.userId,
            '',
            null,
            'googleMeeting',
            null,
            link,
        );
    }
}

function makeid(length) {
    var result = '';
    var characters = '-abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function changeEmailToTask(taskId, status) {
    let accountData = getAppData().account;
    let updates = {};

    if (!status) {
        updates['/emailToTask/' + accountData.tasks[taskId].emailToTask] = null;
        updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/emailToTask'] = null;
    } else {
        let newKey = makeid(48);
        updates['/emailToTask/' + newKey] = { aId: accountData.settings.id, tId: taskId };
        updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/emailToTask'] = newKey;

        tracking('Emailed to task', taskId);
    }

    return db.update(undefined, updates);
}

export function changeDependencySetting(param) {
    if (param && ['allow', 'warn', 'block'].indexOf(param) !== -1) {
        segmentTrack('Update workspace dependency setting', { accountId: getAppData().account.settings.id, param });
        db.set('/accounts/' + getAppData().account.settings.id + '/settings/dependenciesBlock', param);
    }
}

var updatingStats = false;

export async function saveStats() {
    const currentTime4h = Math.floor(moment().unix() / 60 / 60 / 4);
    const account = getAppData().account;
    const accountSettings = account?.settings;
    const lastStatsSaved = accountSettings?.lastStatsSaved;

    const hasMoreThanFourHoursSinceLastStatsSaved = !lastStatsSaved || (lastStatsSaved && currentTime4h > lastStatsSaved);

    if (updatingStats || !hasMoreThanFourHoursSinceLastStatsSaved || !accountSettings || !accountSettings?.id) {
        return;
    }

    updatingStats = true;

    await db.set('/accounts/' + accountSettings.id + '/settings/lastStatsSaved', currentTime4h);
    await saveStatsData({ ...account, time: currentTime4h, id: accountSettings.id });

    updatingStats = false;
}

export async function alertsHandlerCaller() {
    const currentTime4h = Math.floor(moment().unix() / 60 / 60 / 4);
    const account = getAppData().account;
    const accountSettings = account?.settings;
    const lastStatsSaved = accountSettings?.lastStatsSaved;

    const hasMoreThanFourHoursSinceLastStatsSaved = !lastStatsSaved || (lastStatsSaved && currentTime4h > lastStatsSaved);

    if (updatingStats || !hasMoreThanFourHoursSinceLastStatsSaved || !accountSettings || !accountSettings?.id) {
        return;
    }

    updatingStats = true;

    await alertsHandler({ ...account, id: accountSettings.id });

    updatingStats = false;
}

export function saveNewTag(taskId, tagName, tagColor = 1) {
    let accountData = getAppData().account;
    var newTagKey = db.pushkey('/accounts/' + accountData.settings.id + '/tags');
    var updates = {};
    updates['/accounts/' + accountData.settings.id + '/tags/' + newTagKey] = { title: tagName, color: tagColor };

    var taskTags = [];
    if (accountData.tasks && accountData.tasks[taskId]) {
        if (accountData.tasks[taskId].tags) {
            taskTags = [...accountData.tasks[taskId].tags];
        }
        taskTags.push(newTagKey);

        updates['/accounts/' + accountData.settings.id + '/tasks/' + taskId + '/tags'] = taskTags;
    }
    segmentTrack('Created Tag', { accountId: accountData.settings.id, tagName });
    db.update(undefined, updates);

    return new Promise(resolve => {
        resolve({ id: newTagKey, title: tagName, color: tagColor });
    });
}

export function changeTags(taskId, tags) {
    segmentTrack('Change Task Tags', { accountId: getAppData().account.settings.id, taskId, tags });
    db.set('/accounts/' + getAppData().account.settings.id + '/tasks/' + taskId + '/tags', tags);
}

export function changeTagTitle(tagId, title) {
    segmentTrack('Change Tag Title', { accountId: getAppData().account.settings.id, tagId, title });
    db.set('/accounts/' + getAppData().account.settings.id + '/tags/' + tagId + '/title', title);
}

export function addNewTag(title = '[NEW TAG]', color = 1) {
    var newTagKey = db.pushkey('/accounts/' + getAppData().account.settings.id + '/tags');
    segmentTrack('Add new tag', { accountId: getAppData()?.account?.settings?.id });
    db.set('/accounts/' + getAppData().account.settings.id + '/tags/' + newTagKey, { title, color });

    return newTagKey;
}

export function deleteTag(tagId) {
    segmentTrack('Deleted Tag', { accountId: getAppData().account.settings.id, tagId });
    db.remove('/accounts/' + getAppData().account.settings.id + '/tags/' + tagId);
}

export function changeTagColor(tagId, color) {
    segmentTrack('Change Tag Color', { accountId: getAppData()?.account?.settings?.id, tagId, color });
    db.set('/accounts/' + getAppData().account.settings.id + '/tags/' + tagId + '/color', color);
}

export function createTaskUnder(taskId) {
    let accountData = getAppData().account;

    let accountId = accountData.settings.id;
    let userId = getAppData()?.user?.data?.userId;

    segmentTrack('Add task', { accountId });
    segmentTrack('Add task under');

    let currentTask, parentId;
    let index = 0;

    if (accountData?.tasks[taskId]) {
        currentTask = accountData.tasks[taskId];
        parentId = currentTask.parent;
        index = currentTask.index + 1;
    }

    let updates = {};

    let newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');

    let followObj = {};
    let permissions;

    if (parentId) {
        permissions = { comingFrom: accountData.tasks[parentId].permissions.comingFrom };
        followObj = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
            acc[userId] = 'inherited';
            return acc;
        }, {});
    } else {
        permissions = { comingFrom: 'base' };
        followObj[userId] = 'inherited';
    }

    let newTaskData = {
        createdAt: moment().format(),
        index: index,
        title: '',
        status: 'todo',
        createdBy: userId,
        followers: followObj,
        permissions: permissions,
        maxEffort: 1,
        minEffort: 0
    };
    if (parentId) {
        newTaskData.parent = parentId;
    }
    updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = newTaskData;

    // if there is a parentId we add the new task to the parent childrens
    if (parentId) {
        let newChilds = [{ id: newTaskKey, type: 'task' }];

        _.each(accountData.tasks[parentId].childrens, c => {
            newChilds.push(c);
        });

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = newChilds;
    }


    var tasksFromParent = getTasksFromParent(parentId);
    let newIndexes = {};
    //insert new task
    newIndexes[newTaskKey] = {id: newTaskKey,index};

    tasksFromParent.forEach(t => {
        var tIndex = t.index;

        if (t.index >= index) {
            tIndex = t.index + 1;
        }
        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(item.id === newTaskKey){
            if(index !== currindex){
                updates['/accounts/' + accountId + '/tasks/' + newTaskKey].index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });


    db.update(undefined, updates);

    dispatch(hideLoader());

    changeChallengeTaskCount(1);

    return new Promise(resolve => {
        resolve(newTaskKey);
    });
}

export function createTaskAbove(taskId) {
    let accountData = getAppData().account;

    let accountId = accountData.settings.id;
    let userId = getAppData()?.user?.data?.userId;

    segmentTrack('Add task', { accountId });
    segmentTrack('Add task under');

    let currentTask, parentId;
    let index = 0;

    if (accountData?.tasks[taskId]) {
        currentTask = accountData.tasks[taskId];
        parentId = currentTask.parent;
        index = currentTask.index;
    }

    let updates = {};

    let newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');

    let followObj = {};
    let permissions;

    if (parentId) {
        permissions = { comingFrom: accountData.tasks[parentId].permissions.comingFrom };
        followObj = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
            acc[userId] = 'inherited';
            return acc;
        }, {});
    } else {
        permissions = { comingFrom: 'base' };
        followObj[userId] = 'inherited';
    }

    let newTaskData = {
        createdAt: moment().format(),
        index: index,
        title: '',
        status: 'todo',
        createdBy: userId,
        followers: followObj,
        permissions: permissions,
        maxEffort: 1,
        minEffort: 0
    };
    if (parentId) {
        newTaskData.parent = parentId;
    }
    updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = newTaskData;

    // if there is a parentId we add the new task to the parent childrens
    if (parentId) {
        let newChilds = [{ id: newTaskKey, type: 'task' }];

        _.each(accountData.tasks[parentId].childrens, c => {
            newChilds.push(c);
        });

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = newChilds;
    }



    var tasksFromParent = getTasksFromParent(parentId);
    let newIndexes = {};
    //insert new task
    newIndexes[newTaskKey] = {id: newTaskKey,index};

    tasksFromParent.forEach(t => {
        var tIndex = t.index;

        if (t.index >= index) {
            tIndex = t.index + 1;
        }
        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(item.id === newTaskKey){
            if(index !== currindex){
                updates['/accounts/' + accountId + '/tasks/' + newTaskKey].index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });


    db.update(undefined, updates);

    dispatch(hideLoader());

    changeChallengeTaskCount(1);

    return new Promise(resolve => {
        resolve(newTaskKey);
    });
}

export function createSubTask(taskId) {
    let accountData = getAppData().account;

    let accountId = accountData.settings.id;
    let userId = getAppData()?.user?.data?.userId;

    segmentTrack('Add task', { accountId });
    segmentTrack('Add subtask', { accountId, taskId });

    let parentId = taskId;
    let index = 0;

    let updates = {};

    let newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');

    let followObj = {};
    let permissions;

    if (parentId) {
        permissions = { comingFrom: accountData.tasks[parentId].permissions.comingFrom };
        followObj = [userId, ...Object.keys(_.get(accountData.tasks, [parentId,'followers'], {}))].reduce((acc, userId) => {
            acc[userId] = 'inherited';
            return acc;
        }, {});
    } else {
        permissions = { comingFrom: 'base' };
        followObj[userId] = 'inherited';
    }
    let newTaskData = {
        createdAt: moment().format(),
        index: index,
        title: '',
        status: 'todo',
        createdBy: userId,
        followers: followObj,
        permissions: permissions,
        maxEffort: 1,
        minEffort: 0
    };

    if (parentId) {
        newTaskData.parent = parentId;
    }
    updates['/accounts/' + accountId + '/tasks/' + newTaskKey] = newTaskData;

    // if there is a parentId we add the new task to the parent childrens
    if (parentId) {
        let newChilds = [{ id: newTaskKey, type: 'task' }];

        _.each(accountData.tasks[parentId].childrens, c => {
            newChilds.push(c);
        });

        updates['/accounts/' + accountId + '/tasks/' + parentId + '/childrens'] = newChilds;
    }


    var tasksFromParent = getTasksFromParent(parentId);
    let newIndexes = {};
    //insert new task
    newIndexes[newTaskKey] = {id: newTaskKey,index};

    tasksFromParent.forEach(t => {
        var tIndex = t.index;

        if (t.index >= index) {
            tIndex = t.index + 1;
        }
        newIndexes[t.id] = {id: t.id, index: tIndex};
        if (tIndex !== t.index) {
            updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
        }
    });


    newIndexes = _.sortBy(newIndexes, 'index');
    newIndexes.forEach((item, currindex)=>{
        if(item.id === newTaskKey){
            if(index !== currindex){
                updates['/accounts/' + accountId + '/tasks/' + newTaskKey].index = currindex;
            }
        }
        else if(
            (!updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && accountData.tasks[item.id].index !== currindex) ||
            (updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] && updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] !== currindex)
        ){
            updates['/accounts/' + accountId + '/tasks/' + item.id + '/index'] = currindex;
        }
    });

    db.update(undefined,updates);

    dispatch(hideLoader());

    changeChallengeTaskCount(1);

    return new Promise(resolve => {
        resolve(newTaskKey);
    });
}

export function multiMarkDone(selectedItems) {
    let accountData = getAppData().account;
    var updates = {};
    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/status'] = 'done';
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/doneAt'] = moment().format('YYYY-MM-DD');
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/board'] = 'done';
        }
    });
    tracking('Edited Multiple Tasks');
    toastr.success('Tasks have been marked as done');
    return db.update(undefined, updates);
}

export function multiForceAssign(userId, selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    if (!userId && getAppData()?.user?.data?.userId) {
        userId = getAppData()?.user?.data?.userId;
    }
    const userName = accountData.users[userId].displayName;
    let canUpdate = true;

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            // check if userId is in task workers
            let perm = getItemPermissions(
                userId,
                item.id,
                accountData.tasks[item.id].permissions,
                accountData.tasks,
                accountData.users[userId]?.permissions?.admin || false,
                accountData?.settings?.basePermissions,
            );
            if (!perm.canWork) {
                canUpdate = false;
            } else {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/forcedUser'] = [userId];
            }
        }
    });
    if (canUpdate) {
        tracking('Edited Multiple Tasks');
        toastr.success('Tasks have been force assigned to ' + userName);
        db.update(undefined, updates);
        return new Promise(resolve => {
            resolve('success');
        });
    } else {
        toastr.error('One or more tasks cannot have this user force assigned. Check tasks permissions.');
        return new Promise(resolve => {
            resolve('error');
        });
    }
}

export function multiRemoveForceAssign(selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/forcedUser'] = null;
        }
    });
    tracking('Edited Multiple Tasks');
    toastr.success('Force assignments removed from tasks.');
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function multiSkillChange(skillId, selectedItems) {
    let accountData = getAppData().account;
    if (!skillId) {
        toastr.error('Please select a skill');
        return new Promise(resolve => {
            resolve('error');
        });
    } else {
        let updates = {};

        selectedItems.forEach(item => {
            if (accountData && accountData.tasks && accountData.tasks[item.id]) {
                updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/skill'] = skillId;
            }
        });

        tracking('Edited Multiple Tasks');
        toastr.success('Tasks skill has been changed');
        db.update(undefined, updates);
        return new Promise(resolve => {
            resolve('success');
        });
    }
}

export function multiEffortChange(minEffort, maxEffort, selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/minEffort'] = parseFloat(minEffort).toFixed(2);
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/maxEffort'] = parseFloat(maxEffort).toFixed(2);
        }
    });

    tracking('Edited Multiple Tasks');
    toastr.success('Tasks effort has been changed');

    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function multiPriorityChange(priority, selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/priority'] = priority;
        }
    });
    tracking('Edited Multiple Tasks');

    toastr.success('Tasks priority has been changed');
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function multiDuedateChange(date, selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/deadline'] = date ? date.toISOString() : null;
        }
    });
    tracking('Edited Multiple Tasks');
    toastr.success('Tasks due date has been changed');
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function multiDelaydateChange(date, selectedItems) {
    let accountData = getAppData().account;
    let updates = {};

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/delayUntil'] = date ? date.toISOString() : null;
        }
    });

    tracking('Edited Multiple Tasks');

    toastr.success('Tasks Wait Until date has been changed');
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function multiTagsChange(tags, selectedItems) {
    let accountData = getAppData().account;
    let updates = {},
        list = [];
    tags.forEach(item => {
        list.push(item.id);
    });
    if (list.length === 0) {
        list = null;
    }

    selectedItems.forEach(item => {
        if (accountData && accountData.tasks && accountData.tasks[item.id]) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + item.id + '/tags'] = list;
        }
    });

    tracking('Edited Multiple Tasks');

    toastr.success('Tasks tags have been changed');
    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

function getDeletedDependencies(taskId, accountData, accountId, existingUpdates) {
    var deletedDependencies = {},
        updates = {},
        changeDependencies = {},
        changeDependentOn = {};

    if (!existingUpdates) {
        existingUpdates = {};
    }

    if (accountData && accountData.tasks) {
        for (var task in accountData.tasks) {
            if (Object.keys(existingUpdates).indexOf('/accounts/' + accountId + '/tasks/' + task) === -1) {
                if (accountData.tasks[task].dependentOnThis && accountData.tasks[task].dependentOnThis.indexOf(taskId) !== -1) {
                    deletedDependencies['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'] =
                        accountData.tasks[task].dependentOnThis;

                    var newDep = accountData.tasks[task].dependentOnThis;
                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis']) {
                        newDep = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'];
                    }

                    newDep.splice(accountData.tasks[task].dependentOnThis.indexOf(taskId), 1);
                    if (newDep.length === 0) {
                        newDep = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'] = newDep;
                    changeDependentOn[task] = newDep;
                }
                if (accountData.tasks[task].dependencies && accountData.tasks[task].dependencies.indexOf(taskId) !== -1) {
                    deletedDependencies['/accounts/' + accountId + '/tasks/' + task + '/dependencies'] =
                        accountData.tasks[task].dependencies;

                    var newDep2 = accountData.tasks[task].dependencies;
                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependencies']) {
                        newDep2 = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependencies'];
                    }

                    newDep2.splice(accountData.tasks[task].dependencies.indexOf(taskId), 1);
                    if (newDep2.length === 0) {
                        newDep2 = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/dependencies'] = newDep2;
                    changeDependencies[task] = newDep2;
                }

                if (
                    accountData.tasks[task].childrens &&
                    _.find(accountData.tasks[task].childrens, child => {
                        return child && child.id === taskId && child.type === 'task';
                    })
                ) {
                    var childrensUpdate = accountData.tasks[task].childrens,
                        indexToRemove = null;

                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/childrens']) {
                        childrensUpdate = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/childrens'];
                    }

                    _.each(childrensUpdate, (child, index) => {
                        if (child.id === taskId) {
                            indexToRemove = index;
                        }
                    });
                    if (indexToRemove !== null) {
                        childrensUpdate.splice(indexToRemove, 1);
                    }

                    if (childrensUpdate.length === 0) {
                        childrensUpdate = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/childrens'] = childrensUpdate;
                }
            }
        }
    }

    return {
        deletedDependencies,
        updates,
        changeDependencies,
        changeDependentOn,
    };
}

export function multiDelete(selectedItems) {
    let accountData = getAppData().account;

    dispatch(showLoader());

    let tasksToDelete = [],
        tasksChangeChildren = {},
        tasksChangeDependencies = {},
        tasksChangeDependentOn = {},
        tasksChangedIndex = {},
        parentId;

    selectedItems.forEach(item => {
        if (item.type === 'task') {
            tasksToDelete.push(item.id);
        }
        parentId = null;
        // check for parents children chnage because of deletion of item
        if (
            accountData.tasks &&
            accountData.tasks[item.id] &&
            accountData.tasks[item.id].parent
        ) {
            parentId = accountData.tasks[item.id].parent;
            if (tasksChangeChildren[parentId] || accountData.tasks[parentId]) {
                var tempParents = tasksChangeChildren[parentId] || accountData.tasks[parentId].childrens;
                var indexToRemove;
                tempParents.forEach((child, index) => {
                    if (child.type === item.type && child.id === item.id) {
                        indexToRemove = index;
                    }
                });
                if (indexToRemove || indexToRemove === 0) {
                    tempParents.splice(indexToRemove, 1);
                }
                tasksChangeChildren[parentId] = tempParents;
            }
        }

        //log index order changes
        var tasks = getTasksFromParent(parentId),
            tIndex = 0;
        tasks.forEach(t => {
            tIndex = null;
            if (t.index > accountData.tasks[item.id].index) {
                var currentIndex = t.index;
                if (tasksChangedIndex[t.id]) {
                    currentIndex = tasksChangedIndex[t.id];
                }

                tIndex = currentIndex - 1;
            }
            if ((tIndex || tIndex === 0) && t.id !== item.id) {
                tasksChangedIndex[t.id] = tIndex;
            }
        });

        // check for dependencies to remove because of task deletion

        var test = getDeletedDependencies(item.id, accountData, accountData.settings.id);
        tasksChangeDependencies = { ...tasksChangeDependencies, ...test.changeDependencies };
        tasksChangeDependentOn = { ...tasksChangeDependentOn, ...test.changeDependentOn };
    });

    //cleanup for updates
    let updates = {};
    tasksToDelete.forEach(tid => {
        updates['/accounts/' + accountData.settings.id + '/tasks/' + tid] = null;
    });
    for (var ti1 in tasksChangeChildren) {
        if (tasksToDelete.indexOf(ti1) === -1) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + ti1 + '/childrens'] = tasksChangeChildren[ti1];
        }
    }
    for (var ti2 in tasksChangeDependencies) {
        if (tasksToDelete.indexOf(ti2) === -1) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + ti2 + '/dependencies'] = tasksChangeDependencies[ti2];
        }
    }
    for (var ti3 in tasksChangeDependentOn) {
        if (tasksToDelete.indexOf(ti3) === -1) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + ti3 + '/dependentOnThis'] = tasksChangeDependentOn[ti3];
        }
    }
    for (var ti4 in tasksChangedIndex) {
        if (tasksToDelete.indexOf(ti4) === -1) {
            updates['/accounts/' + accountData.settings.id + '/tasks/' + ti4 + '/index'] = tasksChangedIndex[ti4];
        }
    }

    tracking('Edited Multiple Tasks');

    dispatch(hideLoader());
    toastr.success('All items have been deleted');

    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve('success');
    });
}

export function logger(name, data) {
    db.push('/accounts/' + getAppData()?.account?.settings?.id + '/logger', {
        when: moment().format(),
        userId: getAppData()?.user?.data?.userId,
        name,
        data,
    });
}

export function updateUserSchedule(userId, data) {
    return db.set('/accounts/' + getAppData().account.settings.id + '/users/' + userId + '/schedule', data);
}

export function updateUserTimezone(userId, timezone) {
    return db.set('/accounts/' + getAppData()?.account?.settings?.id + '/users/' + userId + '/timezone', timezone);
}

export function updateUsersTimezone(val) {
    let updates = {};
    for (var userId in getAppData().account.users) {
        updates['/accounts/' + getAppData().account.settings.id + '/users/' + userId + '/timezone'] = val;
    }

    return db.update(undefined, updates);
}

export function listenTaskDescription(taskId) {
    if (taskId && getAppData()?.account?.settings?.id) {
        db.on('/accountsData/' + getAppData()?.account?.settings?.id + '/tasks/' + taskId + '/description', desc => {
            if (desc.val()) {
                dispatch(setTaskDescription(taskId, desc.val()));
            }
        });
    }
}

export function unlistenTaskDescription(taskId) {
    if (taskId && getAppData()?.account?.settings?.id) {
        return new Promise(resolve => {
            dispatch(unsetTaskDescription());
            db.off('/accountsData/' + getAppData()?.account?.settings?.id + '/tasks/' + taskId + '/description');
            resolve();
        });
    }
}

export function recordEvent(name, data) {
    segmentTrack(name, data);
}

export const cleanUpDependeciesWhenMoving = ({ movedTaskId, destinationId, allTasks }) => {
    const movedTask = allTasks[movedTaskId];
    if(movedTask){
        const destinationTask = allTasks[destinationId];
        const allMovedIds = movedTask.childrens ? getAllChildrensOfTask(movedTaskId, allTasks) : [movedTaskId];
        const parentsIds = destinationTask?.parent
            ? [destinationId, ...getParentsTree({ taskId: destinationId, allTasks })]
            : [destinationId];
        // [{ref: path for update, value: [] }]
        const updateComands = {};
        parentsIds.forEach(taskId => {
            const currentTask = allTasks[taskId];
            if (currentTask?.dependencies && currentTask?.dependencies?.find(el => allMovedIds.includes(el))) {
                const filteredDependecies = currentTask.dependencies?.filter(el => !allMovedIds.includes(el));
                // task new dependecies
                updateComands[`/accounts/${getAppData()?.account?.settings?.id}/tasks/${taskId}/dependencies`] = filteredDependecies;
            }
        });

        allMovedIds.forEach(taskId => {
            const currentTask = allTasks[taskId];
            if (currentTask?.dependencies && currentTask.dependencies?.find(el => parentsIds.includes(el))) {
                const filteredDependecies = currentTask.dependencies?.filter(el => !parentsIds.includes(el));
                updateComands[`/accounts/${getAppData()?.account?.settings?.id}/tasks/${taskId}/dependencies`] = filteredDependecies;
            }
        });

        db.update(undefined, updateComands);
    }
};

export const cleanUpDependeciesOfTask = ({ taskId, allTasks }) => {
    if (allTasks[taskId] && allTasks[taskId].id && getAppData()?.account?.settings?.id) {
        const currentTask = allTasks[taskId];
        const updateComands = {};
        if (currentTask.dependencies) {
            const childIds = currentTask.childrens ? getAllChildrensOfTask(taskId, allTasks) : [taskId];
            const parentsIds = currentTask.parent ? getParentsTree({ taskId, allTasks }) : [taskId];
            const join = [...parentsIds, ...childIds];

            if (currentTask.dependencies.find(el => join.includes(el))) {
                const filteredDependecies = currentTask.dependencies?.filter(el => allTasks[el] && !join.includes(el));
                updateComands[`/accounts/${getAppData()?.account?.settings?.id}/tasks/${taskId}/dependencies`] = filteredDependecies;
            }
        }
        return updateComands;
    }

    return {};
};

const getAllChildrensOfTask = (taskId, allTasks, onlyChilds = false) => {
    const currentTask = allTasks[taskId];
    if (!currentTask) {
        return [];
    }

    if (Array.isArray(currentTask.childrens)) {
        if (onlyChilds) {
            return _.flatten(currentTask.childrens.map(el => getAllChildrensOfTask(el.id, allTasks, onlyChilds)));
        }

        return _.flatten([taskId, ...currentTask.childrens.map(el => getAllChildrensOfTask(el.id, allTasks, onlyChilds))]);
    }

    return [taskId];
};

const getParentsTree = ({ taskId, allTasks }) => {
    if (allTasks[taskId]?.parent) {
        return [allTasks[taskId].parent, ...getParentsTree({ taskId: allTasks[taskId].parent, allTasks })];
    }

    return [];
};

// calendars handlers

export const getAccountsDataConnectCalendars = ({ accountId, userId }, callback) => {
    const ref = db.r(`/accountsData/${accountId}/users/${userId}/connectedAccounts`);
    ref.on('value', snap => callback(snap));
    return ref;
};

export const getAccountsDataConnectCalendarsOff = ({ accountId, userId }) => {
    db.off(`/accountsData/${accountId}/users/${userId}/connectedAccounts`);
};

export const setCalendarSettings = ({ accountId, userId, calendarRef, values }) => {
    // maybe save the
    return db.r(`/accounts/${accountId}/users/${userId}/calendars/${calendarRef}/sharedWith`).set(values);
};

export const setFirstCalendarConnected = (userId) => {
    return db.r(`/users/${userId}/firstCalendarConnected`).set(true);
};


// handle recurring tasks
export const createRecurrentTasks = ({ taskId, currentTask, newTasks }) => {
    const accountId = getAppData()?.account?.settings?.id;
    const userId = getAppData()?.user?.data?.userId;
    const updates = {};
    const newTasksIds = [];

    // maybe save the
    newTasks.forEach((task, idx) => {
        const newTaskKey = db.pushkey('/accounts/' + accountId + '/tasks');
        newTasksIds.push({
            id: newTaskKey,
            type: 'task',
        });

        updates[`/accounts/${accountId}/tasks/${newTaskKey}`] = {
            createdAt: moment().format(),
            createdBy: userId,
            id: newTaskKey,
            index: idx,
            ...(currentTask.maxEffort && { maxEffort: currentTask.maxEffort }),
            ...(currentTask.minEffort && { minEffort: currentTask.minEffort }),
            ...(currentTask.skill && { skill: currentTask.skill }),
            permissions: currentTask.permissions,
            parent: taskId,
            status: 'todo',
            title: task.title,
            //  missing
            delayUntil: moment(parseInt(task.taskDelay)).toISOString(),
            deadline: newTasks[idx + 1]
                ? moment(parseInt(newTasks[idx + 1].taskDelay))
                    .subtract(1, 'day')
                    .toISOString()
                : null,
            isRecurringTask: true,
        };
    }, {});

    updates[`/accounts/${accountId}/tasks/${taskId}/childrens`] = newTasksIds;
    updates[`/accounts/${accountId}/tasks/${taskId}/isRecurringTask`] = true;

    return db.update(undefined, updates);
};

export const updateRecurentTasks = ({ taskId, editField, afterToday = false }) => {
    const accountId = getAppData()?.account?.settings?.id;
    const accountTasks = getAppData()?.account?.tasks;
    const taskEdited = accountTasks[taskId];
    const limitDate = afterToday ? moment() : moment(taskEdited.delayUntil);
    let updates = {};

    if (taskEdited.childrens && editField === 'title') {
        taskEdited.childrens.forEach(({ id }, idx) => {
            updates[`/accounts/${accountId}/tasks/${id}/${editField}`] = `(${idx}) ${taskEdited[editField]}`;
        });
    }

    if (editField !== 'title' && !taskEdited.childrens) {
        const tasksToEdit = accountTasks[taskEdited.parent].childrens.filter(({ id }) => {
            return moment(accountTasks[id].delayUntil).isAfter(limitDate) && accountTasks[id].status === 'todo';
        });

        if (editField === 'maxEffort' || editField === 'minEffort') {
            tasksToEdit.forEach(({ id }) => {
                updates[`/accounts/${accountId}/tasks/${id}/maxEffort`] = taskEdited.maxEffort;
                updates[`/accounts/${accountId}/tasks/${id}/minEffort`] = taskEdited.minEffort;
            });
        } else {
            tasksToEdit.forEach(({ id }) => {
                updates[`/accounts/${accountId}/tasks/${id}/${editField}`] = taskEdited[editField];
            });
        }
    }

    return db.update(undefined, updates);
};
export const getUserPastEvents = ({ userId }) =>
    new Promise(resolve => {
        return db
            .r(`/accountsData/${getAppData()?.account?.settings?.id}/users/${userId}/pastEvents`)
            .once('value', snap => resolve(snap.val()));
    });

export const handleFirstStepsAccount = ({ type }) => {
    const accountId = getAppData()?.account?.settings?.id;
    const firstStepsConfig = getAppData()?.account?.settings?.firstStepsConfig;
    const userId = getAppData()?.user?.data?.userId;
    if (!firstStepsConfig || !firstStepsConfig[type] || (firstStepsConfig[type] && !firstStepsConfig[type].completed)) {
        if (type === 'skills') {
            tracking('Completed Step 2');
        }
        if (type === 'users') {
            tracking('Completed Step 3');
        }
        if (type === 'tasks') {
            tracking('Completed Step 4');
        }

        db.r(`/accounts/${accountId}/settings/firstStepsConfig/${type}`).set({
            completed: true,
            by: userId,
            timestamp: Date.now(),
        });
    }
};
export const handleFirstStepsUserStartWorking = ({ userWorkingOnTask }) => {
    const userData = getAppData()?.user?.data;
    const userFirstStepsConfig = getAppData()?.userFirstStepsConfig;

    if (userWorkingOnTask !== userData.userId) {
        return;
    }

    if (userData && !userFirstStepsConfig?.startWorking?.completed) {
        tracking('Completed Step 5');
        db.r(`/users/${userData.userId}/firstStepsConfig/startWorking`).set({
            completed: true,
            timestamp: Date.now(),
        });
    }
};

export const handleFirstStepsVideos = ({ videoRef, params }) => {
    const userData = getAppData()?.user?.data;

    if (Object.keys(userData?.firstStepsConfig?.videos || {}) === 7) {
        tracking('Completed Step 1');
    }

    if (userData?.userId) {
        db.r(`/users/${userData.userId}/firstStepsConfig/videos/${videoRef}`).update(params);
    }
};

export const getAccountPlan = (accountId) => db.once(`/accounts/${accountId}/settings/plan`);

export const setCompletedPopupGuide = (popupTitle) => {
    const popupGuidesDisabled = getDisablePopupGuides(getState());

    if(popupGuidesDisabled) return;

    const userId = getAppData()?.user?.data?.userId;
    const popupGuides = getPopupGuides();
    const isAlreadyCompleted = !_.isEmpty(popupGuides) && popupGuides[popupTitle];

    if(!isAlreadyCompleted) {
        segmentTrack('Popup guide seen', {
            userId: getAppData()?.user?.data?.userId,
            popupTitle,
            timestamp: Date.now()
        });
        db.set(`/users/${userId}/popupsGuideConfig/${popupTitle}`, true);
    }
};

export const defineNewUserPopupGuides = ({userId, completed = false}) => {
    const popupGuidesDisabled = getDisablePopupGuides(getState());
    if(popupGuidesDisabled) return;

    db.set(`/users/${userId}/popupsGuideConfig`, {
        noEffort: completed,
        noSkill: completed,
        priorityMessage: completed,
        taskMultipleEdit: completed,
        taskRightClick: completed,
        warnMessages: completed,
        enterToAdd: completed
    });
};

export const addCustomDataToWorkspace = () => {
    const accountId = getAppData().account.settings.id;
    const accountUsers = getAppData().account.users;
    const customData = createDefaultWorkspace({
        accountId,
        currentUsers: accountUsers
    });

    db.set(`/accounts/${accountId}/skillGroups`, customData.skillGroups);
    db.set(`/accounts/${accountId}/skills`, customData.skills);
    db.set(`/accounts/${accountId}/tasks`, customData.tasks);
    db.set(`/accounts/${accountId}/users`, customData.users);
    db.set(`/accounts/${accountId}/settings/basePermissions`, customData.basePermissions);
    db.set(`/accountsData/${accountId}/tasks`, customData.accountsData);
};

function getDeletedDependenciesNew(taskId, accountData, accountId, existingUpdates) {
    var deletedDependencies = {},
        updates = {},
        changeDependencies = {},
        changeDependentOn = {};

    if (!existingUpdates) {
        existingUpdates = {};
    }

    if (accountData && accountData.tasks) {
        for (var task in accountData.tasks) {
            if (Object.keys(existingUpdates).indexOf('/accounts/' + accountId + '/tasks/' + task) === -1) {
                if (accountData.tasks[task].dependentOnThis && accountData.tasks[task].dependentOnThis.indexOf(taskId) !== -1) {
                    deletedDependencies['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'] =
                        accountData.tasks[task].dependentOnThis;

                    var newDep = accountData.tasks[task].dependentOnThis;
                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis']) {
                        newDep = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'];
                    }

                    newDep.splice(accountData.tasks[task].dependentOnThis.indexOf(taskId), 1);
                    if (newDep.length === 0) {
                        newDep = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/dependentOnThis'] = newDep;
                    changeDependentOn[task] = newDep;
                }
                if (accountData.tasks[task].dependencies && accountData.tasks[task].dependencies.indexOf(taskId) !== -1) {
                    deletedDependencies['/accounts/' + accountId + '/tasks/' + task + '/dependencies'] =
                        accountData.tasks[task].dependencies;

                    var newDep2 = accountData.tasks[task].dependencies;
                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependencies']) {
                        newDep2 = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/dependencies'];
                    }

                    newDep2.splice(accountData.tasks[task].dependencies.indexOf(taskId), 1);
                    if (newDep2.length === 0) {
                        newDep2 = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/dependencies'] = newDep2;
                    changeDependencies[task] = newDep2;
                }

                if (
                    accountData.tasks[task].childrens &&
                    _.find(accountData.tasks[task].childrens, child => {
                        return child && child.id === taskId && child.type === 'task';
                    })
                ) {
                    var childrensUpdate = accountData.tasks[task].childrens,
                        indexToRemove = null;

                    if (existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/childrens']) {
                        childrensUpdate = existingUpdates['/accounts/' + accountId + '/tasks/' + task + '/childrens'];
                    }

                    _.each(childrensUpdate, (child, index) => {
                        if (child.id === taskId) {
                            indexToRemove = index;
                        }
                    });
                    if (indexToRemove !== null) {
                        childrensUpdate.splice(indexToRemove, 1);
                    }

                    if (childrensUpdate.length === 0) {
                        childrensUpdate = null;
                    }
                    updates['/accounts/' + accountId + '/tasks/' + task + '/childrens'] = childrensUpdate;
                }
            }
        }
    }

    return {
        deletedDependencies,
        updates,
        changeDependencies,
        changeDependentOn,
        accountData,
    };
}

export function deleteTasksUpdates({tasksIds = [], prevAccountData = {}, prevUpdates = {}}) {
    const accountId = getAppData()?.account?.settings?.id;
    let updates = prevUpdates;
    let accountData = !_.isEmpty(prevAccountData) ? prevAccountData : getAppData().account;
    let deletedDep = {};

    tasksIds.forEach(taskId=> {
        const currentTask = accountData.tasks[taskId];
        if(currentTask){

            updates['/accounts/' + accountId + '/tasks/' + taskId] =  null;
            if(updates['/accounts/' + accountId + '/tasks/' + taskId + '/dependencies']){ delete updates['/accounts/' + accountId + '/tasks/' + taskId + '/dependencies']; }
            if(updates['/accounts/' + accountId + '/tasks/' + taskId + '/dependentOnThis']){ delete updates['/accounts/' + accountId + '/tasks/' + taskId + '/dependentOnThis']; }
            if(updates['/accounts/' + accountId + '/tasks/' + taskId + '/childrens']){ delete updates['/accounts/' + accountId + '/tasks/' + taskId + '/childrens']; }
            if(updates['/accounts/' + accountId + '/tasks/' + taskId + '/index']){ delete updates['/accounts/' + accountId + '/tasks/' + taskId + '/index']; }

            const delDep = getDeletedDependenciesNew(taskId, accountData, accountId, updates);
            updates = _.extend(updates, delDep.updates);

            deletedDep = delDep.deletedDependencies;


            const tasksFromParent = getTasksFromParent(currentTask.parent);
            let tIndex = 0;

            tasksFromParent.forEach(t => {
                if (t.index > currentTask.index && t.id !== taskId && accountData.tasks[t.id]) {
                    tIndex = t.index - 1;
                    if(!updates['/accounts/' + accountId + '/tasks/' + t.id] && updates['/accounts/' + accountId + '/tasks/' + t.id] !== null){
                        updates['/accounts/' + accountId + '/tasks/' + t.id + '/index'] = tIndex;
                    }
                }
            });


            if(currentTask.childrens) {
                const childrenIds = currentTask.childrens.map(child=>child.id);
                const childUpdates = deleteTasksUpdates({tasksIds: childrenIds, prevAccountData: accountData, prevUpdates: updates });
                updates = childUpdates.updates;
            }

            // if prevUpdates is not empty, it means we're deleting the previous taskId children
            if(!_.isEmpty(prevUpdates)){
                let newupdates = {};
                for(var r in updates){
                    if (
                        r !== `/accounts/${accountId}/tasks/${taskId}/dependencies` &&
                        r !== `/accounts/${accountId}/tasks/${taskId}/dependentOnThis` &&
                        r !== `/accounts/${accountId}/tasks/${taskId}/childrens` &&
                        r !== `/accounts/${accountId}/tasks/${taskId}/index`
                    ) {
                        newupdates[r] = updates[r];
                    }
                }
                updates = newupdates;
            }
        }
    });

    return {updates, accountData, deletedDep};
}

export const deleteTasks = (tasksIds) => {
    const accountId = getAccountId(getState());
    const stateTasks = getAccountTasks(getState());
    const taskContent = stateTasks[tasksIds[0]];

    if(!taskContent) {return;}

    delete taskContent.canView;
    delete taskContent.canManage;
    delete taskContent.canAdmin;
    delete taskContent.canWork;
    delete taskContent.estimations;
    delete taskContent.closedSortable;
    delete taskContent.path;
    delete taskContent.risks;
    delete taskContent.tasktype;

    const changes = deleteTasksUpdates({tasksIds});

    db.update(undefined, changes.updates);

    addChallenge('deleteTask');
    segmentTrack('Delete task', { accountId, tasksIds });

    if(tasksIds.length > 1) {return;}

    return {
        undo: () => {
            addTask(accountId, taskContent?.parent, null, taskContent?.id, taskContent);
            db.update(undefined, changes.deletedDep);
        }
    };
};

export const setFreemiumRollback = () => {
    const userId = getUserId(getState());
    const accountId = getAccountId(getState());

    db.set(`/accounts/${accountId}/users/${userId}/freemiumRollback`, 'readed');
};


export const saveCustomFieldSetting = ({accountId, id, type, name, choices, deleteTasksData = false}) => {
    if(!id || id === true){
        id = db.pushkey(`/accounts/${accountId}/settings/customFields`);
    }

    if(deleteTasksData){

        let updates = {};
        const list = getAppData()?.account?.tasks;
        Object.keys(list).forEach(taskId => {
            if(list[taskId].customFields){
                Object.keys(list[taskId].customFields).forEach(fieldId => {
                    if(fieldId === id){
                        updates[`/accounts/${accountId}/tasks/${taskId}/customFields/${fieldId}`] = null;
                    }
                });
            }
        });
        db.update(undefined, updates);

    }

    db.set(`/accounts/${accountId}/settings/customFields/${id}`, {name, type, choices});
};

export const saveTaskCustomFieldValue = ({accountId, taskId, fieldId, value}) => {
    db.set(`/accounts/${accountId}/tasks/${taskId}/customFields/${fieldId}`, value);
};