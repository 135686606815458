import { useSelector, useDispatch } from 'react-redux';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import classnames from 'clsx';
import { IntlProvider } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as firebaseEvents from '../../utils/firebaseEvents';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import WorkManager from '../WorkManager/WorkManager';
import ErrorPage from '../ErrorPage/ErrorPage';
import RecurringTasksChangeModal from '../RecurringTasksChangeModal/RecurringTasksChangeModal';
import ChangeTaskLocationModal from '../ChangeTaskLocationModal/ChangeTaskLocationModal';
import SelectDependenciesModal from '../SelectDependenciesModal/SelectDependenciesModal';
import SelectSourceStatusModal from '../SelectSourceStatus/SelectSourceStatus';
import ChatMessagesToastr from '../ChatMessagesToastr/ChatMessagesToastr';
import flattenedInternalization from '../../utils/flattenInternalization';
//import FreemiumRollbackMessage from './FreemiumRollbackMessage';
import '../../utils/printThis';
import { PLANLESS_APP_URL } from '../../utils/constants';
import { tracking } from '../../utils/utils';
import  { sendErrorReport }  from '../../utils/errorReportHandlers';
import { Button } from '@material-ui/core';
import { getActiveUserTime, getApp, getHasConnection, getLoader, getLoaders, getLocale, getParentChildIds, getVersion } from '../../utils/selectors/app';
import { getAccountEndPlanAt, getAccountId, getAccountIsMaxResourcersReached, getAccountIsTrialEnded, getAccountIsTrialExtended, getAccountPlan } from '../../utils/selectors/account';
import { getUser, getUserPermissionsAdmin } from '../../utils/selectors/user';
import { gapiLoad, setActiveUserTime } from '../App/AppActions';

import DefaultModal from '../../components/DefaultModal';
import useModal from '../../utils/hooks/useModal';
import PricingModal from '../PricingModal';
import MaxResourceModal from '../MaxResourceModal';
import TrialEndModal from '../TrialEndModal';
import UpgradeSubscriptionModal from '../UpgradeSubscriptionModal/index';

// init a var to save calculations
window.parentsChilds = {};
window.timelineTaskItems = {};

const App = ({router, routes, children}) => {
    const dispatch = useDispatch();
    const [hasError, setHasError] = useState(false);
    const [showUpdate, setShowUpdate] = useState('no');
    const [allLoadedRan, setAllLoadedRan] = useState(false);
    const [loaderHidden, setLoaderHidden] = useState(false);
    const [gapiLoaded, setGapiLoaded] = useState(false);
    const [alertSubscriptionSent, setAlertSubscriptionSent] = useState(false);
    const [localeInternalization, setLocaleInternalization] = useState({});

    const [isPricingModalShowing, togglePricingModal] = useModal();
    const [isTrialEndModalShowing, toggleTrialEndModal] = useModal();
    const [isMaxResourcersModalShowing, toggleMaxResourcesModal] = useModal();
    const trialEndedAllowedRoutes = ['/settings/subscription', '/settings/team-members', '/tasks'];
    const hubspotAllowedRoutes = ['/login', '/create-account', '/create-workspace'];

    const activeUserTime = useSelector(getActiveUserTime);
    const endPlanAt = useSelector(getAccountEndPlanAt);
    const hasConnection = useSelector(getHasConnection);
    const accountId = useSelector(getAccountId);
    const loader = useSelector(getLoader);
    const loaders = useSelector(getLoaders);
    const locale = useSelector(getLocale);
    const plan = useSelector(getAccountPlan);
    const userData = useSelector(getUser);
    const userAdminPerm = useSelector(getUserPermissionsAdmin);
    const version = useSelector(getVersion);
    const parentChildIds = useSelector(getParentChildIds);
    const isTrialEnded = useSelector(getAccountIsTrialEnded);
    const isMaxResourcesReached = useSelector(getAccountIsMaxResourcersReached);
    const isAccountTrialExtended = useSelector(getAccountIsTrialExtended);

    const fetchInternalization = useCallback(async () => {
        const flattenedLocaleInternalization = (locale) => {
            const localeJSON = require(`../../../locales/${locale}.json`);
            return flattenedInternalization(localeJSON);
        };

        setLocaleInternalization(flattenedLocaleInternalization(locale));
    }, [locale]);

    const redirectIfTrialHasEnded = useCallback(() => {
        if (!isTrialEnded) return;

        const isRouteNotAllowed = !trialEndedAllowedRoutes.some(route => router.location.pathname.includes(route));

        if (isRouteNotAllowed) {
            browserHistory.push('/tasks');
        }
    }, [isTrialEnded]);

    const checkTrial = useCallback(() => {
        const isRouteTask = router.location.pathname === '/tasks';

        if (isTrialEnded && isRouteTask) toggleTrialEndModal();
    }, [isTrialEnded, router.location.pathname]);

    const checkActiveHubspotChat = useCallback(() => {
        const isRouteNotAllowed = !hubspotAllowedRoutes.some(route => router.location.pathname.includes(route));

        if (isRouteNotAllowed) {
            window.HubSpotConversations?.widget?.remove();
            return;
        }

        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.load();
        } else {
            const isHubspotLoaded = window.HubSpotConversations && window.HubSpotConversations.widget && window.HubSpotConversations.widget.status().loaded;
            const isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 767;
            window.hsConversationsOnReady = [
                () => {
                    setTimeout(()=>{
                        if (isHubspotLoaded && isMobile) {
                            window.HubSpotConversations.widget.remove();
                        }
                    }, 1000);
                },
            ];
        }
    }, [window.HubSpotConversations, router.location.pathname]);

    const checkIfMaxResourcesReached = useCallback(() => {
        if(isMaxResourcesReached) toggleMaxResourcesModal();
    }, [isMaxResourcesReached]);

    const onCloseTrialEndModal = useCallback((clickedInSubscribe) => {
        if (isTrialEnded && !clickedInSubscribe) return;
        togglePricingModal();
    }, [isTrialEnded, togglePricingModal]);

    const onCloseMaxResourcesModal = useCallback((clickedInSubscribe) => {
        if (isMaxResourcesReached && !clickedInSubscribe) return;
        toggleMaxResourcesModal();
        browserHistory.push('/settings/subscription');
    }, [isMaxResourcesReached, toggleMaxResourcesModal]);

    const onClosePricingModal = useCallback((isRedirecting) => {
        togglePricingModal();

        if (isRedirecting) {
            toggleTrialEndModal();
        }
    }, [togglePricingModal, toggleTrialEndModal]);

    useEffect(() => {
        checkActiveHubspotChat();
    }, [checkActiveHubspotChat]);

    useEffect(() => {
        checkTrial();
    }, [checkTrial]);

    useEffect(() => {
        fetchInternalization();
    }, [fetchInternalization]);

    useEffect(() => {
        redirectIfTrialHasEnded();
    }, [router.location.pathname, redirectIfTrialHasEnded]);

    useEffect(() => {
        checkIfMaxResourcesReached();
    }, [checkIfMaxResourcesReached]);

    useEffect(() => {
        if (!(userData?.data?.userId && !alertSubscriptionSent)) return;

        const allowedLocations = ['subscription', 'team-members'];
        const userType = (userAdminPerm) ? 'admin':'user';
        const isPlanOver = endPlanAt && !moment(endPlanAt).isSameOrAfter(moment(), 'day');

        if (allLoadedRan && (isPlanOver || isMaxResourcesReached) && !allowedLocations.includes(routes[3]?.path)) {

            if(isPlanOver) {
                setAlertSubscriptionSent(true);
                setTimeout(() => {
                    toastr.error(
                        localeInternalization['subscription.ended.' + plan + '.title'],
                        localeInternalization['subscription.ended.' + userType + '.description'],
                    );
                    setAlertSubscriptionSent(false);
                    browserHistory.push('/settings/subscription');
                }, 100);
                return;
            }

            checkIfMaxResourcesReached();
        }
    }, [routes, allLoadedRan, endPlanAt, plan, alertSubscriptionSent, isMaxResourcesReached, checkIfMaxResourcesReached]);

    if (process.env.NODE_ENV !== 'production' && window) {
        const appState = useSelector(getApp);
        window.$app = appState;
    }

    const theme = useMemo(() => {
        return createTheme({
            palette: {
                primary: {
                    main: '#ff5445',
                    light: '#243758',
                },
                secondary: {
                    main: '#F0562C',
                },
                text: {
                    primary: '#333',
                },
            },
            typography: {
                fontFamily: ['Eina01', 'sans-serif'],
            },
            overrides: {
                MuiButton: {
                    root: {
                        borderRadius: '8px'
                    }
                },
                MuiDialogActions: {
                    root: {
                        padding: '20px'
                    }
                },
            }
        });
    }, []);

    const checkVersion = useCallback(() => {
        $.getJSON(`${PLANLESS_APP_URL}/version.json`, data => {
            if (data.version !== version) {
                var setTo = 'yes';
                if (data.force === 'true') {
                    setTo = 'force';
                }
                setShowUpdate(setTo);
            }
        });
    }, [version]);

    useEffect(()=>{
        window.onload = function () {
            $('#loader').css('opacity', 0);
            $('#loader').css('display', 'none');
        };

        firebaseEvents.defineFirebaseEvents(dispatch);

        if (process.env.NODE_ENV === 'production') {
            setInterval(checkVersion, 10000);
            checkVersion();
        }

        if(window.StonlyWidget){
            window.StonlyWidget('startURLWatcher');
        }

        if (window.Chargebee) {
            window.Chargebee.init({
                site: (process.env.NODE_ENV === 'development') ? 'planless-test' : 'planless',
                enableGTMTracking: true,
                enableFBQTracking: true,
            });
        }
    }, []);

    const updateGapiSigninStatus = (isSignedIn) => {
        dispatch(gapiLoad(isSignedIn));
    };

    const initGapiClient = () => {
        if (window.gapi && window.gapi.client) {

            window.gapi.client
                .init({
                    apiKey: 'AIzaSyDdPojJp6exXh11w60JzPhAqUzfEXk7rYw',
                    clientId: '764279075795-9d55ri1vj5kraf7m84qn4lobpctcl6ep.apps.googleusercontent.com',
                    discoveryDocs: [
                        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
                        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
                    ],
                    scope: 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
                })
                .then(function () {
                    // Listen for sign-in state changes.
                    window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateGapiSigninStatus);

                    updateGapiSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
                });
        }
    };

    useEffect(()=>{
        if (!activeUserTime.isSet && accountId && userData?.data?.uid) {
            dispatch(setActiveUserTime(null));

            window.addEventListener('blur', () => {
                if (accountId && userData?.data?.uid) {
                    tracking(
                        'Active user time',
                        {
                            data: {
                                accountId,
                                userId: userData?.data?.uid,
                                timeSpent: (Date.now() - activeUserTime.timer) / 1000
                            }
                        });
                }
                dispatch(setActiveUserTime(null));
            });
            window.addEventListener('focus', () => {
                dispatch(setActiveUserTime(Date.now()));
            });
        }
    }, [activeUserTime, accountId, userData]);

    useEffect(()=>{
        if (
            allLoadedRan &&
            window.analytics &&
            userData?.data?.uid &&
            (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')
        ) {
            window.analytics.identify(userData.data.uid, { lastseen: moment().format() });

            if(!window.stonlyTrack) return;
            window.stonlyTrack('identify', userData.data.uid, { lastseen: moment().format() });

            if(isAccountTrialExtended) {
                window.stonlyTrack('identify', userData.data.uid, { 'trial-extended': isAccountTrialExtended });
            }
        }
    }, [userData, allLoadedRan, isAccountTrialExtended]);

    useEffect(()=>{

        if (window.gapi && !gapiLoaded) {
            window.gapi.load('client:auth2', initGapiClient);
            setGapiLoaded(true);
        }
    });

    useEffect(()=>{
        if (!allLoadedRan && !loaders.tasks && !loaders.skills && !loaders.users && !loaders.daysoff && !loaders.settings) {
            const redirectAccount = _.get(router, ['location', 'query', 'redirectAccount'], null);

            if(redirectAccount){
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('redirectAccount');

                if(redirectAccount && accountId !== redirectAccount){
                    firebaseEvents.changeActiveAccount(redirectAccount);
                }
                window.location.search = searchParams.toString();
            }

            firebaseEvents.allDataLoadedActions();
            setAllLoadedRan(true);
        }
    }, [accountId, allLoadedRan, loaders, router, parentChildIds]);

    useEffect(()=>{

        if (
            (!loaderHidden && children.type.displayName === 'StructOff') ||
            (!loaderHidden && userData?.data?.userId)
        ) {
            $('#loader').css('opacity', 0);
            $('#loader').css('display', 'none');
            setLoaderHidden(true);
        }

    }, [children, loaderHidden, userData]);

    const getDisconnected = useCallback(() => {
        if (!hasConnection) {
            return (
                <div className="disconnected">
                    <strong>You are disconnected!</strong>
                    You can continue to work but your changes would only be synced when you go back online.
                </div>
            );
        }
        return null;
    }, [hasConnection]);

    const getLoaderDiv = useCallback(() => {
        return (
            <div className={classnames('loader', { show: loader })}>
                <img src="../../../stylesheets/assets/planless-big-loader.gif" alt="Loader icon" />
            </div>
        );
    }, [loader]);

    const getVersionChange = useCallback(() => {
        return (showUpdate !== 'no') ? (
            <div className={classnames('versionUpdate', { force: (showUpdate === 'force') })}>
                <img src="../../../stylesheets/assets/logo.svg?v=2" className="logo" alt="logo" />
                <p>A new version of Planless is available.</p>
                <Button size="small" variant="outlined" onClick={()=>{ document.location.reload(); }}>Get new version</Button>
            </div>
        ) : null;
    }, [showUpdate]);

    try {
        if (userData === undefined) {
            return null;
        }

        if(hasError){
            return <IntlProvider locale={locale} messages={localeInternalization}>
                <ErrorPage />
            </IntlProvider>;
        }

        return (
            <IntlProvider locale={locale} messages={localeInternalization}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div
                            className={classnames('App', { active: true })}
                        >
                            <DefaultModal />
                            <MaxResourceModal isOpen={isMaxResourcersModalShowing} onClose={onCloseMaxResourcesModal} />
                            <PricingModal isOpen={isPricingModalShowing} onClose={onClosePricingModal}/>
                            <TrialEndModal isOpen={isTrialEndModalShowing} onClose={onCloseTrialEndModal}/>
                            <UpgradeSubscriptionModal/>
                            {/* <FreemiumRollbackMessage /> */}
                            <div
                                className={classnames('AppContent')}
                                style={{ opacity: 0 }}
                            >
                                {children}
                                <WorkManager />
                                {getDisconnected()}
                                <SelectSourceStatusModal/>
                                <RecurringTasksChangeModal />
                                <ChangeTaskLocationModal />
                                <SelectDependenciesModal />
                            </div>
                            {getLoaderDiv()}
                            {getVersionChange()}
                            <ChatMessagesToastr />
                        </div>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </IntlProvider>
        );
    } catch(errorInfo) {
        sendErrorReport(
            {
                accountId,
                currentUserId: userData?.data?.userId,
                localStorageData: JSON.stringify(localStorage.valueOf()),
                errorInfo: JSON.stringify({ errorInfo }),
                currentPath: window.location.href
            }
        );
        setHasError(true);
    }
};

export default memo(withRouter(App));