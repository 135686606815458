import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import { getSkillsDescription } from '../../utils/selectors/account';
import SelectSkill from '../SelectSkill/SelectSkill';

const MultiSkillDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [selectedSkillIds, setSelectedSkillIds] = useState([]);
    const [isShowing, setIsShowing] = useState(false);

    const selectedSkillsDescription = useSelector(getSkillsDescription(selectedSkillIds));

    const onApply = () => {
        firebaseEvents.multiSkillChange(selectedSkillIds, selectedItems).then(res => {
            if (res !== 'error') {
                setSelectedLines();
                showMultiDetails();
                setSelectedSkillIds([]);
            }
        });
    };

    const onCancel = () => {
        showMultiDetails();
    };

    const toggleIsShowing = () => setIsShowing(prevState => !prevState);

    const onSelectSkills = skillIds => {
        setSelectedSkillIds(skillIds);
        toggleIsShowing();
    };

    return <div id="multiSkillDetails">
        <label>Set tasks skill to</label>
        <div className="skillSelect">
            <div>
                <span onClick={toggleIsShowing}>
                    {selectedSkillIds.length ? selectedSkillsDescription : 'Select a skill'}
                </span>
            </div>
            <div className="items" id="multiSkillDetailsSelect" >
                {isShowing && <SelectSkill
                    isMultiSelect
                    currentSkill={selectedSkillIds}
                    onSelect={onSelectSkills}
                    onClose={toggleIsShowing}
                    open={isShowing}
                />}
            </div>
        </div>
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={onApply}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={onCancel}>
            Cancel
        </Button>
    </div>;
};

export default MultiSkillDetails;