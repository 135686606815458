import { ADD_TASK_LIST_LEVEL } from '../utils';

export const getAsanaInfo = ({ task, parentsCount, isInsideSyncFolder, importSubtasks }) => {
    const isTaskLastLevel = importSubtasks ? parentsCount === 9 : parentsCount === 5;

    const canAddTask = () => {
        const isEligibleSourceType = ['section', 'task', 'subtask'].includes(task?.sourceType?.name);

        if(isTaskLastLevel) return false;

        return isEligibleSourceType || (!task?.sourceType && isInsideSyncFolder);
    };

    const getAddTaskListLevel = () => {
        if(!importSubtasks || parentsCount === 8) return ADD_TASK_LIST_LEVEL.ONE;

        if(parentsCount === 7) return ADD_TASK_LIST_LEVEL.TWO;

        return ADD_TASK_LIST_LEVEL.THREE;
    };

    return {
        canAddSyncAppTask: canAddTask(),
        hasDependencies: true,
        hasSourceTags: true,
        hasSourceStatus: true,
        addTaskListLevel: getAddTaskListLevel(),
    };
};