import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useIntl} from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import OpenIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'clsx';
import moment from 'moment-timezone';
import _ from 'underscore';
import {  browserHistory } from 'react-router';
import Loader from '../../components/Loader';
import { getLoadersSkills, getLoadersTasks, getLoadersUsers } from '../../utils/selectors/app';
import { getUserId, getUserTimezone } from '../../utils/selectors/user';
import { getAccountTasks } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const NextDeadlines = ({masonry})=>{
    const intl = useIntl();

    const loadersTasks = useSelector(getLoadersTasks);
    const loadersUsers = useSelector(getLoadersUsers);
    const loadersSkills = useSelector(getLoadersSkills);
    const userUserId = useSelector(getUserId);
    const tasks = useSelector(getAccountTasks);
    const userTimezone = useSelector(getUserTimezone);

    const getShowAllDuedates = () => {
        return (localStorage.getItem('showAllDuedates') && localStorage.getItem('showAllDuedates') === 'true');
    };

    const [showAllDuedates, setShowAllDuedates] = useState(getShowAllDuedates());

    useEffect(()=>{
        if(masonry.current){
            masonry.current.masonry.reloadItems();
            masonry.current.masonry.layout();
        }
    });

    
    const doShowAllDuedates = () => {
        try {
            localStorage.setItem('showAllDuedates', false); 
            setShowAllDuedates(false);
        } catch (error) {
            return null;
        }
    };

    const getTaskPath = (taskId) => {

        if(tasks && tasks[taskId] && tasks[taskId].path){
            return tasks[taskId].path.join(' / ');
        }
        return null;
    };

    const getNextDeadlines = () => {
        if(loadersTasks || loadersUsers || loadersSkills) {
            return (
                <CardContent>
                    <Loader fullScreen={false} show={true} />
                </CardContent>
            );
        }
        
        var duedates = [];
        var dueDatesContent = (
            <div className="allRead">
                <img src="../../../stylesheets/assets/noduedate.png?v=2" alt="No due date" />
                <span>{intl.formatMessage({id:'dashboard.no-duedate'})}</span>
            </div>
        );
        if(userUserId && tasks){
            for(var taskId in tasks){
                tasks[taskId].id = taskId;
                let currentTask = tasks[taskId];
                if(currentTask.status && currentTask.status !== 'done' && currentTask.deadline && currentTask.canView){
                    if(
                        !showAllDuedates || 
                        (currentTask.estimations && currentTask.estimations.userId && ((typeof currentTask.estimations.userId === 'string' && currentTask.estimations.userId === userUserId) || currentTask.estimations.userId.indexOf(userUserId) !== -1)) ||
                        (currentTask.status === 'inprogress' && currentTask?.userWorking?.indexOf(userUserId) !== -1)
                    ){
                        duedates.push(currentTask);
                    }

                }
            }
            if(duedates.length){
                let hasMoreThan5 = (duedates.length > 5)?<div className="moreThan5">{intl.formatMessage({id:'dashboard.more-than-5-duedates'})}</div>:null;

                duedates = _.sortBy(duedates, 'deadline').slice(0,5);

                let tempContent = [];
                duedates.forEach((task)=>{
                    let deadline = moment.tz(task.deadline, userTimezone).hour(23).minute(59).fromNow();
                    let dueDateClass = '';
                    if(task.risks?.taskAtDanger){ dueDateClass = 'danger'; }
                    if(task.risks?.taskAtRisk){ dueDateClass = 'warning'; }
                    if(moment.tz(task.deadline, userTimezone).format('YYYY-MM-DD') === moment.tz(userTimezone).format('YYYY-MM-DD')){
                        deadline = 'today';
                    }
                    
                    if(moment.tz(task.deadline, userTimezone).format('YYYY-MM-DD') === moment.tz(userTimezone).add(1,'days').format('YYYY-MM-DD')){
                        deadline = 'tomorrow';
                    }

                    tempContent.push(
                        <Card className="dueTask" key={task.id} variant="outlined" onClick={() => browserHistory.push('/dashboard/' + task.id)}>
                            <CardContent>
                                <div className={dueDateClass}>{deadline}</div>
                                <div>
                                    {getTaskPath(task.id) && <h2 className='path'>{getTaskPath(task.id)}</h2>}
                                    <h1>{task.title}</h1>
                                </div>
                                <Tooltip title="See task details">
                                    <IconButton className="openBtn" onClick={() => browserHistory.push('/dashboard/' + task.id)}>
                                        <OpenIcon />
                                    </IconButton>
                                </Tooltip>
                            </CardContent>
                        </Card>
                    );
                });

                dueDatesContent = (
                    <div>
                        {tempContent}
                        {hasMoreThan5}
                    </div>
                );
            }
        } 

        return dueDatesContent;
    };

    const doShowAssignedToMe = () => {
        try {
            localStorage.setItem('showAllDuedates', true); 
            setShowAllDuedates(true);
        } catch (error) {
            return null;
        }
    };


    return <Card className="nextDeadlines" square={true}>
        <CardHeader title="Next Due Dates" action={
            <div>
                <a onClick={doShowAllDuedates} className={classnames('toggleOption', {active: !showAllDuedates})}>{intl.formatMessage({id:'ALL'})}</a>
                <a onClick={doShowAssignedToMe} className={classnames('toggleOption', {active: showAllDuedates})}>{intl.formatMessage({id:'ASSIGNED TO ME'})}</a>
            </div>
        }  />
        <CardContent>
            {getNextDeadlines()}
        </CardContent>
    </Card>;
};

export default withCustomErrorBoundary(NextDeadlines);

