var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { getState } from '../../../../configureStore';
import { db } from '../../../firebaseEvents';
import { getAccountId } from '../../../selectors/account';
import segmentIdentify from '../../../analytics/segmentIdentify';
import { NB_CONNECTED_PROJECTS_BY_TOOL, NB_USERS_MATCHED_BY_TOOL } from '../../../../views/Integrations/components/ConnectedProjects/constants';
export var setSyncAppCounters = function (_a) {
    var syncAppType = _a.syncAppType, counterType = _a.counterType, value = _a.value;
    return __awaiter(void 0, void 0, void 0, function () {
        var accountId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    accountId = getAccountId(getState());
                    return [4 /*yield*/, db.set("/accounts/".concat(accountId, "/settings/syncAppsCounters/").concat(syncAppType, "/").concat(counterType), value)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var updateSyncAppCounterNbProjects = function (_a) {
    var syncAppType = _a.syncAppType, nbConnectedProjects = _a.nbConnectedProjects;
    return __awaiter(void 0, void 0, void 0, function () {
        var propertyName, trackData;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    propertyName = NB_CONNECTED_PROJECTS_BY_TOOL[syncAppType];
                    trackData = (_b = {}, _b[propertyName] = nbConnectedProjects, _b);
                    segmentIdentify(trackData);
                    return [4 /*yield*/, setSyncAppCounters({ syncAppType: syncAppType, counterType: propertyName, value: nbConnectedProjects })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var updateSyncAppCounterNbUsersMatch = function (_a) {
    var syncAppType = _a.syncAppType, nbUsersMatched = _a.nbUsersMatched;
    return __awaiter(void 0, void 0, void 0, function () {
        var propertyName, trackData;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    propertyName = NB_USERS_MATCHED_BY_TOOL[syncAppType];
                    trackData = (_b = {}, _b[propertyName] = nbUsersMatched, _b);
                    segmentIdentify(trackData);
                    return [4 /*yield*/, setSyncAppCounters({ syncAppType: syncAppType, counterType: propertyName, value: nbUsersMatched })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
};
