import { Checkbox, Chip, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';
import { saveTaskCustomFieldValue } from '../../utils/firebaseEvents';
import useInternalization from '../../utils/hooks/useInternalization';
import { getAccountId, getAccountTasksCustomField, getAccountUsers } from '../../utils/selectors/account';
import { getUserDateformat } from '../../utils/selectors/user';
import RemoveIcon from '@material-ui/icons/Cancel';
import _ from 'underscore';
import moment from 'moment';
import { tracking } from '../../utils/utils';

const TaskCustomFieldLine = ({ fieldId, field, taskId }) => {
    const { t } = useInternalization();
    const userDateFormat = useSelector(getUserDateformat);
    const accountId = useSelector(getAccountId);
    const [value, setValue] = useState(null);
    const users = useSelector(getAccountUsers);
    const data = useSelector(getAccountTasksCustomField(taskId, fieldId));

    useEffect(() => {
        setValue(data);
    },[taskId, data]);


    const save = (val) => {
        saveTaskCustomFieldValue({
            accountId, taskId, fieldId, value: val
        });
        tracking('Set custom field value', {accountId, taskId});
    };

    const changePeople = (e, newUsers, reason) => {
        newUsers = _.filter(newUsers, (userId) => userId);
        
        if(reason === 'clear') {
            newUsers = [];
        }

        if(reason === 'remove-option') {
            newUsers = _.uniq([...newUsers]);
        }

        save(newUsers);
        setValue(newUsers);
    };

    const usersOptions = Object.values(users).reduce((acc, user) => {
        acc.push({ id: user.userId, name: user.displayName });
        return acc;
    }, [])
        .sort((a, b) => a.name > b.name ? 1 : - 1)
        .map((user) => user.id);

    return <li className="TaskCustomFieldLine">
        <dl>
            <dt>{field.name}</dt>
            <dt>
                {field.type === 'text' && <TextField value={value || ''} fullWidth onChange={e=>setValue(e.target.value)} onBlur={(e)=>{ save(e.target.value); }} />}
                {field.type === 'number' && <TextField type="number" value={value || ''} onChange={e=>setValue(e.target.value)} onBlur={(e)=>{ save(e.target.value); }} />}
                {field.type === 'checkbox' && <Checkbox style={{marginLeft:'-5px', padding:'5px'}} size="small" checked={Boolean(value)} onChange={e=>{ setValue(e.target.checked); save(e.target.checked); }} />}
                {field.type === 'date' && <>
                    <DatePicker className="customDateField" value={value ? moment(value, 'YYYY-MM-DD'):null} format={userDateFormat} emptyLabel={t('None')} autoOk={true} cancelLabel={null} okLabel={null} onChange={date=>{ setValue(date); save(date ? date.format('YYYY-MM-DD'):null); }} />
                    {Boolean(value) && <RemoveIcon className="RemoveDate" onClick={()=>{ setValue(null); save(null); }} />}
                </>}
                {field.type === 'list' && <Select fullWidth value={value || '-'} defaultValue="-" onChange={e=>{ setValue(e.target.value !== '-' ? e.target.value : null); save(e.target.value !== '-' ? e.target.value : null); }}>
                    <MenuItem className='TaskCustomFieldLine-MenuItem' value="-"> {t('None')} </MenuItem>
                    {field.choices.map(item=><MenuItem className='TaskCustomFieldLine-MenuItem' value={item}>{item}</MenuItem>)}
                </Select>}
                {field.type === 'people' && <Autocomplete
                    multiple
                    filterSelectedOptions={true}
                    options={usersOptions}
                    value={value || []}
                    getOptionLabel={(option) => {
                        if(users[option]) {
                            return (
                                <div 
                                    className="optionUser"
                                >
                                    <StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>
                                    <Typography variant='subtitle2'>
                                        {(users[option]) ? users[option].displayName : ''}
                                    </Typography>
                                </div>
                            );
                        }
                    }}
                    renderInput={params => (
                        <TextField {...params} variant="outlined" fullWidth />
                    )}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => {
                            var props = getTagProps({ index });
                            props.className = 'workloadChip';
                            if (users[option] && !users[option].viewer) {
                                return (
                                    <Chip
                                        size='small'
                                        avatar={<StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>}
                                        variant="outlined"
                                        label={users[option] && users[option]?.displayName ? users[option].displayName : ''}
                                        {...props}
                                    />
                                );
                            }
                        });
                    }
                    }
                    filterOptions={(options, { inputValue }) => {
                        const results = options.reduce((acc, option) => {
                            if (users[option]?.displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1) {
                                acc.push(option);
                            }
                            return acc;
                        }, []);
                        return results;
                    }}
                    onChange={changePeople}
                />}
            </dt>
        </dl>
    </li>; 
};

export default TaskCustomFieldLine;