import _ from 'underscore';
import { getTaskExpenses } from './controllers/tasks/expenses';

const getUser = (userId, users) => {
    return {
        userId: userId,
        skills: _.get(users[userId],['skills'], {}),
        cost: {
            internalCost: parseFloat(_.get(users[userId],['cost','internalCost','value'], 0)),
            billableCost: parseFloat(_.get(users[userId],['cost','billableCost','value'], 0)),
        }
    };
};
// will become a promisse
export const getPrevisionValue = {
    now: {
        internal: async (task, allTasks, accountUsers, checkWorktimeEntry = null, accountDataTasks)  => {
            const tasksExpenses = await getExpensesValue(task, allTasks, accountDataTasks);
            const tasksWorkTimeValue = getTaskWorkValue({task, allTasks, accountUsers, costType: 'internalCost', checkWorktimeEntry});
            return tasksWorkTimeValue + tasksExpenses;
        },
        billable: async (task, allTasks, accountUsers, checkWorktimeEntry = null, accountDataTasks)  => {
            const tasksExpenses = await getExpensesValue(task, allTasks, accountDataTasks);
            const tasksWorkTimeValue = getTaskWorkValue({task, allTasks, accountUsers, costType: 'billableCost', checkWorktimeEntry});
            return tasksWorkTimeValue + tasksExpenses;
        },
    },
    future: {
        internal: async (task, allTasks, accountUsers , accountSkills, checkEntry =  null)  => {
            const tasksExpenses = await getExpensesValue(task, allTasks);
            const tasksWorkTimeValue = getTaskWorkValueFuture({task, allTasks, accountUsers , accountSkills, costType: 'internalCost', checkEntry});
            return tasksWorkTimeValue + tasksExpenses;
        },
        billable: async (task, allTasks, accountUsers , accountSkills, checkEntry =  null)  => {
            const tasksExpenses = await getExpensesValue(task, allTasks);
            const tasksWorkTimeValue = getTaskWorkValueFuture({task, allTasks, accountUsers , accountSkills, costType: 'billableCost', checkEntry});
            return tasksWorkTimeValue + tasksExpenses;
        },
    },
};

// will become a promisse
export const getExpensesValue = async (task, allTasks, accountsDataTasks = null) => {
    if(!task || !task.childrens) {
        return 0;
    }
    //
    const taskChilds = getChildrensOfTask(task.id, allTasks, false);
    // get task expenses
    const expenses = accountsDataTasks ? taskChilds.map(taskId => _.get(accountsDataTasks, [taskId, 'expenses'],  [])).flat() : (await Promise.all(taskChilds.map(taskId => getTaskExpenses(taskId)))).flat();

    const currentTasksExpenses = expenses ? expenses.reduce((acc, expense) => acc + parseInt(expense.total) || 0, 0) : 0;

    return currentTasksExpenses;
};

// will become a promisse
export const getExpensesValueNoAsync =  (task, allTasks, accountsDataTasks = null) => {
    if(!task || !task.childrens) {
        return 0;
    }
    //
    const taskChilds = getChildrensOfTask(task.id, allTasks, false);
    // get task expenses
    const expenses = taskChilds.map(taskId => _.get(accountsDataTasks, [taskId, 'expenses'],  [])).flat();

    const currentTasksExpenses = expenses ? expenses.reduce((acc, expense) => acc + parseInt(expense.total) || 0, 0) : 0;

    return currentTasksExpenses;
};


export const getTaskWorkValue = ({task, allTasks, checkWorktimeEntry, accountUsers, costType = 'internalCost'}) => {
    if(!task || !task.childrens && (!task.workingTime || !Object.values(task.workingTime).length)) {
        return 0;
    }

    if(task.childrens) {
        const childTasksValue = task.childrens.reduce((acc, { id }) => {
            if(allTasks[id]){
                return acc + getTaskWorkValue({task: allTasks[id], allTasks, accountUsers,  costType, checkWorktimeEntry });
            }
            return acc;
        }, 0);
        return childTasksValue;
    }

    const currentTasksWorkTimeValue = task.workingTime ? Object.values(task.workingTime).reduce((acc, workTimeEntry) => {
        if(checkWorktimeEntry && !checkWorktimeEntry(workTimeEntry)) {
            return acc;
        }

        const user = getUser(workTimeEntry.member, accountUsers);
        const value = parseFloat(workTimeEntry.hours);
        if(!value){
            return acc;
        }

        return acc + value * user.cost[costType];
    }, 0) : 0;


    return currentTasksWorkTimeValue;
};

export const getTaskWorkValueSingle = ({task, checkWorktimeEntry, accountUsers, costType = 'internalCost'}) => {
    if(!task) {
        return 0;
    }
    if(!task.childrens) {
        return 0;
    }

    const currentTasksWorkTimeValue = task.workingTime ? Object.values(task.workingTime).reduce((acc, workTimeEntry) => {
        if(checkWorktimeEntry && !checkWorktimeEntry(workTimeEntry)) {
            return acc;
        }

        const user = getUser(workTimeEntry.member, accountUsers);
        const value = parseFloat(workTimeEntry.hours);
        if(!value){
            return acc;
        }

        return acc + value * user.cost[costType];
    }, 0) : 0;


    return currentTasksWorkTimeValue;
};

const calculateSkillMultiplicator = ({ skillLevel, factor }) => {
    return skillLevel < 5 ? factor - (((factor-1)/4)*(skillLevel-1)) : 1 - (((1 - (1/factor))/5)*(skillLevel-5));
};

export const getTaskWorkValueFuture = ({task, allTasks, accountUsers, accountSkills, costType = 'internalCost', checkEntry}) => {
    const taskSkills = task.skill || [];
    let estimatedUser = _.get(task, ['estimations', 'userId'], []);

    if(typeof estimatedUser === 'string'){
        estimatedUser = [estimatedUser];
    }
    const maxEffort = parseInt(_.get(task, 'maxEffort',0)) * estimatedUser.length;
    const minEffort = parseInt(_.get(task, 'minEffort',0)) * estimatedUser.length;
    const averageEffort  = (maxEffort + minEffort) / 2;

    if(!task.childrens && (!taskSkills?.length || !estimatedUser.length || !maxEffort || !minEffort )){
        return 0;
    }

    if(!task.childrens){
        // by date
        if(checkEntry && !checkEntry(task)) {
            return 0;
        }

        const userEstimationValue = estimatedUser.reduce((acc, userId) => {
            const user = getUser(userId, accountUsers);

            const userTaskSkills = user.skills ? taskSkills.filter(skillId => user.skills[skillId]).map(skillId => ({
                skillLevel: user.skills[skillId],
                factor: accountSkills[skillId].factor,
            })) : [];

            if(!userTaskSkills.length){
                return acc;
            }

            const skillMultiplicator = userTaskSkills.reduce(
                (acc, { skillLevel, factor }) => acc + calculateSkillMultiplicator({ skillLevel, factor }),
                0
            ) / userTaskSkills.length;
            const value = user.cost[costType] * (averageEffort * skillMultiplicator);
            return acc + value;
        }, 0);

        return userEstimationValue;
    }

    const childTasksValue = task.childrens.reduce((acc, { id }) => {
        if(allTasks[id]){
            return acc + getTaskWorkValueFuture({task: allTasks[id], allTasks, accountUsers, accountSkills, costType, checkEntry });
        }
        return acc;
    }, 0);

    return childTasksValue;
};



export const getTaskValueSplitted = ({taskId, allTasks, accountUsers}) => {
    const allOfThisTaskChilds = getChildrensOfTask(taskId,allTasks, true);
    const usersSplit = {};
    const skillSplit = {};
    let totalInternalTaskValue = 0;
    let totalBillableTaskValue = 0;

    allOfThisTaskChilds.forEach(tsId => {
        const currentTask = allTasks[tsId];
        let totalInternalCostWorkedTime = 0;
        let totalBillableCostWorkedTime = 0;
        if (currentTask.workingTime) {
            Object.values(currentTask.workingTime).forEach((workTimeEntry) => {
                const user = getUser(workTimeEntry.member, accountUsers);
                const value = parseFloat(workTimeEntry.hours);
                const internalCost = value * user.cost['internalCost'];
                const billableCost = value * user.cost['billableCost'];
                totalInternalCostWorkedTime += internalCost;
                totalBillableCostWorkedTime += billableCost;
                if(usersSplit[workTimeEntry.member]){
                    usersSplit[workTimeEntry.member]['internalCost'] += internalCost;
                    usersSplit[workTimeEntry.member]['billableCost'] += billableCost;
                } else {
                    usersSplit[workTimeEntry.member] = {
                        internalCost: internalCost,
                        billableCost: billableCost,
                    };
                }
            });

            if(currentTask?.skill?.length) {
                currentTask?.skill?.forEach(skillId => {
                    if(skillSplit[skillId]){
                        skillSplit[skillId]['internalCost'] += totalInternalCostWorkedTime;
                        skillSplit[skillId]['billableCost'] += totalBillableCostWorkedTime;
                    } else {
                        skillSplit[skillId] = {
                            internalCost: totalInternalCostWorkedTime,
                            billableCost: totalBillableCostWorkedTime,
                        };
                    }
                });
            }
        }
        totalInternalTaskValue += totalInternalCostWorkedTime;
        totalBillableTaskValue += totalBillableCostWorkedTime;
    });

    return {
        totalInternalTaskValue: totalInternalTaskValue,
        totalBillableTaskValue: totalBillableTaskValue,
        bySkills: skillSplit,
        byUsers: usersSplit,
    };
};

const getChildrensOfTask = (taskId, allTasks, onlyChilds = false) => {
    const currentTask = allTasks[taskId];
    if(!currentTask) {
        return [];
    }

    if(currentTask.childrens){
        if(onlyChilds){
            return currentTask.childrens.map(el => getChildrensOfTask(el.id, allTasks, onlyChilds)).flat();
        }

        return [taskId, ...currentTask.childrens.map(el => getChildrensOfTask(el.id, allTasks, onlyChilds))].flat();
    }

    return [taskId];
};

const fieldNamePeriodicityParser  =  {
    none: {
        totalInternalCost: 'totalInternalCost',
        totalBillableCost: 'totalBillableCost',
        totalInternalCostByUser: 'totalInternalCostByUser',
        totalBillableCostByUser: 'totalBillableCostByUser',
        totalInternalCostBySkill: 'totalInternalCostBySkill',
        totalBillableCostBySkill: 'totalBillableCostBySkill',
    },
    year: {
        totalInternalCost: 'totalInternalCostYear',
        totalBillableCost: 'totalBillableCostYear',
        totalInternalCostByUser: 'totalInternalCostByUserYear',
        totalBillableCostByUser: 'totalBillableCostByUserYear',
        totalInternalCostBySkill: 'totalInternalCostBySkillYear',
        totalBillableCostBySkill: 'totalBillableCostBySkillYear',
    },
    month: {
        totalInternalCost: 'totalInternalCostMonth',
        totalBillableCost: 'totalBillableCostMonth',
        totalInternalCostByUser: 'totalInternalCostByUserMonth',
        totalBillableCostByUser: 'totalBillableCostByUserMonth',
        totalInternalCostBySkill: 'totalInternalCostBySkillMonth',
        totalBillableCostBySkill: 'totalBillableCostBySkillMonth',
    },
    week: {
        totalInternalCost: 'totalInternalCostWeek',
        totalBillableCost: 'totalBillableCostWeek',
        totalInternalCostByUser: 'totalInternalCostByUserWeek',
        totalBillableCostByUser: 'totalBillableCostByUserWeek',
        totalInternalCostBySkill: 'totalInternalCostBySkillWeek',
        totalBillableCostBySkill: 'totalBillableCostBySkillWeek',
    },
};

export const getFieldNamesForPeriodicity = (periodicity) => fieldNamePeriodicityParser[periodicity] ? fieldNamePeriodicityParser[periodicity] : fieldNamePeriodicityParser.none;