import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, browserHistory } from 'react-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import {toastr} from 'react-redux-toastr';
import SocialSignup from '../SocialSignup/SocialSignup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { getUserId } from '../../utils/selectors/user';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import Paper from '@material-ui/core/Paper';

const Login = (props) => {
    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userId = useSelector(getUserId);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if(userId) {
            if(props?.routing?.locationBeforeTransitions?.query?.redirect){
                browserHistory.push(props.routing.locationBeforeTransitions.query.redirect);
            }
            else {
                browserHistory.push('/');
            }
        }
    }, [userId, password]);

    const t = (id, values) => {
        return intl.formatMessage({id}, values);
    };

    const createAccountWithEmailAndPassword = () => {
        firebase.auth().createUserWithEmailAndPassword(email,password)
            .catch((error) => {
                if(error.code === 'auth/email-already-in-use'){
                    firebase.auth().fetchSignInMethodsForEmail(email).then((result)=>{
                        toastr.error(t('error.title'), t('auth/account-exists-with-different-credential/' + result.toString()), '', {
                            component: (
                                <a
                                    href="#"
                                    className="CreateAccountEmailExistErrorButton"
                                    onClick={e => {
                                        e.preventDefault();
                                        browserHistory.push('/login');
                                        toastr.removeByType('error');
                                    }}
                                >
                                    {t('Go to login')}
                                </a>
                            ),
                        });
                    });
                }
                else {
                    toastr.error(t('error.title'), t(error.code));
                }
            });
    };

    const signInWithEmailAndPassword = (e) => {
        e.preventDefault();

        firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
            if(error.code === 'auth/user-not-found'){
                toastr.error(t('error.title'), t(error.code), {
                    component: (
                        <a
                            href="#"
                            className="LoginEmailNotExistErrorButton"
                            onClick={e => {
                                e.preventDefault();
                                createAccountWithEmailAndPassword();
                                toastr.removeByType('error');
                            }}
                        >
                            {t('Sign up with this email/password')}
                        </a>
                    ),
                });
            }
            else {
                toastr.error(t('error.title'), t(error.code));
            }
        });
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <Paper square={true} elevation={9} className="delay-1s login-container paper-container">
            <div className='Login animated flipInY'>
                <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
                <form onSubmit={signInWithEmailAndPassword}>
                    <TextField
                        margin="normal"
                        fullWidth={true}
                        type="email"
                        label={t('Email')}
                        onChange={onChangeEmail}
                    />
                    <FormControl>
                        <InputLabel htmlFor="passwordField">{t('Password')}</InputLabel>
                        <Input
                            fullWidth={true}
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={onChangePassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div className='Login__animated__signIn'>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            {t('Sign in')}
                        </Button>

                        <p><Link to="/login/reset-password">{t('Forgot your password?')}</Link></p>
                    </div>
                </form>

                <div className="divider"><span>{t('Or')}</span></div>

                <SocialSignup />

                <div className='divider__empty'></div>

                <div className='Login__bottom'>
                    <p>{t('Don\'t have an account?')} <Link to="/create-account">{t('Sign up here')}</Link></p>
                </div>
                {props.children}
            </div>
        </Paper>
    );
};

export default withCustomErrorBoundary(Login);