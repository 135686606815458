import React from 'react';
import { withRouter } from 'react-router';
import Filters from '../Filters/Filters';

import PresetSelector from './PresetSelector';
import QuickSearch from './QuickSearch';
import CalendarMembersSelect from './CalendarMembersSelect';
import ViewModesSwitch from './ViewModesSwitch';
import CurrentProjectBar from './CurrentProjectBar';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import SectionTitle from '../SectionTitle/SectionTitle';
import { CalendarToday, Speed } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const TasksActionBar = ({routes}) => {
    const intl = useIntl();
    const currentRoute = routes[3].path;

    return (
        <>
            <div className="StructTasksHeader">

                <div>
                    {currentRoute !== 'calendar' && currentRoute !== 'workload' && <>
                        <CurrentProjectBar />
                        <ViewModesSwitch />
                    </>}
                    {currentRoute === 'calendar' && <SectionTitle title={intl.formatMessage({id:'Calendar'})} icon={<CalendarToday />} />}
                    {currentRoute === 'workload' && <SectionTitle title={intl.formatMessage({id:'Workload'})} icon={<Speed />} />}
                </div>
                <div>
                    {(currentRoute === 'calendar')?<CalendarMembersSelect />:null}
                    {currentRoute !== 'calendar' && currentRoute !== 'workload' &&
                        <div data-sidebar='preventClose'>
                            <PresetSelector />
                            <QuickSearch />
                        </div>
                    }
                </div>              

            </div>

            {(currentRoute !== 'calendar' && currentRoute !== 'workload') ? (
                <>
                    <Filters/>
                </>
            ):null}

        </>
    );

};

export default withRouter(withCustomErrorBoundary(TasksActionBar));