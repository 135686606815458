import React from 'react';
import Proptypes from 'prop-types';
import TaskOverview from './TaskOverview';
import EffortOverview from './EffortOverview';
import DateDriftOverview from './DateDriftOverview';
import DaysOffOverview from './DaysOffOverview';
import TimeWorkedOverview from './TimeWorkedOverview';
import UpcomingTasksOverview from './UpcomingTasksOverview';
import RecentlyDoneTasksOverview from './RecentlyDoneTasksOverview';
import UpcomingEventsOverview from './UpcomingEventsOverview';
import RiskOverview from './RiskOverview';
import InternalCostBreakdown from './InternalCostBreakdown';
import BillableCostBreakdown from './BillableCostBreakdown';
import RecentsEventsOverview from './RecentsEventsOverview';
import TaskRepartitionOverview from './TaskRepartitionOverview';
import EffortBreakdownOverview from './EffortBreakdownOverview';
import EventsOverview from './EventsOverview';
import CostBudgetOverview from './CostBudgetOverview';
import ExpensesListOverview from './ExpensesListOverview';
import Unautorized from '../../Unautorized/Unautorized';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Overview = ({
    stateTasks,
    workspaceSelected,
    childsParents,
    datesRange,
    hasAutorization,
    dateFormat,
    userTimezone,
    usersAllEvents,
}) => {
    if (!hasAutorization) {
        return <Unautorized />;
    }

    return (
        <div className="Overview">
            <DateDriftOverview
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                userTimezone={userTimezone}
            />
            <TaskOverview
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                userTimezone={userTimezone}
            />
            <EffortOverview
                workspaceSelected={workspaceSelected}
                stateTasks={stateTasks}
                childsParents={childsParents}
                userTimezone={userTimezone}
            />
            <TimeWorkedOverview
                workspaceSelected={workspaceSelected}
                stateTasks={stateTasks}
                childsParents={childsParents}
                datesRange={datesRange}
                userTimezone={userTimezone}
            />
            <CostBudgetOverview
                workspaceSelected={workspaceSelected}
                stateTasks={stateTasks}
                childsParents={childsParents}
                datesRange={datesRange}
            />
            <DaysOffOverview stateTasks={stateTasks} userTimezone={userTimezone} />
            <EventsOverview
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                dateFormat={dateFormat}
                datesRange={datesRange}
                userTimezone={userTimezone}
                usersAllEvents={usersAllEvents}
            />
            <TaskRepartitionOverview
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                dateFormat={dateFormat}
                userTimezone={userTimezone}
            />
            <EffortBreakdownOverview
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                dateFormat={dateFormat}
                userTimezone={userTimezone}
            />
            <InternalCostBreakdown
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                dateFormat={dateFormat}
            />
            <BillableCostBreakdown
                stateTasks={stateTasks}
                workspaceSelected={workspaceSelected}
                childsParents={childsParents}
                dateFormat={dateFormat}
            />
            <div className={'overview-tables-contaier'}>
                <UpcomingTasksOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
                <RecentlyDoneTasksOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
                <UpcomingEventsOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
                <RecentsEventsOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
                <RiskOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
                <ExpensesListOverview
                    stateTasks={stateTasks}
                    workspaceSelected={workspaceSelected}
                    childsParents={childsParents}
                    dateFormat={dateFormat}
                    userTimezone={userTimezone}
                />
            </div>
        </div>
    );
};

Overview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(Overview);
