import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { handleStopPropagation, tracking } from '../../utils/utils';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import classnames from 'clsx';
import EditSkillModal from '../EditSkillModal/EditSkillModal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getAccountId, getAccountSkills } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { SearchInput, SkillList } from './components';
import useInternalization from '../../utils/hooks/useInternalization';
import { getUserPermissionsSkills } from '../../utils/selectors/user';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

const SelectSkill = ({ open, currentSkill, onClose, onSelect, isMultiSelect, defaultValue = {} }) => {
    const { t } = useInternalization();
    const [checkedItems, setCheckItems] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [isEditSkillOpen, setIsEditSkillOpen] = useState(false);
    const hasUserSkillPermission = useSelector(getUserPermissionsSkills);
    const accountSkills = useSelector(getAccountSkills);

    const accountId = useSelector(getAccountId);

    const checkSkillIsSelected = (id) => checkedItems.includes(id);

    useEffect(() => {
        setSearchValue('');
        setCheckItems(currentSkill || []);
    }, [open, currentSkill]);

    useEffect(() => {
        setSearchValue('');
    }, []);

    const handleSearch = useCallback(({ target: { value } }) => setSearchValue(value), []);

    const onSelectSkill = React.useCallback((e, selectedId) => {
        e?.stopPropagation();

        if (!isMultiSelect) {
            onSelect(selectedId);
            return;
        }

        setCheckItems(prevState =>
            checkSkillIsSelected(selectedId)
                ? prevState.filter(el => el !== selectedId)
                : [...checkedItems, selectedId]
        );
    }, [checkedItems, isMultiSelect]);

    const handleEditSkill = (e) => {
        handleStopPropagation(e);
        setIsEditSkillOpen(!isEditSkillOpen);
    };

    const handleEditSkillSubmit = (key) => {
        if (!isMultiSelect) {
            onSelect(key);
        }

        onSelectSkill(null, key);
        setIsEditSkillOpen(false);
    };

    const handleClose = (e) => {
        handleStopPropagation(e);
        tracking('CanceledSelectSkill', { accountId });
        onClose(e);
    };

    const handleSubmit = () => {
        onSelect(checkedItems);
    };

    return <>
        <Dialog maxWidth={'md'} className="SelectSkill_Modal" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} data-sidebar='preventClose'>
            <MuiDialogTitle className="title" disableTypography onClick={handleStopPropagation}>
                <Typography variant="h6"> {t('Select a Skill')}</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers onClick={handleStopPropagation}>
                <SearchInput
                    onChange={handleSearch}
                    placeholder={
                        hasUserSkillPermission
                            ? 'Search or type to create a new skill...'
                            : 'Search...'
                    }
                />
                {isMultiSelect && 
                    <div className='de-selectAll'>
                        <a onClick={()=>setCheckItems(accountSkills ? Object.keys(accountSkills):[])}>
                            <CheckBox /> Select all
                        </a>
                        <a onClick={()=>setCheckItems([])}>
                            <CheckBoxOutlineBlank /> Deselect all
                        </a>
                    </div>
                }
                <SkillList {...{
                    searchValue,
                    isMultiSelect,
                    onSelectSkill,
                    checkSkillIsSelected,
                }}/>

                {hasUserSkillPermission && (
                    <div className={classnames('Skill-not-found-btn', { multi: isMultiSelect })}>
                        <Button onClick={handleEditSkill} variant="contained" fullWidth>
                            {
                                searchValue.trim().length >= 1 && <> {t('CREATE')} <span className="task-name-btn"> "{searchValue}" </span> {t('SKILL')}</>
                            }
                            {
                                !searchValue.trim().length && t('CREATE NEW SKILL')
                            }
                        </Button>
                    </div>
                )}
            </MuiDialogContent>
            {isMultiSelect && <MuiDialogActions className='actions'>
                <Button
                    className="cancel-btn"
                    onClick={handleClose}
                    variant="contained"
                >
                    {t('CANCEL')}
                </Button>
                <Button
                    className="submit-btn"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t('Save Changes')}
                </Button>
            </MuiDialogActions>}
        </Dialog >
        {
            isEditSkillOpen && <EditSkillModal
                defaultValue={defaultValue}
                isOpen={isEditSkillOpen}
                skillName={searchValue}
                onSubmit={handleEditSkillSubmit}
                onClose={handleEditSkill}
            />
        }
    </>;
};

SelectSkill.propTypes = {
    open: Proptypes.bool.isRequired,
    currentSkill: Proptypes.array,
    onClose: Proptypes.func.isRequired,
    onSelect: Proptypes.func.isRequired,
    isMultiSelect: Proptypes.bool,
    defaultValue: Proptypes.object,
};

export default withCustomErrorBoundary(SelectSkill);