import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'clsx';
import { browserHistory } from 'react-router';
import _ from 'underscore';
import filtersConf from './filters.json';
import FiltersAdd from '../FiltersAdd/FiltersAdd';
import FiltersAndOr from '../FiltersAndOr/FiltersAndOr';
import FiltersDetail from '../FiltersDetail/FiltersDetail';
import moment from 'moment';
import * as firebaseEvents from '../../utils/firebaseEvents';
import {toastr} from 'react-redux-toastr';
import SavePresetIcon from '@material-ui/icons/CreateNewFolder';
import ClearIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { tracking } from '../../utils/utils';
import { Divider } from '@material-ui/core';
import { getFilters, getShowFilters } from '../../utils/selectors/app';
import { getAccountCustomFields, getAccountId, getAccountSkills, getAccountTags, getAccountUsers } from '../../utils/selectors/account';
import { getUserDateformat, getUserId } from '../../utils/selectors/user';
import { showFilters, updateFilters } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Filters = ({notEffective})=>{
    const intl = useIntl();
    const dispatch = useDispatch();

    const filters = useSelector(getFilters);
    const skills = useSelector(getAccountSkills);
    const users = useSelector(getAccountUsers);
    const tags = useSelector(getAccountTags);
    const dateFormat = useSelector(getUserDateformat);
    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const showFiltersVal = useSelector(getShowFilters);
    const customFields = useSelector(getAccountCustomFields);

    const [openSavePreset, setOpenSavePreset] = useState(false);
    const [saveChoiceValue, setSaveChoiceValue] = useState('new');
    const [saveTextValue, setSaveTextValue] = useState('');
    const [saveSelectValue, setSaveSelectValue] = useState('');

    useEffect(()=>{
        if(window?.location?.search?.match('filtersOpen')) {
            browserHistory.push('/tasks');
            dispatch(showFilters(true));
        }
    }, []);

    const onRequestDelete = (groupIndex, filterIndex) => {
        var filtersParsed = JSON.parse(filters);

        if(_.size(filtersParsed[groupIndex]) === 2){
            delete filtersParsed[groupIndex];
        }
        else {
            delete filtersParsed[groupIndex][filterIndex];
        }

        var newFilters = {andor: filtersParsed.andor},
            i = 0;

        _.each(filtersParsed, (group, key)=>{
            if(key !== 'andor'){
                var newGroup = {andor: filtersParsed[key].andor},
                    j=0;

                _.each(filtersParsed[key], (filter, fKey)=>{
                    if(fKey !== 'andor'){
                        newGroup[j] = filter;
                        j++;
                    }
                });

                newFilters[i] = newGroup;
                i++;
            }
        });

        
        firebaseEvents.addChallenge('deleteFilter');

        dispatch(updateFilters(newFilters, accountId));
    };

    const onChangeAndOr = (groupIndex, type) => {

        var filtersParsed = JSON.parse(filters);
        if(groupIndex !== undefined && groupIndex !== null){
            filtersParsed[groupIndex].andor = type;
        }
        else {
            filtersParsed.andor = type;
        }
        
        dispatch(updateFilters(filtersParsed, accountId));
    };

    const onChangeAdd = (groupIndex, filterId) => {
        
        var filtersParsed = JSON.parse(filters),
            nb,
            type = null,
            value = null,
            filterData = filtersConf[filterId];

        if(!filterData && customFields[filterId]){
            if(customFields[filterId].type === 'text'){
                filterData = {type: 'string'};
            }
            else if(customFields[filterId].type === 'number'){
                filterData = {type: 'number'};
            }
            else if(customFields[filterId].type === 'date'){
                filterData = {type: 'date'};
            }
            else if(customFields[filterId].type === 'checkbox'){
                filterData = {type: 'choice', choices: ['Checked', 'Unchecked']};
            }
            else if(customFields[filterId].type === 'people'){
                filterData = {type: 'choice', choices: 'users'};
            }
            else if(customFields[filterId].type === 'list'){
                filterData = {type: 'choice', choices: customFields[filterId].choices};
            }
        }

        switch(filterData.type){
        case 'choice':
            type = 'is';
            break;

        case 'string':
            type = 'contains';
            value = '';
            break;

        case 'number':
            type = 'is';
            value = '1';
            break;

        case 'date':
            type = 'on';
            value = moment().format('YYYY-MM-DD');
            break;

        case 'has':
            type = 'hasValue';
            break;
        }

        if(filterData.type === 'choice'){

            if(_.isArray(filterData.choices)){
                value = filterData.choices;
            }
            else if(filterData.choices === 'users'){
                value = [userId];
            }
            else if(filterData.choices === 'skills'){
                value = _.map(skills, (skill, key)=>{
                    return key;
                });
            }
        }

        if(groupIndex !== undefined && groupIndex !== null){
            nb = _.size(filtersParsed[groupIndex])-1;
            filtersParsed[groupIndex][nb] = {id: filterId, type: type, value: value};
        }
        else {
            nb = _.size(filtersParsed)-1;
            filtersParsed[nb] = {andor:'and', 0: {id: filterId, type: type, value: value}};
        }

        tracking('Used Filters');
        dispatch(updateFilters(filtersParsed, accountId));

        firebaseEvents.addChallenge('addFilter');
    };

    const onChangeFilterType = (type, groupIndex, filterIndex) => {

        var filtersParsed = JSON.parse(filters);
        filtersParsed[groupIndex][filterIndex].type = type;
        
        dispatch(updateFilters(filtersParsed, accountId));
    };

    const onChangeFilterValues = (values, groupIndex, filterIndex) => {

        if(
            (_.isArray(values) && values.length > 0) ||
            !_.isArray(values)
        ){

            var filtersParsed = JSON.parse(filters);
            filtersParsed[groupIndex][filterIndex].value = values;
            
            dispatch(updateFilters(filtersParsed, accountId));

        }
    };

    const renderFilter = (filter, groupIndex, filterIndex) => {
        var val = filter.value,
            currFilterConf = filtersConf[filter.id];

        if(!currFilterConf && customFields[filter.id]){
            currFilterConf = {
                label: customFields[filter.id].name,
                icon: 'hdr_weak'
            };

            if(customFields[filter.id].type === 'text'){
                currFilterConf.type = 'string';
            }
            else if(customFields[filter.id].type === 'number'){
                currFilterConf = {
                    ...currFilterConf,
                    type: 'number',
                    suffix: '',
                    min: 0,
                    max: 999999,
                    hasornot: true
                };
            }
            else if(customFields[filter.id].type === 'date'){
                currFilterConf.type = 'date';
                currFilterConf.hasornot = true;
            }
            else if(customFields[filter.id].type === 'checkbox'){
                currFilterConf.type = 'choice';
                currFilterConf.choices = ['Checked', 'Unchecked'];
            }
            else if(customFields[filter.id].type === 'people'){
                currFilterConf.type = 'choice';
                currFilterConf.choices = 'users';
            }
            else if(customFields[filter.id].type === 'list'){
                currFilterConf.type = 'choice';
                currFilterConf.choices = customFields[filter.id].choices;
            }
        }

        if(_.isArray(filter.value)){
            val = [...filter.value];
            if(_.size(val) === _.size(currFilterConf.choices)){
                val = intl.formatMessage({id:'any'});
            }
            else {
                if(currFilterConf.choices === 'users'){
                    if(_.size(val) === _.size(users)){
                        val = [intl.formatMessage({id:'any'})];
                    }
                    else {
                        _.each(val, (txt, key)=>{
                            if(txt === userId){
                                val[key] = intl.formatMessage({id:'Me'});    
                            }
                            else {
                                if(users[txt]){
                                    val[key] = (users[txt])?users[txt].displayName:'';
                                }
                            }
                        });
                    }
                }
                else if(currFilterConf.choices === 'skills'){
                    if(_.size(val) === _.size(skills)){
                        val = [intl.formatMessage({id:'any'})];
                    }
                    else {
                        _.each(val, (txt, key)=>{
                            val[key] = skills[txt].name;
                        });
                    }
                }
                else if(currFilterConf.choices === 'tags'){
                    _.each(val, (txt, key)=>{
                        val[key] = tags[txt].title;
                    });
                }
                else {
                    _.each(val, (txt, key)=>{
                        val[key] = intl.formatMessage({id:txt});
                    });
                }

                val = val.join(' ' +  intl.formatMessage({id:'or'}) + ' ');
            }
        }

        var effective = true;
        if(notEffective && notEffective.indexOf(filter.id) !== -1){
            effective = false;
        }

        if(
            filter.id === 'expectedDeliveryDate' ||
            filter.id === 'earliestDeliveryDate' ||
            filter.id === 'latestDeliveryDate' ||
            filter.id === 'delayDate' ||
            filter.id === 'eventStartDate' ||
            filter.id === 'eventEndDate'
        ){
            val = moment(val).format(dateFormat || 'DD/MM/YYYY');
        }
        
        return (
            <FiltersDetail 
                key={'filter_' + groupIndex + filterIndex}
                onChangeType={(type)=>onChangeFilterType(type, groupIndex, filterIndex)} 
                onChangeValues={(values)=>onChangeFilterValues(values, groupIndex, filterIndex)} 
                onRequestDelete={onRequestDelete} 
                groupIndex={groupIndex}
                filterIndex={filterIndex}
                label={intl.formatMessage({id:currFilterConf.label})} 
                type={filter.type} 
                effective={effective} 
                val={val}
                value={filter.value}
                filterConf={currFilterConf}
            />
        );
    };

    const renderGroupAdd = (groupIndex) => {
        return <FiltersAdd key={'filterAdd_' + groupIndex} groupIndex={groupIndex} onChange={onChangeAdd} />;
    };

    const renderAndOr = (type, groupIndex, i) => {
        return <FiltersAndOr key={'filterAndor_' + groupIndex + i} groupIndex={groupIndex} type={type} onChange={onChangeAndOr} />;
    };

    const renderGroup = (group, index) => {

        var AndOr = group.andor;

        var result = [],
            j=0;

        _.each(group, (filter, key)=>{
            if(key !== 'andor'){
                result.push(renderFilter(filter, index, key));

                j++;
                if(j < _.size(group)-1){
                    result.push(renderAndOr(AndOr, index, j));
                }
                else {
                    result.push(renderGroupAdd(index));
                }
            }
        });

        return (
            <div className="chipGroup" key={index}>{result}</div>
        );
    };

    const doOpenSavePreset = () => {
        setOpenSavePreset(true);
    };
    const clearFilters = () => {
        dispatch(updateFilters({andor:'and'}, accountId));
    };

    const handleCloseSavePreset = () => {
        setSaveTextValue('');
        setSaveSelectValue('');
        setOpenSavePreset(false);
    };


    const changeSaveChoice = (e) => {
        setSaveChoiceValue(e.target.value);
    };

    const saveTextValueChange = (event) => {
        setSaveTextValue(event.target.value);
    };

    const saveSelectValueChange = (event) => {
        setSaveSelectValue(event.target.value);
    };

    const getSavePresetInput = () => {
        if(saveChoiceValue === 'new'){
            return (
                <TextField
                    id='presetName'
                    autoFocus
                    value={saveTextValue}
                    label={intl.formatMessage({id:'Preset name'})}
                    onChange={saveTextValueChange}
                    fullWidth={true}
                />
            );
        }
        else {
            var list = _.map(users[userId].filterPresets, (preset, key)=>{
                return <MenuItem key={key} value={key}>{preset.name}</MenuItem>;
            });

            return (
                <TextField
                    select
                    label={intl.formatMessage({id:'Choose your Preset'})}
                    value={saveSelectValue}
                    onChange={saveSelectValueChange}
                    fullWidth={true}
                >
                    {list}
                </TextField>
            );   
        }
    };

    const handleSavePreset = (event) => {
        event.preventDefault();

        var presetName = '',
            presetId = null;

        if(saveChoiceValue === 'new'){
            presetName = saveTextValue;    
        }
        else {
            presetId = saveSelectValue;   
        }

        if(presetName !== '' || presetId){
            firebaseEvents.savePreset(
                accountId,
                userId,
                presetId,
                presetName,
                filters,
                intl
            );

            firebaseEvents.addChallenge('savePreset');

            handleCloseSavePreset();
        }
        else {
            toastr.error(intl.formatMessage({id:'error.title'}), intl.formatMessage({id:'Error saving your preset'}));
        }
    };

    if(showFiltersVal && users && users[userId]){
        var filtersParsed = JSON.parse(filters);
        
        // check if filters contain users or skills from another account
        var changed = false;
        for(var f in filtersParsed){
            for(var j in filtersParsed[f]){
                if(filtersParsed[f][j].id === 'assigned'){
                    for(var u in filtersParsed[f][j].value){
                        if(!users[filtersParsed[f][j].value[u]]){
                            changed = true;
                            delete filtersParsed[f][j];
                            break;
                        }
                    }
                }
                else if(filtersParsed[f][j].id === 'skill'){
                    for(var s in filtersParsed[f][j].value){
                        if(!skills[filtersParsed[f][j].value[s]]){
                            changed = true;
                            delete filtersParsed[f][j];
                            break;
                        }
                    }
                }
            }
        }
        if(changed){
            for(var f2 in filtersParsed){
                if(Object.keys(filtersParsed[f2]).length === 1){
                    delete filtersParsed[f2];
                }
            }
            dispatch(updateFilters(filtersParsed, accountId));
            return null;
        }


        var firstLevelAndOr = filtersParsed.andor;

        var renderedGroups = [],
            i=0;

        _.each(filtersParsed, (group, key)=>{
            if(key !== 'andor'){
                renderedGroups.push(renderGroup(group, key));

                i++;
                if(i < _.size(filtersParsed)-1){
                    renderedGroups.push(renderAndOr(firstLevelAndOr, null, i));
                }
            }
        });

        return (
            <div className="Filters" data-sidebar='preventClose'>

                <div className="filtercontent">

                    {renderedGroups}

                    <FiltersAdd onChange={onChangeAdd} />

                    <a className={classnames('saveFilter')} onClick={doOpenSavePreset}><SavePresetIcon/> {intl.formatMessage({id:'Save as preset'})}</a>
                    {(renderedGroups.length)?(
                        <a className={classnames('clearFilters')} onClick={clearFilters}><ClearIcon/> {intl.formatMessage({id:'Clear filters'})}</a>
                    ):null}
                </div>

                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={openSavePreset}
                    onClose={handleCloseSavePreset}
                    className='saveAsPreset'
                    data-sidebar='preventClose'
                >
                    <form onSubmit={handleSavePreset}>
                        <DialogTitle>{intl.formatMessage({id:'Save as preset'})}</DialogTitle>
                        <Divider />
                        <DialogContent>
                            <RadioGroup name="savePresetChoice" className="savePresetChoice" value={saveChoiceValue} onChange={changeSaveChoice}>
                                <FormControlLabel
                                    control={<Radio size='small' />}
                                    value="existing"
                                    label={intl.formatMessage({id:'Save to existing preset'})}
                                />
                                <FormControlLabel
                                    control={<Radio size='small' />}
                                    value="new"
                                    label={intl.formatMessage({id:'Create new preset'})}
                                />
                            </RadioGroup>
                            {getSavePresetInput()}
                        </DialogContent>
                        <DialogActions className='actions'>
                            <Button
                                variant="contained"
                                onClick={handleCloseSavePreset}
                            >{intl.formatMessage({id:'Cancel'})}</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSavePreset}
                            >{intl.formatMessage({id:'Save'})}</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
    return null;

};

export default withCustomErrorBoundary(Filters);
