import { db } from '../../firebaseEvents';
import { getAccountId } from '../../selectors/account';
import { getState } from '../../../configureStore';

const taskPath = (accountId, taskId) => `/accounts/${accountId}/tasks/${taskId}`;

export const updateTaskSourceStatus = ({taskId, statusId, statusName, planlessStatus}) => {
    db.update(undefined, {
        [`${taskPath(getAccountId(getState()), taskId)}/status`]: planlessStatus,
        [`${taskPath(getAccountId(getState()), taskId)}/sourceStatus`]: {
            id: statusId,
            name: statusName
        }
    });
};
