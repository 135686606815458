import React from 'react';
import classnames from 'clsx';
import { Button, Checkbox } from '@material-ui/core';
import useInternalization from '../../../utils/hooks/useInternalization';

export function SkillItem({ skill, onSelectSkill, isMultiSelect, isSkillSelected, isShowing }) {
    const { t } = useInternalization();
    const marginLeft = `${skill.lvl * 2}rem`;

    const ButtonComponent = (skillId) => isSkillSelected ? (
        <Button
            className="SelectSkill_CURRENT_BTN"
            variant="contained">
            {t('Current')}
        </Button>
    ) : (
        <Button
            value={skillId}
            className="SelectSkill_BTN"
            id={skillId}
            onClick={(e) => onSelectSkill(e, skillId)}
            variant="contained">
            {t('Select')}
        </Button>
    );

    return (
        <div
            onClick={(e) => onSelectSkill(e, skill.id)}
            className={classnames('SelectSkill_Skill', { hidden: !isShowing })}
            id={skill.id}
            key={skill.id}>
            <div style={{ marginLeft }}>
                <span>{skill.name}</span>
                <>
                    {isMultiSelect ? (
                        <Checkbox
                            color='primary'
                            value={skill.id}
                            onChange={(e) => onSelectSkill(e, skill.id)}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={isSkillSelected}
                        />
                    ) : (
                        <ButtonComponent skillId={skill.id} />
                    )}
                </>
            </div>
        </div>
    );
}