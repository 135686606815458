import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

export function SearchInput({ onChange, placeholder }) {
    return (
        <div className="input-search-container">
            <TextField
                InputProps={{
                    startAdornment: <InputAdornment className="search-icon" position="start"><SearchIcon /></InputAdornment>,
                }}
                placeholder={placeholder}
                className="input-search"
                fullWidth
                onChange={onChange}
                autoFocus
            />
        </div>
    );
}
