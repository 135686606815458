import React from 'react';
import Tooltip from 'rc-tooltip';
import { SIRV_INTEGRATIONS_LOGOS_URL, SYNC_APP_NAMES, TOOL_SOURCE_LINKS } from '../../views/Integrations/utils';
import useInternalization from '../../utils/hooks/useInternalization';
import classnames from 'clsx';

const SyncProjectLogo = ({ toolName, tooltipPlacement = 'top', fromViewMode = 'folderLine', task }) => {
    const { t } = useInternalization();

    const handleOpenExternalTask = (e) => {
        e.stopPropagation();

        if (!task) return;

        const baseLink = TOOL_SOURCE_LINKS[toolName];

        if(task?.sourceUrl) {
            window.open(task?.sourceUrl);
            return;
        }

        window.open(baseLink);
    };

    if(!toolName) {
        return null;
    }

    return (
        <Tooltip
            placement={tooltipPlacement}
            overlay={t('syncprojectlogo.tooltip', {toolName: SYNC_APP_NAMES[toolName]})}>
            <img
                onClickCapture={handleOpenExternalTask}
                className={classnames(`SyncProjectLogo ${fromViewMode}`, { clickable: !!task && !!task?.sourceUrl })}
                src={`${SIRV_INTEGRATIONS_LOGOS_URL}/tasklineIcon/${toolName}.png`}
                alt={`${toolName}.svg`}
            />
        </Tooltip>
    );
};

export default SyncProjectLogo;