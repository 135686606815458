import React, { useRef } from 'react';
import Masonry from 'react-masonry-component';
import NextEvents  from '../NextEvents/NextEvents';
import TodoList  from './TodoList';
import NextDeadlines  from './NextDeadlines';
import NextTasks  from './NextTasks';
import SectionTitle  from '../SectionTitle/SectionTitle';
import {withCustomErrorBoundary} from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { useIntl } from 'react-intl';
import { Dashboard as DashboardIcon } from '@material-ui/icons';

const Dashboard = ({ children }) => {
    const intl = useIntl();
    const masonry = useRef();

    return (
        <div className="Dashboard">
            <SectionTitle title={intl.formatMessage({id:'Dashboard'})} icon={<DashboardIcon />} />
            <Masonry ref={masonry} updateOnEachImageLoad={true} enableResizableChildren={true} className="masonry">
                <TodoList masonry={masonry} />
                <NextTasks masonry={masonry} />
                <NextDeadlines masonry={masonry} />
                <NextEvents masonry={masonry} />
            </Masonry>
            {children}
        </div>
    );
};

export default withCustomErrorBoundary(Dashboard);