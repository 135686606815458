export var TREE_LEVELS_BY_TOOL = {
    asana: {
        workspace: {
            childrenType: 'teams',
            team: {
                childrenType: 'projects',
            },
        },
    },
    clickup: {
        workspace: {
            childrenType: 'spaces',
            space: {
                childrenType: 'lists',
            },
        },
    },
};
