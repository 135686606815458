import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';
import { getAccountsDataTasks } from  '../../../utils/controllers/tasks/budget';
import { currencyFormat  } from '../../../utils/currencyFormat';
import { getPath } from '../utils';
import { sortByDate } from '../utils';

const getInfo = (task, id, stateTasks, expense) => ({
    id: id,	
    path: task.parent ? getPath(task.parent, stateTasks) : task.title,	
    taskName: task.title || '',
    date: expense.date,
    expenseTitle: expense.title,
    expenseTypes: expense.types, 
    expenseUnits: expense.units,
    expensePricePerUnit: expense.pricePerUnit,
    expenseTotal: expense.total,
    expensetotalWithVat: expense.totalWithVat,
});

const ExpensesListOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
    dateFormat,
}) => {
    const currency = useSelector((state) => state.app?.account?.settings.costBudget?.currency || 'USD');
    const [expensesList, setExpensesList] = useState([]);

    useEffect( () => {
        let workspaceTasks =  workspaceSelected === 'root' ? 
            Object.keys(stateTasks) : 
            [..._.get(childsParents, workspaceSelected , []), workspaceSelected];
        
        getAccountsDataTasks().then(data => {
            const expensesArray = [];
            workspaceTasks.forEach(taskId => {
                const currentTask = stateTasks[taskId];
                if(!currentTask || !data[taskId]) {
                    return false;
                }

                const tasksExpenses = data[taskId].expenses;
                if (tasksExpenses) {
                    tasksExpenses.forEach(el => expensesArray.push(getInfo(currentTask, taskId, stateTasks, el)));
                }
            });
            setExpensesList(expensesArray.sort((a,b) => sortByDate(b, a,'date')).slice(0,5));
        });
    }, [workspaceSelected, stateTasks, childsParents]);
    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return <Card>
        <CardHeader 
            title={<span> <b>Expenses</b></span> }
            action={
                <Button component={Link}  size="small" variant="outlined" color="primary"
                    key="expenses-list" 
                    to={`${path}/expenses-list`}
                >
                    See All <b>Expenses</b>
                </Button>
            }
        />
        <CardContent>
            {
                expensesList.length ? 
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Expenses Name</TableCell>
                                <TableCell>Total with Vat</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {expensesList.map((row) => (
                                <TableRow key={`expensesList-${row.taskName}-${row.id}`}>
                                    <TableCell align="left">{moment(row.date).format(dateFormat)}</TableCell>
                                    <TableCell align="left">{row.expenseTitle}</TableCell>
                                    <TableCell align="left">{currencyFormat({ value: row.expensetotalWithVat, currency: currency })}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className='empty  small'>No Data</div>
            }
        </CardContent>
    </Card>;
};

ExpensesListOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default ExpensesListOverview;