import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import _ from 'underscore';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import AlertsDialog from './AlertsDialog';
import UserCell from '../../Reports/UserCell';
import EditIcon from '@material-ui/icons/Edit';
import { removeAlert, getTaskAlerts, updateAlert, createAlert } from '../../../utils/controllers/tasks/alerts';
import Proptypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const getAlert = item => ({
    id: _.get(item, 'id', null),
    notificationType: _.get(item, 'notificationType', ''),
    to: _.get(item, 'to', []),
    alertType: _.get(item, 'alertType', ''),
    value: _.get(item, 'value', 0),
    previsionType: _.get(item, 'previsionType', ''),
    onValue: _.get(item, 'onValue', ''),
    checkStatus: _.get(item, 'checkStatus', ''),
    name: _.get(item, 'name', ''),
});

const Alerts = ({ taskBudget, taskId }) => {
    const intl = useIntl();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editItem, setEdit] = useState(null);
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        getTaskAlerts(taskId).then(data =>
            setAlerts(
                data
                    ? Object.keys(data).map(id => ({
                        ...getAlert(data[id]),
                        id: id,
                    }))
                    : [],
            ),
        );
    }, []);

    const handleDialog = () => {
        if (!taskBudget) {
            toastr.confirm(intl.formatMessage({ id: 'alert-warning' }), {
                okText: intl.formatMessage({ id: 'Ok' }),
                showCloseButton: false,
                disableCancel: true,
            });
            return;
        }
        setDialogOpen(!dialogOpen);
    };

    const handleAdd = (id, alert) => {
        if (id) {
            updateAlert(taskId, id, alert);
        } else {
            createAlert(taskId, alert).then(() => {});
        }
        getTaskAlerts(taskId).then(data =>
            setAlerts(
                data
                    ? Object.keys(data).map(id => ({
                        ...getAlert(data[id]),
                        id: id,
                    }))
                    : [],
            ),
        );
        setDialogOpen(!dialogOpen);
        setEdit(null);
    };

    const handleDelete = id => {
        toastr.confirm(intl.formatMessage({ id: 'delete-alert-warning' }), {
            onOk: () => {
                const alertsCopy = [...alerts].filter(el => el.id !== id);
                setAlerts(alertsCopy);
                removeAlert(taskId, id);
            },
            okText: intl.formatMessage({ id: 'OK' }),
            cancelText: intl.formatMessage({ id: 'toastr.confirm.cancel' }),
        });
    };

    const handleEdit = id => {
        setEdit(alerts.find(el => el.id === id));
        setDialogOpen(!dialogOpen);
    };

    return (
        <div className="Alerts">
            <Button variant="contained" onClick={()=>{
                setEdit(false);
                handleDialog();
            }} color="primary" aria-label="add">
                {intl.formatMessage({ id: 'alert.add-new' })}
            </Button>

            <Table aria-label="simple table">
                {alerts.length ? (
                    <>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{intl.formatMessage({ id: 'alert.name' })}</TableCell>
                                <TableCell align="left">
                                    {intl.formatMessage({ id: 'alert.notificationType' })}
                                </TableCell>
                                <TableCell align="left">{intl.formatMessage({ id: 'alert.to' })}</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                    </>
                ) : null}
                <TableBody>
                    {alerts.length ? (
                        alerts.map(row => (
                            <TableRow key={row.name}>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{intl.formatMessage({ id: row.notificationType })}</TableCell>
                                <TableCell align="left">
                                    {row.to.map(el => (
                                        <UserCell
                                            color={accountTeamMembers[el].color}
                                            key={el}
                                            userId={el}
                                            name={accountTeamMembers[el].displayName}
                                            avatarPath={accountTeamMembers[el].avatar}
                                        />
                                    ))}
                                </TableCell>
                                <TableCell scope="row">
                                    <EditIcon className="icon" onClick={() => handleEdit(row.id)} />
                                    <DeleteIcon className="icon" onClick={() => handleDelete(row.id)} />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                {intl.formatMessage({ id: 'No Alert is defined' })}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {dialogOpen && <AlertsDialog
                taskBudget={taskBudget}
                editItem={editItem}
                open={dialogOpen}
                handleAdd={handleAdd}
                handleClose={handleDialog}
            />}
        </div>
    );
};

Alerts.propTypes = {
    taskId: Proptypes.string.isRequired,
};

export default withCustomErrorBoundary(Alerts);
