import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TaskEventCell from '../TaskEventCell';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const RiskOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
    dateFormat,
    userTimezone
}) => {
    const tasks = React.useMemo(()=> {
        if (stateTasks) {
               
            let workspaceTasks =  workspaceSelected === 'root' ? 
                Object.keys(stateTasks) : 
                _.get(childsParents, workspaceSelected , [workspaceSelected]);

            const getTaskInfo = (task, taskId) => ({
                id: taskId,	
                taskName: task.title || 'N/A',
                dueDate: task.deadline,
                expectAt:  task.estimations?.expectedAt || 'N/A',
            });

            return workspaceTasks
                .filter(taskId => {
                    const currentTask = stateTasks[taskId];
                    
                    if(!currentTask || currentTask.status === 'done' ){
                        return false;
                    }

                    if(!currentTask.risks?.taskAtDanger  && !currentTask.risks?.taskAtRisk){
                        return false;
                    }

                    return true;
                })
                .map(el => getTaskInfo(stateTasks[el],el))
                .slice(0,5);
        }

    }, [stateTasks, workspaceSelected]);
    const path = window.location.pathname.split('/').slice(0,3).join('/');


    return <Card>
        <CardHeader 
            title={<span> <b>Tasks At Risk</b></span> }
            action={
                <Button component={Link}  size="small" variant="outlined" color="primary"
                    key="risks" 
                    to={`${path}/risks`}
                >
                    See All <b>Tasks at risk</b>
                </Button>
            }
        />
        <CardContent>
            {
                tasks.length ? 
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Expected At</TableCell>
                                <TableCell>Task Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((row) => (
                                <TableRow key={`RiskOverview-${row.taskName}-${row.id}`}>
                                    <TableCell align="left">{row.dueDate !== 'N/A' ? moment.tz(row.dueDate, 'YYYY-MM-DD', userTimezone).format(dateFormat) : row.dueDate }</TableCell>
                                    <TableCell align="left">{row.expectAt !== 'N/A' ? moment.tz(row.expectAt, 'X', userTimezone).format(dateFormat) : row.expectAt }</TableCell>
                                    <TableCell align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className='empty  small'>No Data</div>
            }
        </CardContent>
    </Card>;
};

RiskOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default RiskOverview;