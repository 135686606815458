import { FolderOpen, FolderOpenOutlined, FormatLineSpacing, InsertChart, MonetizationOn, OpenInNew, Security } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import {
    getAccountCostBudgetSetting,
    getAccountId,
    getAccountPlan,
    getAccountTask,
    getAccountTaskCanAdmin,
    getAccountTaskCanManage,
    getAccountTaskCanView,
    getAccountTaskChildrens,
    getAccountTaskIsConnectProject,
    getAccountTaskIsRecurring,
    getAccountTaskParent,
    getAccountTaskSyncAppType,
    getAccountTaskTitle
} from '../../utils/selectors/account';
import { getLimitViewTo } from '../../utils/selectors/app';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { openChangeTaskLocationModal, setLimitViewTo } from '../App/AppActions';
import { permissionsByPlan } from '../../utils/constants';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';

const CurrentProjectBar = ({router})=>{
    const dispatch = useDispatch();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const accountId = useSelector(getAccountId);
    const accountPlan = useSelector(getAccountPlan);
    const limitView = useSelector(getLimitViewTo);
    const taskTitle = useSelector(getAccountTaskTitle(limitView)) || intl.formatMessage({id:'All folders and tasks'});
    const taskParent = useSelector(getAccountTaskParent(limitView));
    const costBudgetIsOn = useSelector(getAccountCostBudgetSetting);
    const taskChildrens = useSelector(getAccountTaskChildrens(limitView));
    const syncAppType = useSelector(getAccountTaskSyncAppType(limitView));
    const taskIsConnectProject = useSelector(getAccountTaskIsConnectProject(limitView));
    const task = useSelector(getAccountTask(limitView));

    const taskIsRecuring = useSelector(getAccountTaskIsRecurring(limitView));
    const canAdmin = useSelector(getAccountTaskCanAdmin(limitView));
    const canView = useSelector(getAccountTaskCanView(limitView));
    const canViewParent = useSelector(getAccountTaskCanView(taskParent));
    const canManage = useSelector(getAccountTaskCanManage(limitView));

    const openDetails = ()=>{
        browserHistory.push(`/${router.routes[3].path}/${limitView}`);
        setAnchorEl(null);
    };

    const openParent = ()=>{
        dispatch(setLimitViewTo({accountId, value: taskParent}));
        setAnchorEl(null);
    };

    const insightsClick = useCallback(() => {
        browserHistory.push(`/insights/${limitView}/overview`);
    }, [limitView, permissionsByPlan, accountPlan]);

    const financialsClick = useCallback(() => {
        browserHistory.push(`/tasks/${limitView}/budget`);
    }, [limitView, permissionsByPlan, accountPlan]);

    const changeTaskLocationClick = useCallback((e) => {
        e.preventDefault();
        dispatch(openChangeTaskLocationModal({ taskId: limitView, parent: taskParent}));
    }, [limitView, taskParent]);

    const permissionsClick =  useCallback(() => {

        browserHistory.push('/tasks/' + limitView + '/permissions');
    }, [limitView, permissionsByPlan, accountPlan]);

    return (
        <div className='CurrentProjectBar'>
            <FolderOpenOutlined />
            <h1>{taskTitle}</h1>

            {limitView && <>
                {syncAppType && taskIsConnectProject && <SyncProjectLogo toolName={syncAppType} task={task} fromViewMode={'CurrentProject'}/>}
                <IconButton onClick={(e)=>setAnchorEl(e.currentTarget)} data-sidebar='preventClose' ><MoreVertIcon /></IconButton>

                <Menu
                    className="CurrentProjectBarMenu"
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                    anchorEl={anchorEl}
                    keepMounted
                    data-sidebar='preventClose'
                >
                    {canView &&
                        <MenuItem
                            dense
                            onClick={openDetails}
                        >
                            <ListItemIcon><OpenInNew /></ListItemIcon>
                            {intl.formatMessage({id:'Open Details'})}
                        </MenuItem>
                    }
                    {(!taskParent || canViewParent) &&
                        <MenuItem
                            dense
                            onClick={openParent}
                        >
                            <ListItemIcon><FolderOpen /></ListItemIcon>
                            {intl.formatMessage({id:'Go to parent folder'})}
                        </MenuItem>
                    }
                    {canView && canManage && !taskIsRecuring &&
                        <MenuItem
                            dense
                            onClick={changeTaskLocationClick}
                        >
                            <ListItemIcon><FormatLineSpacing /></ListItemIcon>
                            {intl.formatMessage({id:'Change Location'})}
                        </MenuItem>
                    }
                    {canAdmin &&
                        <MenuItem
                            dense
                            onClick={permissionsClick}
                        >
                            <ListItemIcon><Security /></ListItemIcon>
                            {intl.formatMessage({id:'Permissions'})}
                        </MenuItem>
                    }
                    {canManage && costBudgetIsOn && taskChildrens &&
                        <MenuItem
                            dense
                            onClick={financialsClick}
                        >
                            <ListItemIcon><MonetizationOn /></ListItemIcon>
                            {intl.formatMessage({id:'Financials'})}
                        </MenuItem>
                    }
                    {canManage && taskChildrens &&
                        <MenuItem
                            dense
                            onClick={insightsClick}
                        >
                            <ListItemIcon><InsertChart /></ListItemIcon>
                            {intl.formatMessage({id:'Insights'})}
                        </MenuItem>
                    }
                </Menu>
            </>}

        </div>
    );
};

export default withRouter(CurrentProjectBar);