import React, { useEffect, useMemo, useState } from 'react';
import 'firebase/storage';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import Unautorized from '../Unautorized/Unautorized';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import Loader from '../../components/Loader';
import TaskDetailStopwatch from './TaskDetailStopwach';
import TaskDetailMenu from '../TaskDetailMenu/TaskDetailMenu';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';
import TaskTitleInput from '../TaskTitleInput/TaskTitleInput';
import TaskDetailEmailToTask from './TaskDetailEmailToTask';
import ManageTaskFollowers from '../ManageTaskFollowers/ManageTaskFollowers';
import TaskDetailWarnings from './TaskDetailWarnings';
import CustomSideBar from '../CustomSideBar/CustomSideBar';
import TaskDetailsTabs  from './TaskDetailsTabs';
import TaskDetailPath  from './TaskDetailPath';
import { getUserId } from '../../utils/selectors/user';
import { getAccountId, getAccountTaskCanView, getAccountTaskChildrens, getAccountTaskExist, getAccountTaskParent, getAccountTaskStatus, getAccountTaskTitle } from '../../utils/selectors/account';
import { getLoadersSettings, getLoadersTasks, getLoadersUsers } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
import useSyncData from '../../utils/hooks/useSyncData';

const TaskDetail = ({router, children})=>{
    const { t } = useInternalization();
    const [showEmailToTask, setShowEmailToTask ] = useState(false);

    const taskId = router.params.uid;
    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const loadersTasks = useSelector(getLoadersTasks);
    const loadersSettings = useSelector(getLoadersSettings);
    const loadersUsers = useSelector(getLoadersUsers);
    const taskExist = useSelector(getAccountTaskExist(taskId));
    const taskChildrens = useSelector(getAccountTaskChildrens(taskId));
    const taskParent = useSelector(getAccountTaskParent(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const canView = useSelector(getAccountTaskCanView(taskId));
    const taskTitle = useSelector(getAccountTaskTitle(taskId));
    const loadingTasks = useSelector(getLoadersTasks);
    const loadingSettings = useSelector(getLoadersSettings);
    const loadingUsers = useSelector(getLoadersUsers);
    const syncAppData = useSyncData(taskId);

    const { isSyncAppTask, currentTask } = syncAppData;

    const isTaskInProgress = useMemo(() => taskStatus === 'inprogress', [taskStatus]);

    const close = () => {
        firebaseEvents.addChallenge('closeDetailsPanel');
        setTimeout(() => {
            if(window.location.pathname.includes('/addTask')) return;

            if (router.routes[2]?.path?.includes('insights')) {
                browserHistory.push(`/insights/${router.params.taskId}/${router.params.mode}`);
                return;
            }
            if (router?.routes && router?.routes[3] && router.routes[3].path !== ':uid') {
                browserHistory.push('/' + router.routes[3].path);
            } else {
                browserHistory.push('/' + router.routes[2].path);
            }
        }, 500);
    };

    const keyboardShortcuts = (e) => {
        if (e.keyCode === 27) {
            close();
        }
    };

    useEffect(()=>{
        firebaseEvents.addChallenge('openDetails');

        if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 767) {
            $('.FirstStepsHeader').css('opacity', 0);
        }

        if (localStorage.getItem('taskSidebarWidth')) {
            var width = parseInt(localStorage.getItem('taskSidebarWidth'));
            if (width < 620) {
                width = 620;
            } else if (width > window.innerWidth - 20) {
                width = window.innerWidth - 20;
            }
            $('.sidebar').css('width', width + 'px');
            $('.handle').css('right', width - 5 + 'px');
            $('.sidebarCloseBtn').css('right', width + 'px');
            localStorage.setItem('taskSidebarWidth', width);
        }

        $(document).on('keyup', keyboardShortcuts);

        return function cleanup(){
            $('.FirstStepsHeader').css('opacity', 1);
            $(document).off('keyup', keyboardShortcuts);
        };
    }, []);

    const deleteTask = () => {
        if (userId && accountId && taskId && taskId) {
            const hasChildren = taskChildrens;
            const taskIdToDelete = [taskId];

            if (hasChildren) {
                toastr.confirm(
                    t('All sub tasks of this task will also be deleted. This operation cannot be reverted! Are you sure?'),
                    {
                        onOk: () => {
                            firebaseEvents.deleteTasks(taskIdToDelete);
                            toastr.success(t('Tasks deleted'));
                            close();
                        },
                        okText: t('DELETE'),
                        cancelText: t('toastr.confirm.cancel'),
                    },
                );
            } else {
                const action = firebaseEvents.deleteTasks(taskIdToDelete);

                toastr.success(t('Task deleted'), '', {
                    component: (
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                action.undo();
                                toastr.removeByType('success');
                            }}
                        >
                            {t('Cancel')}
                        </a>
                    ),
                });
            }
        }
    };

    const onShowEmailToTask = () => {
        setShowEmailToTask(true);
    };

    const handleCloseEmailToTask = () => {
        setShowEmailToTask(false);
    };

    const sidebarContent = () => {
        if(loadersTasks || loadersSettings || loadersUsers) {
            return <Loader/>;
        }

        if (userId && canView) {
            const currentUrl = router.routes[3].path !== ':uid' ? router.routes[3].path : router.routes[2].path;

            return (
                <div>
                    <div className="taskDetailHeader">
                        <div className="flex">
                            <TaskDetailPath taskId={taskId} />
                            {currentTask?.syncAppType && taskParent && (
                                <SyncProjectLogo
                                    toolName={currentTask?.syncAppType}
                                    tooltipPlacement={'bottom'}
                                    fromViewMode={'taskSidebarHeader'}
                                    task={currentTask}
                                />
                            )}
                            <ManageTaskFollowers taskId={taskId} />
                            {!(loadingTasks || loadingUsers || loadingSettings) && <TaskDetailMenu
                                taskId={taskId}
                                onShowEmailToTask={onShowEmailToTask}
                                currentUrl={currentUrl}
                                onClose={close}
                                deleteTask={deleteTask}
                                isSyncAppTask={isSyncAppTask}
                            />}
                        </div>
                        <div className="flex">
                            {!taskChildrens && <TaskStatusIcon taskId={taskId} />}
                            <TaskTitleInput
                                taskId={taskId}
                                value={taskTitle}
                                placeholder={t('Task name')}
                                accountId={accountId}
                                isTaskSyncProject={isSyncAppTask}
                            />
                        </div>
                    </div>

                    <div className="timerWarnings">
                        {!isSyncAppTask && isTaskInProgress && <TaskDetailStopwatch taskId={taskId} />}
                        <TaskDetailWarnings taskId={taskId}/>
                    </div>
                    <TaskDetailsTabs taskId={taskId} location={location} routes={router.routes} syncAppData={syncAppData} />
                    <TaskDetailEmailToTask taskId={taskId} open={showEmailToTask} onClose={handleCloseEmailToTask} />
                </div>
            );
        } else {
            return <Unautorized/>;
        }

    };

    if(!loadersTasks && !taskExist){
        close();
    }
    return (
        <CustomSideBar onClose={close} className={'TaskDetail'}>
            {sidebarContent()}
            {children}
        </CustomSideBar>
    );
};

export default withCustomErrorBoundary(withRouter(TaskDetail));

