import _ from 'underscore';
import moment from 'moment';
import { db } from '../firebaseEvents';

export const mapQuote = ({ quote, id}) => ({
    name: _.get(quote, 'name', ''),
    phase: _.get(quote, 'phase', ''),
    email: _.get(quote, 'email', ''),
    companyName: _.get(quote, 'companyName', ''),
    nbUsers: _.get(quote, 'nbUsers', ''),
    originalPrice: _.get(quote, 'originalPrice', ''),
    finalPrice: _.get(quote, 'finalPrice', ''),
    discount: _.get(quote, 'discount', null),
    trialTime: _.get(quote, 'trialTime', ''),
    dueTodayPrice: _.get(quote, 'dueTodayPrice', ''),
    hasTrial: !!_.get(quote, 'trialTime', null),
    hasDiscount: !!_.get(quote, 'discount', null),
    changeBeePlanId: _.get(quote, 'changeBeePlanId', ''),
    planName: _.get(quote, 'planName', ''),
    cupponCode: _.get(quote, 'cupponCode', ''),
    currency: _.get(quote, 'currency', ''),
    createAt: _.get(quote, 'createAt', moment().format()),
    discountLabel: _.get(quote, 'discountLabel', ''),
    years: _.get(quote, 'years', ''),
    months: _.get(quote, 'months', ''),
    plan: _.get(quote, 'plan', ''),
    id: id || _.get(quote, 'id', null)
});

const quotesPath = 'quotes';
export const getQuote = ({ code }) => {
    return db.once(`/${quotesPath}/${code}`);
};

export const getQuotes = () => {
    return db.once(`/${quotesPath}`);
};

export const getListenerQuotes = (callback) => {
    return db.on(`/${quotesPath}`, callback);
};

export const offQuotesListener = () => {
    return db.off(`/${quotesPath}`);
};

export const getListenerQuote = (id, callback) => {
    return db.on(`/${quotesPath}/${id}`, callback);
};

export const offQuoteListener = (id) => {
    return db.off(`/${quotesPath}/${id}`);
};

export const createQuote = ({ quote, id }) => {
    const key = id || db.pushkey(`/${quotesPath}`);

    return db.set(`/${quotesPath}/${key}`, mapQuote({ quote, id: key }));
};

export const updateQuote = ({ id, field, value }) => {
    return db.set(`/${quotesPath}/${id}/${field}`, value );
};