import React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import Badge from '@material-ui/core/Badge';
import { useIntl } from 'react-intl';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { defineClosedSortables, setLimitViewTo, showFilters, updateFilters } from '../../App/AppActions';
import { getAccountId, getAccountTasks } from '../../../utils/selectors/account';
import _ from 'underscore';
import {browserHistory} from 'react-router';
import { getAccountDataSyncApps } from '../../../utils/selectors/accountData';
import { getAuthUrl } from '../../../utils/services/syncApp';

const WarningItem = ({count,type,onClick,children})=>{
    const intl = useIntl();

    return (
        <div className={`WarningItem warningType-${type}`} onClick={onClick}>
            {count?<Chip size="small" label={count} />:<div/>}
            <div>{intl.formatMessage({id: `warningPanel.text.${type}`},{num: count})}</div>
            <div>{children}</div>
        </div>
    );
};

const WarningPanel = ()=>{
    const intl = useIntl();
    const accountId = useSelector(getAccountId);
    const tasks = useSelector(getAccountTasks);
    const accountSyncApps = useSelector(getAccountDataSyncApps);
    const dispatch = useDispatch();

    const nbWarnings = React.useMemo(() => {
        const warnings = {
            syncAppClickUp: _.filter(accountSyncApps, (syncApp)=> syncApp.type === 'clickup' && syncApp.status === 'error').length,
            risks: _.filter(tasks, (task)=>task?.risks?.taskAtRisk).length,
            dangers: _.filter(tasks, (task)=>task?.risks?.taskAtDanger).length,
            missingSkill: _.filter(tasks, (task)=>task?.risks?.taskNoSkill).length,
            missingEffort: _.filter(tasks, (task)=>task?.risks?.taskNoEffort).length,
            missingUserWithSkill: _.filter(tasks, (task)=>task?.risks?.taskEstimationError).length
        };

        warnings.total = Object.values(warnings).reduce((acc, entry) => acc + entry, 0);

        return warnings;
    }, [accountSyncApps, tasks]);

    return (
        <div className="WarningPanel">
            <div className="details">
                {nbWarnings.total === 0 && <div className="noWarning">
                    <img src="https://planless.sirv.com/App/start-scratch.png" />
                    {intl.formatMessage({id:'warningPanel.no.warning'})}
                </div>}
                {nbWarnings.total > 0 && <div className="warningTitle">
                    {intl.formatMessage({id:'warningPanel.title'})}
                </div>}
                {nbWarnings.risks > 0 &&
                    <WarningItem
                        type="risks"
                        onClick={()=>{
                            dispatch(updateFilters({0:{0:{id:'risk', type:'is', value: ['Due date at risk']},andor: 'and'},andor: 'and'}, accountId));
                            dispatch(showFilters(true));
                            dispatch(setLimitViewTo({accountId,value:null}));
                            dispatch(defineClosedSortables([], accountId));
                            browserHistory.push('/tasks');
                        }}
                        count={nbWarnings.risks}
                    >
                        <Tooltip title={intl.formatMessage({id:'warningPanel.action.seeTasks'})}>
                            <IconButton size="small" className="seeTaskBtn">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </WarningItem>
                }
                {nbWarnings.syncAppClickUp > 0 &&
                    <div className={'WarningItem warningType-reconnect'}>
                        <WarningIcon />
                        <div>{intl.formatMessage({id: 'The connection to your ClickUp was lost.'})}</div>
                        <Button
                            onClick={async () => {
                                const redirectUri = await getAuthUrl({type: 'clickup'});
                                window.location.href = redirectUri.data;
                            }}
                            variant="outlined"
                            color="primary"
                            className='WarningItem__reconnectButton'
                        >
                            {intl.formatMessage({id:'Reconnect'})}
                        </Button>
                    </div>
                }
                {nbWarnings.dangers > 0 &&
                    <WarningItem
                        type="dangers"
                        onClick={()=>{
                            dispatch(updateFilters({0:{0:{id:'risk', type:'is', value: ['Due date in danger']},andor: 'and'},andor: 'and'}, accountId));
                            dispatch(showFilters(true));
                            dispatch(setLimitViewTo({accountId,value:null}));
                            dispatch(defineClosedSortables([], accountId));
                            browserHistory.push('/tasks');
                        }}
                        count={nbWarnings.dangers}
                    >
                        <Tooltip title={intl.formatMessage({id:'warningPanel.action.seeTasks'})}>
                            <IconButton size="small" className="seeTaskBtn">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </WarningItem>
                }
                {nbWarnings.missingSkill > 0 &&
                    <WarningItem
                        type="missingSkill"
                        onClick={()=>{
                            dispatch(updateFilters({0:{0:{id:'risk', type:'is', value: ['Missing skill']},andor: 'and'},andor: 'and'}, accountId));
                            dispatch(showFilters(true));
                            dispatch(setLimitViewTo({accountId,value:null}));
                            dispatch(defineClosedSortables([], accountId));
                            browserHistory.push('/tasks');
                        }}
                        count={nbWarnings.missingSkill}
                    >
                        <Tooltip title={intl.formatMessage({id:'warningPanel.action.seeTasks'})}>
                            <IconButton size="small" className="seeTaskBtn">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </WarningItem>
                }
                {nbWarnings.missingEffort > 0 &&
                    <WarningItem
                        type="missingEffort"
                        onClick={()=>{
                            dispatch(updateFilters({0:{0:{id:'risk', type:'is', value: ['Missing effort']},andor: 'and'},andor: 'and'}, accountId));
                            dispatch(showFilters(true));
                            dispatch(setLimitViewTo({accountId,value:null}));
                            dispatch(defineClosedSortables([], accountId));
                            browserHistory.push('/tasks');
                        }}
                        count={nbWarnings.missingEffort}
                    >
                        <Tooltip title={intl.formatMessage({id:'warningPanel.action.seeTasks'})}>
                            <IconButton size="small" className="seeTaskBtn">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </WarningItem>
                }
                {nbWarnings.missingUserWithSkill > 0 &&
                    <WarningItem
                        type="missingUserWithSkill"
                        onClick={()=>{
                            dispatch(updateFilters({0:{0:{id:'risk', type:'is', value: ['No team member with required skill']},andor: 'and'},andor: 'and'}, accountId));
                            dispatch(showFilters(true));
                            dispatch(setLimitViewTo({accountId,value:null}));
                            browserHistory.push('/tasks');
                        }}
                        count={nbWarnings.missingUserWithSkill}
                    >
                        <Tooltip title={intl.formatMessage({id:'warningPanel.action.seeTasks'})}>
                            <IconButton size="small" className="seeTaskBtn">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </WarningItem>
                }
            </div>
            <Badge
                overlap="rectangular"
                badgeContent={nbWarnings.total}
                color="primary"
            >
                <WarningIcon className="WarningIcon" />
            </Badge>
        </div>
    );
};

export default React.memo(withCustomErrorBoundary(WarningPanel));