import React from 'react';
import classnames from 'clsx';
import useInternalization from '../../../utils/hooks/useInternalization';
import { Button } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { handleStopPropagation } from '../../../utils/utils';

const StopWorking = ({ showStopWorking, handleMarkAsDone, handleStopWorking }) => {
    const { t } = useInternalization();

    return (
        <div
            onClick={e => handleStopPropagation(e)}
            className={classnames('stopWorking', { show: showStopWorking })}
        >
            <Button
                size="small"
                variant="contained"
                className="stopBtn"
                color="primary"
                onClick={handleStopWorking}
            >
                {t('Stop working')}
            </Button>
            <Button
                size="small"
                variant="contained"
                className="doneBtn"
                color="secondary"
                onClick={handleMarkAsDone}
            >
                {t('Mark as done')}
            </Button>
        </div>
    );
};

export default withCustomErrorBoundary(StopWorking);