import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import _ from 'underscore';
import { withRouter } from 'react-router';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { getUserDateformat } from '../../../../utils/selectors/user';
import { getAccountPlanNbusers, getAccountUsers, getAccountIsLifetime, getAccountPlanSubscriptionCanceled, } from '../../../../utils/selectors/account';
import { getOnlyResourceUsers } from '../../../../utils/memberTypes';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
var PlanDetail = function (_a) {
    var endPlanAt = _a.endPlanAt;
    var t = useInternalization().t;
    var dateFormat = useSelector(getUserDateformat);
    var isLifetime = useSelector(getAccountIsLifetime);
    var planNbusers = useSelector(getAccountPlanNbusers);
    var accountUsers = useSelector(getAccountUsers);
    var IsSubscriptionCanceled = useSelector(getAccountPlanSubscriptionCanceled);
    if (!accountUsers)
        return null;
    var memberResources = getOnlyResourceUsers(accountUsers);
    var isPlanOver = !moment(endPlanAt).isSameOrAfter(moment(), 'day');
    var formattedEndPlanAt = moment(endPlanAt).format(dateFormat || 'DD/MM/YYYY');
    var progressLabel = function () {
        var usedLabel = t('settings.subscription.planDetail.used');
        var ofLabel = t('settings.subscription.planDetail.of', { total: planNbusers });
        return (React.createElement(React.Fragment, null,
            usedLabel,
            " ",
            ' ',
            React.createElement("strong", null,
                " ",
                _.size(memberResources),
                " "),
            ofLabel));
    };
    var progressValue = React.useMemo(function () {
        if (_.size(memberResources) > parseFloat(planNbusers)) {
            return 100;
        }
        return Math.floor((_.size(memberResources) / parseFloat(planNbusers)) * 100);
    }, [memberResources, planNbusers]);
    return (React.createElement("div", { className: "plan-detail" },
        React.createElement("span", { className: "plan-detail__title" }, t('settings.subscription.planDetail.title')),
        React.createElement("ul", null,
            !isLifetime && (React.createElement(React.Fragment, null,
                React.createElement("li", { className: "plan-detail__item" }, t('settings.subscription.planDetail.upto', { resources: planNbusers })),
                React.createElement("li", { className: "plan-detail__item" }, t("settings.subscription.planDetail.".concat(
                // eslint-disable-next-line no-nested-ternary
                isPlanOver ? 'expired' : !IsSubscriptionCanceled ? 'renewal' : 'expires'), { date: formattedEndPlanAt })))),
            isLifetime && (React.createElement(React.Fragment, null,
                React.createElement("li", { className: "plan-detail__item" }, t('settings.subscription.planDetail.lifetime')),
                React.createElement("li", { className: "plan-detail__item" }, t('settings.subscription.planDetail.lifetime2'))))),
        !isLifetime && (React.createElement("div", { className: "plan-detail__progress" },
            React.createElement("h5", null, progressLabel()),
            React.createElement(LinearProgress, { variant: "determinate", value: progressValue })))));
};
export default withRouter(withCustomErrorBoundary(PlanDetail));
