import { Button } from '@material-ui/core';
import React from 'react';
import { browserHistory } from 'react-router';

const OfferAccepted = ({email}) => <div className='OfferAccepted'>
    <p className='title'>
        <strong>
        Thank you for subscribing!
        </strong>
    </p>
    <p>
        We are thrilled to have you with us and we can’t wait to see you leveraging Planless for better results.
    </p>    
    <p>
        All that is left to do is to create your account with your <span className='email'>{email}</span> email address
    </p>
    <Button onClick={() => browserHistory.push('/create-account')}>
        create your account
    </Button>
</div>;

export default OfferAccepted; 