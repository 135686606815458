import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import { getGroupByTitle, handleStopPropagation } from '../../utils/utils';
import AddIcon from '@material-ui/icons/AddCircle';
import {toastr} from 'react-redux-toastr';
import _ from 'underscore';
import $ from 'jquery';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Sortable from 'sortablejs';
import classnames from 'clsx';
import Button from '@material-ui/core/Button';
import {canSee} from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import ClosedIcon from '@material-ui/icons/ChevronRight';
import OpenedIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditSkillModal from '../EditSkillModal/EditSkillModal';
import SkillLine from '../SkillLine/SkillLine';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import { getAccountId, getAccountSkills, getAccountSkillsGroups } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const GroupLine = ({groupId, title, addSkill, children}) =>{
    const intl = useIntl();
    const accountId = useSelector(getAccountId);

    const [stateTitle, setStateTitle] = useState(title);
    const [opened, setOpened] = useState(true);

    const deleteGroup = (e) => {
        handleStopPropagation(e);
        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this user group?'}), {
            onOk: () => {
                firebaseEvents.deleteSkillGroup(accountId, groupId, intl);
            },
            okText: intl.formatMessage({id:'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
        });
    };

    const changeGroupTitle = () => {
        firebaseEvents.addChallenge('addSkillGroup');
        firebaseEvents.changeSkillGroupTitle(accountId, groupId, stateTitle);
    };


    var deleteBtn = null,
        nbChildrens = 0,
        folderIcon = <ClosedIcon onClick={()=>setOpened(true)} />;

    if(opened){
        folderIcon = <OpenedIcon onClick={()=>setOpened(false)} />;
    }

    for(var i in children.props.children){
        nbChildrens += children.props.children[i].length;
    }

    if(nbChildrens === 0){
        deleteBtn = (
            <Button onClickCapture={(e)=>deleteGroup(e, groupId)} title={intl.formatMessage({id:'Delete'})}>
                <DeleteIcon />
            </Button>
        );
    }
    return (
        <div className={classnames('groupLine', {open: opened})} key={groupId} data-groupid={groupId}>
            <div>
                {folderIcon}
                <input value={stateTitle} className="skillName groupInput" onChange={(e)=>setStateTitle(e.target.value)} onBlur={changeGroupTitle} />
                <Button className='addIcon' onClick={()=>addSkill(groupId)} title={intl.formatMessage({id:'Add new skill'})}>
                    <AddIcon />
                </Button>
                {deleteBtn}
            </div>
            {children}
        </div>
    );
};


const Skills = ({children,router})=>{
    const intl = useIntl();
    const skills = useSelector(getAccountSkills);
    const skillGroups = useSelector(getAccountSkillsGroups);
    const userPermissions = useSelector(getUserPermissions);
    const accountId = useSelector(getAccountId);

    const [showAddNewSkill, setShowAddNewSkill] = useState(false);
    const [newSkillGroup, setNewSkillGroup] = useState(null);
    const [sortableList, setSortableList] = useState([]);

    const destroySortables = () => {
        sortableList.forEach(sortable => {
            if(sortable.el){
                sortable.destroy();
            }
        });
        setSortableList([]);
    };

    const createSortables = () => {
        let list = [];
        for (var i in Array.from(document.getElementsByClassName('group'))) {
            var sortable = Sortable.create(document.getElementsByClassName('group')[i], {
                group: 'groupId',
                filter: '.groupInput',
                preventOnFilter: false,
                onEnd: evt => {
                    $(evt.item).appendTo($(evt.from));

                    firebaseEvents.addChallenge('orderSkills');
                    if (evt.item.className.search('skillLine') !== -1) {
                        firebaseEvents.changeSkillGroup(
                            accountId,
                            evt.item.getAttribute('data-skillid'),
                            evt.to.getAttribute('data-groupid') || null,
                        );
                    } else {
                        firebaseEvents.changeSkillGroupParent(
                            accountId,
                            evt.item.getAttribute('data-groupid'),
                            evt.to.getAttribute('data-groupid') || null,
                        );
                    }
                },
            });

            list.push(sortable);
        }
        setSortableList(list);
    };

    useEffect(()=>{
        destroySortables();
        createSortables();

        return function cleanup(){
            destroySortables();
        };
    }, [skillGroups, skills, userPermissions]);

    const addSkill = (groupId) => {
        setShowAddNewSkill(true);
        setNewSkillGroup(groupId);
    };

    const newGroup = () => {
        firebaseEvents.addChallenge('addSkillGroup');
        firebaseEvents.createNewSkillGroup(accountId, intl);
    };

    const deleteSkill = (e, skillId) => {
        handleStopPropagation(e);

        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this skill?'}), {
            onOk: () => {
                firebaseEvents.addChallenge('deleteSkill');
                firebaseEvents.deleteSkill(
                    skillId, 
                    accountId, 
                    intl
                );
            },
            okText: intl.formatMessage({id:'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
            id: ('toastr-confirm-delete')
        });
    };

    const getSkillRows = (groupId) => {
        var list = [],
            skillsList = _.map(skills, (skill,skillId)=>{
                return {...skill, id: skillId};
            });
        skillsList = _.sortBy(skillsList, 'name');

        

        _.each(skillsList, (skill)=>{
            if(skill.group === groupId || (!groupId && !skill.group)){
                list.push(
                    <SkillLine data-skillid={skill.id} name={skill.name} id={skill.id} key={skill.id} paramsId={router.params.uid} deleteSkill={deleteSkill}/>
                );
            }
        });
        
        return list;
    };

    const getGroups = (parent) => {

        if(skillGroups) {

            var groupList = [];

            var skillGroupsList = getGroupByTitle(skillGroups, parent);

            for(var i in skillGroupsList){
                groupList.push(
                    <GroupLine 
                        key={skillGroupsList[i].id} 
                        groupId={skillGroupsList[i].id} 
                        title={skillGroupsList[i].title} 
                        addSkill={addSkill}
                    >
                        {getGroups(skillGroupsList[i].id)}
                    </GroupLine>
                );
            }

            return (
                <div className="group" data-groupid={parent} data-sidebar='preventClose'>
                    {groupList}
                    {getSkillRows(parent)}
                </div>
            );
        }
        return null;
    };



    if(!canSee(['admin', 'skills'], { data: { permissions: userPermissions }})) return <Unautorized />;

    return (
        <div className='Skills'>

            <h1>{intl.formatMessage({id:'Skills'})}</h1>
            
            <div data-sidebar='preventClose'>
                {!_.isEmpty(skills) &&
                <>
                    <Button variant="contained" className="topBtn" color="primary" onClick={()=>addSkill()}>{intl.formatMessage({id:'Add new skill'})}</Button>
                    <Button variant="contained" className="topBtn" color="primary" onClick={newGroup}>{intl.formatMessage({id:'Add new group'})}</Button>
                </>}

                {_.isEmpty(skills) &&
                <>
                    <NoTaskFound noSkillEmptyState={true}/>
                    <Button variant="contained" className="topBtn" color="primary" onClick={()=>addSkill()}>{intl.formatMessage({id:'Add new skill'})}</Button>
                </>    
                }
            </div>

            {getGroups()}

            {children}

            <EditSkillModal
                isOpen={showAddNewSkill}
                groupId={newSkillGroup}
                onSubmit={(skillId)=>{
                    browserHistory.push('/settings/skills/' + skillId);
                    setShowAddNewSkill(false);
                    setNewSkillGroup(null);
                }}
                onClose={()=>{
                    setShowAddNewSkill(false);
                    setNewSkillGroup(null);
                }}
            />

        </div>
    );
    
};

export default withRouter(withCustomErrorBoundary(Skills));