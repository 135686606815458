
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import classnames from 'clsx';
import { browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import CalendarConfig from '../CalendarConfig/CalendarConfig';
import { getAccountsDataConnectCalendars, setFirstCalendarConnected } from '../../utils/firebaseEvents';
import { showLoader, hideLoader } from '../../views/App/AppActions';
import { 
    mapConnectAccounts,
    disconectCalendar,
    connectCalendar,
    deleteCalendarAccount,
    resyncCalendarAccount,
    connectAccount,
} from '../../utils/calendarIntegration';
import { tracking } from '../../utils/utils';
import CalendarItem from './CalendarItem';
import { getAccountId } from '../../utils/selectors/account';
import { getUserCalendarConnected, getUserId } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ConnectCalendar  = ({ calendarScope }) => {
    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const firstCalendarConnected = useSelector(getUserCalendarConnected);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const [connectCalendars, setConnectCalendars] = useState([]); 
    const [dialogState, setDialogState] = useState({
        calendarId: null,
        calendar: null,
        accountRef: null,
        calendarRef: null,
        isOpen: false,
        connectedAccountName: null,
        connectedAccountId: null,
    }); 
    const [isLoading, setIsLoading] = useState(true); 
    const [callbackReference, setCallbackReference] = useState(null); 
    const intl = useIntl();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(userId && accountId) {
            if(!callbackReference) {
                const firebaseConnection = getAccountsDataConnectCalendars({
                    userId: userId,
                    accountId: accountId
                }, (snap) => { 
                    if(snap && snap.val()) {
                        setConnectCalendars(mapConnectAccounts(snap.val()).filter(ca => ca.connected ));
                    }
                    setIsLoading(false);
                });
                setCallbackReference(firebaseConnection);
            }
        }
    }, [userId, accountId]);

    useEffect(() => {
        return () => {
            if(callbackReference) {
                callbackReference.off();
            }
        };
    }, [callbackReference]);

    const t = (id, values) =>  {
        return intl.formatMessage({id}, values);
    };
   
    const handleCalendarConnection = async (value, calendarId, email) => {
        try{
            if(value) {
                dispatch(showLoader());
                await connectCalendar({
                    userId: userId,
                    accountId: accountId,
                    calendarId,
                    email
                });
                tracking('Connected Calendar');

                if(!firstCalendarConnected) {
                    toastr.success(t('Calendar Connected!'), '', {
                        component: (
                            <a
                                href="#"
                                onClick={handleGoToCalendar}
                            >
                                {t('GO TO CALENDAR')}
                            </a>
                        ),
                    });
                    setFirstCalendarConnected(userId);
                }

            } else {
                await disconectCalendar({
                    userId: userId,
                    accountId: accountId,
                    calendarId,
                    email
                });
            }
        }catch(err){
            toastr.error(t('connect-calendar-error'));
        } finally {
            dispatch(hideLoader());
        }
    }; 

    const handleDeleteAccount = async (email) => {
        try{
            dispatch(showLoader());
            await deleteCalendarAccount({
                userId: userId,
                accountId: accountId,
                email 
            });
        }catch(err){
            toastr.error(t('Something went wrong'));
        } finally {
            browserHistory.push('/settings/integrations');
            dispatch(hideLoader());
        }
    };

    const handleResyncAccount = async (email) => {
        try{
            dispatch(showLoader());
            await resyncCalendarAccount({
                userId: userId,
                accountId: accountId,
                email 
            });
        }catch(err){
            toastr.error(t('Something went wrong'));
        } finally {
            dispatch(hideLoader());
        }
    };

    const openDialog = (calendarId, calendar, email) => {
        setDialogState({
            calendarId, 
            calendar, 
            isOpen: true,
            connectedAccountName: email,
            connectedAccountId: email,
        });
    };

    const closeDialog = () => {
        setDialogState({
            isOpen: false
        });
    };

    const handleGoToCalendar = () => {
        tracking('First calendar redirect');
        browserHistory.push('/calendar');
    };

    if (!isLoading && !connectCalendars.length) {
        browserHistory.push('/settings/integrations/');
    }

    return (
        <div className="calendars">
            <div className={classnames('settingsInnerContent CalendarList', {empty: (!isLoading && !connectCalendars.length)})}>
                
                {isLoading && <CircularProgress  />}

                {(!isLoading && !!connectCalendars.length) && connectCalendars.map(({email, calendars, scope}, index) => {

                    if(calendarScope !== scope) return null;

                    return <CalendarItem
                        key={index} 
                        calendarScope={calendarScope}
                        scope={scope}
                        email={email}
                        calendars={calendars} 
                        handleResyncAccount={handleResyncAccount} 
                        handleDeleteAccount={handleDeleteAccount} 
                        handleCalendarConnection={handleCalendarConnection} 
                        connectAccount={connectAccount}
                        openDialog={openDialog}
                    />;
                })}
            </div>
            { dialogState.isOpen &&  
                <CalendarConfig 
                    handleClose={closeDialog}
                    isOpen={dialogState.isOpen}    
                    calendar={dialogState.calendar}
                    calendarId={dialogState.calendarId}
                    calendarScope={calendarScope}
                    connectedAccountName={dialogState.connectedAccountName}
                    connectedAccountId={dialogState.connectedAccountId}    
                />
            }
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(ConnectCalendar));
