import React, { useState, useEffect, memo } from 'react';
import { memberResourcersTypes, MEMBER_TYPES } from '../../utils/memberTypes';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import SkillSlider from '../../components/SkillSlider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { handleStopPropagation, validateEmail } from '../../utils/utils';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import { toastr } from 'react-redux-toastr';
import { sendMemberInvite, createNewMember, createNewSkillIsolated } from '../../utils/firebaseEvents';
import { canSee } from '../../utils/userFunctions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import MemberTypeDropdown from '../../components/MemberTypeDropdown/MemberTypeDropdown';
import _ from 'underscore';
import { getAccountChargebeeCustomerId, getAccountId, getAccountName, getAccountPlanNbusers, getAccountSkills, getAccountUsers } from '../../utils/selectors/account';
import { getUserId, getUserPermissions } from '../../utils/selectors/user';
import { toggleUpgradeSubscriptionModalShow } from '../../utils/actions/upgradeSubscriptionModal';

const CreateNewMemberModal = ({ onSubmit, isOpen, displayName = '', defaultEmail = null, onClose, groupId = null, disableCreateTypes = []}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [newDisplayName, setDisplayName] = useState('');
    const [newMemberType, setNewMemberType] = useState(MEMBER_TYPES.userAndResource);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [email, setEmail] = useState('');

    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const accountTeamMembers = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);
    const accountSkills = useSelector(getAccountSkills);
    const accountName = useSelector(getAccountName);
    const currentUserId = useSelector(getUserId);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const userPermissions = useSelector(getUserPermissions);
    const memberResources = getOnlyResourceUsers(accountTeamMembers);
    
    useEffect(() => {
        setSelectedSkills([]);
        setDisplayName(displayName);
        setEmail(defaultEmail ? defaultEmail : '');
    }, [isOpen, displayName, defaultEmail]);

    const handleCreateSkill = React.useCallback((searchValue, selectedSkills) => {
        const newSkillKey = createNewSkillIsolated(searchValue, null);

        setSelectedSkills([...selectedSkills, { id: newSkillKey, name: searchValue }]);
    }, [selectedSkills]);

    useEffect(() => {
        setDisplayName(displayName);
    }, [isOpen, displayName]);

    const handleDisplayName = React.useCallback(({ target: { value } }) => setDisplayName(value), []);

    const handleEmail = React.useCallback(({ target: { value } }) => setEmail(value), []);

    const handleSelectMemberType = React.useCallback((memberType) => setNewMemberType(memberType), []);

    const handleAutoComplete = React.useCallback((_, values) => {
        const lastValue = values[values.length - 1];
       
        if (!values.length) {
            setSelectedSkills([]);
            return;
        }
       
        if (lastValue.id) {
            setSelectedSkills(values.map(el => ({skillLvl: 5, ...el })));
            return;
        }

        if (lastValue.value === 'blocked') {
            return;
        }

        handleCreateSkill(lastValue.inputValue, selectedSkills);
    }, [selectedSkills]);


    const updateSlider = React.useCallback((e, value, skillId) => {
        handleStopPropagation(e);
        setSelectedSkills(selectedSkills.map(el => el.id === skillId ? ({ ...el, skillLvl: value }) :  el));
    }, [selectedSkills]);

    const updateSliderCommit = React.useCallback((e, value, skillId) => {
        handleStopPropagation(e);

        if (value === 0) {
            setSelectedSkills(selectedSkills.filter(el => el.id !== skillId));
            return;
        }

        setSelectedSkills(selectedSkills.map(el => el.id === skillId ? ({ ...el, skillLvl: value }) :  el));
    }, [selectedSkills]);

    const handleClose = React.useCallback((e) => {
        handleStopPropagation(e);
        onClose();
    }, [onClose]); 

    const handleSave = React.useCallback(async (e) => {
        handleStopPropagation(e);

        if(!newDisplayName || newDisplayName.trim() === ''){
            toastr.error('Please, provide a name for the new member');
            return;
        }

        if(!selectedSkills.length && newMemberType !== MEMBER_TYPES.userOnly){
            toastr.error('At least one skill is required for resources');
            return;
        }
        if(email && email !== '' && !validateEmail(email) && newMemberType !== MEMBER_TYPES.resourceOnly){
            toastr.error('In order to invite a member the email has to be valid');
            return;
        }

        let validEmail = null;
        if(email && email !== '' && validateEmail(email)) {
            validEmail = email;
        }

        if(newMemberType === MEMBER_TYPES.userOnly) {
            const newMemberUserOnly = await createNewMember({ displayName: newDisplayName, email: validEmail, accountId, memberType: newMemberType });
            if(validEmail) {
                sendMemberInvite(accountId, newMemberUserOnly, {}, validEmail, accountTeamMembers[currentUserId].displayName, accountName, null, false);
            }
            
            toastr.success('Member successfully created!');
            onSubmit(newMemberUserOnly,{groupId:null});
            return;
        }
        
        if (parseInt(planNbusers) > _.size(memberResources)) {
            const skills = selectedSkills.reduce((acc, el) => ({...acc,[el.id]: el.skillLvl || 5 }),{}); 
            const newMemberKey = await createNewMember({
                skills,
                groupId,
                displayName: newDisplayName,
                email: validEmail,
                accountId,
                memberType: newMemberType
            });

            if(validEmail) {
                sendMemberInvite(accountId, newMemberKey, {}, validEmail, accountTeamMembers[currentUserId].displayName, accountName, null, false);
            }
            
            toastr.success('Member successfully created!');
            onSubmit(newMemberKey,{groupId:null});
            return;
        }

        if (_.size(memberResources) + 1 > parseInt(planNbusers) && canSee(['admin', 'subscription'], { data: { permissions: userPermissions } })) {
            dispatch(toggleUpgradeSubscriptionModalShow({ show: true }));
        }
    }, [
        currentUserId,
        newDisplayName, 
        selectedSkills, 
        email, 
        newMemberType, 
        accountId, 
        accountTeamMembers, 
        accountName, 
        planNbusers, 
        userPermissions, 
        chargebeeCustomerId, 
        selectedSkills,
    ]);
    
    const skills = !accountSkills ? [] : Object.keys(accountSkills).map(el => ({ id: el,...accountSkills[el] })).filter(el => !selectedSkills.find(a => a.id === el.id));

    return <Dialog maxWidth={'md'} className="CreateNewMemberModal" onClick={handleStopPropagation} onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen} data-sidebar='preventClose'>
        <MuiDialogTitle className="title" disableTypography onClick={handleStopPropagation}>
            <Typography variant="h6"> {intl.formatMessage({ id: 'Create Member' })}</Typography>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers onClick={handleStopPropagation}>
            <FormControl fullWidth className="skill-name-container" component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({ id: 'Name' })} </FormLabel>
                <Input
                    value={newDisplayName}
                    onChange={handleDisplayName}
                    onClick={handleStopPropagation}
                    id="displayName"
                    name="displayName"
                    label={intl.formatMessage({ id: 'Name' })}
                    placeholder={intl.formatMessage({ id: 'Name' })}
                    type="text"
                    autoFocus
                />
            </FormControl>
            <div className="member-type-container">
                <small>Member Type</small>
                <MemberTypeDropdown 
                    actionMode="create" 
                    fromViewMode="CreateNewMemberModal" 
                    onSelect={handleSelectMemberType}
                    defaultMemberType={newMemberType}
                />
            </div>
            {newMemberType !== MEMBER_TYPES.resourceOnly &&  (
                <FormControl fullWidth className="skill-name-container Email-input" component="fieldset">
                    <FormLabel component="legend">{intl.formatMessage({ id: 'Email' })} </FormLabel>
                    <Input
                        value={email}
                        onClick={handleStopPropagation}
                        onChange={handleEmail}
                        id="email"
                        name="email"
                        label={intl.formatMessage({ id: 'Email' })}
                        placeholder={intl.formatMessage({ id: 'Email (if you want to invite this user)' })}
                        type="text"
                    />
                </FormControl>
            )}
            {newMemberType !== MEMBER_TYPES.userOnly && (
                <FormControl fullWidth className="buttons-container remove-padding" component="fieldset" onClick={handleStopPropagation}>
                    <Autocomplete
                        
                        fullWidth
                        value={selectedSkills}
                        onChange={handleAutoComplete}
                        filterSelectedOptions={true}
                        multiple
                        className="User_Selector autocomplete owners"
                        id="users-To"
                        options={skills}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const props = getTagProps({ index });

                                return (
                                    <Chip
                                        size='small'
                                        variant="outlined"
                                        label={option.name}
                                        {...props}
                                    />
                                );
                            })
                        }
                        filterOptions={(options, { inputValue }) => {
                            const valid = options.filter((o) => {
                                return !inputValue || o.name?.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1;
                            });

                            if (valid.length) {
                                return valid;
                            }

                            if(!String(inputValue).trim()) {
                                return [{
                                    value: 'blocked',
                                    inputValue: inputValue,
                                    name: 'Type to search or create a new skill...',
                                }];
                            }

                            return [{
                                value: 'new',
                                inputValue: inputValue,
                                name: 'Create new Skill "' + inputValue + '"',
                            }];
                        }}
                        getOptionLabel={(option) => <div key={option.name} className="optionUser">{option.name} </div>}
                        renderInput={(params) => {
                            return <TextField
                                onClick={e => e.preventDefault()}
                                placeholder={!selectedSkills.length ? 'Type to search or create a new skill...' : ''}
                                {...params}
                                InputLabelProps={{shrink:true}}
                                label="Resource Skills"
                                variant="outlined"
                            />;
                        }}
                    />
                </FormControl>
            )}
            {!!selectedSkills.length && memberResourcersTypes.includes(newMemberType) && <span className="info">Define below the resource’s level of expertise on these skills:</span>}
            {
                memberResourcersTypes.includes(newMemberType) && selectedSkills.map(el => <div className="User_Skill_Container">
                    <div className="skillName"> {el.name} </div>
                    <div className="skill_slider">
                        <SkillSlider
                            onChange={(e, value) => updateSlider(e, value, el.id)}
                            onChangeCommitted={(e, value) => updateSliderCommit(e, value, el.id)}
                            value={el.skillLvl >= 0 ? el.skillLvl : 5}
                        />
                    </div>
                </div>)
            } 
        </MuiDialogContent>
        <MuiDialogActions className='actions'>
            <Button
                className="cancel-btn"
                onClick={handleClose}
                variant="contained"
            >
                {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
            <Button
                disabled={disableCreateTypes.includes(newMemberType)}
                className="submit-btn"
                variant="contained"
                color="primary"
                onClick={handleSave}
            >
                {intl.formatMessage({ id: 'Save Changes' })}
            </Button>
        </MuiDialogActions>
    </Dialog >;
};


CreateNewMemberModal.propTypes = {
    isOpen: Proptypes.bool.isRequired,
};

export default memo(withCustomErrorBoundary(CreateNewMemberModal));