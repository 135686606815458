import { getState } from '../../../configureStore';
import { db } from '../../firebaseEvents';
import { getAccountId } from '../../selectors/account';
export var updateMemberType = function (userId, memberType) {
    var _a;
    var accountId = getAccountId(getState());
    var updates = (_a = {}, _a["/accounts/".concat(accountId, "/users/").concat(userId, "/memberType")] = memberType, _a);
    db.update(undefined, updates);
};
export default updateMemberType;
