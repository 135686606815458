import React from 'react';
import { Link, withRouter } from 'react-router';
import classnames from 'clsx';
import Badge from '@material-ui/core/Badge';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TasksIcon from '@material-ui/icons/CheckCircle';
import CalendarIcon from '@material-ui/icons/Event';
import WorkloadIcon from '@material-ui/icons/Speed';
import InsightsIcon from '@material-ui/icons/Equalizer';
import ChatIcon from '@material-ui/icons/Forum';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getUserNbUnreadMessages } from '../../utils';
import { getAccountChat } from '../../../../utils/selectors/account';
import { getUserId, getUserIsViewer } from '../../../../utils/selectors/user';

const NavLinks = ({ router: { params: { uid } }, location: { pathname }, isSticky, handleCloseHeader }) => {
    const intl = useIntl();
    const accountChat = useSelector(getAccountChat);
    const userId = useSelector(getUserId);
    const viewer = useSelector(getUserIsViewer);
    const nbChatNotifications = React.useMemo(() => getUserNbUnreadMessages({ userId, chatData: accountChat }), [userId, accountChat]);

    const isRoute = React.useCallback((route) => {
        const regex = new RegExp('^' + route);
        return (regex.test(pathname));
    }, [pathname]);

    const taskLink = React.useMemo(() => {
        return uid &&
            !isRoute('/settings') &&
            !isRoute('/chat') &&
            !isRoute('/insights') &&
            !isRoute('/dashboard/userEvents') &&
            !isRoute('/calendar/userEvents') ? uid : false;
    }, [uid, isRoute]);

    const handleCloseSidebar = React.useCallback(() => {
        if(!isSticky && window.innerWidth < 768) handleCloseHeader();
    }, [handleCloseHeader]);

    return (
        <>
            <Link
                key="dashboard"
                to={taskLink ? `/dashboard/${taskLink}` : '/dashboard'}
                onClick={handleCloseSidebar}
                className={classnames('dashboardMenu', { active: Boolean(isRoute('/dashboard')) })}
            >
                <DashboardIcon />
                <span>{intl.formatMessage({id:'Dashboard'})}</span>
            </Link>
            <Link
                key="tasks"
                to={taskLink ? `/tasks/${taskLink}` : '/tasks'}
                onClick={handleCloseSidebar}
                className={classnames('tasksMenu', { active: isRoute('/tasks') })}
            >
                <TasksIcon />
                <span>{intl.formatMessage({id:'Tasks'})}</span>
            </Link>
            <Link
                key="calendar"
                to={taskLink ? `/calendar/${taskLink}` : '/calendar'}
                onClick={handleCloseSidebar}
                className={classnames('calendarMenu', { active: isRoute('/calendar') })}
            >
                <CalendarIcon />
                <span>{intl.formatMessage({id:'Calendar'})}</span>
            </Link>
            <Link
                key="workload"
                to={taskLink ? `/workload/${taskLink}` : '/workload'}
                onClick={handleCloseSidebar}
                className={classnames('workloadMenu', { active: isRoute('/workload') })}
            >
                <WorkloadIcon />
                <span>{intl.formatMessage({id:'Workload'})}</span>
            </Link>

            {!viewer && <>
                <Link
                    key="insights"
                    to="/insights/root/overview"
                    onClick={handleCloseSidebar}
                    className={classnames('insightsMenu', { active: isRoute('/insights') })}
                >
                    <InsightsIcon />
                    <span>{intl.formatMessage({id:'Insights'})}</span>
                </Link>
                <Link
                    key="chat"
                    to="/chat"
                    onClick={handleCloseSidebar}
                    className={classnames('chatMenu', { active: isRoute('/chat') })}
                >
                    <ChatIcon />
                    <Badge
                        overlap="rectangular"
                        color="secondary"
                        badgeContent={nbChatNotifications === 20 ? '20+' : `${nbChatNotifications}`}
                        invisible={!nbChatNotifications}>
                        <span>{intl.formatMessage({id:'Chat'})}</span>
                    </Badge>
                </Link>
            </>}
        </>
    );
};



export default React.memo(withRouter(NavLinks));