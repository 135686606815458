import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getLoadersDaysOff, getLoadersSkills, getLoadersTasks, getLoadersUsers, getQuickSearch } from '../../utils/selectors/app';
import { quickSearch } from '../App/AppActions';
import TextField from '@material-ui/core/TextField';
import useDebounce from '../../utils/hooks/useDebounce';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const QuickSearch = () => {
    const dispatch = useDispatch();
    const quickSearchState = useSelector(getQuickSearch);
    const [searchValue, setSearchValue] = useState(quickSearchState || '');
    const loaderTasks = useSelector(getLoadersTasks);
    const loaderSkills = useSelector(getLoadersSkills);
    const loaderUsers = useSelector(getLoadersUsers);
    const loaderDaysOff = useSelector(getLoadersDaysOff);

    const changeQuickSearch = useCallback((val) => setSearchValue(val), []);

    useDebounce(()=> {
        dispatch(quickSearch(searchValue));
    }, 750, [searchValue]);

    return (
        <TextField 
            disabled={loaderTasks || loaderSkills || loaderUsers || loaderDaysOff}
            className="quickSearch"
            variant="outlined"
            label="Quick search..."
            size="small"
            value={searchValue}
            onChange={(e)=>changeQuickSearch(e.target.value)}
            InputProps={{
                endAdornment: (searchValue !== '')?(
                    <InputAdornment position="end"><ClearIcon onClick={()=>changeQuickSearch('')} /></InputAdornment>
                ):null,
            }}
        />
    );
};

export default memo(withCustomErrorBoundary(QuickSearch));

