import React, { useEffect } from 'react';
import { Info } from '@material-ui/icons';
import Tooltip from 'rc-tooltip';
import { FormControl, FormControlLabel, FormLabel, Input, Radio } from '@material-ui/core';

import useInternalization from '../../../../../utils/hooks/useInternalization';
import { setOriginalEstimateValue } from '../../../../../utils/controllers/syncApp/jira';
import { withCustomErrorBoundary } from '../../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const OriginalEstimate = ({ optionSelected, originalEstimation, handleSelectOption, syncAppId }) => {
    const { t } = useInternalization();
    const isChecked = optionSelected === 0;
    const [rangeValue, setRangeValue] = React.useState(20);
    
    useEffect(() => {
        if(originalEstimation) {
            setRangeValue(parseInt(originalEstimation));
        }
    }, [originalEstimation]);

    const handleUpdateRange = (e) => {
        const rangeValue = parseInt(e.target.value) || 0;
        setOriginalEstimateValue({ 
            syncAppId, 
            value: rangeValue
        });
        setRangeValue(rangeValue);
    };

    return (
        <div className="OriginalEstimate">
            <FormControlLabel
                checked={isChecked}
                value={0} 
                onChange={handleSelectOption}
                control={<Radio size="small" />} 
                label={t('Original estimate')} 
            />

            {isChecked && (
                <div>
                    <FormControl className='range'>
                        <FormLabel component='legend'>
                            {t('Apply a range of: ')}
                            <Tooltip
                                placement="top" 
                                overlay={t('If you define 20%, a 10h estimation will become a range of 8h-12h.')}
                            >
                                <Info />
                            </Tooltip>
                        </FormLabel>
                        <Input
                            onChange={handleUpdateRange}
                            className="range__number"
                            value={rangeValue}
                            id="range"
                            name="range"
                            type="number"
                        />
                        <span>%</span>
                    </FormControl>
                </div>
            )}
        </div>
    );
};

export default withCustomErrorBoundary(OriginalEstimate);