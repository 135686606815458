import React, { useState } from 'react';
import { Button, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { createQuote } from '../../utils/databaseQuerys/quote';

const Form = () => {
    const [data, setData] = useState({});
  
    const handleOnChange = ({target: { value, id }} ) => {
        setData({
            ...data,
            [id]: value
        });
    };

    const onSubmit = async () => {
        await createQuote({ quote: data, id: data.id });
    };

    return <div className='form'>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                    Customer personal Code
            </InputLabel>
            <Input
                id="id"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                    Plan Name
            </InputLabel>
            <Input
                id="planName"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Chargee Bee Plan Id
            </InputLabel>
            <Input
                id="changeBeePlanId"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Cuppon Code
            </InputLabel>
            <Input
                id="cupponCode"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Currency
            </InputLabel>
            <Input
                id="currency"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Name
            </InputLabel>
            <Input
                id="name"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
            Email
            </InputLabel>
            <Input
                id="email"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
            Company Name
            </InputLabel>
            <Input
                id="companyName"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
            Number of Users
            </InputLabel>
            <Input
                id="nbUsers"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
            Original Price
            </InputLabel>
            <Input
                id="originalPrice"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Final Price
            </InputLabel>
            <Input
                id="finalPrice"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Discount
            </InputLabel>
            <Input
                id="discount"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Trial Time
            </InputLabel>
            <Input
                id="trialTime"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Submit Today Price
            </InputLabel>
            <Input
                id="dueTodayPrice"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Discount Label
            </InputLabel>
            <Input
                id="discountLabel"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Year(if not by year don't write here)
            </InputLabel>
            <Input
                id="years"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                Month(if not by months don't write here)
            </InputLabel>
            <Input
                id="months"
                onChange={handleOnChange}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="age-native-simple">
                PlanessPlans(team or business)
            </InputLabel>
            <Input
                id="plan"
                onChange={handleOnChange}
            />
        </FormControl>
        <Button onClick={onSubmit}>
                Submit
        </Button>
    </div>;
};
export default Form;
