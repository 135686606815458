export const videoSteps = [
    {
        name: 'Introduction to Planless',
        time: '02:39',
        videoRef: '637494533',
    },
    {
        name: 'Basics',
        time: '02:44',
        videoRef: '637496311',
    },
    {
        name: 'First steps',
        time: '02:38',
        videoRef: '637511358',
    },
    {
        name: 'Setting up',
        videoRef: '637504151',
        time: '02:18',
    },
    {
        name: 'Staring to plan work',
        videoRef: '637525984',
        time: '03:54',
    },
    {
        name: 'Making progress',
        videoRef: '637529715',
        time: '02:33',
    },
    {
        name: 'Next steps',
        videoRef: '637534877',
        time: '01:32',
    },
];
