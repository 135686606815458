import React from 'react';
import { useIntl } from 'react-intl';
import StepsHelp from './StepsHelp';
import StepsLearnMore from './StepsLearnMore';
import StepsFeedback from './StepsFeedback';
import StepsVideos from './StepsVideos';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const FirstSteps = ()=>{
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    return <div className="FirstSteps">
        <p className='steps-mobileWarn'>{t('first.steps-mobile-warn1')} <br></br>{t('first.steps-mobile-warn2')}</p>
        <StepsVideos />
        <div className='FirstSteps__footer'>
            <StepsHelp />
            <StepsLearnMore />
            <StepsFeedback />
        </div>
    </div>;
};

export default withCustomErrorBoundary(FirstSteps);