import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import classnames from 'clsx';
import { useSkillList } from '../hooks/useSkillList';
import useInternalization from '../../../utils/hooks/useInternalization';
import { GroupItem } from './GroupItem';
import { SkillItem } from './SkillItem';

export function SkillList({ searchValue, isMultiSelect, onSelectSkill, checkSkillIsSelected }) {
    const { t } = useInternalization();
    const { skillList, isAccountSkillsEmpty } = useSkillList({ searchValue });
    const rootSkillGroupIdList = useMemo(() => Object.keys(_.omit(skillList, 'groupLessSkills')), [skillList]);
    const groupLessSkillList = useMemo(() => skillList.groupLessSkills || [], [skillList]);
    const [groupsOpen, setGroupOpen] = useState([]);

    useEffect(() => {
        setGroupOpen(rootSkillGroupIdList);
    }, [rootSkillGroupIdList]);

    const checkGroupIsOpen = (id) => groupsOpen.includes(id);

    const onOpenGroup = React.useCallback((e) => {
        e?.stopPropagation();
        const id = e.target.tagName !== 'path' ? e.target.id : e.target.parentElement.id;
        setGroupOpen(prevState => checkGroupIsOpen(id) ? prevState.filter(el => el !== id) : [...prevState, id]);
    }, [groupsOpen]);

    return (
        <>
            {!_.isEmpty(skillList) ? (
                <div className={classnames('skill-list-container', { limited: isMultiSelect })}>
                    {rootSkillGroupIdList.map(groupId =>
                        <GroupItem {...{
                            group: skillList[groupId],
                            checkGroupIsOpen,
                            onOpenGroup,
                            onSelectSkill,
                            isMultiSelect,
                            isParentExpanded: checkGroupIsOpen(groupId),
                            checkSkillIsSelected,
                        }} />
                    )}
                    {groupLessSkillList.skills.map(skill =>
                        <SkillItem {...{
                            skill,
                            onSelectSkill,
                            isMultiSelect,
                            isSkillSelected: checkSkillIsSelected(skill.id),
                            isShowing: true,
                        }} />
                    )}
                </div>
            ) : (
                <div className="Skill-not-found-container">
                    <div className="Skill-not-found">
                        <img src="https://planless.sirv.com/App/emptyStates/skills.png" />
                        {isAccountSkillsEmpty ? (
                            <>
                                <span>{t('No skill found')}</span>
                                <span>{t('select.skill.empty')}</span>
                            </>
                        ) : (
                            <h3>{t('No skill found')}</h3>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}