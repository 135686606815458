import React from 'react';
import Typography from '@material-ui/core/Typography';
import {canSee} from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import CurrencySettings from '../CurrencySettings/CurrencySettings';
import { useSelector } from 'react-redux';
import ExpensesManager from '../ExpensesManager/ExpensesManager';
import { useIntl } from 'react-intl';
import { permissionsByPlan } from '../../utils/constants';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const FinancialSettings = () => {
    const intl = useIntl();
    const user = useSelector((state) => state.app?.user);
    const accountPlan = useSelector((state) => state.app?.account.settings.plan);
    
    if(!permissionsByPlan.financials.includes(accountPlan) || !user || !canSee(['admin'], user)) return <Unautorized />;

    return (
        <div className='FinancialSettings'>
            <Typography variant="h1" gutterBottom>
                {intl.formatMessage({id: 'financial-settings'})}
            </Typography>

            <CurrencySettings />

            <Typography className='expenses-types' variant="h1" gutterBottom>
                {intl.formatMessage({id: 'expenses-types'})}
            </Typography>

            <ExpensesManager />
        </div>
    );
};

export default withCustomErrorBoundary(FinancialSettings);
