import React from 'react';
import SettingsEffort from './SettingsEffort';
import SlackConnections from '../../../SlackConnections/SlackConnections';
import CalendarIntegration from '../../../CalendarIntegration/CalendarIntegration';
import AcademyBanner from '../AcademyBanner/AcademyBanner';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const SyncSettings = ({ toolName, syncAppId }) => {

    return (
        <div className='SyncSettings'>
            {
                toolName === 'slack' ? (
                    <SlackConnections />
                ) : toolName === 'google-calendar' ? (
                    <CalendarIntegration calendarScope={'google'}/>
                ) : toolName === 'microsoft-calendar' ? (
                    <CalendarIntegration calendarScope={'microsoft'}/> 
                ) : (
                    <>
                        <SettingsEffort toolName={toolName} syncAppId={syncAppId}/>
                        <AcademyBanner toolName={toolName}/>
                    </> 
                )   
            }
        </div>
    );
};

export default withCustomErrorBoundary(SyncSettings);
