import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { toastr } from 'react-redux-toastr';
import { getAccountTasks, getAccountUsers, getSlackConnections } from '../../utils/selectors/account';
import { getAssignedEntries, getHierarchyEntries, getTaskPath } from './utils';
import { useSelector } from 'react-redux';
import { VariableSizeList } from 'react-window';
import { useIntl } from 'react-intl';
import { SLACK_REDIRECT_URL } from '../../utils/constants';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import { CardContent, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const SlackConnections = () => {
    const intl = useIntl();
    const [connectionsList,setConnectionsList] = useState([]);
    const slackConnections = useSelector(getSlackConnections);
    const users = useSelector(getAccountUsers);
    const tasks = useSelector(getAccountTasks);

    const removeSlackConnection = (connectionId) => {
        toastr.confirm(intl.formatMessage({id: 'Are you sure you want to remove this slack connection?'}), {
            onOk: () => {
                firebaseEvents.removeSlackConnection(connectionId);
            },
            okText: intl.formatMessage({id: 'DELETE'}),
            cancelText: intl.formatMessage({id: 'CANCEL'})
        });
    };

    const handleChangeMadebyFilter = (event, values, connectionId) => {
        const list = values.map(val=>val.id);

        firebaseEvents.changeSlackConnectionMadebyFilter(connectionId,list);
    };

    const handleChangeHierarchyFilter = (event, values, connectionId) => {
        const list = values.map(val=>val.id);

        firebaseEvents.changeSlackConnectionHierarchyFilter(connectionId,list);
    };

    const handleAddSlack = () => {
        window.location.replace(`https://slack.com/oauth/v2/authorize?client_id=${process.env.SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write,channels:join,groups:write&redirect_uri=${SLACK_REDIRECT_URL}`);
    };

    useEffect(() => {
        if(slackConnections && _.isEmpty(connectionsList)) {
            const connectionsList = [];

            Object.keys(slackConnections || {}).forEach(connectionId => {
                const hierarchyDefaultValues = [];
                if(slackConnections[connectionId].hierarchy){
                    _.uniq(slackConnections[connectionId].hierarchy).forEach((id)=>{
                        if(tasks[id]){
                            hierarchyDefaultValues.push({id, title: getTaskPath(id, tasks)});
                        }
                    });
                }

                const madebyDefaultValues = [];
                if(slackConnections[connectionId].madeBy){
                    _.uniq(slackConnections[connectionId].madeBy).forEach((id)=>{
                        if(users[id]){
                            madebyDefaultValues.push({id, title: users[id]?.displayName});
                        }
                    });
                }

                connectionsList.push({connectionId, hierarchyDefaultValues, madebyDefaultValues});
            });
            setConnectionsList(connectionsList);
        }
    }, [slackConnections, connectionsList]);

    return <>
        {(!connectionsList.length && !slackConnections) ? 
            <CardContent><CircularProgress  /></CardContent> 
            : 
            <div className='SlackConnections'>
                {connectionsList.map(({connectionId, hierarchyDefaultValues, madebyDefaultValues}, index) => {
                    return <ExpansionPanel className="slackConnection" key={`${connectionId}-${index}`} data-connection-id={connectionId} elevation={0}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <img src="https://planless.sirv.com/App/integrations/slack.png" alt="Slack logo" width='50px' height='50px'/>
                            {intl.formatMessage({id: 'slack.connection.title'}, {
                                teamName: <b key="teamName">{slackConnections[connectionId]?.team?.name}</b>, 
                                channelName: <b key="channelName">{slackConnections[connectionId]?.incoming_webhook?.channel}</b>
                            })}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <h4>{intl.formatMessage({id: 'Notify on following changes'})}:</h4>
                            <div>
                                <FormControlLabel control={
                                    <Switch 
                                        size="small" 
                                        checked={(slackConnections && slackConnections[connectionId] && slackConnections[connectionId].tasksAdded)} 
                                    />
                                } label="Tasks added" onChange={(e)=>this.switchConnectionVar(e, 'tasksAdded')} />
                                <FormControlLabel control={
                                    <Switch 
                                        size="small" 
                                        checked={(slackConnections && slackConnections[connectionId] && slackConnections[connectionId].tasksChanged)} 
                                    />
                                } label="Tasks changed" onChange={(e)=>this.switchConnectionVar(e, 'tasksChanged')} />
                                <FormControlLabel control={
                                    <Switch 
                                        size="small" 
                                        checked={(slackConnections && slackConnections[connectionId] && slackConnections[connectionId].tasksDeleted)} 
                                    />
                                } label="Tasks deleted" onChange={(e)=>this.switchConnectionVar(e, 'tasksDeleted')} />
                            </div>
                            <h4>{intl.formatMessage({id: 'Filters'})}:</h4>
                            <div>
                                <Autocomplete
                                    size="small"
                                    className="filterAutocomplete"
                                    multiple
                                    filterSelectedOptions
                                    classes={{option: connectionId, clearIndicator: connectionId, tag: connectionId}}
                                    options={getHierarchyEntries(hierarchyDefaultValues, tasks)}
                                    defaultValue={hierarchyDefaultValues}
                                    getOptionLabel={option => option?.title}
                                    renderInput={params => (
                                        <TextField {...params} label="In hierarchy" fullWidth variant="outlined" />
                                    )}
                                    onChange={(event, values) => handleChangeHierarchyFilter(event, values, connectionId)}
                                    disableListWrap
                                    ListboxComponent={ListboxComponent}
                                />

                                <Autocomplete
                                    size="small"
                                    className="filterAutocomplete"
                                    multiple
                                    filterSelectedOptions
                                    classes={{option: connectionId, clearIndicator: connectionId, tag: connectionId}}
                                    options={getAssignedEntries(madebyDefaultValues, users)}
                                    defaultValue={madebyDefaultValues}
                                    getOptionLabel={option => option?.title}
                                    renderInput={params => (
                                        <TextField {...params} label="Change made by" fullWidth variant="outlined" />
                                    )}
                                    onChange={(event, value) => handleChangeMadebyFilter(event, value, connectionId)}
                                />
                            </div>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Button variant="outlined" color="secondary" className="removeSlackConnection" onClick={()=>{removeSlackConnection(connectionId);}}>{intl.formatMessage({id: 'Remove this connection'})}</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>;
                })}

                <a className="addToSlackBtn" onClick={handleAddSlack} >
                    <img alt="Add to Slack" height="34" width="118.15" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                </a>
            </div>
        }
    </>;
};

export default SlackConnections;

