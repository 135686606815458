import React, { useEffect, useState } from 'react';
import classnames from 'clsx';
import * as firebaseEvents from '../../utils/firebaseEvents';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTaskCanManage, getAccountTaskDelayUntil, getAccountTaskHasChildren, getAccountTaskIsRecurring, getAccountTaskStarton, getAccountTaskStatus } from '../../utils/selectors/account';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import Tooltip from 'rc-tooltip';
import { DateTimePicker } from '@material-ui/pickers';
import RemoveIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { toastr } from 'react-redux-toastr';
import useInternalization from '../../utils/hooks/useInternalization';
import { isRegularTimeFormat } from '../../utils/utils';
import moment from 'moment';

const TaskDetailStartAfter = ({ taskId }) => {
    const { t } = useInternalization();
    const taskDelayUntil = useSelector(getAccountTaskDelayUntil(taskId));
    const [delayUntil, setDelayUntil] = useState(taskDelayUntil);
    const taskStartOn = useSelector(getAccountTaskStarton(taskId));
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskIsRecurringTask = useSelector(getAccountTaskIsRecurring(taskId));
    const taskHasChildren = useSelector(getAccountTaskHasChildren(taskId));
    const accountId = useSelector(getAccountId);
    const userDateFormat = useSelector(getUserDateformat);
    const userTimeFormat = useSelector(getUserTimeformat);

    const dateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
    const isRegularTime = isRegularTimeFormat(userTimeFormat);

    const changeDelay = (date) => {
        if(!taskCanManage || taskStatus === 'done' || !date) {
            return;
        }

        const dateISO = date.toISOString();
        setDelayUntil(dateISO);
        firebaseEvents.changeTaskDelay(accountId, taskId, dateISO);
    };

    const removeDelayDate = () => {
        setDelayUntil(null);
        firebaseEvents.addChallenge('removeDelayTask');
        firebaseEvents.changeTaskDelay(accountId, taskId, null);
    };

    const clickAction = () => {
        if (!taskStartOn) {
            return;
        }

        toastr.error(t('taskDelayBlockedByStartOn.message'));
    };

    const isDisabled = !(taskStatus !== 'done' && taskCanManage && !taskStartOn && !(taskIsRecurringTask && taskHasChildren));

    useEffect(() => {
        setDelayUntil(taskDelayUntil);
    }, [taskDelayUntil]);

    return (
        <React.Fragment>
            <li>
                <dl>
                    <dt>
                        {t('Wait Until')}
                        <Tooltip placement="top" overlay={t('task.table.startAfter.help.text')}>
                            <InfoIcon />
                        </Tooltip>
                    </dt>
                    <dt className={classnames('Delaydate', { regularTime: isRegularTime })} onClick={clickAction}>
                        <DateTimePicker
                            className={classnames('taskDelayDate')}
                            margin="normal"
                            placeholder={t('None')}
                            value={delayUntil ? delayUntil : new Date(new Date().setHours(0, 0, 0, 0))}
                            labelFunc={() => delayUntil ? moment(delayUntil).format(dateTimeFormat) : ''}
                            onChange={changeDelay}
                            fullWidth
                            cancelLabel="Cancel"
                            okLabel="Confirm"
                            ampm={isRegularTime}
                            disabled={isDisabled}
                            showTodayButton
                            todayLabel={t('now')}
                        />
                        {delayUntil && <RemoveIcon className="RemoveDelay" onClick={removeDelayDate} />}
                    </dt>
                </dl>
            </li>
        </React.Fragment>
    );
};

TaskDetailStartAfter.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailStartAfter;
