import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import { addSlackAccess } from '../../utils/firebaseEvents';
import $ from 'jquery';
import { CLOUD_FUNCTIONS, SLACK_REDIRECT_URL } from '../../utils/constants';
import { getAccountId } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';



const ConnectSlack = ({location})=>{
    const accountId = useSelector(getAccountId);
    const [sent, setSent] = useState(false);

    useEffect(()=>{
        if (accountId && !sent) {
            setSent(false);
            const code = location.query.code;

            if (code) {
                $.ajax({
                    url: `${CLOUD_FUNCTIONS.slackAuthAccess}?code=${code}&redirect_uri=${encodeURI(
                        SLACK_REDIRECT_URL,
                    )}`,
                })
                    .done(slackCred => {
                        if (slackCred) {
                            slackCred = JSON.parse(slackCred);
                        }
                        if (slackCred && slackCred.ok === true) {
                            addSlackAccess(slackCred);
                            browserHistory.push('/settings/workspace');
                        } else {
                            browserHistory.push('/settings/workspace');
                        }
                    })
                    .fail(() => {
                        browserHistory.push('/settings/workspace');
                    });
            } else {
                browserHistory.push('/settings/workspace');
            }
        }
    }, [accountId]);

    return <div/>;
};

export default withRouter(withCustomErrorBoundary(ConnectSlack));
