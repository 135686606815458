import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ArrowDropRight from '@material-ui/icons/Check';


import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const FiltersAndOr = ({type, groupIndex, onChange})=>{
    const intl = useIntl();

    const [stateType, setStateType] = useState(type);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(()=>{
        setStateType(type);
    }, [type]);

    const handleClick = (event) => {
        event.preventDefault();

        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const doChange = (val) => {
        onChange(groupIndex, val);
        setOpen(false);
    };


    var andSelected = <span className="empty"/>, orSelected = <span className="empty"/>;

    if(stateType === 'and'){
        andSelected = <ArrowDropRight />;
    }
    if(stateType === 'or'){
        orSelected = <ArrowDropRight />;
    }
    return (
        <div> 
            <Chip className="chip sep" onClick={handleClick} data-groupindex={groupIndex} label={intl.formatMessage({id:stateType})} />
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                onClose={handleRequestClose}
            >
                <MenuList className="andOrSelect">
                    <MenuItem onClick={()=>doChange('and')}>{andSelected} {intl.formatMessage({id:'and'})}</MenuItem>
                    <MenuItem onClick={()=>doChange('or')}>{orSelected} {intl.formatMessage({id:'or'})}</MenuItem>
                </MenuList>
            </Popover>
        </div>
    );
};

export default withCustomErrorBoundary(FiltersAndOr);