import React, { useCallback, useMemo } from 'react';
import classnames from 'clsx';
import useInternalization from '../../../utils/hooks/useInternalization';
import SelectTeamMember from '../../SelectTeamMember/SelectTeamMember';
import { useSelector } from 'react-redux';
import { getAccountTaskStatus, getAccountUsers } from '../../../utils/selectors/account';
import { Button } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { handleStopPropagation } from '../../../utils/utils';

const WhoIsWorking = ({
    showWhoIsWorking,
    taskNbworkers,
    handleSetStartWorking,
    handleChangeAssignedUser,
    handleMarkAsDone,
    userWorking,
    taskId = null
}) =>  {
    const { t } = useInternalization();
    const [selectTeamMemberOpen, setSelectTeamMemberOpen] = React.useState(false);
    const accountUsers = useSelector(getAccountUsers);
    const taskStatus = useSelector(getAccountTaskStatus(taskId));

    const handleHideSelectMember = useCallback(() => setSelectTeamMemberOpen(false), []);

    const handleShowSelectMember = useCallback(() => setSelectTeamMemberOpen(true), []);

    const handleChangeAssignees = useCallback((selectedMembers) => {
        setSelectTeamMemberOpen(false);
        handleChangeAssignedUser(selectedMembers);
    }, [handleChangeAssignedUser]);

    const maxSelect = useMemo(
        () => taskNbworkers ?? (Array.isArray(userWorking?.length) ? userWorking?.length : 1),
        [taskNbworkers, userWorking]
    );

    return (
        <div
            onClick={e => handleStopPropagation(e)}
            className={classnames('whoIsWorking', {
                show: showWhoIsWorking,
                multiWorker: taskNbworkers && taskNbworkers > 1,
            })}
            data-sidebar='preventClose'
        >
            {taskStatus === 'todo' && (
                <div className="whoSelect">
                    <div onClick={handleShowSelectMember}>
                        <span className="label">{t('Who starts working?')}</span>
                        <span>
                            {accountUsers[userWorking]?.displayName}
                        </span>
                    </div>
                    {selectTeamMemberOpen && (
                        <SelectTeamMember
                            maxSelect={maxSelect}
                            currentUser={userWorking || []}
                            onSelect={handleChangeAssignees}
                            onClose={handleHideSelectMember}
                            open={selectTeamMemberOpen}
                            taskId={taskId}
                        />
                    )}
                </div>
            )}
            <Button
                size="small"
                variant="contained"
                className="startBtn"
                color="primary"
                onClick={handleSetStartWorking}
            >
                {t('Start')}
            </Button>
            <Button
                size="small"
                variant="contained"
                className="doneBtn"
                color="secondary"
                onClick={handleMarkAsDone}
            >
                {t('Mark as done')}
            </Button>
        </div>
    );
};

export default withCustomErrorBoundary(WhoIsWorking);