import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'underscore';
import Chip from '@material-ui/core/Chip';
import { getUserAvatar } from '../../../utils/utils';
import Badge from '@material-ui/core/Badge';
import classnames from 'clsx';

export const UserList = ({ userId, users, chatDataDirect, hideMobileMenu, routerParams, getNbUnread }) => {
    const usersList = _.sortBy(users, 'displayName').filter(u => u.userId && u.userId !== userId && !u.viewer);

    const getDirectChannel = (userId) => {
        return chatDataDirect
            ? Object.values(chatDataDirect).find(c => c.participants && c.participants.includes(userId))
            : null;
    };

    return usersList.length ? (
        <ul>
            {usersList.map(user => {
                const channel = getDirectChannel(user.userId);
                const channelUnreadMessagesCount = channel ? getNbUnread(channel) : 0;
                const nbUnread = channelUnreadMessagesCount > 20 ? '20+' : channelUnreadMessagesCount;

                return (
                    <li
                        key={user.userId}
                        onClick={() => {
                            hideMobileMenu();
                            browserHistory.push('/chat/direct/' + user.userId);
                        }}
                        className={classnames({
                            active: routerParams.type === 'direct' && routerParams.uid === user.userId,
                        })}
                    >
                        <span>
                            <Badge
                                overlap="rectangular"
                                variant="dot"
                                invisible={user.connected && user.connected.state === 'online' ? false : true}
                            >
                                {getUserAvatar(user.userId, false, users[user.userId] && users[user.userId].avatar)}
                            </Badge>
                            {user ? user.displayName : ''}
                        </span>
                        {nbUnread > 0 && <Chip size="small" label={nbUnread} color="secondary" />}
                    </li>
                );
            })}
        </ul>
    ) : (
        <span className="noChannel">No team members</span>
    );
};
