import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AddFilterIcon from '@material-ui/icons/AddCircleOutline';
import filtersConf from '../Filters/filters.json';
import _ from 'underscore';

import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { useSelector } from 'react-redux';
import { getAccountCustomFields } from '../../utils/selectors/account';

const FiltersAdd = ({onChange, groupIndex})=>{
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const customFields = useSelector(getAccountCustomFields);


    const handleClick = (event) => {
        event.preventDefault();

        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const doChange = (val) => {
        onChange(groupIndex, val);
        setOpen(false);
    };

    var chip = <Chip className="chip addFilter" onClick={handleClick} icon={<AddFilterIcon/>} label={intl.formatMessage({id:'Add filter'})} />;
    if(groupIndex){
        chip = <Chip className="chip add" onClick={handleClick} data-groupindex={groupIndex} label="+" />;
    }

    var items = [];

    _.each(filtersConf, (filter, key)=>{
        if(key !== 'tasktype'){
            items.push(
                <MenuItem 
                    key={key}  
                    onClick={()=>doChange(key)}
                >
                    <Icon className="material-icons">{filter.icon}</Icon>
                    {intl.formatMessage({id:filter.label})}
                </MenuItem>
            );
        }
    });

    if(customFields){
        Object.keys(customFields).sort((a,b)=>{ return (customFields[a].name.toUpperCase() < customFields[b].name.toUpperCase()) ? -1 : 1; }).forEach(fieldId => {
            items.push(
                <MenuItem 
                    key={fieldId}  
                    onClick={()=>doChange(fieldId)}
                >
                    <Icon className="material-icons">hdr_weak</Icon>
                    {customFields[fieldId].name}
                </MenuItem>
            );
        });
    }

    return (
        <div className="FiltersAdd">
            {chip}
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                onClose={handleRequestClose}
            >
                <MenuList className="FiltersAddList">
                    {items}
                </MenuList>
            </Popover>
        </div>
    );

};

export default withCustomErrorBoundary(FiltersAdd);
