import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { getAccountId } from '../../utils/selectors/account';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import AddIcon from '@material-ui/icons/PersonAdd';
import ClosedIcon from '@material-ui/icons/ChevronRight';
import OpenedIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const GroupLine = ({groupId, title, children, handleAddNewMember, emptyGroup = false}) => {
    const intl = useIntl();
    const [stateTitle, setStateTitle] = useState(title);
    const [opened, setOpened] = useState(true);

    const accountId = useSelector(getAccountId);

    const onTitleChange = (e) => setStateTitle(e.target.value);

    const deleteGroup = () => {
        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this user group?'}), {
            onOk: () => {
                firebaseEvents.deleteUserGroup(accountId, groupId, intl);
            },
            okText: intl.formatMessage({id: 'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id: 'toastr.confirm.cancel'}),
            id: 'toastr-confirm-delete',
        });
    };

    const changeGroupTitle = () => firebaseEvents.changeUserGroupTitle(accountId, groupId, stateTitle);

    const onAddNewMember = () => handleAddNewMember(groupId);

    return (
        <div
            className={classnames('groupLine', { open: opened })}
            key={groupId}
            data-groupid={groupId}
        >
            <div>
                {opened ? <OpenedIcon onClick={()=>setOpened(false)} /> : <ClosedIcon onClick={()=>setOpened(true)} />}
                <input
                    value={stateTitle}
                    className="userName groupInput"
                    onChange={onTitleChange}
                    onBlur={changeGroupTitle}
                />
                <Button
                    onClick={onAddNewMember}
                    title={intl.formatMessage({id:'Add a new team member'})}
                >
                    <AddIcon />
                </Button>
                
                {emptyGroup && (
                    <Button onClick={() => deleteGroup()} title={intl.formatMessage({id:'Delete'})}>
                        <DeleteIcon />
                    </Button>
                )}

            </div>
            {children}
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(GroupLine));