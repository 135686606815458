import moment from 'moment-timezone';
import _ from 'underscore';
import Dexie from 'dexie';
import { tracking } from '../../utils/utils';
import { getStatsData } from '../../utils/services/statsService';


export const validateNumber = (value) => isNaN(value) ? 0 : value;

export const exportCSV = (datasets, labels, renderColumns, formatTitle, csvTitle) => {
    tracking('Save report as CSV', { csvTitle });
    const dataSetsParents = getParentsFromDataSets(datasets);
    const headers = ';' + labels.map(el => formatTitle(el)).join(';');
    const rows = dataSetsParents.reduce((acc, el) => {
        if(el.ignoreColumn){
            return acc;
        }

        if (el.subFolders.length) {
            acc += `${el.name}\n`;
            el.subFolders.forEach(subFolder => {
                acc += renderColumns(subFolder, dataSetsParents);
            });
            return acc;
        }
        return acc + renderColumns(el, dataSetsParents);
    } ,'');

    downloadFile(encodeURI(`data:text/csv;charset=utf-8,${headers}\r\n${rows}`), csvTitle);
};

export const downloadFile = (url, docTitle) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${docTitle}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named 'my_data.csv'.
    link.remove();
};


export const downloadAsImage = (canvas) => {
    tracking('Reporst exporting as IMG');

    const a = document.createElement('a');
    a.download='download';
    a.href = canvas.toDataURL();
    document.body.append(a);
    a.click();
    a.remove();
};

export const getDataByTask = async ({workspaceData, selectedTasks, accountId, userTimezone}) => {
    if (selectedTasks?.length) {
        const csvFiles = await Promise.all(selectedTasks.map(el => getCsvFile(accountId, el, userTimezone)));
        let csvLine = {};
        // use only the last CSV of each day
        csvFiles.forEach((file) => {
            file.forEach(taskLine => {
                if(csvLine[taskLine.id]) {
                    const lineDate = convertToDateTimeForNative(taskLine.Datetime);
                    const exists = csvLine[taskLine.id].findIndex( line =>
                        isSameDate(convertToDateTimeForNative(line.Datetime), lineDate));

                    if(exists === -1){
                        csvLine[taskLine.id].push(taskLine);
                    } else {
                        csvLine[taskLine.id][exists] = taskLine;
                    }
                } else {
                    csvLine[taskLine.id] = [taskLine];
                }
            });
        });

        return csvLine;
    }
    let csvLine = {};
    workspaceData.forEach((taskLine) => {
        if(csvLine[taskLine.id]) {
            const lineDate = convertToDateTimeForNative(taskLine.Datetime);
            const exists = csvLine[taskLine.id].findIndex( line =>
                isSameDate(convertToDateTimeForNative(line.Datetime), lineDate));

            if(exists === -1){
                csvLine[taskLine.id].push(taskLine);
            } else {
                csvLine[taskLine.id][exists] = taskLine;
            }
        } else {
            csvLine[taskLine.id] = [taskLine];
        }
    });
    return csvLine;
};

export const getCsvFile = async (accountId, taskId, userTimezone) => {
    const idb = new Dexie('planless-cvs');

    idb.version(1).stores({
        csvFiles: 'accountId,taskId'
    });

    const existData = await idb.csvFiles.where({ accountId, taskId }).last();

    const existDataForToday = existData && existData.timestamp && moment(existData.timestamp).tz(userTimezone).isSame(moment().tz(userTimezone), 'day');

    if (existDataForToday) {
        return existData.files;
    }

    if (existData) {
        await idb.csvFiles.where({ accountId, taskId }).delete();
    }

    const files = await getStatsData({ accountId, taskId });
    await idb.csvFiles.put({ accountId, taskId, files: files, timestamp: moment().tz(userTimezone).format('YYYY/MM/DD')});

    return files;
};

const aggregatePerYear = ({labels, datasets, stakData=false, useLastDataAvailable = false}) =>  {
    const t = deepCloneDataSets({labels, datasets}).datasets;
    Object.values(t).forEach(el => el.data = []);

    const data = labels.reduce((acc, label, idx) => {
        const year = moment(label).year();

        if(acc.labels.includes(year)){
            // remove the last value from the datasets
            acc.idxToRemove.push(idx-1);
            if (stakData) {
                Object.entries(t).forEach(([key,el]) => {
                    el.data[acc.labels.length - 1] += datasets[key].data[idx];
                });
            }

            if (useLastDataAvailable) {
                Object
                    .entries(t)
                    .forEach(([key,el]) => {
                        if(datasets[key].data[idx]){
                            el.data[acc.labels.length - 1] = datasets[key].data[idx];
                        }
                    });
            }

            return acc;
        }

        if (stakData || useLastDataAvailable) {
            Object.entries(t).forEach(([key,el]) => {
                el.data.push(datasets[key].data[idx]);
            });
        }

        acc.labels.push(year);
        return acc;
    }, {
        labels: [],
        datasets: datasets,
        idxToRemove: []
    });

    if(!stakData && !useLastDataAvailable){
        Object.values(data.datasets).forEach(el => {
            el.data = el.data.filter((a,idx) => !data.idxToRemove.includes(idx));
        });
    }
    return {
        ...data,
        datasets: stakData || useLastDataAvailable  ? t : data.datasets
    };
};

const aggregatePerMonth = ({labels, datasets, stakData=false, useLastDataAvailable = false}) =>  {
    const t = deepCloneDataSets({labels, datasets}).datasets;
    Object.values(t).forEach(el => el.data = []);

    const data =  labels.reduce((acc, label, idx) => {
        // moment return month from 0 to 11
        const month = moment(label, 'YYYY-MM-DD').month() + 1;
        const year = moment(label, 'YYYY-MM-DD').year();
        let newLabel =  `${month}/${year}`;

        if(acc.labels.includes(newLabel)){

            acc.idxToRemove.push(idx-1);
            if (stakData) {
                Object.entries(t).forEach(([key,el]) => {
                    el.data[acc.labels.length - 1] += datasets[key].data[idx];
                });
            }

            if (useLastDataAvailable) {
                Object
                    .entries(t)
                    .forEach(([key,el]) => {
                        if(datasets[key].data[idx]){
                            el.data[acc.labels.length - 1] = datasets[key].data[idx];
                        }
                    });
            }

            return acc;
        }

        if (stakData || useLastDataAvailable) {
            Object.entries(t).forEach(([key,el]) => {
                el.data.push(datasets[key].data[idx]);
            });
        }

        acc.labels.push(newLabel);
        return acc;
    }, {
        labels: [],
        datasets: datasets,
        idxToRemove: []
    });
    // remove the last value from the datasets
    if(!stakData && !useLastDataAvailable){
        Object.values(data.datasets).forEach(el => {
            el.data = el.data.filter((a,idx) => !data.idxToRemove.includes(idx));
        });
    }

    return {
        ...data,
        datasets: stakData || useLastDataAvailable  ? t : data.datasets
    };
};

const aggregatePerWeek = ({labels, datasets, stakData = false, useLastDataAvailable = false }) =>  {
    const t = deepCloneDataSets({labels, datasets}).datasets;
    Object.values(t).forEach(el => el.data = []);

    const data = labels.reduce((acc, label, idx) => {
        const year = moment(label, 'YYYY-MM-DD').year();
        const week = moment(label, 'YYYY-MM-DD').week();

        const exists = acc.labels.find(el => moment(el).week() === week && moment(el).year() === year);
        if (exists) {
            // remove the last value from the datasets
            acc.idxToRemove.push(idx-1);
            acc.labels.pop();
            acc.labels.push(label);

            if (stakData) {
                Object.entries(t).forEach(([key,el]) => {
                    el.data[acc.labels.length - 1] += datasets[key].data[idx];
                });
            }

            if (useLastDataAvailable) {
                Object
                    .entries(t)
                    .forEach(([key,el]) => {
                        if(datasets[key].data[idx]){
                            el.data[acc.labels.length - 1] = datasets[key].data[idx];
                        }
                    });
            }

            return acc;
        }

        acc.labels.push(label);

        if (stakData || useLastDataAvailable ) {
            Object.entries(t).forEach(([key,el]) => {
                el.data.push(datasets[key].data[idx]);
            });
        }

        return acc;
    }, {
        labels: [],
        datasets: datasets,
        idxToRemove: []
    });

    // remove the last value from the datasets
    if(!stakData && !useLastDataAvailable){
        Object.values(data.datasets).forEach(el => {
            el.data = el.data.filter((a,idx) => !data.idxToRemove.includes(idx));
        });
    }

    return {
        ...data,
        datasets: stakData || useLastDataAvailable  ? t : data.datasets
    };
};

/**
 * labels has all of the dates
 * datasets
 * @param {*} type
 * @param { label: [], datasets: [{
 *  data: []
 * }] } data
 * @returns
 */
export const agregateData = (type = 'day', data) => {
    // if per day we dont do shit:
    switch (type) {
    case 'year':
        return aggregatePerYear(data);
    case 'month':
        return aggregatePerMonth(data);
    case 'week':
        return aggregatePerWeek(data);
    case 'day':
        return addEachDayToDatasets(data);
    default:
        return data;
    }
};

export const getSplitType = (startDate, endDate) => {
    // if per day we dont do shit:
    const differenceByMonth = endDate.diff(startDate, 'months');

    if(differenceByMonth < 1)
        return  'day';

    if(differenceByMonth < 12)
        return  'week';

    return  'month';
};

export const convertToDateTime = (time) => {
    return moment.unix(parseInt(time)*60*60*4);
};

export const convertToDateTimeForNative = (time) => {
    return new Date(parseInt(time)*60*60*4*1000);
};

export const deepCloneDataSets = ({ labels, datasets }) => ({
    labels: [...labels],
    datasets: Object.keys(datasets).reduce((acc, el) => ({...acc, [el]: {...datasets[el], data: [...datasets[el].data]} }), {})
});

export const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const getDateByFormat = (date) => {
    if(moment(date, 'YYYY', true).isValid()){
        return moment(date, 'YYYY', true);
    }
    else if(moment(date, 'YYYY/MM/DD', true).isValid()){
        return moment(date, 'YYYY/MM/DD', true);
    }
    else if(moment(date, 'M/YYYY', true).isValid()){
        return moment(date, 'M/YYYY', true);
    }
    return moment(date);
};

export const getDateFormated = (date) => {
    if(moment(date, 'YYYY', true).isValid()){
        return date;
    }
    else if(moment(date, 'YYYY/MM/DD', true).isValid()){
        return `${moment(date, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
    }
    else if(moment(date, 'M/YYYY', true).isValid()){
        return `${moment(date, 'M/YYYY').format('MMMM YYYY')}`;
    }

    return moment(date).format('MMM D, YY');
};

export const backgroundColor = (color, chart, userTimezone) => ({p1: {parsed: { x }}}) => chart  && chart  && moment().tz(userTimezone).isBefore(getDateByFormat(chart.data.labels[x])) ? increase_brightness(color, 70) : color;

function increase_brightness(hex, percent){
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if(hex.length == 3){
        hex = hex.replace(/(.)/g, '$1$1');
    }

    var r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);

    return '#' +
       ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
       ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
       ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

export const borderDash = (chart, userTimezone) => ({p1: {parsed: { x }}}) => chart  && moment().tz(userTimezone).isBefore(getDateByFormat(chart.data.labels[x])) ? [6, 6] : undefined;

export const getParentsFromDataSets = (dataSets) => Object.values(dataSets).reduce((acc, el) => {
    const isGrouped = el.id && el.id.includes('--');
    const mainId = isGrouped ? el.id.split('--')[0] : el.id;
    const subId = isGrouped && `-${el.id.split('--')[1]}`;
    const exists = acc.find(e => e.id.includes(mainId));
    const type = el.labelType;
    const ignoreColumn = !!el.ignoreColumn;
    // if id oesnt exists
    if (!mainId) {
        return acc;
    }

    if (exists) {
        const subItemExist = exists.subFolders.find(e => e.id.includes(subId));
        if(isGrouped) {
            // only if is sub folder
            if (subItemExist) {
                subItemExist[type] = el.data;
            } else {
                exists.subFolders.push({
                    name: el.subName,
                    id: subId,
                    [type]: el.data,
                    ignoreColumn
                });
            }

            return acc;
        }
        exists[type] = el.data;
        return acc;
    }
    const newEntry = {
        name: el.parentName,
        id: mainId,
        ignoreColumn,
        [type]: el.data,
        subFolders: []
    };

    if(subId){
        newEntry.subFolders.push({
            name:  el.subName,
            id: subId,
            ignoreColumn,
            [type]: el.data,
        });
    }

    acc.push(newEntry);
    return acc;
}, []);


export const colorPalet = [
    ['#1e4fa2','#6183be','#a5b9da','#bbcae3'],
    ['#29bee2','#69d1eb','#a9e5f3','#beebf6'],
    ['#f0562c','#f4886b','#f9bbab','#faccbf'],
    ['#ff9900','#ffb74c','#ffd699','#ffe0b2'],
    ['#353535','#494949','#5d5d5d','#c2c2c2'],
    ['#00a384','#4cbea9','#99dace','#b2e3da'],
    ['#6638b3','#9473ca','#c2afe1','#d1c3e8'],
    ['#9a1683','#b85ba8','#d7a2cd','#e1b9da'],
    ['#d1281e','#df6861','#eda9a5','#f1bebb'],
    ['#00b24b','#4cc981','#99e0b7','#b2e8c9'],
    ['#293f52','#697886','#949fa8','#d4d9dc'],
    ['#085c6b','#528d97','#9cbec4','#cedee1'],
    ['#743f00','#9d784c','#c7b299','#e3d9cc'],
    ['#957815','#b5a05b','#cabb8a','#eae4d0'],
    ['#7f7f7f','#999999','#b2b2b2','#e5e5e5'],
    ['#155e50','#5b8e84','#8aaea7','#d0dfdc'],
    ['#100074','#574c9d','#9f99c7','#cfcce3'],
    ['#44153c','#7c5b76','#a18a9d','#dad0d8'],
    ['#7a0c05','#a25450','#bc8582','#e4cecd'],
    ['#5d7400','#8d9d4c','#bec799','#dfe3cc']
];

export  const getColor = (datasetsLength, datasetIndex) => {
    if(datasetsLength <= 1){
        return [ colorPalet[0][0], colorPalet[1][0], colorPalet[2][0], colorPalet[3][0] ];
    }

    const paletRow = datasetIndex - ( parseInt((datasetIndex / colorPalet.length ), 10) * colorPalet.length);

    return [colorPalet[paletRow][0],colorPalet[paletRow][1],colorPalet[paletRow][2],colorPalet[paletRow][3]];
};

export const isSameDate = (date, toCompare) => date.getDate() === toCompare.getDate() &&
    date.getMonth() === toCompare.getMonth() &&
    date.getFullYear() === toCompare.getFullYear();

export const isBefore = (dateA, dateB) =>
    (dateA.getDate() < dateB.getDate()  && dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear()) ||
    (dateA.getMonth() < dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear()) ||
    dateA.getFullYear() < dateB.getFullYear();

export const getMonth = (month) => month >= 10 ? month : `0${month}`;

export const addEachDayToDatasets = ({labels, datasets }) => {
    if(!labels.length || !Object.keys(datasets).length){
        return {labels, datasets};
    }

    const firstDay = new Date(labels[0]);
    const lastDay = new Date(_.last(labels));
    lastDay.setDate(lastDay.getDate() + 1);
    const newLabels = [];
    let idxWithExistingData = 0;
    while(!isSameDate(firstDay,lastDay)) {
        const formatedFirstDay =  `${firstDay.getFullYear()}-${getMonth(firstDay.getMonth() + 1)}-${firstDay.getDate()}`;
        const labelExists = labels.find(el => isSameDate(new Date(el), firstDay));

        if(!labelExists){
            Object.values(datasets).forEach(dataSet => {
                // add to array
                const lineValue =  dataSet.data[idxWithExistingData - 1];
                dataSet.data.splice(
                    idxWithExistingData,
                    0,
                    lineValue
                );
            });
        }

        newLabels.push(formatedFirstDay);
        firstDay.setDate(firstDay.getDate() + 1);
        idxWithExistingData++;
    }
    return {
        datasets,
        labels: newLabels
    };
};


export const menuItems = [
    {
        type: 'overview',
        translation: 'overview',
        usesDateRange: true,
    },
    {
        subheader:true,
        name:'Reports'
    },
    {
        type: 'date-drift',
        translation: 'date-drift',
        usesDateRange: true
    },
    {
        type: 'task-count-burndown',
        translation: 'task-count-burndown',
        usesDateRange: true,
    },
    {
        type: 'effort-burndown',
        translation: 'effort-burndown',
        usesDateRange: true,
    },
    {
        type: 'time-worked-total',
        usesDateRange: true,
        translation: 'time-worked-total',
    },
    {
        type: 'days-off',
        usesDateRange: true,
        translation: 'days-off',
    },
    {
        type: 'team-members-count',
        usesDateRange: true,
        translation: 'team-members-count',

    },
    {
        type: 'events',
        translation: 'events',
        usesDateRange: true,
    },
    {
        type: 'cost-budget',
        usesDateRange: true,
        translation: 'cost-budget',
    },
    {
        subheader:true,
        name:'Repartitions'
    },
    {
        type: 'task-breakdown',
        usesDateRange: false,
        translation: 'task-breakdown',
    },
    {
        type: 'effort-breakdown',
        usesDateRange: false,
        translation: 'effort-breakdown',
    },
    {
        type: 'internal-cost-budget-repartition',
        usesDateRange: false,
        translation: 'internal-cost-budget-repartition',
    },
    {
        type: 'billable-cost-budget-repartition',
        usesDateRange: false,
        translation: 'billable-cost-budget-repartition',
    },
    {
        type: 'expenses-repartition',
        usesDateRange: false,
        translation: 'expenses-repartition',
    },
    {
        subheader:true,
        name:'Lists'
    },
    {
        type: 'time-worked-detailed',
        usesDateRange: true,
        translation: 'time-worked-detailed',
    },
    {
        type: 'upcoming-tasks',
        translation: 'upcoming-tasks',
        usesDateRange: false,
    },
    {
        type: 'upcoming-events',
        usesDateRange: false,
        translation: 'upcoming-events',
    },
    {
        usesDateRange: false,
        type: 'recently-done-tasks',
        translation: 'recently-done-tasks',
    },
    {
        usesDateRange: false,
        type: 'recent-events',
        translation: 'recent-events',
    },
    {
        type: 'risks',
        usesDateRange: false,
        translation: 'risks',
    },
    {
        type: 'expenses-list',
        usesDateRange: true,
        translation: 'expenses',
    },
];

export const sortByDate =(a,b, field = 'startDate') => {
    if(Date.parse(a[field]) && Date.parse(b[field])){
        const dateA = new Date(a[field]);
        const dateB = new Date(b[field]);

        return dateA.valueOf() - dateB.valueOf();
    }
    if(a[field] === 'N/A' && b[field] !== 'N/A' ){
        return 1;
    }
    if(b[field] === 'N/A' && a[field] !== 'N/A' ){
        return -1;
    }
    if(b[field] === 'N/A' && a[field] === 'N/A' ){
        return 0;
    }
    return a[field] - b[field];
};

export const getPath = (parentId, allTasks) => {
    const parentTask = allTasks[parentId];

    if(!parentTask){
        return '';
    }

    if(parentTask.parent && parentId !== parentTask.parent ) {
        return `${getPath(parentTask.parent, allTasks)} » ${parentTask.title}`;
    }
    return parentTask.title;
};

export const getUserInfo = (user) => {
    if(!user) {
        return {
            avatar: '',
            displayName: ''
        };
    }

    return {
        avatar: user.avatar,
        displayName: user.displayName
    };
};

export const sortOtherToLast = (a,b)=> {
    if(a.name === 'Others'){
        return 1;
    }

    if(b.name === 'Others'){
        return -1;
    }

    return -(a.value- b.value);
};

export const getEventInfo = ({path, participants, when, eventName}, id) => ({
    path: path,
    id: id,
    participants: participants,
    when: when || 'N/A',
    eventName: eventName,
});