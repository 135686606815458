import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUserId } from '../../../../utils/selectors/user';
import { getAccountUsers } from '../../../../utils/selectors/account';
import * as firebaseEvents from '../../../../utils/firebaseEvents';
import { getOnlyUsers } from '../../../../utils/memberTypes';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import StateUserAvatar from '../../../../components/UserAvatar/StateUserAvatar';

const PermissionsByType = ({ permissionType }) => {
    const intl = useIntl();
    const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
    const [autocompleteValues, setAutocompleteValues] = React.useState([]);

    const accountUsers = useSelector(getAccountUsers);
    const users = getOnlyUsers(accountUsers);

    const currentUserId = useSelector(getUserId);

    const handleChange = React.useCallback((usersIds) => {
        const actions = {
            admin: () => firebaseEvents.addChallenge('changeAdmins'),
            team: () => firebaseEvents.addChallenge('changePermissionTeams'),
            skills: () => firebaseEvents.addChallenge('changePermissionSkills'),
            daysoff: () => firebaseEvents.addChallenge('changePermissionDaysoff'),
            boards: () => firebaseEvents.addChallenge('changePermissionBoards'),
        };

        if(permissionType === 'admin' && usersIds.length < 1) {
            toastr.error(intl.formatMessage({ id: 'one.admin.mandatory'}));
            return;
        }

        if(permissionType !== 'subscription') {
            actions[permissionType]();
        }

        firebaseEvents.changeSettingsPermissions(permissionType, usersIds);
    }, [permissionType]);

    const handleRemoveUserFromPermission = useCallback((userId) => {
        firebaseEvents.removeUserPermission(permissionType, userId);
    }, [permissionType]);

    useEffect(() => {
        if (!users) return null;

        const usersWithPermissionType = Object.values(users).reduce((acc, user) => {
            if (user.permissions[permissionType] === true) {
                acc.push(user.userId);
            }
            return acc;
        }, []);

        const usersAvailableList = Object.values(users).reduce((acc, user) => {
            if(user.permissions[permissionType] === false) {
                acc.push({ id: user.userId, name: user?.displayName });
            }
            return acc;
        }, [])
            .sort((a, b) => (a.displayName > b.displayName ? 1 : 2))
            .map((user) => user.id);
        
        setAutocompleteValues(usersWithPermissionType);
        setAutocompleteOptions(usersAvailableList);
    }, [accountUsers]);

    return (
        <Autocomplete
            size="small"
            className="usersAutocomplete"
            multiple
            filterSelectedOptions
            options={autocompleteOptions}
            value={autocompleteValues}
            getOptionLabel={(option) => (
                <div
                    className="optionUser"
                >
                    <StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>
                    {(users[option]) ? users[option].displayName : ''}
                </div>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Users" fullWidth />
            )}
            renderTags={(value, getTagProps) => value.map((option, index) => {
                const props = getTagProps({ index });
                if (option === currentUserId) {
                    props.onDelete = null;
                } else {
                    props.onDelete = () => handleRemoveUserFromPermission(option);
                }

                return (
                    <Chip
                        className='userchip'
                        avatar={<StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>}
                        variant="outlined"
                        label={(users[option]) ? users[option].displayName : ''}
                        {...props}
                    />
                );
            })
            }

            filterOptions={(options, { inputValue }) => {
                const results = options.reduce((acc, option) => {
                    if (users[option]?.displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1) {
                        acc.push(option);
                    }
                    return acc;
                }, []);

                return results;
            }}
            onChange={(e, values) => handleChange(values)}
        />
    );
};

export default memo(withCustomErrorBoundary(PermissionsByType));
