export const ADD_TASK_LIST_LEVEL = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
};

export const isTaskInsideSyncFolder = (task, stateTasks, parents) => {
    const connectedFolder = task?.isConnectProject ? task : parents
        .map(parentId => stateTasks[parentId])
        .find(parent => parent?.isConnectProject);

    return !!connectedFolder;
};