import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import Unautorized from '../Unautorized/Unautorized';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import { getUserId } from '../../utils/selectors/user';
import { getAccountTaskCanManage, getAccountTaskCanWork, getAccountTaskStatus, getAccountUsers } from '../../utils/selectors/account';
import { setWorkManager } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const BoardStartWorking = ({router})=>{
    const taskId = router.params.uid;
    const dispatch = useDispatch();
    const intl = useIntl();

    const userId = useSelector(getUserId);
    const status = useSelector(getAccountTaskStatus(taskId));
    const canWork = useSelector(getAccountTaskCanWork(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));
    const users = useSelector(getAccountUsers);

    const [whoWorking, setWhoWorking] = useState(null);
    const [showUserSelect, setShowUserSelect] = useState(false);

    useEffect(()=>{
        if(!whoWorking && userId){
            setWhoWorking(userId);
        }
    }, [userId]);

    const doShowUserSelect = () => {
        setShowUserSelect(true);
    };

    const hideUserSelect = () => {
        setShowUserSelect(false);
    };

    const handleClose = () => {
        browserHistory.push('/board');
    };

    const changeAssignedUser = (value) => {
        setWhoWorking(value);
        setShowUserSelect(false);
    };

    const setStartWorking = () => {
        dispatch(setWorkManager({
            taskId,
            userId: whoWorking,
            previousState: status,
            newState: 'inprogress',
            boardId: router.params.boardid
        }));

        handleClose();
    };


    if (canWork || canManage) {

        return (
            <Dialog
                className="BoardStartWorking"
                open={true}
                onClose={handleClose}
                maxWidth="lg"
            >
                <DialogTitle>{intl.formatMessage({id:'Who starts working?'})}</DialogTitle>
                <DialogContent className="whoSelect">
                    <div onClick={doShowUserSelect}>
                        <span className="label">{intl.formatMessage({id:'Who starts working?'})}</span>
                        <span>{(users[whoWorking]) ? users[whoWorking].displayName : ''}</span>
                    </div>
                    <SelectTeamMember
                        currentUser={whoWorking ? [whoWorking] : []}
                        onSelect={changeAssignedUser}
                        onClose={hideUserSelect}
                        open={showUserSelect}
                        taskId={taskId}
                    />
                </DialogContent>
                <DialogActions className='actions'>
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                        {intl.formatMessage({id:'Cancel'})}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={setStartWorking}
                    >
                        {intl.formatMessage({id:'Start'})}
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

    return (
        <Dialog
            className="BoardStartWorking"
            open={true}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle>{intl.formatMessage({id:'Who starts working?'})}</DialogTitle>
            <DialogContent className="whoSelect">
                <Unautorized />
            </DialogContent>
            <DialogActions className='actions'>
                <Button
                    color="primary"
                    onClick={handleClose}
                >
                    {intl.formatMessage({id:'Cancel'})}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withRouter(withCustomErrorBoundary(BoardStartWorking));
