import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import {
    CLOUD_FUNCTIONS
} from './constants';

export const affiliateAddTrial = ({
    pl_aid, chargebeeId
}) => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.affiliateAddTrial,
    params: {
        link: pl_aid,
        chargebeeId: chargebeeId,
    }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});