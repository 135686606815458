import React, { useEffect, useState } from 'react';
import Info from './Info';
import SubscritionInfo from './SubscritionInfo';
import OfferAccepted from './OfferAccepted';
import ErrorPage from '../ErrorPage/ErrorPage';
import { getListenerQuote, offQuoteListener, mapQuote } from '../../utils/databaseQuerys/quote';
import {  withRouter } from 'react-router';
import _ from 'underscore';
import Loader from '../../components/Loader';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const OverviewOffer = ({ location }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
  
    useEffect(() => {
        if(!(location?.query?.code)) {
            setIsLoading(false);
            setError(true);
        } else {
            getListenerQuote(location.query.code, (data) => {
                if(data.val()) {
                    setData( mapQuote({ quote: data.val() }));
                    setIsLoading(false);
                    return;
                }
                setIsLoading(false);
                setError(true);
            });
        }
        return () => offQuoteListener();
    }, [location?.query?.code]);

    if(isLoading) {
        return <div className='OverviewOffer fullHeight backWhite'> <Loader fullScreen={false} show={true} /> </div>;
    }

    if(error) {
        return <ErrorPage />;
    }

    const phase = _.get(data, 'phase', '');

    if(phase === 'submited') {
        return <div className='OverviewOffer fullHeight'>
            <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
            <OfferAccepted {...data} />
        </div>;
    }

    return <div className='OverviewOffer'>
        <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
        <div>
            <Info {...data} />
            <SubscritionInfo {...data} />
        </div>
    </div>;
};

export default withRouter(withCustomErrorBoundary(OverviewOffer));
