import React from 'react';
import { useSelector } from 'react-redux';
import  UserAvatar from './UserAvatar';
import { getAccountUser } from '../../utils/selectors/account';

const StateUserAvatar = ({ userId, className = '', disableTooltip = false }) => {
    const user = useSelector(getAccountUser(userId));
    
    return <UserAvatar
        className={className}
        color={user?.color}
        displayName={user?.displayName ? user?.displayName : user?.email ? user?.email : 'Untitled' }
        avatarUrl={user?.avatar}
        deletedUser={!user}
        disableTooltip={disableTooltip}
    />;
};

export default StateUserAvatar;