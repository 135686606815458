import { browserHistory } from 'react-router';
import React, { useCallback } from 'react';
import NotificationInfo from './NotificationInfo';
import NotificationLine from './NotificationLine';
import {  useSelector } from 'react-redux';
import _ from 'underscore';
import { getAccountTasks } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const NotificationTask = ({ taskId, notifications }) => {
    const accountTasks = useSelector(getAccountTasks);
    const firstNot = _.first(notifications);
    const task = _.get(accountTasks, [taskId], firstNot.taskData);
    
    const openTask = useCallback((e) => {
        e.stopPropagation(e);
        const parentTargetWithDataSidebar = e.target.closest('[data-opensidebar]');
        if(parentTargetWithDataSidebar){
            const field = e.target.closest('[data-field]').dataset.field;
            browserHistory.push(`/notifications/${taskId}?openOn=${field}`);
        }
    }, [taskId]);
    
    if(!task){
        return null;
    }
    
    return (
        <div onClick={openTask} className="Notification-Card">
            <NotificationInfo taskId={taskId} notifications={notifications} />
            {notifications.map(notification => (
                <NotificationLine
                    taskId={taskId}
                    key={notification.id}
                    notificationId={notification.id}
                    isRead={notification.readed}
                    createdBy={notification.createdBy}
                    beforeValue={notification.oldValue}
                    afterValue={notification.newValue}
                    actionType={notification.actionType}
                    createdAt={notification.createdAt}
                    taskData={notification.taskData}
                    field={notification.field}
                    type={notification.type}
                />
            ))}
        </div>
    );
};

export default withCustomErrorBoundary(NotificationTask);