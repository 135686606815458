import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Sortable from 'sortablejs';
import $ from 'jquery';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import DeleteIcon from '@material-ui/icons/Delete';
import DragIcon from '@material-ui/icons/DragHandle';

import { canSee } from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { permissionsByPlan } from '../../utils/constants';
import { getAccountBoards, getAccountId, getAccountPlan } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const BoardLine = ({boardId, name, index, status})=>{
    const intl = useIntl();
    const accountId = useSelector(getAccountId);
    const [title, setTitle] = useState(name);

    const changeBoardTitle = (e) => {
        setTitle(e.target.value);
        firebaseEvents.updateBoardTitle(
            boardId,
            e.target.value,
            accountId
        );
    };

    const changeBoardStatus = (e) => {
        firebaseEvents.changeBoardStatus(boardId, e.target.checked);
    };

    const deleteBoard = () => {

        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this board? All taks from this board will be moved to the "In progress" board.'}), {
            onOk: () => {
                firebaseEvents.addChallenge('deleteBoard');

                firebaseEvents.deleteBoard(
                    boardId,
                    index,
                    accountId,
                    intl
                );
            },
            okText: intl.formatMessage({id:'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
            id: ('toastr-confirm-delete')
        });
    };

    var field = <TextField fullWidth={true} value={title} onChange={changeBoardTitle} />,
        deleteBtn = <Button className='deleteBtn' title={intl.formatMessage({id:'Delete'})} data-key={boardId} data-index={index} onClick={deleteBoard}><DeleteIcon /></Button>,
        stateSwitch = (
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>To be done</Grid>
                <Grid item>
                    <Switch size='small' checked={(status !== 'todo') ? true : false} onChange={changeBoardStatus} name="status" />
                </Grid>
                <Grid item>In progress</Grid>
            </Grid>
        );

    if (boardId === 'inprogress') {
        field = name;
        deleteBtn = null;
        stateSwitch = null;
    }

    return (
        <div className="board" data-key={boardId}>
            <DragIcon className="handle" />
            <div>
                {field}
            </div>
            {stateSwitch}
            {deleteBtn}
        </div>
    );

};


const BoardsSettings = () => {
    const intl = useIntl();
    const boards = useSelector(getAccountBoards);
    
    const plan = useSelector(getAccountPlan);
    const accountId = useSelector(getAccountId);
    const userPermissions = useSelector(getUserPermissions);

    useEffect(()=>{
        if (plan && permissionsByPlan.boards.includes(plan) && canSee(['admin'], { data: { permissions: userPermissions } })) {
            if(Sortable.get(document.getElementById('sortableBoards'))?.el){
                Sortable.get(document.getElementById('sortableBoards')).destroy();
            }
            Sortable.create(document.getElementById('sortableBoards'), {
                handle: '.handle',
                onUpdate: () => {
                    var list = [];
                    $('#sortableBoards .board').each((index, value) => {
                        list.push(value.getAttribute('data-key'));
                    });

                    firebaseEvents.addChallenge('orderBoards');

                    firebaseEvents.orderBoards(list, accountId);
                }
            });
        }

        return function cleanup(){
            if(Sortable.get(document.getElementById('sortableBoards'))?.el){
                Sortable.get(document.getElementById('sortableBoards')).destroy();
            }
        };

    }, [boards]);

    const addBoard = () => {
        firebaseEvents.addChallenge('addBoard');
        firebaseEvents.addBoard(
            accountId,
            intl.formatMessage({id:'New board'})
        );
    };




    if (!permissionsByPlan.boards.includes(plan) || !canSee(['admin', 'boards'], { data: { permissions: userPermissions } })) return <Unautorized />;

    var list = [],
        boardsList = {};

    if (boards) {
        _.each(boards, (board, key) => {
            boardsList[key] = {...board, key};
        });

        boardsList = _.sortBy(boardsList, 'index');

        for (var i in boardsList) {

            list.push(
                <BoardLine
                    key={boardsList[i].key}
                    boardId={boardsList[i].key}
                    index={boardsList[i].index}
                    name={boardsList[i].name}
                    status={boardsList[i].status}
                />
            );

        }
    }


    return (
        <div className='BoardsSettings'>

            <h1>{intl.formatMessage({id:'Kanban Boards'})}</h1>

            <Button variant="contained" color="primary" onClick={addBoard}>{intl.formatMessage({id:'Add new board'})}</Button>

            <p>
                {intl.formatMessage({id:'boards-settings-text-intro'})}
            </p>

            <div className="boardsList">
                <div className="board">
                    <DragIcon />
                    <div>{intl.formatMessage({id:'To be done'})}</div>
                </div>
                <div className="canBeOrdered" id="sortableBoards">
                    {list}
                </div>
                <div className="board">
                    <DragIcon />
                    <div>{intl.formatMessage({id:'Done'})}</div>
                </div>
            </div>

        </div>
    );



};

export default withCustomErrorBoundary(BoardsSettings);


