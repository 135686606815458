var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import classnames from 'clsx';
import 'firebase/storage';
import { browserHistory } from 'react-router';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { toastr } from 'react-redux-toastr';
import RecurrentTask from '../RecurrentTask';
import { getAccountPlan, getAccountCostBudgetSetting, getAccountBasePermissionsOwners, getAccountBasePermissionsManagers, getAccountTaskCanManage, getAccountTaskCanAdmin, getAccountTaskParent, getAccountTaskIsRecurring, getAccountTaskHasChildren, getAccountTaskISourceId, } from '../../utils/selectors/account';
import { getUserPermissions, getUserId } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
var TaskDetailMenu = function (_a) {
    var _b = _a.taskId, taskId = _b === void 0 ? '' : _b, currentUrl = _a.currentUrl, onShowEmailToTask = _a.onShowEmailToTask, onClose = _a.onClose, deleteTask = _a.deleteTask, isSyncAppTask = _a.isSyncAppTask;
    var _c = __read(useState(null), 2), isOpen = _c[0], setIsOpen = _c[1];
    var _d = __read(useState(false), 2), isOpenReccurrentTask = _d[0], setOpenReccurentTask = _d[1];
    var taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    var taskCanAdmin = useSelector(getAccountTaskCanAdmin(taskId));
    var taskParent = useSelector(getAccountTaskParent(taskId));
    var taskIsRecurring = useSelector(getAccountTaskIsRecurring(taskId));
    var taskHasChildrens = useSelector(getAccountTaskHasChildren(taskId));
    var taskSourceId = useSelector(getAccountTaskISourceId(taskId));
    var canManageParent = useSelector(getAccountTaskCanManage(taskParent || ''));
    var accountPlan = useSelector(getAccountPlan);
    var isBudgetManagementOn = useSelector(getAccountCostBudgetSetting);
    var accountOwners = useSelector(getAccountBasePermissionsOwners);
    var accountManagers = useSelector(getAccountBasePermissionsManagers);
    var userPermissions = useSelector(getUserPermissions);
    var userId = useSelector(getUserId);
    var t = useInternalization().t;
    var handlePermissions = function () {
        if (accountPlan !== 'basic' && accountPlan !== 'standard') {
            setIsOpen(null);
            browserHistory.push("/".concat(currentUrl, "/").concat(taskId, "/permissions"));
        }
    };
    var openBudgetManagement = function () {
        setIsOpen(null);
        browserHistory.push("/".concat(currentUrl, "/").concat(taskId, "/budget"));
    };
    var handleHistory = function () {
        setIsOpen(null);
        browserHistory.push("/".concat(currentUrl, "/").concat(taskId, "/history"));
    };
    var openDuplicateTask = function () {
        setIsOpen(null);
        if (taskSourceId && !taskParent) {
            toastr.confirm(t('synchronization.duplicate.alert'), {
                onOk: function () {
                    browserHistory.push("/".concat(currentUrl, "/").concat(taskId, "/duplicate"));
                },
                okText: t('CONTINUE'),
                cancelText: t('CANCEL'),
            });
            return;
        }
        browserHistory.push("/".concat(currentUrl, "/").concat(taskId, "/duplicate"));
    };
    var handleOpen = function (e) {
        if (e.currentTarget) {
            setIsOpen(e.currentTarget);
        }
    };
    var handleClose = function () {
        setIsOpen(null);
    };
    var handleOpenReccurentTask = function () {
        setIsOpen(null);
        setOpenReccurentTask(!isOpenReccurrentTask);
    };
    var handleEmailToTask = function () {
        setIsOpen(null);
        onShowEmailToTask();
    };
    var hasPermission = taskCanManage
        && ((taskParent && (taskCanAdmin || canManageParent))
            || (!taskParent
                && ((userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.admin) || accountOwners.indexOf(userId) !== -1 || accountManagers.indexOf(userId) !== -1)));
    var isRecurrentTask = taskIsRecurring;
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleOpen },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Menu, { open: !!isOpen, className: "taskDetailsMoreMenu", anchorEl: isOpen, keepMounted: true, onClose: handleClose },
            React.createElement(MenuItem, { onClick: onClose }, t('Close')),
            !isRecurrentTask && !isSyncAppTask && (React.createElement(MenuItem, { onClick: handleEmailToTask }, t('Email to task setting'))),
            hasPermission && !taskSourceId && (React.createElement(MenuItem, { onClick: deleteTask }, t('Delete task'))),
            (!isRecurrentTask || (isRecurrentTask && taskHasChildrens)) && hasPermission && (React.createElement(MenuItem, { onClick: openDuplicateTask }, t('Duplicate'))),
            hasPermission && taskHasChildrens && isBudgetManagementOn && (React.createElement(MenuItem, { onClick: openBudgetManagement }, t('Financials'))),
            taskCanAdmin && (React.createElement(MenuItem, { className: classnames('premium', {
                    onlyHigher: accountPlan === 'basic' || accountPlan === 'standard',
                }), onClick: handlePermissions }, t('Permissions'))),
            hasPermission && React.createElement(MenuItem, { onClick: handleHistory },
                " ",
                t('Changes history'),
                " "),
            hasPermission && !taskHasChildrens && !isRecurrentTask && !isSyncAppTask && (React.createElement(MenuItem, { onClick: handleOpenReccurentTask }, t('Make task recurrent')))),
        isOpenReccurrentTask && React.createElement(RecurrentTask, { taskId: taskId, handleClose: handleOpenReccurentTask })));
};
export default withCustomErrorBoundary(TaskDetailMenu);
