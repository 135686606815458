import React, { useCallback, useEffect } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TeamIcon from '@material-ui/icons/Group';
import SkillsIcon from '@material-ui/icons/School';
import AccountIcon from '@material-ui/icons/AccountBalanceWallet';
import DaysoffIcon from '@material-ui/icons/Event';
import UserIcon from '@material-ui/icons/AccountCircle';
import ImportDataIcon from '@material-ui/icons/CloudDownload';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BoardIcon from '@material-ui/icons/ViewColumn';
import TagsIcon from '@material-ui/icons/Label';
import CustomFieldsIcon from '@material-ui/icons/HdrWeak';
import WorkspaceIcon from '@material-ui/icons/Domain';
import FinancialsIcon from '@material-ui/icons/Money';
import PermissionsIcon from '@material-ui/icons/Security';
import ApiIcon from '@material-ui/icons/VpnKey';

import classnames from 'clsx';
import {canSee} from '../../utils/userFunctions';

import NotInMobile from '../NotInMobile/NotInMobile';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import SectionTitle from '../SectionTitle/SectionTitle';
import { Settings as SettingsIcon } from '@material-ui/icons';
import moment from 'moment';
import { getAccountEndPlanAt, getAccountIsTrial, getAccountPlanNbusers, getAccountUsers } from '../../utils/selectors/account';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import _ from 'underscore';

const Settings = ({location, children})=>{
    const intl = useIntl();
    const userPermissions = useSelector(getUserPermissions);
    const endPlanAt = useSelector(getAccountEndPlanAt);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const accountUsers = useSelector(getAccountUsers);
    const isTrial = useSelector(getAccountIsTrial);
    const memberResources = getOnlyResourceUsers(accountUsers);

    useEffect(()=>{
        firebaseEvents.addChallenge('accessSettings');
    }, []);

    const isRoute = (route) => {
        var regex = new RegExp('^' + route);
        return (regex.test(location.pathname));
    };

    const getRoute = useCallback((type, route, name, icon) => {
        var link = ()=>browserHistory.push(route),
            itemClass = classnames({active: isRoute(route)});

        const canSeePermissions = ['financials', 'permissions', 'apikeys'].includes(type) ? ['admin'] : ['admin', type];
        const canSeeWhenPlanIsOver = ['Members', 'Subscription'];
        const isPlanOver = !moment(endPlanAt).isSameOrAfter(moment(), 'day');
        const limitResourcesReached = !isTrial && _.size(memberResources) > parseFloat(planNbusers);

        return (
            <ListItem
                button
                className={itemClass}
                onClick={link}
                disabled={
                    ((isPlanOver || limitResourcesReached) && !canSeeWhenPlanIsOver.includes(name)) ||
                    (type !== null && !canSee(canSeePermissions, { data: { permissions: userPermissions }}))
                }
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={intl.formatMessage({id: name})} />
            </ListItem>
        );
    }, [userPermissions, endPlanAt, isTrial, memberResources, planNbusers]);


    return (
        <div className='Settings'>
            <List className="menu" data-sidebar='preventClose'>
                <SectionTitle title={intl.formatMessage({id:'Settings'})} icon={<SettingsIcon />} />
                {getRoute(null, '/settings/my-account', 'My Account', <UserIcon />)}
                <Divider />
                {getRoute('admin', '/settings/workspace', 'Workspace', <WorkspaceIcon />)}
                <Divider />
                {getRoute('team', '/settings/team-members', 'Members', <TeamIcon />)}
                <Divider />
                {getRoute('skills', '/settings/skills', 'Skills', <SkillsIcon />)}
                <Divider />
                {getRoute('daysoff', '/settings/daysoff', 'Global Days-off', <DaysoffIcon />)}
                <Divider />
                {getRoute('boards', '/settings/boards', 'Kanban boards', <BoardIcon />)}
                <Divider />
                {getRoute('tags', '/settings/tags', 'Tags', <TagsIcon />)}
                <Divider />
                {getRoute('customFields', '/settings/customFields', 'Custom fields', <CustomFieldsIcon />)}
                <Divider />
                {getRoute('financials', '/settings/financials', 'Financials', <FinancialsIcon />)}
                <Divider />
                {getRoute('permissions', '/settings/permissions', 'Permissions', <PermissionsIcon />)}
                <Divider />
                {getRoute('admin', '/settings/import-data', 'Import Data', <ImportDataIcon />)}
                <Divider />
                {getRoute('admin', '/settings/integrations', 'Integrations', <AccountTreeIcon />)}
                <Divider />
                {getRoute('apikeys', '/settings/apikeys', 'Api keys', <ApiIcon />)}
                <Divider />
                {getRoute('subscription', '/settings/subscription', 'Subscription', <AccountIcon />)}
            </List>

            <div className={classnames('content', {hideonMobile: location.pathname.indexOf('/settings/my-account') === -1})}>
                {children}
            </div>
            {location.pathname.indexOf('/settings/my-account') === -1 && <NotInMobile />}
        </div>
    );

};

export default withRouter(withCustomErrorBoundary(Settings));