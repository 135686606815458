import _ from 'underscore';
import moment from 'moment';

export const getTimeWorked = ({ taskHasChildrens, parentChildIds, taskId, tasks, taskWorkingTime }) => {
    let totalHours = 0;
        
    if (taskHasChildrens) {
        let childrens = [];
        _.each(parentChildIds?.parents, (childParents, childId) => {
            if (childParents.indexOf(taskId) !== -1) {
                childrens.push(childId);
            }
        });
            
        _.each(childrens, child => {
            if (tasks && tasks[child]) {
                _.each(tasks[child]?.workingTime, (workTimeChild) => {
                    const nbWorkers = (!workTimeChild?.member || typeof workTimeChild?.member === 'string') ? 1 : workTimeChild?.member.length;
                    totalHours += parseFloat(workTimeChild?.hours)*nbWorkers;
                });
            }
        });
    }
        
    _.each(taskWorkingTime, (workTime) => {
        const nbWorkers = (typeof workTime?.member === 'string') ? 1 : workTime?.member?.length;
        totalHours += parseFloat(workTime.hours)*nbWorkers;
    });

    if(totalHours === 0){
        return ' - ';
    }

        
    let duration = moment.duration(totalHours, 'hours');
    const hours = Math.floor(duration.asHours());
    duration.subtract(moment.duration(hours, 'h'));
    const minutes = Math.floor(duration.asMinutes());        

    return `${hours>0 ? hours+'h':''}${minutes>0 ? minutes+'m':''}`;
};
