import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import _ from 'underscore';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Switch, Checkbox, Tooltip } from '@material-ui/core';

const LineItem = ({ 
    itemData,
    itemId, 
    handleCheckItem, 
    handleToggleImportSubtasks,
    orderedProjectList = [],
    name,
    parentIds = [], 
    childIds = [], 
    nestedChildrenIds = [], 
    syncList = [], 
    checkedItems = [], 
    isImportingSubtasks = false,
    step = 1,
    parent = null,
}) => {
    const [openArrow, setOpenArrow] = useState(true);
    const alreadySynchronized = !!(
        syncList.find(el=>el.selectedItem===itemId) || 
        nestedChildrenIds.some(childId=>syncList.map(el=>el.selectedItem).includes(childId))
    );
    const isDisabled = (itemData.type === 'space' && _.isEmpty(childIds)) || (step === 1 && alreadySynchronized);
    const hasChildren = !_.isEmpty(childIds);


    const handleCheckItemId = React.useCallback(() => {
        if(step === 2 || isDisabled) return;

        handleCheckItem({
            item: {...itemData, childIds},
            parent: parent
        });
    }, [step, itemData, parent, childIds]);


    const handleCloseParent = React.useCallback((e) => {
        e.stopPropagation();

        e.target.closest('.ProjectsTreeView').childNodes.forEach(childItem => {
            if(childItem.classList.contains('ProjectsTreeView')) {
                childItem.classList.toggle('hidden');
            }
        });

        setOpenArrow(!openArrow);
    }, [openArrow]);

    const handleToggleSwitch = () => handleToggleImportSubtasks(itemData);

    const isChecked = checkedItems.includes(itemId) || 
        parentIds.some(id=>checkedItems.includes(id)) ||
        (hasChildren && childIds.every(id=>checkedItems.includes(id))) ||
        (hasChildren && nestedChildrenIds.every(id=>checkedItems.includes(id)));


    const isIndeterminate = isDisabled || (
        (
            !checkedItems.includes(itemId) && 
            hasChildren
        ) && 
        (
            (checkedItems.some(id=>childIds.includes(id)) && !childIds.every(id=>checkedItems.includes(id))) 
          || 
            (checkedItems.some(id=>nestedChildrenIds.includes(id)) && !nestedChildrenIds.every(id=>checkedItems.includes(id)))
        )
    );


    const amountChildrenImportingSubTasks = () => {
        const childrenFromSyncList = nestedChildrenIds.filter(childId => 
            _.isEmpty(orderedProjectList.find(item=>item.id===childId)?.nestedChildrenIds) && 
            syncList.find(item=>item.selectedItem === childId)
        );

        if(childrenFromSyncList.every(childId => syncList.find(item=>item.selectedItem === childId)?.importSubtasks)) {
            return 'every';
        }

        if(childrenFromSyncList.some(childId => syncList.find(item=>item.selectedItem === childId)?.importSubtasks)) {
            return 'some';
        }

        return 'none';
    };

    const getDisabledTooltipContent = () => {
        return alreadySynchronized ? 'Project already synchronized.' : 'Empty spaces cannot be selected.';
    };

    return (
        <div  
            onClick={handleCheckItemId}
            className={classnames('SelectProject__outerline', {disabled: isDisabled})} 
            id={itemId} 
            key={itemId}
        >   
            <div 
                style={{marginLeft: `${parentIds.length * 2}rem`}} 
                className={classnames('SelectProject__innerline', {showSwitchOnHover: step === 2 && hasChildren})} 
            >

                {hasChildren && openArrow && <KeyboardArrowDown onClickCapture={handleCloseParent}/>}
                {hasChildren && !openArrow && <KeyboardArrowRight onClickCapture={handleCloseParent}/>}

                <span>{name}</span>

                {step === 1 && (
                    <Tooltip 
                        placement={'right'} 
                        title={getDisabledTooltipContent()} 
                        arrow 
                        disableFocusListener={!isDisabled} 
                        disableHoverListener={!isDisabled} 
                        disableTouchListener={!isDisabled}
                    >
                        <Checkbox
                            className={isDisabled && 'disabled'}
                            color='secondary'
                            onChange={handleCheckItemId}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={isChecked}
                            indeterminate={isIndeterminate}
                        />
                    </Tooltip>
                )}

                {step===2 && !isDisabled && (
                    <Switch 
                        className={classnames('', {
                            ghostChecked: amountChildrenImportingSubTasks() === 'some'
                        })}
                        checked={
                            isImportingSubtasks || 
                            (   
                                hasChildren && 
                                amountChildrenImportingSubTasks() === 'every' || 
                                amountChildrenImportingSubTasks() === 'some'
                            )
                        } 
                        size={'small'} 
                        onClick={handleToggleSwitch}
                    />
                )}

            </div>
        </div>
    );
};

LineItem.Proptypes = {
    itemData: PropTypes.object.isRequired,
    itemId: PropTypes.string.isRequired,
    handleSelectSubTask: PropTypes.func,
    handleCheckItem: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    parentIds: PropTypes.array.isRequired,
    childIds: PropTypes.array,
    nestedChildrenIds: PropTypes.array,
    syncList: PropTypes.array,
    orderedProjectList: PropTypes.array,
    checkedItems: PropTypes.array,
    step: PropTypes.number.isRequired,
    parent: PropTypes.object,
    isImportingSubtasks: PropTypes.bool.isRequired,
    syncAppId: PropTypes.string
};

export default memo(LineItem);