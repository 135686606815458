export const JIRA_ESTIMATION_TYPES = {
    ORIGINAL : 'original',
    STORYPOINT : 'storyPoint'
};

export const effortChangeTypeFields = {
    onAddProject: 'import',
    onToolUpdate: 'onUpdate'
};

export const EVENT_NAMES_BY_TOOL = {
    clickup: {
        0: 'dontSyncClickUpEffort',
        1: 'syncClickUpEffort'
    },
    jira: {
        0: 'dontSyncJiraEffort',
        1: 'syncJiraEffort'
    }
};

export const effortChangeTypes = {
    clickup: {
        onAddProject: {
            title: 'When adding a project:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
        onToolUpdate: {
            title: 'When updated on ClickUp:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
    },
    jira: {
        onAddProject: {
            title: 'When adding a project:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
        onToolUpdate: {
            title: 'When updated on Jira:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
    },
    jiraServer: {
        onAddProject: {
            title: 'When adding a project:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
        onToolUpdate: {
            title: 'When updated on Jira Server:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
    },
    asana: {
        onAddProject: {
            title: 'When adding a project:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
        onToolUpdate: {
            title: 'When updated on ClickUp:',
            options: ['Don\'t synchronize', 'Synchronize'],
        },
    },
};

export const getNextFibonacciNumber = ({ prevNumbers }) => {
    if(prevNumbers.length < 1) return 1;
    if(prevNumbers.at(-1) === 1) return 2;

    return prevNumbers.at(-1) + prevNumbers.at(-2);
};

export const getNewEffortRange = (currBiggerMaxEffort) => ({min: currBiggerMaxEffort, max: currBiggerMaxEffort*2});

export const formatShortCuts = ({min, max}) => {
    const regex = new RegExp(/\d(m|d)?$/);

    let minvalue = min;
    let maxvalue = max;

    if(!regex.test(minvalue)) {
        minvalue = 0;
    }

    if (minvalue[minvalue.length - 1] === 'd') {
        minvalue = minvalue.slice(0, minvalue.length - 1) * 8;
    } else if (minvalue[minvalue.length - 1] === 'm') {
        minvalue = minvalue.slice(0, minvalue.length - 1) / 60;
    }

    if(!regex.test(maxvalue)) {
        maxvalue = 0;
    }

    if (maxvalue[maxvalue.length - 1] === 'd') {
        maxvalue = maxvalue.slice(0, maxvalue.length - 1) * 8;
    } else if (maxvalue[maxvalue.length - 1] === 'm') {
        maxvalue = maxvalue.slice(0, maxvalue.length - 1) / 60;
    }

    if(maxvalue < minvalue) {
        maxvalue = minvalue;
    }

    return {
        min: parseFloat(parseFloat(minvalue).toFixed(2)),
        max: parseFloat(parseFloat(maxvalue).toFixed(2)),
    };
};