import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NestedList from './NestedList';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import Select from '@material-ui/core/Select';
import { useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import classnames from 'clsx';

const buildSkillsTree = ({skills,accountSkills, accountSkillsGroups}) => skills.reduce((acc, skillKey) => {
    const skill = accountSkills[skillKey];
    if(!skill) {
        return acc;
    }
    if(!skill.group){
        acc[skillKey] = {
            title: skill.name,
            id: skillKey
        };            
    } else if(acc[skill.group]) {
        acc[skill.group].childs.push({
            id: skillKey,
            title: skill.name
        }); 
    } else {
        acc[skill.group] = {
            title: accountSkillsGroups[skill.group].title,
            childs: [{
                id: skillKey,
                title: skill.name
            }]
        };
    }

    return acc;
}, {}); 

const buildTeamMembersTree = ({teamMembers, accountTeamMembers, accountUserGroups}) => teamMembers.reduce((acc, teamMemberKey) => {
    const teamMember = accountTeamMembers[teamMemberKey];
    if(!teamMember || teamMember.viewer) {
        return acc;
    }
    if(!teamMember.group){
        acc[teamMemberKey] = {
            title: teamMember.displayName,
            id: teamMemberKey
        };            
    }else if(acc[teamMember.group]){
        acc[teamMember.group].childs.push({
            id: teamMemberKey,
            title: teamMember.displayName
        }); 
    }else{
        acc[teamMember.group] = {
            title: accountUserGroups[teamMember.group].title,
            childs: [{
                id: teamMemberKey,
                title: teamMember.displayName
            }]
        };
    }

    return acc;
}
, {});

const buildTaskTree = ({accountTasks, tasks}) => tasks.reduce((acc, el) => {
    const currentTask = accountTasks[el.id];
    if(currentTask && currentTask.canManage){
        return  currentTask ? {
            ...acc,
            [el.id]: {
                title: currentTask.title,
                id: el.id
            }
        } : acc;
    }

    return acc;
}, {}); 

const buildExpenseTypes = ({expenseTypes}) => Object.keys(expenseTypes).reduce((acc, el) => {
    acc[el] = {
        title: expenseTypes[el].name,
        id: el
    };
    return acc;
}, {}); 

const treeBuildAdapter = {
    skills: buildSkillsTree,
    users: buildTeamMembersTree,
    tasks: buildTaskTree,
    expenseTypes: buildExpenseTypes,
};


export default function BreakdownLvL({
    tasks,
    skills,
    teamMembers,
    options,
    handleChange,
    updateCheckboxs,
    currentLvl,
    currentValue,
    enabled
}) {
    const intl = useIntl();
    const accountSkills = useSelector((state) => state.app?.account?.skills || {});
    const accountSkillsGroups = useSelector((state) => state.app?.account?.skillGroups || {});
    const accountUserGroups = useSelector((state) => state.app?.account?.userGroups || {});
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountTasks = useSelector((state) => state.app?.account?.tasks || {});
    const expenseTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes || {});
    const [isOpen, setIsOpen] = useState(false);
    const breakdownRef = useRef(null);
    const [selectedBoxs, setSelectedBox] = React.useState([]);

    useEffect(() => {
        if(currentValue){
            setIsOpen(breakdownRef.current);
        }
    }, [currentValue]);

    let treeToUse = {};
 
    if( currentValue !== 'none' ) {
        treeToUse = treeBuildAdapter[currentValue]({
            skills, accountSkills, accountSkillsGroups,
            teamMembers, accountTeamMembers, accountUserGroups,
            accountTasks, tasks, expenseTypes
        });
    }

    const allItens = treeToUse ? Object.values(treeToUse)
        .map(el => el.childs || [{id: el.id}])
        .flat()
        .map(el => el.id) : [];


    const handlePopover = (event) => {
        setIsOpen(event.currentTarget);
    };
    const handleClose = () => {
        setIsOpen(null);
    };
    
    const handleCheckbox = (id) => {
        let newSelectedBoxs; 
        if(selectedBoxs.includes(id)) {
            newSelectedBoxs = selectedBoxs.filter(el => el !== id); 
        }else {
            newSelectedBoxs = [...selectedBoxs, id]; 
        }
        setSelectedBox(newSelectedBoxs);
        updateCheckboxs(currentLvl, newSelectedBoxs);
    };

    const handleSelectAll = (data) => {
        setSelectedBox(data);
        updateCheckboxs(currentLvl, data);
    };

    return <div className={classnames('breakdowns', { blocked: !enabled })} style={{ position: 'relative', display: 'flex', marginRight: '1rem'}}>
        <FormControl fullWidth>
            <InputLabel id="top-lelel-relector"> {currentLvl === 0 ? 'Primary breakdown' : 'Secondary breakdown'}</InputLabel>
            <Select
                id="select"
                value={currentValue}
                size="small"
                className="breakdownSelect"
                fullWidth
                renderValue={(value) => intl.formatMessage({id: `breakdown.${value}` }, { data: allItens.length === selectedBoxs.length ? 'All' :  selectedBoxs.length })}
            >     
                {
                    options.map(el => ( <MenuItem 
                        key={el.value}  
                        value={el.value}
                        className="breakdownMenuItem"
                        onClick={() => handleChange(currentLvl, el.value)}
                    >
                        {el.text}
                    </MenuItem> ))
                }
            </Select>  
        </FormControl>     
        { currentValue !=='none' && <>
            <div 
                ref={breakdownRef}
                className="settingsIcon"
                onClick={handlePopover}
            >   
                <SettingsIcon/>
            </div>   
            <Popover
                anchorEl={isOpen}
                open={!!isOpen}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                onClose={handleClose}
                className="breakdownSettings"
            >
                <NestedList
                    handleCheckbox={handleCheckbox}
                    handleSelectAll={handleSelectAll}
                    checkboxData={selectedBoxs}
                    tree={treeToUse} 
                />
            </Popover></>}
    </div>;
}