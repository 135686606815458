
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import { browserHistory } from 'react-router';
import { getAccountId, getAccountSkills } from '../../utils/selectors/account';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';

import Proptypes from 'prop-types';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const SkillLine = ({ name, id, paramsId, deleteSkill }) => {
    const intl = useIntl();
    const [skillName, setSkillName] = useState('');
    const accountId = useSelector(getAccountId);
    const accountSkills = useSelector(getAccountSkills);

    useEffect(() => {
        if(name) {
            setSkillName(name);
        }
    }, [name]);

    const changeName = (e) => {
        setSkillName(e.target.value);
    };

    const saveName = () => {
        const newName = skillName.trim() !== '' ? skillName : 'Untitled';
        firebaseEvents.addChallenge('changeSkillName');
        firebaseEvents.updateSkillData(accountId,id, {name: newName});
    };

    const getDeleteButton = () => {
        let deleteButton = (
            <Button title={intl.formatMessage({id: 'Delete'})} onClickCapture={(e)=> deleteSkill(e, id, accountId)}>
                <DeleteIcon />
            </Button>
        );

        if(Object.keys(accountSkills).length === 1){
            deleteButton = null;
        }

        return deleteButton;
    };

    const handleEditSkill = (id) => {
        browserHistory.push('/settings/skills/' + id);
    };

    return (
        <>
            <div data-sidebar='preventClose' data-skillid={id} className={classnames('skillLine', {onSidebar: paramsId === id})} onClick={()=> handleEditSkill(id)}>
                <input className='skillLineInput' value={skillName} onBlur={saveName} onChange={changeName} data-sidebar='preventClose'/>

                <Button title={intl.formatMessage({id: 'Edit skill'})} >
                    <EditIcon />
                </Button>
                {getDeleteButton()}
            </div>
        </>
    );
};

SkillLine.propTypes = {
    name: Proptypes.string,
    id: Proptypes.string.isRequired,
}; 

export default withCustomErrorBoundary(SkillLine);


