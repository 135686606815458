import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';
import { currencyFormat  } from '../../../utils/currencyFormat';

const CostBudgetTable = ({ onColumnHover, datasets, labels, userDateFormat, currency }) =>   {
    const renderColumns = useCallback(((row, isChild, datasetsParents) => {
        const defaultExpenses = datasetsParents.find(({id}) => id === 'defaultExpenses');
        const defaultBudget = datasetsParents.find(({id}) => id === 'defaultBudget');
        if(row.id === 'defaultBudget' || row.id === 'defaultExpenses'){
            return null;
        }
        
        const titleRow = [{ isName: true,  value: row.name , isChild } ];
        const internalCostRow = [{ isTitle: true, value: 'Internal Cost (with expenses)' } ];
        const internalCostWRow = [{ isTitle: true, value: 'Internal Cost (without expenses)' } ];
        const billableCostRow = [{ isTitle: true, value: 'Billable Cost (with expenses)' } ];
        const billableCostWRow = [{ isTitle: true, value: 'Billable Cost (without expenses)' } ];
        const budget = [{ isTitle: true, value: 'Budget' } ];

        labels.forEach((l, idx) => {
            internalCostRow.push( { value:  currencyFormat({ value: row.internalCost[idx], currency: currency }) , isChild } );
            billableCostRow.push( { value:  currencyFormat({ value: row.billableCost[idx], currency: currency }) , isChild } );
            internalCostWRow.push( { value:  currencyFormat({ value: row.internalCost[idx]  - defaultExpenses.expenses[idx], currency: currency }) , isChild } );
            billableCostWRow.push( { value:  currencyFormat({ value: row.billableCost[idx]  - defaultExpenses.expenses[idx], currency: currency }), isChild } );
            budget.push( { value:  currencyFormat({ value: defaultBudget.budgetValue[idx], currency: currency }) , isChild } );
            titleRow.push( { value: null, isChild  } );
        });

        return [ 
            titleRow,
            internalCostRow,
            internalCostWRow,
            billableCostRow,
            billableCostWRow,
            budget
        ];
    }),[labels]);
    const formatTitle = (title) =>  getDateFormated(title);

    return <Table
        size="small"
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
        userDateFormat={userDateFormat} />;
};

export default React.memo(CostBudgetTable);