import { IconButton } from '@material-ui/core';
import Tooltip from 'rc-tooltip';
import { Save } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import useInternalization from '../../utils/hooks/useInternalization';
import { getAccountCustomFields, getAccountSkills, getAccountTags, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { getLimitViewTo, getParentChildIds, getTimelineTasksToShow } from '../../utils/selectors/app';
import { getUserId } from '../../utils/selectors/user';
import { tracking } from '../../utils/utils';
import { getPrevisionValue } from '../../utils/costBudget';

const ExportData = ()=>{

    const { t } = useInternalization();
    const PRIORITES = [t('Normal'),t('Medium'),t('High'),t('Urgent')];

    const tasksToShow = useSelector(getTimelineTasksToShow);
    const accountTasks = useSelector(getAccountTasks);
    const parentChildIds = useSelector(getParentChildIds);
    const limitView = useSelector(getLimitViewTo);
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const accountSkills = useSelector(getAccountSkills);
    const accountCustomFields = useSelector(getAccountCustomFields);
    const accountTags = useSelector(getAccountTags);




    const getTags = (task) => {
        return task.tags.map(id => accountTags[id] ? accountTags[id].title || '' : '').join(', ');
    };


    const getPeople = (userIds) => {
        return userIds ? userIds.map(id => accountUsers[id] ? accountUsers[id].displayName || 'Untitled' : 'Delete user').join(', ') : '';
    };

    const getAssignees = (task) => {

        let userId = [];
        if (task.status === 'inprogress') {
            userId = task.userWorking || task.estimations?.userId || task.forcedUser;
        } else if (task.status !== 'done') {
            if (!task.nbWorkers || task.nbWorkers === 1) {
                if (task.forcedUser) {
                    userId = task.forcedUser;
                } else if (task.estimations?.userId) {
                    userId = task.estimations?.userId;
                }
            } else {
                if (task.forcedUser && task.forcedUser.length === task.nbWorkers) {
                    userId = task.forcedUser;
                } else if (task.estimations?.userId) {
                    userId = task.estimations?.userId;
                }
            }
        } else if (task.doneBy) {
            userId = task.doneBy;
        } else if (task.userWorking) {
            userId = task.userWorking;
        }
        if(task.status === 'done'){
            userId = task.doneBy || task.forcedUser || task.userWorking;
        }

        if (typeof userId === 'string') {
            userId = [userId];
        }

        return getPeople(userId);
    };

    const getTimeWorked = (task)=>{
        let totalHours = 0;

        if (task.childrens) {
            let childrens = [];
            _.each(parentChildIds?.parents, (childParents, childId) => {
                if (childParents.indexOf(task.id) !== -1) {
                    childrens.push(childId);
                }
            });

            _.each(childrens, child => {
                if (accountTasks && accountTasks[child]) {
                    _.each(accountTasks[child]?.workingTime, (workTimeChild) => {
                        const nbWorkers = (!workTimeChild?.member || typeof workTimeChild?.member === 'string') ? 1 : workTimeChild?.member.length;
                        totalHours += parseFloat(workTimeChild?.hours)*nbWorkers;
                    });
                }
            });
        }

        _.each(task.workingTime, (workTime) => {
            const nbWorkers = (typeof workTime?.member === 'string') ? 1 : workTime?.member?.length;
            totalHours += parseFloat(workTime.hours)*nbWorkers;
        });

        if(totalHours === 0){
            return '';
        }


        let duration = moment.duration(totalHours, 'hours');
        const hours = Math.floor(duration.asHours());
        duration.subtract(moment.duration(hours, 'h'));
        const minutes = Math.floor(duration.asMinutes());

        return `${hours>0 ? hours+'h':''}${minutes>0 ? minutes+'m':''}`;
    };

    const injectTaskCosts  = async (task) => {
        let checkWorktimeEntry = null;
        let checkTaskEntry = null;
        if (task.costBudgetSettings?.type === 'retainer') {
            checkWorktimeEntry = entry => moment(entry.date).isSame(moment(), task.costBudgetSettings?.periodicity);
            checkTaskEntry = task => {
                const date = _.get(task, ['estimations', 'expectedAt'], null);

                return !date || moment(date, 'X').isSame(moment(), task.costBudgetSettings?.periodicity);
            };
        }

        const internalCost = await getPrevisionValue.now.internal(
            task,
            accountTasks,
            accountUsers,
            checkWorktimeEntry,
        );
        const billableCost = await getPrevisionValue.now.billable(
            task,
            accountTasks,
            accountUsers,
            checkWorktimeEntry,
        );
        const futureInternalCost = await getPrevisionValue.future.internal(
            task,
            accountTasks,
            accountUsers,
            accountSkills,
            checkTaskEntry,
        );
        const futureBillableCost = await getPrevisionValue.future.billable(
            task,
            accountTasks,
            accountUsers,
            accountSkills,
            checkTaskEntry,
        );

        return {
            ...task,
            internalCost,
            billableCost,
            futureInternalCost,
            futureBillableCost,
        };
    };

    const doExportCsv = (data)=>{

        const csvContent = 'data:text/csv;charset=utf-8,' + data;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('data-sidebar', 'preventClose');
        link.setAttribute('download', 'export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        tracking('Exported timeline data to CSV file', userId);
    };

    const exportData = async () => {
        let maxSubLevels = 0;

        let mappedTasks = tasksToShow.map(taskId => {
            const taskData = accountTasks[taskId];
            let nbParents = parentChildIds?.parents[taskId].length || 0;
            if(limitView){
                nbParents -= (parentChildIds?.parents[limitView].length+1);
            }
            if(nbParents>maxSubLevels){
                maxSubLevels = nbParents;
            }
            nbParents++;

            return { ...taskData, taskId, nbParents };
        });

        maxSubLevels++;


        let data = '"Folders and tasks",' + new Array(maxSubLevels).join('"",');

        // define column headers
        data += '"ID","Status","Nb workers","Assignee(s)","Skill","Min. Effort","Max. Effort","Priority",';
        data += '"Due date","Dependencies","Tags","Start on","Wait until","Time worked","Start working","Expected finish","Earliest finish","Latest finish",';

        // get headers for custom fields
        if(accountCustomFields){
            Object.keys(accountCustomFields).sort((a, b)=> accountCustomFields[a].name.toUpperCase() > accountCustomFields[b].name.toUpperCase() ? 1 : -1).forEach(cfId=>{
                data += `"${accountCustomFields[cfId].name}",`;
            });
        }

        data += '"Budget","Budget type","Actual internal cost","Actual billable cost","Forecasted internal cost","Forecasted billable cost"';

        data += '\n';

        // inject tasks costs

        mappedTasks = await Promise.all(mappedTasks.map(task=>injectTaskCosts(task)));

        // get the data for each task
        mappedTasks.forEach(task => {
            // set the amount of columns before
            data += new Array(task.nbParents).join('"",');
            data += `"${task.title}",`;
            if(maxSubLevels-task.nbParents+1 > 0){
                data += new Array(maxSubLevels-task.nbParents+1).join('"",');
            }
            data += `"${task.taskId}",`;
            data += !task.childrens ? `"${t(task.status || 'todo')}",` : '"",';
            data += !task.childrens ? `"${task.nbWorkers || 1}",` : '"",';
            data += !task.childrens ? `"${getAssignees(task)}",` : '"",';
            data += !task.childrens && task?.skill?.length ? `"${task.skill.map(skillId => `${accountSkills[skillId]?.name || ''}`).join(' | ')}",` : '"",';
            data += `"${task.minEffort || 0}",`;
            data += `"${task.maxEffort || 0}",`;
            data += !task.childrens ? `"${PRIORITES[task.priority || 0]}",` : '"",';
            data += task.deadline ? `"${task.deadline}",` : '"",';
            data += task.dependencies ? `"${task.dependencies.join(',')}",` : '"",';
            data += task.tags ? `"${getTags(task)}",` : '"",';
            data += !task.childrens && task.startOn ? `"${task.startOn}",` : '"",';
            data += task.delayUntil ? `"${task.delayUntil}",` : '"",';
            data += `"${getTimeWorked(task)}",`;
            data += task?.estimations?.startAt ? `"${moment(task.estimations.startAt, 'X').format()}",` : '"",';
            data += task?.estimations?.expectedAt ? `"${moment(task.estimations.expectedAt, 'X').format()}",` : '"",';
            data += task?.estimations?.earliestAt ? `"${moment(task.estimations.earliestAt, 'X').format()}",` : '"",';
            data += task?.estimations?.endAt ? `"${moment(task.estimations.endAt, 'X').format()}",` : '"",';

            // get custom fields data
            if(accountCustomFields){
                Object.keys(accountCustomFields).sort((a, b)=> accountCustomFields[a].name.toUpperCase() > accountCustomFields[b].name.toUpperCase() ? 1 : -1).forEach(cfId=>{
                    if(task.customFields && task.customFields[cfId]){
                        if(accountCustomFields[cfId].type === 'checkbox'){
                            data += task.customFields[cfId] ? '"TRUE",' : '"FALSE",';
                        }
                        else if(accountCustomFields[cfId].type === 'people'){
                            data += `"${getPeople(task.customFields[cfId])}",`;
                        }
                        else {
                            data += `"${task.customFields[cfId]}",`;
                        }
                    }
                    else if(accountCustomFields[cfId].type === 'checkbox'){
                        data += '"FALSE",';
                    }
                    else {
                        data += '"",';
                    }
                });
            }

            // get budgets / costs / expenses - TODO
            data += task.childrens && task.costBudgetSettings?.value ? `"${task.costBudgetSettings.value}",` : '"",';
            data += task.childrens && task.costBudgetSettings?.type ? task.costBudgetSettings?.type === 'fixed' ? '"Fixed",' : `"Retainer (${task.costBudgetSettings?.periodicity})",` : '"",';
            data += `"${task.internalCost}",`;
            data += `"${task.billableCost}",`;
            data += `"${task.futureInternalCost}",`;
            data += `"${task.futureBillableCost}",`;
            data += '\n';

        });

        doExportCsv(data);
    };

    return <Tooltip overlay={t('Export data as CSV')} placement="bottom"><IconButton data-sidebar='preventClose' className="settings" variant='contained' onClick={exportData}><Save/></IconButton></Tooltip>;
};

export default ExportData;