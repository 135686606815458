export function isAdmin(app){
    return (app.user && app.user.data && app.user.data.permissions && app.user.data.permissions.admin);
}

export function canSee(requiredRoles, user){
    var can = false;

    for(var r in requiredRoles){
        if(user && user.data && user.data.permissions && user.data.permissions[requiredRoles[r]]){
            can = true;
            break;
        }
    }
    return can;
}

export function canSeePerm(requiredRoles, userPerm){
    var can = false;

    for(var r in requiredRoles){
        if(userPerm && userPerm[requiredRoles[r]]){
            can = true;
            break;
        }
    }
    return can;
}