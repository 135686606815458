import{ getChildsFromParent, getItemPermissions } from '../../utils';
import _ from 'underscore';
import { addChallenge, segmentTrack, db } from '../../firebaseEvents';
import { getAccountId, getAccountTasks } from '../../selectors/account';
import { getUserId } from '../../selectors/user';
import { getAppData, getState } from '../../../configureStore';

export const FOLLOWER_STATE_TYPES = {
    'manually':'manually',
    'inherited':'inherited'
};

export const isFollowTypeManually = (accountTasks, taskId, userId) => _.get(accountTasks, [taskId,'followers',userId]) === FOLLOWER_STATE_TYPES.manually;

export function followTask(taskId, userId) {
    const accountId = getAccountId(getState());
    const accountTasks = getAccountTasks(getState());
    const taskIdAndChildrensIds = [..._.flatten(getChildsFromParent(taskId, accountTasks)), taskId] || [];
    const accountData = getAppData().account;
    let updates = {};

    if(!accountTasks[taskId]?.childrens) {
        updates[`/accounts/${accountId}/tasks/${taskId}/followers/${userId}`] = FOLLOWER_STATE_TYPES.manually;
    } else {
        taskIdAndChildrensIds.forEach(currTaskId => {
            if(
                getItemPermissions(
                    userId, 
                    currTaskId, 
                    accountTasks[currTaskId].permissions, 
                    accountTasks, 
                    accountData.users[userId]?.permissions?.admin || false, 
                    accountData?.settings?.basePermissions)
                    .canView
            ){
                if(currTaskId === taskId) {
                    updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${userId}`] = FOLLOWER_STATE_TYPES.manually;
                }
                if(currTaskId !== taskId && !isFollowTypeManually(accountTasks, currTaskId, userId)) {
                    updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${userId}`] = FOLLOWER_STATE_TYPES.inherited;    
                }
            }
        });
    }

    const NumTaskFollowers = Object.keys(accountData.tasks[taskId].followers || {}).length || 0;

    segmentTrack('Manually follow task', { accountId, taskId, userId});
    segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers });
    
    addChallenge('followTask');
    db.update(undefined, updates);
}

export function unfollowTask(taskId, userId) {
    const accountId = getAccountId(getState());
    const accountTasks = getAccountTasks(getState());
    const activeUserId = getUserId(getState());
    const taskIdAndChildrensIds = [..._.flatten(getChildsFromParent(taskId, accountTasks)), taskId] || [];
    const accountData = getAppData().account;
    let updates = {};

    if(!accountTasks[taskId]?.childrens) {
        updates[`/accounts/${accountId}/tasks/${taskId}/followers/${userId}`] = null;
    } else {
        taskIdAndChildrensIds.forEach(currTaskId => {
            if(currTaskId === taskId || !isFollowTypeManually(accountTasks, currTaskId, userId)) {
                updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${userId}`] = null;
            }
        });
    }

    const NumTaskFollowers = Object.keys(accountData.tasks[taskId].followers || {}).length || 0;

    segmentTrack(userId === activeUserId ? 'Manually unfollow task' : 'Unfollow task by other', { accountId, taskId, userId});
    segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers });
    
    addChallenge('unfollowTask');
    db.update(undefined, updates);
}

export function updateTaskFollowers({ taskId, newFollowersIds = []}) {
    let updates = {};
    const accountData = getAppData().account;
    const accountTasks = getAccountTasks(getState());
    const activeUserId = getUserId(getState());
    const accountId = getAccountId(getState());
    const prevFollowersIds = !_.isEmpty(accountTasks[taskId].followers) ? Object.keys(accountTasks[taskId].followers) : [];
    const taskIdAndChildrensIds = [..._.flatten(getChildsFromParent(taskId, accountTasks)), taskId] || [];
    const prevAndNewfollowersIds = _.uniq([...prevFollowersIds, ...newFollowersIds]);

    if (!accountTasks[taskId]?.childrens) {
        updates = prevAndNewfollowersIds.reduce((acc, currId)=>{
            // removing followers
            if(prevFollowersIds.includes(currId) && !newFollowersIds.includes(currId)) {
                acc[`/accounts/${accountId}/tasks/${taskId}/followers/${currId}`] = null;
                return acc;
            }

            // adding followers
            acc[`/accounts/${accountId}/tasks/${taskId}/followers/${currId}`] = FOLLOWER_STATE_TYPES.manually;            
            return acc;
        }, {});
    } else {
        taskIdAndChildrensIds.forEach(currTaskId => {
            prevAndNewfollowersIds.forEach(currId => {

                // removing followers
                if(prevFollowersIds.includes(currId) && !newFollowersIds.includes(currId)) {
                    if(currTaskId === taskId) {
                        updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${currId}`] = null;
                    } 
                    else if(!isFollowTypeManually(accountTasks, currTaskId, currId)) {
                        updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${currId}`] = null;
                    }
                } 
                
                // adding followers
                else {
                    if(
                        getItemPermissions(
                            currId, 
                            currTaskId, 
                            accountTasks[currTaskId].permissions, 
                            accountTasks, 
                            accountData.users[currId]?.permissions?.admin || false, 
                            accountData?.settings?.basePermissions)
                            .canView
                    ){
                        if(currTaskId === taskId) {
                            updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${currId}`] = FOLLOWER_STATE_TYPES.manually;
                        } 
                        else if(!isFollowTypeManually(accountTasks, currTaskId, currId)) {
                            updates[`/accounts/${accountId}/tasks/${currTaskId}/followers/${currId}`] = FOLLOWER_STATE_TYPES.inherited;
                        }
                    }
                }
            });
        });
    }

    // Track events related to forced/manually follow/unfollow task for each user
    prevAndNewfollowersIds.forEach(userId => {

        // Track Start Following
        if(newFollowersIds.includes(userId)) {
            if(userId === activeUserId) {
                addChallenge('followTask');
                segmentTrack('Manually follow task', { accountId, taskId, activeUserId});
                return;
            }
            segmentTrack('Forced follow by others', { accountId, taskId, userId});
        }

        // Track Stop following
        if(prevFollowersIds.includes(userId) && !newFollowersIds.includes(userId)) {
            if(userId === activeUserId) {
                addChallenge('unfollowTask');
                segmentTrack('Manually unfollow task', { accountId, taskId, activeUserId});
                return;
            }
            segmentTrack('Unfollow task by other', { accountId, taskId, userId});
        }
    });

    segmentTrack('Users following task', { accountId, taskId, newFollowersIds });
    segmentTrack('NumTaskFollowers', { accountId, taskId, NumTaskFollowers: newFollowersIds.length || 0});

    db.update(undefined, updates);
    return new Promise(resolve => {
        resolve();
    });
}