import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import classnames from 'clsx';
import _  from 'underscore';
import { followTask, unfollowTask, updateTaskFollowers } from '../../utils/controllers/tasks/followers';
import { getAccountBasePermissions, getAccountId, getAccountTask, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { getUserId } from '../../utils/selectors/user';
import { useIntl } from 'react-intl';
import { canUserFollowTask, IsEnterKey } from '../../utils/utils';
import { Button } from '@material-ui/core';
import ManageTaskFollowersList from './ManageTaskFollowersList';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import FollowIcon from '@material-ui/icons/NotificationsActive';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ManageTaskFollowers = ({ taskId }) => {
    const intl = useIntl();
    const [showNewFollowersChoice, setShowNewFollowersChoice] = useState(false);
    const [followersListOpen, setFollowersListOpen] = useState(null);
    const [shortcutEnabled, setShortcutEnabled] = useState(false);
    const basePermissions = useSelector(getAccountBasePermissions);
    const task = useSelector(getAccountTask(taskId));
    const accountTasks = useSelector(getAccountTasks);
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);
    const isFolder = task.childrens && !_.isEmpty(task.childrens);
    const isFollowing = !!(_.get(accountTasks, [taskId, 'followers', userId], false));

    const handleShortCutKeyFollow = (e) => {
        if(
            (e.key !== 'f' && e.keyCode !== 70) ||
            document.activeElement.tagName === 'INPUT' ||
            document.activeElement.tagName === 'TEXTAREA' ||
            document.activeElement.classList.contains('fr-view')
        ) {
            return;
        }

        handleToggleFollow();
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseOverSidebar);
        if(shortcutEnabled) {
            document.addEventListener('keypress', handleShortCutKeyFollow);
        }
        
        return () => {
            document.removeEventListener('mousemove', handleMouseOverSidebar);
            document.removeEventListener('keypress', handleShortCutKeyFollow);
        };
    }, [shortcutEnabled, handleMouseOverSidebar, handleShortCutKeyFollow]);

    useEffect(()=> {
        if(!showNewFollowersChoice && followersListOpen) {
            document.addEventListener('keyup', handleConfirmEnterKey);
        }
        return () => {
            document.removeEventListener('keyup', handleConfirmEnterKey);
        };
    }, [handleConfirmEnterKey, showNewFollowersChoice, followersListOpen]);

    const handleConfirmEnterKey = (e) => {
        if(!IsEnterKey(e)) return;

        const okButton = document.querySelector('.rrt-button.rrt-ok-btn.toastr-control');
        if(okButton) {
            okButton.click();
        }
    };

    const followersList = useMemo(() => Object.keys(task?.followers || {}).reduce((acc, currUserId) => {
        if(!accountUsers[currUserId] || (!accountUsers[currUserId]?.permissions?.admin && !canUserFollowTask({ userId: currUserId, taskId: taskId, accountTasks, basePermissions }))) {
            return acc;
        }
        if(currUserId !== userId) {
            acc.push(accountUsers[currUserId]); 
        } else {
            acc.unshift(accountUsers[currUserId]);
        }
        return acc;
    }, []), [accountUsers, accountTasks, userId, taskId, basePermissions]);

    const followersListIds = useMemo(() => followersList.map(user=>user.userId), [followersList]);

    const handleToggleFollow = () => {
        if(isFollowing) {
            unfollowTask(taskId, userId);
            return;
        }

        if(!isFolder) {
            followTask(taskId, userId);
            return;
        }

        toastr.confirm(intl.formatMessage({id: 'manage.task.followers.shortcut'}), {
            onOk: () => {
                followTask(taskId, userId);
            },
            okText: intl.formatMessage({id: 'CONFIRM'}),
            cancelText: intl.formatMessage({id: 'CANCEL'}),
        });
    };

    const handleMouseOverSidebar = useCallback((e) => {
        const sidebar = document.querySelector('.sidebar');

        if(sidebar && e.clientX > sidebar.getBoundingClientRect().x) {
            setShortcutEnabled(true);
        } else {
            setShortcutEnabled(false);
        }
    }, []);

    const handleClose = () => {
        setFollowersListOpen(null);
    };

    const handleCloseNewFollowersChoice = useCallback(() => {
        setShowNewFollowersChoice(false);
    }, []);

    const handleOpenNewFollowersChoice = useCallback(() => {
        setShowNewFollowersChoice(true);
    }, []);

    const handleToggleFollowersList = useCallback((e) => {
        if(e.currentTarget) {
            setFollowersListOpen(e.currentTarget);
        }
    }, []);

    const handleUpdateFollowers = (followersIds) => {
        const newFollowersIds = _.isArray(followersIds) ? followersIds : [followersIds];
        const isOnlyRemovingFollowers = newFollowersIds.every(id=>followersListIds.includes(id)) && !_.isEmpty(followersListIds);

        if(isFolder && !isOnlyRemovingFollowers) {
            toastr.confirm(intl.formatMessage({id: 'manage.task.followers.confirm'}), {
                onOk: async () => {
                    await updateTaskFollowers({
                        accountId, 
                        taskId, 
                        newFollowersIds
                    });
                    
                    setShowNewFollowersChoice(false);
                },
                okText: intl.formatMessage({id: 'CONFIRM'}),
                cancelText: intl.formatMessage({id: 'CANCEL'}),
            });
            return;
        }

        updateTaskFollowers({
            accountId, 
            taskId, 
            newFollowersIds
        }).then(()     => {
            setShowNewFollowersChoice(false);
        }); 
    };

    return <div className={classnames('ManageTaskFollowers', {active: followersListOpen})} onClick={handleToggleFollowersList}>
        <span id='ManageTaskFollowers__shortcut__tooltip'>{isFollowing ? intl.formatMessage({id: 'Stop Following'}) : intl.formatMessage({id: 'Start Following'})} <strong>F</strong></span>
        
        <Button startIcon={<FollowIcon className={classnames('ManageTaskFollowers__FollowIcon', { following: isFollowing, active: followersListOpen })} />}>
            {!!followersListIds.length && <span className='ManageTaskFollowers__FollowIcon__count'>{followersListIds.length}</span>}
        </Button>

        <ManageTaskFollowersList
            handleAddFollowers={handleOpenNewFollowersChoice} 
            followersList={followersList} 
            isOpen={followersListOpen} 
            onClose={handleClose}
            taskId={taskId}
            isFollowing={isFollowing}
            handleToggleFollow={handleToggleFollow}
        />

        {showNewFollowersChoice && <SelectTeamMember
            maxSelect={Object.keys(accountUsers).length|1}
            currentUser={followersListIds}
            onSelect={handleUpdateFollowers}
            onClose={handleCloseNewFollowersChoice}
            open={showNewFollowersChoice}
            taskId={taskId}
            title='followers'
        />}
    </div>;
};


ManageTaskFollowers.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default memo(withCustomErrorBoundary(ManageTaskFollowers));
