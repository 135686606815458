import React, { useEffect, useState } from 'react';
import 'firebase/storage';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import classnames from 'clsx';
import TaskDetailComments from './TaskDetailComments';
import TaskDetailTimeWorked from './TaskDetailTimeWorked';
import TaskDetailAssignedUsers from './TaskDetailAssignedUsers';
import TaskDetailFiles from './TaskDetailFiles';
import TaskDetailDescription from './TaskDetailDescription';
import TaskDetailSkillSelect from './TaskDetailSkillSelect';
import TaskDetailTimeline from './TaskDetailTimeline';
import TaskSourceStatus from './TaskSourceStatus';
import TaskDetailStartOn from './TaskDetailStartOn';
import TaskDetailStartAfter from './TaskDetailStartAfter';
import { TaskDetailDueDate } from './TaskDetailDueDate';
import TaskDetailEffortInputs from './TaskDetailEffortInputs';
import TaskDetailPrioritySelect from './TaskDetailPrioritySelect';
import TaskDetailSelectTags from './TaskDetailSelectTags';
import TaskDetailSourceTags from './TaskDetailSourceTags';
import TaskDetailDependencies from './TaskDetailDependencies';
import TaskCustomFields from './TaskCustomFields';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useIntl } from 'react-intl';
import { getAccountTasks, getAccountTaskTags } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskDetailsTabs = ({ taskId, location, routes, syncAppData }) => {
    const accountTasks = useSelector(getAccountTasks);
    const task = {
        ...accountTasks[taskId],
        id: taskId
    };
    const [activeTab, setActiveTab] = useState('details');
    const intl = useIntl();

    const { syncAppType, isSyncAppTask, hasSourceTags, hasDependencies, hasSourceStatus } = syncAppData;
    const taskTags = useSelector(getAccountTaskTags(taskId));

    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };


    const handleSwitchActiveTabs = (e, val) => {
        if(val !== 'comments') {
            const sidebar = document.querySelector('.sidebar');
            if(sidebar && sidebar.style.overflow !== 'scroll') {
                sidebar.style.overflow = 'scroll';
            }
        }
        setActiveTab(val);
    };

    useEffect(() => {
        const validTabs = [ 'details', 'description', 'time', 'files', 'comments'];
        const { query } = location;
        if(validTabs.includes(query?.openOn)){
            handleSwitchActiveTabs(null, query.openOn);
        }
    }, [location]);

    const nbComments = !_.isEmpty(task.comments) ? Object.keys(task.comments).length : 0;
    const nbFiles = !_.isEmpty(task.files) ? Object.keys(task.files).length : 0;
    const currentUrl = routes[3]?.path !== ':uid' ? routes[3].path : routes[2].path;

    const canShowSourceTags = hasSourceTags && taskTags && taskTags.length;
    const canShowDependecies = !isSyncAppTask || hasDependencies;

    return (
        <>
            <Tabs
                value={activeTab}
                onChange={handleSwitchActiveTabs}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={t('Details')} value="details" />
                <Tab label={t('Description')} value="description" />
                {!isSyncAppTask && <Tab label={t('Files') + ' (' + nbFiles + ')'} value="files" />}
                {!isSyncAppTask && <Tab label={t('Time worked')} value="time" />}
                {!isSyncAppTask &&  <Tab label={t('Comments') + ' (' + nbComments + ')'} value="comments" />}
            </Tabs>
            <div className={classnames({ hide: activeTab !== 'details' })}>
                <div className="flex central detailsContent">
                    <ul>
                        {isSyncAppTask && hasSourceStatus && <TaskSourceStatus task={task} syncAppType={syncAppType} />}
                        <TaskDetailSkillSelect taskId={task.id} />
                        <TaskDetailEffortInputs taskId={task.id} />
                        {!task.childrens && <TaskDetailAssignedUsers taskId={task.id} />}
                        {!task.childrens && <TaskDetailStartOn taskId={task.id} />}
                        <TaskDetailStartAfter taskId={task.id} />
                        <TaskDetailDueDate taskId={task.id} />
                        {!task.childrens && <TaskDetailPrioritySelect taskId={task.id} />}
                        {canShowDependecies && <TaskDetailDependencies taskId={task.id} />}
                        {(!isSyncAppTask || canShowSourceTags) &&  <TaskDetailSelectTags taskId={task.id} />}
                        {canShowSourceTags && <TaskDetailSourceTags task={task} taskTags={taskTags} syncAppType={syncAppType} />}
                        <TaskCustomFields taskId={task.id} />
                    </ul>
                    <TaskDetailTimeline taskId={task.id} />
                </div>
            </div>
            <div className="taskDetailFooter">
                {activeTab === 'description' && (
                    <TaskDetailDescription activeTab={activeTab} taskId={task.id} isTaskSyncProject={isSyncAppTask}/>
                )}
                {activeTab === 'time' && !isSyncAppTask && (
                    <TaskDetailTimeWorked activeTab={activeTab} taskId={task.id} currentUrl={currentUrl} />
                )}
                {activeTab === 'files' && !isSyncAppTask && (
                    <TaskDetailFiles activeTab={activeTab} taskId={task.id} />
                )}
                {activeTab === 'comments' && !isSyncAppTask && (
                    <TaskDetailComments
                        taskNbComments={nbComments}
                        activeTab={activeTab}
                        taskId={task.id}
                        comment={task.comment}
                    />
                )}
            </div>
        </>
    );
};

export default withCustomErrorBoundary(TaskDetailsTabs);
