import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';

const EffortTable = ({ onColumnHover, datasets, labels, userDateFormat }) =>   {
    const renderColumns = useCallback(((row, isChild) => {
        const hoursOfEvents = [{ isTitle: true, value: 'Hours Of Events' } ];
        const titleRow = [{ isName: true,  value: row.name , isChild } ];


        labels.forEach((l, idx) => {
            hoursOfEvents.push( { value:  `${row.hoursOfEvents[idx]} h`, isChild } );
            titleRow.push( { value: null, isChild  } );
        });

        return [ 
            titleRow,
            hoursOfEvents,
        ];
    }),[labels]);
    const formatTitle = (title) =>  getDateFormated(title);

    return <Table
        size="small"
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
        userDateFormat={userDateFormat} />;
};

export default React.memo(EffortTable);