import React from 'react';
import Button from '@material-ui/core/Button';
import { ACADEMY_LINK } from '../../utils/constants';
import { useIntl } from 'react-intl';

const StepsLearnMore = () => {
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    return (
        <div className="StepsLearnMore">
            <h1>{t('Where to learn more?')}</h1>
            <div>
                <p>
                    {t('Want to become an expert on Planless?')}
                    <br />
                    {t('You’ll find tons of videos, tutorials and help on:')}
                </p>
                <div>
                    <img src="../../../stylesheets/assets/academy-logo.svg" alt="Planless academy logo" />
                </div>
                <Button variant="contained" onClick={() => window.open(ACADEMY_LINK)}>
                    {t('EXPLORE OUR ACADEMY')}
                </Button>
            </div>
        </div>
    );
};

export default StepsLearnMore;
