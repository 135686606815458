/* eslint-disable max-len */
import moment from 'moment';
import _ from 'underscore';
import { getOnlyResourceUsers } from '../memberTypes';
export var getAccountTasks = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.tasks; };
export var getAccountTasksCount = function (state) { var _a, _b; return Object.keys(((_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.tasks) || {}).length; };
export var getAccountSkills = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.skills; };
export var getAccountSkillsGroups = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.skillGroups; };
export var getAccountTags = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.tags; };
export var getAccountEvents = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.events; };
export var getSlackConnections = function (state) { return _.get(state, ['app', 'account', 'slackConnections'], null); };
// app / account / settings
export var getAccountSeenMidTrialModal = function (state) { return _.get(state, ['app', 'account', 'settings', 'seenMidTrialModal'], false); };
export var getAccountStorageSize = function (state) { return _.get(state, ['app', 'account', 'settings', 'storageSize'], ''); };
export var getAccountName = function (state) { return _.get(state, ['app', 'account', 'settings', 'name'], ''); };
export var getAccountCode = function (state) { return _.get(state, ['app', 'account', 'settings', 'code'], ''); };
export var getAccountChargebeeCustomerId = function (state) { return _.get(state, ['app', 'account', 'settings', 'chargebeeCustomerId'], null); };
export var getAccountBoards = function (state) { return _.get(state, ['app', 'account', 'settings', 'boards'], null); };
export var getAccountBasePermissions = function (state) { return _.get(state, ['app', 'account', 'settings', 'basePermissions'], null); };
export var getAccountBasePermissionsOwners = function (state) { return _.get(state, ['app', 'account', 'settings', 'basePermissions', 'owners'], []); };
export var getAccountBasePermissionsManagers = function (state) { return _.get(state, ['app', 'account', 'settings', 'basePermissions', 'managers'], []); };
export var getAccountBasePermissionsWorkers = function (state) { return _.get(state, ['app', 'account', 'settings', 'basePermissions', 'workers'], []); };
export var getAccountCostBudgetSetting = function (state) { return _.get(state, ['app', 'account', 'settings', 'costBudget', 'isOn'], true); };
export var getAccountCostBudgetCurrency = function (state) { return _.get(state, ['app', 'account', 'settings', 'costBudget', 'currency'], ''); };
export var getAccountFirstSteps = function (state) { return _.get(state, ['app', 'account', 'settings', 'firstStepsConfig'], null); };
export var getAccountDependenciesBlock = function (state) { return _.get(state, ['app', 'account', 'settings', 'dependenciesBlock'], null); };
export var getAccountId = function (state) { return _.get(state, ['app', 'account', 'settings', 'id'], ''); };
export var getAccountCustomFields = function (state) { return _.get(state, ['app', 'account', 'settings', 'customFields'], null); };
export var getAccountIsLifetime = function (state) { return _.get(state, ['app', 'account', 'settings', 'isLifetime'], false); };
export var getAccountPlanNbusers = function (state) { return _.get(state, ['app', 'account', 'settings', 'planNbusers'], ''); };
export var getAccountPlan = function (state) { return _.get(state, ['app', 'account', 'settings', 'plan'], ''); };
export var getAccountEndPlanAt = function (state) { return _.get(state, ['app', 'account', 'settings', 'endPlanAt'], ''); };
export var getAccountIsTrial = function (state) { return _.get(state, ['app', 'account', 'settings', 'isTrial'], false); };
export var getAccountIsTrialExtended = function (state) { return _.get(state, ['app', 'account', 'settings', 'trialExtended'], false); };
export var getAccountPlanCurrency = function (state) { return _.get(state, ['app', 'account', 'settings', 'planCurrency'], null); };
export var getAccountPlanPeriodicity = function (state) { return _.get(state, ['app', 'account', 'settings', 'planPeriodicity'], null); };
export var getAccountPlanSubscriptionCanceled = function (state) { return _.get(state, ['app', 'account', 'settings', 'subscriptionCanceled'], false); };
export var getAccountChat = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.chat; };
export var getAccountDaysoff = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.daysoff; };
export var getAccountApiKeys = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.apiKeys; };
export var getAccountSlackConnections = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.slackConnections; };
// app / account / tasks / task
export var getAccountTaskOriginalPermissions = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'originalTaskPermissions'], null); }; };
export var getAccountTaskStoryPoint = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'storyPoint'], null); }; };
export var getAccountTaskExist = function (taskId) { return function (state) { return !!(_.get(state, ['app', 'account', 'tasks', taskId], null)); }; };
export var getAccountTask = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId], null); }; };
export var getAccountTaskPath = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'path'], []); }; };
export var getAccountTaskCanView = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canView'], false); }; };
export var getAccountTaskCanManage = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canManage'], false); }; };
export var getAccountTaskCanWork = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canWork'], false); }; };
export var getAccountTaskCanWorkersCreateTask = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canWorkersCreateTask'], true); }; };
export var getAccountTaskCanWorkersChangeEffort = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canWorkersChangeEffort'], true); }; };
export var getAccountTaskCanAdmin = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'canAdmin'], false); }; };
export var getAccountTaskParent = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'parent'], null); }; };
export var getAccountTaskSyncAppType = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'syncAppType'], null); }; };
export var getAccountTaskSourceKey = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'sourceKey'], null); }; };
export var getAccountTaskISourceId = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'sourceId'], null); }; };
export var getAccountTaskIsConnectProject = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'isConnectProject'], null); }; };
export var getAccountTaskSyncAppId = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'syncAppId'], null); }; };
export var getAccountTaskSourceType = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'sourceType'], null); }; };
export var getAccountTaskIndex = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'index'], 0); }; };
export var getAccountTaskSourceStatus = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'sourceStatus'], ''); }; };
export var getAccountTaskStatus = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'status'], null); }; };
export var getAccountTaskTitle = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'title'], null); }; };
export var getAccountTaskSkill = function (taskId) { return function (state) {
    var skillsIds = _.get(state, ['app', 'account', 'tasks', taskId, 'skill'], []);
    // Before multi skill features the skill was a string. So we need to convert it to an array because some accounts still have the old format
    if (_.isString(skillsIds)) {
        return [skillsIds];
    }
    return skillsIds;
}; };
export var getAccountTaskSkillRages = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'skillRanges'], {}); }; };
export var getAccountTaskMinEffort = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'minEffort'], null); }; };
export var getAccountTaskMaxEffort = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'maxEffort'], null); }; };
export var getAccountTaskDelayUntil = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'delayUntil'], null); }; };
export var getAccountTaskStarton = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'startOn'], null); }; };
export var getAccountTaskDeadline = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'deadline'], null); }; };
export var getAccountTaskStartedWorkingAt = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'startedWorkingAt'], null); }; };
export var getAccountTaskDoneAt = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'doneAt'], null); }; };
export var getAccountTaskForcedUsers = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'forcedUser'], null); }; };
export var getAccountTaskIsRecurring = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'isRecurringTask'], false); }; };
export var getAccountTaskUserworking = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'userWorking'], null); }; };
export var getAccountTaskNbworkers = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'nbWorkers'], null); }; };
export var getAccountTaskDoneby = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'doneBy'], null); }; };
export var getAccountTaskFirstTaskNoSkill = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'firstTaskNoSkill'], null); }; };
export var getAccountTaskPriority = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'priority'], null); }; };
export var getAccountTaskDependencies = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'dependencies'], null); }; };
export var getAccountTaskTags = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'tags'], null); }; };
export var getAccountTaskSourceTags = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'sourceTags'], []); }; };
export var getAccountTaskWorkingTime = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'workingTime'], null); }; };
export var getAccountTaskFiles = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'files'], null); }; };
export var getAccountTaskComments = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'comments'], null); }; };
export var getAccountTaskEmailToTask = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'emailToTask'], null); }; };
export var getAccountTaskPermissions = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'permissions'], null); }; };
export var getAccountTaskCommits = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'commits'], null); }; };
export var getAccountTaskTimerPause = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'timerPause'], null); }; };
export var getAccountTaskTimerStart = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'timerStart'], null); }; };
export var getAccountTaskRisksTaskForcedUserNoShe = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskForcedUserNoShe'], null); }; };
export var getAccountTaskRisksTaskAtDanger = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskAtDanger'], null); }; };
export var getAccountTaskRisksTaskAtRisk = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskAtRisk'], null); }; };
export var getAccountTaskRisksTaskNoSkill = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskNoSkill'], null); }; };
export var getAccountTaskRisksTaskNoEffort = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskNoEffort'], null); }; };
export var getAccountTaskRisksTaskEstimationError = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskEstimationError'], null); }; };
export var getAccountTaskRisksTaskNoWorkers = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'risks', 'taskNoWorkers'], null); }; };
export var getAccountTaskEstimations = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'estimations'], null); }; };
export var getAccountTaskEstimationsUserId = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'estimations', 'userId'], null); }; };
export var getAccountTaskChildrens = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'childrens'], null); }; };
export var getAccountTaskHasChildren = function (taskId) { return function (state) { return _.isObject(_.get(state, ['app', 'account', 'tasks', taskId, 'childrens'], null)); }; };
export var getAccountTasksCustomFields = function (taskId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'customFields'], {}); }; };
export var getAccountTasksCustomField = function (taskId, fieldId) { return function (state) { return _.get(state, ['app', 'account', 'tasks', taskId, 'customFields', fieldId], null); }; };
// app / account / users
export var getAccountUsers = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.users; };
export var getAccountUser = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId], null); }; };
export var getAccountUserMemberType = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'memberType'], null); }; };
export var getAccountUserExist = function (userId) { return function (state) { return !!(_.get(state, ['app', 'account', 'users', userId], null)); }; };
export var getAccountUserAccountId = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'accountId'], null); }; };
export var getAccountUserIsViewer = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'viewer'], false); }; };
export var getAccountUserEmail = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'email'], null); }; };
export var getAccountUserUid = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'uid'], null); }; };
export var getAccountUserDisplayName = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'displayName'], ''); }; };
export var getAccountUserSkills = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'skills'], null); }; };
export var getAccountUserSchedule = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'schedule'], null); }; };
export var getAccountUserDaysOff = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'daysoff'], null); }; };
export var getAccountUserAvatar = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'avatar'], null); }; };
export var getAccountUserTimezone = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'timezone'], null); }; };
export var getAccountUserExtraHours = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'extraHours'], null); }; };
export var getAccountUserPermissionsWorkload = function (userId) { return function (state) { return _.get(state, ['app', 'account', 'users', userId, 'permissions', 'workload'], []); }; };
export var getAccountUserGroups = function (state) { return _.get(state, ['app', 'account', 'userGroups'], null); };
export var getAccountUserGroupTitle = function (groupId) { return function (state) { return _.get(state, ['app', 'account', 'userGroups', groupId, 'title'], null); }; };
export var getAccountIsTrialEnded = function (state) {
    var isTrial = getAccountIsTrial(state);
    var endPlanAt = getAccountEndPlanAt(state);
    return isTrial && moment(endPlanAt).isBefore();
};
export var getAccountIsMidTrial = function (state) {
    var hasSeenMidTrialModal = getAccountSeenMidTrialModal(state);
    if (hasSeenMidTrialModal)
        return false;
    var isTrial = getAccountIsTrial(state);
    var endPlanAt = getAccountEndPlanAt(state);
    return isTrial && moment(endPlanAt).diff(moment(), 'days') === 7;
};
export var getAccountIsMaxResourcersReached = function (state) {
    var isTrial = getAccountIsTrial(state);
    var endPlanAt = getAccountEndPlanAt(state);
    var isPlanOver = !moment(endPlanAt).isSameOrAfter(moment(), 'day');
    var planNbusers = getAccountPlanNbusers(state);
    var accountUsers = getAccountUsers(state);
    if (!accountUsers)
        return false;
    var memberResources = getOnlyResourceUsers(accountUsers);
    if (_.size(memberResources) > parseFloat(planNbusers) && !isTrial && !isPlanOver)
        return true;
    return false;
};
export var getAccountResourceMembers = function (state) {
    var accountUsers = getAccountUsers(state);
    if (!accountUsers)
        return {};
    return getOnlyResourceUsers(accountUsers);
};
export var getTaskAllowedWorkers = function (taskId) { return function (state) {
    var _a, _b, _c, _d;
    var basePermissionsWorkers = getAccountBasePermissionsWorkers(state);
    var accountTasks = getAccountTasks(state);
    var accountUsers = getAccountUsers(state);
    if (!accountUsers)
        return {};
    var onlyResourceUsers = getOnlyResourceUsers(accountUsers);
    if (!accountTasks || !accountTasks[taskId])
        return {};
    var comingFrom = accountTasks[taskId].permissions.comingFrom;
    var taskWorkers = [];
    if (comingFrom === 'base') {
        taskWorkers = basePermissionsWorkers;
    }
    else if (comingFrom === taskId) {
        taskWorkers = ((_b = (_a = accountTasks[taskId]) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.workers) || [];
    }
    else if ((accountTasks[comingFrom])) {
        taskWorkers = ((_d = (_c = accountTasks[comingFrom]) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.workers) || [];
    }
    else {
        taskWorkers = [];
    }
    var taskWorkersResources = Object.values(onlyResourceUsers).reduce(function (workers, user) {
        if (taskWorkers.includes(user.userId)) {
            // eslint-disable-next-line no-param-reassign
            workers[user.userId] = user;
        }
        return workers;
    }, {});
    return taskWorkersResources;
}; };
export var getSkillsDescription = function (skillIds) { return function (state) {
    var accountSkills = getAccountSkills(state);
    if (!skillIds || !skillIds.length || !accountSkills || !_.isArray(skillIds)) {
        return 'Not defined';
    }
    return skillIds === null || skillIds === void 0 ? void 0 : skillIds.map(function (skillId) { var _a, _b; return (_b = (_a = accountSkills[skillId]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''; }).join(', ');
}; };
export var getAccountCanUsersManageOwnSkills = function (state) { return _.get(state, ['app', 'account', 'settings', 'canUsersManageOwnSkills'], false); };
