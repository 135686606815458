import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import {  Card, Tooltip } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import _ from 'underscore';

import { getAccountId, getAccountTasks, getAccountUsers } from '../../../../utils/selectors/account';
import { getAccountDataSyncAppType, getAccountDataSyncAppUsers } from '../../../../utils/selectors/accountData';
import { setSyncAppMatchUser } from '../../../../utils/controllers/syncApp/syncApp';
import { batchUpdates } from '../../../../utils/databaseQuerys/notifications';
import StateUserAvatar from '../../../../components/UserAvatar/StateUserAvatar';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const PlanlessUserLine = ({ username,  email, sourceId, syncAppId, planlessId, matched = false }) => {
    const [matchedUserId, setMatchedUserId] = useState(planlessId);
    const planlessUsers = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);
    const accountTasks = useSelector(getAccountTasks);
    const syncAppType = useSelector(getAccountDataSyncAppType(syncAppId));

    const handleMatchUser = memberId => {
        setMatchedUserId(memberId);
        setSyncAppMatchUser({
            syncAppType,
            syncAppId,
            planlessId: memberId,
            sourceId: sourceId,
        });
        const tasksWithTheUser = Object.keys(accountTasks).filter(taskId => {
            const oa = _.get(accountTasks, [taskId, 'originalAssignees'], []);
            const fu = _.get(accountTasks, [taskId, 'forcedUser'], []);

            return !fu.length && !!oa.find(u => u.id === sourceId);
        });

        const updates = tasksWithTheUser.reduce(
            (acc, taskId) => ({
                ...acc,
                [`/accounts/${accountId}/tasks/${taskId}/forcedUser`]: [memberId],
            }),
            {},
        );

        if (!_.isEmpty(updates)) {
            batchUpdates(updates);
        }
    };

    const handleOpenSidebar = memberId =>
        browserHistory.push({
            pathname: `/settings/integrations/${syncAppType}/user/${memberId}`,
            state: 'from_integrations',
        });

    return (
        <>
            <Card className="PlanlessUserLine" elevation={0}>
                <div className="PlanlessUserLine__userCard">
                    {matched ? (
                        <>
                            <div
                                data-sidebar="preventClose"
                                className="PlanlessUserLine__userCard__userProfile"
                                onClick={() => handleOpenSidebar(matchedUserId)}
                            >
                                <StateUserAvatar
                                    className="avatar"
                                    userId={matchedUserId}
                                    disableTooltip={true}
                                />
                                <span>{planlessUsers[matchedUserId]?.displayName}</span>
                            </div>
                        </>
                    ) : (
                        <UnMatchedActions syncAppId={syncAppId} username={username} email={email} handleMatchUser={handleMatchUser} />
                    )}
                </div>
            </Card>
        </>
    );
};

const UnMatchedActions = ({ username, email, handleMatchUser, syncAppId }) => {
    const intl = useIntl();
    const planlessUsers = useSelector(getAccountUsers);
    const syncAppUsers = useSelector(getAccountDataSyncAppUsers(syncAppId));
    const [usersUsed, setUsersUsed] = useState([]);

    React.useEffect(() => {
        if(syncAppUsers){
            setUsersUsed(Object.values(syncAppUsers).map(el => el.planlessId).filter(Boolean));
        }
    }, [syncAppUsers]);
    
    const suggestion = React.useMemo(() => {
        const validUsers = Object.values(planlessUsers).filter(el => !usersUsed.includes(el.userId));
        const firstValidation = validUsers.find(
            el => el.displayName === username && email && el.email === email,
        );
        if (firstValidation) {
            return firstValidation;
        }
        const secondValidation = validUsers.find(el => email && el.email === email);
        if (secondValidation) {
            return secondValidation;
        }
        const thirdValidation = validUsers.find(el => el.displayName === username);
        if (thirdValidation) {
            return thirdValidation;
        }
        return null;
    }, [username, email, planlessUsers, usersUsed]);

    const handleClick = () => handleMatchUser(suggestion.userId);

    return (
        <>
            {!suggestion && (
                <>
                    <CancelIcon className="notMatched" /> <span>{intl.formatMessage({ id: 'Not Matched' })}</span>{' '}
                </>
            )}
            {suggestion && (
                <Tooltip
                    classes={{
                        popper: 'custom-popper',
                    }}
                    placement="top"
                    title="Match User"
                    
                >
                    <div
                        onClick={handleClick}
                        data-sidebar="preventClose"
                        className="PlanlessUserLine__userCard__suggest"
                    >
                        <StateUserAvatar
                            className="avatar"
                            userId={suggestion.userId}
                            disableTooltip={true}
                        />
                        <span>{suggestion?.displayName}</span>
                        <div className="Matche_Icon_Container">
                            <Check />
                        </div>
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default withCustomErrorBoundary(PlanlessUserLine);
