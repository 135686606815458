import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import { downloadFile, getPath, getUserInfo } from '../utils';
import TableContainer from '../TableContainer';
import Unautorized from '../../Unautorized/Unautorized';
import TableCell from '@material-ui/core/TableCell';
import TaskEventCell from '../TaskEventCell';
import UserCell from '../UserCell';
import Chip from '@material-ui/core/Chip';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const getTaskInfo = ({ task, stateTasks, userTimezone, accountSkills }) => ({
    path: task.parent ? getPath(task.parent, stateTasks) : task.title,
    taskName: task.title || '',
    startDate: task.estimations?.startAt
        ? moment.tz(task.estimations?.startAt, 'X', userTimezone).format('YYYY/MM/DD')
        : '',
    expectedFinishDate: task.estimations?.expectedAt
        ? moment.tz(task.estimations?.expectedAt, 'X', userTimezone).format('YYYY/MM/DD')
        : '',
    assignedTo: [],
    skill: task?.skill?.length ? task.skill.map(skillId => accountSkills[skillId]?.name || '').join(', ') : '',
    effort: `${task.minEffort}-${task.maxEffort}`,
    dueDate: task.deadline ? moment.tz(task.deadline, 'YYYY-MM-DD', userTimezone).format('YYYY/MM/DD') : '',
});

const getEventInfo = ({ path, participants, when, eventName, type = 'event' }, id) => ({
    path: path,
    id: id,
    participants: participants,
    when: when || 'N/A',
    eventName: eventName,
    type,
});

const parseMoment = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
};

const RecentsEvents = ({
    stateTasks,
    usersAllEvents,
    workspaceSelected,
    dateFormat,
    hasAutorization,
    childsParents,
    userTimezone,
}) => {
    const accountEvents = useSelector(state => state?.app?.account?.events);
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector(state => state.app?.account?.skills || {});
    const intl = useIntl();
    const [dataTablesData, setDataTablesData] = useState([]);
    const defaultBreakdownLvls = DefaultBreakdownLvls(intl).filter(el => el.value !== 'skills');
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);

    const updateBreakdowns = async newBreakdowns => {
        setBreakdownData(newBreakdowns);
    };

    useEffect(() => {
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
    }, [workspaceSelected]);

    const headCells = [
        { id: 'path', label: 'Path' },
        { id: 'eventName', label: 'Event name' },
        { id: 'when', type: 'date', label: 'When' },
        { id: 'participants', type: 'array-users', label: 'Participants' },
    ];

    const handleExportAsCSV = ({ name, data }) => {
        const columns = headCells.map(el => el.label).join(';');
        const content = data.map(el => {
            const row = headCells.reduce((acc, { id, type }) => {
                let field = el[id];

                if (type === 'array-users') {
                    field = field.map(user => user.name).join(',');
                }

                if (type === 'date') {
                    field = el[id] !== 'N/A' ? moment(el[id]).format(dateFormat) : el[id];
                }

                return `${acc}${field};`;
            }, '');
            return row;
        });
        downloadFile(
            encodeURI(`data:text/csv;charset=utf-8,${columns}\r\n${content.join('\r\n')}`),
            `Recents-Events${name}`,
        );
    };

    useEffect(() => {
        const today = moment().tz(userTimezone);

        const newDataTablesData = [];
        if (stateTasks && accountEvents) {
            // get breakdowns =
            const breakdownBySkillEntry = breakdownData.find(el => el.value === 'skills');
            const breakdownByTaskEntry = breakdownData.find(el => el.value === 'tasks');
            const breakdownByUsersEntry = breakdownData.find(el => el.value === 'users');

            let filteredEvents = [];
            if (breakdownByTaskEntry?.selectedData.length) {
                const accountEventsKeys = Object.keys(accountEvents);
                filteredEvents = breakdownByTaskEntry.selectedData.map(el => {
                    const childsTasks = _.get(childsParents, el, [el]);
                    return {
                        source: el,
                        events: accountEventsKeys.filter(
                            eventId =>
                                accountEvents[eventId].parent === workspaceSelected ||
                                childsTasks.includes(accountEvents[eventId].parent),
                        ),
                    };
                });
            } else {
                filteredEvents = [
                    {
                        source: workspaceSelected,
                        events:
                            workspaceSelected === 'root'
                                ? Object.keys(accountEvents)
                                : Object.keys(accountEvents).filter(
                                    eventId =>
                                        accountEvents[eventId].parent === workspaceSelected ||
                                          _.get(childsParents, workspaceSelected, [workspaceSelected]).includes(
                                              accountEvents[eventId].parent,
                                          ),
                                ),
                    },
                ];
            }

            // if on root lvl I have to get all of the calendar integrated events for each user
            if (!breakdownByTaskEntry?.length && workspaceSelected === 'root') {
                const usersEventsData = breakdownByUsersEntry?.selectedData?.length
                    ? breakdownByUsersEntry?.selectedData.map(userId =>
                        usersAllEvents[userId].map(e => ({ ...e, userId: userId })),
                    )
                    : Object.keys(usersAllEvents).map(userId =>
                        usersAllEvents[userId].map(e => ({ ...e, userId: userId })),
                    );
                const allEvents = _.flatten(usersEventsData);
                const userEvents = allEvents.filter(event =>
                    moment.tz(event.start, 'X', userTimezone).isBefore(today, 'day'),
                );

                userEvents.forEach(event => {
                    const name = 'root';
                    const eventParticipants = event.participants || [];
                    const eventUsers = eventParticipants.map(el => ({ name: el?.email, avatar: null, userId: null }));
                    let key = workspaceSelected;
                    const eventInfo = getEventInfo(
                        {
                            eventName: event.name,
                            when: event.start,
                            participants: eventUsers,
                            path: event.calendarId,
                            type: 'userEvents',
                        },
                        event.id,
                    );

                    if (
                        breakdownByUsersEntry &&
                        breakdownByUsersEntry.lvl === 0 &&
                        breakdownByUsersEntry.selectedData.length
                    ) {
                        let keyForUsers = event.userId;
                        let nameForUsers = event.name;

                        const dataTableEntry = newDataTablesData.find(el => el.id === keyForUsers);

                        if (dataTableEntry) {
                            dataTableEntry.data.push({ ...eventInfo, participants: eventUsers });
                        } else {
                            newDataTablesData.push({
                                name: nameForUsers,
                                id: keyForUsers,
                                data: [{ ...eventInfo, participants: eventUsers }],
                            });
                        }
                        return;
                    }
                    const dataTableEntry = newDataTablesData.find(el => el.id === key);

                    if (dataTableEntry) {
                        dataTableEntry.data.push(eventInfo);
                    } else {
                        newDataTablesData.push({ name, id: key, data: [eventInfo] });
                    }
                    return;
                });
            }

            filteredEvents.forEach(entry => {
                entry.events.forEach(eventId => {
                    const event = accountEvents[eventId];

                    if (!event) {
                        return;
                    }

                    const currentTask = event.parent ? stateTasks[event.parent] : { title: '' };
                    if (!currentTask) {
                        return;
                    }
                    const taskInfo = event.parent
                        ? getTaskInfo({ task: currentTask, stateTasks, userTimezone, accountSkills })
                        : { path: '-' };
                    if (!event) {
                        return;
                    }
                    const eventParticipants = event.participants || [];
                    const eventUsers = eventParticipants.map(el => ({
                        name: getUserInfo(accountTeamMembers[el])?.displayName,
                        avatar: getUserInfo(accountTeamMembers[el])?.avatar,
                        userId: el,
                    }));
                    let key = workspaceSelected;
                    let name = workspaceSelected === 'root' ? '' : stateTasks[workspaceSelected].title;

                    // is the event beetween the date range so after today and before the endDate
                    // if there is no recurrency  --- will go normal way the event is valid our will be the startDate of Event.
                    if (!event.recurrency && moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                        const eventInfo = getEventInfo(
                            {
                                eventName: event.name,
                                when: event.start,
                                participants: eventUsers,
                                path: taskInfo.path,
                            },
                            eventId,
                        );

                        if (
                            breakdownByUsersEntry &&
                            breakdownByUsersEntry.lvl === 0 &&
                            breakdownByUsersEntry.selectedData.length
                        ) {
                            const onlySelectedUsers = eventUsers.filter(el =>
                                breakdownByUsersEntry.selectedData.includes(el.userId),
                            );
                            if (!onlySelectedUsers.length) {
                                return;
                            }

                            onlySelectedUsers.forEach(el => {
                                let keyForUsers = el.userId;
                                let nameForUsers = el.name;

                                if (breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length) {
                                    nameForUsers += ` ${stateTasks[entry.source].title}`;

                                    keyForUsers += `|${entry.source}`;
                                }

                                const dataTableEntry = newDataTablesData.find(el => el.id === keyForUsers);

                                if (dataTableEntry) {
                                    dataTableEntry.data.push({ ...eventInfo, participants: [el] });
                                } else {
                                    newDataTablesData.push({
                                        name: nameForUsers,
                                        id: keyForUsers,
                                        data: [{ ...eventInfo, participants: [el] }],
                                    });
                                }
                            });
                            return;
                        }

                        if (
                            breakdownByTaskEntry &&
                            breakdownByTaskEntry.lvl === 0 &&
                            breakdownByTaskEntry.selectedData.length
                        ) {
                            key = entry.source;
                            name = stateTasks[entry.source].title;
                            if (breakdownBySkillEntry && breakdownBySkillEntry.selectedData.length) {
                                key += currentTask?.skill?.join('|');
                                name += currentTask?.skill?.map(skillId => accountSkills[skillId]?.name || '').join(' ');
                            }

                            if (breakdownByUsersEntry && breakdownByUsersEntry.selectedData.length) {
                                const onlySelectedUsers = eventUsers.filter(el =>
                                    breakdownByUsersEntry.selectedData.includes(el.userId),
                                );
                                if (!onlySelectedUsers.length) {
                                    return;
                                }

                                onlySelectedUsers.forEach(el => {
                                    name += ` ${el.name || ''}`;
                                    key += `|${el.userId}`;

                                    const dataTableEntry = newDataTablesData.find(el => el.id === el.userId);

                                    if (dataTableEntry) {
                                        dataTableEntry.data.push({ ...eventInfo, participants: [el] });
                                    } else {
                                        newDataTablesData.push({
                                            name,
                                            id: key,
                                            data: [{ ...eventInfo, participants: [el] }],
                                        });
                                    }
                                });

                                return;
                            }
                        }

                        const dataTableEntry = newDataTablesData.find(el => el.id === key);

                        if (dataTableEntry) {
                            dataTableEntry.data.push(eventInfo);
                        } else {
                            newDataTablesData.push({ name, id: key, data: [eventInfo] });
                        }
                        return;
                    }

                    if (event.recurrency && moment.tz(event.start, 'X', userTimezone).isBefore(today)) {
                        let lastEvent = moment.tz(event.start, 'X', userTimezone);
                        while (lastEvent.isBefore(today)) {
                            const endOfWeek = moment(lastEvent).endOf('week');
                            Object.keys(event.recurrency).forEach(weekday => {
                                if (event.recurrency[weekday] && parseMoment[weekday]) {
                                    const expecetedDate = moment(endOfWeek).day(parseMoment[weekday]);
                                    if (expecetedDate.isBefore(today, 'day')) {
                                        const eventInfo = getEventInfo(
                                            {
                                                eventName: event.name,
                                                when: expecetedDate.unix(),
                                                participants: eventUsers,
                                                path: taskInfo.path,
                                            },
                                            eventId,
                                        );
                                        if (
                                            breakdownByUsersEntry &&
                                            breakdownByUsersEntry.lvl === 0 &&
                                            breakdownByUsersEntry.selectedData.length
                                        ) {
                                            const onlySelectedUsers = eventUsers.filter(el =>
                                                breakdownByUsersEntry.selectedData.includes(el.userId),
                                            );
                                            if (!onlySelectedUsers.length) {
                                                return;
                                            }

                                            onlySelectedUsers.forEach(el => {
                                                let keyForUsers = el.userId;
                                                let nameForUsers = el.name;

                                                if (breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length) {
                                                    nameForUsers += ` ${stateTasks[entry.source].title}`;

                                                    keyForUsers += `|${entry.source}`;
                                                }

                                                const dataTableEntry = newDataTablesData.find(
                                                    el => el.id === keyForUsers,
                                                );

                                                if (dataTableEntry) {
                                                    dataTableEntry.data.push({ ...eventInfo, participants: [el] });
                                                } else {
                                                    newDataTablesData.push({
                                                        name: nameForUsers,
                                                        id: keyForUsers,
                                                        data: [{ ...eventInfo, participants: [el] }],
                                                    });
                                                }
                                            });
                                            return;
                                        }

                                        if (
                                            breakdownByTaskEntry &&
                                            breakdownByTaskEntry.lvl === 0 &&
                                            breakdownByTaskEntry.selectedData.length
                                        ) {
                                            key = entry.source;
                                            name = stateTasks[entry.source].title;

                                            if (breakdownByUsersEntry && breakdownByUsersEntry.selectedData.length) {
                                                const onlySelectedUsers = eventUsers.filter(el =>
                                                    breakdownByUsersEntry.selectedData.includes(el.userId),
                                                );
                                                if (!onlySelectedUsers.length) {
                                                    return;
                                                }

                                                onlySelectedUsers.forEach(el => {
                                                    name += ` ${el.name || ''}`;
                                                    key += `|${el.userId}`;

                                                    const dataTableEntry = newDataTablesData.find(
                                                        el => el.id === el.userId,
                                                    );

                                                    if (dataTableEntry) {
                                                        dataTableEntry.data.push({ ...eventInfo, participants: [el] });
                                                    } else {
                                                        newDataTablesData.push({
                                                            name,
                                                            id: key,
                                                            data: [{ ...eventInfo, participants: [el] }],
                                                        });
                                                    }
                                                });

                                                return;
                                            }
                                        }

                                        // no breakdowns = taskInfo
                                        const dataTableEntry = newDataTablesData.find(el => el.id === key);

                                        if (dataTableEntry) {
                                            dataTableEntry.data.push(eventInfo);
                                        } else {
                                            newDataTablesData.push({ name, id: key, data: [eventInfo] });
                                        }
                                    }
                                }
                            });
                            lastEvent.add(parseInt(event.recurrency.everyXweeks), 'weeks');
                        }
                    }
                });
            });
            setDataTablesData(newDataTablesData);
        }
    }, [stateTasks, workspaceSelected, breakdownData, usersAllEvents]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => Object.keys(accountTeamMembers), [accountTeamMembers]);
    const availableSkills = React.useMemo(() => Object.keys(accountSkills), [accountSkills]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(
        () =>
            workspaceSelected === 'root'
                ? Object.entries(stateTasks)
                    .map(([id, el]) => (!el.parent ? { id } : false))
                    .filter(Boolean)
                : _.get(stateTasks[workspaceSelected], 'childrens', []),
        [workspaceSelected],
    );

    if (!hasAutorization) {
        return <Unautorized />;
    }

    const renderRow = (idx, row) => [
        <TableCell key={`recents-events-${row.when}-path`} align="left">
            {row.path}
        </TableCell>,
        <TableCell key={`recents-events-${row.when}-eventName`} align="left">
            {' '}
            <TaskEventCell name={row.eventName} id={row.id} type={row.type} />{' '}
        </TableCell>,
        <TableCell key={`recents-events-${row.when}-when`} align="left">
            {row.when !== 'N/A' ? moment.tz(row.when, 'X', userTimezone).format(dateFormat) : 'N/A'}
        </TableCell>,
        <TableCell key={`recents-events-${row.when}-user`} className={'users-cell'} align="left">
            {[...row.participants].slice(0, 3).map(el => (
                <UserCell color={el.color} key={el.userId} userId={el.userId} avatarPath={el.avatar} name={el.name} />
            ))}
            {row.participants.length > 3 && (
                <Chip className="plus-chip" label={`+${row.participants.length - 3}`} disabled />
            )}
        </TableCell>,
    ];

    const breakdownRules = {
        tasks: ['none', 'users'],
        users: ['none', 'tasks'],
        none: ['none', 'users', 'tasks'],
    };
    return (
        <>
            <Paper>
                <div className="breakdownsContainer">
                    <BreakdownController
                        rules={breakdownRules}
                        enabled={dataTablesData.length}
                        breakdowns={breakdownData}
                        updateBreakdowns={updateBreakdowns}
                        tasks={subItensTasks}
                        teamMembers={availableTeamMembers}
                        skills={availableSkills}
                        workspaceSelected={workspaceSelected}
                    />
                </div>
            </Paper>
            <Paper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {dataTablesData.length ? (
                        dataTablesData.map(el => (
                            <TableContainer
                                defaultSort="when"
                                headCells={headCells}
                                renderRow={renderRow}
                                exportAsCSV={handleExportAsCSV}
                                key={el.id}
                                {...el}
                            />
                        ))
                    ) : (
                        <div className={'empty'}>No recents events found</div>
                    )}
                </div>
            </Paper>
        </>
    );
};

RecentsEvents.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    dateFormat: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
    hasAutorization: Proptypes.bool.isRequired,
};

export default withCustomErrorBoundary(RecentsEvents);
