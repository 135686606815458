import React from 'react';
import { Button } from '@material-ui/core';
import { browserHistory } from 'react-router';


const NotInMobile = ()=>{
    return (
        <div className="NotInMobile">
            This view is not available on mobile.
            <Button variant="outlined" onClick={()=>{ browserHistory.goBack(); }}>Go back</Button>
        </div>
    );
};

export default NotInMobile;