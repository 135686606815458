import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import _ from 'underscore';
import $ from 'jquery';
import classnames from 'clsx';
import Unautorized from '../Unautorized/Unautorized';
import InfoIcon from '@material-ui/icons/Info';
import * as firebaseEvents from '../../utils/firebaseEvents';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Divider } from '@material-ui/core';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import { getAccountId, getAccountTask, getAccountUsers } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const AddWorkTime = ({router}) => {
    const intl = useIntl();
    const task = useSelector(getAccountTask(router.params.uid));
    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const users = useSelector(getAccountUsers);


    const formatNum = (number) => {
        return Math.round(number * 100) / 100;
    };

    let predefinedHours = null;

    if (task?.minEffort && task?.maxEffort) {
        predefinedHours = formatNum((parseFloat(task.maxEffort) + parseFloat(task.minEffort)) / 2);
    }

    const [whoWorked, setWhoWorked] = useState(userId);
    const [date, setDate] = useState(moment().toDate());
    const [hours, setHours] = useState(predefinedHours);
    const [showUserSelect, setShowUserSelect] = useState(false);

    const close = () => {
        var path = '/' + router.routes[3].path;
        if (router.routes[3].path === ':uid') {
            path = '/' + router.routes[2].path;
        }

        browserHistory.push(path + '/' + router.params.uid);
    };

    const save = () => {
        firebaseEvents.addTimeWorked(
            accountId,
            router.params.uid,
            {
                member: whoWorked,
                date: moment(date).toISOString(),
                hours
            },
            false
        );

        close();
    };

    const changeAssignedUser = (value) => {
        setWhoWorked(value);
        setShowUserSelect(false);
    };

    const doShowUserSelect = () => {
        setShowUserSelect(true);
    };

    const hideUserSelect = (e) => {
        if ($(e.target).closest('.whoSelect').length === 0) {
            setShowUserSelect(false);
        }
    };

    const getWhoSelect = () => {

        if (users) {
            const isDisabled = !(task?.canManage);
            const taskNbWorkers = task?.nbWorkers || null;
            const userWorking = !task?.userWorking ? _.isArray(task.userWorking) : task.userWorking || [];
            const maxSelectWorkers = taskNbWorkers ? taskNbWorkers : userWorking ? _.uniq([userWorking]).length : 1;
            let selectedNames = (users[whoWorked]) ? users[whoWorked].displayName : '';
            
            if(_.isArray(whoWorked) && whoWorked.length > 1) {
                selectedNames = whoWorked.reduce((acc, userId) => {
                    const userName = users[userId]?.displayName;                    
                    acc.push(userName);
                    return acc;
                }, []).join(', ');
            }

            return (
                <div className={classnames('whoSelect', { disabled: isDisabled })}>
                    <div onClick={(isDisabled) ? null : doShowUserSelect}>
                        <span className="label">{intl.formatMessage({id:'Who worked'})}</span>
                        <span>{selectedNames}</span>
                    </div>
                    {showUserSelect && <SelectTeamMember
                        maxSelect={maxSelectWorkers}
                        currentUser={_.isArray(whoWorked) ? whoWorked : [whoWorked] || []}
                        onSelect={changeAssignedUser}
                        onClose={hideUserSelect}
                        open={doShowUserSelect}
                        taskId={task.id}
                    />}
                </div>
            );
        }


        return null;
    };

    const changeDate = (date) => {
        setDate(date.format('YYYY-MM-DD'));
    };

    const changeHours = (e) => {
        setHours(e.target.value.replace(',', '.'));
    };

    const checkHours = () => {
        let value = hours;
        if (value) {
            if (value[value.length - 1] === 'd') {
                value = value.slice(0, value.length - 1) * 8;
            }
            else if (value[value.length - 1] === 'm') {
                value = value.slice(0, value.length - 1) / 60;
            }

            if (value !== hours) {
                setHours(formatNum(value));
            }
            return;
        }
        setHours(1);
    };

    const getHoursInput = () => {
        let hoursVal = hours;
        if (hoursVal === null) {
            hoursVal = 1;
        }

        return (
            <TextField
                autoFocus
                margin="normal"
                className="hours"
                label={intl.formatMessage({id:'Number of hours'})}
                value={hoursVal}
                onChange={changeHours}
                onBlur={checkHours}
            />
        );
    };

    const handleEnterKeySave = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            save();
        }
    };

    if (
        task?.canWork ||
        task?.canManage
    ) {

        return (
            <Dialog
                className='AddWorkTime'
                open={true}
                onClose={close}
                onKeyUp={handleEnterKeySave}
                maxWidth={false}
                data-sidebar='preventClose'
            >
                <DialogTitle>{intl.formatMessage({id:'Add time worked'})}</DialogTitle>
                <Divider />
                <DialogContent className="content">
                    <div className="content">
                        <div className="line">

                            {getWhoSelect()}

                            <DatePicker
                                className="datePicker"
                                format="YYYY-MM-DD"
                                margin="normal"
                                label={intl.formatMessage({id:'When'})}
                                value={date}
                                onChange={changeDate}
                                autoOk={true}
                                cancelLabel={null}
                                okLabel={null}
                            />

                            <div className="hoursContainer">
                                {getHoursInput()}

                                <Tooltip placement="top" overlay={intl.formatMessage({id:'timetracking.hours.help.text'})}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>

                        </div>


                    </div>
                </DialogContent>
                <DialogActions className="actions">
                    <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
                    <Button variant="contained" onClick={save} color="primary">{intl.formatMessage({id:'Save Changes'})}</Button>
                </DialogActions>

            </Dialog>
        );
    }


    return (
        <Dialog
            className='AddWorkTime'
            open={true}
            onClose={close}
            maxWidth={false}
        >
            <DialogTitle>{intl.formatMessage({id:'Add time worked'})}</DialogTitle>
            <Divider />
            <DialogContent className="content">
                <div className="content">
                    <Unautorized />
                </div>
            </DialogContent>
            <DialogActions className="actions">
                <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
            </DialogActions>

        </Dialog>
    );




};

export default withRouter(withCustomErrorBoundary(AddWorkTime));