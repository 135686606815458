import React, { useMemo } from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getAccountTaskEmailToTask } from '../../utils/selectors/account';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskDetailEmailToTask = ({ taskId, open, onClose }) => {
    const intl = useIntl();
    const taskEmailToTask = useSelector(getAccountTaskEmailToTask(taskId));

    const changeEmailToTask = (e) => {
        firebaseEvents.changeEmailToTask(taskId, e.target.checked);
    };

    const getAddressInfo = useMemo(() => {
        if (!taskEmailToTask) {
            return null;
        }
        return <>
            <strong>{intl.formatMessage({id: 'Send your tasks to: '})} {' '}</strong>
            <span onClick={() => navigator.clipboard.writeText('mailto:planlessio+' + taskEmailToTask + '@gmail.com')} >
                <a href={'mailto:planlessio+' + taskEmailToTask + '@gmail.com'}>planlessio+{taskEmailToTask}@gmail.com</a>
                <FileCopy id='emailToTask__copyLink' onClick={() => toastr.success('Link copied!')} />
            </span>
        </>;
    }, [taskEmailToTask]);

    return (
        <Dialog
            className="emailToTaskDialog"
            open={open}
            onClose={onClose}
            maxWidth={false}
        >
            <DialogTitle>{intl.formatMessage({id: 'Email to task settings'})}</DialogTitle>
            <Divider />
            <DialogContent className="content">
                <p>
                    {intl.formatMessage({id: 'In order to create sub tasks by sending emails, you need to activate this option and send emails to the generated email'})}
                </p>
                <div>
                    <Switch
                        checked={taskEmailToTask}
                        onChange={changeEmailToTask}
                        size='small'
                    />
                    {intl.formatMessage({id: 'Activate Email to task?'})}
                </div>
                {getAddressInfo}
            </DialogContent>
            <DialogActions className="actions">
                <Button id='actions__close' variant="contained" onClick={onClose}>
                    {intl.formatMessage({id: 'Close'})}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TaskDetailEmailToTask.propTypes = {
    taskId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withCustomErrorBoundary(TaskDetailEmailToTask);
