import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment-timezone';
import _ from 'underscore';
import {useSelector} from 'react-redux';
import {getUserId} from '../../utils/selectors/user';
import {getAccountChat, getAccountUsers} from '../../utils/selectors/account';
import { getUserAvatar } from '../../utils/utils';
import { toastr } from 'react-redux-toastr';
import { browserHistory } from 'react-router';
import linkifyHtml from 'linkifyjs/html';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { Twemoji } from 'react-emoji-render';
import dompurify from 'dompurify';

const ChatMessagesToastr = () =>{
    const currUser = useSelector(getUserId);
    const users = useSelector(getAccountUsers);
    const chat = useSelector(getAccountChat);
    const [chatMessagesToastrSent, setChatMessagesToastrSent] = useState({});

    const newMessageAudio = new Audio('../../../stylesheets/assets/audio/new.mp3');
    const messageAudio = new Audio('../../../stylesheets/assets/audio/message.mp3');
    messageAudio.volume = 0.5;

    const sanitizer = dompurify.sanitize;

    const parseComment = (comment) => {
        return sanitizer(
            comment
                .replace('\n\n', '<br/><br/>')
                .replace('\r\r', '<br/><br/>')
                .replace('\n', '<br/>')
                .replace('\r', '<br/>')
                .replace(/@\[((?:[^\]]*))\]\(((?:[^)]*))\)/gi, '<span class="mention">@$1</span>')
                .replace(/\*\*((?:[^*]*))\*\*/gi, '<b>$1</b>')
                .replace(/\*((?:[^*]*))\*/gi, '<em>$1</em>')
                .replace(/´((?:[^*]*))´/gi, '<code>$1</code>'),
        );
    };

    
    if (currUser && chat) {

        for (var channelType in chat) {
            for (var i in chat[channelType]) {
                var channel = chat[channelType][i];
                if (channel.messages && channel.participants && channel.participants.indexOf(currUser) !== -1) {
                    // limit to last 40 messages
                    var msgs = _.sortBy(channel.messages, 'at');
                    msgs = msgs.reverse();
                    msgs = msgs.slice(0, 40);

                    msgs.forEach(msg => {
                        if (
                            moment(msg.at).isAfter(moment().subtract(1, 'minutes')) &&
                            !chatMessagesToastrSent[channelType + '.' + i + '.' + msg.k] &&
                            msg.sentBy !== currUser
                        ) {


                            var avatarSender = getUserAvatar(msg.sentBy, false, users[msg.sentBy]?.avatar);
                            var clickAction = '/chat/' + channelType + '/' + i;

                            var title = users[msg.sentBy]
                                ? users[msg.sentBy].displayName
                                : null;

                            if (channelType === 'direct') {
                                clickAction = '/chat/' + channelType + '/' + msg.sentBy;
                                if (msg.type === 'file') {
                                    title += ' sent you a file';
                                } else {
                                    title += ' sent you a direct message';
                                }
                            } else if (channelType === 'channels') {
                                if (msg.type === 'file') {
                                    title += ' sent a file in #' + i;
                                } else {
                                    title += ' sent a message in #' + i;
                                }
                            } else if (channelType === 'groups') {
                                if (msg.type === 'file') {
                                    title += ' sent a file in a group chat';
                                } else {
                                    title += ' sent a message in a group chat';
                                }
                            }

                            if (msg.type === 'text' || msg.type === 'file') {
                                if (window.location.pathname !== clickAction) {
                                    toastr.light(title, {
                                        timeOut: 10000,
                                        className: 'chatToastr',
                                        icon: avatarSender,
                                        position: 'bottom-left',
                                        transitionIn: 'bounceIn',
                                        transitionOut: 'bounceOut',
                                        onToastrClick: () => browserHistory.push(clickAction),
                                        closeOnToastrClick: true,
                                        component: (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: linkifyHtml(
                                                        parseComment(
                                                            ReactDOMServer.renderToString(
                                                                <Twemoji svg text={msg.value || ''} />,
                                                            ),
                                                        ),
                                                    ),
                                                }}
                                            ></span>
                                        ),
                                    });

                                    newMessageAudio.play();
                                } else {
                                    firebaseEvents.markChatMessageAsRead(channelType, i, msg.k);
                                    messageAudio.play();
                                }
                            }
                            let newEntry = {};
                            newEntry[channelType + '.' + i + '.' + msg.k] = true;
                            setChatMessagesToastrSent({...chatMessagesToastrSent, ...newEntry});
                        }
                    });
                }
            }
        }
    }

    return null;
};

export default ChatMessagesToastr;