import React, { useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getAccountTaskDoneby, getAccountTaskEstimationsUserId, getAccountTaskForcedUsers, getAccountTaskHasChildren, getAccountTaskNbworkers, getAccountTaskStatus, getAccountTaskUserworking, getAccountUserAvatar, getAccountUserDisplayName} from '../../utils/selectors/account';
import { getUserAvatar } from '../../utils/utils';
import Tooltip from 'rc-tooltip';
import * as firebaseEvents from '../../utils/firebaseEvents';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { Avatar } from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import _ from 'underscore';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';

const TeamMemberAvatar = ({ userId }) => {
    const userName = useSelector(getAccountUserDisplayName(userId));
    const userAvatar = useSelector(getAccountUserAvatar(userId));

    return useMemo(()=>(
        <Tooltip
            key={userId}
            placement="left"
            overlay={userName}
        >
            {getUserAvatar(userId, false, userAvatar)}
        </Tooltip>
    ), [userId, userName, userAvatar]);
};


const TeamMembersAvatars = ({ teamMembersIds }) => {
    return (
        <AvatarGroup max={4}>
            {(!teamMembersIds || !teamMembersIds.length) && <Avatar className="avatar emptyAvatar noAssignee"><AccountCircleOutlined /></Avatar>}
            {teamMembersIds && teamMembersIds.length && teamMembersIds.map((userId, index) => {
                return <TeamMemberAvatar userId={userId} key={index} />;
            })}
        </AvatarGroup>
    );    
};

const TaskLineUser = ({ taskId }) => {
    const intl = useIntl();
    const taskForcedUsers = useSelector(getAccountTaskForcedUsers(taskId));
    const taskEstimationsUserId = useSelector(getAccountTaskEstimationsUserId(taskId), shallowEqual);
    const usersWorking = useSelector(getAccountTaskUserworking(taskId));
    const taskNbWorkers = useSelector(getAccountTaskNbworkers(taskId));
    const doneBy = useSelector(getAccountTaskDoneby(taskId));
    const status = useSelector(getAccountTaskStatus(taskId));
    const taskHasChildrens = useSelector(getAccountTaskHasChildren(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));

    const [showSelectTeamMember, setShowSelectTeamMember] = useState(false);

    const teamMembersIds = useMemo(() => {
        let userId = [];
        if (status === 'inprogress') {
            userId = usersWorking || taskEstimationsUserId || taskForcedUsers;
        } else if (status !== 'done') {
            if (!taskNbWorkers || taskNbWorkers === 1) {
                if (taskForcedUsers) {
                    userId = taskForcedUsers;
                } else if (taskEstimationsUserId) {
                    userId = taskEstimationsUserId;
                }
            } else {
                if (taskForcedUsers && taskForcedUsers.length === taskNbWorkers) {
                    userId = taskForcedUsers;
                } else if (taskEstimationsUserId) {
                    userId = taskEstimationsUserId;
                }
            }
        } else if (doneBy) {
            userId = doneBy;
        } else if (usersWorking) {
            userId = usersWorking;
        }
        if(status === 'done'){
            userId = doneBy || taskForcedUsers || usersWorking;
        }

        if (typeof userId === 'string') {
            userId = [userId];
        }

        return userId;
    }, [status, usersWorking, taskNbWorkers, taskForcedUsers, taskEstimationsUserId, doneBy, taskHasChildrens]);

    const changeAssignedUser = (values) => {
        const selectedUsers = Array.isArray(values) ? values : [values];
        const nbWorkers = parseInt(taskNbWorkers) || 1;
        const prevAssignees = _.uniq([
            ...taskEstimationsUserId || [], 
            ...taskForcedUsers || [],
        ]);

        if(!selectedUsers.length) {
            setShowSelectTeamMember(false);
            return;
        }

        if(_.size(selectedUsers) > nbWorkers) {
            toastr.error('Maximum number of workers already selected. Remove one first.');
            return;
        }
        
        if(
            _.size(prevAssignees) > 1 &&
            selectedUsers.every(assignee => prevAssignees.includes(assignee)) &&
            prevAssignees.every(assignee => selectedUsers.includes(assignee))
        ) {
            setShowSelectTeamMember(false);
            return;
        }

        if(taskStatus === 'done'){
            firebaseEvents.changeAssignedUser(taskId, selectedUsers);
            setShowSelectTeamMember(false);
            return;
        }

        toastr.confirm(intl.formatMessage({id: 'Are you sure you want to force/assign this member on the task?'}), {
            onOk: () => {
                firebaseEvents.changeAssignedUser(taskId, selectedUsers);
                setShowSelectTeamMember(false);
                return;
            },
            okText: intl.formatMessage({id: 'FORCE ASSIGN'}),
            cancelText: intl.formatMessage({id: 'toastr.confirm.cancel'}),
        });

    };

    if(taskHasChildrens) {
        return <div/>;
    }

    return (
        <>
            <div className="TaskLineUser" onClick={(e)=>{ setShowSelectTeamMember(true); e.stopPropagation();  }}>
                <TeamMembersAvatars teamMembersIds={teamMembersIds} />
            </div>
            {
                showSelectTeamMember && <SelectTeamMember
                    maxSelect={taskNbWorkers || 1}
                    currentUser={teamMembersIds || []}
                    onSelect={changeAssignedUser}
                    onClose={()=>setShowSelectTeamMember(false)}
                    open={true}
                    taskId={taskId}
                />
            }
        </>
    );
};

export default React.memo(withCustomErrorBoundary(TaskLineUser));