import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import _ from 'underscore';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { handleStopPropagation, tracking } from '../../utils/utils';
import { getOnlyResourceUsers, MEMBER_TYPES } from '../../utils/memberTypes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import SkillSlider from '../../components/SkillSlider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { createNewSkillIsolated, addSkillToUsers } from '../../utils/firebaseEvents';
import { canSee } from '../../utils/userFunctions';
import { toastr } from 'react-redux-toastr';
import { createNewMember } from '../../utils/firebaseEvents';
import { getAccountChargebeeCustomerId, getAccountId, getAccountPlan, getAccountPlanNbusers, getAccountUsers } from '../../utils/selectors/account';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';
import { getUserPermissions } from '../../utils/selectors/user';
import { toggleUpgradeSubscriptionModalShow } from '../../utils/actions/upgradeSubscriptionModal';

const EditSkillModal = ({ onSubmit, isOpen, skillName = '', onClose, groupId, defaultValue = {} }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newSkillName, setNewSkillName] = useState(skillName);

    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const userPermissions = useSelector(getUserPermissions);
    const accountId = useSelector(getAccountId);
    const accountPlan = useSelector(getAccountPlan);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const accountUsers = useSelector(getAccountUsers);
    const memberResources = getOnlyResourceUsers(accountUsers);

    useEffect(() => {
        if (!_.isEmpty(defaultValue?.selectedUsers)) {
            setSelectedUsers(defaultValue.selectedUsers.map(el => ({ ...memberResources[el], userId: el })));
        }

        if(skillName.trim() !== '') {
            setNewSkillName(skillName);
        }
    }, [isOpen, defaultValue, skillName]);

    // to be replaced by default values when someones was time.
    useEffect(() => {
        const usersKeys = Object.keys(memberResources);
        if (usersKeys.length === 1) {
            setSelectedUsers(usersKeys.map(el => ({ ...memberResources[el], userId: el })));
        }
    }, [accountUsers]);

    const handleCreateTeamMember = React.useCallback( async (searchValue, selectedUsers) => {
        if(_.size(memberResources) + 1 > parseInt(planNbusers)) {
            dispatch(toggleUpgradeSubscriptionModalShow({ show: true }));
            return;
        }

        if (canSee(['admin', 'subscription'], { data: { permissions: userPermissions } })) {
            const newMemberId = await createNewMember({
                accountId,
                displayName: searchValue,
                memberType: MEMBER_TYPES.userAndResource
            });

            toastr.success('Resource successfully created!');
            setSelectedUsers([...selectedUsers, { userId: newMemberId, displayName: searchValue }]);
            return;
        }
    }, [accountUsers, planNbusers, accountId, accountPlan, userPermissions, chargebeeCustomerId]);

    const handleSkillNameInput = React.useCallback(({ target: { value } }) => {
        setNewSkillName(value);
    }, []);

    const handleAutoComplete = React.useCallback((e, values) => {
        handleStopPropagation(e);

        const lastValue = values[values.length - 1];
        if (!values.length) {
            setSelectedUsers([]);
            return;
        }
        if (lastValue.userId) {
            setSelectedUsers(values);
            return;
        }

        handleCreateTeamMember(lastValue.inputValue, selectedUsers);
    }, [selectedUsers]);

    const updateSlider = (e, value, { userId }) => {
        setSelectedUsers(selectedUsers.map(el => (el.userId === userId ? { ...el, skillLvl: value } : el)));
    };

    const updateSliderCommit = (e, value, { userId }) => {
        setSelectedUsers(selectedUsers.map(el => (el.userId === userId ? { ...el, skillLvl: value } : el)));

        if (value === 0) {
            setSelectedUsers(selectedUsers.filter(el => el.userId !== userId));
            return;
        }
    };

    const handleSave = () => {
        if (!newSkillName || newSkillName.trim() === '') {
            toastr.error('A Skill name is required');
            return;
        }

        const newSkillKey = createNewSkillIsolated(newSkillName, groupId);

        if (selectedUsers.length) {
            addSkillToUsers({
                skillKey: newSkillKey,
                usersAndSkillLvl: selectedUsers.map(el => ({ userId: el.userId, skillLvl: el.skillLvl || 5 })),
            });
        }

        setNewSkillName('');
        setSelectedUsers([]);

        onSubmit(newSkillKey);
    };

    const handleClose = () => {
        tracking('CanceledAddSkill', { accountId: accountId });
        onClose();
    };

    const users = React.useMemo(() => {
        return !memberResources
            ? []
            : Object.values(memberResources).filter(
                el => !selectedUsers.find(a => a.userId === el.userId) && !el.viewer,
            );
    }, [accountUsers, selectedUsers]);

    return (
        <Dialog
            maxWidth={'md'}
            className="EditSkillModal"
            onClose={handleClose}
            onClick={handleStopPropagation}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            data-sidebar="preventClose"
        >
            <MuiDialogTitle
                className="title"
                disableTypography
                onClick={handleStopPropagation}
                onClose={handleClose}
            >
                <Typography variant="h6"> {intl.formatMessage({ id: 'Edit Skill' })}</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers id='EditSkillModalContent' onClick={handleStopPropagation} >
                <FormControl fullWidth className="skill-name-container" component="fieldset">
                    <FormLabel component="legend">{intl.formatMessage({ id: 'Skill name' })} </FormLabel>
                    <Input
                        value={newSkillName}
                        onChange={handleSkillNameInput}
                        onClick={handleStopPropagation}
                        id="skillName"
                        name="skillName"
                        label={intl.formatMessage({ id: 'Skill name' })}
                        type="text"
                        autoFocus
                    />
                </FormControl>
                {newSkillName && newSkillName.trim() !== '' && (
                    <>
                        <FormControl fullWidth className="buttons-container remove-padding" component="fieldset" onClick={handleStopPropagation}>
                            <Autocomplete
                                fullWidth
                                value={selectedUsers}
                                onChange={handleAutoComplete}
                                filterSelectedOptions={true}
                                multiple
                                className="User_Selector autocomplete owners"
                                id="users-To"
                                options={users}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const props = getTagProps({ index });

                                        return (
                                            <Chip
                                                key={index}
                                                className="MuiChip-deleteIcon"
                                                avatar={
                                                    <StateUserAvatar
                                                        className="avatar"
                                                        userId={option.userId}
                                                        disableTooltip={true}
                                                    />
                                                }
                                                variant="outlined"
                                                label={option.displayName}
                                                {...props}
                                            />
                                        );
                                    })
                                }
                                filterOptions={(options, { inputValue }) => {
                                    const valid = options.filter(o => {
                                        return (
                                            !inputValue ||
                                            o.displayName?.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !==
                                                -1
                                        );
                                    });

                                    if (valid.length) {
                                        return valid;
                                    }

                                    if(inputValue.trim() === '') {
                                        return [{value: 'empty', inputValue: '', displayName: 'Type a name of a resource'}];
                                    }
                                    return [{value: 'new', inputValue: inputValue, displayName: 'Create new resource "' + inputValue + '"'}];
                                }}
                                getOptionLabel={option => {
                                    if(option.value === 'empty') {
                                        return <div
                                            key={option.displayName}
                                            onClick={(e) => handleStopPropagation(e)}
                                            className="optionUser"
                                        >
                                            {option.displayName}
                                        </div>;
                                    }

                                    return <div key={option.displayName} className="optionUser">
                                        <StateUserAvatar
                                            className="avatar"
                                            userId={option.userId}
                                            disableTooltip={true}
                                        />
                                        {option.displayName}{' '}
                                    </div>;
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            placeholder={
                                                !selectedUsers.length
                                                    ? 'Type to search or create a new resource...'
                                                    : ''
                                            }
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            label="Resources with this skill"
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        {!!selectedUsers.length && (
                            <span className="info">
                                Define below the level of expertise in this skill for each resource.
                            </span>
                        )}
                        {selectedUsers.map(el => (
                            <div className="User_Skill_Container">
                                <StateUserAvatar
                                    className="avatar"
                                    userId={el.userId}
                                    disableTooltip={true}
                                />
                                <div className="userName"> {el.displayName} </div>
                                <div className="skill_slider">
                                    <SkillSlider
                                        onChange={(e, value) => updateSlider(e, value, el)}
                                        onChangeCommitted={(e, value) => updateSliderCommit(e, value, el)}
                                        value={el.skillLvl >= 0 ? el.skillLvl : 5}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </MuiDialogContent>
            <MuiDialogActions className="actions">
                <Button className="cancel-btn" onClick={e => {handleStopPropagation(e); handleClose();}} variant="contained">
                    {intl.formatMessage({ id: 'CANCEL' })}
                </Button>
                <Button className="submit-btn" variant="contained" color="primary" onClick={e => {handleStopPropagation(e); handleSave();}}>
                    {intl.formatMessage({ id: 'Save Changes' })}
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
};

export default React.memo(EditSkillModal);
