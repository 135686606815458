import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getAccountId, getAccountSkills, getAccountUserSkills } from '../../../utils/selectors/account';
import { addChallenge, updateMemberData } from '../../../utils/firebaseEvents';
import _ from 'underscore';
import MemberSkillComponent from '../../MemberSkillComponent/MemberSkillComponent';
import { Button } from '@material-ui/core';
import SelectSkill from '../../SelectSkill/SelectSkill';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../../utils/hooks/useInternalization';

const Skills = ({ router, helperText = null }) => {
    const { t } = useInternalization();
    // It will use this to get the skills of the user when the component is being usend in MyAccount.tsx
    const userLoggedId = useSelector(state => state.app.user.data.userId);
    const isShowingByRoute = !!router.params.uid;
    const userId = useMemo(
        () => isShowingByRoute ? router.params.uid : userLoggedId,
        [router.params.uid, userLoggedId, isShowingByRoute]
    );

    const accountId = useSelector(getAccountId);
    const accountSkills = useSelector(getAccountSkills);
    const userSkills = useSelector(getAccountUserSkills(userId));

    const userSkillsIds = useMemo(
        () => !_.isEmpty(userSkills) ? Object.keys(userSkills).filter(id => userSkills[id]) : [],
        [userSkills]
    );
    const memberSkills = useMemo(
        () => !_.isEmpty(accountSkills) && !_.isEmpty(userSkillsIds) ?
            _.sortBy(
                userSkillsIds.filter(skillId => accountSkills[skillId]).map(skillId => ({
                    id: skillId,
                    name: accountSkills[skillId]?.name,
                    level: userSkills[skillId],
                })),
                'name'
            ) : [],
        [userSkillsIds, accountSkills]
    );

    const [showSkillChoice, setShowSkillChoice] = useState(false);

    const addSkillToList = (values) => {
        const data = Array.isArray(values) ? values : [values];

        const newSkillList = data.reduce((acc, el) => {
            return {
                ...acc,
                [el]: _.get(userSkills, [el], null) || 5
            };
        }, {});

        addChallenge('addMemberSkill');

        updateMemberData(
            accountId,
            userId,
            { skills: newSkillList }
        );

        setShowSkillChoice(false);
    };

    const updateSkills = (skillId, value) => {
        var skills = {...userSkills};
        skills[skillId] = value;

        addChallenge('changeMemberSkillLevel');
        if (value === 0) {
            addChallenge('removeMemberSkill');
        }

        updateMemberData(
            accountId,
            userId,
            { skills: skills }
        );

    };

    return (
        <div className='skills'>
            <p>{isShowingByRoute ? t('employee.skills.info.tooltip') : helperText}</p>
            <div className="skillSelect">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowSkillChoice(!showSkillChoice)}
                >{t('Add a skill')}</Button>
                {showSkillChoice && <SelectSkill
                    defaultValue={{
                        selectedUsers: [userId]
                    }}
                    isMultiSelect
                    currentSkill={userSkillsIds}
                    onSelect={addSkillToList}
                    onClose={() => setShowSkillChoice(false)}
                    open={showSkillChoice}
                />}
            </div>
            <div className="skills">
                {!!memberSkills.length && memberSkills.map(skill => (
                    <MemberSkillComponent
                        key={skill.id}
                        skillId={skill.id}
                        label={skill.name}
                        level={skill.level}
                        onChange={updateSkills} />
                ))}
            </div>
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(Skills));