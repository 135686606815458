import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import {  sortOtherToLast } from '../utils';
import { getTaskValueSplitted } from '../../../utils/costBudget';

import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const BillableCostBreakdown = ({ 
    stateTasks,
    workspaceSelected,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);
    
    const [data, setData] = useState({
        teamMembersData: {},
        skillsData: {},
        tasksData: {}, 
        total: 0,
    });

    useEffect(() => {
        // in the case of beeing root lvl 
        // get all of the sub childs 
        const workspaceMainItens = workspaceSelected === 'root' ? 
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean) 
            : _.get(stateTasks[workspaceSelected], 'childrens' , []);
        
        const byTeamMember = {}; 
        const bySkill = {}; 
        const byTask = {}; 
        let total = 0;
        
        workspaceMainItens.forEach(el => {

            if(el.type && el.type !== 'task'){
                return false;
            }

            const costValueSplitted = getTaskValueSplitted({taskId: el.id, allTasks: stateTasks, accountUsers: accountTeamMembers});

            //  costValueSplitted
            // by TM 
            Object.entries(costValueSplitted.byUsers).forEach(([userId, value]) => {
                if(byTeamMember[userId]){
                    byTeamMember[userId].value = value.billableCost; 
                } else {
                    const userDetail = accountTeamMembers[userId];
                    byTeamMember[userId] = {
                        value: value.billableCost, 
                        name: userDetail?.displayName || '(deleted user)', 
                        id: userId,
                    };
                }
            });
            // bySkill
            Object.entries(costValueSplitted.bySkills).forEach(([skillId, value]) => {
                if(bySkill[skillId]){
                    bySkill[skillId].value = value.billableCost; 
                } else {
                    const skillDetails = !accountSkills || !accountSkills[skillId] ? { name: 'No Skill defined'} : accountSkills[skillId];
                    bySkill[skillId] = {
                        value:  value.billableCost, 
                        name:  skillDetails?.name,
                        id:  skillId,
                    };
                }
            });
            // bySkill
            if(byTask[el.id]){
                byTask[el.id].value = costValueSplitted.totalBillableTaskValue; 
            } else {
                byTask[el.id] = {
                    value: costValueSplitted.totalBillableTaskValue, 
                    name: stateTasks[el.id].title,
                    id: el.id, 
                };
            }

            total += costValueSplitted.totalBillableTaskValue;
        });

        const convertData = (dataEntry) => {
            const data = []; 
            Object
                .values(dataEntry)
                .sort((a, b) => b.value - a.value)
                .forEach((el, idx) => {
                    if(idx === 3){
                        data.push({
                            name: 'Others',
                            value: el.value
                        });
                        return;
                    }

                    if(idx > 3) {
                        data[3].value += el.value;
                        return ;
                    }

                    data.push({
                        name: el.name,
                        value: el.value
                    });
                    return ;
                });
            return data;
        };

        setData({
            skillsData: convertData(bySkill),
            tasksData: convertData(byTask),
            teamMembersData: convertData(byTeamMember),
            total: total,
        });
    }, [workspaceSelected, stateTasks, accountTeamMembers, accountSkills]);

    const path = window.location.pathname.split('/').slice(0,3).join('/');
    let hasData = (toCheck) =>  Object.keys(toCheck).find(key => toCheck[key].value);
    return <div className={'overview-repartition'}>
        <div>
            <div>
            Billable Cost per Sub Items
            </div>
            <div>
                {
                    hasData(data.tasksData) ? data
                        .tasksData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>) 
                        : <div className='empty small'> No Data</div>        
                }
            </div>
        </div>
        <div>
            <div>
            Billable Cost per Team Members
            </div>
            <div>
                {
                    hasData(data.teamMembersData) ? data
                        .teamMembersData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>) 
                        : <div className='empty small'> No Data</div>        
                }
            </div>
        </div>
        <div>
            <div>
            Billable Cost per Skills
            </div>
            <div>
                {
                    hasData(data.skillsData) ? data
                        .skillsData
                        .sort(sortOtherToLast)
                        .map(el => <li key={`${el.name}-${el.value}`}><span>{el.name}</span><b>{`${parseFloat((el.value / data.total) * 100).toFixed(2)}%`}</b></li>) 
                        : <div className='empty small'> No Data</div>        
                }
            </div>
        </div>
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="effort-breakdown" 
                to={`${path}/billable-cost-budget-repartition`}
            >
                Go to <b>Billable Cost repartition</b> report
            </Button>
        </div>
    </div>;
};

BillableCostBreakdown.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default BillableCostBreakdown;