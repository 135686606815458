import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useIntl} from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import SortIcon from '@material-ui/icons/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'clsx';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Sortable from 'sortablejs';
import { getUserTodos } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Todo = React.memo((props)=>{
    const [title, setTitle] = useState(props.todo.title);
    const sortHandle = (props.todo.doneAt)?null:<SortIcon className="handle" />;

    return (
        <li className={(props.todo.doneAt)?'done':'todo'} data-todoid={props.todo.id}>
            {sortHandle}
            <Checkbox color="primary" size="small" checked={!!(props.todo.doneAt)} onChange={(e)=>firebaseEvents.markTodo(e.target.checked,props.todo.id)} />
            <input value={title} onChange={(e)=>setTitle(e.target.value)} onBlur={(e)=>firebaseEvents.saveTodoTitle(e.target.value, props.todo.id)} />
            <DeleteIcon className="deleteTask" onClick={()=>firebaseEvents.deleteTodo(props.todo.id)} />
        </li>
    );
});

const TodoList = ({masonry})=>{
    const intl = useIntl();
    const userTodos = useSelector(getUserTodos);
    const [showCompleted, setShowCompleted] = useState(false);

    useEffect(()=>{
        if(masonry.current){
            masonry.current.masonry.reloadItems();
            masonry.current.masonry.layout();
        }

        Sortable.create(document.getElementById('todos'), {
            handle: '.handle',
            filter: '.new',
            onEnd: (e)=>{
                firebaseEvents.orderTodos(e.item.getAttribute('data-todoid'), e.oldIndex, e.newIndex);
            }
        });
    });

    const addTodo = (e, order, force) => {
        if((e.keyCode === 13 || force) && e.target.value !== ''){
            firebaseEvents.addTodo(e.target.value, order);
            e.target.value = '';
        }
    };


    let todos =[], 
        nbTodos = 0, 
        list;

    if(userTodos){
        if(showCompleted){
            list = _.sortBy(userTodos, 'doneAt').reverse();
        }
        else {
            list = _.sortBy(userTodos, 'order');
        }

        list.forEach((todo)=>{

            if(
                (showCompleted && todo.doneAt) ||
                (!showCompleted && !todo.doneAt)
            ){
                nbTodos++;
                todos.push(
                    <Todo todo={todo} key={todo.id} />
                );
            }
        });
    }



    if(showCompleted && todos.length === 0){
        todos.push(
            <li key="none" className="empty">No to-do completed</li>
        );
    }

    return (
        <Card className="myTasks" square={true}>
            <CardHeader title="My Private To-do List" action={
                <div>
                    <a onClick={()=>setShowCompleted(false)} className={classnames('toggleOption', {active: !showCompleted})}>{intl.formatMessage({id:'TO DO'})}</a>
                    <a onClick={()=>setShowCompleted(true)} className={classnames('toggleOption', {active: showCompleted})}>{intl.formatMessage({id:'DONE'})}</a>
                </div>
            } />
            <CardContent>
                <ul id="todos">
                    {todos}
                </ul>
                {!showCompleted && (
                    <ul>
                        <li className="new" key="new">
                            <Checkbox color="primary" size="small" checked={false} disabled={true} />
                            <input placeholder="New to-do" onKeyUp={(e)=>addTodo(e, nbTodos)} onBlur={(e)=>addTodo(e, nbTodos, true)} />
                        </li>
                    </ul>
                )}
            </CardContent>
        </Card>
    );    

};

export default withCustomErrorBoundary(TodoList);