import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import _ from 'underscore';
import OverViewCard from '../OverViewCard';
import { getPrevisionValue, } from '../../../utils/costBudget';
import { currencyFormat  } from '../../../utils/currencyFormat';
import { Link } from 'react-router';
import { useIntl } from 'react-intl';

const CostBudgetOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const intl = useIntl();
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const settingsCurrency = useSelector((state)=> state?.app?.account?.settings.costBudget?.currency || 'USD');  
    const [overviewCurrentData, setOverviewCurrentData] = useState( {
        internalCost:  0,
        billableCost:  0,
        budget: 0,
        type: '',
        periodicity: ''
    });
    
    useEffect(()=> {
        (async () => {
            const workspaceTasks = workspaceSelected === 'root' ?  Object.entries(stateTasks).map(([id, el]) => !el.parent ?  id : false).filter(Boolean) : [workspaceSelected];
            const info = await Promise.all(workspaceTasks.map(async (el) => {
                const currentTask = stateTasks[el];
                const taskBudget = _.get(currentTask,['costBudgetSettings','value'], 0);
                const taskBudgetType = _.get(currentTask,['costBudgetSettings','type'], 0);
                const taskBudgetPeriodicity = _.get(currentTask,['costBudgetSettings','periodicity'], 'month');
                try{
                    const internalCost  = await getPrevisionValue.now.internal(currentTask, stateTasks, accountTeamMembers);
                    const billableCost  = await getPrevisionValue.now.billable(currentTask, stateTasks, accountTeamMembers);
                    return {
                        internalCost:  internalCost,
                        billableCost:  billableCost,
                        budget: taskBudget,
                        type: taskBudgetType,
                        periodicity: taskBudgetPeriodicity
                    };
                } catch(err) {
                    return {
                        internalCost:  0,
                        billableCost:  0,
                        budget: 0,
                        type: '',
                        periodicity: ''
                    };
                }
            }));
            if (workspaceTasks ==='root') {
                const aggregatedInfo = info.reduce((acc,el) => {
                    acc.internalCost = acc.internalCost +  el.internalCost;
                    acc.billableCost = acc.billableCost +  el.billableCost;
                    acc.budget = acc.budget +  el.budget;
                    return acc;
                }, {
                    internalCost: 0,
                    billableCost: 0,
                    budget: 0,
                });
                setOverviewCurrentData(aggregatedInfo);
                return;
            }
            if(info && info[0]){
                setOverviewCurrentData(info[0]);
            }
        })(); 
    }, [stateTasks, childsParents, workspaceSelected, accountTeamMembers]);
    
    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return <div className={classnames('bigNumbers')}>
        {
            workspaceSelected !== 'root' 
                ?
                <OverViewCard 
                    title={'Actual Budget'} 
                    value={overviewCurrentData.budget ? currencyFormat({ value: overviewCurrentData.budget, currency: settingsCurrency }) : 'N/A' } 
                    subTitle={ 
                        overviewCurrentData.budget ? `${intl.formatMessage({id: `${overviewCurrentData.type}-cost`})} ${overviewCurrentData.periodicity ? `,${intl.formatMessage({id: `per ${overviewCurrentData.periodicity}`})}` : '' }` : 'N/A'}
                />
                :
                <OverViewCard 
                    title={'Actual Budget'} 
                    value={overviewCurrentData.budget ? currencyFormat({ value: overviewCurrentData.budget, currency: settingsCurrency })  : 'N/A' } 
                />
        }
        <OverViewCard title={'Internal Cost'} value={currencyFormat({ value: overviewCurrentData.internalCost, currency: settingsCurrency })} />
        <OverViewCard title={'Billable Cost'} value={currencyFormat({ value: overviewCurrentData.billableCost, currency: settingsCurrency })} />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="tasks" 
                to={`${path}/cost-budget`}
            >
                Go to <b>Cost</b> report
            </Button>
        </div>
    </div>;
};

CostBudgetOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default CostBudgetOverview;