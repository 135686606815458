import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import Radio from '@material-ui/core/Radio';
import moment from 'moment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Expense from './Expenses/Expenses';
import Alerts from './Alerts/Alerts';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import FormLabel from '@material-ui/core/FormLabel';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'underscore';
import MenuItem from '@material-ui/core/MenuItem';
import { updateTaskCostBudgetSettings } from '../../utils/controllers/tasks/teamMemberCosts';
import Proptypes from 'prop-types';
import { getPrevisionValue } from '../../utils/costBudget';
import { currencyFormat } from '../../utils/currencyFormat';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'clsx';
import { getSymbol } from '../../utils/currencyFormat';
import ReactDOM from 'react-dom';
import { permissionsByPlan } from '../../utils/constants';
import Unautorized from '../Unautorized/Unautorized';
import { getLoadersTasks, getLoadersUsers } from '../../utils/selectors/app';
import { getAccountTasks, getAccountPlan, getAccountCostBudgetCurrency, getAccountSkills, getAccountUsers } from '../../utils/selectors/account';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../../components/Loader';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const areSettingsValid = ({ value, periodicity, costType }) =>
    (costType === 'retainer' && ['week', 'month', 'year'].includes(periodicity) && !isNaN(value)) ||
  (costType === 'fixed' && !isNaN(value));

const areDifferent = (original, copy) =>
    original.periodicity !== copy.periodicity || original.type !== copy.type || original.value !== copy.value;

const BudgetManagementDialog = ({ params: { uid }, router }) => {
    const [originalValues, setOriginalValues] = useState({
        periodicity: '',
        value: '',
        type: '',
    });
    const [periodicity, setPeriodicity] = useState('');
    const [inputValue, setInputValue] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [changed, setChanged] = useState(false);
    const [costs, setCosts] = useState({
        internalCost: 0,
        billableCost: 0,
        futureInternalCost: 0,
        futureBillableCost: 0,
    });
    const [costType, setCostType] = useState('fixed');
    const intl = useIntl();
    const taskLoaded = useSelector(getLoadersTasks);
    const usersLoaded = useSelector(getLoadersUsers); 
    const accountPlan = useSelector(getAccountPlan);
    const currency = useSelector(getAccountCostBudgetCurrency);
    const accountSkills = useSelector(getAccountSkills);
    const accountTeamMembers = useSelector(getAccountUsers);
    const stateTasks = useSelector(getAccountTasks); 


    const hasChilds = stateTasks && stateTasks[uid] && !!stateTasks[uid].childrens;
    const hasPermission = stateTasks && stateTasks[uid] && (stateTasks[uid].canAdmin || (stateTasks[uid].canManage && stateTasks[uid].permissions?.comingFrom !== uid));
    const currencySymbol = getSymbol(currency);

    useEffect(() => {
        if (
            !_.isEmpty(accountSkills) &&
            !_.isEmpty(accountTeamMembers) &&
            stateTasks &&
            stateTasks[uid] &&
            stateTasks[uid].costBudgetSettings
        ) {
            ReactDOM.unstable_batchedUpdates(() => {
                setPeriodicity(stateTasks[uid].costBudgetSettings.periodicity || '');
                setCostType(stateTasks[uid].costBudgetSettings.type || 'fixed');
                setInputValue(stateTasks[uid].costBudgetSettings.value);
                setOriginalValues({
                    periodicity: stateTasks[uid].costBudgetSettings.periodicity,
                    value: stateTasks[uid].costBudgetSettings.value,
                    type: stateTasks[uid].costBudgetSettings.type,
                });
            });
        }
    }, [stateTasks, accountTeamMembers, accountSkills]);

    useEffect(() => {
        (async () => {
            if(!stateTasks[uid]){
                return;
            }
            let checkWorktimeEntry = null;
            let checkTaskEntry = null;
            if (costType === 'retainer') {
                checkWorktimeEntry = entry => moment(entry.date).isSame(moment(), periodicity);
                checkTaskEntry = task => {
                    const date = _.get(task, ['estimations', 'expectedAt'], null);

                    return !date || moment(date, 'X').isSame(moment(), periodicity);
                };
            }

            const internalCost = await getPrevisionValue.now.internal(
                stateTasks[uid],
                stateTasks,
                accountTeamMembers,
                checkWorktimeEntry,
            );
            const billableCost = await getPrevisionValue.now.billable(
                stateTasks[uid],
                stateTasks,
                accountTeamMembers,
                checkWorktimeEntry,
            );
            const futureInternalCost = await getPrevisionValue.future.internal(
                stateTasks[uid],
                stateTasks,
                accountTeamMembers,
                accountSkills,
                checkTaskEntry,
            );
            const futureBillableCost = await getPrevisionValue.future.billable(
                stateTasks[uid],
                stateTasks,
                accountTeamMembers,
                accountSkills,
                checkTaskEntry,
            );

            setCosts({
                internalCost,
                billableCost,
                futureInternalCost,
                futureBillableCost,
            });
        })();
    }, [accountTeamMembers, accountTeamMembers, accountSkills, periodicity, costType]);

    const handleSelect = ({ target: { value } }) => {
        setChanged(true);
        setPeriodicity(value);
    };

    const handleTabChange = (e, value) => {
        setTabValue(value);
    };

    const handleClose = () => {
        if (changed) {
            toastr.confirm(intl.formatMessage({ id: 'task-budget-changes-close' }), {
                onOk: () => router.push(`/tasks/${uid}`),
                okText: intl.formatMessage({ id: 'OK' }),
                cancelText: intl.formatMessage({ id: 'toastr.confirm.cancel' }),
            });
            return;
        }

        router.push(`/tasks/${uid}`);
    };

    const handleInput = ({ target: { value } }) => {
        setInputValue(value.replace(',','.'));
    };

    const handleInputBlur = ({ target: { value } }) => {
        if (!value || isNaN(value)) {
            setInputValue(0);
            return;
        }

        setInputValue(value.includes('.') ? parseFloat(value).toFixed(2) : parseFloat(value));
        setChanged(true);
    };

    const saveChanges = () => {
        toastr.confirm(intl.formatMessage({ id: 'task-budget-warning' }), {
            onOk: () => {
                setChanged(false);
                updateTaskCostBudgetSettings({
                    taskId: uid,
                    value: inputValue,
                    periodicity: periodicity,
                    type: costType,
                });
                toastr.success(intl.formatMessage({ id: 'successfully-updated' }));
            },
            okText: intl.formatMessage({ id: 'OK' }),
            cancelText: intl.formatMessage({ id: 'toastr.confirm.cancel' }),
        });
    };

    const handleSetCostType = ({ target: { value } }) => {
        setChanged(true);
        ReactDOM.unstable_batchedUpdates(() => {
            setCostType(value);
            setPeriodicity(value === 'retainer' ? 'month' : '');
        });
    };

    const handleEnterKeyAdd = e => {
        if ((e.key === 'Enter' || e.keyCode === 13) && canSubmit) {
            saveChanges();
        }
    };

    if (!hasChilds) {
        return null;
    }
    
    if(taskLoaded || usersLoaded) {
        return (
            <Dialog fullWidth maxWidth="md" className="BudgetManagementDialog" open={true} onClose={handleClose}>
                <MuiDialogTitle className="title" disableTypography>
                    <Typography variant="h6">{intl.formatMessage({ id: 'Financials' })}</Typography>
                </MuiDialogTitle>
                <MuiDialogContent className="content" dividers>
                    <CardContent>
                        <Loader fullScreen={false} show={true} />
                    </CardContent>
                </MuiDialogContent>
            </Dialog>
        );
    }

    let biggerCost = 0;
    if (costs.internalCost > biggerCost) {
        biggerCost = costs.internalCost;
    }
    if (costs.billableCost > biggerCost) {
        biggerCost = costs.billableCost;
    }
    if (costs.futureInternalCost > biggerCost) {
        biggerCost = costs.futureInternalCost;
    }
    if (costs.futureBillableCost > biggerCost) {
        biggerCost = costs.futureBillableCost;
    }

    let internalCostWidth = (costs.internalCost / biggerCost) * 100,
        billableCostWidth = (costs.billableCost / biggerCost) * 100,
        futureInternalCostWidth = (costs.futureInternalCost / biggerCost) * 100,
        futureBillableCostWidth = (costs.futureBillableCost / biggerCost) * 100,
        currentProfit = (100 - (costs.internalCost * 100) / inputValue).toFixed(0),
        forecastedProfit = (100 - (costs.futureInternalCost * 100) / inputValue).toFixed(0);

    const canSubmit =
    hasPermission &&
    areDifferent(originalValues, { value: inputValue, periodicity, type: costType }) &&
    areSettingsValid({ value: inputValue, periodicity, costType });

    const hasPermissionsToSee = permissionsByPlan.financials.includes(accountPlan);

    return (
        <Dialog fullWidth maxWidth="md" className="BudgetManagementDialog" open={true} onClose={handleClose}>
            <MuiDialogTitle className="title" disableTypography>
                <Typography variant="h6">{intl.formatMessage({ id: 'Financials' })}</Typography>
                <CloseIcon onClick={handleClose} />
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers>
                {!hasPermissionsToSee && <Unautorized />}
                {hasPermissionsToSee && (
                    <>
                        {' '}
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label="Budget" id="budget-tab" />
                            <Tab label="Expenses" id="expenses-tab" />
                            <Tab label="Alerts" id="alerts-tab" />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <div onKeyUp={handleEnterKeyAdd} className="budget-content">
                                <div>
                                    <div className="budgetType">
                                        <FormControl className="buttons-container" component="fieldset">
                                            <FormLabel component="legend">{intl.formatMessage({ id: 'budget.type' })}</FormLabel>
                                            <RadioGroup
                                                className="buttons-container"
                                                onChange={handleSetCostType}
                                                value={costType}
                                                aria-label={intl.formatMessage({ id: 'type-of-budget-management' })}
                                                name="type"
                                            >
                                                <FormControlLabel
                                                    value="fixed"
                                                    control={<Radio size="small" />}
                                                    label={intl.formatMessage({ id: 'fixed-cost' })}
                                                />
                                                <FormControlLabel
                                                    value="retainer"
                                                    control={<Radio size="small" />}
                                                    label={intl.formatMessage({ id: 'retainer-cost' })}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <div className={'retainer'}>
                                            {costType === 'fixed' && (
                                                <TextField
                                                    disabled={!hasPermission}
                                                    margin="normal"
                                                    onChange={handleInput}
                                                    onBlur={handleInputBlur}
                                                    value={inputValue}
                                                    id="fixed-cost"
                                                    label={intl.formatMessage({ id: 'budget.amount' })}
                                                    type="text"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                                                    }}
                                                    fullWidth
                                                />
                                            )}
                                            {costType === 'retainer' && (
                                                <>
                                                    <TextField
                                                        disabled={!hasPermission}
                                                        margin="normal"
                                                        onChange={handleInput}
                                                        onBlur={handleInputBlur}
                                                        value={inputValue}
                                                        id="cost"
                                                        label={intl.formatMessage({ id: 'budget.amount' })}
                                                        type="text"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                                                        }}
                                                        fullWidth
                                                    />
                                                    <FormControl className="selector">
                                                        <InputLabel htmlFor="age-native-simple">
                                                            {intl.formatMessage({ id: 'periodicity' })}
                                                        </InputLabel>
                                                        <Select value={periodicity} onChange={handleSelect}>
                                                            <MenuItem value={'week'}>{intl.formatMessage({ id: 'per week' })}</MenuItem>
                                                            <MenuItem value={'month'}>{intl.formatMessage({ id: 'per month' })}</MenuItem>
                                                            <MenuItem value={'year'}>{intl.formatMessage({ id: 'per year' })}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        </div>
                                        <Button
                                            disabled={!canSubmit}
                                            onClick={saveChanges}
                                            variant="contained"
                                            color="primary"
                                            className="save-changes"
                                        >
                                            {intl.formatMessage({ id: 'Save Changes' })}
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <div className="overview">
                                        <div>
                                            <h1>{intl.formatMessage({ id: 'costs' })}</h1>
                                            <div>
                                                <div>
                                                    <div className="progressLabels">
                                                        <span>
                                                            {intl.formatMessage({ id: 'actual-internal-cost' })}
                                                            {costType === 'retainer' && '(Current Period)'}{' '}
                                                        </span>
                                                        <span>{currencyFormat({ value: costs.internalCost, currency: currency })}</span>
                                                    </div>
                                                    <div className="progress" style={{ width: internalCostWidth + '%' }} />
                                                </div>
                                                <div>
                                                    <div className="progressLabels">
                                                        <span>
                                                            {intl.formatMessage({ id: 'actual-billable-cost' })}
                                                            {costType === 'retainer' && '(Current Period)'}
                                                        </span>
                                                        <span>{currencyFormat({ value: costs.billableCost, currency: currency })}</span>
                                                    </div>
                                                    <div className="progress" style={{ width: billableCostWidth + '%' }} />
                                                </div>
                                                <div>
                                                    <div className="progressLabels">
                                                        <span>
                                                            {intl.formatMessage({ id: 'forecasted-internal-cost' })}
                                                            {costType === 'retainer' && '(Current Period)'}
                                                        </span>
                                                        <span>{currencyFormat({ value: costs.futureInternalCost, currency: currency })}</span>
                                                    </div>
                                                    <div className="progress" style={{ width: futureInternalCostWidth + '%' }} />
                                                </div>
                                                <div>
                                                    <div className="progressLabels">
                                                        <span>
                                                            {intl.formatMessage({ id: 'forecasted-billable-cost' })}
                                                            {costType === 'retainer' && '(Current Period)'}
                                                        </span>
                                                        <span>{currencyFormat({ value: costs.futureBillableCost, currency: currency })}</span>
                                                    </div>
                                                    <div className="progress" style={{ width: futureBillableCostWidth + '%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        {!!inputValue && (
                                            <div>
                                                <h1>{intl.formatMessage({ id: 'profits' })}</h1>
                                                <div className="profits">
                                                    <div className="profitsValues">
                                                        <span>{intl.formatMessage({ id: 'current' })}</span>
                                                        <span>
                                                            {currencyFormat({ value: inputValue - costs.internalCost, currency: currency })}
                                                        </span>
                                                    </div>
                                                    <div className={classnames('circularProgress', { danger: currentProfit < 0 })}>
                                                        <CircularProgress
                                                            variant="determinate"
                                                            value={Math.abs(currentProfit) > 100 ? 100 : Math.abs(currentProfit)}
                                                        />
                                                        <span>{currentProfit}%</span>
                                                    </div>
                                                    <div className="profitsValues">
                                                        <span>{intl.formatMessage({ id: 'forecasted' })}</span>
                                                        <span>
                                                            {currencyFormat({ value: inputValue - costs.futureInternalCost, currency: currency })}
                                                        </span>
                                                    </div>
                                                    <div className={classnames('circularProgress', { danger: forecastedProfit < 0 })}>
                                                        <CircularProgress
                                                            variant="determinate"
                                                            value={Math.abs(forecastedProfit) > 100 ? 100 : Math.abs(forecastedProfit)}
                                                        />
                                                        <span>{forecastedProfit}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                browserHistory.push('/insights/' + uid + '/cost-budget');
                                            }}
                                            color="primary"
                                            size="small"
                                        >
                                            {intl.formatMessage({ id: 'budget.overview.button' })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Expense taskId={uid} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Alerts taskBudget={inputValue} taskId={uid} />
                        </TabPanel>
                    </>
                )}
            </MuiDialogContent>
        </Dialog>
    );
};

BudgetManagementDialog.propTypes = {
    params: Proptypes.object.isRequired,
    router: Proptypes.object.isRequired,
};

const TabPanel = ({ children, value, index }) => {
    return <div hidden={value !== index}>{children}</div>;
};

export default withCustomErrorBoundary(BudgetManagementDialog);
