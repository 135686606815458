import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import useInternalization from '../../utils/hooks/useInternalization';
var MaxResourceModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var t = useInternalization().t;
    if (!isOpen)
        return null;
    return (React.createElement(Dialog, { maxWidth: "md", open: isOpen, "aria-labelledby": "customized-dialog-title", onClose: function () { return onClose(false); } },
        React.createElement("div", { className: "trialend-container" },
            React.createElement("strong", { className: "trialend-title" }, t('MaxResourceModal.title')),
            React.createElement("img", { src: "https://planless.sirv.com/App/blocked/planning_mentalmap.png", width: "670", height: "185", alt: "" }),
            React.createElement("div", { className: "trialend-subs" },
                React.createElement("span", null, t('MaxResourceModal.subscribe')),
                React.createElement("span", null, t('MaxResourceModal.planning'))),
            React.createElement("strong", { className: "trialend-simple" }, t('MaxResourceModal.simple')),
            React.createElement("p", { className: "trialend-unlimited" }, t('MaxResourceModal.unlimited')),
            React.createElement(Button, { className: "btn-subscribe", variant: "contained", color: "secondary", onClick: function () { return onClose(true); } }, t('MaxResourceModal.buttons.subscribe')))));
};
export default React.memo(MaxResourceModal);
