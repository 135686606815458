import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAccountBasePermissionsManagers,
    getAccountBasePermissionsOwners,
    getAccountDaysoff,
    getAccountId,
    getAccountTasks,
    getAccountUsers,
} from '../../utils/selectors/account';
import {
    getCalculating,
    getClosedSortables,
    getQuickSearch,
    getLoadersSkills,
    getLoadersDaysOff,
    getLoadersUsers,
    getParentChildIds,
    getTimelineFirstLevelTasks,
    getTimelineRange,
    getTimelineTasksToShow,
    getLimitViewTo,
    getFilters
} from '../../utils/selectors/app';
import Dexie from 'dexie';
import {defineClosedSortables, setTimelineRange} from '../App/AppActions';
import { getUserId, getUserPermissionsAdmin, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import {getTaskItems, checkOverLines} from './utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'clsx';
import _ from 'underscore';
import $ from 'jquery';
import { useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Timeline from '../Timeline/Timeline';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import TaskLine from '../TaskLine/TaskLine';
import { browserHistory, withRouter } from 'react-router';
import MultiSelectMenu  from './MultiSelectMenu';
import TimeLineHelpers  from './TimeLineHelpers';
import TaskListHeader from './TaskListHeader';
import moment from 'moment-timezone';
import Sortable from 'sortablejs';
import interact from 'interactjs';
import { CardContent } from '@material-ui/core';
import Loader from '../../components/Loader';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { shouldBlockSyncListReorder } from '../Integrations/utils';
import { TASK_LINE_SIZE } from '../../utils/constants';
import { tracking } from '../../utils/utils';

let persistentSortableClone;

const Tasks = ({ children, router: { params: { uid } } }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pathname = window.location.pathname;

    const loaderSkills = useSelector(getLoadersSkills);
    const loaderUsers = useSelector(getLoadersUsers);
    const loaderDaysOff = useSelector(getLoadersDaysOff);
    const allDataLoaded = !(loaderSkills || loaderUsers || loaderDaysOff);

    const tasks = useSelector(getAccountTasks);
    const parentChildIds = useSelector(getParentChildIds);
    const accountId = useSelector(getAccountId);
    const timezone = useSelector(getUserTimezone);
    const closedSortables = useSelector(getClosedSortables);
    const calculating = useSelector(getCalculating);
    const userId = useSelector(getUserId);
    const isUserAdmin = useSelector(getUserPermissionsAdmin);
    const baseOwners = useSelector(getAccountBasePermissionsOwners);
    const baseManagers = useSelector(getAccountBasePermissionsManagers);
    const timeFormat = useSelector(getUserTimeformat);
    const timelineRange = useSelector(getTimelineRange);
    const users = useSelector(getAccountUsers);
    const daysoff = useSelector(getAccountDaysoff);
    const tasksToShow = useSelector(getTimelineTasksToShow);
    const firstLevelTasks = useSelector(getTimelineFirstLevelTasks);
    const quickSearch = useSelector(getQuickSearch);
    const currentFilters = useSelector(getFilters);

    const limitView = useSelector(getLimitViewTo);

    const [selectedItems, setSelectedItems] = useState([]);
    const [startEndIndexes, setStartEndIndexes] = useState({ start: 0, end: 50 });

    useEffect(()=>{
        if (accountId) {
            const idb = new Dexie('planlessStore');

            idb.version(1).stores({
                planlessStore: 'key',
            });
            idb.planlessStore.get(`closedSortables-${accountId}`).then(data => {
                dispatch(defineClosedSortables(data?.data || [], accountId));
            });
        }
    }, [accountId]);

    const getStartEndIndexes = useCallback(() => {
        let startIndex = 0,
            stopIndex = 50;
        let taskHeaderDiv = $('#tasks-header + div');

        if (taskHeaderDiv.offset()) {
            const diff = taskHeaderDiv.offset().top - $(window).scrollTop();

            if (diff <= 0) {
                startIndex = Math.floor((0 - diff) / 34);
            }

            stopIndex =
                startIndex +
                Math.ceil(
                    ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)) / 34,
                );
        }

        setStartEndIndexes({
            start: startIndex,
            end: stopIndex
        });
    }, []);


    const setOpenTaskBg = useCallback(() => {
        $('#openTaskBg').css('top', -100);

        if (pathname.startsWith('/tasks/')) {
            if (
                $('.TaskLine[data-task-id=' + uid + ']') &&
                $('.TaskLine[data-task-id=' + uid + ']').offset()
            ) {
                $('#openTaskBg').css(
                    'top',
                    $('.TaskLine[data-task-id=' + uid + ']').offset().top,
                );
            }
        }
    }, [pathname, uid]);

    const checkSelectedItems = useCallback((e) => {
        const parents = $(e.target).parents();

        if(
            selectedItems.length &&
            !e.shiftKey &&
            !e.metaKey && // In case of selecting multiple task lines
            (!parents.closest('.sortable root').length ||  // Don't reload the whole tasks if click event was outside of the sortables
            parents.closest('#tasks-header').length > 0) // Ignore click event if it was on the tasks header
        ) {
            return;
        }

        if (parents && parents.last().length && parents.toArray().find(el => el.getAttribute && el.getAttribute('class')?.includes('SelectSkill_Modal'))) {
            return;
        }
        if (parents && parents.last().length && parents.toArray().find(el => el.getAttribute &&  el.getAttribute('class')?.includes('SelectTeamMember_Modal'))) {
            return;
        }

        if (
            $(e.target).closest('.multiAssignedUserChoice').length === 0 &&
            $(e.target).closest('.nameContainer').length === 0 &&
            $(e.target).className !== 'nameContainer'
        ) {
            $('.multiAssignedUserChoice').css('display', 'none');
        }
        if ($(e.target).closest('.skillSelect').length === 0) {
            $('#multiSkillDetailsSelect').css('display', 'none');
        }
        if (
            parseInt($('#multiPriorityDetails').css('left')) === 0 &&
            $(e.target).length &&
            ($(e.target)[0].localName === 'body' ||
                ($(e.target)[0].className &&
                    typeof $(e.target)[0].className === 'string' &&
                    $(e.target)[0].className.search('taskPriorityMenuItem') !== -1))
        ) {
            return;
        }
        if (
            parseInt($('#multiTagsDetails').css('left')) === 0 &&
            $(e.target).length &&
            (($(e.target)[0] && $(e.target)[0].id && $(e.target)[0].id.search('tagsSelect-option-') !== -1) ||
                ($(e.target)[0] &&
                    $(e.target)[0].className &&
                    typeof $(e.target)[0].className === 'string' &&
                    $(e.target)[0].className.search('tagSelectItem') !== -1))
        ) {
            return;
        }

        if (
            $(e.target).closest('#multiSelectMenu').length ||
            $(e.target).closest('.MuiDialog-root').length ||
            $(e.target).closest('.MuiAutocomplete-popper').length ||
            $(e.target).closest('.redux-toastr').length ||
            $(e.target).closest('.multiDatePicker').length ||
            $(e.target).closest('.addTagDialog').length
        ) {
            return;
        }

        let item;
        let list;

        if (
            (
                $(e.target).hasClass('TaskTitleInput') ||
                $(e.target).hasClass('minEffort') ||
                $(e.target).hasClass('maxEffort')
            ) &&
            $(e.target).hasClass('fromTaskLine') &&
            $(e.target).data('task-id')
        ) {
            item = { type: 'task', id: $(e.target).data('task-id') };
        } else if ($(e.target).closest('.TaskLineContainer').length) {
            item = { type: 'task', id: $(e.target).closest('.TaskLineContainer').data('task-id') };
        } else if ($(e.target).closest('.vis-group').length) {
            var classes = $(e.target).closest('.vis-group')[0].classList,
                id,
                type;

            classes.forEach(className => {
                if (className.startsWith('id-')) {
                    id = className.substring(3);
                } else if (className === 'task') {
                    type = className;
                }
            });

            item = { type, id };
        }

        if (!item) {
            setSelectedItems([]);
        } else if (e.metaKey || e.ctrlKey) {
            list = [...selectedItems];
            var index = -1;
            list.forEach((t,i) => {
                if (t.type === item.type && t.id === item.id) {
                    index = i;
                }
            });
            if (index === -1) {
                list.push(item);
            }
            else {
                list.splice(index, 1);
            }
            setSelectedItems(list);
        } else if (e.shiftKey && selectedItems.length > 0) {
            list = [];

            let startIndex = tasksToShow.indexOf(selectedItems[0].id),
                stopIndex = tasksToShow.indexOf(item.id);

            if(startIndex < stopIndex){
                list = [...tasksToShow].slice(startIndex, stopIndex+1).map((t)=>{ return {type:'task', id:t}; });
            }
            else {
                list = [...tasksToShow].slice(stopIndex, startIndex+1).map((t)=>{ return {type:'task', id:t}; }).reverse();
            }

            setSelectedItems(list);
        } else {
            setSelectedItems([item]);
        }

    }, [selectedItems, tasksToShow]);


    const setSelectedLines = useCallback(() => {

        $('#selectedLines').empty();
        var itemsToRemoveFromSelection = [];

        selectedItems.forEach(selected => {
            var top = -100;
            if (
                selected.type === 'task' &&
                $('.TaskLine[data-task-id=' + selected.id + ']') &&
                $('.TaskLine[data-task-id=' + selected.id + ']').offset()
            ) {
                top = $('.TaskLine[data-task-id=' + selected.id + ']').offset().top;
            }
            if (top !== -100) {
                $('#selectedLines').append('<div style="top:' + top + 'px"></div>');
            } else {
                itemsToRemoveFromSelection.push(selected);
            }
        });

        itemsToRemoveFromSelection.forEach(item => {
            var index = null;
            selectedItems.forEach((sel, ind) => {
                if ((!index || index === 0) && sel.id === item.id && sel.type === item.type) {
                    index = ind;
                }
            });
            if (!index || index === 0) {
                selectedItems.splice(index, 1);
            }
        });

        if (selectedItems.length > 1) {
            browserHistory.push('/tasks');
            $('#multiSelectMenu').css('left', 50);


            const hideTagsAndDeleteOptions = !!(selectedItems.map(selectedItem=>(tasks[selectedItem.id])).find(task=>task.sourceId));

            var nbTaskWithChildrens = 0,
                nbTaskCanManage = 0,
                nbTaskCanWork = 0,
                nbDone = 0;

            selectedItems.forEach(current => {
                if (tasks[current.id].childrens) {
                    nbTaskWithChildrens++;
                }
                if (tasks[current.id].canManage) {
                    nbTaskCanManage++;
                }
                if (tasks[current.id].canWork) {
                    nbTaskCanWork++;
                }
                if (tasks[current.id].status === 'done') {
                    nbDone++;
                }
            });

            $('#multiSelectMenu .actions > button').css('display', 'none');
            $('#multiSelectMenu .actionsDetails > div').css('left', -1000);

            if (nbTaskWithChildrens === 0) {
                if (nbTaskCanManage === selectedItems.length) {
                    if (nbDone !== selectedItems.length) {
                        $('#multiStatus').css('display', 'inline-flex');
                    }
                    $('#multiAssign').css('display', 'inline-flex');
                    $('#multiRemoveForceAssign').css('display', 'inline-flex');
                    $('#multiSkill').css('display', 'inline-flex');
                    $('#multiEffort').css('display', 'inline-flex');
                    $('#multiPriority').css('display', 'inline-flex');
                }
                if (nbTaskCanWork === selectedItems.length) {
                    if (nbDone !== selectedItems.length) {
                        $('#multiStatus').css('display', 'inline-flex');
                    }
                    $('#multiEffort').css('display', 'inline-flex');
                }
            }

            if (nbTaskCanManage === selectedItems.length) {
                $('#multiDeadline').css('display', 'inline-flex');
                $('#multiDelay').css('display', 'inline-flex');
                $('#multiTags').css('display', 'inline-flex');

                $('#multiDelete').css('display', 'inline-flex');
            }

            if (nbTaskCanManage === selectedItems.length) {
                $('#multiDelete').css('display', 'inline-flex');
            }

            if(hideTagsAndDeleteOptions) {
                $('#multiTags').css('display', 'none');
                $('#multiDelete').css('display', 'none');
            }
        } else {
            $('#multiSelectMenu').css('left', -1000);
        }
    }, [selectedItems, /* taskMultipleEdit, */ tasks]);

    const removeSelectedLines = useCallback((e) => {
        const TasksContainer = document.querySelector('.TasksContainer');
        const Tasks = document.querySelector('.Tasks');
        const header = document.querySelector('.Header');
        const tasksHeader = document.querySelector('#tasks-header');
        const structTasksHeader = document.querySelector('.StructTasksHeader');
        const selectedLines = document.querySelector('#selectedLines');

        if(!selectedLines.hasChildNodes()) return;

        if(
            header && header.contains(e.target) ||
            structTasksHeader && structTasksHeader.contains(e.target) ||
            tasksHeader && tasksHeader.contains(e.target) ||
            (
                Tasks && Tasks.contains(e.target) &&
                TasksContainer && !TasksContainer.contains(e.target)
            )
        ){
            setSelectedItems([]);

            if (selectedLines && selectedLines.firstChild) {
                while (selectedLines.firstChild) {
                    selectedLines.removeChild(selectedLines.firstChild);
                }
            }
        }
    }, []);

    const toggleSortable = useCallback((e) => {
        e.stopPropagation();

        if(!accountId) return;

        let cs = [...closedSortables];
        let taskId = $(e.currentTarget).closest('.tasklinesortable').data('task-id');

        if(!_.isString(taskId)) {
            taskId = JSON.stringify(taskId);
        }

        if ($(e.currentTarget).hasClass('closed')) {
            if(cs.includes(taskId)) {
                cs = _.without(cs, taskId);
            }
            firebaseEvents.addChallenge('expandTask');
        } else {
            cs.push(taskId);
            firebaseEvents.addChallenge('collapseTask');
        }

        $(e.currentTarget).toggleClass('closed');

        dispatch(defineClosedSortables(cs, accountId));
    }, [closedSortables, accountId]);

    const showMultiDetails = useCallback((item = null) => {
        $('#multiSelectMenu .actionsDetails > div').css('left', -1000);

        setTimeout(() => {
            if (item) {
                $('#multiSelectMenu .actionsDetails > div#multi' + item + 'Details').css('left', 0);
            }
        }, 200);
    }, []);

    const closeMultiSelectMenu = useCallback(() => {
        setSelectedItems([]);
        $('#multiSelectMenu').css('left', -1000);
    }, []);

    const nSetSelectedLines = useCallback((newSelectedItems = []) => {
        setSelectedItems(newSelectedItems);
    }, []);

    const rangeChangeHandler = useCallback((e) => {
        firebaseEvents.addChallenge('moveTimeline');
        var tRange = localStorage.getItem('timelineRange-' + accountId);
        tRange = JSON.parse(tRange);

        if (
            !tRange ||
            !moment(tRange.start).isSame(e.start, 'day') ||
            !moment(tRange.end).isSame(e.end, 'day')
        ) {
            const val = { start: moment(e.start), end: moment(e.end) };
            localStorage.setItem(
                'timelineRange-' + accountId,
                JSON.stringify(val),
            );
            dispatch(setTimelineRange(val));
        }
    }, [accountId]);

    const timeLineClickHandler = useCallback(() => {
        document.activeElement.blur();
        $('body').trigger('mouseup');
    }, []);

    const updateTasksIndexAndChilds = useCallback((evt) => {
        if (!accountId || !tasks) return;

        const filtersBeingApplied = currentFilters !== '{"andor":"and"}';
        const currMovedTaskId = $(evt.item).children('.tasklinesortable').data('task-id');
        const initParent = $(evt.from).siblings('.tasklinesortable').data('task-id') || limitView || null;
        const destParent = $(evt.to).siblings('.tasklinesortable').data('task-id') || limitView || null;
        const prevSiblingInitParent = $(evt.item).prev().children('.tasklinesortable').data('task-id') || null;

        let newIndex = evt.newIndex;
        let oldIndex = evt.oldIndex;

        if(evt.type === 'update' && newIndex > oldIndex) {
            newIndex--;
        }

        if(evt.type === 'update' && !prevSiblingInitParent) {
            newIndex = 0;
        }

        if(filtersBeingApplied) {
            const childNodesFromDestParent = [...evt.target.childNodes];
            const childTasksFromDestParent = firebaseEvents.getTasksFromParent(destParent, tasks);

            // if size is different, filters applied hide some of the child nodes
            if(_.size(childNodesFromDestParent) !== _.size(childTasksFromDestParent)) {
                const prevSiblingDestParent = $(evt.target.children[newIndex - 1]).children('.tasklinesortable') || null;

                newIndex = (prevSiblingDestParent && prevSiblingDestParent.data('task-index')) ? prevSiblingDestParent.data('task-index') + 1 : newIndex;
            }
        }

        // clean up all the dependencies when moving a task.
        firebaseEvents.cleanUpDependeciesWhenMoving({
            movedTaskId: currMovedTaskId,
            destinationId: $(evt.to).siblings('.tasklinesortable').data('task-id'),
            allTasks: tasks,
        });

        firebaseEvents.reorderTasks({
            type: evt.type, // "update" if in same parent, "add" when being moved to another parent
            taskId: currMovedTaskId, // the task id that is being moved
            initParent, // the initial parent, which can also be the current limitView folder
            destParent, // the destination parent, which can also be the current limitView folder
            oldIndex, // old index in the initial parent
            newIndex, // new index in the destination parent
        });
    }, [accountId, tasks, limitView, currentFilters]);

    const updateSortables = useCallback(() => {
        let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let isMobileWidth = windowWidth <= 620;

        const DOMSortable = $('.sortable');
        if(!DOMSortable || !tasksToShow.length) return;

        DOMSortable.each(function (index, element) {
            const isElSortable = $(element).hasClass('isSortable');
            const isParentElSortableVisible = (
                (!$(element).hasClass('closed') && !$(element).hasClass('empty')) ||
                (!$(element).parent().parent().hasClass('closed') && !$(element).parent().hasClass('isNotVisible'))
            );

            if (isElSortable && isParentElSortableVisible) {
                let hasVisible = false;

                if (isElSortable && $(element).hasClass('invisible')) {
                    $(element).children().each(function() {
                        if (!$(this).hasClass('isNotVisible')) {
                            hasVisible = true;
                        }
                    });
                } else {
                    hasVisible = true;
                }

                if (hasVisible) {
                    Sortable.create(element, {
                        group: 'tasks',
                        filter: 'input',
                        preventOnFilter: false,
                        forceFallback: true,
                        delay:50,
                        disabled: isMobileWidth || tasksToShow.length === 0,
                        onSort: () => {},
                        onMove: evt => {
                            const taskChanged = $(evt.dragged.firstChild).data('taskId');
                            const parentDest = $(evt.to).data('taskId');
                            const parentOrigin = evt.target?.parentNode?.firstChild?.getAttribute('data-task-id');
                            const parentIds = parentChildIds.parents;
                            const isTaskSyncProject = !!tasks[taskChanged]?.sourceId;

                            if (shouldBlockSyncListReorder({ taskChanged, parentDest, parentOrigin, isTaskSyncProject })) {
                                return false;
                            }

                            // check when moving a task under another one if it doesn't
                            // have a dependency with parents. If yes, we remove it

                            return !(
                                parentDest &&
                                tasks[taskChanged]?.dependencies &&
                                (_.uniq(tasks[taskChanged]?.dependencies).indexOf(
                                    parentDest,
                                ) !== -1 ||
                                    (parentIds[parentDest] &&
                                        _.intersection(
                                            _.uniq(tasks[taskChanged]?.dependencies),
                                            parentIds[parentDest],
                                        ).length > 0))
                            );
                        },
                        onStart: (e) => {
                            $('.sortable.isSortable').siblings('.TaskLine').css('opacity', 1);

                            //create a clone
                            const el = e.item;
                            let index = e.oldIndex;
                            if (index < 0) {
                                index = 0;
                            }

                            persistentSortableClone = e.from.insertBefore(
                                el.cloneNode(true),
                                e.from.children[index]
                            );
                            persistentSortableClone.classList.add('sortable-curentDragged');
                            persistentSortableClone.classList.remove('sortable-ghost');
                        },
                        onEnd: (evt) => {
                            setTimeout(() => $(evt.item).css('display', 'block'), 50);

                            if(persistentSortableClone){
                                persistentSortableClone.remove();
                            }
                            persistentSortableClone = null;
                        },
                        onAdd: evt => {
                            $(evt.item).css('display', 'none');
                            if(persistentSortableClone){
                                persistentSortableClone.remove();
                            }
                            persistentSortableClone = null;

                            $(evt.item).appendTo($(evt.from));
                            updateTasksIndexAndChilds(evt);
                        },
                        onUpdate: evt => {
                            if(persistentSortableClone){
                                persistentSortableClone.remove();
                            }
                            persistentSortableClone = null;
                            updateTasksIndexAndChilds(evt);
                        },
                    });
                }
            }
        });
    }, [tasksToShow, tasks, updateTasksIndexAndChilds, parentChildIds]);



    const keyboardShortcuts = useCallback((e) => {
        let interval;

        if (
            $(e.target).closest('.TaskList').length &&
            e.target.className === 'NewTaskTitleInput inputField' &&
            e.target.localName === 'input'
        ) {
            // ENTER key => saves the title and creates a new task
            if (e.keyCode === 13) {
                // no control keys => new task under
                if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
                    firebaseEvents.createTaskUnder($(e.target).closest('.TaskLine').data('task-id')).then(taskId => {
                        interval = setInterval(() => {
                            if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                clearInterval(interval);
                                var i = 0;
                                interval = setInterval(() => {
                                    if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                        clearInterval(interval);
                                        const newTaskLineInput = document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input');
                                        handleFocusOnNewTask(newTaskLineInput, taskId);
                                    } else {
                                        if (i > 100) {
                                            clearInterval(interval);
                                        }
                                        i++;
                                        document
                                            .querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"]')
                                            .click();
                                    }
                                }, 50);
                            }
                        }, 50);
                    });
                }
                // shiftKey pressed => new task above
                else if (!e.ctrlKey && !e.altKey && e.shiftKey) {
                    firebaseEvents.createSubTask($(e.target).closest('.TaskLine').data('task-id')).then(taskId => {
                        interval = setInterval(() => {
                            if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                clearInterval(interval);
                                var i = 0;
                                interval = setInterval(() => {
                                    if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                        clearInterval(interval);
                                        const newTaskLineInput = document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input');
                                        handleFocusOnNewTask(newTaskLineInput, taskId);
                                    } else {
                                        if (i > 100) {
                                            clearInterval(interval);
                                        }
                                        i++;
                                        document
                                            .querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"]')
                                            .click();
                                    }
                                }, 50);
                            }
                        }, 50);
                    });
                }
                // ctrlKey pressed => new sub task
                else if (e.ctrlKey && !e.altKey && !e.shiftKey) {
                    firebaseEvents.createTaskAbove($(e.target).closest('.TaskLine').data('task-id')).then(taskId => {
                        interval = setInterval(() => {
                            if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                clearInterval(interval);
                                var i = 0;
                                interval = setInterval(() => {
                                    if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                        clearInterval(interval);
                                        const newTaskLineInput = document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input');
                                        handleFocusOnNewTask(newTaskLineInput, taskId);
                                    } else {
                                        if (i > 100) {
                                            clearInterval(interval);
                                        }
                                        i++;
                                        document
                                            .querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"]')
                                            .click();
                                    }
                                }, 50);
                            }
                        }, 50);
                    });
                }
            } else if (e.keyCode === 8 && e.ctrlKey && !e.altKey && !e.shiftKey) {
                const taskIdToDelete = [$(e.target).closest('.TaskLine').data('task-id')];
                firebaseEvents.deleteTasks(taskIdToDelete);
                setSelectedItems([]);
            }
        } else if (e.keyCode === 13 && e.ctrlKey) {
            firebaseEvents
                .addTask(accountId, null, '', null, null, userId)
                .then(taskId => {
                    interval = setInterval(() => {
                        if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                            clearInterval(interval);
                            var i = 0;
                            interval = setInterval(() => {
                                if (document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input')) {
                                    clearInterval(interval);
                                    const newTaskLineInput = document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"] > input');
                                    handleFocusOnNewTask(newTaskLineInput, taskId);
                                } else {
                                    if (i > 100) {
                                        clearInterval(interval);
                                    }
                                    i++;
                                    document.querySelector('.NewTaskTitleInput[data-task-id="' + taskId + '"]').click();
                                }
                            }, 50);
                        }
                    }, 50);
                });
        }
    }, [accountId, userId]);

    const handleFocusOnNewTask = useCallback((newTaskLineInput, taskId) => {
        newTaskLineInput.setAttribute('data-newtaskinput-id', taskId);
        newTaskLineInput.focus();
        newTaskLineInput.click();
    }, []);

    const getTimelineItemsAndGroups = useCallback(()=>{
        let daysoffList = [],
            timelineItems = [],
            timelineGroups = [],
            timelineRangeStart = new Date(timelineRange.start),
            timelineRangeEnd = new Date(timelineRange.end);

        if(tasksToShow.length){

            for (var i in daysoff) {
                const dayoff = daysoff[i],
                    startDo = new Date(dayoff.startDate),
                    endDo = new Date(dayoff.endDate);

                if (
                    startDo.getTime() <= timelineRangeEnd.getTime() &&
                    endDo.getTime() >= timelineRangeStart.getTime()
                ) {
                    let sd = new Date(dayoff.startDate);
                    sd.setHours(0);
                    sd.setMinutes(0);
                    sd.setSeconds(0);
                    let ed = new Date(dayoff.endDate);
                    ed.setHours(23);
                    ed.setMinutes(59);
                    ed.setSeconds(59);
                    daysoffList.push({
                        start: sd,
                        end: ed,
                        type: 'background',
                    });
                }
            }

            tasksToShow.forEach((taskId,index)=>{
                if(index >= startEndIndexes.start-15 && index <= startEndIndexes.end+15){
                    timelineGroups.push({ id: taskId, className: 'task id-' + taskId, index: index });
                    daysoffList.forEach(dayoff => {
                        timelineItems.push({ ...dayoff, group: taskId });
                    });
                    timelineItems = timelineItems.concat(
                        getTaskItems(tasks, tasks[taskId], taskId, timelineRangeStart, timelineRangeEnd, users, timezone, intl)
                    );
                }
            });

        }

        return { groups: timelineGroups, items: timelineItems };

    }, [timelineRange, tasksToShow, daysoff, tasks, startEndIndexes, users, timezone]);

    const setTaskHeaderProps = useCallback(()=>{

        if(
            document.getElementById('tasks-header') &&
            document.getElementById('taskListLeft') &&
            document.getElementById('Tasks')
        ){
            document.getElementById('tasks-header').style.top = document.getElementById('Tasks').offsetTop + 'px';
            document.getElementById('tasks-header').style.width = document.getElementById('taskListLeft').clientWidth + 'px';
        }

        setTimeout(setTaskHeaderProps, 10);
    }, []);

    const setTaskListLeftScrollLeft = useCallback(()=>{
        if(
            document.getElementById('taskListLeft') &&
            document.getElementById('tasks-header')
        ){
            document.getElementById('taskListLeft').scrollLeft = document.getElementById('tasks-header').scrollLeft;
        }
    }, []);


    const setTaskHeaderScrollLeft = useCallback(()=>{
        if(
            document.getElementById('taskListLeft') &&
            document.getElementById('tasks-header')
        ){
            document.getElementById('tasks-header').scrollLeft = document.getElementById('taskListLeft').scrollLeft;
        }
    }, []);

    const onResize = () => {
        var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        windowWidth -= document.querySelector('.Header').getBoundingClientRect().width;
        var timelineWidth = $('.TaskList > div.timelineParent').width();
        var percentTimeline = (timelineWidth / windowWidth) * 100;
        var percentList = 100 - percentTimeline;

        $('.TaskList > div:first-child').width(`${percentList}%`);
        $('.TaskList > div.timelineParent').width(`${percentTimeline}%`);

        firebaseEvents.addChallenge('resizeTimeline');

        localStorage.setItem('timelineWidth', JSON.stringify({ timeline: percentTimeline, list: percentList }));
    };

    useEffect(() => {
        tracking('Saw Timeline View');
        setTimeout(setTaskHeaderProps, 10);

        $('#tasks-header').on('scroll', setTaskListLeftScrollLeft);
        $('#taskListLeft').on('scroll', setTaskHeaderScrollLeft);

        $('#Tasks').on('scroll', setSelectedLines);
        $('#Tasks').on('scroll', setOpenTaskBg);
        $('#Tasks').on('scroll', checkOverLines);
        $('#Tasks').on('scroll', getStartEndIndexes);

        interact('.TaskList > div:last-child')
            .resizable({
                edges: { left: true, right: false, bottom: false, top: false },
                modifiers: [
                    // keep the edges inside the parent
                    interact.modifiers.restrictEdges({
                        outer: 'parent',
                        endOnly: true,
                    }),

                    // minimum size
                    interact.modifiers.restrictSize({
                        min: { width: 50 },
                    }),
                ],

                inertia: true,
            })
            .on('resizemove', function (event) {
                var target = event.target;
                var x = parseFloat(target.getAttribute('data-x')) || 0;
                var y = parseFloat(target.getAttribute('data-y')) || 0;

                var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                windowWidth -= document.querySelector('.Header').getBoundingClientRect().width;
                var timelineWidth = parseInt(event.rect.width);
                var percentTimeline = (timelineWidth / windowWidth) * 100;
                var percentList = 100 - percentTimeline;


                // translate when resizing from top or left edges
                x += event.deltaRect.left;
                y += event.deltaRect.top;

                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);

                $('.TaskList > div:first-child').width(`${percentList}%`);
                $('.TaskList > div.timelineParent').width(`${percentTimeline}%`);

                firebaseEvents.addChallenge('resizeTimeline');

                localStorage.setItem('timelineWidth', JSON.stringify({ timeline: percentTimeline, list: percentList }));
            });

        document.addEventListener('click', removeSelectedLines);
        $(document).on('mouseover', checkOverLines);
        $(document).on('keyup', keyboardShortcuts);
        $(document).on('click', checkSelectedItems);
        $(window).on('resize', onResize);
        updateSortables();

        return function cleanup(){

            $('#tasks-header').off('scroll', setTaskListLeftScrollLeft);
            $('#taskListLeft').off('scroll', setTaskHeaderScrollLeft);

            $('#Tasks').off('scroll', setSelectedLines);
            $('#Tasks').off('scroll', setOpenTaskBg);
            $('#Tasks').off('scroll', checkOverLines);
            $('#Tasks').off('scroll', getStartEndIndexes);

            document.removeEventListener('click', removeSelectedLines);
            $(document).off('mouseover', checkOverLines);
            $(document).off('click');
            $(document).off('keyup');
            $(window).off('resize', onResize);
        };

    }, [selectedItems, setTaskHeaderProps]);

    useEffect(()=>{
        // Some popups can show up simultaneously depending the users' actions. Show the first one, and once it's done, the comp updates and we show the next one
        // const popups = document.querySelectorAll('.customMessageToolTip');
        // if(popups && popups[0]) {popups[0].classList.add('show');}

        // if timeline component exists add class on it to be able to hide.
        const vistimeline = document.querySelectorAll('.vis-timeline');
        if(vistimeline.length && !vistimeline[0].parentElement.classList.contains('timeLineParent')){
            vistimeline[0].parentElement.classList.add('timeLineParent');
        }

        updateSortables();

        var timelineHelper = $('#timelineHelper');
        if (timelineHelper) {
            timelineHelper.css('top', $(window).scrollTop() + 160);
        }

        if (localStorage.getItem('timelineWidth')) {
            $('.TaskList > div:first-child').width(JSON.parse(localStorage.getItem('timelineWidth')).list + '%');
            $('.TaskList > div:last-child').width(JSON.parse(localStorage.getItem('timelineWidth')).timeline + '%');
        }

        $('.vis-group').css('marginTop', 0);

        $('.TaskLine .minEffort, .TaskLine .maxEffort').off('focus', checkSelectedItems);
        $('.TaskLine .minEffort, .TaskLine .maxEffort').on('focus', checkSelectedItems);

        setOpenTaskBg();

        return function cleanup(){
            $('.TaskLine .minEffort, .TaskLine .maxEffort').off('focus', checkSelectedItems);
        };
    }, [updateSortables, setOpenTaskBg]);

    useEffect(()=>{
        setSelectedLines();
    }, [selectedItems]);

    useEffect(()=>{
        // debugger;
        if(document.getElementById('Tasks')){
            document.getElementById('Tasks').scrollTo(0,0);
        }
    }, [limitView]);

    useEffect(()=>{
        const topMargin = (startEndIndexes.start-15 > 0)?((startEndIndexes.start-15)*34):0;
        if(document.getElementsByClassName('vis-itemset').length){
            document.getElementsByClassName('vis-itemset')[0].style.marginTop = `${topMargin}px`;
        }
        if(document.querySelectorAll('.timeLineParent').length && document.querySelectorAll('.taskListLeft').length){
            const height = document.querySelectorAll('.taskListLeft')[0].clientHeight;
            document.querySelectorAll('.timeLineParent')[0].style.height = `${height}px`;
        }
        if(document.getElementById('taskListLeft')){
            let fullHeight = window.innerHeight - document.getElementById('taskListLeft').getBoundingClientRect().top;
            if(tasksToShow.length*TASK_LINE_SIZE+100 > fullHeight){ fullHeight = tasksToShow.length*TASK_LINE_SIZE+100; }
            document.getElementById('taskListLeft').style.height = fullHeight + 'px';
        }
    }, [startEndIndexes]);

    const {groups, items} = getTimelineItemsAndGroups();

    return (
        <div className="Tasks" id="Tasks">
            {calculating && <CircularProgress />}
            <div id="lineBg" />
            <div id="selectedLines" />
            <div id="openTaskBg" />
            <div className="TasksContainer">

                {!allDataLoaded && tasksToShow.length === 0 && <CardContent><Loader fullScreen={false} show={true} /></CardContent>}

                {((quickSearch !== '' && tasksToShow.length === 0) || (allDataLoaded && (Object.keys(tasks).length === 0 || tasksToShow.length === 0))) && (<NoTaskFound/>)}

                {tasksToShow.length >= 1 && (
                    <div className={classnames('TaskList', { empty: firstLevelTasks.length === 0 })}>
                        <div className='taskListLeft' id='taskListLeft'>
                            <TaskListHeader taskIdLimitView={limitView} />
                            <div className={classnames('sortable root', { isSortable: (isUserAdmin || baseOwners.includes(userId) || baseManagers.includes(userId)) })} data-sidebar='preventClose'>

                                {firstLevelTasks.length ? firstLevelTasks.map((taskId) => {
                                    if(tasksToShow.includes(taskId)){
                                        return (
                                            <TaskLine
                                                key={taskId}
                                                taskId={taskId}
                                                viewBaseLink='/tasks'
                                                toggleSortable={toggleSortable}
                                                isToggleClosed={closedSortables.includes(taskId)}
                                                startEndIndexes={startEndIndexes}
                                                sidebarOpenDisabled={Boolean(selectedItems.length >= 2)}
                                            />
                                        );
                                    }
                                }) : <NoTaskFound/>}

                            </div>
                        </div>
                        {firstLevelTasks.length &&
                            <Timeline
                                nbTasks={tasksToShow.length}
                                stack={false}
                                timeFormat={timeFormat || 'HH:mm'}
                                start={timelineRange.start}
                                end={timelineRange.end}
                                rangechangedHandler={rangeChangeHandler}
                                clickHandler={timeLineClickHandler}
                                items={items}
                                groups={groups}
                            />
                        }
                    </div>
                )}

                <MultiSelectMenu
                    showMultiDetails={showMultiDetails}
                    closeMultiSelectMenu={closeMultiSelectMenu}
                    setSelectedLines={nSetSelectedLines}
                    selectedItems={(selectedItems.length > 1)?selectedItems:[]}
                />

                {children}
            </div>
            <TimeLineHelpers />
        </div>
    );
};

export default React.memo(withRouter(withCustomErrorBoundary(Tasks)));