import OverViewCard from '../OverViewCard';
import React from 'react';
import classnames from 'clsx';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';

const getExpectedDates = (stateTasks, workspaceSelected, userTimezone) => {
    let maxExpected = moment().tz(userTimezone).subtract(99, 'year');
    let maxEarliest = moment().tz(userTimezone).subtract(99, 'year');
    let maxLastest = moment().tz(userTimezone).subtract(99, 'year');
    let maxDeadline = moment().tz(userTimezone).subtract(99, 'year');
    if(workspaceSelected === 'root'){
        Object.keys(stateTasks).forEach(taskKey => {
            const currentTask = stateTasks[taskKey];
            if(currentTask.estimations && currentTask.estimations.endAt){
                maxLastest = moment.tz(currentTask.estimations.endAt, 'X', userTimezone).isBefore(maxLastest) ? maxLastest : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
                maxEarliest = moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone).isBefore(maxEarliest) ? maxEarliest : moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone);
                maxExpected = moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone).isBefore(maxExpected) ? maxExpected : moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);
                maxDeadline = moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone).isBefore(maxDeadline) ? maxDeadline : moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone);
            }
        });
    } else {
        const currentTask = stateTasks[workspaceSelected];
        if(currentTask.estimations && currentTask.estimations.endAt){
            maxLastest = moment.tz(currentTask.estimations.endAt, 'X', userTimezone).isBefore(maxLastest) ? maxLastest : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
            maxEarliest = moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone).isBefore(maxEarliest) ? maxEarliest : moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone);
            maxExpected = moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone).isBefore(maxExpected) ? maxExpected : moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);
            maxDeadline = moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone).isBefore(maxDeadline) ? maxDeadline : moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone);
        }
    }
    return {
        lastest: maxLastest,
        expected: maxExpected,
        earliest: maxEarliest,
        deadline: maxDeadline
    };
};


const DateDriftOverview = ({ 
    workspaceSelected, 
    stateTasks,
    userTimezone
}) => {
    const expectedDates = React.useMemo(() => getExpectedDates(stateTasks, workspaceSelected, userTimezone), [stateTasks, workspaceSelected, userTimezone]);
    const renderDate = (date) => !moment(date).isValid() || !moment(date).isAfter(moment().tz(userTimezone).subtract(1, 'day')) ? 'N/A' : moment(date).format('MMM D, YY');
    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return <div className={classnames('bigNumbers')}>
        <OverViewCard title={'Expected finish date'} value={renderDate(expectedDates.expected)} />
        <OverViewCard title={'Earliest finish date'} value={renderDate(expectedDates.earliest)} />
        <OverViewCard title={'Latest finish date'} value={renderDate(expectedDates.lastest)} />
        <OverViewCard title={'Due date'} value={workspaceSelected === 'root' ? 'N/A' : renderDate(expectedDates.deadline)} />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="tasks" 
                to={`${path}/date-drift`}
            >
                Go to <b>Date Drift</b> report
            </Button>
        </div>
    </div>;
};

export default DateDriftOverview;