import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { getAccountDataSyncAppUsers } from '../../../../utils/selectors/accountData';
import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core';

import UserLine from './UserLine';
import LineActions from './LineActions';
import { SYNC_APP_NAMES } from '../../utils';
import { getAccountId } from '../../../../utils/selectors/account';
import { refreshUsers } from '../../../../utils/services/syncApp';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const UserMap = ({ toolName, syncAppId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [usersList, setUsersList] = useState({
        matchedUsers: [],
        nonMatchedUsers: [],
    });
    const syncAppUsersData = useSelector(getAccountDataSyncAppUsers(syncAppId));
    const accountId = useSelector(getAccountId);

    const handleRefreshUsers = React.useCallback(async () => {
        try {
            setIsLoading(true);
            await refreshUsers({
                accountId,
                syncAppId,
            });
        } catch (err) {
            toastr.error('There was an issue, try again or contact planless');
        } finally {
            setIsLoading(false);
        }
    }, [accountId, syncAppId]);

    useEffect(() => {
        if (syncAppUsersData) {
            const matchedUsers = _.filter(syncAppUsersData, user => user.planlessId);
            const nonMatchedUsers = _.filter(syncAppUsersData, user => !user.planlessId);

            setUsersList({ matchedUsers, nonMatchedUsers });
        }
    }, [syncAppUsersData]);

    if (!syncAppId) return null;

    return (
        <div className="UserMap">
            <div className="UserMap__header">
                <Typography variant="subtitle1">{SYNC_APP_NAMES[toolName]}</Typography>
                <Typography variant="subtitle1">Planless</Typography>
            </div>
            {isLoading && <CircularProgress />}

            {!isLoading &&
                _.map(usersList, (usersListByType, type) => {
                    return <UsersList usersListByType={usersListByType} type={type} syncAppId={syncAppId} key={type} />;
                })}
            {!isLoading && (
                <Button className="refreshUsersBtn" onClick={handleRefreshUsers}>
                    Refresh Users List
                </Button>
            )}
        </div>
    );
};

export default withCustomErrorBoundary(UserMap);

const UsersList = ({ usersListByType = [], syncAppId, type = 'nonMatchedUsers' }) => {
    const intl = useIntl();
    const [openArrow, setOpenArrow] = useState(true);

    const handleClick = () => setOpenArrow(!openArrow);

    const getNonMatchedListToggler = () => {
        if (type === 'nonMatchedUsers') {
            return (
                <div className={'notMatchedTitle'}>
                    <IconButton onClick={handleClick}>
                        {openArrow ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                    <Typography variant={'subtitle1'}>{intl.formatMessage({ id: 'Not matched users' })}</Typography>
                </div>
            );
        }
    };

    if (_.isEmpty(usersListByType)) {
        return (
            <div className="UserMap__users">
                {getNonMatchedListToggler()}

                {!(type === 'nonMatchedUsers' && !openArrow) ? (
                    <div className={'UserMap__emptyState'}>
                        <img src={`https://planless.sirv.com/App/emptyStates/${type}.png`} />
                        <Typography variant={'h6'}>
                            {intl.formatMessage({ id: `${type}.empty.state.title` })}
                        </Typography>
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <div>
            {getNonMatchedListToggler()}
            <div className='UserMap__container'>
                <div className="UserMap__users">
                    {usersListByType &&
                        Object.values(usersListByType).map(
                            ({ avatarUrl, color, sourceDeleted, displayName, sourceId, email, planlessId }) => {
                                if (type === 'nonMatchedUsers' && !openArrow) return null;
                                return <React.Fragment key={`userpack-${sourceId}`}>
                                    <UserLine
                                        key={`userline-${sourceId}`}
                                        sourceColor={color}
                                        sourceAvatar={avatarUrl}
                                        username={displayName}
                                        sourceDeleted={sourceDeleted}
                                        sourceId={sourceId}
                                        planlessId={planlessId}
                                        email={email}
                                        syncAppId={syncAppId}
                                        type={type}
                                    />
                                    <LineActions
                                        key={`actions-${sourceId}`}
                                        sourceColor={color}
                                        sourceAvatar={avatarUrl}
                                        username={displayName}
                                        sourceDeleted={sourceDeleted}
                                        sourceId={sourceId}
                                        planlessId={planlessId}
                                        email={email}
                                        syncAppId={syncAppId}
                                        type={type}
                                        matched={type === 'matchedUsers'}
                                    />
                                </React.Fragment>;
                            },
                        )}
                </div>
            </div>
        </div>
    );
};
