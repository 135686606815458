import React, { memo } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { getAccountTasks } from '../../../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ComingFromSection = ({
    comingFrom,
    taskId,
    revertPermissions,
    editActive,
    cancelChange,
    setEditActive,
    deletePermissions,
    taskParent
}) => {
    const intl = useIntl();
    const tasks = useSelector(getAccountTasks);

    let linkModify = null;
    let comingFromDisclaimer = null;

    const handleCancelChange = () => cancelChange();

    const handleRedirect = (e, path) => {
        browserHistory.push(path);
    };

    const handleSetEditActive = () => setEditActive();

    const handleDeletePermissions = () => deletePermissions();

    if (comingFrom === taskId) {
        linkModify = (
            <Button
                key="permDeleteLink"
                variant="outlined"
                color="secondary"
                onClick={handleDeletePermissions}
            >
                {taskParent ? intl.formatMessage({ id: 'permissions.delete.link' }) : intl.formatMessage({ id: 'permissions.revert.base.link' })}
            </Button>
        );
        if (revertPermissions) {
            linkModify = (
                <Button key="permCancelLink" variant="outlined" onClick={handleCancelChange}>
                    {intl.formatMessage({ id: 'permissions.delete.cancel.link' })}
                </Button>
            );
        }

        comingFromDisclaimer = intl.formatMessage({ id: 'permissions.comingFrom.self' }, {
            linkModify,
        });
    } else {
        linkModify = (
            <Button
                key="permChangeLink"
                variant="outlined"
                color="primary"
                onClick={handleSetEditActive}
            >
                {intl.formatMessage({ id: 'permissions.change.link' })}
            </Button>
        );
        if (editActive) {
            linkModify = (
                <Button key="permCancelLink" variant="outlined" onClick={cancelChange}>
                    {intl.formatMessage({ id: 'permissions.change.cancel.link' })}
                </Button>
            );
        }

        if (comingFrom === 'base' || !comingFrom) {
            comingFromDisclaimer = (
                <>
                    {intl.formatMessage({ id: 'permissions.comingFrom.base' })}
                    <button
                        type="button"
                        onClick={(e) => handleRedirect(e, '/settings/permissions')}
                    >
                        {intl.formatMessage({ id: 'base permissions' })}
                    </button>
                    {linkModify}
                </>
            );
        } else {
            comingFromDisclaimer = intl.formatMessage({ id: 'permissions.comingFrom.other' }, {
                comingFrom: (
                    <button
                        type="button"
                        key="permissionComingFromTaskLink"
                        className="permissionComingFromTaskLink"
                        onClick={(e) => handleRedirect(e, `/tasks/${comingFrom}`)}
                    >
            "{tasks && tasks[comingFrom]?.title}"
                    </button>
                ),
                linkModify,
            });
        }
    }

    return (<p>{comingFromDisclaimer}</p>);
};

export default memo(withRouter(withCustomErrorBoundary(ComingFromSection)));
