import React, { memo } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import LineItem from './LineItem';
import { childrenMatchSearchValue, isAlreadySynchronized, matchesSearchValue, parentsMatchSearchValue } from '../../utils';

const ProjectsTreeViewFull = ({
    orderedProjectList = [], 
    currentList = [], 
    parentIds = [], 
    handleCheckItem, 
    handleCloseParent, 
    handleToggleImportSubtasks, 
    checkedItems = [], 
    step = 1, 
    parent = null, 
    searchValue = '', 
    syncList = [],
    syncAppId,
    prevSyncList = [],
}) => {

    return <>
        {currentList.map((item, index) => {
            const {id, name, type, childs, parentId, nestedChildrenIds, parentsBefore} = item;
            
            const childIds = (childs?.map(el=>el.id) || []);
            const isImportingSubtasks = syncList.find(el=>el.selectedItem===id)?.importSubtasks || false;
            const isSearching = searchValue.trim() !== '';
            const nestedChildrenNames = _.uniq(nestedChildrenIds.map(childId=>(orderedProjectList.find(el=>el.id===childId)?.name)));
            const parentsBeforeNames = parentsBefore.map(parentId=>orderedProjectList.find(el=>el.id===parentId)?.name); 

            const hasNoSubItemChecked = !checkedItems.includes(id) && !nestedChildrenIds.some(id=>checkedItems.includes(id));
            const alreadySynchronized = isAlreadySynchronized({id, childIds, prevSyncList, orderedProjectList, nestedChildrenIds});

            const isLineHidden = (step === 2 && (hasNoSubItemChecked || alreadySynchronized)) || (isSearching && !(
                matchesSearchValue(name, searchValue) || 
                childrenMatchSearchValue(nestedChildrenNames, searchValue) || 
                parentsMatchSearchValue(parentsBeforeNames, searchValue)
            ));

            return (
                <div key={`${id}-${index}`} className='ProjectsTreeView'>     
                    {isLineHidden ? null : (
                        <LineItem
                            syncAppId={syncAppId}
                            itemData={item}
                            parent={parent}
                            itemId={id}
                            handleCheckItem={handleCheckItem}
                            name={name}
                            parentIds={_.uniq([...parentIds, parentId])}
                            checkedItems={checkedItems}
                            handleCloseParent={handleCloseParent}
                            handleToggleImportSubtasks={handleToggleImportSubtasks}
                            type={type}
                            orderedProjectList={orderedProjectList}
                            childIds={childIds}
                            key={id}
                            step={step}
                            nestedChildrenIds={nestedChildrenIds}
                            isImportingSubtasks={isImportingSubtasks}
                            syncList={syncList}
                        />
                    )}

                    {!_.isEmpty(childs) && <ProjectsTreeViewFull 
                        syncAppId={syncAppId}
                        currentList={childs}
                        parent={{...item, childIds: childIds}}
                        parentIds={_.uniq([...parentIds, parentId, id])} 
                        handleCheckItem={handleCheckItem} 
                        handleToggleImportSubtasks={handleToggleImportSubtasks}
                        checkedItems={checkedItems}
                        handleCloseParent={handleCloseParent}
                        orderedProjectList={orderedProjectList}
                        type={type}
                        step={step}
                        searchValue={searchValue}
                        syncList={syncList}
                        prevSyncList={prevSyncList}
                    />}
                </div>
            );
        })}
    </>;
};

ProjectsTreeViewFull.Proptypes = {
    currentList: PropTypes.array.isRequired,
    parentIds: PropTypes.array.isRequired,
    handleSelecItem: PropTypes.func.isRequired,
    handleCloseParent: PropTypes.func,
    step: PropTypes.number.isRequired,
    checkedItems: PropTypes.array.isRequired,
    handleToggleImportSubtasks: PropTypes.func,
    parent: PropTypes.object,
    searchValue: PropTypes.string.isRequired,
    prevSyncList: PropTypes.array, 
    syncList: PropTypes.array,
    syncAppId: PropTypes.string,
};

export default memo(ProjectsTreeViewFull);