import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import { CLOUD_FUNCTIONS } from '../../utils/constants';
import { getAccountChargebeeCustomerId, getAccountIsTrial, getAccountPlanPeriodicity } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
import PricingModal from '../PricingModal';


const SubscribeSection = () => {
    const { t } = useInternalization();
    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const isTrial = useSelector(getAccountIsTrial);
    const planPeriodicity = useSelector(getAccountPlanPeriodicity);
    const [showSubscribe, setShowSubscribe] = useState(false);

    useEffect(()=> {
        if (window.Chargebee && chargebeeCustomerId) {
            var chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.setPortalSession(function () {
                return $.ajax({
                    url: `${CLOUD_FUNCTIONS.chargebeePortalSession}?customerId=${chargebeeCustomerId}`,
                    method: 'GET',
                });
            });
        }
    }, [chargebeeCustomerId, window.Chargebee]);

    const openPortal = () => {
        if (window.Chargebee) {
            var chargebeeInstance = window.Chargebee.getInstance();

            let cbPortal = chargebeeInstance.createChargebeePortal();
            cbPortal.open({
                close() {
                    //close callbacks
                },
            });
        }
    };

    return (
        <>
            <Button 
                className="manageSubBtn" 
                variant="contained" 
                size="large" 
                color="secondary" 
                onClick={isTrial || !planPeriodicity ? ()=>setShowSubscribe(true) : openPortal}>
                {isTrial || !planPeriodicity ? t('Subscribe'):t('Manage subscription')}
            </Button>
            {showSubscribe && <PricingModal isOpen={true} onClose={()=>setShowSubscribe(false)}/>}
        </>
    );
};

export default withCustomErrorBoundary(SubscribeSection);