import React  from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAccountTags } from '../../utils/selectors/account';
import { Chip } from '@material-ui/core';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import Tooltip from 'rc-tooltip';
import { SYNC_APP_NAMES } from '../Integrations/utils';

const TaskDetailSourceTags = ({ task, taskTags, syncAppType }) => {
    const intl = useIntl();
    const accountTags = useSelector(getAccountTags);

    return (
        <li>
            <dl>
                <dt>{intl.formatMessage({id:'Orignal Tags'})}</dt>
                <dt>
                    <div className="listTags souceTags">
                        <Tooltip placement={'top'} overlay={intl.formatMessage({id: 'select.source.tags'}, {toolName: SYNC_APP_NAMES[syncAppType]})}>
                            <div>
                                {
                                    taskTags.map((id, idx) => <Chip
                                        key={`${idx}-${accountTags[id].title}`}
                                        label={accountTags[id].title}
                                        className={`color${accountTags[id].color}`}
                                    />)
                                }
                            </div>
                        </Tooltip>
                        <SyncProjectLogo toolName={syncAppType} tooltipPlacement={'bottom'} fromViewMode={'taskSidebarHeader'} task={task}/>
                    </div>
                </dt>
            </dl>
        </li>
    );
};

TaskDetailSourceTags.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailSourceTags;
