import moment from 'moment';
import { MEMBER_TYPES } from '../memberTypes';
import { db, segmentTrack, getRandomCode } from '../firebaseEvents';

export const createAccount = async ({
    email, 
    displayName,
    accountName,
    chargebeeCustomerId,
    nbUsers,
    plan,
    planEndAt,
    trialTime
})  => {
    const newAccountKey = db.pushkey('/accounts');
    const code = getRandomCode();
    const queryData = (await db.equalSimply('/users', 'email', email)).val();
    // it means there is already exist the user
    const userId = queryData ? Object.keys(queryData)[0] : db.pushkey('/users');
    const userData = {
        [userId]: {
            // we don't know 
            ...(queryData && {uid: queryData[userId].uid}),
            userId: userId,
            accountId: newAccountKey,
            color: '#7b68ee',
            displayName,
            email,
            isAdmin: true,
            emailNotifications: true,
            browserNotifications: false,
            memberType: MEMBER_TYPES.userAndResource,
            permissions: {
                admin: true,
                team: false,
                skills: false,
                daysoff: false,
                boards: false,
                subscription: false,
            },
            schedule: [
                { d: '1', day: 'Mon', start: '09:00', end: '12:00', nbMin: 180 },
                { d: '1', day: 'Mon', start: '13:00', end: '18:00', nbMin: 300 },
                { d: '2', day: 'Tue', start: '09:00', end: '12:00', nbMin: 180 },
                { d: '2', day: 'Tue', start: '13:00', end: '18:00', nbMin: 300 },
                { d: '3', day: 'Wed', start: '09:00', end: '12:00', nbMin: 180 },
                { d: '3', day: 'Wed', start: '13:00', end: '18:00', nbMin: 300 },
                { d: '4', day: 'Thu', start: '09:00', end: '12:00', nbMin: 180 },
                { d: '4', day: 'Thu', start: '13:00', end: '18:00', nbMin: 300 },
                { d: '5', day: 'Fri', start: '09:00', end: '12:00', nbMin: 180 },
                { d: '5', day: 'Fri', start: '13:00', end: '18:00', nbMin: 300 },
            ],
            timezone: moment.tz.guess() || 'Europe/London',
        }
    };
    const accountData = {
        id: newAccountKey,
        settings: {
            chargebeeCustomerId,
            id: newAccountKey,
            accountId: newAccountKey,
            name: accountName,
            code: code,
            planNbusers: nbUsers,
            createdAt: moment().format(),
            // to defined
            endPlanAt: moment(planEndAt).format(),
            // to define
            plan,
            isTrial: !!trialTime,
            isLifetime: false,
            boards: {
                inprogress: {
                    name: 'In progress',
                    index: 0,
                },
            },
            basePermissions: {
                workers: [userId],
            },
        },
        chat: {
            channels: {
                general: {
                    createdAt: moment().format(),
                    createdBy: userId,
                    description:
                            'This is a general channel for all team members. Change this description to welcome your team members.',
                    participants: [userId],
                    perm: {
                        owners: [userId],
                    },
                },
            },
        },
        users: userData,
    };
        // create account
    await db.set(`/accounts/${newAccountKey}`, accountData);
    // only do this if there is an user accoun 
    if(queryData){
        await db.set(`/users/${userId}/accounts`, [...queryData[userId].accounts, newAccountKey]);
        await db.set(`/users/${userId}/activeAccount`, newAccountKey);
    }
    else {
        db.set(`/users/${userId}`, { accounts: [newAccountKey]});
    }
    segmentTrack('Created workspace from quote', { accountId: newAccountKey });
  
};