import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';

import Unautorized from '../Unautorized/Unautorized';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Divider } from '@material-ui/core';
import { getUserId, getUserPermissionsAdmin } from '../../utils/selectors/user';
import { getAccountBasePermissions, getAccountId, getAccountTaskCanAdmin, getAccountTaskCanManage, getAccountTaskChildrens, getAccountTaskParent } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const DuplicateTask = ({router}) => {
    const intl = useIntl();
    const taskId = router.params.uid;

    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const isUserAdmin = useSelector(getUserPermissionsAdmin);
    const basePermissions = useSelector(getAccountBasePermissions);
    const taskParent = useSelector(getAccountTaskParent(taskId));
    const taskChildrens = useSelector(getAccountTaskChildrens(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));
    const canAdmin = useSelector(getAccountTaskCanAdmin(taskId));

    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState(true);
    const [skill, setSkill] = useState(true);
    const [effort, setEffort] = useState(true);
    const [due, setDue] = useState(true);
    const [delay, setDelay] = useState(true);
    const [dependencies, setDependencies] = useState(true);
    const [forced, setForced] = useState(true);
    const [files, setFiles] = useState(false);
    const [comments, setComments] = useState(false);
    const [followers, setFollowers] = useState(false);
    const [childrens, setChildrens] = useState(true);


    const close = () => {
        var pathIndex = 3;
        if(router.routes[pathIndex].path === ':uid'){
            pathIndex = 2;
        }

        var path = '/' + router.routes[pathIndex].path;

        if(router.routes[pathIndex+2] && router.routes[pathIndex+2].path === 'duplicate'){
            path += '/' + taskId;
        }

        browserHistory.push(path);

    };

    const save = async () => {
        if (!accountId) return;

        await firebaseEvents.duplicateTask(
            accountId,
            taskId,
            {
                status,
                description,
                skill,
                effort,
                due,
                delay,
                dependencies,
                forced,
                files,
                comments,
                followers,
                childrens
            }
        );

        close();
    };

    const getChildrensCheckbox = () => {
        if(taskChildrens){
            return <FormControlLabel
                control={
                    <Switch
                        size='small'
                        checked={childrens}
                        onChange={()=>setChildrens(!childrens)}
                        color="secondary"
                    />
                }
                label={intl.formatMessage({id:'duplicate.tasks.childrens'})}
            />;
        }

        return null;
    };

    const getCheckbox = (state, setState, label) => {
        return (
            <FormControlLabel
                control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} checked={state} onChange={()=>setState(!state)} color="primary" />}
                label={intl.formatMessage({id:label})}
            />
        );
    };

    if(canManage){
        if(
            (taskParent && canAdmin) ||
            (taskParent && canManage) ||
            (
                !taskParent &&
                (
                    (isUserAdmin) ||
                    (basePermissions && basePermissions.owners && basePermissions.owners.indexOf(userId) !== -1) ||
                    (basePermissions && basePermissions.owners && basePermissions.owners.indexOf(userId) !== -1) ||
                    (basePermissions && basePermissions.managers && basePermissions.managers.indexOf(userId) !== -1)
                )
            )
        ){
            return (
                <Dialog
                    className='DuplicateTask'
                    open={true}
                    onClose={close}
                    maxWidth={false}
                    onKeyUp={(e) => e.key === 'Enter' || e.keyCode === 13 ? save() : null}
                    data-sidebar='preventClose'
                >
                    <DialogTitle>{intl.formatMessage({id:'Duplicate a task'})}</DialogTitle>
                    <Divider/>
                    <DialogContent className="content">

                        <p>{intl.formatMessage({id:'duplicate.text.desc'})}</p>

                        <div className="flex">

                            <div>
                                {getCheckbox(status, setStatus, 'Status')}
                                {getCheckbox(description, setDescription, 'Description')}
                                {getCheckbox(skill, setSkill, 'Skill')}
                                {getCheckbox(effort, setEffort, 'Effort')}
                                {getCheckbox(due, setDue, 'Due date')}
                                {getCheckbox(delay, setDelay, 'Wait until')}
                            </div>
                            <div>
                                {getCheckbox(dependencies, setDependencies, 'Dependencies')}
                                {getCheckbox(forced, setForced, 'Forced team member')}
                                {getCheckbox(files, setFiles, 'Files')}
                                {getCheckbox(comments, setComments, 'Comments')}
                                {getCheckbox(followers, setFollowers, 'Followers')}
                            </div>

                        </div>

                        {getChildrensCheckbox()}

                    </DialogContent>

                    <DialogActions className="actions">
                        <Button variant="contained" onClick={close} >{intl.formatMessage({id:'Close'})}</Button>
                        <Button variant="contained" onClick={save} color="primary" >{intl.formatMessage({id:'Duplicate'})}</Button>
                    </DialogActions>

                </Dialog>
            );
        }
    }

    return (
        <Dialog
            className='DuplicateTask'
            open={true}
            onClose={close}
            maxWidth={false}
        >
            <DialogTitle>{intl.formatMessage({id:'Duplicate a task'})}</DialogTitle>
            <Divider/>
            <DialogContent className="content">
                <Unautorized />
            </DialogContent>

            <DialogActions className="actions">
                <Button variant="contained" onClick={close} >{intl.formatMessage({id:'Cancel'})}</Button>
            </DialogActions>

        </Dialog>
    );
};

export default withRouter(withCustomErrorBoundary(DuplicateTask));