import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {browserHistory} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { addTask, segmentTrack } from '../../utils/firebaseEvents';
import useInternalization from '../../utils/hooks/useInternalization';
import { getAccountId } from '../../utils/selectors/account';
import { defineClosedSortables, setLimitViewTo } from '../../views/App/AppActions';
import { getUserId } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const CreateNewFolder = ({parent = null, onClose, fromSideBar = false}) =>{
    const dispatch = useDispatch();
    const { t } = useInternalization();
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);

    const [name, setName] = useState('');

    const save = ()=>{
        if(name === ''){
            toastr.error(t('Please insert a name'));
        }
        else {
            // create folder
            // addTask(accountId, parentId, title, key, content, userId)
            addTask(accountId, parent, name, null, null, userId).then(key=>{
                addTask(accountId, key, t('Untitled'), null, null, userId).then(()=>{
                    if(fromSideBar){
                        dispatch(setLimitViewTo({accountId, value: key}));
                        dispatch(defineClosedSortables([], accountId));
                        browserHistory.push('/tasks');
                    }

                    segmentTrack('Add project', {accountId, userId, folderName: name, folderId: key, parent});
                    onClose();
                });
            });
        }
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.keyCode === 13) {
            setTimeout(()=>{
                save();
            }, 50);
            
            return;
        }
    };

    return (
        <Dialog onClose={onClose} open={true} maxWidth="xs" fullWidth className='CreateNewFolder'>
            <DialogTitle>
                {t('Create a folder')}
            </DialogTitle>
            <DialogContent>
                <TextField onKeyDown={handleKeyPress} autoFocus label={t('Name')} fullWidth value={name} onChange={(e)=>setName(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('Cancel')}</Button>
                <Button variant="contained" color='primary' onClick={save}>{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    );
};
export default withCustomErrorBoundary(CreateNewFolder);