import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationIcon from '@material-ui/icons/Notifications';
import { Tooltip } from '@material-ui/core';
import { getBellListenerNotifications, offBellNotificationsListener } from '../../utils/databaseQuerys/notifications';
import { getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { segmentTrack } from '../../utils/firebaseEvents';

const NotificationsBadge = ({showTooltip}) => {
    const [currentNotifications, setCurrentNotifications] = useState(0);
    const [pageActive, setPageActive] = useState(true);
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);
    const intl = useIntl();

    useEffect(() => {
        window.addEventListener('blur', () => setPageActive(false));
        window.addEventListener('focus', () => setPageActive(true));
    }, []);

    const handleAnimateNotificationsBell = useCallback(() => {
        try {
            document.querySelector('.NotificationIcon')?.classList.add('bell');
            document.querySelector('.Notifications')?.classList.add('zoomBell');
            setTimeout(() => {
                document.querySelector('.NotificationIcon')?.classList.remove('bell');
                document.querySelector('.Notifications')?.classList.remove('zoomBell');
            }, 5000);
        } catch (error) {
            return null;
        }
    }, []);

    useEffect(() => {
        if (currentAccountId && currentUserId) {
            getBellListenerNotifications({ accountId: currentAccountId, userId: currentUserId }, data => {
                setCurrentNotifications(data.val()|0);

                if(pageActive && data.val() > 0) handleAnimateNotificationsBell();
            });
        }

        return () => {
            if(currentAccountId && currentUserId){
                offBellNotificationsListener({
                    accountId: currentAccountId,
                    userId: currentUserId,
                });
            }
        };
    }, [currentAccountId, currentUserId, handleAnimateNotificationsBell]);

    const handleTrackClick = () => {
        if(currentUserId) {
            segmentTrack('clickedNotificationsPageIcon', {userId: currentUserId});
        }
    };

    return (
        <Link to="/notifications">
            <Badge
                overlap="rectangular"
                className="Notifications"
                badgeContent={currentNotifications || null}
                color="primary"
                onClick={handleTrackClick}
            >
                {!!showTooltip && <Tooltip placement='right' title={intl.formatMessage({id: 'Notifications'})}>
                    <IconButton>
                        <NotificationIcon className="NotificationIcon" />
                    </IconButton>
                </Tooltip>}
                {!showTooltip && <IconButton>
                    <NotificationIcon className="NotificationIcon" />
                </IconButton>}

            </Badge>
        </Link>
    );
};

export default memo(NotificationsBadge);
