import React from 'react';
import Button from '@material-ui/core/Button';
import { UPVOTY_LINK } from '../../utils/constants';
import { useIntl } from 'react-intl';

const StepsFeedback = () => {
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    return (
        <div className="StepsFeedback">
            <h1>{t('We listen to you')}</h1>
            <div>
                <p>
                    {t('Want to know what we are building next? Want to propose an improvement? Report an issue?')}<br/><br/>
                    {t('We are constantly listening to our users to make Planless better day after day.')}
                </p>
                <Button variant="contained" onClick={() => window.open(UPVOTY_LINK)}>
                    {t('GIVE FEEDBACK')}
                </Button>
            </div>
        </div>
    );
};

export default StepsFeedback;
