import React, { useEffect, useState } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'underscore';
import $ from 'jquery';
import Chip from '@material-ui/core/Chip';
import SearchIcon from '@material-ui/icons/Search';
import { getUserAvatar } from '../../utils/utils';
import { getOnlyUsers } from '../../utils/memberTypes';
import classnames from 'clsx';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { isAdmin } from '../../utils/userFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { toastr } from 'react-redux-toastr';
import { getAccountChat, getAccountUsers } from '../../utils/selectors/account';
import { getUserId, getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { UserList } from './components/UserList';

const Chat = ({children, router})=>{
    const intl = useIntl();
    const userPermissions = useSelector(getUserPermissions);
    const userId = useSelector(getUserId);
    const chatData = useSelector(getAccountChat);
    const accountUsers = useSelector(getAccountUsers);
    const users = getOnlyUsers(accountUsers);

    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [listGroupParticipants, setListGroupParticipants] = useState([]);
    const [showCreateChannel, setShowCreateChannel] = useState(false);
    const [listChannelParticipants, setListChannelParticipants] = useState([]);
    const [nameChannelText, setNameChannelText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showMobileChannels, setShowMobileChannels] = useState(false);

    useEffect(()=>{
        $('.Header .howToBtn').css('bottom', -200);
        $('.Header .bookDemoBtn').css('bottom', -200);

        if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 767) {
            const firstStepsHeader = document.querySelectorAll('.FirstStepsHeader');
            if(firstStepsHeader.length){
                firstStepsHeader[0].style.opacity = 0;
            }
        }

        return function cleanup(){
            $('.Header .howToBtn').css('bottom', 30);
            $('.Header .bookDemoBtn').css('bottom', 30);

            const firstStepsHeader = document.querySelectorAll('.FirstStepsHeader');
            if(firstStepsHeader.length){
                firstStepsHeader[0].style.opacity = 1;
            }
        };
    }, []);

    const hideMobileMenu = ()=> {
        setShowMobileChannels(false);
    };

    const getChannels = () => {
        var results = [];

        if (chatData && chatData.channels) {
            for (var channelKey in chatData.channels) {
                chatData.channels[channelKey].key = channelKey;
            }

            var channels = _.sortBy(chatData.channels, 'key');

            channels.forEach(channel => {
                if (channel.participants && channel.participants.indexOf(userId) !== -1) {
                    var nbUnread = getNbUnread(channel);
                    var chip = null;

                    if (nbUnread !== 0) {
                        if (nbUnread > 20) {
                            nbUnread = '20+';
                        }
                        chip = <Chip size="small" label={nbUnread} color="secondary" />;
                    }

                    results.push(
                        <li
                            key={'channel-' + channel.key}
                            onClick={() => {
                                hideMobileMenu();
                                browserHistory.push('/chat/channels/' + channel.key);
                            }}
                            className={classnames({
                                active: router.params.type === 'channels' && router.params.uid === channel.key,
                            })}
                        >
                            <span># &nbsp;{channel.key}</span>
                            {chip}
                        </li>,
                    );
                }
            });
        }

        if (results.length) {
            return <ul>{results}</ul>;
        } else {
            return <span className="noChannel">No channel found</span>;
        }
    };

    const getGroupParticipants = (groupId) => {
        if (chatData && chatData.groups && chatData.groups[groupId]) {
            var participants = chatData.groups[groupId].participants;
            var res = [];

            participants.forEach(p => {
                if(users[p]){
                    res.push(getUserAvatar(p, false, users[p]?.avatar));
                }
            });

            return <AvatarGroup max={4}>{res}</AvatarGroup>;
        }
        return null;
    };

    const getGroups = () => {
        var results = [];

        if (chatData && chatData.groups) {
            for (var channelKey in chatData.groups) {
                chatData.groups[channelKey].key = channelKey;
            }

            var channels = _.sortBy(chatData.groups, 'key');

            channels.forEach(channel => {
                if (channel.participants && channel.participants.indexOf(userId) !== -1) {
                    var nbUnread = getNbUnread(channel);
                    var chip = null;

                    if (nbUnread !== 0) {
                        if (nbUnread > 20) {
                            nbUnread = '20+';
                        }
                        chip = <Chip size="small" label={nbUnread} color="secondary" />;
                    }

                    results.push(
                        <li
                            key={'channel-' + channel.key}
                            onClick={() => {
                                hideMobileMenu();
                                browserHistory.push('/chat/groups/' + channel.key);
                            }}
                            className={classnames({
                                active: router.params.type === 'groups' && router.params.uid === channel.key,
                            })}
                        >
                            <span>{getGroupParticipants(channel.key)}</span>
                            {chip}
                        </li>,
                    );
                }
            });
        }

        if (results.length) {
            return <ul>{results}</ul>;
        } else {
            return <span className="noChannel">No groups found</span>;
        }
    };

    const getNbUnread = (chat) => {
        let count = 0;

        for (const key in chat.messages) {
            count += chat.messages[key].seenBy && !chat.messages[key].seenBy.includes(userId) ? 1 : 0;

            if (count > 20) {
                break;
            }
        }

        return count;
    };

    const getCreateGroup = () => {
        var options = [];

        var tempOptions = [];
        _.each(users, (u, k) => {
            if (!u.viewer && k && k !== userId) {
                tempOptions.push({ id: k, name: u.displayName });
            }
        });
        tempOptions = _.sortBy(tempOptions, 'name');

        _.each(tempOptions, u => {
            options.push(u.id);
        });

        return (
            <Dialog
                className="ChatCreateGroup"
                maxWidth="sm"
                fullWidth
                open={showCreateGroup}
                onClose={() => {
                    setShowCreateGroup(false);
                    setListGroupParticipants([]);
                }}
            >
                <DialogTitle>Create new group chat</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        size="small"
                        className="autocomplete createGroupParticipants"
                        multiple
                        filterSelectedOptions={true}
                        options={options}
                        value={listGroupParticipants}
                        getOptionLabel={option => (
                            <div className="optionUser" key={option}>
                                {getUserAvatar(option, false, users[option].avatar)}
                                {users[option] ? users[option].displayName : ''}
                            </div>
                        )}
                        renderInput={params => (
                            <TextField
                                autoFocus
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({id:'Participants to add'})}
                                fullWidth
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                if (users[option]) {
                                    var props = getTagProps({ index });
                                    return (
                                        <Chip
                                            avatar={getUserAvatar(option, false, users[option].avatar)}
                                            variant="outlined"
                                            label={users[option] ? users[option].displayName : ''}
                                            {...props}
                                        />
                                    );
                                }
                                return null;
                            })
                        }
                        filterOptions={(options, { inputValue }) => {
                            var results = [];
                            options.forEach(o => {
                                if (
                                    users[o] &&
                                    users[o].displayName.search(
                                        new RegExp(encodeURIComponent(inputValue), 'gi'),
                                    ) !== -1
                                ) {
                                    results.push(o);
                                }
                            });
                            return results;
                        }}
                        onChange={(e, values) => {
                            setListGroupParticipants(values);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowCreateGroup(false);
                            setListGroupParticipants([]);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={createGroupChat} color="primary">
                        Create group chat
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const createGroupChat = () => {
        firebaseEvents.createGroupChat(listGroupParticipants).then(newKey => {
            let interval = setInterval(() => {
                if (chatData.groups && chatData.groups[newKey]) {
                    clearInterval(interval);
                    browserHistory.push('/chat/groups/' + newKey);
                }
            }, 100);
        });
        setShowCreateGroup(false);
        setListGroupParticipants([]);
    };

    const getCreateChannel = () => {
        if (isAdmin({
            user: {
                data: {
                    permissions: userPermissions,
                },
            },
        })) {
            var options = [];

            var tempOptions = [];
            _.each(users, (u, k) => {
                if (!u.viewer && k && k !== userId) {
                    tempOptions.push({ id: k, name: u.displayName });
                }
            });
            tempOptions = _.sortBy(tempOptions, 'name');

            _.each(tempOptions, u => {
                options.push(u.id);
            });

            return (
                <Dialog
                    className="ChatCreateChannel"
                    maxWidth="sm"
                    fullWidth
                    open={showCreateChannel}
                    onClose={() => {
                        setShowCreateChannel(false);
                        setListChannelParticipants([]);
                        setNameChannelText('');
                    }}
                >
                    <DialogTitle>Create new channel</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            label="Channel name"
                            variant="outlined"
                            className="channelNameInput"
                            size="small"
                            fullWidth
                            inputProps={{
                                pattern: '[A-Za-z0-9-_]{3,20}',
                                maxLength: 35,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                            }}
                            value={nameChannelText}
                            onChange={e => setNameChannelText(e.target.value)}
                        />
                        <Autocomplete
                            size="small"
                            className="autocomplete createChannelParticipants"
                            multiple
                            filterSelectedOptions={true}
                            options={options}
                            value={listChannelParticipants}
                            getOptionLabel={option => (
                                <div className="optionUser" key={option}>
                                    {getUserAvatar(option, false, users[option].avatar)}
                                    {users[option] ? users[option].displayName : ''}
                                </div>
                            )}
                            renderInput={params => (
                                <TextField
                                    autoFocus
                                    {...params}
                                    variant="outlined"
                                    label={intl.formatMessage({id:'Participants to add'})}
                                    fullWidth
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    if (users[option]) {
                                        var props = getTagProps({ index });
                                        return (
                                            <Chip
                                                avatar={getUserAvatar(option, false, users[option].avatar)}
                                                variant="outlined"
                                                label={
                                                    users[option] ? users[option].displayName : ''
                                                }
                                                {...props}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }
                            filterOptions={(options, { inputValue }) => {
                                var results = [];
                                options.forEach(o => {
                                    if (
                                        users[o] &&
                                        users[o].displayName.search(
                                            new RegExp(encodeURIComponent(inputValue), 'gi'),
                                        ) !== -1
                                    ) {
                                        results.push(o);
                                    }
                                });
                                return results;
                            }}
                            onChange={(e, values) => {
                                setListChannelParticipants(values);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShowCreateChannel(false);
                                setListChannelParticipants([]);
                                setNameChannelText('');
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={createChannel} color="primary">
                            Create channel
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const createChannel = () => {
        var newName = nameChannelText;

        if (newName.length > 35) {
            toastr.error('Channel name can be maximum 35 characters');
        } else if (newName.length < 4) {
            toastr.error('Channel name must be at least 4 characters');
        } else if (!newName.match(/^[A-Za-z0-9-_]{4,35}$/)) {
            toastr.error('Channel name can only contain letters, numbers, "-" and "_" (No spaces)');
        } else if (chatData.channels[newName]) {
            toastr.error('Channel name already exist.');
        } else {
            firebaseEvents.createChannel(nameChannelText, listChannelParticipants).then(name => {
                let interval = setInterval(() => {
                    if (chatData.channels[name]) {
                        clearInterval(interval);
                        browserHistory.push('/chat/channels/' + name);
                    }
                }, 100);
            });
            setShowCreateChannel(false);
            setListChannelParticipants([]);
            setNameChannelText('');
        }
    };

    const checkKeySearch = (e) => {
        if (e.key === 'Enter') {
            launchSearch();
        }
    };

    const launchSearch = () => {
        hideMobileMenu();
        browserHistory.push('/chat/search?s=' + searchText);
    };


    if (userId) {
        if (!children) {
            browserHistory.push('/chat/channels/general');
        }

        return (
            <div className='Chat'>
                <div>
                    <Card className={classnames('channels', {showMobile: showMobileChannels})} elevation={0} square={true}>

                        <CardContent>
                            <h1>
                                <span>Channels</span>
                                {isAdmin({
                                    user: {
                                        data: {
                                            permissions: userPermissions,
                                        },
                                    },
                                }) ? (
                                        <Tooltip title="New channel">
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setShowCreateChannel(true);
                                                    setListChannelParticipants([]);
                                                    setNameChannelText('');
                                                }}
                                            >
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                            </h1>
                            {getChannels()}
                            <h1>
                                <span>Group chat</span>
                                <Tooltip title="New group chat">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setShowCreateGroup(true);
                                            setListGroupParticipants([]);
                                        }}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </h1>
                            {getGroups()}
                            <h1>Direct chat</h1>
                            <UserList
                                userId={userId}
                                users={users}
                                chatDataDirect={chatData?.direct}
                                hideMobileMenu={hideMobileMenu}
                                routerParams={router.params}
                                getNbUnread={getNbUnread} />

                            {getCreateGroup()}
                            {getCreateChannel()}
                        </CardContent>
                    </Card>
                    <div className="searchChats">
                        <input
                            type="text"
                            value={searchText}
                            placeholder="Search conversations..."
                            onChange={e => setSearchText(e.target.value)}
                            onKeyPress={checkKeySearch}
                        />
                        <IconButton size="small" onClick={launchSearch}>
                            <SearchIcon />
                        </IconButton>
                    </div>
                </div>
                {children}

                <Button className="mobileShowChannels" onClick={()=>{ setShowMobileChannels(!showMobileChannels);}}>Switch Channel</Button>
            </div>
        );
    }
    return null;


};

export default withRouter(withCustomErrorBoundary(Chat));