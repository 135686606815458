export var ADD_PROJECT_EVENT_BY_TOOL = {
    clickup: 'clickedAddClickUpProject',
    jira: 'clickedAddJiraProject',
};
export var STOP_SYNC_EVENT_BY_TOOL = {
    clickup: 'stopClickUpProjectSync',
    jira: 'stopJiraProjectSync',
};
export var SYNC_PROJECT_EVENT_BY_TOOL = {
    clickup: 'syncedClickUpProject',
    jira: 'syncedJiraProject',
};
export var NB_CONNECTED_PROJECTS_BY_TOOL = {
    clickup: 'nbClickUpConnectedProjects',
    jira: 'nbJiraConnectedProjects',
};
export var NB_USERS_MATCHED_BY_TOOL = {
    clickup: 'nbClickUpUsersMatched',
    jira: 'nbJiraUsersMatched',
};
