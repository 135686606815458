import React from 'react';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import { ADD_TASK_LIST_LEVEL } from '../../utils/hooks/useSyncData/utils';

const ThreeLevelsExample = (
    <>
      Project A<br/>
      --Task of Project A<br/>
      ----Subtask of task of Project A<br/>
      Project B<br/>
      --Task of Project B<br/>
    </>
);

const TwoLevelsExample = (
    <>
      Task A<br/>
      --Subtask of Task A<br/>
      Task B<br/>
      --Subtask of Task B<br/>
    </>
);

const OneLevelExample = (
    <>
      Task A<br/>
      Task B<br/>
    </>
);

const exampleLevelMapper = {
    [ADD_TASK_LIST_LEVEL.ONE]: OneLevelExample,
    [ADD_TASK_LIST_LEVEL.TWO]: TwoLevelsExample,
    [ADD_TASK_LIST_LEVEL.THREE]: ThreeLevelsExample,
    [ADD_TASK_LIST_LEVEL.FOUR]: ThreeLevelsExample,
};

const AddTaskListExample = ({ task, isSyncAppTask, syncAppType, addTaskListLevel }) => {
    const example = isSyncAppTask ? exampleLevelMapper[addTaskListLevel] : ThreeLevelsExample;

    return (
        <p className='example'>
            {isSyncAppTask && <SyncProjectLogo toolName={syncAppType} task={task} fromViewMode={'AddTaskList'} />}
            {example}
        </p>
    );
};

export default AddTaskListExample;