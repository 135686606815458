
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { isExpenseValid } from  './utils';
import ExpensesSelector from  './ExpensesSelector';
import ExpensesCreate from  './ExpensesCreate';
import { updateCostBudgetSettingsExpensesTypes } from  '../../../utils/controllers/tasks/expenses';
import InputAdornment from '@material-ui/core/InputAdornment';
import Proptypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { getSymbol  } from '../../../utils/currencyFormat';

const ExpensesDialog = ({ open, handleClose, handleAdd, editExpense }) =>  {
    const intl = useIntl();
    const [expense, setExpense] = useState( {
        title: '',
        date: Date.now(),
        unit: 1,
        types: [],
        pricePerUnit: 0,
        vat: 0,
    });
    const [newExpenseType, setNewExpenseType] = useState(null);
    const [inputsErrors, setInputsErrors] = useState({});
    const currency = useSelector((state) => state.app?.account?.settings.costBudget.currency || 'USD');
    const currencySymbol = getSymbol(currency);
    const userDateFormat = useSelector((state) => state.app?.user?.data?.dateFormat || 'DD/MM/YYYY');

    useEffect(()=> {
        if(editExpense){
            setExpense({
                title: editExpense.title,
                date: editExpense.date,
                units: editExpense.units,
                pricePerUnit: editExpense.pricePerUnit,
                types: editExpense.types || [],
                vat: editExpense.vat || 0,
            });
            return ;
        }
        setExpense({
            title: '',
            date: Date.now(),
            units: 1,
            types: [],
            pricePerUnit: 0,
            vat: 0,
        });
    },[open, editExpense]);

    const handleInputs = ({ target: { value, id}}) => {
        setExpense({
            ...expense,
            [id]: value,
        });
        setInputsErrors({ ...inputsErrors, [id]: false });
    };

    const handleNumbers = ({ target: { value, id}}) => {
        setExpense({
            ...expense,
            [id]: value.replace(',','.'),
        });
    };

    const handleNumbersBlur = ({ target: { value, id}}) => {
        if (!value || isNaN(value)) {
            setExpense({
                ...expense,
                [id]: 0,
            });
            return;
        }
        setExpense({
            ...expense,
            [id]: value.includes('.') ? parseFloat(value).toFixed(2) : parseFloat(value),
        });
        setInputsErrors({ ...inputsErrors, [id]: false });
    };

    const handleUnits = ({ target: { value, id}}) => {
        if (!value || isNaN(parseInt(value)) || value < 1) {
            return;
        }
        
        setExpense({
            ...expense,
            [id]: parseInt(value),
        });
        setInputsErrors({ ...inputsErrors, [id]: false });
    };

    const handleExpensesTypes = async  (e, values) => {
        if(values?.inputValue) {
            setNewExpenseType({
                name: values.inputValue
            });
            return; 
        }

        setExpense({
            ...expense,
            types: [values?.id]
        });
    };

    const handleEnterKeyAdd = (e) => {
        if((e.key === 'Enter' || e.keyCode === 13) && isExpenseValid(expense)) {
            onAdd();
        }
    };

    const onAdd = () => {
        handleAdd({
            ...expense,
            total: expense.pricePerUnit * expense.units,
            totalWithVat: parseFloat(expense.pricePerUnit * expense.units) + parseFloat(expense.vat),
            id: editExpense ? editExpense.id : null
        });
    };

    const handleCloseCreate = () => {
        setNewExpenseType(null);
    };

    const handleCreateNewType = ({name, color}) => {
        const newItem = updateCostBudgetSettingsExpensesTypes({name, color});
        newItem.then((m) => {
            setNewExpenseType(null);
            setExpense({
                ...expense,
                types: [...expense.types, m.key]
            });
        });
    };

    const changeDate = (date) => {
        setExpense({
            ...expense,
            date: date.valueOf(),
        });
    };
    
    return <>
        {newExpenseType && <ExpensesCreate open={true} value={newExpenseType.name} handleClose={handleCloseCreate} handleOnAdd={handleCreateNewType} />}    
        <Dialog className="ExpensesAdd" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} onKeyUp={handleEnterKeyAdd}>
            <MuiDialogTitle className="title" disableTypography >
                <Typography variant="h6">{editExpense?intl.formatMessage({id: 'expense.edit'}):intl.formatMessage({id: 'expense.add-new'})}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers>
                <div className="fields1">
                    <TextField
                        autoFocus
                        margin="normal"
                        placeholder='Description'
                        onChange={handleInputs}
                        value={expense.title}
                        id="title"
                        label={intl.formatMessage({id: 'expense.title'})}
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="fields2">
                    <DatePicker
                        format={userDateFormat}
                        margin="normal"
                        label={intl.formatMessage({id: 'expense.date'})}
                        onChange={changeDate}
                        value={expense.date}
                        autoOk={true}
                        cancelLabel={null}
                        okLabel={null}
                        id="date"
                        fullWidth
                    />
                    <ExpensesSelector selected={expense.types} onChange={handleExpensesTypes} />
                </div>
                <div className="fields3">
                    

                    <TextField
                        margin="normal"
                        onChange={handleNumbers}
                        onBlur={handleNumbersBlur}
                        value={expense.pricePerUnit}
                        id="pricePerUnit"
                        label={intl.formatMessage({id: 'expense.pricePerUnit'})}
                        type="text"
                        error={inputsErrors.pricePerUnit || false}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                        }}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        placeholder='Units'
                        onChange={handleUnits}
                        value={expense.units}
                        id="units"
                        label={intl.formatMessage({id: 'expense.units'})}
                        type='number'
                        error={inputsErrors.units || false}
                        fullWidth
                    />

                    <TextField
                        margin="normal"
                        placeholder='total'
                        value={expense.pricePerUnit * expense.units}
                        disabled
                        id="total"
                        label={intl.formatMessage({id: 'expense.total'})}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                        }}
                        error={inputsErrors.total || false}
                        fullWidth
                    />
                </div>
                <div className="fields3">
                    <div/>
                    <TextField
                        margin="normal"
                        onChange={handleNumbers}
                        onBlur={handleNumbersBlur}
                        value={expense.vat}
                        id="vat"
                        label={intl.formatMessage({id: 'expense.vat'})}
                        type="text"
                        error={inputsErrors.vat || false}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                        }}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        placeholder='total'
                        value={parseFloat(expense.pricePerUnit * expense.units) + parseFloat(expense.vat)}
                        disabled
                        id="total"
                        label={intl.formatMessage({id: 'expense.totalWithVat'})}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                        }}
                        error={inputsErrors.total || false}
                        fullWidth
                    />
                </div>
                
            </MuiDialogContent>
            <MuiDialogActions>
                <Button variant="contained" onClick={handleClose}>
                    {intl.formatMessage({id: 'Cancel'})}
                </Button>
                <Button 
                    variant="contained" color="primary" disabled={!isExpenseValid(expense)} onClick={onAdd}>
                    {editExpense && editExpense.id ? intl.formatMessage({id: 'expense.edit'}) : intl.formatMessage({id: 'expense.add-new'})}
                </Button>
            </MuiDialogActions>
        </Dialog>
    </>;
};


ExpensesDialog.propTypes = {
    editExpense: Proptypes.object,
    open: Proptypes.bool.isRequired,
    handleClose: Proptypes.func.isRequired,
    handleAdd: Proptypes.func.isRequired
}; 

export default ExpensesDialog;