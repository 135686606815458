import React, { useEffect, useState, memo, useCallback, useMemo } from 'react';
import classnames from 'clsx';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import { browserHistory } from 'react-router';
import { checkIsMobileWidth, handleStopPropagation } from '../../utils/utils';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { updateTaskTitle, addChallenge, deleteTasks } from '../../utils/firebaseEvents';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getAccountTaskHasChildren, getAccountTaskTitle, getAccountTaskParent } from '../../utils/selectors/account';
import { openReccurentTaskModal } from '../App/AppActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenTaskIcon from '@material-ui/icons/OpenInNew';
import Tooltip from 'rc-tooltip';
import useSyncData from '../../utils/hooks/useSyncData';

const SINGLEFONTSIZE = 12;

const NewTaskTitleInput = ({
    taskId,
    taskIndex,
    openMoreOptions,
    isRecurringTask,
    rightClickActiveTask,
    viewBaseLink = '/tasks'
}) => {
    const dispacth = useDispatch();
    const accountId = useSelector(getAccountId);
    const taskName = useSelector(getAccountTaskTitle(taskId));

    const taskHasChildrens = useSelector(getAccountTaskHasChildren(taskId));
    const taskParent = useSelector(getAccountTaskParent(taskId));
    // const taskSourceId = useSelector(getAccountTaskISourceId(taskId));
    // const isConnectProject = useSelector(getAccountTaskIsConnectProject(taskId));
    // const syncAppType = useSelector(getAccountTaskSyncAppType(taskId));
    // const sourceType = useSelector(getAccountTaskSourceType(taskId));
    const isNotRecurringTask = !isRecurringTask || !!(isRecurringTask && taskHasChildrens);

    const [isMobileWidth, setIsMobileWidth] = useState(false);
    const [taskTitle, setTaskTitle] = useState(taskName);
    const [inputWidth, setInputWidth] = useState(0);

    const { currentTask, syncAppType } = useSyncData(taskId);

    useEffect(() => {
        setIsMobileWidth(checkIsMobileWidth());
        window.addEventListener('resize', checkDeviceWidth);

        return () => {
            window.removeEventListener('resize', checkDeviceWidth);
        };
    }, []);

    const checkDeviceWidth = useCallback(() => {
        setIsMobileWidth(checkIsMobileWidth());
    }, []);

    useEffect(() => {
        if(taskName || taskName === '') {
            setTaskTitle(taskName);
        }
    }, [taskName, currentTask?.sourceId]);

    useEffect(() => {
        const canvas = document.getElementById(`canvas-${taskId}`);
        const inputField = document.getElementById(`inputField-${taskId}`);
        let ctx;

        if(canvas && inputField && taskTitle) {
            ctx = canvas.getContext('2d');
            inputField.addEventListener('input', drawHiddenTextToMeasure());
        }
        function drawHiddenTextToMeasure() {
            ctx.font = 'normal 13px Eina01, sans-serif';
            let metrics = ctx.measureText(inputField.value || 'Untitled');
            setInputWidth(Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight));
        }

        return () => {
            if(canvas && inputField && taskTitle) {
                inputField.removeEventListener('input', drawHiddenTextToMeasure());
            }
        };
    }, [taskId, taskTitle, syncAppType]);

    const handleInputChange = useCallback(({ target: { value }}) => {
        setTaskTitle(value);
    }, []);

    const handleToggleInputFocus = useCallback((e) => {
        if(viewBaseLink === '/list') {
            const id = e.target.id.replace('inputField-', '');
            document.getElementById(`TableRow-${id}`).classList.toggle('focused');
        }
    }, [viewBaseLink]);

    const handleChangeTaskName = useCallback((e) => {
        const newTask = document.querySelector(`[data-newtaskinput-id="${taskId}"]`);
        const task = [taskId];
        handleStopPropagation(e);

        if(newTask) {
            if(!taskTitle) {
                deleteTasks(task);
                return;
            }
            newTask.removeAttribute('data-newtaskinput-id');
        }
        if(taskTitle === taskName) return;

        handleToggleInputFocus(e);

        const newTitle = !taskTitle || taskTitle === 'Untitled' ? 'Untitled' : taskTitle;

        updateTaskTitle(accountId, taskId, newTitle);
        setTaskTitle(newTitle);

        dispacth(openReccurentTaskModal({
            taskId: taskId,
            updatedField: 'title',
        }));

        addChallenge('changeTaskName');
    }, [taskId, taskTitle, taskName, accountId, handleToggleInputFocus]);

    const handleKey = useCallback((e) => {
        if (e.keyCode === 13 || e.keyCode === 27) {
            handleChangeTaskName(e);
        }
    }, [handleChangeTaskName]);

    const handleOpenDetails = useCallback((e) => {
        e.stopPropagation();
        browserHistory.push(`${viewBaseLink}/${taskId}`);
    }, [viewBaseLink, taskId]);

    const buttons = useMemo(()=>{
        return (
            <div className={classnames('icons-container', {'show': rightClickActiveTask === taskId})}>
                <Tooltip
                    mouseLeaveDelay={0}
                    placement="top"
                    overlay={'Open Details'}
                >
                    <div onClick={handleOpenDetails} className="Bullet-Options-Icons details">
                        <OpenTaskIcon />
                    </div>
                </Tooltip>
                <Tooltip
                    mouseLeaveDelay={0}
                    placement="top"
                    overlay={'More Options'}
                >
                    <div
                        data-task-id={taskId}
                        data-task-index={taskIndex}
                        onClick={openMoreOptions}
                        className="Bullet-Options-Icons menu"
                    >
                        <MoreVertIcon />
                    </div>
                </Tooltip>
                {!isMobileWidth && syncAppType && currentTask?.sourceId && !currentTask?.isConnectProject && taskParent && (
                    <SyncProjectLogo
                        toolName={syncAppType}
                        tooltipPlacement={'right'}
                        task={currentTask}
                    />
                )}
            </div>
        );
    }, [rightClickActiveTask, handleOpenDetails, taskId, taskParent, taskIndex, openMoreOptions, isMobileWidth, currentTask, taskHasChildrens, syncAppType]);

    const isInputVisible = React.useMemo(() => {
        return Boolean(isNotRecurringTask && !isMobileWidth && (!currentTask?.isConnectProject || (currentTask?.isConnectProject && !taskParent)));
    }, [isNotRecurringTask, isMobileWidth, currentTask, taskParent]);

    return (
        <div
            data-task-id={taskId}
            data-task-index={taskIndex}
            className={classnames('NewTaskTitleInput', {
                isSync: syncAppType && currentTask?.sourceType?.hierarchyLevel === 0 && !currentTask?.isConnectProject,
                fromListViewMode: viewBaseLink === '/list'
            })}>
            {
                <>
                    <canvas className='NewTaskTitleInput__canvas' id={`canvas-${taskId}`}></canvas>
                    {
                        isInputVisible ?
                            <input
                                className={classnames('NewTaskTitleInput inputField', {noTitle: !taskTitle})}
                                data-task-id={taskId}
                                data-task-index={taskIndex}
                                id={`inputField-${taskId}`}
                                onKeyUp={handleKey}
                                onContextMenu={handleStopPropagation}
                                tabIndex={1}
                                onClick={handleStopPropagation}
                                onBlur={handleChangeTaskName}
                                onFocus={handleToggleInputFocus}
                                onChange={handleInputChange}
                                value={taskTitle}
                                style={{width: `${inputWidth + SINGLEFONTSIZE}px`}}
                            />
                            :
                            <span
                                data-task-id={taskId}
                                data-task-index={taskIndex}
                            >
                                {taskTitle}
                            </span>
                    }

                    {/* {
                        titleInputRef && !guidePopupEnterToAdd && <DefaultTootip
                            open={!guidePopupEnterToAdd && orderedTaskListLength === TASKTOSHOWTOLTIP}
                            placement='left'
                            newRef={titleInputRef}
                            tooltipId='enterToAdd'
                            taskId={taskId}
                        />
                    } */}

                    {!!currentTask?.isConnectProject &&
                        <SyncProjectLogo
                            toolName={syncAppType}
                            tooltipPlacement={'right'}
                            task={currentTask}
                        />
                    }

                    {buttons}
                </>
            }
        </div>
    );
};

export default memo(withCustomErrorBoundary(NewTaskTitleInput));