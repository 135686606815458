import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { getUserAvatar  } from '../../utils/utils';
import Button from '@material-ui/core/Button';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import { getUserId } from '../../utils/selectors/user';
import { getAccountUsers } from '../../utils/selectors/account';


const MultiAssignDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [multiAssignedUserId, setMultiAssignedUserId] = useState(null);
    const [multiDetailCurrent, setDetailCurrent] = useState();
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);

    const handleConfirm = () => {
        firebaseEvents
            .multiForceAssign(multiAssignedUserId, selectedItems)
            .then(result => {
                if (result !== 'error') {
                    setSelectedLines();
                    showMultiDetails();
                    setMultiAssignedUserId(null);
                }
            });
    };

    const handleNo = () => {
        showMultiDetails();
    };

    const handleForceAssignClick = () => {
        setDetailCurrent('User');
    };

    const handleSelect = userId => {
        setMultiAssignedUserId(userId);
        setDetailCurrent(null);
    };
    const handleCloseModal = () => {
        setDetailCurrent(null);
    };

    return <div id="multiAssignDetails">
        <div className="assignedUser">
            <label>Force assign tasks to</label>
            <div
                className="nameContainer"
                onClick={handleForceAssignClick}
            >
                {getUserAvatar(
                    multiAssignedUserId || userId,
                    false,
                    accountUsers[multiAssignedUserId || userId]?.avatar,
                )}
                {accountUsers[multiAssignedUserId || userId]
                    ? accountUsers[
                        multiAssignedUserId || userId
                    ].displayName
                    : ''}
            </div>
            {multiDetailCurrent === 'User' && <SelectTeamMember
                currentUser={[multiAssignedUserId || userId]}
                onSelect={handleSelect}
                onClose={handleCloseModal}
                open={multiDetailCurrent === 'User'}
            />}
        </div>
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
        Cancel
        </Button>
    </div>;
};

export default MultiAssignDetails; 