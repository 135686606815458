import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import SplitBy from '../SplitBy/SplitBy';
import OverViewCard from '../OverViewCard';
import TimeWorkedTable from './TimeWorkedTable';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import ReactDOM from 'react-dom';

import {
    agregateData,
    getDataByTask,
    convertToDateTime,
    deepCloneDataSets,
    backgroundColor,
    borderDash,
    downloadAsImage,
    getColor,
    exportCSV,
    validateNumber,
    getDateFormated,
    getMonth,
} from '../utils';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const getDataBySkills = ({lastValue, currentChartData, csvLines, data, tasksIds, skillsData, hasBreakdownByTask, stateTasks}) => data.reduce((acc, skillId) => {
    if(!skillId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = skillId + '-' + taskId;
            let timeWorked = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData.skills.findIndex(key => skillId === key);
                if (idxOfSelect > -1) {
                    timeWorked = parseInt(findData['Time worked Skills'][idxOfSelect]);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    timeWorked = lastChartPointData?.timeWorked || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                timeWorked = lastChartPointData?.timeWorked || 0;
            }

            acc.push({
                id,
                breakdownId: skillId,
                label:   skillsData[skillId].name +  '-' +  stateTasks[taskId].title,
                timeWorked: timeWorked,
                tasksId: [taskId],
                parentName: skillsData[skillId].name,
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }
    // if you split by task then

    // if no other breakdown.
    const linesWithSkill = csvLines.filter(line => line.skills.includes(skillId));

    const vadas = linesWithSkill.reduce((acc,l) => {
        const idxOfSelect = l.skills.findIndex(key => skillId === key);
        if (idxOfSelect >= 0) {
            acc.timeWorked +=  parseInt(l['Time worked Skills'][idxOfSelect]);
            acc.tasksId.push(l.id);
        }
        return acc;
    }, { timeWorked: 0, tasksId: []});

    acc.push({
        id: skillId,
        breakdownId: skillId,
        label:  skillsData[skillId].name,
        timeWorked: vadas.timeWorked,
        tasksId: vadas.tasksId,
        parentName: skillsData[skillId].name,
    });

    return acc;
}, []);

const breakdownDataByUseres = ({lastValue, currentChartData, csvLines, data, tasksIds, teamMembersData, hasBreakdownByTask, stateTasks}) => data.reduce((acc, userId) => {
    if(!userId) {
        return acc;
    }

    if (hasBreakdownByTask) {
        //get all the availble tasks
        tasksIds.forEach((taskId) => {
            const id = userId + '-' + taskId;
            let timeWorked = 0;
            const findData = csvLines.find(line => line.id === taskId);
            if (findData) {
                const idxOfSelect = findData?.teamMembers?.findIndex(key => userId === key);
                if (idxOfSelect > -1) {
                    timeWorked = parseInt(findData['Time worked Team members'][idxOfSelect]);
                } else if(lastValue) {
                    const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                    timeWorked = lastChartPointData?.timeWorked || 0;
                }
            } else if(lastValue) {
                const lastChartPointData = currentChartData[lastValue].find(el => el.id === id);
                timeWorked = lastChartPointData?.timeWorked || 0;
            }

            acc.push({
                id,
                label:   teamMembersData[userId]?.displayName +  '-' +  stateTasks[taskId].title,
                timeWorked: timeWorked,
                breakdownId: userId,
                tasksId: [taskId],
                parentName: teamMembersData[userId]?.displayName || 'Deleted User',
                subName: stateTasks[taskId].title,
            });
        });

        return acc;
    }

    // if no other breakdown.
    const linesWithUser = csvLines.filter(line => line.teamMembers.includes(userId));
    const vadas = linesWithUser.reduce((acc,line) => {
        const idxOfSelect = line?.teamMembers?.findIndex(key => userId === key);
        if (idxOfSelect >= 0) {
            acc.timeWorked += parseInt(line['Time worked Team members'][idxOfSelect]);
            acc.tasksId.push(line.id);
        }
        return acc;
    }, { timeWorked: 0, tasksId: []});

    acc.push({
        id: userId,
        breakdownId: userId,
        label:  teamMembersData[userId]?.displayName  || 'Deleted User',
        timeWorked: vadas.timeWorked,
        tasksId: vadas.tasksId,
        parentName: teamMembersData[userId]?.displayName  || 'Deleted User',
    });

    return acc;
}, []);


ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 	'Time Worked',
                data: [],
                fill: true,
                yAxisID: 'y',
                pointRadius: 0
            },
        ]
    },
    options: {
        responsive: true,
        animation: false,
        stacked: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                }
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function(context){
                        return getDateFormated(context[0].label);
                    },
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size:12
                },
                titleColor: '#fff',
                titleFont: {
                    size:14
                },
                boxWidth: 8,
                boxHeight: 8
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function(value) {
                        const labelValue = this.getLabelForValue(value);
                        if(moment(labelValue, 'YYYY', true).isValid()){
                            return labelValue;
                        }
                        else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        }
                        else if(moment(labelValue, 'M/YYYY', true).isValid()){
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }

                        return `${moment(labelValue).format('MMM D, YY')}`;
                    },
                    color: '#999',
                    font: {
                        size: 11
                    }
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                lineWidth: 0,
                drawTicks: false,
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11
                    }
                }
            },
        },
    },
};

const TimeWorked = ({
    workspaceData,
    accountId,
    stateTasks,
    datesRange,
    workspaceSelected,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    childsParents,
    userTimezone
}) => {
    const accountSkills = useSelector((state) => state.app?.account?.skills || {});
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const chartCanvas = useRef(null);
    const [csvData, setCSVData] = useState({});
    const [currentChart, setCurrentChart] = useState(null);
    const [currentData, setCurrentData] = useState({labels: [], datasets: {} } );
    const intl = useIntl();
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl);
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);
    const [displayData, setDisplayData] = useState({labels: [], datasets: {} } );
    const dispatch = useDispatch();



    const updateBreakdowns = async (newBreakdowns) => {
        const breakdownDataByTask = newBreakdowns.find(el => el.value === 'tasks');
        if (
            !breakdownDataByTask?.selectedData.length && !csvData[workspaceSelected]
            ||
            breakdownDataByTask?.selectedData.length && csvData[workspaceSelected]
            ||
            breakdownDataByTask?.selectedData.length &&
            breakdownDataByTask?.selectedData.length !== Object.keys(csvData).length     &&
            !csvData[workspaceSelected]
        ) {
            dispatch(showLoader());
            const data = await getDataByTask({
                accountId: accountId,
                workspaceData: workspaceData,
                selectedTasks: breakdownDataByTask?.selectedData || [],
                userTimezone
            });

            ReactDOM.unstable_batchedUpdates(()=> {
                setBreakdownData(newBreakdowns);
                setCSVData(data);
            });
            return;
        }

        setBreakdownData(newBreakdowns);
    };

    // Should only occor on the mount
    useEffect( () => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = (e) => {
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);

                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));

                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });

                const reactVirtualizedGriElement = document.getElementsByClassName('ReactVirtualized__Grid');

                if(reactVirtualizedGriElement.length > 1){
                    const directChildren = reactVirtualizedGriElement[1]?.children[0];
                    const width = directChildren?.children[0]?.style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };

            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]);


    const onWorkspaceChange = async () => {
        dispatch(showLoader());
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
        const data = await getDataByTask({
            accountId: accountId,
            workspaceData: workspaceData,
            selectedTasks: [],
            userTimezone
        });

        setCSVData(data);
    };

    useEffect( () => {
        onWorkspaceChange();
    }, [workspaceData, accountId]);


    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }

        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item) => {
            let line = '';
            line += `${item.name}\n`;
            line += `Time Worked;${item.timeWorked.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'time-work-chart');
    };

    const breakdownDataByTask = breakdownData.find(el => el.value === 'tasks');
    const breakdownDataBySkills = breakdownData.find(el => el.value === 'skills');
    const breakdownDataByUsers = breakdownData.find(el => el.value === 'users');

    const handleData =  async () => {
        if(currentChart && !_.isEmpty(csvData)) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let possibleBreaks = [];
            // get all the tasks IDS
            const allTasksId = Object.keys(csvData);
            const allChartPoints = Object.values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime)*60*60*4 * 1000);
                    const formatedDate =  `${newDate.getFullYear()}/${getMonth(newDate.getMonth() + 1)}/${newDate.getDate()}`;

                    if(acc[formatedDate]){
                        return {
                            ...acc,
                            [formatedDate]: [el,...acc[formatedDate]]
                        };
                    }

                    return {
                        ...acc,
                        [formatedDate]: [el]
                    };
                }, {});


            let lastValue = '';
            // have to go throw all of the checkpoints
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                if (breakdownDataByUsers?.lvl === 0 && breakdownDataByUsers.selectedData.length) {
                    possibleBreaks = breakdownDataByUsers.selectedData;

                    const data = breakdownDataByUseres({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataByUsers.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        teamMembersData: accountTeamMembers
                    });

                    lastValue = key;
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }

                if (breakdownDataBySkills?.lvl === 0 && breakdownDataBySkills.selectedData.length) {
                    possibleBreaks = breakdownDataBySkills.selectedData;

                    const data = getDataBySkills({
                        csvLines: csvLines,
                        currentChartData:  chartPointAcc,
                        data: breakdownDataBySkills.selectedData,
                        hasBreakdownByTask: !!breakdownDataByTask,
                        lastValue: lastValue,
                        stateTasks: stateTasks,
                        tasksIds: breakdownDataByTask?.selectedData || [],
                        skillsData: accountSkills
                    });
                    return {
                        ...chartPointAcc,
                        [key]: data
                    };
                }
                //if you have the lvl 0 breakdown beeing tasks =
                if (breakdownDataByTask?.lvl === 0) {
                    possibleBreaks = allTasksId;

                    const values = allTasksId.reduce((tasksAcc, taskId) => {
                        let timeWorked = 0;
                        // lvl 2 === teamMember
                        const findData = csvLines.find(line => line.id === taskId);
                        if(breakdownDataByUsers  && breakdownDataByUsers.selectedData.length){
                            const teamMembers = breakdownDataByUsers.selectedData.length ? breakdownDataByUsers.selectedData : [];
                            possibleBreaks = teamMembers;
                            teamMembers.forEach(tmId => {
                                let id = taskId +  '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData?.teamMembers?.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        timeWorked = parseInt(findData['Time worked Team members'][idxOfSelect]);
                                    } else if(lastValue) {
                                        const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                        timeWorked = lastChartPointData?.timeWorked || 0;
                                    }
                                } else if(lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    timeWorked = lastChartPointData?.timeWorked || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label: stateTasks[taskId].title +  '-' +  accountTeamMembers[tmId]?.displayName || 'Delete User',
                                    breakdownId: tmId,
                                    timeWorked: timeWorked,
                                    tasksId: [taskId],
                                    parentName: stateTasks[taskId].title,
                                    subName: accountTeamMembers[tmId]?.displayName,
                                });
                            });
                            return tasksAcc;
                        }

                        if(breakdownDataBySkills  && breakdownDataBySkills.selectedData.length ){
                            possibleBreaks = breakdownDataBySkills.selectedData;
                            breakdownDataBySkills.selectedData.forEach(tmId => {
                                let id = taskId +  '-' + tmId;
                                if (findData) {
                                    const idxOfSelect = findData.skills.findIndex(key => tmId === key);
                                    if (idxOfSelect >= 0) {
                                        timeWorked = parseInt(findData['Time worked Skills'][idxOfSelect]);
                                    }
                                } else if(lastValue) {
                                    const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === id);
                                    timeWorked = lastChartPointData?.timeWorked || 0;
                                }

                                tasksAcc.push({
                                    id: id,
                                    label: stateTasks[taskId].title +  '-' +  accountSkills[tmId].name,
                                    timeWorked: timeWorked,
                                    tasksId: [taskId],
                                    breakdownId: tmId,
                                    parentName: stateTasks[taskId].title,
                                    subName: accountSkills[tmId].name,
                                });
                            });
                            return tasksAcc;
                        }


                        if(findData){
                            timeWorked = parseInt(findData['Time worked']);
                        } else if(lastValue) {
                            const lastChartPointData = chartPointAcc[lastValue].find(el => el.id === taskId);
                            timeWorked = lastChartPointData?.timeWorked || 0;
                        }

                        return [ ...tasksAcc, {
                            id: taskId,
                            label: stateTasks[taskId]?.title || 'N/A',
                            timeWorked: timeWorked,
                            tasksId: [taskId],
                            parentName: stateTasks[taskId]?.title || 'N/A',
                        }];
                    }, []);
                    // saving last value
                    lastValue = key;

                    return {
                        ...chartPointAcc,
                        [key]: values
                    };
                }

                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) =>  {
                    return {
                        id: `default${idx}`,
                        label: '',
                        timeWorked: parseInt(line['Time worked']),
                        tasksId: [line.id],
                        parentName: ''
                    };
                });

                return { ...chartPointAcc,
                    [key]: values
                };
            }, {});

            const chartData = Object.entries(newData).reduce((acc, [key, items]) => {
                acc.labels.push(key);
                items.forEach((el, itemIdx) => {
                    if (!acc.datasets[el.id+'-timeWorked']) {
                        const [colorA ] = getColor(possibleBreaks.length,itemIdx);
                        acc.datasets[el.id+'-timeWorked'] =  {
                            ...el,
                            // missing this one
                            id: el.id,
                            label:  el.label + ' Time Worked',
                            data: [validateNumber(el.timeWorked) ],
                            borderColor: colorA,
                            backgroundColor: colorA,
                            fill: true,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            source: el.tasksId,
                            labelType: 'timeWorked',
                            segment: {
                                borderWidth: 2,
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorA, currentChart, userTimezone),
                            }
                        };
                    } else {
                        acc.datasets[el.id+'-timeWorked'].data.push(validateNumber(el.timeWorked));
                    }
                });

                return acc;
            } , {
                labels: [],
                datasets: {}
            });

            if(!moment(chartData.labels[0]).tz(userTimezone).isSame(startDate, 'day')){
                chartData.labels = [startDate.format('YYYY/MM/DD'),...chartData.labels];
                Object.values(chartData.datasets)
                    .forEach(el => {
                        el.data =  [el.data[0],...el.data];
                    });
            }

            if(!moment(_.last(chartData.labels)).tz(userTimezone).isSame(moment().tz(userTimezone), 'day')){
                chartData.labels.push(moment().tz(userTimezone).format('YYYY/MM/DD'));
                Object.values(chartData.datasets).forEach(el => el.data.push(_.last(el.data)));
            }

            chartData.labels.push(moment().tz(userTimezone).add(1, 'day').format('YYYY/MM/DD'));
            chartData.labels.push(endDate.format('YYYY/MM/DD'));
            Object.values(chartData.datasets).forEach(el => el.data.push(...[null,null]));


            // this will add every single day to our datasets
            const aggregatedDataByDay = agregateData('day', deepCloneDataSets(chartData));
            /*     let displayData = aggregatedDataByDay;
            if(splitBy !== 'day'){
                displayData = agregateData(splitBy, deepCloneDataSets(aggregatedDataByDay));
            }
            */            // need to find the lowest value
            let min = -1;
            let max = 0;
            Object.values(displayData.datasets).forEach(el => {
                el.data.forEach(entry => {
                    if(max === 0 || max < entry)
                        max = entry;
                    if((min > entry || min === -1) && entry !== null)
                        min = entry;
                });
            });
            currentChart.options.scales.y.min = min <= 0 ? 0 : min - (min * 0.10);
            /*
                    currentChart.data.datasets = Object.values(displayData.datasets);
                    currentChart.data.labels = displayData.labels;
                    currentChart.update();
            */
            /*             setDisplayData(displayData); */
            setCurrentData(aggregatedDataByDay);
        }
        dispatch(hideLoader());
    };

    useEffect(() => {
        if(currentChart && currentData){
            const displayData = agregateData(splitBy, deepCloneDataSets(currentData));
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(displayData);
        }
    }, [splitBy,currentData, currentChart]);

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    };
    /*
    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
        currentChart.data.labels = [];
        const datasetsSize = currentChart.data.datasets.length;
        // clear old datasets data
        for(let i = 0; i < datasetsSize; i++){
            currentChart.data.datasets[i].data = [];
        }

        const aggregatedData = agregateData(mode, deepCloneDataSets(currentData));
        setDisplayData(aggregatedData);
        currentChart.data.datasets = Object.values(aggregatedData.datasets);
        currentChart.data.labels = aggregatedData.labels;
        currentChart.update();
        currentChart.resize();
    };  */

    useEffect(()=> {
        handleData();
    }, [datesRange, breakdownData, csvData, currentChart]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => _.uniq(workspaceData.map(el => el.teamMembers).flat()), [workspaceData]);
    // get all of the available skills
    const availableSkills = React.useMemo(() => _.uniq(workspaceData.map(el => el.skills).flat()), [workspaceData]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ?
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    const overviewCurrentData = React.useMemo(() => {
        const startDate = moment(datesRange.startDate);
        const endDate = moment(datesRange.endDate)
            .isAfter(moment().tz(userTimezone)) ? moment().tz(userTimezone) : moment(datesRange.endDate) ;
        const workspaceTasks = workspaceSelected === 'root' ? Object.keys(stateTasks) :
            _.get(childsParents, workspaceSelected , [workspaceSelected]);

        let totalWorktime = 0;
        workspaceTasks.forEach(taskId => {
            const currentTask = stateTasks[taskId];
            if(!currentTask || !currentTask.workingTime){
                return;
            }
            const workTimeEntries = Object.values(currentTask.workingTime);
            workTimeEntries.forEach((workTimeEntry) => {
                const workTimeDate = moment.tz(workTimeEntry.date, 'YYYY-MM-DD', userTimezone);
                if (!workTimeDate.isBetween(startDate, endDate)) {
                    return false;
                }

                totalWorktime += parseFloat(workTimeEntry.hours);
            });
        });

        const timeWorkPerMonth = totalWorktime > 0 ? parseInt(totalWorktime / endDate.diff(startDate, 'months')) : 'N/A';
        const timeWorkPerWeek = totalWorktime > 0 ?  parseInt(totalWorktime / endDate.diff(startDate, 'weeks')) : 'N/A';
        const timeWorkedPerDay = totalWorktime > 0 ?  parseFloat(totalWorktime / endDate.diff(startDate, 'days')).toFixed(2) : 'N/A';

        return {
            totalTimeWorked: parseInt(totalWorktime) || 'N/A',
            timeWorkPerMonth,
            timeWorkPerWeek,
            timeWorkedPerDay
        };
    }, [stateTasks, childsParents, workspaceSelected, datesRange]);

    if (!hasAutorization) {
        return <Unautorized />;
    }

    const renderText = (data) => isNaN(data) || data === 'N/A' ? 'N/A' : `${data}h`;

    return <>
        <Paper className="topSection">
            <div className="breakdownsContainer">
                <BreakdownController
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    tasks={subItensTasks}
                    skills={availableSkills}
                    teamMembers={availableTeamMembers}
                    workspaceSelected={workspaceSelected}
                />
            </div>
            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard
                    title={'Total Time Worked'}
                    value={renderText(overviewCurrentData.totalTimeWorked)}
                />
                <OverViewCard
                    title={'Average Time Worked per Day'}
                    value={renderText(overviewCurrentData.timeWorkedPerDay)}
                />
                <OverViewCard
                    title={'Average Time Worked per Week'}
                    value={renderText(overviewCurrentData.timeWorkPerWeek)} />
                <OverViewCard
                    title={'Average Time Worked per Month'}
                    value={renderText(overviewCurrentData.timeWorkPerMonth)}
                />
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(chartCanvas.current)}>
                    Save as Img
                    </Button>
                </div>
                <canvas height="300" width="800" ref={chartCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                        Save as CSV
                    </Button>
                </div>
                <TimeWorkedTable
                    onColumnHover={onColumnHover}
                    userDateFormat={dateFormat}
                    datasets={displayData.datasets}
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
};

TimeWorked.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(TimeWorked);