import { db } from '../../firebaseEvents';
import { tracking } from '../../utils';
import { getAccountId } from '../../selectors/account';
import { getState, getAppData } from '../../../configureStore';

export const updateTeamMemberInternalCost = (userId, internalCost) => {
    tracking('Update Team Member Internal Cost', { accountId: getAccountId(getState()), userId, internalCost });

    db.set(`/accounts/${getAccountId(getState())}/users/${userId}/cost/internalCost`, {
        changedWhen: Date.now().valueOf(),
        changedBy: getAppData()?.user?.data?.userId,
        value: parseFloat(internalCost),
    });
};

export const updateTeamMemberBillableCost = (userId, billableCost) => {
    tracking('Update Team Member Billable Cost', { accountId: getAccountId(getState()), userId, billableCost });

    db.set(`/accounts/${getAccountId(getState())}/users/${userId}/cost/billableCost`, {
        changedWhen: Date.now().valueOf(),
        changedBy: getAppData()?.user?.data?.userId,
        value: parseFloat(billableCost),
    });
};


export const updateTaskCostBudgetSettingsValue = (taskId, value) => {
    tracking('Update Task Cost Budget Settings Value', {
        accountId: getAccountId(getState()),
        taskId: taskId,
        value,
    });

    db.set(`/accounts/${getAccountId(getState())}/tasks/${taskId}/costBudgetSettings/value`, parseFloat(value));
};

export const updateTaskCostBudgetSettingsType = (taskId, type) => {
    tracking('Update Task Cost Budget Settings Type', {
        accountId: getAccountId(getState()),
        taskId: taskId,
        type,
    });

    db.set(`/accounts/${getAccountId(getState())}/tasks/${taskId}/costBudgetSettings/type`, type);
};

export const updateTaskCostBudgetSettingsPeriodicity = (taskId, value) => {
    tracking('Update Task Cost Budget Settings Periodicity', {
        accountId: getAccountId(getState()),
        taskId: taskId,
        value,
    });

    db.set(`/accounts/${getAccountId(getState())}/tasks/${taskId}/costBudgetSettings/periodicity`, value);
};

export const updateTaskCostBudgetSettings = ({ taskId, type, value, periodicity }) => {
    tracking('Update Task Cost Budget Settings', {
        accountId: getAccountId(getState()),
        taskId,
        type,
        value,
        periodicity,
    });

    updateTaskCostBudgetSettingsValue(taskId, value);
    updateTaskCostBudgetSettingsPeriodicity(taskId, periodicity);
    updateTaskCostBudgetSettingsType(taskId, type);
};