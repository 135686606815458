import ReactDOMServer from 'react-dom/server';
import React, { useState } from 'react';
import classnames from 'clsx';
import dompurify from 'dompurify';
import linkifyHtml from 'linkifyjs/html';
import { Twemoji } from 'react-emoji-render';
import Tooltip from 'rc-tooltip';
import TaskCommentInput from '../TaskCommentInput/TaskCommentInput';
import CommentFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const sanitizer = dompurify.sanitize;

const TaskDetailComment = ({
    id,
    taskId,
    showUserName,
    comment,
    mine,
    userAt,
    deleteAction
})=>{
    const [showEdit, setShowEdit] = useState(false);

    const parseComment = (value) => {
        return sanitizer(
            value
                .replace(/\n/gi, '<br/>')
                .replace(/\r/gi, '<br/>')
                .replace(/@\[((?:[^\]]*))\]\(((?:[^)]*))\)/gi, '<span class="mention">@$1</span>')
                .replace(/\*\*((?:[^*]*))\*\*/gi, '<b>$1</b>')
                .replace(/\*((?:[^*]*))\*/gi, '<em>$1</em>')
                .replace(/´((?:[^*]*))´/gi, '<code>$1</code>'),
        );
    };

    if (comment.type === 'file') {
        if (
            comment.contentType &&
            (comment.contentType === 'image/jpeg' ||
                comment.contentType === 'image/png' ||
                comment.contentType === 'image/gif')
        ) {
            return (
                <div className={classnames('comment', { mine: mine })}>
                    {showUserName && userAt}
                    <p>
                        <a target="_blank" href={comment.url}>
                            <img src={comment.url} title={comment.comment} alt={comment.comment} />
                        </a>
                    </p>
                    {mine ? (
                        <div className="actions">
                            <Tooltip overlay="Delete comment" placement="bottom">
                                <DeleteIcon className="deleteComment" onClick={deleteAction} />
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
            );
        } else {
            return (
                <div className={classnames('comment', { mine: mine })}>
                    {showUserName && userAt}
                    <p className="download">
                        <CommentFileIcon /> {comment.comment}{' '}
                        <a target="_blank" href={comment.url}>
                            Download
                        </a>
                    </p>
                    {mine ? (
                        <div className="actions">
                            <Tooltip overlay="Delete comment" placement="bottom">
                                <DeleteIcon className="deleteComment" onClick={deleteAction} />
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
            );
        }
    } else {
        return (
            <div className={classnames('comment', { mine: mine })}>
                {showUserName && userAt}
                {!showEdit ? (
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: linkifyHtml(
                                    parseComment(ReactDOMServer.renderToString(<Twemoji svg text={comment.comment} />)),
                                ),
                            }}
                        ></span>
                    </p>
                ) : (
                    <TaskCommentInput
                        taskId={taskId}
                        commentId={id}
                        comment={comment.comment}
                        onCancel={() => setShowEdit(false)}
                    />
                )}
                {mine && !showEdit ? (
                    <div className="actions">
                        <Tooltip overlay="Edit comment" placement="bottom">
                            <EditIcon className="editComment" onClick={() => setShowEdit(true)} />
                        </Tooltip>
                        <Tooltip overlay="Delete comment" placement="bottom">
                            <DeleteIcon className="deleteComment" onClick={deleteAction} />
                        </Tooltip>
                    </div>
                ) : null}
            </div>
        );
    }
};

TaskDetailComment.defaultProps = {
    showUserName: true,
};

export default TaskDetailComment;
