import React from 'react';
import { useSelector } from 'react-redux';
import { getAccountCustomFields } from '../../utils/selectors/account';
import TaskCustomFieldLine from './TaskCustomFieldLine';

const TaskCustomFields = ({taskId}) => {
    const fieldsList = useSelector(getAccountCustomFields);
    if(!fieldsList) {
        return null;
    }


    return (
        <>
            {Object.keys(fieldsList)
                .sort((a,b)=>{
                    return (fieldsList[a].name.toUpperCase() < fieldsList[b].name.toUpperCase()) ? -1 : 1;
                })
                .map(fieldId => <TaskCustomFieldLine key={fieldId} fieldId={fieldId} field={fieldsList[fieldId]} taskId={taskId} />)}
        </>
    );
};

export default TaskCustomFields;