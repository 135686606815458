import React, { memo } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import LineItemByStep from './LineItemByStep';
import { childrenMatchSearchValue, isAlreadySynchronized, matchesSearchValue, parentsMatchSearchValue } from '../../utils';

const ProjectsTreeViewByStep = ({
    orderedProjectList = [], 
    currentList = [], 
    parentIds = [], 
    handleCheckItem, 
    handleCloseParent, 
    handleToggleImportSubtasks, 
    handleExpandTreeLevel,
    checkedItems = [], 
    step = 1, 
    parent = null, 
    searchValue = '', 
    syncList = [],
    syncAppId,
    prevSyncList = [],
    treeStep = '',
    treeDataLoadInfo = null,
    blockExpandMore = false,
    toolName = '',
}) => {

    return <>
        {currentList.map((item, index) => {
            const {id, name, type, childs, parentId, nestedChildrenIds, parentsBefore} = item;
            
            const childIds = (childs?.map(el=>el.id) || []);
            const isImportingSubtasks = syncList.find(el=>el.selectedItem===id)?.importSubtasks || false;
            const isSearching = searchValue.trim() !== '';
            const nestedChildrenNames = _.uniq(nestedChildrenIds.map(childId=>(orderedProjectList.find(el=>el.id===childId)?.name)));
            const parentsBeforeNames = parentsBefore.map(parentId=>orderedProjectList.find(el=>el.id===parentId)?.name); 

            const hasNoSubItemChecked = !checkedItems.includes(id) && !nestedChildrenIds.some(id=>checkedItems.includes(id));
            const alreadySynchronized = isAlreadySynchronized({id, childIds, prevSyncList, orderedProjectList, nestedChildrenIds});

            const isLineHidden = (step === 2 && (hasNoSubItemChecked || alreadySynchronized)) || (isSearching && !(
                matchesSearchValue(name, searchValue) || 
                childrenMatchSearchValue(nestedChildrenNames, searchValue) || 
                parentsMatchSearchValue(parentsBeforeNames, searchValue)
            ));

            return (
                <div key={`${id}-${index}`} className='ProjectsTreeView'>     
                    {!isLineHidden && (
                        <LineItemByStep
                            blockExpandMore={blockExpandMore}
                            treeDataLoadInfo={treeDataLoadInfo}
                            syncAppId={syncAppId}
                            treeStep={treeStep}
                            itemData={item}
                            parent={parent}
                            itemId={id}
                            handleCheckItem={handleCheckItem}
                            name={name}
                            parentIds={_.uniq([...parentIds, parentId])}
                            checkedItems={checkedItems}
                            handleCloseParent={handleCloseParent}
                            handleToggleImportSubtasks={handleToggleImportSubtasks}
                            handleExpandTreeLevel={handleExpandTreeLevel}
                            type={type}
                            orderedProjectList={orderedProjectList}
                            childIds={childIds}
                            key={id}
                            step={step}
                            nestedChildrenIds={nestedChildrenIds}
                            isImportingSubtasks={isImportingSubtasks}
                            syncList={syncList}
                            toolName={toolName}
                        />)
                    }

                    {!_.isEmpty(childs) && 
                        <ProjectsTreeViewByStep
                            blockExpandMore={blockExpandMore}
                            treeDataLoadInfo={treeDataLoadInfo}
                            syncAppId={syncAppId}
                            currentList={childs}
                            parent={{...item, childIds: childIds}}
                            parentIds={_.uniq([...parentIds, parentId, id])} 
                            handleCheckItem={handleCheckItem} 
                            handleToggleImportSubtasks={handleToggleImportSubtasks}
                            handleExpandTreeLevel={handleExpandTreeLevel}
                            checkedItems={checkedItems}
                            handleCloseParent={handleCloseParent}
                            orderedProjectList={orderedProjectList}
                            type={type}
                            step={step}
                            searchValue={searchValue}
                            syncList={syncList}
                            prevSyncList={prevSyncList}
                            treeStep={treeStep}
                            toolName={toolName}
                        />}
                </div>
            );
        })}
    </>;
};

ProjectsTreeViewByStep.Proptypes = {
    handleSelecItem: PropTypes.func.isRequired,
    handleCloseParent: PropTypes.func,
    handleToggleImportSubtasks: PropTypes.func,
    handleExpandTreeLevel: PropTypes.func,
};

export default memo(ProjectsTreeViewByStep);