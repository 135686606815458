/* eslint-disable no-param-reassign */
import _ from 'underscore';
// MEMBER TYPES
export var MEMBER_TYPES = {
    userOnly: 'userOnly',
    userAndResource: 'userAndResource',
    resourceOnly: 'resourceOnly',
};
export var memberResourcersTypes = [MEMBER_TYPES.resourceOnly, MEMBER_TYPES.userAndResource];
export var memberUsersTypes = [MEMBER_TYPES.userOnly, MEMBER_TYPES.userAndResource];
export var getOnlyResourceUsers = function (accountUsers) {
    if (!_.isEmpty(accountUsers)) {
        return Object.values(accountUsers).reduce(function (resources, member) {
            if (memberResourcersTypes.includes(member.memberType)) {
                resources[member.userId] = member;
            }
            return resources;
        }, {});
    }
    return {};
};
export var getOnlyUsers = function (accountUsers) {
    if (!_.isEmpty(accountUsers)) {
        return Object.values(accountUsers).reduce(function (users, member) {
            if (memberUsersTypes.includes(member.memberType)) {
                users[member.userId] = member;
            }
            return users;
        }, {});
    }
    return {};
};
