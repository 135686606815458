import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import Proptypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { addNewTag } from '../../utils/firebaseEvents';
import classnames from 'clsx';

const EditTagModal = ({ onSubmit, isOpen, tagName, onClose }) => {
    const [newTagName, setNewTagName] = useState(tagName);
    const [newTagColor, setNewTagColor] = useState(1);
    const intl = useIntl();

    
    useEffect(() => {
        setNewTagName(tagName);
    }, [isOpen, tagName]);

    const handleTagNameInput = React.useCallback(({ target: { value } }) => {
        setNewTagName(value);
    }, []);

    
    const handleSave = () => {
        const newTagKey = addNewTag(newTagName, newTagColor);

        onSubmit(newTagKey);
    };

    return <Dialog maxWidth={'md'} className="EditTagModal" onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <MuiDialogTitle className="title" disableTypography >
            <Typography variant="h6"> {intl.formatMessage({ id: 'Edit Tag' })}</Typography>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers>
            <FormControl fullWidth className="tag-name-container" component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({ id: 'Tag name' })} </FormLabel>
                <Input
                    value={newTagName}
                    onChange={handleTagNameInput}
                    id="tagName"
                    name="tagName"
                    label={intl.formatMessage({ id: 'Tag name' })}
                    type="text"
                />
            </FormControl>

            <div className="colors">
                <div
                    onClick={() => setNewTagColor(1)}
                    className={classnames('color1', { active: newTagColor === 1 })}
                />
                <div
                    onClick={() => setNewTagColor(2)}
                    className={classnames('color2', { active: newTagColor === 2 })}
                />
                <div
                    onClick={() => setNewTagColor(3)}
                    className={classnames('color3', { active: newTagColor === 3 })}
                />
                <div
                    onClick={() => setNewTagColor(4)}
                    className={classnames('color4', { active: newTagColor === 4 })}
                />
                <div
                    onClick={() => setNewTagColor(5)}
                    className={classnames('color5', { active: newTagColor === 5 })}
                />
                <div
                    onClick={() => setNewTagColor(6)}
                    className={classnames('color6', { active: newTagColor === 6 })}
                />
                <div
                    onClick={() => setNewTagColor(7)}
                    className={classnames('color7', { active: newTagColor === 7 })}
                />
                <div
                    onClick={() => setNewTagColor(8)}
                    className={classnames('color8', { active: newTagColor === 8 })}
                />
                <div
                    onClick={() => setNewTagColor(9)}
                    className={classnames('color9', { active: newTagColor === 9 })}
                />
                <div
                    onClick={() => setNewTagColor(10)}
                    className={classnames('color10', { active: newTagColor === 10 })}
                />
            </div>
            
        </MuiDialogContent>
        <MuiDialogActions className='actions'>
            <Button
                className="cancel-btn"
                onClick={onClose}
                variant="contained"
            >
                {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
            <Button
                disabled={!newTagName || newTagName.trim() === ''}
                className="submit-btn"
                variant="contained"
                color="primary"
                onClick={handleSave}
            >
                {intl.formatMessage({ id: 'Save Changes' })}
            </Button>
        </MuiDialogActions>
    </Dialog >;
};


EditTagModal.propTypes = {
    isOpen: Proptypes.bool.isRequired,
};

export default EditTagModal;