import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';

const SelectProjectActionButtons = ({ 
    step, 
    handleNextStep, 
    handleSubmit, 
    onClose, 
    nextDisabled = false, 
    handleSetFirstStep
}) => {
    const intl = useIntl();

    return (
        <>
            {
                step===1 ? (
                    <>
                        <Button
                            className="cancel-btn"
                            onClick={onClose}
                            variant="contained"
                        >
                            {intl.formatMessage({ id: 'CANCEL' })}
                        </Button>
                        <Button
                            id="next-btn"
                            variant="contained"
                            color="primary"
                            disabled={nextDisabled} 
                            onClick={handleNextStep}
                        >
                            {intl.formatMessage({ id: 'Next' })}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            id="back-button"
                            onClick={handleSetFirstStep}
                            variant="contained"
                        >
                            {intl.formatMessage({ id: 'BACK' })}
                        </Button>
                        <Button
                            className="submit-btn"
                            variant="contained"
                            color="primary"
                            disabled={nextDisabled} 
                            onClick={handleSubmit}
                        >
                            {intl.formatMessage({ id: 'Syncronize' })}
                        </Button>
                    </>
                ) 
            }
        </>
    );
};

export default SelectProjectActionButtons;