import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardContent, Typography } from '@material-ui/core';
import { GUIDE_INTEGRATIONS_LINK_BY_TOOL, SYNC_APP_NAMES, GUIDE_INTEGRATIONS_BASE_LINK} from '../../utils';

const AcademyBanner = ({ toolName }) => {
    const intl = useIntl();

    return (
        <Card elevation={0} className={'SyncSettings__academy'}>
            <img src="https://planless.sirv.com/App/integrations/icons/bookIcon.png"/>
            <CardContent>
                <Typography variant='subtitle2'>{intl.formatMessage({id: 'integrations.tool.settings.academy.1'}, {toolName: SYNC_APP_NAMES[toolName]})}</Typography>
                <Typography variant='subtitle2'>
                    <a 
                        target='_blank'
                        href={`${GUIDE_INTEGRATIONS_BASE_LINK}${GUIDE_INTEGRATIONS_LINK_BY_TOOL[toolName]}`}
                    >
                        {intl.formatMessage({id: 'integrations.tool.settings.academy.link'})}
                    </a> 
                    {' '} {intl.formatMessage({id: 'integrations.tool.settings.academy.2'})}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AcademyBanner;