var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import { IsEnterKey } from '../utils';
var useConfirmToastrEnterKey = function (_a) {
    var _b = _a.disableConfirm, disableConfirm = _b === void 0 ? false : _b, _c = _a.dependencies, dependencies = _c === void 0 ? [] : _c;
    var handleConfirmEnterKey = function (e) {
        if (!IsEnterKey(e))
            return;
        var okButton = document.querySelector('.rrt-button.rrt-ok-btn.toastr-control');
        if (okButton) {
            okButton.click();
        }
    };
    useEffect(function () {
        if (!disableConfirm) {
            document.addEventListener('keyup', handleConfirmEnterKey);
        }
        return function () {
            if (!disableConfirm) {
                document.removeEventListener('keyup', handleConfirmEnterKey);
            }
        };
    }, __spreadArray([handleConfirmEnterKey], __read(dependencies), false));
};
export default useConfirmToastrEnterKey;
