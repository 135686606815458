import _ from 'underscore';
import { marks } from '../../../shared/constants';
export function getInitialSliderMark(users) {
    if (users > 250) {
        return marks[marks.length - 1];
    }
    var mark = marks.find(function (_a) {
        var value = _a.value, label = _a.label;
        var resource = Number(label);
        var isGreaterThanUsers = resource >= users;
        var isLessThanUsers = _.isNumber(marks[value]) ? users <= Number(marks[value].label) : true;
        return isGreaterThanUsers && isLessThanUsers;
    });
    return mark || marks[0];
}
