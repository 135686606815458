import { Chip, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'underscore';
import React from 'react';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getOnlyResourceUsers } from '../../../utils/memberTypes';
import { addChallenge, updateWorkloadPermissions } from '../../../utils/firebaseEvents';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getAccountUserPermissionsWorkload, getAccountUsers } from '../../../utils/selectors/account';
import StateUserAvatar from '../../../components/UserAvatar/StateUserAvatar';

const Permissions = ({ memberId }) => {
    const intl = useIntl();
    const accountUsers = useSelector(getAccountUsers);
    const users = getOnlyResourceUsers(accountUsers);
    const userWorkloadPermissions = useSelector(getAccountUserPermissionsWorkload(memberId));

    const workloadUsersOptions = Object.values(users).reduce((acc, user) => {
        if(!userWorkloadPermissions.includes(user.userId)) {
            acc.push({ id: user.userId, name: user.displayName });
        }
        return acc;
    }, [])
        .sort((a, b) => a.name > b.name ? 1 : - 1)
        .map((user) => user.id);


    const changeWorkloadUsers = (e, newWorkloadUsers, reason) => {
        newWorkloadUsers = _.filter(newWorkloadUsers, (userId) => userId);
        
        if(reason === 'clear') {
            newWorkloadUsers = [memberId];
        }

        if(reason === 'remove-option') {
            newWorkloadUsers = _.uniq([...newWorkloadUsers, memberId]);
        }

        addChallenge('changeMemberWorkloadPermissions');
        updateWorkloadPermissions(memberId, newWorkloadUsers);
    };

    return <div className='permissions'>
        <p>{intl.formatMessage({id:'employee.workloadPermissions.info.tooltip'})}</p>
        <p>{intl.formatMessage({id:'employee.workloadPermissions.info.tooltip2'})}</p>

        <Autocomplete
            className="workloadAutocomplete"
            multiple
            filterSelectedOptions={true}
            options={workloadUsersOptions}
            value={userWorkloadPermissions}
            getOptionLabel={(option) => {
                if(users[option]) {
                    return (
                        <div 
                            className="optionUser"
                        >
                            <StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>
                            <Typography variant='subtitle2'>
                                {(users[option]) ? users[option].displayName : ''}
                            </Typography>
                        </div>
                    );
                }
            }}
            renderInput={params => (
                <TextField {...params} variant="outlined" label={intl.formatMessage({id:'Has access to:'})} fullWidth />
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                    var props = getTagProps({ index });
                    if (option === memberId) {
                        props.onDelete = null;
                    }
                    props.className = 'workloadChip';
                    if (users[option] && !users[option].viewer) {
                        return (
                            <Chip
                                size='small'
                                avatar={<StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>}
                                variant="outlined"
                                label={users[option] && users[option]?.displayName ? users[option].displayName : ''}
                                {...props}
                            />
                        );
                    }
                });
            }
            }
            filterOptions={(options, { inputValue }) => {
                const results = options.reduce((acc, option) => {
                    if (users[option]?.displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1) {
                        acc.push(option);
                    }
                    return acc;
                }, []);
                return results;
            }}
            onChange={changeWorkloadUsers}
        />
    </div>;
};

export default withRouter(withCustomErrorBoundary(Permissions));