import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import classnames from 'clsx';
import TasksActionBar from '../TasksActionBar/TasksActionBar';
import { getLimitViewTo, getShowFilters } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const StructTasks = ({children})=>{
    const limitViewTo = useSelector(getLimitViewTo);
    const showFilters = useSelector(getShowFilters);

    return (
        <div className='StructTasks'>
            <TasksActionBar />
            
            <Card 
                className={classnames('StructTasksContent', {
                    LimitedViewOpen: !!limitViewTo, 
                    filterOpen: !!showFilters,
                    limitViewAndfilterOpen: !!limitViewTo && !!showFilters
                })} 
                square={true} 
                elevation={0}
            >
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </div>
    );

};

export default withCustomErrorBoundary(StructTasks);