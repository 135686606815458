import React, { useState } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import _ from 'underscore';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getAccountTags } from '../../utils/selectors/account';
import { useSelector } from 'react-redux';
import AddTagDialog from './AddTagDialog';

const filter = createFilterOptions();


const MultiTagsDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [multiTags, setMultiTags] = useState([]);
    const [textValue, setTextValue] = useState('');
    const [addDialogOpen, setDialogOpen] = useState(false);
    const tags = useSelector(getAccountTags);

    const getTags = (defaultValues) =>  {
        var results = [];
        if (tags) {
            for (var i in tags) {
                var has = false;
                for (var j in defaultValues) {
                    if (!has && i === defaultValues[j].id) {
                        has = true;
                    }
                }
                if (!has) {
                    results.push({
                        id: i,
                        title: tags[i].title,
                        color: tags[i].color,
                    });
                }
            }
        }
        results = _.sortBy(results, 'title');
        return results;
    };

    const handleConfirm =() => {
        firebaseEvents.multiTagsChange(multiTags, selectedItems).then(() => {
            setSelectedLines();
            setMultiTags([]); 
        });
        showMultiDetails();
    };

    const handleNo = () => {
        showMultiDetails();
    };

    const handleChangeMultiTags = (event, values) => {
        if (values && values.length && values[values.length - 1].inputValue) {
            setDialogOpen(true);
            setTextValue(values[values.length - 1].inputValue);
        } else {
            setMultiTags(values);
        }
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    const handleTagCreated = (newTagKey) => {
        setMultiTags([...multiTags, newTagKey]);
        setDialogOpen(false);
    };


    return   <div id="multiTagsDetails">
        <label>Set tasks tags to</label>
        <Autocomplete
            size="small"
            multiple
            id="tagsSelect"
            options={getTags(multiTags)}
            getOptionLabel={option => option.title}
            renderOption={option => {
                return <span className="tagSelectItem">{option.title}</span>;
            }}
            value={multiTags}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    var title = option.title;
                    var props = getTagProps({ index });
                    props.className += ' color' + option.color;

                    return <Chip label={title} {...props} />;
                })
            }
            renderInput={params => <TextField {...params} fullWidth className="tagsInput" />}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: 'Create tag "' + params.inputValue + '"',
                    });
                }

                return filtered;
            }}
            disableClearable
            onChange={handleChangeMultiTags}
        />
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
            Cancel
        </Button>
        {addDialogOpen  && <AddTagDialog 
            handleCancel={handleCancel}
            handleTagCreated={handleTagCreated}
            tagName={textValue}
        />
        }
    </div>;
};

export default MultiTagsDetails;