import React from 'react';
import { unfollowTask } from '../../utils/controllers/tasks/followers';
import { MenuItem } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const FollowerLine = ({ displayName, userId, taskId, index }) => {
    const onUnfollow = () => {
        unfollowTask(taskId, userId);
    };

    return (
        <MenuItem key={`${userId}-${index}`} className="ManageTaskFollowers__Menu__item">
            <StateUserAvatar
                className="Menu__item__avatar"
                userId={userId}
            />
            <span className="Menu__item__name">{displayName}</span>
            <DeleteIcon className="Menu__item__deleteIcon" onClick={onUnfollow} />
        </MenuItem>
    );
};

export default FollowerLine;
