import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { browserHistory } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import CreateNewMemberModal from '../CreateNewMemberModal/CreateNewMemberModal';
import MembersTree from './MembersTree';
import Button from '@material-ui/core/Button';

const TeamMembers = ({children}) => {
    const intl = useIntl();

    const [newMemberModalOpen, setNewMemberModalOpen] = useState(false);
    const [groupId, setGroupId] = useState(null);

    const handleOpenNewMemberModal = React.useCallback(() => setNewMemberModalOpen(true), []);
    const handleCloseNewMemberModal = React.useCallback(() => setNewMemberModalOpen(false), []);

    const handleAddNewGroup = React.useCallback(() => firebaseEvents.createNewUserGroup(), []);
    const handleAddNewMember = React.useCallback((groupId) => {
        setGroupId(groupId);
        handleOpenNewMemberModal();
        firebaseEvents.addChallenge('addMember');
    }, []);

    const handleSubmit = React.useCallback((userId) => {
        browserHistory.push(`/settings/team-members/${userId}`);
        handleCloseNewMemberModal();
    }, [handleCloseNewMemberModal]);

    return (
        <div className="TeamMembers">
            <h1>{intl.formatMessage({id:'Members'})}</h1>
            
            <div data-sidebar='preventClose'>
                <Button variant="contained" className="topBtn" color="primary" onClick={handleOpenNewMemberModal} data-sidebar='preventClose'>
                    {intl.formatMessage({id:'Add new member'})}
                </Button>
                <Button variant="contained" className="topBtn" color="primary" onClick={handleAddNewGroup} data-sidebar='preventClose'>
                    {intl.formatMessage({id:'Add new group'})}
                </Button>
            </div>

            <MembersTree handleAddNewMember={handleAddNewMember}/>

            <CreateNewMemberModal
                displayName="New Team Member"
                isOpen={newMemberModalOpen}
                onClose={handleCloseNewMemberModal}
                groupId={groupId}
                onSubmit={handleSubmit}
            />
            
            {children}
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(TeamMembers));