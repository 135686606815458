import React from 'react';
import { Button, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { Add, Info } from '@material-ui/icons';
import _ from 'underscore';
import { createStoryPoint } from '../../../../../utils/controllers/syncApp/jira';
import { withCustomErrorBoundary } from '../../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../../../../utils/hooks/useInternalization';
import StoryPointLine from './StoryPointLine';
import Tooltip from 'rc-tooltip';
import { segmentTrack } from '../../../../../utils/firebaseEvents';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../utils/selectors/user';

const StoryPointsEstimate = ({ optionSelected, storyPoints, effortChangeType, syncAppId, handleSelectOption }) => {
    const { t } = useInternalization();
    const currentUserId = useSelector(getUserId);
    const isChecked = optionSelected === 1; 

    const handleCreateStoryPoint = React.useCallback(async() => {
        await createStoryPoint({
            syncAppId,
            storyPoints,
        });

        segmentTrack('addStoryPoint', { userId: currentUserId });
    }, [storyPoints, syncAppId]);

    return (
        <div className="StoryPointsEstimate">
            <div className="StoryPointsEstimate__top">
                <FormControlLabel
                    checked={isChecked}
                    value={1}
                    onChange={handleSelectOption}
                    control={<Radio size="small" />} 
                    label={t('Story points estimate')} 
                >
            
                </FormControlLabel>

                <Tooltip
                    className={'StoryPointsEstimate__info'}
                    placement="top" 
                    overlay={t('You must add the conversion from Story Point to Effort Range (h) so the tasks properly sync')}
                >
                    <Info />
                </Tooltip>
            </div>

            {isChecked && (
                <>

                    <div className="StoryPointsEstimate__header">
                        <Typography variant="subtitle2">{t('STORY POINT')}</Typography>
                        <Typography variant="subtitle2">{t('EFFORT RANGE (h)')}</Typography>
                    </div>

                    {storyPoints && _.map(storyPoints, (storyPoint, spId, allStoryPoints) => {
                        return (
                            <StoryPointLine 
                                key={spId}
                                spId={spId}
                                effortChangeType={effortChangeType}
                                storyPoint={storyPoint}
                                syncAppId={syncAppId}
                                allStoryPoints={allStoryPoints}
                            />
                        );
                    })}

                    <Button 
                        className="storyPoint__new" 
                        onClick={handleCreateStoryPoint} 
                        startIcon={<Add />}
                    >
                        {t('Add New')}
                    </Button>

                </>
            )}
        </div>
    );
};

export default withCustomErrorBoundary(StoryPointsEstimate);