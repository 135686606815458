import React from 'react';
import { Typography } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import EffortType from './EffortType';
import useInternalization from '../../../../utils/hooks/useInternalization';

const SettingsEffort = ({ syncAppId, toolName }) => {
    const { t } = useInternalization();
    const effortChangeTypes = ['onAddProject', 'onToolUpdate'];

    return (
        <div className='SyncSettings__effort'>
            {syncAppId && <>
                <Typography id='stonly-syncSettings-effort' variant='subtitle1'>{t('Effort')}</Typography>

                <div className='SyncSettings__effort__cards'>
                    {effortChangeTypes.map((type, index) => {
                        // On the 15/06/2022 we started to restrict to only one/global effort change type.
                        // This means that we don't have anymore the 'When adding a project...', 'When updated on...',
                        // For the sake of not losing the logic that could be reverted in the future, we will just show 1 type of change effort.
                        // The changes will be stored on the db both to ...effortSettings/import and to ...effortSettings/onUpdate to prevent further issues.
                        if(index === 0) return;

                        return (
                            <EffortType
                                key={`${type}-${index}`}
                                type={type}
                                syncAppId={syncAppId}
                                toolName={toolName}
                            />
                        );
                    })}
                </div>
            </>}
        </div>
    );
};

export default withCustomErrorBoundary(SettingsEffort);