import React, { useState, useCallback, useEffect } from 'react';
import _ from 'underscore';
import Sidebar from 'react-sidebar';
import Handle from '@material-ui/icons/Code';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const onmousemove =  (e) => {
    var width = window.innerWidth - e.clientX;
    if (width < 620) { width = 620; }
    else if (width > (window.innerWidth - 20)) { width = window.innerWidth - 20; }

    const sidebar = document.querySelectorAll('.sidebar');
    const handle = document.querySelectorAll('.handle');
    const sidebarCloseBtn = document.querySelectorAll('.sidebarCloseBtn');

    if(_.size(sidebar) && _.size(handle) && _.size(sidebarCloseBtn)) {
        sidebar[0].style.width = `${width}px`;
        handle[0].style.right = `${width-5}px`;
        sidebarCloseBtn[0].style.right = `${width-5}px`;
    }
};

const touchMove =  (e) => {
    var width = window.innerWidth - e.targetTouches[0].clientX;
    if (width < 620) { width = 620; }
    else if (width > (window.innerWidth - 20)) { width = window.innerWidth - 20; }
    
    const sidebar = document.querySelectorAll('.sidebar');
    const handle = document.querySelectorAll('.handle');
    const sidebarCloseBtn = document.querySelectorAll('.sidebarCloseBtn');

    if(_.size(sidebar) && _.size(handle) && _.size(sidebarCloseBtn)) {
        sidebar[0].style.width = `${width}px`;
        handle[0].style.right = `${width-5}px`;
        sidebarCloseBtn[0].style.right = `${width-5}px`;
    }
};

const CustomSideBar =  ({ children, onClose, className }) => {
    const intl = useIntl();
    const [sidebarOpen, setSidebarOpen] = useState(false); 

    useEffect(() => {
        setSidebarOpen(true);
        if(localStorage.getItem('taskSidebarWidth')){
            let width = parseInt(localStorage.getItem('taskSidebarWidth'));
            if(width < 620){ width = 620; }
            else if(width > (window.innerWidth-20)){ width = window.innerWidth-20; }
            
            document.querySelectorAll('.sidebar')[0].style.width = `${width}px`;
            document.querySelectorAll('.handle')[0].style.right = `${width-5}px`;
            document.querySelectorAll('.sidebarCloseBtn')[0].style.right = `${width-5}px`;

            localStorage.setItem('taskSidebarWidth', width);
        }

        if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 767) {
            const firstStepsHeader = document.querySelectorAll('.FirstStepsHeader');
            if(firstStepsHeader.length){
                firstStepsHeader[0].style.opacity = 0;
            }
        }
        
        document.addEventListener('click', handleSidebarClickAway);
        document.addEventListener('keyup', keyboardShortcuts);

        return () => {
            document.removeEventListener('click', handleSidebarClickAway);
            document.removeEventListener('keyup', keyboardShortcuts);
            const firstStepsHeader = document.querySelectorAll('.FirstStepsHeader');

            if(firstStepsHeader.length){
                firstStepsHeader[0].style.opacity = 1;
            }
        };
    }, []);

    const handleSidebarClickAway = (e) => {
        const pageHasDialogOpen = document.querySelectorAll('.MuiDialog-root, .MuiBackdrop-root, MuiPopover-root').length;
        const pageHasToastrConfirmOpen = document.querySelectorAll('.rrt-confirm-holder').length;
        const sidebar = document.querySelector('.sidebar');
        const sidebarLeftEdge = sidebar.getBoundingClientRect().x;
        const eventClickOverSidebar = e.clientX > sidebarLeftEdge;
        const parentTargetWithDataSidebar = e.target.closest('[data-sidebar]');
        const targetWithDataSidebar = e.target.getAttribute('data-sidebar');
        const targetWithDashboardTask = e.target.closest('.Dashboard') && e.target.closest('.taskCard');
        const targetWithDashboardDueTask = e.target.closest('.Dashboard') && e.target.closest('.dueTask');

        if(!sidebar) return;

        if(
            !targetWithDataSidebar &&
            !parentTargetWithDataSidebar && 
            !eventClickOverSidebar && 
            !pageHasDialogOpen && 
            !pageHasToastrConfirmOpen &&
            !targetWithDashboardDueTask &&
            !targetWithDashboardTask
        ) {
            close();
        }
    };

    const stopDrag = () => {
        localStorage.setItem('taskSidebarWidth', parseInt(document.querySelectorAll('.sidebarCloseBtn')[0].style.width));

        window.onmousemove = null;  
        document.body.removeEventListener('touchmove', touchMove);  
    };

    const onSetSidebarOpen = () => {
        setSidebarOpen(false);
        close();
    };

    const startDrag = () => {
        window.onmousemove = onmousemove;  
        document.body.addEventListener('touchmove', touchMove);  
    };

    const close = () => {
        const sidebar = document.querySelectorAll('.sidebar')[0];
        const sidebarCloseBtn = document.querySelectorAll('.sidebarCloseBtn')[0];

        sidebar.classList.remove('slideInRight');
        sidebar.classList.remove('animated');
        sidebar.classList.add('slideOutRight');
        sidebar.classList.add('animated');

        sidebarCloseBtn.classList.remove('fadeInRightBig');
        sidebarCloseBtn.classList.remove('animated');
        sidebarCloseBtn.classList.add('animated');
        sidebarCloseBtn.classList.add('fadeOutRightBig');

        document.removeEventListener('keyup', keyboardShortcuts);
        
        onClose && onClose();
    };

    const keyboardShortcuts = useCallback((e) => {
        if(e.keyCode === 27){
            close();
        }
    }, [close]);

    return <div className={className} onMouseUp={stopDrag} onTouchEnd={stopDrag}>
        <Sidebar sidebar={children}
            open={sidebarOpen}
            pullRight={true}
            rootClassName="sidebarContainer"
            sidebarClassName="sidebar animated slideInRight faster"
            overlayClassName="overlay"
            onSetOpen={onSetSidebarOpen}>
        
            <Handle className="handle" onMouseDown={startDrag} data-sidebar='preventClose'/>                    
        </Sidebar>
        <div className="sidebarCloseBtn animated fadeInRightBig faster" onMouseDown={close}><ChevronRight /><ChevronRight /><div>Close <span>esc</span></div></div>
        <Button className="backBtnSidebar" onClick={close}><ChevronLeft /> {intl.formatMessage(({id: 'BACK'}))}</Button>
    </div>;
};

export default withCustomErrorBoundary(CustomSideBar);