import { Button } from '@material-ui/core';
import React from 'react';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { handleStopPropagation } from '../../../../utils/utils';

const StoryPointLine = ({ storyPoint, isCurrentSp, handleSelectStoryPoint }) => {
    const { t } = useInternalization();
    const { spvalue, effort } = storyPoint;

    const handleSelect = (e) => {
        handleStopPropagation(e);
        handleSelectStoryPoint(storyPoint);
    };

    return (
        <div className="SelectEffort__StoryPointLine__outerLine" onClick={handleSelect}>
            <div className="SelectEffort__StoryPointLine__innerLine">

                <div className="StoryPoint__value">
                    <span>{spvalue}</span>
                </div>

                <div className="effort__value">
                    <div className="effort__value__spans">
                        <span>{effort.min}</span>
                        <small>-</small>
                        <span>{effort.max}</span>
                    </div>
                </div>

                {isCurrentSp ? (
                    <Button
                        className="current__storyPoint"
                        variant="contained"
                        color="primary"
                    >
                        {t('select.effort.storypointline.current')} 
                    </Button>
                ) : (
                    <Button
                        className="select__storyPoint"
                        variant="contained"
                        color="primary"
                    >
                        {t('select.effort.storypointline.select')} 
                    </Button>
                )}
            </div>
        </div>
    );
};

export default withCustomErrorBoundary(StoryPointLine);