import Paper from '@material-ui/core/Paper';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import { downloadFile, getColor } from '../utils';
import { getTaskExpenses } from '../../../utils/controllers/tasks/expenses';
import * as ChartJs from 'chart.js';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import html2canvas from 'html2canvas';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { currencyFormat  } from '../../../utils/currencyFormat';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const ExpenseTypeRepartition = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const expensesTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes || {});
    const chartRef = useRef();
    const [chart, setChart] = useState(null);
    const [data, setData] = useState({
        byType: {},
        total: 0,
    });
    const [usedColor, setUsedColor] = useState([]);
    const settingsCurrency = useSelector((state)=> state?.app?.account?.settings.costBudget?.currency || 'USD');  

    useEffect(() => {
        if(!_.isEmpty(expensesTypes)){
        // in the case of beeing root lvl 
        // get all of the sub childs 
            const workspaceMainItens = workspaceSelected === 'root' ? Object.keys(stateTasks) : [..._.get(childsParents, workspaceSelected , []), workspaceSelected];

            const byType = {}; 
            let total = 0;
            // get all of the expenses 
            Promise.all(workspaceMainItens.map(taskId => getTaskExpenses(taskId))).then(el => {
                const allExpenses = el.flat();
                allExpenses.forEach(expense => {
                    if(expense.types) {
                        expense.types.forEach(typeId => {
                            if(byType[typeId]){
                                byType[typeId].total = expense.total; 
                            } else {
                                byType[typeId] = {
                                    value: expense.total, 
                                    name: expensesTypes[typeId].name || 'Deleted Type',
                                    id: typeId, 
                                };
                            }
                        });
                        total += expense.total;
                    }
                });
                setData({
                    byType: byType,
                    total: total,
                });
            });
        }

    }, [childsParents, workspaceSelected, stateTasks, expensesTypes, chart]);
  

    useEffect( () => {
        if(chart){
            const colors = [];
            const chartInfo = Object
                .values(data.byType)
                .sort((a,b) => b.value - a.value)
                .reduce((acc, entry, idx) => {
                    const color = getColor(idx + 1,idx)[0];
    
                    if(idx === 4) 
                    { 
                        acc.labels.push('Others');
                        acc.datasets[0].data.push(entry.value);
                        acc.datasets[0].backgroundColor.push(color);
                        colors.push(color);
    
                        return acc;
                    }
                    if(idx > 4) {
                        colors.push(acc.datasets[0].backgroundColor[4]);
    
                        if(idx ===  Object.values(data.byType).length - 1) {
                            acc.datasets[0].data[4] = `${parseFloat((((acc.datasets[0].data[4] + entry.value) / data.total)  * 100  )).toFixed(2)}`;
                            return acc;
                        }
                        
                        acc.datasets[0].data[4] = acc.datasets[0].data[4] + entry.value;
                        return acc;
                    }
                    
                    acc.labels.push(entry.name);
                    acc.datasets[0].data.push( entry.value > 0 ? `${parseFloat((entry.value / data.total) * 100).toFixed(2)}` : 0);
                    acc.datasets[0].backgroundColor.push(color);
                    colors.push(color);
    
                    return acc;
                }, {
                    labels: [],
                    datasets: [{
                        label: '',
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    }]
                });
            chart.data.datasets =chartInfo.datasets;
            chart.data.labels =chartInfo.labels;
            chart.update();
            setUsedColor(colors);
        }

    }, [chart, data]); 

    useEffect( () => {
        if(!_.isEmpty(data.byType) && !chart) {
            const thisChart = new ChartJs.Chart(chartRef.current.getContext('2d'), {
                type: 'pie',
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        }
                    }
                }
            });
            setChart(thisChart);
        }
    }, [chartRef, data]); 
    
    const downloadImg = () => {
        html2canvas(document.querySelector('#expense-type-repartition-chart'))
            .then(canvas => {
                document.body.appendChild(canvas);
                canvas.style.display = 'none';
                return canvas;
            })
            .then(canvas => {
                const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                const a = document.createElement('a');
                a.setAttribute('download', 'my-image.png');
                a.setAttribute('href', image);
                a.click();
                canvas.remove();
            });
    };

    const exportCSV = useCallback((data, totalDataOfChart, name) => {
        const headers = 'Item; % effort';
        const rows = Object
            .values(data)
            .sort((a,b) => b.value - a.value)
            .map(el => `${el.name};${parseFloat((el.value / totalDataOfChart) * 100).toFixed(2)}%;`);
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${headers}\r\n${rows.join('\r\n')}`), name);
    }, []);
    
    if(_.isEmpty(data.byType)) {
        return <Paper className={'breakdown-page'} >
            <div id={'expense-repartition-chart'} className='chart-list-continer'>
                <div className='breakdown-sub-header'>
                    <h4>Expense Types Repartition</h4>
                </div>
                <div  className='chart-container'>
                    <div className={'empty'}>No expenses found</div>
                </div>
            </div>
        </Paper>;
    }

    return <Paper className={'breakdown-page'} >
        <div id={'expense-type-repartition-chart'} className='chart-list-continer'>
            <div className='breakdown-sub-header'>
                <h4>Expense Types Repartition</h4>
                <div data-html2canvas-ignore>
                    <Button
                        size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />} 
                        onClick={()=> exportCSV(data.byType, data.total, 'Expenses Repartition By Type')}>
                    Save as CSV
                    </Button>
                    <Button
                        size="small" 
                        color="primary"
                        aria-label="save as img" 
                        startIcon={<ImageIcon />}  
                        onClick={()=> downloadImg(chartRef.current)}>
                    Save as Img
                    </Button>
                </div>
            </div>
            <div  className='chart-container'>
                <div>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Chart Reference</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object
                                .values(data.byType)
                                .sort((a,b)=> {
                                    if(a.name === 'Others'){
                                        return 1;
                                    }
                                        
                                    if(b.name === 'Others'){
                                        return -1;
                                    }
                                        
                                    return -(a.value- b.value);
                                }).map((el,idx) => <TableRow key={`row-${idx}`}>
                                    <TableCell align="left">
                                        <div style={{
                                            backgroundColor: usedColor[idx],
                                            height: '1rem',
                                            width: '1rem',
                                            borderRadius: '1rem',
                                            marginRight: '0.5rem',
                                        }} />
                                    </TableCell>
                                    <TableCell align="left">
                                        {el.name}
                                    </TableCell>
                                    <TableCell>
                                        {el.value > 0 ? `${parseFloat((el.value / data.total) * 100).toFixed(2)}%` : `${0}%`}
                                    </TableCell>
                                    <TableCell>
                                        { currencyFormat({ value: el.value, currency: settingsCurrency })}
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                   
                </div>
                <div>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    </Paper>;
};

ExpenseTypeRepartition.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(ExpenseTypeRepartition);
