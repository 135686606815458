/* eslint-disable max-len */
import _ from 'underscore';
import { getState } from '../../../configureStore';
import { db } from '../../firebaseEvents';
import { getAccountId, getAccountTasks } from '../../selectors/account';
var removeMemberFromTasksWorkers = function (userId) {
    var accountId = getAccountId(getState());
    var tasksUserIsWorker = _.filter(getAccountTasks(getState()) || {}, (function (task) { var _a, _b; return !!((_a = task === null || task === void 0 ? void 0 : task.forcedUser) === null || _a === void 0 ? void 0 : _a.includes(userId)) || !!((_b = task === null || task === void 0 ? void 0 : task.userWorking) === null || _b === void 0 ? void 0 : _b.includes(userId)); }));
    if (_.isEmpty(tasksUserIsWorker)) {
        return;
    }
    var updates = tasksUserIsWorker.reduce(function (acc, task) {
        var forcedUsersUpdated = _.filter(task.forcedUser || [], function (id) { return id !== userId; });
        var usersWorkingUpdated = _.filter(task.userWorking || [], function (id) { return id !== userId; });
        acc["/accounts/".concat(accountId, "/tasks/").concat(task.id, "/forcedUser")] = !_.isEmpty(forcedUsersUpdated) ? forcedUsersUpdated : null;
        acc["/accounts/".concat(accountId, "/tasks/").concat(task.id, "/userWorking")] = !_.isEmpty(usersWorkingUpdated) ? usersWorkingUpdated : null;
        return acc;
    }, {});
    db.update(undefined, updates);
};
export default removeMemberFromTasksWorkers;
