import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DialogContentText } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const ResetPassword = () => {
    const intl = useIntl();
    const emailRef = useRef();
    const [emailError, setEmailError] = useState(null);


    const validateEmail = (email) => {
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const reset = (e) => {
        e.preventDefault();

        var email = emailRef.current.children[1].children[0].value;
        if(validateEmail(email)){
            
            firebaseEvents.resetPassword(email, intl, close);

        }
        else {
            setEmailError('Incorrect email');
        }

    };

    const close = () => {
        browserHistory.push('/login');
    };


    return (
        <Dialog className='ResetPassword' open={true} maxWidth={'xs'} onClose={close} >
            <div className='ResetPassword__innerBox'>
                <DialogTitle>{intl.formatMessage({id:'Password reset'})}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reset-password-description">
                    Enter your account's email address to reset your password
                    </DialogContentText>
                    <form onSubmit={reset}>
                        <TextField type="text" className="email" ref={emailRef} fullWidth={true} label={intl.formatMessage({id:'Your email address'})} error={!!(emailError)} helperText={emailError} />

                        <Button className='confirm' variant="contained" type="submit">
                            {intl.formatMessage({id:'Reset password'})}
                        </Button>

                        <Button className='cancel' variant="contained" onClick={close}>
                            {intl.formatMessage({id:'toastr.confirm.cancel'})}
                        </Button>
                    </form>
                </DialogContent>
            </div>
        </Dialog>
    );

};

export default withCustomErrorBoundary(ResetPassword);