import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';

import { getAccountSkills, getAccountSkillsGroups } from '../../../utils/selectors/account';

const getSkillLevel = (skillGroups, group) => {
    if (!group) {
        return 1;
    }

    return getSkillLevel(skillGroups, skillGroups[group]?.parent) + 1;
};

const mapElementsKeys = elements => Object.keys(elements).map(key => _.omit({
    ...elements[key],
    id: key,
    name: _.get(elements, [key, 'title'], _.get(elements, [key, 'name'], key)),
}, 'title')).sort((a, b) => elements[a]?.name?.toLowerCase() > elements[b]?.name?.toLowerCase() ? 1 : -1);

export function useSkillList({ searchValue }) {
    const accountSkillGroups = useSelector(getAccountSkillsGroups);
    const accountSkills = useSelector(getAccountSkills);
    const mappedGroups = useMemo(() => mapElementsKeys(accountSkillGroups), [accountSkillGroups]);
    const mappedSkills = useMemo(() => mapElementsKeys(accountSkills), [accountSkills]);
    const isAccountSkillsEmpty = _.isEmpty(accountSkills);

    const getGroupSkills = (groupId) => mappedSkills.filter(skill => skill.group === groupId);
    const getGroupChildren = (groupId) => mappedGroups.filter(group => group.parent === groupId);
    const filterSkillsBySearch = (skill) => skill.name.toLowerCase().match(searchValue.toLowerCase());

    const groupLessSkills = useMemo(() => ({
        lvl: 1,
        skills: mappedSkills.filter(skill => !skill.group).filter(filterSkillsBySearch),
    }), [mappedSkills, searchValue]);

    const skillList = useMemo(() => mappedGroups.reduce((acc, group) => {
        const groupSkills = getGroupSkills(group.id);
        const groupChildren = getGroupChildren(group.id);

        // if group does not have skills either children just skip it
        if (!groupSkills.length && !groupChildren.length) {
            return acc;
        }

        const matchedSkills = groupSkills.filter(filterSkillsBySearch);
        const skillMatchesSearch = !!matchedSkills.length;
        const groupMatchesSearch = group?.name.toLowerCase().match(searchValue.toLowerCase());

        if (!skillMatchesSearch && !groupMatchesSearch) {
            return acc;
        }

        const filteredGroupSkills = skillMatchesSearch ? matchedSkills : groupSkills;

        const groupData = {
            ...group,
            name: group.name,
            lvl: getSkillLevel(accountSkillGroups, group.parent),
            skills: filteredGroupSkills.map(skill => ({ ...skill, lvl: getSkillLevel(accountSkillGroups, group.id) })),
        };

        if (group.parent) {
            let groupParent = group.parent;

            while (groupParent) {
                if (!acc[groupParent]) {
                    acc[groupParent] = {
                        name: mappedGroups.find(g => g.id === groupParent).name,
                        id: groupParent,
                        lvl: getSkillLevel(accountSkillGroups, accountSkillGroups[groupParent].parent),
                        skills: [],
                    };
                }

                acc[groupParent].skills = [...acc[groupParent].skills, groupData];

                groupParent = accountSkillGroups[groupParent]?.parent;
            }

            return acc;
        }

        return {
            ...acc,
            [group.id]: {
                ...groupData,
                skills: acc[group.id] ? [...acc[group.id].skills, ...groupData.skills] : groupData.skills,
            },
        };
    }, { groupLessSkills }), [mappedGroups, mappedSkills, groupLessSkills, searchValue]);

    return {
        skillList,
        isAccountSkillsEmpty
    };
}