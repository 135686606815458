var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import { createRecurrentTasks } from './firebaseEvents';
var momentWeekdaysAdpter = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
};
var byWeek = function (_a) {
    var recurringSettings = _a.recurringSettings, currentTask = _a.currentTask;
    var startDate = moment(recurringSettings.startDate);
    var dateLimit = moment(recurringSettings.dateLimit);
    var weekdaysSelected = Object.keys(recurringSettings.weekdays).filter(function (el) { return recurringSettings.weekdays[el]; });
    var newTasks = [];
    var typeOfLimit = recurringSettings.typeOfLimit, numberOfRepetitions = recurringSettings.numberOfRepetitions, everyXWeek = recurringSettings.everyXWeek;
    // from the start of the week
    var initBeggingOfWeek = moment(startDate).startOf('weeks');
    var _loop_1 = function () {
        var copy = moment(initBeggingOfWeek);
        // create a task fo
        weekdaysSelected.forEach(function (el) {
            var newTaskName = "(".concat(newTasks.length + 1, ") ").concat(currentTask.title);
            var taskDelay = copy.isoWeekday(momentWeekdaysAdpter[el]);
            if (copy.isAfter(startDate)) {
                newTasks.push({
                    title: newTaskName,
                    taskDelay: taskDelay.valueOf().toString(),
                });
            }
        });
        initBeggingOfWeek.add(everyXWeek, 'weeks');
    };
    while ((typeOfLimit === 'date' && initBeggingOfWeek.isBefore(dateLimit))
        || (typeOfLimit === 'occurrences' && newTasks.length < numberOfRepetitions)) {
        _loop_1();
    }
    return newTasks;
};
var byYear = function (_a) {
    var recurringSettings = _a.recurringSettings, currentTask = _a.currentTask;
    var startDate = moment(recurringSettings.startDate);
    var dateLimit = moment(recurringSettings.dateLimit);
    var newTasks = [];
    var dayByYear = recurringSettings.dayByYear, monthByYear = recurringSettings.monthByYear, typeOfLimit = recurringSettings.typeOfLimit, numberOfRepetitions = recurringSettings.numberOfRepetitions;
    // from the start of the week
    // from the start of the week
    var initBeggingOfWeek = moment(startDate).startOf('year');
    while ((typeOfLimit === 'date' && initBeggingOfWeek.isBefore(dateLimit))
        || (typeOfLimit === 'occurrences' && newTasks.length < numberOfRepetitions)) {
        var copy = moment(initBeggingOfWeek).month(monthByYear);
        copy.date(dayByYear);
        if (copy.isAfter(startDate)) {
            var newTaskName = "(".concat(newTasks.length + 1, ") ").concat(currentTask.title);
            newTasks.push({
                title: newTaskName,
                taskDelay: copy.valueOf().toString(),
            });
        }
        // create a task fo
        initBeggingOfWeek.add(1, 'year');
    }
    return newTasks;
};
var byMonth = function (_a) {
    var recurringSettings = _a.recurringSettings, currentTask = _a.currentTask;
    var startDate = moment(recurringSettings.startDate);
    var dateLimit = moment(recurringSettings.dateLimit);
    var weekdaysSelected = Object.keys(recurringSettings.weekdays).filter(function (el) { return recurringSettings.weekdays[el]; });
    var newTasks = [];
    var dayOfEvent = recurringSettings.dayOfEvent, typeOfLimit = recurringSettings.typeOfLimit, numberOfRepetitions = recurringSettings.numberOfRepetitions, monthType = recurringSettings.monthType, onThe = recurringSettings.onThe;
    if (monthType === 'day') {
        // from the start of the week
        var initBeggingOfWeek = moment(startDate).startOf('month');
        while ((typeOfLimit === 'date' && initBeggingOfWeek.isBefore(dateLimit))
            || (typeOfLimit === 'occurrences' && newTasks.length < numberOfRepetitions)) {
            var copy = moment(initBeggingOfWeek).date(dayOfEvent);
            if (copy
                && (typeOfLimit === 'occurrences' || (typeOfLimit === 'date' && copy.isBefore(dateLimit)))) {
                var newTaskName = "(".concat(newTasks.length + 1, ") ").concat(currentTask.title);
                newTasks.push({
                    title: newTaskName,
                    taskDelay: copy.valueOf().toString(),
                });
            }
            // create a task fo
            initBeggingOfWeek.add(1, 'month');
        }
    }
    if (monthType === 'ordinal') {
        // from the start of the week
        var initBeggingOfWeek = moment(startDate).startOf('month');
        while ((typeOfLimit === 'date' && initBeggingOfWeek.isBefore(dateLimit))
            || (typeOfLimit === 'occurrences' && newTasks.length < numberOfRepetitions)) {
            var copy = moment(initBeggingOfWeek).isoWeekday(momentWeekdaysAdpter[weekdaysSelected[0]] + (onThe - 1) * 7);
            if (!copy.isSame(initBeggingOfWeek, 'month')) {
                copy.add(onThe * 7, 'days');
            }
            if (copy.isAfter(moment())
                && (typeOfLimit === 'occurrences' || (typeOfLimit === 'date' && copy.isBefore(dateLimit)))) {
                var newTaskName = "(".concat(newTasks.length + 1, ") ").concat(currentTask.title);
                newTasks.push({
                    title: newTaskName,
                    taskDelay: copy.valueOf().toString(),
                });
            }
            // create a task fo
            initBeggingOfWeek.add(1, 'month');
        }
    }
    return newTasks;
};
export var recurringFactory = {
    month: byMonth,
    week: byWeek,
    year: byYear,
};
export var createRecurringFunctions = function (_a) {
    var recurringSettings = _a.recurringSettings, stateTasks = _a.stateTasks, taskId = _a.taskId, toastr = _a.toastr;
    return __awaiter(void 0, void 0, void 0, function () {
        var currentTask, type, newTasks;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    currentTask = stateTasks[taskId];
                    type = recurringSettings.type;
                    if (!recurringFactory[type]) return [3 /*break*/, 2];
                    newTasks = recurringFactory[type]({
                        currentTask: currentTask,
                        recurringSettings: recurringSettings,
                    });
                    if (newTasks.length > 100) {
                        toastr.success('Task created with success but limited to 100 tasks.', '');
                    }
                    return [4 /*yield*/, createRecurrentTasks({
                            newTasks: newTasks.slice(0, 100),
                            taskId: taskId,
                            currentTask: currentTask,
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
};
