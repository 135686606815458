import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { sortByDate } from '../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TaskEventCell from '../TaskEventCell';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';

const UpcomingTasksOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
    dateFormat,
    userTimezone
}) => {
    const upcomingTasks = React.useMemo(()=> {
        if (stateTasks) {
               
            let workspaceTasks =  workspaceSelected === 'root' ? 
                Object.keys(stateTasks) : 
                _.get(childsParents, workspaceSelected , [workspaceSelected]);

            const getTaskInfo = (task, taskId) => ({
                id: taskId,	
                taskName: task.title || '',
                startDate: task.estimations?.startAt,
            });

            return workspaceTasks.filter(taskId => {
                const currentTask = stateTasks[taskId];
                if(!currentTask || currentTask.status !== 'todo' || !currentTask.estimations || currentTask.childrens){
                    return false;
                }
                return true;
            })
                .map(el => getTaskInfo(stateTasks[el],el));
        }

    }, [stateTasks, workspaceSelected]);

    const path = window.location.pathname.split('/').slice(0,3).join('/');
    return <Card>
        <CardHeader 
            title={<span> <b> Upcoming Tasks</b></span> }
            action={
                <Button component={Link}  size="small" variant="outlined" color="primary"
                    key="tasks" 
                    to={`${path}/upcoming-tasks`}
                >
                    See All <b>Upcoming Tasks</b>
                </Button>
            }
        />
        <CardContent>
            {
                upcomingTasks.length ? 
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Date</TableCell>
                                <TableCell>Task Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {upcomingTasks
                                .sort((a,b) => sortByDate(a,b,'startDate'))
                                .slice(0,5)
                                .map((row) => (
                                    <TableRow key={`upcomingTasks-${row.id}`}>
                                        <TableCell align="left">{row.startDate !== 'N/A' ? moment.tz(row.startDate, 'X', userTimezone).format(dateFormat) : row.startDate }</TableCell>
                                        <TableCell align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    :
                    <div className='empty  small'>No Data</div>
            }
        </CardContent>
    </Card>;
};

UpcomingTasksOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    dateFormat: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default UpcomingTasksOverview;