import React from 'react';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { SYNC_APP_TYPE } from '../../utils';
import ClickUpSyncOptions from './ClickUp/ClickUpSyncOptions';
import AsanaSyncOptions from './Asana/AsanaSyncOptions';
import JiraSyncOptions from './Jira/JiraSyncOptions';

const SyncronizeOptions = ({ syncAppEffortSettings, toolName, effortChangeType, syncAppId }) => {
    if(toolName === SYNC_APP_TYPE.CLICKUP) {
        return (
            <ClickUpSyncOptions
                effortSettingsData={syncAppEffortSettings}
                effortChangeType={effortChangeType}
                syncAppId={syncAppId}
            />
        );
    }

    if([SYNC_APP_TYPE.JIRA, SYNC_APP_TYPE.JIRASERVER].includes(toolName)) {
        return (
            <JiraSyncOptions
                syncAppId={syncAppId}
                effortChangeType={effortChangeType}
                effortSettingsData={syncAppEffortSettings}
            />
        );
    }

    if(toolName === SYNC_APP_TYPE.ASANA) {
        return (
            <AsanaSyncOptions
                effortSettingsData={syncAppEffortSettings}
                effortChangeType={effortChangeType}
                syncAppId={syncAppId}
            />
        );
    }

    return null;
};

export default withCustomErrorBoundary(SyncronizeOptions);