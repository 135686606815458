import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TaskEventCell from '../TaskEventCell';
import { Link } from 'react-router';
import Button from '@material-ui/core/Button';
import { getEventInfo, getPath, sortByDate } from '../utils';

const parseMoment = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
};

const UpcomingEventsOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
    dateFormat,
    userTimezone
}) => {
    const accountEvents = useSelector((state) => state.app?.account?.events, (a,b) => a && b && Object.keys(a).length === Object.keys(b).length);
    const [dataTablesData, setDataTablesData] = useState([]);

    useEffect(()=> {
        const newDataTablesData = [];
        if (stateTasks && !_.isEmpty(accountEvents)) {
            const filteredEvents =  workspaceSelected === 'root' ? 
                Object.keys(accountEvents) : 
                Object.keys(accountEvents)
                    .filter(eventId =>accountEvents[eventId].parent === workspaceSelected || _.get(childsParents, workspaceSelected , [workspaceSelected]).includes(accountEvents[eventId].parent));
            
            filteredEvents.forEach(eventId => {
                const event = accountEvents[eventId];
                if (!event) {
                    return;
                }

                const currentTask = event.parent ? stateTasks[event.parent] : { title: ''} ;
                if(!currentTask){
                    return;
                }
                // if not it means there is data.
                const taskPath =  event.parent ? getPath(currentTask.parent, stateTasks) : '-';
                                   
                const today = moment().tz(userTimezone);
                // is the event beetween the date range so after today and before the endDate
                // if there is no recurrency  --- will go normal way the event is valid our will be the startDate of Event.
                if(!event.recurrency && moment.tz(event.start, 'X', userTimezone).isAfter(today)) {
                    const eventInfo = getEventInfo({
                        eventName: event.name,
                        when: event.start,
                        participants: [],
                        path: taskPath
                    }, eventId);

                    newDataTablesData.push(eventInfo);
                    return;
                }

                if(event.recurrency){
                    let lastEvent = moment.tz(event.start, 'X', userTimezone);
                    do {
                        const endOfWeek = moment(lastEvent).endOf('week');
                        Object.keys(event.recurrency).forEach(weekday => {
                            if(event.recurrency[weekday] && parseMoment[weekday]) {
                                const expecetedDate = moment(endOfWeek).day(parseMoment[weekday]);
                                if( expecetedDate.isAfter(today, 'day')) {
                                    const eventInfo = getEventInfo({
                                        eventName: event.name,
                                        when: expecetedDate.unix(),
                                        participants: [],
                                        path: taskPath
                                    },eventId );

                                    newDataTablesData.push(eventInfo);
                                }
                            }
                        });
                        lastEvent.add(parseInt(event.recurrency.everyXweeks), 'weeks');
                    }while(dataTablesData.length < 5  && lastEvent.isBefore(moment().tz(userTimezone).add(100, 'days')));      }
            });
            const data = newDataTablesData.sort((a,b) => sortByDate(a,b,'when')).slice(0,5);

            setDataTablesData(data);
        }

    }, [stateTasks, childsParents, workspaceSelected, dateFormat, accountEvents]);
    const path = window.location.pathname.split('/').slice(0,3).join('/');


    return <Card>
        <CardHeader 
            title={<span> <b> Upcoming Events</b></span> }
            action={
                <Button component={Link}  size="small" variant="outlined" color="primary"
                    key="tasks" 
                    to={`${path}/upcoming-events`}
                >
                    See All <b>Upcoming Events</b>
                </Button>
            }
        />
        <CardContent>
            {
                dataTablesData.length ? 
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Event Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dataTablesData.map((row) => (
                                    <TableRow key={`row-${row.id}-${row.when}`}>
                                        <TableCell align="left">{row.when !== 'N/A' ? moment.tz(row.when, 'X', userTimezone).format(dateFormat) : row.when }</TableCell>
                                        <TableCell align="left"><TaskEventCell name={row.eventName} id={row.id} type='event' /></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    :
                    <div className='empty  small'>No Data</div>
            }
        </CardContent>
    </Card>;
};

UpcomingEventsOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
    dateFormat: Proptypes.string.isRequired,
};

export default React.memo(UpcomingEventsOverview);