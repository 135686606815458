import React, { Suspense, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import * as firebaseEvents from '../../utils/firebaseEvents';
import useDebounce from '../../utils/hooks/useDebounce';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import $ from 'jquery';
import _ from 'underscore';
import Tribute from 'tributejs';

import { getUserId } from '../../utils/selectors/user';
import { useSelector } from 'react-redux';
import { segmentTrack } from '../../utils/firebaseEvents';
import { getAccountId, getAccountStorageSize, getAccountUsers } from '../../utils/selectors/account';
import { getAccountTask } from '../../utils/selectors/account';
import { CircularProgress } from '@material-ui/core';
import { updateTaskDescription } from '../../utils/controllers/accountData/tasks/taskDescription';
import { pluginsEnabledList, toolbarButtonsList, fontFamilyOptions } from './config';
import { useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import Froalaeditor from 'froala-editor';
import { getOnlyUsers } from '../../utils/memberTypes';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const FroalaEditor = React.lazy(() => import('react-froala-wysiwyg'));

const TextEditor = ({ taskId, value, isTaskSyncProject}) => {
    const intl = useIntl();
    const [editorState, setEditorState] = useState('');
    const [startTimer, setStartTimer] = useState(0);

    const accountId = useSelector(getAccountId);
    const accountStorageSize = useSelector(getAccountStorageSize);
    const task = useSelector(getAccountTask(taskId));
    const isDisabled = (!task?.canManage && !task?.canWork);
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const users = getOnlyUsers(accountUsers);

    useDebounce(() => handleUpdate(), 1000, [editorState, taskId, isTaskSyncProject]);

    useEffect(() => {
        if(value) {
            setEditorState(value);
        }
    }, [value]);

    useEffect(() => {
        if(taskId) {
            setEditorState('');
            firebaseEvents.listenTaskDescription(taskId);
        }
        return () => {
            firebaseEvents.unlistenTaskDescription(taskId);
        };
    }, [taskId]);

    const handleUpdate = () => {
        if(value === editorState) return;

        updateTaskDescription(accountId, taskId, editorState);
    };

    const handleSaveContent = (editorState) => {
        setEditorState(editorState);

        const saveStatusEl = document.createElement('div');
        saveStatusEl.className = 'saveStatus';
        saveStatusEl.innerHTML = intl.formatMessage({id:'Saving...'});

        $('.TextEditor .fr-second-toolbar').prepend(saveStatusEl);
        setTimeout(()=>{
            saveStatusEl.innerHTML = intl.formatMessage({id:'✔︎ Saved'});
            setTimeout(()=>{
                saveStatusEl.remove();
            }, 1000);
        }, 1000);
    };

    const handleEditorFocus = () => setStartTimer(performance.now());

    const handleEditorBlur = () => {
        const endTimer = performance.now();
        const timeStamp = Math.round(endTimer - startTimer);

        segmentTrack('Task description session duration', {userId: userId, timeSpent: timeStamp});
    };

    const usersList = _.toArray(users).reduce((acc, user) => {
        if(user.userId !== userId) {
            acc.push({
                key: user.displayName,
                value: user.userId,
                avatar: user.avatar
            });
        }
        return acc;
    }, []);

    const tribute = new Tribute({
        values: _.sortBy(usersList, 'key'),
        selectTemplate: function(item) {
            const userTags = item.string?.split(' ').slice(0,2).map(el => el[0]).join('') ?? '';
            const useColor = item.original.color || '#202020';

            return (item.original.avatar) ?
                '<span class="fr-deletable fr-tribute" data-userId="' + item.original.value + '"><img src="'+ item.original.avatar + '">' + item.original.key + '</span>'
                : `<span styles="font-size: 11px;margin-right: 5px;width: 18px;height: 18px;font-weight: 700;color: #FFFFFF !important;display: flex;align-items: center;justify-content: center;text-align: center;border-radius: 50%;text-transform: uppercase;background-color: ${useColor}" class="fr-deletable fr-tribute" data-userId="${item.original.value}">${userTags}</span>`;
        },
        menuItemTemplate: function (item) {
            const userTags = item.string?.split(' ').slice(0,2).map(el => el[0]).join('') ?? '';
            const useColor = item.original.color || '#202020';

            return (item.original.avatar) ?
                '<img src="'+ item.original.avatar + '">' + item.string :
                `<span style="font-size: 11px;margin-right: 5px;width: 18px;height: 18px;font-weight: 700;color: #FFFFFF !important;display: flex;align-items: center;justify-content: center;text-align: center;border-radius: 50%;text-transform: uppercase;background-color: ${useColor}" class="fr-deletable fr-tribute">${userTags}</span>${item.string}`;
        }
    });

    useEffect(()=>{
        $('.TextEditor .fr-view .fr-tribute').each((index, el)=>{
            if(users[el.getAttribute('data-userId')]){
                const user = users[el.getAttribute('data-userId')];
                const userTags = (user.displayName || user.email).split(' ').slice(0,2).map(el => el[0]).join('');
                const useColor = user.color || '#202020';

                if(users[el.getAttribute('data-userId')].avatar){
                    el.innerHTML = '<img src="'+ users[el.getAttribute('data-userId')].avatar + '">' + users[el.getAttribute('data-userId')].displayName;
                }
                else {
                    el.innerHTML = `<span style="font-size: 11px;margin-right: 5px;width: 18px;height: 18px;font-weight: 700;color: #FFFFFF !important;display: flex;align-items: center;justify-content: center;text-align: center;border-radius: 50%;text-transform: uppercase;background-color: ${useColor}" class="fr-deletable fr-tribute">${userTags}</span>` + users[el.getAttribute('data-userId')].displayName;
                }
            }
        });
    }, [users]);

    Froalaeditor.DefineIconTemplate('material_design', '<span class="muiIcon"><i class="[NAME]"></i><span>');
    Froalaeditor.DefineIcon('checkbox', {NAME: 'check_box', template: 'material_design'});
    Froalaeditor.RegisterCommand('checkbox', {
        icon: 'checkbox',
        title: 'Checkbox',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('<input type="checkbox" /> &nbsp;');
            this.undo.saveStep();
        }
    });

    return (
        <div className={classnames('TextEditor', {disabled: isDisabled, descriptionOfSyncTask: isTaskSyncProject})} data-sidebar='preventClose'>
            <Suspense fallback={<CircularProgress />}>
                <FroalaEditor
                    tag='textarea'
                    config={{
                        toolbarSticky: false,
                        linkAlwaysBlank: true,
                        videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
                        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
                        pluginsEnabled: pluginsEnabledList,
                        toolbarButtons: toolbarButtonsList,
                        attribution: false, // Hide Froala's watermark
                        key: process.env.FROALA_EDITOR_KEY,
                        fontFamily: fontFamilyOptions,
                        events: {
                            'focus': () => handleEditorFocus(),
                            'blur': () => handleEditorBlur(),
                            'initialized' : function() {
                                const editor = this;

                                if(isDisabled) {
                                    editor.edit.off();
                                }
                                tribute.attach(editor.el);
                                editor.events.on('keydown', function(e) {
                                    if (e.which == 13 && tribute.isActive) {
                                        return false;
                                    }
                                }, true);
                            },

                            'image.beforeUpload': function(e){
                                const file = e[0];

                                if(file.size > 250000000){
                                    toastr.error(intl.formatMessage({id:'file.over.250MB'}));
                                    return false;
                                }
                                else {
                                    const fullPath = `files/${accountId}/${taskId}/${Date.now()}`;
                                    const storageRef = firebase.storage().ref(fullPath).child(file.name || 'Untitled');

                                    storageRef.put(file).then((snapshot) => {
                                        storageRef.getDownloadURL()
                                            .then((downloadURL) => {
                                                this.image.insert(downloadURL, null, null, this.image.get());
                                            });

                                        var newStorageSize = accountStorageSize || 0;
                                        newStorageSize += (snapshot.metadata.size/1000/1000/1000);
                                        firebaseEvents.db.set('/accounts/' + accountId + '/settings/storageSize', newStorageSize);

                                    });
                                }
                            },

                            'file.beforeUpload': function(e){
                                const fileD = e[0];

                                if(fileD.size > 250000000){
                                    toastr.error(intl.formatMessage({id:'file.over.250MB'}));
                                    return false;
                                }
                                else {
                                    const fullPath = `files/${accountId}/${taskId}/${Date.now()}`;
                                    const storageRef = firebase.storage().ref(fullPath).child(fileD.name || 'Untitled');

                                    storageRef.put(fileD).then((snapshot) => {
                                        storageRef.getDownloadURL()
                                            .then((downloadURL) => {
                                                this.file.insert(downloadURL, fileD.name || 'Untitled', this.file);
                                            });

                                        var newStorageSize = accountStorageSize || 0;
                                        newStorageSize += (snapshot.metadata.size/1000/1000/1000);
                                        firebaseEvents.db.set('/accounts/' + accountId + '/settings/storageSize', newStorageSize);

                                    });
                                    return false;
                                }
                            }
                        }
                    }}
                    model={editorState}
                    onModelChange={handleSaveContent}
                />
            </Suspense>
        </div>
    );
};

TextEditor.propTypes = {
    taskId: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default withCustomErrorBoundary(TextEditor);