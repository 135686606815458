import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, browserHistory } from 'react-router';
import firebase from 'firebase/app';
import { getUserId } from '../../utils/selectors/user';
import 'firebase/auth';
import {toastr} from 'react-redux-toastr';
import classnames from 'clsx';
import SocialSignup from '../SocialSignup/SocialSignup';
import ReCAPTCHA from 'react-google-recaptcha';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const captcha = React.createRef();

const CreateAccountA = () => {
    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userId = useSelector(getUserId);
    const [passStrength, setPassStrength] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if(userId) {
            browserHistory.push('/');
        }

        if(password) {
            checkPasswordStrength();
        }
    }, [userId, password, passStrength]);

    const checkPasswordStrength = () => {
        const isPassOnlyString = new RegExp(/^[a-z]*$/igm);
        const isPassOnlyNumbers = new RegExp(/^[0-9]*$/igm);
        const isMedium = new RegExp(/^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$/igm);
        const isStrong = new RegExp(/(?=.*?[#?!@$%^&*-])/);

        if(password.split('').length <= 5) {
            setPassStrength('weak');
            return;
        }

        if(isPassOnlyString.test(password) && password.split('').length <= 10 || isPassOnlyNumbers.test(password) && password.split('').length <= 10) {
            setPassStrength('weak');
            return;
        }

        if(password.split('').length >= 6 && password.split('').length <= 10) {
            setPassStrength('medium');
            return;
        }

        if(isStrong.test(password)){
            setPassStrength('strong');
            return;
        }

        if(isMedium.test(password)) {
            setPassStrength('medium');
            return;
        }
    };

    const t = (id, values) => {
        return intl.formatMessage({id}, values);
    };

    const createAccountWithEmailAndPassword = () => {
        firebase.auth().createUserWithEmailAndPassword(email,password)
            .catch((error) => {
                captcha.current.reset();
                if(error.code === 'auth/email-already-in-use'){
                    firebase.auth().fetchSignInMethodsForEmail(email).then((result)=>{
                        toastr.error(t('error.title'), t('auth/account-exists-with-different-credential/' + result.toString()), '', {
                            component: (
                                <a
                                    href="#"
                                    className="CreateAccountEmailExistErrorButton"
                                    onClick={e => {
                                        e.preventDefault();
                                        browserHistory.push('/login');
                                        toastr.removeByType('error');
                                    }}
                                >
                                    {t('Go to login')}
                                </a>
                            ),
                        });
                    });
                }
                else {
                    toastr.error(t('error.title'), t(error.code));
                }
            });
    };

    const validateCatpcha = (e) => {
        e.preventDefault();

        captcha.current.execute();
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onChangePassword = (e) => {
        if(e.target.value === '') {
            setPassStrength('');
        }
        setPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const getFormHelperText = useMemo(() => {
        if(password !== '') {
            let helperText = {
                weak: t('Password strength: weak'),
                medium: t('Password strength: medium'),
                strong: t('Password strength: strong'),
            };
            return helperText[passStrength];
        }
        return '';
    }, [passStrength, password]);

    return (
        <div className="createaccounta-container">
            <div className="paper-container">
                <Paper square={true} className="delay-1s paper-content">
                    <div className='CreateAccountA animated flipInY'>
                        <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" className='logo'/>
                        <div className='CrateAccount__info'>
                            <h1>{t('Ready to gain planning super-powers?')}</h1>
                            <p>{t('create.account-text-message')}</p>
                        </div>

                        <form onSubmit={validateCatpcha}>
                            <TextField
                                margin="normal"
                                fullWidth={true}
                                type="email"
                                label={t('Email')}
                                onChange={onChangeEmail}
                            />
                            <FormControl
                                className={classnames('passwordField', {empty: passStrength === '', weak: passStrength === 'weak', medium: passStrength === 'medium', strong: passStrength === 'strong'})}
                            >
                                <InputLabel htmlFor="passwordField">Password</InputLabel>
                                <Input
                                    fullWidth={true}
                                    margin="normal"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={onChangePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{getFormHelperText}</FormHelperText>
                            </FormControl>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                {t('Create account')}
                            </Button>
                        </form>

                        <div className="divider"><span>{t('Or')}</span></div>

                        <SocialSignup />

                        <div className='divider__empty'></div>

                        <div className='CreateAccount__bottom'>
                            <p>{t('Already have an account?')} <Link to="/login">{t('Login here')}</Link></p>
                        </div>

                        <ReCAPTCHA
                            ref={captcha}
                            size="invisible"
                            sitekey="6LfeoyIUAAAAALg753967VUp22Mwpxd7tlQFSzmZ"
                            onChange={createAccountWithEmailAndPassword}
                        />
                    </div>
                </Paper>
            </div>
            <div className="createaccounta-banner">
                <div className="witness">
                    <p className="witness-testimony">{t('witnessTestimony')}</p>
                    <img className="witness-img" src="../../../stylesheets/assets/joshua-mitchel.svg" alt="Witness Image" />
                    <span className="witness-name">{t('witnessName')}</span>
                    <span className="witness-role">{t('witnessRole')}</span>
                </div>
            </div>
        </div>
    );
};

export default withCustomErrorBoundary(CreateAccountA);

