import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'clsx';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/FilterList';
import FolderSpecial from '@material-ui/icons/FolderSpecial';
import IconButton from '@material-ui/core/IconButton';
import { getFilters, getShowFilters } from '../../utils/selectors/app';
import { showFilters, updateFilters } from '../App/AppActions';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { getAccountId, getAccountUsers } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { useIntl } from 'react-intl';
import _ from 'underscore';
import DeleteIcon from '@material-ui/icons/Delete';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const PresetSelector = () => {
    const intl = useIntl();
    const [showPresets, setShowPresets] = useState(true);
    const showFiltersState = useSelector(getShowFilters);
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const users = useSelector(getAccountUsers);
    const filters = useSelector(getFilters);
    const dispatch = useDispatch();


    const handleToggleFilterSwitch = (e) => {
        e.stopPropagation();
        dispatch(showFilters(!showFiltersState));
    };


    const deletePreset = (presetId) => {
        firebaseEvents.deletePreset(
            accountId,
            userId,
            presetId,
            intl.formatMessage
        );
    };
    
    const loadPreset = (presetId) => {
        dispatch(updateFilters(
            JSON.parse(users[userId].filterPresets[presetId].filters),
            accountId
        ));

        firebaseEvents.addChallenge('loadPreset');

        setShowPresets(false);

        setTimeout(()=>{
            setShowPresets(true);
        }, 100);
    };


    let nbFilters = 0;
    _.each(JSON.parse(filters), (item, index)=>{
        if(index !== 'andor'){ 
            _.each(item, (item2, index2)=>{
                if(index2 !== 'andor'){
                    nbFilters++;
                }
            });
        }
    });

    var presetList = [];
    if(users && users[userId]){
        for(var i in users[userId].filterPresets){
            users[userId].filterPresets[i].id = i;
        }
        presetList = _.sortBy(users[userId].filterPresets, 'name');
        presetList = _.map(presetList, (preset, key)=>{
            return (
                <li key={key} data-sidebar='preventClose'>
                    <span onClick={()=>loadPreset(preset.id)}>{preset.name}</span>
                    <DeleteIcon onClick={()=>deletePreset(preset.id)} />
                </li>
            );
        });
    }
    
    if(!presetList.length){
        presetList = [
            <li key="notfound" data-sidebar='preventClose'>
                <span><em>{intl.formatMessage({id:'No preset found'})}</em></span>
            </li>
        ];
    }

    return (
        <>
            <Button 
                className={classnames('filterSwitch', {active: showFiltersState})} 
                onClick={handleToggleFilterSwitch} 
                startIcon={<FilterIcon />} 
            >   
                {nbFilters > 0 && <span id='filterSwitch__bubble'>{nbFilters}</span>}
                {intl.formatMessage({id:'Filters'})}
            </Button>
        
            <div className="presetSelector">
                <IconButton 
                    variant="contained" 
                    color="secondary" 
                    className="presetBtn" 
                    size="small"
                >
                    <FolderSpecial />
                </IconButton>
                {(showPresets)?(
                    <div className="presetList">
                        <h1><FolderSpecial />Saved filter presets</h1>
                        <ul>
                            {presetList}
                        </ul>
                    </div>
                ):null}
            </div>
        </>
    );
};

export default memo(withCustomErrorBoundary(PresetSelector));

