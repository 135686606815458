import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { useIntl } from 'react-intl';
import { AddCircle } from '@material-ui/icons';
import { Typography, Button } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { getAccountId } from '../../../../utils/selectors/account';
import { getAccountDataSyncAppFolders } from '../../../../utils/selectors/accountData';
import { connectFolder } from '../../../../utils/services/syncApp';
import ProjectsTree from './ProjectsTree';
import SelectProject from '../../../SelectProject/SelectProject';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { segmentTrack } from '../../../../utils/firebaseEvents';
import { getUserId } from '../../../../utils/selectors/user';
import { ADD_PROJECT_EVENT_BY_TOOL, SYNC_PROJECT_EVENT_BY_TOOL } from './constants';
import { updateSyncAppCounterNbProjects } from '../../../../utils/controllers/account/settings/syncAppCounters';
import { SYNC_APP_TYPE } from '../../utils';

const TOOLS_WITHOUT_IMPORT_SUBTASKS = ['trello'];

const ConnectedProjects = ({ toolName, syncAppId }) => {
    const intl = useIntl();

    const [connectedTreeData, setConnectedTreeData] = useState([]);
    const [connectedProjects, setConnectedProjects] = useState([]);
    const [openSelectProject, setOpenSelectProject] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const syncFolder = useSelector(getAccountDataSyncAppFolders(syncAppId));
    const currentAccountId = useSelector(getAccountId);
    const currentUserId = useSelector(getUserId);

    const handleClick = React.useCallback(() => {
        setOpenSelectProject(true);
        segmentTrack(ADD_PROJECT_EVENT_BY_TOOL[toolName], { userId: currentUserId });
    }, [currentUserId, toolName]);
    const handleClose = React.useCallback(() => setOpenSelectProject(false), []);

    useEffect(() => {
        if(!_.isEmpty(syncFolder)) {
            const connectedProjects = Object.values(syncFolder).map(el => ({
                importSubtasks: el.importSubtasks,
                selectedItem: toolName === SYNC_APP_TYPE.JIRA ? el.sourceKey : el.sourceId,
                projectId: el.sourceId,
                name: el.sourceName,
                parents: el.parents,
            }));

            const projectsTree = connectedProjects.reduce((acc, item) => {
                const { parents } = item;
                let current = acc;

                parents.forEach((parent) => {
                    const currParent = current.find(el => el.id === parent.id);
                    if(currParent){
                        current = currParent.childs;
                        return;
                    }
                    const newParent = {
                        id: parent.id,
                        name: parent.name,
                        childs: []
                    };

                    current.push(newParent);
                    current = newParent.childs;
                });

                const itemData = {name: item.name, childs: []};
                if(!_.isUndefined(item.importSubtasks) && !_.isUndefined(item.selectedItem)) {
                    itemData.importSubtasks = item.importSubtasks;
                    itemData.selectedItem = item.selectedItem;
                }
                current.push(itemData);
                return acc;
            }, []);

            setConnectedProjects(connectedProjects);
            setConnectedTreeData(projectsTree);
        } else {
            setConnectedProjects([]);
            setConnectedTreeData([]);
        }
    }, [syncFolder]);

    const handleSynchronize = React.useCallback( async (syncList) => {
        handleClose();

        try {
            setIsLoading(true);
            const existingIds = Object.values(syncFolder || {}).map(el => el.sourceId);
            const newSyncList = syncList.filter(selectedItem => !existingIds.includes(selectedItem.selectedItem));

            const newData = newSyncList.map(selectedItems => ({
                parents: selectedItems.parents,
                importSubtasks: selectedItems.importSubtasks,
                id: selectedItems.selectedItem,
                name: selectedItems?.name || '',
                type: selectedItems?.type || null,
            }));

            await connectFolder({
                syncAppId,
                accountId: currentAccountId,
                items: newData,
            });

            segmentTrack(SYNC_PROJECT_EVENT_BY_TOOL[toolName], { userId: currentUserId });

            const nbConnectedProjects = _.size(existingIds) + _.size(newSyncList);
            await updateSyncAppCounterNbProjects({ syncAppType: toolName, nbConnectedProjects });

        } catch (error) {
            toastr.error('Something went wrong when trying to synchronize the projects.');
        } finally {
            setIsLoading(false);
        }
    }, [syncFolder, currentAccountId, currentUserId, syncAppId, toolName, handleClose]);


    const getEmptyState = () => {
        if(!isLoading && _.isEmpty(connectedProjects) && _.isEmpty(syncFolder)) {
            return (
                <div className='ConnectedProjects__emptyState'>
                    <img src="https://planless.sirv.com/App/emptyStates/connectSyncProjects.png" alt=""/>
                    <h2>{intl.formatMessage({id: 'No projects connected.'})}</h2>
                </div>
            );
        }
    };

    const getLoader = () => {
        if(!isLoading) return;

        return <>
            <CircularProgress />
            <Typography variant='subtitle1'>{intl.formatMessage({id: 'Adding projects...'})}</Typography>
        </>;
    };

    return (
        <>
            <div className='ConnectedProjects'>
                {!isLoading && (
                    <Button className='addProject' startIcon={<AddCircle />} onClick={handleClick}>
                        {intl.formatMessage({id:'Add a Project'})}
                    </Button>
                )}

                {getLoader()}

                {!_.isEmpty(connectedProjects) && !isLoading &&
                    <>
                        <div className={'ConnectedProjects__header'}>
                            <Typography variant='subtitle1'>{intl.formatMessage({id: 'Name:'})}</Typography>
                            {!TOOLS_WITHOUT_IMPORT_SUBTASKS.includes(toolName) && <Typography variant='subtitle1'>{intl.formatMessage({id: 'Sync Subtasks?'})}</Typography>}
                        </div>

                        <div className={'ConnectedProjects__syncFoldersTree'}>
                            <ProjectsTree
                                dataTree={connectedTreeData}
                                syncAppId={syncAppId}
                                toolName={toolName}
                            />
                        </div>
                    </>
                }

                {getEmptyState()}
            </div>

            <SelectProject
                open={openSelectProject}
                currentSyncList={connectedProjects}
                syncAppId={syncAppId}
                currentProjects={connectedProjects.map(item=>item.selectedItem)}
                onClose={handleClose}
                toolName={toolName}
                onSelect={handleSynchronize}
                ignoreSubtasksOption={TOOLS_WITHOUT_IMPORT_SUBTASKS.includes(toolName)}
            />
        </>
    );
};

export default withCustomErrorBoundary(ConnectedProjects);