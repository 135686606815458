import React from 'react';
import NotificationTask from './NotificationTask';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { notificationsPath,  } from '../../utils/databaseQuerys/notifications';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { databaseBatchUpdate } from '../../utils/utils';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const RenderNotifications = ({ title, notificationsByTask }) => {
    const intl = useIntl();
    const currentUserId = useSelector(getUserId);
    const currentAccountId = useSelector(getAccountId);
    const allNotificationsReaded = !_.flatten(
        Object.values(notificationsByTask)
    ).find((notification) => !notification.readed);
  
    const markTaskAsRead = (e) => {
        e.stopPropagation(e);
        const updates = _.flatten(
            Object.values(notificationsByTask)
        ).reduce((acc, notification) => {
            return {
                ...acc,
                [`${notificationsPath({ accountId: currentAccountId, userId: currentUserId})}/${notification.id}/readed`]: !allNotificationsReaded
            };
        }, {});
        databaseBatchUpdate(updates);
    };

    return  <>
        <div className="title-container">
            <div>{intl.formatMessage({id: title})}</div>
            <div>
                <Button variant="outlined" onClick={markTaskAsRead} size="small">
                    {
                        allNotificationsReaded ? intl.formatMessage({id: 'Mark All as unread'}) : intl.formatMessage({id: 'Mark All as read'})
                    }
                </Button>
            </div>
        </div>
        {Object
            .keys(notificationsByTask)
            .sort((a,b) => _.first(notificationsByTask[a]).createdAt < _.first(notificationsByTask[b]).createdAt ? 1 : -1 )
            .map(taskId => (<NotificationTask key={taskId} taskId={taskId} notifications={notificationsByTask[taskId]} />
            ))}
    </>;
}; 

export default React.memo(withCustomErrorBoundary(RenderNotifications));