import { checkTaskAgainstFiltersNew } from '../../utils/utils';
import moment from 'moment-timezone';
import classnames from 'clsx';
import _ from 'underscore';
import $ from 'jquery';

export function getTasks({
    tasks = {},
    parentChildIdsParents = {},
    limitViewTo = null,
    quickSearch = '',
    filters = '{"andor":"and"}',
    timezone = moment.tz.guess(),
    closedSortables = [],
    customFields
}){

    if(!closedSortables){ closedSortables = []; }
    if(Object.keys(tasks).length === 0 || Object.keys(parentChildIdsParents).length === 0 || Object.keys(tasks[Object.keys(tasks)[0]]).indexOf('canView') === -1){
        return {
            tasksToShow: [],
            firstLevelTasks: []
        };
    }

    let allTasks = {...tasks};
    let parents = {...parentChildIdsParents};
    const firstLevelTasks = [];

    const tasksToShow = (currentParent = null) => {
        let tree = {};

        if(!currentParent){
            Object.keys(parents).forEach((item)=>{
                if(parents[item].length === 0 && allTasks[item]){
                    let currIndex = parseInt(allTasks[item].index);
                    while(tree[currIndex]){ currIndex++; }
                    tree[currIndex] = item;
                }
            });
        }
        else if(allTasks[currentParent] && allTasks[currentParent].childrens){
            for(const i in allTasks[currentParent].childrens){
                if(allTasks[currentParent].childrens[i] && allTasks[currentParent].childrens[i] !== 'newTask' && allTasks[allTasks[currentParent].childrens[i].id]){
                    let currIndex = parseInt(allTasks[allTasks[currentParent].childrens[i].id].index);
                    while(tree[currIndex]){ currIndex++; }
                    tree[currIndex] = allTasks[currentParent].childrens[i].id;
                }
            }
        }

        let res = [];

        Object.values(tree).forEach(item => {
            if (currentParent === limitViewTo) {
                firstLevelTasks.push(item);
            }

            if (allTasks[item]?.canView) {

                const canShow = checkTaskAgainstFiltersNew(
                    quickSearch,
                    filters,
                    allTasks[item],
                    { tasks },
                    null,
                    null,
                    timezone,
                    customFields
                );

                if (canShow) {
                    res.push(item);
                }

                if (allTasks[item].childrens) {
                    const filteredChilds = tasksToShow(item);
                    const hasChildrenFiltered = filteredChilds.length > 0;

                    if (hasChildrenFiltered && !res.includes(item)) {
                        res.push(item);
                    }

                    if (hasChildrenFiltered && !closedSortables.includes(item)) {
                        res = [...res, ...filteredChilds];
                    }
                }

            }

            delete allTasks[item];
            delete parents[item];
        });

        return res;
    };

    return {
        tasksToShow: tasksToShow(limitViewTo),
        firstLevelTasks: _.uniq(firstLevelTasks)
    };

}


export function getTaskItems(tasks, task, taskId, timelineRangeStart, timelineRangeEnd, users, timezone, intl) {
    var items = [];
    if(task){

        var tooltipContent = '';

        if (task.estimations && task.estimations.startAt && task.estimations.userId && task.status !== 'done') {
            if (typeof task.estimations.userId === 'string') {
                task.estimations.userId = [task.estimations.userId];
            }

            tooltipContent += '<div class="withAvatar">';

            task.estimations.userId.forEach((u, index) => {
                const user = users[u];
                if(user) {
                    const userTags = (user?.displayName || user?.email).split(' ').slice(0,2).map(el => el[0]).join('');
                    const useColor = user?.color || '#202020';

                    let avatar =
                        users[u]?.avatar
                            ? '<img src="' + users[u].avatar + '" alt="avatar" class="avatar' + index + '"/>'
                            : `<div class="timelieviewicon"><span class="avatar0" style="font-size: 11px;width: 30px;height: 30px;font-weight: 700;color: #FFFFFF !important;display: flex;align-items: center;justify-content: center;text-align: center;border-radius: 50%;text-transform: uppercase;background-color: ${useColor}">${userTags}</span></div>`;
                    let userName = users[u] ? users[u].displayName : '';
                    tooltipContent += avatar + '<strong>' + `${index > 0 ? ', ' : ''}` + userName + '</strong>';
                }
            });

            tooltipContent += ((task.status === 'inprogress')? ' stared on ':' will start ') +
                '<strong class="start">' +
                moment.tz(task.estimations.startAt, 'X', timezone).format('lll') +
                '</strong></div>';
        }
        if (task.estimations && task.estimations.expectedAt && task.status !== 'done') {
            tooltipContent +=
                '<div><span>Expected</span><strong class="expected">' +
                moment.tz(task.estimations.expectedAt, 'X', timezone).format('lll') +
                '</strong></div>';
        }
        if (task.estimations && task.estimations.earliestAt && task.estimations.endAt && task.status !== 'done') {
            tooltipContent +=
                '<div class="earliestLatestBlock"><div><span>Earliest</span><strong class="earliest">' +
                moment.tz(task.estimations.earliestAt, 'X', timezone).format('lll') +
                '</strong></div><div><span>Latest</span><strong class="latest">' +
                moment.tz(task.estimations.endAt, 'X', timezone).format('lll') +
                '</strong></div></div>';
        }
        else {
            if (task.estimations && task.estimations.earliestAt && task.status !== 'done') {
                tooltipContent +=
                    '<div><span>Earliest</span><strong class="earliest">' +
                    moment.tz(task.estimations.earliestAt, 'X', timezone).format('lll') +
                    '</strong></div>';
            }
            if (task.estimations && task.estimations.endAt && task.status !== 'done') {
                tooltipContent +=
                    '<div><span>Latest</span><strong class="latest">' +
                    moment.tz(task.estimations.endAt, 'X', timezone).format('lll') +
                    '</strong></div>';
            }
        }

        var taskAtDanger = task.risks?.taskAtDanger,
            taskAtRisk = task.risks?.taskAtRisk;

        var hasWorkedLine = false;

        if (task.canView) {
            if (task.startedWorkingAt) {
                var end;

                if (task.status === 'done') {
                    end = moment.tz(task.doneAt, 'YYYY-MM-DD', timezone).hour(23).minute(59).seconds(59);
                } else if (task.estimations && task.estimations.startAt) {
                    end = moment.tz(task.estimations.startAt, 'X', timezone);
                    hasWorkedLine = true;
                }
                if (end) {
                    if (
                        new Date(task.startedWorkingAt).getTime() <= timelineRangeEnd.getTime() &&
                        end.valueOf() >= timelineRangeStart.getTime()
                    ) {
                        items.push({
                            start: moment.tz(task.startedWorkingAt, timezone).hour(0).minute(0).seconds(0),
                            end,
                            group: taskId,
                            type: 'range',
                            title: tooltipContent,
                            className: 'worked',
                        });
                    }
                }
            }
            if (task.status === 'done' && task.doneAt) {
                if (
                    new Date(task.doneAt).getTime() <= timelineRangeEnd.getTime() &&
                    new Date(task.doneAt).getTime() >= timelineRangeStart.getTime()
                ) {
                    items.push({
                        start: moment.tz(task.doneAt, timezone).hour(23).minute(59).seconds(59),
                        group: taskId,
                        type: 'point',
                        content: '&#10004;',
                        title:
                            '<div><span>Finished</span><strong>' +
                            moment.tz(task.doneAt, timezone).format('ll') +
                            '</strong></div>',
                        className: 'done',
                    });
                }
            }
            if (task.status !== 'done' && task.estimations && task.estimations.startAt && task.estimations.endAt) {
                if (
                    moment.tz(task.estimations.startAt, 'X', timezone).valueOf() <=
                    timelineRangeEnd.getTime() &&
                    moment.tz(task.estimations.endAt, 'X', timezone).valueOf() >=
                    timelineRangeStart.getTime()
                ) {
                    items.push({
                        start: moment
                            .tz(task.estimations.startAt, 'X', timezone),
                        end: moment
                            .tz(task.estimations.endAt, 'X', timezone),
                        group: taskId,
                        type: 'range',
                        title: tooltipContent,
                        className: classnames({
                            danger: taskAtDanger,
                            risk: taskAtRisk,
                            workedBefore: hasWorkedLine,
                            hasChilds: task.childrens && task.childrens.length > 0,
                        }),
                    });
                }
            }
            if (task.status !== 'done' && task.estimations && task.estimations.expectedAt) {
                if (
                    moment.tz(task.estimations.expectedAt, 'X', timezone).valueOf() <=
                    timelineRangeEnd.getTime() &&
                    moment.tz(task.estimations.expectedAt, 'X', timezone).valueOf() >=
                    timelineRangeStart.getTime()
                ) {
                    items.push({
                        start: moment
                            .tz(task.estimations.expectedAt, 'X', timezone),
                        group: taskId,
                        type: 'point',
                        title: tooltipContent,
                        className: classnames({
                            danger: taskAtDanger,
                            risk: taskAtRisk,
                            hasChilds: task.childrens && task.childrens.length > 0,
                        }),
                    });
                }
            }
            if (task.deadline) {
                if (
                    new Date(task.deadline).getTime() <= timelineRangeEnd.getTime() &&
                    new Date(task.deadline).getTime() >= timelineRangeStart.getTime()
                ) {
                    items.push({
                        start: moment.tz(task.deadline, timezone).hour(23).minute(59).seconds(59),
                        group: taskId,
                        type: 'point',
                        title:
                            '<div><span>Due date</span><strong>' +
                            moment.tz(task.deadline, timezone).format('ll') +
                            '</strong></div>',
                        className: classnames('deadline', {
                            danger: taskAtDanger,
                            risk: taskAtRisk,
                            hasChilds: task.childrens && task.childrens.length > 0,
                        }),
                    });
                }
            }
            if (task.dependentOnThis) {
                var dateDependentOnThis;

                if (task.status === 'done' && task.doneAt) {
                    dateDependentOnThis = moment.tz(task.doneAt, timezone).hour(0).minute(0).seconds(0).add(1, 'days');
                } else if (task.status !== 'done' && task.estimations && task.estimations.endAt) {
                    dateDependentOnThis = moment
                        .tz(task.estimations.endAt, 'X', timezone);
                }

                if (dateDependentOnThis) {
                    if (
                        dateDependentOnThis.valueOf() <= timelineRangeEnd.getTime() &&
                        dateDependentOnThis.valueOf() >= timelineRangeStart.getTime()
                    ) {
                        items.push({
                            start: dateDependentOnThis,
                            group: taskId,
                            type: 'point',
                            title: getTootlipDependentOnThis(
                                tasks,
                                task.dependentOnThis,
                                intl.formatMessage({id:'Tasks depending on this one:'}),
                            ),
                            className: classnames('dependAfter', {
                                hasChilds: task.childrens && task.childrens.length > 0,
                            }),
                        });
                    }
                }
            }

            if (task.dependencies) {
                var dateDependencies;

                if (task.startedWorkingAt) {
                    dateDependencies = moment.tz(task.startedWorkingAt, timezone).hour(0).minute(0).seconds(0);
                } else if (task.status !== 'done' && task.estimations && task.estimations.startAt) {
                    dateDependencies = moment
                        .tz(task.estimations.startAt, 'X', timezone);
                }

                if (dateDependencies) {
                    if (
                        dateDependencies.valueOf() <= timelineRangeEnd.getTime() &&
                        dateDependencies.valueOf() >= timelineRangeStart.getTime()
                    ) {
                        items.push({
                            start: dateDependencies,
                            group: taskId,
                            type: 'point',
                            title: getTootlipDependentOnThis(
                                tasks,
                                _.uniq(task.dependencies),
                                intl.formatMessage({id:'Depending on:'}),
                            ),
                            className: classnames('dependBefore', {
                                hasChilds: task.childrens && task.childrens.length > 0,
                            }),
                        });
                    }
                }
            }
        }
    }

    return items;
}

function getTootlipDependentOnThis(tasks, taskList, title) {
    if (taskList) {
        var result = '<ul><strong>' + title + '</strong>';

        taskList.forEach(taskId => {
            if (tasks[taskId]) {
                result += '<li>' + tasks[taskId].title + '</li>';
            }
        });

        result += '</ul>';

        return result;
    }
}

export function checkOverLines(event) {
    var tl = $(event.target).closest('.TaskLine');
    var vg = $(event.target).closest('.vis-group');
    var target = null;
    if (tl.length) {
        target = $(event.target).closest('.TaskLine');
    } else if (vg.length) {
        target = $(event.target).closest('.vis-group');
    }
    if (target) {
        $('#lineBg').css('top', $(target).offset().top);
    } else {
        $('#lineBg').css('top', -100);
    }
}