import moment from 'moment-timezone';
import { db } from './firebaseEvents';

export const createDefaultWorkspace = ({ accountId, currentUsers = {} }) => {
    const currentUserKeys = Object.keys(currentUsers);
    const defaultUsers = {
        // is the admin
        userA: {
            id: currentUserKeys[0],
            data: currentUsers[currentUserKeys[0]]

        }, // never exists.
        // userB
        userB: {
            id: currentUserKeys[1] || db.pushkey('/users'),
            invited: currentUserKeys.length >= 2,
            data: currentUserKeys.length >= 2 ? currentUsers[currentUserKeys[1]] : {},
        },
        //defaultUsers.userC
        userC: {
            id: currentUserKeys[2] || db.pushkey('/users'),
            invited: currentUserKeys.length >= 3,
            data: currentUserKeys.length >= 3 ? currentUsers[currentUserKeys[2]] : {},

        },
        //defaultUsers.userD
        userD: {
            id: currentUserKeys[3] || db.pushkey('/users'),
            invited: currentUserKeys.length >= 4,
            data: currentUserKeys.length >= 4 ? currentUsers[currentUserKeys[3]] : {},
        }
    };

    const data = {
        'basePermissions': {
            'workers': [
                defaultUsers.userA.id,
                defaultUsers.userB.id,
                defaultUsers.userC.id,
                defaultUsers.userD.id,
            ]
        },
        'skillGroups': {
            '-MoiRUZYqmij2g3YSLGv': {
                'title': 'Design'
            },
            '-MoiRVPrJ4VYo5HjQvu-': {
                'title': 'Development'
            },
            '-MoiRWAIP6js_5L9z8Wx': {
                'title': 'Marketing'
            }
        },
        'skills': {
            '-MoZm0flaJ4QdzwLSHyO': {
                'factor': 2,
                'group': '-MoiRUZYqmij2g3YSLGv',
                'name': 'UX/UI Design'
            },
            '-MoZm0fmidy2mgoMSgdT': {
                'factor': 2,
                'group': '-MoiRVPrJ4VYo5HjQvu-',
                'name': 'Frontend'
            },
            '-MoZm0fmidy2mgoMSgdU': {
                'factor': 2,
                'group': '-MoiRWAIP6js_5L9z8Wx',
                'name': 'SEO'
            },
            '-MoZm0fmidy2mgoMSgdV': {
                'factor': 2,
                'group': '-MoiRVPrJ4VYo5HjQvu-',
                'name': 'Backend'
            },
            '-MoZm0fmidy2mgoMSgdW': {
                'factor': 2,
                'name': 'Management'
            },
            '-MoZm0fmidy2mgoMSgdX': {
                'factor': 2,
                'group': '-MoiRWAIP6js_5L9z8Wx',
                'name': 'Ads Management'
            },
            '-MoZm0fmidy2mgoMSgdY': {
                'factor': 2,
                'group': '-MoiRUZYqmij2g3YSLGv',
                'name': 'Graphic Design'
            },
            '-MohMrHheWJ_Kq-tX228': {
                'factor': 2,
                'group': '-MoiRWAIP6js_5L9z8Wx',
                'name': 'Copywriting'
            }
        },
        'tasks': {
            '-MoZm0fmidy2mgoMSgdZ': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'childrens': [
                    {
                        'id': '-MohJiMMdicJ7msSQFbQ',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdc',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdf',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdg',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdh',
                        'type': 'task'
                    }
                ],
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdZ',
                'index': 0,
                'maxEffort': '98',
                'minEffort': '46',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'startedWorkingAt': '2021-11-17',
                'status': 'todo',
                'tasktype': 'task',
                'title': 'Website Update 🖥️ [DEMO DATA]'
            },
            '-MoZm0fmidy2mgoMSgdc': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'childrens': [
                    {
                        'id': '-MoZm0fmidy2mgoMSgdd',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgde',
                        'type': 'task'
                    }
                ],
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdc',
                'index': 2,
                'maxEffort': '16',
                'minEffort': '8',
                'parent': '-MoZm0fmidy2mgoMSgdZ',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'status': 'todo',
                'tasktype': 'task',
                'title': 'Current Bugs 🐞'
            },
            '-MoZm0fmidy2mgoMSgdd': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgde'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 0,
                'maxEffort': 4,
                'minEffort': 2,
                'parent': '-MoZm0fmidy2mgoMSgdc',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 3,
                'skill': '-MoZm0fmidy2mgoMSgdV',
                'status': 'todo',
                'title': 'Mapping'
            },
            '-MoZm0fmidy2mgoMSgde': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdd'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 1,
                'maxEffort': 12,
                'minEffort': 6,
                'parent': '-MoZm0fmidy2mgoMSgdc',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 2,
                'skill': '-MoZm0fmidy2mgoMSgdV',
                'status': 'todo',
                'title': 'Corrections'
            },
            '-MoZm0fmidy2mgoMSgdf': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'closedSortable': false,
                'comments': {
                    '-MohMDyDxiL6hDWsxZw-': {
                        'at': moment().subtract(1, 'day').toISOString(),
                        'comment': `@[${defaultUsers.userD.data?.displayName ? defaultUsers.userD.data?.displayName : 'James'}](${defaultUsers.userD.id})  Please, take a look at this - https://developers.google.com/search/docs/beginner/seo-starter-guide`,
                        'from': defaultUsers.userA.id,
                        'mentions': [
                            {
                                'childIndex': 0,
                                'display': defaultUsers.userD.data?.displayName ? defaultUsers.userD.data?.displayName : 'James',
                                'id': defaultUsers.userD.id,
                                'index': 0,
                                'plainTextIndex': 0
                            }
                        ]
                    }
                },
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'followers': {
                    [defaultUsers.userD.id]: true,
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdf',
                'index': 0,
                'maxEffort': 10,
                'minEffort': 6,
                'parent': '-MoZm0fmidy2mgoMSgdZ',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 1,
                'skill': '-MoZm0fmidy2mgoMSgdU',
                'status': 'todo',
                'tasktype': 'task',
                'title': 'SEO Optimization'
            },
            '-MoZm0fmidy2mgoMSgdg': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependencies': [
                    '-MohJiMMdicJ7msSQFbQ'
                ],
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgdh'
                ],
                'files': {
                    '-MohNZWzDwOqB3dA8O9v': {
                        'firebaseStorage': true,
                        'fullPath': 'files/-MoZkcyMSk3-ifvGNR0f/-MoZm0fmidy2mgoMSgdg/colors-RGB.pdf',
                        'name': 'colors-RGB.pdf',
                        'size': 9.47708E-4,
                        'url': 'https://firebasestorage.googleapis.com/v0/b/planless-prod.appspot.com/o/files%2F-MoZkcyMSk3-ifvGNR0f%2F-MoZm0fmidy2mgoMSgdg%2Fcolors-RGB.pdf?alt=media&token=fedfdc95-1c19-4076-a7d3-3ce86ea3abdb'
                    }
                },
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 3,
                'maxEffort': 24,
                'minEffort': 8,
                'parent': '-MoZm0fmidy2mgoMSgdZ',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0flaJ4QdzwLSHyO',
                'status': 'todo',
                'title': 'Final UI Design'
            },
            '-MoZm0fmidy2mgoMSgdh': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'deadline': moment().add(8, 'day').toISOString(),
                'delayUntil': moment().add(4, 'day').toISOString(),
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdg'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdh',
                'index': 4,
                'maxEffort': 32,
                'minEffort': 16,
                'parent': '-MoZm0fmidy2mgoMSgdZ',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0fmidy2mgoMSgdT',
                'status': 'todo',
                'tasktype': 'task',
                'title': 'Develop Frontend'
            },
            '-MoZm0fmidy2mgoMSgdk': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'childrens': [
                    {
                        'id': '-MoZm0fmidy2mgoMSgdl',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdo',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdp',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdq',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdr',
                        'type': 'task'
                    }
                ],
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdk',
                'index': 1,
                'maxEffort': '41',
                'minEffort': '21',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'startedWorkingAt': '2021-11-15',
                'status': 'todo',
                'tasktype': 'task',
                'title': 'Marketing Campaign 📊  [DEMO DATA]'
            },
            '-MoZm0fmidy2mgoMSgdl': {
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'childrens': [
                    {
                        'id': '-MoZm0fmidy2mgoMSgdm',
                        'type': 'task'
                    },
                    {
                        'id': '-MoZm0fmidy2mgoMSgdn',
                        'type': 'task'
                    }
                ],
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdl',
                'index': 0,
                'maxEffort': 8,
                'minEffort': 4,
                'parent': '-MoZm0fmidy2mgoMSgdk',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'startedWorkingAt': '2021-11-15',
                'status': 'todo',
                'tasktype': 'task',
                'title': 'Briefing Preparation 📓'
            },
            '-MoZm0fmidy2mgoMSgdm': {
                'board': 'inprogress',
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'closedSortable': false,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgdn'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'id': '-MoZm0fmidy2mgoMSgdm',
                'index': 0,
                'maxEffort': 5,
                'minEffort': 3,
                'parent': '-MoZm0fmidy2mgoMSgdl',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 2,
                'skill': '-MoZm0fmidy2mgoMSgdW',
                'startedWorkingAt': '2021-11-15',
                'status': 'inprogress',
                'tasktype': 'task',
                'timerStart': moment().subtract(1, 'hours').toISOString(),
                'title': 'Analysis Preparation',
                'userWorking': [
                    defaultUsers.userA.id
                ],
                'workingTime': {
                    '-MohMmUumS2Tkd7mwrVl': {
                        'date': moment().subtract(1, 'day').add(2, 'hours').toISOString(),
                        'hours': '2',
                        'member': defaultUsers.userA.id
                    }
                }
            },
            '-MoZm0fmidy2mgoMSgdn': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'deadline': moment().add(3, 'day').toISOString(),
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdm'
                ],
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgdo',
                    '-MoZm0fmidy2mgoMSgdq'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 1,
                'maxEffort': 3,
                'minEffort': 1,
                'parent': '-MoZm0fmidy2mgoMSgdl',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 2,
                'skill': '-MoZm0fmidy2mgoMSgdW',
                'status': 'todo',
                'title': 'Prepare Output Document'
            },
            '-MoZm0fmidy2mgoMSgdo': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdn'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 1,
                'maxEffort': 2,
                'minEffort': 1,
                'parent': '-MoZm0fmidy2mgoMSgdk',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MohMrHheWJ_Kq-tX228',
                'status': 'todo',
                'title': 'Blog Post Announcement'
            },
            '-MoZm0fmidy2mgoMSgdp': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'delayUntil': moment().add(9, 'day').toISOString(),
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdq'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 3,
                'maxEffort': 3,
                'minEffort': 2,
                'parent': '-MoZm0fmidy2mgoMSgdk',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0fmidy2mgoMSgdX',
                'status': 'todo',
                'title': 'Set Up Ads'
            },
            '-MoZm0fmidy2mgoMSgdq': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdn'
                ],
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgdp',
                    '-MoZm0fmidy2mgoMSgdr'
                ],
                'files': {
                    '-MohNUsDUsreWeKACGiC': {
                        'firebaseStorage': true,
                        'fullPath': 'files/-MoZkcyMSk3-ifvGNR0f/-MoZm0fmidy2mgoMSgdq/colors-RGB.pdf',
                        'name': 'colors-RGB.pdf',
                        'size': 9.47708E-4,
                        'url': 'https://firebasestorage.googleapis.com/v0/b/planless-prod.appspot.com/o/files%2F-MoZkcyMSk3-ifvGNR0f%2F-MoZm0fmidy2mgoMSgdq%2Fcolors-RGB.pdf?alt=media&token=90bfa180-81bb-4636-b8bd-77af903beee9'
                    }
                },
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 2,
                'maxEffort': 12,
                'minEffort': 6,
                'parent': '-MoZm0fmidy2mgoMSgdk',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0fmidy2mgoMSgdY',
                'status': 'todo',
                'title': 'Produce Visuals'
            },
            '-MoZm0fmidy2mgoMSgdr': {
                'canView': true,
                'createdAt': '2021-11-15T15:07:13-03:00',
                'createdBy': defaultUsers.userA.id,
                'deadline': moment().add(9, 'days').toISOString(),
                'dependencies': [
                    '-MoZm0fmidy2mgoMSgdq'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true
                },
                'index': 4,
                'maxEffort': 16,
                'minEffort': 8,
                'parent': '-MoZm0fmidy2mgoMSgdk',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0fmidy2mgoMSgdT',
                'status': 'todo',
                'title': 'Update Landing Page'
            },
            '-MohJiMMdicJ7msSQFbQ': {
                'board': 'inprogress',
                'canAdmin': true,
                'canManage': true,
                'canView': true,
                'canWork': true,
                'closedSortable': false,
                'comments': {
                    '-MohLbM19Lo-0UNos-yf': {
                        'at': moment().subtract(3, 'hours').toISOString(),
                        'comment': `@[${defaultUsers.userB.data?.displayName || 'Mathew'}](${defaultUsers.userB.id}), could you please let me know once you have the first drafts?`,
                        'from': defaultUsers.userA.id,
                        'mentions': [
                            {
                                'childIndex': 0,
                                'display': defaultUsers.userB.data?.displayName || 'Mathew',
                                'id': defaultUsers.userB.id,
                                'index': 0,
                                'plainTextIndex': 0
                            }
                        ]
                    }
                },
                'createdAt': '2021-11-17T06:55:47-03:00',
                'createdBy': defaultUsers.userA.id,
                'dependentOnThis': [
                    '-MoZm0fmidy2mgoMSgdg'
                ],
                'followers': {
                    [defaultUsers.userA.id]: true,
                    [defaultUsers.userB.id]: true,
                    'userId': true
                },
                'id': '-MohJiMMdicJ7msSQFbQ',
                'index': 1,
                'maxEffort': '16.00',
                'minEffort': '8.00',
                'parent': '-MoZm0fmidy2mgoMSgdZ',
                'permissions': {
                    'comingFrom': 'base'
                },
                'priority': 0,
                'skill': '-MoZm0flaJ4QdzwLSHyO',
                'startedWorkingAt': '2021-11-17',
                'status': 'inprogress',
                'tasktype': 'task',
                'timerStart': moment().subtract(2, 'hours').toISOString(),
                'title': 'Create Wireframes',
                'userWorking': [
                    defaultUsers.userB.id
                ],
                'workingTime': {
                    '-MohLKLaHOttdbpsgTXm': {
                        'date': moment().subtract(2, 'hours').toISOString(),
                        'hours': '2',
                        'member': defaultUsers.userB.id
                    }
                }
            }
        },
        users: {
            [defaultUsers.userA.id]: {
                ...currentUsers[defaultUsers.userA.id],
                ...getUserA(defaultUsers.userA.id),
                permissions: {
                    admin: true,
                    team: true,
                    skills: true,
                    daysoff: true,
                    boards: true,
                    subscription: true,
                    workload: [
                        defaultUsers.userA.id,
                        defaultUsers.userB.id,
                        defaultUsers.userC.id,
                        defaultUsers.userD.id,
                    ],
                },
            },
            [defaultUsers.userB.id]: {
                ...(defaultUsers.userB.invited && currentUsers[defaultUsers.userB.id]),
                ...getUserB({
                    userId: defaultUsers.userB.id,
                    accountId: accountId,
                    invited: defaultUsers.userB.invited
                }),
                permissions: {
                    admin: true,
                    team: true,
                    skills: true,
                    daysoff: true,
                    boards: true,
                    subscription: true,
                    workload: [
                        defaultUsers.userA.id,
                        defaultUsers.userB.id,
                        defaultUsers.userC.id,
                        defaultUsers.userD.id,
                    ],
                },
            },
            [defaultUsers.userC.id]: {
                ...(defaultUsers.userC.invited && currentUsers[defaultUsers.userC.id]),
                ...getUserC({ userId: defaultUsers.userC.id, accountId: accountId, invited: defaultUsers.userC.invited }),
                permissions: {
                    admin: true,
                    team: true,
                    skills: true,
                    daysoff: true,
                    boards: true,
                    subscription: true,
                    workload: [
                        defaultUsers.userA.id,
                        defaultUsers.userB.id,
                        defaultUsers.userC.id,
                        defaultUsers.userD.id,
                    ],
                },
            },
            [defaultUsers.userD.id]: {
                ...(defaultUsers.userD.invited && currentUsers[defaultUsers.userD.id]),
                ...getUserD({ userId: defaultUsers.userD.id, accountId: accountId, invited: defaultUsers.userD.invited }),
                permissions: {
                    admin: true,
                    team: true,
                    skills: true,
                    daysoff: true,
                    boards: true,
                    subscription: true,
                    workload: [
                        defaultUsers.userA.id,
                        defaultUsers.userB.id,
                        defaultUsers.userC.id,
                        defaultUsers.userD.id,
                    ],
                },
            },
        },
        accountsData: {
            '-MoZm0fmidy2mgoMSgda': {
                'description': '<p>Guidelines <a href="https://www.figma.com/blog/how-to-wireframe/" target="_blank">here</a> .</p>\n'
            },
            '-MoZm0fmidy2mgoMSgdd': {
                'description': '<p><span style="font-size: 14px;">Please, list down here the points found.</span></p>\n'
            },
            '-MoZm0fmidy2mgoMSgdf': {
                'description': '<p><span style="font-size: 14px;font-family: Arial;">Please, review the current website SEO and implement the necessary optimizations.</span></p>\n<ul>\n<li><a href="htttps://mywebsite.com" target="_blank"><span style="font-size: 14px;font-family: Arial;">htttps://mywebsite.com</span></a></li>\n<li><a href="https://app.planless.io/tasks/-MoZm0fmidy2mgoMSgdf#" target="_blank"><span style="color: blue;background-color: rgb(255,255,255);font-size: 14px;font-family: Arial;">htttps://blog.mywebsite.com</span></a></li>\n</ul>\n'
            },
            '-MoZm0fmidy2mgoMSgdo': {
                'description': '<p><span style="font-size: 14px;">We need to produce a blog post giving spoilers about the upcoming marketing campaign.</span></p>\n<p><span style="font-size: 14px;">Please, have in mind we won\'t have the visuals done. So prepare the post without it.</span></p>\n'
            }
        }
    };

    return data;
};


const getUserD = ({ accountId, userId, invited }) => ({
    ...(!invited && ({
        'accountId': accountId,
        'connected': {
            'last_changed': 1637252306617,
            'state': 'offline'
        },
        'avatar': 'https://planless.sirv.com/App/james.png',
        'displayName': 'James',
        'memberType': 'userAndResource',
        'isAdmin': true,
        'notifications': {
            '-MohMESh3QUSeptr1vAw': {
                'message': 'notif.task.mentionned',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
            '-MoiWD6KvpXeuaH22P3n': {
                'message': 'notif.task.changed.title',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
        },
        'userId': userId,
        'permissions': {
            'admin': true,
            'boards': true,
            'daysoff': true,
            'skills': true,
            'subscription': true,
            'team': true,
        },
    })),
    'schedule': [
        {
            'd': '1',
            'day': 'Mon',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '1',
            'day': 'Mon',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        }
    ],
    'skills': {
        '-MoZm0fmidy2mgoMSgdU': 5,
        '-MoZm0fmidy2mgoMSgdX': 5,
        '-MohMrHheWJ_Kq-tX228': 5,
        '-MonapxWYtZPuGXwK9ic': 0
    },
    'timezone': moment.tz.guess() || 'Europe/London',
    'uid': 'yWOUYKYlmvNRsAIWSrs6foiKf4T2'
});

const getUserA = () => ({
    'schedule': [
        {
            'd': '1',
            'day': 'Mon',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '1',
            'day': 'Mon',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        }
    ],
    'skills': {
        '-MoZm0fmidy2mgoMSgdW': 5,
        '-MonapxWYtZPuGXwK9ic': 0
    },
    'timezone': moment.tz.guess() || 'Europe/London'
});

const getUserB = ({ accountId, userId, invited }) => ({
    ...(!invited && ({
        'accountId': accountId,
        'avatar': 'https://planless.sirv.com/App/mathew.png',
        'connected': {
            'last_changed': 1637252306617,
            'state': 'offline'
        },
        'displayName': 'Mathew',
        'memberType': 'userAndResource',
        'isAdmin': true,
        'notifications': {
            '-MohMESh3QUSeptr1vAw': {
                'message': 'notif.task.mentionned',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
            '-MoiWD6KvpXeuaH22P3n': {
                'message': 'notif.task.changed.title',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
        },
        'userId': userId,
        'permissions': {
            'admin': true,
            'boards': true,
            'daysoff': true,
            'skills': true,
            'subscription': true,
            'team': true,
        },
    })),
    'schedule': [
        {
            'd': '1',
            'day': 'Mon',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '1',
            'day': 'Mon',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        }
    ],
    'skills': {
        '-MoZm0flaJ4QdzwLSHyO': 5,
        '-MoZm0fmidy2mgoMSgdY': 5,
        '-MonapxWYtZPuGXwK9ic': 0
    },
    'timezone': moment.tz.guess() || 'Europe/London'
});

const getUserC = ({ accountId, userId, invited }) => ({
    ...(!invited && ({
        'accountId': accountId,
        'connected': {
            'last_changed': 1637252306617,
            'state': 'offline'
        },
        'displayName': 'Emma',
        'memberType': 'userAndResource',
        'avatar': 'https://planless.sirv.com/App/emma.png',
        'isAdmin': true,
        'notifications': {
            '-MohMESh3QUSeptr1vAw': {
                'message': 'notif.task.mentionned',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
            '-MoiWD6KvpXeuaH22P3n': {
                'message': 'notif.task.changed.title',
                'taskId': '-MoZm0fmidy2mgoMSgdf',
            },
        },
        'userId': userId,
        'permissions': {
            'admin': true,
            'boards': true,
            'daysoff': true,
            'skills': true,
            'subscription': true,
            'team': true,
        },
    })),
    'schedule': [
        {
            'd': '1',
            'day': 'Mon',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '1',
            'day': 'Mon',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '2',
            'day': 'Tue',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '3',
            'day': 'Wed',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '4',
            'day': 'Thu',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '12:00',
            'nbMin': 180,
            'start': '09:00'
        },
        {
            'd': '5',
            'day': 'Fri',
            'end': '18:00',
            'nbMin': 300,
            'start': '13:00'
        }
    ],
    'skills': {
        '-MoZm0fmidy2mgoMSgdT': 5,
        '-MoZm0fmidy2mgoMSgdV': 5,
        '-MonapxWYtZPuGXwK9ic': 0
    },
    'timezone': moment.tz.guess() || 'Europe/London'
});