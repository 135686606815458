import React, { memo, useState, useEffect} from 'react';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import { useIntl } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import { getUserId } from '../../utils/selectors/user';
import { useSelector, useDispatch } from 'react-redux';
import { getParentParentChilds, tracking } from '../../utils/utils';
import { getChildsFromParent, getParentsBefore, getVisibleTasksOrdered, getFilteredTasksWithPath, getTasksWithPathSorted } from '../../utils/utils';
import { Checkbox, Dialog, IconButton, Typography } from '@material-ui/core';
import { getAccountId, getAccountTasks } from '../../utils/selectors/account';
import { closeSelectDependenciesModal } from '../App/AppActions';
import { getSelectDependenciesModal } from '../../utils/selectors/app';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import SortableHandleClosed from '@material-ui/icons/ChevronRight';
import SortableHandleOpen from '@material-ui/icons/ExpandMore';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import useSyncData from '../../utils/hooks/useSyncData';

export const isCheckboxDisabled = (id, taskId, status, childrens, childsFromThisTask, parentsBeforeThisTask, stateTasks) => {
    if(
        (status !== 'todo' && !childrens) ||
        (taskId === id || _.flatten(getParentsBefore(id, stateTasks)).includes(taskId) || parentsBeforeThisTask.includes(id)) ||
        (taskId === id || childsFromThisTask.includes(id)) ||
        (stateTasks[id].dependencies && stateTasks[id].dependencies.indexOf(taskId) !== -1) ||
        parentsBeforeThisTask.find(t => (stateTasks[id].dependencies && stateTasks[id].dependencies.indexOf(t) !== -1))
    ) {
        return true;
    }
    return false;
};

export const isIndeterminate = (checkedItems, id, stateTasks) => {
    if(!checkedItems?.includes(id) && _.flatten(getChildsFromParent(id, stateTasks)).some((c) => checkedItems?.includes(c))) {
        return true;
    }
    return false;
};

export const isChecked = (checkedItems, id, stateTasks) => {
    if(
        checkedItems?.includes(id) ||
        (
            stateTasks[id].childrens ? _.flatten(getChildsFromParent(id, stateTasks)).every((c) => c.type === 'task' &&
            checkedItems?.includes(c)) : false
        )
    ) {
        return true;
    }
    return false;
};

const SelectDependenciesModal = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [keyWord, setKeyWord] = useState('');
    const [showTree, setShowTree] = useState(true);
    const [checkedItems, setCheckedItems] = useState([]);
    const [startTimer, setStartTimer] = useState(0);

    const selectDependenciesModal = useSelector(getSelectDependenciesModal);
    const userId = useSelector(getUserId);
    const taskId = selectDependenciesModal.data.taskId;
    const tasks = useSelector(getAccountTasks);
    const accountId = useSelector(getAccountId);
    const syncAppData = useSyncData(taskId);

    useEffect(() => {
        if (tasks[taskId] && selectDependenciesModal) {
            setStartTimer(performance.now());

            if(tasks[taskId]?.dependencies) {
                const dependenciesChilds = [];
                const dependencies = tasks[taskId]?.dependencies;

                dependencies.forEach(dep => {
                    if (tasks[dep]?.childrens) {
                        dependenciesChilds.push(..._.flatten(getChildsFromParent(dep, tasks)));
                    }
                });
                setCheckedItems([...dependencies, ...dependenciesChilds]);
            }
        }
    }, [tasks[taskId], tasks[taskId]?.dependencies, selectDependenciesModal]);

    const onCheck = (id) => {
        const isChecked = checkedItems.includes(id);
        let childsFromParent = _.flatten(getChildsFromParent(id, tasks));

        if (isChecked) {
            let checkedLeft = [];
            const parentTaskBefore = _.flatten(getParentsBefore(id, tasks));
            checkedLeft = checkedItems.filter(e => e !== id && !parentTaskBefore.includes(e));

            if (tasks && tasks[id].childrens) {
                checkedLeft = checkedLeft.filter(e => !childsFromParent.includes(e));
            }

            setCheckedItems(checkedLeft);
            return;
        }

        let newChecked = [...checkedItems, id];
        if (tasks && tasks[id].childrens) {
            newChecked = [...newChecked, ...childsFromParent];
        }

        const newCheckList = getParentParentChilds({ currentCheckList: newChecked, stateTasks: tasks, id });
        setCheckedItems(newCheckList);
    };

    const onSave = () => {
        const endTimer = performance.now();
        const timeStamp = Math.round(endTimer - startTimer);
        tracking('Updated the task dependencies', {userId, timestamp: timeStamp});

        const newDependenciesArr = checkedItems.filter(el => !checkedItems.includes(tasks[el]?.parent));

        if(_.isEqual(selectDependenciesModal.data.dependencies, _.uniq(newDependenciesArr))) {
            handleClose();
            setCheckedItems([]);
            return;
        }

        firebaseEvents.changeTaskDependentOn(accountId, taskId, _.uniq(newDependenciesArr));
        dispatch(closeSelectDependenciesModal());
        setCheckedItems([]);
    };

    const handleToggleViewMode = (e) => {
        setShowTree(false);
        setKeyWord(e.target.value);
    };

    const handleClearInput = () => {
        setShowTree(true);
        setKeyWord('');
    };

    const handleClose = () => {
        dispatch(closeSelectDependenciesModal());
        setCheckedItems([]);
    };

    if(selectDependenciesModal.isOpen){
        return (
            <Dialog maxWidth={'md'} open={selectDependenciesModal.isOpen} elevation={2} aria-labelledby="customized-dialog-title" onClose={handleClose} data-sidebar='preventClose'>
                <div className='Select'>
                    <MuiDialogTitle className="title" disableTypography >
                        <Typography variant="h6"> {intl.formatMessage({id: 'Select Dependencies'})}</Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent className="Select__inputWrapper">
                        <div className="Select__input">
                            <SearchIcon id="searchIcon" />
                            <DebounceInput placeholder="Search..." type="text" value={keyWord} minLength={1} debounceTimeout={1000} onChange={handleToggleViewMode}/>
                            {!showTree &&
                                (<div id="clearInput" onClick={handleClearInput}><CloseIcon />{keyWord !== '' ?
                                    (<span>{intl.formatMessage({id: 'Clear & go to the tree view'})}</span>) :
                                    (<span>{intl.formatMessage({id: 'Go to the tree view'})}</span>)}</div>
                                )}
                        </div>
                    </MuiDialogContent>
                    {showTree ?
                        (<TreeView checkedItems={checkedItems} onCheck={onCheck} taskId={taskId} stateTasks={tasks} syncAppData={syncAppData} />)
                        : (<SearchView keyWord={keyWord} accountId={accountId} checkedItems={checkedItems} onCheck={onCheck} taskId={taskId} stateTasks={tasks} syncAppData={syncAppData}/>)
                    }
                    <MuiDialogActions className="Select__btnOptions">
                        <Button variant="contained" onClick={handleClose}>
                            {intl.formatMessage({id: 'CANCEL'})}
                        </Button>
                        <Button disabled={!checkedItems.length} className={classnames('', {saveDisabled: !checkedItems.length})} variant="contained" color="primary" onClick={onSave}>
                            {intl.formatMessage({id: 'SAVE CHANGES'})}
                        </Button>
                    </MuiDialogActions>
                </div>
            </Dialog>
        );
    }
    return null;
};

export const TreeView = ({ checkedItems, onCheck, taskId, stateTasks, syncAppData }) => {
    const [openLines, setOpenLines] = useState([]);
    const [visibleLines, setVisibleLines] = useState([]);
    const [parentsBeforeThisTask, setParentsBeforeThisTask] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const { currentTask, isSyncAppTask, syncAppType } = syncAppData;

    useEffect(() => {
        if(stateTasks[taskId]) {
            const visibleTasksOrdered = getVisibleTasksOrdered(taskId, stateTasks);
            const parentsBefore = _.flatten(getParentsBefore(taskId, stateTasks));
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
            setParentsBeforeThisTask(_.flatten(getParentsBefore(taskId, stateTasks)));
            setVisibleLines(visibleTasksOrdered);
            setOpenLines(parentsBefore);
        }
    }, [stateTasks[taskId]]);

    const closeTasks = (id) => {
        const index = visibleLines.findIndex((el) => el.id === id);
        const parentBefore = visibleLines
            .slice(0, index + 1)
            .map((el) => el.parent);

        const lastChildIndexClicked = visibleLines
            .slice(index + 1)
            .findIndex((el) => !el.parent || parentBefore.includes(el.parent));

        if (lastChildIndexClicked >= 0) {
            setVisibleLines([
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + lastChildIndexClicked + 1),
            ]);
        } else {
            setVisibleLines(visibleLines.slice(0, index + 1));
        }

        const openTasksChildrens = _.flatten(getChildsFromParent(id, stateTasks)).filter((c) => visibleLines.map(task => task.id).includes(c));

        setOpenLines(openLines.filter((el) => el !== id && !openTasksChildrens.includes(el)));
    };

    const openTasks = (id) => {
        const index = visibleLines.findIndex((el) => el.id === id);

        if (stateTasks[id] && stateTasks[id].childrens) {
            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...stateTasks[id].childrens
                    .filter((el) => el.type === 'task' && stateTasks[el.id].parent)
                    .map((cTask) => ({
                        ...stateTasks[cTask.id],
                        id: cTask.id,
                        indent: visibleLines[index].indent + 2,
                    }))
                    .sort((a, b) => (a.index > b.index ? 1 : -1)),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines([...newVisibleLines]);
            setOpenLines([...openLines, id]);
        } else {
            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines(newVisibleLines);
            setOpenLines([...openLines, id]);
        }
    };

    const toggleOpenClose = (id) => {
        if (openLines.includes(id)) {
            closeTasks(id);
        } else {
            openTasks(id);
        }
    };

    const handleClick = (e, id, isDisabled) => {
        const isClickable =
            e.target.classList.contains('itemTitle') ||
            e.target.classList.contains('row') ||
            e.target.classList.contains('taskStatus') ||
            e.target.classList.contains('lineNode');

        if(!isDisabled && isClickable) {
            onCheck(id);
        }
    };

    const getIcons = (id, childrens) => {
        if (childrens && openLines.includes(id)) {
            return <SortableHandleOpen className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        if (childrens && !openLines.includes(id)) {
            return <SortableHandleClosed className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        return <TaskStatusIcon taskId={id}/>;
    };

    const handleCheckLine = (id, isDisabled) => {
        return <Checkbox
            color='primary'
            disabled={isDisabled}
            onChange={(e) => onCheck(id, e)}
            inputProps={{'aria-label': 'secondary checkbox'}}
            checked={isChecked(checkedItems, id, stateTasks)}
            indeterminate={isIndeterminate(checkedItems, id, stateTasks)}
        />;
    };

    return (
        <div className='SelectorsDialogTree'>
            <ul className='SelectorsDialogTree__container'>
                {visibleLines.filter(t=>t.canView).map(({ id, title, indent, status, childrens }, index) => {
                    const isDisabled = isCheckboxDisabled(id, taskId, status, childrens, childsFromThisTask, parentsBeforeThisTask, stateTasks);
                    return (
                        <li key={`${id}-${index}`} onClick={(e) => handleClick(e, id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'} style={{ paddingLeft: `${indent}rem`, paddingRight: '0.6rem'}} >
                            {getIcons(id, childrens)}
                            <div className='lineNode'>
                                <span className={classnames('itemTitle', {
                                    hasNoChildrens: !childrens,
                                    isSync: isSyncAppTask
                                })}>
                                    {title}
                                    {isSyncAppTask && currentTask?.sourceId && <SyncProjectLogo task={currentTask} toolName={syncAppType} />}
                                </span>
                            </div>
                            {handleCheckLine(id, isDisabled)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const SearchView = ({ keyWord, taskId, checkedItems, onCheck, stateTasks, syncAppData }) => {
    const intl = useIntl();
    const [linesFiltered, setLinesFiltered] = useState([]);
    const [parentsBeforeThisTask, setParentsBeforeThisTask] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const { currentTask, isSyncAppTask, syncAppType } = syncAppData;

    useEffect(() => {
        if(stateTasks[taskId]) {
            const newTasksWithPath = getTasksWithPathSorted(stateTasks);
            setLinesFiltered(getFilteredTasksWithPath(keyWord, newTasksWithPath) ?? newTasksWithPath);
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
            setParentsBeforeThisTask(_.flatten(getParentsBefore(taskId, stateTasks)));
        }
    }, [keyWord, stateTasks[taskId]]);

    if(_.isEmpty(linesFiltered) && keyWord) {
        return <div draggable='false' className='noResultsFound'>{intl.formatMessage({id: 'Not found!' })}
            <img
                src={'https://planless.sirv.com/App/notasks.png'}
                className='noTaskFound__img'
                alt="notasks"
            />
            <span>{intl.formatMessage({id: 'Check if the item already exists or add a new one' })}
            </span>
        </div>;
    }

    const handleClick = (id, isDisabled) => {
        if(!isDisabled) {
            onCheck(id);
        }
    };

    const handleCheckLine = (id, isDisabled) => {
        return <Checkbox
            color='primary'
            disabled={isDisabled}
            onChange={(e) => handleClick(id, isDisabled, e)}
            inputProps={{'aria-label': 'secondary checkbox'}}
            checked={isChecked(checkedItems, id, stateTasks)}
            indeterminate={isIndeterminate(checkedItems, id, stateTasks)}
        />;
    };

    return (
        <div className='SelectorsDialogSearch'>
            {linesFiltered.map(({ id, title, path, status, childrens }, index) => {
                const isDisabled = isCheckboxDisabled(id, taskId, status, childrens, childsFromThisTask, parentsBeforeThisTask, stateTasks);
                return(
                    <div key={`${id}-${index}`} onClick={() => handleClick(id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'}>
                        <div className='row__content'> <small>{path}</small>
                            <strong>
                                {title}
                                {isSyncAppTask && currentTask?.sourceId && <SyncProjectLogo task={currentTask} toolName={syncAppType} />}
                            </strong>
                        </div>
                        {handleCheckLine(id, isDisabled)}
                    </div>
                );
            })}
        </div>
    );
};

export default memo(SelectDependenciesModal);
