import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import classnames from 'clsx';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const SelectorOption = ({ isActive, onSelect, handleClose, option, handleOpen, openTasks}) => {
    const intl = useIntl();
    
    const handleClick = () => {
        if(!option.childrens || !option.childrens.length) {
            onSelect(option.id);
            return;
        }

        if(!openTasks.includes(option.id) && option.childrens && option.childrens.length){
            handleOpen(option.id);
            return;
        }
        handleClose(option.id);
    };

    const handleSelect = () => {
        onSelect(option.id);
    };

    return (
        <MenuItem
            className={classnames('MenuItem', { isActive:  isActive })}
            key={`menu-item-${option.id}`}
            value={option.id}
            onClick={handleClick}
            style={{
                display: 'flex',
                marginLeft: `${option.levelDeep}rem`
            }}
        >   
            {!openTasks.includes(option.id) && option.childrens && option.childrens.length >= 1 && (
                <ArrowRight fontSize="small" />
            )}
            {openTasks.includes(option.id) && (
                <ArrowDropDown fontSize="small" />
            )}
            <div className={classnames('MenuItem', { isActive:  isActive })} >
                {option.title}
            </div>
            <div 
                className={'select-label'}
                onClick={handleSelect}>
                {intl.formatMessage({id: 'Select'})}
            </div>
        </MenuItem>
    );
};


const TopLevelSelector = ({ onChange, workspaceSelected }) => {
    const stateTasks = useSelector((state) => state.app?.account?.tasks || {});
    const [openTasks, setOpenTasks] = useState([]);
    const [tasksOptions, setTasksOptions] = useState([]);
    const [closeSelect, setCloseSelect] = useState(false);
    const intl = useIntl();
    
    // have a look to see if some of them are events
    useEffect(() => {
        if(Object.keys(stateTasks).length){

            const mainParents = Object.entries(stateTasks)
                .map(([id, el]) => {
                    if (!el.parent && el.childrens?.find(child => child.type !== 'event') && el.canManage) {
                        return {...el, id};
                    }
                })
                .sort((a,b) => a.index - b.index)   
                .filter(Boolean);

            if(workspaceSelected === 'root') {
                setTasksOptions(mainParents);
                setOpenTasks([]);
            } else {
                const currentTask = stateTasks[workspaceSelected];

                if(!currentTask || !currentTask.parent || !stateTasks[currentTask.parent]){
                    setTasksOptions(mainParents);
                    setOpenTasks([workspaceSelected]);
                    return ;
                }
                // need to rebuild the selector---
                let parentTaskId = currentTask.parent; 
                const childs = [];
                const newOpen = [];
                while(parentTaskId){
                    const parentTask = stateTasks[parentTaskId];
                    const thisParentChilds = parentTask.childrens
                        .filter(el => !newOpen.includes(el.id) && el.type !== 'event' && stateTasks[el.id].canManage)
                        .map(el => ({
                            ...stateTasks[el.id],
                            id: el.id,
                        }));
                    childs.push(thisParentChilds);
                    newOpen.push(parentTaskId);
                    parentTaskId = stateTasks[parentTaskId].parent;
                }
            
                const newTaskOption = [];
                const reversedChild = childs.reverse();
                newOpen.reverse().sort((a,b) => stateTasks[a].index - stateTasks[b].index).forEach((id, idx) => {
                    newTaskOption.push({
                        ...stateTasks[id],
                        id: id,
                        levelDeep: idx 
                    });
                    reversedChild[idx].sort((a,b) => a.index - b.index).forEach((item) => {
                        newTaskOption.push({
                            ...item, 
                            levelDeep: idx + 1
                        });
                    });
                });
                const data = mainParents.sort((a,b) => a.index - b.index).map(el => {
                    if(!newOpen.includes(el.id)){
                        return el;
                    }
                    return newTaskOption; 
                }).flat();
    
                setTasksOptions(data);
                setOpenTasks(newOpen);
            }
        }

    }, [stateTasks, workspaceSelected]);
    //
    const onSelect = (id) => {
        setCloseSelect(false);
        onChange(workspaceSelected === id ? 'root' : id);
    };

    const onClick = () => {
        setCloseSelect(true);
    };

    const onClose = () => {
        setCloseSelect(false);
    };
    
    const handleOpen = (id) => {
        setOpenTasks([...openTasks, id]);
       
        const index = tasksOptions.findIndex((el) => id === el.id);
        if (index >= 0) {
            const levelDeep = tasksOptions[index].levelDeep;
            const nextLvlDeep = levelDeep ? levelDeep + 1 : 1;
            const tasksCopy = [...tasksOptions];
            // get all then child tasks
            const childTasks = 
                stateTasks[id].childrens
                    .filter(el => el.type !== 'event' && stateTasks[el.id].canManage)
                    .map(el => ({
                        ...stateTasks[el.id],
                        id: el.id,
                        levelDeep: nextLvlDeep
                    }))
                    .sort((a,b) => a.index - b.index);   
            
            // add to array
            tasksCopy.splice(
                index + 1,
                0,
                childTasks
            );
            
            setTasksOptions(tasksCopy.flat());
        }
    };
  
    const handleClose = (id) => {
        // get the index of the closed task
        const index = tasksOptions.findIndex((el) => id === el.id);
        if (index >= 0) {
            const currentLevelDeep = tasksOptions[index].levelDeep || 0;
            let limitIndex = index + 1;
            let count = 0;
            const idToBeClosed = [];
            // find where the deep lvl changes that means everthig until there belong was a child of the current task.
            while(
                tasksOptions[limitIndex] && tasksOptions[limitIndex].levelDeep > currentLevelDeep
            ) {
                count++;
                idToBeClosed.push(tasksOptions[limitIndex].id);
                limitIndex++;
            }
    
            const tasksCopy = [...tasksOptions];
            tasksCopy.splice(index + 1,count);
            setTasksOptions(tasksCopy);
            // remove the closed and parent from the openTasks tasks.
            setOpenTasks(openTasks.filter(el => el !== id && !idToBeClosed.includes(el)));
        }
    };

    const defaultValue = {
        title:  intl.formatMessage({id: 'Workspace'}),
        id: 'root'
    };
    
    return (
        <FormControl>
            <InputLabel id="top-lelel-relector"> {intl.formatMessage({id: 'Scope'})}</InputLabel>
            <Select
                open={closeSelect}
                onClose={onClose}
                onOpen={onClick}
                variant="outlined"
                id="select-top-lvl"
                value={workspaceSelected || 'root'}
                renderValue={(el) => el === 'root' ? defaultValue.title : stateTasks[el].title}
                MenuProps={{className:'scopeSelectorMenu'}}
            >
                <MenuItem
                    className={classnames('MenuItem', { isActive:  defaultValue.id === workspaceSelected })}
                    key={`menu-item-${defaultValue.id}`}
                    onClick={() => onSelect(defaultValue.id)}
                    value={defaultValue.id}
                >   
                    <div className={classnames('MenuItem', { isActive:  defaultValue.id === workspaceSelected })} >
                        {defaultValue.title}
                    </div>
                    <div 
                        className={'select-label'}
                        onClick={() => onSelect(defaultValue.id)}>
                        {intl.formatMessage({id: 'Select'})}
                    </div>
                </MenuItem>
                {tasksOptions.map((option) =>  (
                    <SelectorOption
                        isActive={option.id === workspaceSelected}
                        key={`SelectorOption-item-${option.id}`}
                        onSelect={onSelect}
                        option={option}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        openTasks={openTasks}
                    />
                ))
                }
            </Select>
        </FormControl>
    );
};

export default React.memo(withCustomErrorBoundary(TopLevelSelector)); 