import _ from 'underscore';
import { ADD_TASK_LIST_LEVEL } from '../utils';

const JIRA_SOURCE_TYPES = {
    RESOURCE: 'resource',
    PROJECT: 'project',
    EPIC: 'Epic',
    STORY: 'Story',
    BUG: 'Bug',
    TASK: 'Task',
    SUBTASK: 'Sub-task',
};

export const getJiraInfo = ({ task, parentsCount, isInsideSyncFolder, importSubtasks }) => {
    const isPublished = !!task?.sourceId;
    const eligibleTypes = importSubtasks && !_.isEmpty(task?.sourceType)
        ? [JIRA_SOURCE_TYPES.TASK, JIRA_SOURCE_TYPES.BUG, JIRA_SOURCE_TYPES.EPIC, JIRA_SOURCE_TYPES.STORY, JIRA_SOURCE_TYPES.PROJECT]
        : [JIRA_SOURCE_TYPES.EPIC, JIRA_SOURCE_TYPES.PROJECT];

    const canAddTask = () => {
        const isEligibleSourceType = !_.isEmpty(task?.sourceType) && eligibleTypes.includes(task?.sourceType.name);

        const isLastLevel = importSubtasks ? parentsCount === 4 : parentsCount === 3;

        return isEligibleSourceType || (_.isEmpty(task?.sourceType) && isInsideSyncFolder && !isLastLevel);
    };

    const getAddTaskListLevel = () => {
        const hasSourceType = !_.isEmpty(task?.sourceType);
        const isProjectLevel = hasSourceType && task?.sourceType.name === JIRA_SOURCE_TYPES.PROJECT;
        const isEpicLevel = hasSourceType && task?.sourceType.name === JIRA_SOURCE_TYPES.EPIC;
        const isTaskLevel = ([3, 2].includes(parentsCount) && !isPublished) ||
            (hasSourceType && [JIRA_SOURCE_TYPES.TASK, JIRA_SOURCE_TYPES.BUG, JIRA_SOURCE_TYPES.STORY].includes(task?.sourceType.name));

        if (isProjectLevel) {
            return importSubtasks ? ADD_TASK_LIST_LEVEL.FOUR : ADD_TASK_LIST_LEVEL.THREE;
        }

        if (isEpicLevel) {
            return importSubtasks ? ADD_TASK_LIST_LEVEL.TWO : ADD_TASK_LIST_LEVEL.ONE;
        }

        if (isTaskLevel) {
            return ADD_TASK_LIST_LEVEL.ONE;
        }
    };

    return {
        canAddSyncAppTask: canAddTask(),
        hasDependencies: true,
        hasSourceTags: true,
        hasSourceStatus: true,
        addTaskListLevel: getAddTaskListLevel(),
    };
};