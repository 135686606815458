import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {withRouter} from 'react-router';
import {useSelector} from 'react-redux';
import { getAccountUserSchedule, getAccountUserTimezone } from '../../../utils/selectors/account';
import moment from 'moment-timezone';
import { updateUserSchedule, updateUserTimezone } from '../../../utils/firebaseEvents';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import classnames from 'clsx';
import _ from 'underscore';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Availability = ({router, calendarDisabled = false}) => {
    const userId = router.params.uid;
    const [schedule, setSchedule] = useState([]);

    const userTimezone = useSelector(getAccountUserTimezone(userId));
    const userSchedule = useSelector(getAccountUserSchedule(userId));
    const DragAndDropCalendar = withDragAndDrop(Calendar);

    useEffect(() => {
        if(userId && userSchedule && userTimezone) {
            setSchedule(getUserSchedule(userSchedule));
        }
    }, [userId, userSchedule, userTimezone, getUserSchedule]); 

    const MyWeek = (props)=>{
        return <TimeGrid {...props} range={MyWeek.range(props.date)} eventOffset={15} />;
    };

    MyWeek.range = () => {
        return [
            moment('2020-01-06').toDate(),
            moment('2020-01-07').toDate(),
            moment('2020-01-08').toDate(),
            moment('2020-01-09').toDate(),
            moment('2020-01-10').toDate(),
            moment('2020-01-11').toDate(),
            moment('2020-01-12').toDate()
        ];
    };

    MyWeek.title = () => {
        return '';
    };

    const getUserSchedule = (data) => {
        moment.tz.setDefault(moment.tz.guess());

        var memberData = {},
            results = [];

        if (data) {
            memberData.schedule = data;
        }

        if (memberData && memberData.schedule) {
            var i = 0;
            memberData.schedule.forEach((item) => {

                var dates = ['2020-01-12', '2020-01-06', '2020-01-07', '2020-01-08', '2020-01-09', '2020-01-10', '2020-01-11'];
                var date = dates[item.d];

                results.push({
                    id: moment().unix + '-' + i,
                    start: moment(date + ' ' + item.start).toDate(),
                    end: moment(date + ' ' + item.end).toDate(),
                    title: ''
                });
                i++;
            });

            return results;
        }

        return [];
    };

    useEffect(()=>{
        moment.tz.setDefault(moment.tz.guess());
    }, []); 

    const changeUserSchedule = (events) => {
        var results = [];
        events.forEach((event) => {
            results.push({
                d: moment(event.start).format('d'),
                day: moment(event.start).format('ddd'),
                start: moment(event.start).format('HH:mm'),
                end: moment(event.end).format('HH:mm'),
                nbMin: moment(event.end).diff(moment(event.start), 'minutes')
            });
        });
        results = _.sortBy(results, 'start');
        results = _.sortBy(results, 'd');
        setSchedule(getUserSchedule(results));

        updateUserSchedule(userId, results);
    };

    const localizer = momentLocalizer(moment);

    return <div className={classnames('availability', {calendarDisabled: calendarDisabled})}>
        <div>
            <TextField
                className="timezoneSelect"
                value={userTimezone || moment.tz.guess()}
                select
                label="Timezone"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => {
                    updateUserTimezone(userId, e.target.value);
                }}
            >
                {moment.tz.names().map((option) => {
                    if (option.search('/') !== -1 && option.search('Etc/') === -1) {
                        return (
                            <MenuItem key={option} value={option} dense>
                                {option}
                            </MenuItem>
                        );
                    }
                })}
            </TextField>

            <DragAndDropCalendar
                events={schedule}
                getNow={() => moment().tz(moment.tz.guess()).toDate()}
                view={Views.WEEK}
                views={{ week: MyWeek }}
                toolbar={false}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                scrollToTime={moment().set({ h: 7, m: 0 }).toDate()}
                min={moment().tz(moment.tz.guess()).hours(0).minutes(0).seconds(0).toDate()}
                max={moment().tz(moment.tz.guess()).hours(23).minutes(59).seconds(59).toDate()}
                selectable
                step={30}
                timeslots={4}
                formats={{
                    dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture),
                    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'h:mm A', culture)
                }}
                onView={() => { }}
                onEventResize={({ event, start, end }) => {

                    const calendarEvents = schedule;

                    if (moment(end).isAfter(start, 'day')) {
                        end = moment(start).hour(23).minutes(59).toDate();
                    }

                    if (moment(end).minutes() === 59 && moment(end).hour() !== 23) {
                        end = moment(end).minutes(0).add(1, 'hour').toDate();
                    }
                    if (moment(end).minutes() === 29) {
                        end = moment(end).minutes(30).toDate();
                    }

                    var overlap = [];
                    calendarEvents.forEach((event2, index) => {
                        if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isSameOrAfter(moment(event2.start)) &&
                            moment(end).isSameOrBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isBefore(moment(event2.start)) &&
                            moment(end).isSameOrAfter(moment(event2.start)) &&
                            moment(end).isBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                            calendarEvents[index].end = event2.end;
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isBefore(moment(event2.start)) &&
                            moment(end).isAfter(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                            calendarEvents[index].end = end;
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isSameOrAfter(moment(event2.start)) &&
                            moment(start).isSameOrBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = event2.start;
                            calendarEvents[index].end = end;
                        }

                    });

                    if (overlap.length === 0) {
                        const nextEvents = calendarEvents.map(existingEvent => {
                            return existingEvent.id == event.id
                                ? { ...existingEvent, start, end }
                                : existingEvent;
                        });

                        changeUserSchedule(nextEvents);
                    }
                    else {
                        overlap.pop();
                        var temp = [];
                        calendarEvents.forEach((el, i) => {
                            if (overlap.indexOf(i) === -1 && el.id !== event.id) {
                                temp.push(el);
                            }
                        });

                        changeUserSchedule(temp);
                    }
                }}
                onDoubleClickEvent={(event) => {

                    const calendarEvents = schedule;

                    const nextEvents = [];
                    calendarEvents.forEach(existingEvent => {
                        if (existingEvent.id !== event.id) {
                            nextEvents.push(existingEvent);
                        }
                    });

                    changeUserSchedule(nextEvents);
                }}
                onSelectSlot={({ start, end }) => {
                    const calendarEvents = schedule;
                    
                    var overlap = [];
                    calendarEvents.forEach((event, index) => {
                        if (
                            moment(start).isSame(moment(event.start), 'day') &&
                            moment(start).isBefore(moment(event.start)) &&
                            moment(end).isSameOrAfter(moment(event.start)) &&
                            moment(end).isBefore(moment(event.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                        }
                        else if (
                            moment(start).isSame(moment(event.start), 'day') &&
                            moment(start).isBefore(moment(event.start)) &&
                            moment(end).isAfter(moment(event.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                            calendarEvents[index].end = end;
                        }
                        else if (
                            moment(start).isSame(moment(event.start), 'day') &&
                            moment(start).isSameOrAfter(moment(event.start)) &&
                            moment(start).isSameOrBefore(moment(event.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].end = end;
                        }

                    });

                    if (overlap.length === 0) {
                        calendarEvents.push({ id: moment().valueOf(), start, end });

                        changeUserSchedule(calendarEvents);
                    }
                    else {
                        overlap.pop();
                        var temp = [];
                        calendarEvents.forEach((el, i) => {
                            if (overlap.indexOf(i) === -1) {
                                temp.push(el);
                            }
                        });

                        changeUserSchedule(temp);
                    }

                }}
                onEventDrop={({ event, start, end }) => {

                    const calendarEvents = schedule;

                    if (moment(end).isAfter(start, 'day')) {
                        end = moment(start).hour(23).minutes(59).toDate();
                    }

                    if (moment(end).minutes() === 59 && moment(end).hour() !== 23) {
                        end = moment(end).minutes(0).add(1, 'hour').toDate();
                    }
                    if (moment(end).minutes() === 29) {
                        end = moment(end).minutes(30).toDate();
                    }

                    var overlap = [];
                    calendarEvents.forEach((event2, index) => {
                        if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isSameOrAfter(moment(event2.start)) &&
                            moment(end).isSameOrBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isBefore(moment(event2.start)) &&
                            moment(end).isSameOrAfter(moment(event2.start)) &&
                            moment(end).isBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                            calendarEvents[index].end = event2.end;
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isBefore(moment(event2.start)) &&
                            moment(end).isAfter(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = start;
                            calendarEvents[index].end = end;
                        }
                        else if (
                            event2.id !== event.id &&
                            moment(start).isSame(moment(event2.start), 'day') &&
                            moment(start).isSameOrAfter(moment(event2.start)) &&
                            moment(start).isSameOrBefore(moment(event2.end))
                        ) {
                            overlap.push(index);
                            calendarEvents[index].start = event2.start;
                            calendarEvents[index].end = end;
                        }

                    });

                    if (overlap.length === 0) {
                        const nextEvents = calendarEvents.map(existingEvent => {
                            return existingEvent.id == event.id
                                ? { ...existingEvent, start, end }
                                : existingEvent;
                        });

                        changeUserSchedule(nextEvents);
                    }
                    else {
                        overlap.pop();
                        var temp = [];
                        calendarEvents.forEach((el, i) => {
                            if (overlap.indexOf(i) === -1 && el.id !== event.id) {
                                temp.push(el);
                            }
                        });

                        changeUserSchedule(temp);
                    }


                }}
            />
            <p><br /><em>Double-click existing zones to delete them. Drag on open slots to create new ones. Drag handles to change start/end hours.</em></p>

            {getUserSchedule.length === 0 && <div className="noScheduleWarning">This team member has no availabilities. Planless won't be able to plan any work for this member. Make sure to define their availabilities.</div>}

            

        </div>
    </div>;
};

export default withRouter(withCustomErrorBoundary(Availability));