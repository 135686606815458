import { db } from '../../firebaseEvents';
import { tracking } from '../../utils';
import { getAccountId } from '../../selectors/account';
import { getState } from '../../../configureStore';

export const updateCostBudgetSettings = ({ currency = 'USD' }) => {
    tracking('Update Cost Budget Settings"', { accountId: getAccountId(getState()), currency });

    db.set(`/accounts/${getAccountId(getState())}/settings/costBudget`, {
        currency: currency,
        isOn: true,
    });
};

export const getAccountsDataTasks = async () => {
    const data = await db.r(`/accountsData/${getAccountId(getState())}/tasks`).once('value'); 
    
    if(!data.val()) {
        return {};
    }

    return data.val();
};