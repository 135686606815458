import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import _ from 'underscore';
import DoneBySelection from './DoneBySelection';
import { diff } from 'deep-object-diff';
import { getTimeDurationInSeconds, tracking } from '../../utils/utils';
import * as firebaseEvents from '../../utils/firebaseEvents';

import firebase from 'firebase/app';
import { FOLLOWER_STATE_TYPES } from '../../utils/controllers/tasks/followers';
import { getAccountBoards, getAccountDependenciesBlock, getAccountId, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import { getOrderedTaskList, getWorkManager } from '../../utils/selectors/app';
import { setWorkManager } from '../App/AppActions';
import useIsLocalStorageAvailable from '../../utils/hooks/useIsLocalStorageAvailable';
let database = firebase.database();

const WorkManager = ()=>{
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLocalStorageAvailable = useIsLocalStorageAvailable();

    const accountTasks = useSelector(getAccountTasks);
    const accountUsers = useSelector(getAccountUsers);
    const accountId = useSelector(getAccountId);
    const accountBoards = useSelector(getAccountBoards);
    const dependenciesBlockSetting = useSelector(getAccountDependenciesBlock);
    const currentUserId = useSelector(getUserId);
    const workManager = useSelector(getWorkManager);
    const orderedTaskList = useSelector(getOrderedTaskList);

    const getLogCheck = () => {
        return !!(localStorage.getItem('logCheck') && localStorage.getItem('logCheck') === 'true');
    };

    const getEffortCheck = () => {
        return !!(localStorage.getItem('effortCheck') && localStorage.getItem('effortCheck') === 'true');
    };

    const getNextCheck = () => {
        return !(localStorage.getItem('nextCheck') && localStorage.getItem('nextCheck') === 'false');
    };

    const getForceAssignCheck = () => {
        return !(localStorage.getItem('forceAssignCheck') && localStorage.getItem('forceAssignCheck') === 'false');
    };

    const [stateForce, setStateForce] = useState(false);
    const [stateForceDep, setStateForceDep] = useState(false);
    const [stateLogTimeHours, setStateLogTimeHours] = useState(0);
    const [stateLogTimeMinutes, setStateLogTimeMinutes] = useState(0);
    const [stateLogCheck, setStateLogCheck] = useState(getLogCheck());
    const [stateEffortMin, setStateEffortMin] = useState(0);
    const [stateEffortMax, setStateEffortMax] = useState(0);
    const [stateEffortCheck, setStateEffortCheck] = useState(getEffortCheck());
    const [stateNextCheck, setStateNextCheck] = useState(getNextCheck());
    const [stateForceAssignCheck, setStateForceAssignCheck] = useState(getForceAssignCheck());
    const [stateDoneByUsers, setStateDoneByUsers] = useState([]);

    const handleDisableLogTimeWorked = React.useCallback(({ target: { checked } }) => {
        isLocalStorageAvailable && localStorage.setItem('logCheck', checked.toString());
        setStateLogCheck(checked);
    }, [isLocalStorageAvailable]);

    const handleKeepTaskForcedAssignTo = React.useCallback(({ target: { checked } }) => {
        isLocalStorageAvailable && localStorage.setItem('forceAssignCheck', checked.toString());
        setStateForceAssignCheck(checked);
    }, [isLocalStorageAvailable]);

    const getUserTaskInProgress = useCallback(() => {
        if (!workManager.userId) {
            return null;
        }

        const value = Object.keys(accountTasks)
            .map(taskKey => ({ ...accountTasks[taskKey], id: taskKey }))
            .filter(({ status, nbWorkers, userWorking }) => status === 'inprogress' && (!nbWorkers || nbWorkers === 1) && !!userWorking)
            .find(({ userWorking }) => {
                const isUserWorkingString = typeof userWorking === 'string';
                const isWorkManagerUserIdString = typeof workManager.userId === 'string';

                if (isUserWorkingString && isWorkManagerUserIdString) {
                    return userWorking === workManager.userId;
                }

                // it means both are arrays
                if (!isUserWorkingString && !isWorkManagerUserIdString) {
                    return userWorking.some(u => workManager.userId?.includes(u));
                }

                // it means we have a string in the userWorking and an array in the workManager.userId
                if (isUserWorkingString && !isWorkManagerUserIdString) {
                    return workManager.userId?.includes(userWorking);
                }

                // it means we have an array in the userWorking and a string in the workManager.userId
                if (!isUserWorkingString && isWorkManagerUserIdString) {
                    return userWorking.includes(workManager.userId);
                }

                return false;
            });

        return value || null;
    }, [accountTasks, workManager.userId]);


    const getEffortLeftCalc = (subtractHours, task) => {
        let result = { minEffort: 0, maxEffort: 0 };
        if (task) {
            result.minEffort = (task.minEffort) ? parseFloat(task.minEffort) : 0;
            result.maxEffort = (task.maxEffort) ? parseFloat(task.maxEffort) : 0;
            result.minEffort -= subtractHours;
            result.maxEffort -= subtractHours;
            if (result.minEffort < 0) { result.minEffort = 0; }
            if (result.maxEffort < 0) { result.maxEffort = 0.25; }
        }
        return result;
    };

    const getTimeWorkedInSeconds = (task) => {
        if (!task) return;

        const { timerStart, timerPause, startedWorkingAt } = task;
        const startAt = timerStart ?? startedWorkingAt;
        const endAt = timerPause ?? moment();

        return getTimeDurationInSeconds(startAt, endAt);
    };

    const startValues = useMemo(() => {
        const values = { logTimeHours: 0, logTimeMinutes: 0, effortMin: 0, effortMax: 0 };

        if (!workManager.taskId) {
            return values;
        }

        const { taskId, previousState, newState } = workManager;
        const userTaskInProgress = getUserTaskInProgress();

        const accountTask = accountTasks[taskId] ? accountTasks[taskId] : null;
        const isNewStateInProgress = newState === 'inprogress';
        const isPreviousStateInProgress = previousState === 'inprogress';
        const task = !isPreviousStateInProgress && isNewStateInProgress && userTaskInProgress
            ? userTaskInProgress
            : accountTask;

        const timeWorkedInSeconds = getTimeWorkedInSeconds(task);
        const duration = moment.duration(timeWorkedInSeconds, 'seconds');
        const hours = Math.floor(duration.asHours());
        duration.subtract(moment.duration(hours, 'h'));
        const minutes = Math.floor(duration.asMinutes());

        values.logTimeHours = hours;
        values.logTimeMinutes = minutes;

        var hoursDec = parseFloat(moment.duration({ hours, minutes }).asHours().toFixed(2));

        var effortsLeft = getEffortLeftCalc(hoursDec, task);

        values.effortMin = effortsLeft.minEffort;
        values.effortMax = effortsLeft.maxEffort;

        return values;
    }, [workManager.taskId, workManager.previousState, workManager.newState]);

    const getPossibleDoneByUsers = () => {
        if (accountTasks) {
            const task = accountTasks[workManager.taskId];
            const userWorking = _.get(task, 'userWorking', []);
            const forcedUser = _.get(task, 'forcedUser', []);
            const estimationsUser = _.get(task, ['estimations', 'userId'], []);

            if (estimationsUser.length) {
                return estimationsUser;
            }

            if (forcedUser.length) {
                return forcedUser;
            }

            return userWorking;
        }
    };


    useEffect(()=>{
        setStateLogTimeHours(startValues.logTimeHours);
        setStateLogTimeMinutes(startValues.logTimeMinutes);
        setStateEffortMin(startValues.effortMin);
        setStateEffortMax(startValues.effortMax);
    }, [accountTasks, workManager.newState, workManager.previousState, workManager.taskId]);

    useEffect(()=>{
        setStateDoneByUsers(getPossibleDoneByUsers());
    }, [accountTasks, workManager.taskId]);


    const stripTaskProps = (task) => {
        delete task.estimations;
        delete task.path;
        delete task.risks;
        return task;
    };

    const close = ()  => {
        dispatch(setWorkManager({ taskId: null, userId: null, previousState: null, newState: null, force: false, forceDep: false }));
    };

    const getTaskPath = (taskId)  => {

        let parentsPath = [],
            currentTask = taskId;

        if (accountTasks[taskId]) {

            while (accountTasks[currentTask] && accountTasks[currentTask].parent) {
                parentsPath.push(accountTasks[accountTasks[currentTask].parent].title);
                currentTask = accountTasks[currentTask].parent;
            }
            parentsPath = parentsPath.reverse().join(' / ');

            return parentsPath;
        }
        return null;
    };

    const getNextTaskForUser = (userId)  => {
        var taskList = orderedTaskList;
        var listTodo = [];

        if (typeof userId === 'string') {
            userId = [userId];
        }

        if (userId && userId.length === 1) {

            taskList.forEach((task) => {
                if (
                    !task.childrens &&
                    task.status === 'todo' &&
                    task.estimations && (task.estimations.userId?.indexOf(userId[0]) !== -1 || (typeof task.estimations.userId === 'string' && task.estimations.userId === userId[0]))
                ) {
                    var priorityInt = task.priority || 0;
                    task.shouldStartAt = parseInt(task.estimations.startAt) - parseInt(priorityInt);
                    listTodo.push(task);
                }

            });

            listTodo = _.sortBy(listTodo, 'shouldStartAt').slice(0, 1);
            if (listTodo.length) {
                return listTodo[0];
            }
        }
        return null;
    };

    const handleEnterKeySave = (e)  => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            save();
        }
    };

    const save = ()  => {
        if (workManager && workManager.taskId) {
            let previousState = workManager.previousState,
                newState = workManager.newState,
                taskId = workManager.taskId,
                userId = workManager.userId,
                userTaskInProgress = getUserTaskInProgress(),
                nextUserTask = null,
                amount = 0,
                canContinue = true;

            if (previousState !== newState) {
                tracking('Update task status', {data: { accountId: accountId, taskId, status: newState }});
            }

            if (nextUserTask && stateNextCheck) {
                tracking('Update task status', { data: { accountId: accountId, taskId: nextUserTask.id, status: 'inprogress' } });
            }

            if (newState === 'inprogress') {
                firebaseEvents.handleFirstStepsUserStartWorking({ userWorkingOnTask: userId });
            }

            if (previousState === 'inprogress') {
                userId = accountTasks[taskId].userWorking;
                nextUserTask = getNextTaskForUser(userId);


                if (nextUserTask && stateNextCheck) {
                    // check is task to start has unresolved dependencies
                    var dependencyWarning = checkDependencyWarning('todo', 'inprogress', nextUserTask.id, userId, nextUserTask);
                    if (dependencyWarning !== 'allow') {
                        if (dependencyWarning === 'block') {
                            toastr.error('The task you want to start has dependencies that are not done. You cannot start working on it.');
                        }
                        else if (dependencyWarning === 'warn') {
                            toastr.confirm('The task you want to start has dependencies that are not done. Are you sure you want to start it?', {
                                onOk: () => {
                                    setStateForceDep(true);
                                },
                                onCancel: () => {
                                    setStateForceDep(false);
                                },
                                okText: intl.formatMessage({id:'toastr.confirm'}),
                                cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
                            });
                        }
                        canContinue = false;
                    }
                }
            }

            if (canContinue) {
                let updates = {},
                    actualBoardStatus,
                    update,
                    update2,
                    task,
                    task2,
                    prevFollowers,
                    minEff,
                    maxEff,
                    toSave,
                    key;

                if ((previousState === 'todo' || previousState === 'done') && newState === 'inprogress') {
                    // set userTaskInProgress status to todo
                    update = { status: 'todo', doneAt: null };
                    task = {...accountTasks[userTaskInProgress.id]};
                    actualBoardStatus = 'none';
                    if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                        actualBoardStatus = task.board;
                    }
                    else if (task.board && accountBoards[task.board]) {
                        if (accountBoards[task.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'todo') {
                        update.board = 'todo';
                    }

                    task = _.extend(task, update);
                    // accountTasks[userTaskInProgress.id] = task;


                    // put taskId inprogress and set timer with userId as working on it
                    // Next task to work
                    task2 = {...accountTasks[taskId]};
                    if (typeof userId === 'string') {
                        userId = [userId];
                    }
                    update2 = {
                        status: 'inprogress',
                        userWorking: userId,
                        timerStart: moment().format(),
                        timerPause: null
                    };
                    if (stateForce) {
                        update2.forcedUser = userId;
                    }
                    if (!task2.startedWorkingAt) {
                        update2.startedWorkingAt = moment().format('YYYY-MM-DD');
                    }
                    actualBoardStatus = 'none';
                    if (task2.board && (task2.board === 'todo' || task2.board === 'done' || task2.board === 'inprogress')) {
                        actualBoardStatus = task2.board;
                    }
                    else if (task2.board && accountBoards[task2.board]) {
                        if (accountBoards[task2.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'inprogress') {
                        update2.board = 'inprogress';
                    }

                    task2 = _.extend(task2, update2);
                    if (!task2.followers) {
                        task2.followers = {};
                    }
                    task2.followers[userId] = true;


                    if (!stateLogCheck && task.syncAppType !== 'trello') {
                        // logtime on userTaskInProgress
                        amount = parseFloat(stateLogTimeHours) + parseFloat(stateLogTimeMinutes) / 60;

                        if (amount > 0) {
                            toSave = {
                                member: userId,
                                date: moment().toISOString(),
                                hours: amount
                            };
                            key = database.ref('/accounts/' + accountId + '/tasks/' + userTaskInProgress.id + '/workingTime').push().key;
                            if (!task.workingTime) {
                                task.workingTime = {};
                            }
                            task.workingTime[key] = toSave;

                        }
                    }

                    if (!stateEffortCheck && task.syncAppType !== 'trello') {
                        // update effort on userTaskInProgress
                        minEff = parseFloat(stateEffortMin);
                        maxEff = parseFloat(stateEffortMax);

                        if (!maxEff || parseFloat(maxEff) < parseFloat(minEff)) {
                            maxEff = minEff;

                        }
                        if(parseFloat(maxEff) === 0) {
                            maxEff = 0.25;
                        }

                        task.minEffort = parseFloat(minEff).toFixed(2);
                        task.maxEffort = parseFloat(maxEff).toFixed(2);
                    }

                    if (stateForceAssignCheck) {
                        task.forcedUser = (typeof userId === 'string') ? [userId] : userId;
                    }

                    if (workManager.boardId) {
                        task2.board = workManager.boardId;
                    }

                    updates['/accounts/' + accountId + '/tasks/' + taskId] = stripTaskProps(task2);
                    updates['/accounts/' + accountId + '/tasks/' + userTaskInProgress.id] = stripTaskProps(task);
                }

                else if (previousState === 'todo' && newState === 'done') {
                    // set Task as done

                    update = { status: 'done', doneAt: moment().format('YYYY-MM-DD'), userWorking: stateDoneByUsers, doneBy: stateDoneByUsers, forcedUser: stateDoneByUsers };
                    task = {...accountTasks[taskId]};

                    actualBoardStatus = 'none';
                    if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                        actualBoardStatus = task.board;
                    }
                    else if (task.board && accountBoards[task.board]) {
                        if (accountBoards[task.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'done') {
                        update.board = 'done';
                    }

                    if(!task.followers) {
                        task.followers = {};
                    }

                    prevFollowers = Object.keys(task?.followers || {});
                    _.uniq([...prevFollowers , ...stateDoneByUsers, currentUserId]).forEach(userId => {
                        if(_.get(task, ['followers', userId]) !== FOLLOWER_STATE_TYPES.manually) {
                            task.followers[userId] = 'inherited';
                        }
                    });

                    task = _.extend(task, update);

                    if (!stateLogCheck) {
                        // logtime on taskId

                        amount = parseFloat(stateLogTimeHours) + parseFloat(stateLogTimeMinutes) / 60;

                        if (amount > 0) {

                            toSave = {
                                member: stateDoneByUsers,
                                date: moment().toISOString(),
                                hours: amount
                            };
                            key = database.ref('/accounts/' + accountId + '/tasks/' + taskId + '/workingTime').push().key;
                            if (!task.workingTime) {
                                task.workingTime = {};
                            }
                            task.workingTime[key] = toSave;

                        }
                    }

                    updates['/accounts/' + accountId + '/tasks/' + taskId] =  stripTaskProps(task);

                }

                else if (previousState === 'inprogress' && newState === 'todo') {

                    update = { status: 'todo', doneAt: moment().format('YYYY-MM-DD') };
                    task = {...accountTasks[taskId]};

                    actualBoardStatus = 'none';
                    if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                        actualBoardStatus = task.board;
                    }
                    else if (task.board && accountBoards[task.board]) {
                        if (accountBoards[task.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'todo') {
                        update.board = 'todo';
                    }

                    task = _.extend(task, update);


                    if (!stateLogCheck && task.syncAppType !== 'trello') {
                        // logtime on taskId

                        amount = parseFloat(stateLogTimeHours) + parseFloat(stateLogTimeMinutes) / 60;

                        if (amount > 0) {

                            toSave = {
                                member: userId,
                                date: moment().toISOString(),
                                hours: amount
                            };
                            key = database.ref('/accounts/' + accountId + '/tasks/' + taskId + '/workingTime').push().key;
                            if (!task.workingTime) {
                                task.workingTime = {};
                            }
                            task.workingTime[key] = toSave;

                        }
                    }

                    if (!stateEffortCheck && task.syncAppType !== 'trello') {
                        // update effort on userTaskInProgress
                        minEff = parseFloat(stateEffortMin);
                        maxEff = parseFloat(stateEffortMax);

                        if (!maxEff || parseFloat(maxEff) < parseFloat(minEff)) {
                            maxEff = minEff;
                        }

                        if(parseFloat(maxEff) === 0) {
                            maxEff = 0.25;
                        }

                        task.minEffort = parseFloat(minEff).toFixed(2);
                        task.maxEffort = parseFloat(maxEff).toFixed(2);
                    }

                    if (stateForceAssignCheck) {
                        task.forcedUser = (typeof userId === 'string') ? [userId] : userId;
                    }

                    if (nextUserTask && stateNextCheck) {
                        // put taskId inprogress and set timer with userId as working on it

                        task2 = {...accountTasks[getNextTaskForUser(userId).id]};
                        if (typeof userId === 'string') {
                            userId = [userId];
                        }
                        update2 = {
                            status: 'inprogress',
                            userWorking: userId,
                            timerStart: moment().format(),
                            timerPause: null
                        };
                        if (stateForce) {
                            update2.forcedUser = userId;
                        }
                        if (!task2.startedWorkingAt) {
                            update2.startedWorkingAt = moment().format('YYYY-MM-DD');
                        }
                        actualBoardStatus = 'none';
                        if (task2.board && (task2.board === 'todo' || task2.board === 'done' || task2.board === 'inprogress')) {
                            actualBoardStatus = task2.board;
                        }
                        else if (task2.board && accountBoards[task2.board]) {
                            if (accountBoards[task2.board].status !== 'todo') {
                                actualBoardStatus = 'inprogress';
                            }
                            else {
                                actualBoardStatus = 'todo';
                            }
                        }

                        if (actualBoardStatus !== 'inprogress') {
                            update2.board = 'inprogress';
                        }

                        task2 = _.extend(task2, update2);
                        if (!task2.followers) {
                            task2.followers = {};
                        }
                        task2.followers[userId] = true;

                        updates['/accounts/' + accountId + '/tasks/' + getNextTaskForUser(userId).id] = stripTaskProps(task2);
                    }

                    if (workManager.boardId) {
                        task.board = workManager.boardId;
                    }

                    updates['/accounts/' + accountId + '/tasks/' + taskId] = stripTaskProps(task);
                }

                else if (previousState === 'inprogress' && newState === 'done') {
                    update = { status: 'done', doneAt: moment().format('YYYY-MM-DD') };
                    task = {...accountTasks[taskId]};

                    actualBoardStatus = 'none';
                    if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                        actualBoardStatus = task.board;
                    }
                    else if (task.board && accountBoards[task.board]) {
                        if (accountBoards[task.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'done') {
                        update.board = 'done';
                    }
                    if(!task.followers) {
                        task.followers = {};
                    }

                    prevFollowers = Object.keys(task?.followers || {});
                    _.uniq([...prevFollowers , ...stateDoneByUsers, currentUserId]).forEach(userId => {
                        if(_.get(task, ['followers', userId]) !== FOLLOWER_STATE_TYPES.manually) {
                            task.followers[userId] = 'inherited';
                        }
                    });

                    task = _.extend(task, update);

                    if (!stateLogCheck) {
                        // logtime on taskId

                        amount = parseFloat(stateLogTimeHours) + parseFloat(stateLogTimeMinutes) / 60;

                        if (amount > 0) {

                            toSave = {
                                member: userId,
                                date: moment().toISOString(),
                                hours: amount
                            };
                            key = database.ref('/accounts/' + accountId + '/tasks/' + taskId + '/workingTime').push().key;
                            if (!task.workingTime) {
                                task.workingTime = {};
                            }
                            task.workingTime[key] = toSave;

                        }
                    }

                    updates['/accounts/' + accountId + '/tasks/' + taskId] = stripTaskProps(task);

                    if (nextUserTask && stateNextCheck) {
                        // put taskId inprogress and set timer with userId as working on it

                        task2 = {...accountTasks[getNextTaskForUser(userId).id]};
                        if (typeof userId === 'string') {
                            userId = [userId];
                        }
                        update2 = {
                            status: 'inprogress',
                            userWorking: userId,
                            timerStart: moment().format(),
                            timerPause: null
                        };
                        if (stateForce) {
                            update2.forcedUser = userId;
                        }
                        if (!task2.startedWorkingAt) {
                            update2.startedWorkingAt = moment().format('YYYY-MM-DD');
                        }
                        actualBoardStatus = 'none';
                        if (task2.board && (task2.board === 'todo' || task2.board === 'done' || task2.board === 'inprogress')) {
                            actualBoardStatus = task2.board;
                        }
                        else if (task2.board && accountBoards[task2.board]) {
                            if (accountBoards[task2.board].status !== 'todo') {
                                actualBoardStatus = 'inprogress';
                            }
                            else {
                                actualBoardStatus = 'todo';
                            }
                        }

                        if (actualBoardStatus !== 'inprogress') {
                            update2.board = 'inprogress';
                        }

                        task2 = _.extend(task2, update2);
                        if (!task2.followers) {
                            task2.followers = {};
                        }
                        task2.followers[userId] = true;

                        updates['/accounts/' + accountId + '/tasks/' + getNextTaskForUser(userId).id] = stripTaskProps(task2);
                    }
                }

                firebaseEvents.db.update(undefined, updates);

                dispatch(setWorkManager({
                    taskId: null,
                    userId: null,
                    previousState: null,
                    newState: null,
                    force: false,
                    forceDep: false
                }));
            }
        }
    };

    const checkDependencyWarning = (previousState, newState, taskId, userId, task)  => {
        var setting = 'warn';
        if (dependenciesBlockSetting) {
            setting = dependenciesBlockSetting;
        }
        if (setting !== 'allow' && previousState === 'todo' && newState === 'inprogress' && task.dependencies) {
            var unfinishedDependencies = [];
            task.dependencies.forEach((dep) => {
                if (accountTasks[dep] && accountTasks[dep].status !== 'done') {
                    unfinishedDependencies.push(dep);
                }
            });

            if (unfinishedDependencies.length) {
                return setting;
            }
        }

        return 'allow';
    };

    const handleDoneUsersInput = (_, values)  => {
        const taskId = workManager.taskId;
        const task = accountTasks[taskId];
        const limit = task.nbWorkers ? task.nbWorkers : 1;

        if (values.length <= limit) {
            setStateDoneByUsers(values.map(el => el.userId));
            return;
        }

        if (limit === 1 && values.length > 1) {
            setStateDoneByUsers([values[1].userId]);
            return;
        }

        if (limit > 1 && values.length > limit) {
            toastr.error('Limit reachead, remove one to be able to add another.');
        }
    };

    if (workManager && workManager.taskId) {
        let previousState = workManager.previousState,
            newState = workManager.newState,
            taskId = workManager.taskId,
            userId = workManager.userId,
            task = accountTasks[taskId] || null;


        if (previousState === 'inprogress') {
            userId = task.userWorking;
        }
        let dialogTitle,
            actualBoardStatus,
            update,
            userTaskInProgress = getUserTaskInProgress(),
            nextTaskTitle = 'Next task on your plate',
            nextTask,
            hideLogTime = false,
            hideForceAssign = true,
            hideEffortLeft = false,
            isNotUser = task?.estimations?.userId && !task?.estimations?.userId.includes(userId) && task?.estimations?.userId !== userId,
            name = (currentUserId !== userId) ? ((accountUsers[userId]) ? accountUsers[userId].displayName : '') : 'You',
            nameR = (currentUserId !== userId) ? ((accountUsers[userId]) ? accountUsers[userId].displayName : '') : 'your',
            verb = (currentUserId !== userId) ? 'was' : 'were',
            multiWorker = !!(task.nbWorkers && task.nbWorkers > 1);

        const userDoneByLimit = task.nbWorkers ? task.nbWorkers : 1;

        if (multiWorker) {
            if (task.status === 'inprogress' || !task?.estimations?.userId) {
                userId = task?.userWorking;
            }
            else {
                userId = task?.estimations?.userId;
            }

            if(_.isArray(userId)) {
                name = userId.reduce((acc, id) => {
                    if(accountUsers[id]) {
                        acc.push(accountUsers[id]?.displayName || '');
                    }
                    return acc;
                }, []).join('/');
                verb = 'were';
            }
        }

        if (!stateForceDep) {
            var dependencyWarning = checkDependencyWarning(previousState, newState, taskId, userId, task);

            if (dependencyWarning !== 'allow') {
                if (dependencyWarning === 'block') {
                    toastr.error('The task you want to start has dependencies that are not done. You cannot start working on it.');
                    close();
                }
                else if (dependencyWarning === 'warn') {
                    toastr.confirm('The task you want to start has dependencies that are not done. Are you sure you want to start it?', {
                        onOk: () => {
                            setStateForceDep(true);
                        },
                        onCancel: () => {
                            close();
                        },
                        okText: intl.formatMessage({id:'toastr.confirm'}),
                        cancelText: intl.formatMessage({id:'toastr.confirm.cancel'})
                    });
                }
                return null;
            }
        }

        if ((previousState === 'todo' || previousState === 'done') && newState === 'inprogress') {
            if (newState === 'inprogress') {
                firebaseEvents.handleFirstStepsUserStartWorking({ userWorkingOnTask: userId });
            }

            if (multiWorker) {

                task = {...accountTasks[taskId]};


                if (typeof userId === 'string') {
                    userId = [userId];
                }
                update = {
                    status: 'inprogress',
                    userWorking: (typeof userId === 'string') ? [userId] : userId,
                    timerStart: moment().format(),
                    timerPause: null
                };

                if (!task.startedWorkingAt) {
                    update.startedWorkingAt = moment().format('YYYY-MM-DD');
                }

                actualBoardStatus = 'none';
                if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                    actualBoardStatus = task.board;
                }
                else if (task.board && accountBoards[task.board]) {
                    if (accountBoards[task.board].status !== 'todo') {
                        actualBoardStatus = 'inprogress';
                    }
                    else {
                        actualBoardStatus = 'todo';
                    }
                }

                if (actualBoardStatus !== 'inprogress') {
                    update.board = 'inprogress';
                }
                task = _.extend(task, update);
                if (!task.followers) {
                    task.followers = {};
                }

                const users = (typeof userId === 'string') ? [userId] : userId;

                if (users && users.length) {
                    users.forEach(u => {
                        task.followers[u] = true;
                    });
                }

                if (workManager.boardId) {
                    task.board = workManager.boardId;
                }

                const difference = diff(accountTasks[taskId], task);
                if (difference && Object.keys(difference).length > 1) {
                    firebaseEvents.db.set('/accounts/' + accountId + '/tasks/' + taskId, stripTaskProps(task));
                }

                close();
            }
            else {

                if (isNotUser && !stateForce) {
                    toastr.confirm(intl.formatMessage({id:'who.working.different.prediction.force'}), {
                        onOk: () => {
                            setStateForce(true);
                        },
                        onCancel: () => {
                            close();
                        },
                        okText: intl.formatMessage({id:'toastr.confirm.force.and.start'}),
                        cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
                    });

                    return null;
                }

                if (userTaskInProgress) {
                    dialogTitle = <div><strong>{name}</strong> {verb} working on <strong>{userTaskInProgress.title}</strong>. Its status will be changed to <strong>To be done</strong></div>;
                    nextTaskTitle = name + ' will start working on';
                    nextTask = accountTasks[taskId];
                    nextTask.id = taskId;
                    hideForceAssign = false;
                }
                else {


                    task = {...accountTasks[taskId]};


                    if (typeof userId === 'string') {
                        userId = [userId];
                    }
                    update = {
                        status: 'inprogress',
                        userWorking: (typeof userId === 'string') ? [userId] : userId,
                        timerStart: moment().format(),
                        timerPause: null
                    };
                    if (isNotUser) {
                        update.forcedUser = userId;
                    }

                    if (!task.startedWorkingAt) {
                        update.startedWorkingAt = moment().format('YYYY-MM-DD');
                    }

                    actualBoardStatus = 'none';
                    if (task.board && (task.board === 'todo' || task.board === 'done' || task.board === 'inprogress')) {
                        actualBoardStatus = task.board;
                    }
                    else if (task.board && accountBoards[task.board]) {
                        if (accountBoards[task.board].status !== 'todo') {
                            actualBoardStatus = 'inprogress';
                        }
                        else {
                            actualBoardStatus = 'todo';
                        }
                    }

                    if (actualBoardStatus !== 'inprogress') {
                        update.board = 'inprogress';
                    }
                    if(!task.followers) {
                        task.followers = {};
                    }

                    const newFollowers = _.isArray(userId) ? [...userId, currentUserId] : [userId, currentUserId];
                    const prevFollowers = Object.keys(task?.followers);
                    _.uniq([...prevFollowers , ...newFollowers]).forEach(userId => {
                        if(_.get(task, ['followers', userId]) !== FOLLOWER_STATE_TYPES.manually) {
                            task.followers[userId] = 'inherited';
                        }
                    });

                    if (workManager.boardId) {
                        update.board = workManager.boardId;
                        task.board = workManager.boardId;
                    }

                    task = _.extend(task, update);

                    const difference = diff(accountTasks[taskId], task);
                    if (difference && Object.keys(difference).length > 1) {
                        firebaseEvents.db.set('/accounts/' + accountId + '/tasks/' + taskId, stripTaskProps(task));
                    }

                    close();
                    return null;
                }
            }

        }
        else if (previousState === 'todo' && newState === 'done') {
            dialogTitle = <div><strong>{task.title}</strong> will be marked as <strong>done</strong></div>;
            hideEffortLeft = true;
        }
        else if (previousState === 'inprogress' && newState === 'todo') {
            dialogTitle = <div><strong>{name}</strong> {verb} working on <strong>{task.title}</strong>. Its status will be changed to <strong>To be done</strong></div>;
            nextTaskTitle = `Next task on ${nameR}${nameR.endsWith('s') ? '' : '\'s'} plate`;
            nextTask = getNextTaskForUser(userId);
            userTaskInProgress = task;
            hideForceAssign = false;
        }
        else if (previousState === 'inprogress' && newState === 'done') {
            dialogTitle = <div><strong>{task.title}</strong> will be marked as <strong>done</strong></div>;
            hideEffortLeft = true;
            nextTaskTitle = `Next task on ${nameR}${nameR.endsWith('s') ? '' : '\'s'} plate`;
            nextTask = getNextTaskForUser(userId);
            userTaskInProgress = task;
        }

        if(task.syncAppType === 'trello'){
            hideLogTime = true;
            hideEffortLeft = true;
        }

        let logTime = null;
        if (!hideLogTime) {

            logTime = (
                <div className="logTime">
                    <h3>Log time worked {(multiWorker) ? '(for each assignee)' : null}</h3>
                    <div className="flex">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={stateLogTimeHours}
                            onBlur={
                                (e) => {
                                    var hoursDec = parseFloat(moment.duration({
                                        hours: e.target.value,
                                        minutes: stateLogTimeMinutes
                                    }).asHours().toFixed(2));
                                    var effortLeft = getEffortLeftCalc(hoursDec, userTaskInProgress);
                                    var fieldVal = parseInt(e.target.value);
                                    if (isNaN(fieldVal)) { fieldVal = 0; }
                                    setStateLogTimeHours(fieldVal);
                                    setStateEffortMin(effortLeft.minEffort);
                                    setStateEffortMax(effortLeft.maxEffort);
                                }
                            }
                            onChange={(e) => setStateLogTimeHours(e.target.value)}
                        />
                        <span>hours</span>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            inputProps={{ min: 0, max: 60, step: 15 }}
                            value={stateLogTimeMinutes}
                            onBlur={
                                (e) => {
                                    let hoursVal = stateLogTimeHours;
                                    var minutesVal = parseInt(e.target.value);
                                    if (isNaN(minutesVal)) { minutesVal = 0; }
                                    if (minutesVal === 60) {
                                        minutesVal = 0;
                                        hoursVal++;
                                    }
                                    var hoursDec = parseFloat(moment.duration({
                                        hours: hoursVal,
                                        minutes: minutesVal
                                    }).asHours().toFixed(2));
                                    var effortLeft = getEffortLeftCalc(hoursDec, userTaskInProgress);

                                    setStateLogTimeHours(hoursVal);
                                    setStateLogTimeMinutes(minutesVal);
                                    setStateEffortMin(effortLeft.minEffort);
                                    setStateEffortMax(effortLeft.maxEffort);
                                }
                            }
                            onChange={(e) => setStateLogTimeMinutes(e.target.value)}
                        />
                        <span>minutes</span>
                    </div>
                    <FormControlLabel
                        label="Do not log time worked"
                        control={<Checkbox color="primary" checked={stateLogCheck} onChange={handleDisableLogTimeWorked} />}
                    />
                </div>
            );
        }

        let effortLeft;
        if (!hideEffortLeft) {
            effortLeft = (
                <div className="effortLeft">
                    <h3>Effort left <small>(hours)</small></h3>
                    <div className="flex">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            label="Min."
                            inputProps={{ min: 0, step: 0.25 }}
                            value={stateEffortMin}
                            onBlur={(e) => {
                                var fieldVal = parseInt(e.target.value);
                                if (isNaN(fieldVal)) { fieldVal = 0; }
                                setStateEffortMin(fieldVal);
                            }}
                            onChange={(e) => setStateEffortMin(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            label="Max."
                            inputProps={{ min: 0, step: 0.25 }}
                            value={stateEffortMax}
                            onBlur={(e) => {
                                var fieldVal = parseInt(e.target.value);
                                if (isNaN(fieldVal)) { fieldVal = 0; }
                                setStateEffortMax(fieldVal);
                            }}
                            onChange={(e) => setStateEffortMax(e.target.value)}
                        />
                    </div>
                    <FormControlLabel control={
                        <Checkbox color="primary" checked={stateEffortCheck} onChange={(e) => {
                            isLocalStorageAvailable && localStorage.setItem('effortCheck', e.target.checked.toString());
                            setStateEffortCheck(e.target.checked);
                        }} />
                    } label="Do not change effort left" />
                </div>
            );
        }

        let forceAssign;
        if (!hideForceAssign) {
            forceAssign = (
                <div className="forceAssign">
                    <FormControlLabel control={
                        <Checkbox color="primary" checked={stateForceAssignCheck} onChange={handleKeepTaskForcedAssignTo} />
                    } label={'Keep task force assigned to ' + name} />
                </div>
            );
        }

        if (nextTask) {

            nextTask = (
                <div className="container">
                    <div className="nextTask">
                        <h3>{nextTaskTitle}</h3>
                        <div className="flex">
                            <Card variant="outlined" className="taskCard">
                                <CardContent>
                                    <div className="taskCardDetails">
                                        <h2>{getTaskPath(nextTask.id)}</h2>
                                        <h1>{nextTask.title}</h1>
                                    </div>
                                </CardContent>
                            </Card>
                            {!((previousState === 'todo' || previousState === 'done') && newState === 'inprogress') && (
                                <FormControlLabel control={
                                    <Checkbox color="primary" checked={stateNextCheck} onChange={(e) => {
                                        isLocalStorageAvailable && localStorage.setItem('nextCheck', e.target.checked.toString());
                                        setStateNextCheck(e.target.checked);
                                    }} />
                                } label="Start working on this task" />
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Dialog
                className='WorkManager'
                open={true}
                onClose={close}
                maxWidth={false}
                onKeyUp={handleEnterKeySave}
            >
                <DialogTitle className="WorkManagerTitle">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent className="content">
                    <div className="container">
                        {logTime}
                        {effortLeft}
                    </div>
                    {forceAssign}
                    {nextTask}
                    {
                        (previousState === 'todo' && newState === 'done') && <div className="container suportLegacy">
                            <DoneBySelection
                                onChange={handleDoneUsersInput}
                                taskId={taskId}
                                values={stateDoneByUsers}
                                disabled={stateDoneByUsers.length === userDoneByLimit}
                            />
                        </div>
                    }
                </DialogContent>

                <DialogActions className="actions">
                    <Button variant="contained" onClick={close}>{intl.formatMessage({id:'Cancel'})}</Button>
                    <Button variant="contained" onClick={save} color="primary">{intl.formatMessage({id:'Save Changes'})}</Button>
                </DialogActions>

            </Dialog>
        );
    }

    return null;

};

export default WorkManager;

