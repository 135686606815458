import { useEffect, useState } from 'react';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { getAccountTasks } from '../../selectors/account';
import { getParentChildIds } from '../../selectors/app';
import { getAccountDataSyncApps } from '../../selectors/accountData';
import getSyncAppData from './syncApps';

const useSyncData = taskId => {
    const stateTasks = useSelector(getAccountTasks);
    const parentChildIds = useSelector(getParentChildIds);
    const syncApps = useSelector(getAccountDataSyncApps);

    const parents = _.get(parentChildIds, ['parents', taskId], []);

    const [data, setData] = useState(getSyncAppData(
        taskId,
        stateTasks,
        parents,
        syncApps
    ));

    useEffect(() => {
        setData(getSyncAppData(
            taskId,
            stateTasks,
            parents,
            syncApps
        ));
    }, [taskId]);

    return data;
};

export default useSyncData;