import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import {toastr} from 'react-redux-toastr';
import firebase from 'firebase/app';
import 'firebase/auth';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const AuthAction = ({location}) =>{
    const intl = useIntl();
    const [content, setContent] = useState('');
    const [linkText, setLinkText] = useState('');
    const [showPassForm, setShowPassForm] = useState(false);
    const password = useRef();

    const getParameterByName = useCallback((param) => {
        return (location?.query[param]) ? location.query[param]:null;
    }, [location]);

    const handleLogin = (token) => {
        setContent('Please wait while we try to log you in...');
        firebase.auth().signOut().then(()=>{
            for(var i in localStorage){
                if(i.indexOf('firebase:authUser') !== -1){
                    localStorage.removeItem(i);
                }
            }
            $.ajax({
                url: 'https://ot.planless.io/checkLoginToken',
                method: 'POST',
                data: {
                    token: token
                },
                async: true,
                crossDomain: true
            })
                .done((result)=>{
                    
                    firebase.auth().signInWithCustomToken(result.token).then(()=>{
                        browserHistory.push('/login');
                    }).catch(function(error) {
                        toastr.error(error.message);
                        browserHistory.push('/login');
                    });
                })
                .fail((error)=>{
                    toastr.error(error.responseJSON.error);
                    browserHistory.push('/login');
                });

        });
    };


    const updatePwd = (e) => {
        e.preventDefault();
        var actionCode = getParameterByName('oobCode'),
            accountEmail,
            newPassword = password.current.children[1].children[0].value;

        firebase.auth().verifyPasswordResetCode(actionCode).then(function(email) {
            accountEmail = email;

            // Save the new password.
            firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function() {
                setContent(intl.formatMessage({id:'Your password has been changed!'}));
                setLinkText('Go to Planless');
                setShowPassForm(false);
                firebase.auth().signInWithEmailAndPassword(accountEmail, newPassword);
            }).catch(function(error) {
                toastr.error(intl.formatMessage({id:'error.title'}), intl.formatMessage({id:error.code}));
            });
        }).catch(function() {
            setContent(intl.formatMessage({id:'There was an error on changing your password! Please try again.'}));
        });
    };

    const changePasswordForm = () => {
        return (showPassForm) ? (
            <form onSubmit={updatePwd}>
                <TextField 
                    autoFocus
                    type="password" 
                    label={intl.formatMessage({id:'Please enter your new password'})} 
                    ref={password}
                    margin="normal"
                    fullWidth={true}
                />

                <Button 
                    variant="contained"
                    className="changeBtn"
                    color="primary"
                    type="submit"
                >
                    {intl.formatMessage({id:'Change my password'})} 
                </Button>
            </form>
        ) : null;
    };


    const handleResetPassword = (actionCode) => {

        if(!actionCode){
            setContent(intl.formatMessage({id:'There was an error on changing your password! Please try again.'}));
            setLinkText(intl.formatMessage({id:'Login'}));
        }
        else {
            firebase.auth().verifyPasswordResetCode(actionCode).then(function() {
                setContent('');
                setLinkText('');
                setShowPassForm(true);
            }).catch(function() {
                setContent(intl.formatMessage({id:'There was an error on changing your password! Please try again.'}));
                setLinkText(intl.formatMessage({id:'Login'}));
            });
        }
    };

    const handleRecoverEmail = (actionCode) => {
        var restoredEmail = null;

        if(!actionCode){
            setContent(intl.formatMessage({id:'There was an error recovering your email! Please try again.'}));
            setLinkText(intl.formatMessage({id:'Login'}));
        }
        else {
            firebase.auth().checkActionCode(actionCode).then(function(info) {
                restoredEmail = info['data']['email'];

                return firebase.auth().applyActionCode(actionCode);
            }).then(function() {
                setContent(intl.formatMessage({id:'Your email has been reverted to'}, {email: restoredEmail}));
                setLinkText(intl.formatMessage({id:'Login'}));
            }).catch(function() {
                setContent(intl.formatMessage({id:'There was an error recovering your email! Please try again.'}));
                setLinkText(intl.formatMessage({id:'Login'}));
            });
        }
    };

    const handleVerifyEmail = (actionCode) => {
        
        if(!actionCode){
            setContent(intl.formatMessage({id:'There was an error verifing your email! Please try again.'}));
            setLinkText(intl.formatMessage({id:'Login'}));
        }
        else {
            firebase.auth().applyActionCode(actionCode).then(function() {
                setContent(intl.formatMessage({id:'Your email has been verified!'}));
                setLinkText(intl.formatMessage({id:'Login'}));
                browserHistory.push('/login');
                setTimeout(()=>{ document.location.reload(); }, 300);

            }).catch(function() {
                setContent(intl.formatMessage({id:'There was an error verifing your email! Please try again.'}));
                setLinkText(intl.formatMessage({id:'Login'}));
            });
        }
    };

    useEffect(()=>{
        var mode = getParameterByName('mode'),
            actionCode = getParameterByName('oobCode');

        switch (mode) {
        case 'resetPassword':
            handleResetPassword(actionCode);
            break;
        case 'recoverEmail':
            handleRecoverEmail(actionCode);
            break;
        case 'verifyEmail':
            handleVerifyEmail(actionCode);
            break;
        case 'login':
            handleLogin(actionCode);
            break;
        default:
            setContent(intl.formatMessage({id:'Something went wrong!'}));
        }
    }, []);


    return (
        <div className="AuthAction">
            <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
            <div>{content}</div>
            {changePasswordForm()}
            {(linkText !== '') && <Link to="/login">{linkText}</Link>}
        </div>
    );

};

export default withRouter(withCustomErrorBoundary(AuthAction));