import Proptypes from 'prop-types';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useIntl } from 'react-intl';
import { DateRange } from 'react-date-range';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { DatePicker } from '@material-ui/pickers';
import classnames from 'clsx';

const getDateFilter = (type, maxMinRange, userTimezone) => {
    switch(type){
    case 'lastSevenDays':
        return [moment().tz(userTimezone).subtract(7, 'days'), moment().tz(userTimezone)]; 
    case 'lastThirty':
        return [moment().tz(userTimezone).subtract(30, 'days'), moment().tz(userTimezone)]; 
    case 'lastMonth':
        return [moment().tz(userTimezone).subtract(1, 'M').startOf('month'), moment().tz(userTimezone).subtract(1, 'M').endOf('month')]; 
    case 'lastQuarter':
        return [moment().tz(userTimezone).subtract(1, 'quarter').startOf('quarter'), moment().tz(userTimezone).subtract(1, 'quarter').endOf('quarter')]; 
    case 'currentQuarter':
        return [moment().tz(userTimezone).startOf('quarter'), moment().tz(userTimezone).endOf('quarter')]; 
    case 'lastYear':
        return [moment().tz(userTimezone).subtract(1, 'Y').startOf('Year'), moment().tz(userTimezone).subtract(1, 'Y').endOf('Year')]; 
    case 'currentYear':
        return [moment().tz(userTimezone).startOf('Year'), moment().tz(userTimezone).endOf('Year')]; 
    case 'allData':
        return [maxMinRange.startDate, maxMinRange.endDate]; 
    default:
        return [moment().tz(userTimezone).subtract(1, 'M'), moment().tz(userTimezone).subtract(1, 'M')];
    }
};
const getDropdownOptions = (intl) => [
    {
        value: 'lastSevenDays',
        name: intl.formatMessage({id: 'form.select.date.last-7'}),
    },
    {
        value: 'lastThirty',
        name: intl.formatMessage({id: 'form.select.date.last-30'}),
    },
    {
        value: 'lastMonth',
        name: intl.formatMessage({id: 'form.select.date.last-month'}),
    },
    {
        value: 'lastQuarter',
        name: intl.formatMessage({id: 'form.select.date.last-quarter'}),
    },
    {
        value: 'currentQuarter',
        name: intl.formatMessage({id: 'form.select.date.current-quarter'}),
    },
    {
        value: 'lastYear',
        name: intl.formatMessage({id: 'form.select.date.last-year'}),
    },
    {
        value: 'currentYear',
        name: intl.formatMessage({id: 'form.select.date.current-year'}),
    },
    {
        value: 'custom',
        name: intl.formatMessage({id: 'form.select.date.Custom'}),
    },
    {
        value: 'allData',
        name: intl.formatMessage({id: 'form.select.date.all-data'}),
    },
];
const DateFilter = ({ maxMinRange, onChange, dateFormat, startDate, endDate, enabled, userTimezone }) => {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateFilterSelected, setDateFilterSelected] =  useState('allData'); 
    const [startDateModal, setStartDateModal] =  useState(moment().tz(userTimezone)); 
    const [endDateModal, setEndDateModal] =  useState(moment().tz(userTimezone)); 
    const dropdownRef =  useRef(); 
    const [customDateFilter, setCustomDateFilter] =  useState(''); 
    const dropdownOptions = getDropdownOptions(intl);

    useEffect(() => {
        setStartDateModal(startDate);
        setEndDateModal(endDate);
        setCustomDateFilter(`${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`);
    }, [startDate, endDate]);

    const handlePopover = useCallback((event) => {
        if(!enabled) {
            return ;
        }
        
        setAnchorEl(event.currentTarget);
    }, [enabled]);

    const handleDateFilterChange = useCallback((e)=> {
        const value = e?.target?.value;
        const [start, end] = getDateFilter(value, maxMinRange, userTimezone);
        setDateFilterSelected(value);
        setStartDateModal(start);
        setEndDateModal(end);
    }, [maxMinRange]);

    const handleCloseDialog = useCallback(() => { setAnchorEl(null); }, []);
    
    const handleDialogConfirm = useCallback(() => {
        setAnchorEl(null);
        setCustomDateFilter(`${moment(startDateModal).format(dateFormat)} - ${moment(endDateModal).format(dateFormat)}`);
        onChange({startDate: startDateModal, endDate: endDateModal});
    }, [startDateModal,dateFormat, endDateModal]);

    const handleStartDate = useCallback((date) =>   {
        setDateFilterSelected('custom');
        setStartDateModal(date);
    }, []);
    
    const handleEndDate = useCallback((date) =>   {
        setDateFilterSelected('custom');
        setEndDateModal(date);
    }, []);
    
    const handleDateRangeSelector =  useCallback((date) =>   {
        setDateFilterSelected('custom');
        setStartDateModal(date.startDate);
        setEndDateModal(date.endDate);
    }, []);

    return <>
        <div 
            className={classnames('dateRangeContainer',{ blocked: !enabled })}
            onClick={handlePopover}>
            {customDateFilter}
            <ArrowDropDown />
        </div>
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            onClose={handleCloseDialog}
            className="container dateRangeSelector"
            onKeyUp={(e) => e.key === 'Enter' || e.keyCode === 13 ? handleDialogConfirm() : null}
        >   
            {
                anchorEl && <div className="container">
                    <div className="controll">
                        <Select
                            innerRef={dropdownRef}
                            labelId="date-range-filter"
                            value={dateFilterSelected}
                            onChange={handleDateFilterChange}
                            size="small"
                        >   
                            {dropdownOptions.map(({value, name}) => (
                                <MenuItem className="dateRangeSelectOption" key={value + name} value={value}>
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <DatePicker
                            className="datePicker"
                            format={dateFormat}
                            margin="normal"
                            label={'From'}
                            value={startDateModal}
                            onChange={handleStartDate}
                            autoOk={true}
                            cancelLabel={null}
                            okLabel={null}
                            size="small"
                        />
                        <DatePicker
                            className="datePicker"
                            format={dateFormat}
                            margin="normal"
                            label={'To'} 
                            onChange={handleEndDate}
                            autoOk={true}
                            value={endDateModal}
                            cancelLabel={null}
                            okLabel={null}
                            size="small"
                        />
                        <Button className={'confirm-button'} variant="contained" onClick={handleDialogConfirm} color="secondary">{intl.formatMessage({id: 'form.select.date.confirm'})}</Button>
                        <Button variant="contained" onClick={handleCloseDialog}>{intl.formatMessage({id: 'Cancel'})}</Button>
                    </div>
                    <div>
                        <DateRange
                            onInit={handleDateRangeSelector}
                            onChange={handleDateRangeSelector}
                            startDate={startDateModal}
                            endDate={endDateModal}
                            linkedCalendars
                            calendars={3}
                        />
                    </div>
                </div>
            }
        </Popover>
    </>;
};

DateFilter.propTypes = {
    dateFormat: Proptypes.string.isRequired,
    onChange: Proptypes.func.isRequired,
};
export default React.memo(DateFilter);