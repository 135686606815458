import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {canSee} from '../../utils/userFunctions';
import { getAccountDependenciesBlock, getAccountId, getAccountName } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { browserHistory } from 'react-router';

const LISTBOX_PADDING = 8;

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};


const WorkspaceSettings = ()=>{
    const intl = useIntl();

    const name = useSelector(getAccountName);
    const dependenciesBlock = useSelector(getAccountDependenciesBlock);
    const accountId = useSelector(getAccountId);
    const userPermissions = useSelector(getUserPermissions);

    const [stateName, setStateName] = useState(name || '');

    useEffect(()=>{
        if(stateName === '' && name){
            setStateName(name);
        }
    }, [name]);

    const changeName = (e) => {
        setStateName(e.target.value);
    };

    const saveName = () => {

        firebaseEvents.addChallenge('renameWorkspace');
        firebaseEvents.changeWorkspaceName(
            accountId,
            stateName
        );
    };

    const changeDependencySetting = (e)=>{
        firebaseEvents.changeDependencySetting(e.target.value);
    };

    const getDependencySetting = () => {

        return (
            <RadioGroup aria-label="gender" name="dependency" value={dependenciesBlock || 'warn'} onChange={changeDependencySetting}>
                <FormControlLabel value="allow" control={<Radio size='small' />} label="Allow to start working" />
                <FormControlLabel value="warn" control={<Radio size='small' />} label="Warn and ask for confirmation" />
                <FormControlLabel value="block" control={<Radio size='small' />} label="Do not allow to start working" />
            </RadioGroup>
        );
    };

    if(!canSee(['admin'], { data: { permissions: userPermissions }} )) {
        browserHistory.push('/settings/my-account');
    }

    return (
        <div className='WorkspaceSettings'>
            <Typography variant="h1" gutterBottom>
                {intl.formatMessage({id:'Workspace settings'})}
            </Typography>

            <div className="settingsInnerContent">

                <TextField
                    label={intl.formatMessage({id:'Workspace name'})}
                    value={stateName}
                    onChange={changeName}
                    onBlur={saveName}
                    fullWidth={true}
                />
            </div>

            <Typography variant="h1" gutterBottom>
                {intl.formatMessage({id:'Settings'})}
            </Typography>

            <div className="settingsSection">

                <b>Dependencies</b>
                <FormLabel component="legend">When starting to work on a task that has a dependency with an uncompleted task:</FormLabel>
                {getDependencySetting()}

            </div>
        </div>
    );
};

export default withCustomErrorBoundary(WorkspaceSettings);
