import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import _ from 'underscore';
import { getAccountUsers } from '../../../../utils/selectors/account';
import { memberResourcersTypes, memberUsersTypes } from '../../../../utils/memberTypes';
import StateUserAvatar from '../../../../components/UserAvatar/StateUserAvatar';

const PermissionsBaseAutocomplete = ({
    basePermissionType,
    onChange,
    currentValues = [],
    owners = [],
    workers = [],
}) => {
    const intl = useIntl();
    const users = useSelector(getAccountUsers);

    if (!users) return null;

    const usersIds = _.map(users, (user) => user.userId);

    const handleChangeValues = (event, values, actionType) => {
        onChange({ usersIds: values, type: basePermissionType, actionType });
    };

    const shouldPreventRemove = (userId) => {
        if (users[userId]?.permissions?.admin && basePermissionType === 'owners') {
            return true;
        }
        return false;
    };

    const optionsFilteredByType = {
        owners: _.filter(usersIds, (userId) => memberUsersTypes.includes(users[userId].memberType)),
        managers: _.filter(usersIds, (userId) => !owners.includes(userId) && !workers.includes(userId)),
        workers: _.filter(usersIds, (userId) => (!workers.includes(userId)) && memberResourcersTypes.includes(users[userId].memberType)),
        viewers: _.filter(usersIds, (userId) => users[userId].memberType === 'userOnly' && !users[userId].permissions.admin),
    };

    return (
        <>
            <Typography variant="h5">{intl.formatMessage({ id: basePermissionType })}</Typography>
            <Typography variant="body2">
                {intl.formatMessage({ id: `permissions.${basePermissionType}.description` })}
            </Typography>
            <Autocomplete
                size="small"
                className={`autocomplete ${basePermissionType}`}
                multiple
                filterSelectedOptions
                options={optionsFilteredByType[basePermissionType] || []}
                value={currentValues}
                getOptionLabel={(option) => (
                    <div
                        className="optionUser"
                    >
                        <StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>
                        {(users[option]) ? users[option].displayName : ''}
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={intl.formatMessage({ id: basePermissionType })}
                        fullWidth
                    />
                )}
                renderTags={(value, getTagProps) => value.map((option, index) => {
                    if (!users[option]) return null;

                    const props = getTagProps({ index });
                    if (shouldPreventRemove(option)) {
                        props.onDelete = null;
                    }
                    return (
                        <Chip
                            className="userchip"
                            avatar={<StateUserAvatar className="avatar" userId={option} disableTooltip={true}/>}
                            variant="outlined"
                            label={(users[option]) ? users[option].displayName : ''}
                            {...props}
                        />
                    );
                })
                }
                filterOptions={(options, { inputValue }) => {
                    const results = options.reduce((acc, option) => {
                        if (users[option].displayName?.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1) {
                            acc.push(option);
                        }
                        return acc;
                    }, []);

                    return results;
                }}
                onChange={handleChangeValues}
            />
        </>
    );
};

export default PermissionsBaseAutocomplete;
