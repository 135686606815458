import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { Dialog, Button } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import Pricing from './components/Pricing';
import ResourceSlider from './components/ResourceSlider';
import useInternalization from '../../utils/hooks/useInternalization';
import { recordEvent } from '../../utils/firebaseEvents';
import { generateChargebeeCheckoutNew } from '../../utils/chargeBee';
import { getAccountChargebeeCustomerId, getAccountId } from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';

const PricingModal = ({ isOpen, onClose }) => {
    const { t } = useInternalization();
    const [resourceValue, setResourceValue] = useState('');
    const [period, setPeriod] = useState('yearly');
    const [currency, setCurrency] = useState('USD');
    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const accountId = useSelector(getAccountId);
    const currentUserId = useSelector(getUserId);

    if(!isOpen) return null;

    const isResourceEqualMore = resourceValue === 'MORE';

    const redirectToMemberSettings = () => {
        onClose(true);
        browserHistory.push('/settings/team-members');
    };

    const handleSubscription = () => {
        if (window.Chargebee && chargebeeCustomerId) {
            onClose();
            const chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.openCheckout({
                hostedPage: async () => {
                    return (

                        await generateChargebeeCheckoutNew({
                            chargebeeCustomerId,
                            workspaceId: accountId,
                            planPeriodicity: period,
                            planCurrency: currency,
                            planNbResources: resourceValue,
                        })
                    ).data;
                },
                step: (step) => {
                    recordEvent('Trial subscription step', step);
                },
                error: () => {
                    recordEvent('Checkout error');
                    toastr.error('There has been an error while contacting the payment processor. Please contact us in the chat.','');
                },
                success: () => {
                    recordEvent('SubscribedPaidPlan', accountId, currentUserId);
                },
            });
        }
    };

    return (
        <Dialog maxWidth="md" open={isOpen} aria-labelledby="customized-dialog-title" onClose={() => onClose(false)}>
            <div className="pricing-modal">
                <h3>{t('pricingModal.title')}</h3>
                <ResourceSlider {...{ setResourceValue, isResourceEqualMore, redirectToMemberSettings }} />
                <Pricing {...{
                    resourceValue, isResourceEqualMore, setPeriod, setCurrency,
                }}
                />
                <div className="bottom-btns">
                    <Button
                        className="btn-subscribe"
                        variant="contained"
                        color="secondary"
                        disabled={isResourceEqualMore}
                        onClick={handleSubscription}
                    >
                        {t('pricingModal.buttons.subscribe')}
                    </Button>
                    <Button
                        className="btn-help"
                        variant="outlined"
                        color="secondary"
                        onClick={() => { document.location.href = 'mailto:hello@planless.io'; }}
                    >
                        {t('pricingModal.buttons.help')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default React.memo(PricingModal);
