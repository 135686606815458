import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';

const TimeWorkedTable = ({ onColumnHover, datasets, labels, userDateFormat }) =>   {
    const renderColumns = useCallback(((row, isChild) => {
        const timeWorked = [{ isTitle: true, value: 'Nº Team Members' } ];
        const titleRow = [{ isName: true,  value: row.name , isChild } ];

        labels.forEach((l, idx) => {
            timeWorked.push( { value:  row.nbTeamMembers[idx], isChild } );
            titleRow.push( { value: null, isChild  } );
        });

        return [ 
            titleRow,
            timeWorked,
        ];
    }),[labels]);
    const formatTitle = (title) =>  getDateFormated(title);

    return <Table
        size="small"
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
        userDateFormat={userDateFormat} />;
};

export default React.memo(TimeWorkedTable);