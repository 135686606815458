import React, { useState } from 'react';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { Button, Dialog, FormControl, FormHelperText, IconButton, InputAdornment, Input, InputLabel, TextField, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { toastr } from 'react-redux-toastr';

export const JiraServerDataModal = ({ open, handleClose, handleConnect }) => {
    const [formState, setFormState] = useState({
        serverUrl: '',
        clientId: '',
        clientSecret: '',
        redirectUri: `${window.location.origin}${window.location.pathname}`
    });
    const [showSecret, setShowSecret] = useState(false);
    const { t } = useInternalization();

    const handleClickShowSecret = () => {
        setShowSecret(!showSecret);
    };

    const handleInputChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const handleOnClick = () => {
        handleConnect(formState);
        handleClose();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(formState.redirectUri);
        toastr.success('URL copied to clipboard');
    };

    return (
        <Dialog
            className="JiraServerAuthModal"
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title">
            <MuiDialogTitle className="title" disableTypography >
                <Typography variant="h6">Jira Server</Typography>
            </MuiDialogTitle>
            <MuiDialogContent>
                <div className="row">
                    <span className="help">
                        First you need to create your Jira Server application link and copy this url (<a className="help-url" onClick={copyToClipboard}>{ formState.redirectUri }</a>) into the `Redirect URL` field there.
                    </span>
                    <div className="form-container">
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            name="serverUrl"
                            label="Server URL"
                            placeholder="https://jira.example.com"
                            defaultValue={formState.serverUrl}
                            onChange={handleInputChange}
                            type="text"
                            className="remove-padding"
                            helperText="Please, enter the URL of your Jira Server"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Client ID"
                            name="clientId"
                            defaultValue={formState.clientId}
                            onChange={handleInputChange}
                            type="text"
                            className="remove-padding"
                            helperText="Please, enter the Client ID generated in your Jira Server"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl className="form-input-container remove-padding">
                            <InputLabel htmlFor="clientSecret" shrink>Client Secret</InputLabel>
                            <Input
                                id="clientSecret"
                                name="clientSecret"
                                margin="dense"
                                required
                                fullWidth
                                defaultValue={formState.clientSecret}
                                onChange={handleInputChange}
                                type={showSecret ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowSecret}
                                            edge="end"
                                        >
                                            {showSecret ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>Please, enter the Client secret generated in your Jira Server</FormHelperText>
                        </FormControl>
                    </div>
                </div>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    className="cancel-btn"
                    onClick={handleClose}
                    variant="contained"
                >
                    {t('CANCEL')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleOnClick}>Connect</Button>
            </MuiDialogActions>
        </Dialog>
    );
};
