import { db } from '../../firebaseEvents';
import { tracking } from '../../utils';
import { getAccountId } from '../../selectors/account';
import { getState } from '../../../configureStore';

const alertsPathBuilder = (accountId, taskId) => `/accountsData/${accountId}/tasks/${taskId}/alerts`;
export const getTaskAlerts = async taskId => {
    const data = await db.r(alertsPathBuilder(getAccountId(getState()), taskId)).once('value');
    if(!data.val()) {
        return [];
    }

    return data.val();
};

export const updateTaskAlerts = (taskId, alertId, alert) => {
    tracking('Update Task Alerts', { accountId: getAccountId(getState()), taskId, alert, alertId });

    db.set(alertsPathBuilder(getAccountId(getState()), taskId) + `/${alertId}`, alert);
};
export const updateAlert = (taskId, alertId, alert) => {
    tracking('Update Alert', { accountId: getAccountId(getState()), taskId, alert, alertId });

    return db.set(alertsPathBuilder(getAccountId(getState()), taskId) + `/${alertId}`, alert);
};

export const removeAlert = (taskId, alertId) => {
    tracking('Remove Alert', { accountId: getAccountId(getState()), taskId, alertId });

    return db.remove(alertsPathBuilder(getAccountId(getState()), taskId) + `/${alertId}`);
};

export const createAlert = (taskId, alert) => {
    tracking('Create Alert', { accountId: getAccountId(getState()), taskId, alert });

    return db.push(alertsPathBuilder(getAccountId(getState()), taskId), alert);
};