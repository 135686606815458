import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import _ from 'underscore';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Switch, Checkbox, Tooltip } from '@material-ui/core';
import { amountChildrenImportingSubTasks, isLineChecked, isLineIndeterminate } from '../../utils';
import { TREE_LEVELS_BY_TOOL } from '../../constants';

const LineItemByStep = ({ 
    itemData,
    handleCheckItem, 
    handleToggleImportSubtasks,
    handleExpandTreeLevel,
    orderedProjectList = [],
    parentIds = [], 
    syncList = [], 
    checkedItems = [], 
    isImportingSubtasks = false,
    step = 1,
    parent = null,
    treeStep = '',
    treeDataLoadInfo = null,
    blockExpandMore = false,
    toolName = ''
}) => {
    const [openArrow, setOpenArrow] = useState(true);
    const [loadingChildren, setLoadingChildren] = useState(false);
    const [arrowHidden, setArrowHidden] = useState(false);
    const { childs, id: itemId, name, nestedChildrenIds, type } = itemData;
    const secondLvlType = _.get(TREE_LEVELS_BY_TOOL, [toolName, 'workspace', type], null);

    const rootItemId = parentIds.filter(id => id)[0] || null;
    const childIds = childs.map(child => child.id);
    const hasChildren = !_.isEmpty(childIds);
    const alreadySynchronized = !!(syncList.find(el=>el.selectedItem===itemId) || nestedChildrenIds.some(childId=>syncList.map(el=>el.selectedItem).includes(childId)));
    const isDisabled = step === 1 && alreadySynchronized;
    const isChecked = isLineChecked(checkedItems, itemId, parentIds, hasChildren, childIds, nestedChildrenIds);
    const isIndeterminate = isLineIndeterminate(isDisabled, checkedItems, itemId, childIds, nestedChildrenIds, hasChildren);
    const childrenImportingSubTasks = amountChildrenImportingSubTasks(nestedChildrenIds, orderedProjectList, syncList);

    const isCheckBoxVisible = () => {
        if(step === 2) return false;

        if(type === 'workspace') {
            return _.get(treeDataLoadInfo, [itemId, 'allChildsLoaded'], false);
        }

        if(secondLvlType) {
            return _.get(treeDataLoadInfo, [rootItemId, 'childs', itemId, 'isLoaded'], false);
        }

        return true;
    };

    const handleCheckItemId = React.useCallback(() => {
        if(step === 2 || isDisabled || !isCheckBoxVisible()) return;

        handleCheckItem({
            item: {...itemData, childIds},
            parent: parent
        });
    }, [step, itemData, parent, childIds]);

    const handleToggleOpen = React.useCallback( async (e) => {
        e.stopPropagation();

        e.target.closest('.ProjectsTreeView').childNodes.forEach(childItem => {
            if(childItem.classList.contains('ProjectsTreeView')) {
                childItem.classList.toggle('hidden');
            }
        });

        const notPreviouslyOpen = !_.get(treeDataLoadInfo, [rootItemId, 'childs', itemId, 'isLoaded'], false);

        if(notPreviouslyOpen) {
            setLoadingChildren(true);
            await handleExpandTreeLevel(itemData);
        }

        setOpenArrow(!openArrow);
        setLoadingChildren(false);
    }, [openArrow, handleExpandTreeLevel, treeDataLoadInfo, itemData, rootItemId, itemId]);

    const handleToggleSwitch = () => handleToggleImportSubtasks(itemData);

    React.useEffect(() => {
        if(secondLvlType && treeStep === 'workspaces') {
            setOpenArrow(false);
        }

        if(!secondLvlType && !hasChildren) {
            setArrowHidden(true);
        }
    }, [type, treeStep, hasChildren]);

    const getTooltipTitle = () => alreadySynchronized ? 'Project already synchronized.' : 'Empty spaces cannot be selected.';

    return <>
        <div
            onClick={ handleCheckItemId }
            className={ classnames('SelectProject__outerline', { 
                disabled: isDisabled,
                loadingChildren: loadingChildren
            }) }
            id={ itemId }
            key={ itemId }
        >
            <div
                style={{ marginLeft: `${parentIds.length * 2}rem` }}
                className={ classnames('SelectProject__innerline', { showSwitchOnHover: step === 2 && hasChildren }) }
            >

                { !arrowHidden && openArrow && <KeyboardArrowDown className={blockExpandMore ? 'disabled' : ''} onClickCapture={ handleToggleOpen } /> }
                { !arrowHidden && !openArrow && <KeyboardArrowRight className={blockExpandMore ? 'disabled' : ''} onClickCapture={ handleToggleOpen } /> }

                <span>{ name }</span>

                { isCheckBoxVisible() && (
                    <Tooltip
                        placement={ 'right' }
                        title={ getTooltipTitle() }
                        arrow
                        disableFocusListener={ !isDisabled }
                        disableHoverListener={ !isDisabled }
                        disableTouchListener={ !isDisabled }
                    >
                        <Checkbox
                            className={ isDisabled && 'disabled' }
                            color='secondary'
                            onChange={ handleCheckItemId }
                            inputProps={ { 'aria-label': 'secondary checkbox' } }
                            checked={ isChecked }
                            indeterminate={ isIndeterminate } />
                    </Tooltip>
                ) }

                { step === 2 && !isDisabled && (
                    <Switch
                        className={ classnames('', { ghostChecked: childrenImportingSubTasks === 'some' }) }
                        checked={ isImportingSubtasks ||
                            (hasChildren && childrenImportingSubTasks === 'every' || childrenImportingSubTasks === 'some') }
                        size={ 'small' }
                        onClick={ handleToggleSwitch } />
                ) }
            </div>

        </div>
        
        {loadingChildren && 
            <div 
                style={{ marginLeft: `${!parent ? 6 : parentIds.length * 4}rem` }} 
                className="SelectProject__loadingChildren">
                <span></span>
            </div>
        }
    </>;
};

LineItemByStep.Proptypes = {
    itemData: PropTypes.object.isRequired,
    handleSelectSubTask: PropTypes.func,
    handleCheckItem: PropTypes.func.isRequired,
    handleToggleImportSubtasks: PropTypes.func,
};

export default memo(LineItemByStep);