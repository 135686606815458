/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import { render } from 'react-dom';
import Store from './configureStore';
// Application Main CSS
require('../stylesheets/main.scss');
function run() {
    // rewrite url for trello integration connect
    if (window.location.href.includes('state=trello#token=')) {
        window.location.href = window.location.href.replace('state=trello#token=', 'state=trello&token=');
    }
    render(React.createElement(Store, null), document.getElementById('root'));
}
// Check if polyfill required
if (!global.Intl) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    require.ensure([
        'intl',
        'intl/locale-data/jsonp/en.js',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ], function (require) {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        run();
    });
}
else
    run();
