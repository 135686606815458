var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Switch } from '@material-ui/core';
import { getAccountCostBudgetCurrency } from '../../../../utils/selectors/account';
import { currencyOptions, supportedCurrencies } from '../../utils/constants';
import { Currency, Recurrence } from '../../../../shared/enums';
import { getPlanPriceByResource, getPlanTotalPrice } from '../../../../utils/pricing';
import useInternalization from '../../../../utils/hooks/useInternalization';
var Pricing = function (_a) {
    var resourceValue = _a.resourceValue, isResourceEqualMore = _a.isResourceEqualMore, setPeriod = _a.setPeriod, setCurrency = _a.setCurrency;
    var t = useInternalization().t;
    var budgetCurrency = useSelector(getAccountCostBudgetCurrency);
    var _b = __read(useState(Currency.USD), 2), currentCurrency = _b[0], setCurrentCurrency = _b[1];
    var _c = __read(useState(Recurrence.YEARLY), 2), currentRecurrence = _c[0], setCurrentDuration = _c[1];
    useEffect(function () {
        var currency = supportedCurrencies.includes(budgetCurrency) ? budgetCurrency : Currency.USD;
        setCurrency(currency ? currency : Currency.USD);
        setCurrentCurrency(currency ? currency : Currency.USD);
    }, [budgetCurrency]);
    var handleSwitchChange = function (_, checked) {
        setPeriod(checked ? Recurrence.YEARLY : Recurrence.MONTHLY);
        setCurrentDuration(checked ? Recurrence.YEARLY : Recurrence.MONTHLY);
    };
    var getSwitchClass = function (recurrence) {
        var recurrenceClass = recurrence === Recurrence.MONTHLY ? 'monthly' : 'yearly';
        return "".concat(recurrenceClass, " ").concat(currentRecurrence === recurrence ? '' : 'inactive');
    };
    var handleCurrencyButton = function (currency) {
        setCurrency(currency);
        setCurrentCurrency(currency);
    };
    var getCurrencyButtonClass = function (currency) { return "".concat(currentCurrency === currency ? 'active' : ''); };
    var currencyButtons = currencyOptions.map(function (currencyOption) { return (React.createElement(Button, { key: currencyOption.currency, variant: "contained", className: getCurrencyButtonClass(currencyOption.currency), onClick: function () { return handleCurrencyButton(currencyOption.currency); } },
        currencyOption.currency,
        " ",
        currencyOption.symbol)); });
    var formattedPrice = isResourceEqualMore
        ? t('pricingModal.pricing.contactUs.title')
        : getPlanPriceByResource(currentRecurrence, currentCurrency, resourceValue);
    var getFormattedPriceResume = function () {
        if (isResourceEqualMore)
            return '';
        var priceResume = getPlanTotalPrice(currentRecurrence, currentCurrency, resourceValue);
        var per = t('pricingModal.pricing.price.per').toLowerCase();
        var recurrence = currentRecurrence === Recurrence.MONTHLY
            ? t('pricingModal.pricing.price.recurrence.month').toLowerCase()
            : t('pricingModal.pricing.price.recurrence.year').toLowerCase();
        return "".concat(priceResume, " ").concat(per, " ").concat(recurrence);
    };
    var getPeriod = function () {
        if (isResourceEqualMore)
            return t('pricingModal.pricing.contactUs.subtitle');
        var durationDescription = t('pricingModal.pricing.price.recurrence.month').toLowerCase();
        return "".concat(t('pricingModal.pricing.price.recurrence.period').toLowerCase(), " ").concat(durationDescription);
    };
    return (React.createElement("div", { className: "pricing-container" },
        !isResourceEqualMore && (React.createElement("div", { className: "variables" },
            React.createElement(Grid, { component: "label", container: true, alignItems: "center", className: "recurrence" },
                React.createElement(Grid, { item: true, className: getSwitchClass(Recurrence.MONTHLY) }, t('pricingModal.pricing.price.recurrence.monthly')),
                React.createElement(Grid, { item: true },
                    React.createElement(Switch, { size: "medium", onChange: handleSwitchChange, name: "status", checked: currentRecurrence === Recurrence.YEARLY })),
                React.createElement(Grid, { item: true, className: getSwitchClass(Recurrence.YEARLY) }, t('pricingModal.pricing.price.recurrence.yearly'))),
            React.createElement("div", { className: "currencies" }, currencyButtons))),
        React.createElement("div", { className: "price" },
            React.createElement("span", { className: "price-value" }, formattedPrice),
            React.createElement("span", { className: "price-recurrence" }, getPeriod()),
            !isResourceEqualMore && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "price-resume" }, getFormattedPriceResume()),
                React.createElement("span", null,
                    React.createElement("strong", null, t('pricingModal.pricing.price.everything').toUpperCase())))))));
};
export default React.memo(Pricing);
