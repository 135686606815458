import React from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';
import _ from 'underscore';
import StartAddingTasksIcon from '@material-ui/icons/AddCircle';
import { Button } from '@material-ui/core';
import { getAccountTasks } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const NoTaskFound = ({ eventEmptyState, workloadEmptyState, connectCalendarEmptyState, noSkillEmptyState }) => {
    const tasks = useSelector(getAccountTasks);
    const intl = useIntl();
    const t = (id, values) => {
        return intl.formatMessage({ id }, values);
    };

    return (
        <>
            {eventEmptyState || connectCalendarEmptyState ? (
                <div className='NoEventFound'>
                    <img
                        src="https://planless.sirv.com/App/noevent.png"
                        className='noEventFound'
                        alt="noEvents"
                    />
                    {eventEmptyState ? t('No event found!') : t('No calendar connected')}
                    {eventEmptyState ? <span>{t('You can start adding new tasks or connect to a calendar')}</span> : <span>{t('Please connect to one of your existing calendars')}</span>}
                    <div>
                        {eventEmptyState && (<Button variant="contained" color="primary" className='AddTaskBtn' onClick={() => browserHistory.push('/tasks/addTask')} startIcon={<StartAddingTasksIcon />}>{t('Add a task')}</Button>)}
                        <Button variant="contained" color="primary" className='ConnectCalendar' onClick={()=>browserHistory.push('/settings/integrations')}>{t('CONNECT A CALENDAR')}</Button>
                    </div>
                </div>
            ) : (
                <div className='NoTaskFound'>
                    <img
                        src={`https://planless.sirv.com/App/${workloadEmptyState || noSkillEmptyState ? 'noduedate' : 'notasks'}.png`}
                        className='noTaskFound'
                        alt="notasks"
                    />
                    {workloadEmptyState ? t('No workload data!') : noSkillEmptyState ? t('No Skill Found!') : t('No task found!')}
                    {workloadEmptyState ? <span>{t('workload.empty-state-message')}</span> : noSkillEmptyState ? <span>{t('skills.empty-state-message')}</span> : null}
                    {!noSkillEmptyState && !workloadEmptyState && !_.isEmpty(tasks) ? <><span>{t('No task found description')}</span><span className='OR'>{t('or')}</span></>:null}
                    {!noSkillEmptyState && <Button variant="contained" color="primary" className='AddTaskBtn fromWorkLoadView' onClick={() => browserHistory.push('/tasks/addTask')} startIcon={<StartAddingTasksIcon />}>{t('Add a task')}</Button>}
                </div>
            )}
        </>
    );
};

export default withCustomErrorBoundary(NoTaskFound);
