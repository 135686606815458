export const ACADEMY_LINK = 'https://help.planless.com';
export const UPVOTY_DOMAIN = 'feedback.planless.com';
export const UPVOTY_LINK = 'https://feedback.planless.com/';
export const GET_STARTED_LINK = 'https://planless.com/get-started/';
export const HUBSPOT_LINK_FREE_CALL = 'https://meetings.hubspot.com/planless/demo';
export const PRICING_LINK = 'https://planless.com/pricing';
export const FAQ_PLANS_CHANGE = 'https://academy.planless.com/frequently-asked-questions/faq-plans-change';

// STORAGE - this number are to be read in GB
export const STORAGE_LIMIT = {
    free: 1,
    team: 10,
};

// PLANS
export const PLANS = {
    free: 'free',
    team: 'team',
    business: 'business',
    premium: 'premium'
};
export const permissionsByPlan = {
    insights: [PLANS.premium, PLANS.business],
    recurringTask: [PLANS.premium, PLANS.business, PLANS.team],
    emailToTask: [PLANS.premium, PLANS.business, PLANS.team],
    daysoff: [PLANS.premium, PLANS.business, PLANS.team],
    boards: [PLANS.premium, PLANS.business, PLANS.team],
    financials: [PLANS.premium, PLANS.business],
    permissions: [PLANS.premium, PLANS.business, PLANS.team],
    apikeys: [PLANS.premium, PLANS.business, PLANS.team],
    subscription: [PLANS.premium, PLANS.business, PLANS.team],
    teamMemberSkill: [PLANS.premium, PLANS.business, PLANS.team],
    recurrentTask: [PLANS.premium, PLANS.business, PLANS.team],
    integrations: [PLANS.premium, PLANS.business, PLANS.team],
    filters: [PLANS.premium, PLANS.business, PLANS.team],
    permissionsTask: [PLANS.premium, PLANS.business],
};

const cloudFunctionsURLOptions  = {
    production: 'https://us-central1-planless-prod.cloudfunctions.net',
    stagging: 'https://us-central1-planless-staging.cloudfunctions.net',
    development: 'https://us-central1-planless-staging.cloudfunctions.net',
    // development: 'http://localhost:5000/planless-staging/us-central1',
    affiliate: 'https://us-central1-planless-affiliates.cloudfunctions.net',
};


export const CLOUD_FUNCTIONS_URL = cloudFunctionsURLOptions[process.env.NODE_ENV] || cloudFunctionsURLOptions.stagging;

const planlessAppURLOptions  = {
    production: 'https://app.planless.io',
    stagging: 'https://planless-staging.web.app',
    development: 'https://planless-staging.web.app'
};
export const PLANLESS_APP_URL = planlessAppURLOptions[process.env.NODE_ENV] || planlessAppURLOptions.stagging;

const firebaseStorageGoogleApisOptions  = {
    production: 'https://firebasestorage.googleapis.com/v0/b/planless-prod.appspot.com',
    stagging: 'https://firebasestorage.googleapis.com/v0/b/planless-staging.appspot.com',
    development: 'https://firebasestorage.googleapis.com/v0/b/planless-staging.appspot.com'
};

export const FIREBASE_STORAGE_GOOGLE_APIS = firebaseStorageGoogleApisOptions[process.env.NODE_ENV] || firebaseStorageGoogleApisOptions.stagging;

export const CLOUD_FUNCTIONS = {
    sendMemberInviteEmailNew: `${CLOUD_FUNCTIONS_URL}/sendMemberInviteEmailNew`,
    saveForStats: `${CLOUD_FUNCTIONS_URL}/saveForStats`,
    alertsHandler: `${CLOUD_FUNCTIONS_URL}/alertsHandler`,
    createZoomMeeting: `${CLOUD_FUNCTIONS_URL}/createZoomMeeting`,
    slackAuthAccess: `${CLOUD_FUNCTIONS_URL}/slackAuthAccess`,
    connectZoomOauth: `${CLOUD_FUNCTIONS_URL}/connectZoomOauth`,
    getBasecampToken: `${CLOUD_FUNCTIONS_URL}/getBasecampToken`,
    getBasecampAuth: `${CLOUD_FUNCTIONS_URL}/getBasecampAuth`,
    getBasecampData: `${CLOUD_FUNCTIONS_URL}/getBasecampData`,
    getWrikeToken: `${CLOUD_FUNCTIONS_URL}/getWrikeToken`,
    getWrikeData: `${CLOUD_FUNCTIONS_URL}/getWrikeData`,
    getJiraProjects: `${CLOUD_FUNCTIONS_URL}/getJiraProjects`,
    getJiraIssues: `${CLOUD_FUNCTIONS_URL}/getJiraIssues`,
    getBasecampDataForImport: `${CLOUD_FUNCTIONS_URL}/getBasecampDataForImport`,
    disconnectZoomOauth: `${CLOUD_FUNCTIONS_URL}/disconnectZoomOauth`,
    chargebeeCheckoutQuote: `${CLOUD_FUNCTIONS_URL}/chargebeeCheckoutQuote`,
    chargebeeCheckoutNew: `${CLOUD_FUNCTIONS_URL}/chargebeeCheckoutNew`,
    hubspotUpdateContactData: `${CLOUD_FUNCTIONS_URL}/hubspotUpdateContactData`,
    chargebeePortalSession: `${CLOUD_FUNCTIONS_URL}/chargebeePortalSession`,
    chargebeeUpgradePlan: `${CLOUD_FUNCTIONS_URL}/chargebeeUpgradePlan`,
    getTaskHistory: `${CLOUD_FUNCTIONS_URL}/getTaskHistoryNew`,
    addUserToSubscription: `${CLOUD_FUNCTIONS_URL}/addUserToSubscription`,
    removeUserToSubscription: `${CLOUD_FUNCTIONS_URL}/removeUserToSubscription`,
    disconnectCalendar: `${CLOUD_FUNCTIONS_URL}/disconnectCalendar`,
    connectCalendar: `${CLOUD_FUNCTIONS_URL}/connectCalendar`,
    disconnectAccount: `${CLOUD_FUNCTIONS_URL}/disconnectAccount`,
    connectAccountSync: `${CLOUD_FUNCTIONS_URL}/connectAccountSync`,
    calendarSync: `${CLOUD_FUNCTIONS_URL}/calendarSync`,
    googleCalendarHookCreation: `${CLOUD_FUNCTIONS_URL}/googleCalendarHookCreation`,
    getAuthToken: `${CLOUD_FUNCTIONS_URL}/getAuthToken`,
    getUserEvent: `${CLOUD_FUNCTIONS_URL}/getUserEvent`,
    chargebeeCreateCustomerManual: `${CLOUD_FUNCTIONS_URL}/chargebeeCreateCustomerManual`,
    affiliateAddTrial: `${cloudFunctionsURLOptions.affiliate}/affiliateAddTrial`,
    errorReportHandler: `${CLOUD_FUNCTIONS_URL}/errorReportHandler`,
    getAuthUrl: `${CLOUD_FUNCTIONS_URL}/getAuthUrl`,
    connectApp: `${CLOUD_FUNCTIONS_URL}/connectApp`,
    getTasks: `${CLOUD_FUNCTIONS_URL}/getTasks`,
    syncData: `${CLOUD_FUNCTIONS_URL}/syncData`,
    getItems: `${CLOUD_FUNCTIONS_URL}/getItems`,
    connectFolder: `${CLOUD_FUNCTIONS_URL}/connectFolder`,
    getStatsData: `${CLOUD_FUNCTIONS_URL}/getStatsData`,
    desyncProject: `${CLOUD_FUNCTIONS_URL}/desyncProject`,
    cancelQueue: `${CLOUD_FUNCTIONS_URL}/cancelQueue`,
    refreshUsers: `${CLOUD_FUNCTIONS_URL}/refreshUsers`,
};

export const SLACK_REDIRECT_URL =  `${PLANLESS_APP_URL}/settings/integrations/connectSlack`;

export const ZOOM_REDIRECT_URL =  `${PLANLESS_APP_URL}/connectZoom`;

export const FIREBASE_STATS_PATH = 'stats2';



// values in px
export const TIMELINE_HEADER_SIZE = 50;
export const TASK_LINE_SIZE = 34;

export const MILITARY_TIME_FORMAT = 'HH:mm';
export const REGULAR_TIME_FORMAT = 'LT';
