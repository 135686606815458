import React, { useState, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import { getFilteredTasksWithPath, getChildsFromParent, getParentsBefore, getTasksWithPathSorted } from '../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox';

export const isCheckboxDisabled = (taskId, id, parentsBeforeThisTask, childsFromThisTask) => {
    if(
        taskId === id ||
        parentsBeforeThisTask.includes(id) ||
        childsFromThisTask.includes(id)
    ) {
        return true;
    }
    return false;
};

export const isSelectDisabled = (taskId, id, currentParent, childsFromThisTask, status, canManage) => {
    if(
        !canManage ||
        status !== 'todo' ||
        taskId === id ||
        currentParent === id ||
        childsFromThisTask.includes(id)
    ) {
        return true;
    }
    return false;
};

export const isIndeterminate = (checkedItems, id, stateTasks) => {
    if(!checkedItems?.includes(id) && _.flatten(getChildsFromParent(id, stateTasks)).some((c) => checkedItems?.includes(c))) {
        return true;
    }
    return false;
};

export const isChecked = (checkedItems, id, stateTasks) => {
    if(
        checkedItems?.includes(id) ||
        _.flatten(getParentsBefore(id, stateTasks)).some((c) => checkedItems?.includes(c)) ||
        (
            stateTasks[id]?.childrens ?
                _.flatten(getChildsFromParent(id, stateTasks)).every((c) => checkedItems?.includes(c)) : false
        )
    ) {
        return true;
    }
    return false;
};

const SelectorsDialogSearch = ({ type, keyWord, taskId, currentParent, checkedItems, newParent, onSelect, onCheck,stateTasksReplace = null }) => {
    const intl = useIntl();
    const [linesFiltered, setLinesFiltered] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const [parentsBeforeThisTask, setParentsBeforeThisTask] = useState([]);

    const stateTasks = stateTasksReplace || useSelector((state) => state.app.account.tasks);

    useEffect(() => {
        if(taskId) {
            setParentsBeforeThisTask(_.flatten(getParentsBefore(taskId, stateTasks)));
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
        }

        if (stateTasks) {
            const newTasksWithPath = getTasksWithPathSorted(stateTasks); 
            setLinesFiltered(keyWord ? getFilteredTasksWithPath(keyWord, newTasksWithPath) : newTasksWithPath);
        }
    }, [keyWord, stateTasks, taskId]);

    const handleClick = (id, isDisabled, e) => {
        if(!isDisabled) {
            if(type === 'manageDependencies') {
                onCheck(id, e);
            } else {
                onSelect(id);
            } 
        }      
    };

    const getCheckOrSelectLine = (id, isDisabled) => {   
        if(type === 'manageDependencies') {

            return <Checkbox
                color='primary' 
                disabled={isDisabled}
                onChange={(e) => handleClick(id, isDisabled, e)}
                inputProps={{'aria-label': 'secondary checkbox'}}  
                checked={isChecked(checkedItems, id, stateTasks)} 
                indeterminate={isIndeterminate(checkedItems, id, stateTasks)}
            />;

        } else {
            if(id === currentParent) {
                return <span className='currentParent'>{intl.formatMessage({id: 'CURRENT PARENT' })}</span>;
            }

            if(id === newParent) {
                return <span onClick={() => onSelect(id)} className='newParent'>{intl.formatMessage({id: 'NEW PARENT' })}</span>;
            } 

            if(id == taskId) {
                return <span className='currentTaskId'>{intl.formatMessage({id: 'ACTIVE TASK' })}</span>;
            }
            
            if(!isDisabled) {
                return <span onClick={() => onSelect(id)} className='hoverSelect'>{intl.formatMessage({id: 'SELECT' })}</span>;
            }
        }
    };

    return (
        <div className='SelectorsDialogSearch'>
            {linesFiltered.length > 0 ? linesFiltered.map(({ id, title, path, status, canManage }, index) => { 
                
                const isDisabled = type === 'changeParent' ?
                    isSelectDisabled(taskId, id, currentParent, childsFromThisTask, status, canManage) :
                    isCheckboxDisabled(taskId, id, parentsBeforeThisTask, childsFromThisTask);
        
                return(
                    <div key={`${id}-${index}`} onClick={() => handleClick(id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'}>
                        <div className='row__content'> <small>{path}</small> <strong>{title}</strong> </div>
        
                        {getCheckOrSelectLine(id, isDisabled)}
                                
                    </div>
                );
            })  :   (
                <>
                    {
                        keyWord && (<div draggable='false' className='noResultsFound'>{intl.formatMessage({id: 'Not found!' })}<span>{intl.formatMessage({id: 'Check if the item already exists or add a new one' })}</span></div>)
                    }
                </>
            )
            }
        </div>
    );
};

SelectorsDialogSearch.propTypes = {
    type: Proptypes.string.isRequired,
    keyWord: Proptypes.string,
    taskId: Proptypes.string.isRequired,
    newParent: Proptypes.string,
    currentParent: Proptypes.string,
    checkedItems: Proptypes.array,
    onSelect: Proptypes.func,
    onCheck: Proptypes.func,
};

export default memo(SelectorsDialogSearch);