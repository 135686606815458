import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PathIcon from '@material-ui/icons/FolderOpen';
import SortAsc from '@material-ui/icons/ArrowDownward';
import SortDesc from '@material-ui/icons/ArrowUpward';
import PrevIcon from '@material-ui/icons/SkipPrevious';
import NextIcon from '@material-ui/icons/SkipNext';
import FirstIcon from '@material-ui/icons/FirstPage';
import LastIcon from '@material-ui/icons/LastPage';
import Tooltip from 'rc-tooltip';
import _ from 'underscore';
import moment from 'moment-timezone';
import TaskLine from '../TaskLine/TaskLine';
import classnames from 'clsx';
import { checkTaskAgainstFiltersNew, checkTaskAgainstLimit } from '../../utils/utils';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { tracking, getTaskPathArrow } from '../../utils/utils';
import NotInMobile from '../NotInMobile/NotInMobile';
import Loader from '../../components/Loader';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import { getFilters, getLimitViewTo, getLoaders, getOrderedTaskList, getParentChildIds, getQuickSearch } from '../../utils/selectors/app';
import { getAccountCustomFields, getAccountSkills, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { getUserDateformat, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const DEFAULT_FAR_DATE = '9999/01/01';

const List = ({children})=>{
    const intl = useIntl();

    const parentChildIds = useSelector(getParentChildIds);
    const orderedTaskList = useSelector(getOrderedTaskList);
    const filters = useSelector(getFilters);
    const quickSearch = useSelector(getQuickSearch);
    const timezone = useSelector(getUserTimezone);
    const dateFormat = useSelector(getUserDateformat);
    const timeFormat = useSelector(getUserTimeformat);
    const tasks = useSelector(getAccountTasks);
    const users = useSelector(getAccountUsers);
    const skills = useSelector(getAccountSkills);
    const loaders = useSelector(getLoaders);
    const limitViewTo = useSelector(getLimitViewTo);
    const customFields = useSelector(getAccountCustomFields);

    let listOrder = {key:'startOn', order:'asc'},
        itemsPerPageData = 25;

    if(localStorage.getItem('listOrder')){
        listOrder = JSON.parse(localStorage.getItem('listOrder'));
    }
    else {
        localStorage.setItem('listOrder', JSON.stringify(listOrder));
    }

    if(localStorage.getItem('listItemsPerPage')){
        itemsPerPageData = localStorage.getItem('listItemsPerPage');
    }
    else {
        localStorage.setItem('listItemsPerPage', itemsPerPageData);
    }

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageData);
    const [orderBy, setOrderBy] = useState(listOrder.key);
    const [orderDirection, setOrderDirection] = useState(listOrder.order);

    const handlePaginationScroll = (e) => {
        $('.listFooter').css('marginLeft', e.target.scrollLeft);
    };

    useEffect(()=>{
        $('.List').on('scroll', handlePaginationScroll);
        tracking('Saw Table View');

        return function cleanup(){
            $('.List').off('scroll', handlePaginationScroll);
        };
    }, []);


    const getRawTaskList = () => {
        if(tasks){
            var taskList = [];
            var list = orderedTaskList;
            var parentIds = parentChildIds.parents;

            _.each(list, (task)=>{
                if(!task.childrens && task.canView){
                    task.estimations = tasks[task.id]?.estimations;
                    taskList.push(task);
                }
            });

            // reduce tasklist depending on filters
            var filteredTaskList = [];
            _.each(taskList, (task)=>{
                if(checkTaskAgainstLimit(task, null, parentIds, limitViewTo) && checkTaskAgainstFiltersNew(quickSearch, filters, task, {tasks:tasks}, null, ['tasktype'], null, customFields)){
                    filteredTaskList.push(task);
                }
            });

            // second sort by order defined
            filteredTaskList = _.sortBy(filteredTaskList, ((currTask)=>{
                if(orderBy === 'startOn'){
                    let startOn = null;
                    if(currTask.status !== 'todo' && currTask.startedWorkingAt){
                        startOn = moment(currTask.startedWorkingAt).unix();
                    }
                    else if(currTask.estimations && currTask.estimations.startAt){
                        startOn = currTask.estimations.startAt;
                    }

                    if(orderDirection === 'asc' && startOn === null) {
                        return DEFAULT_FAR_DATE;
                    }
                    return startOn;
                }
                else if(orderBy === 'path'){
                    const path = getTaskPathArrow(currTask.id, tasks);
                    if(orderDirection === 'asc' && path.trim() === ''){
                        return 'z';
                    }
                    return path;
                }
                else if(orderBy === 'title'){
                    return currTask.title || '';
                }
                else if(orderBy === 'assignedTo'){

                    if(currTask.status === 'inprogress' && currTask.userWorking){
                        return (users[currTask.userWorking[0]])?users[currTask.userWorking[0]].displayName:'';
                    }
                    else if(currTask.status !== 'done'){
                        if(currTask.forcedUser){
                            return (users[currTask.forcedUser[0]])?users[currTask.forcedUser[0]].displayName:'';
                        }
                        else if(currTask.estimations && currTask.estimations.userId){
                            return (users[currTask.estimations.userId[0]])?users[currTask.estimations.userId[0]].displayName:'';
                        }
                    }
                    else if(currTask.doneBy){
                        return (users[currTask.doneBy[0]])?users[currTask.doneBy[0]].displayName:'';
                    }
                    else if(currTask.userWorking){
                        return (users[currTask.userWorking[0]])?users[currTask.userWorking[0]].displayName:'';
                    }
                    return null;
                }
                else if(orderBy === 'skill'){
                    if(orderDirection === 'asc') {
                        return currTask?.skill?.length ? skills[currTask.skill[0]]?.name?.toLowerCase() : 'z';
                    }
                    return currTask?.skill?.length ? skills[currTask.skill[0]]?.name?.toLowerCase() : null;
                }
                else if(orderBy === 'effort'){
                    return (parseFloat(currTask.minEffort)+parseFloat(currTask.maxEffort))/2;
                }
                else if(orderBy === 'priority'){
                    return currTask.priority || '';
                }
                else if(orderBy === 'dueDate'){
                    return (orderDirection === 'asc') ? currTask.deadline || DEFAULT_FAR_DATE : currTask.deadline || '';
                }
                else if(orderBy === 'expected'){
                    return (orderDirection === 'asc' && (currTask.doneAt || !currTask.estimations?.expectedAt)) ? DEFAULT_FAR_DATE : currTask.estimations?.expectedAt || '';
                }
                else if(orderBy === 'earliest'){
                    return (orderDirection === 'asc' && (currTask.doneAt || !currTask.estimations?.earliestAt)) ? DEFAULT_FAR_DATE : currTask.estimations?.earliestAt || '';
                }
                else if(orderBy === 'latest'){
                    return (orderDirection === 'asc' && (currTask.doneAt || !currTask.estimations?.endAt)) ? DEFAULT_FAR_DATE : currTask.estimations?.endAt || '';
                }
                else if(orderBy === 'doneOn'){
                    return (orderDirection === 'asc') ? currTask.doneAt || DEFAULT_FAR_DATE : currTask.doneAt || '';
                }
                else if(orderBy === 'worked'){
                    var workingTime = 0;
                    for(var i in currTask.workingTime){
                        workingTime += parseFloat(currTask.workingTime[i].hours);
                    }

                    return (orderDirection === 'asc' && workingTime === 0) ? 9999999 : workingTime;
                }
                else {
                    return currTask.index;
                }
            }));

            //reverse if order is desc
            if(orderDirection === 'desc'){
                filteredTaskList.reverse();
            }

            return filteredTaskList;
        }

        return [];

    };

    const handleRowClick = (e, id) => {
        e & e.stopPropagation();
        browserHistory.push(`/list/${id}`);
    };

    const getTaskList = (taskList) => {
        if(taskList && taskList.length){
            var list = [];

            // reduce list for pagination
            taskList = taskList.slice(
                (page-1)*itemsPerPage,
                page*itemsPerPage-1
            );

            _.each(taskList, (task)=>{

                let startAt = '-',
                    expected = '-',
                    earliest = '-',
                    latest = '-',
                    doneon = '-',
                    workingTime = 0,
                    parentsPath = getTaskPathArrow(task.id, tasks);

                if(task.status !== 'todo' && task.startedWorkingAt){
                    startAt = moment.tz(task.startedWorkingAt+' 00:00', 'YYYY-MM-DD HH:mm', timezone).format(((dateFormat)?dateFormat:'DD/MM/YY') + ' ' + ((timeFormat)?timeFormat:'HH:mm'));
                }
                else if(task.estimations && task.estimations.startAt){
                    startAt = moment.tz(task.estimations.startAt, 'X', timezone).format(((dateFormat)?dateFormat:'DD/MM/YY') + ' ' + ((timeFormat)?timeFormat:'HH:mm'));
                }
                if(!task.doneAt && task.estimations && task.estimations.expectedAt){
                    expected = moment.tz(task.estimations.expectedAt, 'X', timezone).format(((dateFormat)?dateFormat:'DD/MM/YY') + ' ' + ((timeFormat)?timeFormat:'HH:mm'));
                }
                if(!task.doneAt && task.estimations && task.estimations.earliestAt){
                    earliest = moment.tz(task.estimations.earliestAt, 'X', timezone).format(((dateFormat)?dateFormat:'DD/MM/YY') + ' ' + ((timeFormat)?timeFormat:'HH:mm'));
                }
                if(!task.doneAt && task.estimations && task.estimations.endAt){
                    latest = moment.tz(task.estimations.endAt, 'X', timezone).format(((dateFormat)?dateFormat:'DD/MM/YY') + ' ' + ((timeFormat)?timeFormat:'HH:mm'));
                }
                if(task.doneAt){
                    doneon = moment.tz(task.doneAt, 'YYYY-MM-DD', timezone).format((dateFormat)?dateFormat:'DD/MM/YY');
                }

                if(task.workingTime){
                    for(var i in task.workingTime){
                        workingTime += parseFloat(task.workingTime[i].hours);
                    }
                }

                if(workingTime !== 0){
                    workingTime = (parseFloat(workingTime).toFixed(2)*100/100) + 'h';
                }
                else {
                    workingTime = '-';
                }

                list.push(
                    <TableRow id={`TableRow-${task.id}`} key={task.id} onClick={(e) => handleRowClick(e, task.id)} className={classnames('TableRow', {onSidebar: window.location.pathname === `/list/${task.id}`})}>
                        <TableCell>
                            <Tooltip placement="right" overlay={parentsPath}>
                                <div>
                                    <PathIcon className="pathIcon" />
                                    <span className="longPath">{parentsPath}</span>
                                </div>
                            </Tooltip>
                        </TableCell>

                        <TableCell className="titleCell" colSpan={6}>
                            <TaskLine
                                key={task.id}
                                taskId={task.id}
                                isHidden={false}
                                isClosed={false}
                                isToggleClosed={false}
                                isNotVisible={false}
                                viewBaseLink='/list'
                            />

                        </TableCell>
                        <TableCell>{startAt}</TableCell>
                        <TableCell>{expected}</TableCell>
                        <TableCell>{earliest}</TableCell>
                        <TableCell>{latest}</TableCell>
                        <TableCell>{doneon}</TableCell>
                        <TableCell>{workingTime}</TableCell>
                    </TableRow>

                );
            });
            return list;
        }
    };

    const getPagination = (taskList) => {
        if(taskList && taskList.length){

            var nbPages = Math.ceil(taskList.length/itemsPerPage);

            if(page > nbPages){
                setPage(1);
            }

            var nextPage = <Button onClick={()=>{setPage(page+1);}} title={intl.formatMessage({id:'Next page'})} disabled={page === nbPages}><NextIcon /></Button>;
            var prevPage = <Button onClick={()=>{setPage(page-1);}} title={intl.formatMessage({id:'Previous page'})} disabled={page === 1}><PrevIcon /></Button>;
            var firstPage = <Button onClick={()=>{setPage(1);}} title={intl.formatMessage({id:'First page'})} disabled={page === 1}><FirstIcon /></Button>;
            var lastPage = <Button onClick={()=>{setPage(nbPages);}} title={intl.formatMessage({id:'Last page'})} disabled={page === nbPages}><LastIcon /></Button>;

            var pageUnits = [],
                start = page - 2;

            if(start+5 > nbPages){ start = nbPages-4; }
            if(start < 1){ start = 1; }

            for(var i = 0; i < 5; ++i){
                var currPage = start+i;
                if(currPage <= nbPages){
                    pageUnits.push(<Button key={'page-' + currPage} onClick={changePage} className={classnames('page', {currPage: page === currPage})} disabled={page === currPage}>{currPage}</Button>);
                }
            }

            return (
                <div className="pagination">
                    {firstPage}
                    {prevPage}
                    {pageUnits}
                    {nextPage}
                    {lastPage}
                </div>
            );
        }
    };

    const changePage = (e) => {
        setPage(parseInt($(e.currentTarget).text()));
    };

    const changeOrder = (key) => {
        var orderDir = 'asc';

        if(orderBy === key){
            if(orderDirection === 'asc'){
                orderDir = 'desc';
            }
        }

        localStorage.setItem('listOrder', JSON.stringify({key: key, order: orderDir}));


        firebaseEvents.addChallenge('listOrderColumn');

        setPage(1);
        setOrderBy(key);
        setOrderDirection(orderDir);
    };

    const getHeaderTitle = (id, title, cl) => {
        if(!cl) cl = orderDirection;

        return (
            <TableCell
                className={classnames(`${cl} ${title}`, {active: orderBy === id})}
            >
                <span onClick={()=>changeOrder(id)}>
                    {intl.formatMessage({id:title})} <SortAsc className="sortAscIcon" /><SortDesc className="sortDescIcon" />
                </span>
            </TableCell>
        );
    };

    var taskList = getRawTaskList();

    return (
        <div className={classnames('List', {empty: !taskList || !taskList.length})}>
            {(loaders.tasks || loaders.users || loaders.skills) ? (
                <div>
                    <Loader show={true} />
                </div>
            ) : taskList && taskList.length ? (
                <>
                    <div className="TaskList" data-sidebar='preventClose'>
                        <div className="tableContainer">

                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        {getHeaderTitle('path', 'Path')}
                                        {getHeaderTitle('title', 'Title')}
                                        {getHeaderTitle('assignedTo', 'Who', `whoTableCell ${orderDirection}`)}
                                        {getHeaderTitle('skill', 'Skill')}
                                        {getHeaderTitle('effort', 'Effort (h)')}
                                        {getHeaderTitle('priority', 'Priority')}
                                        {getHeaderTitle('dueDate', 'Due date')}
                                        {getHeaderTitle('startOn', 'Start working')}
                                        {getHeaderTitle('expected', 'Expected done')}
                                        {getHeaderTitle('earliest', 'Earliest done')}
                                        {getHeaderTitle('latest', 'Latest done')}
                                        {getHeaderTitle('doneOn', 'Done on')}
                                        {getHeaderTitle('worked', 'Worked')}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getTaskList(taskList)}
                                </TableBody>
                            </Table>

                        </div>
                    </div>

                    <div className="listFooter">
                        <div className="itemsPerPageSelector">
                            <span>{intl.formatMessage({id:'Results per page'})}</span>
                            <TextField
                                size="small"
                                select
                                value={parseInt(itemsPerPage)}
                                onChange={(event)=>{
                                    localStorage.setItem('listItemsPerPage', event.target.value);
                                    setPage(1);
                                    setItemsPerPage(event.target.value);
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </TextField>
                        </div>

                        {getPagination(taskList)}

                        <div className="nbResults">
                            <span>
                                {intl.formatMessage({id:'X results'}, {
                                    results: taskList.length
                                })}
                            </span>
                            <span>
                                {intl.formatMessage({id:'Page X of X'}, {
                                    current: page,
                                    total: Math.ceil(taskList.length/itemsPerPage)
                                })}
                            </span>
                        </div>
                    </div>

                    {children}
                </>
            ) : (
                <NoTaskFound />
            )}
            <NotInMobile />
        </div>
    );

};

export default withCustomErrorBoundary(List);

