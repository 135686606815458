import Bugsnag from '@bugsnag/js';
import _ from 'underscore';
import axios from 'axios';
import { 
    CLOUD_FUNCTIONS
} from './constants';
export const mapConnectAccounts = (connectAccount) => {
    if(!connectAccount || _.isEmpty(connectAccount)) {
        return [];
    }

    return Object.keys(connectAccount).map(el => ({
        ...connectAccount[el],
        refId: el,
        calendars: mapCalendarsToArray(connectAccount[el].calendars,el )
    }));
};

export const mapCalendarsToArray = (calendars, accountRef) => {
    if(!calendars || _.isEmpty(calendars)) {
        return [];
    }

    return Object.keys(calendars).map(el => ({
        ...calendars[el],
        refId: el,
        accountRef,
    }));
};


export const disconectCalendar = ({ email, accountId, userId, calendarId }) => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.disconnectCalendar,
    params: { accountId, userId, calendarId, email }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const connectCalendar = ({ accountId, userId, calendarId, email })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.connectCalendar,
    params: { accountId, userId, calendarId, email }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const deleteCalendarAccount = ({ accountId, userId, email }) => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.disconnectAccount,
    params: { accountId, userId, email }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const resyncCalendarAccount = ({ accountId, userId, email })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.connectAccountSync,
    params: { accountId, userId, email }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const resyncCalendar = ({ accountId, userId, calendarId })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.calendarSync,
    params: { accountId, userId, calendarId }
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const connectAccount = (query) => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.googleCalendarHookCreation,
    params: query
});

export const getAuthToken = (query)  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.getAuthToken,
    params: query
}).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});

export const getEventData = (query)  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.getUserEvent,
    params: query
}).then(el => el.data).catch(function (error) {
    // handle error
    Bugsnag.notify(error);
});
