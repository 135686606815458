import React from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
// import { PLANS } from '../../utils/constants';
import { browserHistory } from 'react-router';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'clsx';
// import { toastr } from 'react-redux-toastr';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getUserEmail } from '../../utils/selectors/user';

const SelectWorkspace = () => {
    const intl = useIntl();
    const userAccounts = useSelector(state => state.app?.userAccounts);
    const actualId = useSelector(state => state.app?.account?.settings?.id);
    const currentUserEmail = useSelector(getUserEmail);
    const changeWorkspace = (e, id) => {
        e.preventDefault();

        firebaseEvents.changeActiveAccount(id);
    };

    const createWorkspace = async () => {
        // const accountsPlans = await Promise.all(userAccounts.map(el => new Promise(resolve => {
        //     firebaseEvents.getAccountPlan(el.id).then(result => resolve(result.val()));
        // })));

        // const filterFree = accountsPlans ? accountsPlans.filter(el => el === PLANS.free) : [];
        // if (filterFree.length >= 2) {
        //     toastr.error('You already have 2 free workspaces upgrade or delete one off them to create a new one.');
        //     return;
        // }

        browserHistory.push('/create-workspace');
    };

    return (
        <div className='SelectWorkspace'>
            <div>
                <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
                <h2>{intl.formatMessage({ id: 'Select your workspace' })}</h2>

                <div className='SelectWorkspace__options'>
                    {
                        userAccounts && userAccounts.map((a, idx) => {
                            return (
                                <Card elevation={0} key={`${a.id}-${idx}`} className={classnames({ active: a.id === actualId })} onClick={(e) => { 
                                    if(a.id === actualId){
                                        browserHistory.push('/');
                                    }
                                    else {
                                        changeWorkspace(e, a.id);
                                    }
                                }}>
                                    <CardHeader
                                        className={classnames({ untitled: !a.name })}
                                        title={a.name || intl.formatMessage({ id: 'Untitled' })}
                                        subheader={a.code}
                                    />
                                    <CardContent>
                                        {a.id === actualId &&
                                            <a className='activeWorkspace'>Active</a>
                                        }
                                    </CardContent>
                                </Card>
                            );
                        })
                    }
                    {(currentUserEmail || '').match('planless.io') && (
                        <Card className={classnames('create')} elevation={0} key='select-workspace' onClick={createWorkspace}>
                            <AddIcon />
                            <h1>{intl.formatMessage({ id: 'Create new workspace' })}</h1>
                        </Card>
                    )}   
                </div>
            </div>
            <Button variant='contained' type='button' className='goBackActiveWorkspace' onClick={() => browserHistory.goBack()}>{intl.formatMessage({id: 'workspaces.go-back'})}</Button>
        </div >
    );
};

export default withCustomErrorBoundary(SelectWorkspace);
