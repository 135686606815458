import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const MemberSkillComponent = ({ skillId, label, level, onChange }) => {
    const [stateLevel, setStateLevel] = useState(level);

    const updateSlider = (e, value) => {
        if(onChange){
            onChange(skillId, value);
        }
    };

    const updateSliderLive = (e, value) => {
        setStateLevel(value);
    };

    return (
        <div className="MemberSkillComponent">
            <span>{label}</span>
            <div className="level">
                <Slider
                    color="secondary"
                    min={0}
                    max={10}
                    step={1}
                    value={stateLevel}
                    onChange={updateSliderLive}
                    onChangeCommitted={updateSlider}
                    valueLabelDisplay="auto"
                />
                <div className="sliderLegend"><span>0</span><span></span><span></span><span></span><span></span><span>5</span><span id={'middle-point'}></span><span></span><span></span><span></span><span>10</span></div>
            </div>
        </div>
    );

};

export default withCustomErrorBoundary(MemberSkillComponent);