import React, { memo, useState, useEffect} from 'react';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import { useIntl } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import { useSelector, useDispatch } from 'react-redux';
import { tracking as segmentTrack } from '../../utils/utils';
import { getChildsFromParent, getParentsBefore, getVisibleTasksOrdered, getFilteredTasksWithPath, getTasksWithPathSorted } from '../../utils/utils';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { getAccountId, getAccountTasks } from '../../utils/selectors/account';
import { closeChangeTaskLocationModal } from '../App/AppActions';
import { getChangeTaskLocationModal } from '../../utils/selectors/app';
import { getUserId } from '../../utils/selectors/user';
import { shouldBlockSyncListReorder } from '../Integrations/utils';
import { toastr } from 'react-redux-toastr';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import SortableHandleClosed from '@material-ui/icons/ChevronRight';
import SortableHandleOpen from '@material-ui/icons/ExpandMore';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import useSyncData from '../../utils/hooks/useSyncData';

export const isSelectDisabled = ({id, taskId, currentParent = null, status, childrens = null, canManage = false, childsFromThisTask = []}) => {
    if(!canManage || taskId === id || currentParent === id || childsFromThisTask.includes(id) || (status !== 'todo' && !childrens) || (!currentParent && id === 'ROOT')) {
        return true;
    }
    return false;
};

const ChangeTaskLocationModal = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [keyWord, setKeyWord] = useState('');
    const [showTree, setShowTree] = useState(true);
    const [newParent, setNewParent] = useState(null);
    const [startTimer, setStartTimer] = useState(0);

    const changeTaskLocationModal = useSelector(getChangeTaskLocationModal);
    const taskId = changeTaskLocationModal.data.taskId;
    const tasks = useSelector(getAccountTasks);
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const currentParent = tasks[taskId]?.parent || null;

    const syncAppData = useSyncData(taskId);

    useEffect(() => {
        if (tasks) {
            setShowTree(true);
            setKeyWord('');
            setStartTimer(performance.now());
        }
    }, [tasks, changeTaskLocationModal.isOpen]);

    const handleToggleViewMode = (e) => {
        setShowTree(false);
        setKeyWord(e.target.value);
    };

    const handleClearInput = () => {
        setShowTree(true);
        setKeyWord('');
    };

    const handleClose = () => {
        setNewParent(null);
        dispatch(closeChangeTaskLocationModal());
    };

    const onSelect = (id) => {
        setNewParent(id);
    };

    const onSave = () => {
        const reorderClickUpBlocked = shouldBlockSyncListReorder({
            taskChanged: taskId,
            parentDest: newParent,
            parentOrigin:
            tasks[taskId]?.parent,
            isTaskSyncProject: tasks[taskId]?.sourceId
        });

        if(reorderClickUpBlocked) {
            toastr.error(intl.formatMessage({id: 'clickup.list.reorder.error'}));
            return;
        }

        const endTimer = performance.now();
        const timeStamp = Math.round(endTimer - startTimer);

        segmentTrack('Change task location', {accountId, userId, timestamp: timeStamp});

        let newIndex = 0;
        if (tasks[newParent]?.childrens) {
            newIndex = tasks[newParent]?.childrens.length + 1;
        } else if (newParent === 'ROOT') {
            newIndex = Object.values(tasks).filter(t => !t.parent).length + 1;
        }

        firebaseEvents.cleanUpDependeciesWhenMoving({ movedTaskId: taskId, destinationId: newParent, allTasks: tasks });

        firebaseEvents.reorderTasks({
            type: 'add',
            taskId: taskId,
            initParent: tasks[taskId].parent,
            destParent: newParent,
            oldIndex: tasks[taskId].index,
            newIndex,
        });

        setNewParent(null);
        dispatch(closeChangeTaskLocationModal());
    };

    if(changeTaskLocationModal.isOpen){
        return (
            <Dialog maxWidth={'md'} open={changeTaskLocationModal.isOpen} elevation={2} aria-labelledby="customized-dialog-title" onClose={handleClose} data-sidebar='preventClose'>
                <div className='Select'>
                    <MuiDialogTitle className="title" disableTypography >
                        <Typography variant="h6"> {intl.formatMessage({ id: 'Change Location' })}</Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent className="Select__inputWrapper">
                        <div className="Select__input">
                            <SearchIcon id="searchIcon" />
                            <DebounceInput placeholder="Search..." type="text" value={keyWord} minLength={1} debounceTimeout={1000} onChange={handleToggleViewMode}/>
                            {!showTree &&
                                (<div id="clearInput" onClick={handleClearInput}><CloseIcon />{keyWord !== '' ?
                                    (<span>{intl.formatMessage({id: 'Clear & go to the tree view'})}</span>) :
                                    (<span>{intl.formatMessage({id: 'Go to the tree view'})}</span>)}</div>
                                )}
                        </div>
                    </MuiDialogContent>
                    {showTree ?
                        (<TreeView onSelect={onSelect} taskId={taskId} syncAppData={syncAppData} newParent={newParent} currentParent={currentParent}/>)
                        : (<SearchView keyWord={keyWord} onSelect={onSelect} taskId={taskId} syncAppData={syncAppData} newParent={newParent} currentParent={currentParent}/>)
                    }
                    <MuiDialogActions className="Select__btnOptions">
                        <Button variant="contained" onClick={handleClose}>
                            {intl.formatMessage({id: 'CANCEL'})}
                        </Button>
                        <Button disabled={!newParent} className={classnames('', {saveDisabled: !newParent})} variant="contained" color="primary" onClick={onSave}>
                            {intl.formatMessage({id: 'SAVE CHANGES'})}
                        </Button>
                    </MuiDialogActions>
                </div>
            </Dialog>
        );
    }
    return null;
};

export const TreeView = ({ taskId, currentParent, newParent, onSelect, syncAppData }) => {
    const intl = useIntl();
    const [openLines, setOpenLines] = useState([]);
    const [visibleLines, setVisibleLines] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const stateTasks = useSelector(getAccountTasks);

    const { currentTask, syncAppType, isSyncAppTask } = syncAppData;

    useEffect(() => {
        if(taskId) {
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
        }

        if (stateTasks && stateTasks[taskId]) {
            const parentsBefore = _.flatten(getParentsBefore(taskId, stateTasks));
            const visibleTasksOrdered = getVisibleTasksOrdered(taskId, stateTasks);

            const root = {
                title: 'TOP LEVEL OF THE WORKSPACE',
                id: 'ROOT',
                index: 0,
                indent: 0,
                canView: true,
                canManage: true,
                status: 'todo',
            };

            setVisibleLines([root, ...visibleTasksOrdered.map((t) => ({
                ...t,
                indent: t.indent + 2,
            }))]);
            setOpenLines([root.id, ...parentsBefore]);
            return;
        }
    }, [taskId, stateTasks]);

    const closeTasks = (id) => {
        if(id === 'ROOT') {
            setVisibleLines(visibleLines.filter((t) => t.id === 'ROOT'));
            setOpenLines([]);
            return;
        }

        const index = visibleLines.findIndex((el) => el.id === id);
        const parentBefore = visibleLines
            .slice(0, index + 1)
            .map((el) => el.parent);

        const lastChildIndexClicked = visibleLines
            .slice(index + 1)
            .findIndex((el) => !el.parent || parentBefore.includes(el.parent));

        if (lastChildIndexClicked >= 0) {
            setVisibleLines([
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + lastChildIndexClicked + 1),
            ]);
        } else {
            setVisibleLines(visibleLines.slice(0, index + 1));
        }

        const openTasksChildrens = _.flatten(getChildsFromParent(id, stateTasks)).filter((c) => visibleLines.map(task => task.id).includes(c));

        setOpenLines(
            openLines.filter((el) => el !== id && !openTasksChildrens.includes(el))
        );
    };

    const openTasks = (id) => {
        const index = visibleLines.findIndex((el) => el.id === id);

        if(id === 'ROOT') {
            setVisibleLines([
                ...visibleLines,
                ...Object.keys(stateTasks).filter((taskId) => !stateTasks[taskId].parent)
                    .map((taskId) => ({
                        ...stateTasks[taskId],
                        indent: 2
                    }))
                    .sort((a,b) => a.index > b.index ? 1 : -1)
            ]);
            setOpenLines(['ROOT']);
            return;
        }

        if (stateTasks[id] && stateTasks[id].childrens) {

            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...stateTasks[id].childrens
                    .filter((el) => el.type === 'task' && stateTasks[el.id].parent)
                    .map((cTask) => ({
                        ...stateTasks[cTask.id],
                        id: cTask.id,
                        indent: visibleLines[index].indent + 2,
                    }))
                    .sort((a, b) => (a.index > b.index ? 1 : -1)),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines([...newVisibleLines]);
            setOpenLines([...openLines, id]);
        } else {
            const newVisibleLines = [
                ...visibleLines.slice(0, index + 1),
                ...visibleLines.slice(index + 1),
            ];

            setVisibleLines(newVisibleLines);
            setOpenLines([...openLines, id]);
        }
    };

    const toggleOpenClose = (id) => {
        if (openLines.includes(id)) {
            closeTasks(id);
        } else {
            openTasks(id);
        }
    };

    const handleClick = (e, id, isDisabled) => {
        const isClickable =
            e.target.classList.contains('itemTitle') ||
            e.target.classList.contains('row') ||
            e.target.classList.contains('taskStatus') ||
            e.target.classList.contains('lineNode');

        if(!isDisabled && isClickable) {
            onSelect(id);
        }
    };

    const getIcons = (id, childrens) => {
        if (childrens && openLines.includes(id) || id === 'ROOT' && openLines.includes('ROOT')) {
            return <SortableHandleOpen className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        if (childrens && !openLines.includes(id) || id === 'ROOT' && !openLines.includes('ROOT')) {
            return <SortableHandleClosed className='openCloseTaskIcon' onClick={() => toggleOpenClose(id)}/>;
        }
        return <TaskStatusIcon taskId={id}/>;
    };

    const handleSelectLocation = (id, isDisabled) => {
        if((currentParent && currentParent === id || (!currentParent && id === 'ROOT'))) {
            return <span className='currentParent'>{intl.formatMessage({id: 'CURRENT LOCATION' })}</span>;
        }
        if(id === newParent) {
            return <span onClick={() => onSelect(id)} className='newParent'>{intl.formatMessage({id: 'NEW LOCATION' })}</span>;
        }
        if(id == taskId) {
            return <span className='currentTaskId'>{intl.formatMessage({id: 'ACTIVE TASK' })}</span>;
        }
        if(!isDisabled) {
            return <span onClick={() => onSelect(id)} className='hoverSelect'>{intl.formatMessage({id: 'SELECT' })}</span>;
        }
    };

    return (
        <div className='SelectorsDialogTree'>
            <ul className='SelectorsDialogTree__container'>
                {visibleLines.filter(t=>t.canView).map(({ id, title, indent, status, childrens, canManage, sourceId }, index) => {
                    const isDisabled = isSelectDisabled({id, taskId, currentParent, status, childrens, canManage, childsFromThisTask}) || (sourceId && !childrens);

                    return (
                        <li key={`${id}-${index}`} onClick={(e) => handleClick(e, id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'} style={{ paddingLeft: `${indent}rem`, paddingRight: '0.6rem'}} >
                            {getIcons(id, childrens)}
                            <div className='lineNode'>
                                <span className={classnames('itemTitle', {
                                    hasNoChildrens: !childrens,
                                    isSync: isSyncAppTask
                                })}>
                                    {title}
                                    {isSyncAppTask && currentTask?.sourceId && <SyncProjectLogo task={currentTask} toolName={syncAppType} />}
                                </span>
                            </div>
                            {handleSelectLocation(id, isDisabled)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const SearchView = ({ keyWord, taskId, currentParent, newParent, onSelect, syncAppData }) => {
    const intl = useIntl();
    const [linesFiltered, setLinesFiltered] = useState([]);
    const [childsFromThisTask, setChildsFromThisTask] = useState([]);
    const stateTasks = useSelector(getAccountTasks);
    const { currentTask, syncAppType, isSyncAppTask } = syncAppData;

    useEffect(() => {
        if(taskId) {
            setChildsFromThisTask(_.flatten(getChildsFromParent(taskId, stateTasks)));
        }

        if (stateTasks) {
            const newTasksWithPath = getTasksWithPathSorted(stateTasks);
            setLinesFiltered(getFilteredTasksWithPath(keyWord, newTasksWithPath) ?? newTasksWithPath);
        }
    }, [keyWord, stateTasks, taskId]);

    if(_.isEmpty(linesFiltered) && keyWord) {
        return <div draggable='false' className='noResultsFound'>{intl.formatMessage({id: 'Not found!' })}
            <img
                src={'https://planless.sirv.com/App/notasks.png'}
                className='noTaskFound__img'
                alt="notasks"
            />
            <span>{intl.formatMessage({id: 'Check if the item already exists or add a new one' })}
            </span>
        </div>;
    }

    const handleClick = (id, isDisabled) => {
        if(!isDisabled) {
            onSelect(id);
        }
    };

    const getCheckOrSelectLine = (id, isDisabled) => {
        if(id === currentParent) {
            return <span className='currentParent'>{intl.formatMessage({id: 'CURRENT PARENT' })}</span>;
        }
        if(id === newParent) {
            return <span onClick={() => onSelect(id)} className='newParent'>{intl.formatMessage({id: 'NEW PARENT' })}</span>;
        }
        if(id == taskId) {
            return <span className='currentTaskId'>{intl.formatMessage({id: 'ACTIVE TASK' })}</span>;
        }
        if(!isDisabled) {
            return <span onClick={() => onSelect(id)} className='hoverSelect'>{intl.formatMessage({id: 'SELECT' })}</span>;
        }
    };

    return (
        <div className='SelectorsDialogSearch'>
            {linesFiltered.map(({ id, title, path, status, childrens, canManage }, index) => {
                const isDisabled = isSelectDisabled({id, taskId, currentParent, status, childrens, canManage, childsFromThisTask});
                return(
                    <div key={`${id}-${index}`} onClick={() => handleClick(id, isDisabled)} className={isDisabled ? 'disabledRow' : 'row'}>
                        <div className='row__content'> <small>{path}</small>
                            <strong>{title}
                                {isSyncAppTask && currentTask?.sourceId && <SyncProjectLogo task={currentTask} toolName={syncAppType} />}
                            </strong>
                        </div>
                        {getCheckOrSelectLine(id, isDisabled)}
                    </div>
                );
            })}
        </div>
    );
};

export default memo(ChangeTaskLocationModal);