import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { useIntl } from 'react-intl';
import classnames from 'clsx';
import { browserHistory } from 'react-router';
import { Button, Badge, CircularProgress } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTasks } from '../../../utils/selectors/account';
import { getUserId } from '../../../utils/selectors/user';
import { cancelQueue, syncData } from '../../../utils/services/syncApp';
import { getAccountDataSyncApps } from '../../../utils/selectors/accountData';
import { getListenerSyncAppsProcess, offListenerSyncAppsProcess } from '../../../utils/controllers/syncApp/syncApp';
import moment from 'moment';
import useTimeout from '../../../utils/hooks/useTimeout';
import { toastr } from 'react-redux-toastr';
import { getParentConnectedProject, tracking } from '../../../utils/utils';
import { segmentTrack } from '../../../utils/firebaseEvents';
import { getParentChildIds } from '../../../utils/selectors/app';

const LastSyncTimer = ()=>{
    const intl = useIntl();
    const [lastTimeSync, setLastTimeSync ] = useState(null);
    const [timerFormatted, setTimerFormatted] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInProcess, setIsInProcess] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const accountTasks = useSelector(getAccountTasks);
    const currentAccountId = useSelector(getAccountId);
    const currentUserId = useSelector(getUserId);
    const syncApps = useSelector(getAccountDataSyncApps);
    const parentChildIds = useSelector(getParentChildIds);

    const hasConnectFolder = React.useMemo(() => _.isNull(syncApps) ? false : !!Object.values(syncApps, {}).find(el => el.syncFolder), [syncApps]);
    const seconds = moment().diff(moment(lastTimeSync), 'seconds');
    const minutes = moment().diff(moment(lastTimeSync), 'minutes');
    const hours = moment().diff(moment(lastTimeSync), 'hours');
    const days = moment().diff(moment(lastTimeSync), 'days');
    const badgeValue = minutes < 60 ? `${minutes}m` : hours < 24 ? `${hours}h` : `${parseInt(hours / 24)}d`;

    const publishNow = React.useCallback( async () => {
        try{
            setIsLoading(true);
            tracking('planningPublished', { accountId: currentAccountId });
            const syncAppParents = Object.keys(accountTasks).filter(taskId => accountTasks[taskId].childrens && accountTasks[taskId].syncAppType);

            const data = syncAppParents.reduce((acc, taskId) => {
                const task = accountTasks[taskId];

                const allChilds = _.get(task, 'childrens', [])
                    .map(({id: childTaskId}) => [{id: childTaskId},..._.get(accountTasks[childTaskId], ['childrens'], [])]);

                const connectProjectTask = task.isConnectProject ? task : getParentConnectedProject(taskId, accountTasks);
                const syncAppId = task.syncAppId ? task.syncAppId : _.get(connectProjectTask, 'syncAppId', null);
                const syncFolderSourceId = _.get(connectProjectTask, 'sourceId', null);

                const mappedData = {
                    [taskId]: {
                        oa: _.get(task, ['originalAssignees'], []), // clickup case we need to send originalAssignees //
                        psi:  _.get(accountTasks, [_.get(task, 'parent'), 'sourceId'], ''),
                        plsi: _.get(task, 'parent'),
                        od: _.get(task, ['originalDependencies'], []),
                        dl: _.get(task, 'deadline'),
                        da: _.get(task, 'doneAt'),
                        t: _.get(task, 'title'),
                        ss: _.get(task, ['status'], 'todo'),
                        st: _.get(task, ['sourceStatus','id'], ''),
                        s: _.get(task, 'sourceId'),
                        d: _.get(task, ['dependencies'], []),
                        sa: _.get(task, ['estimations','startAt']),
                        ea: _.get(task, ['estimations','expectedAt']),
                        ua: _.get(task, ['estimations','userId'], _.get(task, ['forcedUser'], [])),
                        te: _.flatten(Object.values(_.get(task, ['estimations','periods'], {}))).filter(period => period.end *1000 > Date.now()).reduce((acc,l) => parseFloat(l.nbHours) + acc, 0),
                        syncAppType: _.get(task, 'syncAppType'), // needed for jira publishing
                        storyPoint: _.get(task, 'storyPoint', null),
                        syncFolderSourceId,
                        sty: _.get(task, 'sourceType'), // needed for trello, basecamp publishing
                        startOn: _.get(task, 'startOn'), // needed for basecamp publishing
                        psty: _.get(accountTasks, [_.get(task, 'parent'), 'sourceType'], ''),
                        sourceStatus: _.get(task, 'sourceStatus'),
                        childrenCount: allChilds.length,
                    }
                };

                acc[syncAppId] = {
                    ...acc[syncAppId],
                    ...mappedData,
                };

                _.flatten(allChilds).forEach(({id: childTaskId}) => {
                    const childTask = accountTasks[childTaskId];
                    const rootParentId = _.get(parentChildIds, ['parents', childTaskId], '').at(-1);
                    const rootParentSourceId = _.get(accountTasks[rootParentId], 'sourceId', '');

                    if (!childTask) return;

                    const mappedChildData = {
                        [childTaskId]: {
                            workspaceSourceId: rootParentSourceId, // Root/Workspace parent sourceId
                            oa: _.get(childTask, ['originalAssignees'], []), // clickup case we need to send originalAssignees //
                            ps: _.get(task, 'sourceId'), // main folder|syncApp parent source Id
                            plsi: _.get(childTask, 'parent'), // parent planless id
                            psi:  _.get(accountTasks, [_.get(childTask, 'parent'), 'sourceId'], ''), // parent source Id
                            psty: _.get(task, 'sourceType'), // Parent sourceType
                            t: _.get(childTask, 'title'),
                            s: _.get(childTask, 'sourceId'),
                            min: _.get(childTask, 'minEffort', 0),
                            max: _.get(childTask, 'maxEffort', 0),
                            dl: _.get(childTask, 'deadline'),
                            d: _.get(childTask, ['dependencies'], []),
                            da: _.get(childTask, 'doneAt'),
                            ss: _.get(childTask, ['status'], 'todo'),
                            st: _.get(childTask, ['sourceStatus','id'], ''),
                            od: _.get(childTask, ['originalDependencies'], []),
                            sa: _.get(childTask, ['estimations','startAt']),
                            ea: _.get(childTask, ['estimations','expectedAt']),
                            ua: _.get(childTask, ['estimations','userId'], _.get(childTask, ['forcedUser'], [])),
                            te: _.flatten(Object.values(_.get(childTask, ['estimations','periods'], {}))).filter(period => period.end *1000 > Date.now()).reduce((acc,l) => parseFloat(l.nbHours) + acc, 0),
                            syncAppType: _.get(childTask, 'syncAppType'),
                            storyPoint: _.get(childTask, 'storyPoint', null), // needed for jira publishing
                            syncFolderSourceId,
                            sty: _.get(childTask, 'sourceType'), // needed for trello publishing
                            startOn: _.get(childTask, 'startOn'), // needed for basecamp publishing
                            sourceStatus: _.get(childTask, 'sourceStatus'),
                            childrenCount: _.get(childTask, 'childrens', []).length,
                        }
                    };

                    acc[syncAppId] = {
                        ...acc[syncAppId],
                        ...mappedChildData,
                    };
                });

                return acc;
            }, {});

            await syncData({
                accountId: currentAccountId,
                currentUserId: currentUserId,
                data,
            });

            segmentTrack('publishPlanning', {accountId: currentAccountId, userId: currentUserId});
        }catch(err){
            toastr.error('There was a problem while trying to publish.');
        } finally {
            setIsLoading(false);
        }
    }, [currentAccountId, accountTasks, currentUserId]);

    const handleGoToIntegrations = () => {
        browserHistory.push('/settings/integrations');
    };

    const handleCancel = async () => {
        try{
            setIsLoading(true);
            tracking('canceledPlanningPublish', { accountId: currentAccountId });
            await cancelQueue({
                accountId: currentAccountId
            });
        } catch(err) {
            toastr.error('There was a problem while canceling.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(currentAccountId){
            getListenerSyncAppsProcess({
                currentAccountId,
                callBack: (snap) => {
                    const val = snap.val();
                    if(val){
                        setLastTimeSync(val.lastSyncFolder);
                        setIsInProcess(val.status === 'inprocess');
                    }
                }
            });
        }
        return () => {
            if(currentAccountId){
                offListenerSyncAppsProcess();
            }
        };
    }, [currentAccountId]);


    useTimeout(() => {
        if(lastTimeSync !== Date.now() && isShowing) {
            formatTimer();
        }
    }, [1000]);

    const formatTimer = () => {
        if(seconds < 60) {
            setTimerFormatted(`${seconds} seconds ago`);
        }

        if(minutes >= 1 && minutes < 60) {
            setTimerFormatted(`${minutes} minutes and ${(seconds - (minutes * 60))} seconds ago`);
        }

        if(minutes >= 60 && hours < 24) {
            setTimerFormatted(`${hours} hours and ${minutes - hours * 60} minutes ago`);
        }

        if(days === 1) {
            setTimerFormatted(`1 day and ${parseInt(hours - 24)} hours ago`);
        }

        if(days >= 2) {
            setTimerFormatted(`${days} days ago`);
        }
    };

    const getBadgeIcon = () => {
        if(!lastTimeSync || !hasConnectFolder) {
            return <Update className="UpdateIcon" />;
        }
        return (
            <Badge overlap="rectangular" badgeContent={badgeValue} color="primary">
                <Update className="UpdateIcon" />
            </Badge>
        );
    };

    const getActionButton = () => {
        if(!isLoading) {
            return <Button onClick={publishNow} variant='contained' className='LastSyncTimer__details__publish'>
                {intl.formatMessage({id: 'publish now'})}
            </Button>;
        }
    };

    if(isInProcess && !isLoading){
        return <>
            <div className="LastSyncTimer">
                <div className='LastSyncTimer__details centered'>
                    <span className='LastSyncTimer__title'>Publishing Planing...</span>
                    <span className='email-will-besent'>You will be emailed when it's completed</span>
                    <span className='email-will-besent'>Feel free to close this window</span>
                    <Button onClick={handleCancel} variant='outlined' className='LastSyncTimer-cancel-btn'>
                        {intl.formatMessage({id: 'Cancel'})}
                    </Button>
                </div>
                <Update className="UpdateIcon" />
            </div>
        </>;
    }

    if(!hasConnectFolder) {
        return <>
            <div className="LastSyncTimer">
                <div className={'LastSyncTimer__details noSyncApps'}>
                    <img src="https://planless.sirv.com/App/emptyStates/addsynctool.png" />
                    <Button onClick={handleGoToIntegrations} variant='outlined' className='LastSyncTimer__details__redirect'>
                        {intl.formatMessage({id: 'Add a connected project'})}
                    </Button>
                </div>
                {getBadgeIcon()}
            </div>
        </>;
    }

    const getContent = () => {
        if(isLoading) return;

        if(!lastTimeSync) {
            return <h1>{intl.formatMessage({id: 'lastSyncTimer.title.publish'})}</h1>;
        }
        return (
            <>
                <h1>{intl.formatMessage({id: 'lastSyncTimer.title.last.publish'})}</h1>
                <h1 className={classnames('', {ghostLoader: !timerFormatted})}>
                    {timerFormatted}
                </h1>
            </>
        );
    };

    return <>
        <div
            className="LastSyncTimer"
            onMouseOver={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
        >
            <div className={classnames('LastSyncTimer__details', {noSyncApps: !syncApps})}>
                {isLoading && (<CircularProgress />)}

                {getContent()}

                {getActionButton()}
            </div>
            {getBadgeIcon()}
        </div>
    </>;

};

export default React.memo(LastSyncTimer);