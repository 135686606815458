import React, { useState } from 'react';
import classnames from 'clsx';
import * as firebaseEvents from '../../utils/firebaseEvents';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTaskCanManage, getAccountTaskDeadline, getAccountTaskHasChildren, getAccountTaskIsRecurring, getAccountTaskStatus } from '../../utils/selectors/account';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import { DateTimePicker } from '@material-ui/pickers';
import RemoveIcon from '@material-ui/icons/Cancel';
import useInternalization from '../../utils/hooks/useInternalization';
import { isRegularTimeFormat } from '../../utils/utils';
import moment from 'moment';

export const TaskDetailDueDate = ({ taskId }) => {
    const { t } = useInternalization();
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskIsRecurring = useSelector(getAccountTaskIsRecurring(taskId));
    const taskHasChildren = useSelector(getAccountTaskHasChildren(taskId));
    const taskDeadline = useSelector(getAccountTaskDeadline(taskId));
    const [deadline, setDeadline] = useState(taskDeadline);
    const accountId = useSelector(getAccountId);
    const userDateFormat = useSelector(getUserDateformat);
    const userTimeFormat = useSelector(getUserTimeformat);

    const dateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
    const isRegularTime = isRegularTimeFormat(userTimeFormat);

    const changeDeadline = (date) => {
        if(!taskCanManage || taskStatus === 'done' || !date) {
            return;
        }

        const dateISO = date.toISOString();
        setDeadline(dateISO);
        firebaseEvents.changeTaskDeadline(accountId, taskId, dateISO);
    };

    const removeDeadline = () => {
        setDeadline(null);
        firebaseEvents.addChallenge('removeDuedateTask');
        firebaseEvents.changeTaskDeadline(accountId, taskId, null);
    };

    const isDisabled = !(taskStatus !== 'done' && taskCanManage && !(taskIsRecurring && taskHasChildren));

    return (
        <React.Fragment>
            <li>
                <dl>
                    <dt>
                        {t('Due date')}
                    </dt>
                    <dt className={classnames('Duedate', { regularTime: isRegularTime })}>
                        <DateTimePicker
                            className={classnames('taskDueDate')}
                            margin="normal"
                            placeholder={t('None')}
                            value={deadline ? deadline : new Date(new Date().setHours(0, 0, 0, 0))}
                            labelFunc={() => deadline ? moment(deadline).format(dateTimeFormat) : ''}
                            onChange={changeDeadline}
                            fullWidth
                            cancelLabel="Cancel"
                            okLabel="Confirm"
                            ampm={isRegularTime}
                            disabled={isDisabled}
                            showTodayButton
                            todayLabel={t('now')}
                        />
                        {deadline && <RemoveIcon className="RemoveDuedate" onClick={removeDeadline} />}
                    </dt>
                </dl>
            </li>
        </React.Fragment>
    );
};

TaskDetailDueDate.propTypes = {
    taskId: PropTypes.string.isRequired
};
