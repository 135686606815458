import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { CheckBox, Delete, Edit, Filter1, ListAlt, TextFields, Today } from '@material-ui/icons';
import Group from '@material-ui/icons/Group';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
import { getAccountCustomFields, getAccountId } from '../../utils/selectors/account';
import classnames from 'clsx';
import { toastr } from 'react-redux-toastr';
import { saveCustomFieldSetting } from '../../utils/firebaseEvents';
import { tracking } from '../../utils/utils';

const types = {
    text: { name: 'Text', icon: <TextFields /> },
    checkbox: { name: 'Checkbox',  icon: <CheckBox /> },
    number: { name: 'Number',  icon: <Filter1 /> },
    people: { name: 'People',  icon: <Group /> },
    date: { name: 'Date',  icon: <Today /> },
    list: { name: 'List', icon: <ListAlt /> }
};

const CustomFieldsSettings = () => {
    const { t } = useInternalization();
    const accountId = useSelector(getAccountId);

    const fields = useSelector(getAccountCustomFields);
    const [modal, setModal] = useState(false);
    const [radioType, setRadioType] = useState('text');
    const [listList, setListList] = useState(['Choice 1']);
    const [modalNameField, setModalNameField] = useState('');

    const deleteField = (fieldId)=>{
        toastr.confirm(t('custom-fields-setting-confirm-delete'), {
            onOk: () => {

                saveCustomFieldSetting({
                    accountId: accountId,
                    id: fieldId, 
                    type: null, 
                    name: null, 
                    choices: null,
                    deleteTasksData:true
                });

                tracking('Deleted custom field');

            },
            okText: t('DELETE'),
            cancelText: t('CANCEL'),
        });
    };


    const getList = () => {
        return fields ? Object.keys(fields).sort((a,b)=>{ return (fields[a].name.toUpperCase() < fields[b].name.toUpperCase()) ? -1 : 1; }).map(fieldId => {

            return (
                <TableRow className="fieldItem" key={fieldId}>
                    <TableCell>
                        <b>{fields[fieldId].name}</b>
                    </TableCell>
                    <TableCell>{types[fields[fieldId].type].name}</TableCell>
                    <TableCell><em>{fields[fieldId].choices ? fields[fieldId].choices.join(', '):''}</em></TableCell>
                    <TableCell align="right">
                        <IconButton size="small" onClick={()=> { 
                            setModal(fieldId); 
                            setRadioType(fields[fieldId].type); 
                            setModalNameField(fields[fieldId].name); 
                            setListList(fields[fieldId].type === 'list' ? [...fields[fieldId].choices, ''] : ['Choice 1', '']);
                        }}>
                            <Edit />
                        </IconButton>
                        <IconButton size="small" onClick={()=> { deleteField(fieldId); }}>
                            <Delete />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );

        }) : null;
    };

    const changeListList = (index, value) => {
        let newArray = [...listList];
        newArray[index] = value;
        if(newArray[newArray.length - 1] !== ''){
            newArray.push('');
        }
        setListList(newArray);
    };

    const deleteListList = (index) => {
        let newArray = [...listList];
        newArray.splice(index, 1);
        setListList(newArray);
    };

    const save = () => {
        if(modalNameField === ''){
            toastr.error(t('custom-fields-setting-error-missing-name'));
            return;
        }
        let list = null;
        if(radioType === 'list'){
            list = listList.filter((item,i,self) => item !== '' && self.indexOf(item) === i);
            if(list.length === 0){
                toastr.error(t('custom-fields-setting-error-missing-list'));
                return;
            }
        }

        if(modal !== true && modal !== false && radioType !== fields[modal].type){
            toastr.confirm(t('custom-fields-setting-confirm-change-type'), {
                onOk: () => {

                    saveCustomFieldSetting({
                        accountId: accountId,
                        id: modal === true ? null:modal, 
                        type: radioType, 
                        name: modalNameField, 
                        choices: list,
                        deleteTasksData: true    
                    });
        
                    setModal(false);

                    tracking('Updated custom field');

                },
                okText: t('CONFIRM'),
                cancelText: t('CANCEL'),
            });
        }
        else {
            saveCustomFieldSetting({
                accountId: accountId,
                id: modal === true ? null:modal, 
                type: radioType, 
                name: modalNameField, 
                choices: list,
                deleteTasksData: false
            });

            setModal(false);

            tracking(modal === true ? 'Created custom field':'Updated custom field');
        }

    };


    return (
        <div className='CustomFieldsSettings'>
            <h1>{t('Custom fields')}</h1>
            
            <Button variant="contained" color="primary" onClick={()=>{ 
                setModal(true); 
                setRadioType('text'); 
                setModalNameField(''); 
                setListList(['Choice 1', '']);
            }}>{t('Add a custom field')}</Button>

            <p>
                {t('custom-fields-settings-text-intro')}
            </p>

            <div className="fieldsList">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Type')}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getList() || <TableRow>
                            <TableCell align="center" colSpan="4"><em>{t('custom-fiedls-settings-empty-state')}</em></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            {modal && <Dialog open={true} onClose={()=>setModal(false)} className="customFieldModal" maxWidth={false}>
                <DialogTitle>{t(modal === true ? 'custom-fields-settings-new':'custom-fields-settings-edit')}</DialogTitle>
                <DialogContent>
                    <TextField label={t('Name of the field')} value={modalNameField} onChange={(e)=>setModalNameField(e.target.value)} fullWidth autoFocus onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            save();
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }} />
                    <div className="customFieldTypes">
                        {Object.keys(types).map(fieldId => {
                            return (
                                <div key={fieldId} className={classnames('fiedlType', {active: radioType === fieldId})} onClick={()=>setRadioType(fieldId)}>
                                    {types[fieldId].icon}
                                    <h2>{types[fieldId].name}</h2>
                                    <p>{t(`custom-fields-settings-desc-${fieldId}`)}</p>
                                </div>
                            );
                        })}
                    </div>
                    {radioType === 'list' && <div className="listList">
                        {listList.map((listItem,index) => {
                            return (
                                <div className={classnames('listListItem', {new: listItem === ''})} key={index}>
                                    <span>Choice {index+1}:</span>
                                    <TextField value={listItem} fullWidth onChange={(e)=>changeListList(index, e.target.value)} />
                                    <IconButton size="small" onClick={()=>deleteListList(index)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            );
                        })}
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={()=>setModal(false)}>{t('Cancel')}</Button>
                    <Button variant='contained' color="primary" onClick={save}>{t('Save')}</Button>
                </DialogActions> 
            </Dialog>}

            
        </div>
    );
};

export default withCustomErrorBoundary(CustomFieldsSettings);