import { useIntl } from 'react-intl';
import React from 'react';
import BreakdownLvL from './BreakdownLvl';

export const DefaultBreakdownLvls = (t) => [
    {
        value: 'none',
        text: t.formatMessage({ id: 'breakdown.select.none' })
    },
    {
        value: 'tasks',
        text: t.formatMessage({ id: 'breakdown.select.subItens' })
    },
    {
        value: 'skills',
        text: t.formatMessage({ id: 'breakdown.select.skills' })
    },
    {
        value: 'users',
        text: t.formatMessage({ id: 'breakdown.select.teamMembers' })
    },
];

const defaultRules = {
    'tasks': ['none', 'users', 'skills'],
    'skills': ['none', 'tasks'],
    'users': ['none', 'tasks'],
    'none': ['none' , 'users', 'skills', 'tasks'],
};

const BreakdownController = ({
    tasks,
    skills,
    teamMembers,
    updateBreakdowns,
    breakdowns, 
    enabled = true,
    rules = defaultRules,
    preDefaultBreakdownLvls = null
}) => {
    const intl = useIntl();
    const  defaultBreakdownLvls = preDefaultBreakdownLvls || DefaultBreakdownLvls(intl);
   
    
    const handleChange = (lvlChanged, value) => {
        const newLvl = breakdowns.filter(el => el.lvl <= lvlChanged)
            .map(el => el.lvl === lvlChanged ?
                { options: el.options, lvl: lvlChanged, value: value, selectedData: [] }
                : el);

        if(lvlChanged === 0) {
            const m = defaultBreakdownLvls.filter(el => rules[value].includes(el.value));
            value !== 'none' && newLvl.push({
                lvl: newLvl.length,
                value: 'none',
                selectedData: [],
                options: m,
            });
        }

        updateBreakdowns(newLvl);
    };
    
    const handleCheckbox = (lvlChanged, data) => {
        const newLvl = breakdowns.map(el => el.lvl === lvlChanged ? {
            ...el,
            selectedData: data,
        } :  el);

        updateBreakdowns(newLvl);
    };

    return breakdowns.map(el => {
        const shouldRender = el.options.filter(el => el.value !== 'none').length;
        return shouldRender ? <BreakdownLvL
            key={`${el.lvl}-${el.value}`}
            enabled={enabled}
            options={el.options}
            tasks={tasks}
            skills={skills}
            teamMembers={teamMembers}
            handleChange={handleChange}
            updateCheckboxs={handleCheckbox}
            currentLvl={el.lvl}
            currentValue={el.value}
        /> : null;
    }
    );
};

export default BreakdownController;