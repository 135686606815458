import React from 'react';
import classnames from 'clsx';
import PropTypes from 'prop-types';
import FolderOpen from '@material-ui/icons/FolderOpen';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { openChangeTaskLocationModal } from '../App/AppActions';
import { getAccountTaskPath, getAccountTaskCanManage, getAccountTaskParent } from '../../utils/selectors/account';
import { getOrderedTaskListLength } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
import useSyncData from '../../utils/hooks/useSyncData';

const TaskDetailPath = ({ taskId }) => {
    const { t } = useInternalization();
    const taskPath = useSelector(getAccountTaskPath(taskId), shallowEqual);
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId));
    const taskParent = useSelector(getAccountTaskParent(taskId), shallowEqual);
    const orderedTaskListLength = useSelector(getOrderedTaskListLength);
    const { isSyncAppTask, isInsideSyncFolder } = useSyncData(taskId);
    const dispatch = useDispatch();

    const getTaskPath = ()=> {
        if (!taskPath?.length) {
            return '/';
        }
        return taskPath?.join(' / ');
    };

    return (
        <div className="taskPath">
            <FolderOpen /> <span>{getTaskPath()}</span>

            {orderedTaskListLength > 1 && !isInsideSyncFolder && !isSyncAppTask &&
                <button
                    data-sidebar='preventClose'
                    size="small"
                    type="button"
                    disabled={!taskCanManage}
                    onClick={() => dispatch(openChangeTaskLocationModal({taskId, parent: taskParent || null}))}
                    className={classnames('changeLocationButton', { disabled: !taskCanManage })}
                >
                    {t('CHANGE LOCATION')}
                </button>
            }
        </div>
    );
};

TaskDetailPath.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default withCustomErrorBoundary(TaskDetailPath);





