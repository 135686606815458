import React from 'react';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getUserPermissionsAdmin } from '../../utils/selectors/user';
import { getAccountBasePermissionsManagers, getAccountBasePermissionsOwners } from '../../utils/selectors/account';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import useInternalization from '../../utils/hooks/useInternalization';
import getSyncAppData from '../../utils/hooks/useSyncData/syncApps';
import { getParentChildIds } from '../../utils/selectors/app';
import { getAccountDataSyncApps } from '../../utils/selectors/accountData';
import { getTaskPathDescription } from './utils';

const AddTaskListAutoComplete = ({ tasks, orderedTaskList, onLocationChange, userId, location }) => {
    const { t } = useInternalization();
    const isAdmin = useSelector(getUserPermissionsAdmin);
    const basePermissionsOwners = useSelector(getAccountBasePermissionsOwners);
    const basePermissionsManagers = useSelector(getAccountBasePermissionsManagers);

    const parentChildIds = useSelector(getParentChildIds);
    const syncApps = useSelector(getAccountDataSyncApps);

    const parents = _.get(parentChildIds, ['parents', location?.id], []);

    const options = orderedTaskList ? orderedTaskList.reduce((acc, taskOption) => {
        if(
            acc &&
            !acc.find(el => el.id === 'base') &&
            ((isAdmin) ||
            (basePermissionsOwners.find(id => id === userId)) ||
            (basePermissionsManagers.find(id => id === userId)))
        ) {
            acc.push({id: 'base', title: `---${t('Top level of the workspace')}---`, syncAppType: null});
        }

        if(acc && taskOption?.canManage) {
            const taskSyncAppData = getSyncAppData(taskOption.id, tasks, parents, syncApps);
            const isSyncAppTask = _.get(taskSyncAppData, 'isSyncAppTask', false);
            const canAddSyncAppTask = _.get(taskSyncAppData, 'canAddSyncAppTask', false);

            if (isSyncAppTask && !canAddSyncAppTask) {
                return acc;
            }

            acc.push({ ...taskOption, title: getTaskPathDescription(taskOption) });
        }

        return acc;
    }, []) : [];

    return (
        <Autocomplete
            className="destAutocomplete"
            options={options}
            value={location}
            getOptionLabel={option => option.title || ''}
            disableClearable
            renderOption={option => {
                return (
                    <>
                        {option.title}
                        {option.syncAppType && <SyncProjectLogo toolName={option.syncAppType} task={option}/>}
                    </>
                );
            }}
            renderInput={params => (
                <TextField {...params} label={t('Location')} fullWidth />
            )}
            onChange={onLocationChange}
        />
    );
};

export default AddTaskListAutoComplete;