import React, { useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useIntl } from 'react-intl';
import { menuItems } from '../utils';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import classnames from 'clsx';

const MenuItem = ({ onClick,  type, translation, isActive }) => {
    const handleClick = useCallback(() => {
        onClick(type);
    }, [type]);
    const intl = useIntl();

    return  <ListItem
        className={classnames({ active: isActive })}
        button
        onClick={handleClick}
    >
        <ListItemText primary={intl.formatMessage({id: `reports.types.${translation}`})} />
        {(translation !== 'overview')?(
            <ListItemSecondaryAction>
                <Tooltip placement="right" title={intl.formatMessage({id: `reports.types.${translation}.description`})}>
                    <InfoIcon />
                </Tooltip>
            </ListItemSecondaryAction>
        ):null}
    </ListItem>;
};


const Menu = ({ activeMenu, handleMenuClick }) => {
    return (
        <List className="Reports-Menu">
            {menuItems.map((el) => 
                <React.Fragment key={`${el.type}-${el.index}-${Math.random()}`}>
                    {el.subheader?(
                        <ListSubheader disableSticky>{el.name}</ListSubheader>
                    ):(
                        <MenuItem 
                            {...el} 
                            key={`${el.type}-${el.index}`}
                            isActive={ activeMenu === el.type} 
                            onClick={handleMenuClick} />
                    )}
                    {/*{index < menuItems.length && <Divider />}*/}
                </React.Fragment>)
            }
        </List>
    );
};

export default React.memo(Menu);