import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, MultiGrid, CellMeasurer, CellMeasurerCache  } from 'react-virtualized';
import classnames from 'clsx';

import { 
    getParentsFromDataSets
} from './utils';

const _cache = new CellMeasurerCache({
    defaultHeight: 30,
    defaultWidth: 150,
    fixedHeight: true,
});
const DataTable = ({ onColumnHover, datasets, labels, renderColumns, formatTitle }) =>   {
    const grid = useRef(null);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableRows, setTableRows] = useState([]);
   
    useEffect(()=> {
        const datasetsParents = getParentsFromDataSets(datasets);
        let newRows = datasetsParents.map(el => {
            if(el.subFolders.length && el.name !== ''){
                const sub = el.subFolders.map(sub => renderColumns(sub, true, datasetsParents)).flat();
            
                return [
                    [
                        { value: el.name, isName: true },
                        ...labels.map(() => ({ value: '' }))
                    ],   
                    ...sub           
                ];
            } 
            return renderColumns(el, false, datasetsParents);
        }).flat();
        let columns = [ {value: '', isHeader: true }, ...labels.map((el) => ({ value: formatTitle(el), isHeader: true }))];
        const rows = [columns, ...newRows];
        setTableColumns(columns);
        setTableRows(rows.filter(Boolean));
        _cache.clearAll();
    }, [grid, datasets, labels]);
   
    const handleMouseLeave = () => onColumnHover(null);
    const handleMouseOver =(column) => { onColumnHover(column); };

    const cellRenderer = ({ parent, columnIndex, key, rowIndex, style, }) => {
        if(!tableRows[rowIndex] ){
            return null;
        }
        const item = tableRows[rowIndex][columnIndex];
        if(!item){
            return null;
        }
        return ( <CellMeasurer
            cache={_cache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}>
            <div
                data-column={columnIndex}
                onMouseOver={() => handleMouseOver(columnIndex)}
                key={key} 
                style={style}
                className={classnames({
                    ['name-cell']: item.isName, 
                    ['name-child']: item.isName && item.isChild, 
                    ['table-header']: item.isHeader, 
                    ['parent']: !item.isChild,
                    ['child']: item.isChild,
                    ['title-cell']: item.isTitle,
                    cell: !item.isTitle })}
            >
                {item.value}
            </div>
        </CellMeasurer>
        );
    };

    return <div onMouseLeave={handleMouseLeave} style={{width: '100%', height: (tableRows.length +  1) * 40 }}>
        <AutoSizer>
            {({ width }) => {
                return (<MultiGrid
                    className={'BodyGrid'}
                    columns={tableColumns}
                    tableColumns={tableColumns}
                    tableRows={tableRows}
                    ref={grid}
                    fixedColumnCount={1}
                    onColumnHover={handleMouseOver}
                    cellRenderer={cellRenderer}
                    columnCount={tableColumns.length}
                    deferredMeasurementCache={_cache}
                    columnWidth={_cache.columnWidth}
                    height={(tableRows.length +  1) * 40}
                    rowCount={tableRows.length + 1}
                    rowHeight={40}
                    width={width}
                />);
            }}
        </AutoSizer>
    </div>;
};

export default DataTable;