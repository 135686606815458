import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import classnames from 'clsx';
import OverViewCard from '../OverViewCard';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router';

const TaskOverview = ({ 
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    // get all of the sub childs 
    const workspaceTasks = React.useMemo(() =>workspaceSelected === 'root' ? 
        Object.keys(stateTasks) : _.get(childsParents, workspaceSelected , [workspaceSelected]), [workspaceSelected, childsParents]);

    const overviewCurrentData = React.useMemo(() => workspaceTasks.reduce((acc, el) => {
        const currentTask = stateTasks[el];

        if(!currentTask || stateTasks.parent && !stateTasks[stateTasks.parent]){
            return acc;
        }
        
        if(currentTask.status === 'done' && !currentTask.childrens){
            acc.done++;
        }

        if(currentTask.status !== 'done' && !currentTask.childrens){
            acc.notDone++;
        }

        return acc;
    }, { done: 0, notDone: 0 }), [workspaceTasks, stateTasks]); 
    const path = window.location.pathname.split('/').slice(0,3).join('/');

    return <div className={classnames('bigNumbers')}>
        <OverViewCard title={'Tasks Done'} value={overviewCurrentData.done} />
        <OverViewCard title={'Tasks to be Done'} value={overviewCurrentData.notDone} />
        <OverViewCard title={'Total Tasks'} value={overviewCurrentData.done + overviewCurrentData.notDone} />
        <OverViewCard title={'% complete'} 
            value={overviewCurrentData.notDone + overviewCurrentData.done > 0 ? 
                `${Math.round((overviewCurrentData.done*100) / (overviewCurrentData.notDone + overviewCurrentData.done) * 100) / 100}%` 
                : '0%'  } />
        <div className="overview-report-link">
            <Button component={Link}  size="small" variant="outlined" color="primary"
                key="task-count-burndown" 
                to={`${path}/task-count-burndown`}
            >
                Go to <b>Task Count Burndown</b> report
            </Button>
        </div>
    </div>
    ;
};

TaskOverview.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default TaskOverview;