import { getUserUid } from '../selectors/user';
import { getState } from '../../configureStore';

const segmentIdentify = (data = {}) => {
    const userUid = getUserUid(getState());

    if (window.analytics && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')) {
        window.analytics.identify(userUid, data);
    }
};

export default segmentIdentify;
