import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import { downloadFile, getPath }from '../utils';
import TableContainer from '../TableContainer';
import Unautorized from '../../Unautorized/Unautorized';
import TableCell from '@material-ui/core/TableCell';
import TaskEventCell from '../TaskEventCell';
import UserCell from '../UserCell';
import TableRow from '@material-ui/core/TableRow';
import { getAccountSkills, getAccountUsers } from '../../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TimeWorkDetail = ({
    datesRange,
    dateFormat,
    stateTasks,
    workspaceSelected,
    hasAutorization,
    childsParents,
    userTimezone
}) => {
    const accountTeamMembers = useSelector(getAccountUsers);
    const accountSkills = useSelector(getAccountSkills);
    const intl = useIntl();
    const [dataTablesData, setDataTablesData] = useState([]);
    const  defaultBreakdownLvls = DefaultBreakdownLvls(intl);
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);

    const updateBreakdowns = async (newBreakdowns) => {
        setBreakdownData(newBreakdowns);
    };


    useEffect( () => {
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
    }, [workspaceSelected]);


    const headCells = [
        { id: 'path',  label: 'Path' },
        { id: 'taskName',  label: 'Task Name' },
        { id: 'who', type: 'user', label: 'Who' },
        { id: 'date', type: 'date', label: 'Date' },
        { id: 'hours', type: 'float' , label: 'hours' },
    ];

    const renderRow =  (idx, row) => [
        <TableCell key={`time-work-detail-${row.id}-path`} align="left">{row.path}</TableCell>,
        <TableCell key={`time-work-detail-${row.id}-name`} align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>,
        <TableCell key={`time-work-detail-${row.id}-user`} align="left"><UserCell color={row.who.color} key={row.who.userId} userId={row.who.userId} avatarPath={row.who.avatar} name={row.who.name}  /> </TableCell>,
        <TableCell key={`time-work-detail-${row.id}-date`} align="left">{row.date !== 'N/A' ? moment.tz(row.date, 'YYYY-MM-DD', userTimezone).format(dateFormat) : row.date }</TableCell>,
        <TableCell key={`time-work-detail-${row.id}-hour`} align="left">{parseFloat(row.hours).toFixed(2)}</TableCell>,
    ];

    const handleExportAsCSV = ({name, data}) => {
        const columns = headCells.map(el =>  el.label).join(';');
        const content = data.map(el => {
            const row = headCells.reduce((acc, { id, type }) => {
                let field = el[id];

                if(type === 'user') {
                    field = field.name;
                }
                if(type === 'float') {
                    field = parseFloat(field).toFixed(2);
                }

                if(type === 'date') {
                    field =  el[id] !== 'N/A' ? moment.tz(moment(el[id]), 'YYYY-MM-DD', userTimezone || moment.tz.guess()).format('YYYY-MM-DD') : el[id];
                }

                return `${acc}${field};`;
            }, '');
            return row;
        });
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${columns}\r\n${content.join('\r\n')}`), `Time-worked${name}`);
    };

    useEffect(()=> {
        const newDataTablesData = [];
        if (stateTasks && accountTeamMembers) {
            // get breakdowns =
            const breakdownBySkillEntry = breakdownData.find(el => el.value === 'skills');
            const breakdownByTaskEntry = breakdownData.find(el => el.value === 'tasks');
            const breakdownByUsersEntry = breakdownData.find(el => el.value === 'users');

            let workspaceTasks = {};
            if(breakdownByTaskEntry?.selectedData.length) {
                workspaceTasks = breakdownByTaskEntry.selectedData.map(el => {
                    return childsParents[el] ? ({source: el, childs: childsParents[el]}) : ({source: el, childs: [el]});
                });
            } else {
                workspaceTasks = [
                    {
                        source: workspaceSelected,
                        childs: workspaceSelected === 'root' ? Object.keys(stateTasks) : _.get(childsParents, workspaceSelected , [workspaceSelected])
                    }
                ];
            }



            workspaceTasks.forEach(taskEntry => {
                taskEntry.childs.forEach(taskId => {

                    const currentTask = stateTasks[taskId];
                    if(!currentTask || !currentTask.workingTime){
                        return;
                    }
                    const workTimeEntries = Object.values(currentTask.workingTime);
                    workTimeEntries.forEach((workTimeEntry) => {

                        const workTimeEntryMember = workTimeEntry.member ? !_.isArray(workTimeEntry.member) ? [workTimeEntry.member] : workTimeEntry.member : [];
                        workTimeEntryMember.forEach(memberId => {

                            const workTimeDate = moment.tz(moment(workTimeEntry.date), 'YYYY-MM-DD', userTimezone);
                            if (!workTimeDate.isBetween(moment(datesRange.startDate), moment(datesRange.endDate))) {
                                return false;
                            }

                            const taskInfo =  {
                                id: taskId,
                                path: currentTask.parent ? getPath(currentTask.parent, stateTasks) : currentTask.title,
                                taskName: currentTask.title || '',
                                date: workTimeDate.format() ||  'N/A',
                                who: {
                                    name: accountTeamMembers[memberId]?.displayName,
                                    avatar: accountTeamMembers[memberId]?.avatar,
                                    userId: memberId,
                                },
                                hours: workTimeEntry.hours,
                            };

                            let key = workspaceSelected;
                            let name = workspaceSelected === 'root' ? '' : stateTasks[workspaceSelected].title;

                            let taskUser = {
                                isForced: false,
                                userId: memberId ,
                                name: accountTeamMembers[memberId]?.displayName
                            };


                            if (breakdownBySkillEntry && breakdownBySkillEntry.lvl === 0 && breakdownBySkillEntry.selectedData.length) {
                                if (!currentTask?.skill?.some(skillId => breakdownBySkillEntry.selectedData.includes(skillId))) {
                                    return false;
                                }
                                key = currentTask?.skill?.join('|');
                                name  = currentTask?.skill?.map(skillId => accountSkills[skillId]?.name || '').join(' ');

                                // if it is breakdown by task as well
                                if(breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length){

                                    name  += ` ${stateTasks[taskEntry.source].title}`;

                                    key += `|${taskEntry.source}`;
                                }
                            }

                            if (breakdownByUsersEntry && breakdownByUsersEntry.lvl === 0 && breakdownByUsersEntry.selectedData.length) {
                                const onlySelectedUsers = breakdownByUsersEntry.selectedData.filter(userId => userId === taskUser.userId);
                                if(!onlySelectedUsers.length) {
                                    return  false;
                                }

                                onlySelectedUsers.forEach(userId => {
                                    let keyForUsers = userId;
                                    let nameForUsers = accountTeamMembers[userId]?.displayName || '';

                                    if(breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length){

                                        nameForUsers  += ` ${stateTasks[taskEntry.source].title}`;

                                        keyForUsers += `|${taskEntry.source}`;
                                    }

                                    const dataTableEntry = newDataTablesData.find(el => el.id === keyForUsers);

                                    if (dataTableEntry) {
                                        dataTableEntry.data.push({ ...taskInfo });
                                    } else {
                                        newDataTablesData.push({ name: nameForUsers, id: keyForUsers, data: [{ ...taskInfo }]});
                                    }
                                });
                                return;
                            }

                            if (breakdownByTaskEntry && breakdownByTaskEntry.lvl === 0 && breakdownByTaskEntry.selectedData.length) {
                                key = taskEntry.source;
                                name  = stateTasks[taskEntry.source].title;
                                if (breakdownBySkillEntry && breakdownBySkillEntry.selectedData.length) {
                                    key += currentTask?.skill?.join('|');
                                    name += currentTask?.skill?.map(skillId => accountSkills[skillId]?.name || '').join(' ');
                                }

                                if (breakdownByUsersEntry && breakdownByUsersEntry.selectedData.length) {
                                    const onlySelectedUsers = breakdownByUsersEntry.selectedData.filter(userId => userId === taskUser.userId);
                                    if(!onlySelectedUsers.length) {
                                        return  false;
                                    }

                                    onlySelectedUsers.forEach(userId => {
                                        name  += ` ${accountTeamMembers[userId]?.displayName || ''}`;
                                        key += `|${userId}`;

                                        const dataTableEntry = newDataTablesData.find(el => el.id === userId);

                                        if (dataTableEntry) {
                                            dataTableEntry.data.push({ ...taskInfo });
                                        } else {
                                            newDataTablesData.push({ name, id: key, data: [{ ...taskInfo }]});
                                        }
                                    });

                                    return;
                                }
                            }

                            // no breakdowns = taskInfo
                            const dataTableEntry = newDataTablesData.find(el => el.id === key);

                            if (dataTableEntry) {
                                dataTableEntry.data.push(taskInfo);
                            } else {
                                newDataTablesData.push({ name, id: key, data: [taskInfo]});
                            }
                        });
                    });
                });
            });
            setDataTablesData(newDataTablesData);
        }

    }, [stateTasks, datesRange, workspaceSelected, breakdownData, accountTeamMembers]);

    // get all the Skills and team members available
    const availableTeamMembers = React.useMemo(() => Object.keys(accountTeamMembers), [accountTeamMembers]);
    const availableSkills = React.useMemo(() => Object.keys(accountSkills), [accountSkills]);

    // get all of the sub childs
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ?
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    if (!hasAutorization) {
        return <Unautorized />;
    }
    const extraRows = (data) =>  {
        if(!data.length) {
            return;
        }

        const totalHours = data.reduce((acc, el) => acc + parseFloat(el.hours), 0);
        return <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell className={'bold'} align="left" colSpan={2}>Total Hours</TableCell>
            <TableCell className={'bold'} align="left" >{parseFloat(totalHours).toFixed(2)}</TableCell>
        </TableRow>;
    };

    return <>
        <Paper>
            <div className="breakdownsContainer">
                <BreakdownController
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    tasks={subItensTasks}
                    teamMembers={availableTeamMembers}
                    skills={availableSkills}
                    enabled={dataTablesData.length}
                    workspaceSelected={workspaceSelected}
                />
            </div>
        </Paper>
        <Paper>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                    dataTablesData.length ?
                        dataTablesData.map(el => <TableContainer
                            defaultSort='date'
                            headCells={headCells}
                            renderRow={renderRow}
                            exportAsCSV={handleExportAsCSV}
                            key={el.id}
                            extraRows={extraRows(el.data)}
                            {...el}
                        />)
                        : <div className={'empty'}>No time work available</div>
                }
            </div>
        </Paper>
    </>;
};

TimeWorkDetail.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    dateFormat: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,

};

export default withCustomErrorBoundary(TimeWorkDetail);