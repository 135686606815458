import React from 'react';
import { Avatar } from '@material-ui/core';
import Tooltip from 'rc-tooltip';

const UserAvatar = ({ displayName = '', color='', avatarUrl = '', deletedUser = false, className = '', disableTooltip = false }) => {
    if(deletedUser) {
        return  <div className={`deletedUsersIcon ${className}`}> ? </div>;
    }

    if(avatarUrl) {
        if(disableTooltip) {
            return <div>
                <Avatar className={`defaultAvatar ${className}`} sizes='small' src={avatarUrl} />
            </div>;
        }

        return <Tooltip placement={'top'} overlay={displayName}>
            <div>
                <Avatar className={`defaultAvatar ${className}`} sizes='small' src={avatarUrl} />
            </div>
        </Tooltip>;
    }

    const userTags = displayName?.split(' ').slice(0,2).map(el => el[0]).join('');
    const useColor = color || '#202020';

    if(disableTooltip) {
        return <div className={`defaultAvatar ${className}`} style={{ backgroundColor: useColor }}> {userTags} </div>;
    }

    return <Tooltip placement={'top'} overlay={displayName}>
        <div className={`defaultAvatar ${className}`} style={{ backgroundColor: useColor }}> {userTags} </div>
    </Tooltip>;
};

export default React.memo(UserAvatar);