import React from 'react';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import Header from '../Header/Header';

const StructOn = ({children}) => {
    return (
        <div className='StructOn'>
            <Header />
            <div>
                {children}
            </div>
        </div>
    );
};

export default withCustomErrorBoundary(StructOn);
