import moment from 'moment';
import React from 'react';
import useInternalization from '../../../../utils/hooks/useInternalization';
var TrialDetail = function (_a) {
    var endPlanAt = _a.endPlanAt;
    var t = useInternalization().t;
    var endPlanDate = moment(endPlanAt);
    var daysLeft = endPlanDate.isBefore() ? 0 : endPlanDate.diff(moment(), 'days');
    return (React.createElement("div", { className: "trial-detail" },
        React.createElement("span", { className: "trial-detail__days" }, daysLeft),
        React.createElement("div", { className: "trial-detail__description" },
            React.createElement("span", null, t('settings.subscription.trialDetail.daysLeft')),
            React.createElement("span", null, t('settings.subscription.trialDetail.onYourTrial')))));
};
export default TrialDetail;
