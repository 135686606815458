import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import BreakdownController, { DefaultBreakdownLvls } from '../Breakdown/BreakdownController';
import { downloadFile, getPath } from '../utils';
import TableContainer from '../TableContainer';
import Unautorized from '../../Unautorized/Unautorized';
import TableCell from '@material-ui/core/TableCell';
import TaskEventCell from '../TaskEventCell';
import { getAccountsDataTasks } from  '../../../utils/controllers/tasks/budget';
import { currencyFormat  } from '../../../utils/currencyFormat';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';


const getInfo = (task, id, stateTasks, expense) => ({
    id: id,	
    path: task.parent ? getPath(task.parent, stateTasks) : task.title,	
    taskName: task.title || '',
    date: expense.date,
    expenseTitle: expense.title,
    expenseTypes: expense.types, 
    expenseUnits: expense.units,
    expensePricePerUnit: expense.pricePerUnit,
    expenseTotal: expense.total,
    expensetotalWithVat: expense.totalWithVat,
    vat: expense.vat,
});

const RiskList = ({ 
    stateTasks,
    workspaceSelected,
    hasAutorization,
    dateFormat,
    childsParents,
}) => {
    const userDateFormat = useSelector((state) => state.app?.user?.data?.dateFormat || 'DD/MM/YYYY');
    const expensesTypes = useSelector((state) => state.app?.account?.settings?.expensesTypes || {});
    const currency = useSelector((state) => state.app?.account?.settings.costBudget.currency || 'USD');
    const [ accountsDataTasks, setAccountsDataTasks] = useState({});
    const intl = useIntl();
    const [dataTablesData, setDataTablesData] = useState([]);
    const  defaultBreakdownLvls = [
        ...DefaultBreakdownLvls(intl).filter(el =>  el.value === 'none' || el.value === 'tasks'),
        {
            value: 'expenseTypes',
            text: intl.formatMessage({ id: 'expense-types' })
        }
    ];
    const [breakdownData, setBreakdownData] = useState([
        {
            lvl: 0,
            value: 'none',
            selectedData: [],
            options: defaultBreakdownLvls,
        },
    ]);

    const updateBreakdowns = async (newBreakdowns) => {
        setBreakdownData(newBreakdowns);
    };
    
   
    useEffect( () => {
        setBreakdownData([
            {
                lvl: 0,
                value: 'none',
                selectedData: [],
                options: defaultBreakdownLvls,
            },
        ]);
    }, [workspaceSelected]);
    
    useEffect( () => {
        getAccountsDataTasks().then(data => setAccountsDataTasks(data));
    }, []);

     
    useEffect(()=> {
        const newDataTablesData = [];
        if (stateTasks && !_.isEmpty(accountsDataTasks)) {
            let workspaceTasks = {};
            const breakdownExpenseTypes = breakdownData.find(el => el.value === 'expenseTypes');
            const breakdownByTaskEntry = breakdownData.find(el => el.value === 'tasks');

            if(breakdownByTaskEntry?.selectedData.length) {
                workspaceTasks = breakdownByTaskEntry.selectedData.map(el => {
                    return childsParents[el] ? ({source: el, childs: [el, ...childsParents[el]]}) : ({source: el, childs: [el]});
                });
            } else {
                workspaceTasks = [
                    {
                        source: workspaceSelected, 
                        childs: workspaceSelected === 'root' ?
                            Object.keys(stateTasks) : [..._.get(childsParents, workspaceSelected , []), workspaceSelected]
                    }
                ];
            } 

            workspaceTasks.forEach(taskEntry => {
                taskEntry.childs.forEach(async taskId => {
                    if(!accountsDataTasks[taskId]){
                        return false;
                    }
                    if(!accountsDataTasks[taskId].expenses ) {
                        return false;
                    }
                    const currentTask = stateTasks[taskId];
                    if(!currentTask){
                        return;
                    }
                    const tasksExpenses = accountsDataTasks[taskId].expenses;
                    tasksExpenses.forEach(expense => {
                        if(!expense.types){
                            return;
                        }
                        // if not it means there is data.
                        let key = workspaceSelected;
                        let name = workspaceSelected === 'root' ? '' : stateTasks[workspaceSelected].title;

                        if (breakdownExpenseTypes && breakdownExpenseTypes.lvl === 0 && breakdownExpenseTypes.selectedData.length) {
                            const exists = expense.types?.find(el => breakdownExpenseTypes.selectedData.includes(el));
                            if(!exists){
                                return false;
                            }

                            key = expense.types[0];
                            name  = expensesTypes[expense.types[0]].name || '';
                        
                            // if it is breakdown by task as well
                            if(breakdownByTaskEntry && breakdownByTaskEntry.selectedData.length){
                              
                                name  += ` ${stateTasks[taskEntry.source].title}`;
    
                                key += `|${taskEntry.source}`;
                            }
                        } 
    
                       
                        if (breakdownByTaskEntry && breakdownByTaskEntry.lvl === 0 && breakdownByTaskEntry.selectedData.length) {
                            key = taskEntry.source;
                            name  = stateTasks[taskEntry.source].title;

                            if (breakdownExpenseTypes && breakdownExpenseTypes.selectedData.length) {
                                const exists = expense.types.find(el => breakdownExpenseTypes.selectedData.includes(el));
                                if(!exists){
                                    return false;
                                }
    
                                name  += ` ${expensesTypes[expense.types[0]].name || ''}`;
                                key += `|${expense.types[0]}`;
                            } 
                        }
                        const taskInfo = getInfo(currentTask, taskId, stateTasks, expense);
                        const dataTableEntry = newDataTablesData.find(el => el.id === key);
                        if (dataTableEntry) {
                            dataTableEntry.data.push(taskInfo);
                        } else {
                            newDataTablesData.push({ name, id: key, data: [taskInfo]});
                        }
                    });
                });
            });
            setDataTablesData(newDataTablesData);
        }

    }, [stateTasks,accountsDataTasks, workspaceSelected, breakdownData]);

    if (!hasAutorization) {
        return <Unautorized />;
    }

    const headCells = [
        { id: 'path',  label: 'Path' },
        { id: 'taskName',  label: 'Task Name' },
        { id: 'expenseTitle',  label: 'Expense Description' },
        { id: 'date', type: 'date',  label: 'Date', },
        { id: 'expenseTypes', type: 'array',  label: 'Type' },
        { id: 'expensePricePerUnit', type:'price', label: 'Price Per Unit' },
        { id: 'expenseUnits', label: 'Units' },
        { id: 'expenseTotal', type:'price', label: 'Total' },
        { id: 'vat', type:'price', label: 'Vat' },
        { id: 'expensetotalWithVat', type:'price', label: 'Total with Vat' },
    ];

    const handleExportAsCSV = ({name, data}) => {
        const columns = headCells.map(el =>  el.label).join(';');
        const content = data.map(el => {
            const row = headCells.reduce((acc, { id, type }) => {
                let field = el[id];

                if(type === 'array') {
                    field = field.map(type => expensesTypes[type].name).join(',');
                }

                if(type === 'price') {
                    field =  currencyFormat({ value: field, currency: currency });
                }
            
                if(type === 'date') {
                    field =  el[id] !== 'N/A' ? moment(el[id]).format(dateFormat) : el[id];
                }
                
                return `${acc}${field};`;
            }, '');
            return row;
        });
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${columns}\r\n${content.join('\r\n')}`), `Risk-list${name}`);
    };
    
    const renderRow =  (idx, row) => [
        <TableCell key={`${row.id}-cell-path`} align="left">{row.path}</TableCell>,
        <TableCell key={`${row.id}-cell-name`} align="left"><TaskEventCell name={row.taskName} id={row.id} type='task' /></TableCell>,
        <TableCell align="left">{row.expenseTitle}</TableCell>,
        <TableCell align="left">{moment(row.date).format(userDateFormat)}</TableCell>,
        <TableCell align="left">
            { 
                row.expenseTypes && row.expenseTypes.map(el => 
                    <div className={`type-content color${expensesTypes[el].color}`}>
                        {expensesTypes[el].name} 
                    </div>
                )
            }
        </TableCell>,
        <TableCell align="left">{currencyFormat({ value: row.expensePricePerUnit, currency })}</TableCell>,
        <TableCell align="left">{row.expenseUnits}</TableCell>,
        <TableCell align="left">{currencyFormat({ value: row.expenseTotal, currency })}</TableCell>,
        <TableCell align="left">{currencyFormat({ value: row.vat, currency })}</TableCell>,
        <TableCell align="left">{currencyFormat({ value: row.expensetotalWithVat, currency })}</TableCell>,
    ];
    // get all of the sub childs 
    const subItensTasks = React.useMemo(() => workspaceSelected === 'root' ? 
        Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean) 
        : _.get(stateTasks[workspaceSelected], 'childrens' , []),  [workspaceSelected]);

    const defaultRules = {
        'tasks': ['none', 'expenseTypes'],
        'expenseTypes': ['none', 'tasks'],
        'none': ['none', 'expenseTypes', 'tasks'],
    };

    return <>
        <Paper> 
            <div className="breakdownsContainer">
                <BreakdownController
                    preDefaultBreakdownLvls={defaultBreakdownLvls}
                    rules={defaultRules}
                    breakdowns={breakdownData}
                    updateBreakdowns={updateBreakdowns}
                    enabled={dataTablesData.length}
                    workspaceSelected={workspaceSelected}
                    tasks={subItensTasks}
                />
            </div>
        </Paper>
        <Paper>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                    dataTablesData.length ?
                        dataTablesData.map(el => 
                            <TableContainer 
                                defaultOrder='desc'
                                defaultSort='date'
                                headCells={headCells}
                                renderRow={renderRow}
                                exportAsCSV={handleExportAsCSV}
                                key={el.id} {...el} 
                            />)
                        : <div className={'empty'}>No expense found</div>
                }
            </div>
        </Paper>
    </>;
};

RiskList.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    dateFormat: Proptypes.string.isRequired,
    hasAutorization: Proptypes.bool.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(RiskList);