import { db } from '../../firebaseEvents';
import { getAccountId } from '../../selectors/account';
import { getState, getAppData } from '../../../configureStore';
import { tracking } from '../../utils';

export const updateCostBudgetSettingsExpensesTypes = ({ name, color }) => {
    tracking('Update Cost Budget Settings Expenses Types', {
        accountId: getAppData()?.account?.settings?.id,
        name,
        color,
    });

    return db.push(`/accounts/${getAppData()?.account?.settings?.id}/settings/expensesTypes`, { color, name });
};
export const updateExpensesTypeColor = (expenseId, color) => {
    tracking('Update Expenses Type Color', { accountId: getAppData()?.account?.settings?.id, expenseId, color });

    return db.set(`/accounts/${getAppData()?.account?.settings?.id}/settings/expensesTypes/${expenseId}/color`, color);
};
export const updateExpensesTypeName = (expenseId, name) => {
    tracking('Update Expenses Type Name', { accountId: getAppData()?.account?.settings?.id, expenseId, name });

    return db.set(`/accounts/${getAppData()?.account?.settings?.id}/settings/expensesTypes/${expenseId}/name`, name);
};
export const removeExpenseType = typeId => {
    tracking('Remove Expense Type', { accountId: getAppData()?.account?.settings?.id, typeId });

    // clear all the expenses with that type.
    db.r(`/accountsData/${getAppData()?.account?.settings?.id}/tasks`).on('value', snap => {
        const tasks = snap.val();
        Object.keys(tasks).forEach(taskId => {
            const task = tasks[taskId];
            if (task.expenses && task.expenses.length) {
                const clone = task.expenses.map(expense => ({
                    ...expense,
                    types: expense.types?.filter(m => m !== typeId) || [],
                }));
                updateTaskExpenses(taskId, clone);
            }
        });
    });
    return db.remove(`/accounts/${getAppData()?.account?.settings?.id}/settings/expensesTypes/${typeId}`);
};

const expensesPathBuilder = (accountId, taskId) => `/accountsData/${accountId}/tasks/${taskId}/expenses`;
export const updateTaskExpenses = (taskId, expenses) => {
    tracking('Update Task Expenses');
    db.set(expensesPathBuilder(getAccountId(getState()), taskId), expenses);
};

export const getTaskExpenses = async taskId => {
    const data = await db.r(expensesPathBuilder(getAppData()?.account?.settings?.id, taskId)).once('value');
    
    if(!data.val()) {
        return [];
    }

    return data.val();
};
