import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { browserHistory } from 'react-router';
import { useSelector, shallowEqual } from 'react-redux';
import { getLoadersSettings, getLoadersTasks, getLoadersUsers, getClosedSortables, getTimelineTasksToShow, getPopupGuidesTaskRightClick, getTimelineColumns, getParentChildIds } from '../../utils/selectors/app';
import classnames from 'clsx';
import {
    getAccountCustomFields,
    getAccountId,
    getAccountTaskCanManage,
    getAccountTaskChildrens,
    getAccountTaskDeadline,
    getAccountTaskDelayUntil,
    getAccountTaskHasChildren,
    getAccountTaskIndex,
    getAccountTaskIsRecurring,
    getAccountTaskRisksTaskNoSkill,
    getAccountTasks,
    getAccountTasksCustomFields,
    getAccountTaskStarton,
    getAccountTaskStatus,
    getAccountTaskWorkingTime,
    getAccountUserAvatar,
    getAccountUserDisplayName
} from '../../utils/selectors/account';
import TaskStatusIcon from '../TaskStatusIcon/TaskStatusIcon';
import SortableHandleOpen from '@material-ui/icons/ExpandMore';
import SortableHandleClosed from '@material-ui/icons/ChevronRight';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import UpdateIcon from '@material-ui/icons/Update';
import RemoveIcon from '@material-ui/icons/Cancel';
import NewTaskTitleInput from '../NewTaskTitleInput/NewTaskTitleInput';
import TaskLineUser  from './TaskLineUser';
import TaskLineSkill  from './TaskLineSkill';
import TaskEffortInputs from '../TaskEffortInputs/TaskEffortInputs';
import TaskPrioritySelect from '../TaskPrioritySelect/TaskPrioritySelect';
import TasksRightClickMenu from '../Tasks/TasksRightClickMenu';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { toastr } from 'react-redux-toastr';
import { Avatar, Checkbox, MenuItem, Select } from '@material-ui/core';
import { AccountCircleOutlined, Cancel } from '@material-ui/icons';
import { getUserAvatar, isRegularTimeFormat, tracking } from '../../utils/utils';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import TaskLineWarnings from './TaskLineWarnings';
import { getTimeWorked } from './utils';
import useInternalization from '../../utils/hooks/useInternalization';

const TaskLine = ({ taskId, isToggleClosed, toggleSortable, viewBaseLink, startEndIndexes, sidebarOpenDisabled }) => {
    const { t } = useInternalization();

    const [selectMemberCustomField, setSelectMemberCustomField] = useState(null);
    const [rightClickShow, setRightClickShow] = useState(false);
    const [rightClickMenuPosition, setRightClickMenuPosition] = useState({top:0, left:0});
    const [showDueDatePicker, setShowDueDatePicker] = useState(false);
    const [showStartonPicker, setShowStartonPicker] = useState(false);
    const [showDelayPicker, setShowDelayPicker] = useState(false);

    const tasksToShow = useSelector(getTimelineTasksToShow, shallowEqual);
    const isNotVisible = (startEndIndexes) && (tasksToShow.indexOf(taskId) > startEndIndexes.end+15 || tasksToShow.indexOf(taskId) < startEndIndexes.start-15);

    const accountId = useSelector(getAccountId);
    const loadingTasks = useSelector(getLoadersTasks, shallowEqual);
    const loadingSettings = useSelector(getLoadersSettings, shallowEqual);
    const loadingUsers = useSelector(getLoadersUsers, shallowEqual);
    const taskHasChildrens = useSelector(getAccountTaskHasChildren(taskId), shallowEqual);
    const taskChildrens = useSelector(getAccountTaskChildrens(taskId), shallowEqual);
    const taskIndex = useSelector(getAccountTaskIndex(taskId), shallowEqual);
    const taskStatus = useSelector(getAccountTaskStatus(taskId), shallowEqual);
    const taskCanManage = useSelector(getAccountTaskCanManage(taskId), shallowEqual);
    const taskIsRecurring = useSelector(getAccountTaskIsRecurring(taskId), shallowEqual);
    const taskWorkingTime = useSelector(getAccountTaskWorkingTime(taskId), shallowEqual);

    const taskDueDate = useSelector(getAccountTaskDeadline(taskId));
    const taskDelay = useSelector(getAccountTaskDelayUntil(taskId));
    const taskStarton = useSelector(getAccountTaskStarton(taskId));
    const userDateFormat = useSelector(getUserDateformat);
    const userTimeFormat = useSelector(getUserTimeformat);
    const dateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
    const isRegularTime = isRegularTimeFormat(userTimeFormat);

    const tasks = useSelector(getAccountTasks);
    const parentChildIds = useSelector(getParentChildIds);
    const customFields = useSelector(getAccountCustomFields);
    const taskCustomFields = useSelector(getAccountTasksCustomFields(taskId));
    const taskRiskTaskNoSkill = useSelector(getAccountTaskRisksTaskNoSkill(taskId), shallowEqual);
    const taskRightClick = useSelector(getPopupGuidesTaskRightClick, shallowEqual);
    const timelineColumns = useSelector(getTimelineColumns);
    const closedSortables = useSelector(getClosedSortables, shallowEqual);

    const handleRowClick = useCallback((e) => {
        if(sidebarOpenDisabled) return;

        if(!e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
            browserHistory.push(`/tasks/${taskId}`);
        }
    }, [taskId, sidebarOpenDisabled]);

    const getChilds = useMemo(()=>{
        let childsMap = {};

        if(taskChildrens){

            Object.keys(taskChildrens).forEach(item=>{
                if(tasksToShow && tasksToShow.indexOf(taskChildrens[item].id) !== -1){
                    childsMap[tasksToShow.indexOf(taskChildrens[item].id)] = taskChildrens[item].id;
                }
            });

        }

        let childs = [];
        Object.keys(childsMap).forEach(item=>{
            childs.push(
                <TaskLine
                    key={childsMap[item]}
                    taskId={childsMap[item]}
                    viewBaseLink='/tasks'
                    toggleSortable={toggleSortable}
                    isToggleClosed={closedSortables.indexOf(childsMap[item]) !== -1}
                    startEndIndexes={startEndIndexes}
                    sidebarOpenDisabled={sidebarOpenDisabled}
                />
            );
        });

        if (childs.length === 0 && !taskHasChildrens) {
            childs = <span>&nbsp;</span>;
        }

        return {childs, childsLength: Object.keys(childsMap).length};

    }, [taskHasChildrens, taskChildrens, tasksToShow, toggleSortable, closedSortables, startEndIndexes, sidebarOpenDisabled]);

    const isSortable = useMemo(()=>{
        return taskCanManage && !taskIsRecurring;
    }, [taskCanManage, taskIsRecurring]);

    const getSortableHandle = useCallback(()=>{
        if(taskHasChildrens){
            return (!isToggleClosed) ?
                <SortableHandleOpen className="SortableHandle" onClick={toggleSortable} data-sidebar='preventClose' /> :
                <SortableHandleClosed className="SortableHandle closed" onClick={toggleSortable} data-sidebar='preventClose' />;
        }
        return null;
    }, [taskHasChildrens, isToggleClosed, toggleSortable]);

    const getStatusHandler = useCallback(()=>{
        return (taskHasChildrens) ? <div data-sidebar='preventClose' /> : <TaskStatusIcon taskId={taskId} />;
    }, [taskHasChildrens, taskId]);

    const getTaskRighClickMenu = useCallback(()=>((rightClickShow) ?
        <TasksRightClickMenu taskId={taskId} position={rightClickMenuPosition} />
        : null
    ), [taskId, rightClickMenuPosition, rightClickShow]);

    const hideRightClickMenu = (e) => {

        if(e?.target?.dataset?.taskId === taskId) {
            return;
        }

        setRightClickShow(false);
    };

    useEffect(()=>{
        if(rightClickShow){
            document.addEventListener('click', hideRightClickMenu);
            document.addEventListener('contextmenu', hideRightClickMenu);
        }
        else {
            document.removeEventListener('click', hideRightClickMenu);
            document.removeEventListener('contextmenu', hideRightClickMenu);
        }

        return function cleanup(){
            document.removeEventListener('click', hideRightClickMenu);
            document.removeEventListener('contextmenu', hideRightClickMenu);
        };
    }, [rightClickShow]);

    const taskLineRightClick = useCallback((e) =>{
        e.stopPropagation();

        if(!taskRightClick) {firebaseEvents.setCompletedPopupGuide('taskRightClick');}
        e.preventDefault();

        setRightClickMenuPosition({
            top: (e.clientY - 10 + 320 > window.innerHeight) ? window.innerHeight - 320 : e.clientY - 10,
            left: e.clientX + 5
        });
        setRightClickShow(true);

    }, [taskRightClick]);



    if(
        (startEndIndexes && tasksToShow.indexOf(taskId) > startEndIndexes.end+50) ||
            loadingTasks || loadingUsers || loadingSettings
    ){
        return null;
    }

    const baseLink = viewBaseLink || '/tasks';

    if (isNotVisible) {
        if (baseLink !== '/tasks' && !isToggleClosed) {
            return (
                <div
                    data-sidebar='preventClose'
                    className={classnames('taskline' + taskId, {
                        isNotVisible: isNotVisible,
                        hasChildrens: taskHasChildrens,
                    })}
                >
                    <div
                        data-task-id={taskId}
                        data-task-type="task"
                        data-task-index={taskIndex}
                        className="TaskLine empty"
                    >
                        <div className="loadingTask" />
                    </div>
                </div>
            );
        }

        return (
            <div
                data-sidebar='preventClose'
                className={classnames('taskline' + taskId, {
                    isNotVisible: isNotVisible,
                    hasChildrens: taskHasChildrens,
                })}
            >
                <div
                    data-task-id={taskId}
                    data-task-type="task"
                    data-task-index={taskIndex}
                    className="TaskLine tasklinesortable empty"
                    data-sidebar='preventClose'
                >
                    <div data-sidebar='preventClose'>
                        <div className="loadingTask" />
                    </div>
                </div>
                <div
                    className={classnames('sortable invisible', {
                        isSortable: isSortable,
                        closed: isToggleClosed,
                        empty: getChilds.childsLength === 0,
                    })}
                    data-task-id={taskId}
                >
                    {getChilds.childs}
                </div>
            </div>
        );
    }


    if (baseLink !== '/tasks' && !isToggleClosed) {
        return (
            <div
                data-sidebar='preventClose'
                className={classnames('taskline' + taskId, {
                    hasChildrens: taskHasChildrens,
                })}
            >
                <div
                    data-sidebar='preventClose'
                    data-task-id={taskId}
                    data-task-type="task"
                    data-task-index={taskIndex}
                    className={classnames('TaskLine', taskStatus)}
                >
                    <div className='TaskLine-size' data-sidebar='preventClose'>
                        {taskIsRecurring &&
                                <Tooltip placement="right" overlay={
                                    t(taskHasChildrens ? 'task.warning.recurring-folder' : 'task.warning.recurring-occurrence')
                                }>
                                    <UpdateIcon className="MissingIcon" />
                                </Tooltip>
                        }
                        <TaskLineWarnings taskId={taskId} />
                        {getStatusHandler()}
                        <div
                            className="title-icons-container"
                            id={`title-icons-container-${taskId}`}
                        >
                            <NewTaskTitleInput
                                isRecurringTask={taskIsRecurring}
                                openMoreOptions={taskLineRightClick}
                                taskId={taskId}
                                taskIndex={taskIndex}
                                viewBaseLink={baseLink}
                                titleInputRef={document.getElementById(`title-icons-container-${taskId}`)}
                            />
                        </div>
                        {timelineColumns.includes('assignee') && <TaskLineUser
                            taskId={taskId}
                        />}
                    </div>
                    {timelineColumns.includes('skill') && <TaskLineSkill
                        canManage={taskCanManage}
                        status={taskStatus}
                        id={taskId}
                        taskNoSkill={taskRiskTaskNoSkill}
                    />}
                    {timelineColumns.includes('effort') && <div className='effortCol'>
                        <TaskEffortInputs
                            taskId={taskId}
                            disabled={taskStatus === 'done' || taskHasChildrens}
                            taskLine={true}
                        />
                    </div>}
                    {timelineColumns.includes('priority') && <div className='priorityCol'>
                        <TaskPrioritySelect
                            taskId={taskId}
                            isBlank={taskHasChildrens}
                            canManage={taskCanManage}
                            multiTask={false}
                        />
                    </div>}
                    <div className={classnames('duedateCol', {disabled: !taskCanManage})}>
                        <span onClick={duedateClick}>
                            {taskDueDate && moment(taskDueDate).format(userDateFormat) || ' - '}
                        </span>
                        {taskDueDate && <Cancel onClick={(e)=>{ e.stopPropagation(); firebaseEvents.changeTaskDeadline(accountId, taskId, null); }} />}
                    </div>
                </div>
            </div>
        );
    }

    const duedateClick = (e)=>{
        e.stopPropagation();
        if(taskCanManage){
            setShowDueDatePicker(true);
        }
        else {
            toastr.error(t('You don\'t have the permissions to change this due date'));
        }
    };

    const startonClick = (e)=>{
        e.stopPropagation();
        if(taskCanManage){
            setShowStartonPicker(true);
        }
        else {
            toastr.error(t('You don\'t have the permissions to change this start on date'));
        }
    };

    const startafterClick = (e)=>{
        e.stopPropagation();
        if(taskCanManage && !taskStarton){
            setShowDelayPicker(true);
        }
        else if(taskStarton){
            toastr.error(t('taskDelayBlockedByStartOn.message'));
        }
        else {
            toastr.error(t('You don\'t have the permissions to change this start after date'));
        }
    };

    const changeDueDate = (date) => {
        firebaseEvents.changeTaskDeadline(accountId, taskId, date.toISOString());
        setShowDueDatePicker(false);
    };

    const changeStarton = (date) => {
        firebaseEvents.changeTaskStarton(accountId, taskId, date.toISOString());
        setShowStartonPicker(false);
    };

    const changeDelay = (date)=>{
        firebaseEvents.changeTaskDelay(accountId, taskId, date.toISOString());
        setShowDelayPicker(false);
    };

    const saveCustomField  = (fieldId, val) => {
        firebaseEvents.saveTaskCustomFieldValue({
            accountId, taskId, fieldId, value: val
        });
        tracking('Set custom field value', {accountId, taskId});
    };

    const TeamMemberAvatar = ({ userId }) => {
        const userName = useSelector(getAccountUserDisplayName(userId));
        const userAvatar = useSelector(getAccountUserAvatar(userId));

        return useMemo(()=>(
            <Tooltip
                key={userId}
                placement="left"
                overlay={userName}
            >
                {getUserAvatar(userId, false, userAvatar)}
            </Tooltip>
        ), [userId, userName, userAvatar]);
    };

    const getCustomFields = () => {
        let list = [];

        if(customFields){

            Object.keys(customFields)
                .sort((a,b)=>{ return (customFields[a].name.toUpperCase() < customFields[b].name.toUpperCase()) ? -1 : 1; })
                .forEach(fieldId=>{
                    if(timelineColumns.includes(fieldId)){
                        let value = '-';

                        // text
                        // checkbox
                        // number
                        // people
                        // date
                        // list
                        if(customFields[fieldId].type === 'text' || customFields[fieldId].type === 'number'){
                            value = taskCustomFields[fieldId] || '-';
                        }
                        else if(customFields[fieldId].type === 'checkbox'){
                            value = <Checkbox onClick={e=>e.stopPropagation()} style={{marginLeft:'-5px', padding:'5px'}} size="small" checked={Boolean(taskCustomFields[fieldId])} onChange={e=>{
                                saveCustomField(fieldId, e.target.checked);
                            }} />;
                        }
                        else if(customFields[fieldId].type === 'date'){
                            value = <div className="customDateField-container" onClick={e=>e.stopPropagation()}>
                                <DatePicker
                                    className="customDateField"
                                    value={taskCustomFields[fieldId] ? moment(taskCustomFields[fieldId], 'YYYY-MM-DD'):null}
                                    format={userDateFormat}
                                    emptyLabel={'-'}
                                    autoOk
                                    cancelLabel={null}
                                    okLabel={null}
                                    onChange={date=>{
                                        saveCustomField(fieldId, date ? date.format('YYYY-MM-DD'):null);
                                    }}
                                />
                                {Boolean(taskCustomFields[fieldId]) && <RemoveIcon className="RemoveDate" onClick={()=>{ saveCustomField(fieldId, null); }} />}
                            </div>;
                        }
                        else if(customFields[fieldId].type === 'list'){
                            value = <Select className="customListField" onClick={e=>e.stopPropagation()} fullWidth value={taskCustomFields[fieldId] || '-'} defaultValue="-" onChange={e=>{ saveCustomField(fieldId, e.target.value !== '-' ? e.target.value : null); }}>
                                <MenuItem className='TaskCustomFieldLine-MenuItem' value="-"> - </MenuItem>
                                {customFields[fieldId].choices.map(item=><MenuItem className='TaskCustomFieldLine-MenuItem' value={item}>{item}</MenuItem>)}
                            </Select>;
                        }
                        else if(customFields[fieldId].type === 'people'){
                            value = <AvatarGroup max={3} onClick={e=>{ e.stopPropagation(); setSelectMemberCustomField(fieldId); }}>
                                {(!taskCustomFields[fieldId] || !taskCustomFields[fieldId].length) && <Avatar className="avatar emptyAvatar noAssignee"><AccountCircleOutlined /></Avatar>}
                                {taskCustomFields[fieldId] && taskCustomFields[fieldId].length && taskCustomFields[fieldId].map((userId, index) => {
                                    return <TeamMemberAvatar userId={userId} key={index} />;
                                })}
                            </AvatarGroup>;
                        }

                        list.push(
                            <div key={fieldId} className='customFieldCol'><span>{value}</span></div>
                        );
                    }
                });
        }
        return list;
    };

    const changeCustomFieldMembers = (list) => {
        saveCustomField(selectMemberCustomField, list);
        setSelectMemberCustomField(null);
    };


    return (
        <>
            <div
                data-sidebar='preventClose'
                id={`taskLine-${taskId}`}
                data-task-id={taskId}
                className={classnames('TaskLineContainer taskline' + taskId, {
                    hasChildrens: taskHasChildrens,
                })}
            >
                <div
                    id={`taskLine-sort-${taskId}`}
                    className={classnames('TaskLine', 'tasklinesortable', taskStatus)}
                    data-task-id={taskId}
                    data-task-type="task"
                    data-task-index={taskIndex}
                    onContextMenu={taskLineRightClick}
                    onClick={handleRowClick}
                    data-sidebar='preventClose'
                >
                    <div className='TaskLine-size'>
                        <div
                            className="IconContainer"
                            id={`IconContainer-task-${taskId}`}
                        >
                            <TaskLineWarnings taskId={taskId} />
                            {taskIsRecurring &&
                                <Tooltip placement="right" overlay={
                                    t(taskHasChildrens ? 'task.warning.recurring-folder' : 'task.warning.recurring-occurrence')
                                }>
                                    <UpdateIcon className="UpdateIcon" />
                                </Tooltip>
                            }
                        </div>
                        {getSortableHandle()}
                        {getStatusHandler()}
                        <div className="title-icons-container" id={`title-icons-container-${taskId}`}>
                            <NewTaskTitleInput
                                isRecurringTask={taskIsRecurring}
                                openMoreOptions={taskLineRightClick}
                                taskId={taskId}
                                taskIndex={taskIndex}
                                titleInputRef={document.getElementById(`title-icons-container-${taskId}`)}
                            />
                        </div>
                        {timelineColumns.includes('assignee') && <TaskLineUser
                            taskId={taskId}
                        />}
                    </div>
                    {timelineColumns.includes('skill') && <TaskLineSkill
                        canManage={taskCanManage}
                        status={taskStatus}
                        id={taskId}
                        taskNoSkill={taskRiskTaskNoSkill}
                    />}
                    {timelineColumns.includes('effort') && <div
                        className='effortCol'
                        id={`TaskLine-TaskEffortInputs-${taskId}`}
                    >
                        <TaskEffortInputs
                            taskLine={true}
                            taskId={taskId}
                            disabled={taskStatus === 'done' || taskHasChildrens}
                        />
                    </div>}

                    {timelineColumns.includes('priority') && <div
                        className='TaskPriorityRef priorityCol'
                        id={`TaskLine-taskPriorityRef-${taskId}`}
                    >
                        <TaskPrioritySelect
                            taskId={taskId}
                            isBlank={taskHasChildrens}
                            canManage={taskCanManage}
                            multiTask={false}
                        />
                    </div>}

                    {timelineColumns.includes('duedate') && (
                        <div className={classnames('duedateCol', {disabled: !taskCanManage})}>
                            <span onClick={duedateClick}>
                                {taskDueDate && moment(taskDueDate).format(dateTimeFormat) || ' - '}
                            </span>
                            {taskDueDate && <Cancel onClick={(e)=>{ e.stopPropagation(); firebaseEvents.changeTaskDeadline(accountId, taskId, null); }} />}
                        </div>
                    )}
                    {timelineColumns.includes('starton') && (
                        <div className={classnames('startonCol', {disabled: !taskCanManage})}>
                            {!taskHasChildrens && (
                                <>
                                    <span onClick={startonClick}>
                                        {taskStarton && moment(taskStarton).format(dateTimeFormat) || ' - '}
                                    </span>
                                    {taskStarton && <Cancel onClick={(e)=>{ e.stopPropagation(); firebaseEvents.changeTaskStarton(accountId, taskId, null); }} />}
                                </>
                            )}
                        </div>
                    )}
                    {timelineColumns.includes('startafter') && (
                        <div className={classnames('startafterCol', {disabled: !taskCanManage})}>
                            <span onClick={startafterClick}>
                                {taskDelay && moment(taskDelay).format(dateTimeFormat) || ' - '}
                            </span>
                            {taskDelay && <Cancel onClick={(e)=>{ e.stopPropagation(); firebaseEvents.changeTaskDelay(accountId, taskId, null); }} />}
                        </div>
                    )}
                    {timelineColumns.includes('timeworked') && (
                        <div className={classnames('timeworkedCol')}>
                            <span>
                                {getTimeWorked({ taskId, taskHasChildrens, tasks, parentChildIds, taskWorkingTime })}
                            </span>
                        </div>
                    )}
                    {getCustomFields()}
                </div>
                <div
                    className={classnames('sortable', {
                        isSortable: isSortable,
                        closed: isToggleClosed,
                        empty: !taskHasChildrens,
                    })}
                    data-task-id={taskId}
                >
                    {getChilds.childs}
                </div>
                {getTaskRighClickMenu()}
            </div>

            {/* {!popupGuidesDisabled && (
                <TaskLineTooltips
                    taskId={taskId}
                    isRightClickActive={rightClickShow}
                />
            )} */}

            {showDueDatePicker && (
                <div className="TaskLineDateTimePicker">
                    <DateTimePicker
                        open={showDueDatePicker}
                        variant="dialog"
                        format={dateTimeFormat}
                        margin="normal"
                        placeholder={t('None')}
                        value={taskDueDate ? taskDueDate : new Date(new Date().setHours(0, 0, 0, 0))}
                        onChange={changeDueDate}
                        fullWidth
                        cancelLabel="Cancel"
                        okLabel="Confirm"
                        ampm={isRegularTime}
                        onClose={() => setShowDueDatePicker(false)}
                        showTodayButton
                        todayLabel={t('now')}
                    />
                </div>
            )}
            {showStartonPicker && (
                <div className="TaskLineDateTimePicker">
                    <DateTimePicker
                        open={showStartonPicker}
                        variant="dialog"
                        format={dateTimeFormat}
                        margin="normal"
                        placeholder={t('None')}
                        value={taskStarton ? taskStarton : new Date(new Date().setHours(0, 0, 0, 0))}
                        onChange={changeStarton}
                        fullWidth
                        cancelLabel="Cancel"
                        okLabel="Confirm"
                        ampm={isRegularTime}
                        onClose={() => setShowStartonPicker(false)}
                        showTodayButton
                        todayLabel={t('now')}
                    />
                </div>
            )}
            {showDelayPicker && (
                <div className="TaskLineDateTimePicker">
                    <DateTimePicker
                        open={showDelayPicker}
                        variant="dialog"
                        format={dateTimeFormat}
                        margin="normal"
                        placeholder={t('None')}
                        value={taskDelay ? taskDelay : new Date(new Date().setHours(0, 0, 0, 0))}
                        onChange={changeDelay}
                        fullWidth
                        cancelLabel="Cancel"
                        okLabel="Confirm"
                        ampm={isRegularTime}
                        onClose={() => setShowDelayPicker(false)}
                        showTodayButton
                        todayLabel={t('now')}
                    />
                </div>
            )}
            {selectMemberCustomField && (
                <SelectTeamMember
                    maxSelect={99999}
                    currentUser={taskCustomFields[selectMemberCustomField] || []}
                    onSelect={changeCustomFieldMembers}
                    onClose={()=>setSelectMemberCustomField(null)}
                    open
                />
            )}
        </>
    );

};

export default React.memo(withCustomErrorBoundary(TaskLine));