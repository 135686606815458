import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import moment from 'moment-timezone';
import classnames from 'clsx';
import _ from 'underscore';
import {  browserHistory } from 'react-router';
import Selectors from '../../utils/selectors';
import OpenIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { Button, Tooltip } from '@material-ui/core';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const getEventData =  (startDate, endDate, startHour, endHour, allDay, timeFormat = 'HH:mm',) => {
    let date = '';
    let start = '';

    const allDayFormat = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MMMM Do'
    };

    const hoursFormat = {
        sameDay: '[Today at] ' + timeFormat,
        nextDay: '[Tomorrow at]  ' + timeFormat,
        nextWeek: 'dddd [at] ' + timeFormat,
        lastDay: '[Yesterday at] ' + timeFormat,
        lastWeek: '[Last] dddd [at] ' + timeFormat,
        sameElse: 'MMMM Do [at] ' + timeFormat
    };

    if(startDate === endDate){
        if(allDay){
            start = moment(startDate + ' 00:00').unix();
            date = moment(startDate).calendar(null, allDayFormat);
        }
        else {
            start = moment(startDate + ' ' + startHour).unix();
            date = moment(startDate + ' ' + startHour, 'YYYY-MM-DD HH:mm').calendar(null,hoursFormat);
        }
    }
    else {
        if(allDay) {
            start = moment(startDate + ' 00:00').unix();
            date = moment(startDate).calendar(null, allDayFormat) + ' to ' + moment(endDate).calendar(null, allDayFormat);
        }
        else {
            start = moment(startDate + ' ' + startHour).unix();
            date = moment(startDate + ' ' + startHour).calendar(null,hoursFormat) + ' to ' + moment(endDate + ' ' + endHour).calendar(null,hoursFormat);
        }
    }

    return {start, date};
};


const DayLineEvents = ({ day, monthDay, nextEvents, userTimezone, i }) => {
    const handleClick = (id) => {
        browserHistory.push('/dashboard/userEvents/' + id);
        return;
    };

    if(day && nextEvents) {
        const isToday = moment.utc().tz(userTimezone).format('DD') == day;

        return <Card className="NextEventsCard event" variant="outlined" key={i}>
            <CardContent className='event__content'>

                <div className='event__content__date'>
                    <div>
                        <span className={classnames({isToday: isToday})}>{day}</span>
                        <h4 className={classnames({isToday: isToday})}>{monthDay}</h4>
                    </div>
                </div>

                <div className='event__content__data' >

                    {nextEvents.map((e, idx) => {
                        return <div key={`${e.event.name}-${idx}`}>
                            <h2>{e.hour.time}<small>{e.hour.dayPeriod}</small></h2>
                            <h3 onClick={() => handleClick(e.event.id)}>{e.event.name}</h3>
                            <Tooltip title="See event details">
                                <IconButton className="openBtn" onClick={() => handleClick(e.event.id)}>
                                    <OpenIcon />
                                </IconButton>
                            </Tooltip>
                        </div>;
                    })}
                    
                </div>

            </CardContent>
        </Card>; 
    }
}; 




const NextEvents = React.memo(() => {
    const intl = useIntl();
    const userTimezone = useSelector(Selectors.userSelector.getUserTimezone);
    const userTimeformat = useSelector(Selectors.userSelector.getUserTimeformat);
    const userEvents = useSelector(Selectors.userSelector.getUserEvents);

    const t = (id, values) =>  {
        return intl.formatMessage({id}, values);
    };

    const getNextEvents = () => {
        let events = [];

        if(userEvents && userTimezone) {

            userEvents.forEach(currEnv => {
                const currEnvMoment = moment.utc(currEnv.start, 'X').tz(userTimezone);
                const currEnvDay = currEnvMoment.format('D');
                let startDayFormatted = currEnvMoment.format('YYYY-MM-DD');
                let endDayFormatted = moment.utc(currEnv.end, 'X').tz(userTimezone).format('YYYY-MM-DD');
                let startHourFormatted = currEnvMoment.format('HH:mm');
                let endHourFormatted = moment.utc(currEnv.end, 'X').tz(userTimezone).format('HH:mm');
                const data = getEventData(startDayFormatted, endDayFormatted, startHourFormatted, endHourFormatted, currEnv.allDay, userTimeformat);
                if(currEnvMoment.isAfter(moment())){
                    events.push(
                        {
                            type: 'userEvent', 
                            start: data.start,
                            date: data.date, 
                            event: currEnv,
                            isFreeTime: currEnv.isFreeTime,
                            calendarSummary: currEnv.calendarName || '',
                            scope: currEnv.scope || 'google',
                            hour: { 
                                time: moment(currEnvMoment, ['H:mm']).format('h:mm').replace(':00', ''), 
                                dayPeriod: moment(currEnvMoment, ['H:mm']).format('h:mmA').slice(-2)
                            },
                            day: currEnvDay,
                        });
                }
            });
        }

        if(events.length) {

            events = _.sortBy(events, 'start').slice(0,8);

            let nextDaysWithEvents = _.uniq(events.map((e)=>e.day)).slice(0,8);
            let nextEvents = {};
                
            events.forEach((e) => {
                if(nextDaysWithEvents.includes(e.day)) {
                    nextEvents[e.day] = [...events.filter((evt)=>evt.day===e.day)];
                }
            });
            
            const tempResults = nextDaysWithEvents.map((day, idx) => {
                const monthDay = `${moment.utc(nextEvents[day][0].start, 'X').tz(userTimezone).format('MMMM').slice(0, 3)}, ${moment.utc(nextEvents[day][0].start, 'X').tz(userTimezone).format('dddd').slice(0, 3)}`;

                return <DayLineEvents day={day} monthDay={monthDay} nextEvents={nextEvents[day]} userTimezone={userTimezone} i={`${day}-${idx}`} key={`${day}-${idx}`}/>;
            });

            return (
                <div>
                    {tempResults}
                    {events.length > 8 && <div className="moreThan5">{t('You have more events to come. Showing only the 5 next ones here.')}</div>}
                </div>
            );
        }

        return (
            <div className="allRead">
                <img src="../../../stylesheets/assets/noevent.png?v=2" alt="No event" />
                <span>{t('No events found')}</span>
                <Button onClick={()=>browserHistory.push('/settings/my-account?redirect=true')}>CONNECT A CALENDAR</Button>
            </div>
        );
    };

    return (
        <Card className="nextEvents" square={true}>
            <CardHeader title="Next Events" />
            <CardContent className='nextEvents__content'>
                {getNextEvents()}
            </CardContent>
        </Card>
    );
});

export default withCustomErrorBoundary(NextEvents);

