import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { addCustomDataToWorkspace, defineNewUserPopupGuides } from '../../../js/utils/firebaseEvents';
import { tracking } from '../../../js/utils/utils';
import { getAccountId } from '../../../js/utils/selectors/account';
import { getPopupGuides } from '../../../js/utils/selectors/app';
import { getUserId } from '../../../js/utils/selectors/user';
import { useSelector } from 'react-redux';

const ChoseBeging = () => {
    const intl = useIntl();
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const popupGuides = useSelector(getPopupGuides);

    const handleCustomData = () => {
        addCustomDataToWorkspace();
        tracking('Created workspace with data', {
            accountId: accountId,
        });

        if(userId && !popupGuides) {
            defineNewUserPopupGuides({userId, completed: true});
        }

        browserHistory.push('/tasks?showTour=withData');
    };

    const handleEmptyScratch = () => {
        tracking('Created workspace without data', {
            accountId: accountId,
        });

        if(userId && !popupGuides) {
            defineNewUserPopupGuides({userId, completed: false});
        }
        
        browserHistory.push('/tasks?showTour=withoutData');
    };

    /* 
        // Hide temporarily import data option
    const handleEmptyImport = () => {
        tracking('Created workspace importing data', {
            accountId: accountId,
        });
        browserHistory.push('/settings/import-data');
    }; */

    return <div className='CreateWorkspace'>
        <div className="chose-begining">
            <img
                className="chose-begining-img"
                src="../../../stylesheets/assets/logo.svg?v=2"
                alt="Planless logo"
            />
            <h2 className="chose-begining-title">{intl.formatMessage({ id: 'choose-demo-title' })}</h2>
            <span className="chose-begining-info">{intl.formatMessage({ id: 'choose-demo-info' })}</span>
            <div className="card-container">
                <div onClick={handleCustomData} className="chose-card">
                    <img src="https://planless.sirv.com/App/start-demo-data.png" alt="With demo Data" />
                    <span className="span-with-data">{intl.formatMessage({ id: 'start-with-demo-data' })}</span>
                </div>
                <div onClick={handleEmptyScratch} className="chose-card">
                    <img src="https://planless.sirv.com/App/start-scratch.png" alt="From Scratch" />
                    <span>{intl.formatMessage({ id: 'start-from-scratch' })}</span>
                </div>
                {/*  
                    // Hide temporarily import data option
                    <div onClick={handleEmptyImport} className="chose-card">
                    <img src="https://planless.sirv.com/App/start-import.png" alt="Starting with imports" />
                    <span>{intl.formatMessage({ id: 'import-your-data' })}</span>
                </div> */}
            </div>
        </div>
    </div>;
};

export default memo(ChoseBeging);
