import React, { useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import _  from 'underscore';
import classnames from 'clsx';
import { useIntl } from 'react-intl';
import FollowerLine from './FollowerLine';
import { Button, Menu } from '@material-ui/core';
import FollowIcon from '@material-ui/icons/NotificationsActive';
import { handleStopPropagation } from '../../utils/utils';

const ManageTaskFollowersList = ({ followersList, taskId, isOpen, onClose, handleAddFollowers, isFollowing, handleToggleFollow }) => {
    const intl = useIntl();

    const handleClose = (e) => {
        handleStopPropagation(e);
        onClose && onClose();
    };

    useEffect(() => {
        window.addEventListener('resize', calcPopupLeftPosition);
        return () => {
            window.removeEventListener('resize', calcPopupLeftPosition);
        };
    }, [calcPopupLeftPosition]);

    const calcPopupLeftPosition = useCallback(() => {
        const bellButton = document.querySelector('.ManageTaskFollowers');
        const popup = document.querySelector('.ManageTaskFollowers__Menu > .MuiMenu-paper');
        
        if(bellButton && popup) {
            const position = bellButton.getBoundingClientRect().right - popup.offsetWidth + 57;
            popup.style.left = `${position}px`;
        }
    }, []);

    return <Menu open={!!isOpen} className="ManageTaskFollowers__Menu" anchorEl={isOpen} onClose={handleClose}>

        <div className='Menu__top'>
            <Button 
                startIcon={<FollowIcon/>} 
                variant="contained" 
                className={classnames('Menu__top__followBtn', {notFollowing: !isFollowing})} 
                fullWidth 
                onClick={handleToggleFollow}
            >
                {intl.formatMessage(!isFollowing ? 
                    {id: 'Start Following'} : 
                    {id: 'Stop Following'})} 
                <span className='shortcut__btn'>F</span> 
            </Button>
        </div>

        {_.isEmpty(followersList) && <div className='ManageTaskFollowers__Menu__emptyState'>
            <img src="https://planless.sirv.com/App/nocomments.png" alt="no followers found" />
            <span>{intl.formatMessage({id: 'No followers found'})}</span>
        </div>}

        {followersList.map(({displayName, avatar, userId, color}, index) => <FollowerLine  color={color} displayName={displayName} avatar={avatar} userId={userId} key={index} taskId={taskId}/> )}

        <div className='Menu__bottom'>
            <Button variant="contained" className='Menu__bottom__addBtn' fullWidth onClick={handleAddFollowers}>{intl.formatMessage(({id: 'Manage Followers'}))}</Button> 
        </div>
    </Menu>;
};

ManageTaskFollowersList.propTypes = {
    isOpen: PropTypes.object,
    taskId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    followersList: PropTypes.array.isRequired,
    handleAddFollowers: PropTypes.func.isRequired
};

export default memo(ManageTaskFollowersList);