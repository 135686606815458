import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import { useSelector } from 'react-redux';
import { isAlertValid } from './utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Proptypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { getSymbol  } from '../../../utils/currencyFormat';
import Chip from '@material-ui/core/Chip';
import StateUserAvatar from '../../../components/UserAvatar/StateUserAvatar';

const AlertsDialog = ({ taskBudget, editItem, open, handleClose, handleAdd }) =>  {
    const intl = useIntl();
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const currentUserId = useSelector(state => state?.app?.user?.data.userId);
    const currency = useSelector((state) => state.app?.account?.settings.costBudget.currency || 'USD');
    const currencySymbol = getSymbol(currency);
    const [alert, setAlert] = useState( {
        notificationType: ['email', 'inapp'],
        to: [currentUserId],
        alertType: 'percentage',
        value: 90,
        previsionType: 'now',
        onValue: 'internal',
        checkStatus: '4',
        name: '',
    });


    useEffect(()=> {
        if(editItem){
            setAlert({
                notificationType: editItem.notificationType,
                name: editItem.name,
                to: editItem.to,
                alertType: editItem.alertType,
                value: editItem.value,
                previsionType: editItem.previsionType,
                onValue: editItem.onValue,
                checkStatus: editItem.checkStatus,
                id: editItem.id,
            });
        } else {
            setAlert({
                notificationType: ['email', 'inapp'],
                to: [currentUserId],
                alertType: 'percentage',
                value: 90,
                previsionType: 'now',
                onValue: 'internal',
                checkStatus: '4',
                name: '',
            });
        }
    },[open, editItem]);

    const close = ()=>{
        setAlert({
            notificationType: ['email', 'inapp'],
            to: [currentUserId],
            alertType: 'percentage',
            value: 90,
            previsionType: 'now',
            onValue: 'internal',
            checkStatus: '4',
            name: '',
        });
        handleClose();
    };


    const handleInputs = ({ target: { value, name }}) => {
        setAlert({
            ...alert,
            [name]: value,
        });
    };

    const handleAlertType = ({ target: { value, name }}) => {
        setAlert({
            ...alert,
            [name]: value,
            value: value === 'percentage' ? (alert.value / taskBudget) * 100 : ((alert.value / 100) * taskBudget)
        });
    };

    const handleCheckBox = ({ target: { value }}) => {
        setAlert({
            ...alert,
            notificationType: alert.notificationType.includes(value) ? alert.notificationType.filter(el => el !== value) : [...alert.notificationType,value],
        });
    };
    const handleAutoComplete = (_, values) => {
        setAlert({
            ...alert,
            to: values.map(el => el.userId),
        });
    };

    const onAdd = () => {
        handleAdd(
            alert.id,
            {
                ...alert,
                to: alert.to || [],
            }
        );
    };

    const handleInput  = ({ target: { value }}) => {
        if (!value || isNaN(value)) {
            return;
        }

        setAlert({
            ...alert,
            value: value.includes('.') ? parseFloat(value).toFixed(2) : parseFloat(value),
        });
    };

    const handleEnterKeyAdd = (e) => {
        if((e.key === 'Enter' || e.keyCode === 13) && isAlertValid(alert)) {
            onAdd();
        }
    };

    const users = Object.values(accountTeamMembers);
    const selectedUsers = alert.to?.map(el => accountTeamMembers[el]) || [];
    return <Dialog maxWidth={'md'} className="AlertsDialog" onClose={close} aria-labelledby="customized-dialog-title" open={open} onKeyUp={handleEnterKeyAdd}>
        <MuiDialogTitle className="title" disableTypography >
            <Typography variant="h6">{editItem?intl.formatMessage({id: 'Edit Alert'}):intl.formatMessage({id: 'alert.add-new'})}</Typography>
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers>
            <div className="fields1">
                
                <FormControl className="alert-by-container" component="fieldset">  
                    <TextField 
                        autoFocus
                        value={alert.name} 
                        onChange={handleInputs}
                        id="name"
                        name="name"
                        label={intl.formatMessage({id: 'Alert Name'})}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </FormControl>
                <FormControl className="alert-by-container" component="fieldset">
                    <FormLabel component="legend">{intl.formatMessage({id: 'alert.when'})}</FormLabel>
                    <RadioGroup value={alert.onValue}  onChange={handleInputs} className="buttons-container"  aria-label={intl.formatMessage({id: 'alert.on'})} id="onValue" name="onValue">
                        <FormControlLabel value="internal" control={<Radio size="small" />} label={intl.formatMessage({id: 'internal-cost'})} />
                        <FormControlLabel value="billable" control={<Radio size="small" />} label={intl.formatMessage({id: 'billable-cost'})} />
                    </RadioGroup>
                </FormControl>
                <FormControl className="alert-by-container" component="fieldset">
                    <FormLabel component="legend">{intl.formatMessage({id: 'alert.reaches'})}</FormLabel>
                    <RadioGroup value={alert.alertType} onChange={handleAlertType} className="buttons-container"  aria-label={intl.formatMessage({id: 'alert.alert-by'})} id="alertType" name="alertType">
                        <FormControlLabel value="percentage" control={<Radio size="small" />} label={intl.formatMessage({id: 'alert.percetage'})} />
                        <FormControlLabel value="fixedCost" control={<Radio size="small" />} label={intl.formatMessage({id: 'alert.fixed-threshold'})} />
                    </RadioGroup>
                </FormControl>
                <Input 
                    value={alert.value} 
                    onChange={handleInput}
                    id="value"
                    name="value"
                    label={intl.formatMessage({id: 'alert.value'})}
                    type="text"
                    endAdornment={alert.alertType === 'percentage' ? <InputAdornment position="end">%</InputAdornment> : <InputAdornment position="end">{currencySymbol}</InputAdornment>}
                />
            </div>
            <FormControl className="buttons-container" component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({id: 'alert.trigger-when-value-reaches'})}</FormLabel>
                <RadioGroup value={alert.previsionType} onChange={handleInputs} className="buttons-container"  aria-label={intl.formatMessage({id: 'alert.trigger-alert'})} id="previsionType" name="previsionType">
                    <FormControlLabel value="now" control={<Radio size="small" />} label={intl.formatMessage({id: 'immediately'})} />
                    <FormControlLabel value="future" control={<Radio size="small" />} label={intl.formatMessage({id: 'alert.in-the-future'})} />
                </RadioGroup>
            </FormControl>
            <FormControl  className="data-info-container" component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({id: 'alert.send-notification-by'})} </FormLabel>
                <div>
                    <FormControlLabel checked={alert.notificationType.includes('inapp')} onChange={handleCheckBox} value="inapp" control={<Checkbox size="small"/>} label={intl.formatMessage({id: 'inapp'})} />
                    <FormControlLabel checked={alert.notificationType.includes('email')} onChange={handleCheckBox} value="email" control={<Checkbox size="small"/>} label={intl.formatMessage({id: 'by-email'})} />
                </div>
            </FormControl>
            <FormControl fullWidth className="buttons-container" component="fieldset">
                <Autocomplete
                    value={selectedUsers}
                    onChange={handleAutoComplete}
                    filterSelectedOptions={true}
                    multiple
                    className="autocomplete owners"
                    id="users-To"
                    options={users}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const props = getTagProps({ index });

                            return (
                                <Chip
                                    avatar={
                                        <StateUserAvatar 
                                            className="avatar"
                                            userId={option.userId}
                                        />
                                    }
                                    variant="outlined"
                                    label={option.displayName}
                                    {...props}
                                />
                            );
                        })
                    }
                    filterOptions={(options, {inputValue})=>{
                        return options.filter((o)=> {
                            return !inputValue || accountTeamMembers[o] && accountTeamMembers[o].displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1;
                        });
                    }}
                    getOptionLabel={(option) => <div key={option.displayName} className="optionUser">
                        <StateUserAvatar 
                            className="avatar"
                            userId={option.userId}
                        />
                        {option.displayName}
                    </div>}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={intl.formatMessage({id: 'alert.to'})} variant="outlined" />}
                />
            </FormControl>
            <FormControl fullWidth className="buttons-container" component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({id: 'Check Status'})} </FormLabel>
                <Select
                    name="checkStatus"
                    id="checkStatus"
                    value={alert.checkStatus}
                    onChange={handleInputs}
                >
                    <MenuItem key={'1'} value={'1'}>{intl.formatMessage({id: 'Hour'})}</MenuItem>)
                    <MenuItem key={'4'} value={'4'}>{intl.formatMessage({id: '4 hours'})}</MenuItem>)
                    <MenuItem key={'12'} value={'12'}>{intl.formatMessage({id: '12 hours'})}</MenuItem>)
                    <MenuItem key={'24'} value={'24'}>{intl.formatMessage({id: '24 hours'})}</MenuItem>)
                </Select>
            </FormControl>
        </MuiDialogContent>
        <MuiDialogActions>
            <Button onClick={close} variant="contained">
                {intl.formatMessage({id: 'Cancel'})}
            </Button>
            <Button 
                disabled={!isAlertValid(alert)} 
                variant="contained"
                color="primary"
                onClick={onAdd}
            >
                {editItem && editItem.id ? intl.formatMessage({id: 'Edit Alert'}) : intl.formatMessage({id: 'Add Alert'})}
            </Button>
        </MuiDialogActions>
    </Dialog>;
};


AlertsDialog.propTypes = {
    editItem: Proptypes.object,
    open: Proptypes.bool.isRequired,
    handleClose: Proptypes.func.isRequired,
    handleAdd: Proptypes.func.isRequired
}; 

export default AlertsDialog;