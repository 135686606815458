import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import * as appactions from '../App/AppActions';
import * as firebaseEvents from '../../utils/firebaseEvents';
import _ from 'underscore';
import $ from 'jquery';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {toastr} from 'react-redux-toastr';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { canSee } from '../../utils/userFunctions';

import InputAdornment from '@material-ui/core/InputAdornment';
import {getBaseLocation} from '../../utils/utils';
import { ACADEMY_LINK, CLOUD_FUNCTIONS } from '../../utils/constants';
import { Divider, Typography } from '@material-ui/core';
import Unautorized from '../Unautorized/Unautorized';
import { getOrderedTaskList } from '../../utils/selectors/app';
import { getUserId, getUserPermissions, getUserPermissionsAdmin } from '../../utils/selectors/user';
import { getAccountBasePermissions, getAccountId, getAccountPlan, getAccountSkills, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import languageEncoding from 'detect-file-encoding-and-language';

var OAuth = require('@zalando/oauth2-client-js');

var basecamp = new OAuth.Provider({
    id: 'basecamp',
    authorization_url: 'https://launchpad.37signals.com/authorization/new'
});

var wrike = new OAuth.Provider({
    id: 'wrike',
    authorization_url: 'https://login.wrike.com/oauth2/authorize/v4'
});

const MAX_REQUESTE_ALLOW_PER_PAGE = 5;
const REQUESTE_PER_PAGE = {};
const mondayPaginanted = ({token,mondaySelectedBoard,colList, page, limit}) => new Promise((resolve) => {
    REQUESTE_PER_PAGE[page] = REQUESTE_PER_PAGE[page] ? REQUESTE_PER_PAGE + 1 : 1;

    if(MAX_REQUESTE_ALLOW_PER_PAGE === REQUESTE_PER_PAGE[page]) {
        return resolve([]);
    }

    $.ajax({
        url: 'https://api.monday.com/v2',
        method: 'POST',
        headers: {
            Authorization: token,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify({
            query: `{ boards(ids: ${mondaySelectedBoard}){ groups{id title items(limit: ${limit}, page: ${page}){id name column_values(ids: [${colList.join(',')}]){id text}}} } }`
        }),
        processData: false,
        async: true,
        crossDomain: true
    }).done((res)=>{
        const data = res.data.boards[0].groups;
        const stillHasData = data.find(el => el.items.length);
        if(stillHasData) {
            mondayPaginanted({token,mondaySelectedBoard,colList, page: page + 1, limit}).then(el => resolve([data, el]));
        } else {
            resolve(data);
        }

    }).fail((error)=>{
        if(error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0] && error.responseJSON.errors[0].message){
            toastr.error(error.responseJSON.errors[0].message);
        }
    });
});

var platforms = {
    'csv': {helpName: 'a CSV file', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-a-csv-file`, fileExtension: '.csv', active: true},
    'asana': {helpName: 'Asana', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-asana`, fileExtension: 'token', active: true},
    'basecamp': {helpName: 'Basecamp', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-basecamp`, fileExtension: 'oauth', active: true, client_id: '31be947b9f84d3d6593b1233b57ebf1fe7ee3be6'},
    'jira': {helpName: 'Jira', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-jir`, fileExtension: 'jira', active: true},
    'monday': {helpName: 'Monday.com', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-monday`, fileExtension: 'token', active: true},
    'todoist': {helpName: 'Todoist', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-todoist`, fileExtension: 'token', active: true},
    'trello': {helpName: 'Trello', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-trello`, fileExtension: '.json', active: true},
    'wrike': {helpName: 'Wrike', docUrl: `${ACADEMY_LINK}/import-tasks/import-tasks-from-wrike`, fileExtension: 'oauth', active: true, client_id: 'C7w52Zue'}
};

var tempTeams = [];


const Import = ({children, location})=>{
    const intl = useIntl();

    const orderedTaskList = useSelector(getOrderedTaskList);
    const isAdmin = useSelector(getUserPermissionsAdmin);
    const userId = useSelector(getUserId);
    const usersData = useSelector(getAccountUsers);
    const skillsData = useSelector(getAccountSkills);
    const accountId = useSelector(getAccountId);
    const tasksData = useSelector(getAccountTasks);
    const basePermissions = useSelector(getAccountBasePermissions);
    const plan = useSelector(getAccountPlan);
    const userPermissions = useSelector(getUserPermissions);

    const getPlatform = useCallback(() => {
        if(children){
            var loc = location.pathname.split('/');
            var platform = loc[loc.length-1];
            loc.pop();
            browserHistory.push(document.location.origin + loc.join('/'));
            getOauthToken(platform, location.query.code);
            return {platform: platform};
        }
        return {platform: null};
    }, [children, location, document.location.origin]);

    const baseLocation = useCallback(() => {
        if (location.query.parentId) {
            return { id: location.query.parentId, title: tasksData[location.query.parentId].path.join('/') + '/' + tasksData[location.query.parentId].title };
        }

        return { id: 'base', title: '---Top level of the workspace---' };
    }, [location.query.parentId, tasksData]);

    const [platform, setPlatform] = useState(getPlatform().platform);
    const [data, setData] = useState(null);
    const [validatedTeam, setValidatedTeam] = useState(false);
    const [validatedSkills, setValidatedSkills] = useState(false);
    const [parentId, setParentId] = useState(baseLocation());
    const [token, setToken] = useState('');
    const [jiraSite, setJiraSite] = useState('');
    const [jiraEmail, setJiraEmail] = useState('');
    const [finishImportOpen, setFinishImportOpen] = useState(false);
    const [teamMatchOpen, setTeamMatchOpen] = useState(false);
    const [skillMatchOpen, setSkillMatchOpen] = useState(false);
    const [oauthToken, setOauthToken] = useState(false);
    const [basecampProjects, setBasecampProjects] = useState(false);
    const [wrikeData, setWrikeData] = useState(false);
    const [asanaProjects, setAsanaProjects] = useState(null);
    const [mondayBoards, setMondayBoards] = useState(null);
    const [mondayBoardCols, setMondayBoardCols] = useState(null);
    const [mondaySelectedBoard, setMondaySelectedBoard] = useState(null);
    const [jiraProjects, setJiraProjects] = useState(null);

    const dispatch = useDispatch();


    const t = (id, values) => {
        return intl.formatMessage({id}, values);
    };

    useEffect(()=>{
        setPlatform(getPlatform().platform);
    }, [getPlatform]);

    useEffect(()=>{
        setParentId(baseLocation());
    }, [baseLocation]);

    const getOauthToken = (platform, code) => {

        if(platform === 'basecamp'){

            dispatch(appactions.showLoader());

            $.ajax({
                url: CLOUD_FUNCTIONS.getBasecampToken,
                method: 'POST',
                data: {
                    redirect_uri: document.location.href.split('?')[0] + '/' + platform,
                    code: code
                },
                async: true,
                crossDomain: true
            }).done((res)=>{

                $.ajax({
                    url:  CLOUD_FUNCTIONS.getBasecampAuth,
                    method: 'POST',
                    data: {
                        token: res.access_token
                    },
                    async: true,
                    crossDomain: true
                }).done((res2)=>{
                    res.data = res2;
                    setOauthToken(res);

                    $.ajax({
                        url: CLOUD_FUNCTIONS.getBasecampData,
                        method: 'POST',
                        data: {
                            token: res.access_token,
                            method: 'GET',
                            url: res2.accounts[0].href + '/projects.json'
                        },
                        async: true,
                        crossDomain: true
                    }).done((res3)=>{
                        res3.forEach((r)=>{
                            r.selected = true;
                        });
                        dispatch(appactions.hideLoader());
                        setBasecampProjects(res3);

                    }).fail(()=>{
                        dispatch(appactions.hideLoader());
                        toastr.error('Could not get basecamp projects.');
                    });

                }).fail(()=>{
                    dispatch(appactions.hideLoader());
                    toastr.error('Could not get basecamp data.');
                });



            }).fail(()=>{
                dispatch(appactions.hideLoader());
                toastr.error('Could not get basecamp oauth token.');
            });
        }
        else if(platform === 'wrike'){

            dispatch(appactions.showLoader());

            $.ajax({
                url: CLOUD_FUNCTIONS.getWrikeToken,
                method: 'POST',
                data: {
                    redirect_uri: document.location.href.split('?')[0] + '/' + platform,
                    code: code
                },
                async: true,
                crossDomain: true
            }).done((res)=>{


                $.ajax({
                    url:  CLOUD_FUNCTIONS.getWrikeData,
                    method: 'POST',
                    data: {
                        token: res.access_token,
                        method: 'GET',
                        url: 'https://www.wrike.com/api/v4/folders?fields=["description","space"]',
                        data: {}
                    },
                    async: true,
                    crossDomain: true
                }).done((res2)=>{

                    $.ajax({
                        url:  CLOUD_FUNCTIONS.getWrikeData,
                        method: 'POST',
                        data: {
                            token: res.access_token,
                            method: 'GET',
                            url: 'https://www.wrike.com/api/v4/tasks?fields=["parentIds","superParentIds","responsibleIds","description","superTaskIds"]',
                            data: {}
                        },
                        async: true,
                        crossDomain: true
                    }).done((res3)=>{

                        $.ajax({
                            url:  CLOUD_FUNCTIONS.getWrikeData,
                            method: 'POST',
                            data: {
                                token: res.access_token,
                                method: 'GET',
                                url: 'https://www.wrike.com/api/v4/contacts',
                                data: {}
                            },
                            async: true,
                            crossDomain: true
                        }).done((res4)=>{

                            var spacesIds = [], spaces = [];
                            res2.data.forEach((folder)=>{
                                if(folder.title === 'Root'){
                                    spacesIds = folder.childIds;
                                }
                            });
                            res2.data.forEach((folder)=>{
                                if(spacesIds.indexOf(folder.id) !== -1){
                                    folder.rootSpace = true;
                                    folder.index = spaces.length;
                                    folder.selected = true;
                                    spaces.push(folder);
                                }
                            });

                            var tasks = {};
                            res3.data.forEach((t)=>{
                                tasks[t.id] = t;
                            });

                            var contacts = {};
                            res4.data.forEach((c)=>{
                                contacts[c.id] = c;
                            });

                            setOauthToken(res);
                            setWrikeData({folders: res2.data, spaces:spaces, tasks: tasks, contacts: contacts});
                            dispatch(appactions.hideLoader());

                        }).fail(()=>{
                            dispatch(appactions.hideLoader());
                            toastr.error('Could not get wrike contacts.');
                        });

                    }).fail(()=>{
                        dispatch(appactions.hideLoader());
                        toastr.error('Could not get wrike tasks.');
                    });

                }).fail(()=>{
                    dispatch(appactions.hideLoader());
                    toastr.error('Could not get wrike folders and spaces.');
                });

            }).fail(()=>{
                dispatch(appactions.hideLoader());
                toastr.error('Could not get wrike oauth token.');
            });
        }
    };

    const close = ()=> {
        if(finishImportOpen) {
            setFinishImportOpen(false);
        } else if(teamMatchOpen) {
            setTeamMatchOpen(false);
        } else if(skillMatchOpen) {
            setSkillMatchOpen(false);
        }
    };

    const uploadFile = async (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];
        const fileInfo = await languageEncoding(file);
        const westernEuropeanEncoding = 'ISO-8859-1';
        const fileEncoding = fileInfo.encoding || westernEuropeanEncoding;
        const textDecoder = new TextDecoder(fileEncoding);

        const onLoad = () => {
            const decodedCSV = textDecoder.decode(reader.result);
            getData(decodedCSV);
            reader.removeEventListener('load', onLoad);
        };

        reader.addEventListener('load', onLoad, false);
        reader.readAsArrayBuffer(file);
    };

    const getData = (data)=> {
        var error = false,
            results = [],
            skills = [],
            teams = [];

        if(platform === 'csv'){
            let delimiter = ',';
            var lines = data.split('\n');
            var headers = lines[0].split(',');
            if(headers.length <= 1){
                headers = lines[0].split(';');
                delimiter = ';';
            }
            if(headers.length <= 1){
                error = true;
            }
            lines.shift();

            var levelCols = [],
                skillCol = null,
                minEffortCol = null,
                maxEffortCol = null,
                priorityCol = null,
                statusCol = null,
                assignedCol = null,
                dueDateCol = null,
                delayDateCol = null,
                descCol = null;

            headers.forEach((val, index)=>{
                val = val.replace(/(\r\n|\n|\r)/gm, '');
                if(!skillCol && val === 'Skill'){ skillCol = index; }
                else if(!minEffortCol && val === 'Min Effort'){ minEffortCol = index; }
                else if(!maxEffortCol && val === 'Max Effort'){ maxEffortCol = index; }
                else if(!priorityCol && val === 'Priority'){ priorityCol = index; }
                else if(!statusCol && val === 'Status'){ statusCol = index; }
                else if(!assignedCol && val === 'Assigned To'){ assignedCol = index; }
                else if(!dueDateCol && val === 'Due Date'){ dueDateCol = index; }
                else if(!delayDateCol && val === 'Wait Until'){ delayDateCol = index; }
                else if(!descCol && val === 'Description'){ descCol = index; }
                else {
                    levelCols.push(index);
                }
            });

            var parentIndexes = [];

            lines.forEach((val, lineIndex)=>{
                var result = {};

                var cols = val.split(delimiter);
                cols.forEach((val,index)=>{
                    val = val.replace(/(\r\n|\n|\r)/gm, '');

                    if(index === skillCol && val !== ''){
                        const skillsSplited = val.split('|').map(skillName => skillName.trim());
                        const skillsNotPushedYet = skillsSplited.filter(skillName => !skills.includes(skillName));

                        skills.push(...skillsNotPushedYet);

                        result.skill = skillsSplited.map(skillName => skills.indexOf(skillName));
                    }
                    else if(index === assignedCol && val !== ''){
                        if(teams.indexOf(val) === -1){ teams.push(val); }
                        result.assignedTo = teams.indexOf(val);
                    }
                    else if(index === minEffortCol && val !== ''){
                        if(!isNaN(parseFloat(val))){
                            result.minEffort = parseFloat(val);
                        }
                        else {
                            error = true;
                        }
                    }
                    else if(index === maxEffortCol && val !== ''){
                        if(!isNaN(parseFloat(val))){
                            result.maxEffort = parseFloat(val);
                        }
                        else {
                            error = true;
                        }
                    }
                    else if(index === priorityCol && val !== ''){
                        if(val === 'Urgent'){ val = 3; }
                        else if(val === 'High'){ val = 2; }
                        else if(val === 'Medium'){ val = 1; }
                        else { val = 0; }
                        result.priority = val;
                    }
                    else if(index === statusCol && val !== ''){
                        if(val !== 'done' && val !== 'inprogress'){ val = 'todo'; }
                        result.status = val;
                    }
                    else if(index === dueDateCol && val !== ''){
                        if(moment(val, 'YYYY-MM-DD').isValid()){
                            result.dueDate = moment(val).format('YYYY-MM-DD');
                        }
                        else {
                            error = true;
                        }
                    }
                    else if(index === delayDateCol && val !== ''){
                        if(moment(val, 'YYYY-MM-DD').isValid()){
                            result.delayDate = moment(val).format('YYYY-MM-DD');
                        }
                        else {
                            error = true;
                        }
                    }
                    else if(index === descCol && val !== ''){
                        result.description = val;
                    }
                    else if(levelCols.indexOf(index) !== -1  && val !== ''){
                        var level = levelCols.indexOf(index);
                        if(parentIndexes.length >= level){
                            parentIndexes[level] = lineIndex;

                            if(level > 0){
                                result.parent = parentIndexes[level-1];
                            }

                            result.title = val;

                        }
                        else {
                            error = true;
                        }
                    }

                });

                // if task is in progress it should have assignedTo
                if(result.status && result.status === 'inprogress' && (result.assignedTo === null || result.assignedTo === undefined)){ error = true; }

                if(result.title){
                    results.push(result);
                }

            });
        }
        else if(platform === 'trello'){
            var membersKeymap = {},
                boardsKeymap = {},
                tasksKeymap = {};

            data = JSON.parse(data);

            // create a first task that will be the parent with the name of the board
            results.push({title: data.name, description: data.desc});

            // add members to teams array
            _.each(data.members, (member)=>{
                membersKeymap[member.id] = teams.length;
                teams.push(member.fullName);
            });

            // add lists to results array
            data.lists = _.sortBy(data.lists, 'pos');
            _.each(data.lists, (list)=>{
                boardsKeymap[list.id] = results.length;
                results.push({title:list.name, parent:0});
            });

            // add cards to results array
            data.cards = _.sortBy(data.cards, 'pos');
            _.each(data.cards, (card)=>{
                if(!card.closed){
                    tasksKeymap[card.id] = results.length;

                    var taskData = {title: card.name, description: card.desc, parent: boardsKeymap[card.idList]};

                    if(card.due && moment(card.due).isValid()){
                        taskData.dueDate = moment(card.due).format('YYYY-MM-DD');
                    }
                    if(card.idMembers && card.idMembers.length>0){
                        taskData.assignedTo = membersKeymap[card.idMembers[0]];
                    }

                    results.push(taskData);
                }
            });

            if(data.checklists && data.checklists.length > 0){

                data.checklists = _.sortBy(data.checklists, 'pos');
                _.each(data.checklists, (checklist)=>{
                    if(checklist.checkItems && checklist.checkItems.length > 0){
                        var parent = tasksKeymap[checklist.idCard];

                        checklist.checkItems = _.sortBy(checklist.checkItems, 'pos');
                        _.each(checklist.checkItems, (checkItem)=>{

                            var itemData = {title: checkItem.name, parent: parent};

                            if(checkItem.due && moment(checkItem.due).isValid()){
                                itemData.dueDate = moment(checkItem.due).format('YYYY-MM-DD');
                            }
                            if(checkItem.idMember){
                                itemData.assignedTo = membersKeymap[itemData.idMember];
                            }
                            if(checkItem.state === 'complete'){
                                itemData.status = 'done';
                            }

                            results.push(itemData);

                        });

                    }

                });

            }

        }

        // put team existing values
        teams.forEach((val,index)=>{
            var teamVal = {name: val, value:'new'};
            if(plan === 'free'){
                teamVal = {name: val, value:'no'};
            }

            // find same name in team members
            _.each(usersData, (user, key)=>{
                if(!user.viewer && user.displayName === val){
                    teamVal.value = key;
                }
            });

            teams[index] = teamVal;

        });

        // put skills existing values
        skills = skills.map(skillName => {
            const skillKey = Object.keys(skillsData).find(key => skillsData[key].name === skillName);
            return { name: skillName, value: skillKey ? skillKey : 'new' };
        });

        $('#fileUpload')[0].value = '';

        if(error){
            toastr.error('There is an error in your file. We couldn\'t import it.');
        }
        else {
            setData({results, skills, teams});
            setValidatedTeam(!!(teams.length === 0));
            setValidatedSkills(!!(skills.length === 0));

            if(teams.length > 0) {
                setTeamMatchOpen(true);
            } else if (skills.length > 0) {
                setSkillMatchOpen(true);
            } else {
                setFinishImportOpen(true);
            }
        }
    };

    const getTokenProjects = ()=> {
        if(platform === 'asana' && token !== ''){
            dispatch(appactions.showLoader());
            $.ajax({
                url: 'https://app.asana.com/api/1.0/projects?opt_fields=name,workspace.name',
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token
                },
                async: true,
                crossDomain: true
            }).done((res)=>{
                var data = res.data, workspaces = [], results = {};

                data.forEach((val)=>{
                    if(workspaces.indexOf(val.workspace.gid) === -1){
                        workspaces.push(val.workspace.gid);
                        results[val.workspace.gid] = {name: val.workspace.name, projects: {}};
                    }
                    results[val.workspace.gid].projects[val.gid] = {name: val.name, selected:true};
                });

                dispatch(appactions.hideLoader());
                setAsanaProjects(results);

            }).fail((error)=>{
                dispatch(appactions.hideLoader());
                if(error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0] && error.responseJSON.errors[0].message){
                    toastr.error(error.responseJSON.errors[0].message);
                }
            });
        }
        else if(platform === 'monday' && token !== ''){
            dispatch(appactions.showLoader());

            $.ajax({
                url: 'https://api.monday.com/v2',
                method: 'POST',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({query:'{ boards(limit:500){id name} }'}),
                processData: false,
                async: true,
                crossDomain: true
            }).done((res)=>{

                dispatch(appactions.hideLoader());
                if(res.data && res.data.boards && res.data.boards.length){
                    setMondayBoards(res.data.boards);
                    setMondaySelectedBoard(res.data.boards[0].id);
                }
                else {
                    toastr.error('No boards found');
                }

            }).fail((error)=>{
                dispatch(appactions.hideLoader());
                if(error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0] && error.responseJSON.errors[0].message){
                    toastr.error(error.responseJSON.errors[0].message);
                }
            });
        }
        else if(platform === 'todoist' && token !== ''){
            dispatch(appactions.showLoader());
            $.ajax({
                url: 'https://api.todoist.com/sync/v8/sync?token=' + token + '&sync_token=*&resource_types=[%22projects%22,%22collaborators%22,%22items%22,%22sections%22]',
                method: 'GET',
                async: true,
                crossDomain: true
            }).done((res)=>{

                dispatch(appactions.hideLoader());
                if(res && res.projects){
                    handleTodoistData(res);
                }
                else {
                    toastr.error('No data found');
                }

            }).fail((error)=>{
                dispatch(appactions.hideLoader());
                if(error && error.responseJSON && error.responseJSON.error){
                    toastr.error(error.responseJSON.error);
                }
            });
        }
        else if(platform === 'jira' && token !== '' && jiraSite !== '' && jiraEmail !== ''){

            dispatch(appactions.showLoader());

            $.ajax({
                url: CLOUD_FUNCTIONS.getJiraProjects,
                method: 'POST',
                data: {
                    jiraSite: jiraSite,
                    token: btoa(jiraEmail + ':' + token)
                },
                async: true,
                crossDomain: true
            }).done((res)=>{

                if(res && res.values){

                    res.values.forEach((p)=>{
                        p.selected = true;
                    });
                    setJiraProjects(res.values);

                    dispatch(appactions.hideLoader());
                }
                else {
                    dispatch(appactions.hideLoader());
                    toastr.error('Could not connect to Jira');
                }

            }).fail((error)=>{
                dispatch(appactions.hideLoader());
                if(error && error.responseJSON && error.responseJSON.error){
                    toastr.error(error.responseJSON.error);
                }
                else {
                    toastr.error('Could not connect to Jira');
                }
            });

        }
        else {
            toastr.error('Please enter your token.');
        }
    };

    const handleTodoistData = (data)=> {
        var tasks = [], teams = [], collabIndexMap = {}, projectIndexMap = {}, sectionsIndexMap = {}, itemsIndexMap = {};

        _.each(data.collaborators,(user, userIndex)=>{
            data.collaborators[userIndex].index = teams.length;
            collabIndexMap[user.id] = teams.length;
            teams.push(user.full_name);
        });

        _.each(data.projects, (project, projectIndex)=>{
            data.projects[projectIndex].index = tasks.length;
            projectIndexMap[project.id] = tasks.length;
            tasks.push({title: project.name});
        });

        _.each(data.projects, (project2)=>{
            if(project2.parent_id && projectIndexMap[project2.parent_id]){
                tasks[projectIndexMap[project2.id]].parent = projectIndexMap[project2.parent_id];
            }
        });

        _.each(data.sections, (section, sectionIndex)=>{
            data.sections[sectionIndex].index = tasks.length;
            sectionsIndexMap[section.id] = tasks.length;
            tasks.push({title: section.name, parent: projectIndexMap[section.project_id], priority: 0});
        });

        _.each(data.items, (item, itemIndex)=>{
            data.items[itemIndex].index = tasks.length;
            itemsIndexMap[item.id] = tasks.length;
            var taskData = {title: item.content, status:'todo'};
            if(!item.parent_id && item.section_id){
                taskData.parent = sectionsIndexMap[item.section_id];
            }
            else if(!item.parent_id && item.project_id){
                taskData.parent = projectIndexMap[item.project_id];
            }

            if(item.checked !== 0){ taskData.status = 'done'; }
            if(item.date_completed && moment(item.date_completed).isValid()){
                taskData.doneAt = moment(item.date_completed).format('YYYY-MM-DD');
            }

            if(item.due && item.due.date && moment(item.due.date).isValid()){
                taskData.dueDate = moment(item.due.date).format('YYYY-MM-DD');
            }

            if(item.priority){
                taskData.priority = item.priority-1;
            }

            if(item.responsible_uid){
                taskData.assignedTo = collabIndexMap[item.responsible_uid];
            }

            tasks.push(taskData);
        });

        _.each(data.items, (item2)=>{
            if(item2.parent_id){
                tasks[item2.index].parent = itemsIndexMap[item2.parent_id];
            }
        });

        // put team existing values
        teams.forEach((val,index)=>{
            var teamVal = {name: val, value:'new'};
            if(plan === 'free'){
                teamVal = {name: val, value:'no'};
            }

            // find same name in team members
            _.each(usersData, (user, key)=>{
                if(!user.viewer && user.displayName === val){
                    teamVal.value = key;
                }
            });

            teams[index] = teamVal;

        });

        setData({results: tasks, skills: [], teams: teams});
        setFinishImportOpen(!!(teams.length === 0));
        setValidatedTeam(!!(teams.length === 0));
        setTeamMatchOpen(!!(teams.length !== 0));
        setValidatedSkills(true);
    };

    const getPlatformHelp = ()=> {
        var platformName = platforms[platform].helpName;
        var platformDocUrl = platforms[platform].docUrl;
        var fileExtension = platforms[platform].fileExtension;

        var btn = (
            <Button variant="contained" size="large" color="primary" startIcon={<UploadIcon/>}>
                    Upload your {fileExtension} file
                <input type="file" id="fileUpload" accept={fileExtension} onChange={uploadFile} />
            </Button>
        );

        if(fileExtension === 'token'){
            btn = (
                <div className="tokenInput">
                    <TextField
                        value={token}
                        onChange={(e)=>setToken(e.target.value)}
                        label={t('Your Personal Access Token')}
                        fullWidth={true}
                    />
                    <Button
                        variant="contained" size="large" color="primary" startIcon={<UploadIcon/>}
                        onClick={getTokenProjects}
                    >
                        {t('Connect to Platform', {platform: platforms[platform].helpName})}
                    </Button>
                </div>
            );
        }
        else if(fileExtension === 'oauth'){
            btn = (
                <Button
                    variant="contained" size="large" color="primary" startIcon={<UploadIcon/>}
                    onClick={getOAuthConnect}
                >
                    {t('Connect to Platform', {platform: platforms[platform].helpName})}
                </Button>
            );
        }
        else if(fileExtension === 'jira'){
            btn = (
                <div className="tokenInput jiraInputs">
                    <TextField
                        value={jiraSite}
                        onChange={(e)=>setJiraSite(e.target.value)}
                        label={t('Your Jira URL')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            endAdornment: <InputAdornment position="end">.atlassian.net</InputAdornment>
                        }}
                    />
                    <TextField
                        value={jiraEmail}
                        onChange={(e)=>setJiraEmail(e.target.value)}
                        label={t('Your Jira login email')}
                    />
                    <TextField
                        value={token}
                        onChange={(e)=>setToken(e.target.value)}
                        label={t('Your API Token')}
                    />
                    <Button
                        variant="contained" size="large" color="primary" startIcon={<UploadIcon/>}
                        onClick={getTokenProjects}
                    >
                        {t('Connect to Platform', {platform: platforms[platform].helpName})}
                    </Button>
                </div>
            );
        }

        return (
            <div className="helpSection">
                <p>{t('import.helpText', {platform: platformName, link: <a key={platform + '-docvalue'} href={platformDocUrl} target="_blank">{platformDocUrl}</a>})}</p>

                {btn}
            </div>
        );
    };

    const getOAuthConnect = ()=> {

        var request,
            uri;

        if(platform === 'basecamp'){
            request = new OAuth.Request({
                client_id: platforms[platform].client_id,
                redirect_uri: document.location.href.split('?')[0] + '/' + platform
            });

            uri = basecamp.requestToken(request);
            basecamp.remember(request);

            window.location.href = uri+'&type=web_server';

        }
        else if(platform === 'wrike'){
            request = new OAuth.Request({
                client_id: platforms[platform].client_id,
                redirect_uri: document.location.href.split('?')[0] + '/' + platform,
                scope: 'wsReadOnly'
            });

            uri = wrike.requestToken(request).replace('response_type=token', 'response_type=code');

            wrike.remember(request);

            dispatch(appactions.showLoader());

            window.location.href = uri;

        }
    };

    const getPlatformList = ()=> {
        var platformList = [];

        _.each(platforms, (val, key)=>{
            if(val.active){
                platformList.push(<div key={key} onClick={()=>{
                    if(document.getElementsByClassName('helpSection')[0]){
                        document.getElementsByClassName('helpSection')[0].style.transition = 'opacity 0.1s ease';
                        document.getElementsByClassName('helpSection')[0].style.opacity = 0;
                    }
                    setTimeout(()=>{
                        document.getElementsByClassName('helpSection')[0].style.transition = 'opacity 1s ease';
                        document.getElementsByClassName('helpSection')[0].style.opacity = 1;
                    }, 100);
                    setPlatform(key);

                }} className={(platform===key)?'selected':''}><img src={'../../../stylesheets/assets/import/' + key + '.svg'} alt={key + ' logo'} /></div>);
            }
            else {
                platformList.push(<div key={key} className="inactive"><img src={'../../../stylesheets/assets/import/' + key + '.svg'} alt={key + ' logo'} /></div>);
            }
        });

        return platformList;
    };

    const handleAsanaProjectCheck = (wid,pid)=> {
        var newState = {...asanaProjects};
        newState[wid].projects[pid].selected = !newState[wid].projects[pid].selected;
        setAsanaProjects(newState);
    };

    const asanaSelectAllProjects = ()=> {
        var newState = {...asanaProjects};

        _.each(newState, (w,wid)=>{
            _.each(newState[wid].projects, (p,pid)=>{
                newState[wid].projects[pid].selected = true;
            });
        });
        setAsanaProjects(newState);
    };

    const asanaUnselectAllProjects = ()=> {
        var newState = {...asanaProjects};

        _.each(newState, (w,wid)=>{
            _.each(newState[wid].projects, (p,pid)=>{
                newState[wid].projects[pid].selected = false;
            });
        });

        setAsanaProjects(newState);
    };

    const getAsanaTasks = (task, parentIndex, allTasks)=> {
        var tasks = [];
        var currentIndex = allTasks.length;

        var taskDetails = {title: task.name, parent: parentIndex, status: 'todo'};

        if(task.notes && task.notes !== ''){ taskDetails.description = task.notes; }
        if(task.due_on){ taskDetails.dueDate = moment(task.due_on).format('YYYY-MM-DD'); }
        if(task.completed){ taskDetails.status = 'done'; taskDetails.doneAt = moment(task.completed_at).format('YYYY-MM-DD'); }
        if(task.assignee){
            if(tempTeams.indexOf(task.assignee.name) === -1){
                tempTeams.push(task.assignee.name);
            }
            taskDetails.assignedTo = tempTeams.indexOf(task.assignee.name);
        }

        tasks.push(taskDetails);

        if(task.subtasks && task.subtasks.length > 0){
            _.each(task.subtasks, (t)=>{
                tasks = tasks.concat(getAsanaTasks(t, currentIndex, allTasks.concat(tasks)));
            });
        }

        return tasks;
    };

    const treatAsanaResults = (results)=> {

        var tasks = [], sections = {};

        tempTeams = [];

        _.each(results, (workspace)=>{
            var Windex = tasks.length;
            tasks.push({title: workspace.name});

            _.each(workspace.projects, (project)=>{
                var Pindex = tasks.length;
                tasks.push({title: project.name, parent: Windex});

                _.each(project.data, (task)=>{
                    if(task.memberships && task.memberships[0] && task.memberships[0].section){
                        if(!sections[task.memberships[0].section.gid]){
                            sections[task.memberships[0].section.gid] = {name: task.memberships[0].section.name, index: tasks.length};
                            tasks.push({title: sections[task.memberships[0].section.gid].name, parent: Pindex});

                        }
                        tasks = tasks.concat(getAsanaTasks(task, sections[task.memberships[0].section.gid].index, tasks));
                    }
                    else {
                        tasks = tasks.concat(getAsanaTasks(task, Pindex, tasks));
                    }
                });
            });

        });


        // put team existing values
        tempTeams.forEach((val,index)=>{
            var teamVal = {name: val, value:'new'};
            if(plan === 'free'){
                teamVal = {name: val, value:'no'};
            }

            // find same name in team members
            _.each(usersData, (user, key)=>{
                if(!user.viewer && user.displayName === val){
                    teamVal.value = key;
                }
            });

            tempTeams[index] = teamVal;

        });

        setData({results: tasks, skills: [], teams: tempTeams});
        setFinishImportOpen(!!(tempTeams.length === 0));
        setValidatedTeam(!!(tempTeams.length === 0));
        setTeamMatchOpen(!!(tempTeams.length !== 0));
        setValidatedSkills(true);
    };

    const asanaStartImport = ()=> {
        var counterSelected = 0;

        _.each(asanaProjects, (w,wid)=>{
            _.each(asanaProjects[wid].projects, (p,pid)=>{
                if(asanaProjects[wid].projects[pid].selected){ counterSelected++; }
            });
        });

        if(counterSelected > 100){
            toastr.error('You can import maximum 100 projects at once');
        }
        else if(counterSelected > 0){
            var ajaxCount = 0, newState = {...asanaProjects};
            dispatch(appactions.showLoader());

            _.each(asanaProjects, (w,wid)=>{
                var countProjectsInWorkspace = 0;

                _.each(asanaProjects[wid].projects, (p,pid)=>{

                    if(asanaProjects[wid].projects[pid].selected){
                        countProjectsInWorkspace++;
                        $.ajax({
                            url: 'https://app.asana.com/api/1.0/projects/' + pid + '/tasks?opt_pretty&opt_expand=(this%7Csubtasks%2B)',
                            method: 'GET',
                            headers: {
                                Authorization: 'Bearer ' + token
                            },
                            async: true,
                            crossDomain: true
                        }).done((res)=>{
                            var data = res.data;
                            ajaxCount++;

                            newState[wid].projects[pid].data = data;

                            if(ajaxCount === counterSelected){
                                dispatch(appactions.hideLoader());
                                treatAsanaResults(newState);
                            }

                        }).fail((error)=>{
                            dispatch(appactions.hideLoader());
                            if(error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0] && error.responseJSON.errors[0].message){
                                toastr.error(error.responseJSON.errors[0].message);
                            }
                        });

                    }
                    else {
                        delete newState[wid].projects[pid];
                    }
                });

                if(countProjectsInWorkspace === 0){
                    delete newState[wid];
                }
            });


        }
        else {
            toastr.error('You have to select at least 1 project.');
        }
    };

    const basecampStartImport = ()=> {
        dispatch(appactions.showLoader());

        // make a functions call to import data and sends back tasks,teams,skills
        var list = [];
        basecampProjects.forEach((p)=>{
            if(p.selected){
                var docks = [];
                p.dock.forEach((d)=>{
                    if(d.name ==='todoset'){
                        docks.push({name: d.name, url: d.url}); }
                });

                list.push({selected:true, id:p.id, name: p.name, description:p.description, dock:docks});
            }
        });
        $.ajax({
            url: CLOUD_FUNCTIONS.getBasecampDataForImport,
            method: 'POST',
            data: {
                token: oauthToken.access_token,
                projects: JSON.stringify(list)
            },
            async: true,
            crossDomain: true
        }).done((res)=>{


            // put team existing values
            res.teams.forEach((val,index)=>{
                var teamVal = {name: val, value:'new'};
                if(plan === 'free'){
                    teamVal = {name: val, value:'no'};
                }

                // find same name in team members
                _.each(usersData, (user, key)=>{
                    if(!user.viewer && user.displayName === val){
                        teamVal.value = key;
                    }
                });

                res.teams[index] = teamVal;

            });

            setData({results: res.tasks, skills:[], teams: res.teams});
            setFinishImportOpen(!!(res.teams.length === 0));
            setValidatedTeam(!!(res.teams.length === 0));
            setValidatedSkills(true);
            setTeamMatchOpen(!!(res.teams.length !== 0));

            dispatch(appactions.hideLoader());

        }).fail(()=>{
            dispatch(appactions.hideLoader());
            toastr.error('Could not get basecamp tasks.');
        });
    };

    const basecampSelectAllProjects = ()=> {
        var projects = [...basecampProjects];
        projects.forEach((p)=>{
            p.selected = true;
        });
        setBasecampProjects(projects);
    };

    const basecampUnselectAllProjects = ()=> {
        var projects = [...basecampProjects];
        projects.forEach((p)=>{
            p.selected = false;
        });
        setBasecampProjects(projects);
    };

    const handleBasecampProjectCheck = (index)=> {
        var projects = [...basecampProjects];
        projects[index].selected = !basecampProjects[index].selected;
        setBasecampProjects(projects);
    };

    const getBasecampProjectsChoice = () => {
        if(!data && basecampProjects){

            var result = [];

            _.each(basecampProjects, (project, pid)=>{
                result.push(
                    <FormControlLabel
                        key={pid} className="projectSelector"
                        control={
                            <Checkbox
                                checked={basecampProjects[pid].selected}
                                onChange={()=>handleBasecampProjectCheck(pid)}
                                color="primary"
                            />
                        }
                        label={project.name}
                    />
                );

            });


            return (
                <div className="basecampProjectsChoice">

                    <a onClick={basecampSelectAllProjects}>Select All</a>
                    <a onClick={basecampUnselectAllProjects}>Unselect All</a>

                    <div className="listProjects">
                        {result}
                    </div>
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={basecampStartImport}
                    >
                        {t('Start importing tasks')}
                    </Button>
                </div>
            );

        }

        return null;
    };

    const wrikeStartImport = () => {
        var tasks = [], teams = [], tempTaskKeymap = {}, tempTeamKeymap = {}, foldersToAdd = [], foldersToAddParents = {};

        var selectedSpaces = [];
        wrikeData.spaces.forEach((s)=>{
            if(s.selected){
                selectedSpaces.push(s.id);
                if(s.childIds && s.childIds.length > 0){
                    s.childIds.forEach((c)=>{
                        if(foldersToAdd.indexOf(c) === -1){
                            foldersToAddParents[c] = s.id;
                            foldersToAdd.push(c);
                        }
                    });
                }
                tempTaskKeymap[s.id] = tasks.length;
                tasks.push({title: s.title, description: s.description || null});
            }
        });

        if(selectedSpaces.length > 0){

            wrikeData.folders.forEach((folder)=>{
                if(foldersToAdd.indexOf(folder.id) !== -1){
                    if(folder.childIds && folder.childIds.length > 0){
                        folder.childIds.forEach((c)=>{
                            if(foldersToAdd.indexOf(c) === -1){
                                foldersToAddParents[c] = folder.id;
                                foldersToAdd.push(c);
                            }
                        });
                    }
                    tempTaskKeymap[folder.id] = tasks.length;
                    tasks.push({title: folder.title, description: folder.description || null, parent: tempTaskKeymap[foldersToAddParents[folder.id]]});
                }
            });

            for(var taskId in wrikeData.tasks){
                var task = wrikeData.tasks[taskId];

                var taskData = {
                    title: task.title,
                    description: task.description || null,
                    priority: 0,
                    status: 'todo'
                };

                var parent, shouldImport = false;
                if(task.superTaskIds && task.superTaskIds.length > 0){
                    parent = task.superTaskIds[0];
                    var currentTaskTest = parent;
                    while(
                        wrikeData.tasks[currentTaskTest].superTaskIds &&
                        wrikeData.tasks[currentTaskTest].superTaskIds.length > 0
                    ){
                        currentTaskTest = wrikeData.tasks[currentTaskTest].superTaskIds[0];
                    }
                    var folderId = wrikeData.tasks[currentTaskTest].parentIds[0];
                    if(tempTaskKeymap[folderId]){
                        shouldImport = true;
                    }
                }
                else {
                    parent = task.parentIds[0];
                    shouldImport = true;
                }

                if(shouldImport){

                    taskData.parent = parent;

                    if(task.importance && task.importance === 'Normal'){
                        taskData.priority = 1;
                    }
                    else if(task.importance && task.importance === 'High'){
                        taskData.priority = 2;
                    }

                    if(task.status && task.status === 'Completed'){
                        taskData.status = 'done';
                    }

                    if(task.dates && task.dates.due){
                        taskData.dueDate = moment(task.dates.due).format('YYYY-MM-DD');
                    }

                    if(task.dates && task.dates.duration){
                        taskData.minEffort = parseInt(task.dates.duration)/60;
                        taskData.maxEffort = parseInt(task.dates.duration)/60;
                    }

                    if(task.dates && task.dates.start && moment(task.dates.start).isAfter(moment())){
                        taskData.delayDate = moment(task.dates.start).format('YYYY-MM-DD');
                    }
                    if(task.responsibleIds && task.responsibleIds.length){
                        if(tempTeamKeymap[task.responsibleIds[0]] === undefined){
                            tempTeamKeymap[task.responsibleIds[0]] = teams.length;
                            const userFirstName = wrikeData.contacts[task.responsibleIds[0]]?.firstName || 'Unkown User';
                            const userLastName = wrikeData.contacts[task.responsibleIds[0]]?.lastName || '';
                            teams.push(userFirstName + ' ' + userLastName);
                        }
                        taskData.assignedTo = tempTeamKeymap[task.responsibleIds[0]];
                    }

                    tempTaskKeymap[taskId] = tasks.length;
                    tasks.push(taskData);
                }

            }

            tasks.forEach((t)=>{
                if(t.parent && typeof t.parent === 'string'){
                    t.parent = tempTaskKeymap[t.parent];
                }
            });

            // put team existing values
            teams.forEach((val,index)=>{
                var teamVal = {name: val, value:'new'};
                if(plan === 'free'){
                    teamVal = {name: val, value:'no'};
                }

                // find same name in team members
                _.each(usersData, (user, key)=>{
                    if(!user.viewer && user.displayName === val){
                        teamVal.value = key;
                    }
                });

                teams[index] = teamVal;

            });


            setData({results: tasks, skills:[], teams: teams});
            setFinishImportOpen(!!(teams.length === 0));
            setValidatedTeam(!!(teams.length === 0));
            setTeamMatchOpen(!!(teams.length !== 0));
            setValidatedSkills(true);
        }
        else {
            toastr.error('Please select at least 1 space');
        }
    };

    const wrikeSelectAllProjects = () => {
        var wrikeDataNew = {...wrikeData};
        wrikeDataNew.spaces.forEach((p)=>{
            if(p.rootSpace){
                p.selected = true;
            }
        });
        setWrikeData(wrikeDataNew);
    };

    const wrikeUnselectAllProjects = () => {
        var wrikeDataNew = {...wrikeData};
        wrikeDataNew.spaces.forEach((p)=>{
            if(p.rootSpace){
                p.selected = false;
            }
        });
        setWrikeData(wrikeDataNew);
    };

    const handleWrikeProjectCheck = (index) => {
        var wrikeDataNew = {...wrikeData};
        wrikeDataNew.spaces[index].selected = !wrikeDataNew.spaces[index].selected;

        setWrikeData(wrikeDataNew);
    };

    const getWrikeProjectsChoice = () => {
        if(!data && wrikeData && wrikeData.spaces){

            var result = [];

            _.each(wrikeData.spaces, (project, pIndex)=>{
                result.push(
                    <FormControlLabel
                        key={project.id} className="projectSelector"
                        control={
                            <Checkbox
                                checked={wrikeData.spaces[pIndex].selected}
                                onChange={()=>handleWrikeProjectCheck(pIndex)}
                                color="primary"
                            />
                        }
                        label={project.title}
                    />
                );

            });


            return (
                <div className="wrikeProjectsChoice">

                    <a onClick={wrikeSelectAllProjects}>Select All</a>
                    <a onClick={wrikeUnselectAllProjects}>Unselect All</a>

                    <div className="listProjects">
                        {result}
                    </div>
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={wrikeStartImport}
                    >
                        {t('Start importing tasks')}
                    </Button>
                </div>
            );

        }

        return null;
    };

    const getTokenProjectsChoice = () => {
        if(!data && asanaProjects){
            var result = [];

            _.each(asanaProjects, (workspace, wid)=>{
                var projectList = [];

                _.each(workspace.projects, (project, pid)=>{
                    projectList.push(
                        <FormControlLabel
                            key={pid} className="projectSelector"
                            control={
                                <Checkbox
                                    checked={asanaProjects[wid].projects[pid].selected}
                                    onChange={()=>handleAsanaProjectCheck(wid,pid)}
                                    color="primary"
                                />
                            }
                            label={project.name}
                        />
                    );
                });

                result.push(
                    <div key={wid} className="workspaceSelector">
                        {workspace.name}
                        <div className="listProjects">
                            {projectList}
                        </div>
                    </div>
                );
            });

            return (
                <div className="asanaProjectsChoice">

                    <a onClick={asanaSelectAllProjects}>Select All</a>
                    <a onClick={asanaUnselectAllProjects}>Unselect All</a>

                    {result}
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={asanaStartImport}
                    >
                        {t('Start importing tasks')}
                    </Button>
                </div>
            );
        }
        else if(!data && mondayBoards && !mondayBoardCols){
            var list = [];

            _.each(mondayBoards, (board)=>{
                list.push(<MenuItem value={board.id} key={board.id}>{board.name}</MenuItem>);
            });

            return (
                <div className="mondayBoardsChoice">
                    <TextField
                        select
                        value={mondaySelectedBoard}
                        fullWidth={true}
                        onChange={(e)=>setMondaySelectedBoard(e.target.value)}
                    >
                        {list}
                    </TextField>
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={mondayGetCols}
                    >
                        {t('Start importing')}
                    </Button>
                </div>
            );
        }
        else if(!data && mondayBoards && mondayBoardCols){

            var colList = [];

            _.each(mondayBoardCols, (col, index)=>{
                var options = {
                    'no':{name:'Do not import'},
                    'title':{name:'Task title'},
                    'description':{name: 'Description'},
                    'skill':{name: 'Skill'},
                    'minEffort':{name: 'Min. Effort'},
                    'maxEffort':{name: 'Max. Effort'},
                    'priority':{name: 'Priority'},
                    'status':{name: 'Status'},
                    'dueDate':{name: 'Due Date'},
                    'delayDate':{name: 'Wait Until'},
                    'assignedTo':{name: 'Assigned To'}
                };

                _.each(mondayBoardCols, (col2, index2)=>{
                    if(index2 !== index && col2.value !== 'no'){
                        delete options[col2.value];
                    }
                });

                var selectItems = [];
                _.each(options, (o,i)=>{
                    selectItems.push(<MenuItem value={i} key={i}>{o.name}</MenuItem>);
                });

                colList.push(
                    <div key={col.id}>
                        <span>{col.title}</span>
                        <span>→</span>
                        <TextField
                            select
                            disabled={(mondayBoardCols[index].value === 'title')}
                            value={mondayBoardCols[index].value}
                            onChange={(e)=>changeMondayColVal(index, e.target.value)}
                        >
                            {selectItems}
                        </TextField>
                    </div>
                );
            });

            return (
                <div className="mondayColumnsMatch">
                    <div className="mondayColumnsMatchList">
                        {colList}
                    </div>
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={validateMondayCols}
                    >
                        {t('Continue')}
                    </Button>
                </div>
            );
        }
        else if(!data && jiraProjects){
            var projectList = [];

            _.each(jiraProjects, (project, pid)=>{

                projectList.push(
                    <FormControlLabel
                        key={project.id} className="projectSelector"
                        control={
                            <Checkbox
                                checked={jiraProjects[pid].selected}
                                onChange={()=>handleJiraProjectCheck(pid)}
                                color="primary"
                            />
                        }
                        label={project.name}
                    />
                );
            });

            return (
                <div className="jiraProjectsChoice">

                    <a onClick={jiraSelectAllProjects}>Select All</a>
                    <a onClick={jiraUnselectAllProjects}>Unselect All</a>

                    <div className="listProjects">{projectList}</div>
                    <Button
                        variant="contained" size="large" color="primary"
                        onClick={jiraStartImport}
                    >
                        {t('Start importing tasks')}
                    </Button>
                </div>
            );
        }

        return null;
    };

    const handleJiraProjectCheck = (pid) => {
        var newProjects = [...jiraProjects];
        newProjects[pid].selected = !jiraProjects[pid].selected;
        setJiraProjects(newProjects);

    };

    const jiraSelectAllProjects = () => {
        var all = [...jiraProjects];
        all.forEach((p)=>{
            p.selected = true;
        });
        setJiraProjects(all);
    };

    const jiraUnselectAllProjects = () => {
        var all = [...jiraProjects];
        all.forEach((p)=>{
            p.selected = false;
        });
        setJiraProjects(all);
    };

    const jiraStartImport = () => {

        var selectedProjects = [], tasks = [], teams = [];

        var tasksKeyMap = {}, teamsKeyMap = {};

        jiraProjects.forEach((p)=>{
            if(p.selected){
                selectedProjects.push(p.id);
                tasksKeyMap['p'+p.id] = tasks.length;
                tasks.push({title: p.name, description: p.description});
            }
        });

        if(selectedProjects.length > 0){

            dispatch(appactions.showLoader());
            let projectQuery = [];
            selectedProjects.forEach((p)=>{
                projectQuery.push('project=' + p);
            });

            getJiraIssuesRequest(
                jiraSite,
                btoa(jiraEmail + ':' + token),
                projectQuery,
                0,
                100
            ).then((issues)=>{
                issues.forEach((issue)=>{
                    var taskData = {title: issue.fields.summary, description: (issue.fields.renderedFields && issue.fields.renderedFields.description)?issue.fields.renderedFields.description:null, priority: 0, status:'todo'};
                    if(!issue.fields.parent){
                        taskData.parent = 'p'+issue.fields.project.id;
                    }
                    else {
                        taskData.parent = issue.fields.parent.id;
                    }

                    if(issue.fields.priority){
                        if(issue.fields.priority.name.toLowerCase() === 'highest'){
                            taskData.priority = 3;
                        }
                        else if(issue.fields.priority.name.toLowerCase() === 'high'){
                            taskData.priority = 2;
                        }
                        else if(issue.fields.priority.name.toLowerCase() === 'medium'){
                            taskData.priority = 1;
                        }
                    }

                    if(issue.fields.status){
                        if(issue.fields.status.name.toLowerCase() === 'in progress' && issue.fields.assignee && issue.fields.assignee.displayName){
                            taskData.status = 'inprogress';
                        }
                        else if(issue.fields.status.name.toLowerCase() === 'done'){
                            taskData.status = 'done';
                        }
                    }

                    if(issue.fields.duedate && moment(issue.fields.duedate).isValid()){
                        taskData.dueDate = moment(issue.fields.duedate).format('YYYY-MM-DD');
                    }

                    if(issue.fields.aggregateprogress && issue.fields.aggregateprogress.total && issue.fields.aggregateprogress.total !== 0){
                        var nbHours = parseInt(issue.fields.aggregateprogress.total)/3600;
                        taskData.minEffort = nbHours;
                        taskData.maxEffort = nbHours;
                    }

                    if(issue.fields.assignee && issue.fields.assignee.displayName){
                        var cleanedName = encodeURI(issue.fields.assignee.displayName.toLowerCase().replace(' ',''));
                        if(!teamsKeyMap[cleanedName]){
                            teamsKeyMap[cleanedName] = {name: issue.fields.assignee.displayName , index: teams.length};
                            teams.push(issue.fields.assignee.displayName);
                        }
                        taskData.assignedTo = teamsKeyMap[cleanedName].index;
                    }

                    tasksKeyMap[issue.id] = tasks.length;
                    tasks.push(taskData);

                });

                tasks.forEach((t, index)=>{
                    if(t.parent && (tasksKeyMap[t.parent] || tasksKeyMap[t.parent] === 0)){
                        tasks[index].parent = tasksKeyMap[t.parent];
                    }
                });


                // put team existing values
                teams.forEach((val,index)=>{
                    var teamVal = {name: val, value:'new'};
                    if(plan === 'free'){
                        teamVal = {name: val, value:'no'};
                    }

                    // find same name in team members
                    _.each(usersData, (user, key)=>{
                        if(!user.viewer && user.displayName === val){
                            teamVal.value = key;
                        }
                    });

                    teams[index] = teamVal;

                });

                dispatch(appactions.hideLoader());


                setData({results: tasks, teams, skills:[]});
                setFinishImportOpen(!!(teams.length === 0));
                setValidatedTeam(!!(teams.length === 0));
                setTeamMatchOpen(!!(teams.length !== 0));
                setValidatedSkills(true);

            });
        }
        else {
            toastr.error('Please select at least 1 project.');
        }
    };

    const getJiraIssuesRequest = (jiraSite, token, projectQuery, startAt, maxResults, results) => {
        const selectedProjects = [];
        jiraProjects.forEach((p)=>{
            if(p.selected){
                selectedProjects.push(p.id);
            }
        });
        if(!results){
            results = [];
        }

        return new Promise ((resolve) => {
            $.ajax({
                url: CLOUD_FUNCTIONS.getJiraIssues,
                method: 'POST',
                data: {
                    jiraSite: jiraSite,
                    token: token,
                    projects: selectedProjects,
                },
                async: true,
                crossDomain: true
            }).done((data)=>{
                resolve(data);
            }).fail(()=>{
                toastr.error('Something went wrong refresh');
            });
        });

    };

    const changeMondayColVal = (index, value) => {
        var cols = [...mondayBoardCols];
        cols[index].value = value;
        setMondayBoardCols(cols);
    };

    const validateMondayCols = async () => {
        var colList = [];
        _.each(mondayBoardCols, (cols)=>{
            if(cols.value !== 'no'){
                colList.push(cols.id);
            }
        });

        dispatch(appactions.showLoader());
        try{
            const data = await mondayPaginanted({
                colList: colList,
                limit: 10,
                page: 1,
                token: token,
                mondaySelectedBoard: mondaySelectedBoard
            });

            if(data) {
                const aggregated = _.flatten(data).reduce((acc, item) => {
                    if(acc[item.id] && item.items) {
                        return {
                            ...acc,
                            [item.id]: {
                                ...acc[item.id],
                                items: [...acc[item.id].items, ...item.items]
                            }
                        };
                    }

                    return {
                        ...acc,
                        [item.id]: item,
                    };
                }, {});

                handleMondayData(aggregated);
            }
        }catch(err){
            toastr.error(err);
        }finally{
            dispatch(appactions.hideLoader());
        }

    };

    const handleMondayData = (data) => {
        var tasks = [],
            teams = [],
            skills = [];

        // define first task as the selected board name
        _.each(mondayBoards, (board)=>{
            if(board.id === mondaySelectedBoard){
                tasks.push({title: board.name});
            }
        });

        _.each(data, (group)=>{
            var groupIndex = tasks.length;
            tasks.push({parent: 0, title: group.title});

            _.each(group.items, (task)=>{
                var taskData = {parent: groupIndex, title: task.name, status: 'todo'};

                _.each(task.column_values, (col)=>{

                    _.each(mondayBoardCols, (boardCol)=>{
                        if(boardCol.id === col.id && boardCol.value !== 'no'){
                            if(boardCol.value === 'description'){
                                taskData.description = col.text;
                            }
                            if(boardCol.value === 'skill'){
                                // TODO: import multi skills seperated by |
                                if(col.text !== ''){
                                    if(skills.indexOf(col.text) === -1){
                                        taskData.skill = skills.length;
                                        skills.push(col.text);
                                    }
                                    else {
                                        taskData.skill = skills.indexOf(col.text);
                                    }
                                }
                            }
                            if(boardCol.value === 'minEffort'){
                                if(!isNaN(parseFloat(col.text))){
                                    taskData.minEffort = parseFloat(col.text);
                                }
                            }
                            if(boardCol.value === 'maxEffort'){
                                if(!isNaN(parseFloat(col.text))){
                                    taskData.maxEffort = parseFloat(col.text);
                                }
                            }
                            if(boardCol.value === 'priority'){
                                var val = 0;
                                if(col.text === 'Urgent'){ val = 3; }
                                else if(col.text === 'High'){ val = 2; }
                                else if(col.text === 'Medium'){ val = 1; }

                                taskData.priority = val;
                            }
                            if(boardCol.value === 'status'){
                                if(col.text === 'inprogress' || col.text === 'done'){
                                    taskData.status = col.text;
                                }
                                else {
                                    taskData.status = 'todo';
                                }
                            }
                            if(boardCol.value === 'dueDate'){
                                if(moment(col.text).isValid()){
                                    taskData.dueDate = moment(col.text).format('YYYY-MM-DD');
                                }

                            }
                            if(boardCol.value === 'delayDate'){
                                if(moment(col.text).isValid()){
                                    taskData.delayDate = moment(col.text).format('YYYY-MM-DD');
                                }
                            }
                            if(boardCol.value === 'assignedTo'){
                                if(col.text !== ''){
                                    if(teams.indexOf(col.text) === -1){
                                        taskData.assignedTo = teams.length;
                                        teams.push(col.text);
                                    }
                                    else {
                                        taskData.assignedTo = teams.indexOf(col.text);
                                    }
                                }
                            }
                        }
                    });

                });

                if(task.minEffort && task.maxEffort && task.minEffort > task.maxEffort){
                    task.maxEffort = task.minEffort;
                }

                tasks.push(taskData);
            });
        });

        // put team existing values
        teams.forEach((val,index)=>{
            var teamVal = {name: val, value:'new'};
            if(plan === 'free'){
                teamVal = {name: val, value:'no'};
            }

            // find same name in team members
            _.each(usersData, (user, key)=>{
                if(!user.viewer && user.displayName === val){
                    teamVal.value = key;
                }
            });

            teams[index] = teamVal;

        });

        // put skills existing values
        skills.forEach((val,index)=>{
            var skillVal = {name: val, value:'new'};

            // find same name in skills
            _.each(skillsData, (skill, key)=>{
                if(skill.name === val){
                    skillVal.value = key;
                }
            });

            skills[index] = skillVal;

        });

        setData({results: tasks, skills, teams});
        setFinishImportOpen(!!(tempTeams.length === 0 && skills.length === 0));
        setTeamMatchOpen(!!(teams.length !== 0));
        setValidatedTeam(!!(teams.length === 0));
        setValidatedSkills(!!(skills.length === 0));

    };

    const mondayGetCols = () => {

        dispatch(appactions.showLoader());

        $.ajax({
            url: 'https://api.monday.com/v2',
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({query:'{ boards(ids: ' + mondaySelectedBoard + '){ columns{id title type} } }'}),
            processData: false,
            async: true,
            crossDomain: true
        }).done((res)=>{
            var cols = res.data.boards[0].columns;
            cols.forEach((col,i)=>{
                if(col.id === 'name'){
                    cols[i].value = 'title';
                }
                else {
                    cols[i].value = 'no';
                }
            });
            setMondayBoardCols(cols);

            dispatch(appactions.hideLoader());

        }).fail((error)=>{
            dispatch(appactions.hideLoader());
            if(error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0] && error.responseJSON.errors[0].message){
                toastr.error(error.responseJSON.errors[0].message);
            }
        });

    };

    const getContentTitle = () => {
        if(!data && !asanaProjects && !mondayBoards && !basecampProjects && !jiraProjects && !wrikeData){
            return (
                <p>Select the platform you want to import tasks from:</p>
            );
        }
        else if(!data && asanaProjects){
            return (
                <p>Select the projects you want to import tasks from:</p>
            );
        }
        else if(!data && mondayBoards && !mondayBoardCols){
            return (
                <p>Select the board you want to import:</p>
            );
        }
        else if(!data && mondayBoards && mondayBoardCols){
            return (
                <p>Match your board columns for import:</p>
            );
        }
        else if(!data && basecampProjects){
            return (
                <p>Select the projects you want to import:</p>
            );
        }
        else if(!data && jiraProjects){
            return (
                <p>Select the projects you want to import tasks from:</p>
            );
        }
        else if(!data && wrikeData){
            return (
                <p>Select the spaces you want to import tasks from:</p>
            );
        }
        else if(!validatedTeam && data.teams && data.teams.length > 0){
            return (
                <p>Match your team to your existing team members or create new ones:</p>
            );
        }
        else if(validatedTeam && !validatedSkills && data.skills && data.skills.length > 0){
            return (
                <p>Match your skills to your existing skills or create new ones:</p>
            );
        }

        else if(validatedTeam && validatedSkills){
            return (
                <p>Well done!</p>
            );
        }
    };

    const changeTeamvalue = (index, val) => {
        var newData = {...data};
        newData.teams[index].value = val;
        setData(newData);
    };

    const getTeamSelect = (index) => {
        var list = [
            <MenuItem value="no" key="no">Do not import</MenuItem>,
            <MenuItem value="new" key="new">Create new team member</MenuItem>
        ];

        _.each(usersData, (user, key)=>{
            if(!user.viewer){
                list.push(<MenuItem value={key} key={key}>{user.displayName}</MenuItem>);
            }
        });

        return (
            <div className="teamLine" key={'teammember' + index}>
                <span>{data.teams[index].name}</span>
                <TextField
                    select
                    value={data.teams[index].value}
                    onChange={(e)=>changeTeamvalue(index, e.target.value)}
                >
                    {list}
                </TextField>
            </div>
        );


    };

    const changeSkillvalue = (index, val) => {
        var newData = {...data};
        newData.skills[index].value = val;
        setData(newData);
    };

    const getSkillsSelect = (index) => {
        var list = [
            <MenuItem value="no" key="no">Do not import</MenuItem>,
            <MenuItem value="new" key="new">Create new skill</MenuItem>
        ];

        _.each(skillsData, (skill, key)=>{
            list.push(<MenuItem value={key} key={key}>{skill.name}</MenuItem>);
        });

        return (
            <div className="skillLine" key={'skill' + index}>
                <span>{data.skills[index].name}</span>
                <span>→</span>
                <TextField
                    select
                    value={data.skills[index].value}
                    onChange={(e)=>changeSkillvalue(index, e.target.value)}
                >
                    {list}
                </TextField>
            </div>
        );


    };

    const getTeamMatch = () => {

        if(!validatedTeam && data && data.teams && data.teams.length > 0){

            var list = [];

            data.teams.forEach((member, index)=>{
                list.push(getTeamSelect(index));
            });

            return (
                <Dialog
                    className="teamList"
                    open={teamMatchOpen}
                    onClose={close}
                    maxWidth={false}
                >
                    <DialogTitle>{t('Import Team Members')}</DialogTitle>
                    <Divider />
                    <DialogContent className='content teamSelectContent'>
                        {getContentTitle()}

                        {list}
                    </DialogContent>

                    <DialogActions className="actions">
                        <Button variant="contained" color="primary" onClick={close}>{t('Cancel')}</Button>
                        <Button variant="contained" color="primary" onClick={()=> {
                            if(
                                data.teams.filter(el => el.value === 'new').length &&
                                data.teams.filter(el => el.value === 'new').length +
                                Object.keys(usersData).length  > 4 &&
                                plan === 'free') {
                                toastr.error('Your free account is limited to 4 users. Please upgrade your plan to add users from your import.');
                                return;
                            }

                            setFinishImportOpen(validatedSkills);
                            setValidatedTeam(true);
                            setTeamMatchOpen(false);
                            setSkillMatchOpen(true);

                        }}>{t('Continue')}</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const getSkillsMatch = () => {
        if(validatedTeam && !validatedSkills && data && data.skills && data.skills.length > 0){
            var list = [];

            data.skills.forEach((skill, index)=>{
                list.push(getSkillsSelect(index));
            });

            return (
                <Dialog
                    className="skillsList"
                    open={skillMatchOpen}
                    onClose={close}
                    maxWidth={false}
                >
                    <DialogTitle>{t('Import Skills')}</DialogTitle>
                    <Divider />
                    <DialogContent className='content teamSelectContent'>
                        {getContentTitle()}

                        {list}
                    </DialogContent>

                    <DialogActions className="actions">
                        <Button variant="contained" color="primary" onClick={close}>{t('Cancel')}</Button>
                        <Button variant="contained" color="primary" onClick={()=>{
                            setValidatedSkills(true);
                            setSkillMatchOpen(false);
                            setFinishImportOpen(true);
                        }}>{t('Continue')}</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    const getTaskPath = (taskId) => {
        if(tasksData[taskId]){
            var parentsPath = [tasksData[taskId].title],
                currentTask = taskId;

            while(tasksData[currentTask] && tasksData[currentTask].parent){
                parentsPath.push(tasksData[tasksData[currentTask].parent].title);
                currentTask = tasksData[currentTask].parent;
            }
            parentsPath = parentsPath.reverse().join('/');

            return parentsPath;
        }
        return null;
    };

    const getFinishImport = () => {
        if(validatedTeam && validatedSkills && basePermissions){

            var options = [];
            if(
                (
                    (isAdmin) ||
                    (basePermissions.owners && basePermissions.owners.indexOf(userId) !== -1) ||
                    (basePermissions.managers && basePermissions.managers.indexOf(userId) !== -1)
                )
            ){
                options = [{id: 'base', title: '---' + t('Top level of the workspace') + '---'}];
            }

            var tasks = orderedTaskList;
            if(tasks){
                tasks.forEach((t)=>{
                    if(t.canManage){
                        options.push({id: t.id, title: getTaskPath(t.id)});
                    }
                });
            }

            return (
                <Dialog
                    className="finishImport"
                    open={finishImportOpen}
                    onClose={close}
                    maxWidth={false}
                >
                    <DialogTitle>{t('Import tasks')}</DialogTitle>
                    <Divider />
                    <DialogContent className='content'>
                        {getContentTitle()}
                        <Typography variant='subtitle2'>You are ready to import your tasks. Select where you want to import them:</Typography>

                        <Autocomplete
                            size='small'
                            className="destAutocomplete"
                            options={options}
                            value={parentId}
                            getOptionLabel={option => option.title || ''}
                            disableClearable
                            renderInput={params => (
                                <TextField {...params} label={t('Location')} fullWidth />
                            )}
                            renderOption={(option) => (
                                <Typography variant='subtitle2'>{option.title || ''}</Typography>
                            )}
                            onChange={(e,val)=>{ setParentId(val); }}
                        />
                    </DialogContent>

                    <DialogActions className="actions">
                        <Button variant="contained" color="primary" onClick={close}>{t('Cancel')}</Button>
                        <Button variant="contained" color="primary" onClick={importData}>{t('Import tasks')}</Button>
                    </DialogActions>
                </Dialog>
            );

        }
        return null;
    };

    const importData = () => {
        firebaseEvents.importTasks(accountId, userId, data, parentId.id).then(()=>{
            toastr.success('Your tasks have been successfuly imported');
            browserHistory.push('/tasks');
        });

        setPlatform(getPlatform().platform);
        setData(null);
        setAsanaProjects(null);
        setWrikeData(null);
        setValidatedTeam(false);
        setValidatedSkills(false);
        setParentId(getBaseLocation());
        setToken('');
        setJiraSite('');
        setJiraEmail('');
        setFinishImportOpen(false);
        setTeamMatchOpen(false);
        setSkillMatchOpen(false);
        setJiraProjects(null);
        setMondayBoards(null);
        setMondayBoardCols(null);
        setMondaySelectedBoard(null);
    };

    if (!canSee(['admin'], {data: { permissions: userPermissions}})) return <Unautorized />;

    return (
        <div className='Import'>
            <h1>{t('Import data')}</h1>
            <div>
                <p>Select the platform you want to import tasks from:</p>
                <div className="content">

                    {getPlatformList()}
                    {getTeamMatch()}
                    {getSkillsMatch()}
                    {getFinishImport()}

                    {getTokenProjectsChoice()}
                    {getBasecampProjectsChoice()}
                    {getWrikeProjectsChoice()}

                </div>

                {platform && getPlatformHelp()}
            </div>
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(Import));
