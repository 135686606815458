import React from 'react';
// import Paper from '@material-ui/core/Paper';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const StructOff = ({children}) => {
    return (
        <div className='StructOff'>
            {children}
        </div>
    );
};

export default withCustomErrorBoundary(StructOff);
