import React from 'react';
import _ from 'underscore';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import LineItem from './LineItem';

const ProjectsTree = ({ toolName, dataTree = [], parents = [], syncAppId }) => {
    return <>
        {dataTree.map(treeLine => {
            const { name, id, childs, importSubtasks, selectedItem } = treeLine;

            return <div className='ProjectsTreeView' key={id}>
                {
                    <LineItem
                        name={name}
                        selectedItem={selectedItem}
                        parents={!_.isEmpty(parents) ? parents : ['root']}
                        childs={childs}
                        importSubtasks={importSubtasks || false}
                        syncAppId={syncAppId}
                        toolName={toolName}
                    />
                }
                {
                    !_.isEmpty(childs) && (
                        <ProjectsTree
                            dataTree={childs}
                            parents={!_.isEmpty(parents) ? [...parents, id] : ['root', id]}
                            syncAppId={syncAppId}
                            toolName={toolName}
                        />
                    )
                }
            </div>;
        })}
    </>;
};

export default withCustomErrorBoundary(ProjectsTree);