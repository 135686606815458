import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountChargebeeCustomerId, getAccountId, getAccountPlanCurrency, getAccountPlanNbusers, getAccountPlanPeriodicity } from '../../utils/selectors/account';
import {
    Button,
    Dialog, DialogContent, IconButton, MenuItem, Select, Typography,
} from '@material-ui/core';
import { getUpgradeSubscriptionModalState } from '../../utils/selectors/modals';
import { Recurrence } from '../../shared/enums';
import useInternalization from '../../utils/hooks/useInternalization';
import { marks } from '../../shared/constants';
import { toggleUpgradeSubscriptionModalShow } from '../../utils/actions/upgradeSubscriptionModal';
import { getPlanPriceByResource, getPlanTotalPrice } from '../../utils/pricing';
import Close from '@material-ui/icons/Close';
import { chargebeeUpgradePlan } from '../../utils/chargeBee';
import { toastr } from 'react-redux-toastr';
import { hideLoader, showLoader } from '../App/AppActions';
import { recordEvent } from '../../utils/firebaseEvents';
import { getUserId } from '../../utils/selectors/user';

const UpgradeSubscriptionModal = () => {

    const dispatch = useDispatch();
    const { t } = useInternalization();
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [resources, setResources] = React.useState('');

    const { show } = useSelector(getUpgradeSubscriptionModalState);
    const currentUserId = useSelector(getUserId);
    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const accountId = useSelector(getAccountId);
    const accountPlanCurrency = useSelector(getAccountPlanCurrency);
    const accountPlanPeriodicity = useSelector(getAccountPlanPeriodicity);

    if(!show) return null;

    React.useEffect(() => {
        if (planNbusers) setResources(resourcesValues[0]);
    }, [planNbusers]);

    const resourcesValues = marks
        .filter(mark => mark.label === 'MORE' || Number(mark.label) > Number(planNbusers))
        .map(mark => mark.label);


    const isResourceEqualMore = resources === 'MORE';

    const planPriceByResources = (resourceValue) => {
        if (!accountPlanCurrency || !resourceValue) return '';

        return getPlanPriceByResource(accountPlanPeriodicity, accountPlanCurrency, resourceValue);
    };

    const planTotalPrice = (resourceValue) => {
        if (!accountPlanCurrency || !resourceValue) return '';

        const price = getPlanTotalPrice(accountPlanPeriodicity, accountPlanCurrency, resourceValue);
        const per = accountPlanPeriodicity === Recurrence.MONTHLY
            ? t('upgradeSubscriptionModal.price.perMonth')
            : t('upgradeSubscriptionModal.price.perYear');

        return `${price} ${per}`;
    };

    const handleClose = () => {
        dispatch(toggleUpgradeSubscriptionModalShow({
            show: false,
        }));
    };

    const handleCloseSelect = () => setIsSelectOpen(false);
    const handleOpenSelect = () => setIsSelectOpen(true);
    const handleChangeSelect = (event) => {
        setResources(event.target.value);
    };

    const handleUpgradePlan = ()=>{
        dispatch(showLoader());
        chargebeeUpgradePlan({
            chargebeeCustomerId,
            nbResources: resources,
            currency: accountPlanCurrency,
            periodicity: accountPlanPeriodicity
        }).then(()=>{
            toastr.success(t('upgradeSubscriptionModal.success.title'),t('upgradeSubscriptionModal.success.message'));
            recordEvent('SubscribedPaidPlan', accountId, currentUserId);
            handleClose();
            dispatch(hideLoader());
        });
    };

    return (

        <Dialog fullWidth maxWidth="md" open={show} onClose={handleClose} className="upgrade-subscription">
            <div className="title-container">
                <Typography className="title1" variant="body1" align="center">
                    {t('upgradeSubscriptionModal.title1')}
                </Typography>
                <Typography className="title2" variant="h6" align="center">
                    {t('upgradeSubscriptionModal.title2')}
                </Typography>

                <IconButton className='title-close-modal' aria-label="close" onClickCapture={handleClose}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <div className="subscription-content">
                    <div className="subscription-detail divider">
                        <Typography className="detail-title" variant="subtitle1" align="center">
                            {`${t('upgradeSubscriptionModal.your')} ${t('upgradeSubscriptionModal.currentPlan')}`}
                        </Typography>
                        <Typography className="detail-upto" variant="body1" align="center">
                            {t('upgradeSubscriptionModal.upto', { resources: planNbusers })} Resources
                        </Typography>
                        <div className="price">
                            <span className="price-value">{planPriceByResources(planNbusers)}</span>
                            <span className="price-recurrence">{t('upgradeSubscriptionModal.price.perResourceMonth')}</span>
                            <span className="price-resume">{planTotalPrice(planNbusers)}</span>
                            <span><strong>{ t('upgradeSubscriptionModal.price.everything').toUpperCase() }</strong></span>
                        </div>
                    </div>
                    <div className="subscription-detail">
                        <Typography className="detail-title new-plan" variant="subtitle1" align="center">
                            {`${t('upgradeSubscriptionModal.your')} `}
                            <span>{t('upgradeSubscriptionModal.newPlan')}</span>
                        </Typography>
                        <Typography className="detail-resource" variant="body2" align="center">
                            {t('upgradeSubscriptionModal.resources')}
                        </Typography>
                        <Select
                            className="select-resources"
                            open={isSelectOpen}
                            onClose={handleCloseSelect}
                            onOpen={handleOpenSelect}
                            value={resources}
                            onChange={handleChangeSelect}
                        >
                            {resourcesValues.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value === 'MORE'
                                        ? t('upgradeSubscriptionModal.moreThan250')
                                        : t('upgradeSubscriptionModal.upto', { resources: value })
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                        <div className="price">
                            {!isResourceEqualMore ? (
                                <>
                                    <span className="price-value">{planPriceByResources(resources)}</span>
                                    <span className="price-recurrence">{t('upgradeSubscriptionModal.price.perResourceMonth')}</span>
                                    <span className="price-resume">{planTotalPrice(resources)}</span>
                                    <span className="price-everything">
                                        <strong>{ t('upgradeSubscriptionModal.price.everything').toUpperCase() }</strong>
                                    </span>
                                </>
                            ) : (
                                <Button
                                    className="btn-primary-action"
                                    variant="contained"
                                    color="secondary"
                                    disabled={!isResourceEqualMore}
                                    onClick={() => { document.location.href = 'mailto:hello@planless.io'; }}
                                >
                                    {t('upgradeSubscriptionModal.buttons.contactUs')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="subscription-actions">
                    <Button
                        className="btn-primary-action"
                        variant="contained"
                        color="secondary"
                        disabled={isResourceEqualMore}
                        onClick={handleUpgradePlan}
                    >
                        {t('upgradeSubscriptionModal.buttons.upgrade')}
                    </Button>
                    <a
                        target='_blank'
                        className="btn-help"
                        href={'mailto:planless.io@gmail.com'}
                    >
                        {t('upgradeSubscriptionModal.buttons.help')}
                    </a>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UpgradeSubscriptionModal;
