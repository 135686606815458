import React from 'react';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { SIRV_INTEGRATIONS_LOGOS_URL, SYNC_APP_DESCRIPTION, SYNC_APP_NAMES } from '../../utils';
import StatusActionButton from '../StatusActionButton';

const IntegrationItem = ({ toolName, status, hasError }) => {
    return <>
        <div className='IntegrationItem' id={`IntegrationItem-${toolName}`}>
            <img src={`${SIRV_INTEGRATIONS_LOGOS_URL}/${toolName}.png`} alt={`${toolName}.png`} />

            <div className='IntegrationItem__description'>
                <h4>
                    {SYNC_APP_NAMES[toolName]}
                </h4>
                <p>{SYNC_APP_DESCRIPTION[toolName]}</p>
            </div>

            <StatusActionButton hasError={hasError} status={status} toolName={toolName}/>
        </div>
    </>;
};

export default withCustomErrorBoundary(IntegrationItem);