import React from 'react';
import Tooltip from 'rc-tooltip';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Refresh from '@material-ui/icons/Refresh';
import {  useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId } from '../../utils/selectors/account';
import { setTimelineRange } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TimeLineHelpers = () => {
    const intl = useIntl();
    const accountId = useSelector(getAccountId);
    const dispatch = useDispatch();

    const resetTimelineRange = () => {
        firebaseEvents.addChallenge('initTimeline');

        var timelineRange = localStorage.getItem('timelineRange-' + accountId);
        timelineRange = JSON.parse(timelineRange);

        if (
            !timelineRange ||
            !moment(timelineRange.start).isSame(moment().subtract(1, 'days'), 'day') ||
            !moment(timelineRange.end).isSame(moment().add(30, 'days'), 'day')
        ) {
            const val = { start: moment().subtract(1, 'days'), end: moment().add(30, 'days') };
            localStorage.setItem(
                'timelineRange-' + accountId,
                JSON.stringify(val),
            );
            dispatch(setTimelineRange(val));
        }
    };

    const zoomInTimelineRange = () => {
        var timelineRange = localStorage.getItem('timelineRange-' + accountId);
        timelineRange = JSON.parse(timelineRange);

        var diff = moment(timelineRange.end).diff(moment(timelineRange.start), 'days');

        diff = Math.floor(diff / 2);

        if (diff < 7) {
            diff = 7;
        }

        firebaseEvents.addChallenge('zoomTimeline');

        const val = { start: moment(timelineRange.start), end: moment(timelineRange.start).add(diff, 'days') };
        localStorage.setItem(
            'timelineRange-' + accountId,
            JSON.stringify(val),
        );
        dispatch(setTimelineRange(val));
    };

    const zoomOutTimelineRange = () => {
        var timelineRange = localStorage.getItem('timelineRange-' + accountId);
        timelineRange = JSON.parse(timelineRange);

        var diff = moment(timelineRange.end).diff(moment(timelineRange.start), 'days');

        diff = Math.floor(diff * 2);

        if (diff > 365 * 5) {
            diff = 365 * 5;
        }
        firebaseEvents.addChallenge('zoomTimeline');

        const val = { start: moment(timelineRange.start), end: moment(timelineRange.start).add(diff, 'days') };

        localStorage.setItem(
            'timelineRange-' + accountId,
            JSON.stringify(val),
        );
        dispatch(setTimelineRange(val));
    };
    
    return <div id="timelineHelper">
        <Tooltip placement="left" overlay={intl.formatMessage({id: 'Refresh'})} >
            <div onClick={resetTimelineRange}><Refresh /></div>
        </Tooltip>
        <Tooltip placement="left" overlay={intl.formatMessage({id: 'Zoom In'})} >
            <div onClick={zoomInTimelineRange}><ZoomIn /></div>
        </Tooltip>
        <Tooltip placement="left" overlay={intl.formatMessage({id: 'Zoom Out'})} >
            <div onClick={zoomOutTimelineRange}><ZoomOut /></div>
        </Tooltip>
    </div>;
};

export default React.memo(withCustomErrorBoundary(TimeLineHelpers));  