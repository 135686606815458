import React from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { canSee } from '../../utils/userFunctions';
import classnames from 'clsx';
import { withRouter, browserHistory } from 'react-router';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import { useSelector } from 'react-redux';
import { getAccountChargebeeCustomerId, getAccountId, getAccountIsTrial, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { getUserId, getUserPermissions } from '../../utils/selectors/user';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MEMBER_TYPES_LABELS } from '../../components/MemberTypeDropdown/constants';
import { removeUserToSubscription } from '../../utils/chargeBee';
import _ from 'underscore';
import { Edit } from '@material-ui/icons';
import { handleStopPropagation } from '../../utils/utils';

const UserLine = ({ memberId, router }) => {
    const intl = useIntl();
    const tasks = useSelector(getAccountTasks);
    const accountId = useSelector(getAccountId);
    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const userPermissions = useSelector(getUserPermissions);
    const currentUserId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const isAccountInTrial = useSelector(getAccountIsTrial);
    const memberResources = getOnlyResourceUsers(accountUsers);
    

    const memberData = accountUsers[memberId];

    if(!memberData) return null;

    const deleteMember = (e, memberId) => {
        if(memberId === currentUserId) return;

        handleStopPropagation(e);

        const taskUserWorking = _.find(tasks, task => task?.userWorking?.includes(memberId) && task.status === 'inprogress');

        if (taskUserWorking) {
            toastr.confirm(intl.formatMessage({id:`It's impossible to delete this user as he is working on ${taskUserWorking.title} right now.`}),{
                okText: intl.formatMessage({id:'OK'}),
                disableCancel: true,
            });
            return;
        }

        if (canSee(['admin', 'subscription'], { data: { permissions: userPermissions } })) {
            toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this member?'}), {
                onOk: async () => {
                    try {
                        const usersCountUpdated = _.size(memberResources) - 1;

                        firebaseEvents.addChallenge('deleteMember');
                        firebaseEvents.deleteMember(accountId, memberId, intl);

                        if(isAccountInTrial) return;
                        
                        await removeUserToSubscription({chargebeeCustomerId: chargebeeCustomerId, nbUsers: usersCountUpdated});

                    } catch (error) {
                        toastr.error('Something went wrong when trying to delete this member. Please contact Planless support');
                    }
                },
                okText: intl.formatMessage({id:'toastr.confirm.delete'}),
                cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
                id: 'toastr-confirm-delete',
            });
            return;
        }
        toastr.error('You do not have permissions to delete this member');
    };  

    const editMember = (e, userId) => {
        handleStopPropagation(e);
        browserHistory.push(`/settings/team-members/${userId}`);
    };

    return (
        <div
            key={memberId}
            data-userid={memberId}
            className={classnames('userLine', { onSidebar: router.params.uid === memberId })}
            onClick={(e) => editMember(e, memberId)}
        >
            <UserAvatar
                className="avatar"
                displayName={memberData.displayName}
                avatarUrl={memberData.avatar}
                color={memberData.color}
                disableTooltip={true}
            />
            <span className="userName">{memberData.displayName}</span>
            <span className='memberType'>{MEMBER_TYPES_LABELS[memberData.memberType]}</span>
            <Button 
                onClickCapture={(e) => deleteMember(e, memberId)} 
                title={intl.formatMessage({id:'Delete'})}
                disabled={memberId === currentUserId || memberData?.permissions?.admin}
            >
                <DeleteIcon />
            </Button>
            <IconButton onClick={(e) => editMember(e, memberId)}>
                <Edit />
            </IconButton>
        </div>
    );
};

export default withRouter(withCustomErrorBoundary(UserLine));