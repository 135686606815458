var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import SubscribeSection from '../SubscribeSection/SubscribeSection';
import { canSee } from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import { getAccountEndPlanAt, getAccountIsLifetime, getAccountPlanPeriodicity, } from '../../utils/selectors/account';
import { getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import useInternalization from '../../utils/hooks/useInternalization';
import TrialDetail from './components/TrialDetail';
import PlanDetail from './components/PlanDetail';
var Account = function (_a) {
    var children = _a.children;
    var endPlanAt = useSelector(getAccountEndPlanAt);
    var userPermissions = useSelector(getUserPermissions);
    var t = useInternalization().t;
    var isLifetime = useSelector(getAccountIsLifetime);
    var planPeriodicity = useSelector(getAccountPlanPeriodicity);
    var previousSubscriptions = Boolean(planPeriodicity);
    if (!canSee(['admin', 'subscription'], { data: { permissions: userPermissions } })) {
        return React.createElement(Unautorized, null);
    }
    return (React.createElement("div", { className: "Account" },
        React.createElement("h1", null, t('Subscription')),
        !previousSubscriptions ? (React.createElement(TrialDetail, __assign({}, { endPlanAt: endPlanAt }))) : (React.createElement(PlanDetail, __assign({}, { endPlanAt: endPlanAt }))),
        !isLifetime && React.createElement(SubscribeSection, null),
        children));
};
export default withCustomErrorBoundary(Account);
