import React from 'react';
import classnames from 'clsx';
import StatusItem from '../StatusItem/StatusItem';

const StatusList = ({ statuses, currentStatusSelected, taskId, onClose}) => {
    return (
        <div className={classnames('Status-list-container')}>
            { statuses.map((item, index) => {
                return (
                    <StatusItem
                        key={`${item.id}-${index}`}  
                        item={item} 
                        taskId={taskId}
                        onClose={onClose}
                        isSelected={item.id === currentStatusSelected.id} 
                    />
                ); 
            })}
        </div>
    );
};

export default StatusList;