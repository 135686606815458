import axios from 'axios';
import {
    CLOUD_FUNCTIONS
} from '../constants';

export const getAuthUrl = ({ type, currentUserId, accountId, appData })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.getAuthUrl,
    params: { type, currentUserId, accountId, ...appData }
});

export const connectApp = ({ type, currentUserId, accountId, code }) => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.connectApp,
    data: { type, currentUserId, accountId, code }
});

export const syncData = ({ data, currentUserId, accountId })  => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.syncData,
    data: { data, currentUserId, accountId  }
});

export const getItems = ({ syncAppId, accountId, dataType, itemId })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.getItems,
    params: { syncAppId, accountId, dataType, itemId },
});

export const connectFolder = ({ items, accountId, syncAppId })  => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.connectFolder,
    data: { items, accountId, syncAppId  }
});

export const desyncProject = ({ taskIds, accountId, syncAppId, mainSourceId, parentTaskIds })  => axios.request({
    method: 'post',
    url: CLOUD_FUNCTIONS.desyncProject,
    data: { taskIds, accountId, syncAppId, mainSourceId, parentTaskIds }
});

export const cancelQueue = ({ accountId  })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.cancelQueue,
    params: { accountId },
});

export const refreshUsers = ({ accountId, syncAppId  })  => axios.request({
    method: 'get',
    url: CLOUD_FUNCTIONS.refreshUsers,
    params: { accountId, syncAppId },
});