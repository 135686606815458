import _ from 'underscore';

export const getUserNbUnreadMessages = ({ userId, chatData }) => {
    const nbUnreadMessages = _.reduce(chatData, (count, chatType, chatKey) => {
        let currentCount = count;

        const allChatChannels = _.map(chatType, (channel, channelId) => chatData[chatKey][channelId]);
        const userChannels = _.filter(allChatChannels, (channel) => Boolean(channel?.participants?.includes(userId)));
        const userChannelsMessages = _.reduce(userChannels, (messages, channel) => [...messages, ...Object.values(channel?.messages || {})], []);

        _.forEach(userChannelsMessages, (message) => {
            if(message.seenBy && !message.seenBy.includes(userId)) currentCount++;
            if(currentCount > 20) return;
        });

        return currentCount;
    }, 0);

    if(nbUnreadMessages > 20) return 20;
    return nbUnreadMessages;
};