import React from 'react';
import { Button } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import PrettyFileIcons from 'pretty-file-icons';


const ItemFile = ({name, fileId, url }) => {
    let icon = (
        <img
            src={'../../../stylesheets/assets/fileTypes/' + PrettyFileIcons.getIcon(name, 'svg')}
            alt="file type icon"
        />
    );
    let type = PrettyFileIcons.getIcon(name).toUpperCase() + ' File';
    if (type === 'UNKNOWN File') {
        type = 'File';
    }

    return <div className='itemFile'>
        <Card elevation={0} key={fileId}>
            <CardHeader avatar={icon} title={name} subheader={type} />
            <CardActions disableSpacing>
                <Button color="primary" size="small" onClick={() => window.open(url)}>
                        Download
                </Button>
            </CardActions>
        </Card>
    </div>;
};

export default ItemFile;