import React from 'react';
import PropTypes from 'prop-types';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getAccountTaskCanManage, getAccountTaskDependencies } from '../../utils/selectors/account';
import { useIntl } from 'react-intl';
import { openSelectDependenciesModal } from '../App/AppActions';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { getOrderedTaskListLength } from '../../utils/selectors/app';
import TaskDetailDependenciesList from './TaskDetailDependenciesList';

const TaskDetailDependencies = ({ taskId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const taskDependencies = useSelector(getAccountTaskDependencies(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));

    const accountId = useSelector(getAccountId);
    const orderedTaskListLength = useSelector(getOrderedTaskListLength);

    const handleDeleteAllDependencies = () => {
        firebaseEvents.changeTaskDependentOn(accountId, taskId, []);
    };

    const handleOpenSelectDependenciesModal = () => {
        dispatch(openSelectDependenciesModal({taskId, dependencies: taskDependencies || []}));
    };

    return (
        <li>
            <dl>
                <dt>{intl.formatMessage({id:'Dependent on'})}</dt>
                <dd>
                    {taskDependencies && <TaskDetailDependenciesList taskId={taskId} />}
                    <div className="manageDeleteDependenciesBtn">
                        <Button
                            disabled={orderedTaskListLength <= 1 || !canManage}
                            onClick={handleOpenSelectDependenciesModal}
                            variant="contained"
                            color="primary"
                        >
                            {intl.formatMessage({id:'MANAGE DEPENDENCIES'})}
                        </Button>
                        {taskDependencies && taskDependencies.length > 1 && (
                            <Button
                                disabled={!canManage}
                                variant="outlined"
                                color="default"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleDeleteAllDependencies()}
                            >
                                {intl.formatMessage({id:'DELETE ALL'})}
                            </Button>
                        )}
                    </div>
                </dd>
            </dl>
        </li>
    );
};

TaskDetailDependencies.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailDependencies;
