var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { Link, Slider } from '@material-ui/core';
import { marks } from '../../../../shared/constants';
import { getAccountUsers } from '../../../../utils/selectors/account';
import { getInitialSliderMark } from '../../utils/functions';
import useInternalization from '../../../../utils/hooks/useInternalization';
import { getOnlyResourceUsers } from '../../../../utils/memberTypes';
var calculateLeftPost = function (val) { return "".concat((val - 1) * 11.111, "%"); };
var ResourceSlider = function (_a) {
    var _b;
    var setResourceValue = _a.setResourceValue, isResourceEqualMore = _a.isResourceEqualMore, redirectToMemberSettings = _a.redirectToMemberSettings;
    var t = useInternalization().t;
    var accountUsers = useSelector(getAccountUsers);
    if (!accountUsers)
        return null;
    var memberResources = getOnlyResourceUsers(accountUsers);
    var initialSliderMark = getInitialSliderMark(_.size(memberResources));
    var beforeInitialSliderMark = marks.find(function (mark) { return mark.value === (initialSliderMark.value - 1); });
    var _c = __read(useState(initialSliderMark.value), 2), val = _c[0], setVal = _c[1];
    var _d = __read(useState(calculateLeftPost(val)), 2), leftPos = _d[0], setLeftPos = _d[1];
    var _e = __read(useState(), 2), lastMark = _e[0], setLastMark = _e[1];
    var _f = __read(useState(false), 2), showWarning = _f[0], setShowWarning = _f[1];
    var sliderResource = (_b = marks.find(function (el) { return el.value === val; })) === null || _b === void 0 ? void 0 : _b.label;
    var isResourcesSelectedLessThanExistent = Number(sliderResource) < _.size(memberResources);
    useEffect(function () {
        if (!sliderResource)
            return;
        if (isResourcesSelectedLessThanExistent) {
            setLastMark(marks.find(function (mark) { return mark.value === val; }));
            setVal(initialSliderMark.value);
            setLeftPos(calculateLeftPost(initialSliderMark.value));
            setResourceValue(initialSliderMark.label);
        }
        else {
            setResourceValue(sliderResource);
        }
    }, [sliderResource]);
    useEffect(function () {
        if (!lastMark)
            return;
        setShowWarning(lastMark === beforeInitialSliderMark && val === initialSliderMark.value);
    }, [lastMark, val]);
    var handleSliderChange = function (e, value) {
        var newVal = value;
        setVal(newVal);
        setLeftPos("".concat((newVal - 1) * 11.111, "%"));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "pricing-slider-container" },
            React.createElement("div", { className: "slider" },
                React.createElement("div", { className: "slider-stripes" }, Array.from(Array(101).keys()).map(function (value) { return (React.createElement("span", { key: value })); })),
                React.createElement("div", { className: "slider-component" },
                    React.createElement(Slider, { className: "pricing-slider", valueLabelDisplay: "off", value: val, step: 1, min: 1, max: 10, marks: marks, onChange: handleSliderChange })),
                React.createElement("div", { className: "slider-label", style: { left: leftPos } },
                    React.createElement("span", null, !isResourceEqualMore ? 'UP TO' : 'MORE THAN'),
                    React.createElement("strong", null, isResourceEqualMore ? '250' : sliderResource),
                    React.createElement("p", null, "resources")))),
        showWarning && (React.createElement("div", { className: "resource-limit-warning" },
            t('pricingModal.resourceSlider.warning', { resources: beforeInitialSliderMark === null || beforeInitialSliderMark === void 0 ? void 0 : beforeInitialSliderMark.label }),
            React.createElement("div", null, "".concat(t('pricingModal.resourceSlider.redirect'), " "),
                React.createElement(Link, { className: "redirect-link", color: "secondary", onClick: redirectToMemberSettings }, t('pricingModal.resourceSlider.membersSettings')))))));
};
export default React.memo(ResourceSlider);
