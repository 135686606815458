import React, { useState, useEffect } from 'react';
import classnames from 'clsx';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import DoneIcon from '@material-ui/icons/Done';
import PlayingIcon from '@material-ui/icons/Visibility';
import Vimeo from '@u-wave/react-vimeo';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import { getUserFirstSteps } from '../../utils/selectors/user';
import { useIntl } from 'react-intl';
import { handleFirstStepsVideos } from '../../utils/firebaseEvents';
import { videoSteps } from './utils';
import { CardContent, CircularProgress } from '@material-ui/core';

const GetIcon = ({ currentVideo, activedVideo, idx, onChange }) => {
    const userFirstStepsConfig = useSelector(getUserFirstSteps);

    if (
        currentVideo.videoRef === activedVideo.videoRef &&
        (!userFirstStepsConfig?.videos || !userFirstStepsConfig?.videos[currentVideo.videoRef])
    ) {
        return (
            <PlayingIcon />
        );
    }

    if (!userFirstStepsConfig?.videos || !userFirstStepsConfig?.videos[currentVideo.videoRef]) {
        return (
            <IconButton id={idx} name={idx} onClick={onChange}>
                <PlayCircleFilledIcon />
            </IconButton>
        );
    }

    return (
        <IconButton id={idx} name={idx} onClick={onChange}>
            <DoneIcon />
        </IconButton>
    );
};

const StepsVideos = ({ videoRef }) => {
    const intl = useIntl();
    const [currentVideo, setCurrentVideo] = useState(videoSteps[0]);
    const [isloaded, setLoaded] = useState(false);
    const userFirstStepsConfig = useSelector(getUserFirstSteps);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('firstStepsVideos'));
        if (data && data.videoRef) {
            const videoPosition = videoSteps.findIndex(el => el.videoRef === data.videoRef);

            setCurrentVideo({
                ...videoSteps[videoPosition],
                watched: data.watched,
            });
        }
        setLoaded(true);
    }, []);

    const onChange = e => {
        const name = e.currentTarget.getAttribute('name') ?? e.currentTarget.getAttribute('data-name');

        setCurrentVideo(videoSteps[parseInt(name)]);
    };

    const onEnd = () => {
        handleFirstStepsVideos({
            videoRef: currentVideo.videoRef,
            params: {
                completed: true,
            },
        });

        const videoPosition = videoSteps.findIndex(el => el.videoRef === currentVideo.videoRef);
        if (videoPosition < 6) {
            localStorage.setItem(
                'firstStepsVideos',
                JSON.stringify({
                    videoRef: videoSteps[videoPosition + 1].videoRef,
                    watched: 10,
                }),
            );
            setCurrentVideo(videoSteps[videoPosition + 1]);
        }
    };

    const onPause = e => {
        localStorage.setItem(
            'firstStepsVideos',
            JSON.stringify({
                videoRef: currentVideo.videoRef,
                watched: e.seconds,
            }),
        );
    };

    return (
        <div className="StepsVideos">
            <div className='StepsVideos__header'>
                <h2>{intl.formatMessage({id: 'Learn the basics in some minutes'})}</h2>
                <p>{intl.formatMessage({id: 'Quickly understand how to get started and use Planless at its maximum.'})}</p>
            </div>
            <div className='StepsVideos__content'>
                <div className="videoPlayer">
                    {!isloaded ? (
                        <CardContent>
                            <CircularProgress></CircularProgress>
                        </CardContent>
                    ) : (
                        <Vimeo
                            ref={videoRef}
                            start={currentVideo.watched | 0}
                            onTimeUpdate={onPause}
                            onPause={onPause}
                            onEnd={onEnd}
                            video={currentVideo.videoRef}
                        />
                    )}
                </div>
                <div className="videoList">
                    {videoSteps.map((el, idx) => (
                        <div key={el.videoRef}
                            data-name={idx}
                            onClick={onChange}
                            className={classnames('videoLine', {
                                videoWatching: currentVideo.videoRef === el.videoRef,
                                videoWatched: userFirstStepsConfig?.videos && userFirstStepsConfig?.videos[el.videoRef],
                            })}
                        >
                            <GetIcon currentVideo={el} activedVideo={currentVideo} idx={idx} onChange={onChange} />
                            <p>{`${idx + 1}. ${el.name}`}</p>
                            <span>{el.time}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StepsVideos;