import React from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/Delete';
import { permissionsByPlan } from '../../utils/constants';

import {canSee} from '../../utils/userFunctions';
import Unautorized from '../Unautorized/Unautorized';
import { getAccountDaysoff, getAccountId, getAccountPlan } from '../../utils/selectors/account';
import { getUserDateformat, getUserPermissions } from '../../utils/selectors/user';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Daysoff = ({children})=>{
    const intl = useIntl();

    const accountId = useSelector(getAccountId);
    const plan = useSelector(getAccountPlan);
    const dateFormat = useSelector(getUserDateformat);
    const daysoff = useSelector(getAccountDaysoff);
    const userPermissions = useSelector(getUserPermissions);

    const deleteDayoff = (dayoffId) => {
        toastr.confirm(intl.formatMessage({id:'Are you sure you want to delete this day off?'}), {
            onOk: () => {

                firebaseEvents.addChallenge('deleteGlobalDayoff');
                firebaseEvents.deleteAccountDayoff(
                    accountId, 
                    dayoffId, 
                    intl
                );
            },
            okText: intl.formatMessage({id:'toastr.confirm.delete'}),
            cancelText: intl.formatMessage({id:'toastr.confirm.cancel'}),
            id: ('toastr-confirm-delete')
        });
    };

    const getDaysoffTable = () => {
        var daysoffRows = [];
        _.each(daysoff, (dayoff, dayoffId)=>{
            daysoffRows.push(
                <TableRow key={dayoffId} hover={true}>
                    <TableCell>{moment(dayoff.startDate).format((dateFormat)?dateFormat:'DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(dayoff.endDate).format((dateFormat)?dateFormat:'DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(dayoff.endDate).diff(moment(dayoff.startDate), 'days') +1}</TableCell>
                    <TableCell>
                        <DeleteIcon onClick={()=>deleteDayoff(dayoffId)} />
                    </TableCell>
                </TableRow>
            );
        });
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id:'From'})}</TableCell>
                        <TableCell>{intl.formatMessage({id:'To'})}</TableCell>
                        <TableCell>{intl.formatMessage({id:'Days'})}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {daysoffRows}
                </TableBody>
            </Table>
        );
    };

    if(!permissionsByPlan.daysoff.includes(plan) || !canSee(['admin', 'daysoff'],  { data: { permissions: userPermissions }})) return <Unautorized />;

    return (
        <div className='Daysoff'>
            <h1>{intl.formatMessage({id:'Global Days-off'})}</h1>
            
            <Button className='addDayOffBtn' variant="contained" color="primary" onClick={()=>browserHistory.push('/settings/daysoff/add')}>{intl.formatMessage({id:'Add a day off'})}</Button>

            <div className="table">
                {!_.isEmpty(daysoff) ? (
                    getDaysoffTable()
                ): (
                    <div className="nodaysoff">{intl.formatMessage({id:'account.no.daysoff'})}</div>
                )}
            </div>

            {children}
        </div>
    );
};

export default withCustomErrorBoundary(Daysoff);
