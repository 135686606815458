import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { sortByDate } from './utils';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
const getFirstUser = (data) => data[0]?.name || '';
function sortByUsers(a, b, orderBy) {
    if (getFirstUser(b[orderBy]) < getFirstUser(a[orderBy])) {
        return -1;
    }
    if (getFirstUser(b[orderBy]) > getFirstUser(a[orderBy])) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy, type) {
    if(type === 'date') {
        return order === 'desc'
            ? (a, b) => sortByDate(a, b, orderBy)
            : (a, b) => -sortByDate(a, b, orderBy);
    }

    if(type === 'array-users'){
        return order === 'desc'
            ? (a, b) => sortByUsers(a, b, orderBy)
            : (a, b) => -sortByUsers(a, b, orderBy);
    }

    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead( {   order, orderBy,  onRequestSort, headCells }) {
    const createSortHandler = (property, type) => (event) => {
        onRequestSort(event, property, type);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.type)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function EnhancedTable({ rows, renderRow, headCells, onRequestClick = () => {}, defaultSort = '', defaultOrder = 'asc', extraRows }) {
    const [order, setOrder] = React.useState(defaultOrder);
    const [orderBy, setOrderBy] = React.useState(defaultSort);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnClick = (row) => {
        onRequestClick(row);
    };

    const type = headCells.find(el => el.id === orderBy)?.type || '';
    
    return (
        <div className="reports-table" >
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy, type))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => 
                                <TableRow onClick={() => handleOnClick(row)} hover tabIndex={-1} key={idx} >
                                    {renderRow(idx, row)}
                                </TableRow>
                            )}
                        {extraRows && extraRows}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows.length > 5 && <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            }
        </div>
    );
}
