import { currencyFormat } from './currencyFormat';
import { Recurrence } from '../shared/enums';
import { PRICING_TABLE } from '../shared/constants';
var getTotalResourcePrice = function (recurrence, currency, resourceValue) {
    return Number(PRICING_TABLE[recurrence][currency][resourceValue]);
};
var getPlanPriceByResource = function (recurrence, currency, resourceValue) {
    var totalPrice = getTotalResourcePrice(recurrence, currency, resourceValue);
    var resourcePrice = totalPrice / Number(resourceValue);
    var recurrencePrice = recurrence === Recurrence.MONTHLY ? resourcePrice : resourcePrice / 12;
    var roundedPrice = Math.round(((recurrencePrice) + Number.EPSILON) * 100) / 100;
    return currencyFormat({
        value: roundedPrice.toFixed(2),
        locale: null,
        currency: currency,
    });
};
var getPlanTotalPrice = function (recurrence, currency, resourceValue) {
    var totalPrice = getTotalResourcePrice(recurrence, currency, resourceValue);
    return currencyFormat({
        value: totalPrice.toFixed(2),
        locale: null,
        currency: currency,
    });
};
export { getPlanPriceByResource, getPlanTotalPrice, };
