export var getUser = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user; };
export var getUserEmail = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email; };
export var getUserAvatar = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.avatar; };
export var getUserColor = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.color; };
export var getUserData = function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data; };
export var getUserAccounts = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.userAccounts; };
export var getUserFreemiumRollback = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.freemiumRollback; };
export var getUserTimezone = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.timezone; };
export var getUserId = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.userId; };
export var getUserUid = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.uid; };
export var getUserDisplayName = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.displayName; };
export var getUserAccountId = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.accountId; };
export var getUserTimeformat = function (state) { var _a, _b, _c; return ((_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.timeFormat) || 'HH:mm'; };
export var getUserDateformat = function (state) { var _a, _b, _c; return ((_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.dateFormat) || 'DD/MM/YYYY'; };
export var getUserEvents = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.events; };
export var getUserCalendars = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.calendars; };
export var getUserIsViewer = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.viewer; };
export var getUserPermissions = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions; };
export var getUserPermissionsAdmin = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.admin; };
export var getUserPermissionsBoards = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.boards; };
export var getUserPermissionsDaysOff = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.daysoff; };
export var getUserPermissionsSkills = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.skills; };
export var getUserPermissionsSubscription = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.subscription; };
export var getUserPermissionsTeam = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.team; };
export var getUserPermissionsWorkload = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.permissions) === null || _d === void 0 ? void 0 : _d.workload; };
export var getUserShowEmailNotifInfo = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.showEmailNotifInfo; };
export var getUserCalendarConnected = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.isFirstCalendarConnected; };
export var getUserFirstSteps = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.userFirstStepsConfig; };
export var getUserSurveySent = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.surveySent; };
export var getUserTodos = function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.todos; };
