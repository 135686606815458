// eslint-disable-next-line import/no-cycle
import { addChallenge, db, segmentTrack } from '../../../firebaseEvents';
export var updateTaskDescription = function (accountId, taskId, value) {
    var description = value;
    if (!description || description === '<p></p>\n') {
        description = null;
    }
    db.set("/accountsData/".concat(accountId, "/tasks/").concat(taskId, "/description"), description);
    addChallenge('describeTask');
    segmentTrack('Change task description', { accountId: accountId, taskId: taskId, value: value });
};
