import _ from 'underscore';

export const getTaskPath = (taskId, tasks) => {
    if(tasks[taskId]){
        var parentsPath = [tasks[taskId].title],
            currentTask = taskId;

        while(tasks[currentTask] && tasks[currentTask].parent){
            parentsPath.push(tasks[tasks[currentTask].parent]?.title);
            currentTask = tasks[currentTask].parent;
        }
        parentsPath = parentsPath.reverse().join('/');

        return parentsPath;
    }
    return '';
};

export const getAssignedEntries = (selectedOptions, users) => {
    const currentAssigned = selectedOptions.map(user=>user.id);

    const list = Object.keys(users || {})
        .reduce((acc, userId) => {
            if(users[userId] && !users[userId]?.viewer && !currentAssigned.includes(userId)) {
                acc.push({id: userId, title: users[userId]?.displayName});
            }
            return acc;
        }, []);
    
    const assignedList = _.sortBy(list, 'title');

    return assignedList;
};

export const getHierarchyEntries = (selectedOptions, tasks) => {
    const currentHierarchy = selectedOptions.map(task=>task.id);

    const list = Object.keys(tasks || {})
        .reduce((acc, taskId) => {
            if(tasks && tasks[taskId] && !currentHierarchy.includes(taskId)) {
                acc.push({id: taskId, title: getTaskPath(taskId, tasks)});
            }   
            return acc;
        }, []);

    const hierarchyEntries = _.sortBy(list, 'title');

    return hierarchyEntries;
};