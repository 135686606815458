import React, { useState } from 'react';
import { Slider } from '@material-ui/core';

export const TaskDetailSkillRange = ({ skill, onRangeCommitted, isDisabled }) => {
    const [skillRange, setSkillRange] = useState([skill.range.min, skill.range.max]);

    const onChange = (_, value) => setSkillRange(value);
    const onChangeCommitted = (_, value) => onRangeCommitted(skill.id, { min: value[0], max: value[1] });

    return (
        <div className='SkillRange'>
            <span className="SkillRange__label" title={skill?.name}>{skill?.name}</span>
            <Slider
                color="secondary"
                min={1}
                max={10}
                step={1}
                value={skillRange}
                onChange={onChange}
                onChangeCommitted={onChangeCommitted}
                valueLabelDisplay="auto"
                disabled={isDisabled} />
        </div>
    );
};