import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import { downloadFile } from '../utils';
import { getTaskValueSplitted } from '../../../utils/costBudget';
import ChartWithList  from '../ChartWithList';

import Paper from '@material-ui/core/Paper';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';


const CostBudgetRepartition = ({ 
    stateTasks,
    workspaceSelected,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);
    
    const [data, setData] = useState({
        teamMembersData: {},
        skillsData: {},
        tasksData: {}, 
        total: 0,
    });

    useEffect(() => {
        // in the case of beeing root lvl 
        // get all of the sub childs 
        const workspaceMainItens = workspaceSelected === 'root' ? 
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean) 
            : _.get(stateTasks[workspaceSelected], 'childrens' , []);
        
        const byTeamMember = {}; 
        const bySkill = {}; 
        const byTask = {}; 
        let total = 0;
        
        workspaceMainItens.forEach(el => {

            if(el.type && el.type !== 'task'){
                return false;
            }

            const costValueSplitted = getTaskValueSplitted({taskId: el.id, allTasks: stateTasks, accountUsers: accountTeamMembers});
            //  costValueSplitted
            // by TM 
            Object.entries(costValueSplitted.byUsers).forEach(([userId, value]) => {
                if(byTeamMember[userId]){
                    byTeamMember[userId].value = value.internalCost; 
                } else {
                    const userDetail = accountTeamMembers[userId];
                    byTeamMember[userId] = {
                        value: value.internalCost, 
                        name: userDetail?.displayName || '(deleted user)', 
                        id: userId,
                    };
                }
            });
            // bySkill
            Object.entries(costValueSplitted.bySkills).forEach(([skillId, value]) => {
                if(bySkill[skillId]){
                    bySkill[skillId].value = value.internalCost; 
                } else {
                    const skillDetails = !accountSkills || !accountSkills[skillId] ? { name: 'No Skill defined'} : accountSkills[skillId];
                    bySkill[skillId] = {
                        value:  value.internalCost, 
                        name:  skillDetails?.name,
                        id:  skillId,
                    };
                }
            });
            // bySkill
            if(byTask[el.id]){
                byTask[el.id].value = costValueSplitted.totalInternalTaskValue; 
            } else {
                byTask[el.id] = {
                    value: costValueSplitted.totalInternalTaskValue, 
                    name: stateTasks[el.id].title,
                    id: el.id, 
                };
            }

            total += costValueSplitted.totalInternalTaskValue;
        });

        setData({
            skillsData: bySkill,
            tasksData: byTask,
            teamMembersData: byTeamMember,
            total: total,
        });
    }, [workspaceSelected, stateTasks, accountTeamMembers, accountSkills]);
  

    const exportCSV = useCallback((data, totalDataOfChart, name) => {
        const headers = 'Item; % effort';
        const rows = Object
            .values(data)
            .sort((a,b) => b.value - a.value)
            .map(el => `${el.name};${parseFloat((el.value / totalDataOfChart) * 100).toFixed(2)}%;`);
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${headers}\r\n${rows.join('\r\n')}`), name);
    }, []);
    
    let hasData = (toCheck) =>  Object.keys(toCheck).find(key => toCheck[key].value);

    return <Paper className={'breakdown-page'} >
        <ChartWithList
            isEmpty={!hasData(data.tasksData)} 
            key='ChartWithList-internal-cost-sub-iten'
            id='ChartWithList-internal-cost-sub-iten'
            chartData={data.tasksData}
            chartTitle='Actual Internal Cost per Sub Items'
            totalDataOfChart={data.total}
            exportCSV={exportCSV}
        />
        <ChartWithList
            isEmpty={!hasData(data.teamMembersData)} 
            key='ChartWithList-internal-cost-By-Team-Members'
            id='ChartWithList-internal-cost-By-Team-Members'
            chartData={data.teamMembersData}
            chartTitle='Actual Internal Cost per Team Members'
            totalDataOfChart={data.total}
            exportCSV={exportCSV}
        />
        <ChartWithList 
            isEmpty={!hasData(data.skillsData)} 
            key='ChartWithList-internal-cost-By-Skills'
            id='ChartWithList-internal-cost-By-Skills'
            chartData={data.skillsData}
            chartTitle='Actual Internal Cost per Skills'
            totalDataOfChart={data.total}
            exportCSV={exportCSV}
        />
    </Paper>;
};

CostBudgetRepartition.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(CostBudgetRepartition);