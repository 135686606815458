import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import Proptypes from 'prop-types';
import { downloadFile, getUserInfo } from '../utils';
import ChartWithList  from '../ChartWithList';

import Paper from '@material-ui/core/Paper';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';


const EffortBreakdown = ({
    stateTasks,
    workspaceSelected,
    childsParents,
}) => {
    const accountTeamMembers = useSelector(state => state?.app?.account?.users);
    const accountSkills = useSelector((state) => state.app?.account?.skills);

    const [data, setData] = useState({
        teamMembersData: {},
        skillsData: {},
        tasksData: {},
        totalMaxEffort: 0,
        totalMinEffort: 0,
        totalAverage:0
    });

    useEffect(() => {
        // in the case of beeing root lvl
        // get all of the sub childs
        const workspaceMainItens = workspaceSelected === 'root' ?
            Object.entries(stateTasks).map(([id, el]) => !el.parent ? { id } : false).filter(Boolean)
            : _.get(stateTasks[workspaceSelected], 'childrens' , []);

        const workspaceMainItensChilds  = {};
        workspaceMainItens.forEach(({id}) => {
            workspaceMainItensChilds[id] =  _.get(childsParents, id , [id]);
        });
        const workspaceMainItensChildsToEntries = Object.entries(workspaceMainItensChilds);


        const workspaceTasks = workspaceSelected === 'root' ?
            Object.keys(stateTasks) :
            _.get(childsParents, workspaceSelected , [workspaceSelected]);

        if( workspaceTasks.length) {
            const teamMembersData =  {};
            const skillsData =  {};
            const tasksData =  {};
            let totalMaxEffort = 0;
            let totalMinEffort =  0;

            workspaceTasks.forEach(taskId => {
                const currentTask = stateTasks[taskId];

                if(!currentTask || currentTask.status === 'done' || (stateTasks.parent &&  !stateTasks[stateTasks.parent]) || currentTask.childrens){
                    return;
                }

                // handle team members
                let taskUsers = [];

                // split
                if(currentTask.estimations?.userId){
                    taskUsers = (Array.isArray(currentTask.estimations.userId) ? currentTask.estimations.userId : [currentTask.estimations.userId])
                        .map(el => ({isForced: false, userId: el , avatar: getUserInfo(accountTeamMembers[el]).avatar,  name: getUserInfo(accountTeamMembers[el]).displayName}));
                } else if(currentTask.forcedUser) {
                    taskUsers = (Array.isArray(currentTask.forcedUser) ? currentTask.forcedUser : [currentTask.forcedUser]).map(el => ({isForced: true, avatar: getUserInfo(accountTeamMembers[el]).avatar, userId: el, name: getUserInfo(accountTeamMembers[el])?.displayName }));
                }

                totalMaxEffort += parseInt(currentTask.maxEffort) || 0;
                totalMinEffort += parseInt(currentTask.minEffort) || 0;

                // handle skills
                const skills = currentTask?.skill?.length ? currentTask.skill : [];
                const skillDatasetsMax = skills.map(skillId => skillsData[skillId]).filter(Boolean);

                if (skillDatasetsMax.length) {
                    skillDatasetsMax.forEach(skillDatasetMax => {
                        skillDatasetMax.minEffort += parseInt(currentTask.minEffort) || 0;
                        skillDatasetMax.maxEffort += parseInt(currentTask.maxEffort) || 0;
                    });
                } else {
                    skills.forEach(skillId => {
                        skillsData[skillId] = {
                            id: skillId,
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: accountSkills[skillId]?.name
                        };
                    });
                }
                // handle team members
                if(taskUsers.length) {
                    taskUsers.forEach(({userId}) => {
                        const userDetail = accountTeamMembers[userId];
                        const datasetEntry = teamMembersData[userId];

                        if(datasetEntry){
                            datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                            datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                        } else {
                            teamMembersData[userId] = {
                                id: userId,
                                minEffort: parseInt(currentTask.minEffort) ||  0,
                                maxEffort: parseInt(currentTask.maxEffort) ||  0,
                                name: userDetail?.displayName || '(deleted user)'
                            };
                        }
                    });
                } else {
                    const userData = teamMembersData.none;
                    if(userData){
                        userData.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        userData.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        teamMembersData.none = {
                            id: 'none',
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: 'Not Assigned'
                        };
                    }
                }
                // get parent
                const mainItemEntrie = workspaceMainItensChildsToEntries.find(([, childs]) => childs.includes(taskId));
                if(mainItemEntrie) {
                    const datasetEntry = tasksData[mainItemEntrie[0]];

                    if(datasetEntry){
                        datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        tasksData[mainItemEntrie[0]] = {
                            id: mainItemEntrie[0],
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: stateTasks[mainItemEntrie[0]].title
                        };
                    }
                } else {
                    const datasetEntry = tasksData[taskId];
                    if(datasetEntry){
                        datasetEntry.maxEffort += parseInt(currentTask.maxEffort) ||  0;
                        datasetEntry.minEffort += parseInt(currentTask.minEffort) ||  0;
                    } else {
                        tasksData[taskId] = {
                            id: taskId,
                            minEffort: parseInt(currentTask.minEffort) ||  0,
                            maxEffort: parseInt(currentTask.maxEffort) ||  0,
                            name: stateTasks[taskId].title
                        };
                    }
                }
            });
            const calculateAverage = (data) => {
                Object
                    .entries(data)
                    .forEach(([,el]) => {
                        el.value = (el.maxEffort + el.minEffort) / 2;
                    });

                return data;
            };


            setData({
                skillsData: calculateAverage(skillsData),
                tasksData: calculateAverage(tasksData),
                teamMembersData: calculateAverage(teamMembersData),
                totalMaxEffort,
                totalMinEffort,
                totalAverage: (totalMaxEffort + totalMinEffort) / 2
            });
        }

    }, [workspaceSelected, stateTasks, accountTeamMembers, childsParents, accountSkills]);


    const exportCSV = useCallback((data, totalDataOfChart, name) => {
        const headers = 'Sub Itens; % effort';
        const rows = Object
            .values(data)
            .sort((a,b) => b.value - a.value)
            .map(el => `${el.name};${parseFloat((el.value / totalDataOfChart) * 100).toFixed(2)}%;`);
        downloadFile(encodeURI(`data:text/csv;charset=utf-8,${headers}\r\n${rows.join('\r\n')}`), name);
    }, []);

    let hasData = (toCheck) =>  Object.keys(toCheck).find(key => toCheck[key].maxEffort);

    return <Paper className={'breakdown-page'} >
        <ChartWithList
            isEmpty={!hasData(data.tasksData)}
            key='ChartWithList-effort-sub-tein'
            id='ChartWithList-effort-sub-tein'
            chartData={data.tasksData}
            chartTitle='Average effort per Sub Items'
            totalDataOfChart={data.totalAverage}
            exportCSV={exportCSV}
        />
        <ChartWithList
            isEmpty={!hasData(data.teamMembersData)}
            key='ChartWithList-effort-By-Team-Members'
            id='ChartWithList-effort-By-Team-Members'
            chartData={data.teamMembersData}
            chartTitle='Average effort per Team Members'
            totalDataOfChart={data.totalAverage}
            exportCSV={exportCSV}
        />
        <ChartWithList
            isEmpty={!hasData(data.skillsData)}
            key='ChartWithList-effort-By-Skills'
            id='ChartWithList-effort-By-Skills'
            chartData={data.skillsData}
            chartTitle='Average effort per Skills'
            totalDataOfChart={data.totalAverage}
            exportCSV={exportCSV}
        />
    </Paper>;
};

EffortBreakdown.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(EffortBreakdown);