import React, { useEffect } from 'react';
import { Card, FormControl, RadioGroup, Typography } from '@material-ui/core';

import useInternalization from '../../../../../utils/hooks/useInternalization';
import OriginalEstimate from './OriginalEstimate';
import StoryPointsEstimate from './StoryPointsEstimate';
import _ from 'underscore';
import { effortChangeTypeFields, JIRA_ESTIMATION_TYPES } from '../utils';
import { withCustomErrorBoundary } from '../../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { setEstimationActive } from '../../../../../utils/controllers/syncApp/jira';
import { segmentTrack } from '../../../../../utils/firebaseEvents';
import { getUserId } from '../../../../../utils/selectors/user';
import { useSelector } from 'react-redux';

const JiraSyncOptions = ({ effortChangeType, effortSettingsData, syncAppId }) => {
    const { t } = useInternalization();
    const currentUserId = useSelector(getUserId);
    const [optionSelected, setOptionSelected] = React.useState(0);
    const [originalEstValue, setOriginalEstValue] = React.useState(20);
    const [storyPointsValue, setStoryPointsValue] = React.useState(null);

    const typeByRadioIndex = {0: 'original', 1: 'storyPoint'};
    const eventNameByType = {0: 'originalEstimateJiraEffort', 1: 'storyPointsJiraEffort'};

    useEffect(() => {
        if(effortSettingsData) {
            const effortTypeData = _.get(effortSettingsData, [effortChangeTypeFields[effortChangeType]], {});
            const { estimationActive, originalEstimation, storyPoints } = effortTypeData;
            
            const optionSelected = estimationActive === JIRA_ESTIMATION_TYPES.ORIGINAL ? 0 : 1;
            setOptionSelected(optionSelected);

            setOriginalEstValue(originalEstimation?.value || 20);
            setStoryPointsValue(storyPoints?.value || null);
        }
    }, [effortSettingsData, effortChangeType]);

    const handleSelectOption = (e) => {
        const value = parseInt(e.target.value);
        setEstimationActive({
            syncAppId, 
            estimationActive: typeByRadioIndex[value]
        });
        setOptionSelected(value);

        segmentTrack(eventNameByType[value], { userId: currentUserId });
    };

    return (
        <Card elevation={0}>
            <Typography variant='subtitle1'>{t('With')}:</Typography>
            <FormControl component="fieldset">
                <RadioGroup>
                    <OriginalEstimate
                        syncAppId={syncAppId}
                        optionSelected={optionSelected}
                        effortChangeType={effortChangeType}
                        originalEstimation={originalEstValue}
                        handleSelectOption={handleSelectOption}
                    />

                    <StoryPointsEstimate
                        syncAppId={syncAppId}
                        optionSelected={optionSelected}
                        effortChangeType={effortChangeType}
                        storyPoints={storyPointsValue}
                        handleSelectOption={handleSelectOption}
                    />
                </RadioGroup>
            </FormControl>
        </Card>
    );
};

export default withCustomErrorBoundary(JiraSyncOptions);