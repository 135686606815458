import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import { useDispatch } from 'react-redux';
import { selectSourceStatusModal } from '../App/AppActions';
import { SYNC_APP_NAMES } from '../Integrations/utils';

const TaskSourceStatus = ({ task, syncAppType }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const taskSourceStatus = task?.sourceStatus;

    const handleOpenModal = React.useCallback(() => {
        dispatch(selectSourceStatusModal({ show: true, taskId: task?.id }));
    }, [task]);

    return (
        <li>
            <dl>
                <dt>{SYNC_APP_NAMES[syncAppType]} {intl.formatMessage({id: 'Status'})}</dt>
                <dt>
                    <div
                        onClick={handleOpenModal}
                        className='sourceStatus'
                    >
                        {!taskSourceStatus?.name ? (
                            <span className="sourceStatus__none">{intl.formatMessage({id: 'None'})}</span>
                        ): (
                            <span>{taskSourceStatus?.name}</span>
                        )}
                    </div>
                    <SyncProjectLogo
                        toolName={syncAppType}
                        tooltipPlacement={'bottom'}
                        fromViewMode={'taskSidebarHeader'}
                        task={task}
                    />
                </dt>
            </dl>
        </li>
    );

};

TaskSourceStatus.propTypes = {
    task: PropTypes.object.isRequired
};

export default React.memo(TaskSourceStatus);
