import moment from 'moment';
import isoCountries from '../isoCountries.json';
import phoneCodes from '../countryPhoneCodes.json';

const useLocationInfo = () => {
    const codes = moment.tz.zone(moment.tz.guess()).countries() || [];
    const locationCountryCode = codes.length ? codes[0] : null;
    const locationCountryName = locationCountryCode ? isoCountries[locationCountryCode] : null;
    const locationPhoneCode = locationCountryName ? phoneCodes[locationCountryName.toUpperCase()] : null;

    const data = {
        locationCountryCode: locationCountryCode || 'USA',
        locationCountryName: locationCountryName || '',
        locationPhoneCode: locationPhoneCode || ''
    };

    return data;
};

export default useLocationInfo;