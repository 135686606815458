import { ADD_TASK_LIST_LEVEL } from '../utils';

export const getTrelloInfo = ({ task, parentsCount, isInsideSyncFolder }) => {
    const canAddTask = () => {
        const isEligibleSourceType = task?.sourceType && task?.sourceType === 'list';
        const isListLevel = parentsCount === 2;

        return isEligibleSourceType || (!task?.sourceType && isInsideSyncFolder && isListLevel);
    };

    return {
        canAddSyncAppTask: canAddTask(),
        hasDependencies: false,
        hasSourceTags: false,
        hasSourceStatus: false,
        // can add only task on the list level
        addTaskListLevel: ADD_TASK_LIST_LEVEL.ONE,
    };
};