import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'clsx';
import moment from 'moment-timezone';
import _ from 'underscore';
import * as firebaseEvents from '../../utils/firebaseEvents';
import * as appactions from '../App/AppActions';
import {  browserHistory } from 'react-router';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { getUserEvents, getUserId, getUserTimeformat, getUserTimezone } from '../../utils/selectors/user';
import { getAccountId, getAccountSkills, getAccountTasks } from '../../utils/selectors/account';
import { getLoadersSkills, getLoadersTasks, getLoadersUsers, getOrderedTaskList } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import SyncProjectLogo from '../../components/SyncProjectLogo/SyncProjectLogo';
import Loader from '../../components/Loader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import DoneIcon from '@material-ui/icons/Done';
import OpenIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import useSyncData from '../../utils/hooks/useSyncData';
import { useStopwatch } from 'react-timer-hook';
import { getTimeDurationInSeconds, DURATION_DAY_HOURS } from '../../utils/utils';

class CustomToolbar extends Toolbar {

    render() {
        const {label} = this.props;

        return (
            <div className="toolbar">
                <div className="rbc-toolbar-label">{label}</div>
                <div>
                    <Button variant="outlined" size="small" onClick={() => this.navigate('TODAY')}>today</Button>
                    <IconButton size="small" onClick={() => this.navigate('PREV')}><ChevronLeft fontSize="large" /></IconButton>
                    <IconButton size="small" onClick={() => this.navigate('NEXT')}><ChevronRight fontSize="large" /></IconButton>
                </div>
            </div>
        );
    }
}

const CalendarEvent = React.memo(({ event }) => {
    if(event.type === 'userEvent') {
        return (
            <div>
                <p><strong>{event.title}</strong></p>
                <p className='user-event-display'>
                    {
                        event.scope === 'google' ?
                            <img src="../../../stylesheets/assets/google-icon.svg" className="logo" />
                            :
                            <img src="../../../stylesheets/assets/outlook-icon.svg" className="logo" />
                    }
                    {event.calendarSummary}</p>
            </div>
        );
    }
    return (
        <div>
            <p><strong>{event.title}</strong></p>
            <p className="path">{event.path}</p>
        </div>
    );
});

const EventWrapper = React.memo((props) => {

    return (
        <div className={classnames(props.event.type+'-slot', {isPast: props.event.isPast})} onClick={()=> {
            if(props.event.type === 'task'){
                browserHistory.push('/dashboard/' + props.event.itemId);
            }
            else if(props.event.type === 'event'){
                browserHistory.push('/dashboard/event/' + props.event.itemId);
            }else if(props.event.type === 'userEvent'){
                browserHistory.push('/dashboard/userEvents/' + props.event.itemId);
            }
        }}>
            {props.children}
        </div>
    );
});

const TaskCard = withCustomErrorBoundary(({task, masonry, userId, path, skillName})=>{
    const intl = useIntl();
    const dispatch = useDispatch();
    const { isSyncAppTask } = useSyncData(task.id);

    const getTimeWorkedInSeconds = useCallback(() => {
        const startAt = task.timerStart ?? task.startedWorkingAt;
        const endAt = task.timerPause ?? moment();
        return getTimeDurationInSeconds(startAt, endAt);
    }, [task.timerStart, task.timerPause, task.startedWorkingAt]);

    const timeWorkedInSeconds = getTimeWorkedInSeconds();

    const [changeTimer, setChangeTimer] = useState(false);
    const [changeHours, setChangeHours] = useState(0);
    const [changeMinutes, setChangeMinutes] = useState(0);
    const {
        days,
        hours,
        minutes,
        seconds,
        pause: pauseStopwatch,
        start: startStopwatch,
        reset: resetStopwatch,
    } = useStopwatch({
        offsetTimestamp: moment().add(timeWorkedInSeconds, 'seconds'),
        autoStart: !task.timerPause
    });

    const totalHours = (days * DURATION_DAY_HOURS) + hours;

    const isTaskInProgress = useMemo(() => task.status === 'inprogress', [task.status]);

    useEffect(() => {
        const newOffsetTimestamp = moment().add(timeWorkedInSeconds, 'seconds');
        resetStopwatch(newOffsetTimestamp, !task.timerPause);
    }, [task.timerStart, task.timerPause, task.startedWorkingAt]);

    useEffect(()=>{
        if (!masonry || !masonry.current) {
            return;
        }

        masonry.current.masonry.reloadItems();
        masonry.current.masonry.layout();
    }, [task.status, task.timerPause, masonry]);

    useEffect(() => {
        const stopwatchAction = task.timerPause || !isTaskInProgress ? pauseStopwatch : startStopwatch;
        stopwatchAction();
    }, [task.timerPause, isTaskInProgress]);

    const handleClick = () => {
        if(isSyncAppTask){
            dispatch(appactions.selectSourceStatusModal({ show: true, taskId: task.id }));
        }
    };

    const formatNum = (number) => {
        return Math.round(number*100)/100;
    };

    const doBigClick = () => {
        dispatch(appactions.setWorkManager({
            taskId: task.id,
            userId: userId,
            previousState: task.status,
            newState: (task.status === 'todo') ? 'inprogress' : 'todo'
        }));
    };

    const saveTimer = () => {
        const timerStartDate = moment()
            .subtract(parseInt(changeHours), 'hours')
            .subtract(parseInt(changeMinutes), 'minutes')
            .format();

        const timerPauseDate = task.timerPause ? moment().format() : null;

        firebaseEvents.saveTimer(task.id, timerStartDate, timerPauseDate).then(() => {
            setChangeTimer(false);
        });
    };

    const openChangeTimer = () => {
        setChangeTimer(true);
        setChangeHours(totalHours);
        setChangeMinutes(minutes);
    };

    const pauseTimer = () => {
        const timerStartDate = moment().subtract(parseInt(timeWorkedInSeconds), 'seconds').format();
        const timerPauseDate = moment().format();

        firebaseEvents.saveTimer(task.id, timerStartDate, timerPauseDate).then(() => {
            setChangeTimer(false);
            pauseStopwatch();
        });
    };

    const playTimer = () => {
        const stopWatchStartedAt = moment().subtract(parseInt(timeWorkedInSeconds), 'seconds').format();

        firebaseEvents.saveTimer(task.id, stopWatchStartedAt, null).then(() => {
            setChangeTimer(false);
            startStopwatch();
        });
    };

    const openTaskFromPlate = () => {
        browserHistory.push('/dashboard/' + task.id);
    };

    var bigIcon = <PlayIcon />,
        bigTitle = 'Start working',
        bigColor= 'default',
        bigSize = 'small',
        markDone = null,
        stopTask = null,
        dueDate = null,
        bigClick = doBigClick;

    if(task.status === 'inprogress'){
        bigIcon = <PauseIcon />;
        bigTitle = 'Pause working';
        bigClick = pauseTimer;

        if(task.timerPause){
            bigIcon = <PlayIcon />;
            bigTitle = 'Restart working';
            bigClick = playTimer;
        }

        bigColor= 'secondary';
        bigSize = 'medium';
        markDone = (
            <Tooltip title="Mark as done">
                <Fab disabled={isSyncAppTask} size="small" className="markDoneBtn" color="primary" onClick={()=>dispatch(appactions.setWorkManager({
                    taskId: task.id,
                    userId: userId,
                    previousState: task.status,
                    newState: 'done'
                }))}><DoneIcon /></Fab>
            </Tooltip>
        );
        stopTask = (
            <Tooltip title="Stop working">
                <Fab disabled={isSyncAppTask} size="small" className="stopBtn" color="primary" onClick={doBigClick}><StopIcon /></Fab>
            </Tooltip>
        );

        if(task.deadline){
            dueDate = (
                <dl className={classnames({
                    danger: moment(task.deadline).isBefore(moment('23:59', 'HH:mm')),
                    warning: moment(task.deadline).isBefore(moment('23:59', 'HH:mm').add(1,'days'))
                })}>
                    <dt>{intl.formatMessage({id:'Due date'})}:</dt>
                    <dt>{moment(task.deadline).calendar(null, {
                        sameDay: '[Today]',
                        nextDay: '[Tomorrow]',
                        nextWeek: '[Next] dddd',
                        lastDay: '[Yesterday]',
                        lastWeek: '[Last] dddd',
                        sameElse: 'MMMM Do'
                    })}</dt>
                </dl>
            );
        }
    }

    return (
        <Card variant="outlined" className={classnames('taskCard', {inprogress: (task.status === 'inprogress')})}>
            <CardContent>
                <div onClick={handleClick} className={classnames('timer', {syncType: isSyncAppTask})}>
                    <div className="controls">
                        {stopTask}
                        <Tooltip title={bigTitle}>
                            <Fab disabled={isSyncAppTask} className={'pausePlayBtn'} size={bigSize} color={bigColor} onClick={bigClick}>{bigIcon}</Fab>
                        </Tooltip>
                        {markDone}
                    </div>
                    {!isSyncAppTask &&
                        <div className="counter">
                            <Tooltip title="Click to change">
                                <div onClick={openChangeTimer}><i>{totalHours}h</i> <i>{minutes}m</i> <i>{seconds}s</i></div>
                            </Tooltip>
                        </div>
                    }
                </div>
                <div className="taskCardDetails" >
                    <div onClick={openTaskFromPlate}>
                        {path && <h2 className='path'>{path}</h2>}
                        <h1>
                            {(task && task.title)?task.title:''}
                            {isSyncAppTask && task?.syncAppType && (<SyncProjectLogo toolName={task?.syncAppType} fromViewMode={'NextTasks'} task={task}/>)}
                        </h1>
                        <ul>
                            <li>
                                <dl>
                                    <dt>{intl.formatMessage({id:'Skill'})}:</dt>
                                    <dt>{skillName}</dt>
                                </dl>
                                <dl>
                                    <dt>{intl.formatMessage({id:'Effort'})} (h):</dt>
                                    <dt>{formatNum(task.minEffort)} - {formatNum(task.maxEffort)}</dt>
                                </dl>
                                {dueDate}
                            </li>
                        </ul>
                    </div>

                    <Dialog
                        className="changeTimer"
                        open={changeTimer}
                        onClose={()=>setChangeTimer(false)}
                        maxWidth={false}
                    >
                        <DialogTitle>
                            {intl.formatMessage({id:'Set time spent to'})}
                        </DialogTitle>
                        <DialogContent className="content">
                            <TextField
                                type="number"
                                value={changeHours}
                                inputProps={{min:0}}
                                onChange={(e)=>setChangeHours(e.target.value)}
                            />
                            <span>{intl.formatMessage({id:'hours'})}</span>
                            <TextField
                                type="number"
                                value={changeMinutes}
                                inputProps={{min:0,max:59}}
                                onChange={(e)=>setChangeMinutes(e.target.value)}
                            />
                            <span>{intl.formatMessage({id:'minutes'})}</span>
                        </DialogContent>

                        <DialogActions className="actions">
                            <Button variant="contained" onClick={()=>setChangeTimer(false)}>{intl.formatMessage({id:'Cancel'})}</Button>
                            <Button variant="contained" onClick={saveTimer} color="primary">{intl.formatMessage({id:'Save'})}</Button>
                        </DialogActions>

                    </Dialog>
                </div>
                <Tooltip title="See task details">
                    <IconButton className="openBtn" onClick={()=>browserHistory.push('/dashboard/' + task.id)}>
                        <OpenIcon />
                    </IconButton>
                </Tooltip>
            </CardContent>
        </Card>
    );
});

const NextTasks = ()=>{
    const intl = useIntl();
    const dispatch = useDispatch();

    const userUserId = useSelector(getUserId);
    const userTimeFormat = useSelector(getUserTimeformat);
    const userTimezone = useSelector(getUserTimezone);
    const userEvents = useSelector(getUserEvents);
    const tasks = useSelector(getAccountTasks);
    const skills = useSelector(getAccountSkills);
    const accountId = useSelector(getAccountId);
    const loadersTasks = useSelector(getLoadersTasks);
    const loadersUsers = useSelector(getLoadersUsers);
    const loadersSkills = useSelector(getLoadersSkills);
    const orderedTaskList = useSelector(getOrderedTaskList);

    const getShowCalendar = () => {
        return (localStorage.getItem('showDashboardCalendar') && localStorage.getItem('showDashboardCalendar') === 'true');
    };

    const [showCalendar, setShowCalendar] = useState(getShowCalendar());
    const [calendarDay, setCalendarDay] = useState(moment().tz(userTimezone || moment.tz.guess()).toDate());

    useEffect(()=>{
        setCalendarDay(moment().tz(userTimezone || moment.tz.guess()).toDate());
    }, [userTimezone]);


    const getTaskPath = (taskId) => {
        if(tasks && tasks[taskId] && tasks[taskId].path){
            return tasks[taskId].path.join(' / ');
        }
        return null;
    };

    const getMyPlate = () => {
        if(loadersTasks || loadersUsers || loadersSkills) {
            return (
                <CardContent>
                    <Loader fullScreen={false} show={true} />
                </CardContent>
            );
        }

        if(!showCalendar){
            if(tasks && userUserId){
                var listInProgress = [], listTodo = [];
                const tasksFromState = tasks;
                var taskList = orderedTaskList || [];
                taskList.forEach(({ taskId })=>{
                    const task = tasksFromState[taskId];
                    if(task){
                        if(typeof task.userWorking === 'string'){
                            task.userWorking = [task.userWorking];
                        }

                        if(
                            !task.childrens &&
                            task.canView &&
                            task.status !== 'done' &&
                            (
                                (task.estimations && task.estimations.userId && task.estimations.userId.indexOf(userUserId) !== -1) ||
                                (task.status === 'inprogress' && task.userWorking && task.userWorking.indexOf(userUserId) !== -1)
                            )
                        ){
                            if(task.status === 'inprogress'){
                                listInProgress.push(task);
                            }
                            else {
                                var priorityInt = task.priority || 0;
                                task.shouldStartAt = moment.tz(task.estimations.startAt, 'X', userTimezone).unix() - priorityInt;
                                listTodo.push(task);
                            }
                        }
                    }
                });

                listInProgress = _.sortBy(listInProgress, 'startedWorkingAt');
                listTodo = _.sortBy(listTodo, 'shouldStartAt').slice(0,5-listInProgress.length);

                var renderListInProgress = [], renderListTodo = [];
                listInProgress.forEach((t)=>{
                    renderListInProgress.push(
                        <TaskCard
                            task={t}
                            path={getTaskPath(t.id)}
                            skillName={(skills[t.skill])?skills[t.skill].name:''}
                            key={t.id}
                            userId={userUserId}
                        />
                    );
                });
                listTodo.forEach((t)=>{
                    renderListTodo.push(
                        <TaskCard
                            task={t}
                            path={getTaskPath(t.id)}
                            key={t.id}
                            userId={userUserId}
                        />
                    );
                });

                if(renderListInProgress.length + renderListTodo.length > 0){

                    return (
                        <React.Fragment>
                            <CardContent>
                                {renderListInProgress}
                                {renderListTodo}
                            </CardContent>
                            <CardActions>
                                <Button className='viewAllTasks' color="primary" fullWidth size="small" onClick={viewAssignedMe}>{intl.formatMessage({id:'View all tasks assigned to me'})}</Button>
                            </CardActions>
                        </React.Fragment>

                    );

                }
            }

            return (
                <CardContent>
                    <div className="allRead">
                        <img src="../../../stylesheets/assets/notasks.png?v=2" alt="No tasks" />
                        <span>Looks you have nothing to do...</span>
                        <Button onClick={()=>browserHistory.push('/tasks')}>START ADDING TASKS</Button>
                    </div>
                </CardContent>
            );
        }
        else {
            let list = [], earliest, latest;

            for(let taskId in tasks){
                let currTask = tasks[taskId];

                if(
                    currTask.estimations?.userId &&
                    currTask.estimations?.periods &&
                    currTask.estimations.userId.indexOf(userUserId) !== -1 &&
                    currTask.estimations.periods[userUserId]
                ){
                    currTask.estimations.periods[userUserId].forEach((period,index)=>{
                        let start = moment.tz(period.start, 'X', userTimezone || moment.tz.guess()),
                            end = moment.tz(period.end, 'X', userTimezone || moment.tz.guess());

                        if(
                            start.isSameOrAfter(moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(0).minutes(0).seconds(0)) &&
                            end.isSameOrBefore(moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(23).minutes(59).seconds(59)) &&
                            end.isSameOrAfter(moment().tz(userTimezone || moment.tz.guess()).subtract(1,'hour'))
                        ){
                            let isPast = false;
                            if(end.isSameOrAfter(moment().tz(userTimezone || moment.tz.guess()))){
                                if(!earliest || start.isBefore(earliest)){
                                    earliest = start;
                                }
                                if(!latest || end.isAfter(latest)){
                                    latest = end;
                                }
                            }
                            else {
                                isPast = true;
                            }

                            list.push({
                                id: 'task-' + taskId + '-' + index,
                                type:'task',
                                itemId: taskId,
                                start: start.toDate(),
                                end: end.toDate(),
                                title: currTask.title,
                                path: getTaskPath(taskId),
                                isPast
                            });
                        }
                    });

                }
            }

            if(userEvents) {
                userEvents.forEach(currEnv => {
                    let start = moment.tz(currEnv.start, 'X', userTimezone || moment.tz.guess()),
                        end = moment.tz(currEnv.end, 'X', userTimezone || moment.tz.guess());

                    list.push({
                        id: currEnv.id,
                        type:'userEvent',
                        itemId: currEnv.id,
                        start: start.toDate(),
                        end: end.toDate(),
                        title: currEnv.name,
                        path: '',
                        isPast: false,
                        isFreeTime: currEnv.isFreeTime,
                        calendarSummary: currEnv.calendarName || '',
                        scope: currEnv.scope || 'google',
                    });
                });
            }


            if(!earliest){
                earliest = moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(0).minutes(0).seconds(0);
            }
            else {
                earliest = earliest.subtract(1,'hour');
            }
            if(!latest){
                latest = moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(23).minutes(59).seconds(59);
            }

            if(earliest.isBefore(moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(0).minutes(0).seconds(0))){
                earliest = moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(0).minutes(0).seconds(0);
            }
            if(latest.isAfter(moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(23).minutes(59).seconds(59))){
                latest = moment.tz(calendarDay, userTimezone || moment.tz.guess()).hours(23).minutes(59).seconds(59);
            }

            earliest = earliest.minutes(0).seconds(0);
            moment.tz.setDefault(userTimezone || moment.tz.guess());
            const localizer = momentLocalizer(moment);

            return (
                <React.Fragment>
                    <CardContent>
                        <Calendar
                            className={classnames({empty: (list.length === 0) ,today: moment(calendarDay).isSame(moment().tz(userTimezone || moment.tz.guess()), 'day')})}
                            getNow={()=>moment().tz(userTimezone || moment.tz.guess()).toDate()}
                            events={list}
                            view={Views.DAY}
                            min={earliest.toDate()}
                            max={latest.toDate()}
                            date={calendarDay}
                            toolbar={true}
                            localizer={localizer}
                            timeslots={2}
                            startAccessor="start"
                            endAccessor="end"
                            popup={true}
                            formats={{
                                dayHeaderFormat: (date,culture,localizer)=>{
                                    if(moment(date).isSame(moment().tz(userTimezone || moment.tz.guess()), 'day')){
                                        return 'Today';
                                    }
                                    if(moment(date).isSame(moment().tz(userTimezone || moment.tz.guess()).add(1, 'day'), 'day')){
                                        return 'Tomorrow';
                                    }
                                    return localizer.format(date, 'dddd MMMM Do YYYY', culture);
                                },
                                dayFormat: (date,culture,localizer)=>localizer.format(date, 'dddd', culture),
                                timeGutterFormat: (date,culture,localizer)=>localizer.format(date, userTimeFormat || 'h:mm A', culture),
                                monthHeaderFormat: (date,culture,localizer)=>localizer.format(date, 'MMMM', culture),
                                eventTimeRangeFormat: ({start, end},culture,localizer)=>localizer.format(start, userTimeFormat || 'HH:mm', culture) + ' - ' + localizer.format(end, userTimeFormat || 'HH:mm', culture)
                            }}
                            onView={()=>{}}
                            onNavigate={(e)=>{
                                if(moment(e).isSameOrAfter(moment(), 'day')){
                                    setCalendarDay(moment(e).toDate());
                                }
                            }}
                            components={{
                                event: CalendarEvent,
                                eventWrapper: EventWrapper,
                                toolbar: CustomToolbar,
                            }}

                        />
                        {(list.length === 0)?(
                            <div className="calendar-empty-state allRead">
                                <img src="../../../stylesheets/assets/notasks.png?v=2" alt="No tasks" />
                                <span>{intl.formatMessage({id:'Looks there is no work for you that day'})}</span>
                                <Button onClick={()=>browserHistory.push('/tasks')}>START ADDING TASKS</Button>
                            </div>
                        ):null}


                    </CardContent>
                    <CardActions>
                        <Button className='expandedCalendar' color="primary" fullWidth size="small" onClick={()=>browserHistory.push('/calendar')}>{intl.formatMessage({id:'View expanded calendar'})}</Button>
                    </CardActions>

                </React.Fragment>
            );
        }
    };

    const viewAssignedMe = () => {
        dispatch(appactions.updateFilters({
            '0': {
                '0': {id: 'status', type: 'isnot', value: ['done']},
                '1': {id: 'assigned', type: 'is', value: [userUserId]},
                andor: 'and'
            },
            andor: 'and'
        }, accountId));
        browserHistory.push('/tasks?filtersOpen=true');
    };

    const showList = () => {
        localStorage.setItem('showDashboardCalendar', false);
        setShowCalendar(false);
    };

    const doShowCalendar = () => {
        localStorage.setItem('showDashboardCalendar', true);
        setShowCalendar(true);
    };

    return (
        <Card className="nextTasks" square={true}>
            <CardHeader title="What's On My Plate" action={
                <div>
                    <a onClick={showList} className={classnames('toggleOption', {active: !showCalendar})}>{intl.formatMessage({id:'LIST'})}</a>
                    <a onClick={doShowCalendar} className={classnames('toggleOption', {active: showCalendar})}>{intl.formatMessage({id:'CALENDAR'})}</a>
                </div>
            }  />
            {getMyPlate()}
        </Card>
    );

};

export default withCustomErrorBoundary(NextTasks);