import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';
import { getUserDateformat, getUserTimeformat } from '../../utils/selectors/user';
import useInternalization from '../../utils/hooks/useInternalization';

const MultiDelayDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [delayDate, setDelayDate] = useState();
    const userDateFormat = useSelector(getUserDateformat);
    const userTimeFormat = useSelector(getUserTimeformat);
    const dateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
    const { t } = useInternalization();

    const handleConfirm =() => {
        firebaseEvents
            .multiDelaydateChange(delayDate, selectedItems)
            .then(() => {
                setSelectedLines();
                setDelayDate(null);
            });

        showMultiDetails();
    };

    const handleChange = (e) => {
        setDelayDate(e);
    };

    const handleNo = () => {
        showMultiDetails();
    };

    return   <div id="multiDelayDetails">
        <label>Set tasks to wait until</label>
        <DateTimePicker
            variant="inline"
            className="multiDelayPicker"
            format={dateTimeFormat}
            margin="normal"
            placeholder={t('Select a date')}
            value={delayDate}
            onChange={handleChange}
            autoOk
            fullWidth
            PopoverProps={{ className: 'multiDatePicker' }}
        />
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
            Cancel
        </Button>
    </div>;
};

export default MultiDelayDetails;