import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useIntl } from 'react-intl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';


const NotificationsHubHeader = ({ handleChange, value, handleChangeSwitch, switchValue }) => {
    const intl = useIntl();

    return <div  className="Notifications-Header">
        <Tabs className="Tabs" value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={intl.formatMessage({id: 'All'})} id="all" value="all" />
            <Tab label={intl.formatMessage({id: '@Mentions'})} id="mentions" value="mentions" />
        </Tabs>
        <div className="Switch">
            <FormControlLabel
                control={
                    <Switch
                        size='small'
                        checked={switchValue}
                        onChange={handleChangeSwitch}
                        color="secondary"
                    />
                }
                label={intl.formatMessage({id: 'Only show unreaded'})}
            />
        </div>
    </div>;
};

export default React.memo(withCustomErrorBoundary(NotificationsHubHeader));