import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { checkTaskAgainstFiltersNew, checkTaskAgainstLimit, getTaskPathArrow } from '../../utils/utils';
import * as firebaseEvents from '../../utils/firebaseEvents';
import classnames from 'clsx';
import Tooltip from 'rc-tooltip';
import _ from 'underscore';
import $ from 'jquery';
import moment from 'moment-timezone';
import Sortable from 'sortablejs';
import OpenTaskIcon from '@material-ui/icons/OpenInNew';
import DangerIcon from '@material-ui/icons/Warning';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import { tracking } from '../../utils/utils';
import NotInMobile from '../NotInMobile/NotInMobile';
import NoTaskFound from '../../components/NoTaskFound/NoTaskFound';
import { getFilters, getLimitViewTo, getOrderedTaskList, getParentChildIds, getQuickSearch } from '../../utils/selectors/app';
import { getAccountBoards, getAccountCustomFields, getAccountId, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { getUserId, getUserTimezone } from '../../utils/selectors/user';
import { setWorkManager } from '../App/AppActions';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';
import { toastr } from 'react-redux-toastr';

const Board = ({children})=>{
    const intl = useIntl();
    const dispatch = useDispatch();

    const parentChildIds = useSelector(getParentChildIds);
    const orderedTaskList = useSelector(getOrderedTaskList);
    const filters = useSelector(getFilters);
    const quickSearch = useSelector(getQuickSearch);
    const accountId = useSelector(getAccountId);
    const userId = useSelector(getUserId);
    const timezone = useSelector(getUserTimezone);
    const boards = useSelector(getAccountBoards);
    const tasks = useSelector(getAccountTasks);
    const users = useSelector(getAccountUsers);
    const limitViewTo = useSelector(getLimitViewTo);
    const customFields = useSelector(getAccountCustomFields);

    const [amountPerCol, setAmountPerCol] = useState(25);
    const [sortableList, setSortableList] = useState([]);
    let currentDropBoard = null;

    useEffect(()=>{
        tracking('Saw Board View');
    }, []);


    const destroySortables = () => {
        sortableList.forEach((sortable=>{
            if(sortable.el){
                sortable.destroy();
            }
        }));
        setSortableList([]);
    };

    const createSortables = () => {
        let list = [];

        for(var i in Array.from(document.getElementsByClassName('tasksContainer'))){

            var sortable = Sortable.create(document.getElementsByClassName('tasksContainer')[i], {
                group: 'board', 
                onMove:(evt)=>{
                    $('.tasksContainer').removeClass('draggingTo');
                    const taskId = evt.dragged.getAttribute('data-taskid');
                    const taskData = orderedTaskList.find(el=>el.id === taskId);
                    
                    if(taskData?.syncAppType) {
                        toastr.error('Manage the sync task status from the details panel');
                        return false;
                    }

                    if(evt.from !== evt.to){
                        $(evt.to).addClass('draggingTo');
                        currentDropBoard = evt.to.getAttribute('data-boardid');
                    }
                    else {
                        currentDropBoard = null;
                    }
                    return false;
                },
                onEnd: (evt)=>{
                    $('.tasksContainer').removeClass('draggingTo');
                    var taskId = evt.item.getAttribute('data-taskid');


                    firebaseEvents.addChallenge('boardChangeCol');

                    if(currentDropBoard){
                        var fromBoardStatus,
                            currentDropBoardStatus = 'todo';

                        if(
                            currentDropBoard === 'inprogress' ||
                            (
                                currentDropBoard !== 'todo' && 
                                currentDropBoard !== 'done' && 
                                boards[currentDropBoard].status !== 'todo'
                            )
                        ){
                            currentDropBoardStatus = 'inprogress';
                        }

                        if(evt.from.getAttribute('data-boardid') === 'done'){
                            fromBoardStatus = 'done';
                        }
                        else if(evt.from.getAttribute('data-boardid') === 'todo'){
                            fromBoardStatus = 'todo';
                        }
                        else if(evt.from.getAttribute('data-boardid') === 'inprogress'){
                            fromBoardStatus = 'inprogress';
                        }
                        else {
                            if(!boards[evt.from.getAttribute('data-boardid')] || boards[evt.from.getAttribute('data-boardid')].status !== 'todo'){
                                fromBoardStatus = 'inprogress';
                            }
                            else {
                                fromBoardStatus = 'todo';
                            }
                        }

                        if(currentDropBoard === 'done' || currentDropBoard === 'todo'){
                            
                            if((fromBoardStatus === 'todo' || fromBoardStatus === 'done') && currentDropBoard === 'todo'){
                                firebaseEvents.updateTaskStatus(accountId, taskId, currentDropBoard, false, null, currentDropBoard);
                            }
                            else {
                                dispatch(setWorkManager({
                                    taskId: taskId,
                                    userId: userId,
                                    previousState: fromBoardStatus,
                                    newState: currentDropBoard,
                                    boardId: currentDropBoard
                                }));
                            }
                        }
                        else if(currentDropBoardStatus === 'inprogress' && (fromBoardStatus === 'done' || fromBoardStatus === 'todo')) {
                            if(tasks[taskId].nbWorkers > 1){
                                dispatch(setWorkManager({
                                    taskId: taskId,
                                    userId: userId,
                                    previousState: fromBoardStatus,
                                    newState: currentDropBoardStatus,
                                    boardId: currentDropBoard
                                }));
                            }
                            else {
                                browserHistory.push('/board/startWorking/' + taskId + '/' + currentDropBoard);
                            }
                        }
                        else if(fromBoardStatus === 'inprogress' && (currentDropBoardStatus === 'done' || currentDropBoardStatus === 'todo')) {
                            
                            dispatch(setWorkManager({
                                taskId: taskId,
                                userId: userId,
                                previousState: fromBoardStatus,
                                newState: currentDropBoardStatus,
                                boardId: (currentDropBoard!=='inprogress')?currentDropBoard:currentDropBoardStatus
                            }));
                        }
                        else {
                            firebaseEvents.updateTaskBoard(accountId, taskId, currentDropBoard);
                        }
                    }

                    currentDropBoard = null;
                }
            });
            
            list.push(sortable);
        }
        setSortableList(list);
    };

    useEffect(()=>{
        destroySortables();
        createSortables();

        return function cleanup(){
            destroySortables();
        };

    }, [accountId, boards, tasks]);


    const getUserAvatar = (userId) => {
        if(!userId) return <Tooltip placement="right" overlay={intl.formatMessage({id:'Not defined'})} className="avatar">
            <Avatar className="emptyAvatar">?</Avatar>
        </Tooltip>;

        if(typeof userId === String){
            userId = [userId];
        }

        let list = [];

        userId.forEach(user=>{
            let displayName = null;

            if(users[user] && users[user].displayName){
                displayName = users[user].displayName;
            }

            list.push(<Tooltip key={user} placement="right" overlay={displayName} className="avatar">
                <StateUserAvatar 
                    className="avatar"
                    userId={user}
                />
            </Tooltip>);
        });

        return list;

    };

    const getRawTaskList = () => {
        if(tasks){
            var taskList = [];
            var list = orderedTaskList;
            var parentIds = parentChildIds.parents;

            _.each(list, (task)=>{
                if(!task.childrens && task.canView){
                    taskList.push({
                        ...task,
                        ...tasks[task.id],
                    });
                }
            });

            // reduce tasklist depending on filters
            var filteredTaskList = [];
            _.each(taskList, (task)=>{
                if(checkTaskAgainstLimit(task, null, parentIds, limitViewTo) && checkTaskAgainstFiltersNew(quickSearch, filters, task, {tasks: tasks}, null, ['status', 'tasktype'], null, customFields)){
                    filteredTaskList.push(task);
                }
            });

            return _.groupBy(filteredTaskList, (task)=>{
                if(task.status === 'done'){
                    return task.status;
                }
                else if(task.board === 'inprogress' || task.board === 'todo'){
                    return task.board;
                }
                else if(task.board){
                    return task.board;
                }
                return task.status;
            });
        }

        return [];

    };

    const formatPriority = (priority) => {
        if(priority && priority === 1){
            return intl.formatMessage({id:'Medium'});
        }
        else if(priority && priority === 2){
            return intl.formatMessage({id:'High'});
        }
        else if(priority && priority === 3){
            return intl.formatMessage({id:'Urgent'});
        }
        return intl.formatMessage({id:'Normal'});
    };

    const getWarnings = (taskAtRisk, taskAtDanger, taskNoSkill, taskNoEffort) => {

        if(taskAtDanger){
            return (
                <Tooltip placement="right" overlay={intl.formatMessage({id:'deadline.danger'})}>
                    <DangerIcon className="DangerIcon" />
                </Tooltip>
            );
        }
        else if(taskAtRisk){
            return (
                <Tooltip placement="right" overlay={intl.formatMessage({id:'deadline.risk'})}>
                    <DangerIcon className="RiskIcon" />
                </Tooltip>
            );
        }
        else if(taskNoSkill){
            return (
                <Tooltip placement="right" overlay={intl.formatMessage({id:'task.risk.noskill'})}>
                    <DangerIcon className="MissingIcon" />
                </Tooltip>
            );
        }
        else if(taskNoEffort){
            return (
                <Tooltip placement="right" overlay={intl.formatMessage({id:'task.risk.noeffort'})}>
                    <DangerIcon className="MissingIcon" />
                </Tooltip>
            );
        }
        return null;
    };

    const handleRowClick = (e)  => {
        browserHistory.push(`/board/${e.currentTarget.getAttribute('data-taskid')}`); 
    };

    const renderTasks = (taskList, isDone = false) => {
        var list = [];

        if(isDone){
            taskList = _.sortBy(taskList, 'doneAt').reverse();
        }
        else {
            taskList = _.sortBy(taskList, (currTask)=>{
                if(currTask.status !== 'todo' && currTask.startedWorkingAt){
                    return moment(currTask.startedWorkingAt).unix;
                }
                if(currTask.estimations && currTask.estimations.startAt){
                    return currTask.estimations.startAt;
                }
                return '2999-12-31';
            });
        }


        for(var i in taskList){
            if(i < amountPerCol){
                var userId = null;

                if(taskList[i].status === 'inprogress'){
                    userId = taskList[i]?.userWorking;
                }
                else if(taskList[i].status !== 'done'){
                    if(!taskList[i].nbWorkers || taskList[i].nbWorkers === 1){
                        if(taskList[i].forcedUser){
                            userId = taskList[i].forcedUser;
                        }
                        else if(taskList[i].estimations && taskList[i].estimations.userId){
                            userId = taskList[i].estimations.userId;
                        }
                    }
                    else {
                        if(taskList[i].forcedUser && taskList[i].forcedUser.length === taskList[i].nbWorkers){
                            userId = taskList[i].forcedUser;
                        }
                        else if(taskList[i].estimations && taskList[i].estimations.userId){
                            userId = taskList[i].estimations.userId;
                        }
                    }
                }
                else if(taskList[i].doneBy){
                    userId = taskList[i].doneBy;
                }
                else if(taskList[i].userWorking){
                    userId = taskList[i].userWorking;
                }

                if(typeof userId === 'string'){
                    userId = [userId];
                }

                var taskAtDanger = false,
                    taskAtRisk = false,
                    taskNoSkill = (!taskList[i].skill),
                    taskNoEffort = ((!taskList[i].minEffort && !taskList[i].maxEffort) || (taskList[i].minEffort === '0.00' && taskList[i].maxEffort === '0.00'));

                if(
                    taskList[i].status !== 'done' && 
                    taskList[i].deadline && taskList[i].estimations && taskList[i].estimations.expectedAt &&
                    moment.tz(taskList[i].deadline, 'YYYY-MM-DD', timezone || moment.tz.guess()).isBefore(moment.tz(taskList[i].estimations.expectedAt, 'X', timezone || moment.tz.guess()))
                ){
                    taskAtDanger = true;
                }
                else if(
                    taskList[i].status !== 'done' && 
                    taskList[i].deadline && taskList[i].estimations && taskList[i].estimations.endAt &&
                    moment.tz(taskList[i].deadline, 'YYYY-MM-DD', timezone || moment.tz.guess()).isBefore(moment.tz(taskList[i].estimations.endAt, 'X', timezone || moment.tz.guess()))
                ){
                    taskAtRisk = true;
                }

                var warning = getWarnings(taskAtRisk, taskAtDanger, taskNoSkill, taskNoEffort);
                var title = <div>{warning} {taskList[i].title}</div>;

                list.push(
                    <Card
                        data-sidebar='preventClose' 
                        onClick={handleRowClick} 
                        elevation={0} 
                        key={taskList[i].id} 
                        className={classnames('card', {activeOnSidebar: window.location.pathname === `/board/${taskList[i].id}` })} 
                        data-taskid={taskList[i].id}
                    >
                        <CardHeader
                            data-sidebar='preventClose'
                            title={title}
                            subheader={getTaskPathArrow(taskList[i].id, tasks)}
                            avatar={getUserAvatar(userId)}
                        />
                        <Button className='openTaskBtn' data-taskid={taskList[i].id} onClick={handleRowClick}>
                            <OpenTaskIcon className="OpenTaskIcon" />
                        </Button>
                        <div className="cardPriority">
                            <Tooltip placement="left" overlay={intl.formatMessage({id:'Priority'}) + ': ' + formatPriority(taskList[i].priority)}><div className={'priorityBubble priority-'+taskList[i].priority} /></Tooltip>
                        </div>
                    </Card>
                );

            }
        }

        if(taskList && taskList.length > amountPerCol){
            list.push(
                <Button
                    key="loadMoreBtn"
                    className="loadMoreBtn" 
                    color="primary"
                    fullWidth={true} 
                    onClick={()=>setAmountPerCol(amountPerCol+25)}
                >
                    {intl.formatMessage({id:'Load more...'})}
                </Button>
            );
        }

        return list;
    };

    if(accountId){

        var taskList = getRawTaskList(),
            boardsData = {...boards};

        for(var j in boardsData){
            boardsData[j].key = j;
        }

        boardsData = _.sortBy(boardsData, 'index');

        var boardList = [
            <div key="todo"><h1>To be done</h1><div className="tasksContainer" data-boardid="todo" data-sidebar='preventClose'>{renderTasks(taskList.todo)}</div></div>
        ];
        for(var i in boardsData){
            boardList.push(<div key={boardsData[i].key}><h1>{boards[boardsData[i].key].name}</h1><div className="tasksContainer" data-boardid={boardsData[i].key} data-sidebar='preventClose'>{renderTasks(taskList[boardsData[i].key])}</div></div>);
        }
        boardList.push(<div key="done"><h1>Done</h1><div className="tasksContainer" data-boardid="done" data-sidebar='preventClose'>{renderTasks(taskList.done, true)}</div></div>);


        return (
            <div className={classnames('Board', {empty: !orderedTaskList || orderedTaskList.length === 0})}>

                <div className="BoardContainer">

                    {!orderedTaskList || orderedTaskList.length === 0 ? (
                        <NoTaskFound />
                    ) : (
                        <div className="BoardContent">
                            {boardList}
                        </div>
                    )}

                </div>

                {children}

                <NotInMobile />
                
            </div>
        );

    }

    return null;


}; 

export default withCustomErrorBoundary(Board);