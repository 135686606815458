import _ from 'underscore';
import { getBasecampInfo } from './basecamp';
import { getClickUpInfo } from './clickup';
import { getJiraInfo } from './jira';
import { getTrelloInfo } from './trello';
import { getAsanaInfo } from './asana';
import { SYNC_APP_TYPE } from '../../../../views/Integrations/utils';
import { isTaskInsideSyncFolder } from '../utils';

const syncAppInfoMapper = {
    [SYNC_APP_TYPE.BASECAMP]: getBasecampInfo,
    [SYNC_APP_TYPE.JIRA]: getJiraInfo,
    [SYNC_APP_TYPE.JIRASERVER]: getJiraInfo,
    [SYNC_APP_TYPE.CLICKUP]: getClickUpInfo,
    [SYNC_APP_TYPE.ASANA]: getAsanaInfo,
    [SYNC_APP_TYPE.TRELLO]: getTrelloInfo,
};

const getSpecificSyncAppData = (syncAppType, { task, parentsCount, isInsideSyncFolder, importSubtasks }) => {
    const infoFunction = syncAppInfoMapper[syncAppType];
    return infoFunction ? infoFunction({ task, parentsCount, isInsideSyncFolder, importSubtasks }) : {};
};

const getSyncAppData = (taskId, stateTasks, parents, syncApps) => {
    if (!taskId) {
        return {};
    }

    const task = stateTasks[taskId];
    const isSyncAppTask = isInsideSyncFolder || !!task?.syncAppType;
    const isInsideSyncFolder = isTaskInsideSyncFolder(task, stateTasks, parents);
    const parentsCount = parents.length;
    const isRootTask = !parentsCount;

    const connectedFolder = task?.isConnectProject ? task : parents
        .map(parentId => stateTasks[parentId])
        .find(parent => parent?.isConnectProject);

    const syncAppId = isRootTask ? task?.syncAppId : connectedFolder?.syncAppId;
    const syncApp = _.get(syncApps, syncAppId, {});
    const syncAppType = _.get(syncApp, 'type', _.get(task, 'syncAppType', ''));
    const syncFolders = _.get(syncApp, 'syncFolder', {});
    const syncFolder = Object.values(syncFolders).find(folder => folder.sourceId === connectedFolder?.sourceId);
    const importSubtasks = _.get(syncFolder, 'importSubtasks', false);

    const specificSyncAppData = getSpecificSyncAppData(syncAppType, {
        task,
        parentsCount,
        isInsideSyncFolder,
        importSubtasks,
    });

    return {
        isSyncAppTask,
        isInsideSyncFolder,
        syncFolder,
        syncApp,
        syncAppType,
        currentTask: task,
        ...specificSyncAppData
    };
};

export default getSyncAppData;