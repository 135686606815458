import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import {toastr} from 'react-redux-toastr';

import Button from '../../components/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

import { setCalendarSettings } from '../../utils/firebaseEvents';
import { 
    disconectCalendar,
    resyncCalendar
} from '../../utils/calendarIntegration';
import Selectors from '../../utils/selectors';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const CalendarConfig = ({ connectedAccountName, connectedAccountId,  calendar, isOpen, handleClose, calendarScope}) =>  {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false); 
    const [sharedWith, setSharedWith]  = useState([]);
    const currentUserId = useSelector(Selectors.userSelector.getUserId);
    const currentAccountId = useSelector(Selectors.userSelector.getUserAccountId);
    const userCalendarsConfigs = useSelector(Selectors.userSelector.getUserCalendars);
    const accountTeamMembers = useSelector(Selectors.accountSelector.getAccountUsers);
    const t = (id, values) =>  {
        return intl.formatMessage({id}, values);
    };

    useEffect(()=> {
        if(accountTeamMembers && calendar && userCalendarsConfigs && userCalendarsConfigs[calendar.refId]?.sharedWith){
            setSharedWith(userCalendarsConfigs[calendar.refId].sharedWith.map(el => accountTeamMembers[el]));
        }
    }, [calendar, accountTeamMembers, userCalendarsConfigs]);

    const handleAutoComplete = (_, values) => {
        setCalendarSettings(
            { 
                accountId: currentAccountId,
                userId: currentUserId,
                calendarRef: calendar.refId,
                values: values.map(el => el.userId),
                accountRef: calendar.accountRef
            }
        );
        return setSharedWith(values);
    };

    const handleSyncCalendar = async () => {
        try{
            setIsLoading(true);
            await resyncCalendar({
                userId: currentUserId,
                
                accountId: currentAccountId,
                calendarId: calendar.id
            });
        }catch(err){
            toastr.error(t('Something went wrong'));
        }
        setIsLoading(false);
    };

    const handleDisconnectCalendar = async () => {
        try{
            setIsLoading(true);
            await disconectCalendar({
                userId: currentUserId,
                accountId: currentAccountId,
                calendarId: calendar.id,
                email: connectedAccountId
            });
            handleClose();
        }catch(err){
            toastr.error(t('Something went wrong'));
        }
        setIsLoading(false);
    }; 

    const users = React.useMemo(() => !accountTeamMembers ? [] : Object.values(accountTeamMembers).filter(el => el.userId !== currentUserId), [accountTeamMembers, currentUserId]);
    const selectedUsers = sharedWith;
    return <Dialog maxWidth={'lg'}
        className="CalendarConfig" 
        onClose={handleClose}
        aria-labelledby="customized-dialog-title" 
        open={isOpen}>
        <MuiDialogTitle className="dialog-head " disableTypography >
            <div className="icon">
                <img src={`../../../stylesheets/assets/${calendarScope}-icon.svg`} className="logo" />
            </div>
            <div className="title-container">
                <div className="account-name">{ connectedAccountName } </div>
                <div className="calendar-name">{ calendar.summary } </div>
            </div>        
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers>
            <div>
            
            </div>
            <FormControl fullWidth className="buttons-container" component="fieldset">
                <Autocomplete
                    fullWidth
                    value={selectedUsers}
                    disabled={isLoading}
                    onChange={handleAutoComplete}
                    filterSelectedOptions={true}
                    multiple
                    className="autocomplete owners"
                    id="users-To"
                    options={users}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const props = getTagProps({ index });

                            return (
                                <Chip
                                    avatar={<StateUserAvatar 
                                        className="avatar"
                                        userId={option.userId}
                                    />}
                                    variant="outlined"
                                    label={option.displayName}
                                    {...props}
                                />
                            );
                        })
                    }
                    filterOptions={(options, {inputValue})=>{
                        return options.filter((o)=> {
                            return !inputValue || accountTeamMembers[o] && accountTeamMembers[o].displayName.search(new RegExp(encodeURIComponent(inputValue), 'gi')) !== -1;
                        });
                    }}
                    getOptionLabel={(option) => <div key={option.displayName} className="optionUser">
                        <StateUserAvatar 
                            className="avatar"
                            userId={option.userId}
                        />
                        {option.displayName} </div>}
                    renderInput={(params) => <TextField {...params} label={intl.formatMessage({id: 'Has access to:'})} variant="outlined" />}
                />
            </FormControl>
            <div className="config-actions">
                <Button className="resync"  disabled={isLoading} isLoading={isLoading} onClick={handleSyncCalendar} >
                    {intl.formatMessage({id:'RE-SYNC Events'})}
                </Button>
                <Button className="delete" isLoading={isLoading} disabled={isLoading} onClick={handleDisconnectCalendar} >
                    { intl.formatMessage({id:'Disconnect Caledar'}) } 
                </Button>
            </div>
        </MuiDialogContent>
    </Dialog>;
};


CalendarConfig.propTypes = {
    connectedAccountName: Proptypes.string.isRequired,
    connectedAccountId: Proptypes.string.isRequired,
    calendar: Proptypes.shape({
        id: Proptypes.string.isRequired,
        sharedWith: Proptypes.array,
        summary: Proptypes.string.isRequired,
    }).isRequired,
    isOpen: Proptypes.bool.isRequired,
    handleClose: Proptypes.func.isRequired,
}; 

export default CalendarConfig;