import React from 'react';
import { browserHistory } from 'react-router';
import Dexie from 'dexie';
import {  useIntl } from 'react-intl';
import { logOut } from '../../utils/firebaseEvents';
import { Button } from '@material-ui/core';

const ErrorPage = () => {   
    const intl = useIntl();
    const t = (id) => intl.formatMessage({ id });

    const handleGoBack = () => {
        const refreshCount = localStorage.getItem('error-refresh');
 
        if(parseInt(refreshCount|0) >= 1) { 
            logOut(intl);
            const idb = new Dexie('planless-cvs');
            idb.version(1).stores({
                csvFiles: 'key',
            });

            if(idb['csvFiles']){
                idb['csvFiles'].clear();
            }
            
            const planlessStore = new Dexie('planlessStore');
            planlessStore.version(1).stores({
                planlessStore: 'key',
            });
            if(planlessStore['planlessStore']){
                planlessStore['planlessStore'].clear();
            }
              
            localStorage.clear();
            browserHistory.push('/login');
            window.location.reload();
            return; 
        } 
        
        localStorage.setItem('error-refresh', parseInt(refreshCount|0) + 1);

        if(browserHistory.getCurrentLocation().pathname === '/dashboard') {
            browserHistory.push('/dashboard');
        } else {
            browserHistory.goBack();
        }

        window.location.reload(); 

        return;
    };

    return <div className='ErrorPage'>
        <span className='title'>{t('error-page-title')}</span>
        <span className='info-label'>{t('error-page-info')}</span>
        <Button onClick={handleGoBack} className='error-button'>{t('error-page-button')}</Button>
        <img src='../../../stylesheets/assets/planless-logo-text.png' />
    </div>;
};

export default ErrorPage;