import _ from 'underscore';
import OverViewCard from '../OverViewCard';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import DateDriftTable from './DateDriftTable';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Paper from '@material-ui/core/Paper';
import { getRelativePosition } from 'chart.js/helpers';
import classnames from 'clsx';
import { showLoader, hideLoader } from '../../../views/App/AppActions';
import {  useDispatch } from 'react-redux';
import Unautorized from '../../Unautorized/Unautorized';

import moment from 'moment-timezone';
import * as ChartJs from 'chart.js';
import SplitBy from '../SplitBy/SplitBy';
import { 
    isSameDate,
    getMonth,
    agregateData,
    getDataByTask,
    deepCloneDataSets,
    downloadAsImage,
    convertToDateTime,
    exportCSV, 
    getDateFormated
} from '../utils';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const backgroundColor = (color, chart) => ({p1: {parsed: { x }} }) => chart  && chart.data.labels[x] === 'Current'  ? undefined : color;
const borderDash = (chart) => ({p1: {parsed: { x }}}) => chart  && chart.data.labels[x] === 'Current' ? [6, 6] : undefined;

const getExpectedDates = (stateTasks, workspaceSelected, userTimezone) => {
    let maxExpected = moment().tz(userTimezone).subtract(1, 'year');
    let maxEarliest = moment().tz(userTimezone).subtract(1, 'year');
    let maxLastest = moment().tz(userTimezone).subtract(1, 'year');
    let maxDeadline = moment().tz(userTimezone).subtract(1, 'year');
    if(workspaceSelected === 'root'){
        Object.keys(stateTasks).forEach(taskKey => {
            const currentTask = stateTasks[taskKey];
            if(currentTask.estimations && currentTask.estimations.endAt){
                maxLastest = moment.tz(currentTask.estimations.endAt, 'X', userTimezone).isBefore(maxLastest) ? maxLastest : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
                maxEarliest = moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone).isBefore(maxEarliest) ? maxEarliest : moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone);
                maxExpected = moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone).isBefore(maxExpected) ? maxExpected : moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);
                maxDeadline = null;
            }
        });
    } else {
        const currentTask = stateTasks[workspaceSelected];
        if(currentTask.estimations && currentTask.estimations.endAt ){
            maxLastest = moment.tz(currentTask.estimations.endAt, 'X', userTimezone).isBefore(maxLastest) ? maxLastest : moment.tz(currentTask.estimations.endAt, 'X', userTimezone);
            maxEarliest = moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone).isBefore(maxEarliest) ? maxEarliest : moment.tz(currentTask.estimations.earliestAt, 'X', userTimezone);
            maxExpected = moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone).isBefore(maxExpected) ? maxExpected : moment.tz(currentTask.estimations.expectedAt, 'X', userTimezone);
            maxDeadline = !currentTask.deadline ? null : moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone).isBefore(maxDeadline) ? maxDeadline : moment.tz(currentTask.deadline, 'YYYY-MM-DD', userTimezone);
        }
    }
  
    return {
        lastest:  maxLastest,
        expected: maxExpected,
        earliest: maxEarliest,
        deadline: maxDeadline
    };
};


const DateDrift = ({ 
    workspaceData,
    datesRange,
    workspaceSelected, 
    stateTasks, 
    childsParents,
    hasAutorization,
    splitBy,
    setSplitBy,
    accountId,
    userTimezone
}) => {
    const tableColumnSize = 200; 
    const [currentData, setCurrentData] = useState({labels: [], datasets: {}});
    const [displayData, setDisplayData] = useState({labels: [], datasets: {}});
    const dateDriftCanvas = useRef(null);
    const [currentChart, setCurrentChart] = useState(null);
    const [csvData, setCSVData] = useState({});
    const expectedDates = React.useMemo(() => getExpectedDates(stateTasks, workspaceSelected, userTimezone), [stateTasks, workspaceSelected, userTimezone]);
    const dispatch = useDispatch();

    const config = {
        type: 'line',
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Expected finish date',
                    parentName: 'Expected finish date',
                    data: [],
                    borderColor: '#1E4FA2',
                    backgroundColor: '#1E4FA2',
                    borderWidth: 3,
                    pointRadius: 4,
                    pointBorderColor: '#fff',
                    pointBorderWidth: 1,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    labelType: 'expected',
                    id: 'expected'
                },
                {
                    label: 	'Earliest finish date',
                    parentName: 	'Earliest finish date',
                    data: [],
                    borderColor: '#29bee2',
                    borderWidth: 1,
                    backgroundColor: '#29bee2',
                    pointRadius: 2,
                    pointBorderColor: '#fff',
                    pointBorderWidth: 1,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    labelType: 'earliest',
                    id: 'earliest',
                },
                {
                    label: 	'Latest finish date',
                    parentName: 'Latest finish date',
                    data: [],
                    borderColor: '#F0562C',
                    backgroundColor: '#F0562C',
                    borderWidth: 1,
                    pointRadius: 2,
                    pointBorderColor: '#fff',
                    pointBorderWidth: 1,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    labelType: 'latest',
                    id: 'latest',
                },
                {
                    label:  'Due date',
                    parentName:  'Due date',
                    data: [],
                    borderColor: '#666',
                    backgroundColor: '#666',
                    borderWidth: 1,
                    pointRadius: 0,
                    pointBorderColor: '#fff',
                    pointBorderWidth: 1,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    labelType: 'duedate',
                    id: 'duedate',
                }
            ]
        },
        options: {
            scales: {
                y: {
                    ticks: {
                        maxTicksLimit: 10,
                        callback: function(value) {
                            return moment.unix(value).format('MMM D, YY');
                        },
                        color: '#999',
                        font: {
                            size: 11
                        }
                    }
                },
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxTicksLimit: 10,
                        callback: function(value) {
                            const labelValue = this.getLabelForValue(value);
                            if(moment(labelValue, 'YYYY', true).isValid()){
                                return labelValue;
                            }
                            else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                                return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                            }
                            else if(moment(labelValue, 'M/YYYY', true).isValid()){
                                return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                            }
                            
                            return `${moment(labelValue).format('MMM D, YY')}`;
                        },
                        color: '#999',
                        font: {
                            size: 11
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    fullSize: false,
                    labels: {
                        usePointStyle: true,
                        font: {
                            size: 11
                        },
                        boxWidth: 5,
                        boxHeight: 5
                    }
                },
                title: {
                    display: false,
                    text: '',
                },
                tooltip: {
                    callbacks: {
                        title: function(context){

                            if(moment(context[0].label, 'YYYY', true).isValid()){
                                return context[0].label;
                            }
                            else if(moment.utc(context[0].label, true).isValid()){
                                return `${moment.utc(context[0].label).format('MMM D, YY')}`;
                            }
                            else if(moment(context[0].label, 'M/YYYY', true).isValid()){
                                return `${moment(context[0].label, 'M/YYYY').format('MMMM YYYY')}`;
                            }
                        
                            return context[0].label;
                        },
                        label: function(context) {
                            return `${context.dataset.label}: ${moment.unix(context.parsed.y).format('MMM D, YY')}`;
                        }
                    },
                    cornerRadius: 0,
                    padding: 15,
                    usePointStyle: true,
                    backgroundColor: 'rgba(30,79,162, 0.9)',
                    bodyColor: '#fff',
                    borderColor: 'rgba(255,255,255,0.5)',
                    borderWidth: 1,
                    bodyFont: {
                        size:12
                    },
                    titleColor: '#fff',
                    titleFont: {
                        size:14
                    },
                    boxWidth: 8,
                    boxHeight: 8
                }
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
        },
    };

    useEffect(()=> {
        if(dateDriftCanvas && dateDriftCanvas.current) {
            const chart = new ChartJs.Chart(dateDriftCanvas.current.getContext('2d'), config);
            setCurrentChart(chart);
            chart.data.datasets[0].segment = {
                borderDash: borderDash(chart),
                backgroundColor: backgroundColor('red', chart, userTimezone),
            };
            chart.data.datasets[1].segment = {
                borderDash: borderDash(chart),
                backgroundColor: backgroundColor('blue', chart, userTimezone)
            };
            chart.data.datasets[2].segment = { 
                borderDash: borderDash(chart),
                backgroundColor: backgroundColor('yellow', chart, userTimezone),
            };
            const chartHoverHandler = (e) => { 
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
               
                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
                
                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });
                if(document.getElementsByClassName('ReactVirtualized__Grid').length > 1){
                    const width = document.getElementsByClassName('ReactVirtualized__Grid')[1].children[0].children[0].style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };
    
            chart.options.onHover = chartHoverHandler;
            dateDriftCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        }
    }, [dateDriftCanvas]);


    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }
  
        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = ()=>{
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item) => {
            const handleMap = (value) =>  value && moment.unix(value).isValid() ? moment.unix(value).format('MMM D, YY') : 'N/A';
            return  `${item.name};${item[item.id].map(handleMap).join(';')}\n`;
        };

        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'date-drift');
    };

    useEffect(()=> {
        const startDate = moment(datesRange.startDate);
        const endDate = moment(datesRange.endDate);
        const newData = {
            labels: config.data.labels,
            datasets: config.data.datasets
        };
        if(currentChart && !_.isEmpty(csvData)) {
            dispatch(showLoader());
            newData.labels = [];
            const datasetsSize = newData.datasets.length;
            // clear old datasets data
            for(let i = 0; i < datasetsSize; i++){
                newData.datasets[i].data = [];
            }
           
            // get all of the values as lines and sort by dates
            Object
                .values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .forEach((el) =>  {
                    const dateTime = convertToDateTime(el.Datetime);
                    if (!parseInt(el['Nb tasks not done'])) {
                        newData.labels.push(dateTime.format());
                        newData.datasets[0].data.push(null);
                        newData.datasets[1].data.push(null);
                        newData.datasets[2].data.push(null);
                        newData.datasets[3].data.push(!el.Deadline ? null : el.Deadline);
                    } else if (parseInt(el.Latest)) {
                        newData.labels.push(dateTime.format());
                        newData.datasets[0].data.push(moment.unix(el['Expected finish']).isBefore(dateTime, 'day') ? null : el['Expected finish']);
                        newData.datasets[1].data.push(moment.unix(el.Earliest).isBefore(dateTime, 'day') ? null : el.Earliest);
                        newData.datasets[2].data.push(moment.unix(el.Latest).isBefore(dateTime, 'day') ? null : el.Latest);
                        newData.datasets[3].data.push(!el.Deadline ? null : el.Deadline);
                    } else {
                        newData.datasets[0].data.push(null);
                        newData.datasets[1].data.push(null);
                        newData.datasets[2].data.push(null);
                        newData.datasets[3].data.push(!el.Deadline ? null : el.Deadline);
                    }
                });

            if(!moment(newData.labels[0]).isSame(startDate, 'day')){
                newData.labels = [startDate.format(),...newData.labels];
                Object.values(newData.datasets)
                    .forEach(el => {
                        el.data =  [el.data[0],...el.data];
                    });
            }
            
            const firstDay = new Date(startDate);
            const lastDay = new Date(endDate);
            lastDay.setDate(lastDay.getDate() + 1);
            const newLabels = [];
            let idxWithExistingData = 0;
            while(!isSameDate(firstDay,lastDay)) {
                const formatedFirstDay =  `${firstDay.getFullYear()}-${getMonth(firstDay.getMonth() + 1)}-${firstDay.getDate()}`; 
                const labelExists = newData.labels.find(el => isSameDate(new Date(el), firstDay));
                const momentFirstDay = moment(firstDay).tz(userTimezone);
                
                if(!labelExists){
                    if(momentFirstDay.isSame(moment().tz(userTimezone), 'day')) {

                        newData.datasets[0].data.push(expectedDates.expected.unix());
                        newData.datasets[1].data.push(expectedDates.earliest.unix());
                        newData.datasets[2].data.push(expectedDates.lastest.unix());
                        newData.datasets[3].data.push(!expectedDates.deadline ? expectedDates.deadline : expectedDates.deadline.unix());
                    } else {
                        Object.values(newData.datasets).forEach(dataSet => {
                            // add to array
                            const lineValue =  dataSet.data[idxWithExistingData - 1];
                            const data = dataSet.labelType === 'duedate'? lineValue : !lineValue || moment.unix(lineValue).isBefore(momentFirstDay, 'day') || momentFirstDay.isAfter(moment().tz(userTimezone), 'day') ? null : lineValue; 
                            
                            dataSet.data.splice(
                                idxWithExistingData,
                                0,
                                data
                            );
                        });
                    }
                } else {
                    if(momentFirstDay.isSame(moment().tz(userTimezone), 'day')) {
                        newData.datasets[0].data[newData.datasets[0].data.length - 1] = expectedDates.expected.unix();
                        newData.datasets[1].data[newData.datasets[1].data.length - 1] = expectedDates.earliest.unix();
                        newData.datasets[2].data[newData.datasets[2].data.length - 1] = expectedDates.lastest.unix();
                        newData.datasets[3].data[newData.datasets[3].data.length - 1] = expectedDates.deadline ? expectedDates.deadline.unix() : null;
                    }
                }
        
                newLabels.push(formatedFirstDay);
                firstDay.setDate(firstDay.getDate() + 1);
                idxWithExistingData++;
            }

            newData.labels = newLabels; 
            setCurrentData(newData); 
            dispatch(hideLoader());
        }
    }, [datesRange, currentChart, csvData, stateTasks, childsParents]);
       
    
    const onWorkspaceChange = async () => {
        dispatch(showLoader());
        
        const data = await getDataByTask({
            accountId: accountId,
            workspaceData: workspaceData,
            selectedTasks: [],
            userTimezone
        });

        setCSVData(data);
    };

    useEffect( () => {
        onWorkspaceChange();
    }, [workspaceData, accountId]);

    useEffect(() => {
        if(currentChart && currentData){
            currentChart.data.labels = [];
            const datasetsSize = currentChart.data.datasets.length;
            // clear old datasets data
            for(let i = 0; i < datasetsSize; i++){
                currentChart.data.datasets[i].data = [];
            }
            const clonedData = deepCloneDataSets(currentData);
            if(_.last(currentData.labels) === 'Current'){
                // remove prediction.
                clonedData.labels.pop();
                clonedData.datasets[0].data.pop();
                clonedData.datasets[1].data.pop();
                clonedData.datasets[2].data.pop();
                clonedData.datasets[3].data.pop();
            }
        
            const aggregatedData = agregateData(splitBy, { ...clonedData, useLastDataAvailable: true});

            // add the predition
            if(_.last(currentData.labels) === 'Current'){
                aggregatedData.labels.push(_.last(currentData.labels));
                aggregatedData.datasets[0].data.push(_.last(currentData.datasets[0].data));
                aggregatedData.datasets[1].data.push(_.last(currentData.datasets[1].data));
                aggregatedData.datasets[2].data.push(_.last(currentData.datasets[2].data));
                aggregatedData.datasets[3].data.push(_.last(currentData.datasets[3].data));
            }
        

            currentChart.data.datasets = Object.values(aggregatedData.datasets);
            currentChart.data.labels = aggregatedData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(aggregatedData);
        }
    }, [splitBy,currentData, currentChart]);

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    }; 

    if(currentChart && currentChart.data && currentChart.data.datasets && currentChart.data.datasets[0] && currentChart.data.datasets[0].data && currentChart.data.datasets[0].data.length > 50){
        currentChart.data.datasets.forEach(ds=>{
            ds.pointRadius = 0;
        });
        currentChart.update();
        currentChart.resize();
    }
    
    const renderDate = (date) => !moment(date).isValid() || !moment(date).isAfter(moment().tz(userTimezone).subtract(1, 'day')) ? 'N/A' : moment(date).format('MMM D, YY');

    if (!hasAutorization) {
        return <Unautorized />;
    }

    return <>
        <Paper className="topSection">
            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard title={'Expected finish date'} value={renderDate(expectedDates.expected)} />
                <OverViewCard title={'Earliest finish date'} value={renderDate(expectedDates.earliest)} />
                <OverViewCard title={'Latest finish date'} value={renderDate(expectedDates.lastest)} />
                <OverViewCard title={'Due date'} value={workspaceSelected === 'root' ? 'N/A' : moment.tz(expectedDates.deadline, 'YYYY-MM-DD', userTimezone).format('MMM D, YY')} />
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(dateDriftCanvas.current)}>
                    Save as Image
                    </Button>
                </div>
                <canvas height="300" width="800" ref={dateDriftCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                         Save as CSV
                    </Button>
                </div>
                <DateDriftTable 
                    tableColumnSize={tableColumnSize}
                    onColumnHover={onColumnHover}
                    datasets={displayData.datasets} 
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
  
};

export default withCustomErrorBoundary(DateDrift);