import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'clsx';
import { Link, browserHistory, withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import HeaderUserAccount from '../HeaderUserAccount/HeaderUserAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import { getUserShowEmailNotifInfo } from '../../utils/selectors/user';
import NotificationsBadge from './NotificationsBadge';
import SideMenuTreeSelector from './SideMenuTreeSelector';
import WarningPanel from './WarningPanel/WarningPanel';
import _ from 'underscore';
import { getAccountEndPlanAt, getAccountPlanNbusers, getAccountUsers } from '../../utils/selectors/account';
import { getOnlyResourceUsers } from '../../utils/memberTypes';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { toggleDefaultModalShow } from '../../utils/actions/defaultModal';
import { DefaultModalType } from '../../shared/types';
import LastSyncTimer from './LastSyncTimer/LastSyncTimer';
import { Button, Tooltip } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import moment from 'moment';
import useDebounce from '../../utils/hooks/useDebounce';
import NavLinks from './components/NavLinks/NavLinks';
import { getAccountDataSyncApps } from '../../utils/selectors/accountData';
import useIsLocalStorageAvailable from '../../utils/hooks/useIsLocalStorageAvailable';

const Header = ({ location: { pathname }}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [headerOpen, setHeaderOpen] = useState(false);
    const isLocalStorageAvailable = useIsLocalStorageAvailable();
    const [debouncedHeaderOpen, setDebouncedHeaderOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const showEmailNotifInfo = useSelector(getUserShowEmailNotifInfo);
    const syncApps = useSelector(getAccountDataSyncApps);
    const endPlanAt = useSelector(getAccountEndPlanAt);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const accountUsers = useSelector(getAccountUsers);
    const memberResources = getOnlyResourceUsers(accountUsers);

    useDebounce(() => setDebouncedHeaderOpen(headerOpen), 75, [headerOpen]);

    useEffect(() => {
        if (showEmailNotifInfo) {
            dispatch(toggleDefaultModalShow({
                show: true,
                modalType: DefaultModalType.EMAIL_NOTIF_INFO,
            }));
        }
    }, [showEmailNotifInfo]);

    const isRoute = useCallback((route) => {
        const regex = new RegExp('^' + route);
        return (regex.test(pathname));
    }, [pathname]);

    const changeSticky = useCallback(() => {
        isLocalStorageAvailable && localStorage.setItem('stickyMenu', !isSticky ? 'on':'off');
        setIsSticky(!isSticky);
    }, [isSticky]);

    useEffect(()=>{
        if(isLocalStorageAvailable && localStorage.getItem('stickyMenu')){
            setIsSticky(localStorage.getItem('stickyMenu') === 'on');
        }
    }, [isLocalStorageAvailable]);

    const isAppBlocked = React.useMemo(() => {
        const isPlanOver = !moment(endPlanAt).isSameOrAfter(moment(), 'day');
        const limitResourcesReached = _.size(memberResources) > parseFloat(planNbusers);

        return Boolean(isPlanOver || limitResourcesReached);
    }, [endPlanAt, accountUsers, planNbusers]);

    const handleCloseHeader = React.useCallback(() => {
        if(!isSticky) setHeaderOpen(false);
    }, [isSticky]);
    
    const handleOpenHeader = React.useCallback(() => setHeaderOpen(true), []);

    if(isRoute('/create-workspace') || isRoute('/workspaces')) {
        return null;
    }

    return (
        <div 
            className={classnames('Header', {open: (debouncedHeaderOpen || isSticky)})}
            onMouseLeave={handleCloseHeader}
        >
            <div 
                className='top' 
                onMouseOver={handleOpenHeader} 
            >
                <Link to="/" onClick={handleCloseHeader} className="logo">
                    <img src="../../../stylesheets/assets/logo-icon.svg?v=2" className="black" alt="logo" />
                </Link>

                <nav>
                    <NavLinks isStick={isSticky} handleCloseHeader={handleCloseHeader} />
                </nav>
                
                <SideMenuTreeSelector isAppBlocked={isAppBlocked}/>

                {(debouncedHeaderOpen || isSticky) && 
                    <Tooltip placement='right' title={isSticky ? intl.formatMessage({id: 'Unpin Menu'}):intl.formatMessage({id: 'Pin Menu'})}>
                        <div className={classnames('stickyIcon', {on: isSticky})} onClick={changeSticky}>
                            <ArrowForwardIos />
                            <ArrowForwardIos />
                        </div>
                    </Tooltip>
                }
            </div>

            <div className='bottom'>

                <div className="bottom__nav">
                    <div className="bottom__nav__item">
                        {syncApps && (
                            <>
                                <LastSyncTimer />
                                <span>Publish</span>
                            </>
                        )}
                    </div>

                    <div className="bottom__nav__item">
                        <WarningPanel />
                        <span>Warnings</span>
                    </div>

                    <div
                        className="bottom__nav__item" 
                        onClick={() => { browserHistory.push('/notifications');  handleCloseHeader();}}
                    >
                        <NotificationsBadge showTooltip={(debouncedHeaderOpen || isSticky)?false:true} />
                        <span>Notifications</span>
                    </div>

                    <div 
                        className="bottom__nav__item"
                        onClick={() => { browserHistory.push('/settings/workspace'); handleCloseHeader();}}
                    >
                        {(debouncedHeaderOpen || isSticky) && (
                            <Button className='settingsBtn'>
                                <SettingsIcon className='settingsIcon' />
                            </Button>)
                        }
                        {!(debouncedHeaderOpen || isSticky) && (
                            <Tooltip placement='right' title={intl.formatMessage({id: 'Settings'})}>
                                <Button className='settingsBtn'>
                                    <SettingsIcon className='settingsIcon' />
                                </Button>
                            </Tooltip>)
                        }
                        <span>Settings</span>
                    </div>

                    <div className="bottom__nav__item">
                        <HeaderUserAccount />
                        <span onClick={() => { browserHistory.push('/settings/my-account');  handleCloseHeader();}}>My account</span>
                    </div>
                </div>
            </div>
            {debouncedHeaderOpen && !isSticky && <div className='backdropHeaderOpen' onMouseOver={()=>setHeaderOpen(false)} />}
        </div>
    );

};

export default React.memo(withRouter(withCustomErrorBoundary(Header)));