import React, { useEffect, useState, memo } from 'react';
import _ from 'underscore';
import { SYNC_APP_TYPES, SYNC_CALENDAR_APP_TYPES } from '../../utils';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getAccountsDataConnectCalendars, getAccountsDataConnectCalendarsOff } from '../../../../utils/firebaseEvents';
import { getUserId } from '../../../../utils/selectors/user';
import { getAccountId, getSlackConnections } from '../../../../utils/selectors/account';
import { getAccountDataSyncApps } from '../../../../utils/selectors/accountData';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import IntegrationItem from '../IntegrationItem/IntegrationItem';

const CONNECTIVITY_STATUS = ['Connected', 'Available'];

const StatusSection = () => {
    const [calendars, setCalendars] = useState([]);
    const [slack, setSlack] = useState([]);
    const [syncApps, setSyncApps] = useState([]);
    const userId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const slackConnections = useSelector(getSlackConnections);
    const accountDataSyncApps = useSelector(getAccountDataSyncApps);

    useEffect(() => {
        if(userId && accountId) {
            setSlack([{toolName: 'slack', status: slackConnections ? 'Connected' : 'Available'}]);

            getAccountsDataConnectCalendars({userId: userId, accountId: accountId}, (snap) => {
                const calendars = SYNC_CALENDAR_APP_TYPES.reduce((acc, calendarType) => {
                    const IsConnected = !!(Object.values(snap.val() || {}).find((el=>el.scope===calendarType && el.connected)));
                    return [
                        ...acc,
                        {toolName: `${calendarType}-calendar`, status: IsConnected ? 'Connected' : 'Available'}
                    ];
                }, []);
                setCalendars(calendars);
            });

            const accountDataSyncAppsValues = Object.values(accountDataSyncApps || {});

            const syncApps = SYNC_APP_TYPES.reduce((acc, appType) => {

                const appData = accountDataSyncAppsValues.filter(el => el.status !== 'waiting_auth').find(el=>el.type===appType);
                const status = _.get(appData, 'status', '');

                return [
                    ...acc,
                    {
                        toolName: appType,
                        status: status ? 'Connected' : 'Available',
                        hasError: _.get(appData, 'status', '') === 'error'
                    }
                ];
            }, []);

            setSyncApps(syncApps);
        }

        return () => {
            getAccountsDataConnectCalendarsOff({ accountId, userId });
        };
    }, [userId, accountId, slackConnections, accountDataSyncApps]);

    const allApps = [...syncApps, ...calendars, ...slack];

    return <>
        {CONNECTIVITY_STATUS.map((statusSection) => {
            const apps = allApps.filter(app=>app.status === statusSection);

            return <StatusSectionItems statusSection={statusSection} key={statusSection} apps={apps}/>;
        })}
    </>;
};

export default memo(withCustomErrorBoundary(StatusSection));

const StatusSectionItems = ({ statusSection = null, apps = [] }) => {

    if(_.isEmpty(apps)) return null;

    return <div className='StatusSection'>
        <h4>{statusSection}</h4>
        <Divider />
        {apps.map(({ toolName, status, hasError }, index) => {
            return <IntegrationItem  hasError={hasError} toolName={toolName} status={status} key={`${toolName}-${index}`} />;
        })}
    </div>;
};