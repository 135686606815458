import React, { useCallback } from 'react';
import Table from '../ChartTables';
import { getDateFormated } from '../utils';

const TaskBurndownTable = ({ onColumnHover, datasets, labels, userDateFormat }) =>   {
    const renderColumns = useCallback(((row, isChild) => {
        const doneRow = [{ isTitle: true, value: 'Task Done' } ];
        const titleRow = [{ isName: true,  value: row.name , isChild } ];
        const toBeDone = [{ isTitle: true, value: 'Task Not Done' } ];
        const totalTasks = [{ isTitle: true, value: 'Total Tasks' } ];
        const percente = [{ isTitle: true, value: '% complete' } ];

        labels.forEach((l, idx) => {
            doneRow.push( { value:  row.done[idx], isChild } );
            toBeDone.push( { value:  row.notDone[idx], isChild  } );
            titleRow.push( { value: null, isChild  } );
            totalTasks.push( { value:  row.done[idx] + row.notDone[idx], isChild  } );
            percente.push( { value:  row.progress ? `${Math.round( row.progress[idx] * 100) / 100}%` : '0%', isChild  } );
        });

        return [ 
            titleRow,
            doneRow,
            toBeDone,
            totalTasks,
            percente
        ];
    }),[labels]);
    const formatTitle = (title) =>  getDateFormated(title);

    return <Table
        size="small"
        formatTitle={formatTitle}
        onColumnHover={onColumnHover}
        datasets={datasets}
        renderColumns={renderColumns}
        labels={labels} 
        userDateFormat={userDateFormat} />;
};

export default React.memo(TaskBurndownTable);