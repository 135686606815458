import React, { useState  } from 'react';
import classnames from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getAccountTaskHasChildren, getAccountTaskSkill, getSkillsDescription } from '../../utils/selectors/account';
// import { getFirstTaskNoSkill, getPopupGuidesNoSkill } from '../../utils/selectors/app';
import SelectSkill from '../SelectSkill/SelectSkill';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { openReccurentTaskModal } from '../App/AppActions';
// import DefaultTootip from '../../utils/defaultTooltip';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TaskLineSkill = ( { canManage, status, id,  /* taskNoSkill,  */}) => {
    const dispatch = useDispatch();
    const [showSkillSelect, setShowSkillSelect] = useState(false);
    const accountId = useSelector(getAccountId);
    // const noSkill = useSelector(getPopupGuidesNoSkill);
    const taskHasChildrens = useSelector(getAccountTaskHasChildren(id));
    const taskSkillIds = useSelector(getAccountTaskSkill(id));
    const skillsDescription = useSelector(getSkillsDescription(taskSkillIds));
    // const firstTaskNoSkill = useSelector(getFirstTaskNoSkill);


    if (taskHasChildrens) {
        return <div className='skillCol' data-sidebar='preventClose' />;
    }

    const handleKeyPresssed = e => {
        e.preventDefault();
        setShowSkillSelect(true);
    };

    const hadleShowSkillSelect = (e) => {
        e.stopPropagation();

        if ( status !== 'done' && canManage) {
            setShowSkillSelect(true);
        }
    };

    const hideSkillSelect = () => {
        setShowSkillSelect(false);
    };

    const changeTaskSkill = (skillIds) =>  {
        firebaseEvents.updateTaskSkill(accountId, id, skillIds);
        firebaseEvents.addChallenge('changeTaskSkill');
        firebaseEvents.setCompletedPopupGuide('noSkill');
        dispatch(openReccurentTaskModal({
            taskId: id,
            updatedField: 'skill',
        }));

        setShowSkillSelect(false);

    };

    // const showToolTip = !!(taskNoSkill && !noSkill && id === firstTaskNoSkill);

    return (
        <>
            <div className='skillCol' key={`tasks-skill-collum-${id}`} id={`tasks-skill-collum-${id}`}>
                <div
                    className={classnames('skillSelect', {
                        disabled: !( status !== 'done' && canManage),
                    })}
                    data-taskid={id}
                >
                    <div
                        onClick={hadleShowSkillSelect}
                        onKeyPress={handleKeyPresssed}
                        tabIndex="0"
                    >
                        <span className={classnames({notdefined: !taskSkillIds?.length})}>
                            {skillsDescription}
                        </span>
                    </div>
                </div>
            </div>
            {/* {
                showToolTip && <DefaultTootip
                    open={showToolTip}
                    placement={'left'}
                    newRef={document.getElementById(`tasks-skill-collum-${id}`)}
                    tooltipId={'noSkill'}
                    taskId={id}
                />
            } */}
            {showSkillSelect &&
                <SelectSkill
                    isMultiSelect
                    currentSkill={taskSkillIds}
                    onSelect={changeTaskSkill}
                    onClose={hideSkillSelect}
                    open={showSkillSelect}
                />
            }
        </>
    );
};

export default React.memo(withCustomErrorBoundary(TaskLineSkill));