import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
    getAccountBasePermissions,
    getAccountChargebeeCustomerId,
    getAccountId,
    getAccountPlan,
    getAccountPlanNbusers,
    getAccountResourceMembers,
    getAccountTasks,
    getAccountUserGroups,
    getTaskAllowedWorkers,
} from '../../utils/selectors/account';
import Proptypes from 'prop-types';
import classnames from 'clsx';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { canSee } from '../../utils/userFunctions';
import { toastr } from 'react-redux-toastr';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CreateNewMemberModal from '../CreateNewMemberModal/CreateNewMemberModal';
import { canUserFollowTask, handleStopPropagation, IsEnterKey } from '../../utils/utils';
import { getUserPermissions } from '../../utils/selectors/user';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';
import { toggleUpgradeSubscriptionModalShow } from '../../utils/actions/upgradeSubscriptionModal';
import { getOnlyResourceUsers } from '../../utils/memberTypes';

const getLvl = (userGroups, group) => {
    if (!group) {
        return 0;
    }

    if (userGroups[group].parent) {
        return getLvl(userGroups, userGroups[group].parent) + 1;
    }

    return 1;
};

const SelectTeamMember = ({
    open,
    currentUser,
    title = 'Resource',
    email = null,
    taskId = null,
    blockUsers = [],
    onClose,
    onSelect,
    maxSelect = 1,
}) => {
    if(!open) return null;
    const intl = useIntl();
    const dispatch = useDispatch();

    const [groupsOpen, setGroupOpen] = useState([]);
    const [checkedItens, setCheckItens] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const accountId = useSelector(getAccountId);
    const plan = useSelector(getAccountPlan);
    const planNbusers = useSelector(getAccountPlanNbusers);
    const chargebeeCustomerId = useSelector(getAccountChargebeeCustomerId);
    const userPermissions = useSelector(getUserPermissions);
    const basePermissions = useSelector(getAccountBasePermissions);
    const accountTasks = useSelector(getAccountTasks);
    const userGroups = useSelector(getAccountUserGroups);
    const accountResources = useSelector(getAccountResourceMembers);
    const onlyResources = getOnlyResourceUsers(accountResources);
    const allowedTaskWorkers = useSelector(getTaskAllowedWorkers(taskId));

    useEffect(() => {
        setGroupOpen([]);
        setSearchValue('');
    }, [open]);

    useEffect(() => {
        setCheckItens(currentUser);
    }, [currentUser]);

    useEffect(()=> {
        if(checkedItens) {
            document.addEventListener('keyup', handleKeyUpSaveChanges);
        }
        return () => {
            document.removeEventListener('keyup', handleKeyUpSaveChanges);
        };
    }, [handleKeyUpSaveChanges, checkedItens]);

    const handleKeyUpSaveChanges = (e) => {
        if(!IsEnterKey(e)) return;

        const okButton = document.querySelector('.rrt-button.rrt-ok-btn.toastr-control');
        if(okButton) {
            okButton.click();
            return;
        }
        handleSubmit();
    };

    const userList = React.useMemo(() => Object.keys(taskId ? allowedTaskWorkers : onlyResources || {}).reduce((acc, userId) => {
        if (userGroups !== null) {
            const user = { ...accountResources[userId], id: userId };

            if(blockUsers && blockUsers.includes(userId)){
                return acc;
            }

            if (user.viewer || (title === 'followers' && !canUserFollowTask({ userId, taskId, accountTasks, basePermissions }))) {
                return acc;
            }

            if (
                !user.displayName?.toLowerCase().match(searchValue.toLowerCase()) &&
                !userGroups[user?.group]?.title.toLowerCase().match(searchValue.toLowerCase())
            ) {
                return acc;
            }

            if (user.group) {
                if (acc[user.group]) {
                    acc[user.group].users.push(user);

                    return acc;
                }

                if(userGroups[user.group]) {
                    acc[user.group] = {
                        title: userGroups[user.group].title,
                        id: user.group,
                        lvl: getLvl(userGroups, user.group),
                        users: [user]
                    };
                }

                return acc;
            }

            if (acc.empty) {
                acc.empty.users.push(user);

                return acc;
            }

            acc.empty = {
                lvl: getLvl(userGroups, user.group),
                users: [user]
            };
        }

        return acc;
    }, {}), [blockUsers, allowedTaskWorkers, accountResources, userGroups, searchValue, taskId, accountTasks, basePermissions]);

    const handleOpenGroup = React.useCallback((e) => {
        handleStopPropagation(e);
        const id = e.target.tagName !== 'path' ? e.target.id : e.target.parentElement.id;

        if (groupsOpen.includes(id)) {
            setGroupOpen(groupsOpen.filter(el => el !== id));
            return;
        }
        setGroupOpen([...groupsOpen, id]);
    }, [groupsOpen]);

    const handleSelectTeamMember = React.useCallback((e, selectedId) => {
        handleStopPropagation(e);

        if (maxSelect === 1) {
            onSelect(selectedId);
            return;
        }

        if (checkedItens.includes(selectedId)) {
            setCheckItens(checkedItens.filter(el => el !== selectedId));
            return;
        }

        if (_.size(checkedItens) + 1 > maxSelect) {
            toastr.error('Max element selected, remove some before adding more.');
            return;
        }

        setCheckItens([...checkedItens, selectedId]);
    }, [checkedItens, maxSelect]);

    const handleSearch = React.useCallback(({ target: { value } }) => {
        setSearchValue(value);
    }, []);

    const handleKeyUp = (e) => {
        if(IsEnterKey(e) && _.isEmpty(userList) && open) {
            handleCreateTeamMember();
        }
    };

    const handleCreateTeamMember = React.useCallback(() => {
        if(_.size(accountResources) + 1 > parseFloat(planNbusers)) {
            dispatch(toggleUpgradeSubscriptionModalShow({ show: true }));
            return;
        }

        if (canSee(['admin', 'subscription'], { data: { permissions: userPermissions } })) {
            setOpenModal(true);
            return;
        }
    }, [searchValue, accountResources, planNbusers, accountId, plan, userPermissions, chargebeeCustomerId]);

    const handleSubmit = () => {
        onSelect(checkedItens);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmitModal = (id) => {
        handleSelectTeamMember(null, id);
        setOpenModal(false);
    };

    return <Dialog maxWidth={'md'} className="SelectTeamMember_Modal" onClose={onClose} aria-labelledby="customized-dialog-title" open={open} data-sidebar='preventClose'>
        <MuiDialogTitle className="title" disableTypography onClick={handleStopPropagation}>
            <Typography variant="h6"> {intl.formatMessage({id: `Select ${title}`})} </Typography>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="content" dividers onClick={handleStopPropagation}>
            <div className="input-search-container" >
                <TextField
                    InputProps={{
                        startAdornment: <InputAdornment className="search-icon" position="start"><SearchIcon /></InputAdornment>,
                    }}
                    placeholder="Search or type a name to create a new team member..."
                    className="input-search"
                    fullWidth
                    onChange={handleSearch}
                    onKeyUp={handleKeyUp}
                    autoFocus
                />
            </div>
            {!_.isEmpty(userList) && <div className="skill-list-container">
                {Object.keys(userList).filter(key => key !== 'empty').sort((a, b) => userList[a].title.toLowerCase() > userList[b].title.toLowerCase() ? 1 : -1).map(userGroupId => (<React.Fragment key={userGroupId}>
                    <div className="SelectTeamMember_Group" style={{ marginLeft: `${(userList[userGroupId].lvl) * 2}rem` }} onClick={handleOpenGroup} id={userGroupId} key={userGroupId} >
                        {!groupsOpen.includes(userGroupId) && <KeyboardArrowDown id={userGroupId} onClick={handleOpenGroup} />}
                        {groupsOpen.includes(userGroupId) && <KeyboardArrowRight id={userGroupId} onClick={handleOpenGroup} />}
                        {userList[userGroupId].title}
                    </div>
                    {userList[userGroupId].users.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1).map(user => <div onClick={(e) => handleSelectTeamMember(e, user.id)} className={classnames('SelectTeamMember_Skill', { hidden: groupsOpen.includes(userGroupId) })} key={user.id} >
                        <div style={{ marginLeft: `${(userList[userGroupId].lvl) * (userList[userGroupId].lvl > 1 ? 2.8 : 4)}rem` }}>
                            <StateUserAvatar
                                className="avatar"
                                userId={user.id}
                                disableTooltip={true}
                            />
                            <span className="teamMemberName">{user.displayName}</span>
                            <LineAction maxSelect={maxSelect} checkedItens={checkedItens} lineId={user.id} handleSelectTeamMember={handleSelectTeamMember} />
                        </div>
                    </div>)}
                </React.Fragment>))
                }
                {
                    userList.empty && userList.empty.users.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1).map(user =>
                        <div onClick={(e) => handleSelectTeamMember(e, user.id)} className={'SelectTeamMember_empty'} id={user.id} key={user.id} >
                            <div>
                                <StateUserAvatar
                                    className="avatar"
                                    userId={user.id}
                                    disableTooltip={true}
                                />
                                <span className="teamMemberName">{user.displayName}</span>
                                <LineAction maxSelect={maxSelect} checkedItens={checkedItens} lineId={user.id} handleSelectTeamMember={handleSelectTeamMember} />
                            </div>
                        </div>
                    )
                }
            </div>
            }
            {
                _.isEmpty(userList) && <div className="Skill-not-found-container">
                    <div className="Skill-not-found">
                        {intl.formatMessage({ id: 'User Not Found' })}
                    </div>
                </div>
            }


            {searchValue.trim().length >= 2 && <div className={classnames('Skill-not-found-btn', {multi: maxSelect > 1})}>
                <Button
                    disabled={!canSee(['admin', 'team'], { data: { permissions: userPermissions } })}
                    onClick={handleCreateTeamMember}
                    variant="contained"
                    fullWidth
                >
                    {intl.formatMessage({ id: 'CREATE' })} <span className="task-name-btn"> "{searchValue}" </span> {intl.formatMessage({ id: 'User' })}
                </Button>
            </div>}
        </MuiDialogContent>
        {maxSelect > 1 && <MuiDialogActions className='actions'>
            <Button
                className="cancel-btn"
                onClick={e => {handleStopPropagation(e); onClose(e);}}
                variant="contained"
            >
                {intl.formatMessage({ id: 'CANCEL' })}
            </Button>
            <Button
                className="submit-btn"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                {intl.formatMessage({ id: 'Save Changes' })}
            </Button>
        </MuiDialogActions>}
        <CreateNewMemberModal
            displayName={searchValue}
            isOpen={openModal}
            onClose={handleCloseModal}
            onSubmit={handleSubmitModal}
            defaultEmail={email}
            disableCreateTypes={['userOnly']}
        />
    </Dialog >;
};


const LineAction = ({ checkedItens, lineId, handleSelectTeamMember, maxSelect }) => {
    const intl = useIntl();

    if (maxSelect > 1) {
        return <Checkbox
            color='primary'
            value={lineId}
            onClick={(e) => handleSelectTeamMember(e, lineId)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={checkedItens.includes(lineId)}
        />;
    }

    return <>
        {
            !checkedItens.includes(lineId) && <Button value={lineId} className="SelectTeamMember_BTN" id={lineId} onClick={(e) => handleSelectTeamMember(e, lineId)} variant="contained">
                {intl.formatMessage({ id: 'Select' })}
            </Button>
        }
        {
            checkedItens.includes(lineId) && <Button className="SelectTeamMember_CURRENT_BTN" variant="contained">
                {intl.formatMessage({ id: 'Current' })}
            </Button>
        }
    </>;
};


SelectTeamMember.propTypes = {
    open: Proptypes.bool.isRequired,
    onClose: Proptypes.func.isRequired,
    onSelect: Proptypes.func.isRequired,
    maxSelect: Proptypes.number,
    title: Proptypes.string,
    currentUser: Proptypes.array,
    taskId: Proptypes.string,
    email: Proptypes.string
};

export default SelectTeamMember;