
import { db } from '../../firebaseEvents';
import { getAccountId } from '../../selectors/account';
import { getState } from '../../../configureStore';
import { SYNC_APP_TYPE } from '../../../views/Integrations/utils';
import { JIRA_ESTIMATION_TYPES } from '../../../views/Integrations/components/SyncSettings/utils';
import { updateSyncAppCounterNbUsersMatch } from '../account/settings/syncAppCounters';

export const syncAppPathBuilder = (accountId, syncAppId) => `/accountsData/${accountId}/syncApp/${syncAppId}`;

export const getSyncAppEffortSettings = async (syncAppId) => {
    const syncType = await db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/type`).once('value');
    const data = await db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/effortSettings`).once('value');

    if(syncType.val() === SYNC_APP_TYPE.CLICKUP) {
        const initialData = {
            import: {
                on: 1,
                value: '20',
            },
            onUpdate: {
                on: 1,
                value: '20',
            }, 
            export: {
                on: 1,
                value: '20',
            }, 
        };

        return !data.val() ? initialData : { ...initialData, ...data.val()};
    }

    if(syncType.val() === SYNC_APP_TYPE.JIRA) {
        
        const effortSettings = {
            import: {
                on: 0,
                estimationActive: JIRA_ESTIMATION_TYPES.ORIGINAL,
                originalEstimation: {
                    value: '20', 
                }, 
                storyPoints: {
                    value: {}
                }
            },
            onUpdate: {
                on: 0,
                estimationActive: JIRA_ESTIMATION_TYPES.ORIGINAL,
                originalEstimation: {
                    value: '20', 
                }, 
                storyPoints: {
                    value: {}
                }
            },
        };

        return !data.val() ? effortSettings : { ...effortSettings, ...data.val()};
    }
};

export const setSyncAppEffortSettingsByField = async (syncAppId, field, value) => {
    return  db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/effortSettings/${field}`).set(value);
};

export const setSyncAppMatchUser = async ({ syncAppType, syncAppId, planlessId, sourceId}) => {
    const accountId = getAccountId(getState());
    const users = await db.r(`${syncAppPathBuilder(accountId, syncAppId)}/users`).once('value');
    const nbUsersMatched = Object.values(users.val() || {}).filter(user => !!user.planlessId).length + 1;
    
    updateSyncAppCounterNbUsersMatch({ syncAppType, nbUsersMatched });
    return  db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/users/${sourceId}/planlessId`).set(planlessId);
};

export const setSyncAppUnmatchUser = async ({ syncAppType, syncAppId, sourceId}) => {
    const accountId = getAccountId(getState());
    const users = await db.r(`${syncAppPathBuilder(accountId, syncAppId)}/users`).once('value');
    const nbUsersMatched = Object.values(users.val() || {}).filter(user => !!user.planlessId).length;
    
    updateSyncAppCounterNbUsersMatch({ syncAppType, nbUsersMatched: nbUsersMatched === 0 ? 0 : nbUsersMatched - 1 });
    return  db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/users/${sourceId}/planlessId`).set(null);
};

export const removeSyncAppUser = async ({syncAppId, sourceId}) => {
    return  db.r(`${syncAppPathBuilder(getAccountId(getState()), syncAppId)}/users/${sourceId}`).set(null);
};

export const getListenerSyncAppsProcess = async ({callBack}) => {
    return  db.r(`accountsData/${getAccountId(getState())}/syncAppsProcess`).on('value', callBack);
};

export const offListenerSyncAppsProcess = async () => {
    return  db.off(`accountsData/${getAccountId(getState())}/syncAppsProcess`);
};

