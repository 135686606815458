import React from 'react';
import { Button, Switch } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useIntl } from 'react-intl';

const CalendarItem = ({ email, scope, calendars, handleResyncAccount, handleDeleteAccount, handleCalendarConnection, openDialog}) => {
    const intl  = useIntl();
    
    return (
        <div key={`connect-account-${email}`} className="ConnectedAccount">
            <div className="accountHeader">
                <div className="icon">
                    <img src={`../../../stylesheets/assets/${scope}-icon.svg`} className="logo" />
                </div>
                <div className="title">{email}</div>
                <div className="actions">
                    <Button className="resync" onClick={() => handleResyncAccount(email)}>  {intl.formatMessage({id: 'RE-SYNC' })} </Button>
                    <Button className="delete" onClick={() => handleDeleteAccount(email)}>  {intl.formatMessage({id: 'Remove' })} </Button>
                </div>
            </div>
            <div>
                {calendars.map(calendar => (
                    <div key={`connect-calendar-${calendar.id}-${calendar.connected}`} className="calendarContainer">
                        <Switch size="small" checked={calendar.connected} onChange={(_, value) => handleCalendarConnection(value, calendar.id, email)} />
                        <div>{calendar.summary}</div>
                        {calendar.connected && <div className="calendarSetting"> <SettingsIcon
                            className='icon'
                            onClick={() => openDialog(calendar.id, calendar, email)} /> </div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarItem;