import * as constants from './AppConstants';
import getDefaultLanguage from '../../utils/defaultLanguage';
import versionJson from '../../../version.json';
import { getTasks } from '../Tasks/utils';
import moment from 'moment';
import { DefaultModalType } from '../../shared/types';

const getSavedLimitViewTo = (accountId)=>{
    if(localStorage.getItem('limitViewTo-' + accountId)){
        return localStorage.getItem('limitViewTo-' + accountId);
    }
    return null;
};

const getTimelineRange = (accountId)=>{
    let timelineRange;

    if(accountId){
        timelineRange = localStorage.getItem('timelineRange-' + accountId);
    }

    if (!timelineRange) {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        var endDate = new Date();
        endDate.setDate(endDate.getDate() + 30);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        return {
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        };
    } else {
        return JSON.parse(timelineRange);
    }

};

const getTimelineColumns = ()=>{
    if(localStorage.getItem('timelineColumns')){
        return localStorage.getItem('timelineColumns').split(',');
    }
    return ['assignee','skill','effort','priority'];
};


const app = (
    state = {
        locale: getDefaultLanguage(),
        user: undefined,
        userAccounts: null,
        userChallenges: null,
        account: {
            tasks: {},
            users: {},
            daysoff: {},
            events: {},
            apiKeys: null,
            skills: {},
            settings: {
                maxChallengesCompleted: null,
                basePermissions: null,
                boards: null,
                chargebeeCustomerId: null,
                costBudget: null,
                customFields: null,
                dependenciesBlock: null,
                lastSeen: null,
                lastStatsSaved: null,
                name: null,
                storageSize: null,
                id: null,
                // plan and billinginfo
                plan: null,
                planNbusers: null,
                endPlanAt: null,
                billingInfo: null,
                chargebee: null,
                firstStepsConfig: null,
                isTrial: false,
                isLifetime: false,
                seenMidTrialModal: false,
                canUsersManageOwnSkills: false,
            },
            logger: null,
            skillGroups: null,
            slackConnections: null,
            userGroups: null,
        },
        loaders: {
            tasks: true,
            skills: true,
            users: true,
            daysoff: true,
            settings: true,
        },
        noAccountsLoaded: false,
        tasksEstimations: null,
        // ^ new
        orderedTaskList: null,
        parentChildIds: {
            parents: {},
            childs: {},
        },
        hasConnection: true,
        loader: false,
        discountCode: null,
        workManager: { taskId: null, userId: null, previousState: null, newState: null, boardId: null },
        filters: '{"andor":"and"}',
        version: versionJson.version,
        gapiLoaded: false,
        quickSearch: '',
        closedSortables: [],
        calculating: false,
        taskDescription: null,
        dbUpdates: [],
        recurrentTaskUpdateModal: {
            isOpen: false,
            data: {
                taskId: null,
                updatedField: null,
            },
        },
        changeTaskLocationModal: {
            isOpen: false,
            data: {
                taskId: null,
                parent: null,
            },
        },
        selectDependenciesModal: {
            isOpen: false,
            data: {
                taskId: null,
                dependencies: null,
            },
        },
        lastRecalculate: 0,
        activeUserTime: {
            isSet: false,
            timer: null,
        },
        modals: {
            defaultModal: {
                type: DefaultModalType.EMAIL_NOTIF_INFO,
                show: false,
            },
            upgradeSubscriptionModal: {
                show: false,
            },
            selectStatusModal: {
                show: false,
                taskId: null,
            }
        },
        userFirstStepsConfig: null,
        popupGuides: null,
        disablePopupGuides: true,
        isFirstCalendarConnected: false,
        limitViewTo: null,
        showFilters: false,
        calendarMembersToShow: [],
        timelineRange: getTimelineRange(),
        timelineTasksToShow: [],
        timelineFirstLevelTasks: [],
        syncApps: null,
        timelineColumns: getTimelineColumns(),
    },
    action,
) => {
    switch (action.type) {
    case constants.CHANGE_LOCALE:
        return { ...state, locale: action.locale };
    case constants.SET_ACCOUNT_SETTINGS: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: (action?.data?.id) ? getSavedLimitViewTo(action.data.id) : null,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            account: {
                ...state.account,
                settings: action.data,
                id: action.data.id
            },
            loaders: {
                ...state.loaders,
                settings: false,
            },
            limitViewTo: (action?.data?.id) ? getSavedLimitViewTo(action.data.id) : null,
            timelineRange: getTimelineRange(action?.data?.id),
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.RESET_SETTINGS:
        return {
            ...state,
            orderedTaskList: null,
            parentChildIds: {
                parents: {},
                childs: {},
            },
            account: {
                tasks: {},
                users: {},
                daysoff: {},
                events: {},
                skills: {},
                settings: {
                    basePermissions: null,
                    boards: null,
                    chargebeeCustomerId: null,
                    costBudget: null,
                    customFields: null,
                    dependenciesBlock: null,
                    lastSeen: null,
                    lastStatsSaved: null,
                    maxChallengesCompleted: null,
                    name: null,
                    storageSize: null,
                    id: null,
                    // plan and billinginfo
                    plan: null,
                    planNbusers: null,
                    endPlanAt: null,
                    billingInfo: null,
                    chargebee: null,
                    firstStepsConfig: null,
                    isTrial: false,
                    isLifetime: false,
                    seenMidTrialModal: false,
                    planPeriodicity: null,
                    planCurrency: null,
                    subscriptionCanceled: null,
                    canUsersManageOwnSkills: false,
                },
                logger: null,
                skillGroups: null,
                slackConnections: null,
                userGroups: null,
            },
            loaders: {
                tasks: true,
                skills: true,
                users: true,
                daysoff: true,
                settings: true,
            },
            popupGuides: null,
            isFirstCalendarConnected: false,
            disablePopupGuides: true,
            timelineTasksToShow: [],
            timelineFirstLevelTasks: [],
            syncApps: null
        };
    case constants.LOGOUT:
        return {
            ...state,
            locale: getDefaultLanguage(),
            user: undefined,
            userAccounts: null,
            userChallenges: null,
            account: {
                tasks: {},
                users: {},
                daysoff: {},
                events: {},
                skills: {},
                settings: {
                    basePermissions: null,
                    boards: null,
                    chargebeeCustomerId: null,
                    costBudget: null,
                    customFields: null,
                    dependenciesBlock: null,
                    lastSeen: null,
                    lastStatsSaved: null,
                    maxChallengesCompleted: null,
                    name: null,
                    storageSize: null,
                    id: null,
                    // plan and billinginfo
                    plan: null,
                    planNbusers: null,
                    endPlanAt: null,
                    billingInfo: null,
                    chargebee: null,
                    firstStepsConfig: null,
                    isTrial: false,
                    isLifetime: false,
                    seenMidTrialModal: false,
                    canUsersManageOwnSkills: false,
                },
                logger: null,
                skillGroups: null,
                slackConnections: null,
                userGroups: null,
            },
            tasksEstimations: null,
            // ^ new
            orderedTaskList: null,
            parentChildIds: {
                parents: {},
                childs: {},
            },
            hasConnection: true,
            loader: false,
            discountCode: null,
            workManager: { taskId: null, userId: null, previousState: null, newState: null, boardId: null },
            filters: '{"andor":"and"}',
            version: versionJson.version,
            gapiLoaded: false,
            quickSearch: '',
            closedSortables: [],
            calculating: false,
            taskDescription: null,
            teste: null,
            dbUpdates: [],
            recurrentTaskUpdateModal: {
                isOpen: false,
                data: {
                    taskId: null,
                    updatedField: null,
                },
            },
            changeTaskLocationModal: {
                isOpen: false,
                data: {
                    taskId: null,
                    newParent: null,
                },
            },
            selectDependenciesModal: {
                isOpen: false,
                data: {
                    taskId: null,
                    newDependencies: null,
                },
            },
            lastRecalculate: 0,
            activeUserTime: {
                isSet: false,
                timer: null,
            },
            modals: {
                defaultModal: {
                    type: DefaultModalType.EMAIL_NOTIF_INFO,
                    show: false,
                },
                upgradeSubscriptionModal: {
                    show: false,
                },
                selectStatusModal: {
                    show: false,
                    taskId: null,
                }
            },
            popupGuides: null,
            isFirstCalendarConnected: false,
            disablePopupGuides: true,
            timelineTasksToShow: [],
            timelineFirstLevelTasks: [],
            syncApps: null
        };
    case constants.SET_ACCOUNT_PROPERTY_DATA:
        return { ...state, account: { ...state.account, [action.field]: action.data } };
    case constants.SET_ACCOUNTDATA_SYNC_APPS:
        return {...state, syncApps: action.data};
    case constants.SET_USERS:
        return {
            ...state,
            account: { ...state.account, users: action.data },
            loaders: {
                ...state.loaders,
                users: false,
            },
        };
    case constants.SET_DAYS_OFF:
        return {
            ...state,
            account: {
                ...state.account,
                daysoff: action.data,
            },
            loaders: {
                ...state.loaders,
                daysoff: false,
            },
        };
    case constants.SET_SKILLS:
        return {
            ...state,
            account: {
                ...state.account,
                skills: action.data,
            },
            loaders: {
                ...state.loaders,
                skills: false,
            },
        };
    case constants.SET_TASKS_ESTIMATIONS:
        return { ...state, account: { ...state.account, tasksEstimations: action.data } };
    case constants.SET_TASKS: {
        const timeLineTasks = getTasks({
            tasks: action.data,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        const timelineTasksToShow = state.filters !== '{"andor":"and"}' ? state.timelineTasksToShow : timeLineTasks.tasksToShow;
        const timelineFirstLevelTasks = state.filters !== '{"andor":"and"}' ? state.timelineFirstLevelTasks : timeLineTasks.firstLevelTasks;

        return {
            ...state,
            account: {
                ...state.account,
                tasks: action.data,
            },
            loaders: {
                ...state.loaders,
                tasks: false,
            },
            timelineTasksToShow,
            timelineFirstLevelTasks
        };
    }
    case constants.SET_EVENTS:
        return { ...state, account: { ...state.account, events: action.data } };
    case constants.UPDATE_RECURRENT_TASK_MODAL: {
        const task = state.account.tasks[action.payload.data.taskId];

        if (task.isRecurringTask) {
            return { ...state, recurrentTaskUpdateModal: action.payload };
        }

        return state;
    }
    case constants.CLOSE_RECURRENT_TASK_MODAL: {
        return { ...state, recurrentTaskUpdateModal: action.payload };
    }
    case constants.SET_ACTIVE_USER_TIME: {
        return {
            ...state,
            activeUserTime: {
                isSet: true,
                timer: action.data,
            },
        };
    }
    case constants.GAPI_LOADED:
        return { ...state, gapiLoaded: action.status };
    case constants.CONNECTED:
        return { ...state, hasConnection: action.state };
    case constants.DEFINE_USER: {
        return {
            ...state,
            user: action.user
        };
    }
    case constants.DEFINE_USER_DATA: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: action.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            user: {
                ...state.user,
                data: action.data
            },
            calendarMembersToShow: (action.data?.userId) ? [action.data.userId]:[],
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.DEFINE_USER_ACCOUNTS:
        return { ...state, userAccounts: action.accounts };
    case constants.DEFINE_USER_CHALLENGES:
        return { ...state, userChallenges: action.data };
    case constants.DEFINE_USER_FIRST_STEPS:
        return { ...state, userFirstStepsConfig: action.data };
    case constants.DEFINE_USER_FIRST_CALENDAR_CONNECTED:
        return { ...state, isFirstCalendarConnected: action.data };
    case constants.DEFINE_USER_POPUP_GUIDE_CONFIG:
        return { ...state, popupGuides: action.data };
    case constants.DEFINE_ORDERED_TASKLIST:
        return { ...state, orderedTaskList: action.data };
    case constants.DEFINE_PARENTCHILDIDS: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: action.data.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            parentChildIds: action.data,
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.SET_FILTERS: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: action.data || '{"andor":"and"}',
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            filters: action.data || '{"andor":"and"}',
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.CLEAN_DBUPDATES:
        return { ...state, dbUpdates: [] };

    case constants.SHOW_LOADER:
        return { ...state, loader: true };

    case constants.HIDE_LOADER:
        return { ...state, loader: false };

    case constants.DEFINE_DISCOUNT_CODE:
        return { ...state, discountCode: action.code };
    case constants.UPDATE_FILTERS:{
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: action.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            filters: action.filters,
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }

    case constants.REMOVE_DISCOUNT_CODE:
        return { ...state, discountCode: null };

    case constants.SET_WORK_MANAGER:
        return { ...state, workManager: action.data };

    case constants.QUICK_SEARCH: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: action.data,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            quickSearch: action.data,
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.DEFINE_CLOSEDSORTABLES: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: action.data,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            closedSortables: action.data || [],
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }

    case constants.CALCULATING:
        return { ...state, calculating: action.data };

    case constants.SET_TASK_DESCRIPTION:
        var value = {};
        value[action.taskId] = action.value;
        return { ...state, taskDescription: value };

    case constants.UNSET_TASK_DESCRIPTION:
        return { ...state, taskDescription: null };

    case constants.RECALCULATE:
        return { ...state, lastRecalculate: Date.now() };

    case constants.OPEN_CHANGE_TASK_LOCATION_MODAL: {
        return { ...state, changeTaskLocationModal: action.payload };
    }
    case constants.CLOSE_CHANGE_TASK_LOCATION_MODAL: {
        return { ...state, changeTaskLocationModal: action.payload };
    }
    case constants.OPEN_SELECT_DEPENDENCIES_MODAL: {
        return { ...state, selectDependenciesModal: action.payload };
    }
    case constants.CLOSE_SELECT_DEPENDENCIES_MODAL: {
        return { ...state, selectDependenciesModal: action.payload };
    }
    case constants.SET_LIMITVIEWTO: {
        const timeLineTasks = getTasks({
            tasks: state.account.tasks,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: action.value,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            limitViewTo: action.value,
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.SHOW_FILTERS: {
        return { ...state, showFilters: action.value };
    }
    case constants.SET_CALENDAR_MEMBERS_TO_SHOW: {
        return { ...state, calendarMembersToShow: action.value };
    }
    case constants.COMBINED_ENGINE_UPDATE: {
        // eslint-disable-next-line no-case-declarations
        const timeLineTasks = getTasks({
            tasks: action.data.tasks,
            parentChildIdsParents: action.data.parentChildIds.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        const timelineTasksToShow = state.filters !== '{"andor":"and"}' ? state.timelineTasksToShow : timeLineTasks.tasksToShow;
        const timelineFirstLevelTasks = state.filters !== '{"andor":"and"}' ? state.timelineFirstLevelTasks : timeLineTasks.firstLevelTasks;

        return {
            ...state,
            account: {
                ...state.account,
                tasks: action.data.tasks,
                users: action.data.users,
            },
            orderedTaskList: action.data.orderedTaskList,
            parentChildIds: action.data.parentChildIds,
            timelineTasksToShow,
            timelineFirstLevelTasks
        };
    }
    case constants.SET_TIMELINE_RANGE: {
        return {
            ...state,
            timelineRange: action.value
        };
    }
    case constants.UPDATE_SINGLE_TASK: {
        const newTasksData = {
            ...state.account.tasks,
            [action.taskId]: action.data
        };

        const timeLineTasks = getTasks({
            tasks: newTasksData,
            parentChildIdsParents: state.parentChildIds?.parents,
            limitViewTo: state.limitViewTo,
            quickSearch: state.quickSearch,
            filters: state.filters,
            timezone: state.user?.data?.timezone || moment.tz.guess(),
            closedSortables: state.closedSortables,
            customFields: state.account?.settings?.customFields
        });

        return {
            ...state,
            account: {
                ...state.account,
                tasks: newTasksData
            },
            timelineTasksToShow: timeLineTasks.tasksToShow,
            timelineFirstLevelTasks: timeLineTasks.firstLevelTasks
        };
    }
    case constants.UPDATE_SINGLE_USER: {
        const newUsersData = {
            ...state.account.users,
            [action.userId]: action.data
        };

        return {
            ...state,
            account: {
                ...state.account,
                users: newUsersData
            }
        };
    }
    case constants.SET_TIMELINE_COLUMNS: {
        return {
            ...state,
            timelineColumns: action.data
        };
    }
    case constants.SET_TASKS_LOADED: {
        return {
            ...state,
            loaders: {
                ...state.loaders,
                tasks: false,
            }
        };
    }

    case constants.DEFAULT_MODAL:
        return { ...state, modals: { ...state.modals, defaultModal: { ...state.defaultModal, ...action.data } } };
    case constants.UPGRADE_SUBSCRIPTION_MODAL:
        return {
            ...state,
            modals: {
                ...state.modals,
                upgradeSubscriptionModal: {
                    ...action.data
                }
            }
        };

    case constants.SELECT_SOURCE_STATUS_MODAL:
        return {
            ...state,
            modals: {
                ...state.modals,
                selectStatusModal: {
                    ...action.data
                }
            }
        };

    default:
        return state;
    }
};

export default app;
