import React, { useState } from 'react';
import {  useIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Button from '@material-ui/core/Button';
import TaskEffortInputs from '../TaskEffortInputs/TaskEffortInputs';
import Tooltip from 'rc-tooltip';
import InfoIcon from '@material-ui/icons/Info';

const MultiEffortDetails = ({ setSelectedLines, showMultiDetails, selectedItems }) => {
    const [multiMaxEffort, setMax] = useState(1);
    const [multiMinEffort, setMin] = useState(0);
    const intl = useIntl();
    const t = (id) => intl.formatMessage({ id });

    const handleConfirm = () => {
        firebaseEvents
            .multiEffortChange(
                multiMinEffort,
                multiMaxEffort,
                selectedItems,
            )
            .then(() => {
                setSelectedLines();
                setMax(1);
                setMin(0);
            });
        showMultiDetails();
    };

    const handleChange = (min, max) => {
        setMax(max);
        setMin(min);
    };

    const handleNo = () => {
        showMultiDetails();
    };

    return   <div id="multiEffortDetails">
        <label>
        Set tasks effort to (hours){' '}
            <Tooltip placement="top" overlay={t('task.table.effort.help.text')}>
                <InfoIcon />
            </Tooltip>
        </label>
        <TaskEffortInputs
            onChange={handleChange}
            multiEffortDetails={true}
        />
        <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleConfirm}
        >
            Apply
        </Button>
        <Button variant="outlined" size="small" onClick={handleNo}>
            Cancel
        </Button>
    </div>;
};

export default MultiEffortDetails; 