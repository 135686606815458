import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { getRelativePosition } from 'chart.js/helpers';
import * as ChartJs from 'chart.js';
import Button from '@material-ui/core/Button';
import classnames from 'clsx';
import Paper from '@material-ui/core/Paper';
import SplitBy from '../SplitBy/SplitBy';
import OverViewCard from '../OverViewCard';
import TeamMembersTable from './TeamMembersTable';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Unautorized from '../../Unautorized/Unautorized';
import { showLoader, hideLoader } from '../../../views/App/AppActions';

import { 
    agregateData,
    getDataByTask,
    convertToDateTime,
    deepCloneDataSets,
    backgroundColor,
    borderDash,
    downloadAsImage,
    getColor,
    exportCSV, 
    validateNumber,
    getDateFormated,
    getMonth,
    getCsvFile
} from '../utils';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';

ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const chartConfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 	'Nº Team Members',
                data: [],
                fill: true,
                yAxisID: 'y',
                pointRadius: 0
            },
        ]
    },
    options: {
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 11
                    },
                    boxWidth: 5,
                    boxHeight: 5,
                }
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                callbacks: {
                    title: function(context){
                        return getDateFormated(context[0].label);
                    },
                },
                cornerRadius: 0,
                padding: 15,
                usePointStyle: true,
                backgroundColor: 'rgba(30,79,162, 0.9)',
                bodyColor: '#fff',
                borderColor: 'rgba(255,255,255,0.5)',
                borderWidth: 1,
                bodyFont: {
                    size:12
                },
                titleColor: '#fff',
                titleFont: {
                    size:14
                },
                boxWidth: 8,
                boxHeight: 8
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10,
                    callback: function(value) {
                        const labelValue = this.getLabelForValue(value);
                        if(moment(labelValue, 'YYYY', true).isValid()){
                            return labelValue;
                        }
                        else if(moment(labelValue, 'YYYY/MM/DD', true).isValid()){
                            return `${moment(labelValue, 'YYYY/MM/DD', true).format('MMM D, YY')}`;
                        }
                        else if(moment(labelValue, 'M/YYYY', true).isValid()){
                            return `${moment(labelValue, 'M/YYYY').format('MMM YY')}`;
                        }
                        
                        return `${moment(labelValue).format('MMM D, YY')}`;
                    },
                    color: '#999',
                    font: {
                        size: 11
                    }
                },
                grid: {
                    drawTicks: false,
                    display: false,
                },
            },
            y: {
                drawTicks: false,
                stacked: true,
                display: true,
                position: 'left',
                ticks: {
                    maxTicksLimit: 10,
                    color: '#999',
                    font: {
                        size: 11
                    }
                }
            },
        },
    },
};

const TeamMembersChart = ({ 
    accountId,
    datesRange,
    dateFormat,
    hasAutorization,
    splitBy,
    setSplitBy,
    userTimezone
}) => {
    const chartCanvas = useRef(null);
    const [currentChart, setCurrentChart] = useState(null);
    const [currentData, setCurrentData] = useState({labels: [], datasets: {} } );
    const [displayData, setDisplayData] = useState({labels: [], datasets: {} } );
    const dispatch = useDispatch();
    const [csvData, setCSVData] = useState({});
    const [workspaceData, setWorkspaceData] = useState([]);
    
    useEffect( () => {
        dispatch(showLoader());
        getCsvFile(accountId, 'root', userTimezone).then(async (result) => {
            const data = await getDataByTask({
                accountId: accountId,
                workspaceData: result,
                selectedTasks: [],
                userTimezone
            });
            
            setWorkspaceData(result);
            setCSVData(data);
            dispatch(hideLoader());
        });
    }, [accountId]);

    // Should only occor on the mount 
    useEffect( () => {
        if(chartCanvas?.current) {
            dispatch(showLoader());
            const chart = new ChartJs.Chart(chartCanvas.current.getContext('2d'), chartConfig);
            const chartHoverHandler = (e) => { 
                const canvasPosition = getRelativePosition(e, chart);
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
    
                Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
                
                document.querySelectorAll(`[data-column="${dataX + 1}"]`).forEach((tChild) => {
                    tChild.classList.add('hover-row');
                });

                const ReactVirtGrid = document.getElementsByClassName('ReactVirtualized__Grid');
                if(
                    ReactVirtGrid.length > 1 &&
                   ReactVirtGrid[1]?.children.length &&
                   ReactVirtGrid[1]?.children[0]?.children.length
                ){
                    const width = document.getElementsByClassName('ReactVirtualized__Grid')[1].children[0].children[0].style.width.replace('px', '');
                    document.getElementsByClassName('ReactVirtualized__Grid')[1].scroll({ left: (dataX * width)  - 60 });
                }
            };
    
            chart.options.onHover = chartHoverHandler;
            chartCanvas.current.onmouseleave = () => Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
            setCurrentChart(chart);
        }
    }, [chartCanvas]); 

    const onColumnHover = (columnIndex) => {
        Object.values(document.getElementsByClassName('hover-row')).forEach(el => el.classList.remove('hover-row'));
        if(columnIndex > 0) {
            document.querySelectorAll(`[data-column="${columnIndex}"]`).forEach((tChild) => {
                tChild.classList.add('hover-row');
            });

            const columnElements = currentChart.data.datasets.map((dataset, idx) => {
                return {datasetIndex: idx, index: columnIndex - 1};
            });

            currentChart.tooltip.setActiveElements(columnElements);
            currentChart.update();
            return;
        }
  
        currentChart.tooltip.setActiveElements([]);
        currentChart.update();
    };

    const handleExportAsCSV = () => {
        const formatTitle = (title) =>  getDateFormated(title);

        const renderColumns = (item) => {
            let line = '';
            line += `${item.name}\n`;
            line += `Nº Team Members;${item.nbTeamMembers.join(';')}\n`;
            return line;
        };
        exportCSV(displayData.datasets,displayData.labels, renderColumns, formatTitle, 'team-members-data');
    };

    const handleData =  async () => {
        if(currentChart && !_.isEmpty(csvData)) {
            const startDate = moment(datesRange.startDate);
            const endDate = moment(datesRange.endDate);
            let possibleBreaks = [];
            // get all the tasks IDS 
            const allChartPoints = Object.values(csvData)
                .flat()
                .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
                .sort((a, b) => a.Datetime - b.Datetime)
                .reduce((acc, el) => {
                    const newDate = new Date(parseInt(el.Datetime)*60*60*4 * 1000);
                    const formatedDate =  `${newDate.getFullYear()}/${getMonth(newDate.getMonth() + 1)}/${newDate.getDate()}`; 
                   
                    if(acc[formatedDate]){
                        return {
                            ...acc,
                            [formatedDate]: [el,...acc[formatedDate]]   
                        };
                    }
                    
                    return {
                        ...acc,
                        [formatedDate]: [el]   
                    };
                }, {});
         

            // have to go throw all of the checkpoints 
            const newData = Object.entries(allChartPoints).reduce((chartPointAcc, [key, csvLines]) => {
                // if there is no break down we aggregate the information.
                const values = csvLines.map((line, idx) =>  {
                    return {
                        id: `default${idx}`,
                        label: '',
                        nbTeamMembers: parseInt(line['Nb Team members']),
                        tasksId: [line.id],
                        parentName: ''
                    };
                });

                return { ...chartPointAcc, 
                    [key]: values
                };
            }, {});

            const chartData = Object.entries(newData).reduce((acc, [key, items]) => {
                acc.labels.push(key);
            
                items.forEach((el, itemIdx) => {
                    if (!acc.datasets[el.id+'-nbTeamMembers']) {
                        const [colorA ] = getColor(possibleBreaks.length,itemIdx);
                        acc.datasets[el.id+'-nbTeamMembers'] =  {
                            ...el,
                            // missing this one
                            id: el.id,
                            label:  el.label + ' Nº Team Members',
                            data: [validateNumber(el.nbTeamMembers)],
                            borderColor: colorA,
                            backgroundColor: colorA,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 0,
                            borderWidth: 2,
                            pointBorderColor: '#fff',
                            pointBorderWidth: 1,
                            source: el.tasksId,
                            labelType: 'nbTeamMembers',
                            segment: { 
                                borderWidth: 2,
                                borderDash: borderDash(currentChart, userTimezone),
                                backgroundColor: backgroundColor(colorA, currentChart, userTimezone),
                            } 
                        };
                    } else {
                        acc.datasets[el.id+'-nbTeamMembers'].data.push(validateNumber(el.nbTeamMembers)); 
                    }
                });

                return acc;
            } , { 
                labels: [], 
                datasets: {}
            });

                  
            if(!moment(chartData.labels[0]).tz(userTimezone).isSame(startDate, 'day')){
                chartData.labels = [startDate.format('YYYY/MM/DD'),...chartData.labels];
                Object.values(chartData.datasets)
                    .forEach(el => {
                        el.data =  [el.data[0],...el.data];
                    });
            }

            if(!moment(_.last(chartData.labels)).tz(userTimezone).isSame(moment(), 'day')){
                chartData.labels.push(moment().tz(userTimezone).format('YYYY/MM/DD'));
                Object.values(chartData.datasets).forEach(el => el.data.push(_.last(el.data)));
            }

            chartData.labels.push(moment().tz(userTimezone).add(1, 'day').format('YYYY/MM/DD'));
            chartData.labels.push(endDate.format('YYYY/MM/DD'));
            Object.values(chartData.datasets).forEach(el => el.data.push(...[null,null]));
            
            // need to find the lowest value
            let min = -1;
            let max = 0;
            Object.values(chartData.datasets).forEach(el => {
                el.data.forEach(entry => {
                    if(max === 0 || max < entry)
                        max = entry;
                    if((min > entry || min === -1) && entry !== null)
                        min = entry;
                });
            });
            currentChart.options.scales.y.min = min === 0 ? 0 : min - (min * 0.10);
            currentChart.options.scales.y.max = max === 0 ? 0 : max + (max * 0.10);
            // this will add every single day to our datasets
            const aggregatedDataByDay = agregateData('day', deepCloneDataSets(chartData));
            /*         let displayData = aggregatedDataByDay;
            if(splitBy !== 'day'){
                displayData = agregateData(splitBy, deepCloneDataSets(aggregatedDataByDay));
            }
    
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            */
            setCurrentData(aggregatedDataByDay); 
        /*             setDisplayData(displayData);    */
        }
        dispatch(hideLoader());
    };
    
    useEffect(() => {
        if(currentChart && currentData){
            const displayData = agregateData(splitBy, deepCloneDataSets(currentData));
            currentChart.data.datasets = Object.values(displayData.datasets);
            currentChart.data.labels = displayData.labels;
            currentChart.update();
            currentChart.resize();
            setDisplayData(displayData);
        }
    }, [splitBy,currentData, currentChart]);

    const onChangeSplit = (e, mode) => {
        setSplitBy(mode);
    }; 
    
    useEffect(()=> {
        handleData();
    }, [datesRange,  csvData, currentChart]);
    
    const overviewCurrentData = React.useMemo(() => {
        const startDate = moment(datesRange.startDate);
        const endDate = moment(datesRange.endDate);
        const sortedData = workspaceData
            .filter((a)=> convertToDateTime(a.Datetime).isBetween(startDate, endDate))
            .sort((a, b) => a.Datetime - b.Datetime);

        const lastValue = sortedData.length ?  parseInt(sortedData[sortedData.length - 1]['Nb Team members']) : 0;

        return {
            totalTeamMembers: lastValue,
        };
    }, [datesRange, workspaceData]); 

    if (!hasAutorization) {
        return <Unautorized />;
    }

    return <>
        <Paper className="topSection"> 
            <div className="splitByContainer">
                <SplitBy
                    currentValue={splitBy}
                    onChange={onChangeSplit}
                />
            </div>
        </Paper>
        <Paper>
            <div className={classnames('bigNumbers')}>
                <OverViewCard 
                    title={'Total Team Members'} 
                    value={overviewCurrentData.totalTeamMembers}
                />
            </div>
            <div className={classnames('chartArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as img" startIcon={<ImageIcon />}  onClick={()=> downloadAsImage(chartCanvas.current)}>
                        Save as Img
                    </Button>
                </div>
                <canvas height="300" width="800" ref={chartCanvas} />
            </div>
            <div className={classnames('dataTableArea')}>
                <div>
                    <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                        Save as CSV
                    </Button>
                </div>
                <TeamMembersTable 
                    onColumnHover={onColumnHover}
                    userDateFormat={dateFormat}
                    datasets={displayData.datasets} 
                    labels={displayData.labels}
                />
            </div>
        </Paper>
    </>;
};

TeamMembersChart.propTypes = {
    stateTasks: Proptypes.object.isRequired,
    workspaceData: Proptypes.array.isRequired,
    workspaceSelected: Proptypes.string.isRequired,
    datesRange: Proptypes.shape({
        startDate: Proptypes.object.isRequired,
        endDate: Proptypes.object.isRequired,
    }).isRequired,
    accountId: Proptypes.string.isRequired,
    childsParents: Proptypes.object.isRequired,
};

export default withCustomErrorBoundary(TeamMembersChart);