import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { updateTeamMemberBillableCost, updateTeamMemberInternalCost } from  '../../../utils/controllers/tasks/teamMemberCosts';
import Proptypes from 'prop-types';
import { getSymbol } from '../../../utils/currencyFormat';
import { withCustomErrorBoundary } from '../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { getAccountCostBudgetCurrency, getAccountUsers } from '../../../utils/selectors/account';


const BudgetManagementTab = ({ router }) => {
    const userId = router.params.uid;
    const intl = useIntl();
    const [internalCost, setInternalCost] = useState(0);
    const [billableCost, setBillableCost] = useState(0);
    const hasPermission = false;
    const currency = useSelector(getAccountCostBudgetCurrency || 'USD');
    const accountTeamMembers = useSelector(getAccountUsers);
    const currencySymbol = getSymbol(currency);
    const defaultInternal = _.get(accountTeamMembers, [userId,'cost', 'internalCost','value'], 0);
    const defaultBillable = _.get(accountTeamMembers, [userId,'cost', 'billableCost','value'], 0);
    
    useEffect(() => {
        setInternalCost(defaultInternal || 0);
    },[defaultInternal, userId]);

    useEffect(() => {
        setBillableCost(defaultBillable || 0);
    },[defaultBillable, userId]);

    const updateInternalCostValue = ({ target: { value }}) => {
        if (!value || isNaN(value)) {
            setInternalCost(0);
            return;
        }
        
        const newValue = value.includes('.') ? parseFloat(value).toFixed(2) : parseFloat(value);
        setInternalCost(newValue);
        updateTeamMemberInternalCost(userId, newValue);
    };

    const updateBillableCostValue = ({ target: { value }}) => {
        if (!value || isNaN(value)) {
            setBillableCost(0);
            return;
        }
        const newValue = value.includes('.') ? parseFloat(value).toFixed(2) : parseFloat(value);
        setBillableCost(newValue);
        updateTeamMemberBillableCost(userId, newValue);
    };

    const onChangeInternalCost = ({ target: { value }}) => {
        setInternalCost(value.replace(',','.'));
    };

    const onChangeBillableCost = ({ target: { value }}) => {
        setBillableCost(value.replace(',','.'));
    };

    return <div className="BudgetManagement">
        <div className="fields">
            <div className="container">
                <div className="inputTitle">
                    {intl.formatMessage({id: 'internal-cost'})}
                </div>
                <div className="input">
                    <Input
                        onChange={onChangeInternalCost}
                        value={internalCost}
                        disabled={hasPermission}
                        id="internalCost"
                        type="number"
                        onBlur={updateInternalCostValue}
                        endAdornment={<InputAdornment position="end">{currencySymbol}/hour</InputAdornment>}
                    />

                </div>
            </div>
            <div className="container">
                <div className="inputTitle">
                    {intl.formatMessage({id: 'billable-cost'})}
                </div>
                <div className="input">
                    <Input
                        value={billableCost}
                        onChange={onChangeBillableCost}
                        disabled={hasPermission}
                        id="billableCost"
                        type="number"
                        onBlur={updateBillableCostValue}
                        endAdornment={<InputAdornment position="end">{currencySymbol}/hour</InputAdornment>}
                    />
                </div>
            </div>
        </div>
        <div className="legend">
            <div>
                <p>{intl.formatMessage({id: 'employee.budget-management.info.internal-cost'})}</p>
                <p>{intl.formatMessage({id: 'employee.budget-management.info.billable-cost'})}</p>
            </div>
        </div>
    </div>;
};

BudgetManagementTab.propTypes = {
    userId: Proptypes.string,
    hidden: Proptypes.bool.isRequired,
}; 

export default withRouter(withCustomErrorBoundary(BudgetManagementTab));