import React, { memo } from 'react';
import * as firebaseEvents from '../../utils/firebaseEvents'; 
import classnames from 'clsx';
import moment from 'moment';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { getAccountId, getAccountTask, getAccountTasks, getAccountUsers } from '../../utils/selectors/account';
import { getUserAvatar, tracking } from '../../utils/utils';
import { getParentChildIds } from '../../utils/selectors/app';
import { getUserDateformat, getUserId, getUserTimezone } from '../../utils/selectors/user';
import { Button } from '@material-ui/core';
import OpenTaskIcon from '@material-ui/icons/OpenInNew';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import DeleteIcon from '@material-ui/icons/Delete';

const TaskDetailTimeWorked = ({ taskId, activeTab, currentUrl }) => {
    const intl = useIntl();

    const task = useSelector(getAccountTask(taskId));
    const users = useSelector(getAccountUsers);
    const userId = useSelector(getUserId);
    const tasks = useSelector(getAccountTasks);
    const parentChildIds = useSelector(getParentChildIds);
    const dateFormat = useSelector(getUserDateformat);
    const accountId = useSelector(getAccountId);
    const timezone = useSelector(getUserTimezone);

    let trackingCsv = '"Task","Who","Date","Hours"\n';

    const getTimeTrackedTable = () => {
        if(!task) return null;

        let list = [];
        let totalHours = 0;
        
        if (task.childrens) {
            let childrens = [];
            _.each(parentChildIds?.parents, (childParents, childId) => {
                if (childParents.indexOf(taskId) !== -1) {
                    childrens.push(childId);
                }
            });
            
            _.each(childrens, child => {
                if (tasks && tasks[child]) {
                    _.each(tasks[child]?.workingTime, (workTimeChild, workTimeChildId) => {
                        const nbWorkers = (!workTimeChild?.member || _.isString(workTimeChild?.member)) ? 1 : workTimeChild?.member.length;
                        list.push({
                            child: true,
                            childId: child,
                            workTimeId: workTimeChildId,
                            member: workTimeChild.member,
                            date: workTimeChild?.date,
                            hours: workTimeChild?.hours,
                        });
                        totalHours += parseFloat(workTimeChild?.hours)*nbWorkers;
                    });
                }
            });
        }
        
        _.each(task?.workingTime, (workTime, workTimeId) => {
            const nbWorkers = (!workTime?.member || _.isString(workTime?.member)) ? 1 : workTime?.member?.length;
            list.push({
                child: false,
                workTimeId,
                member: workTime.member,
                date: workTime.date,
                hours: workTime.hours,
            });
            totalHours += parseFloat(workTime.hours)*nbWorkers;
        });
        
        if (list.length === 0) {
            return (
                <div className="noTimetracking">
                    <img src="../../../stylesheets/assets/noevent.png?v=2" alt="No time tracked" />
                    <h1>{intl.formatMessage({id: 'no time worked for now'})}</h1>
                </div>
            );
        }
        
        list = _.sortBy(list, 'date');
        let renderedList = [];
        
        _.each(list, item => {
            let childClass = '';
            let icon = <DeleteIcon onClick={() => removeTimeWorked(item.workTimeId)} />;
            
            if (item.child && tasks) {
                childClass = 'childWorkTime';
                icon = <OpenTaskIcon onClick={() => browserHistory.push('/' + currentUrl + '/' + item.childId)} />;
            }
            
            if (!task.canManage && item.member !== userId) {
                icon = null;
            }

            const timeTrackedMembers = item.member ? !_.isArray(item.member) ? [item.member] : item.member : [];
            const dateOfWork = moment.tz(moment(item.date), dateFormat || 'DD/MM/YYYY', timezone || moment.tz.guess()).format('DD/MM/YYYY');

            timeTrackedMembers.forEach((memberId) => {
                trackingCsv +=
                '"' +
                (tasks[item.childId] ? tasks[item.childId].title : tasks[taskId]?.title) +
                '","' +
                (users[memberId] ? users[memberId].displayName : intl.formatMessage({id: 'User deleted'})) +
                '","' +
                dateOfWork +
                '","' +
                parseFloat(item.hours).toFixed(2) +
                '"\n';
            });
            
            renderedList.push(
                <TableRow key={item.workTimeId} className={childClass} hover={true}>
                    <TableCell>
                        {dateOfWork}
                    </TableCell>
                    <TableCell>
                        <ul className='timeTrackingSection__user'>
                            {timeTrackedMembers.map((memberId, index)=> {
                                return <li key={`${memberId}-${index}`}>
                                    {getUserAvatar(memberId, false, users[memberId]?.avatar)}
                                    <strong>{users[memberId] ? users[memberId].displayName : intl.formatMessage({id: 'User deleted'})}</strong>
                                </li>;
                            })}
                        </ul>
                    </TableCell>
                    <TableCell>{parseFloat(item.hours).toFixed(2)}</TableCell>
                    <TableCell>{icon}</TableCell>
                </TableRow>,
            );
        });

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{intl.formatMessage({id: 'Date'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'Who'})}</TableCell>
                        <TableCell>{intl.formatMessage({id: 'Hours'})}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderedList}</TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{intl.formatMessage({id:'Total'})}</TableCell>
                        <TableCell>{parseFloat(totalHours).toFixed(2)}</TableCell>
                        <TableCell>
                            <a onClick={exportTrackingCsv}>{intl.formatMessage({id: 'Export to CSV'})}</a>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    };

    const exportTrackingCsv = () => {
        const csvContent = 'data:text/csv;charset=utf-8,' + trackingCsv;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('data-sidebar', 'preventClose');
        link.setAttribute('download', 'export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        tracking('Downloaded time worked CSV file', userId);
    };

    const removeTimeWorked = (workTimeId) => {
        const action = firebaseEvents.removeTimeWorked({accountId, taskId, timeWorkedId: workTimeId});

        toastr.success(intl.formatMessage({id: 'Time worked deleted'}), '', {
            component: (
                <a
                    data-sidebar='preventClose'
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        action.undo();
                        toastr.removeByType('success');
                    }}
                >
                    {intl.formatMessage({id: 'Cancel'})}
                </a>
            ),
        });
    };

    return (
        <div className={classnames('timeTrackingSection', { hide: activeTab !== 'time' })}>
            <Button
                className="addTimetracking"
                color="primary"
                size="small"
                onClick={() => browserHistory.push('/' + currentUrl + '/' + taskId + '/addWorkTime')}
                disabled={!task.canAdmin && !task.canManage && !task.canWork}
            >
                {intl.formatMessage({id: 'ADD TIME WORKED' })}
            </Button>

            <div className="timeTackingList">{getTimeTrackedTable()}</div>
        </div>
    );
};

TaskDetailTimeWorked.propTypes = {
    currentUrl: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default memo(TaskDetailTimeWorked);
