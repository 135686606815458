import React, { useEffect, useState } from 'react';
import SkillSlider from '../../components/SkillSlider';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import StateUserAvatar from '../UserAvatar/StateUserAvatar';


const TeamMemberSlider = ({userId, displayName,  skillId, skillLvl: userSkillLvl}) => {
    const [skillLvl, setSkillLvl] = useState(userSkillLvl);

    useEffect(() => {
        if(skillLvl !== userSkillLvl) {
            setSkillLvl(userSkillLvl);
        }
    }, [userSkillLvl]);

    const updateSlider = (e, value) => {
        setSkillLvl(value);
    };

    const updateSliderCommit = (e, value) => {
        setSkillLvl(value);
        firebaseEvents.addChallenge('changeMemberSkillLevuser');

        firebaseEvents.addSkillToUsers(
            {
                skillKey: skillId,
                usersAndSkillLvl: [{
                    userId: userId,
                    skillLvl: value
                }]
            }
        );
    };

    return (
        <div className="User_Skill_Container">
            <StateUserAvatar 
                className="avatar"
                userId={userId}
                disableTooltip={true}
            />
            <div className="userName"> {displayName} </div>
            <div className="skill_slider">
                <SkillSlider
                    onChange={(e, value) => updateSlider(e, value)}
                    onChangeCommitted={(e, value) => updateSliderCommit(e, value)}
                    value={skillLvl >= 0 ? skillLvl : 5}
                />
            </div>
        </div>
    );
};

export default withCustomErrorBoundary(TeamMemberSlider);
