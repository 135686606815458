var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import 'firebase/storage';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { getRecurrentTaskUpdateModal } from '../../utils/selectors/app';
import { closeReccurentTaskModal } from '../App/AppActions';
var RecurringTasksChangeModal = function () {
    var dispatch = useDispatch();
    var _a = __read(useState('0'), 2), setting = _a[0], setSetting = _a[1];
    var formatMessage = useIntl().formatMessage;
    var recurrentTaskUpdateModal = useSelector(getRecurrentTaskUpdateModal);
    useEffect(function () {
        setSetting('0');
    }, [recurrentTaskUpdateModal]);
    var handleSave = function () {
        dispatch(closeReccurentTaskModal());
        if (setting !== '0') {
            firebaseEvents.updateRecurentTasks({
                taskId: recurrentTaskUpdateModal.data.taskId,
                editField: recurrentTaskUpdateModal.data.updatedField,
                afterToday: setting === '2',
            });
        }
    };
    var handleInputs = function (_a) {
        var value = _a.target.value;
        setSetting(value);
    };
    return (React.createElement(Dialog, { maxWidth: "sm", className: "RecurringTasksChangeModal", disableEscapeKeyDown: true, "aria-labelledby": "customized-dialog-title", open: recurrentTaskUpdateModal.isOpen },
        React.createElement(MuiDialogTitle, { className: "Modal__Title", disableTypography: true },
            React.createElement(Typography, { variant: "h6" }, formatMessage({ id: 'Recurrent task updated' }))),
        React.createElement(MuiDialogContent, { className: "content", dividers: true },
            React.createElement("p", null, "You changed a recurrent Task, do you want to update the following ones?"),
            React.createElement(FormControl, { component: "fieldset" },
                React.createElement(RadioGroup, { value: setting, onChange: handleInputs, className: "buttons-container", id: "onValue", name: "onValue" },
                    React.createElement(FormControlLabel, { value: "0", control: React.createElement(Radio, { size: "small" }), label: "Only this occurence" }),
                    React.createElement(FormControlLabel, { value: "1", control: React.createElement(Radio, { size: "small" }), label: "This occurrence and all occurrences after this one" }),
                    React.createElement(FormControlLabel, { value: "2", control: React.createElement(Radio, { size: "small" }), label: "All future occurrences" })))),
        React.createElement(MuiDialogActions, null,
            React.createElement(Button, { variant: "contained", color: "primary", onClick: handleSave }, formatMessage({ id: 'Save' })))));
};
export default RecurringTasksChangeModal;
