import React, { useEffect, useState } from 'react';
import {  useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'clsx';
import * as firebaseEvents from '../../utils/firebaseEvents';
import TextField from '@material-ui/core/TextField';
import { toastr } from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAccountTags } from '../../utils/selectors/account';

const TagColor = ({ handleSelectColor, id, currentSelected }) => {
    const handleClick = () => handleSelectColor(id);

    return  <div
        onClick={handleClick}
        className={classnames(`color${id}`, { active: currentSelected === id })}
    />;
};

const AddTagDialog = ({ handleCancel, handleTagCreated, tagName})  => {
    const [newTagColor, setNewTagColor] = useState(1);
    const [tagDialogText, setTagDialogText] = useState('');
    const tags = useSelector(getAccountTags);
    const intl = useIntl();
    const t = (id) => intl.formatMessage({ id });

    const handleNewTagColor = (colorId) => setNewTagColor(colorId);

    useEffect(() => {
        setTagDialogText(tagName);
    }, [tagName]);

    const saveNewTag = () => {
        var existingTags = [];
        if (tags) {
            for (var i in tags) {
                existingTags.push(tags[i].title);
            }
        }

        if (tagDialogText === '') {
            toastr.error('Please enter a Tag name');
        } else if (existingTags.indexOf(tagDialogText) !== -1) {
            toastr.error('A tag with this name already exist');
        } else {
            firebaseEvents.saveNewTag(null, tagDialogText, newTagColor).then(newTagKey => {
                setTagDialogText('');
                setNewTagColor(1);
                handleTagCreated(newTagKey);
            });
        }
    };

    const handleChangeInput = e => {
        setTagDialogText(e.target.value);
    };

    return (
        <Dialog
            className="addTagDialog"
            open={true}
            onClose={handleCancel}
            maxWidth={false}
        >
            <DialogTitle>{t('Create a tag')}</DialogTitle>
            <DialogContent className="content">
                <TextField
                    label="Tag name"
                    value={tagDialogText}
                    fullWidth
                    onChange={handleChangeInput}
                />
                <div className="colors">
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={1}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={2}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={3}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={4}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={5}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={6}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={7}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={8}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={9}
                        currentSelected={newTagColor}
                    />
                    <TagColor 
                        handleSelectColor={handleNewTagColor}
                        id={10}
                        currentSelected={newTagColor}
                    />
                </div>
            </DialogContent>
            <DialogActions className="actions">
                <Button
                    variant="contained"
                    onClick={handleCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button color="primary" variant="contained" onClick={saveNewTag}>
                    {t('Create and Add Tag')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddTagDialog; 