import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import EditTagModal from '../EditTagModal/EditTagModal';
import _ from 'underscore';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';


const SelectTag = ({ open, currentTags, onClose, onSelect }) => {
    const intl = useIntl();
    const [checkedItens, setCheckItens] = useState([]);
    const [isEditTagOpen, setIsEditTagOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const accountTags = useSelector(state => state.app?.account?.tags);

    useEffect(() => {
        if(open) {
            setSearchValue('');
            if(currentTags) {
                setCheckItens(currentTags);
            }
        }
    }, [currentTags, open]);

    const tagsList = React.useMemo(() => {
        let list = [];
        if(accountTags){

            for(let tagId in accountTags){
                if (accountTags[tagId].title.toLowerCase().match(searchValue.toLowerCase())) {
                    list.push({ ...accountTags[tagId], id: tagId });
                } 
            }
        }
        return list;
    }, [accountTags, searchValue]);

    const handleSelectTag = React.useCallback((selectedId) => {
        

        if (checkedItens.includes(selectedId)) {
            setCheckItens(checkedItens.filter(el => el !== selectedId));
            return;
        }

        setCheckItens([...checkedItens, selectedId]);
    }, [checkedItens]);

    const handleSearch = React.useCallback(({ target: { value } }) => {
        setSearchValue(value);
    }, []);

    const handleEditTag = () => {
        setIsEditTagOpen(!isEditTagOpen);
    };

    const handleEditTagSubmit = (key) => {
        handleSelectTag(key);
        setIsEditTagOpen(false);
        setSearchValue('');
    };

    const handleSubmit = () => {
        onSelect(checkedItens);
    };
    return <>
        <Dialog maxWidth={'md'} className="SelectTag_Modal" onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <MuiDialogTitle className="title" disableTypography >
                <Typography variant="h6"> {intl.formatMessage({ id: 'Select Tags' })}</Typography>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent className="content" dividers>
                <div className="input-search-container">
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment className="search-icon" position="start"><SearchIcon /></InputAdornment>,
                        }}
                        placeholder="Search or type to create a new tag..."
                        className="input-search"
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                    />
                </div>
                {!_.isEmpty(tagsList) && <div className="tag-list-container">
                    {
                        tagsList && tagsList.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1).map(tag =>
                            <div onClick={() => handleSelectTag(tag.id)} className={'SelectTag_Tag_empty'} id={tag.id} key={tag.id} >
                                <div>
                                    <span className={'colorBubble' + tag.color} />
                                    <span>{tag.title}</span>
                                    <LineAction checkedItens={checkedItens} lineId={tag.id} handleSelectTag={handleSelectTag} />
                                </div>
                            </div>
                        )
                    }
                </div>
                }
                {
                    _.isEmpty(tagsList) && <div className="Tag-not-found-container">
                        <div className="Tag-not-found">
                            {intl.formatMessage({ id: 'No tag found' })}
                        </div>
                    </div>
                }

                <div className="Tag-not-found-btn">
                    <Button onClick={handleEditTag} variant="contained" fullWidth>
                        {
                            searchValue.trim().length >= 1 && <> {intl.formatMessage({ id: 'CREATE' })} <span className="task-name-btn"> "{searchValue}" </span> {intl.formatMessage({ id: 'TAG' })}</>
                        }
                        {
                            !searchValue.trim().length && intl.formatMessage({ id: 'CREATE NEW TAG' })
                        }
                    </Button>
                </div>
            </MuiDialogContent>
            <MuiDialogActions className='actions'>
                <Button
                    className="cancel-btn"
                    onClick={onClose}
                    variant="contained"
                >
                    {intl.formatMessage({ id: 'CANCEL' })}
                </Button>
                <Button
                    className="submit-btn"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {intl.formatMessage({ id: 'Save Changes' })}
                </Button>
            </MuiDialogActions>
        </Dialog >
        {
            isEditTagOpen && <EditTagModal
                isOpen={isEditTagOpen}
                tagName={searchValue.trim()}
                onSubmit={handleEditTagSubmit}
                onClose={handleEditTag}
            />
        }
    </>;
};


const LineAction = ({ checkedItens, lineId, handleSelectTag }) => {
    return <Checkbox
        color='secondary'
        value={lineId}
        onChange={() => handleSelectTag(lineId)}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={checkedItens.includes(lineId)}
    />;
};


SelectTag.propTypes = {
    open: Proptypes.bool.isRequired,
    currentTags: Proptypes.array,
    onClose: Proptypes.func.isRequired,
    onSelect: Proptypes.func.isRequired
};

export default SelectTag;