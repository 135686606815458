import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Tooltip from 'rc-tooltip';
import classnames from 'clsx';
import moment from 'moment-timezone';
import Fab from '@material-ui/core/Fab';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { getUserId } from '../../utils/selectors/user';
import { getAccountTaskStartedWorkingAt, getAccountTaskStatus, getAccountTaskTimerPause, getAccountTaskTimerStart, getAccountTaskUserworking } from '../../utils/selectors/account';
import {withCustomErrorBoundary} from '../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { useStopwatch } from 'react-timer-hook';
import { getTimeDurationInSeconds, DURATION_DAY_HOURS } from '../../utils/utils';

const TaskDetailStopwatch = ({ taskId })=>{
    const currentUser = useSelector(getUserId);
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskTimerPauseDate = useSelector(getAccountTaskTimerPause(taskId));
    const taskTimerStartDate = useSelector(getAccountTaskTimerStart(taskId));
    const userWorking = useSelector(getAccountTaskUserworking(taskId));
    const startedWorkingAt = useSelector(getAccountTaskStartedWorkingAt(taskId));

    const getTimeWorkedInSeconds = useCallback(() => {
        const startAt = taskTimerStartDate ?? startedWorkingAt;
        const endAt = taskTimerPauseDate ?? moment();
        return getTimeDurationInSeconds(startAt, endAt);
    }, [taskTimerStartDate, taskTimerPauseDate, startedWorkingAt]);

    const timeWorkedInSeconds = getTimeWorkedInSeconds();

    const [changeTimer, setChangeTimer] = useState(false);
    const [changeHours, setChangeHours] = useState(0);
    const [changeMinutes, setChangeMinutes] = useState(0);
    const {
        days,
        hours,
        minutes,
        seconds,
        pause: pauseStopwatch,
        start: startStopwatch,
        reset: resetStopwatch
    } = useStopwatch({
        offsetTimestamp: moment().add(timeWorkedInSeconds, 'seconds'),
        autoStart: !taskTimerPauseDate
    });

    const totalHours = (days * DURATION_DAY_HOURS) + hours;

    const isTaskInProgress = useMemo(() => taskStatus === 'inprogress', [taskStatus]);

    useEffect(() => {
        const stopwatchAction = taskTimerPauseDate || !isTaskInProgress ? pauseStopwatch : startStopwatch;
        stopwatchAction();
    }, [taskTimerPauseDate, isTaskInProgress]);

    useEffect(() => {
        const newOffsetTimestamp = moment().add(timeWorkedInSeconds, 'seconds');
        resetStopwatch(newOffsetTimestamp, !taskTimerPauseDate);
    }, [taskTimerStartDate, taskTimerPauseDate, startedWorkingAt]);

    const saveTimer = (e) => {
        e.preventDefault();

        const timerStartDate = moment()
            .subtract(parseInt(changeHours), 'hours')
            .subtract(parseInt(changeMinutes), 'minutes')
            .format();

        const timerPauseDate = taskTimerPauseDate ? moment().format() : null;

        firebaseEvents.saveTimer(taskId, timerStartDate, timerPauseDate).then(() => {
            setChangeTimer(false);
        });
    };

    const isCurrentUserWorkingOnThisTask = useMemo(() =>
        (typeof userWorking === 'string' && userWorking === currentUser) ||
        (typeof userWorking !== 'string' && userWorking.includes(currentUser))
    , [userWorking, currentUser]);

    const openChangeTimer = () => {
        if (!isCurrentUserWorkingOnThisTask) {
            toastr.error('Only the user(s) working on the task can update the timer');
            return;
        }

        setChangeTimer(true);
        setChangeHours(totalHours);
        setChangeMinutes(minutes);
    };

    const pauseTimer = () => {
        if (!isCurrentUserWorkingOnThisTask) {
            toastr.error('Only the user(s) working on the task can update the timer');
            return;
        }

        const timerStart = moment().subtract(parseInt(timeWorkedInSeconds), 'seconds').format();
        const timerPause = moment().format();
        firebaseEvents.saveTimer(taskId, timerStart, timerPause).then(() => {
            setChangeTimer(false);
            pauseStopwatch();
        });
    };

    const playTimer = () => {
        if (!isCurrentUserWorkingOnThisTask) {
            toastr.error('Only the user(s) working on the task can update the timer');
            return;
        }

        const stopWatchStartedAt = moment().subtract(parseInt(timeWorkedInSeconds), 'seconds').format();

        firebaseEvents.saveTimer(taskId, stopWatchStartedAt, null).then(() => {
            setChangeTimer(false);
            startStopwatch();
        });
    };

    const bigIcon = isTaskInProgress && !taskTimerPauseDate ? <PauseIcon /> : <PlayIcon />;
    const bigTitle = isTaskInProgress && !taskTimerPauseDate ? 'Pause working' : taskTimerPauseDate ? 'Restart working' : 'Start working';
    const bigClick = isTaskInProgress && !taskTimerPauseDate ? pauseTimer : playTimer;
    const bigColor = isTaskInProgress ? 'secondary' : 'default';
    const bigSize = isTaskInProgress ? 'medium' : 'small';

    return (
        <div
            variant="outlined"
            className={classnames('Timer', { inprogress: taskStatus === 'inprogress' })}
        >
            <label>TIME ELAPSED</label>
            <div className="timerContent">
                <div className="controls">
                    <Tooltip placement="bottom" overlay={bigTitle}>
                        <Fab size={bigSize} color={bigColor} onClick={bigClick}>
                            {bigIcon}
                        </Fab>
                    </Tooltip>
                </div>
                <div className="counter">
                    <Tooltip placement="bottom" overlay="Click to change">
                        <div onClick={openChangeTimer}>
                            {`${totalHours}h ${minutes}m ${seconds}s`}
                        </div>
                    </Tooltip>
                </div>

                <Dialog
                    className="changeTimer"
                    open={changeTimer}
                    onClose={() => setChangeTimer(false)}
                    maxWidth={false}
                >
                    <DialogTitle>Set time spent to</DialogTitle>
                    <DialogContent className="content">
                        <form onSubmit={saveTimer}>
                            <TextField
                                autoFocus
                                type="number"
                                value={changeHours}
                                inputProps={{ min: 0 }}
                                onChange={e => setChangeHours(e.target.value)}
                            />
                        </form>
                        <span>hours</span>
                        <form onSubmit={saveTimer}>
                            <TextField
                                type="number"
                                value={changeMinutes}
                                inputProps={{ min: 0, max: 59 }}
                                onChange={e => setChangeMinutes(e.target.value)}
                            />
                        </form>
                        <span>minutes</span>
                    </DialogContent>

                    <DialogActions className="actions">
                        <Button variant="contained" onClick={() => setChangeTimer(false)}>
                                Cancel
                        </Button>
                        <Button variant="contained" onClick={saveTimer} color="primary">
                                Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );

};

export default withCustomErrorBoundary(TaskDetailStopwatch);