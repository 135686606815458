import React, {useEffect, useState } from 'react';
import {  withRouter } from 'react-router';
import {  getListenerQuotes, offQuotesListener } from '../../utils/databaseQuerys/quote';
import { PLANLESS_APP_URL } from '../../utils/constants';


const RenderQuote = (props) => {
    return <div>
        <div>
            Name: {props.name}
        </div>
        <div>
            Company Name: {props.companyName}
        </div>
        <div>
            Created At: {props.createAt}
        </div>
        <div>
            Link: {PLANLESS_APP_URL}/overviewoffer?code={props.id}
        </div>
    </div>;
};

const List = () => {
    const [quotes, setQuotes] = useState({});
    
    useEffect(() => {
        getListenerQuotes((data) => {
            setQuotes(data.val() || {});
        });
        return () => {
            offQuotesListener();
        };
    }, []);
 
    return <div>
        {Object.keys(quotes).map(el => <RenderQuote {...quotes[el]} />)}
    </div>;
};
export default withRouter(List);
