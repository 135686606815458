import React, { useEffect, useState } from 'react';
import {toastr} from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isAdmin } from '../../utils/userFunctions';
import { currencies  } from '../../utils/currencyFormat';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { updateCostBudgetSettings } from  '../../utils/controllers/tasks/budget';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const CurrencySettings = ()  => {
    const user = useSelector((state) => state.app.user);
    const costBudgetSetttings = useSelector((state) => state.app?.account?.settings.costBudget);
    const intl = useIntl();
    const [currency, setCurrency] = useState('USD');
    
    useEffect(() => {
        if(costBudgetSetttings){
            setCurrency(costBudgetSetttings?.currency);
        }
    }, [costBudgetSetttings]);

    const handleSelect = ({ target: { value }}) => {
        toastr.confirm(
            intl.formatMessage({id: 'update-currency-warning'}),
            {
                onOk: () => {
                    setCurrency(value);
                    updateCostBudgetSettings({
                        currency: value,
                    });
                },
                okText: intl.formatMessage({id: 'OK'}),
                cancelText: intl.formatMessage({id:  'toastr.confirm.cancel' })
            },
        );
    };

    if(!user){
        return null;
    }

    const isUserAdmin = isAdmin({user});
    if(!isUserAdmin){
        return null;
    }

    return <>
        <div className="currencyContainer">
            <FormControl className="selector">
                <InputLabel htmlFor="workspace-currency">{intl.formatMessage({id: 'workspace-currency'})}</InputLabel>
                <Select
                    className='selector__input'
                    value={currency}
                    onChange={handleSelect}
                >
                    {currencies.map(({value, name}) => <MenuItem key={value} value={value}>{name} </MenuItem>)}
                </Select>
            </FormControl>
        </div>
    </>;
};

export default withCustomErrorBoundary(CurrencySettings);