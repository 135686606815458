// eslint-disable-next-line import/prefer-default-export
export var sectionTypes = [
    { permissionType: 'admin', sectionTitle: 'Administrators' },
    { permissionType: 'team', sectionTitle: 'Can view/edit/delete members' },
    { permissionType: 'skills', sectionTitle: 'Can view/edit/delete Skills' },
    { permissionType: 'memberSkills', sectionTitle: 'Can users manage their own skills' },
    { permissionType: 'daysoff', sectionTitle: 'Can view/edit/delete Global days off' },
    { permissionType: 'boards', sectionTitle: 'Can view/edit/delete Boards settings' },
    { permissionType: 'subscription', sectionTitle: 'Can modify Subscription' },
    { permissionType: 'base', sectionTitle: 'Tasks base permissions' },
];
