/* eslint-disable max-len */
export var defaultModalInfo = {
    emailNotifInfo: {
        header: 'Email notifications are enabled',
        title: 'Notifications - New email notifications are ready for you always be on track.',
        desc: "Keep getting notified when relevant changes happen on tasks you're involved in. Now you can better and quicker understand the current scenario without leaving the email.",
        link: 'https://academy.planless.com/notifications/email-notifications',
        image: 'https://planless.sirv.com/App/blocked/emailNotifInfo.png',
        buttons: {
            secondary: { text: 'View settings' },
            primary: { text: 'Got it' },
        },
    },
    midTrial: {
        header: 'Time flies, and your trial is half way there.',
        title: 'Explore more of Planless capabilities in our Academy',
        desc: 'Whatever the type of project or workflow, Planless.io provides you with all the tools you need to manage your work, collaborate efficiently and analyze your past, present and future.',
        link: 'https://academy.planless.com/notifications/email-notifications',
        image: 'https://planless.sirv.com/App/blocked/planning_mentalmap.png',
        buttons: {
            secondary: { text: 'Manage subscription' },
            primary: { text: 'Got it' },
        },
    },
    memberTypes: {
        header: 'New unified members management.',
        title: 'Members - An unified way to manage your workspace users, resources, and viewers.',
        desc: 'Define if your member is a Resource Only - who can be assigned to tasks, and have their work planned - an User Only - who can manages project parameters - User and Resource or Viewer with the new settings section. Define if your member is a Resource Only - who can be assigned to tasks, and have their work planned - an User Only - who can manages project parameters - User and Resource or Viewer with the new settings section. Subscriptions plans are now based on number of resources',
        link: 'https://academy.planless.io/',
        image: 'https://planless.sirv.com/App/blocked/memberTypes.png',
        buttons: {
            secondary: { text: 'View settings' },
            primary: { text: 'Got it' },
        },
    },
};
