export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CONNECTED = 'CONNECTED';
export const LOGOUT = 'LOGOUT';
export const DEFINE_USER = 'DEFINE_USER';
export const DEFINE_USER_DATA = 'DEFINE_USER_DATA';
export const DEFINE_USER_ACCOUNTS = 'DEFINE_USER_ACCOUNTS';
export const DEFINE_USER_CHALLENGES = 'DEFINE_USER_CHALLENGES';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const DEFINE_DISCOUNT_CODE = 'DEFINE_DISCOUNT_CODE';
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_WORK_MANAGER = 'SET_WORK_MANAGER';
export const SET_ACTIVE_USER_TIME = 'SET_ACTIVE_USER_TIME';
export const GAPI_LOADED = 'GAPI_LOADED';
export const QUICK_SEARCH = 'QUICK_SEARCH';
export const DEFINE_ORDERED_TASKLIST = 'DEFINE_ORDERED_TASKLIST';
export const DEFINE_PARENTCHILDIDS = 'DEFINE_PARENTCHILDIDS';
export const DEFINE_CLOSEDSORTABLES = 'DEFINE_CLOSEDSORTABLES';
export const CALCULATING = 'CALCULATING';
export const SET_TASK_DESCRIPTION = 'SET_TASK_DESCRIPTION';
export const UNSET_TASK_DESCRIPTION = 'UNSET_TASK_DESCRIPTION';
export const CLEAN_DBUPDATES = 'CLEAN_DBUPDATES';
export const RECALCULATE = 'RECALCULATE';
export const UPDATE_RECURRENT_TASK_MODAL = 'UPDATE_RECURRENT_TASK_MODAL';
export const CLOSE_RECURRENT_TASK_MODAL = 'CLOSE_RECURRENT_TASK_MODAL';
export const SET_ACCOUNT_SETTINGS = 'SET_ACCOUNT_SETTINGS';
export const SET_TASKS = 'SET_TASKS';
export const SET_USERS = 'SET_USERS';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_DAYS_OFF = 'SET_DAYS_OFF';
export const SET_SKILLS = 'SET_SKILLS';
export const SET_CLICKUP_ACCOUNTS = 'SET_CLICKUP_ACCOUNTS';
export const SET_TASKS_ESTIMATIONS = 'SET_TASKS_ESTIMATIONS';
export const SET_ACCOUNT_PROPERTY_DATA = 'SET_ACCOUNT_PROPERTY_DATA';
export const DEFAULT_MODAL = 'DEFAULT_MODAL';
export const UPGRADE_SUBSCRIPTION_MODAL = 'UPGRADE_SUBSCRIPTION_MODAL';
export const DEFINE_USER_FIRST_STEPS = 'DEFINE_USER_FIRST_STEPS';
export const DEFINE_USER_FIRST_CALENDAR_CONNECTED = 'DEFINE_USER_FIRST_CALENDAR_CONNECTED';
export const DEFINE_USER_POPUP_GUIDE_CONFIG = 'DEFINE_USER_POPUP_GUIDE_CONFIG';
export const RESET_SETTINGS = 'RESET_SETTINGS';
export const SET_FILTERS = 'SET_FILTERS';
export const OPEN_CHANGE_TASK_LOCATION_MODAL = 'OPEN_CHANGE_TASK_LOCATION_MODAL';
export const CLOSE_CHANGE_TASK_LOCATION_MODAL = 'CLOSE_CHANGE_TASK_LOCATION_MODAL';
export const OPEN_SELECT_DEPENDENCIES_MODAL = 'OPEN_SELECT_DEPENDENCIES_MODAL';
export const CLOSE_SELECT_DEPENDENCIES_MODAL = 'CLOSE_SELECT_DEPENDENCIES_MODAL';
export const SET_LIMITVIEWTO = 'SET_LIMITVIEWTO';
export const SHOW_FILTERS = 'SHOW_FILTERS';
export const SET_CALENDAR_MEMBERS_TO_SHOW = 'SET_CALENDAR_MEMBERS_TO_SHOW';
export const COMBINED_ENGINE_UPDATE = 'COMBINED_ENGINE_UPDATE';
export const SET_TIMELINE_RANGE = 'SET_TIMELINE_RANGE';
export const UPDATE_SINGLE_TASK = 'UPDATE_SINGLE_TASK';
export const UPDATE_SINGLE_USER = 'UPDATE_SINGLE_USER';
export const SET_ACCOUNTDATA_SYNC_APPS = 'SET_ACCOUNTDATA_SYNC_APPS';
export const SET_TIMELINE_COLUMNS = 'SET_TIMELINE_COLUMNS';
export const SET_TASKS_LOADED = 'SET_TASKS_LOADED';
export const SELECT_SOURCE_STATUS_MODAL = 'SELECT_SOURCE_STATUS_MODAL';
