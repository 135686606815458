import React from 'react';
import { useIntl } from 'react-intl';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function SplitBy({
    onChange,
    currentValue
}) {
    const intl = useIntl();
    const options = [
        {value: 'day', text: intl.formatMessage({ id: 'Day'})},
        {value: 'week', text: intl.formatMessage({ id: 'Week'}) },
        {value: 'month', text: intl.formatMessage({ id: 'Month'})},
        {value: 'year', text: intl.formatMessage({ id: 'Year'})},
    ];

    return <ToggleButtonGroup className="splitByToggle" orientation="horizontal" value={currentValue} exclusive onChange={onChange}>
        {options.map(({value, text}) =>  <ToggleButton key={value} value={value} aria-label={value}>
            {text}
        </ToggleButton> )}
    </ToggleButtonGroup>;
}