import React, {useState, useEffect, memo} from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import _ from 'underscore';
import TextField from '@material-ui/core/TextField';
import SelectTeamMember from '../SelectTeamMember/SelectTeamMember';
import TeamMemberSlider from '../../components/TeamMemberSlider/TeamMemberSlider';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { Button, Typography } from '@material-ui/core';
import { getAccountId, getAccountPlan, getAccountSkills, getAccountUsers } from '../../utils/selectors/account';
import CustomSideBar from '../CustomSideBar/CustomSideBar';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const EditSkill = ({ routeParams }) => {
    const intl = useIntl();
    const [name, setName] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [usersWithSkill, setUsersWithSkill] = useState(null);

    const accountId = useSelector(getAccountId);
    const skills = useSelector(getAccountSkills);
    const users = useSelector(getAccountUsers);
    const accountPlan = useSelector(getAccountPlan);
    const skillId = routeParams.uid;
    
    
    const t = (id, values) => {
        return intl.formatMessage({id}, values);
    };

    useEffect(() => {        
        if(!_.isEmpty(skills) && !_.isEmpty(users) && skillId) {
            firebaseEvents.addChallenge('editSkill');
            const usersWithCurrSkill = getUsersWithCurrSkill(users);
    
            setUsersWithSkill(usersWithCurrSkill);
            setName(skills[skillId]?.name);
            setSelectedUsers(usersWithCurrSkill ? usersWithCurrSkill.map(u=>u.userId) : []);
        }

    }, [skills, users, skillId]); 

    useEffect(() => {
        if(!showUserSelect) {
            setShowUserSelect(false);
        }
    }, [showUserSelect]);

    const getUsersWithCurrSkill = (users) => {
        const usersWithCurrSkill = Object.values(users).reduce((acc, value) => {
            if(value.skills && value.skills[skillId] && value.skills[skillId] !== 0) {
                acc.push({
                    ...value,
                    skillLvl: value.skills[skillId],
                });
            }
            return acc;
        }, []);
        
        return usersWithCurrSkill;
    };

    const handleSelectUsers = (values) => {
        let usersToAddSkill = Array.isArray(values) ? values : [values];
        let usersToRemoveSkill = selectedUsers.filter((u)=>!usersToAddSkill.includes(u));

        if(usersToAddSkill.length) {
            firebaseEvents.addSkillToUsers(
                {
                    skillKey: skillId,
                    usersAndSkillLvl: usersToAddSkill.map(userId => {
                        let skillLvl = 5;
                        if(users[userId] && users[userId].skills && users[userId].skills[skillId] && users[userId].skills[skillId] !== 0) {
                            skillLvl = users[userId].skills[skillId];
                        }

                        return (
                            {   
                                userId, 
                                skillLvl: skillLvl,
                            }
                        );
                    }),
                }
            );
        }

        if(usersToRemoveSkill.length) {
            firebaseEvents.removeSkillFromUsers(
                {
                    skillKey: skillId,
                    usersToRemoveSkill: usersToRemoveSkill,
                }
            );
        }
        
        setShowUserSelect(false);
    };

    const changeName = (e) => {
        setName(e.target.value);
    };

    const saveName = () => {
        const newName = name.trim() !== '' ? name : 'Untitled';
        setName(newName);
        
        firebaseEvents.addChallenge('changeSkillName');
        firebaseEvents.updateSkillData(
            accountId,
            skillId, 
            {name: newName}
        );
    };

    const close = () => {
        setTimeout(()=>{
            browserHistory.push('/settings/skills');
        }, 500);
    };

    const onOpen = () => {
        setShowUserSelect(true);
    };

    const onClose = () => {
        setShowUserSelect(false);
    };

    const onKeyUp = (e) => {
        if(e.key === 'Enter' || e.keyCode === 13) {
            onOpen();
            e.stopImmediatePropagation();
        }
    };

    return (
        <CustomSideBar onClose={close} className={'EditSkill'}>
            {
                skills && (
                    <>
                        <div className='EditSkillTopBar'>
                            <h2>{t('Edit skill')}</h2>
                            <TextField fullWidth={true} type="text" className='EditSkillName' label={t('Skill name')} value={name} onChange={changeName} onBlur={saveName} />
                        </div>
                        
                        <Typography className='teamMembers' component="div" role="tabpanel" variant="body2">
                            <p>{t('employee.team.members.info.tooltip')}</p>
                            <div className="teamMemberSelect">
                                <Button variant="contained" color="primary" onKeyUp={onKeyUp} onClick={onOpen}> {t('Add Resource')} </Button>
                                    
                                {showUserSelect && <SelectTeamMember
                                    maxSelect={Object.values(users).length + 1}
                                    currentUser={selectedUsers}
                                    onSelect={handleSelectUsers}
                                    onClose={onClose}
                                    open={showUserSelect}
                                />}
                            </div>
                                    
                            {
                                usersWithSkill && usersWithSkill.map(({color, userId, displayName, avatar, skillLvl}, index) => 
                                    <TeamMemberSlider
                                        userId={userId} 
                                        color={color} 
                                        skillLvl={skillLvl}
                                        key={`${userId}-${index}`} 
                                        skillId={skillId} 
                                        displayName={displayName} 
                                        avatar={avatar}
                                        accountPlan={accountPlan}
                                    />
                                )
                            }
        
                        </Typography>
                    </>
                )
            }
        </CustomSideBar>
    );
};

export default memo(withCustomErrorBoundary(EditSkill));