import React from 'react';
import Button from '@material-ui/core/Button';
import Table from './Table';
import CsvIcon from '@material-ui/icons/InsertDriveFile';


export default ({id, name , data, exportAsCSV, extraRows, renderRow,defaultOrder, defaultSort, headCells}) => {
    const handleExportAsCSV = () => {
        exportAsCSV({name, data});
    };

    return <div key={id}>
        <div className='container'>
            <div className='title'>
                {name}
            </div>
            <div>
                <Button size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />}  onClick={handleExportAsCSV}>
                    Save as CSV
                </Button>
            </div>
        </div>
        <div>
            {data.length ? <Table 
                extraRows={extraRows} 
                defaultOrder={defaultOrder} 
                size="small" defaultSort={defaultSort} 
                renderRow={renderRow} 
                rows={data}
                headCells={headCells} 
            /> : <div> Invalid Data </div>}
        </div>
    </div>;
};
