import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/storage';
import classnames from 'clsx';
import { toastr } from 'react-redux-toastr';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoIcon from '@material-ui/icons/Info';

import _ from 'underscore';
import { MentionsInput, Mention } from 'react-mentions';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Tooltip from 'rc-tooltip';
import { Picker as EmojiPicker } from 'emoji-mart';
import EmojiIcon from '@material-ui/icons/InsertEmoticon';
import FileIcon from '@material-ui/icons/AttachFile';
import FileUploader from 'react-firebase-file-uploader';
import { getItemPermissions } from '../../utils/utils';
import { getOnlyUsers } from '../../utils/memberTypes';
import { STORAGE_LIMIT } from '../../utils/constants';
import { MENTIONS_INPUT_STYLES } from './MentionsInputStyles';

import $ from 'jquery';
import {
    getAccountBasePermissions,
    getAccountId,
    getAccountPlan,
    getAccountStorageSize,
    getAccountTaskPermissions,
    getAccountTasks,
    getAccountUsers,
} from '../../utils/selectors/account';
import { getUserId } from '../../utils/selectors/user';
import StateUserAvatar from '../../components/UserAvatar/StateUserAvatar';

const TaskCommentInput = ({ comment, onCancel, taskId, commentId }) => {
    const intl = useIntl();

    const [commentValue, setCommentValue] = useState(comment || '');
    const [mentions, setMentions] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState();
    const [fileUploader, setFileUploader] = useState(null);

    const accountId = useSelector(getAccountId);
    const storageSize = useSelector(getAccountStorageSize);
    const plan = useSelector(getAccountPlan);
    const tasks = useSelector(getAccountTasks);
    const taskPermissions = useSelector(getAccountTaskPermissions(taskId));
    const basePermissions = useSelector(getAccountBasePermissions);
    const userId = useSelector(getUserId);
    const accountUsers = useSelector(getAccountUsers);
    const users = getOnlyUsers(accountUsers);

    const updateComment = () => {
        if (commentValue) {
            firebaseEvents.updateTaskComment(accountId, taskId, commentId, userId, commentValue, mentions);

            onCancel();
            setCommentValue('');
            setMentions(null);
        }
    };

    const sendComment = () => {
        if (commentValue) {
            firebaseEvents.addTaskComment(accountId, taskId, userId, commentValue, mentions);

            if (mentions && mentions.length > 0) {
                firebaseEvents.addChallenge('commentMention');
            } else {
                firebaseEvents.addChallenge('comment');
            }

            setCommentValue('');
            setMentions(null);
        }
    };

    const manageKeydown = e => {
        if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
            if (onCancel) {
                updateComment();
            } else {
                sendComment();
            }
        }
    };

    useEffect(() => {
        $('#TaskCommentInput textarea').on('keydown', manageKeydown);
        $('.TaskDetail .sidebar').css('overflow', 'hidden');

        return function cleanup() {
            $('#TaskCommentInput textarea').off('keydown', manageKeydown);
        };
    });

    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
        setCommentValue(newValue);
        setMentions(mentions);
    };

    const getUsersForMentions = () => {
        if (taskPermissions) {
            var list = [];

            const permissions = getItemPermissions(null, taskId, taskPermissions, tasks, false, basePermissions).perms;

            for (var i in users) {
                if (users[i].permissions && users[i].permissions.admin) {
                    list.push(i);
                }
            }

            list = _.union(list, permissions.owners, permissions.managers, permissions.workers, permissions.viewers);

            var results = [];
            list.forEach(u => {
                if (u !== userId && users[u] && users[u]?.displayName) {
                    results.push({
                        id: u,
                        display: users[u] ? users[u].displayName : '',
                        color: users[u] ? users[u].color : '',
                        avatar: users[u]?.avatar,
                    });
                }
            });
            return results;
        }

        return null;
    };

    const renderSuggestion = entry => {
        return (
            <div className="suggestItem">
                <StateUserAvatar
                    className="avatar"
                    userId={entry.id}
                />
                {entry.display}
            </div>
        );
    };

    const insertEmoji = emoji => {
        setShowEmojiPicker(false);
        setCommentValue(commentValue + emoji.native);
    };

    const uploadFile = e => {
        const {
            target: { files },
        } = e;
        if (files && files.length) {
            var fileSizeGb = files[0].size / 1000 / 1000 / 1000;
            var workspaceSize = storageSize || 0;

            if (fileSizeGb > 0.25) {
                toastr.error(intl.formatMessage({ id: 'file.over.250MB' }));
            } else if (STORAGE_LIMIT[plan] && workspaceSize + fileSizeGb > STORAGE_LIMIT[plan]) {
                toastr.error(intl.formatMessage({ id: 'max.workspace.storage.error' }));
            } else {
                firebase
                    .storage()
                    .ref('files/' + accountId + '/' + taskId + '/comments')
                    .listAll()
                    .then(t => {
                        var sameNameExist = false;
                        if (t.items && t.items.length) {
                            t.items.forEach(i => {
                                if (!sameNameExist && i.name === files[0].name) {
                                    sameNameExist = true;
                                }
                            });
                        }
                        if (sameNameExist) {
                            toastr.error(intl.formatMessage({ id: 'storage.filename.same.error' }));
                        } else {
                            fileUploader.startUpload(files[0]);
                        }
                    });
            }
        }
    };

    const handleUploadStart = () => {
        setIsUploading(true);
        setProgress(0);
    };

    const handleProgress = progress => {
        setProgress(progress);
    };

    const handleUploadError = () => {
        toastr.error(intl.formatMessage({ id: 'error uploading file' }));
        setIsUploading(false);
    };
    const handleUploadSuccess = filename => {
        var fullPath = 'files/' + accountId + '/' + taskId + '/comments';

        firebase
            .storage()
            .ref(fullPath)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                firebase
                    .storage()
                    .ref(fullPath)
                    .child(filename)
                    .getMetadata()
                    .then(metadata => {
                        firebaseEvents.addFileToTaskComment(accountId, userId, taskId, {
                            contentType: metadata.contentType,
                            firebaseStorage: true,
                            name: filename,
                            url: url,
                            fullPath: metadata.fullPath,
                            size: metadata.size / 1000 / 1000 / 1000,
                        });
                    });
            });

        setIsUploading(false);
        setProgress(100);
    };

    if (tasks && users && basePermissions) {
        var emojiPicker = null;
        if (showEmojiPicker) {
            emojiPicker = (
                <EmojiPicker
                    set="twitter"
                    emojiSize={20}
                    theme="light"
                    showPreview={false}
                    showSkinTones={false}
                    style={{ position: 'absolute', bottom: '30px', left: '-190px' }}
                    onSelect={insertEmoji}
                />
            );
        }

        return (
            <div
                className={classnames('TaskCommentInput', { edit: commentId !== null && commentId !== undefined })}
                id="TaskCommentInput"
            >
                <MentionsInput value={commentValue} onChange={handleChange} style={MENTIONS_INPUT_STYLES}>
                    <Mention
                        trigger="@"
                        data={getUsersForMentions()}
                        renderSuggestion={renderSuggestion}
                        style={{ backgroundColor: 'rgba(30, 79, 162, 0.15)', borderRadius: '3px' }}
                    />
                </MentionsInput>
                <div className="buttons">
                    <div className="commentTooltip">
                        <Tooltip
                            placement="top"
                            overlay={intl.formatMessage({ id: 'comment.info.tooltip' })}
                            arrow={false}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    <div className="emojiPicker">
                        <EmojiIcon onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
                        {emojiPicker}
                    </div>
                    <div className="FilePicker">
                        <FileIcon />
                        <FileUploader
                            ref={instance => {
                                setFileUploader(instance);
                            }}
                            accept="*"
                            storageRef={firebase.storage().ref('files/' + accountId + '/' + taskId + '/comments')}
                            onChange={uploadFile}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadSuccess}
                            onProgress={handleProgress}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                width: '100%',
                                opacity: 0,
                            }}
                        />
                    </div>
                    {onCancel ? (
                        <div className="editButtons">
                            <Button variant="contained" size="small" onClick={onCancel}>
                                {intl.formatMessage({ id: 'Cancel' })}
                            </Button>
                            <Button variant="contained" size="small" color="primary" onClick={updateComment}>
                                {intl.formatMessage({ id: 'Save' })}
                            </Button>
                        </div>
                    ) : (
                        <Button
                            className="addCommentBtn"
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={sendComment}
                        >
                            {intl.formatMessage({ id: 'Add Comment' })}
                        </Button>
                    )}
                </div>

                {isUploading ? (
                    <div className="fileUploadProgress">
                        <p>{intl.formatMessage({ id: 'Uploading file' })}...</p>
                        <LinearProgress variant="determinate" value={progress} />
                        <div>{progress}%</div>
                    </div>
                ) : null}
            </div>
        );
    }
    return null;
};

export default TaskCommentInput;
