import React, { useState } from 'react';
import classnames from 'clsx';
import { toastr } from 'react-redux-toastr';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import _ from 'underscore';

import TaskStatusIcon from '../../../TaskStatusIcon/TaskStatusIcon';
import { desyncProject } from '../../../../utils/services/syncApp';
import { getAccountId, getAccountTasks } from '../../../../utils/selectors/account';
import { getParentChildIds } from '../../../../utils/selectors/app';
import { hideLoader, showLoader } from '../../../App/AppActions';
import { withCustomErrorBoundary } from '../../../../utils/CustomErrorBoundary/CustomErrorBoundary';
import { segmentTrack } from '../../../../utils/firebaseEvents';
import { STOP_SYNC_EVENT_BY_TOOL } from './constants';
import { getUserId } from '../../../../utils/selectors/user';
import { getAccountDataSyncAppFolders } from '../../../../utils/selectors/accountData';
import { updateSyncAppCounterNbProjects } from '../../../../utils/controllers/account/settings/syncAppCounters';
import { SYNC_APP_TYPE } from '../../utils';

const TOOLS_WITHOUT_IMPORT_SUBTASKS = ['trello'];

const LineItem = ({ name, parents = [], childs = [], importSubtasks, selectedItem, syncAppId, toolName }) => {
    const [openArrow, setOpenArrow] = useState(true);

    const intl = useIntl();
    const dispatch = useDispatch();
    const currentUserId = useSelector(getUserId);
    const accountId = useSelector(getAccountId);
    const parentChildIds = useSelector(getParentChildIds);
    const accountTasks = useSelector(getAccountTasks);
    const syncFolder = useSelector(getAccountDataSyncAppFolders(syncAppId));

    const handleCloseParent = React.useCallback((e) => {
        e.stopPropagation();

        e.target.closest('.ProjectsTreeView').childNodes.forEach(childItem => {
            if(childItem.classList.contains('ProjectsTreeView')) {
                childItem.classList.toggle('hidden');
            }
        });

        setOpenArrow(!openArrow);
    }, [openArrow]);

    const handleStopSynchronization = React.useCallback(() => {
        toastr.confirm(
            intl.formatMessage({id:'synchronization.stop.alert'}),
            {
                onOk: async () => {
                    try {
                        dispatch(showLoader());
                        const taskKeySourceMap = Object
                            .keys(accountTasks)
                            .filter(taskKey =>
                                accountTasks[taskKey].isConnectProject &&
                                accountTasks[taskKey].syncAppType === toolName
                            )
                            .reduce((acc, taskKey) => {
                                if (acc[taskKey]) {
                                    return;
                                }

                                const task = accountTasks[taskKey];
                                const sourceIdentifier = toolName === SYNC_APP_TYPE.JIRA ? task.sourceKey : task.sourceId;

                                return {
                                    ...acc,
                                    [sourceIdentifier]: taskKey
                                };
                            }, {});

                        const taskKey = taskKeySourceMap[selectedItem];

                        if (!taskKey) {
                            throw new Error('Task not found');
                        }

                        await desyncProject({
                            accountId,
                            syncAppId,
                            mainSourceId: selectedItem,
                            taskIds: [taskKey, ..._.get(parentChildIds, ['childs', taskKey], [])],
                            parentTaskIds: _.get(parentChildIds, ['parents', taskKey], []),
                        });

                        const existingIds = Object.values(syncFolder || {}).map(el => el.sourceId);
                        const nbConnectedProjects = existingIds.filter(id => !!id && id !== selectedItem).length;
                        await updateSyncAppCounterNbProjects({ syncAppType: toolName, nbConnectedProjects });

                        toastr.success(intl.formatMessage({id:'Synchronization successfuly ended'}));
                    } catch (error) {
                        toastr.error(intl.formatMessage({id: 'stop.sync.error'}));
                    } finally {
                        dispatch(hideLoader());
                    }
                },
                okText: intl.formatMessage({id:'CONTINUE'}),
                cancelText: intl.formatMessage({id:'CANCEL'}),
            },
        );

        segmentTrack(STOP_SYNC_EVENT_BY_TOOL[toolName], { userId: currentUserId });
    }, [desyncProject, accountId, syncAppId, selectedItem, parentChildIds, dispatch, toolName, currentUserId, syncFolder]);

    return (
        <div
            className={'SelectProject__outerline'}
        >
            <div
                className={'SelectProject__innerline'}
                style={{marginLeft: `${parents.length * 2}rem`}}
            >

                {!_.isEmpty(childs) && openArrow && <KeyboardArrowDown onClickCapture={handleCloseParent}/>}
                {!_.isEmpty(childs) && !openArrow && <KeyboardArrowRight onClickCapture={handleCloseParent}/>}

                <span>{name}</span>


                {_.isEmpty(childs) && (
                    <div className={'ProjectsTree__options'}>
                        {!TOOLS_WITHOUT_IMPORT_SUBTASKS.includes(toolName) && <TaskStatusIcon className={classnames('icon', {done: importSubtasks})}/>}
                        <Button className={'stopSyncButton'} variant='contained' onClick={handleStopSynchronization}>Stop Sync</Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withCustomErrorBoundary(LineItem);