import moment from 'moment';
import React from 'react';
import VisJsTimeline from 'react-visjs-timeline';
import { TIMELINE_HEADER_SIZE, TASK_LINE_SIZE } from '../../utils/constants';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const Timeline = ({
    nbTasks,
    stack,
    timeFormat,
    start,
    end,
    rangechangedHandler,
    clickHandler,
    items,
    groups,
    changedHandler,
    itemoverHandler,
    zoomMin,
    zoomMax,
    minOption,
    timeAxis
})=>{
    
    let tf= (timeFormat === 'LT') ? 'LT' : 'HH:mm', 
        tfs = (timeFormat === 'LT') ? 'LTS' : 'HH:mm:ss';
    
    const duration = moment.duration(moment(end).diff(moment(start))).asDays();

    const options = {
        width: '100%',
        autoResize: true,
        horizontalScroll: true,
        stack: stack,
        showCurrentTime: true,
        locale: 'en',
        zoomKey: 'metaKey',
        zoomMin: (zoomMin)?zoomMin:1000*60*60*24*7,
        zoomMax: (zoomMax)?zoomMax:1000*60*60*24*365*5,
        orientation: 'top',
        groupOrder: 'index',
        start: start,
        end: end,
        tooltip: {
            followMouse: true
        },
        format : {
            minorLabels: {
                millisecond:'SSS',
                second:     's',
                minute:     tf,
                hour:       tf,
                weekday:    'ddd D',
                day:        'D',
                week:       '[W]w',
                month:      'MMMM',
                year:       'YYYY'
            },
            majorLabels: {
                millisecond: tfs,
                second:     'D MMMM ' + tf,
                minute:     'ddd D MMMM',
                hour:       'ddd D MMMM',
                weekday:    'MMMM YYYY',
                day:        'MMMM YYYY',
                week:       'MMMM YYYY',
                month:      'YYYY',
                year:       ''
            }
        },
        timeAxis: {
            scale: (duration < 60) ? 'day' : (duration < 180) ? 'week' : (duration < 700) ? 'month' : 'year',
            step: 1
        }
    };

    if(nbTasks){
        options.height = TIMELINE_HEADER_SIZE + ( TASK_LINE_SIZE * nbTasks );
    }
    if(minOption){
        options.min = minOption;
    }
    if(timeAxis){
        options.timeAxis = timeAxis;
    }

    return (
        <VisJsTimeline
            items={items} 
            options={options} 
            groups={groups}
            animate={{
                duration: 1000,
                easingFunction: 'easeInQuint'
            }}
            rangechangedHandler={rangechangedHandler}
            clickHandler={clickHandler}
            changedHandler={changedHandler}
            itemoverHandler={itemoverHandler}
        />
    );
};

export default React.memo(withCustomErrorBoundary(Timeline));