import React from 'react';
import PropTypes from 'prop-types';
import TaskEffortInputs from '../TaskEffortInputs/TaskEffortInputs';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from 'rc-tooltip';
import { useSelector } from 'react-redux';
import { getAccountTaskHasChildren, getAccountTaskISourceId, getAccountTaskStatus } from '../../utils/selectors/account';
import { useIntl } from 'react-intl';

const TaskDetailEffortInputs = ({ taskId }) => {
    const intl = useIntl();
    const taskStatus = useSelector(getAccountTaskStatus(taskId));
    const taskHaschildren = useSelector(getAccountTaskHasChildren(taskId));
    const taskSourceId = useSelector(getAccountTaskISourceId(taskId));

    const isDisabled = taskStatus === 'done' || taskHaschildren || (taskStatus === 'inprogress' && !taskSourceId);

    return (
        <li>
            <dl>
                <dt>
                    {intl.formatMessage({id:'Effort'})} (h){' '}
                    <Tooltip placement="top" overlay={intl.formatMessage({id:'task.table.effort.help.text'})}>
                        <InfoIcon />
                    </Tooltip>
                </dt>
                <dt>
                    <TaskEffortInputs taskId={taskId} disabled={isDisabled}/>
                </dt>
            </dl>
        </li>
    );
};

TaskDetailEffortInputs.propTypes = {
    taskId: PropTypes.string.isRequired
};

export default TaskDetailEffortInputs;
