import React, {  useEffect, useRef, useState } from 'react';
import _ from 'underscore';
import Proptypes from 'prop-types';
import { getColor } from './utils';
import * as ChartJs from 'chart.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import CsvIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import html2canvas from 'html2canvas';

ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

const ChartWithList = ({ 
    chartData, 
    chartTitle,
    id,
    totalDataOfChart,
    exportCSV,
    isEmpty
}) => {
    const chartRef = useRef();
    const [chart, setChart] = useState(null);
    const [usedColor, setUsedColor] = useState([]);

    useEffect(() => {
        if(chart){
            const colors = [];
            const data = Object
                .values(chartData)
                .sort((a,b) => b.value - a.value)
                .reduce((acc, entry, idx) => {
                    const color = getColor(idx + 1,idx)[0];

                    if(idx === 4) 
                    { 
                        acc.labels.push('Others');
                        acc.datasets[0].data.push(entry.value);
                        acc.datasets[0].backgroundColor.push(color);
                        colors.push(color);

                        return acc;
                    }
                    if(idx > 4) {
                        colors.push(acc.datasets[0].backgroundColor[4]);

                        if(idx ===  Object.values(chartData).length - 1) {
                            acc.datasets[0].data[4] = `${parseFloat((((acc.datasets[0].data[4] + entry.value) / totalDataOfChart)  * 100  )).toFixed(2)}`;
                            return acc;
                        }
                        
                        acc.datasets[0].data[4] = acc.datasets[0].data[4] + entry.value;
                        return acc;
                    }
                    
                    acc.labels.push(entry.name);
                    acc.datasets[0].data.push( entry.value > 0 ? `${parseFloat((entry.value / totalDataOfChart) * 100).toFixed(2)}` : 0);
                    acc.datasets[0].backgroundColor.push(color);
                    colors.push(color);

                    return acc;
                }, {
                    labels: [],
                    datasets: [{
                        label: chartTitle,
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    }]
                });
            chart.data.datasets = data.datasets;
            chart.data.labels = data.labels;
            chart.update();
            setUsedColor(colors);
        }

    }, [chartData, chart, totalDataOfChart]);

    useEffect( () => {
        if(!isEmpty) {
            const thisChart = new ChartJs.Chart(chartRef.current.getContext('2d'), {
                type: 'pie',
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        }
                    }
                }
            });
            setChart(thisChart);
        }
    }, [chartRef, isEmpty]); 

    const downloadImg = () => {
        html2canvas(document.querySelector(`#${id}-chart`))
            .then(canvas => {
                document.body.appendChild(canvas);
                canvas.style.display = 'none';
                return canvas;
            })
            .then(canvas => {
                const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                const a = document.createElement('a');
                a.setAttribute('download', 'my-image.png');
                a.setAttribute('href', image);
                a.click();
                canvas.remove();
            });
    };
    
    if(isEmpty) {
        return <div id={`${id}-chart`} className='chart-list-continer'>
            <div className='breakdown-sub-header'>
                <h4>{chartTitle}</h4>
            </div>
            <div  className='chart-container'>
                <div className={'empty'}>No data found</div>
            </div>
        </div>;
    }

    return <div id={`${id}-chart`} className='chart-list-continer'>
        <div className='breakdown-sub-header'>
            <h4>{chartTitle}</h4>
            <div data-html2canvas-ignore>
                <Button
                    size="small" color="primary" aria-label="save as csv" startIcon={<CsvIcon />} 
                    onClick={()=> exportCSV(chartData, totalDataOfChart, chartTitle)}>
                    Save as CSV
                </Button>
                <Button
                    size="small" 
                    color="primary"
                    aria-label="save as img" 
                    startIcon={<ImageIcon />}  
                    onClick={()=> downloadImg(chartRef.current)}>
                    Save as Img
                </Button>
            </div>
        </div>
        <div  className='chart-container'>
            <div>
                {
                    !_.isEmpty(chartData) && <List dense>
                        {Object
                            .values(chartData)
                            .sort((a,b)=> {
                                if(a.name === 'Others'){
                                    return 1;
                                }
                                
                                if(b.name === 'Others'){
                                    return -1;
                                }
                                
                                return -(a.value- b.value);
                            }).map((el,idx) =>
                                <ListItem key={el.name}>
                                    <div style={{
                                        backgroundColor: usedColor[idx],
                                        height: '1rem',
                                        width: '1rem',
                                        borderRadius: '1rem',
                                        marginRight: '0.5rem',
                                    }} />
                                    <ListItemText
                                        className={'list-text'}
                                        primary={el.name}
                                        secondary={el.value > 0 ? `${parseFloat((el.value / totalDataOfChart) * 100).toFixed(2)}%` : `${0}%`}
                                    />
                                </ListItem>)}
                    </List>
                }
            </div>
            <div>
                <canvas ref={chartRef} />
            </div>
        </div>
    </div>;
};

ChartWithList.propTypes = {
    chartData: Proptypes.object.isRequired, 
    chartTitle: Proptypes.string.isRequired,
    id: Proptypes.string.isRequired,
    totalDataOfChart: Proptypes.number.isRequired,
    exportCSV: Proptypes.func.isRequired,
    isEmpty: Proptypes.bool.isRequired,
};

export default ChartWithList;