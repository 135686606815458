import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import classnames from 'clsx';
import { openReccurentTaskModal } from '../App/AppActions';
import DefaultTootip from '../../utils/defaultTooltip';
import { getAccountId, getAccountTaskCanManage, getAccountTaskHasChildren, getAccountTaskIndex, getAccountTaskIsRecurring, getAccountTaskParent, getAccountTasksCount, getAccountTaskTitle } from '../../utils/selectors/account';
import { getPopupGuidesEnterToAdd } from '../../utils/selectors/app';
import { withCustomErrorBoundary } from '../../utils/CustomErrorBoundary/CustomErrorBoundary';

const TASKTOSHOWTOLTIP = 2;

const TaskTitleInput = ({taskId, value, taskLine, placeholder, titleInputRef, isTaskSyncProject})=>{
    const dispatch = useDispatch();
    const accountId = useSelector(getAccountId);
    const taskIndex = useSelector(getAccountTaskIndex(taskId));
    const taskTitle = useSelector(getAccountTaskTitle(taskId));
    const taskParent = useSelector(getAccountTaskParent(taskId));
    const taskIsRecurringTask = useSelector(getAccountTaskIsRecurring(taskId));
    const taskHasChildrens = useSelector(getAccountTaskHasChildren(taskId));
    const canManage = useSelector(getAccountTaskCanManage(taskId));
    const nbTasks = useSelector(getAccountTasksCount);
    const guidePopupEnterToAdd = useSelector(getPopupGuidesEnterToAdd);
    const [stateValue, setStateValue] = useState(value);
    const [showInput, setShowInput] = useState(false);
    
    useEffect(()=>{
        setStateValue(value);
    }, [taskId]);


    const updateTaskName = (e) => {
        setStateValue(e.target.value);
    };

    const saveTaskTitle = () => {
        if(taskTitle !== stateValue) {
            firebaseEvents.updateTaskTitle(accountId, taskId, stateValue || '');
            
            dispatch(openReccurentTaskModal({
                taskId: taskId,
                updatedField: 'title',
            }));
    
            firebaseEvents.addChallenge('changeTaskName');
        }
        setShowInput(false);
    };


    const doShowInput = (e) => {
        if (
            e.type === 'focus' &&
            (e.nativeEvent.relatedTarget === null || $(e.nativeEvent.relatedTarget).hasClass('TaskTitleInput')) &&
            !$(e.currentTarget).hasClass('focused') || (isTaskSyncProject && !taskParent)
        ) {
            return false;
        }
        if (canManage) {
            setShowInput(true);
        }
    };


    const isNotRecurringTask = !taskIsRecurringTask || (taskIsRecurringTask && taskHasChildrens);

    if (showInput && isNotRecurringTask) {
        return <>
            <input
                className={classnames('TaskTitleInput', {
                    fromTaskLine: taskLine,
                    disabled: (isTaskSyncProject && !taskParent)
                })}
                type="text"
                autoFocus
                data-task-id={taskId}
                data-task-index={taskIndex}
                value={stateValue}
                placeholder={placeholder}
                onChange={updateTaskName}
                onBlur={saveTaskTitle}
            />
            {/* {titleInputRef && !guidePopupEnterToAdd && showInput  && <DefaultTootip 
                open={!guidePopupEnterToAdd && nbTasks === TASKTOSHOWTOLTIP} 
                placement='left' 
                newRef={titleInputRef}
                tooltipId='enterToAdd'
                taskId={taskId}
            />} */}
        </>;
            
    }

    return <>
        <span
            className={classnames('TaskTitleInput', {
                disabled: !canManage || (isTaskSyncProject && !taskParent),
                fromTaskLine: taskLine,
            })}
            onClick={doShowInput}
            onFocus={() => setTimeout(() => showInput, 50)}
            data-task-id={taskId}
            tabIndex="0"
        >
            {stateValue}
        </span>
        {titleInputRef && !guidePopupEnterToAdd && showInput && <DefaultTootip 
            open={!guidePopupEnterToAdd && showInput && nbTasks === TASKTOSHOWTOLTIP} 
            placement='left' 
            newRef={titleInputRef}
            tooltipId='enterToAdd'
            taskId={taskId}
        />}
    </>;

};

export default withCustomErrorBoundary(TaskTitleInput);
